import {CeeViewDataSource, Renderer, State, UserPrefs, Utils} from "tools";
import {Application, Cookies, LocalEventsManager, RemoteEventsManager} from "core";
import {
	CustomNotification,
	Dependencies,
	EventsToolbar,
	GridMenu,
	ModalWindow,
	MultiSelectGridFilter,
	PreferencesPanel
} from "controls";
import {Settings} from "settings";
import ErrorHandler from 'core/errorHandler';
import {AgentsRouter} from 'areas/management/agents/bundleDescription';
import {IncidentsRouter} from "../incidents/bundleDescription";
import {getSeverityState} from 'controls/stateRenderer/severityState';
import {SetInformationWindow} from 'controls/setInformationWindow';
import React from "react";
import ReactDOM from "react-dom";

export default function AssetHealthReasons (config) {
	Utils.apply(this, config);
	this.module = {
		name: 'AssetHealthReasons',
		initialConfig: config
	};
    this.hasEvents = true;
    this.subscriberId = Utils.guid();
	this.loadUserPreferences();
};

jQuery.extend(AssetHealthReasons.prototype, Application.prototype, {
	/**
	 * This is main init function
	 */
	initComponent: function () {
		Application.prototype.initComponent.call(this);

		this.indicatorSelector = '.reason_view .cw_new_tag_indicator';

		if (!this.childMode) {

		}

		this.actionNotification = new CustomNotification({
			appendToElement: '.cw_health_reasons_list',
		});
		this.id = Utils.guid();
		var eventStatus = JSON.parse(UserPrefs.get('eventsStatus')) || {
			startFrom: 0,
			playing: true
		};
		this.eventsToolbar = new EventsToolbar({
			renderTo: $('#cw_reasons_events_toolbar'),
			callBackFn: $.proxy(this.onEventsTimeout, this),
			subscriberId: this.subscriberId,
			startFrom: eventStatus.startFrom,
			playing: eventStatus.playing
		});
		State.markedCheckboxes = [];
		State.isFromEvent = false;
		this.initKendoComponents();
	},
	initKendoComponents() {
		this.checkboxStates = [];
		this.setDefaultColumns();
		this.setGridMessages();
		this.removeListeners();
		this.attachListeners();
		State.mainApp.contextMenu.setActionButton('ASSET');
		let datasourceParams = this.getDatasourceParams();
		let ahReasonsFilter = datasourceParams.ahReasonsFilter;
		let ahReasonsSort = datasourceParams.ahReasonsSort;
		let gridUrl = datasourceParams.gridUrl;
		this.setDataSource(gridUrl, ahReasonsFilter, ahReasonsSort);
		this.setGrid();
		this.setGridMenu();
		this.setPreferencesPanel();
		this.removeMask();
	},
	setDefaultColumns() {
		this.defaultColumns = {
			id: {
				hidden: false,
				width: 40
			},
			severity: {
				hidden: false,
				width: 45
			},
			assetName: {
				hidden: false,
				width: 240
			},
			accountName: {
				hidden: !State.includeSubaccounts,
				width: 150
			},
			agentName: {
				hidden: false,
				width: 150
			},
			timestamp: {
				hidden: false,
				width: 200
			},
			text: {
				hidden: false,
				width: 200
			},
			action: {
				hidden: false,
				width: 60
			},
			acknowledged: {
				hidden: false,
				width: 150
			},
			acknowledgedByName: {
				hidden: false,
				width: 150
			},
			acknowledgedAt: {
				hidden: false,
				width: 150
			},
			assignedToName: {
				hidden: false,
				width: 150
			},
			assignedToType: {
				hidden: false,
				width: 150
			},
			information: {
				hidden: false,
				width: 200
			},
			monitorName: {
				hidden: false,
				width: 150
			},
			monitorTypeText: {
				hidden: false,
				width: 100
			},
			monitorClassText: {
				hidden: true,
				width: 100
			},
			subsystem: {
				hidden: false,
				width: 120
			},
			identifierName: {
				hidden: true,
				width: 150
			},
			identifierInstance: {
				hidden: true,
				width: 150
			}
		};
	},
	setGridMessages() {
		this.gridMessages = {
			isTrue: '<span class="glyphicons status_icon circle-arrow-top"></span>',
			isFalse: '<span class="glyphicons status_icon circle-arrow-down"></span>',
			clear: lang.CLEAR,
			info: lang.grid.filter.SHOW_ITEMS,
			filter: lang.FILTER
		};
	},
	getDatasourceParams() {
		var ahReasonsSort, ahReasonsFilter, columns, gridUrl;
		var defaultFsView = UserPrefs.get('defaultFsView');
		if (Utils.isGuid(defaultFsView)) {
			var pref = JSON.parse(UserPrefs.get(defaultFsView));
			pref = pref || {
					sort: [],
					filter: []
				};
			ahReasonsSort = pref.sort;
			ahReasonsFilter = pref.filter;
			this.ahReasonsColumns = pref.columns;
		} else {
			if (this.isFromAssetGroup ) {
				if (UserPrefs.get('ahGroupReasonsSort') && UserPrefs.get('ahGroupReasonsSort') !== '[]') {
					ahReasonsSort = JSON.parse(UserPrefs.get('ahGroupReasonsSort'));
				}
			} else {
				if (UserPrefs.get('ahReasonsSort') && UserPrefs.get('ahReasonsSort') !== '[]') {
					ahReasonsSort = JSON.parse(UserPrefs.get('ahReasonsSort'));
				}
			}
			if (this.isFromAssetGroup) {
				if (UserPrefs.get('ahGroupReasonsFilter')) {
					ahReasonsFilter = JSON.parse(UserPrefs.get('ahGroupReasonsFilter'));
				}
			} else {
				if (UserPrefs.get('ahReasonsFilter')) {
					ahReasonsFilter = JSON.parse(UserPrefs.get('ahReasonsFilter'));
				}
			}
		}

		//if no sorting set default grid sorting
		if (!ahReasonsSort) {
			ahReasonsSort = [{
				field: 'timestamp',
				dir: 'desc',
				compare: null
			}];
		}

		if (this.isFromAssetGroup) {
			this.baseUrl = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/health/assetGroups/' + this.assetGroupId + '/reasons';
		} else {
			this.baseUrl = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/health/assetReasons';
		}
		this.includeSubaccounts = '?includeSubaccounts=' + State.includeSubaccounts;
		gridUrl = this.baseUrl + this.includeSubaccounts;

		if (this.preferences) {
			ahReasonsSort = this.preferences.sort || ahReasonsSort;
			ahReasonsFilter = this.preferences.filter || ahReasonsFilter;
		}

		ahReasonsFilter = UserPrefs.restoreDateFilter(ahReasonsFilter);

		return {
			ahReasonsSort: ahReasonsSort,
			ahReasonsFilter: ahReasonsFilter,
			gridUrl: gridUrl
		}
	},
	setDataSource(gridUrl, ahReasonsFilter, ahReasonsSort) {
		this.dataSource = new CeeViewDataSource({
			transport: {
				read: {
					url: gridUrl,
					contentType: "application/json; charset=utf-8",
					type: "POST",
					dataType: "json",
					cache: false
				},
				parameterMap: $.proxy(function (data, type) {
					if (data.filter?.filters) {
						data.filter.filters = Utils.changeDateFilterToString(data.filter.filters);
					}
					this.gridPayload = data;
					return kendo.stringify(data);
				}, this)
			},
			schema: {
				parse: response => {
					this.filterOptions = response.filterOptions;
					return response;
				},
				data: function (response) {
					var response = response.items;
					if (response.length) {
						for (var i = 0, length = response.length; i < length; i++) {
							response[i].timestamp = new Date(response[i].timestamp);
							if (response[i].assignedToType === 'ASSIGN_TEAM') {
								response[i].assignedToGlyph = 'parents';
								if (response[i].assignedTo) {
									response[i].assignedToMouseover = lang.TEAM + ': ' + response[i].assignedTo;
								} else {
									response[i].assignedToMouseover = lang.TEAM;
								}
							} else if (response[i].assignedToType === 'ASSIGN_USER') {
								response[i].assignedToGlyph = 'user';
								if (response[i].assignedTo) {
									response[i].assignedToMouseover = lang.USER + ': ' + response[i].assignedTo;
								} else {
									response[i].assignedToMouseover = lang.USER;
								}
							} else {
								response[i].assignedToGlyph = '';
								response[i].assignedToMouseover = '';
							}
							if (response[i].acknowledgedByName) {
								response[i].acknowledgedMouseover = lang.ACKNOWLEDGED_BY + ' ' + response[i].acknowledgedByName;
							} else {
								response[i].acknowledgedMouseover = '';
							}
						}
					}
					return response;
				},
				total: function (response) {
					this.visibleItems = response.visible;
					this.totalItems = response.total;
					return response.visible;
				},
				model: {
					id: 'id',
					fields: {
						id: {
							type: 'string',
							editable: false
						},
						severity: {
							type: 'string',
							editable: false
						},
						assetName: {
							type: 'string',
							editable: false
						},
						accountName: {
							type: 'string',
							editable: false
						},
						agentName: {
							type: 'string',
							editable: false
						},
						timestamp: {
							type: 'date',
							editable: false
						},
						text: {
							type: 'string',
							editable: false
						},
						action: {
							nullable: true,
							editable: true
						},
						acknowledged: {
							type: 'boolean',
							editable: false
						},
						acknowledgedByName: {
							type: 'string',
							editable: false
						},
						/*acknowledgedAt: {
						 type: 'date',
						 editable: false
						 },*/
						assignedToName: {
							type: 'string',
							editable: false
						},
						monitorName: {
							type: 'string',
							editable: false
						},
						monitorTypeText: {
							type: 'string',
							editable: false
						},
						monitorClassText: {
							type: 'string',
							editable: false
						},
						subsystem: {
							type: 'string',
							editable: false
						},
						identifierName: {
							type: 'string',
							editable: false
						},
						identifierInstance: {
							type: 'string',
							editable: false
						},
						information: {
							type: 'string',
							editable: false
						}
					}
				}
			},
			pageSize: 50,
			serverSorting: true,
			serverPaging: true,
			serverFiltering: true,
			filter: ahReasonsFilter,
			sort: ahReasonsSort,
			change: $.proxy(function (e) {
				var sort;
				if (e.items.length === 0) {
					if ($('.cw_health_reasons_list').find('.status').length) {
						$('.cw_health_reasons_list').find('.status').remove();
					}
				} else {
					if ($('.cw_health_reasons_list').find('.status').length) {
						$('.cw_health_reasons_list').find('.status').remove();
					}
					$('.cw_health_reasons_list').find('.k-grid-content').removeClass('hide');
				}

				if (JSON.stringify(this.currentGridFilter) !== JSON.stringify(this.dataSource.filter())) {
					this.filteringChanged = true;
				} else {
					this.filteringChanged = false;
				}
				let restoreCheckboxes = true;
				if (this.filteringChanged && this.selectedIds) {
					this.selectedIds = null;
					State.markedCheckboxes = [];
					$('.cw_grid_check_all').prop('checked', false);
					$('.cw_grid_check').prop('checked', false);
					this.onAssetCheck();
					restoreCheckboxes = false;
				}
				this.currentGridFilter = this.dataSource.filter();

				if (restoreCheckboxes) {
					var restoreCheckboxStates = () => {
						Utils.setCheckboxStates(this.grid, this.checkboxStates);
					};
					setTimeout($.proxy(restoreCheckboxStates, this), 200);
				}
			}, this)
		});
	},
	setGrid() {
		var ahSC;
		if (this.isFromAssetGroup) {
			ahSC = JSON.parse(UserPrefs.get('ahGroupReasonsColumns'));
		} else {
			ahSC = JSON.parse(UserPrefs.get('ahReasonsColumns'));
		}
		if (ahSC !== null) {
			if (typeof ahSC === "string" || (typeof ahSC === "object" && ahSC.length === 0)) {
				ahSC = null;
			}
		}
		var ahReasonsColumns = this.ahReasonsColumns || ahSC || this.defaultColumns;
		ahReasonsColumns = Utils.completeColumns(ahReasonsColumns, this.defaultColumns);
		kendo.ui.progress($('.cw_health_reasons_list'), true);
		var filterMessages = lang.grid.filter;
		var scope = this;
		var gridHeight = this.isFromAssetGroup ? this.treeListHeight - 40 : '';
		this.grid = $('.cw_health_reasons_list').kendoCustomGrid({
			dataSource: this.dataSource,
			height: gridHeight,
			editable: true,
			resizable: true,
			reorderable: true,
			selectable: 'row',
			cacheScrollCheckboxes: true,
			cacheScrollPosition: true,
			scrollable: {
				virtual: true
			},
			sortable: {
				mode: 'multiple',
				allowUnsort: true
			},
			filterable: {
				extra: false,
				operators: {
					string: {
						startswith: filterMessages.STARTS_WITH,
						neq: filterMessages.NEQ,
						eq: filterMessages.EQ,
						contains: filterMessages.CONTAINS
					},
					number: {
						gte: filterMessages.IAE,
						gt: filterMessages.IA,
						lte: filterMessages.IBE,
						lt: filterMessages.IB
					},
					date: {
						gte: filterMessages.IAE,
						gt: filterMessages.IA,
						lte: filterMessages.IBE,
						lt: filterMessages.IB
					}
				}
				//,messages: this.gridMessages
			},
			columns: Utils.rearrangeColumns([{
				field: 'id',
				title: lang.SELECTOR,
				attributes: {
					'class': 'text_center'
				},
				headerAttributes: {
					'class': 'text_center'
				},
				menu: false,
				sortable: false,
				filterable: false,
				template: '<input type="checkbox" class="cw_grid_check" data-id="${id}" />',
				headerTemplate: '<input type="checkbox" class="cw_grid_check_all" />',
				hidden: ahReasonsColumns.id.hidden,
				width: ahReasonsColumns.id.width
			}, {
				field: 'severity',
				title: lang.assethealth.SEVERITY,
				sortable: {
					compare: $.proxy(function (a, b) {
						return Utils.customCompare(a, b, 'severityIndex', 6, this.dataSource.sortNow);
					}, this)
				},
				filterable: {
					operators: {
						string: {
							eq: filterMessages.ISIN,
							neq: filterMessages.ISNOTIN
						}
					},
					ui: function (element) {
						var multiselect = new MultiSelectGridFilter({
							element: element,
							field: 'severity',
							grid: scope.grid,
							dataSource: new kendo.data.DataSource({data: [{
								text: lang.CRITICAL,
								icon: '<span class="cw_status_indicator cw_es_indicator cw_status_widget_color is_critical"></span>',
								value: 'CRITICAL'
							}, {
								text: lang.MAJOR,
								icon: '<span class="cw_status_indicator cw_es_indicator cw_status_widget_color is_major"></span>',
								value: 'MAJOR'
							}, {
								text: lang.MINOR,
								color: 'is_minor',
								icon: '<span class="cw_status_indicator cw_es_indicator cw_status_widget_color is_minor"></span>',
								value: 'MINOR'
							}, {
								text: lang.OK,
								icon: '<span class="cw_status_indicator cw_es_indicator cw_status_widget_color is_ok"></span>',
								value: 'NONE'
							}, {
								text: lang.MAINTENANCE,
								icon: '<span class="cw_status_indicator cw_es_indicator cw_status_widget_color is_idle"><span class="glyphicons wrench"></span></span>',
								value: 'MAINTENANCE'
							}], serverSorting: true}),
						});
					}
				},
				attributes: {
					'class': 'text_center'
				},
				/*template: function (item) {
					return Renderer.severity(item.severity, item);
				},*/
				template: item => getSeverityState(item.severity, item),
				hidden: ahReasonsColumns.severity ? ahReasonsColumns.severity.hidden : false,
				width: ahReasonsColumns.severity ? ahReasonsColumns.severity.width : 45
			}, {
				field: 'assetName',
				title: lang.ASSET,
				sortable: true,
				filterable: {
					messages: this.gridMessages
				},
				attributes: {
					'class': "tooltip ellipsis to_expand cw_grid_link cw_asset_link"
				},
				hidden: ahReasonsColumns.assetName.hidden,
				width: ahReasonsColumns.assetName.width
			}, {
				field: 'accountName',
				title: lang.ACCOUNT,
				sortable: true,
				filterable: {
					ui: function (element) {
						new MultiSelectGridFilter({
							element: element,
							field: 'accountName',
							grid: scope.grid,
							itemTemplate: '#=data.text#',
							tagTemplate: '#=data.text#',
							dataSource: scope.filterOptions['accountName']
						});
					},
					messages: this.gridMessages,
					extra: false,
					operators: {
						string: {
							neq: filterMessages.NEQ,
							eq: filterMessages.EQ
						}
					}
				},
				hidden: !State.includeSubaccounts || ahReasonsColumns.accountName.hidden,
				width: ahReasonsColumns.accountName.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'agentName',
				title: lang.AGENT,
				template: item => {
					return `<span data-agentid="${item.agentId}" class="${Utils.canEditAgent(item.agentId) ? 'cw_grid_link cw_agent_name' : ''}">${item.agentName}</span>`
				},
				sortable: true,
				filterable: {
					ui: function (element) {
						new MultiSelectGridFilter({
							element: element,
							field: 'agentName',
							grid: scope.grid,
							itemTemplate: '#=data.text#',
							tagTemplate: '#=data.text#',
							dataSource: scope.filterOptions['agentName']
						});
					},
					messages: this.gridMessages,
					extra: false,
					operators: {
						string: {
							neq: filterMessages.NEQ,
							eq: filterMessages.EQ
						}
					}
				},
				hidden: ahReasonsColumns.agentName.hidden,
				width: ahReasonsColumns.agentName.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'timestamp',
				title: lang.TIME,
				sortable: true,
				filterable: {
					extra: true,
					ui: function (el) {
						el.kendoDateTimePicker({
							format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
							timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat)
						});
					},
					messages: this.gridMessages
				},
				template: '#=Renderer.browserDateRenderer(timestamp, "datetime")#',
				//format: '{0: ' + Cookies.CeesoftUserDateTimeFormat + '}',
				hidden: ahReasonsColumns.timestamp.hidden,
				width: ahReasonsColumns.timestamp.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'text',
				title: lang.REASON,
				sortable: true,
				menu: false,
				filterable: true,
				attributes: {
					"class": "tooltip ellipsis to_expand reason_text"
				},
				headerAttributes: {
					'class': 'mw100'
				},
				hidden: ahReasonsColumns.text.hidden,
				width: ahReasonsColumns.text.width
			}, {
				field: 'action',
				title: lang.ACTION,
				sortable: false,
				menu: false,
				filterable: false,
				template: '#= monitorType === "WINSERVICE" ? "' + lang.SELECT + '" : ""#',
				editor: $.proxy(function(container, options) {
					if (options.model.monitorType === 'WINSERVICE') {
						options.model.action = undefined;
						var input = $('<input type="text" />');
						input.attr('name', options.field);
						input.appendTo(container);
						input.kendoDropDownList({
							optionLabel: {
								text: lang.SELECT_ACTION,
								value: ''
							},
							dataSource: [{
								text: lang.service.START_SERVICE,
								value: 'start'
							}, {
								text: lang.service.STOP_SERVICE,
								value: 'stop'
							}],
							dataTextField: 'text',
							dataValueField: 'value',
							change: $.proxy(function(e) {
								this.sendServiceAction(e, container);
							}, this)
						});
						input.select(0);
					}
				}, this),
				hidden: ahReasonsColumns.action.hidden,
				width: ahReasonsColumns.action.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'acknowledged',
				title: lang.service.ACKNOWLEDGED,
				sortable: true,
				filterable: {
					messages: {
						isTrue: lang.YES,
						isFalse: lang.NO,
						clear: lang.CLEAR,
						info: lang.grid.filter.SHOW_ITEMS,
						filter: lang.FILTER
					}
				},
				template: '#= acknowledged || inIncident ? \'<span class="glyphicons ok"></span>\' : ""#',
				hidden: ahReasonsColumns.acknowledged.hidden,
				width: ahReasonsColumns.acknowledged.width,
				attributes: {
					'title': '#=acknowledgedMouseover || "" #'
				}
			}, {
				field: 'acknowledgedByName',
				title: lang.service.ACKNOWLEDGED_BY,
				sortable: true,
				menu: false,
				filterable: true,
				template: '#=acknowledgedByName ? acknowledgedByName : "" #',
				hidden: ahReasonsColumns.acknowledgedByName.hidden,
				width: ahReasonsColumns.acknowledgedByName.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'acknowledgedAt',
				title: lang.incidents.TIME_OF_FLAG,
				sortable: true,
				menu: false,
				filterable: false,
				template: '#=Renderer.browserDateRenderer(acknowledgedAt, Constants.DATETIME)#',
				hidden: ahReasonsColumns.acknowledgedAt.hidden,
				width: ahReasonsColumns.acknowledgedAt.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'assignedToName',
				title: lang.ASSIGNED_TO,
				sortable: true,
				menu: false,
				filterable: true,
				template: '#=assignedToName ? assignedToName : "" #',
				hidden: ahReasonsColumns.assignedToName.hidden,
				width: ahReasonsColumns.assignedToName.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'assignedToType',
				title: lang.ASSIGNED_TO_TYPE,
				sortable: true,
				filterable: {
					operators: {
						string: {
							eq: filterMessages.EQ,
							neq: filterMessages.NEQ
						}
					},
					ui: function (element) {
						var multiselect = new MultiSelectGridFilter({
							element: element,
							field: 'assignedToType',
							grid: scope.grid,
							dataSource: [{
								text: lang.NONE,
								icon: '<span class="glyphicons"></span>',
								value: 'ASSIGN_NONE'
							}, {
								text: lang.USER,
								icon: '<span class="glyphicons user"></span>',
								value: 'ASSIGN_USER'
							}, {
								text: lang.TEAM,
								icon: '<span class="glyphicons parents"></span>',
								value: 'ASSIGN_TEAM'
							}]
						});
					}
				},
				template: '<span class="glyphicons #=assignedToGlyph#" title="#=assignedToMouseover#"></span>',
				attributes: {
					'class': 'tooltip ellipsis to_expand text_center'
				},
				hidden: ahReasonsColumns.assignedToType.hidden,
				width: ahReasonsColumns.assignedToType.width
			}, {
				field: 'information',
				title: lang.INFORMATION,
				sortable: true,
				filterable: true,
				template: '#=information ? information : "" #',
				hidden: ahReasonsColumns.information.hidden,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				},
				width: ahReasonsColumns.information.width
			}, {
				field: 'monitorName',
				title: lang.MONITOR,
				template: '<a class="cw_grid_link cw_monitor_link" data-id="${monitorId}" data-type="${monitorType}">${monitorName}</a>',
				sortable: true,
				menu: true,
				filterable: true,
				hidden: ahReasonsColumns.monitorName.hidden,
				width: ahReasonsColumns.monitorName.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'monitorTypeText',
				title: lang.assethealth.MONITOR_TYPE,
				sortable: true,
				filterable: {
					ui: function (element) {
						new MultiSelectGridFilter({
							element: element,
							field: 'monitorTypeText',
							grid: scope.grid,
							itemTemplate: '#=data.text#',
							tagTemplate: '#=data.text#',
							dataSource: scope.filterOptions['monitorTypeText']
						});
					},
					messages: this.gridMessages,
					extra: false,
					operators: {
						string: {
							neq: filterMessages.NEQ,
							eq: filterMessages.EQ
						}
					}
				},
				hidden: ahReasonsColumns.monitorTypeText.hidden,
				width: ahReasonsColumns.monitorTypeText.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			},{
				field: 'monitorClassText',
				title: lang.assethealth.MONITOR_CLASS,
				sortable: true,
				filterable: {
					ui: function (element) {
						new MultiSelectGridFilter({
							element: element,
							field: 'monitorClassText',
							grid: scope.grid,
							itemTemplate: '#=data.text#',
							tagTemplate: '#=data.text#',
							dataSource: scope.filterOptions['monitorClassText']
						});
					},
					messages: this.gridMessages,
					extra: false,
					operators: {
						string: {
							neq: filterMessages.NEQ,
							eq: filterMessages.EQ
						}
					}
				},
				hidden: ahReasonsColumns.monitorClassText.hidden,
				width: ahReasonsColumns.monitorClassText.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'subsystem',
				title: lang.assethealth.SUBSYSTEM,
				sortable: true,
				filterable: true,
				hidden: ahReasonsColumns.subsystem.hidden,
				width: ahReasonsColumns.subsystem.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'identifierName',
				title: lang.assethealth.IDENTIFIER_NAME,
				sortable: true,
				filterable: true,
				hidden: ahReasonsColumns.identifierName.hidden,
				width: ahReasonsColumns.identifierName.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'identifierInstance',
				title: lang.assethealth.IDENTIFIER_INSTANCE,
				sortable: true,
				filterable: true,
				hidden: ahReasonsColumns.identifierInstance.hidden,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}], ahReasonsColumns),
			columnMenu: true,
			change: $.proxy(this.onRowExpand, this),
			dataBound: $.proxy(this.onReasonsDataBound, this)
		}).data('kendoCustomGrid')

		$('.k-virtual-scrollable-wrap').scroll(function () {
			State.dynamicGridPos = $('.cw_health_reasons_list').data('kendoCustomGrid').wrapper.find(".k-scrollbar").scrollTop();
		});

		// Add Kendo tooltip to the header of the columns
		Utils.gridColumnHeaderTooltip(this.grid);
		this.adjustSectionHeight();
	},
	setGridMenu() {
		this.menu = new GridMenu({
			renderTo: 'cw_ah_grid_menu',
			items: [{
				id: 'cw_incidents_create',
				icon: 'construction-cone',
				text: lang.incidents.CREATE_INCIDENT,
				fn: this.onCreateIncident,
				scope: this,
				role: 'INCIDENT_CREATE',
				disabled: true
			}, {
				id: 'cw_acknowledge',
				icon: 'check',
				text: lang.incidents.MARK_AS_SEEN,
				fn: this.onAckUnack,
				scope: this,
				disabled: true
			}, {
				id: 'cw_unacknowledge',
				icon: 'unchecked',
				text: lang.UNACKNOWLEDGE,
				fn: this.onAckUnack,
				scope: this,
				disabled: true
			}, {
				id: 'cw_reset_hi',
				icon: 'restart',
				text: lang.assethealth.RESET_HI,
				fn: this.onResetHIButton,
				scope: this,
				cssClass: '',
				disabled: true
			}, {
				id: 'cw_create_filter',
				icon: 'filter',
				text: lang.assethealth.CREATE_FILTER,
				fn: this.onCreateFilter,
				scope: this,
				cssClass: '',
				disabled: true,
				role: State.mainApp.session.hasV2Permissions ? 'HEALTHFILTER_CREATE': undefined
			}, {
				id: 'cw_assign',
				icon: 'object-align-horizontal',
				text: lang.ASSIGN,
				fn: this.onAssign,
				role: 'EVENT_SUMMARY_LIST',
				scope: this,
				disabled: true
			}, {
				id: 'cw_unassign',
				icon: 'undo',
				text: lang.UNASSIGN,
				fn: this.onUnassign,
				role: 'EVENT_SUMMARY_LIST',
				scope: this,
				disabled: true
			}, {
				id: 'cw_delete_reason',
				icon: 'remove-circle',
				text: lang.DELETE_REASON,
				fn: () => {
					this.onRemove();
				},
				scope: this,
				disabled: true
			}, {
				id: 'cw_set_information',
				icon: 'info-sign',
				text: lang.SET_INFORMATION,
				fn: () => {
					this.onSetInformation();
				},
				scope: this,
				disabled: true
			}]
		});
	},
	setPreferencesPanel() {
		var searchValue;
		if (this.isFromAssetGroup) {
			searchValue = UserPrefs.get('defaultGroupReasonsFsView') ? '' : (UserPrefs.get('ahGroupReasonsSearchPhrase') || '');
		} else {
			searchValue = UserPrefs.get('defaultReasonsFsView') ? '' : (UserPrefs.get('ahReasonsSearchPhrase') || '');
		}
		this.gridFilterPanel = new PreferencesPanel({
			renderTo: 'cw_asset_reasons_filters',
			grid: this.grid,
			modulePrefName: this.isFromAssetGroup ? 'GroupReasonsHealth' : 'ReasonsHealth',
			defaultPrefViewKey: this.isFromAssetGroup ? 'defaultGroupReasonsFsView' : 'defaultReasonsFsView',
			prefListKey: this.isFromAssetGroup ? 'ahGroupReasonsFSViews' : 'ahReasonsFSViews',
			userPref: State.currentApp.userPref,
			onRemove: $.proxy(State.currentApp.saveUserPreferences, State.currentApp),
			searchFields: ['assetName', 'accountName', 'text', 'monitorName', 'subsystem', 'monitorTypeText', 'monitorClassText'],
			searchValue: searchValue,
			defaultColumns: this.defaultColumns
		});

		if (this.isFromAssetGroup) {
			this.hideViewMgmt();
		}
	},
	hideViewMgmt() {
		$('.cw_view_button').addClass('hide');
		$('.cw_filter_reset').addClass('hide');
		$('.cw_filter_list').addClass('hide');
	},
	/*
	* Handler function for sending actions for a service
	* @param {Object} e The dropdown change event object
	* @param {Object} container The jQuery element container
	* */
	sendServiceAction: function(e, container) {
		var value = e.sender.value(), command, url, data, item, uid, allowedIdentifiers = {
			'winservices.state': true,
			'winservices.status': true,
			'winservices.auto': true
		};
		switch (value) {
			case 'start':
				command = 'START';
				break;
			case 'stop':
				command = 'STOP';
				break;
		}
		if (command) {
			uid = container.closest('tr').data('uid');
			item = this.grid.dataSource.getByUid(uid);

			if (allowedIdentifiers[item.identifierName]) {
				data = {
					assetId: item.assetId,
					monitorId: item.monitorId,
					identifierName: item.identifierName,
					identifierInstance: item.identifierInstance
				};
				url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/monitors/WINSERVICE/serviceCommand/' + command;
				Utils.ajax(url, 'POST', JSON.stringify(data), $.proxy(function (result) {
					//console.log(result);
					if (result.success) {
						this.actionNotification.setOptions({
							message: result.message,
							status: 'info'
						}).show();
					} else {
						this.actionNotification.setOptions({
							message: result.message,
							status: 'error'
						}).show();
					}
				}, this));
			}
		}
	},
	/**
	 * Removes listeners
	 */
	removeListeners: function () {
		$('.cw_health_reasons_list').off();
		$('#cw_health_reasons_find').off();
		this.onAssetSavedHandler = $.proxy(this.onAssetSaved, this);
		LocalEventsManager.unbind('assetsaved', this.onAssetSavedHandler);
		LocalEventsManager.unbind('assetsAddedToGroup', this.onAssetsAdded);
        $('body').off('click', '.cw_monitorname');
        $('body').off('click', '.cw_agentname');
	},
	/**
	 * Attaches listeners
	 */
	attachListeners: function () {
		let grid = $('.cw_health_reasons_list');
		grid.on('click', '.cw_asset_link', $.proxy(this.onAssetNameClick, this));
		grid.on('click', '.cw_grid_check', $.proxy(this.onAssetCheck, this));
		grid.on('click', '.cw_grid_check_all', $.proxy(this.onAssetCheckAll, this));
		grid.on('click', '.k-grid-content .cw_status_indicator', $.proxy(this.onAgentStateClick, this));
		grid.on('click', '.cw_monitor_link', $.proxy(this.onMonitorClick, this));
		grid.on('click', '.cw_incidentnumber_click', $.proxy(this.onIncidentNumberClick, this));
		grid.on('click', '.cw_agent_name', $.proxy(this.onAgentNameClick, this));

		$('#cw_health_reasons_find').on('keyup', $.proxy(this.onSearch, this));
		LocalEventsManager.bind('assetsaved', this.onAssetSavedHandler);
		LocalEventsManager.bind('assetsAddedToGroup', $.proxy(this.onAssetsAdded, this));
        $('.cw_health_reasons_list').off('mouseover', '[data-monitorerror="true"]').on('mouseover', '[data-monitorerror="true"]', $.proxy(function (e) {
            Utils.onMonitorErrorOver(e, {
                dataSource: this.grid.dataSource,
                toolTip: this.tooltipOverview
            });
        }, this));
        $('body').on('click', '.cw_monitorname', $.proxy(this.onMonitorNameClick, this));
        $('body').on('click', '.cw_agentname', $.proxy(this.onAgentNameClick, this));

	},
	/*
	 * Handler function for setting the tooltip
	 * */
    setToolTip: function () {
        if (!this.tooltipOverview) {
            this.tooltipOverview = $('.cw_health_reasons_list').kendoTooltip({
                filter: '[data-monitorerror="true"]',
                autoHide: false,
                position: 'right',
                show: function(e)  { e.sender.popup.element.addClass('warning_tooltip'); }
            }).data("kendoTooltip");
        }
    },
    /**
     * Handler for the click event on the monitor name
     * @param {Object} e The click event
     */
    onMonitorNameClick: function (e) {
        e.preventDefault();
        var target = $(e.currentTarget);

        var monitorId = target.data('id');
        var monitorType = target.data('type');

        this.openMonitorView(monitorId, monitorType, true, e);
    },
	/**
	 * Handler funtion for the click event on the Reset health index button
	 */
	onResetHIButton: function () {
		var checkboxes = this.grid.wrapper.find('.cw_grid_check');
		var length = checkboxes.length;
		var monitors = [];
		var i, checkbox, uid, item, found;
		if (this.selectedItems) {
			for (let i = 0; i < this.selectedItems.length; i++) {
				found = false;
				for (var j = 0; j < monitors.length; j++) {
					if (monitors[j].monitorId === this.selectedItems[i].monitorId && monitors[j].assetId === this.selectedItems[i].assetId) {
						found = true;
						break;
					}
				}
				if (!found) {
					monitors.push({
						monitorId: this.selectedItems[i].monitorId,
						assetId: this.selectedItems[i].assetId
					});
				}
			}
		} else {
			for (i = 0; i < length; i++) {
				checkbox = $(checkboxes[i]);
				if (checkbox.is(':checked')) {
					uid = checkbox.closest('tr').data('uid');
					item = this.grid.dataSource.getByUid(uid);
					found = false;
					for (var j = 0; j < monitors.length; j++) {
						if (monitors[j].monitorId === item.monitorId && monitors[j].assetId === item.assetId) {
							found = true;
							break;
						}
					}
					if (!found) {
						monitors.push({
							monitorId: item.monitorId,
							assetId: item.assetId
						});
					}
				}
			}
		}
		Utils.resetMonitorsHI(monitors, $.proxy(function () {
			this.grid.wrapper.find('.cw_grid_check').prop('checked', false);
			this.grid.wrapper.find('.cw_grid_check_all').prop('checked', false);
		}, this));
		$('.cw_dialog_content').find('.cw_dialog_text').addClass('required_textarea');
		$('.cw_dialog_content').find('.cw_dialog_text textarea').addClass('required_textarea cw_reasons_dialog');
		$('.cw_dialog_content').find('.dialog_input').on('keyup', $.proxy(function () {
			if ($('.cw_dialog_content').find('.dialog_input').val()) {
				$('.cw_dialog_content').find('.cw_dialog_text').removeClass('required_textarea');
			} else {
				$('.cw_dialog_content').find('.cw_dialog_text').addClass('required_textarea');
			}

		}, this));
	},
	onCreateFilter: function (e) {
		var checkbox = this.grid.wrapper.find('.cw_grid_check:checked');
		var uid = checkbox.closest('tr').data('uid');
		var record = this.grid.dataSource.getByUid(uid);
		State.mainApp.loadModule('AssetHealthFiltersConfiguration', '', {
			mode: 'create',
			populate: {
				assetId: record.assetId,
				monitorId: record.monitorId,
				monitorType: record.monitorType,
				severity: record.severity,
				reasonDescription: record.text,
				reasonSubsytem: record.subsystem,
				identifierName: record.identifierName || '',
				identifierInstance: record.identifierInstance || '',
				accountId: record.accountId,
				accountName: record.accountName
			}
		}, e);
	},
	/**
	 * Handler function for clicking assign option in grid menu
	 */
	onAssign: function () {
		this.assignWindow = new ModalWindow({
			title: lang.ASSIGN_EVENT,
			width: 400,
			resizable: true,
			minHeight: 200,
			height: 200,
			modal: true,
			actions: [],
			url: 'include/Summary/AssignEventForm.jsp',
			refresh: $.proxy(function () {
				this.onAssignWindowLoaded();
				$('#cw_assign_event_close').on('click', $.proxy(this.onAssignWindowClose, this));
				$('#cw_assign_event_update').on('click', $.proxy(this.onAssignWindowUpdate, this));
			}, this)
		});
		this.assignWindow.open();
	},
	/**
	 * Handler function for actions required to be performed when the assigned window was loaded
	 */
	onAssignWindowLoaded: function () {
		var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/recipients';
		this.assignSelector = $('#cw_assign_event_selector').kendoDropDownList({
			dataSource: new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: url,
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				schema: {
					data: function (response) {
						var result, userTeamArray = [];
						if (response.success) {
							result = response.data;
						} else if (response.length) {
							result = response;
						} else {
							result = [];
						}
						for (var i = 0, length = result.length; i < length; i++) {
							result[i] = {
								text: result[i].name,
								value: result[i].id,
								type: result[i].type
							};
							switch (result[i].type) {
								case 'TEAM':
									result[i].optionGlyphClass = 'parents';
									userTeamArray.push(result[i]);
									break;
								case 'USER':
									result[i].optionGlyphClass = 'user';
									userTeamArray.push(result[i]);
									break;
								case 'EMAIL':
									break;
							}
						}
						return userTeamArray;
					}
				},
				group: {
					field: 'type'
				},
				error: ErrorHandler.kendoServerError
			}),
			dataTextField: 'text',
			dataValueField: 'value',
			optionLabel: ' ',
			optionLabelTemplate: '<span id="cw_assign_placeholder">User/team...</span>',
			valueTemplate: '<span class="glyphicons ${optionGlyphClass}" style="padding-top: 3px"></span><span style="padding-left: 10px">${text}</span>',
			open: $.proxy(function () {
				//overwrite kendo groupable questionable functionality (show different first group; moreover, show groupable row when no data)
				var recipientsOpenedContainer = $('#cw_assign_event_selector-list');
				//hide option label from selection area
				recipientsOpenedContainer.find('.k-list-optionlabel').addClass('hide');
				var recipientsGroupHeader = recipientsOpenedContainer.find('.k-group-header');
				var recipientsNoDataContainer = recipientsOpenedContainer.find('.k-nodata');
				if (recipientsNoDataContainer.attr('style')) {//check for style display:none property put by kendo
					//case when multiselect has data; transform the grouping header row into a normal grouping element
					recipientsGroupHeader.addClass('hide');
					var firstItem = recipientsOpenedContainer.find('li').first();
					firstItem.append('<div class="k-group">' + recipientsGroupHeader.html() + '</div>');
				} else {
					//case when multiselect has no data; hide the grouping header, fix for poor kendo functionality
					recipientsGroupHeader.addClass('hide');
				}
			}, this),
			dataBound: function () {
				$('#cw_assign_placeholder').css('color', '#b8b8b8');
			}
		}).data('kendoDropDownList');
	},
	/**
	 * Handler function called when closing the assigned window
	 */
	onAssignWindowClose: function () {
		this.assignWindow.close();
	},
	/**
	 * Handler function called when completing the assigned form
	 */
	onAssignWindowUpdate: function (e) {
		var recipientUrl;
		var selectedCheckboxes = $('.cw_health_reasons_list').find('.cw_grid_check:checked');
		var selectedEventIds = [];
		for (var i = 0; i < selectedCheckboxes.length; i++) {
			let id = $(selectedCheckboxes[i]).attr('data-id');
			if (id && id !== 'null') {
				selectedEventIds.push();
			}
		}
		var selectedRecipient = this.assignSelector.value();
		var allRecipients = this.assignSelector.dataSource.data();
		for (var j = 0; j < allRecipients.length; j++) {
			if (selectedRecipient === allRecipients[j].value) {
				if (allRecipients[j].type === 'USER') {
					recipientUrl = 'userId';
				} else if (allRecipients[j].type === 'TEAM') {
					recipientUrl = 'teamId';
				}
			}
		}
		var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/summary/eventSummary/assign?' + recipientUrl + '=' + selectedRecipient;
		Utils.ajax(url, 'POST', JSON.stringify(this.selectedIds || selectedEventIds), $.proxy(function (result) {
			if (result.success) {
				this.actionNotification.setOptions({
					message: lang.messages.EVENT_SUCCESSFULLY_ASSIGNED,
					status: 'success'
				}).show();
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
			this.assignWindow.close();
		}, this));
	},
	/**
	 * Handler function for clicking unassign option in grid menu
	 */
	onUnassign: function () {
		var selectedCheckboxes = $('.cw_health_reasons_list').find('.cw_grid_check:checked');
		var selectedEventIds = [];
		for (var i = 0; i < selectedCheckboxes.length; i++) {
			let id = $(selectedCheckboxes[i]).attr('data-id');
			if (id && id !== 'null') {
				selectedEventIds.push(id);
			}
		}
		var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/summary/eventSummary/unassign?';
		Utils.ajax(url, 'POST', JSON.stringify(this.selectedIds || selectedEventIds), $.proxy(function (result) {
			if (result.success) {
				this.actionNotification.setOptions({
					message: lang.messages.EVENT_SUCCESSFULLY_UNASSIGNED,
					status: 'success'
				}).show();
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},
	onRemove: function () {
		this.removeSelected();
	},
	removeSelected: async function () {
		let selectedCheckboxes = $('.cw_health_reasons_list').find('.cw_grid_check:checked');
		let uids = [];

		for (let i = 0; i < selectedCheckboxes.length; i++) {
			let checkbox = $(selectedCheckboxes[i]);

			let uid = checkbox.closest('tr').attr('data-uid');
			let record = this.dataSource.getByUid(uid);

			uids.push({
				id: record.id,
				accountId: record.accountId
			});
		}

		if (this.selectedItems) {
			var selected = [];
			for (let i = 0; i < this.selectedItems.length; i++) {
				selected.push({
					accountId: this.selectedItems[i].accountId,
					id: this.selectedItems[i].id
				})
			}
		}

		uids = selected || uids;

		if (!uids.length) {
			return;
		}
		var message = uids.length === 1 ? lang.messages.REASON_SUCCESSFULLY_REMOVED : lang.messages.REASONS_SUCCESSFULLY_REMOVED;

		const url = `${Settings.serverPath}accounts/${Cookies.CeesoftCurrentAccountId}/health/assetReasons/delete`;
		const {result} = await Utils.ajaxPromise(url, 'POST', JSON.stringify(uids));

		if (result.success) {
			this.actionNotification.setOptions({
				message: message,
				status: 'success'
			}).show();
			this.grid.dataSource.read();
		} else {
			Utils.showInfo(lang.ALERT, result.message, result.details);
		}
	},
	getFilterableForSeverity: function (element) {
		var menu = $(element).parent();
		menu.find(".k-filter-help-text").text(lang.assethealth.messages.SELECT_ASSETS);
		//menu.find("[data-role=dropdownlist]").remove();
		element.removeAttr("data-bind");
		var operatorEl = menu.find("[data-role=dropdownlist]");
		operatorEl.find('option[value="contains"]').remove();
		operatorEl.find('option[value="startswith"]').remove();
		operatorEl.find('option[value="eq"]').text(lang.grid.filter.ISIN);
		operatorEl.find('option[value="neq"]').text(lang.grid.filter.ISNOTIN);
		operatorEl.attr('data-index', '1');
		var multiSelect = element.kendoSortedMultiSelect({
			dataSource: [{
					text: lang.CRITICAL,
					color: 1,
					value: '3'
				}, {
					text: lang.MAJOR,
					color: 2,
					value: '2'
				}, {
					text: lang.MINOR,
					color: 3,
					value: '1'
				}, {
					text: lang.OK,
					color: 5,
					value: '0'
				}],
			dataTextField: 'text',
			dataValueField: 'value',
			optionLabel: lang.grid.FILTER_SELECT_VALUE,
			itemTemplate: '<span class="cw_status_indicator cw_status_widget_color cw_color#=data.color#"></span><span>${data.text}</span>',
			tagTemplate: '<span class="cw_status_indicator cw_status_widget_color cw_color#=data.color#"></span><span>${data.text}</span>'
		}).data('kendoSortedMultiSelect');
		menu.find('[type=submit]').on('click', {widget: multiSelect, operatorElement: operatorEl}, $.proxy(this.scope.filterBySeverityIndicator, {scope: this.scope, dataSource: this.scope.dataSource, field: this.field}));
		menu.find('[type=reset]').on('click', $.proxy(function (e) {
			multiSelect.value([]);
		}, this));
		//fix for default value
		setTimeout(function () {
			operatorEl.data('kendoDropDownList').select(1);
		}, 100);
	},
	filterBySeverityIndicator: function (e) {
		var indicators = e.data.widget.value();
		var operator = e.data.operatorElement.data('kendoDropDownList').value();
		var logic = (operator === 'eq') ? 'or' : 'and';
		var currentFilter = this.dataSource.filter();
		var filter = {logic: logic, filters: []};
		for (var i = 0; i < indicators.length; i++) {
			filter.filters.push({field: this.field, operator: operator, value: indicators[i]});
		}
		if (currentFilter && currentFilter.filters) {
			for (var i = 0; i < currentFilter.filters.length; i++) {
				if (currentFilter.filters[i].filters) {
					for (var j = 0; j < currentFilter.filters[i].filters.length; j++) {
						if (currentFilter.filters[i].filters[j].field === this.field) {
							currentFilter.filters[i].filters.splice(j, 1);
							j--;
						}
					}
				}
				if (currentFilter.filters[i].field === this.field) {
					currentFilter.filters.splice(i, 1);
					i--;
				}
			}
			if (filter.filters.length) {
				currentFilter.filters.push(filter);
			}
			this.dataSource.filter(currentFilter);
		} else {

			this.dataSource.filter(filter);
		}
	},
    /**
     * Handler function for clicking one of the acknowledge or unacknowledge options in menu item
     */
	onAckUnack: function (e) {
		var checkboxes = this.grid.wrapper.find('.cw_grid_check');
		var length = checkboxes.length;
		var i, assetIds = [], assetId, checkbox, item, assetName, reasonId, url, message;
		var payload = {
			assetIds: [],
			eventIds: []
		};

		if (this.selectedItems) {
			for (let j = 0; j < this.selectedItems.length; j++) {
				payload.assetIds.push(this.selectedItems[j].assetId);
				payload.eventIds.push(this.selectedItems[j].id);
			}
		} else {
			for (i = 0; i < length; i++) {
				checkbox = $(checkboxes[i]);
				item = this.grid.dataSource.getByUid(checkbox.closest('tr').data('uid'));
				assetId = item.assetId;
				reasonId = item.id;
				if (checkbox.is(':checked') && payload.assetIds.indexOf(assetId) === -1) {
					payload.assetIds.push(assetId);
					payload.eventIds.push(reasonId);
					assetName = item.assetName;
				}
			}
		}

		var target = $(e.currentTarget);
		if (target.attr('id') === 'cw_acknowledge') {
			url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/health/acknowledgeAssetHealthEvents';
			message = assetIds.length > 1 ? lang.messages.REASONS_ASSETS_ACKNOWLEDGED : kendo.template(lang.messages.REASONS_ACKNOWLEDGED)({'assetName': assetName});
		} else if (target.attr('id') === 'cw_unacknowledge') {
			url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/health/unacknowledgeAssetHealthEvents';
			message = assetIds.length > 1 ? lang.messages.REASONS_ASSETS_UNACKNOWLEDGED : kendo.template(lang.messages.REASONS_UNACKNOWLEDGED)({'assetName': assetName});
		}
		Utils.ajax(url, 'POST', JSON.stringify(payload), $.proxy(function (result) {
			if (result.success) {
				this.grid.wrapper.find('.cw_grid_check:checked').trigger('click');
				this.grid.wrapper.find('.cw_grid_check_all:checked').trigger('click');
				this.grid.dataSource.read();
				this.actionNotification.setOptions({
					message: message,
					status: 'success'
				}).show();
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},
	/**
	 * Handler function clicking incident number
	 * @param {Object} e The event click object
	 */
	onIncidentNumberClick: function (e) {
		var currentTarget = $(e.currentTarget),
			incidentId = currentTarget.data('incidentid');

		if (incidentId) {
			State.mainApp.navigate(IncidentsRouter.details(incidentId),
				{
					extra: {
						isFromAssetHealthReasons: true
					}
				});
		}
	},
	/**
	 * Handler for the click event on the monitor name
	 * @param {Object} e The click event
	 */
	onMonitorClick: function (e) {
		e.preventDefault();
		var target = $(e.currentTarget);
		var monitorId = target.data('id'), monitorType = target.data('type');
		this.openMonitorView(monitorId, monitorType, false, e);
	},
	/*
	 * Handler function for agent state click
	 */
	onAgentStateClick: function (e) {
		State.mainApp.loadModule('ManagementAgents', null, {}, e);
	},
	/**
	 * Handler function for create incident button
	 */
	onCreateIncident: function (e) {
		var assetsCheckboxes = this.grid.wrapper.find('.cw_grid_check');
		var assets = [], accountList = [], reasonsIds = [];

		if (this.selectedItems) {
			for (let i = 0; i < this.selectedItems.length; i++) {
				assets.push({
					id: this.selectedItems[i].assetId,
					name: this.selectedItems[i].assetName
				});
				reasonsIds.push(this.selectedItems[i].id);
				if (!Utils.getFromListById(accountList, this.selectedItems[i].accountId)) {
					accountList.push({
						id: this.selectedItems[i].accountId,
						name: this.selectedItems[i].accountName
					});
				}
			}
		} else {
			for (var i = 0, length = assetsCheckboxes.length; i < length; i++) {
				if ($(assetsCheckboxes[i]).is(':checked')) {
					var record = this.dataSource.get($(assetsCheckboxes[i]).data('id'));
					assets.push({
						id: record.assetId,
						name: record.assetName
					});
					reasonsIds.push(record.id);
					if (!Utils.getFromListById(accountList, record.accountId)) {
						accountList.push({
							id: record.accountId,
							name: record.accountName
						});
					}
				}
			}
		}

		if (!Utils.getFromListById(accountList, Cookies.CeesoftCurrentAccountId)) {
			accountList.push({
				id: Cookies.CeesoftCurrentAccountId,
				name: Cookies.CeesoftCurrentAccountName
			});
		}
		if (assets.length) {
			State.mainApp.navigate(IncidentsRouter.createNew('ASSET'),{
				extra: {
					assets,
					accountList,
					reasonsIds,
					isFromAssetHealthReasons: true
				}
			});
		} else {
			var status = $('#cw_service_health_tabs').find('.status');
			status.find('p').addClass('error');
			status.find('p').text(lang.assethealth.messages.PLEASE_SELECT_ASSET);
			status.slideDown().delay(2000).slideUp();
		}
	},
	/**
	 * Handler function for the change(select) event on the grid
	 * @param {Object} e The change event object
	 */
	onRowExpand: function (e) {
		var selectedRow = this.grid.select();
		var myRow = selectedRow[0];
		var messageEl = $(myRow).find('.to_expand');
		if ($(messageEl).hasClass('cw_message_expanded')) {
			$(messageEl).removeClass('cw_message_expanded').addClass('ellipsis');
		} else {
			$('.cw_health_reasons_list').find('.k-grid-content').find('td.cw_message_expanded').removeClass('cw_message_expanded').addClass('ellipsis');
			$(messageEl).addClass('cw_message_expanded').removeClass('ellipsis');
		}
	},
	/**
	 * Handler function for the Reasons grid data bound event
	 * @param {Object} e The databound event
	 */
	onReasonsDataBound: function (e) {
		var tooltip = $('.cw_health_reasons_list').find('.k-grid-content').data('kendoTooltip');
		if (tooltip) {
			tooltip.destroy();
		}
		$('.cw_health_reasons_list').find('.k-grid-content').kendoTooltip({
			filter: "td.tooltip:not(:empty)",
			content: function (event) {
				return event.target.text().split("\n").join("<br />");
			}
		});
		var uid, row;
		if (this.grid && this.grid.thead) {
			this.grid.thead.find("[data-field='id']>.k-header-column-menu").remove();
		}
		var data = this.grid.dataSource.data();
		if (data.length) {
			for (var i = 0; i < data.length; i++) {
                uid = data[i].uid;
                row = $(".cw_health_reasons_list tbody").find("tr[data-uid=" + uid + "]");
                if (data[i].acknowledged) {
                    //row.attr('style', 'background-color: #cccccc !important');
                    //row.find('.reason_text').css('color', '#cccccc');
                }
			}
		}
		kendo.ui.progress($('.cw_health_reasons_list'), false);
        this.setErrorToolTip($('.cw_health_reasons_list'));
	},
	/**
	 * Event handler for the keypressed event on the search input
	 * @param {Object} e The event object
	 */
	onSearch: function (e) {
		var val = $(e.currentTarget).val();
		this.dataSource.filter([]);

		if ($.trim(val) !== '') {
			this.dataSource.filter({
				logic: 'or',
				filters: [{
						field: 'targets',
						operator: function (item) {
							var status = false;
							var lowerCaseValue = val.toLowerCase();
							for (var i = 0, length = item.length; i < length; i++) {
								if (item[i].toLowerCase().indexOf(lowerCaseValue) !== -1) {
									status = true;
									break;
								}
							}
							return status;
						}
					}, {
						field: 'name',
						operator: 'contains',
						value: val
					}, {
						field: 'systemType',
						operator: 'contains',
						value: val
					}, {
						field: 'assetType',
						operator: 'contains',
						value: val
					}, {
						field: 'acknowledgedByName',
						operator: 'contains',
						value: val
					}]
			});
		}
	},
	/**
	 * Handler for the asset checkbox click
	 * @param {Object} e The click event
	 */
	onAssetCheck: function (e) {
		var checkboxes = this.grid.wrapper.find('.cw_grid_check');
		var count = 0;
		var accountId;
		var isSameAccount = true;
		var selectAllIndex;
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			var checkboxId = $(checkboxes[i]).attr('data-id');
			if (checkboxId && checkboxId !== 'null') {
				var index = State.markedCheckboxes.indexOf(checkboxId);
				if (this.selectedIds) {
					selectAllIndex = this.selectedIds.indexOf(checkboxId);
				}
				if ($(checkboxes[i]).is(':checked')) {
					count++;
					var record = this.grid.dataSource.get($(checkboxes[i]).attr('data-id'));
					if (accountId) {
						if (record && accountId !== record.accountId) {
							isSameAccount = false;
						}
					} else if (record) {
						accountId = record.accountId;
					}
					if (index === -1) {
						State.markedCheckboxes.push(checkboxId);
					}
					if (selectAllIndex === -1 && accountId && record) {
						this.selectedIds.push(checkboxId);
						this.selectedItems.push({
							assetId: record.assetId,
							id: record.id,
							monitorId: record.monitorId,
							accountId: record.accountId
						});
					}
				} else {
					if (index !== -1) {
						State.markedCheckboxes.splice(index, 1);
					}
					if (selectAllIndex !== undefined && selectAllIndex !== -1 && accountId) {
						this.selectedIds.splice(selectAllIndex, 1);
						this.selectedItems.splice(selectAllIndex, 1);
					}
				}
			}
		}
		if (count) {
			if (count === 1) {
				this.menu.enableItem('cw_create_filter');
			} else {
				this.menu.disableItem('cw_create_filter');
			}
			this.menu.enableItem('cw_incidents_create');
			this.menu.enableItem('cw_reset_hi');
			this.menu.enableItem('cw_acknowledge');
			this.menu.enableItem('cw_unacknowledge');
			this.menu.enableItem('cw_assign');
			this.menu.enableItem('cw_unassign');
			this.menu.enableItem('cw_delete_reason');
			this.menu.enableItem('cw_set_information');
		} else {
			this.menu.disableItem('cw_create_filter');
			this.menu.disableItem('cw_incidents_create');
			this.menu.disableItem('cw_reset_hi');
			this.menu.disableItem('cw_acknowledge');
			this.menu.disableItem('cw_unacknowledge');
			this.menu.disableItem('cw_assign');
			this.menu.disableItem('cw_unassign');
			this.menu.disableItem('cw_delete_reason');
			this.menu.disableItem('cw_set_information');
		}
		this.checkboxStates = Utils.getCheckboxStates(this.grid);
	},
	/**
	 * Handler for the check all checkbox click
	 * @param {Object} e The click event
	 */
	onAssetCheckAll: function (e) {
		var checkAll = this.grid.wrapper.find('.cw_grid_check_all').is(':checked');
		var checkboxes = this.grid.wrapper.find('.cw_grid_check');
		var i, length = checkboxes.length;
		for (i = 0; i < length; i++) {
			$(checkboxes[i]).prop('checked', checkAll);
		}
		this.filteringChanged = false;
		if (checkAll && checkboxes.length) {
			if (checkboxes.length === 1) {
				this.menu.enableItem('cw_create_filter');
			} else {
				this.menu.disableItem('cw_create_filter');
			}
			this.menu.enableItem('cw_incidents_create');
			this.menu.enableItem('cw_reset_hi');
			this.menu.enableItem('cw_acknowledge');
			this.menu.enableItem('cw_unacknowledge');
			this.menu.enableItem('cw_assign');
			this.menu.enableItem('cw_unassign');
			this.menu.enableItem('cw_delete_reason');
			this.menu.enableItem('cw_set_information');

			var accountId;
			var isSameAccount = true;
			for (var i = 0, length = checkboxes.length; i < length; i++) {
				var checkboxId = $(checkboxes[i]).attr('data-id');
				if (checkboxId && checkboxId !== 'null') {
					var index = State.markedCheckboxes.indexOf(checkboxId);
					if ($(checkboxes[i]).is(':checked')) {
						var record = this.grid.dataSource.get($(checkboxes[i]).attr('data-id'));
						if (accountId) {
							if (record && accountId !== record.accountId) {
								isSameAccount = false;
							}
						} else if (record) {
							accountId = record.accountId;
						}
						if (index === -1) {
							State.markedCheckboxes.push(checkboxId);
						}
					}
				}
			}
			this.getAllIds();
		} else {
			State.markedCheckboxes = [];
			this.menu.disableItem('cw_incidents_create');
			this.menu.disableItem('cw_reset_hi');
			this.menu.disableItem('cw_acknowledge');
			this.menu.disableItem('cw_unacknowledge');
			this.menu.disableItem('cw_create_filter');
			this.menu.disableItem('cw_assign');
			this.menu.disableItem('cw_unassign');
			this.menu.disableItem('cw_delete_reason');
			this.menu.disableItem('cw_set_information');
			this.selectedIds = null;
			this.selectedItems = null;
		}
		this.checkboxStates = Utils.getCheckboxStates(this.grid);
	},
	getAllIds: function () {
		var currentReasons;
		let url = this.baseUrl + '/selectAll' + this.includeSubaccounts;
		Utils.ajax(url, 'POST', JSON.stringify(this.gridPayload), $.proxy(function (result) {
			if (result.success) {
				this.selectedIds = this.selectedIds || [];
				this.selectedItems = this.selectedItems || [];
				for (let i = 0; i < result.data.length; i++) {
					currentReasons = result.data[i].reasons;
					for (let j = 0; j < currentReasons.length; j++) {
						currentReasons[j].accountId = result.data[i].accountId;
						this.selectedIds.push(currentReasons[j].id);
						this.selectedItems.push(currentReasons[j]);
					}
				}
			}
		}, this));
	},

	onAssetNameClick: function (e) {
		let asset = this.grid.dataItem($(e.currentTarget).closest('tr'));

		State.mainApp.loadModule('AssetDetails', asset.assetId, {
			id: asset.assetId,
			name: asset.assetName,
			accountName: asset.accountName,
			accountId: asset.accountId
		}, e);
	},

	onAgentNameClick: function (e) {
		let target = $(e.currentTarget), agentId = target.data('agentid');
		if (Utils.canEditAgent(agentId)) {
			State.mainApp.navigate(AgentsRouter.details(agentId));
		}
	},

	checkAssetDependencies: function () {
		var assetId = this.selectedAssets[this.assetIndex++], record, assetName, cb;
		if (assetId) {
			cb = $('.cw_health_reasons_list').find('.cw_grid_check:checked');
			record = this.dataSource.get(assetId);

			this.assetAccountName = record.accountName;
			this.assetAccountId = record.accountId;
			this.selectedAssetId = assetId;
			assetName = record.name;

			var dependenciesUrl = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/assets/' + this.selectedAssetId + '/dependencies';
			Utils.ajax(dependenciesUrl, 'GET', {}, $.proxy(function (data) {
				if (data.length) {
					this.openDependenciesPopup(data, assetName, assetId);
					setTimeout(this.adjustTableHeight, 100);
				} else {
					var deleteUrl = Settings.serverPath + 'accounts/' + this.assetAccountId + '/assets/' + assetId;
					Utils.ajax(deleteUrl, 'DELETE', {}, $.proxy(function (result) {
						if (result.success) {
							this.checkAssetDependencies();
						} else {
							this.resetCheckingAssets();
							Utils.showInfo(lang.ALERT, result.message, result.details);
						}
					}, this));
				}
			}, this));
		} else {
			kendo.ui.progress($('#cw_health_reasons_list'), false);
			this.dataSource.read();
			if (this.dependenciesWindow) {
				this.dependenciesWindow.close();
			}
			this.actionNotification.setOptions({
				message: lang.account.messages.ASSETS_SUCCESS_DELETED,
				status: 'success'
			}).show();
		}
	},
	/**
	 * Resets the checking assets
	 */
	resetCheckingAssets: function () {
		this.selectedAssets = [];
		this.assetIndex = 0;

		kendo.ui.progress($('#cw_health_reasons_list'), false);
		this.dataSource.read();
	},
	/**
	 * Creates and opens the asset dependencies window
	 *
	 * @param {Array} dependencies - The asset dependencies array
	 * @param {String} assetName The asset name
	 * @param {String} assetId The id of the asset
	 */
	openDependenciesPopup: function (dependencies, assetName, assetId) {
		if (this.dependencyWindow) {
			this.dependenciesWindow.close();
		}
		this.dependenciesWindow = new ModalWindow({
			title: lang.INFO,
			width: 960,
			height: 640,
			minWidth: 220,
			actions: [],
			url: 'include/Dependencies.jsp',
			//resize: this.adjustTableHeight,
			onClose: $.proxy(function () {
				this.clearCheckboxes();
			}, this),
			refresh: $.proxy(function () {
				$('#cw_dependencies_deleteall').removeClass('hide');
				this.dependencyWindow = new Dependencies({
					accountId: this.assetAccountId,
					dependencies: dependencies,
					name: assetName,
					container: '.cw_health_reasons_list',
					infoTemplate: lang.account.messages.ASSET_DELETE_INFO,
					deleteAll: $.proxy(function (e) {
						var deleteUrl = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/assets/' + assetId + '/dependencies/deleteAll';
						Utils.ajax(deleteUrl, 'DELETE', {}, $.proxy(function (result) {
							if (result.success) {
								this.checkAssetDependencies();
							} else {
								Utils.showInfo(lang.ALERT, result.message, result.details);
								this.resetCheckingAssets();
							}
						}, this));
					}, this)
				});
			}, this)
		});
		this.dependenciesWindow.open();
	},
	clearCheckboxes: function () {
		var checkAll = $('.cw_health_reasons_list').find('.cw_grid_check_all');
		var checkboxes = $('.cw_health_reasons_list').find('.cw_grid_check');
		var i, length = checkboxes.length;
		checkAll.prop('checked', false);
		for (i = 0; i < length; i++) {
			$(checkboxes[i]).prop('checked', false);
		}
		State.markedCheckboxes = [];
	},
	/**
	 * Method by Andy
	 *
	 */
	adjustSectionHeight: function () {
		var section = $('.cw_assethealth_module').find('.cw_section_full');
		var sectionHeight = section.height();
		section.find('.cw_section_content').css('height', sectionHeight);
		section.find('.k-grid-content').css('height', sectionHeight - 40);
	},
	/**
	 * Saves user preferences
	 */
	saveUserPreferences: function () {
		if (this.isFromAssetGroup) {
			this.saveUserPrefs({
				category: 'GroupReasonsHealth',
				filterPanel: this.gridFilterPanel,
				grid: this.grid,
				eventsToolbar: this.eventsToolbar,
				keys: {
					searchPhrase: 'ahGroupReasonsSearchPhrase',
					columns: 'ahGroupReasonsColumns',
					sort: 'ahGroupReasonsSort',
					filter: 'ahGroupReasonsFilter',
					FsViews: 'ahGroupReasonsFSViews',
					defaultFsView: 'defaultGroupReasonsFsView'
				}
			});
		} else {
			this.saveUserPrefs({
				category: 'ReasonsHealth',
				filterPanel: this.gridFilterPanel,
				grid: this.grid,
				eventsToolbar: this.eventsToolbar,
				keys: {
					searchPhrase: 'ahReasonsSearchPhrase',
					columns: 'ahReasonsColumns',
					sort: 'ahReasonsSort',
					filter: 'ahReasonsFilter',
					FsViews: 'ahReasonsFSViews',
					defaultFsView: 'defaultReasonsFsView'
				}
			});
		}
	},
	/**
	 * Loads user preferences
	 */
	loadUserPreferences: function () {
		this.userPref = [];
		let moduleName = this.isFromAssetGroup ? 'GroupReasonsHealth' : 'ReasonsHealth';
		UserPrefs.load(moduleName, $.proxy(function (result) {
			if (result.success) {
				this.userPref = result.data;
				this.initComponent();
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},
	/**
	 * Handler function for the asset saved event
	 */
	onAssetSaved: function () {
		this.dataSource.read();
	},
	/**
	 * Handler function for the assetsAddedToGroup event
	 */
	onAssetsAdded: function () {
		this.actionNotification.setOptions({
			message: lang.assethealth.messages.ASSETS_SUCCESS_ADDED_TO_GROUP,
			status: 'success'
		}).show();
	},
	onEventsTimeout: function (events) {
		var reasonsGrid = $('.cw_health_reasons_list').data('kendoCustomGrid');
		if (reasonsGrid) {
			State.isFromEvent = true;
			reasonsGrid.dataSource.read();
		}
	},
	onSetInformation() {
		$('.window_area').append('<div id="set-information-window-container"></div>');
		ReactDOM.render(<SetInformationWindow
			onClose={this.onSetInformationClose}
			onUpdate={(value) => this.onSetInformationUpdate(value)}
		></SetInformationWindow>, $('#set-information-window-container').get(0))
	},
	onSetInformationClose() {
		ReactDOM.unmountComponentAtNode($('#set-information-window-container').get(0));
	},
	onSetInformationUpdate(informationValue) {
		let selectedCheckboxes = $('.cw_health_reasons_list').find('.cw_grid_check:checked');
		let selectedIds = [];
		let id;
		if (this.selectedItems) {
			for (let i = 0; i < this.selectedItems.length; i++) {
				id = this.selectedItems[i].id;
				if (id) {
					selectedIds.push(id);
				}
			}
		} else {
			for (let i = 0; i < selectedCheckboxes.length; i++) {
				id = $(selectedCheckboxes[i]).attr('data-id');
				if (id) {
					selectedIds.push(id);
				}
			}
		}

		let url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/summary/eventSummary/information';
		let postObj = {
			eventIds: selectedIds,
			information: informationValue
		};
		Utils.ajax(url, 'POST', JSON.stringify(postObj), $.proxy(function (result) {
			if (result.success) {
				this.actionNotification.setOptions({
					message: lang.messages.INFO_SUCCESSFULLY_UPDATE,
					status: 'success'
				}).show();
				this.clearCheckboxes();
				this.grid.dataSource.read();
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
			this.onSetInformationClose();
		}, this));
	},
	/**
	 * Called when a event is received
	 * @param {Object} event The notification event object
	 */
	onEvent: function (event) {
		this.eventsToolbar.addEvents(event);
	},
	/**
	 * Subscribes to the events
	 */
	subscribe: function () {
		var subscriptionObj = [{
				eventType: 'AssetHealth',
				accountId: Cookies.CeesoftCurrentAccountId,
				includeSubaccounts: State.includeSubaccounts
			}, {
				eventType: 'Administration',
				actionTypes: ['ASSET_CREATE', 'ASSET_UPDATE', 'ASSET_DELETE'],
				accountId: Cookies.CeesoftCurrentAccountId,
				includeSubaccounts: State.includeSubaccounts
			}];
       RemoteEventsManager.subscribe(this.subscriberId, subscriptionObj);
	},
	/**
	 * Called when application is unloaded/destroyed
	 */
	destroy: function () {
		this.saveUserPreferences();
		if (this.eventsToolbar) {
			this.eventsToolbar.destroy();
		}
        if (this.tooltipOverview) {
            this.tooltipOverview.destroy();
        }
        Application.prototype.destroy.call(this);
	}
});
