import {Cookies} from "core/cookies";

import moment from "moment-timezone";
import {ApplicationState} from "framework/applicationState";

export const dateToString = (time, dateType, optionalFormat, optionalTimezone) => {
	if (!time)
		return '';

	let dateFormat = '';
	if (optionalFormat) {
		dateFormat = parseDateFormat(optionalFormat);
	} else {
		switch (dateType) {
			case 'datetime':
				dateFormat = parseDateFormat(Cookies.CeesoftUserDateTimeFormat);
				break;
			case 'date':
				dateFormat = parseDateFormat(Cookies.CeesoftUserDateFormat);
				break;
			case 'time':
				dateFormat = parseDateFormat(Cookies.CeesoftUserTimeFormat);
				break;
			default:
				dateFormat = parseDateFormat(Cookies.CeesoftUserDateTimeFormat);
				break;
		}
	}
	let lang = 'en';
	if (Cookies.CeesoftUserLocale == 'no') {
		lang = 'nb';
	}

	let timezone = Cookies.CeesoftTimezone;
	if (optionalTimezone && optionalTimezone != 'undefined') {
		timezone = optionalTimezone;
	}

	return moment.tz(time, timezone).locale(lang).format(dateFormat);
}

export const dateTimeToString = time => {
	return dateToString(time, 'datetime');
}

export const parseDateFormat = pattern => {
	// default pattern in case there are no cookies set (standalone and guest sb)
	var newPattern = pattern || 'dd.MM.yy HH:mm';
	// convert dd into DD (day of month long)
	if (newPattern.indexOf('dd') !== -1) {
		newPattern = newPattern.replace('dd', 'DD');
	}
	// convert E into ddd || EEE indo ddd || EEEE into dddd (name of
	// day)
	if (newPattern.indexOf('EEEE') !== -1) {
		newPattern = newPattern.replace('EEEE', 'dddd');
	} else if (newPattern.indexOf('E') !== -1 || newPattern.indexOf('EEE') !== -1) {
		newPattern = newPattern.replace('EEE', 'ddd').replace('E', 'ddd');
	}
	// convert yyyy into YYYY (year long)
	if (newPattern.indexOf('yyyy') !== -1) {
		newPattern = newPattern.replace('yyyy', 'YYYY');
	}
	// convert yy into YY (year short)
	if (newPattern.indexOf('yy') !== -1) {
		newPattern = newPattern.replace('yy', 'YY');
	}
	// convert ssss into SSS (milliseconds)
	if (newPattern.indexOf('ssss') !== -1) {
		newPattern = newPattern.replace('ssss', 'SSS');
	}
	// convert zzz to zz
	if (newPattern.indexOf('zzz') !== -1 || newPattern.indexOf('zzzz') !== -1) {
		newPattern = newPattern.replace('zzzz', 'zz').replace('zzz', 'zz');
	}

	return newPattern;
}

export const zonedTimeToUtc = (date, timezone) => {
	return moment(moment(date).format('YYYY-MM-DDTHH:mm:ss') + moment.tz(timezone).format('Z')).toDate();
}

export function combineDateAndTime(dateComponent, timeComponent, dateOnly = false){
	if (dateOnly) {
		return new Date(dateComponent.getFullYear(), dateComponent.getMonth(), dateComponent.getDate())
	} else {
		return new Date(dateComponent.getFullYear(), dateComponent.getMonth(), dateComponent.getDate(),
			timeComponent.getHours(), timeComponent.getMinutes())
	}
}

export function convertTimezone(date, currentTimezone, targetTimezone){
	if(typeof date == 'string'){
		date = new Date(date)
	}

	return new Date(moment(date).tz(currentTimezone, true).tz(targetTimezone).format('YYYY-MM-DD HH:mm:ss'))
}

export function toShortDateTime(date, options){
	options = options ?? {
		formatted: false,
		ignoreZ: true
	}

	if(options.ignoreZ){
		date = date.replace('Z','')
	}

	if(options.formatted)
		return moment(date).format('YYYY-MM-DD HH:mm:ss')
	else
		return moment(date).format('YYYYMMDDTHHmmss')
}
