import moment from 'moment';

import {CeeViewDataSource, DataSourceSeverity, Renderer, State, UserPrefs, Utils} from "tools";
import {BaseMonitorView, MonitorConfiguration, MonitorFragmentDetails} from "areas/assets/monitors";
import {
	CustomNotification,
	Dialog,
	ExpandableTextarea,
	GridMenu,
	ModalWindow,
	MultiToggle,
	PreferencesPanel,
	Switch,
	Tags
} from "controls";
import {Settings} from "settings";
import {Cookies} from "core/cookies";
import {Application, ErrorHandler, LocalEventsManager} from "core";
import Lang from 'core/localization/lang';
import {FilterForm} from "areas/assets";
import MultiSelectGridFilter from 'controls/multiSelectGridFilter';
import {TimeSettingsWrapper} from "areas/assets/monitors/timeSettingsWrapper";
import {DocumentationButtonWrapper} from "controls/react/form/documentationButtonWrapper";
import {Config} from "config";
import {getJSON, getPath, getScript} from "core/monitor/utils";

class DependencyManager {
	constructor() {
		this.dependencies = new Map();
	}

	set(name, func) {
		this.dependencies.set(name, func);
	}

	_get(name) {
		const module = MonitorLoader.getModule(name);

		if (module) {
			return module;
		}

		const dependency = this.dependencies.get(name);

		if (dependency) {
			return dependency;
		}

		console.warn(`DependencyManager: dependency/module ${name} not found`);
	}

	resolve(list) {
		return list.map((d) => this._get(d));
	}
}

const dm = new DependencyManager();

dm.set('Utils', Utils);
dm.set('jQuery', jQuery);
dm.set('BaseMonitorView', BaseMonitorView);
dm.set('CustomNotification', CustomNotification);
dm.set('State', State);
dm.set('CeeViewDataSource', CeeViewDataSource);
dm.set('Settings', Settings);
dm.set('Cookies', Cookies);
dm.set('Renderer', Renderer);
dm.set('GridMenu', GridMenu);
dm.set('lang', Lang);
dm.set('Tags', Tags);
dm.set('ErrorHandler', ErrorHandler);
dm.set('ExpandableTextarea', ExpandableTextarea);
dm.set('MonitorConfiguration', MonitorConfiguration);
dm.set('DataSourceSeverity', DataSourceSeverity);
dm.set('moment', moment);
dm.set('MultiToggle', MultiToggle);
dm.set('ModalWindow', ModalWindow);
dm.set('MonitorFragmentDetails', MonitorFragmentDetails);
dm.set('LocalEventsManager', LocalEventsManager);
dm.set('Switch', Switch);
dm.set('Application', Application);
dm.set('UserPrefs', UserPrefs);
dm.set('PreferencesPanel', PreferencesPanel);
dm.set('Dialog', Dialog);
dm.set('FilterForm', FilterForm);
dm.set('MultiSelectGridFilter', MultiSelectGridFilter);
dm.set('TimeSettingsWrapper', TimeSettingsWrapper);
dm.set('DocumentationButtonWrapper', DocumentationButtonWrapper);

export class MonitorModule {
	constructor(moduleName, dependencies, factoryFunc) {
		//console.log(`${moduleName} module created`);
		this.name = moduleName;
		this.dependencies = dependencies;
		this.factoryFunc = factoryFunc;
	}

	getModuleInstance(...params) {
		const module = this.getModule();
		return new module(...params);
	}

	getModule() {
		if (!this.module) {
			this.module = this.factoryFunc(...dm.resolve(this.dependencies));
		}

		return this.module;
	}
}

export class MonitorView {
	constructor(monitorName, name, template, dependencies, factoryFunc) {
		//console.log(`${name} view created`);
		this.name = name;
		this.template = getPath(monitorName, template);
		this.dependencies = dependencies;
		this.factoryFunc = factoryFunc;
	}

	getViewInstance(...params) {
		if (!this.view) {
			this.view = this.factoryFunc(...dm.resolve(this.dependencies));
		}

		return new this.view(...params);
	}

	getTemplate() {
		return this.template;
	}
}

class MonitorLoaderImpl {
	constructor() {
		this.views = new Map();
		this.modules = new Map();
		this.monitorsMeta = new Map();
	}

	addMonitorMeta(name, config) {
		//console.log(`Meta for monitor "${name}"`);
		this.monitorsMeta.set(name, config);
	}

	addView(monitorName, name, template, dependencies, factoryFunc) {
		if (this.views.has(name)) {
			return;
		}

		this.views.set(name, new MonitorView(monitorName, name, template, dependencies, factoryFunc));
	}

	addModule(moduleName, dependencies, factoryFunc) {
		this.modules.set(moduleName, new MonitorModule(moduleName, dependencies, factoryFunc));
	}

	/**
	 *
	 * @param name
	 * @returns {monitorView | MonitorView}
	 */
	getView(name) {
		return this.views.get(name);
	}

	/**
	 *
	 * @param name
	 * @returns {monitorModule | MonitorModule}
	 */
	getModule(name) {
		return this.modules.get(name);
	}

	loadViews(monitorName) {
		const config = this.monitorsMeta.get(monitorName);
		return Promise.all(config.views.map((view) => MonitorLoaderImpl.loadView(monitorName, view)));
	}

	loadMonitor(name) {
		return MonitorLoaderImpl.loadMonitorMeta(name).then((config) => {
			this.addMonitorMeta(name, config);
			return this.loadViews(name);
		})
	}

	loadMonitors() {
		if (!Config.monitors || !Config.monitors.length) {
			return;
		}

		return Promise.all(Config.monitors.map((name) => this.loadMonitor(name)));
	}

	static loadMonitorMeta(name) {
		return getJSON(getPath(name, 'manifest.json'));
	}

	static loadView(monitorName, viewName) {
		return getScript(getPath(monitorName, viewName));
	}

	resolvePath(monitorName, viewName) {
		return getPath(monitorName, viewName);
	}
}

const monitorLoader = new MonitorLoaderImpl();
export {monitorLoader as MonitorLoader}
