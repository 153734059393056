import React, {useCallback, useRef, useEffect} from "react";
import PropTypes from "prop-types";
import {oneOfThemRequired} from "core/react/oneOfThemRequired";
import {getConfig} from "./gridNativeHelpers/getConfig";
import {handleOneRowSelection} from "./gridNativeHelpers/handleOneRowSelection";
import {onDataBound} from "./gridNativeHelpers/onDataBound";
import {handleResize} from "./gridNativeHelpers/handleResize";
import {handleHideColumn} from "./gridNativeHelpers/handleHideColumn";
import {handleIfDisableCheckAll} from "./gridNativeHelpers/handleIfDisableCheckAll";
import {helperRowSelections} from "./gridNativeHelpers/helperRowSelections";
import {onRowsSelected} from "./gridNativeHelpers/onRowsSelected";
import {handleFixLockedColumn} from "./gridNativeHelpers/handleFixLockedColumn";
import {handleSelection} from "./gridNativeHelpers/handleSelection";
import { getDataSourceFromProps } from "./gridNativeHelpers/getDataSourceFromProps";
import { onRowClicked } from './gridNativeHelpers/onRowClicked';
import { onSave } from './gridNativeHelpers/onSave';
import handleScrollToSelected from './gridNativeHelpers/handleScrollToSelected';
import onSelectionApplied from './gridNativeHelpers/onSelectionApplied';
import onScrollToSelected from './gridNativeHelpers/onScrollToSelected';
import { onRowSelectionChanged } from "./gridNativeHelpers/onRowSelectionChanged";

GridNative.propTypes = {
	columns: PropTypes.array.isRequired,
	containerClass: PropTypes.string,
	dataSource: oneOfThemRequired(['dataSource', 'dataSourceArray']),
	dataSourceArray: oneOfThemRequired(['dataSource', 'dataSourceArray']),
	schema: PropTypes.object,
	dataBound: PropTypes.func,
	onRowsSelected: PropTypes.func,
	onRowSelectionChanged: PropTypes.func,
	onRowClicked: PropTypes.func,
	oneRowSelection: PropTypes.bool,
	onSave: PropTypes.func,
	selectionField: PropTypes.string,
	selectedItems: PropTypes.array,
	filterable: PropTypes.object,
	resizable: PropTypes.bool,
	editable: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.object
	]),
	sortable: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.object
	]),
	fixLockedColumn: PropTypes.bool,
	scrollToSelected: PropTypes.bool,
	onScrollToSelected: PropTypes.func,
	native: PropTypes.bool,
	height: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	keepSorting: PropTypes.bool
};

GridNative.defaultProps = {
	oneRowSelection: false,
	selectionField: 'id',
	native: true,
	keepSorting: true
};

export function GridNative(props) {
	const {containerClass, gridClass} = props;

	const ref = useRef();

	const onRef = useCallback((node) => {
		if (!node || ref.current) {
			return;
		}

		const config = getConfig(props);

		if (props.native) {
			ref.current = $(node)
				.kendoGrid(config)
				.data('kendoGrid');
		}
		else {
			ref.current = $(node)
				.kendoCustomGrid(config)
				.data('kendoCustomGrid');
		}

		helperRowSelections(ref.current, props);

		onDataBound(ref.current, props.dataBound);
		onRowsSelected(ref.current, props.onRowsSelected);
		onRowSelectionChanged(ref.current, props.onRowSelectionChanged, props);
		onRowClicked(ref.current, props.onRowClicked);
		onSave(ref.current, props.onSave);
		onScrollToSelected(ref.current, props.onScrollToSelected);

		onRowsSelected(ref.current, handleOneRowSelection(ref.current, props));
		onDataBound(ref.current, handleResize(ref.current, props));
		onDataBound(ref.current, handleHideColumn(ref.current, props));
		onDataBound(ref.current, handleIfDisableCheckAll(ref.current, props));
		onDataBound(ref.current, handleSelection(ref.current, props));
		onDataBound(ref.current, handleOneRowSelection(ref.current, props));
		onDataBound(ref.current, handleFixLockedColumn(ref.current, props));
		onSelectionApplied(ref.current, handleScrollToSelected(ref.current, props));
	});

	useEffect(() => {
		handleSelection(ref.current, props)();
	}, [props.selectedItems]);

	useEffect(() => {
		ref.current.setDataSource(getDataSourceFromProps(ref.current, props));
	}, [props.dataSourceArray, props.dataSource])

	return (

			<div ref={onRef} className={gridClass}/>

	);
}

export default GridNative;
