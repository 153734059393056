import {Api, State, Utils} from "tools";
import {RemoteEventsManager, Cookies} from "core";
import {Widget} from "areas/service-boards/widget";
import {Settings} from "settings";
import {Designer} from "controls/designer/designer";
import {ServicesApi} from 'areas/services/api';
import {ServicesRouter} from 'areas/services/bundleDescription';
require('jquery.kinetic');

import {translator} from 'core';
import {getAdminEvents} from "areas/services/eventsManager";

const i = translator({
  "Preview service » {0}": {
    "no": "visningstjeneste » {0}"
  }
});

export function PreviewWidget(config) {
	Widget.call(this, config);

	this.requestPath = Settings.serverPath;
	if (this.sessionId) {
		this.requestPath = Settings.serverPath + 'sessions/' + this.sessionId + '/';
		this.mode = 'detached';
	}
	this.initComponent();
};

export {PreviewWidget as default};

jQuery.extend(PreviewWidget.prototype, Widget.prototype, {
	/**
	 * Main init function
	 */
	initComponent: function () {
		if (this.mode !== 'detached') {
			this.addViewerIcon();
		}
		if (!this.title || !this.instanceConfiguration.accountName) {
			this.getServiceName();
		}
		this.attachListeners();
		this.render();
		this.subscribe();
	},
	attachListeners: function () {
		if (State.currentApp?.dashboardDesigner?.props?.mode !== 'designer') {
			let widgetDiv = $('#' + this.id);
			widgetDiv.on('click', '.cw_section_content', $.proxy(this.openViewer, this));
		}
	},
	/**
	 * Retrieves the service name from the server and updates the title
	 */
	getServiceName: function () {
		var url = this.requestPath + 'services/' + this.configuration.serviceId + '/lite';
		Utils.ajax(url, 'GET', {}, $.proxy(function (result) {
			if (result.success) {
				var serviceName = result.data.name;
				this.setTitleIfEmpty(i('Preview service » {0}', serviceName));
				this.serviceModelName = serviceName;
				this.currentAccountName = result.data.accountName;
			} else {
				this.showErrorMessage(result.message);
			}
		}, this), () => {
			this.showErrorMessage("Unknown error");
		});
	},

	onResize: function () {
		this.designer?.fit();
	},

	/**
	 * Appends the viewer icon to the title bar
	 */
	addViewerIcon: function () {
		var widgetDiv = $('#' + this.id);
		widgetDiv.find('.cw_card_options').prepend('<span class="glyphicons charts hide"></span>');
		// Do not remove this for now:
		// widgetDiv.find('.charts').off();
		// widgetDiv.find('.charts').on('click', $.proxy(this.openViewer, this));
	},
	/**
	 * Opens the associated viewer app
	 * @param {String} status The highlight status
	 */
	openViewer: function (e, status) {
		if (this.configuration.modelId && !this.serviceBoard?.layoutCurrentlyEditable) {
			var timeout = Settings.COOKIE_TIMEOUT;
			var accountName = this.instanceConfiguration.accountName || this.currentAccountName;
			if (this.instanceConfiguration.accountId !== Cookies.CeesoftCurrentAccountId) {
				State.isAccountChanged = true;
			}
			Cookies.create("CeesoftCurrentAccountId", this.instanceConfiguration.accountId, timeout);
			Cookies.create("CeesoftCurrentAccountName", accountName, timeout);
			
			if(this.redirectConfig?.doDefaultRedirect)
				State.mainApp?.navigate(ServicesRouter.viewer(this.configuration.serviceId), {newTab: this.redirectConfig?.defaultRedirectInNewTab});
		}
	},
	/**
	 * Renders the widget
	 */
	render: async function () {
		this.designer?.destroy();

		let widgetContentDiv = $('#' + this.id)
			.find('.cw_section_content');

		//2.7 quickfix for geEditor height
		let container = widgetContentDiv.get(0);
		let containerHeight = $(container).height() + 'px';

		let config = {
			container: widgetContentDiv.get(0),
			mode: 'service',
			fit: this.instanceConfiguration.zoom === false ? false : true,
			toolbar: false,
			padding: this.instanceConfiguration.contentPadding,
			chromeless: true,
			sessionId: this.sessionId,
			accountId: this.instanceConfiguration.accountId,
			disableCellSelection: true,
			features:{
				presentationMode: true
			}
		};

		const response = await ServicesApi.getModel(this.instanceConfiguration.serviceId,
			this.instanceConfiguration.accountId, {}, this.sessionId);

		if (!response.success) {
			return;
		}

		this.model = response.data.model;

		config.data = {
			xml: response.data.xml,
			model: response.data.model
		};

		this.designer = new Designer(config);
	},
	/**
	 * Called when an event is received from the server
	 * @param {Object} data The event data object
	 */
	onEvent: function (data) {
		if (!data.length) {
			data = [data];
		}
		for (var i = 0; i < data.length; i++) {
			if ((data[i].elements && data[i].elements.length !== this.model.nodes.length) || data[i].eventType === 'ServiceModel') {
				this.render();
			}
		}
	},
	/**
	 * Subscribes to the events
	 */
	subscribe: function () {
		var subscriptions = getAdminEvents(this.id);
		RemoteEventsManager.subscribe(this.id, subscriptions);
	},
	/**
	 * Destroy
	 */
	destroy: function () {
		Widget.prototype.destroy.call(this);
	}
});
