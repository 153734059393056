import React, { useState, useCallback } from 'react'
import { DatePicker } from 'antd';
import './antDateRangePicker.less';


export const AntDateRangePicker = props => {
    const { RangePicker } = DatePicker;

    let [dateRange, setDateRange] = useState([props.fromDate, props.toDate]);

    const onDateRangeChange = useCallback((value) => {
        setDateRange(value)
        if (props.onChange)
            props.onChange(value)
    },[dateRange]);

    return (<RangePicker format={props.format}
        onChange={onDateRangeChange} value={dateRange}/>);
}