import React, {useCallback} from "react";
import Checkbox, {CheckboxProps} from "antd/lib/checkbox";

import {captureValueLink, wrapOnChange} from "controls/react/ant/utils";
import { CheckboxChangeEvent } from "antd/es/checkbox/Checkbox";


interface AntCheckboxProps extends Omit<CheckboxProps, 'onChange'>{
	valueLink?: any,
	invalid?: boolean,
	errors?: Array<any>,
	value?: boolean,
	onChange?: (value: boolean) => any
}

export const AntCheckbox:React.FunctionComponent<AntCheckboxProps> = props => {
	let {errors, invalid, value, onChange, ...antNativeProps} = captureValueLink(props);

	if(antNativeProps.checked === undefined && value !== undefined){
		antNativeProps.checked = value;
	}

	const onChangeWrapper = useCallback((e) => {
		onChange && onChange(e.target.checked, e);
	}, [onChange])

	return <Checkbox onChange={onChangeWrapper}
	                 {...antNativeProps}/>
}
