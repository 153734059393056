import {BaseMonitorView} from "areas/assets/monitors";
import {CeeViewDataSource, Renderer, State, Utils} from "tools";
import {CustomNotification, GridMenu} from "controls";
import {Application, Cookies} from "core";
import {Settings} from "settings";
import MultiSelectGridFilter from 'controls/multiSelectGridFilter';
import {openTagsFormWindow} from "controls/tagsForm";

export default function View(config) {
	Utils.apply(this, config);
	this.module = {
		name: 'AssetHealthSystemMonitor',
		initialConfig: config
	};
	this.preferencesCategory = 'systemMonitor';
	this.monitorAPIName = 'SYSTEM';
	this.loadUserPreferences();
};

jQuery.extend(View.prototype, BaseMonitorView.prototype, {
	/**
	 * Module related initializations can be done here
	 */
	initComponent: function () {
		BaseMonitorView.prototype.initComponent.call(this);

		this.getTags();
		this.actionNotification = new CustomNotification({
			appendToElement: '.window_area',
		});

		this.gridMessages = {
			isTrue: '<span class="glyphicons status_icon circle-arrow-top"></span>',
			isFalse: '<span class="glyphicons status_icon circle-arrow-down"></span>',
			clear: lang.CLEAR,
			info: lang.grid.filter.SHOW_ITEMS,
			filter: lang.FILTER
		};
		this.firstRender = true;
		this.removeListeners();
		this.attachListeners();
		if (!State.mainApp.session.hasRole('MONITOR_CREATE')) {
			$('#cw_asset_health_system').find('.circle_plus').remove();
		}

		let gridConfig = this.getGridConfig();
		if (gridConfig.sort) {
			for (let i = 0; i < gridConfig.sort.length; i++) {
				if (gridConfig.sort[i].field === 'assetIndicator') {
					gridConfig.sort[i].compare = (a, b) => {
						return Utils.customCompare(a, b, 'monitorHI', '-1', this.dataSource.sortNow);
					}
				}
			}
		}

		this.monitorDataSource = new CeeViewDataSource({
			transport: {
				read: {
					url: Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/monitors/types/SYSTEM?includeSubaccounts=' + State.includeSubaccounts ,
					contentType: "application/json; charset=utf-8",
					type: "GET",
					dataType: "json",
                    cache: false
				}
			},
			schema: {
				model: {
					id: 'id'
				},
				parse: $.proxy(function(result) {
					for (var i = 0; i < result.length; i++) {
						if (result[i].monitorDataUnavailable || result[i].monitorError) {
							result[i].isMonitorError = true;
						}
						if (result[i].monitorHI >= 0 && result[i].monitorHI < 25) {
							result[i].assetIndicator = '0';
						} else if (result[i].monitorHI >= 25 && result[i].monitorHI < 50) {
							result[i].assetIndicator = '1';
						} else if (result[i].monitorHI >= 50 && result[i].monitorHI < 75) {
							result[i].assetIndicator = '2';
						} else if (result[i].monitorHI >= 75 && result[i].monitorHI < 101) {
							result[i].assetIndicator = '3';
						}
						if (result[i].monitorMaintenance) {
							result[i].assetIndicator = '-3';
						}
					}
					return result;
				}, this)
			},
			sort: gridConfig.sort,
			filter: gridConfig.filter
		});

		var gridMessages = {
			clear: lang.CLEAR,
			info: lang.grid.filter.SHOW_ITEMS,
			filter: lang.FILTER
		};
		var filterMessages = lang.grid.filter;

		this.grid = $('#cw_health_system_monitor_list').kendoCustomGrid({
			autoBind: true,
			dataSource: this.monitorDataSource,
			resizable: true,
			reorderable: true,
			selectable: 'row',
			sortable: {
				mode: 'multiple',
			},
			sort: {
				field: 'name',
				dir: 'asc'
			},
			filterable: {
				extra: false,
				operators: {
					string: {
						startswith: this.filterMessages.STARTS_WITH,
						neq: this.filterMessages.NEQ,
						eq: this.filterMessages.EQ,
						contains: this.filterMessages.CONTAINS
					},
					number: {
						gte: this.filterMessages.GTE,
						gt: this.filterMessages.GT,
						lte: this.filterMessages.LTE,
						lt: this.filterMessages.LT
					}
				},
				messages: this.gridMessages
			},
			columns: Utils.rearrangeColumns([{
					field: 'id',
					title: lang.SELECTOR,
					sortable: false,
					filterable: false,
					menu: false,
					attributes: {
						'class': 'text_center'
					},
					headerAttributes: {
						'class': 'text_center'
					},
					template: '<input type="checkbox" class="cw_grid_check" data-id="${id}" />',
					headerTemplate: '<input type="checkbox" class="cw_grid_check_all" />',
					width: gridConfig.columns.id.width,
					hidden: gridConfig.columns.id.hidden
				}, {
					field: 'assetIndicator',
					title: lang.assethealth.SEVERITY,
					sortable: {
						compare: $.proxy(function (a, b) {
							return Utils.customCompare(a, b, 'monitorHI', '-1', this.monitorDataSource.sortNow);
						}, this)
					},
					filterable: {
						operators: {
							string: {
								eq: filterMessages.ISIN,
								neq: filterMessages.ISNOTIN
							}
						},
						ui: $.proxy(Renderer.renderAssetIndicatorFilter, {
							gridSelector: $('#cw_health_system_monitor_list'),
							monitorFiltering: true
						}),
						messages: gridMessages
					},
					headerAttributes: {
						"class": "text_center"
					},
					attributes: {
						'class': "text_center asset_indicator"
					},
					template: function (item) {
						return Renderer.monitor({
							monitorIndex: item.monitorHI,
							monitorError: item.monitorError,
							monitorDataUnavailable: item.monitorDataUnavailable,
							assetMaintenance: item.assetMaintenance,
							monitorMaintenance: item.monitorMaintenance,
							monitorMaintenanceHasPriority: true,
							targetType: item.targetType
						});
					},
					hidden: gridConfig.columns.assetIndicator ? gridConfig.columns.assetIndicator.hidden : false,
					width: gridConfig.columns.assetIndicator ? gridConfig.columns.assetIndicator.width : 100
				}, {
					field: 'name',
					title: lang.assethealth.MONITOR_NAME,
					template: '<a class="cw_grid_link" data-id="${id}">#= name #</a>',
					sortable: true,
					width: gridConfig.columns.name.width,
					hidden: gridConfig.columns.name.hidden,
					attributes: {
						'class': 'expand ellipsis'
					}
				}, {
					field: 'accountName',
					title: lang.ACCOUNT,
					sortable: true,
					filterable: {
						ui: $.proxy(function (element) {
							return Renderer.filterGridByOwnElementDS.call(this, element, 'accountName');
						}, this),
						messages: this.gridMessages,
						extra: false,
						operators: {
							string: {
								neq: this.filterMessages.NEQ,
								eq: this.filterMessages.EQ
							}
						}
					},
					width: gridConfig.columns.accountName.width,
					hidden: gridConfig.columns.accountName.hidden,
					attributes: {
						'class': 'expand ellipsis'
					}
				}, {
					field: 'agentName',
					title: lang.assethealth.AGENT_NAME,
					template: item => {
						return `<span data-agentid="${item.agentId}" class="${Utils.canEditAgent(item.agentId) ? 'cw_link cw_agent_name ellipsis to_expand' : ''}">${item.agentName ? item.agentName : ''}</span>`
					},
					sortable: true,
					filterable: {
						ui: $.proxy(function (element) {
							return Renderer.filterGridByOwnElementDS.call(this, element, 'agentName');
						}, this),
						messages: this.gridMessages,
						extra: false,
						operators: {
							string: {
								neq: this.filterMessages.NEQ,
								eq: this.filterMessages.EQ
							}
						}
					},
					width: gridConfig.columns.agentName.width,
					hidden: gridConfig.columns.agentName.hidden,
					attributes: {
						'class': 'expand ellipsis'
					}
				}, {
					field: 'typeText',
					title: lang.assethealth.MONITOR_TYPE,
					sortable: true,
					filterable: {
						ui: $.proxy(function (element) {
							return Renderer.filterGridByOwnElementDS.call(this, element, 'typeText');
						}, this),
						messages: this.gridMessages,
						extra: false,
						operators: {
							string: {
								neq: this.filterMessages.NEQ,
								eq: this.filterMessages.EQ
							}
						}
					},
					width: gridConfig.columns.typeText.width,
					hidden: gridConfig.columns.typeText.hidden,
					attributes: {
						'class': 'tooltip ellipsis to_expand'
					}
				}, {
					field: 'classText',
					title: lang.assethealth.MONITOR_CLASS,
					sortable: true,
					filterable: {
						ui: $.proxy(function (element) {
							return Renderer.filterGridByOwnElementDS.call(this, element, 'classText');
						}, this),
						messages: this.gridMessages,
						extra: false,
						operators: {
							string: {
								neq: this.filterMessages.NEQ,
								eq: this.filterMessages.EQ
							}
						}
					},
					width: gridConfig.columns.classText.width,
					hidden: gridConfig.columns.classText.hidden,
					attributes: {
						'class': 'tooltip ellipsis to_expand'
					}
				}, {
					field: 'targetType',
					title: lang.TARGET_TYPE,
					sortable: true,
					width: gridConfig.columns.targetType.width,
					hidden: gridConfig.columns.targetType.hidden,
					attributes: {
						'class': 'expand ellipsis'
					}
				}, {
					field: 'targetName',
					title: lang.assethealth.TARGET_NAME,
					sortable: true,
					width: gridConfig.columns.targetName.width,
					hidden: gridConfig.columns.targetName.hidden,
					attributes: {
						'class': 'expand ellipsis'
					},
					template: '<span class="cw_link cw_target_name" data-targettype="#=targetType#" data-targetid="#=targetId#">#=targetName?targetName:""#</span>'
				}, {
				field: 'tags',
				title: lang.TAGS,
				sortable: true,
				filterable: {
					ui: $.proxy(function (element) {
						let multiselect = new MultiSelectGridFilter({
							element: element,
							field: 'tags',
							grid: this.grid,
							itemTemplate: '#=data.text#',
							tagTemplate: '#=data.text#',
							dataSource: this.filterOptions.tags,
							static: true
						});
					}, this),
					messages: this.gridMessages,
					extra: false,
					operators: {
						string: {
							eq: filterMessages.EQ,
							neq: filterMessages.NEQ
						}
					}
				},
				template: item => item.tags.join(','),
				hidden: gridConfig.columns.tags.hidden,
				width: gridConfig.columns.tags.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
					field: 'description',
					title: lang.assethealth.MONITOR_DESCRIPTION,
					sortable: true,
					hidden: gridConfig.columns.description.hidden,
					attributes: {
						'class': 'expand ellipsis'
					}
				}], gridConfig.columns),
			columnMenu: true,
			dataBound: $.proxy(this.onDataBound, this),
			change: $.proxy(this.onRowExpand, this)
		}).data('kendoCustomGrid');
		// Add Kendo tooltip to the header of the columns
		Utils.gridColumnHeaderTooltip(this.grid);
		this.adjustSectionHeight();
		this.grid.thead.find("[data-field='id']>.k-header-column-menu").remove();
		this.gridMenu = new GridMenu({
			renderTo: 'cw_sm_grid_menu',
			items: [{
					id: 'cw_create',
					icon: 'plus-sign',
					text: lang.CREATE,
					fn: this.onMonitorAdd,
					scope: this,
					disabled: false,
					role: 'MONITOR_CREATE'
				}, {
					id: 'cw_delete',
					icon: 'bin',
					text: lang.DELETE,
					fn: this.onRemove,
					scope: this,
					disabled: true,
					role: 'MONITOR_DELETE'
				}, {
					id: 'cw_set_tags',
					icon: 'tag',
					text: lang.SET_TAGS,
					fn: $.proxy(function () {
						return openTagsFormWindow('monitor', this);
					}, this),
					scope: this,
					disabled: true,
					role: 'MONITOR_UPDATE'
				}, {
					id: 'cw_set_maintenance',
					icon: 'wrench',
					text: lang.SET_IN_MAINTENANCE,
					fn: () => {
						this.setMaintenance(true);
					},
					scope: this,
					disabled: true,
					role: 'MONITOR_MAINTENANCE_CREATE'
				}, {
					id: 'cw_end_maintenance',
					icon: 'bin',
					text: lang.END_MAINTENANCE,
					fn: () => {
						this.setMaintenance(false);
					},
					scope: this,
					disabled: true,
					role: 'MONITOR_MAINTENANCE_DELETE'
				}]
		});
		this.renderUserPreferences();
		this.removeMask();
	},
	/**
	 * Handler function for the change(select) event on the grid
	 * @param {Object} e The change event object
	 */
	onRowExpand: function (e) {
		var selectedRow = $(e.sender.select());
		selectedRow.find('.expand').toggleClass('ellipsis');
	},
	/**
	 * Removes listeners
	 */
	removeListeners: function () {
		$('#cw_health_system_monitor_list').off();
	},
	/**
	 * Attaches listeners
	 */
	attachListeners: function () {
		var gridHandler = $('#cw_health_system_monitor_list');
		gridHandler.on('click', '.cw_grid_check', $.proxy(this.onMonitorCheck, this));
		gridHandler.on('click', '.cw_grid_check_all', $.proxy(this.onMonitorCheckAll, this));
		gridHandler.on('click', '.cw_grid_link', $.proxy(this.onMonitorNameClick, this));
		gridHandler.off('mouseover', '.asset_indicator .exclamation-mark').on('mouseover', '.asset_indicator .exclamation-mark', $.proxy(function (e) {
			Utils.onMonitorErrorOver(e, {
				dataSource: this.grid.dataSource,
				toolTip: this.tooltipOverview,
				onlyMonitor: true
			});
		}, this));
	},
	/**
	 * Handler of the includeSubaccounts event
	 * @param {Boolean} includeSubaccounts
	 */
	onIncludeSubaccounts: function (includeSubaccounts) {
		Application.prototype.onIncludeSubaccounts.call(this, includeSubaccounts);
	},
	/**
	 * Handler for the click event on the monitor name
	 * @param {Object} e The click event object
	 */
	onMonitorNameClick: function (e) {
		var data = this.grid.dataItem($(e.currentTarget).closest('tr'));
		var record = this.monitorDataSource.get(data.id);
		this.setHighlightedMonitor(data.id);

		State.mainApp.loadModule('MonitorSystem', data.id, {
			id: data.id,
			name: record.name,
			mode: 'update',
			redirectBack: true
		}, e);
		e.stopPropagation();
	},
	/**
	 * Handler function for the grid data bound event
	 * @param {Object} e The databound event object
	 */
	onDataBound: function (e) {
		this.showNotifications();
		this.highlightMonitor(this.grid);
		if (!this.tooltipOverview) {
			this.tooltipOverview = $('#cw_health_system_monitor_list').kendoTooltip({
				filter: '.asset_indicator .exclamation-mark',
				autoHide: true,
				position: 'bottom',
				show: function (e) {
					e.sender.popup.element.addClass('warning_tooltip');
				}
			}).data("kendoTooltip");
		}
	},
	/**
	 * Handler for the checkbox click
	 * @param {Object} e The click event
	 */
	onMonitorCheck: function (e) {
		var checkboxes = $('.cw_grid_check');
		var total = 0;
		var checked = false;
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			if ($(checkboxes[i]).is(':checked')) {
				checked = true;
				total++;
			}
		}
		if (checked) {
			this.gridMenu.enableItem('cw_delete');
			this.gridMenu.enableItem('cw_set_tags');
			this.gridMenu.enableItem('cw_set_maintenance');
			this.gridMenu.enableItem('cw_end_maintenance');
		} else {
			this.gridMenu.disableItem('cw_delete');
			this.gridMenu.disableItem('cw_set_tags');
			this.gridMenu.disableItem('cw_set_maintenance');
			this.gridMenu.disableItem('cw_end_maintenance');
		}
	},
	/**
	 * Handler for the check all checkbox click
	 * @param {Object} e The click event
	 */
	onMonitorCheckAll: function (e) {
		var checkAll = $('.cw_grid_check_all').is(':checked');
		var checkboxes = $('.cw_grid_check');
		var i, length = checkboxes.length;
		for (i = 0; i < length; i++) {
			$(checkboxes[i]).prop('checked', checkAll);
		}
		this.onMonitorCheck();
	},
	/**
	 * Event handler for the click event on the add monitor button
	 */
	onMonitorAdd: function (e) {
		State.mainApp.loadModule('MonitorSystem', '', {
			mode: 'create'
		}, e);
	},

	/**
	 * Handler function for the click event on the Agent name
	 * @param {Object} e The click event object
	 * @deprecated
	 */
	onAgentClick: function (e) {
		var agentLink = $(e.currentTarget);
		State.mainApp.loadModule('AgentConfigurationView', null, {
			agentType: 'SYSTEM',
			agentName: agentLink.text(),
			agentId: agentLink.data('id')
		}, e);
	}
});
