import produce from "immer";
import {debounce} from 'lodash';

import {getValueOnPath, setValueOnPath} from "tools/utils";
import {addImmer} from "./decorators";

import {useCallback, useContext, useRef, useState, useEffect} from "react";

const i = require('core/localization').translator();

export function createLink(linksContainer, path, getStore, storeMutator){
	const currentValue = getValueOnPath(getStore(), path);

	if(!linksContainer[path]){
		linksContainer[path] = {
			initialized: false,
			rules: [],
			initialValueChanged: false,
			errors: [],
			onChangingCallbacks: [],
			onChangedCallbacks: []
		}
	}else{
		linksContainer[path].initialized = true;
	}

	if(linksContainer[path].link == null || linksContainer[path].value != currentValue) {
		linksContainer[path].link = new Link(
			linksContainer,
			path,
			getStore,
			storeMutator
		);
		linksContainer[path].value = currentValue;
		linksContainer[path].revalidate = true;
	}

	return linksContainer[path].link;
}

export const createObjectLink = (getObject, setObject) => {
	return createLink({}, "", getObject, (mutator, callback) => {
		const store = produce(getObject(), mutator);
		setObject(store);
		callback && callback()
	});
}

export const useMobxLink = store => {
	const linksContainer = useRef({});

	const getStore = useCallback(() => store,[])
	const storeMutator = useCallback((mutator, callback) => {
		mutator(store);
	}, [])

	return createLink(
		linksContainer.current,
		"",
		getStore,
		storeMutator
	);
}

export const useStateLink = (initialValue) => {
	const linksContainer = useRef({});
	const currentValueRef = useRef();
	const callbacksToFireRef = useRef([]);

	const [currentValue, setValue] = useState(initialValue);

	useEffect(() =>{
		const callbacksToFire = callbacksToFireRef.current;
		callbacksToFireRef.current = [];

		callbacksToFire.forEach(c => c && c());

	}, [currentValue]);

	currentValueRef.current = currentValue;

	const getStore = useCallback(
		() => currentValueRef.current,
		[]
	);

	const storeMutator = useCallback(
		(mutator, callback) => {
			let result = produce(currentValueRef.current, mutator);
			if(result === undefined){
				result = currentValueRef.current;
			}

			setValue(result);
			callbacksToFireRef.current.push(callback);
		}, []);

	return createLink(
		linksContainer.current,
		"",
		getStore,
		storeMutator
	)
}

export const useContextLink = (contextType, path) => {
	const linksContainer = useRef({});
	const context = useContext(contextType);

	const getStore = useCallback(() => context, [context]);

	const storeMutator = useCallback(
		(mutator, callback) => context.change(mutator, callback),
		[context]
	);

	return createLink(
		linksContainer.current,
		path,
		getStore,
		storeMutator
	)
}

export function linkState(component, path){
	component.stateValueLinks = component.stateValueLinks || {};

	return createLink(
		component.stateValueLinks,
		path,
		() => component.state,
		(mutator, callback) => component.changeState(mutator, callback)
	);
}

export function linkContext(component, path){
	component.contextValueLinks = component.contextValueLinks || {};
	return createLink(
		component.contextValueLinks,
		path,
		() => component.context,
		(mutator, callback) => component.changeContext(mutator, callback)
	);
}

export function addLinks(target){
	addImmer(target);

	target.prototype.linkState = function(path){
		return linkState(this, path);
	}

	target.prototype.linkContext = function(path){
		return linkContext(this, path);
	}
}

class Link{
	constructor(linksContainer, path, getStore, storeMutator){
		this.linksContainer = linksContainer;
		this.path = path;
		this.getStore = getStore;
		this.storeMutator = storeMutator;
	}

	onChange = (value, e) => {
		this.storage.initialValueChanged = true;

		this.validate(value);

		this.storeMutator(rootStore => {
			const initialValue = this.value;

			if(this.path == ''){
				rootStore = value
			}else{
				setValueOnPath(rootStore, this.path, value);
			}

			this.storage.onChangingCallbacks.forEach( c => {
				 c({rootStore, value, initialValue, e});
			});

			if(this.path == ''){
				return rootStore;
			}

		}, () => {
			this.storage.onChangedCallbacks.forEach( c => c(e))
		});
	}

	validate(value) {
		this.storage.rules.forEach(rule => {
			try {
				rule.invalid = !rule.callback(value)
			}catch(e){
				console.error(e);
				rule.invalid = true;
			}
		});

		this.storage.errors = this.storage.rules.filter(rule => rule.invalid)
			.map(rule => rule.message);

		this.storage.revalidate = false;
	}

	get storage(){
		return this.linksContainer[this.path];
	}

	get value(){
		return getValueOnPath(this.getStore(), this.path);
	}

	get invalid(){
		if(!this.storage.initialValueChanged && this.storage.revalidate){
			this.validate(this.value);
		}
		return this.storage.errors.length > 0
	}

	get invalidSubtree(){
		if(this.invalid)
			return true;

		return this.iterateSubtree(linkPath => this.linksContainer[linkPath].link.invalid, false);
	}

	get errorSubtree(){
		if(this.invalid)
			return true;
		return this.iterateSubtree(linkPath => this.linksContainer[linkPath].errors?.length > 0 ? this.linksContainer[linkPath].errors : null);
	}

	iterateSubtree(callback, defaultResult = undefined) {
		const prefix = this.path + '.';
		const existingLinkPaths = Object.keys(this.linksContainer).filter(x => x.startsWith(prefix));
		for(let linkPath of existingLinkPaths ){
			const result = callback(linkPath);
			if( result !== undefined && result)
				return result;
		}

		return defaultResult;
	}

	get isRequired(){
		return this.storage.isRequired;
	}

	get hasValidation(){
		return this.storage.rules.length > 0;
	}

	get props() {
		return {
			value: this.value,
			onChange: this.onChange,
			invalid: this.invalid,
			errors: this.storage.errors
		}
	}

	get errors(){
		return this.storage.errors
	}

	update(newValueOrCallback, e){
		if(typeof newValueOrCallback == 'function'){
			this.storeMutator( store =>{
				const value = getValueOnPath(store, this.path);
				newValueOrCallback(value, {rootStore:store});
			} );
		}else {
			this.onChange(newValueOrCallback, e);
		}
	}

	get(subPath){
		let fullPath = this.path == ''
			? subPath
			: this.path + '.' + subPath;

		return createLink(this.linksContainer, fullPath, this.getStore, this.storeMutator);
	}

	map(callback){
		return this.value.map((entry, index) => {
			const entryLink = this.get(index);
			return callback(entryLink, index)
		});
	}

	delete(){
		this.storeMutator( store => {
			let array = getValueOnPath(store, this.path, 1);
			array.splice(array.indexOf(this.value, 1));
		});
	}

	//c({rootStore, value, initialValue, e});
	changing(callback){
		if(this.storage.initialized)
			return this;

		this.storage.onChangingCallbacks.push(callback);
		return this;
	}

	changed(callback){
		if(this.storage.initialized)
			return this;

		this.storage.onChangedCallbacks.push(callback);
		return this;
	}

	check(message, callback){
		if(this.storage.initialized)
			return this;

		this.storage.rules.push({message, callback, invalid: false});
		return this;
	}

	required(message = i('Please fill in the field')){
		if(this.storage.initialized)
			return this;

		this.storage.isRequired = true;
		this.check(message, x => {
			if(x == null)
				return false;

			if(Array.isArray(x))
				return x.length > 0;

			return x.toString().trim().length > 0;
		});
		return this;
	}

	getLinkStore(rootStore){
		return getValueOnPath(rootStore, this.path);
	}

	clearSubtree(){
		this.iterateSubtree(linkPath => {delete this.linksContainer[linkPath]});
	}

	debounce(callback){
		if(this.storage.initialized)
			return this;

		this.storage.searchCallback = debounce(callback, 1000);
		return this.changed(() => {
			this.storage.searchCallback(this.value)
		})
	}
}

