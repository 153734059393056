import {Utils} from "tools/utils";
import ServiceBoardView from 'areas/service-boards/serviceBoardView'
import {UserPrefs} from "tools";
import {LocalEventsManager} from "core";
import {Renderer} from "tools";
import {State} from "tools";
import Dialog from 'controls/dialog';
import {translator} from "core";

import "./serviceBoardView.less"

const i = translator({
  "Home » {0}": {
    "no": "Hjem » {0}"
  },
  "NOTIFIER": {
    "en": "View mode: Click edit to modify content",
    "no": "Visnings: Klikk rediger for å endre innhold"
  }
});

export default class AbsoluteServiceBoardView extends ServiceBoardView {
	async init (id) {
		/*Utils.apply(this, config);
		 this.module = {
		 name: 'AbsoluteServiceBoardView',
		 initialConfig: config
		 };*/
		this.layout = 'free';
		this.mode = this.mode || 'read';

		if (State.serviceBoardFormMode === 'create') {
			this.openWidgetForm = true;
		}

		if (State.mainApp && State.mainApp.session.hasRole('SERVICEBOARD_UPDATE')) {
			this.mode = 'edit';

			this.layoutCurrentlyEditable = this.layoutCurrentlyEditable != undefined ? this.layoutCurrentlyEditable : State.serviceBoardFormMode === 'create';

			if (!this.layoutCurrentlyEditable && !this.openWidgetForm && State.mainApp.session.hasRole('SERVICEBOARD_UPDATE')) {
				$('#cw_sb_layout_update').text(i('Edit'));
				this.notifier = $('<div class="view_mode_notifier"></div>').text(i('NOTIFIER'));
			}
			if (!State.mainApp.session.hasRole('SERVICEBOARD_UPDATE')) {
				$('#cw_sb_layout_update').addClass('hide');
			}
			// $('.cw_window_actions').append(this.notifier)
		}

		this.hasEvents = true;
		this.subscriberId = Utils.guid();
		this.lastUpdates = [];
		State.loadedDashboards = {};
		this.initComponent(id);
	}

	attachListeners () {
		ServiceBoardView.prototype.attachListeners.call(this);
		ServiceBoardView.prototype.subscribe.call(this);
		//fix
		$('#content_area').off('click', '.k-i-toggle');
		$('#cw_sb_layout').off('click', '.k-i-toggle');
	}
	/**
	 * Renders the service board
	 * @param {Object} serviceBoard
	 */
	renderContent (serviceBoard) {
		var convertDimensions;
		if (!$('#widget_wrapper').length) {
			$('body').append('<div id="widget_wrapper" class="cw_designer_area"></div>');
		}

		var lastUpdates = JSON.parse(UserPrefs.get('serviceboardLastUpdate'));
		LocalEventsManager.trigger('loadLastUpdate', {
			lastUpdates: lastUpdates
		});
		var found = false;
		var index = 0;
		if (this.lastUpdates && this.lastUpdates.length) {
			for (var i = 0; i < this.lastUpdates.length; i++) {
				if (this.lastUpdates[i].id === this.id) {
					found = true;
					index = i;
					break;
				}
			}
		} else {
			this.lastUpdate = Renderer.dateRenderer(new Date().getTime(), 'datetime');
			this.lastUpdates.push({
				id: this.id,
				lastUpdate: this.lastUpdate
			});
		}
		if (found) {
			this.lastUpdate = this.lastUpdates[index].lastUpdate;
		} else {
			this.lastUpdate = Renderer.dateRenderer(new Date().getTime(), 'datetime');
			this.lastUpdates.push({
				id: this.id,
				lastUpdate: this.lastUpdate
			});
		}
		$('.cw_sboard_last_update').empty().append(lang.serviceBoard.LAST_UPDATE + this.lastUpdate);

		$('.sb-custom-theme').attr('id', this.id);

		this.content = serviceBoard;
		var items = jQuery.parseJSON(serviceBoard);
		var sbDiv = $('#cw_sb_layout'), i;
		sbDiv.empty().removeClass('gridster');
		var length = items.length, item, areaEl = $('#content_area'),
			areaDim = {
				width: areaEl.width(),
				height: areaEl.height()
			}, widgetWidth, widgetHeight;

		if (!$('#cw_sb_layout').length) {
			$('.window_area').append('<div id="cw_sb_layout"></div>');
		}

		if (this.backgroundColor) {
			$(`.cw_sboard_area[id="${this.id}"]`).css('background-color', this.backgroundColor);
			$(`.sboard_scroll`).css('background-color', this.backgroundColor);
		}
		else {
			$(`.cw_sboard_area[id="${this.id}"]`).css('background-color', null);
			$(`.sboard_scroll`).css('background-color', null);
		}

		this.widgets = [];
		widgetWidth = this.widgetWidth || 400;
		widgetHeight = this.widgetHeight || 340;

		var oThis = this;

		for (let i = 0; i < length; i++) {
			/*if (items[i].widget.configuration.hasOwnProperty('includeSubaccounts')) {
			 items[i].widget.configuration.includeSubaccounts = State.includeSubaccounts;
			 }*/

			if (items[i].col && items[i].row && items[i].size_x && items[i].size_y) {
				//only when was converted from grid layout
				let gridDimensions = {
					col: items[i].col,
					row: items[i].row,
					size_x: items[i].size_x,
					size_y: items[i].size_y
				};
				convertDimensions = this.calculateFromGridConversion(gridDimensions);
			}
			if (convertDimensions) {
				items[i].widget.width = convertDimensions.width;
				items[i].widget.height = convertDimensions.height;
				items[i].widget.left = convertDimensions.left;
				items[i].widget.top = convertDimensions.top;
			}

			this.addWidget(items[i].widget);
		}

		if (this.mode !== 'edit') {
			this.disableWidgets();
		}

		if (!this.layoutCurrentlyEditable) {
			this.disableWidgets();
		}

		if (this.openWidgetForm) {
			this.openWidgetForm = false;
			this.onEditLayoutItem();
			this.onWidgetAdd();
		}  else if (this.mode !== 'edit') {
			$('.cw_window_actions').find('.k-button').addClass('hide');
		}

		this.toggleHeaders();

		this.removeMask();
		if ($('#main_loading_mask').length) {
			$('#main_loading_mask').remove();
		}
	}

	disableWidgets () {
		var layout = $('#cw_sb_layout');
		if (this.sbGridMenu) {
			this.sbGridMenu.disableItem('cw_sb_add_widget');
			this.sbGridMenu.disableItem('cw_sb_customize');
			this.sbGridMenu.disableItem('cw_sb_copy');
		}
		var oThis = this;
		layout.find('.cw_freeform_widget').each(function (index, item) {
			var currentPosition = $(item).parent().offset();

			var toggleState = $(item).parent().find('.k-i-toggle').parent().hasClass('hide');

			var widgetWindow = $(item).data('kendoWindow');
			var title = widgetWindow.title();
			oThis.updateWindowOptions(item, {
				resizable: false,
				draggable: false,
			});
			// widgetWindow.setOptions({
			//
			// 	// title: title,
			// 	// position: {
			// 	// 	top: currentPosition.top - $('#cw_sb_layout').offset().top,
			// 	// 	left: currentPosition.left - $('#cw_sb_layout').offset().left
			// 	// }
			// });

			oThis.disableLayering(widgetWindow);
			//oThis.disableMaximization(widgetWindow);
			widgetWindow.wrapper.find('.k-i-toggle').off().on('click', $.proxy(function (e) {
				e.preventDefault();
				this.onWidgetToggleClick(e);
			}, oThis));

			$(item).parent().find('.k-i-toggle').parent().setClass('hide', toggleState)
		});
		layout.find('.k-window-actions').find('.k-i-dimension').parent().addClass('hide');
		layout.find('.k-window-actions').find('.k-i-pencil').parent().addClass('hide');
		layout.find('.k-window-actions').find('.k-i-delete').parent().addClass('hide');
		$('#cw_sb_layout').find('.k-window').css('padding-top', this.headers ? '42px' : '0px');
	}

	enableWidgets () {
		this.sbGridMenu.enableItem('cw_sb_add_widget');
		this.sbGridMenu.enableItem('cw_sb_customize');
		this.sbGridMenu.enableItem('cw_sb_copy');

		var layout = $('#cw_sb_layout');
		var oThis = this;
		layout.find('.cw_freeform_widget').each((index, item) => {
			//not sure if that opotion is needed to be set here, probably not. But it had been before i changed things here, so leaving for now
			this.updateWindowOptions(item, {
				draggable: true,
				resizable: true
			});
		});

		layout.find('.k-window-actions').find('.k-i-dimension').parent().removeClass('hide');
		layout.find('.k-window-actions').find('.k-i-pencil').parent().removeClass('hide');
		layout.find('.k-window-actions').find('.k-i-delete').parent().removeClass('hide');
		$('#cw_sb_layout').find('.k-window').css('padding-top', this.headers ? '42px' : '0px');
	}

	//this method is needed because calling setOptions rerenders parts of the window and all handlers and customizations get lost
	updateWindowOptions(container, options){
		var widgetWindow = $(container).data('kendoWindow');
		const windowRoot = $(container).parent();

		var toggleState = windowRoot.find('.k-i-toggle').parent().hasClass('hide');

		var title = widgetWindow.title();
		widgetWindow.setOptions({
			title,
			...options
		});

		this.disableMaximization(widgetWindow);

		windowRoot.find('.k-i-toggle').parent().setClass('hide', toggleState);

		windowRoot.find('.k-i-dimension').parent().attr('title', lang.serviceBoard.MOVE);
		windowRoot.find('.k-i-pencil').parent().attr('title', lang.serviceBoard.EDIT_WIDGET);
		windowRoot.find('.k-i-delete').parent().attr('title', lang.DELETE);
		windowRoot.find('.k-i-toggle').parent().attr('title', lang.serviceBoard.CHART_SETTINGS);
	}

	onEditLayoutItem () {
		$('.cw_window_actions').find('.k-button').removeClass('hide');
		this.mode = 'edit';
		this.enableWidgets();

		this.gridsterSerialization = this.serialize();
		this.markString = JSON.stringify(this.gridsterSerialization);

		//show resolution
		var isPreviousResolutionCalculated = true;
		this.calculateBoardResolution(isPreviousResolutionCalculated);
		$('.cw_multi_content button').removeClass('no_rights').removeAttr('disabled');
	}

	onCancelLayout () {
		this.skipDirtyCheck = true;
		State.mainApp.loadModule('ServiceBoard');
	}

	addWidget (config) {
		var widget, actions;
		var widgetHtml = this.getWidgetHtml(config);
		var guid = Utils.guid();

		var defaultWidth = this.widgetWidth || 400;
		var defaultHeight = this.widgetHeight || 340;

		var defaultPosition = {
			left: 50 + $('#cw_sb_layout').offset().left + $('.cw_sboard_area').scrollLeft(),
			top: 50 + $('#cw_sb_layout').offset().top + $('.cw_sboard_area').scrollTop()
		};
		$('#cw_sb_layout').append('<div id="' + guid + '" class="cw_freeform_widget">' + widgetHtml + '</div>');

		if (this.isGuest) {
			actions = ['Toggle'];
		} else if (this.headers) {
			actions = ['Toggle', 'Pencil', 'Delete'];
		} else {
			actions = [ 'Dimension', 'Toggle', 'Pencil', 'Delete'];
		}

		var widgetWindow = $('#' + guid).kendoWindow({
			actions: actions,
			title: config.title || '',
			width: config.width || defaultWidth,
			height: config.height || defaultHeight,
			position: {
				top: config.top || defaultPosition.top,
				left: config.left || defaultPosition.left
			},
			resizable: !config.locked,
			draggable: true,
			minHeight: 20,
			appendTo: $('#cw_sb_layout'),
			drag: $.proxy(this.onWidgetWindowDrag, this),
			dragstart: $.proxy(this.onWidgetWindowDragStart, this),
			dragend: $.proxy(this.onWidgetWindowDragEnd, this),
			resize: $.proxy(this.onWidgetWindowResize, this),
			open: $.proxy(this.onWidgetWindowOpen, this)
		}).data('kendoWindow');

		var parent = $('#' + guid).parent();

		if(config.frame === false){
			parent.addClass('no_frame');
			parent.width( parent.width() - 2);
			parent.height( parent.height() - 2);
		}

		if(config.configuration.removeHeader){
			parent.addClass('force-no-header')
		}


		//this.updateOpacity(config, parent);
		//hide toggle icon if not needed

		let index = [
			'metrics', 'single_chart', 'graph_metric', 'multi_graph_asset', 'single_graph', 'multi_graph_assetgroup',
			'multigraph', 'history', 'asset_metrics', 'asset_multigraph', 'grid_event_summary', 'kpi_metrics', 'kpi_multigraph',
			'kpi_history', 'services'].indexOf(config.type);
		if (index === -1) {
			$(parent).find('.k-i-toggle').parent().addClass('hide');
		} else {
			if (config.configuration.hideTimeSelector) {
				$(parent).find('.k-i-toggle').parent().addClass('hide');
			}
		}

		//setting the zindex property
		if (config.zindex) {
			widgetWindow.element.closest('.k-window').css('z-index', config.zindex);
		}
		//adds handler for the custom icon

		widgetWindow.wrapper.on('click', '.k-i-toggle', e => {
			e.preventDefault();
			this.onWidgetToggleClick(e);
		});

		widgetWindow.wrapper.on('click','.k-i-dimension', e => {
			e.preventDefault();
		});

		widgetWindow.wrapper.on('click', '.k-i-pencil', e => {
			e.preventDefault();
			this.onEditWidget(e);
		});

		widgetWindow.wrapper.on('click', '.k-i-delete', e =>{
			e.preventDefault();
			this.onRemoveWidget(e);
		});

		this.disableLayering(widgetWindow);


		config.isKendoWindow = true;
		config.serviceBoardData = this.serviceBoardData;
		widget = this.getWidgetByConfig(config);
		widgetWindow.widget = widget;

		this.updateWindowOptions($('#' + guid), {});

		this.widgets.push(widget);
	}

	calculateFromGridConversion (gridDimensions) {
		let widgetWidth = gridDimensions.size_x * this.widgetWidth;
		let widgetHeight = gridDimensions.size_y * this.widgetHeight;
		let widgetLeft = (gridDimensions.col - 1) * this.widgetWidth + 5;
		let widgetTop = (gridDimensions.row - 1) * this.widgetHeight + 5;
		if (gridDimensions.col > 1) {
			widgetLeft += 10;
		}
		if (gridDimensions.row > 1) {
			widgetTop += 40;
		}
		return {
			width: widgetWidth,
			height: widgetHeight,
			left: widgetLeft,
			top: widgetTop
		};
	}

	disableMaximization (widgetWindow) {
		widgetWindow.wrapper.find('.k-window-titlebar').on('dblclick', function (e) {
			e.stopImmediatePropagation();
		});
	}

	disableLayering (widgetWindow) {
		//disable mousedoun on header to prevent layering
		widgetWindow.wrapper.find('.k-window-titlebar').on('mousedown', $.proxy(function (e) {
			if (this.mode === 'read') {
				e.stopImmediatePropagation();
			}
		}, this));

		//disable mousedoun on widget content to prevent layering
		widgetWindow.element.on('mousedown', $.proxy(function (e) {
			if (this.mode === 'read') {
				e.stopImmediatePropagation();
			}
		}, this));
	}

	addMouseDownEvent (widgetWindow) {
		widgetWindow.dragging._draggable.bind('drag', $.proxy(function (e) {
			var offset = $('#cw_sb_layout').offset();
			$(e.currentTarget).parent().css({
				'top': (e.clientY - offset.top - this.mouseDownY) + 'px',
				'left': (e.clientX - offset.left - this.mouseDownX) + 'px'
			});
		}, this));
		widgetWindow.wrapper.on('mousedown', '.k-window-titlebar', $.proxy(function (e) {
			var parentWindow = $(e.currentTarget).parent();
			var top = parentWindow.offset().top + parentWindow.height() - $('#cw_sb_layout').offset().top;
			var left = parentWindow.offset().left + parentWindow.width() - $('#cw_sb_layout').offset().left;
			var fakeDiv = '<div class="fakediv" style="display:inline-block;position:absolute;z-index:9999;width:1px;height:1px; top:' + top + 'px;left:' + left + 'px;"></div>';
			this.mouseDownX = e.clientX - parentWindow.offset().left;
			this.mouseDownY = e.clientY - parentWindow.offset().top;
			$('#cw_sb_layout').append(fakeDiv);
		}, this));
	}

	updateWidget (widgetObj) {
		var widget = this.getWidget(widgetObj.id);
		if (widget.unsubscribe) {
			widget.unsubscribe();
		}
		widget.destroy();
		var widgetDiv = $('#' + widgetObj.id);
		widgetDiv.closest('.k-widget').find('.k-window-title').html(widgetObj.title);
		widgetDiv.closest('.k-widget').find('.cw_section_content').empty();
		widgetObj.isKendoWindow = true;

		if(widgetObj.frame === false){
			widgetDiv.closest('.k-widget').addClass('no_frame');
		}else{
			widgetDiv.closest('.k-widget').removeClass('no_frame');
		}

		//this.updateOpacity(widgetObj, widgetDiv.closest('.k-widget'));

		this.updateWindowOptions(widgetDiv.parent(),{
			width: widgetObj.width,
			height: widgetObj.height,
			resizable: !widgetObj.locked
		});

		this.enableWidgets();

		//save some old configuration
		var specificConfiguration = {};
		if (widget.hasConfiguration) {
			specificConfiguration = widget.getConfiguration();
		}

		if (widgetObj.configuration) {
			widgetObj.configuration = $.merge(widgetObj.configuration, specificConfiguration);
		}
		//hide toggle icon if not needed
		if (['metrics', 'history', 'multigraph', 'grid_sla', 'grid_service', 'grid_asset', 'grid_event_summary'].indexOf(widgetObj.type) > -1) {
			if (widgetObj.configuration.hideTimeSelector) {
				widgetDiv.parent().parent().find('.k-i-toggle').parent().addClass('hide');
			} else {
				widgetDiv.parent().parent().find('.k-i-toggle').parent().removeClass('hide');
			}
		}
		widgetObj.serviceBoardData = this.serviceBoardData;
		widget = this.getWidgetByConfig(widgetObj);
		if(widget == null)
			return;

		for (var i = 0; i < this.widgets.length; i++) {
			if (this.widgets[i].id === widget.id) {
				this.widgets[i] = widget;
				break;
			}
		}
	}

	updateOpacity(widgetObj, windowRoot){
		let opacity = parseInt(widgetObj.opacity || 100);

		if(isNaN(opacity))
			opacity = 100;

		$('.cw_section_content', windowRoot).css('opacity', opacity / 100);
	}

	getWidgetHtml (config) {
		var widgetPanel = '';
		if (config.type.indexOf('grid') !== -1) {
			if (config.type === 'grid_sla') {
				widgetPanel += '<div id="' + config.id + '" class="cw_section no_title cw_widget cw_selector">';
			} else {
				widgetPanel += '<div id="' + config.id + '" class="cw_section no_title cw_widget">';
			}
		} else if (config.type.indexOf('console') !== -1) {
			widgetPanel += '<div id="' + config.id + '" class="cw_section no_title cw_widget">';
		} else if (config.type === 'gauge') {
			widgetPanel += '<div id="' + config.id + '" class="cw_section no_title cw_widget">';
		} else {
			if (config.type === 'history') {
				widgetPanel += '<div id="' + config.id + '" class="cw_section no_title cw_widget cw_selector">';
			} else {
				widgetPanel += '<div id="' + config.id + '" class="cw_section no_title cw_widget">';
			}
		}

		widgetPanel += '<div class="cw_section_content"></div>';
		widgetPanel += '<div class="cw_widget_actions right">';

		widgetPanel += '</div>';

		if (config.type === 'grid_service' || config.type === 'grid_asset' || config.type === 'grid_reasons') {
			widgetPanel += '<div class="cw_widget_settings" data-servicequalifierid="' + config.configuration.serviceQualifierId + '">';
			if (!this.isGuest) {
				widgetPanel += '<div class="right">';
				widgetPanel += '<input placeholder="' + lang.SEARCH + '" id="' + Utils.guid() + '" class="cw_search_box" type="text" />';
				widgetPanel += '</div>';
			}
			widgetPanel += '</div>';
		}

		if (config.type === 'history') {
		} else if (config.type === 'grid_sla') {
			widgetPanel += '<div class="cw_widget_settings" data-servicequalifierid="' + config.configuration.serviceQualifierId + '">';
			widgetPanel += '<ul class="cw_multi_toggle small right">';
			widgetPanel += '<li class="first_btn"><input type="radio" name="cw_slas_period" value="DAY" />' + lang.DAY + '</li>';
			widgetPanel += '<li><input type="radio" name="cw_slas_period" value="WEEK" checked="checked"/>' + lang.WEEK + '</li>';
			widgetPanel += '<li><input type="radio" name="cw_slas_period" value="MONTH" />' + lang.MONTH + '</li>';
			widgetPanel += '<li class="last_btn"><input type="radio" name="cw_slas_period" value="ALL" />' + lang.ALL + '</li>';
			widgetPanel += '</ul>';
			if (!this.isGuest) {
				widgetPanel += '<div class="right">';
				widgetPanel += '<input placeholder="' + lang.SEARCH + '" id="' + Utils.guid() + '" class="cw_search_box" type="text" />';
				widgetPanel += '</div>';
			}
			widgetPanel += '</div>';
		}
		widgetPanel += '</div>';

		return widgetPanel;
	}

	onWidgetWindowDrag (e) {

	}

	/**
	 * Handler for the widget dragend event
	 * @param {type} e
	 */
	onWidgetWindowDragEnd (e) {
		var canvas = $('#cw_sb_layout');

		canvas.find('.fakediv').remove();
		var windowLeft = e.sender.wrapper.offset().left;
		var layoutLeft = canvas.offset().left;
		var windowTop = e.sender.wrapper.offset().top;
		var layoutTop = canvas.offset().top;
		if (windowLeft < layoutLeft) {
			windowLeft = layoutLeft + 5;
		}
		if (windowTop < layoutTop) {
			windowTop = layoutTop + 5;
		}
		//fix the behaviour for widgets moved up at drag end exactly with the scrollbar top value
		var scrollTop = $('.cw_sboard_area').scrollTop();
		var newScrollTop = windowTop + scrollTop;
		e.sender.wrapper.offset({
			top: newScrollTop,
			left: windowLeft
		});

		this.calculateFreeBoardResolution();
	}

	/**
	 * Method for calculating the free board resolution
	 */
	calculateFreeBoardResolution () {
		var width, height;
		var widgets = this.widgets;
		var allWidgetsInBaseWidth = true;
		var allWidgetsInBaseHeight = true;
		var sboardArea = $('.cw_sboard_area');
		var sboardLayout = $('#cw_sb_layout');
		//check manually if all widgets are in base window; check done because the container does not resize manually after dragging back a widget in main window(TODO further investigation)
		for (var i = 0, length = widgets.length; i < length; i++) {
			var currentWidgetWindow = $('#' + widgets[i].id).closest('.k-window');
			var widgetOffsetRight = $(window).width() - (currentWidgetWindow.offset().left + currentWidgetWindow.outerWidth());
			if (widgetOffsetRight < 0) {
				allWidgetsInBaseWidth = false;
			}
			var widgetOffsetBottom = $(window).height() - (currentWidgetWindow.offset().top + currentWidgetWindow.outerHeight())
			if (widgetOffsetBottom < 0) {
				allWidgetsInBaseHeight = false;
			}
		}
		if (allWidgetsInBaseWidth) {
			width = sboardArea.innerWidth();
		} else {
			width = sboardArea[0].scrollWidth;
		}
		if (allWidgetsInBaseHeight) {
			height = sboardArea.innerHeight();
		} else {
			height = sboardArea[0].scrollHeight;
		}
		if (allWidgetsInBaseHeight && allWidgetsInBaseWidth) {
			if (sboardArea[0].scrollWidth === sboardLayout.width()) {
				sboardArea.css('overflow-x', 'hidden');
			}
			if (sboardArea[0].scrollHeight && sboardLayout.height()) {
				sboardArea.css('overflow-y', 'hidden');
			}
		} else {
			if (sboardArea[0].scrollWidth !== sboardLayout.width()) {
				sboardArea.css('overflow-x', '');
			}
			if (sboardArea[0].scrollHeight !== sboardLayout.height()) {
				sboardArea.css('overflow-y', '');
			}
		}
		sboardLayout.width(width);
		sboardLayout.height(height);
		this.calculateBoardResolution();
	}

	/**
	 * Handler for the widget dragend event
	 * @param {type} e
	 */
	onWidgetWindowDragStart (e) {
		this.currentLayoutWidth = $('#cw_sb_layout').width();
		this.currentLayoutHeight = $('#cw_sb_layout').height();
	}

	serialize () {
		var serviceBoard = [];
		var layoutTop = $('#cw_sb_layout').offset().top;
		var layoutLeft = $('#cw_sb_layout').offset().left;
		for (var i = 0; i < this.widgets.length; i++) {
			var widget = this.setWidgetConfiguration(this.widgets[i]);
			var widgetWindow = $('#' + widget.id).closest('.k-window');
			var kendoWindow = $('#' + widget.id).parent().data('kendoWindow');
			serviceBoard.push({
				widget: {
					configuration: widget.configuration,
					id: widget.id,
					title: kendoWindow.title(),
					type: widget.type,
					width: widgetWindow.width(),
					height: widgetWindow.height(),
					locked: widget.locked,
					frame: widget.frame,
					//opacity: widget.opacity,
					top: widgetWindow.offset().top - layoutTop,
					left: widgetWindow.offset().left - layoutLeft,
					zindex: widgetWindow.css('z-index')
				}
			});
		}
		return serviceBoard;
	}

	/*
	 * Handler function for checking and setting widget specific configuration
	 * @param {Object} widget The widget object
	 */
	setWidgetConfiguration (widget) {
		this.getWidgetConfiguration(widget);

		return widget;
	}

	onWindowResize () {

	}

	onWidgetWindowResize (e) {
		clearTimeout(this.resizeTimeout);
		this.resizeTimeout = setTimeout($.proxy(function() {
			this.updateWidgetWidthInConfig(e.sender);
		}, this), 50);
	}

	updateWidgetWidthInConfig(window){
		const windowDiv = window.element;
		let widgetConfig = this.widgets.find( x => x.id == window.widget.id);
		widgetConfig.width = windowDiv.width();
		widgetConfig.height = windowDiv.height();

		window.widget.onResize();
		this.calculateFreeBoardResolution();
	}

	onWidgetWindowOpen (e) {
		if (!this.headers) {
			var container = e.sender.wrapper;
			container.find('.k-window-actions').addClass('hide').css('z-index', '100');
			container.on('mouseover', e => {
				if(this.layoutCurrentlyEditable) {
					var target = $(e.currentTarget);
					target.find('.k-window-actions').removeClass('hide');
				}
			});
			container.on('mouseleave', e => {
				var target = $(e.currentTarget);
				target.find('.k-window-actions').addClass('hide');
			});
		}
	}

	onHeaderOnOffButton () {
		this.headers = !this.headers;
		this.toggleHeaders();
	}

	onHeaderOnOffButton () {
		if (this.headers) {
			this.headers = false;
			this.turnHeadersOff();
		} else {
			this.headers = true;
			this.turnHeadersOn();
		}
		this.saveServiceBoard();
	}

	toggleHeaders(){
		if(this.headers)
			this.turnHeadersOn();
		else
			this.turnHeadersOff();

		if(this.headers){
			$('#cw_sb_layout').find('.k-window.no_header:not(.force-no-header)')
				.removeClass('no_header')
				.each((i, e) => {
					$(e).height($(e).height() + 30);
				});
		}

		let disableHeadersOn = $('#cw_sb_layout').find('.k-window:not(.no_header).force-no-header');
		if(!this.headers){
			disableHeadersOn = disableHeadersOn.add($('#cw_sb_layout').find('.k-window:not(.no_header)'))
		}
	}

	turnHeadersOff () {
		$('#cw_sb_layout').find('.k-window:not(.no_header)')
			.not('.skip-header-config')
			.addClass('no_header')
			.each((i, e) => {
				$(e).height($(e).height() - 30);
			});
	}

	turnHeadersOn () {
		$('#cw_sb_layout').find('.k-window')
			.not('.skip-header-config')
			.removeClass('no_header')
			.each((i, e) => {
				$(e).height($(e).height() + 30);
			});;
	}

	onToggleWidgetActions (e) {
		var settingsContainer = $(e.currentTarget).closest('.k-window').find('.cw_widget_settings');
		if (settingsContainer.length) {
			if (settingsContainer.is(':visible')) {
				settingsContainer.slideUp(150);
			}
		}
		var widgetActions = $(e.currentTarget).closest('.k-window').find('.cw_widget_actions');
		widgetActions.toggleClass('expanded');
	}

	onRemoveWidget (e) {
		var widget = $(e.currentTarget).closest('.k-window').find('.cw_widget');
		var widgetId = widget.attr('id');
		var dialog = new Dialog({
			title: lang.INFO,
			msg: lang.serviceBoard.messages.WIDGET_REMOVE,
			scope: this,
			fn: function (value, button) {
				if (button === 'ok') {
					var index = -1;
					var widget = this.getWidget(widgetId);
					// remove listeners and tasks
					widget?.unsubscribe?.();

					for (var i = 0; i < this.widgets.length; i++) {
						var widget = this.widgets[i];
						if (widget.id === widgetId) {
							index = i;
						}
					}
					if (index > -1) {
						this.widgets.splice(index, 1);
					}
					$('#' + widgetId).parent().data('kendoWindow').destroy();

				}
			}
		});
		dialog.show();
	}
};
