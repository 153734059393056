import Utils from 'tools/utils';


export function TextWidgetConfiguration(config) {
	Utils.apply(this, config);
	this.initComponent();
};

export {TextWidgetConfiguration as default}

TextWidgetConfiguration.prototype = {

	initComponent: function () {
		let widget = this.widgetConfigurationFormEl;
		var widgetObj = this.widgetObj;
		var html = '<div class="cw_field"><label class="cw_inline">' + lang.TITLE + ': </label><input type="text" class="cw_widget_title full cw_inline" value="' + (widgetObj.title || '') + '" /></div>';
		html += '<div class="cw_field"><input type="checkbox" class="cw_include k-checkbox" id="cw_remove_header"><label for="cw_remove_header" class="k-checkbox-label">' + lang.widget.HIDE_HEADER + '</label></div>';
		// html += '<div class="cw_field"><input type="checkbox" class="cw_include k-checkbox" id="cw_remove_border"><label for="cw_remove_border" class="k-checkbox-label">'+lang.widget.HIDE_BORDER + '</label></div>';
		html += '<div class="cw_field"><textarea id="cw_widget_custom_text" type="text" style="height:400px;"></textarea></div>';
		widget.empty().append(html);
		$('#cw_widget_custom_text').val(widgetObj.configuration.text);
		$('#cw_remove_header').prop('checked', widgetObj.configuration.removeHeader);
		$('#cw_remove_border').prop('checked', widgetObj.configuration.removeBorder);
		this.initKendoComponents();
	},

	removeListeners: function () {

	},

	attachListeners: function () {

	},

	initKendoComponents: function () {
		this.kendoEditor = $('#cw_widget_custom_text').kendoEditor({
			resizable: false,
			height: 420,
			tools: ['bold', 'italic', 'underline', 'justifyLeft', 'justifyCenter', 'justifyRight', 'insertUnorderedList', 'insertOrderedList', 'fontName', 'fontSize', 'foreColor', 'backColor', 'createLink']
		}).data('kendoEditor');
	},

	getValues: function () {
		var title = $('.cw_widget_title').val().trim() || lang.TEXT;
		var widgetObj = {
			id: this.mode === 'update' || this.wasUpdate ? this.widgetObj.id : Utils.guid(),
			type: 'custom_text',
			title: title,
			configuration: {
				text: $('#cw_widget_custom_text').data('kendoEditor').value().trim(),
				removeHeader: $('#cw_remove_header').is(':checked'),
				removeBorder: $('#cw_remove_border').is(':checked')
				//,fontSize: $('#cw_widget_custom_font_size').data('kendoNumericTextBox').value()
			}
		};
		return widgetObj;
	},

	isValid: function () {
		return {
			valid: true,
			validationMessage: ''
		};
	},

	destroy: function () {
		if (this.kendoEditor) {
			$('#cw_widget_custom_text').remove();
		}
	}
};
