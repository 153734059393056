import {select} from 'redux-saga/effects';
import {getCallback} from "../selectors/getCallback";
import {getReduxFormValues} from "../selectors/getReduxFormValues";
import {isFormValid} from "../selectors/isFormValid";
import {getItemValue} from "../selectors/getItemValue";

export function* onChange(params) {
	const {values} = params;

    const onChange = yield select(getCallback, 'onChange');

    if (!onChange) {
    	return;
	}

    const formValues = yield select(getReduxFormValues);
    const info = yield select(getItemValue, 'info');
    const isValid = yield select(isFormValid);

    onChange(values || formValues, info, isValid);
}
