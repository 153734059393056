import './newBudgetWindow.less'

import React, {useRef, useState} from "react";
import {Window} from "../../../controls/react/kendoWrappers";
import {topLeftCornerPosition} from "../../../controls/modalWindow";
import {Section} from "../../../controls/react/layout/section";
import {AccountSelect, BudgetConfigurationForm} from "areas/cost/budget/budgetConfigurationForm";
import {Toolbar} from "../../../controls/react/layout";
import {ActionButtons} from "../../../controls/react/form";
import {CostsApi} from "../api";
import {CostsRouter} from "../bundleDescription";
import State from "../../../tools/state";
import {translator} from "../../../core";
import {AntSelect} from "../../../controls/react/ant/antSelect";
import {FormEntry} from "../../../controls/react/form/formEntry";
import {ModelValidator} from "../../../framework/mobx-integration";
import {observer} from "mobx-react";
import {InfoCircleOutlined} from "@ant-design/icons";
import {BoxView} from "controls/react/layout/boxView";
import {costTranslates} from './../translations';
import {NewBudgetModel} from "areas/cost/budget/newBudgetModel";
import {ApplicationState} from "framework/applicationState";
import {AntInput} from "controls/react/ant/antInput";

const b = require('b_').with('new-budget-window')

const i = translator(costTranslates, {
	'NEW': {no: 'Ny', en: 'New'},
	'AUTO': {no: 'Auto', en: 'Auto'},
	'MANUAL': {no: 'Manuell', en: 'Manual'},
	'DUPLICATE': {en: 'Duplicate'},
	'COST_COLLECTOR': {en: 'Cloud connection', no: 'Skykobling'},
	'Duplicate from': {no: 'Duplikat fra'},
	'Create from': {no: 'Opprett fra'},
	'Connection': {no: 'Tilkobling'}
})


const EnumSelect = observer(({target, modelField, values}: {target: NewBudgetModel, modelField: keyof NewBudgetModel, values: string[]}) => {
	const dataList = values.map((x) => ({name: i(x), id: x}));
	return <FormEntry label={i('Create from')} model={target} modelField={modelField}>
		<AntSelect dataList={dataList}/>
	</FormEntry>
});

const CostModelSelect = observer(({target, dataList}: {target: NewBudgetModel, dataList: any[]}) => {
	return <FormEntry label={i('Duplicate from')} model={target} modelField={'costModelId'}>
		<AntSelect dataList={dataList}/>
	</FormEntry>
});

const CostConnectionSelect = observer(({target, dataList}: {target: NewBudgetModel, dataList: any[]}) => {
	return <FormEntry label={i('COST_COLLECTOR')} model={target} modelField={'cloudConnection'}>
		<AntSelect dataList={dataList}/>
	</FormEntry>
});

const ImportSettings = observer((props: {budget: NewBudgetModel, costType: string}) => {
	const { budget } = props;
	let creationTypeList = ['NEW'];
	if (props.costType === 'MANUAL') {
		creationTypeList.push('DUPLICATE');
	} else {
		budget.creationType = 'COST_COLLECTOR';
	}
	return <>
		{ props.costType === 'MANUAL' && <EnumSelect target={budget} values={creationTypeList} modelField={'creationType'}/> }
		{ budget.creationType === 'DUPLICATE' && <AccountSelect target={budget} modelField={'linkAccountId'} /> }
		{ budget.creationType === 'DUPLICATE' && <CostModelSelect target={budget} dataList={budget.filteredCostModelsDataList}/> }
		{ budget.creationType === 'COST_COLLECTOR' &&
		<Section appearance={'frame'} title={i('Connection')} childrenPadding={true}>
			<AccountSelect target={budget} modelField={'linkAccountId'} />
			<CostConnectionSelect target={budget} dataList={budget.costConnectionModelsDataList}/>
		</Section>
		}
	</>
});

export const ValidationErrorPopup = observer(<T extends object>(props: {validator: ModelValidator<T>, fields: Array<keyof T>}) => {
	const [display, setDisplay] = useState(true);
	let timeoutRef = useRef(null);
	const errors = props.fields.reduce((result: string[], current: keyof T) => ([...result, ...props.validator.getErrors(current)]), []);
	const valuesIsEmpty = props.fields.map(key => props.validator.model[key]).every(x => !x);
	if (valuesIsEmpty || !errors.length) {
		if (!display) {
			setDisplay(true);
		}
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
			timeoutRef.current = null;
		}
		return null;
	}
	if (!timeoutRef.current && display) {
		timeoutRef.current = setTimeout(() => {
			setDisplay(false);
			timeoutRef.current = null;
		}, 3000);
	}
	return <>
		{display &&
			<BoxView containerClass={b('validation-message')} type={"error"} rounded={false} border={false}>
				<Section direction="row" childrenPadding={true} contentPadding={true} appearance="none">
					<InfoCircleOutlined />
					<ul>
						{errors.map(x => <li key={x}>{x}</li>)}
					</ul>
				</Section>
			</BoxView>
		}
	</>
});

interface NewBudgetWindowState {
	budget: NewBudgetModel
}

class NewBudgetWindowUnobserved extends React.Component<any, NewBudgetWindowState> {
	budget: NewBudgetModel
	nameRef =  React.createRef<typeof AntInput>()

	constructor(props: any, context: any) {
		super(props, context);
		this.budget = new NewBudgetModel(props.costType);
	}

	render() {
		const windowTitle = this.props.costType === 'CLOUD' ? i('Cost Store') : i('Cost Model');

		let importSettings = <ImportSettings budget={this.budget}
											 costType={this.props.costType}
		/>
		let sectionContent;
		if (this.props.costType === 'MANUAL') {
			sectionContent =
				<>
					{importSettings}
					<BudgetConfigurationForm budget={this.budget}
											 nameRef={this.nameRef}/>
				</>
		} else {
			sectionContent = <BudgetConfigurationForm budget={this.budget}
													  nameRef={this.nameRef}
													  importSettings={importSettings}/>
		}
		return (
			<Window title={windowTitle} modal position={topLeftCornerPosition}
				notCenter={true}
				height={200} //for minimal height
				resizable={false}
				onClose={this.props.onCancel}
				width={400}
				onInit={() => this.nameRef.current.focus()}
				contentClass={b('content')}
			>
				<ValidationErrorPopup validator={this.budget.validator} fields={['name']} />
				<Section childrenPadding={true}>
					{sectionContent}
				</Section>
				<Toolbar>
					<ActionButtons onSave={this.onSave}
								   onCancel={this.props.onCancel}
								   saveDisabled={!this.budget.valid}
					/>
				</Toolbar>
			</Window>
		)
	}

	onSave = async () => {
		const store = this.budget;
		const budget = {
			id: store.costModelId,
			accountId: store.accountId ?? ApplicationState.accountId,
			name: store.name,
			description: store.description,
			currency: store.currency,
			startDate: store.startDate.format('YYYY-MM-DD')
		}

		let result;
		if (store.creationType === 'NEW' || store.creationType === 'DUPLICATE') {
			result = await CostsApi.saveLink(budget, store.creationType);
		} else {
			const link = {
				...budget
			}
			if (!!store.cloudConnection) {
				link.costModelIds = store.costConnectionModels.find(x => x.id === store.cloudConnection).costModelIds;
			}
			result = await CostsApi.saveLink(link, store.creationType);
		}

		if (result.success) {
			this.props.onCancel();
			let url = CostsRouter.details(result.data);
			State.mainApp.navigate(url);
		}
	}
}

export const NewBudgetWindow = observer(NewBudgetWindowUnobserved);
