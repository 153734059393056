import Api from 'tools/api';

export default class MonitorsAPI {
	static getMonitorType(accountId, monitorId) {
		return Api.accountRoot(accountId) + `monitors/${monitorId}/type`;
	}

	static getSetMaintenanceUrl(accountId, isMaintenance) {
		return Api.accountRoot(accountId) + `monitors/setMaintenance/${isMaintenance}`;
	}
}

export {MonitorsAPI};
