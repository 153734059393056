import React from 'react';
import classnames from "classnames";

import FormEntry from "controls/react/form/formEntry";
import TextBox from "controls/react/form/textBox";

import CEFileChooserWindow from "controls/react/form/formBuilder/subforms/fields/ceFileChooser/ceFileChooserWindow";

import translator from "core/localization/localization";

import './ceFileChooser/ceFileChooser.less';

import {b} from './ceFileChooser/ceFileChooserWindow';

const i = translator({
	'Click to select file': {
		'no': 'Klikk for å velge fil'
	}
});

function CEFileChooser(props) {
	const {input: {onChange, value}, filenameField, fileName, changeField, disabled, directoriesOnly} = props;
	const [showWindow, setShowWindow] = React.useState(false);

	const handleSave = React.useCallback((dir, file) => {
		setShowWindow(false);
		onChange(dir);

		if (filenameField && file) {
			changeField(filenameField, file);
		}
	}, []);

	const handleManualChange = React.useCallback((value) => {
		handleSave(value, null);
	}, [handleSave]);

	const handleCancel = React.useCallback((value) => {
		setShowWindow(false);
	}, []);

	const handleOpen = React.useCallback(() => {
		setShowWindow(true);
	}, []);

	const onLoad = React.useCallback(async (path) => {
		const items = await props.onData(props.targetValue, {...props.values, [props.targetValue] : {initialDirectory: path ? path: ""}});

		return items.map((item) => {
			return {
				title: item.text || item.path,
				key: item.path,
				isLeaf: !item.folder
			}
		});
	}, [props.values, props.onData, props.targetValue ]);

	const stopPropagation = React.useCallback((e) => {
		e.stopPropagation();
	}, []);

	return <>
		<FormEntry label={props.caption}>
			<TextBox value={value} onChange={handleManualChange} title={value} />
			<span className={classnames("glyphicons folder-open", b('folder-icon'))} onClick={handleOpen} title={i('Click to select file')} />
		</FormEntry>
		{showWindow && <div onClick={stopPropagation}><CEFileChooserWindow
			directoriesOnly={props.directoriesOnly}
			value={value}
			fileName={fileName}
			onLoad={onLoad}
			onSave={handleSave}
			onCancel={handleCancel}/>
		</div>}
	</>
}

export default CEFileChooser;
