import {Application, Cookies, ErrorHandler, LocalEventsManager, RemoteEventsManager} from "core";
import {CeeViewDataSource, Renderer, State, UserPrefs, UserSettings, Utils} from "tools";
import {
	CustomNotification,
	Dependencies,
	Dialog,
	EventsToolbar,
	GridMenu,
	ModalWindow,
	PreferencesPanel
} from "controls";
import {Settings} from "settings";
import {AssetColumns} from "areas/assets/assetColumns";
import {AssetGroupForm} from "areas/administration/assetGroupForm";
import AssetHealthReasons from "areas/assets/assetHealthReasons";
import AssetHealthSummary from "areas/assets/assetHealthSummary";
import MultiSelectGridFilter from 'controls/multiSelectGridFilter';
import {openTagsFormWindow} from "controls/tagsForm";
import {AssetGroupRouter} from './bundleDescription';
import {NavigationAction} from 'appStore';
import {completeColumnsWidth} from 'tools/utils';
import lang from 'core/localization/lang'
import {EventSummaryRouter} from "areas/views/bundleDescription"

const openItems = new Set();
let realIdToTreelistId = {};
let treelistIdToRealId = {};

export default function AssetGroupSummaryView(config) {
	Utils.apply(this, config);
	this.module = {
		name: 'AssetGroupSummaryView',
		initialConfig: config
	};
	this.hasEvents = true;
	this.subscriberId = Utils.guid();
	this.loadUserPreferences();
};

jQuery.extend(AssetGroupSummaryView.prototype, Application.prototype, {
	initComponent: function () {
		Application.prototype.initComponent.call(this);

		this.indicatorSelector = '.group_summary_view .cw_new_tag_indicator';

		this.nestedGrids = [];
		this.showGridView = true;
		this.removeListeners();
		this.attachListeners();
		this.id = Utils.guid();
		var eventStatus = JSON.parse(UserPrefs.get('eventsStatus')) || {
				startFrom: 0,
				playing: true
			};
		this.eventsToolbar = new EventsToolbar({
			renderTo: $('#cw_asset_groups_events_toolbar'),
			callBackFn: $.proxy(this.onEventsTimeout, this),
			subscriberId: this.subscriberId,
			startFrom: eventStatus.startFrom,
			playing: eventStatus.playing
		});
		this.actionNotification = new CustomNotification({
			appendToElement: '.window_area',
		});

		this.loadSplitter();
		this.assetGroupTreeListExpanded = [];
	},

	loadSplitter: async function() {
		const splitterConfig = await UserSettings.get('AssetGroupHealthSplitter', 'splitter', "30%") ;

		this.splitter = $('#cw_splitter').kendoSplitter({
			orientation: "horizontal",
			panes: [
				{size: splitterConfig, min: "500px"},
				{min: "400px"}
			],
			resize: async (e) => {
				if (!this.splitter) {
					return;
				}

				const size = parseInt(this.splitter.size(".k-pane:first"));
				const width = e.width;

				let value = 100 * size / width;

				if (value < 20) {
					value = 20;
				}

				await UserSettings.set('AssetGroupHealthSplitter', {
					splitter: Math.floor(value) + "%"
				});
			}
		}).data("kendoSplitter");

		this.initKendoComponents();
	},

	/**
	 * Account application related initializations can be done here
	 */
	initKendoComponents: function () {
		this.defaultColumns = {
			id: {
				hidden: false,
				width: 60
			},
			assetIndicator: {
				hidden: false,
				width: 80
			},
			name: {
				hidden: false,
				width: 200
			},
			groupType: {
				hidden: false,
				width: 200
			},
			tags: {
				hidden: false,
				width: 150
			},
			accountName: {
				hidden: !State.includeSubaccounts,
				width: 150
			}
		};
		this.gridMessages = {
			isTrue: '<span class="glyphicons status_icon circle-arrow-top"></span>',
			isFalse: '<span class="glyphicons status_icon circle-arrow-down"></span>',
			clear: lang.CLEAR,
			info: lang.grid.filter.SHOW_ITEMS,
			filter: lang.FILTER
		};
		State.mainApp.contextMenu.setActionButton('GROUP');
		var defaultFsView = UserPrefs.get('defaultFsView');
		if (Utils.isGuid(defaultFsView)) {
			var pref = JSON.parse(UserPrefs.get(defaultFsView));
			pref = pref || {
					sort: [],
					filter: []
				};
			this.ahGroupSummaryColumns = pref.columns;
		}
		this.initGroupTreeList();
		this.gridMenu = new GridMenu({
			renderTo: 'cw_group_grid_menu',
			items: [{
				id: 'cw_create',
				icon: 'plus-sign',
				text: lang.CREATE,
				fn: State.mainApp.onNewAssetGroup,
				scope: State.mainApp,
				role: 'ASSET_CREATE'
			}, {
				id: 'cw_delete',
				icon: 'bin',
				text: lang.DELETE,
				fn: this.onAssetGroupDelete,
				scope: this,
				disabled: true,
				role: 'ASSET_DELETE'
			}, {
				id: 'cw_set_tags',
				icon: 'tag',
				text: lang.SET_TAGS,
				fn: $.proxy(function () {
					return openTagsFormWindow('assetgroup', this);
				}, this),
				scope: this,
				disabled: true,
				role: 'ASSET_UPDATE'
			}]
		});

		this.tabStrip = $('#cw_group_summary_tab_nav').kendoTabStrip({
			animation: false,
			activate: $.proxy(this.onTabActivate, this)
		}).data('kendoTabStrip');
		this.tabStrip.activateTab($('.cw_nav_assets'));
	},
	onTabActivate(e) {
		switch ($(e.item).data('tab')) {
			case 'reasons':
				this.onReasonsTab();
				break;
			default:
				//assets
				//this.onAssetsTab();
				this.currentTab = 'assets';
				let title = lang.ASSETS;
				if (this.selectedAssetGroupName) {
					title += ' - ' + this.selectedAssetGroupName;
				}
				$('.cw_current_assetgroup').text(title);
				$('#cw_group_assets_list').data('kendoCustomGrid')?.refresh();
				break;
		}
	},
	/**
	 * Handler function for initializing the left group tree list
	 */
	initGroupTreeList: function () {
		var filterMessages = lang.grid.filter;
		var ahGroupSummaryColumns = this.ahGroupSummaryColumns || JSON.parse(UserPrefs.get('ahGroupSummaryColumns')) || this.defaultColumns;
		ahGroupSummaryColumns = Utils.completeColumns(ahGroupSummaryColumns, this.defaultColumns);
		var ahGroupSummarySort, ahGroupSummaryFilter = [];
		var defaultFsView = UserPrefs.get('defaultFsView');

		if (Utils.isGuid(defaultFsView)) {
			let pref = JSON.parse(UserPrefs.get(defaultFsView));
			pref = pref || {
					sort: [],
					filter: []
				};
			ahGroupSummarySort = pref.sort;
			ahGroupSummaryFilter = pref.filter;
			this.ahGroupSummaryColumns = pref.columns;
		} else {
			if (UserPrefs.get('ahGroupSummarySort')) {
				ahGroupSummarySort = JSON.parse(UserPrefs.get('ahGroupSummarySort'));
			} else {
				ahGroupSummarySort = [{
					field: 'name',
					dir: 'asc',
					compare: null
				}];
			}
			if (UserPrefs.get('ahGroupSummaryFilter')) {
				ahGroupSummaryFilter = JSON.parse(UserPrefs.get('ahGroupSummaryFilter'));
			}
		}
		let stateFilters;
		if(this.isFromAssetGroupWidget) {
			this.preferences = {
				sort: [{
					field: 'assetIndicator',
					dir: 'asc'
				}],
				filter: []
			};
		}

		if (this.preferences) {
			ahGroupSummarySort = this.preferences.sort || ahGroupSummarySort;
			ahGroupSummaryFilter = this.preferences.filter || ahGroupSummaryFilter;
		}
		ahGroupSummaryFilter = UserPrefs.restoreDateFilter(ahGroupSummaryFilter);
		if (this.assetGroupFilterIndicator) {
			stateFilters = this.convertStateToFilter(this.assetGroupFilterIndicator);
			ahGroupSummaryFilter.push(stateFilters);
		}

		if (this.assetGroupNameFilter) {
			let nameFilters = this.convertNameToFilter(this.assetGroupNameFilter);
			ahGroupSummaryFilter.push(nameFilters);
		}

		let scope = this;
		let url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/health/assetGroups/treelist?includeSubaccounts=' + State.includeSubaccounts + (this.serviceId ? '&serviceId=' + this.serviceId : '');
		if (this.source && this.source === 'application') {
			url = Settings.serverPath + 'applications/' + this.applicationId + '/assetGroups/treelist';
		}
		this.treeListDataSource = new kendo.data.TreeListDataSource({
			transport: {
				read: {
					url: url,
					contentType: "application/json; charset=utf-8",
					type: "POST",
					dataType: "json",
					beforeSend: function (xhr) {
						xhr.setRequestHeader('Auth-Token', Cookies.sessionId);
					},
					cache: false
				},
				parameterMap: $.proxy(function (data, type) {
					if (data.filter?.filters) {
						data.filter.filters = Utils.changeDateFilterToString(data.filter.filters);
					}
					return kendo.stringify(data);
				}, this)
			},
			schema: {
				model: {
					id: 'treelistId',
					fields: {
						parentId: {
							field: 'parentGroupId',
							nullable: true
						},
						hasChildren: {
							field: 'hasSubgroups'
						},
						realId: {
							field: 'id'
						}
					}
				},
				parse: (response) => {
					realIdToTreelistId = {};
					treelistIdToRealId = {};
					let data = response.items;
					this.filterOptions = response.filterOptions;

					data.forEach((item) => {
						realIdToTreelistId[item.id] = item.treelistId;
						treelistIdToRealId[item.treelistId] = item.id;
					});

					for (var i = 0, length = data.length; i < length; i++) {
						const item = data[i];

						if (item.parentGroupIds && item.parentGroupIds.length) {
							item.parentGroupId = realIdToTreelistId[item.parentGroupIds[0]];
						} else {
							item.parentGroupId = null;
						}

						if (data[i].healthIndex > -1) {
							if (data[i].healthIndex >= 0 && data[i].healthIndex < 25) {
								data[i].assetIndicator = '3';
							}
							if (data[i].healthIndex >= 25 && data[i].healthIndex < 50) {
								data[i].assetIndicator = '2';
							}
							if (data[i].healthIndex >= 50 && data[i].healthIndex < 75) {
								data[i].assetIndicator = '1';
							}
							if (data[i].healthIndex >= 75 && data[i].healthIndex < 101) {
								data[i].assetIndicator = '0';
							}
						} else {
							data[i].assetIndicator = '6';
						}
					}
					this.totalItems = response.total;
					return data;
				}
			},
			sort: ahGroupSummarySort,
			filter: ahGroupSummaryFilter,
			serverSorting: true,
			serverFiltering: true,
			error: (e) => {
				this.loadingError = true;
				ErrorHandler.kendoServerError(e)
			},
			change: $.proxy(function(e) {
				if (!this.currentExpanded) {
					this.initialItems = this.treeList.dataSource.data().length;
				}
				e.secondaryGrid = false;
				e.initialItems = this.totalItems || this.initialItems;
			}, this)
		});

		var treeListHeader = $('#cw_group_treelist_header');
		treeListHeader.append('<span class="cw_asset_group_summary_treelist_header">Asset group</span>');
		var treeListHeaderRealHeight = treeListHeader.height() + parseInt(treeListHeader.css('padding-top').split('px')[0]);
		this.treeListHeight = $('#cw_group_treelist_container').height() - treeListHeaderRealHeight;

		let treeListContainer = $('#cw_groups_list');
		ahGroupSummaryColumns = completeColumnsWidth(ahGroupSummaryColumns, treeListContainer, this.defaultColumns);
		const refreshDataGrids = _.debounce(()=>{scope.refreshGridDatasources()}, 600);
		this.treeList = treeListContainer.kendoCustomTreeList({
			dataSource: this.treeListDataSource,
			height: this.treeListHeight,
			resizable: true,
			scrollable: true,
			selectable: 'row',
			sortable: {
				mode: 'multiple',
				allowUnsort: true
			},
			filterable: {
				extra: false,
				operators: {
					string: {
						startswith: filterMessages.STARTS_WITH,
						neq: filterMessages.NEQ,
						eq: filterMessages.EQ,
						contains: filterMessages.CONTAINS
					},
					number: {
						gte: filterMessages.GTE,
						gt: filterMessages.GT,
						lte: filterMessages.LTE,
						lt: filterMessages.LT
					}
				},
				messages: this.gridMessages
			},
			columns: [{
				field: 'id',
				title: lang.SELECTOR,
				menu: false,
				sortable: false,
				filterable: false,
				template: '<input type="checkbox" class="cw_grid_check" data-id="${id}" data-realid="${realId}"/>',
				headerTemplate: '<input type="checkbox" class="cw_grid_check_all" />',
				attributes: {
					'class': 'text_center'
				},
				headerAttributes: {
					'class': 'text_center'
				},
				hidden: ahGroupSummaryColumns.id.hidden,
				width: ahGroupSummaryColumns.id.width < 60 ? 60 : ahGroupSummaryColumns.id.width
			}, {
				field: 'assetIndicator',
				title: lang.assethealth.SEVERITY,
				sortable: {
					compare: (a, b, descending) => {
						return Utils.customCompare(a, b, 'assetIndicatorSorter', '-1', descending ? 'desc' : 'asc');
					}
				},
				filterable: {
					operators: {
						string: {
							eq: filterMessages.ISIN,
							neq: filterMessages.ISNOTIN
						}
					},
					ui: $.proxy(Renderer.renderAssetIndicatorFilter, {
						gridSelector: $('#cw_groups_list'),
						serverSorting: true
					}),
					messages: this.gridMessages
				},
				attributes: {
					'class': "text_center ellipsis to_expand"
				},
				headerAttributes: {
					"class": "k-header text_center"
				},
				template: function (item) {
					if (item.healthIndex === 0 || (item.healthIndex && item.healthIndex > -1)) {
						return Renderer.monitor({
							monitorIndex: item.healthIndex,
							type: 'system_monitor',
							cssClass: 'inline_block',
							monitorError: item.monitorError,
							monitorDataUnavailable: item.monitorDataUnavailable,
							monitorMaintenance: item.monitorMaintenance,
							ignoreToolTip: true,
							assetMaintenance: item.assetMaintenance,
							targetType: 'ASSET_GROUP'
						});
					} else if (item.hasData) {
						return '<span title="' + lang.account.messages.AGENT_DOWN + '" class="pointer glyphicons imac"></span>';
					} else {
						let glyphClass = '';
						if (item.monitorError) {
							glyphClass = 'glyphicons exclamation-mark';
						} else if (item.assetMaintenance || item.monitorMaintenance) {
							glyphClass = 'glyphicons wrench white';
						}
						let title = [];
						if (item.monitorError) {
							title.push(lang.account.messages.MONITOR_ERROR);
						}
						if (item.assetMaintenance) {
							title.push(lang.account.messages.ASSET_MAINTENANCE);
						}
						if (item.monitorMaintenance) {
							title.push(lang.account.messages.MONITOR_MAINTENANCE);
						}
						title = title.join(' | ');
						return '<div class="cw_pillbox pointer is_idle" data-type="system_monitor"><div class="cw_status asset_group_wrench cw_indicator ' + glyphClass + '" cached-title="' + title + '" title="' + title + '"></div><span class="cw_value">N/A</span></div>';
					}
				},
				hidden: ahGroupSummaryColumns.assetIndicator.hidden,
				width: ahGroupSummaryColumns.assetIndicator.width
			}, {
				field: 'name',
				title: lang.NAME,
				sortable: true,
				filterable: true,
				template: item => {
					return `${item.name}<span title="${lang.CLICK_TO_EDIT_ASSET_GROUP}" class="right glyphicons pencil cw_asset_group_details" style="font-size: 15px;"></span>`;
				},
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				},
				hidden: ahGroupSummaryColumns.name.hidden,
				width: ahGroupSummaryColumns.name.width
			}, {
				field: 'groupType',
				title: lang.TYPE,
				sortable: true,
				filterable: true,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				},
				hidden: ahGroupSummaryColumns.name.hidden,
				width: ahGroupSummaryColumns.name.width
			}, {
				field: 'tags',
				title: lang.TAGS,
				sortable: true,
				filterable: {
					ui: function (element) {
						var multiselect = new MultiSelectGridFilter({
							element: element,
							field: 'tags',
							grid: scope.grid,
							itemTemplate: '#=data.text#',
							tagTemplate: '#=data.text#',
							dataSource: scope.filterOptions['tags']
						});
					},
					messages: this.gridMessages,
					extra: false,
					operators: {
						string: {
							eq: filterMessages.EQ,
							neq: filterMessages.NEQ
						}
					}
				},
				template: item => item.tags.join(','),
				//hidden: ahGroupSummaryColumns.tags.hidden,
				hidden: false,
				width: ahGroupSummaryColumns.tags.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'accountName',
				title: lang.ACCOUNT,
				sortable: true,
				filterable: {
					ui: $.proxy(function (element) {
						return Renderer.filterGridByOwnElementDS.call(this, element, 'accountName');
					}, this),
					messages: this.gridMessages,
					extra: false,
					operators: {
						string: {
							neq: filterMessages.NEQ,
							eq: filterMessages.EQ
						}
					}
				},
				hidden: !State.includeSubaccounts || ahGroupSummaryColumns.accountName.hidden,
				width: ahGroupSummaryColumns.accountName.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}],
			expand: function(e) {
				openItems.add(e.model.realId)
			},
			collapse: function (e) {
				openItems.delete(e.model.realId);
			},
			change: async function(e) {
				if (scope.refreshFromEvents && scope.cachedSelectedRow && scope.cachedSelectedRow.length) {
					scope.selectedRow = scope.cachedSelectedRow;
					scope.refreshFromEvents = false;
				} else {
					if (this.select().length) {
						scope.selectedRow = this.select();
					} else (
						scope.selectedRow = $('#cw_groups_list').find('tbody tr').first()
					)
				}
				if (!scope.treeListRefreshed) {
					await scope.onRowSelect();
					if (!scope.dataSourceChangedOnce) {
						scope.onAssetsTab();
					} else {
						refreshDataGrids();
					}
				}
				scope.treeListRefreshed = false;
				//flag which helps triggering the change from dataBound only at first entrance
				scope.dataSourceChangedOnce = true;

				scope.onRowExpand(e);

				if (scope.selectedRow.length) {
					scope.selectedItemId = scope.treeList.dataItem(scope.selectedRow).realId;
				}
				if (!this.dataSource.data().length) {
					if (scope.currentTab === 'assets') {
						$('.cw_current_assetgroup').text(lang.ASSETS);
					} else if (scope.currentTab === 'reasons') {
						$('.cw_current_assetgroup').text(lang.REASONS);
					}
				}
			},
			dataBound: function() {
				Array.from(openItems.values()).map((realId) => {
					for (let item of scope.treeList.items()) {
						const dataItem = scope.treeList.dataItem(item);

						if (dataItem.realId === realId) {
							scope.treeList.expand(item);
							break;
						}
					}
				});

				var tooltip = $('#cw_groups_list').find('.k-grid-content').data('kendoTooltip');
				if (tooltip) {
					tooltip.destroy();
				}
				$('#cw_groups_list').find('.k-grid-content').kendoTooltip({
					filter: "td.tooltip:not(:empty)",
					content: function (event) {
						return event.target.text().split("\n").join("<br />");
					}
				});

				if (scope.selectedItemId) {
					setTimeout(async function () {
						for (let item of scope.treeList.items()) {
							const itemData = scope.treeList.dataItem(item);

							if (scope.selectedItemId === itemData.realId) {
								scope.treeList.table.find('td').css('background-color', '#ffffff');
								scope.selectedRow = $(item);
								scope.selectedRow.find('td').css('background-color', '#e4f1f9');
								break;
							}
						}
						//await scope.onAssetsTab();
					}, 0);
				}

				var groupsContainer = $('#cw_groups_list');
				var td = groupsContainer.find('td');
				if (td.length) {
					var treeListRows = groupsContainer.find('tbody > tr');
					$(treeListRows).each(function() {
						var groupId = $(this).find('.cw_grid_check').attr('data-id');
						var groupRealId = $(this).find('.cw_grid_check').attr('data-realid');
						$(this).attr('data-id', groupId);
						$(this).attr('data-realid', groupRealId);
					});
					td.css('cursor', 'pointer');
				}

				$('#cw_groups_list').find('.k-i-filter').removeClass('k-i-filter').addClass('k-i-arrowhead-s');

				if (scope.idToBeSelected && !scope.selectionPerformed) {
					scope.selectionPerformed = true;
					let rowToBeSelected = $('tr[data-realid='+ scope.idToBeSelected +']');
					this.select(rowToBeSelected);
				} else {
					this.trigger('change');
				}

				Utils.updateGridFilterIndicators($('#cw_groups_list').data('kendoCustomTreeList'));
			}
		}).data('kendoCustomTreeList');

		Utils.gridColumnHeaderTooltip(this.treeList);
		this.adjustSectionHeight();
		this.setErrorToolTip($('#cw_groups_list'));
		this.treeList.thead.find("[data-field='acknowledged']>.k-header-column-menu").remove();
		this.treeList.thead.find("[data-field='id']>.k-header-column-menu").remove();
		var searchValue = UserPrefs.get('defaultFsView') ? '' : (UserPrefs.get('ahGroupSummarySearchPhrase') || '');
		this.treeListFilterPanel = new PreferencesPanel({
			renderTo: 'cw_asset_group_summary_filters',
			grid: this.treeList,
			modulePrefName: 'AssetGroupHealth',
			defaultPrefViewKey: 'defaultFsView',
			prefListKey: 'ahGroupSummaryFSViews',
			userPref: State.currentApp.userPref,
			onRemove: $.proxy(State.currentApp.saveUserPreferences, State.currentApp),
			searchFields: ['name'],
			searchValue: searchValue,
			defaultColumns: this.defaultColumns,
			searchHeaderRenderer: 'cw_group_treelist_header',
			searchHeader: true,
			onlySearchHeader: true,
			multipleGrids: true
		});

		this.removeMask();
		var groupListContainer = $('#cw_groups_list');
		groupListContainer.closest('.k-pane').css('overflow', 'hidden');
		groupListContainer.find('th').first().css('border-right', '1px solid #cccccc');
		groupListContainer.find('th[data-field="assetIndicator"]').find('.k-icon').css({'float':'right'});
	},
	reloadByTags: function () {
		this.treeList.dataSource.read();
	},
	/**
	 * Handler function for the change(select) event on the grid
	 * @param {Object} e The change event object
	 */
	onRowExpand: function (e) {
		var selectedRow = this.treeList.select();
		var myRow = selectedRow[0];
		var messageEl = $(myRow).find('.to_expand');
		if ($(messageEl).hasClass('cw_message_expanded')) {
			$(messageEl).removeClass('cw_message_expanded').addClass('ellipsis');
		} else {
			$('#cw_groups_list').find('.k-grid-content').find('td.cw_message_expanded').removeClass('cw_message_expanded').addClass('ellipsis');
			$(messageEl).filter(function(){return $(this).width()>0}).addClass('cw_message_expanded').removeClass('ellipsis');
		}
	},
	/**
	 * Handler function for switching the checkbox with the treelist icon, for a nicer appearance
	 */
	switchCheckExpand: function () {
		this.treeList.wrapper.find('.cw_grid_check').each(function() {
			var current = $(this);
			var firstHierarchicalIcon = current.closest('td').find('.k-icon').first();
			$(firstHierarchicalIcon).css('margin-left', '10px');
			current.insertBefore(firstHierarchicalIcon);
		});
	},
	/**
	 * Removes listeners
	 */
	removeListeners: function () {
		$('#cw_groups_list').off();
		this.onAssetGroupSavedHandler = $.proxy(this.onAssetGroupSaved, this);
		LocalEventsManager.unbind('assetgroupsaved', this.onAssetGroupSavedHandler);
		$('body').off('click', '.cw_monitorname');
		$('body').off('click', '.cw_agentname');
	},
	/**
	 * Attaches listeners
	 */
	attachListeners: function () {
		/*setTimeout($.proxy(function() {
		 setInterval($.proxy(function() {
		 this.onEventsTimeout();
		 }, this), 4000);
		 }, this), 3000);*/
		let treeList = $('#cw_groups_list');
		treeList.on('click', '.cw_grid_check', $.proxy(this.onAssetGroupCheck, this));
		treeList.on('click', '.cw_grid_check_all', $.proxy(this.onAssetGroupCheckAll, this));
		treeList.on('click', '.cw_pillbox', $.proxy(this.onAssetGroupStateClick, this));
		treeList.on('click', '.cw_asset_group_details', $.proxy(this.onAssetGroupName, this));
		$('.cw_asset_group_details').on('click', $.proxy(this.onAssetGroupName, this));
		treeList.off('mouseover', '[data-monitorerror="true"]').on('mouseover', '[data-monitorerror="true"]', $.proxy(function (e) {
			Utils.onMonitorErrorOver(e, {
				dataSource: $(e.currentTarget).closest('.k-grid.k-widget').data('kendoCustomGrid').dataSource,
				toolTip: this.tooltipOverview
			});
		}, this));
		LocalEventsManager.bind('assetgroupsaved', this.onAssetGroupSavedHandler);
		$(window).off().on('resize', $.proxy(this.adjustSectionHeight, this));
	},
	/**
	 * Handler function for assetgroupsaved event
	 */
	onAssetGroupSaved: function () {
		this.treeListRefreshed = true;
		this.treeList.dataSource.read();
	},
	/**
	 * Handler function for the click event on the asset group name
	 * @param {Object} e The click event object
	 */
	onAssetGroupName: function (e) {
		e.preventDefault();
		let row = this.treeList.select();
		let assetGroup;
		if (!row.length) {
			assetGroup = this.treeList.dataSource.at(0);
		} else {
			assetGroup = this.treeList.dataItem(row);
		}
		State.mainApp.navigate(AssetGroupRouter.details(assetGroup.realId));
	},
	/**
	 * Opens the edit asset modal form
	 * @param {String} assetGroupId The id of the asset group to be edited
	 */
	openEditForm: function (assetGroupId, accountId) {
		var modalWindow;
		if (State.mainApp.session.hasRole('ASSET_UPDATE') || State.mainApp.session.hasRole('ASSET_READ')) {
			modalWindow = new ModalWindow({
				title: lang.account.ASSET_GROUP_EDIT,
				width: 800,
				height: 640,
				minHeight: 640,
				url: 'include/Administration/AssetGroupForm.jsp?assetGroupId=' + assetGroupId,
				onClose: function () {
					RemoteEventsManager.discard(assetGroupId);
				},
				refresh: $.proxy(function () {
					new AssetGroupForm({
						id: assetGroupId,
						accountId: accountId,
						mode: 'update'
					});
				}, this)
			});
			modalWindow.open();
		}
	},
	/**
	 * Handler for the asset checkbox click
	 * @param {Object} e The click event
	 */
	onAssetGroupCheck: function (e) {
		var count = this.treeList.wrapper.find('.cw_grid_check:checked').length;

		if (count) {
			this.gridMenu.enableItem('cw_delete');
			this.gridMenu.enableItem('cw_set_tags');
			if (count === 1) {
				this.gridMenu.enableItem('cw_edit');
			} else {
				this.gridMenu.disableItem('cw_edit');
			}
		} else {
			this.gridMenu.disableItem('cw_delete');
			this.gridMenu.disableItem('cw_set_tags');
			this.gridMenu.disableItem('cw_edit');
		}
	},
	/**
	 * Handler for the check all checkbox click
	 * @param {Object} e The click event
	 */
	onAssetGroupCheckAll: function (e) {
		var checkAll = this.treeList.wrapper.find('.cw_grid_check_all').is(':checked');
		var checkboxes = this.treeList.wrapper.find('.cw_grid_check');
		checkboxes.prop('checked', checkAll);
		if (checkAll) {
			this.gridMenu.enableItem('cw_delete');
			this.gridMenu.enableItem('cw_set_tags');
			if (checkboxes.length === 1) {
				this.gridMenu.enableItem('cw_edit');
			} else {
				this.gridMenu.disableItem('cw_edit');
			}
		} else {
			this.gridMenu.disableItem('cw_delete');
			this.gridMenu.disableItem('cw_set_tags');
			this.gridMenu.disableItem('cw_edit');
		}
	},
	onAssetGroupStateClick: function (e) {
		const target = $(e.currentTarget);
		const uid = target.closest('tr').data('uid');
		const record = this.treeListDataSource.getByUid(uid);
		State.mainApp.navigate(EventSummaryRouter.list({additionalQuery: {assetGroupIds: record.realId}}))
	},
	/**
	 * Handler for the asset edit button
	 */
	onEditAssetGroup: function () {
		var uid = this.treeList.wrapper.find('.cw_grid_check:checked').closest('tr').data('uid');
		var item = this.grid.dataSource.getByUid(uid);
		this.openEditForm(item.id, item.accountId);
	},
	/**
	 * Handler function for delete button
	 */
	onAssetGroupDelete: function (e) {
		var assetGroups = [];
		if (!$(e.currentTarget).hasClass('disabled')) {
			var checkboxes = $('#cw_groups_list').find('.cw_grid_check:checked');
			for (var i = 0, length = checkboxes.length; i < length; i++) {
				if ($(checkboxes[i]).is(':checked')) {
					assetGroups.push($(checkboxes[i]).data('realid'));
				}
			}
			this.assetGroupsLength = assetGroups.length;
			this.selectedAssetGroups = assetGroups;
			if (this.assetGroupsLength) {
				this.assetGroupIndex = 0;
				this.gridMenu.disableItem('cw_summary_edit');
				this.gridMenu.disableItem('cw_summary_delete');

				var dialog = new Dialog({
					title: lang.INFO,
					msg: lang.account.messages.ASSET_GROUP_REMOVE_CONFIRMATION,
					icon: 'ERROR',
					actionText: 'DELETE',
					fn: $.proxy(function (value, button) {
						if (button === 'ok') {
							kendo.ui.progress($('#cw_groups_list'), true);
							this.checkAssetDependencies();
						}
					}, this)
				});
				dialog.show();
			}
		}
	},
	/**
	 * Checks for asset dependencies
	 */
	checkAssetDependencies: function () {
		var assetGroupId = this.selectedAssetGroups[this.assetGroupIndex++];
		var record, assetGroupName, cb;
		if (assetGroupId) {
			var rowId = $('tr[data-realid='+assetGroupId+']').attr('data-id');
			record = this.treeListDataSource.get(rowId);
			this.assetGroupAccountName = record.accountName;
			this.assetGroupAccountId = record.accountId;
			this.selectedAssetGroupId = record.realId;
			assetGroupName = record.name;
			var dependenciesUrl = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/assetGroups/' + this.selectedAssetGroupId + '/dependencies';
			Utils.ajax(dependenciesUrl, 'GET', {}, $.proxy(function (data) {
				if (data.length) {
					this.openDependenciesPopup(data, assetGroupName, this.selectedAssetGroupId);
					setTimeout(this.adjustTableHeight, 100);
				} else {
					var deleteUrl = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/assetGroups/' + this.selectedAssetGroupId;
					Utils.ajax(deleteUrl, 'DELETE', {}, $.proxy(function (result) {
						if (result.success) {
							this.checkAssetDependencies();
						} else {
							this.resetCheckingAssets();
							Utils.showInfo(lang.ALERT, result.message, result.details);
						}
					}, this));
				}
			}, this));
		} else {
			kendo.ui.progress($('#cw_groups_list'), false);
			this.treeListRefreshed = true;
			this.treeListDataSource.read();

			if (this.dependenciesWindow) {
				this.dependenciesWindow.close();
			}
			this.actionNotification.setOptions({
				message: lang.account.messages.ASSETS_SUCCESS_DELETED,
				status: 'success'
			}).show();
		}
	},
	/**
	 * Resets the checking assets
	 */
	resetCheckingAssets: function () {
		this.selectedAssetGroups = [];
		this.assetGroupIndex = 0;
		kendo.ui.progress($('#cw_groups_list'), false);
		this.treeListRefreshed = true;
		this.treeList.dataSource.read();
	},
	/**
	 * Creates and opens the agent dependencies window
	 *
	 * @param {Array} dependencies - The asset group dependencies array
	 * @param {String} assetGroupName The asset group name
	 * @param {String} assetGroupId The id of the asset group
	 */
	openDependenciesPopup: function (dependencies, assetGroupName, assetGroupId) {
		if (this.dependencyWindow) {
			this.dependenciesWindow.close();
		}
		this.dependenciesWindow = new ModalWindow({
			title: lang.INFO,
			width: 960,
			height: 640,
			minWidth: 220,
			actions: [],
			url: 'include/Dependencies.jsp',
			//resize: this.adjustTableHeight,
			onClose: $.proxy(function () {
				this.clearCheckboxes();
			}, this),
			refresh: $.proxy(function () {
				$('#cw_dependencies_deleteall').removeClass('hide');
				this.dependencyWindow = new Dependencies({
					accountId: this.assetGroupAccountId,
					dependencies: dependencies,
					name: assetGroupName,
					container: '#cw_groups_list',
					infoTemplate: lang.account.messages.ASSET_GROUP_DELETE_INFO,
					deleteAll: $.proxy(function (e) {
						var deleteUrl = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/assetGroups/' + assetGroupId + '/dependencies/deleteAll';
						Utils.ajax(deleteUrl, 'DELETE', {}, $.proxy(function (result) {
							if (result.success) {
								this.checkAssetDependencies();
							} else {
								Utils.showInfo(lang.ALERT, result.message, result.details);
								this.resetCheckingAssets();
							}
						}, this));
					}, this)
				});
			}, this)
		});
		this.dependenciesWindow.open();
	},
	clearCheckboxes: function () {
		var checkAll = $('#cw_groups_list').find('.cw_grid_check_all');
		var checkboxes = $('#cw_groups_list').find('.cw_grid_check');
		var length = checkboxes.length;
		checkAll.prop('checked', false);
		for (var i = 0; i < length; i++) {
			$(checkboxes[i]).prop('checked', false);
		}
	},
	/**
	 * Method by Andy
	 */
	adjustSectionHeight: function () {
		var section = $('.cw_assethealth_module').find('.cw_section_full');
		var	sectionHeight = section.height();
		section.find('.cw_section_content').css('height', sectionHeight);
		section.find('.k-grid-content').css('height', sectionHeight - 40);
	},
	/**
	 * Saves user preferences
	 */
	saveUserPreferences: function () {
		if (this.assetsView) {
			this.assetsView.saveUserPreferences();
		}
		if (this.reasonsView) {
			this.reasonsView.saveUserPreferences();
		}
		this.saveUserPrefs({
			category: 'AssetGroupHealth',
			filterPanel: this.treeListFilterPanel,
			grid: this.treeList,
			eventsToolbar: this.eventsToolbar,
			keys: {
				searchPhrase: 'ahGroupSummarySearchPhrase',
				columns: 'ahGroupSummaryColumns',
				sort: 'ahGroupSummarySort',
				filter: 'ahGroupSummaryFilter',
				FsViews: 'ahGroupSummaryFSViews'
			},
			multipleGrids: true
		});
	},
	/**
	 * Loads user preferences
	 */
	loadUserPreferences: function () {
		this.userPref = [];
		UserPrefs.load('AssetGroupHealth', $.proxy(function (result) {
			if (result.success) {
				this.userPref = result.data;
				this.initComponent();
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},
	onEventsTimeout: function () {
		if(this.loadingError)
			return;

		this.treeListRefreshed = true;
		this.refreshFromEvents = true;
		this.cachedSelectedRow = this.selectedRow;
		this.treeList?.dataSource.read();
		let reasonsGrid = $('.cw_health_reasons_list').data('kendoCustomGrid');
		if (reasonsGrid) {
			reasonsGrid.dataSource.read();
		}
		$('#cw_group_assets_list').data('kendoCustomGrid')?.dataSource?.read();
	},
	/**
	 * Called when a event is received
	 */
	onEvent: function (event) {
		this.eventsToolbar.addEvents(event);
	},
	/**
	 * Subscribes to the events
	 */
	subscribe: function () {
		var subscriptionObj = [{
			eventType: 'AssetGroupHealth',
			accountId: Cookies.CeesoftCurrentAccountId,
			includeSubaccounts: State.includeSubaccounts
		}, {
			eventType: 'AssetHealth',
			accountId: Cookies.CeesoftCurrentAccountId,
			includeSubaccounts: State.includeSubaccounts
		}, {
			eventType: 'Administration',
			actionTypes: [
				'ASSET_GROUP_CREATE', 'ASSET_GROUP_UPDATE', 'ASSET_GROUP_DELETE',
				'ASSET_GROUP_MEMBER_CREATE', 'ASSET_GROUP_MEMBER_DELETE', 'ASSET_GROUP_MEMBER_UPDATE'
			],
			accountId: Cookies.CeesoftCurrentAccountId,
			includeSubaccounts: State.includeSubaccounts
		}];
		RemoteEventsManager.subscribe(this.subscriberId, subscriptionObj);
	},

	convertNameToFilter: function (name) {
		let filterObj = {};

		if(!name){
			return;
		}
		if (name && name.length) {
			filterObj = {
				filters: [],
				logic: 'or'
			};
			filterObj.filters.push({
				field: 'name',
				operator: 'eq',
				value: name
			})
		}
		return filterObj;
	},
	/**
	 * Handler function for converting the state from bucket to filter
	 * @param {String} state The current state
	 */
	convertStateToFilter: function (state) {
		let filterValue, filterObj = {};

		if( state == null ){
			state = []
		} else if( typeof state  === 'string'){
			state = [state];
		}

		if (state && state.length) {
			filterObj = {
				filters: [],
				logic: 'or'
			};

			for (let i = 0; i < state.length; i++) {
				switch (state[i].toUpperCase()) {
					case 'CRITICAL':
						filterValue = 3;
						break;
					case 'MAJOR':
						filterValue = 2;
						break;
					case 'MINOR':
						filterValue = 1;
						break;
					case 'OK':
						filterValue = 0;
						break;
					case 'DOWN':
						filterValue = -1;
						break;
					case 'WARNING':
						filterValue = -2;
						break;
					case 'MAINTENANCE':
						filterValue = -3;
						break;
					case 'NONE':
						filterValue = 'none';
						break;
				}
				if (filterValue === 'none') {
					filterObj.filters = [{
						field: 'assetIndicator',
						operator: 'neq',
						value: 3
					}, {
						field: 'assetIndicator',
						operator: 'neq',
						value: 2
					}, {
						field: 'assetIndicator',
						operator: 'neq',
						value: 1
					}, {
						field: 'assetIndicator',
						operator: 'neq',
						value: 0
					}];
					filterObj.logic = 'and';
				} else {
					filterObj.filters.push({
						field: 'assetIndicator',
						operator: 'eq',
						value: filterValue
					});
				}
			}
		} else {
			filterObj = {
				filters: [],
				logic: 'or'
			};
		}
		return filterObj;
	},
	onRowSelect() {
		if (!$(this.selectedRow[0]).is('tr')) {
			this.selectedRow = this.selectedRow.find('tbody > tr').first();
		}
		this.selectedRowId = this.selectedRow.attr('data-id');
		//make default white all td and color only the selected; apparently not working on tr for treeList
		$('#cw_groups_list').find('td').css('background-color', '#ffffff');
		this.selectedRow.find('td').css('background-color', '#e4f1f9');
		this.getRealId();
	},
	getRealId() {
		var realId;
		var groupId = this.selectedRow.find('.cw_grid_check').attr('data-id');
		var record = this.treeList.dataSource.get(groupId);
		if (record) {
			realId = record.realId;
			this.selectedAssetGroupName = record.name;
			var shownName;
			if (this.currentTab === 'assets') {
				shownName = lang.ASSETS;
			} else if (this.currentTab === 'reasons') {
				shownName = lang.REASONS;
			}
			if (record.name) {
				shownName += ' - ' + record.name;
			}
			$('.cw_current_assetgroup').text(shownName);
		}
		if (!realId) {
			realId = Utils.guid();
		}
		this.realId = realId;
	},
	refreshGridDatasources() {
		let assetsGrid = $('#cw_group_assets_list').data('kendoCustomGrid');
		assetsGrid.dataSource.options.transport.read.url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/health/assetGroups/' + this.realId + '/assets';
		assetsGrid.dataSource.read();
		let reasonsGrid = $('#cw_group_reasons_list').data('kendoCustomGrid');
		if (reasonsGrid) {
			reasonsGrid.dataSource.options.transport.read.url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/health/assetGroups/' + this.realId + '/reasons';
			reasonsGrid.dataSource.read();
		}
	},
	onAssetsTab(refreshGrid) {
		let title = lang.ASSETS;
		if (this.selectedAssetGroupName) {
			title += ' - ' + this.selectedAssetGroupName;
		}
		$('.cw_current_assetgroup').text(title);
		this.currentTab = 'assets';
		if (!this.assetsTabLoaded) {
			this.assetsView = new AssetHealthSummary({
				isFromAssetGroup: true,
				treeListHeight: this.treeListHeight,
				childMode: true,
				selectedAssetGroupId: this.realId
			});
		}
		this.assetsTabLoaded = true;

	},
	onReasonsTab(refreshGrid) {
		let title = lang.REASONS;
		if (this.selectedAssetGroupName) {
			title += ' - ' + this.selectedAssetGroupName;
		}
		$('.cw_current_assetgroup').text(title);
		this.currentTab = 'reasons';
		if (!this.reasonsTabLoaded) {
			this.reasonsView = new AssetHealthReasons({
				assetGroupId: this.realId,
				isFromAssetGroup: true,
				treeListHeight: this.treeListHeight,
				childMode: true
			});
		}
		this.reasonsTabLoaded = true;
	},
	/**
	 * Called when application is unloaded/destroyed
	 */
	destroy: function () {
		if(!this.isFromAssetGroupWidget) {
			this.saveUserPreferences();
		}
		if (this.eventsToolbar) {
			this.eventsToolbar.destroy();
		}
		Application.prototype.destroy.call(this);
	}
});
