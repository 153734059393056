import React, {useEffect} from "react";
import CeeviewNumericField from "controls/react/form/ceeviewNumericField";
import DropDownList from "controls/react/kendoWrappers/dropDownList";
import FormEntry from "controls/react/form/formEntry";
import {Field, FormSection, formValueSelector} from 'redux-form';
import {connect} from "react-redux";
import Label from "../label";
import translator from "core/localization/localization";
import Lang from 'core/localization/lang';

const i = translator({
  "message": {
    "no": "melding"
  }
});

class AlgoTypeComponent extends React.PureComponent {
	render() {
		const {input: {onChange, value}, disabled, meta, data, ...others} = this.props;

		if (!value && data[0]) {
			onChange(data[0].value);
		}

		return <DropDownList autoBind={true} onChange={onChange} value={value} dataSource={data} disabled={disabled} {...others} />
	}
}

export const AlgoOperator = (props) => {
	const operators = [
		{ text: Lang.EQ, value: 'EQ' },
		{ text: Lang.GE, value: 'GE' },
		{ text: Lang.GT, value: 'GT' },
		{ text: Lang.LE, value: 'LE' },
		{ text: Lang.LT, value: 'LT' },
		{ text: Lang.NE, value: 'NE' }
	];
	const {input: {onChange, value}, disabled, meta, ...others} = props;


	if (!value) {
		onChange('LE');
	}

	return <DropDownList autoBind={true} onChange={onChange} value={value} dataSource={operators} disabled={disabled} {...props} />
};

export const AlgoNumeric = (props) => {
	const {input: {onChange, value}, defaultValue, disabled, meta: {invalid}, decimals, ...others} = props;

	useEffect(() => {
		const {input: {value}, defaultValue} = props;

		if (value === undefined || value === "") {
			onChange(defaultValue || 100);
		}
	}, [])

	return <CeeviewNumericField onChange={onChange} value={+value} decimals={decimals !== undefined ? decimals: 2} invalid={invalid} {...others}/>
};

AlgoNumeric.defaultProps = {
	meta: {}
};

const AlgoFactor = (props) => {
	const {input: {onChange, value}, disabled, meta, ...others} = props;
	if (value === undefined) {
		onChange(0);
	}

	return <CeeviewNumericField onChange={onChange} value={+value} type='generic' min={1} max={10} enabled={!disabled} {...others}/>;
};

function getTypeDescription(type, data) {
	if (!data) {
		return;
	}

	for (const item of data) {
		if (item.value === type) {
			return item.description;
		}
	}
}

export class CEAlgorithm extends React.PureComponent {
	constructor(props) {
		super(props);

		this.validatePercentile = (value) => {
			if (value >= 0 && value <= 100) {
				return;
			}
			else if (!value) {
				return 'Field is required';
			}
			else {
				return 'Value is out of 0-100';
			}
		}

		this.validateDays = (value) => {
			if (value >= 1) {
				return;
			}
			else {
				return 'Value is out of >= 1';
			}
		}
	}

	render () {
    	const {algorithm, disabled, namespace, data} = this.props;

    	if (!data) {
    		return null;
		}

		let type;

    	if (algorithm && algorithm.type) {
    		type = algorithm.type;
		} else {
    		type = data[0].value;
		}

		let showOperator = false;
    	let showThreshold = false;
    	let showFactor = false;
		let showMin = false;
		let showMax = false;
		let showDays = false;
		let showPercentile = false;

		switch(type) {
			case 'StaticThresholdAlgorithm':
			case 'DeltaAlgorithm':
				showOperator = true;
				showThreshold = true;
				break;
			case 'DynamicThresholdAlgorithm':
				showFactor = true;
				break;
			case 'RangeAlgorithm':
				showMin = true;
				showMax = true;
				break;
			case 'PercentileAlgorithm':
				showDays = true;
				showPercentile = true;
				break;
		}

        return <FormSection name={namespace || "algorithm"}>
			<FormEntry label={<Label caption={Lang.designer.ALGORITHM} tooltip={getTypeDescription(type, data)} />}>
				<Field name="type" component={AlgoTypeComponent} disabled={disabled} data={data}/>
			</FormEntry>
			{showOperator && <FormEntry label={<Label caption={Lang.designer.OPERATOR} tooltip={i('message')} />}>
				<Field name="operator" component={AlgoOperator} disabled={disabled} />
			</FormEntry>}
			{showThreshold && <FormEntry label={Lang.designer.THRESHOLD}>
				<Field name="threshold" component={AlgoNumeric} defaultValue={100} disabled={disabled}/>
			</FormEntry>}
			{showFactor && <FormEntry label={Lang.designer.FACTOR}>
				<Field name="factor" component={AlgoFactor} disabled={disabled} decimals={0}/>
			</FormEntry>}
			{showMin && <FormEntry label={Lang.MIN}>
				<Field name="min" component={AlgoNumeric} disabled={disabled} />
			</FormEntry>}
			{showMax && <FormEntry label={Lang.MAX}>
				<Field name="max" component={AlgoNumeric} disabled={disabled} />
			</FormEntry>}
			{showPercentile && <FormEntry label={Lang.PERCENTILE}>
				<Field name="percentile" component={AlgoNumeric} disabled={disabled} type={"percent"} defaultValue={95} validate={this.validatePercentile} />
			</FormEntry>}
			{showDays && <FormEntry label={Lang.DAYS}>
				<Field name="days" component={AlgoNumeric} disabled={disabled} decimals={0} defaultValue={1} validate={this.validateDays} />
			</FormEntry>}
		</FormSection>
    }
}

const mapStateToProps = (state, ownProps) => {
	const selector = formValueSelector(ownProps.meta.form);

	return {
		algorithm: selector(state, `properties.algorithm`)
	}
};
export default connect(mapStateToProps, null)(CEAlgorithm);

const warningMapStateToProps = (state, ownProps) => {
	const selector = formValueSelector(qualifierWizardForm);

	return {
		algorithm: selector(state, `properties.warning`),
		namespace: 'warning'
	}
};

export const WarningCEAlgorithmset = connect(warningMapStateToProps, null)(CEAlgorithm);
