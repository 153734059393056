export default function getDiskStatus(data) {
	var status;

	if (data.enabled) {
		if (data.metrics.length) {
			status = 'custom';
		} else {
			status = 'default';
		}
	} else {
		status = 'none';
	}

	return status;
}
