import Utils from 'tools/utils';
import Cookies from 'core/cookies';

/**
 * @class ceeview.controls.TimeRange
 * This class creates time range
 * control
 * @namespace ceeview.controls
 * @constructor
 * @param {Object} config The configuration object
 */
export default function TimeRange(config) {
	Utils.apply(this, config);

	this.initComponent();
};

TimeRange.prototype = {
	/*
	 * @cfg {String} renderTo (Mandatory). jQuery selector for rendering the content
	 */
	renderTo: '',
	/*
	 * @cfg {String} type (Optional). periods kendo component types, can be: date, dateTime.
	 */
	type: 'date',
	/*
	 * @cfg {String} class (Optional). Css class for styling the container.
	 */
	'class': '',
	/*
	 * @cfg {String} style (Optional). Css style for styling the container.
	 */
	'style': '',
	/*
	 * @cfg {String} toggleElement (Mandatory). jQuery selector for clickable element which will make the container to appear
	 */
	toggleElement: '',
	/*
	 * @cfg {Boolean} hideOnClick (Optional). Boolean value for hiding the container after toggleElement is clicked
	 */
	hideOnClick: true,
	/*
	* @cfg (Date) fromValue (Optional). Default value of the FROM component
	*/
	fromValue: '',
	/*
	 * @cfg (Date) toValue (Optional). Default value of the TO component
	 */
	toValue: '',
	/**
	 * Main init class
	 */
	initComponent: function () {
		this.renderTo.append('<div class="cw_timerange_box cw_open_content"><input class="cw_time_range_from"><input class="cw_time_range_to"><button class="k-button right cw_reset_range_element">' + lang.RESET + '</button><button class="k-button k-primary right cw_submit_range_element">' + lang.SELECT + '</button></div>');
		if (this['class']) {
			this.renderTo.addClass(this['class']);
		}
		if (this['style']) {
			this.renderTo.attr('style', this['style']);
		}
		this.removeListeners();
		this.attachListeners();
		this.initKendoComponents();
	},
	/**
	 * Initialize Kendo UI controls
	 */
	initKendoComponents: function () {
		if (this.type === 'date') {
			this.from = this.renderTo.find('.cw_time_range_from').kendoDatePicker({
				format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
				timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserDateTimeFormat),
				value: this.fromValue || new Date()
			}).data('kendoDatePicker');

			this.to = this.renderTo.find('.cw_time_range_to').kendoDatePicker({
				format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
				timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserDateTimeFormat),
				value: this.toValue || new Date()
			}).data('kendoDatePicker');
		} else {
			this.from = this.renderTo.find('.cw_time_range_from').kendoDateTimePicker({
				format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
				timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserDateTimeFormat),
				value: this.fromValue || new Date()
			}).data('kendoDateTimePicker');

			this.to = this.renderTo.find('.cw_time_range_to').kendoDateTimePicker({
				format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
				timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserDateTimeFormat),
				value: this.toValue || new Date()
			}).data('kendoDateTimePicker');
		}
	},
	/**
	 * Handler for clicking toggleElement which makes the container to appear
	 * @param {Object} e The operation event
	 */
	toggleElementClick: function (e) {
		e.stopPropagation();
		this.renderTo.find('.cw_timerange_box').toggleClass('is_open');
	},
	/**
	 * Handler for clicking submit element
	 * @param {Object} e The operation event
	 */
	onSubmitElementClick: function (e) {
		if (this.hideOnClick) {
			this.toggleElementClick(e);
		}
		if (typeof this.onSubmit === 'function') {
			this.onSubmit.call(this, e, this.from.value(), this.to.value());
		}
	},
	/**
	 * Handler for clicking reset element
	 * @param {Object} e The operation event
	 */
	onResetElementClick: function (e) {
		if (this.hideOnClick) {
			this.toggleElementClick(e);
		}
		if (typeof this.onReset === 'function') {
			this.onReset.call(this, e);
		}
	},
	/**
	 * Handler for clicking cancel element
	 * @param {Object} e The operation event
	 */
	onCancelElementClick: function (e) {
		this.toggleElementClick(e);

		if (typeof this.onCancel === 'function') {
			this.onCancel.call(this, e);
		}
	},
	/**
	 * Attaches listeners
	 */
	attachListeners: function () {
		this.toggleElement.on('click', $.proxy(this.toggleElementClick, this));
		this.renderTo.on('click', '.cw_submit_range_element', $.proxy(this.onSubmitElementClick, this));
		this.renderTo.on('click', '.cw_reset_range_element', $.proxy(this.onResetElementClick, this));
		this.renderTo.on('click', '.cw_cancel_range_element', $.proxy(this.onCancelElementClick, this));
	},
	/**
	 * Removes listeners
	 */
	removeListeners: function () {
		this.toggleElement.off('click');
		this.renderTo.off('click', '.cw_submit_range_element');
		this.renderTo.off('click', '.cw_reset_range_element');
		this.renderTo.off('click', '.cw_cancel_range_element');
	},
	/**
	 * Destroy method
	 */
	destroy: function () {
		this.removeListeners();

		this.from.destroy();
		this.to.destroy();
		this.renderTo.empty();
	}
};