import React from 'react';
import PropTypes from 'prop-types';
import {b} from './common';
import Lang from "core/localization/lang";
import DateTimePicker from './dateTimePicker'
import {SET_START_TIME} from "./reducer";

function StartTime(props) {
	const onChange = React.useCallback((value) => {
		props.dispatch({type: SET_START_TIME, value})
	}, []);

	return <div className={b('row')}>
		<div className={b('label')}>
			{Lang.rulegenerator.START}
		</div>
		<DateTimePicker value={props.dtstart} onChange={onChange} />
	</div>;
}

StartTime.propTypes = {
	dtstart: PropTypes.instanceOf(Date),
	dispatch: PropTypes.func.isRequired
};

export default StartTime;
