import {Localization} from 'core/localization';

export const sharedLocalization = new Localization({
  "Summary": {
    "no": "Oversikt"
  },
  "History": {
    "no": "Historie"
  },
  "Selective Services": {
    "no": "Utvalgte tjenester"
  },
  "Single graph": {
    "no": "Enkel graf"
  },
  "Multigraph (Asset)": {
    "no": "Multigraf (ressurs)"
  },
  "Multigraph (AssetGroup)": {
    "no": "Multigraf (ressursgruppe)"
  },
  "Stacked Chart": {
    "no": "Stablet graf"
  },
  "Experimental": {
    "no": "Eksperimentell"
  },
  "Console": {
    "no": "Konsoll"
  },
  "Event summary": {
    "no": "Hendelseoversikt"
  },
  "Web page": {
    "no": "Nettside"
  },
  "Cloud": {
    "no": "Sky"
  },
  "Multi graph": {
    "no": "Multigraf"
  },
  "Grid": {
    "no": "Tabell"
  },
  "Bar chart": {
    "no": "Søylediagram"
  },
  "Cost": {
    "no": "Kostnad"
  },
  "Instances": {
    "no": "Instanser"
  },
  "Time widget": {
    "no": "Tidswidget"
  },
  "Edit widget": {
    "no": "Rediger widget"
  },
  "Table": {
    "no": "Tabell"
  },
  "Cost profile": {
    "no": "Kostprofil"
  },
  "Instances grid": {
    "no": "Instanser tabell"
  },
  "Linear gauge": {
    "no": "Lineær måler"
  },
  "Bubble chart": {
    "no": "Boblediagramm"
  },
  "GPS": {
    "no": "GPS"
  },
  "GPS widget": {
    "no": "GPS widget"
  },
  "Widget could not be loaded": {
    "no": "Widgeten ble ikke lastet"
  },
  "Cost grid": {
    "no": "Kosttabell"
  },
  "Data source": {
    "no": "Datakilde"
  },
  "Display settings": {
    "no": "Visningsinnstillinger"
  },
  "Cloud budget": {
    "no": "Sky budsjett"
  },
  "Spent": {
    "no": "Brukt"
  },
  "Spent %": {
    "no": "Brukt %"
  },
  "Profile": {
    "no": "Profil"
  },
  "Chart settings": {
    "no": "Graf innstillinger"
  },
  "Event state": {
    "no": "Hendelse Tilstand"
  },
  "Currency": {
    "no": "Valuta"
  }
});
