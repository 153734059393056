import { VIRTUAL_DEPENDENCIES } from "../../constants";

export const populateAllDependencies = (key, map, deps) => {
    let out = [];

    for (let dep of deps) {
        out.push(dep);

        if (!map[dep]) {
            continue;
        }

        out = [...out, ...populateAllDependencies(dep, map, map[dep])]
    }

    if (VIRTUAL_DEPENDENCIES.has(key)) {
        out = [...out, ...VIRTUAL_DEPENDENCIES.get(key)];
    }

    return out;
};
