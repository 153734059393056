import {ModelValidator, ValidatableModel} from "framework/mobx-integration";
import moment, {Moment} from "moment";
import {ModelType} from "areas/cost/budget/budgetTypes";
import {autorun, makeAutoObservable, reaction} from "mobx";
import {isOneOf} from "tools/utils";
import {CostsApi, list} from "areas/cost/api";
import {costTranslates} from "areas/cost/translations";
import {apiFetch, HttpStatus} from "framework/api";
import {ApplicationState} from "framework/applicationState";
import {CostProfile} from "../models/costProfile";

const i = require('core/localization/localization').translator(costTranslates);

type CreationType = 'NEW' | 'COST_COLLECTOR' | 'DUPLICATE';

export class NewBudgetModel implements ValidatableModel<NewBudgetModel> {
	name: String;
	description: String;
	currency: String;
	startDate: Moment = NewBudgetModel.defaultDate();

	creationType: CreationType = 'NEW';
	linkAccountId: string;
	accountId: string;
	costModelId: string;
	cloudConnection: string;

	accountCostModels: any[] = [];
	costConnectionModels: any[] = [];

	validator = new ModelValidator(this);
	profiles: CostProfile[];

	profileType: string;

	constructor(profileType: string) {
		makeAutoObservable(this);

		this.profileType = profileType;
		this.accountId = ApplicationState.accountId;
		this.loadProfiles();
		this.validator
			.required('name', () => isOneOf(this.creationType, ['NEW', 'DUPLICATE']))
			.required('currency')
			.required('startDate')
			.required('linkAccountId', () => this.creationType !== 'NEW')
			.required('costModelId', () => !isOneOf(this.creationType, ['NEW', 'COST_COLLECTOR']))
			.required('cloudConnection', () => this.creationType === 'COST_COLLECTOR')
			.required('accountId', () => this.creationType !== 'COST_COLLECTOR')
			.add('name', this.validateName, i('Name exists'));

		autorun(() => {
			if (this.creationType !== 'NEW' && this.linkAccountId) {
				this.loadCostModelsForAccount();
				this.loadCloudConnections();
			}
		});

		reaction(() => this.creationType, (value) => {
			this.cloudConnection = null;
			this.linkAccountId = null;
		});

		reaction(() => this.linkAccountId, (value) => this.cloudConnection = null);
	}

	static defaultDate(): Moment {
		const local = moment().startOf('year');
		return moment(local).utc().add(local.utcOffset(), 'm');
	}

	get valid(): boolean {
		return this.validator.valid;
	}

	// TODO: fix window height resizing to content
	get windowHeight(): number {
		if (this.creationType === 'DUPLICATE') return 500;
		if (this.creationType === 'NEW') return 410;
		return 498;
	}

	async loadCostModelsForAccount() {
		const result = await CostsApi.costModelList(this.linkAccountId);
		this.accountCostModels = result.data || [];
	}

	async loadCloudConnections() {
		const result = await CostsApi.cloudConnections(this.linkAccountId);
		this.costConnectionModels = result.data;
	}

	get filteredCostModelsDataList() {
		return this.accountCostModels.filter(this.costModelFilter)
			.map((cost: { id: string, name: string, modelType: string }) => ({
				name: `${cost.name} (${cost.modelType})`,
				id: cost.id
			}));
	}

	get costConnectionModelsDataList() {
		return this.costConnectionModels
			.map((connection: { id: string, name: string, modelcostModelIds: string[] }) => ({
				name: connection.name,
				id: connection.id
			}));
	}

	get costModelLabel() {
		if (this.creationType === 'COST_COLLECTOR') {
			return i('Costmodel');
		}
		return i('Duplicate from');
	}

	validateName = () => {
		const currentName = this.name?.trim()?.toLowerCase();
		if (!currentName)
			return false;

		return !this.profiles?.some(x => x.name.trim().toLowerCase() == currentName);
	}

	private get costModelFilter(): (cost: any) => boolean {
		if (this.creationType === 'COST_COLLECTOR') {
			return (c: { modelType: ModelType, costProfileId: string }) => {
				return c.costProfileId === null;
			}
		}
		return () => true;
	}

	loadProfiles = async () => {
		const result = await apiFetch(list(ApplicationState.accountId, this.profileType));
		if (result.status === HttpStatus.Ok) {
			this.profiles = result.data;
		}
	}
}
