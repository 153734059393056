export default {
	installedMonitors: [],
	installedMonitorsNames: {},
	installedMonitorSettings: {},
	tagsEnabled: false,
	highcharts: {
		exporting: {
			buttons: {
				contextButton: {
					enabled: false
				},
				printButton: {
					enabled: false
				},
				exportButton: {
					enabled: false
				}
			},
			enabled: false
		}
	}
};

