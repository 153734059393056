import './window.less';

import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from "react-dom";
import _ from 'lodash';

import {IconButton} from "controls/react/form/iconButton";


const skipActions = [
	"Pin",
	"Minimize",
	"Maximize",
	"Close"
];

export class Window extends React.Component {
	constructor(props){
		super(props);
		this.ref = React.createRef();
		this.containerRef = React.createRef();
		this.initialized = false;
		this.initWaitingCallbacks = [];
	}

	render(){
		//external div here is required because kendo deletes root element when window.destroy is called
		return (
			<div ref={this.containerRef}>
				<div ref={this.ref}>
					{this.props.children}
				</div>
			</div>
		);
	}

	setSpinner() {
		if(this.window?.element)
			kendo.ui.progress(this.window?.element, this.props.spinner);
	}

	async waitForInitialization() {
		if (this.initialized) {
			return;
		}
		return new Promise((resolve, _reject) => {
			this.initWaitingCallbacks.push(resolve);
		});
	}

	initializationFinished() {
		this.initialized = true;
		this.initWaitingCallbacks?.forEach(x => x());
	}

	async componentDidMount() {
		const {width, height, modal, title, onClose, onMove, onResize, onInit, resizable, actions, maxHeight, position, scrollable} = this.props;

		onInit && this.initWaitingCallbacks.push(onInit);

		const config = {
			width,
			height,
			modal,
			title,
			maxHeight,
			scrollable,
			position,
			close: onClose,
			dragend: onMove,
			resize: onResize,
			resizable,
			actions: actions || ['Close']
		};

		await addStateStorage(config, this.props.loadState, this.props.saveState);

		const {top, left} = this.props;

		if (top !== undefined && left !== undefined) {
			config.position = {
				top,
				left
			};
		}

		this.window = $(this.ref.current).kendoWindow(config).data('kendoWindow');

		if(this.props.windowContainerRef){
			this.props.windowContainerRef.current = this.ref.current;
		}

		if(this.props.contentClass){
			this.window.wrapper.find('.k-window-content').addClass(this.props.contentClass);
		}

		if(this.props.containerClass){
			this.window.wrapper.addClass(this.props.containerClass);
		}

		if (!config.position) {
			this.window.center();
		}

		if(this.props.zIndex) {
			this.window.wrapper.closest('.k-window').css('z-index', this.props.zIndex);
			$('.k-overlay').css('z-index', this.props.zIndex - 1);
		}


		if(this.props.minimized){
			this.window.minimize();
		}

		this.window.wrapper.addClass(this.props.className);

		this.props.actions && this.props.actions.map((action) => {
			if (skipActions.includes(action)) {
				return;
			}

			const iconClass = `.k-i-${action.toLowerCase()}`;
			const func = this.props[`on${action}`];

			func && this.window.wrapper.find(iconClass).parent().click((e) => {
				e.preventDefault();
				this.props[`on${action}`](e);
			});
		});

		if (this.props.kendoRef) {
			this.props.kendoRef(this.window.wrapper);
		}
		if (this.props.windowRef) {
			this.props.windowRef(this.window);
		}

		//So container div is not messing with layout.
		//Putting this style directly on tag in render stops window from working
		this.containerRef.current.style.display = 'none';
		this.setSpinner();

		if (this.props.titleIcon) {
			this.addTitleIcon();
		}

		if (this.props.capitalizeTitle) {
			this.capitalizeTitle();
		}

		this.initializationFinished()
	}

	async componentDidUpdate(prevProps) {
		await this.waitForInitialization();
		this.setSpinner();

		if( prevProps.title != this.props.title){
			this.window.setOptions({title: this.props.title});
		}

		if( prevProps.width != this.props.width){
			this.window.setOptions({width: this.props.width});
			if (!this.props.notCenter) {
				this.window.center();
			}
		}

		if(_.difference(prevProps.actions, this.props.actions).length != 0){
			this.window.setOptions({
				actions: this.props.actions
			})
		}

		if(prevProps.titleIcon != this.props.titleIcon){
			if (this.props.titleIcon) {
				this.addTitleIcon();
			} else {
				this.removeTitleIcon();
			}
		}

		if(prevProps.height != this.props.height){
			this.window.setOptions({height: this.props.height});
			if (!this.props.notCenter) {
				this.window.center();
			}
		}
	}

	componentWillUnmount(){
		this.window?.destroy();
	}

	isOpened = () => {
		return !this.window.element.is(":hidden")
	}

	toggle = () => {
		if( this.isOpened() )
			this.window.close();
		else
			this.window.open();
	}

	addTitleIcon() {
		let wrapper = this.window.wrapper;
		let titleBar = wrapper.find('.k-window-titlebar');
		titleBar.append('<div class="window-title-icon"></div>');
		ReactDOM.render(
			<IconButton iconName={this.props.titleIcon.class}
						title={this.props.titleIcon.tooltip}
						embedded/>, $('.window-title-icon')[0]);

	}

	removeTitleIcon() {
		ReactDOM.unmountComponentAtNode($('.window-title-icon')[0]);
	}

	capitalizeTitle() {
		let wrapper = this.window.wrapper;
		let title = wrapper.find('.k-window-title');
		title.addClass('capitalize_title');
	}
}

Window.propTypes = {
	spinner: PropTypes.bool,
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	actions: PropTypes.arrayOf(PropTypes.string),
	modal: PropTypes.bool,
	title: PropTypes.any,
	onClose: PropTypes.func,
	onMove: PropTypes.func,
	onResize: PropTypes.func,
	onInit: PropTypes.func,
	containerClass: PropTypes.string,
	contentClass: PropTypes.string,
	resizable: PropTypes.bool,
	notCenter: PropTypes.bool,
	scrollable: PropTypes.bool,
	loadState: PropTypes.func,
	saveState: PropTypes.func
};

Window.defaultProps = {
	spinner: false,
	width: 800,
	onMove: () => {},
	onResize: () => {},
	onClose: () => {},
	resizable: true
}

export async function addStateStorage(config, loadState, saveState){
	if (!loadState || !saveState)
		return;

	const state = await loadState();

	const originalVisible = config.visible;
	Object.assign(config, state);
	if(originalVisible !== undefined){
		config.visible = originalVisible;
	}

	const deactivateOriginal = config.deactivate;
	config.deactivate = async e => {
		deactivateOriginal && await deactivateOriginal(e);
		let state = await loadState();
		await saveState({
			...state,
			visible: false,
		});
	}

	const activateOriginal = config.activate;
	config.activate = async e => {
		activateOriginal && await activateOriginal(e);
		let state = await loadState();
		await saveState({
			...state,
			visible: true,
		});
	}

	const debouncedSaveState = _.debounce(saveState, 1000);

	const originalDragend = config.dragend;
	config.dragend = async e => {
		originalDragend && await originalDragend(e);
		await debouncedSaveState({
			visible: true,
			width: e.sender.wrapper.width(),
			height: e.sender.wrapper.height(),
			position: e.sender.wrapper.position(),
		});
	}

	const resizeOriginal = config.resize;
	config.resize = async e => {
		resizeOriginal && await resizeOriginal(e);
		await debouncedSaveState({
			visible: true,
			width: e.sender.wrapper.width(),
			height: e.sender.wrapper.height(),
			position: e.sender.wrapper.position(),
		});
	}
	return config;
}

export default Window;
