import './assetWindow.less'

import React from 'react';
import ReactDOM from 'react-dom';

import {addLinks} from "core/react/links";
import {translator} from "core/localization/localization";
import {Cookies} from 'core/cookies';

import {GridMenu} from "controls/gridMenu";
import {Window} from 'controls/react/kendoWrappers'
import {MenuButton} from "controls/react";
import {FormEntry, Grid, TextBox} from "controls/react/form";
import {Toolbar, ToolbarItemPosition} from 'controls/react/layout';
import {Section} from 'controls/react/layout/section';
import {FormLayout} from 'controls/react/layout/formLayout';
import AccountDropDown from "controls/react/dropdowns/accountDropDown";
import {BoxView} from "controls/react/layout/boxView";

import {AssetsApi, AssetGroupsApi} from "api";
import {fullTextSearch} from "controls/react/kendoWrappers/grid";
import {Utils} from "tools";
import {
	getDesignerWindowState,
	setDesignerWindowState
} from "controls/designer/extensions/helperDesignerWindowPositions";
import {sharedLocalization} from 'areas/assets/helpers';
import Button from "controls/react/form/button";

const i = translator({
	'Filtering on assetgroup {0}. Click here to clear filter.': {
		no: "Filter for ressursgruppe {0}. Klikk her for å fjerne filteret."
	},
	'Click and drag onto canvas': {
		no: "Klikk å dra ut i designeren"
	}
},sharedLocalization);

const b = require('b_').with('assets-window');

@addLinks
class AssetsWindowBase extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			accountId: Cookies.CeesoftCurrentAccountId
		}

		this.gridColumns = this.getGridColumns();
		this.gridRef = React.createRef();
	}

	render() {
		const accountLink = this.linkState('accountId')
			.changed(value => {
				this.load();
			});

		const searchLink = this.linkState('searchValue')
			.changing(({rootStore}) => rootStore.searchFilter = fullTextSearch(['name'], rootStore.searchValue));

		return (
			<Window ref={r => this.window = r}
			        title={this.getWindowTitle()}
			        actions={["minimize", "close"]}
			        windowRef={this.props.windowRef}
			        contentClass={b()}
			        saveState={state => setDesignerWindowState(this.props.designer,this.getWindowName(), state)}
			        loadState={() => getDesignerWindowState(this.props.designer, this.getWindowName())}>
				<Toolbar>
					<Button title={i('Add')}
					        primary={true}
					        onClick={() => this.addEntriesToDesigner()}
					        position={ToolbarItemPosition.BEFORE_TITLE}
					/>
					<TextBox {...searchLink.props} placeholder={i('Search...')}/>
				</Toolbar>

				<Section childrenPadding={true}>
					{this.state.informationMessage == null &&
						<FormEntry label={i("Account")}>
							<AccountDropDown {...accountLink.props}/>
						</FormEntry>
					}
					{this.state.informationMessage != null &&
						<BoxView onClick={this.onInformationMessageClicked}
						         type={"info"}
						         rounded={true}
						         border={true}>
							{this.state.informationMessage}
						</BoxView>
					}
				</Section>
				<Grid dataSourceArray={this.state.entries}
				      filter={this.state.searchFilter}
				      columns={this.gridColumns}
				      fit
				      selectable={"row"}
				      change={this.gridRowSelected}
				      onDragStarted={this.setDragData}
				      dragTooltip={i('Click and drag onto canvas')}
				      ref={this.gridRef}/>
			</Window>
		);
	}

	componentDidMount() {
		this.load();
	}

	setDragData = (e, dataItem) => {
		e.dataTransfer.setData('designer/new-entry', JSON.stringify(this.getDataForDragAndDrop(dataItem)));
		this.props.hideInfoMessage();
	}

	getGridColumns() {
		return [{
			field: 'name',
			title: this.name,
			editable: false
		}, {
			field: 'type', //not a real field.
			title: i('Type'),
			template: item => {
				return item.assetType || item.groupType && i(item.groupType)
			},
			editable: false
		}];
	}

	addEntriesToDesigner = () => {
		const assetsOrGroups = this.gridRef.current.getCheckedItems();

		const graph = this.props.designer.graph;

		const model = graph.getModel();
		model.beginUpdate();
		try {
			mxUtils.placeElementsInGrid(assetsOrGroups, new mxGeometry(20, 20, 50, 50), 10, 100, 80, (assetOrGroup, position) => {
				const entity = this.generateEntityToInsert(assetOrGroup);
				graph.insertEntity( entity, position );
			} );
		} finally {
			model.endUpdate();
		}
	}

	getDataForDragAndDrop(draggedEntry) {
		//the item user is dragging might not be checked
		//but we still need to drag into layout all checked items plus the one user is currently dragging
		let checkedItems = this.gridRef.current.getCheckedItems();
		if(!checkedItems.find(x => x.id == draggedEntry.id)) {
			checkedItems.push(draggedEntry.toJSON());
		}
		return checkedItems.map( entry => this.generateEntityToInsert(entry));
	}

	gridRowSelected = sender => {

	}

	isOpened = () => {
		return this.window.isOpened();
	}

	toggle = () => {
		this.window.toggle();
	}
}

export class AssetsWindow extends AssetsWindowBase{
	async load() {
		let entries = [];

		if(!this.state.filterByAssetGroup) {
			entries = await AssetsApi.getAssetsLite({accountId: this.state.accountId});
		}else{
			entries = await AssetGroupsApi.getGroupMembers(this.state.filterByAssetGroup.id);
		}

		this.setState({
			entries
		});
	}

	generateEntityToInsert(asset) {
		return {
			userData: {
				applicationMode: 'true',
				datasource: {
					type: 'asset',
					showAsLabel: true,
					assets: [{
						id: asset.id,
						accountId: this.state.accountId
					}]
				}
			},
			additionalStyles:  mxConstants.STYLE_FONTCOLOR + '=' + Constants.BLUE + ';'
		}
	}

	getWindowTitle(){
		return i('Asset');
	}

	getWindowName(){
		return 'assets';
	}

	setAssetGroupFilter = assetGroup => {
		if(assetGroup == null){
			this.onInformationMessageClicked()
		}else {
			this.setState({
				informationMessage: i('Filtering on assetgroup {0}. Click here to clear filter.', assetGroup.name),
				filterByAssetGroup: assetGroup
			}, () => this.load());
		}
	}

	onInformationMessageClicked = () => {
		this.setState({
			informationMessage: null,
			filterByAssetGroup: null
		}, () => this.load())
	}
}

export class AssetGroupsWindow extends AssetsWindowBase{
	async load() {
		let result = await AssetGroupsApi.getGroups(this.state.accountId);

		this.setState({
			entries: result
		});
	}

	generateEntityToInsert(assetGroup) {
		return {
			userData: {
				applicationMode: 'true',
				datasource: {
					type: 'assetGroup',
					assetGroups: [{
						id: assetGroup.id,
						accountId: this.state.accountId,
						showAsLabel: true
					}]
				}
			},
			additionalStyles: mxConstants.STYLE_FONTCOLOR + '=' + Constants.BLUE + ';'
		}
	}

	getWindowTitle(){
		return i('Asset group');
	}

	getWindowName(){
		return 'assetGroups';
	}

	gridRowSelected = ev => {
		let items = ev.sender.select();
		let dataItem = null;
		if(items.length == 1){
			dataItem = ev.sender.dataItem(items[0]);
		}

		this.props.onItemSelected && this.props.onItemSelected(dataItem.toJSON());
	}
}
