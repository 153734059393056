import Utils from 'tools/utils';
import Widget from 'areas/service-boards/widget';
import Settings from 'settings';
import CustomNotification from 'controls/customNotification';
import RemoteEventsManager from 'core/remoteEventsManager';
import State from 'tools/state';
import ChunkProgressBar from 'controls/chunkProgressBar';
import Api from 'tools/api';
import Cookies from 'core/cookies';
import Renderer from 'tools/renderer';
import {ApplicationsRouter} from "areas/application/bundleDescription";
import {translator} from 'core';

const i = translator({});

export function StateWidget(config) {
	Widget.call(this, config);

	this.subscriptionId = null;
	this.requestPath = Settings.serverPath;
	if (this.sessionId) {
		this.requestPath = Settings.serverPath + 'sessions/' + this.sessionId + '/';
	}
	this.configuration.includeSubaccounts = this.configuration.includeSubaccounts || false;
	this.initComponent();
};

export {StateWidget as default};

jQuery.extend(StateWidget.prototype, Widget.prototype, {
	/**
	 * Main init function
	 */
	initComponent: function () {
		this.widgetHandler = $('#' + this.id);
		this.renderContent();
		this.removeListeners();
		this.attachListeners();
		this.statusNotification = new CustomNotification({
			animationTime: 0,
			appendToElement: this.widgetHandler,
		});
	},
	/**
	 * Attaches listeners
	 */
	attachListeners: function () {
		if (!this.sessionId && State.currentApp?.dashboardDesigner?.props?.mode !== 'designer') {
			this.widgetHandler.on('click', '.cw_bar, .cw_progress_bar', $.proxy(this.onApplicationHealthClick, this));
		}
	},
	/**
	 * Removes listeners
	 */
	removeListeners: function () {
		this.widgetHandler.off();

	},
	/**
	 * Renders widget content
	 */
	renderContent: function () {
		var html = '';
		html += '<div class="cw_account_application_chart cw_bars_chart">';
		html += '<div class="cw_bars cw_account_health_bars">';
		html += '<div class="cw_bar is_critical no_bg"><span class="value">0</span><span class="legend">' + lang.CRITICAL + '</span></div>';
		html += '<div class="cw_bar is_major no_bg"><span class="value">0</span><span class="legend">' + lang.MAJOR + '</span></div>';
		html += '<div class="cw_bar is_minor no_bg"><span class="value">0</span><span class="legend">' + lang.MINOR + '</span></div>';
		html += '<div class="cw_bar is_ok no_bg"><span class="value">0</span><span class="legend">' + lang.OK + '</span></div>';
		html += '<div class="cw_bar is_idle no_bg"><span class="value">0</span><span class="legend">' + lang.INACTIVE + '</span></div>';
		html += '</div>';
		html += '<div class="cw_progress"></div>';
		html += '</div>';
		this.widgetHandler.find('.cw_section_content').empty().append(html);
		if (this.isDashboard) {
			this.widgetHandler.find('.cw_progress').addClass('cw_dashboard_progress');
		}

		this.subscribe();
		this.renderApplicationChart();
	},
	/**
	 * Populates the Service Health section
	 */
	renderApplicationChart: async function () {
		let health;
		let tags = this.configuration.tags;
		let tagsArray = [];
		if (tags) {
			for (let i = 0; i < tags.length; i++) {
				if (tags[i].name) {
					tagsArray.push(tags[i].name);
				} else if (typeof tags[i] === 'string') {
					tagsArray.push(tags[i]);
				}
			}
		}
		this.configuration.tags = tagsArray;

		let data = {
			tags: this.configuration.tags,
			showUntagged: this.configuration.showUntagged,
			ignoreUserTags: true
		};

		let url = `${Api.serverRoot()}accounts/${this.configuration.accountId}/summary/applicationAggregate?includeSubaccounts=${this.configuration.includeSubaccounts.toString()}`;
		let result = await Api.fetchPost(url, data);
		if (result.success) {
			health = result.data;
			let items = [{percentageWidth: 100, cssClass: 'is_idle'}];
			if (this.widgetHandler.find('.cw_account_application_chart').length) {
				let healthBars = this.widgetHandler.find('.cw_bars');
				healthBars.find('.is_critical').find('.value').text(health.critical);
				healthBars.find('.is_critical').removeClass('no_bg').addClass(health.critical ? '' : 'no_bg');
				healthBars.find('.is_major').find('.value').text(health.major);
				healthBars.find('.is_major').removeClass('no_bg').addClass(health.major ? '' : 'no_bg');
				healthBars.find('.is_minor').find('.value').text(health.minor);
				healthBars.find('.is_minor').removeClass('no_bg').addClass(health.minor ? '' : 'no_bg');
				healthBars.find('.is_ok').find('.value').text(health.ok);
				healthBars.find('.is_ok').removeClass('no_bg').addClass(health.ok ? '' : 'no_bg');
				healthBars.find('.is_idle').find('.value').text(health.maintenance);
				healthBars.find('.is_idle').removeClass('no_bg').addClass(health.maintenance ? '' : 'no_bg');
				let percentage0 = 0;
				let percentage20 = 0;
				let	percentage60 = 0;
				let percentage40 = 0;
				let percentage100 = 0;

				if (health) {
					if (health.total !== 0) {
						percentage0 = health.maintenance * 100 / health.total;
						percentage60 = health.minor * 100 / health.total;
						percentage40 = health.major * 100 / health.total;
						percentage20 = health.critical * 100 / health.total;
						percentage100 = health.ok * 100 / health.total;
						items = [{
							percentageWidth: percentage20,
							cssClass: 'is_critical',
							title: lang.widget.messages.TOTAL_CRITICAL_APPLICATIONS + ' ' + health.critical
						}, {
							percentageWidth: percentage40,
							cssClass: 'is_major',
							title: lang.widget.messages.TOTAL_MAJOR_APPLICATIONS + ' ' + health.major
						}, {
							percentageWidth: percentage60,
							cssClass: 'is_minor',
							title: lang.widget.messages.TOTAL_MINOR_APPLICATIONS + ' ' + health.minor
						}, {
							percentageWidth: percentage100,
							cssClass: 'is_ok',
							title: lang.widget.messages.TOTAL_OK_APPLICATIONS + ' ' + health.ok
						}, {
							percentageWidth: percentage0,
							cssClass: 'is_idle',
							title: lang.widget.messages.TOTAL_INACTIVE_APPLICATIONS + ' ' + health.maintenance
						}];
					}
				}
				let healthChunkProgresBar = new ChunkProgressBar({
					items: items,
					renderTo: this.widgetHandler.find('.cw_progress')
				});

				items.forEach(x => {
					healthBars.find('.' + x.cssClass).attr('title', x.title);
				});

				Renderer.applicationBarsIcons(health, this.widgetHandler.find('.cw_account_application_chart'));
			}
		} else {
			this.showErrorMessage(result.message);
		}
	},

	onApplicationHealthClick(e) {
		let target = e.currentTarget;
		let stateFilter;
		let redirectToApplication = true;
		if ($(target).hasClass('cw_bar') || $(target).hasClass('cw_progress_bar')) {
			if ($(target).find('.value').text() === '0') {
				redirectToApplication = false;
			} else {
				if ($(target).hasClass('is_critical')) {
					stateFilter = 'CRITICAL';
				} else if ($(target).hasClass('is_major')) {
					stateFilter = 'MAJOR';
				} else if ($(target).hasClass('is_minor')) {
					stateFilter = 'MINOR';
				} else if ($(target).hasClass('is_ok')) {
					stateFilter = 'NONE';
				} else if ($(target).hasClass('is_idle')) {
					stateFilter = 'IDLE';
				}
			}
		}
		if (redirectToApplication && this.redirectConfig?.doDefaultRedirect) {
			let filterObj = {};
			if (stateFilter) {
				filterObj = {
					extra: {
						stateFilter: stateFilter,
						tagFilter: this.configuration.tags,
						accountId: this.configuration.accountId
					},
					newTab: this.redirectConfig?.defaultRedirectInNewTab
				};
			}
			State.mainApp.navigate(ApplicationsRouter.list(), filterObj);
		}
	},

	/**
	 * Called when multiple events are received from the server
	 */
	onEvent: function () {
		this.renderApplicationChart();
	},
	/**
	 * Refresh the widget
	 */
	refresh: function () {
		this.renderApplicationChart();
	},
	/**
	 * Subscribes to server health events
	 */
	subscribe: function () {
		var subscriptionObj = [{
			eventType: 'ApplicationHealth',
			accountId: this.configuration.accountId,
			includeSubaccounts: State.includeSubaccounts
		}];
		this.subscriberId = this.id;
		RemoteEventsManager.subscribe(this.subscriberId, subscriptionObj);
	},
	/**
	 * Destroy
	 */
	destroy: function () {
		Widget.prototype.destroy.call(this);
	}
});
