import './barchartWidget.less';

import React from 'react';
import Settings from 'settings';
import { Cookies } from "core/cookies";
import { sharedLocalization } from "controls/designer/features/widgets/localization";
import { BarchartMetricConfiguration } from './barchartMetricConfiguration';
import { Api, State, UserPrefs, Utils } from 'tools';
import { IconButton } from "controls/react/form/iconButton";
import ReactDOM from "react-dom";
import WidgetInfoContainer from "./widgetInfoContainer";
import { Popover, Alert } from 'antd';
import { compileName } from "../stackChartWidget/helpers";
import { AssetsRouter } from 'areas/assets/bundleDescription';
import { RemoteEventsManager } from 'core';
import {DefaultWidgetWrapper} from "controls/designer/features/widgets/defaultWidgetWrapper";

const b = require('b_').with('metric-barchart');

const i = require('core/localization').translator(sharedLocalization, {
	"Linear gauge": {
		"no": "Lineær måler"
	},
	"Breach": {
		"no": "Brudd"
	},
	"Current": {
		"no": "Nåværende"
	},
	"Min": {
		"no": "Min"
	},
	"Max": {
		"no": "Max"
	},
	"Redirect": {
		"no": "Videresend"
	},
	"No data":{ "no" : "Ingen data"}
});

export function getWidgetDescription() {
	return {
		form: BarchartMetricConfiguration,
		defaultConfig: {
			type: 'metric-barchart',
			title: i('Linear gauge'),
			fullTitle: i('Linear gauge'),
			accountId: Cookies.CeesoftCurrentAccountId,
			isDashboard: true,
			contentPadding : 10,
			legendValue: ['<Asset>', '<Name>']
		},
		widget: BarchartWidget
	}
}

export class BarchartWidget extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			metric: 0,
			date: null
		};
		this.infoContainer = null;
		this.subscription = null;
		this.hasMetricSelected = this.props.config.selectedMetrics?.length > 0;
	}
	componentDidMount() {
		this.subscribe();
		this.getValues();
	}
	componentWillUnmount() {
		this.subscription?.unsubscribe();
	}

	onInfoSignShow = (e) => {
		if(!this.hasMetricSelected)
			return;
		let data = this.props.config.selectedMetrics[0];
		let coordinates = e.target.getBoundingClientRect();
		this.infoContainer = e.target;
		ReactDOM.render(<WidgetInfoContainer
			data={data}
			coordinates={coordinates}
		/>, this.infoContainer);
	}
	onInfoSignHide = (e) => {
		if (this.infoContainer) {
			ReactDOM.unmountComponentAtNode(this.infoContainer);
		}
	}
	redirect = (e) => {
		if (!this.hasMetricSelected)
			return;

		e.stopPropagation();
		if (this.props.designer.config.features?.redirectOnClick && State.currentApp?.dashboardDesigner?.props?.mode !== 'designer'  && this.props.redirectConfig?.type==='Default') {
			State.mainApp?.navigate(AssetsRouter.details(this.props.config.selectedMetrics[0].assetId), {newTab: this.props.redirectConfig?.linkInNewTab});
		}
	}

	subscribe = () => {
		if (!this.hasMetricSelected)
			return;
		var subscriptionObj = [{
			eventType: 'Metric',
			releaseEvents: true,
			qualifierId: this.props.config.selectedMetrics[0].metricId
		}];
		this.subscription = RemoteEventsManager.subscribeCallback(subscriptionObj, this.onEvents);
	}

	onEvents = events => {
		this.setState({
			metric: Math.round(events.metric.v),
			date: new Date(events.metric.t).toLocaleString()
		});
	}

	render() {
		const toolbarAtTheEnd = <IconButton iconName={"info-sign"}
			embedded={true}
			onMouseEnter={this.onInfoSignShow}
			onMouseLeave={this.onInfoSignHide}/>;
		const content = <div>
			<div>{i('Value')} : <b>{this.state.metric ?? i("No data")}</b></div>
			<div>{i('Min')} : <b>{this.props.config.minValue}</b></div>
			<div>{i('Max')} : <b>{this.props.config.maxValue}</b></div>
			<div>{i('Breach')} : <b>{this.props.config.breachValue}</b></div>
			<div>{i('Current')} : <b>{this.state.metric ?? i("No data")}</b></div>
			<div>{i('Redirect')} : <b>{this.props.config.redirect?.label}</b></div>
		</div>
		const legendContainerProps = {
			noLabel: this.props.config.hideLabels,
			noNumbers:this.props.config.hideNumbers,
			noLabelAndNumber: this.props.config.hideLabels && this.props.config.hideNumbers
		}

		return <DefaultWidgetWrapper {...this.props} toolbarAtTheEnd={toolbarAtTheEnd}>
			{(!this.props.dashboardSettings.showWidgetHeader && this.props.dashboardSettings.readOnly)
					 && <div className={b('info-icon')}>{toolbarAtTheEnd}</div>}
			{!this.state.metric && <Alert type="error" message={i("No data")} banner />}
			<div className={b('barchart')} onClick={this.redirect} style={{margin:this.props.config.contentPadding??20}}>
				<Popover content={content} title={this.state.date}>
					<div className={b('barchart-bar', {inverted: this.props.config.invert})}>
						<div className={b('bar',{inverted: this.props.config.invert})} style={{ width: this.getMarkerPosPercentage(this.props.config.breachValue) + '%' }}>
							<div className={b('breach')}>
								{!this.props.config.hideNumbers && <p>{this.props.config.breachValue}</p>}
								{!this.props.config.hideLabels && i('Breach')}
							</div>
						</div>
						{this.state.metric &&
							<div className={b('current')} style={{ left: this.getMarkerPosPercentage(this.state.metric) + '%' }}>
								<div className={b('currentLine')}></div>
								<div className={b('currentText')}>
									{!this.props.config.hideNumbers && <p>{this.state.metric}</p>}
									{!this.props.config.hideLabels && i('Current')}
								</div>
							</div>
						}
						<div className={b('min')}>
							{!this.props.config.hideNumbers && <p>{this.props.config.minValue}</p>}
							{!this.props.config.hideLabels && i('Min')}
						</div>
						<div className={b('max')}>
							{!this.props.config.hideNumbers && <p>{this.props.config.maxValue}</p>}
							{!this.props.config.hideLabels && i('Max')}
						</div>
					</div>
				</Popover>


				{(this.hasMetricSelected && this.props.config.legendValue?.length > 0)
					&& 	<div className={b('legend-container',legendContainerProps)}>
					<span prevent-widget-redirect-from-states-mgr="true" onClick={this.redirect}>{compileName(this.props.config.legendValue, this.props.config.selectedMetrics[0])}</span>
				</div>}
			</div>


		</DefaultWidgetWrapper>
	}

	getValues = async () => {
		if (!this.hasMetricSelected)
			return;
		const url = `${Settings.serverPath}metrics/registeredMetrics/lastinfo`;
		const resp = await Api.fetchPost(url, [{
			metricId: this.props.config.selectedMetrics[0].metricId,
			conversion: "",
			customUnit: ""
		}]);
		this.setState({
			metric: resp[0]?.data[0]?.v ? Math.round(resp[0]?.data[0]?.v) : null,
			date: new Date(resp[0]?.data[0]?.t).toLocaleString()
		});
	}

	getMarkerPosPercentage = (value) =>{
		return (((value - this.props.config.minValue)* 100) / (this.props.config.maxValue - this.props.config.minValue))
	}
}

