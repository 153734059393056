import { Checkbox, DropDownList, FormEntry, MultiSelect, MultiToggle } from "controls/react/form";
import { CollapsibleFrame } from "controls/react/layout/collapsibleFrame";
import { translator } from "core";
import React, { useMemo, useCallback } from 'react';
import { Utils } from "tools";
import { chartTypeDataSource } from "../common/dataSources/chartTypeDataSource";
import { aggregationTypeDataSource } from "../common/dataSources/aggregationTypeDataSource";
import { informationalDataSource } from "../common/dataSources/informationalDataSource";
import { legendDataSource } from "../common/dataSources/legendDataSource";
import { periodsDataSource } from "../common/dataSources/periodsDataSource";
import { getSelectorTypeDataSource, LEGEND_TYPE } from "../common/dataSources/selectorTypeDataSource";
import { timezoneDataSource } from "../common/dataSources/timezoneDataSource";
import { usePlainStore, useStore } from "./useStore";
import './widgetConfiguration.less';
import { DateTimePicker } from "controls/react/kendoWrappers";

const b = require('b_').with('display-settings-frame');

DisplaySettingsFrame.propTypes = {

};

const i = translator({
  "Description type": {
    "no": "Beskrivelsetype"
  },
  "Aggregation": {
    "no": "Aggregering"
  },
  "Time selector": {
  	"no": "Tidsvelger"
  }
});

export function DisplaySettingsFrame(props) {
	const [period, setPeriod] = useStore(["configuration", "period"], 'LASTDAY');
	const [startDate, setStartDate] = useStore(["configuration", "startDate"]);
	const [endDate, setEndDate] = useStore(["configuration", "endDate"]);
	const [hideTimeSelector, setHideTimeSelector] = useStore(["configuration", "hideTimeSelector"]);
	const [chartType, setChartType] = useStore(["configuration", "chartType"], 'line');
	const [aggregationType, setAggregationType] = useStore(["configuration", "aggregationType"], "average");
	const [ignoreMissingData, setIgnoreMissingData] = useStore(["configuration", "ignoreMissingData"]);
	const [hideErrors, setHideErrors] = useStore(["configuration", "hideErrors"]);
	const [showThreshold, setShowThreshold] = useStore(["configuration", "showThreshold"], true);
	const [showXAxis, setShowXAxis] = useStore(["configuration", "showXAxis"], true);
	const [showYAxis, setShowYAxis] = useStore(["configuration", "showYAxis"], true);
	const [showRegression, setShowRegression] = useStore(["configuration", "showRegression"]);
	const [timezone, setTimezone] = useStore(["configuration", "timezone"]);
	const [legendType, setLegendType] = useStore(["configuration", "legendType"], 'none');
	const [informationalTemplate, setInformationalTemplate] = usePlainStore(["configuration", "informationalTemplate"], informationalDataSource);
	const [labelTemplate, setLabelTemplate] = usePlainStore(["configuration", "labelTemplate"], legendDataSource);
	const [accountId] = useStore(["configuration", "accountId"]);
	const [type] = useStore(["type"]);

	let [timeSelectorPosition, setTimeSelectorPosition] = useStore(["configuration", "timeSelectorPosition"]);
	if (!timeSelectorPosition) {
		timeSelectorPosition = hideTimeSelector ? 'NONE' : 'TOP';
	}

	const timezoneDS = useMemo(() => timezoneDataSource());
	const timeSelectorPositionDS = [{
		value: 'TOP',
		text: 'Top',
	}, {
		value: 'BOTTOM',
		text: 'Bottom',
	}, {
		value: 'NONE',
		text: 'None',
	}];
	const selectorTypeDataSource = useMemo(() => getSelectorTypeDataSource(type), [type]);

	const setLabel = useCallback((values) => {
		setLabelTemplate(legendDataSource.filter((item) => values.includes(item)));
	});

	const setInformational = useCallback((values) => {
		setInformationalTemplate(informationalDataSource.filter((item) => values.includes(item)));
	});

	if (!type) {
		return null;
	}

	return (
		<div className={b()}>
			<CollapsibleFrame expanded={props.expanded}
			                  title={lang.widget.DISPLAY_SETTINGS}>
				<FormEntry label={i('Period')}>
					<div className={b('nowrap')}>
						<MultiToggle items={periodsDataSource} value={period} onChange={setPeriod} />
						{/*<div className={b('buffer')}/>
						<Checkbox label={lang.widget.HIDE_TIME_SELECTOR} value={hideTimeSelector} onChange={setHideTimeSelector} />*/}
					</div>
				</FormEntry>
				{period === 'CUSTOM' &&
				<div className={b('custom-time-selector')}>
					<DateTimePicker containerClass={'inline'} value={startDate ? new Date(startDate) : ''} onChange={setStartDate}/>
					<DateTimePicker containerClass={'inline'} value={endDate ? new Date(endDate) : ''} onChange={setEndDate} min={startDate ? new Date(startDate) : new Date(0)}/>
				</div>}
				<FormEntry label={i('Type')}>
					<DropDownList dataSource={chartTypeDataSource} value={chartType} onChange={setChartType} />
				</FormEntry>
				<FormEntry label={i('Aggregation')}>
					<DropDownList dataSource={aggregationTypeDataSource} value={aggregationType} onChange={setAggregationType} />
				</FormEntry>
				<div className={b('nowrap')}>
					<div className={b('buffer')}>
						<Checkbox label={lang.serviceBoard.IGNORE_MISSING_DATA} value={ignoreMissingData} onChange={setIgnoreMissingData} />
					</div>
					<div className={b('buffer')}>
						<Checkbox label={lang.SHOW_REGRESSION} value={showRegression} onChange={setShowRegression} />
					</div>
				</div>
				<div className={b('nowrap')}>
					<div className={b('buffer')}>
						<Checkbox label={lang.widget.messages.HIDE_ERRORS} value={hideErrors} onChange={setHideErrors} />
					</div>
					{!props.hideThreshold && <div className={b('buffer')}>
						<Checkbox label={lang.SHOW_THRESHOLD} value={showThreshold} onChange={setShowThreshold} />
					</div>}
				</div>
				<div className={b('nowrap')}>
					<div className={b('buffer')}>
						<Checkbox label={'Show X axis'} value={showXAxis} onChange={setShowXAxis} />
					</div>
					{!props.hideThreshold && <div className={b('buffer')}>
						<Checkbox label={'Show Y axis'} value={showYAxis} onChange={setShowYAxis} />
					</div>}
				</div>
				<FormEntry label={i('Time selector')}>
					<DropDownList dataSource={timeSelectorPositionDS} value={timeSelectorPosition} onChange={setTimeSelectorPosition} />
				</FormEntry>
				<FormEntry label={i('Timezone')}>
					<DropDownList dataSource={timezoneDS} value={timezone} onChange={setTimezone} autoBind={true} combo={true} plainArray={true} />
				</FormEntry>
				<div className={b('nowrap')}>
					<FormEntry label={i('Description type')}>
						<MultiToggle items={selectorTypeDataSource} value={legendType} onChange={setLegendType} />
					</FormEntry>
					<div className={b('buffer')}/>
				</div>
				{legendType === LEGEND_TYPE.INFORMATIONAL && <FormEntry label={i('Informational')} vertical={true}>
					<MultiSelect
						value={informationalTemplate}
						data={informationalDataSource}
						onChange={setInformational}
						containerClass={'w100'}
					/>
				</FormEntry>}
				{legendType === LEGEND_TYPE.LEGEND && <FormEntry label={i('Legend')} vertical={true}>
					<MultiSelect
						value={labelTemplate}
						data={legendDataSource}
						onChange={setLabel}
						containerClass={'w100'}
					/>
				</FormEntry>}
			</CollapsibleFrame>
		</div>
	);
}
