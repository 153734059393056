import Utils from "tools/utils";

export default class ArrayHelpers {

	static generateUniqueName(items, name) {
		let maxIndex = 0, nameExists = false;
		const startsWith = `${name}-`;

		items.forEach((item) => {
			if (item.name.startsWith(startsWith)) {
				const len = startsWith.length;
				const strNumber = item.name.substring(len);
				const number = +strNumber;

				if (maxIndex < number) {
					maxIndex = number;
				}
			}

			if (item.name == name)
				nameExists = true;
		});

		if( maxIndex == 0 && !nameExists)
			return name;

		return startsWith + ++maxIndex;
	}

	static generateId(array, subArrayAccessor) {
		array.forEach(entry => {
			if (!entry.id) {
				entry.id = Utils.guid();
				if(subArrayAccessor){
					ArrayHelpers.generateId(subArrayAccessor(entry));
				}
			}
		});

		return array;
	}

	static cleanId(array) {
		array.forEach(entry => {
			delete entry.id
		});

		return array;
	}

	static remove(array, element, realId) {
		let index = null;
		realId = realId || 'id';
		if (element[realId] != null) {
			index = array.findIndex(e => e[realId] == element[realId]);
		} else {
			index = array.indexOf(element);
		}
		array.splice(index, 1);
		return array;
	}

	static addOrReplace(array, entry, idFieldName = "id"){
		if( entry[idFieldName] == null ){
			array.push( entry );
			entry[idFieldName] = Utils.guid();
		}else{
			let index = array.findIndex( e => e[idFieldName] == entry[idFieldName]);
			if( index == -1){
				array.push(entry);
			}else {
				array.splice(index, 1, entry);
			}
		}
	}

	static objectToArray(object, propertyField){
		return Object.entries(object).map( ([key, value]) =>{
			return {
				[propertyField]: key,...value
			}
		});
	}
}


export function getLastEntry(array){
	if(array.length == 0)
		return null;

	return array[array.length - 1];
}
