import {root as monitorsRoot} from '../router';

const root = monitorsRoot + '/interface-traffic';

export function getDescriptions(){
	return [
		{
			url: root + '/:id',
			getModuleImport: () => import('./details')
		},
		 // {
		 // 	url: root,
		 // 	getModuleImport: () => import('./list')
		 // }
	];
}

export class InterfaceTrafficRouter {
	static create() {
		return `${root}/new`;
	}

	static list() {
		return `${root}`;
	}
}
