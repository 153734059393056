import {Api, Utils} from 'tools';
import Settings from 'settings';

export default async function getChartData({
	accountId,
 	ignoreMissingData,
	timezone,
	startDate,
	endDate,
	period,
	width,
	useRawData,
	metricsItems}) {

	const {interval} = Utils.getPeriodInterval({
		period: period,
		startDate: startDate,
		endDate: endDate,
		width: width,
	});

	const queryParams = {
		ignoreMissingData,
		timeZone: timezone,
		interval: interval,
		startDate,
		endDate
	};

	if (useRawData) {
		delete queryParams.interval;
	}

	if (!startDate || !endDate) {
		queryParams.timeSelector = period;
		delete queryParams.startDate;
		delete queryParams.endDate;
	}

	const metrics = metricsItems || [];

	const urlBase = `${Settings.serverPath}accounts/${accountId}/metrics/registeredMetrics/data`;

	const url = Api.compileUrlWithParams(urlBase, queryParams);

	const {result} = await Utils.ajaxPromise(url, 'POST', JSON.stringify(metrics));

	return result;
}

