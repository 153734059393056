import {change} from "redux-form";
import {getCurrentItemId} from "../selectors/getCurrentItemId";
import {formBuilderForm} from "../constants";
import {loadDataAction} from "../actions/loadDataAction";
import {put, select} from "redux-saga/effects"

export function* assetSaved({params}) {
    const itemId = yield select(getCurrentItemId);

    if (!itemId) {
        return;
    }

    const data = params[0];

    try {
        const {asset: {accountId: assetAccountId, id: assetId}} = data;

        if (assetAccountId) {
            yield put(change(formBuilderForm, 'properties.assetAccountId', assetAccountId));
        }

        if (assetId) {
            yield put(change(formBuilderForm, 'properties.assetId', assetId));
            yield put(loadDataAction('assetId'));
        }
    } catch (e) {
        console.warn('No new valid asset selected');
    }

    try {
        const {lastSelectedTarget: {id: targetId}} = data;

        if (targetId) {
            yield put(change(formBuilderForm, 'properties.targetId', targetId));
            yield put(loadDataAction('targetId'));
        }
    } catch (e) {
        console.warn('No new valid target selected');
    }
}
