import React from "react";

import {FormEntry, IconButton} from "controls/react/form";
import {AntInput} from "controls/react/ant/antInput";
import {MetricWindow} from "areas/service-boards/widgets/common/metricWindow";
import {inputWithAction} from "controls/react/form/common";
import {ApplicationState} from "framework/applicationState";
import {Api} from "tools";

const i = require('core/localization').translator({
	'Select metric': {},
	'{0} and {1} more': {},
	'Clear selection': {}
})

const b = inputWithAction

export type SingleMetricSelectorProps = {
	accountId?: string;
	value?: string;
	onChange?: (value: string) => void;
	multiple?: boolean
}

export function useMetricName(metricId: string|string[]) {
	const [metricName, setMetricName] = React.useState('');

	React.useEffect(() => {
		if(!metricId || metricId.length == 0 ){
			setMetricName('')
			return;
		}

		Api.fetchPost(Api.accountRoot() + "metrics/registeredMetrics", Array.isArray(metricId) ? metricId: [metricId])
			.then(response => {
				if (response.length == 0 || response.success === false)
					return;

				let name = response[0].assetName + '/' + response[0].instanceName;
				if(Array.isArray(metricId) && metricId.length > 1){
					name = i('{0} and {1} more', name, metricId.length - 1)
				}
				setMetricName(name);
			})
	}, [metricId])

	return metricName;
}

export const SingleMetricSelector = (props: SingleMetricSelectorProps) => {
	const [showWindow, setShowWindow] = React.useState(false);

	const onAdd = React.useCallback(ids => {
		props.onChange(ids.length ? ids[0] : null);
		setShowWindow(false);
	}, []);

	const metricName = useMetricName(props.value);

	return <div className={b()}>
		<AntInput value={metricName} readOnly={true} className={b('input')}/>
		<IconButton iconName={"show-thumbnails"} embedded={true} containerClass={b('action')}
		            onClick={() => setShowWindow(true)}
		            title={i('Select metric')}/>
		<IconButton iconName={"eraser"} embedded={true} containerClass={b('action')}
		            onClick={() => props.onChange(null)}
		            title={i('Clear selection')}/>
		{ showWindow && <MetricWindow
			open={true}
			onClose={() => setShowWindow(false)}
			onAdd={onAdd}
			accountId={props.accountId ?? ApplicationState.accountId}
			singleMetricSelection={true}
			defaultSelectedMetricIds={props.value ? [props.value] : []}
		/>
		}
	</div>
}

export type MultipleMetricsSelectorProps = {
	accountId?: string;
	value?: string[];
	onChange?: (value: string[]) => void;
}

export const MultipleMetricsSelector = (props: MultipleMetricsSelectorProps) => {
	const [showWindow, setShowWindow] = React.useState(false);

	const metricName = useMetricName(props.value);
	const onAdd = React.useCallback(ids => {
		props.onChange(ids);
		setShowWindow(false);
	}, [])

	return <div className={b()}>
		<AntInput value={metricName} readOnly={true} className={b('input')}/>
		<IconButton iconName={"show-thumbnails"} embedded={true} containerClass={b('action')}
		            onClick={() => setShowWindow(true)}
		            title={i('Select metric')}/>
		<IconButton iconName={"eraser"} embedded={true} containerClass={b('action')}
		            onClick={() => props.onChange([])}
		            title={i('Clear selection')}/>
		{ showWindow && <MetricWindow
			open={true}
			onClose={() => setShowWindow(false)}
			onAdd={onAdd}
			accountId={props.accountId ?? ApplicationState.accountId}
			defaultSelectedMetricIds={props.value}
		/>
		}
	</div>
}
