import { getInternalState } from "./getInternalState";
import { _getCurrentItemId } from "./getCurrentItemId";

const _getSavedValues = (state) => {
    const itemId = _getCurrentItemId(state);
    return state.getIn(['items', itemId, 'savedValues']);
};

export const getSavedValues = (state) => {
    return _getSavedValues(getInternalState(state))
};
