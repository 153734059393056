import React from "react";
import ReactDOM from "react-dom";
import {Application, Cookies, ErrorHandler, LocalEventsManager, RemoteEventsManager} from "core";
import {CeeViewDataSource, State, Utils} from "tools";
import {Settings} from "settings";
import {ExpandableTextarea, ModalWindow} from "controls";
import {AssetForm, AuthenticationForm} from "areas/administration";
import FormEntry from "controls/react/form/formEntry";

export function Configurations(config) {
	Utils.apply(this, config);
};

export {Configurations as default, Configurations as MonitorConfiguration}

jQuery.extend(Configurations.prototype, Application.prototype, {
	/**
	 * @property
	 * @type string
	 */
	type: 'Application',
	/**
	 * Module related initializations can be done here
	 */
	initComponent: function () {
		Application.prototype.initComponent.call(this);

		this.descriptionHandler = new ExpandableTextarea({
			renderTo: $('.cw_description'),
			labelTooltip: this.descriptionTooltip
		});

		if (this.createAsset) {
			this._setAddAsset();
		}

		if (this.createTarget) {
			this._setAddTarget();
		}

		if (this.createAuthentication) {
			this._setAddAuthentication();
		}

		this.setRedirectBack();

		if (!State.mainApp.session.hasRole('MONITOR_UPDATE')) {
			$('#cw_save').remove();
			$('#content_area .window_area').append('<div class="cw_view_mask"></div>');
		}

		$('[data-tooltip="generic"]').kendoTooltip({
			width: 300,
			content: function (e) {
				return $(e.sender.element).data('title');
			}
		}).data("kendoTooltip");
	},
	/*
	 * Handler function for setting the redirections
	 */
	setRedirectBack: function () {
		if (this.redirectBack) {
			//override if we need to redirect to specific location
			jQuery.extend(this, {
				/*
				 * Handler function for redirecting on save success
				 */
				redirectView: function () {
					this.breadCrumbRedirect();
				},
				/*
				 * Handler function for redirecting on cancel
				 */
				onCancel: function () {
					this.breadCrumbRedirect();
				}
			});
		}
	},
	/*
	 * Handler function for redirecting to last clickable breadcrumb
	 */
	breadCrumbRedirect: function () {
		if (this.destroy) {
			this.destroy();
		}

		State.mainApp.context.navigation.pop();
	},

	/**
	 * Handler function to get asset name
	 */
	getAssetName: function () {
		var url = Settings.serverPath + 'agents/' + this.selectedAgentId + '/asset';
		if (Utils.isGuid(this.selectedAgentId)) {
			Utils.ajax(url, 'GET', {}, $.proxy(function (result) {
				if (result.success) {
					$('#cw_asset_name').val(result.data.name);
					this.assetId = result.data.id;
					this.systemType = result.data.systemType;
					if (this.monitorType === 'SYSTEM') {
						this.updateShareDiskTabState();
					}

					this.targetId = this.assetId;
					if (this.doRightAction) {
						this.doRightAction();
					}

					if (this.mode === 'create' && this.setNext) { // TODO: has to be removed, added for testing
						this.setNext();
					}
				} else {
					var notifications = this.statusNotification || this.actionSettingsNotification;
					if (notifications) {
						notifications.setOptions({
							message: result.message,
							status: 'error'
						}).show();
					}
				}
			}, this));
		}
	},
	/**
	 * Handler function called to render second list
	 */
	initAssetListTwo: function (id) {
		var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/assetGroups/' + id + '/members/types/AGENT';

		this.groupAssetDataSource = new CeeViewDataSource({
			change: $.proxy(function(e){
				if (this.configuration && this.configuration.configurationAssetId) {
					this.assetListTwo.value(this.configuration.configurationAssetId);
				} else {
					this.assetListTwo.select(0);
				}

				this.assetListTwo.trigger("change");
			}, this),
			transport: {
				read: {
					url: url,
					contentType: 'application/json; charset=utf-8',
					type: 'GET',
					dataType: 'json',
                    cache: false
				}
			},
			error: ErrorHandler.kendoServerError
		});
		this.assetListTwo.setDataSource(this.groupAssetDataSource);
		if (this.mode !== 'view') {
			this.assetListTwo.enable(true);
		}
	},
	/*
	* Handler function for setting the add asset html and functionality
	* */
	_setAddAsset: function () {
        if (State.mainApp.session.hasRole('ASSET_CREATE')) {
            var disableClass = '';
            if (!this.createAsset.enable) {
                disableClass = 'is_disabled';
            }
            var html = '<span title="' + lang.designer.messages.SQ_ASSETNEW_BUTTON + '" class="glyphicons plus-sign pointer cw_create_asset_new ' + disableClass + '"></span>';
            var selector = this.createAsset.assetSelector;
            if (selector && selector.length) {
            	var container = selector.closest('.cw_dropdown_container');
            	container.append(html);
            	container.off('click', '.cw_create_asset_new:not(.is_disabled)').on('click', '.cw_create_asset_new:not(.is_disabled)', $.proxy(this._onCreateAssetClick, this));

                LocalEventsManager.unbind('assetsaved');
                LocalEventsManager.bind('assetsaved', $.proxy(this._onAssetSaved, this));
			}
        }
	},
    /*
    * Handler function for clicking create asset
    * */
    _onCreateAssetClick: function () {
        var panel = $('#js-sq_wizard_panel'), panelPos = panel.offset();

        var width = 400;
        this.sqAssetWindow = new ModalWindow({
            title: lang.account.ASSET_CREATE,
            width: width,
            minHeight: 567,
            height: 'auto',
            url: 'include/Administration/AssetForm.jsp',
            refresh: $.proxy(function () {
            	var assetFormObj = {
					id: null,
					mode: 'create'
				};
            	if (this.onCreateNewAsset) {
            		assetFormObj.onCreateNew = this.onCreateNewAsset;
				}
                new AssetForm(assetFormObj);
            }, this)
        });
        this.sqAssetWindow.open();
	},
	/*
	 * Handler function for setting the add target html and functionality
	 * */
    _setAddTarget: function () {
        if (State.mainApp.session.hasRole('ASSET_UPDATE')) {
            var disableClass = '';
            if (!this.createTarget.enable) {
                disableClass = 'is_disabled';
            }
            var html = '<span title="' + lang.designer.messages.SQ_ASSETNEWTARGET_BUTTON + '" class="glyphicons plus-sign pointer cw_sq_asset_target_new ' + disableClass + '"></span>';
            var selector = this.createTarget.targetSelector;
            if (selector && selector.length) {
            	var container = selector.closest('.cw_dropdown_container');
                container.append(html);
                container.off('click', '.cw_sq_asset_target_new:not(.is_disabled)').on('click', '.cw_sq_asset_target_new:not(.is_disabled)', $.proxy(this._onCreateTargetClick, this));
			}
        }
    },
	/*
	* Handler function for setting the add authentication html and functionality
    * */
	_setAddAuthentication: function () {
		if (State.mainApp.session.hasRole('ASSET_UPDATE')) {
			var disableClass = '';
			if (!this.createAuthentication.enable) {
				disableClass = 'is_disabled';
			}
			var html = '<span title="' + lang.designer.messages.SQ_ASSETNEWTARGET_BUTTON + '" class="glyphicons plus-sign pointer cw_sq_asset_authentication_new ' + disableClass + '"></span>';
			var selector = this.createAuthentication.targetSelector;
			if (selector && selector.length) {
				var container = selector.closest('.cw_dropdown_container');
				container.append(html);
				container.off('click', '.cw_sq_asset_authentication_new:not(.is_disabled)').on('click', '.cw_sq_asset_authentication_new:not(.is_disabled)', $.proxy(this._onCreateAuthenticationClick, this));
				LocalEventsManager.unbind('authenticationsaved');
				LocalEventsManager.bind('authenticationsaved', $.proxy(this._onAuthenticationSaved, this));
			}
		}
	},
	/*
   * Handler function for clicking create authentication
   * */
	_onCreateAuthenticationClick: function () {
		var panel = $('#js-sq_wizard_panel'), panelPos = panel.offset();

		var width = 400;

		this.sqAssetWindow = new ModalWindow({
			title: lang.account.AUTH_CREATE,
			width: width,
			minHeight: 567,
			height: 'auto',
			url: 'include/Administration/AuthenticationForm.jsp',
			refresh: $.proxy(function () {
				new AuthenticationForm({
					id: 'new_authentication',
					mode: 'create',
					isFromDesigner: true,
					designerAuthType: this.createAuthentication.authType,
				});
			}, this)
		});
		this.sqAssetWindow.open();
	},
	_onAuthenticationSaved: function () {
		var authCombo = $('#cw_authentication').data('kendoComboBox');
		authCombo.dataSource.read();
	},
	/*
	 * Handler function called when asset is saved by eventmanager
	 * @param {Object} data The object with saved data (asset, targets)
	 * */
    _onAssetSaved: function (data) {
        var mode = data.mode;
        var asset = data.asset;
        var targets = data.targets || [];
        var combo = {
            asset: this.createAsset.assetSelector.data('kendoComboBox'),
            target: null
        };

        if (this.createAsset.targetSelector && this.createAsset.targetSelector.length) {
        	combo.target = this.createAsset.targetSelector.data('kendoComboBox');
            combo.target.enable(true);
		}

        if (mode !== 'update') {
            //add asset and targets
            this.isCustomEvent = true;
            var dataSource = combo.asset.dataSource.data();
            this.assetId = asset.id;
            dataSource.push({
                id: asset.id,
                name: asset.name
            });
            combo.asset.value(asset.id);
            if (combo.target) {
                combo.target.dataSource.data([]);
			}
        }

        if (combo.target) {
            //update asset with targets
            var dataSource = combo.target.dataSource.data(), target;
            for (var i = 0, length = targets.length; i < length; i++) {
                target = targets[i];
                dataSource.push({
                    id: target.id,
                    editable: target.editable,
                    address: target.address
                });
            }

            var lastTarget = data.lastSelectedTarget;
            if (lastTarget) {
                combo.target.value(lastTarget.id);
            }
		}
	},
	/*
	* Handler function for clicking create target
	* */
    _onCreateTargetClick: function () {
		var panel = $('#js-sq_wizard_panel'), panelPos = panel.offset();

		this.sqAssetWindow = new ModalWindow({
			title: lang.account.ASSET_CREATE,
			width: 430,
			minHeight: 567,
			height: 'auto',
			url: 'include/Administration/AssetForm.jsp',
			refresh: $.proxy(function () {
				new AssetForm({
					id: this.createTarget.assetSelector.data('kendoComboBox').value(),
					mode: 'update'
				});
			}, this)
		});
		this.sqAssetWindow.open();
	},
    /**
     * Abstract. To be overwritten
     */
	destroy: function () {
        if (this.mode === 'update' || this.mode === 'edit') {
            if (this.id) {
               RemoteEventsManager.discard(this.id);
            }
        }
	}
});
