import {root as monitorsRoot} from '../router';

const root = monitorsRoot + '/database/';

export function getDescriptions(){
	return [
		{
			url: root + ':id',
			getModuleImport: () => import('./details'),
			react: true
		}
	];
}

export class DatabaseRouter {
	static create() {
		return `${root}new`;
	}
}
