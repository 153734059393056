import {DataListEntry} from "framework/entities/dataList";
import {AgentState} from "framework/entities/agentState";
import {translator} from "core/localization";
import {createModelSchemaWrapper} from "framework/serializr-integration";
import {primitive} from "serializr";

const i = translator({
	'Agent in warning': {
		no: 'Agent har advarsel'
	},
	'Monitor error: data unavailable': {
		no: 'Monitorfeil: Data er ikke tilgjengelig'
	},
	'Monitor error': {
		no: 'Monitorfeil'
	},
	'Monitor in maintenance': {
		no: 'Monitor in vedlikehold'
	},
	'Asset in maintenance': {
		no: 'Ressursen er satt i vedlikehold'
	}
})

export {i as healthDataLocalization}

// AGENT_DOWN: 'Agenten er nede',
// 	QUALIFIER_ERROR: 'Tjenestepunkt feil',
// 	METRIC_ERROR: 'Metrikkfeil',
// 	AGENT_MAINTENANCE: 'Agent er i vedlikehold',
// 	ASSET_MAINTENANCE: 'Ressursen er satt i vedlikehold',
// 	MONITOR_MAINTENANCE: 'Monitor in vedlikehold',

export enum Severity{
	None = 'NONE',
	Minor = 'MINOR',
	Major = 'MAJOR',
	Critical = 'CRITICAL',
	Invalid = 'INVALID'
}

let severityDataList: Array<DataListEntry<Severity>>;

export function getSeverityDataList(): typeof severityDataList{
	if(severityDataList == null){
		severityDataList = [{
			id: Severity.None,
			name: i('None')
		},{
			id: Severity.Minor,
			name: i('Minor')
		},{
			id: Severity.Major,
			name: i('Major')
		},{
			id: Severity.Critical,
			name: i('Critical')
		}];
	}

	return severityDataList;
}

export function severityToText(severity: Severity){
	switch (severity) {
		case Severity.None: return i('None');
		case Severity.Minor: return i('Minor');
		case Severity.Major: return i('Major');
		case Severity.Critical: return i('Critical');
		case Severity.Invalid: return i('Invalid');
	}
}

export const healthIndexToSeverity = (healthIndex:number) => {
	if(healthIndex == null)
		return Severity.Invalid;

	if(healthIndex >= 75 )
		return Severity.None;

	if (healthIndex >= 50)
		return Severity.Minor;

	if (healthIndex >= 25)
		return Severity.Major;

	if (healthIndex >= 0)
		return Severity.Critical;

	return Severity.Invalid;
}

export const severityToColor = (severity: Severity) => {
	switch (severity) {
		case Severity.None:
			return '#7cc623';
		case Severity.Minor:
			return '#f7e400';
		case Severity.Major:
			return '#f1ae31';
		case Severity.Critical:
			return '#e14b32';
		case Severity.Invalid:
			return '#dddddd';
	}
}

export class HealthDataHolder {
	agentState?: AgentState
	assetMaintenance?: boolean;
	healthIndex?: number;
	assetHI?: number;
	monitorDataUnavailable?: boolean;
	monitorError?: boolean;
	monitorMaintenance?: boolean;
}

createModelSchemaWrapper(HealthDataHolder, {
	agentState: primitive(),
	assetMaintenance: primitive(),
	healthIndex: primitive(),
	assetHI: primitive(),
	monitorDataUnavailable: primitive(),
	monitorError: primitive(),
	monitorMaintenance: primitive()
})

export class HealthDataFlags {
	severity: Severity;
	exclamationMark: boolean;
	wrench: boolean;
	cross: boolean;
	title: string;
	label: string;
}

export function healthDataToFlags(data: HealthDataHolder){
	const flags = new HealthDataFlags();

	if(data.assetMaintenance || data.agentState == AgentState.Maintenance) {
		flags.severity = Severity.Invalid;
		flags.wrench = true;
		flags.title = i('Asset in maintenance');
		flags.label = 'N/A';
	}
	else {
		flags.severity = healthIndexToSeverity(data.healthIndex ?? data.assetHI);
		flags.label = data.healthIndex?.toString() ?? data.assetHI?.toString() ?? 'N/A';

		if (data.agentState == AgentState.Error) {
			flags.cross = true;
		} else if (data.agentState == AgentState.Warning) {
			if (data.monitorDataUnavailable) {
				flags.title = i('Agent in warning') + ' | ' + i('Monitor error: data unavailable')
			} else if (data.monitorError) {
				flags.title = i('Agent in warning') + ' | ' + i('Monitor error')
			}

			if (flags.title) {
				flags.exclamationMark = true;
			}

		} else {
			if (data.monitorDataUnavailable) {
				flags.title = i('Monitor error: data unavailable')
			} else if (data.monitorError) {
				flags.title = i('Monitor error')
			}
			if (flags.title) {
				flags.exclamationMark = true;
			} else if (data.monitorMaintenance) {
				flags.title = i('Monitor in maintenance')
				flags.wrench = true;
			}
		}
	}
	return flags;
}

