import Settings from "settings";
import {Api} from "tools";

class MetricDataSource {
	pageSize = 100;

	constructor(accountId, showSelectedMode, selectedIds) {
		this.accountId = accountId;
		this.showSelectedMode = showSelectedMode;
		this.selectedIds = selectedIds;
	}

	get url() {
		return `${Settings.serverPath}accounts/${this.accountId}/metrics/registeredMetrics/search`;
	}

	getParams(page, filters, sorters, options = {}) {
		const params = {
			take: this.pageSize,
			pageSize: this.pageSize,
			page,
			skip: page * this.pageSize,
			filter: {
				"logic":"and",
				"filters":[]
			}
		};

		

		if (this.showSelectedMode) {
			let filters = []
			if (this.selectedIds?.length > 0){
				filters = this.selectedIds.map((id) => ({
					"operator": "eq",
					"value": id,
					"field": "metricId"
				}));
			}
			else
				//there is a case when showSelected is true, but no metrics are selected we need to show no data grid
				filters.push({
					"operator": "eq",
					"value": 'dummy',
					"field": "metricId"
				})

			params.filter.filters.push({
				logic: 'or',
				filters
			})

		}

		if (filters) {
			for (const field of Object.keys(filters)) {
				params.filter.filters.push({
					"operator": "contains",
					"value": filters[field],
					field
				})
			}
		}
		if (sorters) {
			params.sort = sorters;
		}

		return params;
	}

	async read(page, filters, sorters, options = {}) {
		const skip = page * this.pageSize;

		if (options.previousVisible && skip > options.previousVisible) {
			return {items: [], visible: options.previousVisible, filterOptions: {}}
		}
		return await Api.fetchPost(this.url, this.getParams(page, filters, sorters, options))
	}
}

export default MetricDataSource;
