export const allWidgets =
	{
		'application-preview': () => import('areas/application/widgets/preview'),
		'application-state': () => import('areas/application/widgets/state'),
		'service-preview': () => import('areas/services/widgets/preview'),
		'service-summary': () => import('areas/services/widgets/summary'),
		'service-history': () => import('areas/services/widgets/history'),
		'service-selective': () => import('areas/services/widgets/selectiveServices'),
		'service-grid': () => import('areas/services/widgets/grid'),
		'service-state': () => import('areas/services/widgets/state'),
		'service-single-graph': () => import('areas/services/widgets/singleGraph'),
		'service-multi-graph': () => import('areas/services/widgets/multiGraph'),
		'service-gauge': () => import('areas/services/widgets/gauge'),
		'service-document': () => import('areas/service-boards/widgets/serviceDocument/serviceDocumentWidget'),
		'metric-single-graph': () => import('areas/service-boards/widgets/metricSingleGraph'),
		'metric-multi-graph-asset': () => import('areas/service-boards/widgets/metricMultigraphAsset'),
		'metric-multi-graph-asset-group': () => import('areas/service-boards/widgets/metricMultigraphAssetGroup'),
		'metric-gauge': () => import('areas/service-boards/widgets/metricGauge'),
		'metric-stacked-chart': () => import('areas/service-boards/widgets/stackChartWidget/stackedChart'),
		'metric-custom': () => import('areas/service-boards/widgets/customMetrics/metricCustom'),
		'metric-barchart': () => import('areas/service-boards/widgets/barchartMetrics/barchartWidget'),
		'metric-table': () => import('./metrics/tableWidget'),
		'health-single-graph': () => import('areas/assets/widgets/singleGraph'),
		'health-multi-graph': () => import('areas/assets/widgets/multiGraph'),
		'health-gauge': () => import('areas/assets/widgets/gauge'),
		'asset-grid': () => import('areas/assets/widgets/grid'),
		'asset-console': () => import('areas/assets/widgets/console'),
		'asset-state': () => import('areas/assets/widgets/state'),
		'sla': () => import('areas/sla/widgets/sla'),
		'sla-history': () => import('areas/sla/widgets/history'),
		'sla-state': () => import('areas/sla/widgets/state'),
		'sla-time': () => import('areas/sla/widgets/time'),
		'sla-grid': () => import('areas/sla/widgets/grid'),
		'kpi-single-graph': () => import('areas/kpi/widgets/singleGraph'),
		'kpi-multi-graph': () => import('areas/kpi/widgets/multiGraph'),
		'kpi-gauge': () => import('areas/kpi/widgets/gauge'),
		'kpi-history': () => import('areas/kpi/widgets/history'),
		'summary-grid': () => import('areas/service-boards/widgets/grids/summary'),
		'reasons-grid': () => import('areas/service-boards/widgets/grids/reasons'),
		'incidents-grid': () => import('areas/service-boards/widgets/grids/incidents'),
		'event-summary-grid': () => import('areas/service-boards/widgets/grids/eventSummary'),
		'text': () => import('areas/service-boards/widgets/text'),
		'time': () => import('controls/designer/features/widgets/custom/timeWidget'),
		'gps': () => import('controls/designer/features/widgets/custom/gps/gpsWidget'),
		'web-page': () => import('areas/service-boards/widgets/webPageWidget'),
		'dashboard': () => import('controls/designer/features/widgets/custom/dashboard'),
		'cloud-services-cost-bar-chart': () => import('controls/designer/features/widgets/cloudServices/costBarChart'),
		'cloud-services-cost-grid': () => import('controls/designer/features/widgets/cloudServices/costGrid'),
		'cloud-services-instances-grid': () => import('controls/designer/features/widgets/cloudServices/instancesGrid'),
		'cloud-services-buggle-chart': () => import('controls/designer/features/widgets/cloudServices/bubbleChart'),
		'cost-grid': () => import('controls/designer/features/widgets/cost/gridWidget'),
		'cost-profile': () => import('controls/designer/features/widgets/cost/profile/costProfileWidget'),
		'budget-cost-bar-chart': () => import('controls/designer/features/widgets/cost/budget/budgetBarChart'),
		'event-state': () => import('areas/service-boards/widgets/eventState/eventStateWidget')
	}

export async function getWidgetDescription(widgetType) {
	const module = await allWidgets[widgetType]();
	return module.getWidgetDescription();
}
