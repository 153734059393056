import React from 'react';
import ReactDOM from "react-dom";
import {Utils, applyRequired} from 'tools/utils';
import Cookies from 'core/cookies';
import Settings from 'settings';
import MultiToggle from 'controls/multiToggle';
import GridMenu from 'controls/gridMenu';
import ErrorHandler from 'core/errorHandler';
import State from 'tools/state';
import ConfigurationBase from 'areas/service-boards/configurationBase';
import {AntSelect} from "controls/react/ant/antSelect";
import FormEntry from "controls/react/form/formEntry";
import aggregationTypeDataSource from './common/dataSources/aggregationTypeDataSource';
import { ConversionWindow } from "./common/conversionWindow";

export function GenericMetricsWidgetConfigurationLegacy(config) {
	Utils.apply(this, config);

	this._initComponent();
	return this;
};

export {GenericMetricsWidgetConfigurationLegacy as default};

jQuery.extend(GenericMetricsWidgetConfigurationLegacy.prototype, ConfigurationBase.prototype, {
	/**
	 * @cfg {jQuery element} renderTo Mandatory. The jQuery element where the form is rendered
	 */
	renderTo: null,
	/*
	 * Init the component
	 * */
	_initComponent: async function () {
		await this.completeWidgetObj();
		this.selectedMetrics = {};

		if (!$('#widget_wrapper').length) {
			$('body').append('<div id="widget_wrapper" class="cw_designer_area"></div>');
		}
		this.widgetWrapper = $('#widget_wrapper');

		this._removeListeners();
		this._attachListeners();

		this._render();
		this._initKendoComponents();

		const requiredFields = ['#cw_account'];
		applyRequired(requiredFields);
	},

	async completeWidgetObj() {
		let data = this.widgetObj.configuration.metrics || [];
		let url = `${Settings.serverPath}accounts/${Cookies.CeesoftCurrentAccountId}/metrics/registeredMetrics`;
		let result = await Api.fetchPost(url, data);
		if (this.widgetObj.configuration.metricsItems) {
			for (var i = 0; i < this.widgetObj.configuration.metricsItems.length; i++) {
				for (var j = 0; j < result.length; j++) {
					if (this.widgetObj.configuration.metricsItems[i].metricId === result[j].metricId) {
						result[j].customUnit = this.widgetObj.configuration.metricsItems[i].customUnit;
						result[j].conversion = this.widgetObj.configuration.metricsItems[i].conversion;
						result[j].color = this.widgetObj.configuration.metricsItems[i].color;
					}
				}
			}
		}
		this.widgetObj.configuration.metricsItems = result;
		for (var i = 0; i < this.widgetObj.configuration.metricsItems.length; i++) {
			this.widgetObj.configuration.metricsItems[i].id = Utils.guid();
		}
		let container = this.widgetConfigurationFormEl
		container.empty().html('<div id="cw_metrics_form"></div>');
		this.renderTo = $('#cw_metrics_form');
	},

	/*
	 * Renders the form
	 * */
	_render: function () {
		var widgetObj = this.widgetObj;

		var html = '<fieldset>';
		html += '<legend class="cw_widget_form_legend">' + lang.CONFIGURATION + '</legend>';
		html += '<span class="cw_toggle_container glyphicons minus pointer"></span>';
		html += '<div class="cw_configuration_placeholder pointer hide">' + lang.widget.messages.MAXIMIZE_SETTINGS + '</div>';
		html += '<div class="cw_configuration_content">';
		html += '<div class="cw_field">';
		html += '<label class="cw_inline">' + lang.TITLE + '</label>';
		html += '<input class="cw_inline" id="cw_title" value="' + (widgetObj.title || '') + '"/>';
		html += '</div>';
		html += '<div class="cw_field" id="cw_account_container">';
		html += '<label class="cw_inline">' + lang.ACCOUNT + '</label>';
		html += '<div class="cw_dropdown_container">';
		html += '<input id="cw_account" type="text" value="' + (widgetObj.configuration.accountId || '') + '" />';
		html += '</div>';
		html += '</div>';
		html += '</div>';
		html += '</fieldset>';
		html += '<fieldset>';
		html += '<legend class="cw_widget_form_legend">' + lang.widget.DISPLAY_SETTINGS + '</legend>';
		html += '<span class="cw_toggle_container glyphicons minus pointer"></span>';
		html += '<div class="cw_configuration_placeholder pointer hide">' + lang.widget.messages.MAXIMIZE_SETTINGS + '</div>';
		html += '<div class="cw_configuration_content">';
		html += '<div class="cw_field">';
		html += '<label class="cw_inline">' + lang.PERIOD + '</label>';
		html += '<div id="cw_period"></div>';
		html += '<div class="cw_hide_time_selector">';
		html += '<input type="checkbox" class="k-checkbox" id="cw_hide_time_selector">';
		html += '<label for="cw_hide_time_selector" class="k-checkbox-label">' + lang.widget.HIDE_TIME_SELECTOR + '</label>';
		html += '</div>';
		html += '</div>';
		html += '<div class="cw_field" id="cw_chart_type_container">';
		html += '<label class="cw_inline">' + lang.TYPE + '</label>';
		html += '<div class="cw_dropdown_container">';
		html += '<input id="cw_chart_type" type="text" />';
		html += '</div>';
		html += '</div>';
		html += '<div class="cw_field">';
		html += '<label class="cw_inline">' + lang.AGGREGATION_TYPE + '</label>';
		html += '<div class="cw_dropdown_container">';
		html += '<input id="cw_aggregation_type" type="text" />';
		html += '</div>';
		html += '</div>';
		html += '<div class="cw_field cw_no_label hide" id="cw_dates">';
		html += '<input id="cw_startdate" type="text" /> - <input id="cw_enddate" type="text" />';
		html += '</div>';
		html += '<div class="cw_field" style="width:50%">';
		html += '<input type="checkbox" class="k-checkbox" id="cw_ignore_missing_data">';
		html += '<label for="cw_ignore_missing_data" class="k-checkbox-label">' + lang.serviceBoard.IGNORE_MISSING_DATA + '</label>';
		html += '</div>';
		html += '<div id="cw_threshold_field" class="cw_field" style="width:50%">';
		html += '<input type="checkbox" class="cw_show_threshold k-checkbox" id="cw_show_threshold" title="" checked></span>';
		html += '<label for="cw_show_threshold" class="k-checkbox-label">' + lang.SHOW_THRESHOLD + '</label>';
		html += '</div>';
		html += '<div class="cw_field">';
		html += '<input type="checkbox" class="k-checkbox" id="cw_show_regression">';
		html += '<label for="cw_show_regression" class="k-checkbox-label">' + lang.SHOW_REGRESSION + '</label>';
		html += '</div>';
		html += '<div class="cw_field">';
		html += '<label class="cw_inline">' + lang.TIMEZONE + '</label>';
		html += '<div class="cw_dropdown_container">';
		html += '<input id="cw_timezone" value="' + (widgetObj.configuration.timezone || Cookies.CeesoftTimezone) + '" />';
		html += '</div>';
		html += '</div>';
		html += '<div class="cw_field">';
		html += '<label class="cw_inline">Selector type:</label>';
		html += '<ul id="cw_legend_type_toggle" class="cw_multi_toggle right"></ul>';
		html += '</div>';
		html += '<div id="cw_legend_container" class="cw_field hide">';
		html += '<label class="cw_inline_full">' + lang.LEGEND + '</label>';
		html += '<input id="cw_legend" />';
		html += '</div>';
		/*html += '<div id="cw_informational_container" class="cw_field">';
		html += '<label class="cw_inline_full">' + lang.INFORMATIONAL + '</label>';
		html += '<input id="cw_metrics_informational" type="text" />';
		html += '</div>';*/
		html += '</fieldset>';
		html += '<div class="cw_section cw_selected_metrics">';
		html += '<div class="cw_section_titlebar">';
		html += '<div class="cw_section_title">';
		html += '<div id="cw_selected_metrics_menu" class="cw_global_actions"></div>' + lang.widget.SELECTED_METRICS;
		html += '</div>';
		html += '<button id="cw_select_available_metrics" class="k-button k-primary right" style="margin-right: 15px; ">' + lang.dashboard.SELECT_METRICS + '</button>';
		/*html += '<div id="cw_select_available_metrics" class="right pointer">';
		html += '<span style="font-weight: bold; padding: 4px 15px">' + lang.SHOW_METRICS + '</span>';
		html += '<span class="glyphicons show-thumbnails" title="' + lang.serviceBoard.AVAILABLE_METRICS + '"></span>';
		html += '</div>';*/
		html += '<div class="right" style="margin: 0 15px; height: 29px; width: 32px; cursor: pointer; ">';
		html += '<span id="cw_toggle_advanced" class="glyphicons list advanced_metrics_toggle_legacy"></span>';
		/*html += '<input type="checkbox" class="k-checkbox" id="cw_toggle_advanced">';
		html += '<label for="cw_toggle_advanced" class="k-checkbox-label">' + lang.serviceBoard.ADVANCED_MODE + '</label>';*/
		html += '</div>';
		html += '</div>';
		html += '</div>';
		html += '<div class="cw_section_content">';
		html += '<div id="cw_selected_metrics" style="border: 1px solid #CCCCCC"></div>';
		html += '</div>';
		html += '</div>';

		this.renderTo.html(html);
		if (this.mode === 'new') {
			this.setMinimized();
		}
	},
	/**
	 * Removes listeners
	 */
	_removeListeners: function () {
		this.renderTo.off('click', '#cw_selected_metrics .cw_grid_check');
		if (this.selection !== 'single') {
			this.renderTo.off('click', '#cw_selected_metrics .cw_grid_check_all');
			this.widgetWrapper.off('click', '.cw_grid_check_all');
		}

		this.widgetWrapper.off('click', '.cw_grid_check');
		this.renderTo.off('click', '#cw_select_available_metrics');
		this.widgetWrapper.off('keyup', '#cw_available_metrics_search');
	},
	/**
	 * Attaches listeners
	 */
	_attachListeners: function () {
		if (this.selection !== 'single') {
			this.renderTo.on('click', '#cw_selected_metrics .cw_grid_check_all', $.proxy(this.onSelectedMetricsCheckAllClick, this));
			this.widgetWrapper.on('click', '.cw_grid_check_all', $.proxy(this.onAvailableMetricsCheckAllClick, this));
		}

		this.renderTo.on('click', '#cw_selected_metrics .cw_grid_check', $.proxy(this.onSelectedMetricsCheckClick, this));
		this.widgetWrapper.on('click', '.cw_grid_check', $.proxy(this.onAvailableMetricsCheckClick, this));

		this.renderTo.on('click', '#cw_select_available_metrics', $.proxy(this.renderAvailableMetricsWindow, this));
		this.widgetWrapper.on('keyup', '#cw_available_metrics_search', $.proxy(this.onAvailableMetricsSearch, this));
		this.renderTo.on('click', '.cw_toggle_container', $.proxy(this.onToggleConfiguration, this));
		this.renderTo.on('click', '.cw_configuration_placeholder', $.proxy(this.onToggleConfiguration, this));
		this.renderTo.on('click', '#cw_toggle_advanced', $.proxy(this.onToggleAdvanced, this));
	},
	setMinimized: function () {
		this.renderTo.find('.cw_configuration_content').addClass('hide');
		this.renderTo.find('.cw_configuration_placeholder').removeClass('hide');
		this.renderTo.find('.cw_toggle_container').each(function() {
			$(this).removeClass('minus').addClass('unchecked');
		});
	},
	/**
	 * Initialize Kendo components
	 */
	_initKendoComponents: function () {
		var widgetObj = this.widgetObj;

		if (this.wasUpdate) {
			$('#cw_hide_time_selector').prop('checked', widgetObj.configuration.hideTimeSelector);
			$('#cw_ignore_missing_data').prop('checked', widgetObj.configuration.ignoreMissingData);
			$('#cw_show_regression').prop('checked', widgetObj.configuration.showRegression);
			$('#cw_show_threshold').prop('checked', widgetObj.configuration.showThreshold);
		}

		this.chartType = $('#cw_chart_type').kendoDropDownList({
			dataSource: [{
				text: lang.AREA_CHART,
				value: 'area'
			}, {
				text: lang.AREASPLINE_CHART,
				value: 'areaspline'
			}, {
				text: lang.LINE_CHART,
				value: 'line'
			}, {
				text: lang.RANGE_CHART,
				value: 'arearange'
			}, {
				text: lang.SPLINE_CHART,
				value: 'spline'
			}],
			dataTextField: 'text',
			dataValueField: 'value',
			value: widgetObj.configuration.chartType || 'line'
		}).data('kendoDropDownList');

		this.aggregationType = $('#cw_aggregation_type').kendoDropDownList({
			dataSource: aggregationTypeDataSource,
			dataTextField: 'text',
			dataValueField: 'value',
			value: widgetObj.configuration.aggregationType || 'average'
		}).data('kendoDropDownList');

		this.createAccountComponent({
			element: $('#cw_account'),
			container: $('#cw_account_container')
		});

		this.periodToggle = new MultiToggle({
			id: 'cw_period',
			cssClass: 'cw_multi_toggle right',
			value: widgetObj.configuration.period || 'LASTDAY',
			type: 'label',
			items: [{
				id: 'cw_metrics_period_lasthour',
				title: lang.periodMultiToggle.HOUR,
				label: lang.periodMultiToggle.HOUR,
				selected: false,
				fn: this.onPeriodToggle,
				scope: this,
				value: 'LASTHOUR'
			}, {
				id: 'cw_metrics_period_lastday',
				title: lang.periodMultiToggle.DAY,
				label: lang.periodMultiToggle.DAY,
				selected: true,
				fn: this.onPeriodToggle,
				scope: this,
				value: 'LASTDAY'
			}, {
				id: 'cw_metrics_period_lastweek',
				title: lang.periodMultiToggle.WEEK,
				label: lang.periodMultiToggle.WEEK,
				selected: false,
				fn: this.onPeriodToggle,
				scope: this,
				value: 'LAST7DAYS'
			}, {
				id: 'cw_metrics_period_lastmonth',
				title: lang.periodMultiToggle.MONTH,
				label: lang.periodMultiToggle.MONTH,
				selected: false,
				fn: this.onPeriodToggle,
				scope: this,
				value: 'LAST30DAYS'
			}, {
				id: 'cw_metrics_period_custom',
				title: lang.CUSTOM,
				label: lang.CUSTOM,
				selected: false,
				fn: this.onPeriodToggle,
				scope: this,
				value: 'CUSTOM'
			}]
		});

		this.legendTypeToggle = new MultiToggle({
			id: 'cw_legend_type_toggle',
			cssClass: 'cw_multi_toggle right',
			value: 'line',
			type: 'label',
			items: [{
				id: 'cw_legend_type_legend',
				title: lang.LEGEND,
				label: lang.LEGEND,
				selected: false,
				value: 'legend',
				fn: this.onLegendTypeToggle
			}, {
				id: 'cw_legend_type_none',
				title: lang.NONE,
				label: lang.NONE,
				selected: true,
				value: 'none',
				fn: this.onLegendTypeToggle
			}/*{
				id: 'cw_legend_type_informational',
				title: lang.INFORMATIONAL,
				label: lang.INFORMATIONAL,
				selected: true,
				value: 'informational',
				fn: this.onLegendTypeToggle
			}*/]
		});

		$("#cw_startdate").kendoDateTimePicker({
			format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
			timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat)
		});
		$("#cw_enddate").kendoDateTimePicker({
			format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
			timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat)
		});

		$('#cw_timezone').kendoComboBox({
			dataSource: new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: Settings.serverPath + 'sessions/timezones/?addUserTime=true',
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				error: ErrorHandler.kendoServerError
			}),
			autoBind: true
		});

		this.legendDataSource = [{
			name: '<Asset>',
			id: '<Asset>'
		}, {
			name: '<Name>',
			id: '<Name>'
		}, {
			name: '<Type>',
			id: '<Type>'
		}, {
			name: '<Category>',
			id: '<Category>'
		}, {
			name: '<Instance>',
			id: '<Instance>'
		}];

		this.informationalDataSource = [{
			name: '<Asset>',
			id: '<Name>'
		}, {
			name: '<Type>',
			id: '<Type>'
		}, {
			name: '<Category>',
			id: '<Category>'
		}, {
			name: '<Instance>',
			id: '<Instance>'
		}, {
			name: '<Period>',
			id: '<Period>'
		},{
			name: '<Timezone>',
			id: '<Timezone>'
		}];

		//this.renderInformationalMultiSelect();

		var dataSource = [];
		if (this.mode === 'update' || this.wasUpdate) {
			this.periodToggle.setSelectedItem(widgetObj.configuration.period);
			this.legendTypeToggle.setSelectedItem(widgetObj.configuration.legendType || 'none');
			if (widgetObj.configuration.legendType === 'legend') {
				$('#cw_legend_container').removeClass('hide');
				//$('#cw_informational_container').addClass('hide');
				if (!widgetObj.configuration.labelTemplate) {
					this.legendMultiList = [];
				} else {
					this.legendMultiList = widgetObj.configuration.labelTemplate;
				}
				this.informationalMultiList = this.informationalDataSource;
			} else {
				if (!widgetObj.configuration.informationalTemplate) {
					this.informationalMultiList = this.informationalDataSource;
				} else {
					this.informationalMultiList = widgetObj.configuration.informationalTemplate;
				}
				this.legendMultiList = [];
			}

			this.dataSource = widgetObj.configuration.metricsItems || [];

			for (var i = 0, length = this.dataSource.length; i < length; i++) {
				this.selectedMetrics[this.dataSource[i].metricId] = this.dataSource[i];
			}
		}

		this.renderLegendMultiSelect();

		let singleSelectEditor = function (container, options) {
			$('<input data-bind="value:' + options.field + '"/>')
				.appendTo(container)
				.kendoDropDownList({
					suggest: true,
					dataSource: ["",
						"#7cb5ec",
						"#434348",
						"#90ed7d",
						"#8085e9",
						"#f15c80",
						"#e4d354",
						"#2b908f",
						"#f45b5b",
						"#91e8e1"],
					template: `<div style="background-color: #=data#; width: 20px; height: 20px;"></div>`,
					valueTemplate: `<div style="background-color: #=data#; width: 20px; height: 20px;"></div>`
				});
		};

		var scope = this;

		let gridHeight = 150;
		let showAdvanced = false;
		if (scope.dataSource) {
			for (let i = 0; i < scope.dataSource.length; i++) {
				if (scope.dataSource[i].color || scope.dataSource[i].conversion || scope.dataSource[i].customUnit) {
					showAdvanced = true;
				}
			}
		}

		if (showAdvanced) {
			$('#cw_toggle_advanced').addClass('selected_advanced_metrics_toggle').attr('title', lang.dashboard.HIDE_ADVANCED);
		} else {
			$('#cw_toggle_advanced').removeClass('selected_advanced_metrics_toggle').attr('title', lang.dashboard.SHOW_ADVANCED);
		}

		this.gridSchema = {
			model: {
				id: 'id',
				fields: {
					'id': {editable: false},
					'assetName': {editable: false},
					'categoryNode': {editable: false},
					'registryType': {editable: false},
					'identifier': {editable: false},
					'instanceName': {editable: false},
					'unitType': {editable: false},
					'conversion': {
						type: 'string',
						editable: false
					},
					'color': {editable: true}
				}
			}
		};
		this.selectedMetricsGrid = $('#cw_selected_metrics').kendoCustomGrid({
			sortable: true,
			editable: true,
			height: gridHeight,
			dataSource: new kendo.data.DataSource({
				data: scope.dataSource,
				schema: this.gridSchema
			}),
			dataBound: function () {
				//to be improved by checkin if no of entries actually requires scroll
				if (!this.dataSource.data().length) {
					let noRecordsContainer = scope.renderTo.find('.k-grid-norecords');
					noRecordsContainer.empty().append('<span class="cw_no_records_info">' + lang.widget.messages.SELECT_NEW_METRICS + '</span>');
					noRecordsContainer.addClass('pointer cw_no_records_container').on('click', $.proxy(scope.renderAvailableMetricsWindow, scope));
					scope.renderTo.find('.k-grid-content').css('overflow-y', 'hidden');
				} else {
					scope.renderTo.find('.k-grid-content').css('overflow-y', 'scroll');
				}
				$('.cw_conversion').closest('td').addClass('pointer').on('click', (e) => {
					scope.onConversion(e);
				});
				$('.cw_metric_item_delete').on('click', (e) => {
					scope.onMetricItemDelete(e);
				});
			},
			columns: [{
				field: 'id',
				title: lang.SELECTOR,
				editable: false,
				template: '<input type="checkbox" class="cw_grid_check" id="${metricId}" data-id="${id}" />',
				headerTemplate: this.selection === 'single' ? '' : '<input type="checkbox" class="cw_grid_check_all" />',
				attributes: {
					'class': 'text_center'
				},
				headerAttributes: {
					'class': 'text_center'
				},
				width: 40
			}, {
				field: 'assetName',
				title: lang.ASSET,
				editable: false,
				width: 70
			}, {
				field: 'categoryNode',
				title: lang.NAME,
				editable: false,
				width: 70
			}, {
				field: 'registryType',
				title: lang.TYPE,
				editable: false,
				width: 70
			}, {
				field: 'identifier',
				title: lang.CATEGORY,
				editable: false,
				width: 70
			}, {
				field: 'instanceName',
				title: Lang.serviceBoard.INSTANCE_NAME,
				editable: false,
				width: 70
			}, {
				field: 'unitType',
				title: lang.UNIT,
				editable: false,
				width: 70
			}, {
				field: 'customUnit',
				title: lang.CUSTOM_UNIT,
				editable: true,
				hidden: !showAdvanced,
				width: 78
			}, {
				field: 'conversion',
				title: lang.CONVERSION,
				editable: true,
				hidden: !showAdvanced,
				template: function (data) {
					return `<span class="cw_conversion ellipsis" decimals="${data.decimals}" title="${data.conversion || ''}">${data.conversion || ''} </span>`
				},
				width: 78
			}, {
				field: 'color',
				title: lang.COLOR,
				editor: singleSelectEditor,
				hidden: !showAdvanced,
				template: '<div class="cw_selected_color" style="width: 20px; height: 20px; background-color: #=data.color#;" data-color="#=data.color#" data-id="#=data.metricId#"></div>',
				width: 78
			}, {
				field: '',
				editable: false,
				template: '<span class="cw_metric_item_delete pointer glyphicons bin"></span>',
				width: 30
			}]
		}).data('kendoCustomGrid');

		//workaround for this widget only; to be removed from CSS after all widgets will be aligned
		//$('#create_widget').css('overflow-y', 'initial');

		// Add Kendo tooltip to the header of the columns
		Utils.gridColumnHeaderTooltip(this.selectedMetricsGrid);
		this.adjustSectionHeight();

		this.selectedMetricsGridMenu = new GridMenu({
			renderTo: 'cw_selected_metrics_menu',
			items: [{
				id: 'cw_selected_metrics_add',
				icon: 'plus',
				text: lang.ADD,
				fn: this.renderAvailableMetricsWindow,
				scope: this,
				disabled: false
			}, {
				id: 'cw_selected_metrics_remove',
				icon: 'bin',
				text: lang.DELETE,
				fn: this.onSelectedMetricsRemove,
				scope: this,
				disabled: true
			}]
		});

		/*if ((this.mode === 'update' || this.wasUpdate) && this.widgetObj.configuration.metrics.length > 1) {
			this.hideMultigraphItems();
		}*/
	},

	onConversion(e) {
		e.preventDefault();
		this.target = $(e.target).closest('td');
		this.target.after('<div id="conversion_react_container"></div>');
		this.reactTarget = $('#conversion_react_container');
		this.formula = this.target.text();
		let decimals = this.target.find('span').attr('decimals');
		let targetOffset = this.target.offset();
		this.metricId = this.target.closest('tr').find('.cw_grid_check').attr('id');
		ReactDOM.render(<ConversionWindow
			metricId={this.metricId}
			target={this.target}
			reactTarget={this.reactTarget}
			onUpdate={this.onConversionUpdate}
			onClose={this.onConversionClose}
			offset={targetOffset}
			formula={this.formula}
			decimals={decimals}
		/>,  this.reactTarget.get()[0]);
		setTimeout(() => {
			this.target.text(this.formula)
		});
	},

	onMetricItemDelete(e) {
		let selectedDataSource = $('#cw_selected_metrics').data('kendoCustomGrid').dataSource;
		let uid = $(e.target).closest('tr').data('uid');
		let item = selectedDataSource.getByUid(uid);
		selectedDataSource.remove(item);
		$('[data-metricid="' + item.metricId + '"]').prop('disabled', false);
		this.selectedMetrics[item.metricId] = null;
		delete this.selectedMetrics[item.metricId];
	},

	onConversionUpdate(formula, decimals) {
		let row = $('#' + this.metricId).closest('tr');
		let grid = row.closest('.k-grid').data('kendoCustomGrid');
		let dataSourceArray = grid.dataSource.data().toJSON();
		for (let i = 0; i < dataSourceArray.length; i++) {
			if (dataSourceArray[i].metricId === this.metricId) {
				dataSourceArray[i].conversion = formula;
				dataSourceArray[i].decimals = decimals;
			}
		}
		let dataSource = new kendo.data.DataSource({
			data: dataSourceArray,
			schema: this.gridSchema
		});
		grid.setDataSource(dataSource);
		ReactDOM.unmountComponentAtNode(this.reactTarget.get()[0]);
		$('#conversion_react_container').remove();
	},

	onConversionClose() {
		if (!this.reactTarget && this.wrapper) {
			let metricId = this.wrapper.find('.notification_container').attr('current-conversion-id');
			let row = $('#' + metricId).closest('tr');
			this.reactTarget = row.find('#conversion_react_container');
		}
		ReactDOM.unmountComponentAtNode(this.reactTarget.get()[0]);
		$('#conversion_react_container').remove();
	},

	renderLegendMultiSelect: function() {
		ReactDOM.render(<FormEntry label={lang.LEGEND} vertical style={{maxWidth: '600px'}}>
			<AntSelect
				dataList={this.legendDataSource}
				mode={"multiple"}
				onChange={value => this.onLegendChange(value)}
				defaultValue={this.legendMultiList || []}
				placeholder={lang.SELECT} />
		</FormEntry>,  $('#cw_legend').parent().get()[0]);
	},
	onLegendChange: function(value) {
		this.legendMultiList = value || [];
	},
	renderInformationalMultiSelect: function() {
		ReactDOM.render(<FormEntry label={lang.INFORMATIONAL} vertical style={{maxWidth: '600px'}}>
			<AntSelect
				dataList={this.informationalDataSource}
				mode={"multiple"}
				sortValues={true}
				onChange={value => this.onInformationalChange(value)}
				defaultValue={this.informationalMultiList || []}
				placeholder={lang.SELECT} />
		</FormEntry>, $('#cw_metrics_informational').parent().get()[0]);
	},
	onInformationalChange: function(value) {
		this.informationalMultiList = value || [];
	},
	onToggleConfiguration: function (e) {
		var target = $(e.target);
		var currentFieldset = target.closest('fieldset');
		var currentToggleContainer = currentFieldset.find('.cw_toggle_container');
		currentFieldset.find('.cw_configuration_content').toggleClass('hide');
		currentFieldset.find('.cw_configuration_placeholder').toggleClass('hide');

		if (currentToggleContainer.hasClass('minus')) {
			currentToggleContainer.removeClass('minus').addClass('unchecked');
		} else {
			currentToggleContainer.removeClass('unchecked').addClass('minus');
		}
	},

	onToggleAdvanced(e) {
		if ($(e.target).hasClass('selected_advanced_metrics_toggle')) {
			this.selectedMetricsGrid.hideColumn('customUnit');
			this.selectedMetricsGrid.hideColumn('conversion');
			$(e.target).attr('title', lang.dashboard.SHOW_ADVANCED);
		} else {
			this.selectedMetricsGrid.showColumn('customUnit');
			this.selectedMetricsGrid.showColumn('conversion');
			$(e.target).attr('title', lang.dashboard.HIDE_ADVANCED);
		}
		$(e.target).toggleClass('selected_advanced_metrics_toggle');
	},

	/**
	 * Handler function for hiding the unnecesary items when multigraph case
	 */
	hideMultigraphItems: function () {
		this.legendTypeToggle.setSelectedItem('legend');
		$('#cw_legend_type_toggle').css('pointer-events', 'none').find('.last_btn').css('color', '#B0B0B0');
		$('#cw_legend_container').removeClass('hide');
		$('#cw_informational_container').addClass('hide');
		$('#cw_threshold_field').addClass('hide');
		this.chartType.value('line');
		this.chartType.enable(false);
	},
	/*
	* Checks the selected metric checkbox
	* @param {Object} e The click event object
	* */
	onSelectedMetricsCheckClick: function (e) {
		var checkboxes = $('#cw_selected_metrics .cw_grid_check:checked');
		if (checkboxes.length) {
			this.selectedMetricsGridMenu.enableItem('cw_selected_metrics_remove');
		} else {
			this.selectedMetricsGridMenu.disableItem('cw_selected_metrics_remove');
		}
	},
	onCustomUnitUpdate: function (e) {
		var customUnit = $(e.currentTarget).val();
		var id = $(e.currentTarget).closest('tr').find('.cw_grid_check').attr('data-id');
		var dataSource = this.selectedMetricsGrid.dataSource;
		var total = dataSource.total();
		for (var i = 0; i < total; i++) {
			var record = dataSource.at(i);
			if (record.customUnit === customUnit && record.id !== id) {
				this.actionNotification.setOptions({
					message: lang.assethealth.messages.RULE_EXISTS,
					status: 'error'
				}).show();
				var reopenCell = function () {
					this.rulesGrid.editCell(this.nameContainer);
				};
				setTimeout($.proxy(reopenCell, this), 200);
				break;
			}
		}
	},
	/*
	 * Checks all the selected metric checkboxes
	 * @param {Object} e The click event object
	 * */
	onSelectedMetricsCheckAllClick: function (e) {
		$('#cw_selected_metrics .cw_grid_check').prop('checked', $(e.currentTarget).is(':checked'));
		this.onSelectedMetricsCheckClick();
	},
	/*
	 * Checks the available metric checkbox
	 * @param {Object} e The click event object
	 * */
	onAvailableMetricsCheckClick: function () {
		var checkboxes = $('#cw_available_metrics .cw_grid_check:checked');
		if (checkboxes.length >= 1) {
			$('#cw_add_metrics_button').removeAttr('disabled');
		} else {
			$('#cw_add_metrics_button').attr('disabled', 'disabled');
		}
	},
	/*
	 * Checks all the available metric checkboxes
	 * @param {Object} e The click event object
	 * */
	onAvailableMetricsCheckAllClick: function (e) {
		$('#cw_available_metrics .cw_grid_check').prop('checked', $(e.currentTarget).is(':checked'));
		this.onAvailableMetricsCheckClick();
	},
	/*
	* Removed the selected metrics
	* */
	onSelectedMetricsRemove: function () {
		var checkboxes = $('#cw_selected_metrics .cw_grid_check:checked'), item,
			selectedDataSource = $('#cw_selected_metrics').data('kendoCustomGrid').dataSource;
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			var uid = $(checkboxes[i]).closest('tr').data('uid');
			item = selectedDataSource.getByUid(uid);
			selectedDataSource.remove(item);

			$('[data-metricid="' + item.metricId + '"]').prop('disabled', false);

			this.selectedMetrics[item.metricId] = null;
			delete this.selectedMetrics[item.metricId];
		}
		if (this.selection === 'single') {
			$('#cw_available_metrics .cw_grid_check').prop('disabled', false);
		}
		if (selectedDataSource.data().length <= 1) {
			$('#cw_legend_type_toggle').css('pointer-events', 'initial').find('.last_btn').css('color', 'initial');
			$('#cw_threshold_field').removeClass('hide').find('input').prop('checked', true);
			this.chartType.enable(true);
		}
	},
	/**
	 * Handler function for the click event on qualifier type toggle
	 * @param {String} value The associated value of the clicked item
	 */
	onPeriodToggle: function (value) {
		if (value === 'CUSTOM') {
			$('#cw_dates').removeClass('hide');
		} else {
			$('#cw_dates').addClass('hide');
		}
	},
	/**
	 * Handler function for the click event on legend type toggle
	 * @param {String} value The associated value of the clicked item
	 */
	onLegendTypeToggle: function (value) {
		if (value === 'legend') {
			$('#cw_legend_container').removeClass('hide');
			//$('#cw_informational_container').addClass('hide');
		} else {
			$('#cw_legend_container').addClass('hide');
			//$('#cw_informational_container').removeClass('hide');
		}
	},
	/*
	* Searches the available metrics grid
	* @param {Object} e The key up event object
	* */
	onAvailableMetricsSearch: function (e) {
		var value = $(e.currentTarget).val();
		var dataSource = $('#cw_available_metrics').data('kendoCustomGrid').dataSource;
		if (value) {
			dataSource.filter({
				filters: [{
					field: 'categoryNode',
					operator: 'contains',
					value: value
				}, {
					field: 'identifier',
					operator: 'contains',
					value: value
				}, {
					field: 'instanceName',
					operator: 'contains',
					value: value
				}],
				logic: 'or'
			});
		} else {
			dataSource.filter({});
		}
	},
	/*
	* Gets the form values
	* */
	getValues: function () {
		var title = $('#cw_title').val();
		var ignoreMissingData = $('#cw_ignore_missing_data').is(':checked'),
			showRegression = $('#cw_show_regression').is(':checked'),
			showThreshold = $('#cw_show_threshold').is(':checked');
		var widgetObj = {
			id: this.mode === 'update' || this.wasUpdate ? this.widgetObj.id : Utils.guid(),
			type: this.type,
			title: title,
			configuration: {
				accountId: this.widgetAccountId || $('#cw_account').data('kendoComboBox').value(),
				//assetId: $('#cw_asset').data('kendoComboBox').value(),
				chartType: this.chartType.value(),
				aggregationType: this.aggregationType.value(),
				period: this.periodToggle.getValue(),
				startDate: $('#cw_startdate').data('kendoDateTimePicker').value(),
				endDate: $('#cw_enddate').data('kendoDateTimePicker').value(),
				timezone: $('#cw_timezone').data('kendoComboBox').value(),
				hideTimeSelector: $('#cw_hide_time_selector').is(':checked'),
				labelTemplate: this.legendMultiList,
				ignoreMissingData: ignoreMissingData,
				showRegression: showRegression,
				showThreshold: showThreshold,
				legendType: this.legendTypeToggle.getValue(),
				informationalTemplate: this.informationalMultiList
			}
		};

		var items = this.selectedMetricsGrid.dataSource.data(), metrics = [], metricsItems = [], item;

		for (var i = 0, length = items.length; i < length; i++) {
			item = items[i];
			metrics.push(item.metricId);
			item.color = $(`.cw_selected_color[data-id='${item.metricId}']`).attr('data-color');

			if (item.color === undefined || item.color === 'undefined') {
				delete item.color;
			}

			metricsItems.push(item);
		}

		widgetObj.configuration.metrics = metrics;
		widgetObj.configuration.metricsItems = metricsItems;

		return widgetObj;
	},
	/**
	 * Validates the widget config form
	 * @return {Boolean} valid True if the form is valid, false otherwise
	 */
	isValid: function () {
		var valid = true;
		this.validationMessage = '';

		var accountCombo = $('#cw_account').data('kendoComboBox');
		if (!Utils.isGuid(accountCombo.value())) {
			valid = false;
			this.validationMessage = lang.widget.SELECT_ACCOUNT;
		}

		var items = this.selectedMetricsGrid.dataSource.data();
		if (!items.length) {
			valid = false;
			this.validationMessage = lang.widget.SELECT_METRICS;
		}
		if (this.errorFields && this.errorFields.length) {
			valid = false;
			if (this.errorFields.length > 1) {
				this.validationMessage = lang.widget.SELECT_MISSING_FIELDS;
			} else {
				this.validationMessage = lang.widget.SELECT_MISSING_FIELD;
			}
		}

		return {
			valid: valid,
			validationMessage: this.validationMessage
		};
	},
	/*
	* Called when resize ends
	*/
	resize: function () {
		this.adjustSectionHeight();
	},
	/*
	* Destroys the form
	* */
	destroy: function () {
		if (this.widgetWrapper.length) {
			this.widgetWrapper.remove();
		}
		if (this.availableMetricsWindow) {
			this.availableMetricsWindow.destroy();
			this.availableMetricsWindow = null;
		}
	}
});
