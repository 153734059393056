import './severitiesSelect.less';

import React, {FunctionComponent, useState, useCallback, useEffect} from 'react';
import {Select, SelectProps} from 'antd';

import {Api} from 'tools/api';
import {Settings} from "settings";
import {newGuid} from 'tools/guid';
import {Cookies} from 'core/cookies';



const {Option} = Select;

const i = require('core/localization/localization').translator({
  "Choose a severity...": {
    "no": "Velg en alvorlighet"
  }
});

export class SeveritiesSelect extends React.PureComponent{
	constructor(props) {
		super(props);

		const dataSource = [['CRITICAL', lang.CRITICAL], ['MAJOR', lang.MAJOR], ['MINOR', lang.MINOR], ['OK', lang.OK], ['INACTIVE', lang.INACTIVE]];

		const defaultValue = ['CRITICAL', 'MAJOR', 'MINOR', 'OK', 'INACTIVE'];

		this.state = {
			dataSource: dataSource,
			selectedValue: props.defaultValue || defaultValue
		}
	}

	render(){
		const {onChange, value, invalid, ...others} = this.props;

		const severityColors = {
			CRITICAL: 1,
			MAJOR: 2,
			MINOR: 3,
			OK: 5,
			INACTIVE: 0
		}

		return (
			<Select value={value || this.state.selectedValue}
					mode={'multiple'}
					onChange={this.onChange}
					placeholder={i('Choose a severity...')}
					{...others}>
				{this.state.dataSource.map(severity =>
					<Option key={severity[0]}>
						<span className={`cw_severity_circle cw_status_widget_color cw_color${severityColors[severity[0]]}`}></span>
						<span className="cw_severity_text">{severity[1]}</span>
					</Option>
				)}
			</Select>
		);
	}

	onChange = newValue => {
		this.setState({
			selectedValue: newValue
		});
		this.props.onChange(newValue);
	}

	componentDidUpdate(prevProps) {
		if(!prevProps.defaultValue && this.props.defaultValue) {
			this.setState({selectedValue: this.props.defaultValue});
		}
	}
}
