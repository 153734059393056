import React from "react";
import Settings from 'settings';
import {Window} from "controls/react/kendoWrappers";

import {translator} from "core";
import {
	getQualifierWidth,
	getQualifierHeight,
	getQualifierLeft,
	getQualifierTop,
	getValidationDetails,
	showValidation,
	getValidationMessage,
	getValidationStatus,
	getQualifierFieldValue,
	getValidationType, getValidationAttachment
} from "./reduxReducer";
import {connect} from "react-redux";

import classnames from "classnames";
import {generateAction, HIDE_VALIDATION} from "./reduxActions";

import './configErrorWindow.less';
import PageSourceWindow from "./pageSourceWindow";

const i = translator({
  "Test": {
    "no": "Test"
  },
  "Post validate": {
    "no": "Post validering"
  },
  "Click here to View page source": {
    "no": "Klikk for å se sidekilde"
  }
});

const mapStateToProps = (state, ownProps) => ({
	width: getQualifierWidth(state),
    height: getQualifierHeight(state),
    top: getQualifierTop(state),
    left: getQualifierLeft(state),
    showValidation: showValidation(state),
    details: getValidationDetails(state),
	attachment: getValidationAttachment(state),
    message: getValidationMessage(state),
    status: getValidationStatus(state),
    qualifierName: getQualifierFieldValue(state, 'name'),
    type: getValidationType(state)
});
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        hide: () => dispatch(generateAction(HIDE_VALIDATION))
    }
};
@connect(mapStateToProps, mapDispatchToProps)
export class ConfigErrorWindow extends React.PureComponent {
    ref = React.createRef();
	state = {
		showSourceWindow: false
	};

    componentDidUpdate(prevProps) {
        if ((!prevProps.details && this.props.details) || (prevProps.details !== this.props.details)) {
            this.hideSpinner();
        }
        else if(!this.props.details) {
            this.showSpinner();
        }
    }

    componentDidMount() {
        if (!this.props.details) {
            this.showSpinner();
        }
        else if(!this.props.details) {
            this.hideSpinner();
        }
    }

    showSpinner() {
        kendo.ui.progress($(this.ref.current), true);
    }

    hideSpinner() {
        kendo.ui.progress($(this.ref.current), false);
    }

    onShowSourceWindow = () => {
    	this.setState({
			showSourceWindow: true
		})
	};

	onHideSourceWindow = () => {
		this.setState({
			showSourceWindow: false
		})
	};

    render() {
	    let {width, height, top, left, showValidation, details, qualifierName, name, type, position} = this.props;
	    width = width || position?.width ;
	    height = height || position?.height;
	    top = top || position?.top ;
	    left = left || position?.left;

		let windowWidth = 300;
		let windowHeight = 300;

		let windowTop = top + height / 2;
		let contentHeight = window.innerHeight;
		if (windowTop + windowHeight + 32 > contentHeight) {//32px addes by kendo window
			windowTop = contentHeight - windowHeight - 60;
		}

		let asideWidth = $('.aside').width();
		let contentWidth = window.innerWidth;
		//to the left
		let windowLeft = left - windowWidth - 10;
		if (windowLeft < asideWidth) {
			if (left + width + windowWidth < contentWidth) {
				//to the right
				windowLeft = left + width + 10;
			} else {
				//inside qualifier window as not possible to be either left or right
				windowLeft = left + width - windowWidth - 20;
			}
		}

        if (!showValidation || height === undefined || top === undefined || left === undefined) {
            return null;
        }

        let title;

        switch(type) {
            case 'test':
                title = `${i('Test')} ${qualifierName || name}`;
                break;
            case 'postValidate':
                title = `${i('Post validate')} ${qualifierName || name}`;
                break;
        }

        let url = '';

        if (this.props.attachment) {
        	const {id, name, mimeType} = this.props.attachment;

			url = Settings.serverPath + `services/qualifiers/attachments/${id}?mimeType=${mimeType}&name=${name}`;
		}

        return <Window
	        className='high-zindex'
            title={title}
            modal={false}
            width={windowWidth}
            height={windowHeight}
            top={windowTop}
            left={windowLeft}
            onClose={this.props.hide} >
			{this.props.attachment && <div className={'cw_configuration_attachment_notification'} onClick={this.onShowSourceWindow}>
				{i('Click here to View page source')}
			</div>}
            <div className="cw_configuration_error_content" ref={this.ref}>
                <ul>
                    {details && details.map((item, index) => {
                        return <li key={index}>{item.message}<span className={classnames('glyphicons', 'cw_error_status', {ok: item.success, 'remove-circle': !item.success})} /></li>;
                    })}
                </ul>
            </div>
			{this.state.showSourceWindow && <PageSourceWindow url={url} onClose={this.onHideSourceWindow} />}
        </Window>
    }
}
