import {CeeViewDataSource, Utils} from 'tools';
import translator from 'core/localization';
import {DISK_VIEW} from "areas/assets/monitors/system/shared";

const gridDiskStatuses = {
	custom: lang.CUSTOM,
	'default': lang.DEFAULT,
	none: lang.NONE,
	missing: lang.MISSING
};

const i = translator({
	'No shared disks are added. Click here or in menu to add a shared disk.': {
		no: 'Ingen delte disker er lagt til. Klikk her eller i menyen for å legge til en delt disk.'
	},
});

export default class DiskGrid {
	constructor(type, prefix, mode, onEdit, onAdd) {
		this.type = type;
		this.prefix = prefix;
		this.mode = mode;
		this.onEdit = onEdit;
		this.onAdd = onAdd;
	}

	create() {
		const columns = [
			{
				field: 'id',
				title: '',
				template: '<input type="checkbox" class="cw_grid_check" data-status="#= status#" data-id="#= id #" />',
				headerTemplate: '<input type="checkbox" class="cw_grid_check_all" />',
				sortable: false,
				attributes: {
					'class': 'text_center'
				},
				headerAttributes: {
					'class': 'text_center'
				},
				width: 60
			}
		];

		columns.push({
			field: 'status',
			title: lang.MONITORING,
			template: (data) => gridDiskStatuses[data.status],
			width: 120
		})
		columns.push({
			field: 'name',
			template: this.type === DISK_VIEW.SHARED_DISK ? '<span class="edit_disk cw_grid_link">#=name#</span>': '#=name#',
			title: lang.DISK,
			attributes: {
				'class': "expand ellipsis"
			}
		})

		if (this.type === DISK_VIEW.SHARED_DISK) {
			columns.push({
				field: 'sharePath',
				title: 'Share Path',
				width: 120
			});

			columns.push({
				field: 'server',
				title: lang.favorites.SERVER,
			});

		}

		this.diskGrid = $(`.cw_${this.prefix}disk_list`).kendoCustomGrid({
			noRecords: {
				template: () => {
					return this.type === DISK_VIEW.SHARED_DISK ?
						`<div class="cw_link shared_disk_no_record">${i('No shared disks are added. Click here or in menu to add a shared disk.')}</div>`:
						'No records available';
				}
			},
			autoBind: true,
			sortable: {
				mode: "multiple",
				allowUnsort: true
			},
			resizable: true,
			dataSource: new CeeViewDataSource({
				data: [],
				schema: {
					model: {
						id: 'id',
						fields: {
							enabled: {
								type: 'boolean'
							},
							name: {
								type: 'string'
							}
						}
					}
				},
				sort: [{
					field: 'name',
					dir: 'asc'
				}]
			}),
			columns: columns,
			dataBound: () => this.onDataBound()
		}).data('kendoCustomGrid');
		// Add Kendo tooltip to the header of the columns
		Utils.gridColumnHeaderTooltip(this.diskGrid);

		return this.diskGrid;
	}

	onDataBound() {
		$(`.cw_${this.prefix}disk_list`)
			.off('click', `.edit_disk`)
			.on('click', `.edit_disk`, this.onEdit);

		if (this.diskGrid) {
			$('.shared_disk_no_record', this.diskGrid.wrapper).on('click', this.onAdd);
		}

		if (this.mode === 'view') {
			$(`.cw_${this.prefix}disk_list .cw_grid_check_all`).prop('disabled', 'disabled');
			$(`.cw_${this.prefix}disk_list .cw_grid_check`).prop('disabled', 'disabled');
		}
	}
}

