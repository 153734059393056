const root = '/agents';

export function getDescriptions(){
	return [
		{
			jspUrl: 'include/Management/AgentDetails.jsp',
			url: root + '/:id',
			bodyCssClass: 'cw_management_module',
			getModuleImport: () => import('../agentDetails')
		}
	];
}

export class AgentsRouter {
	static details(agentId) {
		return root + '/' + agentId;
	}
}
