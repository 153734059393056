import UrlBuilder from "tools/urlBuilder";

export function getDescriptions(){
	return [
		{
			jspUrl: 'include/Incidents/IncidentForm.jsp',
			bodyCssClass: 'cw_incident_module',
			url: '/incidents/:id',
			getModuleImport: () => import('./details')
		}
	];
}

export class IncidentsRouter {
	static details(incidentId) {
		return new UrlBuilder(`/incidents/${incidentId}`)
			.build();
	}

	static createNew(contentCategory){
		return new UrlBuilder(`/incidents/new`)
			.add("contentCategory", contentCategory)
			.build();
	}
}
