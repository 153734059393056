import React, { useCallback, useMemo } from "react";
import { AlgoOperator, AlgoNumeric } from "./fields/ceAlgorithm";

import DropDownList from "controls/react/kendoWrappers/dropDownList";
import FormEntry from "controls/react/form/formEntry";
import {FormSection} from 'redux-form';

import Lang from 'core/localization/lang';

const TYPES = [
    {text: Lang.designer.STATIC_THRESHOLD, value: 'StaticThreshold'},
    {text: Lang.designer.NONE, value: 'None'}
];

function Warning(props) {
	const {input} = props;

	const handleTypeChange = useCallback((type) => {
		let out;

		if (type === 'None') {
			out = null;
		}
		else {
			out = {
				operator: 'LE',
				threshold: 70
			}
		}

		input.onChange(out);
	}, [input.onChange]);

	const state = useMemo(() => {
        if (input.value) {
            return {
                type: 'StaticThreshold',
                operator: input.value.operator,
                threshold: input.value.threshold
            }
        }
        else {
            return {
                type: 'None'
            }
        }
	}, [input.value]);

	const handleOperatorChange = useCallback((operator) => {
		input.onChange({
			operator: operator,
			threshold: state.threshold
		})
	}, [input.onChange, state.threshold]);

	const operatorInput = useMemo(() => ({
		value: state.operator,
		onChange: handleOperatorChange
	}), [state.operator, handleOperatorChange]);

	const handleThresholdChange = useCallback((threshold) => {
		input.onChange({
			operator: state.operator,
			threshold: threshold
		})
	}, [input.onChange, state.operator])

	const thresholdInput = useMemo(() => ({
		value: state.threshold,
		onChange: handleThresholdChange
	}), [handleThresholdChange, state.threshold])

	return <FormSection name={"warning"}>
		<FormEntry label={Lang.designer.ALGORITHM}>
			<DropDownList autoBind={true} onChange={handleTypeChange} value={state.type} dataSource={TYPES} />
		</FormEntry>
		{state.type === 'StaticThreshold' && <React.Fragment>
			<FormEntry label={Lang.designer.OPERATOR}>
				<AlgoOperator input={operatorInput} />
			</FormEntry>
			<FormEntry label={Lang.designer.THRESHOLD}>
				<AlgoNumeric input={thresholdInput} defaultValue={70} />
			</FormEntry>
		</React.Fragment>}
	</FormSection>
}

export default Warning;
