import './ceFileChooser.less';

import React from 'react';
import PropTypes from 'prop-types';
import {Tree} from 'antd';
import produce from 'immer';

import Window from 'controls/react/kendoWrappers/window';

import {Toolbar, ToolbarItemPosition} from 'controls/react/layout/toolbar';
import {Button} from 'controls/react/form/button';

import translator from 'core/localization';
import {BoxView} from '../../../../../layout/boxView';

import path from 'path';

const i = translator({
	'Filebrowser': {
		'no': 'Filvelger'
	},
	'No data available, check your agent settings.': {
		'no': 'Ingen data tilgjengelig, sjekk agent innstillingene.'
	}
});

export const b = require('b_').with('file-chooser');

const {DirectoryTree, TreeNode} = Tree;

function unifyKey(value) {
	let unified = value.replace(/\\/g, '/').toUpperCase();

	if (unified[0] === '/') {
		unified = unified.substring(1);
	}

	return unified;
}

function findItem(draft, key) {
	const lookupKey = unifyKey(key);

	for (const item of draft) {
		const itemKey = unifyKey(item.key);

		if (itemKey === lookupKey) {
			return item;
		}

		if (item.children) {
			const found = findItem(item.children, key);

			if (found) {
				return found;
			}
		}
	}
}

const fetchInitialList = async (onLoad) => {
	return await onLoad();
};

const fetchList = async (key, onLoad, tree) => {
	const listItems = await onLoad(key);

	return produce(tree, draft => {
		const parent = findItem(draft, key);

		if (parent) {
			parent.children = listItems;
		}
	});
};

function getDelimiter(value) {
	return (value.indexOf('\\') !== -1) ? '\\' : '/';
}

function getPathsToLoad(value) {
	const delimiter = getDelimiter(value);
	const parts = value.split(delimiter);
	const paths = [];

	let acc;

	parts.length = parts.length - 1;

	for (const part of parts) {
		if (acc === undefined) {
			acc = part;

			if (acc.indexOf(':') !== -1) {
				paths.push(acc.toUpperCase() + delimiter);
			} else {
				paths.push(acc);
			}
		} else {
			acc = [acc, part].join(delimiter);
			paths.push(acc);
		}
	}

	if (delimiter === '\\') {
		return paths;
	}
	else {
		return paths.map((path) => {
			if (path[0] !== '/') {
				return '/' + path;
			} else {
				return path;
			}
		})
	}
}

function joinValue(value, fileName) {
	const delimiter = getDelimiter(value);

	if (!fileName || fileName === '') {
		return value;
	}

	return [value, fileName].join(delimiter);
}

function splitFile(value) {
	const delimiter = getDelimiter(value);

	const parts = value.split(delimiter);
	const filename = parts.splice(-1, 1);

	return [parts.join(delimiter), ...filename];
}

function CEFileChooserWindow(props) {
	const [loading, setLoading] = React.useState(true);
	const [selected, setSelected] = React.useState();
	const [tree, setTree] = React.useState();
	const [treeNodes, setTreeNodes] = React.useState();
	const [errorMessage, setErrorMessage] = React.useState();

	const [defaultExpandedKeys, setDefaultExpandedKeys] = React.useState([]);
	const [selectedKeys, setSelectedKeys] = React.useState([]);

	const onLoadRef = React.useRef(props.onLoad);

	const ref = React.useRef();

	React.useEffect(() => onLoadRef.current = props.onLoad, [props.onLoad]);

	React.useEffect(() => {
		const init = async () => {
			try {
				let tree;

				if (props.value) {
					const value = joinValue(props.value, props.fileName);

					const paths = getPathsToLoad(props.value);

					console.log('paths', paths)

					tree = await fetchInitialList(onLoadRef.current, setTree);

					for (const path of paths) {
						tree = await fetchList(path, onLoadRef.current, tree, setTree);
					}

					const dir = path.dirname(value);

					const item = findItem(tree, value) || findItem(tree, props.value);

					if (item) {
						setDefaultExpandedKeys([item.key]);
						setSelectedKeys([item.key]);
					}
				} else {
					tree = await fetchInitialList(onLoadRef.current, setTree);
				}

				setTree(tree);
				setLoading(false);
			} catch (e) {
				setTree([]);
				setErrorMessage(e.message);
				return;
			}
		};

		init();
	}, []);

	const onLoadData = React.useCallback(async (node) => {
		try {
			const nextTree = await fetchList(node.props.eventKey, onLoadRef.current, tree, setTree);
			setTree(nextTree);
		} catch (e) {
			setTree([]);
			setErrorMessage(e.message);
			return;
		}
	}, [tree]);

	const onSelect = React.useCallback((keys, event) => {
		const id = keys[0];

		setSelectedKeys([id]);

		setSelected(id);
	}, [tree]);

	const onSave = React.useCallback(() => {
		const item = findItem(tree, selected);

		if (item.isLeaf && props.directoriesOnly) {
			const [dir, file] = splitFile(item.key);
			props.onSave(dir, file);
		} else {
			props.onSave(item.key)
		}
	}, [selected, props.onSave]);

	const onDefault = React.useCallback((node) => {
		if (node) {
			setTimeout(() => {
				node.scrollIntoView({block: 'center'});
			}, 100);
		}
	}, []);

	React.useEffect(() => {
		function buildTree(tree) {
			return tree.map((item) => {
				let node;
				let title = item.title;

				if (unifyKey(item.key) === unifyKey(joinValue(props.value, props.fileName))) {
					title = <span ref={onDefault}>{item.title}</span>;
				}

				if (item.children) {
					node = <TreeNode key={item.key} title={title} isLeaf={item.isLeaf}>
						{buildTree(item.children)}
					</TreeNode>
				} else {
					node = <TreeNode key={item.key} title={title} isLeaf={item.isLeaf}/>
				}

				return node;
			})
		}

		if (tree) {
			const newTreeNodes = buildTree(tree);
			setTreeNodes(newTreeNodes);
		}
	}, [tree]);

	React.useEffect(() => {
		kendo.ui.progress($(ref.current), loading);
	}, [loading]);

	return <Window modal={true} onClose={props.onCancel} width={'500px'} height={props.height || '300px'}
				   title={i('Filebrowser')} containerClass={b()}>
		{loading && <div className={b('spinner')} ref={ref}></div>}
		 <div className={b('tree')}>
			 {tree && tree.length > 0 && <DirectoryTree
				defaultExpandedKeys={defaultExpandedKeys}
				selectedKeys={selectedKeys}
				multiple={false}
				onSelect={onSelect}
				defaultExpandAll={false}
				loadData={onLoadData}>
				{treeNodes || null}
			</DirectoryTree>}
		 </div>
		{tree && tree.length === 0 && <>
			{!errorMessage && <div className={b('text')}>
				<BoxView type={'error'} rounded={true} border={true}>
					{i('No data available, check your agent settings.')}
				</BoxView>
			</div>}
			{errorMessage &&
			<div className={b('text')}>
				<BoxView type={'error'} border={true} rounded={true}>
					{errorMessage}
				</BoxView>
			</div>
			}
		</>}
		<Toolbar containerClass={b('toolbar')}>
			<Button title={i('Ok')}
					disabled={!selected}
					primary={true}
					onClick={onSave}
					position={ToolbarItemPosition.AT_THE_END}/>
			<Button title={i('Cancel')}
					primary={false}
					onClick={props.onCancel}
					position={ToolbarItemPosition.AT_THE_END}/>
		</Toolbar>
	</Window>
}

CEFileChooserWindow.propTypes = {
	onLoad: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
	directoriesOnly: PropTypes.bool
};

export default CEFileChooserWindow;
