import Utils from 'tools/utils';
import Application from 'core/application';
import PreferencesPanel from 'controls/preferencesPanel';
import GridMenu from 'controls/gridMenu';
import CustomNotification from 'controls/customNotification';
import UserPrefs from 'tools/userPrefs';
import State from 'tools/state';
import Settings from 'settings';
import Cookies from 'core/cookies';
import Renderer from 'tools/renderer';
import RemoteEventsManager from 'core/remoteEventsManager';
import EventsToolbar from 'controls/eventsToolbar';
import ErrorHandler from 'core/errorHandler';
import {IncidentsRouter} from "../incidents/bundleDescription";
import {getSeverityState} from 'controls/stateRenderer/severityState';

export default function ReasonsView(config) {
	Utils.apply(this, config);
	this.module = {
		name: 'Reasons',
		initialConfig: config
	};
	this.subscriberId = Utils.guid();
	this.initComponent();
};

jQuery.extend(ReasonsView.prototype, Application.prototype, {
	/**
	 * Main init function
	 */
	initComponent: function () {
		Application.prototype.initComponent.call(this);

		this.init();
	},
	/*
	 * Handler function used for initialization the view
	 * */
	init: function () {
		this.actionNotification = new CustomNotification({
			appendToElement: '.cw_monitors_list',
		});

		if (this.monitorType === 'asset_status') {
			this.serverMonitorType = 'all';
		} else {
			this.serverMonitorType = this.monitorType;
		}
		this.allowAssignActions = true;
		this.removeListeners();
		this.attachListeners();
		this.defaultColumns = {
			id: {
				hidden: false,
				width: 40
			},
			severityIndex: {
				hidden: false,
				width: 45
			},
			assetName: {
				hidden: false,
				width: 180
			},
			timestamp: {
				hidden: false,
				width: 180
			},
			text: {
				hidden: false,
				width: 100
			},
			acknowledged: {
				hidden: false,
				width: 150
			},
			acknowledgedByName: {
				hidden: false,
				width: 150
			},
			acknowledgedAt: {
				hidden: false,
				width: 150
			},
			information: {
				hidden: false,
				width: 200
			},
			monitorName: {
				hidden: false,
				width: 150
			},
			monitorTypeText: {
				hidden: false,
				width: 100
			},
			monitorClassText: {
				hidden: true,
				width: 100
			},
			subsystem: {
				hidden: false,
				width: 120
			},
			agentName: {
				hidden: false,
				width: 160
			},
			externalId: {
				hidden: true,
				width: 160
			},
			externalOwner: {
				hidden: true,
				width: 160
			},
			identifierInstance: {
				hidden: true,
				width: 150
			},
			monitorId: {
				hidden: true,
				width: 160
			},
			targetId: {
				hidden: true,
				width: 160
			}
		};
		this.loadUserPreferences();
		this.removeMask();
	},
	/**
	 * Removes listeners
	 */
	removeListeners: function () {
		$('#cw_monitors_list').off();
		$('body').off('click', '.cw_monitorname');
		$('body').off('click', '.cw_agentname');
	},
	/**
	 * Attaches listeners
	 */
	attachListeners: function () {
		$('#cw_monitors_list').on('click', '.cw_grid_check', $.proxy(this.onReasonsCheck, this));
		$('#cw_monitors_list').on('click', '.cw_grid_check_all', $.proxy(this.onReasonsCheckAll, this));
		$('#cw_monitors_list').on('click', '.cw_monitor_name', $.proxy(this.onMonitorClick, this));
		$('#cw_monitors_list').on('click', '.cw_agent_name', $.proxy(this.onAgentNameClick, this));
		$('#cw_monitors_list').on('click', '.cw_asset_name', $.proxy(this.onSqAssetNameClick, this));
		$('#cw_monitors_list').on('click', '.cw_target_name', $.proxy(this.onTargetNameClick, this));
		$('#cw_monitors_list').off('mouseover', '[data-monitorerror="true"]').on('mouseover', '[data-monitorerror="true"]', $.proxy(function (e) {
			Utils.onMonitorErrorOver(e, {
				dataSource: this.grid.dataSource,
				toolTip: this.tooltipOverview
			});
		}, this));
		$('body').on('click', '.cw_monitorname', $.proxy(this.onMonitorNameClick, this));
		$('body').on('click', '.cw_agentname', $.proxy(this.onAgentNameClick, this));
	},
	/**
	 * Handler for the click event on the monitor name
	 * @param {Object} e The click event
	 */
	onMonitorNameClick: function (e) {
		e.preventDefault();
		var target = $(e.currentTarget);

		var monitorId = target.data('id');
		var monitorType = target.data('type');

		this.openMonitorView(monitorId, monitorType, true, e);
	},
	/**
	 * Called when one or multiple events are received from the server
	 * @param {Object} data The event data
	 */
	onEvent: function (data) {
		this.allowAssignActions = true;
		this.dataSource.read();
		this.eventsToolbar.addEvents(events);
	},
	/**
	 * Initializes Kendo components
	 */
	initKendoComponents: function () {
		var filterMessages = lang.grid.filter;
		this.gridMessages = {
			isTrue: '<span class="glyphicons status_icon circle-arrow-top"></span>',
			isFalse: '<span class="glyphicons status_icon circle-arrow-down"></span>',
			clear: lang.CLEAR,
			info: lang.grid.filter.SHOW_ITEMS,
			filter: lang.FILTER
		};
		var sort, filter, columns, url, pref;
		var defaultFsView = UserPrefs.get('defaultFsView');
		if (defaultFsView) {
			pref = JSON.parse(UserPrefs.get(defaultFsView));
			sort = pref.sort;
			filter = pref.filter;
			columns = pref.columns;
		} else {
			if (UserPrefs.get('Sort')) {
				sort = JSON.parse(UserPrefs.get('Sort'));
			} else {
				sort = null;
			}
			if (UserPrefs.get('Filter')) {
				filter = JSON.parse(UserPrefs.get('Filter'));
			} else {
				filter = [];
			}
		}

		if (!sort) {
			sort = [{
				field: 'name',
				dir: 'asc',
				compare: null
			}];
		}

		if (this.preferences) {
			sort = this.preferences.sort || sort;
		}

		if (this.serverMonitorType === 'all') {
			url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/assets/' + this.assetId + '/health/monitors/reasons';
		} else {
			url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/assets/' + this.assetId + '/health/monitors/types/' + this.serverMonitorType + '/reasons';
		}

		this.dataSource = new kendo.ceeview.DataSource({
			transport: {
				read: {
					url: url,
					contentType: "application/json; charset=utf-8",
					type: "GET",
					dataType: "json",
					cache: false
				}
			},
			schema: {
				parse: function (response) {
					if (response.length) {
						for (var i = 0, length = response.length; i < length; i++) {
							response[i].timestamp = new Date(response[i].timestamp);
							if (response[i].acknowledgedByName) {
								response[i].acknowledgedMouseover = lang.ACKNOWLEDGED_BY + ' ' + response[i].acknowledgedByName;
							} else {
								response[i].acknowledgedMouseover = '';
							}
						}
					}
					return response;
				},
				model: {
					id: 'id',
					fields: {
						id: {
							type: 'string',
							editable: false
						},
						severity: {
							type: 'string',
							editable: false
						},
						severityIndex: {
							type: 'string',
							editable: false
						},
						assetName: {
							type: 'string',
							editable: false
						},
						timestamp: {
							type: 'date',
							editable: false
						},
						text: {
							type: 'string',
							editable: false
						},
						monitorName: {
							type: 'string',
							editable: false
						},
						monitorType: {
							type: 'string',
							editable: false
						},
						subsystem: {
							type: 'string',
							editable: false
						},
						accountName: {
							type: 'string',
							editable: false
						},
						agentName: {
							type: 'string',
							editable: false
						},
						externalId: {
							type: 'string',
							editable: false
						},
						externalOwner: {
							type: 'string',
							editable: false
						},
						identifierInstance: {
							type: 'string',
							editable: false
						},
						monitorId: {
							type: 'string',
							editable: false
						},
						targetId: {
							type: 'string',
							editable: false
						},
						acknowledged: {
							type: 'boolean',
							editable: false
						},
						acknowledgedByName: {
							type: 'string',
							editable: false
						},
						/*acknowledgedAt: {
						 type: 'date',
						 editable: false
						 },*/
						information: {
							type: 'string',
							editable: false
						}
					}
				}
			},
			sort: sort,
			filter: filter,
			error: ErrorHandler.kendoServerError
		});

		var columns = JSON.parse(UserPrefs.get('Columns')) || this.defaultColumns;
		columns = Utils.completeColumns(columns, this.defaultColumns);

		var monitorTemplate = State.mainApp.session.hasRole('MONITOR_READ') ? '<a class="cw_grid_link cw_monitor_name" data-id="${monitorId}" data-type="${monitorType}">${monitorName}</a>' : '${monitorName}';
		var agentTemplate = State.mainApp.session.hasRole('AGENT_READ') ? '<span class="cw_grid_link cw_agent_name" data-agentid="#=agentId#">#=agentName?agentName:""#</span>' : '#=agentName?agentName:""#';
		var assetTemplate = State.mainApp.session.hasRole('ASSET_READ') ? '<span class="cw_grid_link cw_asset_name" data-id="#=assetId#" data-name="#=assetName#" data-accountid="#= accountId#" data-accountname="#= accountName#">#=assetName?assetName:""#</span>' : '#=assetName?assetName:""#';

		this.grid = $('#cw_monitors_list').kendoCustomGrid({
			dataSource: this.dataSource,
			editable: true,
			resizable: true,
			scrollable: true,
			reorderable: true,
			selectable: 'row',
			sortable: {
				mode: 'multiple',
				allowUnsort: true
			},
			filterable: {
				extra: false,
				operators: {
					string: {
						startswith: filterMessages.STARTS_WITH,
						neq: filterMessages.NEQ,
						eq: filterMessages.EQ,
						contains: filterMessages.CONTAINS
					},
					number: {
						gte: filterMessages.IAE,
						gt: filterMessages.IA,
						lte: filterMessages.IBE,
						lt: filterMessages.IB
					},
					date: {
						gte: filterMessages.IAE,
						gt: filterMessages.IA,
						lte: filterMessages.IBE,
						lt: filterMessages.IB
					}
				}
				//,messages: this.gridMessages
			},
			columns: Utils.rearrangeColumns([{
				field: 'id',
				title: lang.SELECTOR,
				sortable: false,
				filterable: false,
				menu: false,
				template: '<input type="checkbox" class="cw_grid_check" data-id="${id}" data-monitorid="${monitorId}" />',
				headerTemplate: '<input type="checkbox" class="cw_grid_check_all" />',
				attributes: {
					'class': 'text_center'
				},
				headerAttributes: {
					'class': 'text_center'
				},
				hidden: false,
				width: columns.id.width
			}, {
				field: 'severityIndex',
				title: lang.assethealth.SEVERITY,
				sortable: {
					compare: $.proxy(function (a, b) {
						return Utils.customCompare(a, b, 'severityIndex', 6, this.dataSource.sortNow);
					}, this)
				},
				filterable: {
					ui: $.proxy(this.getFilterableForSeverity, {scope: this, field: 'severityIndex'}),
					messages: this.gridMessages
				},
				//template: item => Renderer.severity(item.severity, item),
				template: item => getSeverityState(item.severity, item),
				hidden: columns.severityIndex.hidden,
				width: columns.severityIndex.width
			}, {
				field: 'assetName',
				title: lang.ASSET,
				template: assetTemplate,
				sortable: true,
				filterable: {
					messages: this.gridMessages
				},
				hidden: columns.assetName.hidden,
				width: columns.assetName.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'timestamp',
				title: lang.service.TIME,
				template: '#= Renderer.browserDateRenderer(timestamp, Constants.DATETIME)#',
				sortable: true,
				filterable: {
					extra: true,
					ui: function (el) {
						el.kendoDateTimePicker({
							format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
							timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat)
						});
					},
					messages: this.gridMessages
				},
				hidden: columns.timestamp.hidden,
				width: columns.timestamp.width
			}, {
				field: 'text',
				title: lang.DESCRIPTION,
				attributes: {
					'class': 'tooltip ellipsis to_expand reason_text'
				},
				sortable: true,
				menu: false,
				filterable: true,
				hidden: columns.text.hidden,
				width: columns.text.width
			}, {
				field: 'monitorName',
				title: lang.assethealth.MONITOR_NAME,
				template: monitorTemplate,
				sortable: true,
				menu: true,
				filterable: true,
				hidden: columns.monitorName.hidden,
				width: columns.monitorName.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'acknowledged',
				title: lang.service.ACKNOWLEDGED,
				sortable: true,
				filterable: {
					messages: {
						isTrue: lang.YES,
						isFalse: lang.NO,
						clear: lang.CLEAR,
						info: lang.grid.filter.SHOW_ITEMS,
						filter: lang.FILTER
					}
				},
				template: '#= acknowledged || inIncident ? \'<span class="glyphicons ok"></span>\' : ""#',
				hidden: columns.acknowledged.hidden,
				width: columns.acknowledged.width,
				attributes: {
					'title': '#=acknowledgedMouseover || "" #'
				}
			}, {
				field: 'acknowledgedByName',
				title: lang.service.ACKNOWLEDGED_BY,
				sortable: true,
				menu: false,
				filterable: true,
				template: '#=acknowledgedByName ? acknowledgedByName : "" #',
				hidden: columns.acknowledgedByName.hidden,
				width: columns.acknowledgedByName.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				},
			}, {
				field: 'acknowledgedAt',
				title: lang.incidents.TIME_OF_FLAG,
				sortable: true,
				menu: false,
				filterable: false,
				template: '#=Renderer.browserDateRenderer(acknowledgedAt, Constants.DATETIME)#',
				hidden: columns.acknowledgedAt.hidden,
				width: columns.acknowledgedAt.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'information',
				title: lang.INFORMATION,
				sortable: true,
				filterable: true,
				template: '#=information ? information : "" #',
				hidden: columns.information.hidden,
				width: columns.information.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				},
			}, {
				field: 'monitorTypeText',
				title: lang.assethealth.MONITOR_TYPE,
				sortable: true,
				filterable: {
					ui: $.proxy(function (element) {
						return Renderer.filterGridByOwnElementDS.call(this, element, 'monitorTypeText');
					}, this),
					messages: this.gridMessages,
					extra: false,
					operators: {
						string: {
							neq: filterMessages.NEQ,
							eq: filterMessages.EQ
						}
					}
				},
				hidden: columns.monitorTypeText.hidden,
				width: columns.monitorTypeText.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'monitorClassText',
				title: lang.assethealth.MONITOR_CLASS,
				sortable: true,
				filterable: {
					ui: $.proxy(function (element) {
						return Renderer.filterGridByOwnElementDS.call(this, element, 'monitorClassText');
					}, this),
					messages: this.gridMessages,
					extra: false,
					operators: {
						string: {
							neq: filterMessages.NEQ,
							eq: filterMessages.EQ
						}
					}
				},
				hidden: columns.monitorClassText.hidden,
				width: columns.monitorClassText.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'subsystem',
				title: lang.assethealth.SUBSYSTEM,
				sortable: true,
				filterable: true,
				hidden: columns.subsystem.hidden,
				width: columns.subsystem.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'agentName',
				title: lang.assethealth.AGENT_NAME,
				sortable: true,
				filterable: {
					ui: $.proxy(function (element) {
						return Renderer.filterGridByOwnElementDS.call(this, element, 'agentName');
					}, this),
					messages: this.gridMessages,
					extra: false,
					operators: {
						string: {
							neq: filterMessages.NEQ,
							eq: filterMessages.EQ
						}
					}
				},
				hidden: columns.agentName.hidden,
				width: columns.agentName.width,
				template: agentTemplate,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'externalId',
				title: lang.account.ASSET_EXTERNAL_ID,
				hidden: columns.externalId.hidden,
				width: columns.externalId.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'externalOwner',
				title: lang.account.ASSET_EXTERNAL_OWNER,
				hidden: columns.externalOwner.hidden,
				width: columns.externalOwner.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'identifierInstance',
				title: lang.assethealth.IDENTIFIER_INSTANCE,
				hidden: columns.identifierInstance.hidden,
				width: columns.identifierInstance.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'monitorId',
				title: 'sourceId',
				hidden: columns.monitorId.hidden,
				width: columns.monitorId.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'targetId',
				title: 'targetId',
				hidden: columns.targetId.hidden,
				width: columns.targetId.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}], columns),
			columnMenu: true,
			change: $.proxy(this.onReasonRowExpand, this),
			dataBound: $.proxy(this.onDataBound, this)
		}).data('kendoCustomGrid');
		// Add Kendo tooltip to the header of the columns
		Utils.gridColumnHeaderTooltip(this.grid);
		this.grid.thead.find("[data-field='id']>.k-header-column-menu").remove();

		var nimsoftMonitorMenu = [];
		if (this.monitorType === 'NIMSOFT') {
			nimsoftMonitorMenu = [{
				id: 'cw_assign_in_progress',
				icon: 'binoculars',
				text: lang.summary.ASSIGN_IN_PROGRESS,
				fn: this.onAssignInProgressButton,
				scope: this,
				cssClass: '',
				disabled: true
			}, {
				id: 'cw_assign',
				icon: 'new-window-alt',
				text: lang.summary.ASSIGN,
				fn: this.onAssignButton,
				scope: this,
				cssClass: '',
				disabled: true
			}];
		}
		var menuItems = jQuery.merge([{
			id: 'cw_incidents_create',
			icon: 'construction-cone',
			text: lang.incidents.CREATE_INCIDENT,
			fn: this.onCreateIncident,
			scope: this,
			role: 'INCIDENT_CREATE',
			disabled: true
		}, {
			id: 'cw_acknowledge',
			icon: 'check',
			text: lang.incidents.MARK_AS_SEEN,
			fn: this.onAckUnack,
			scope: this,
			disabled: true
		}, {
			id: 'cw_unacknowledge',
			icon: 'unchecked',
			text: lang.UNACKNOWLEDGE,
			fn: this.onAckUnack,
			scope: this,
			disabled: true
		}, {
			id: 'cw_reset_hi',
			icon: 'restart',
			text: lang.assethealth.RESET_HI,
			fn: this.onResetHIButton,
			scope: this,
			cssClass: '',
			disabled: true
		}, {
			id: 'cw_create_filter',
			icon: 'filter',
			text: lang.assethealth.CREATE_FILTER,
			fn: this.onCreateFilter,
			scope: this,
			cssClass: '',
			disabled: true,
			role: State.mainApp.session.hasV2Permissions ? 'HEALTHFILTER_CREATE' : undefined
		}], nimsoftMonitorMenu);

		this.gridMenu = new GridMenu({
			renderTo: 'cw_reasons_grid_menu',
			items: menuItems
		});

		var eventStatus = JSON.parse(UserPrefs.get('eventsStatus')) || {
			startFrom: 0,
			playing: true
		};
		this.eventsToolbar = new EventsToolbar({
			renderTo: $('#cw_reasons_play_events'),
			callBackFn: $.proxy(this.onEventsTimeout, this),
			subscriberId: this.subscriberId,
			startFrom: eventStatus.startFrom,
			playing: eventStatus.playing
		});

		var searchValue = UserPrefs.get('defaultFsView') ? '' : (UserPrefs.get('SearchPhrase') || '');
		this.gridFilterPanel = new PreferencesPanel({
			renderTo: 'cw_reasons_filters',
			grid: this.grid,
			modulePrefName: 'AssetReasons',
			defaultPrefViewKey: 'defaultFsView',
			prefListKey: 'FSViews',
			userPref: this.userPref,
			onRemove: $.proxy(this.saveUserPreferences, this),
			searchFields: ['assetName', 'text', 'monitorName', 'agentName', 'identifierInstance', 'monitorTypeText', 'monitorClassText'],
			searchValue: searchValue,
			defaultColumns: this.defaultColumns
		});
	},
	/**
	 * Handler function for create incident button
	 */
	onCreateIncident: function (e) {
		var assetsCheckboxes = $('.cw_grid_check');
		var assets = [], accountList = [], reasonsIds = [];
		for (var i = 0, length = assetsCheckboxes.length; i < length; i++) {
			if ($(assetsCheckboxes[i]).is(':checked')) {
				var record = this.dataSource.get($(assetsCheckboxes[i]).data('id'));
				assets.push({
					id: record.assetId,
					name: record.assetName
				});
				reasonsIds.push(record.id);
				if (!Utils.getFromListById(accountList, record.accountId)) {
					accountList.push({
						id: record.accountId,
						name: record.accountName
					});
				}
			}
		}
		if (!Utils.getFromListById(accountList, Cookies.CeesoftCurrentAccountId)) {
			accountList.push({
				id: Cookies.CeesoftCurrentAccountId,
				name: Cookies.CeesoftCurrentAccountName
			});
		}
		if (assets.length) {
			State.mainApp.navigate(IncidentsRouter.createNew('ASSET'), {
				extra: {
					assets: assets,
					accountList: accountList,
					reasonsIds: reasonsIds,
				}
			});
		} else {
			var status = $('#content_area').find('.status');
			status.find('p').addClass('error');
			status.find('p').text(lang.assethealth.messages.PLEASE_SELECT_ASSET);
			status.slideDown().delay(2000).slideUp();
		}
	},
	/*
	 * Handler function for the grid data bound event
	 * @param {Object} e The databound event
	 */
	onDataBound: function (e) {
		var tooltip = $('#cw_monitors_list').find('.k-grid-content').data('kendoTooltip');
		if (tooltip) {
			tooltip.destroy();
		}
		$('#cw_monitors_list').find('.k-grid-content').kendoTooltip({
			filter: "td.tooltip:not(:empty)",
			content: function (event) {
				return event.target.text().split("\n").join("<br />");
			}
		});
		var uid, row;
		this.adjustSectionHeight();
		this.highlightMonitor(this.grid);
		this.setErrorToolTip($('#cw_monitors_list'));
		var data = this.grid.dataSource.data();
		if (data.length) {
			for (var i = 0; i < data.length; i++) {
				uid = data[i].uid;
				row = $("#cw_monitors_list tbody").find("tr[data-uid=" + uid + "]");
				if (data[i].acknowledged) {
					//row.attr('style', 'background-color: #cccccc !important');
					//row.find('.reason_text').css('color', '#cccccc');
				}
			}
		}
	},
	/**
	 * Handler function for the change(select) event on the grid
	 * @param {Object} e The change event object
	 */
	onReasonRowExpand: function (e) {
		var selectedRow = this.grid.select();
		var myRow = selectedRow[0];
		var messageEl = $(myRow).find('.to_expand');
		if ($(messageEl).hasClass('cw_message_expanded')) {
			$(messageEl).removeClass('cw_message_expanded').addClass('ellipsis');
		} else {
			$('#cw_monitors_list').find('.k-grid-content').find('td.cw_message_expanded').removeClass('cw_message_expanded').addClass('ellipsis');
			$(messageEl).addClass('cw_message_expanded').removeClass('ellipsis');
		}
	},
	/**
	 * Handler funtion for the click event on the Assign button
	 */
	onAssignButton: function () {
		var reasons = [];
		var status = $('#content_area').find('.status');
		$('.cw_grid_check').each(function (index, element) {
			var checkbox = $(this);
			if (checkbox.is(':checked')) {
				reasons.push(checkbox.data('id'));
			}
		});
		if (reasons.length) {
			this.disableActionButtons();
			this.allowAssignActions = false;
			var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/assets/' + this.assetId + '/monitors/' + this.monitorId + '/assignAlarms';
			Utils.ajax(url, 'POST', JSON.stringify(reasons), $.proxy(function (result) {
				if (result.success) {
					// this.dataSource.read();
					status.find('p').addClass('success');
					status.find('p').text(lang.account.messages.REASONS_ASSIGNED);
					status.slideDown().delay(2000).slideUp();
					this.updateLoadingAssignments(reasons);
				} else {
					status.find('p').addClass('error');
					status.find('p').text(result.message);
					status.slideDown().delay(2000).slideUp();
				}
			}, this));
		}
	},
	/**
	 * Handler funtion for the click event on the Assign button
	 */
	onAssignInProgressButton: function () {
		var reasons = [];
		var status = $('#content_area').find('.status');
		$('.cw_grid_check').each(function (index, element) {
			var checkbox = $(this);
			if (checkbox.is(':checked')) {
				reasons.push(checkbox.data('id'));
			}
		});

		if (reasons.length) {
			this.disableActionButtons();
			this.allowAssignActions = false;
			var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/assets/' + this.assetId + '/monitors/' + this.monitorId + '/assignAlarmsInProgress';
			Utils.ajax(url, 'POST', JSON.stringify(reasons), $.proxy(function (result) {
				if (result.success) {
					// this.dataSource.read();
					this.actionNotification.setOptions({
						message: lang.account.messages.REASONS_ASSIGNED,
						status: 'success'
					}).show();

					this.updateLoadingAssignments(reasons);
				} else {
					this.actionNotification.setOptions({
						message: result.message,
						status: 'error'
					}).show();
				}
			}, this));
		}
	},
	/**
	 * Handler function for clicking one of the acknowledge or unacknowledge options in menu item
	 */
	onAckUnack: function (e) {
		var checkboxes = $('.cw_grid_check'), i, length = checkboxes.length, assetIds = [], assetId, checkbox, item,
			assetName, reasonId, url, message;
		var payload = {
			assetIds: [],
			eventIds: []
		};
		for (i = 0; i < length; i++) {
			checkbox = $(checkboxes[i]);
			item = this.grid.dataSource.getByUid(checkbox.closest('tr').data('uid'));
			assetId = item.assetId;
			reasonId = item.id;
			if (checkbox.is(':checked') && payload.assetIds.indexOf(assetId) === -1) {
				payload.assetIds.push(assetId);
				payload.eventIds.push(reasonId);
				assetName = item.assetName;
			}
		}
		var target = $(e.currentTarget);
		if (target.attr('id') === 'cw_acknowledge') {
			url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/health/acknowledgeAssetHealthEvents';
			message = assetIds.length > 1 ? lang.messages.REASONS_ASSETS_ACKNOWLEDGED : kendo.template(lang.messages.REASONS_ACKNOWLEDGED)({'assetName': assetName});
		} else if (target.attr('id') === 'cw_unacknowledge') {
			url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/health/unacknowledgeAssetHealthEvents';
			message = assetIds.length > 1 ? lang.messages.REASONS_ASSETS_UNACKNOWLEDGED : kendo.template(lang.messages.REASONS_UNACKNOWLEDGED)({'assetName': assetName});
		}
		Utils.ajax(url, 'POST', JSON.stringify(payload), $.proxy(function (result) {
			if (result.success) {
				$('.cw_grid_check:checked').trigger('click');
				$('.cw_grid_check_all:checked').trigger('click');
				this.grid.dataSource.read();
				this.actionNotification.setOptions({
					message: message,
					status: 'success'
				}).show();
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},
	/*
	 * Handler function for creating filter
	 * @param {Object} e The click event object
	 * */
	onCreateFilter: function (e) {
		var checkbox = $('.cw_grid_check:checked');
		var uid = checkbox.closest('tr').data('uid');
		var record = this.grid.dataSource.getByUid(uid);
		State.mainApp.loadModule('AssetHealthFiltersConfiguration', '', {
			mode: 'create',
			populate: {
				assetId: record.assetId,
				monitorId: record.monitorId,
				monitorType: record.monitorType,
				severity: record.severity,
				reasonDescription: record.text,
				reasonSubsytem: record.subsystem,
				identifierName: record.identifierName || '',
				identifierInstance: record.identifierInstance || '',
				accountId: record.accountId,
				accountName: record.accountName
			}
		}, e);
	},
	/**
	 * Handler funtion for the click event on the Reset health index button
	 */
	onResetHIButton: function () {
		var checkboxes = $('.cw_grid_check'), i, length = checkboxes.length, monitors = [], checkbox, monitorId;
		for (i = 0; i < length; i++) {
			checkbox = $(checkboxes[i]);
			if (checkbox.is(':checked')) {
				monitorId = checkbox.data('monitorid');
				var found = false;
				for (var j = 0; j < monitors.length; j++) {
					if (monitors[j].monitorId === monitorId && monitors[j].assetId === this.assetId) {
						found = true;
						break;
					}
				}
				if (!found) {
					monitors.push({
						monitorId: monitorId,
						assetId: this.assetId
					});
				}

			}
		}

		Utils.resetMonitorsHI(monitors, function () {
			$('.cw_grid_check').prop('checked', false);
			$('.cw_grid_check_all').prop('checked', false);
		});
	},
	/**
	 * Handler for the service checkbox click
	 */
	onReasonsCheck: function (e) {
		var checkboxes = $('.cw_grid_check');
		var checked = false;
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			if ($(checkboxes[i]).is(':checked')) {
				checked = true;
				break;
			}
		}
		if (this.allowAssignActions) {
			if (checked) {
				this.enableActionButtons();
			} else {
				this.disableActionButtons();
			}
		}
	},
	/**
	 * Handler for the check all reasons checkbox click
	 * @param {Object} e The click event
	 */
	onReasonsCheckAll: function (e) {
		var checkAll = $(e.currentTarget).is(':checked');
		var checkboxes = $('#cw_monitors_list').find('.cw_grid_check');
		var i, length = checkboxes.length;
		for (i = 0; i < length; i++) {
			$(checkboxes[i]).prop('checked', checkAll);
		}
		this.onReasonsCheck();
	},
	/**
	 * Handler for the click event on the monitor name
	 * @param {Object} e The click event
	 */
	onMonitorClick: function (e) {
		e.preventDefault();
		var target = $(e.currentTarget);

		var monitorId = target.data('id');
		var monitorType = target.data('type');

		this.openMonitorView(monitorId, monitorType, false, e);
	},
	/*
	 * Handler function for click event on target name
	 * @param {Object} e The event click object
	 */
	onTargetNameClick: function (e) {
		var uid = $(e.currentTarget).closest('tr').data('uid'), item = this.grid.dataSource.getByUid(uid);
		State.mainApp.loadModule('AssetDetails', item.targetId, {
			id: item.targetId,
			name: item.targetName,
			accountName: this.accountName,
			accountId: this.accountId
		}, e);
	},
	/**
	 * Disables the assign buttons
	 */
	disableActionButtons: function () {
		if (this.gridMenu) {
			this.gridMenu.disableItem('cw_incidents_create');
			this.gridMenu.disableItem('cw_acknowledge');
			this.gridMenu.disableItem('cw_unacknowledge');
			this.gridMenu.disableItem('cw_reset_hi');
			this.gridMenu.disableItem('cw_create_filter');
			this.gridMenu.disableItem('cw_assign');
			this.gridMenu.disableItem('cw_assign_in_progress');
		}
	},
	/**
	 * Enables the assign buttons
	 */
	enableActionButtons: function () {
		if (this.gridMenu) {
			this.gridMenu.enableItem('cw_incidents_create');
			this.gridMenu.enableItem('cw_acknowledge');
			this.gridMenu.enableItem('cw_unacknowledge');
			this.gridMenu.enableItem('cw_reset_hi');
			this.gridMenu.enableItem('cw_create_filter');
			this.gridMenu.enableItem('cw_assign');
			this.gridMenu.enableItem('cw_assign_in_progress');
		}
	},
	/**
	 * Updates the assigned icon
	 * @param {Array} reasons Array of reason objects
	 */
	updateAssigned: function (reasons) {
		var assignCell, assignedCell;
		for (var i = 0, length = reasons.length; i < length; i++) {
			if (reasons[i].assigned) {
				assignCell = $('input[data-id="' + reasons[i].id + '"]').closest('td');
				assignedCell = assignCell.prev();
				assignedCell.empty().html('<span class="glyphicons new-window-alt"></span>');
			}
		}
	},
	/**
	 * Updates the reasons row with loading indicator
	 * @param {Array} reasons Array of reasons GUIDs
	 */
	updateLoadingAssignments: function (reasons) {
		var assignCell, assignedCell;
		for (var i = 0, length = reasons.length; i < length; i++) {
			assignCell = $('input[data-id="' + reasons[i] + '"]').closest('td');
			assignedCell = assignCell.prev();
			assignCell.find('input').addClass('hide');
			assignedCell.empty().html('<span class="cw_inline_spinner"></span>');
		}
	},
	getFilterableForSeverity: function (element) {
		var menu = $(element).parent();
		menu.find(".k-filter-help-text").text(lang.assethealth.messages.SELECT_ASSETS);
		//menu.find("[data-role=dropdownlist]").remove();
		element.removeAttr("data-bind");
		var operatorEl = menu.find("[data-role=dropdownlist]");
		operatorEl.find('option[value="contains"]').remove();
		operatorEl.find('option[value="startswith"]').remove();
		operatorEl.find('option[value="eq"]').text(lang.grid.filter.ISIN);
		operatorEl.find('option[value="neq"]').text(lang.grid.filter.ISNOTIN);
		operatorEl.attr('data-index', '1');
		var multiSelect = element.kendoSortedMultiSelect({
			dataSource: [{
				text: lang.CRITICAL,
				color: 1,
				value: '3'
			}, {
				text: lang.MAJOR,
				color: 2,
				value: '2'
			}, {
				text: lang.MINOR,
				color: 3,
				value: '1'
			}, {
				text: lang.OK,
				color: 5,
				value: '0'
			}],
			dataTextField: 'text',
			dataValueField: 'value',
			optionLabel: lang.grid.FILTER_SELECT_VALUE,
			itemTemplate: '<span class="cw_status_indicator cw_status_widget_color cw_color#=data.color#"></span><span>${data.text}</span>',
			tagTemplate: '<span class="cw_status_indicator cw_status_widget_color cw_color#=data.color#"></span><span>${data.text}</span>'
		}).data('kendoSortedMultiSelect');
		menu.find('[type=submit]').on('click', {
			widget: multiSelect,
			operatorElement: operatorEl
		}, $.proxy(this.scope.filterBySeverityIndicator, {
			scope: this.scope,
			dataSource: this.scope.dataSource,
			field: this.field
		}));
		menu.find('[type=reset]').on('click', $.proxy(function (e) {
			multiSelect.value([]);
		}, this));
		//fix for default value
		setTimeout(function () {
			operatorEl.data('kendoDropDownList').select(1);
		}, 100);
	},
	filterBySeverityIndicator: function (e) {
		var indicators = e.data.widget.value();
		var operator = e.data.operatorElement.data('kendoDropDownList').value();
		var logic = (operator === 'eq') ? 'or' : 'and';
		var currentFilter = this.dataSource.filter();
		var filter = {logic: logic, filters: []};
		for (var i = 0; i < indicators.length; i++) {
			filter.filters.push({field: this.field, operator: operator, value: indicators[i]});
		}
		if (currentFilter && currentFilter.filters) {
			for (var i = 0; i < currentFilter.filters.length; i++) {
				if (currentFilter.filters[i].filters) {
					for (var j = 0; j < currentFilter.filters[i].filters.length; j++) {
						if (currentFilter.filters[i].filters[j].field === this.field) {
							currentFilter.filters[i].filters.splice(j, 1);
							j--;
						}
					}
				}
				if (currentFilter.filters[i].field === this.field) {
					currentFilter.filters.splice(i, 1);
					i--;
				}
			}
			if (filter.filters.length) {
				currentFilter.filters.push(filter);
			}
			this.dataSource.filter(currentFilter);
		} else {

			this.dataSource.filter(filter);
		}
	},
	/**
	 * Method by Andy
	 *
	 */
	adjustSectionHeight: function () {
		var section = $('.cw_section_full');
		var sectionHeight = section.height();
		section.find('.cw_section_content').css('height', sectionHeight);
		section.find('.k-grid-content').css('height', sectionHeight - 40);
	},
	/**
	 * Saves user preferences
	 */
	saveUserPreferences: function () {
		this.saveUserPrefs({
			category: 'AssetReasons',
			grid: this.grid,
			eventsToolbar: this.eventsToolbar,
			filterPanel: this.gridFilterPanel,
			keys: {
				searchPhrase: 'SearchPhrase',
				columns: 'Columns',
				sort: 'Sort',
				filter: 'Filter',
				FsViews: 'FSViews'
			}
		});
	},
	/**
	 * Loads user preferences
	 */
	loadUserPreferences: function () {
		this.userPref = [];
		UserPrefs.load('AssetReasons', $.proxy(function (result) {
			if (result.success) {
				this.userPref = result.data;
				this.initKendoComponents();
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},
	/**
	 * Subscribes to the events
	 */
	subscribe: function () {
		var subscriptionObj = [{
			eventType: 'AssetHealth',
			assetId: this.assetId
		}];
		RemoteEventsManager.subscribe(this.subscriberId, subscriptionObj);
	},
	/**
	 * The destroy method
	 */
	destroy: function () {
		this.saveUserPreferences();
		if (this.eventsToolbar) {
			this.eventsToolbar.destroy();
		}
		if (this.tooltipOverview) {
			this.tooltipOverview.destroy();
		}
		Application.prototype.destroy.call(this);
	}
});
