import React from 'react';
import ReactDOM from 'react-dom';

import Utils from 'tools/utils';
import {ApplicationsApi} from '../api';
import Widget from 'areas/service-boards/widget';
import RemoteEventsManager from 'core/remoteEventsManager';
import LocalEventsManager from 'core/localEventsManager';
import {ApplicationDesigner} from "../application-designer";
import {translator} from 'core';
import {ReasonsGrid} from "areas/application/reasonsGrid";
import {Window} from "controls/react/kendoWrappers";
import {loadReasonsForCell} from "areas/application/application-designer/applicationDesigner";
import {showReasonsGridWindow} from "areas/application/reasonsGridWindow";

const i = translator({
  "Application » {0}": {
    "no": "Applikasjon » {0}"
  },
  "The application is not found": {
    "no": "Fant ikke applikasjon"
  }
});

export class ApplicationWidget extends Widget{
	applicationDesignerRef = React.createRef();
	sp = null;
	editorUi = null;
	serviceElementToCellMap = null;
	services = null;
	onZoom = false;


	async init() {
		var widgetDiv = $('#' + this.id);
		widgetDiv.off();

		this.graphContainer = $('.cw_section_content', widgetDiv);
		this.graphContainer.addClass("presentation");

		//2.7 quickfix for geEditor height
		let container = this.graphContainer.get(0);
		let containerHeight = $(container).height() + 'px';

		const result = await ApplicationsApi.detailsReadonly(this.configuration.applicationId || this.configuration.servicePresentationId,
			this.sessionId);

		if(!result.success){
			this.showErrorMessage(i('The application is not found'));
			return;
		}

		this.setTitleIfEmpty(i("Application » {0}", result.data.name));

		ReactDOM.render(
			<ApplicationDesigner application={result.data}
			                     onLoaded={this.onLoaded}
			                     sessionId={this.sessionId}
			                     redirectOnClick={this.configuration.displayEventsInPopup !== true}
			                     contentPadding={this.configuration.contentPadding}
			                     accountId={this.instanceConfiguration.accountId}
			                     fit={this.configuration.zoom === undefined ? false : this.configuration.zoom}
			                     onCellClicked={this.onCellClicked}
			                     ref={this.applicationDesignerRef}
			                     mode={"preview"}/>,
			container
		);
	}

	onPresentationShown() {
		this.presentation = this.designer.presentation;
		this.editorUi = this.designer.editorUi;
		this.subscribe();
	}

	onResize = () => {
		this.designer?.fit();
	}

	onEvents(data) {
		const myEvents = data.filter(x => x.wId == this.id);

		const anyAdminEvent = myEvents.findIndex(x => x.eventType == 'Administration') != -1;

		if(anyAdminEvent){
			this.initComponent();
		}
	}

	onLoaded = designer => {
		this.designer = designer;
		this.subscribe();
	}

	onCellClicked = async (designer, cell, event) => {
		if (!cell || !this.configuration.displayEventsInPopup) {
			return;
		}

		let reasons = await designer.loadReasonsForCell(cell)
		if(!reasons)
			return;

		let windowTitle;
		if (cell.getAttribute("assetId")) {
			windowTitle = reasons[0]?.assetName;
		} else {
			let assetGroupId = cell.getAttribute("assetGroupId");
			let result = await ApplicationsApi.getAssetGroups(assetGroupId, true);
			windowTitle = result.items.find(x => x.id == assetGroupId)?.name || '';
		}

		let widgetPosition = designer.config.container.getBoundingClientRect();
		let windowLeft = widgetPosition.x + event.graphX + 10;
		let windowTop = widgetPosition.y + event.graphY + 10;

		this.reasonsWindowId = Utils.guid();
		showReasonsGridWindow(() => [reasons, false], windowTitle, windowLeft, windowTop, this.reasonsWindowId);
	}

	subscribe = designer => {
		this.subscriberId = this.id;
		let subscriptions = [{
			eventType: 'Administration',
			actionTypes: ['APPLICATION_UPDATE', 'APPLICATION_DELETE'],
			entityIds: [this.configuration.applicationId || this.configuration.servicePresentationId]
		}];
		this.subscriptionHandler = RemoteEventsManager.subscribe(this.subscriberId, subscriptions);
	}

	destroy(dontUnsubscribe) {
		ReactDOM.unmountComponentAtNode(this.graphContainer[0]);
		if (this.reasonsWindowId) {
			ReactDOM.unmountComponentAtNode(document.getElementById(this.reasonsWindowId));
		}
		if (!dontUnsubscribe) {
			if (this.subscriptionHandler) {
				this.subscriptionHandler.unsubscribe();
			}
			Widget.prototype.destroy.call(this);
		}
	}
}
