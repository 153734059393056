import Utils from 'tools/utils';
import Cookies from 'core/cookies';
import Widget from 'areas/service-boards/widget';
import Settings from 'settings';
import CustomNotification from 'controls/customNotification';

import Highcharts from 'highcharts';
import RemoteEventsManager from 'core/remoteEventsManager';
import Renderer from 'tools/renderer';
import Configuration from 'configuration';
import moment from 'moment';
import translator from "core/localization";
import {getTrendValueWidgetWrapper} from "vendor-init/hightcharts-intergation";

const i18n = translator({});

export function MultigraphWidget(config) {
	Widget.call(this, config);

	this.requestPath = Settings.serverPath;
	if (this.sessionId) {
		this.requestPath = Settings.serverPath + 'sessions/' + this.sessionId + '/';
	}

	this.configuration.timezone = this.configuration.timezone === "<User Time>" ? Cookies.CeesoftTimezone : this.configuration.timezone;

	this.initComponent();
};

export {MultigraphWidget as default};

jQuery.extend(MultigraphWidget.prototype, Widget.prototype, {
	/**
	 * Main init function
	 */
	initComponent: function () {
		if (!this.renderTo) {
			this.renderTo = $('#' + this.id).find('.cw_section_content');
		}

		this.hasTimeSelector = true;
		this.hasMissingData = true;
		this.hasRegression = true;

		this.customControls = {
			target: '#' + this.id,
			toggleClick: function (value) {
				if ($('#' + this.id).find('.k-i-toggle').length) {
					$('#' + this.id).find('.k-i-toggle').trigger('click');
				} else {
					$('#' + this.id).closest('.k-window').find('.k-i-toggle').trigger('click');
				}
			}
		};
		this.createCustomControls();

		this.intervals = {};
		this.statusNotification = new CustomNotification({
			animationTime: 0,
			appendToElement: this.renderTo,
		});
		this.subscribe();
		this.getData();
	},
	/**
	 * Get chart data
	 */
	getData: function () {
		var url, startDate, endDate, width = this.renderTo.width() * 0.6, period;
		this.setTitleIfEmpty(i18n('Multigraph'));
		width = Math.max(width, 300);
		if (!$('#' + this.id).length && this.customControls && this.customControls.target) {
			width = this.customControls.target.width();
		}

		period = Utils.getPeriodInterval({
			period: this.zoomPeriod || this.configuration.period,
			startDate: this.zoomStartDate || this.configuration.startDate,
			endDate: this.zoomEndDate || this.configuration.endDate,
			width: width
		});
		this.interval = period.interval;

		if (this.zoomPeriod === 'CUSTOM' || this.configuration.period === 'CUSTOM') {
			startDate = new Date(this.zoomStartDate || this.configuration.startDate);
			endDate = new Date(this.zoomEndDate || this.configuration.endDate);

			url = this.requestPath + 'monitors/healthIndexes?ignoreMissingData=' + this.configuration.ignoreMissingData + '&timeZone=' + this.configuration.timezone + '&interval=' + this.interval + '&fromTime=' + startDate.getTime() + '&toTime=' + endDate.getTime();
		} else {
			url = this.requestPath + 'monitors/healthIndexes?ignoreMissingData=' + this.configuration.ignoreMissingData + '&timeZone=' + this.configuration.timezone + '&interval=' + this.interval + '&timeSelector=' + this.configuration.period;
		}

		var data = [];
		for (var i = 0; i < this.configuration.monitors.length; i++) {
			data.push({
                monitorId: this.configuration.monitors[i].monitorId || this.configuration.monitors[i].id,
				assetId: this.configuration.monitors[i].assetId
			});
		}
		var widgetContentDiv;
		if (!this.renderTo) {
			widgetContentDiv = $('#' + this.id).find('.cw_section_content');
		} else {
			widgetContentDiv = this.renderTo;
		}

		widgetContentDiv.addClass('assets-multigraphWidget');

		kendo.ui.progress(widgetContentDiv, true);
		Utils.ajax(url, 'POST', JSON.stringify(data), $.proxy(function (result) {
			if (result.data || result.success || Object.keys(result).length) {
				if (result.data && result.data.length) {
					this.render(result.data);
				} else if (result.length) {
					this.render(result);
				} else {
					if (this.onZoom) {
						kendo.ui.progress(widgetContentDiv, false);
						this.statusNotification.setOptions({
							message: lang.widget.messages.NO_ZOOM_AVAILABLE,
							status: 'error',
							style: 'left: 70px'
						}).show();
					} else {
						widgetContentDiv.empty().append('<div class="metric_chart"></div>');
						kendo.ui.progress(widgetContentDiv, false);
						this.statusNotification.setOptions({
							message: result.message,
							status: 'error'
						}).show();
					}
				}
			} else {
				if (!this.onZoom) {
					widgetContentDiv.empty().append('<div class="metric_chart"></div>');
				}
				kendo.ui.progress(widgetContentDiv, false);
				this.statusNotification.setOptions({
					message: result.message || lang.serviceBoard.messages.NO_DATA,
					status: 'error'
				}).show();
			}
		}, this), null, 60000, $.proxy(function () {
			kendo.ui.progress(widgetContentDiv, false);
			this.statusNotification.setOptions({
				message: lang.messages.NO_DATA_AVAILABLE,
				status: 'error'
			}).show();
		}, this));
	},
	getType: function (monitorId) {
		var type = '';
		for (var i = 0; i < this.configuration.monitors.length; i++) {
			if (this.configuration.monitors[i].id === monitorId) {
				type = this.configuration.monitors[i].monitorType;
				break;
			}
		}
		return type;
	},
	/*
	 * Handler function which renders the chart
	 */
	render: function (dataSet, noDataMessage) {
		noDataMessage = noDataMessage || '';
		var oThis = this, series, set, i, noDataState, averageSet, min, max, widgetContentDiv, zoom, length;
		var exporting = jQuery.extend(true, {}, Configuration.highcharts.exporting);
		if (this.onZoom) {
			exporting.enabled = true;
			exporting.buttons.popUpBtn = {
				onclick: $.proxy(function () {
					this.zoomStartDate = null;
					this.zoomEndDate = null;
					this.zoomPeriod = null;
					this.onZoom = false;
					this.unsubscribe();
					this.getData();
					this.subscribe();

					if (this.customControls && typeof this.customControls.zoom === 'function') {
						this.customControls.zoom.call(this, {
							startDate: this.configuration.startDate,
							endDate: this.configuration.endDate,
							period: this.configuration.period
						});
					}
				}, this),
				align: 'left',
				width: 5,
				x: 5,
				y: 5,
				text: lang.RESET,
				theme: {
					'stroke-width': 1,
					stroke: '#aaa',
					fill: '#fff',
					r: 0,
					states: {
						hover: {
							fill: '#eee'
						},
						select: {
							fill: '#ccc'
						}
					}
				}
			};
		}
		this.saveDisabledSeries();
		if (!this.renderTo) {
			widgetContentDiv = $('#' + this.id).find('.cw_section_content');
		} else {
			widgetContentDiv = this.renderTo;
		}
		widgetContentDiv.empty().append('<div class="metric_chart"></div>');
		this.dataSet = dataSet;
		this.data = [];
		var fullName, name;

		for (var i = 0, length = dataSet.length; i < length; i++) {
			var seriesItem = {};
			fullName = this.generateMultigraphLabel(dataSet[i]);
			name = fullName.length > 60 ? fullName.substr(0, 57) + '...' : fullName;

			seriesItem.name = name;
			seriesItem.fullName = fullName;
			seriesItem.visible = true;
			seriesItem.data = [];
			seriesItem.regression = this.configuration.showRegression;
			seriesItem.marker = {
				enabled: false
			};
			seriesItem.regressionSettings = {
				marker: {
					enabled: false
				},
				name: name,
				fullName: fullName,
				guid: Utils.guid(),
				type: 'linear',
				color: 'rgba(255,165,0, 1)'
			};

			if (!seriesItem.name) {
				seriesItem.showInLegend = false;
			}
			for (var j = 0, lng = dataSet[i].metrics.length; j < lng; j++) {
				seriesItem.data.push([dataSet[i].metrics[j].timestamp, dataSet[i].metrics[j].value]);
			}

			seriesItem.dataGrouping = {
				approximation: this.instanceConfiguration.aggregationType || "average"
			};

			this.data.push(seriesItem);
		}

		// remove mask
		kendo.ui.progress(widgetContentDiv, false);
		widgetContentDiv.find('.metric_chart').empty();

		Highcharts.setOptions({
			global: {
				useUTC: true,
				timezoneOffset: -moment.tz(this.configuration.timezone).utcOffset()
			}
		});

		let container = widgetContentDiv.find('.metric_chart');
		let height;
		let dashboardWindow = container.closest('.section__content');
		if (dashboardWindow.length) {
			height = dashboardWindow.height();
			container.css('height', dashboardWindow.height());
		} else {
			height = container.closest('.cw_section_content').height();
		}

		let header = dashboardWindow.closest('.html-shape-container').find('.toolbar_appearance_section-header').first();
		let missingHeader = false;
		if (header.css('display') === 'none' || header.width() === -30) {
			missingHeader = true;
		}
		if (this.instanceConfiguration.timeSelectorPosition === 'BOTTOM') {
			height = height - 30;
			this.moveTimeSelectorBottom(missingHeader);
		} else if (this.instanceConfiguration.timeSelectorPosition === 'TOP' && missingHeader)  {
			height = height - 30;
			this.renderTo.css('margin-top', '35px');
		}

		this.chart = new Highcharts.Chart({
			chart: {
				renderTo: widgetContentDiv.find('.metric_chart')[0],
				height: height,
				type: 'line',
				zoomType: 'x',
				events: {
					load: function () {
					},
					selection: $.proxy(function (event) {
						if (event.xAxis) {
							this.onZoom = true;
							this.zoomStartDate = parseInt(event.xAxis[0].min, 10);
							this.zoomEndDate = parseInt(event.xAxis[0].max, 10);
							this.zoomPeriod = 'CUSTOM';

							this.unsubscribe();

							if (this.customControls && typeof this.customControls.zoom === 'function') {
								this.customControls.zoom.call(this, {
									startDate: this.configuration.startDate,
									endDate: this.configuration.endDate,
									period: this.configuration.period
								});
							}
						}

						event.preventDefault();
						this.getData();
					}, this)
				},
				height: widgetContentDiv.height()
			},
			title: {
				text: ' '
			},
			legend: {
				title: {
					text: '', //lang.assethealth.MONITOR_NAME,
					align: 'center'
				},
				x: 2,
				y: 20,
				floating: false,
				borderWidth: 0,
				layout: 'horizontal',
				align: 'center',
				verticalAlign: 'bottom',
				useHTML: true,
				itemDistance: 15,
				itemStyle: {
					fontSize: "10px"
				},
				style: {
					fontSize: "10px"
				},
				labelFormatter: function () {
					if (this.userOptions.isRegressionLine) {
						return '<span title="' + (this.userOptions.fullName || this.userOptions.name) + '">' + i18n('Trend') + ': ' + this.name + '</span>';
					} else {
						return '<span title="' + (this.userOptions.fullName || this.userOptions.name) + '">' + this.name + '</span>';
					}
				}
			},
			credits: {
				enabled: false
			},
			exporting: exporting,
			xAxis: {
				type: 'datetime',
				labels: {
					staggerLines: 1
				},
				dateTimeLabelFormats: {
					millisecond: '%H:%M:%S',
					second: '%H:%M:%S',
					minute: '%H:%M',
					hour: '%H:%M',
					day: '%e. %b',
					week: '%e. %b',
					month: '%b \'%y',
					year: '%Y'
				},
				visible: this.instanceConfiguration.showXAxis
			},
			yAxis: {
				softMin: 0,
				minPadding: 0,
				title: {
					text: null
				},
				visible: this.instanceConfiguration.showYAxis
			},
			tooltip: {
				crosshairs: true,
				snap: 1,
				shared: true,
				useHTML: true,
				formatter: function (e) {
					var length = this.points.length, userOptions;
					var v = '';
					for (var i = 0; i < length; i++) {
						v = this.points[i].series.name + ': ' + this.y;
					}
					var s = Renderer.browserDateRenderer(this.x, 'datetime', '', oThis.configuration.timezone);

					if (oThis.configuration.showRegression) {
						const trendString = getTrendValueWidgetWrapper(this, oThis);
						if(trendString){
							v += '<br />' + trendString
						}

					}
					return s + '<br />' + v;
				}
			},
			plotOptions: {
				series: {
					marker: {
						enabled: false
					}
				},
				line: {
					events: {
						//click: $.proxy(this.onHealthHistoryPoint, this),
						//legendItemClick: $.proxy(this.onHealthHistoryLegend, this)
					}
				}
			},
			series: this.data
		});

		Highcharts.setOptions({
			global: {
				useUTC: false
			}
		});

		this.restoreDisabledSeries();
	},
	/*
	 * handler for clicking a point
	 */
	onPointClick: function (e) {
		var timeStamp = e.point.options.x, serieName = e.point.series.name;
	},
	/*
	 * Handler for clicking legend
	 */
	onLegendClick: function (e) {
		var clickedLegend = e.target.name;
	},
	/**
	 * Called when a metric event is received
	 */
	onEvent: function () {
		this.getData();
	},
	/**
	 * Triggered after widget resize
	 * @param {Object} event The resize event
	 * @param {Object} ui The UI element - see http://api.jqueryui.com/resizable/
	 */
	onResize: function (event, ui) {
		kendo.ui.progress(this.renderTo, true);
		this.unsubscribe();
		this.subscribe();
		this.getData();
		setTimeout(() => {
			this.createCustomControls();
		});
	},
	getAssetsIds: function () {
		var assetsIds = [];
		for (var i = 0; i < this.configuration.monitors.length; i++) {
			assetsIds.push(this.configuration.monitors[i].assetId);
		}
		return assetsIds;
	},
	/**
	 * Subscribes to qualifier emtrics events
	 * @param {Boolean} reload If true, after subscription reloads the charts data
	 */
	subscribe: function (reload) {
		var subscriptionObj = [{
			eventType: 'AssetHealth',
			assetIds: this.getAssetsIds()
		}];
		this.subscriberId = this.id;
		RemoteEventsManager.subscribe(this.subscriberId, subscriptionObj);
		if (reload) {
			this.getData();
		}
	},
	/**
	 * Destroy
	 */
	destroy: function () {
		Widget.prototype.destroy.call(this);
	}
});
