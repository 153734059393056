import {createModelSchemaWrapper} from "framework/serializr-integration";
import {CostModel} from "./costModel";
import {list, object, primitive} from "serializr";
import {makeAutoObservable} from "mobx";
import {ModelValidator, ValidatableModel} from "../../../framework/mobx-integration";
import {translator} from "core";
import {costTranslates} from "../translations";

const i = translator(costTranslates);

export class CostProfile implements ValidatableModel<CostProfile> {
	id: string;
	name: string;
	description: string;
	costModels: CostModel[];

	validator = new ModelValidator(this);
	private _exisingProfiles: CostProfile[] = [];

	constructor() {
		makeAutoObservable(this);

		this.validator
			.required('name')
			.add('name', () => this.validateName(this._exisingProfiles), i('Name exists'));
	}

	get valid() : boolean {
		return this.validator.valid;
	}

	validateName = (profiles: CostProfile[]) => {
		return !profiles.some((x: CostProfile) => x.name.trim().toLowerCase() == this.name?.trim()?.toLowerCase());
	}

	setExistsProfiles = (profiles: CostProfile[]): void => {
		this._exisingProfiles = profiles;
	}
}

createModelSchemaWrapper(CostProfile, {
	id: primitive(),
	name: primitive(),
	description: primitive(),
	costModels: list(object(CostModel))
})
