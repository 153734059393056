import Utils from 'tools/utils';
import Settings from 'settings';
import Application from 'core/application';
import State from 'tools/state';
import Cookies from 'core/cookies';
import RemoteEventsManager from 'core/remoteEventsManager';
import LocalEventsManager from "core/localEventsManager";
import AbsoluteServiceBoardView from './absoluteServiceBoardView'
import ServiceBoardView from './serviceBoardView'
import {ServiceBoardRouter} from './bundleDescription';

export default function GuestServiceBoard(config) {
	$('body').addClass(`custom-theme`);

	Utils.apply(this, config);
	this.module = {
		name: 'ServiceBoardView',
		initialConfig: config
	};
	this.layout = this.layout || 'grid';

	Utils.getConfig($.proxy(function () {
		this.initComponent();
	}, this));
};

jQuery.extend(GuestServiceBoard.prototype, Application.prototype, {
	/**
	 * Attaches listeners
	 */
	attachListeners: function () {
		$(window).keypress(function (e) {
			if (e.keyCode === 27) {
				e.preventDefault();
			}
		});
		$(window).bind('onbeforeunload', $.proxy(function () {
			this.logout();
		}, this));
	},
	/**
	 * Removes listeners
	 */
	removeListeners: function () {
		$(window).off();
	},
	/**
	 * Service Board aplication related initializations
	 */
	initComponent: function () {
		this.locale = Cookies.CeesoftUserLocale || 'en-US';
		this.removeListeners();
		this.attachListeners();
		this.kendoLocaleLoaded = false;
		kendo.culture(this.locale);
		this.kendoLocaleLoaded = true;
		this.localeLoaded = true;
		this.updateLangMessages();
		this.start();
	},

	updateLangMessages: function () {
		$.fn.kendoCustomGrid.widget.fn.options.noRecords = {
			template: lang.grid.NO_RECORDS
		};
		if (kendo.ui.Pager) {
			kendo.ui.Pager.fn.options.messages = lang.kendo.pager.messages;
		}
		if (kendo.ui.ColumnMenu) {
			kendo.ui.ColumnMenu.fn.options.messages = lang.kendo.columnMenu.messages;
		}
	},

	start: function () {
		if (this.kendoLocaleLoaded && this.localeLoaded) {
			this.doLogin();
		}
	},
	/**
	 * Authenticates the user and obtains the sessionId
	 */
	doLogin: function () {
		Cookies.erase("guestSessionId");
		var url = Settings.serverPath + 'accounts/dashboards/' + this.id + '/login';
		jQuery.ajax({
			url: url,
			type: "GET",
			dataType: "json",
			contentType: "application/json; charset=utf-8",
			crossDomain: true,
			beforeSend: function (x) {
				if (x && x.overrideMimeType) {
					x.overrideMimeType("application/json;charset=UTF-8");
				}
			},
			error: function (result, message) {
				Utils.showInfo(lang.ALERT, lang.account.messages.WARNING_USER_AUTH_CONNECTION_ISSUE);
			},
			success: $.proxy(function (result) {
				if (result.success) {
					if (result.data.locale && this.locale !== result.data.locale) {
						kendo.culture(result.data.locale);
					}
					if (result.data.sessionId) {
						this.sessionId = result.data.sessionId;
					}
					this.requestPath = Settings.serverPath + 'sessions/' + this.sessionId + '/';
					this.accountId = result.data.accountId;
					//Set all the cookies. Might break an existing session.
					Cookies.create("guestSessionId", result.data.sessionId, 24);
					Cookies.create("sessionId", result.data.sessionId, 24);
					Cookies.create("CeesoftTimezone", result.data.timeZone, 24);
					Cookies.create("CeesoftUserLocale", result.data.locale, 24);
					Cookies.create("CeesoftUserDateTimeFormat", result.data.dateTimeFormat, 24);
					Cookies.create("CeesoftUserDateFormat", result.data.dateFormat, 24);
					Cookies.create("CeesoftUserTimeFormat", result.data.timeFormat, 24);
					if (this.id) {
						this.createSocketConnection();
					}
				} else {
					switch (result.message) {
						case 'wizard':
							window.location = 'Wizard.jsp';
							break;
						case 'umchpwd':
							window.location = 'login.jsp';
							break;
						default:
							Utils.showInfo(lang.ALERT, result.message, result.details);
							setTimeout(function () {
								window.location = 'login.jsp';
							}, 3000);
					}
				}
			}, this)
		});
	},
	/*
	 * Handler function for creating the event source
	 * */
	createSocketConnection: function () {
		this.subscriberId = Utils.guid();
		this.eventSourceId = Utils.guid();

		RemoteEventsManager.connect({
			sessionId: this.sessionId || Cookies.guestSessionId,
			subscriberId: this.subscriberId,
			eventSourceId: this.eventSourceId,
			handleEvents: $.proxy(this.handleEvents, this),
			onInitialized: $.proxy(this.loadProperBoard, this),
			onSessionExpired: function () {
				location.reload();
			}
		});
	},
	loadProperBoard: function () {
		const config = {
			id: this.id,
			sessionId: this.sessionId || Cookies.guestSessionId,
			hasEvents: true,
			accountId: this.accountId,
			isGuest: true,
			layout: this.layout,
			headers: this.headers,
			widgetWidth: this.widgetWidth,
			widgetHeight: this.widgetHeight,
			showName: this.showName,
			showUpdateTime: this.showUpdateTime,
			informationOption: this.informationOption,
			position: this.position,
			resolution: this.resolution,
			renderName: true
		};

		let module;
		if (this.layout === 'free') {
			module = new AbsoluteServiceBoardView();
		} else {
			module = new ServiceBoardView();
		}
		State.currentApp = module;
		module.init(this.id, config);

		$('.sboard_scroll').css('background-color', this.backgroundColor || '#dee8ec');
	},
	/**
	 * Renders a serviceboard based on JSON object
	 * @param {Object} serviceBoard
	 */
	renderContent: function (serviceBoard) {
	},
	/**
	 * Called when one or multiple events are received from the server
	 * @param {Object} data The event data
	 */
	handleEvents: function (data) {
		LocalEventsManager.trigger('boardUpdate');

		if (State.currentApp && State.currentApp.handleEvents) {
			State.currentApp.handleEvents(data);
		}

	},

	logout: function () {
		var sessionId = this.sessionId || Cookies.guestSessionId;
		if (sessionId) {
			var sessions = [sessionId];
			var url = Settings.serverPath + 'sessions/logout/';
			Utils.ajax(url, 'POST', JSON.stringify(sessions), function (result) {
				if (result.success) {
					//console.log("Logout completed successfully");
				}
			}, function () {

			});
		}
	}
});
