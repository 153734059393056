import { ApiRequest } from "framework/api"

export interface Account {
	id: string,
	name: string
}

export function copyMonitor(accountId: string, monitorId: string) {
	return new ApiRequest<any>({
		url: `accounts/${accountId}/monitors/${monitorId}/copy`
	});
}
