import {PreferencesPanel} from 'controls';

export default class DiskFilterPanel {
	constructor(prefix, mode) {
		this.prefix = prefix;
		this.mode = mode;
	}

	create(diskGrid) {
		this.diskFilterPanel = new PreferencesPanel({
			renderTo: `cw_${this.prefix}disk_filterpanel`,
			grid: diskGrid,
			searchFields: ['name'],
			hideViews: true
		});

		return this.diskFilterPanel;
	}
}
