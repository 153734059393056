import React from "react";

import {Store} from 'core/react';

export let WidgetConfigurationContext = React.createContext();

export class WidgetConfigurationStore extends Store{
	constructor(props) {
		super(props, WidgetConfigurationContext);

		let context = {
			setValidation: this.setValidation,
			validation: {},

			config: this.props.config,

		}

		this.setInitialContext(context);
	}

	onAfterContextChanged = () => {
		this.whenChanged('config', () => {
			this.props.onChange(JSON.parse(JSON.stringify(this.state.context.config)))
		});

		this.whenChanged('validation', () => {
			this.props.onValidate(JSON.parse(JSON.stringify(this.state.context.validation)))
		});
	}

	setValidation = (name, isValid, validationDetails) =>{
		this.state.context.change(context => {
			if(isValid){
				delete context.validation[name]
			}else {
				context.validation[name] = validationDetails;
			}
		});
	}
}