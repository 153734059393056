import {Settings} from "settings";
import {Cookies} from "core";
import {Api} from "tools"

export class AdministrationApi {
	static getUserUrl(id) {
		return Api.accountRoot() + `users/${id}/?update=true`;
	}

	static getAssetGroupUrl(accountId, id, update) {
		return Api.accountRoot(accountId) + `assetGroups/${id}/?update=${update}`;
	}

	static getAuthenticationUrl(accountId, id, update) {
		return Api.accountRoot(accountId) + `authentications/${id}/?update=${update}`;
	}

	static getTeamUrl(accountId, id, update) {
		return Api.accountRoot(accountId) + `teams/${id}/?update=${update}`;
	}
}
