import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import {reducer as formReducer} from "redux-form";
import createSagaMiddleware from 'redux-saga';
import {rootSaga} from "../sagas";
import {rootReducer} from "../reducers"

import {listener} from './actionListener';
import {logger} from './logger';
import {localToStore} from "./localToStore";

export default function getStore() {
	const middlewares = [];

	if (true | window.REDUX_DEBUG) {
		middlewares.push(logger);
	}

	const sagaMiddleware = createSagaMiddleware();

	middlewares.push(sagaMiddleware, listener.getMiddleware());

	const reducer = combineReducers({
		form: formReducer,
		formBuilder: rootReducer
	});

	const store = createStore(
		reducer,
		compose(applyMiddleware(...middlewares))
	);

	localToStore(store);

	sagaMiddleware.run(rootSaga);

	return store;
}
