import React from 'react';
import ReactDOM from 'react-dom';
import Widget from './widget'

export default class WidgetReactContainer extends Widget {
	constructor(reactWidget, settingsComponent, config, className) {
		super(config);

		this.config = config;
		this.reactWidget = reactWidget;
		this.settingsComponent = settingsComponent;

		if (this.config.customControls) {
			this.widgetSettingsNode = $('<div class="cw_widget_settings"/>');
			$(this.config.customControls.target).append(this.widgetSettingsNode);
		}

		if (className) {
			$(`#${this.id}`).addClass(className)
		}

		this.events = new kendo.Observable();
		this.render();
	}

	render() {
		const ReactWidget = this.reactWidget;
		const WidgetSettings = this.settingsComponent;

		ReactDOM.render(
			<ReactWidget config={this.config}
			             events={this.events}
						 setTitle={title => this.setTitle(title)}
						 unsetTitle={this.unsetTitle.bind(this)}
			             requestPath={this.getRequestPath.bind(this)}/>,
			this.getDomNode()
		);

		if (this.config.customControls) {
			ReactDOM.render(<WidgetSettings
				config={this.config.customControls}
				componentConfig={this.config.configuration}
				events={this.events}
			/>, this.getWidgetSettingsNode());
		}
	}

	getDomNode() {
		return document.getElementById(this.id)?.querySelector('.cw_section_content');
	}

	getWidgetSettingsNode() {
		return this.widgetSettingsNode.get(0);
	}

	onEvents(events) {
		console.log('trigger', events)
		this.events.trigger('events', events);
	}

	onResize(event, ui) {
		this.destroy();
		this.render();
	}

	destroy() {
		try {
			if (this.config.customControls) {
				ReactDOM.unmountComponentAtNode(this.getWidgetSettingsNode());
			}
			let node = this.getDomNode();
			if (node) {
				ReactDOM.unmountComponentAtNode(node);
			}
		} catch(e) {
			if (!['NotFoundError', 'Invariant Violation'].includes(e.name)) {
				throw e;
			}
		}
	}
}
