import './webPageWidget.less';

import React from 'react';


export class WebPageWidget extends React.PureComponent {
	constructor(props) {
		super(props);

		this.iframeRef = React.createRef();
	}

	componentDidMount() {
		let refreshRate = this.props.config.configuration.refreshRate || 3600;
		if( refreshRate < 5){
			refreshRate = 5
		}
		this.interval = setInterval(this.reloadIframe, refreshRate*1000);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	reloadIframe = () => {
		this.iframeRef.current.src = this.iframeRef.current.src;
	}

	render() {
		return <iframe src={this.getUrl()}
		               ref={this.iframeRef}
		               className={'sb-web-page-widget'}
		/>
	}

	getUrl(){
		let url = this.props.config.configuration.url;
		if( url.indexOf('http') != 0 ){
			url = 'http://' + url;
		}
		return url;
	}
}

export default WebPageWidget;
