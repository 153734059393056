import {Application, LocalEventsManager, Cookies, translator} from "core";
import {Chosen, CustomNotification, ExpandableTextarea, ImageUploader, Tags} from "controls";
import {CeeViewDataSource} from "tools";
import ErrorHandler from "core/errorHandler";
import Settings from "settings";
import {Utils, Api, State} from "tools";
import CalendarsAPI from "./calendar/api";

const i = translator();

export let CalendarForm = function (config) {
	Utils.apply(this, config);
	this.initComponent();
};

export default CalendarForm;

jQuery.extend(CalendarForm.prototype, Application.prototype, {
	/**
	 * This is main init function
	 */
	initComponent: async function () {
		this.formEl = $('#cw_calendar_form');
		this.accountId = this.accountId || Cookies.CeesoftCurrentAccountId;
		this.descriptionHandler = new ExpandableTextarea({
			renderTo: this.formEl.find('.cw_description')
		});
		this.removeListeners();
		this.attachListeners();
		this.initKendoComponents();

		this.statusNotification = new CustomNotification({
			appendToElement: '.window_area',
			status: 'success',
			style: 'top:15px; right:15px; left:15px;',
			type: 'icon',
			hideOnClick: true
		});

		this.modalNotification = new CustomNotification({
			appendToElement: '#modal',
			status: 'success',
			type: 'icon',
			hideOnClick: true
		});

		if (this.mode === 'create') {
			this.onCalendarNameKeyup();
		}
	},
	/**
	 * Initialize Kendo UI controls
	 */
	initKendoComponents: function () {
		this.countryDataSource = new kendo.ceeview.DataSource({
			transport: {
				read: {
					url: Settings.serverPath + 'calendars/holiday/countries',
					contentType: "application/json; charset=utf-8",
					type: "GET",
					dataType: "json",
					cache: false
				}
			},
			schema: {
				parse: function (result) {
					if (result.success) {
						return result.data
					}
				}
			},
			error: ErrorHandler.kendoServerError
		});
		this.countryDropDown = $('#cw_country_selector').kendoDropDownList({
			dataSource: this.countryDataSource,
			dataTextField: 'text',
			dataValueField: 'value',
			enable: false
		}).data('kendoDropDownList');
	},

	removeListeners: function () {
		$('#save_calendar').off();
		$('#cancel').off();
	},
	/**
	 * Attaches the listeners for the form's buttons
	 */
	attachListeners: function () {
		$('#cancel').on('click', $.proxy(this.onCancelButton, this));
		$('#save_calendar').on('click', $.proxy(this.onSaveButton, this));
		$('#cw_national_holidays').on('click', $.proxy(this.onHolidaysToggle, this));
		$('#cw_planning_calendar').on('click', $.proxy(this.onPlanningToggle, this));
		$('.js_calendar_name').on('keyup', $.proxy(this.onCalendarNameKeyup, this));
	},
	/**
	 * Removes the invalid CSS class for all controls
	 */
	clearInvalid: function () {
		this.formEl.find('.js_calendar_name').removeClass('invalid');
	},
	/**
	 * Callback function that handles the save response
	 * @param {Object} result
	 */
	onDataSuccessfullySaved: function (result) {
		if (result.success) {
			//$('#calendars_grid').data('kendoCustomGrid').dataSource.read();
			let isPlanningSelected = $('#cw_planning_calendar').is(':checked');
			let isHolidaySelected = $('#cw_national_holidays').is(':checked');
			let calendarType = isPlanningSelected ? 'generic' : 'holiday';
			let calendarObj = {
				id: result.data.id,
				calendarType: calendarType,
				firstLoad: isPlanningSelected && isHolidaySelected ? true : null,
			};
			State.mainApp.loadModule('AdministrationCalendarsDetails', null, calendarObj);
			//close window
			$('#cancel').trigger('click');
		}
		else {
			this.modalNotification.setOptions({
				message: result.message,
				status: 'error'
			}).show();
		}
	},
	onHolidaysToggle(e) {
		this.holidaysChecked = $(e.target).is(':checked');
		this.countryDropDown.enable(this.holidaysChecked);
		if (this.name && (this.holidaysChecked || this.planningChecked)) {
			$('#save_calendar').attr('disabled', false);
		} else {
			$('#save_calendar').attr('disabled', true);
		}
	},
	onPlanningToggle(e) {
		this.planningChecked = $(e.target).is(':checked');
		if (this.name && (this.holidaysChecked || this.planningChecked)) {
			$('#save_calendar').attr('disabled', false);
		} else {
			$('#save_calendar').attr('disabled', true);
		}
	},
	/**
	 * Handler function for the click event on Save button
	 * @param {Object} e The click event object
	 */
	onSaveButton: async function (e) {
		this.name = this.formEl.find('.js_calendar_name').val();
		if(this.name.trim() !== '') {
			let nameExists = await Utils.checkIfNameExists(CalendarsAPI.getSearchUrl(), this.name, this.modalNotification, true);
			if(nameExists) {
				return;
			}
		}
		var description = this.descriptionHandler.value();
		var calendarType;
		var data = {
			name: this.name.trim(),
			description: description.trim(),
			accountId: this.accountId
		};
		this.save(data);
	},
	onCalendarNameKeyup() {
		this.name = this.formEl.find('.js_calendar_name').val();
		Utils.setInvalidField(this.name, this.formEl.find('.js_calendar_name'), false, 'required_name');
		Utils.setPlaceholder($('.js_calendar_name'), i('Please input name...'));
		if (this.name && (this.holidaysChecked || this.planningChecked)) {
			$('#save_calendar').attr('disabled', false);
		} else {
			$('#save_calendar').attr('disabled', true);
		}
	},
	async save(data) {
		var calendarType, holidays = [], events = [], eventString, beginIndex, endIndex, startString, endString, finalString;
		if (this.planningChecked) {
			calendarType = 'generic';
			if (this.holidaysChecked) {
				let currentYear = new Date().getFullYear();
				for (let i = currentYear; i < currentYear + 3; i++) {
					var result = await CalendarsAPI.generateNewHolidayCalendar(i, this.countryDropDown.value());
					if (result.success) {
						holidays.push(result.data);
					} else {
						this.modalNotification.setOptions({
							message: result.message,
							status: 'error'
						}).show();
					}
				}
				for (let j = 0; j < holidays.length; j++) {
					beginIndex = holidays[j].indexOf('BEGIN:VEVENT');
					startString = holidays[j].substring(0, beginIndex - 1);
					eventString = holidays[j].substring(beginIndex, holidays[j].length - 1);
					endIndex = eventString.indexOf('END:VCALENDAR');
					endString = eventString.substring(endIndex, eventString.length - 1);
					eventString = eventString.substring(0, endIndex);
					events.push(eventString);
				}
				finalString = startString;
				finalString += '\n';
				for (let k = 0; k < events.length; k++) {
					finalString += events[k];
				}
				finalString += endString;
				data.icalendar = finalString;
				this.icalendar = finalString;
			} else {
				data.icalendar = '';
			}
		} else {
			calendarType = 'holiday';
			data.countryIdentifier = this.countryDropDown.value();
		}
		var url = Settings.serverPath + 'calendars/' + calendarType;
		Utils.ajax(url, 'POST', JSON.stringify(data), $.proxy(this.onDataSuccessfullySaved, this));
	},
	/**
	 * Handler function for the click event on Cancel button
	 * @param {Object} e The click event object
	 */
	onCancelButton: function (e) {
		var modalWindow = $('#modal').data("kendoWindow");
		modalWindow.close();
		modalWindow.destroy();
	}
});
