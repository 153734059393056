import React from "react"
import {translator} from "core";
import {EventStateChart} from "areas/service-boards/widgets/eventState/eventStateChart";
import {EventSummaryRouter} from "areas/views/bundleDescription"
import './eventStateHomeView.less'

let i = translator({
  "Event state": {
    "no": "Hendelse tilstand"
  },
  "Total number of event": {
    "no": "Totalt antall Hendelser"
  }
});

let b = require('b_').with('event-state-home-view');

export class EventStateHomeView extends React.PureComponent {
	constructor(props) {
		super (props);
		this.state = {

		};
	}
	render() {
		return (
			<div className={b()}>
				<div className={b('titlebar')}>
					<span className={b('title')}>{i('Event state')}</span>
				</div>
				<div className={b('chart-container')}>
					<EventStateChart data={this.props.data}></EventStateChart>
				</div>
				<div title={i('Total number of event')}
				     onClick={this.redirectToEventSummary}
					 className={b(`footer ${this.props.data.total ? 'active': ''}`)}>{this.props.data.total}</div>
			</div>
		)
	}

	redirectToEventSummary = () => {
		State.mainApp.navigate(EventSummaryRouter.list())
	}
}
