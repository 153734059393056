import Utils from 'tools/utils';
import Cookies from 'core/cookies';
import Settings from 'settings';
import Tags from 'controls/tags';
import LocalEventsManager from 'core/localEventsManager';
import ErrorHandler from 'core/errorHandler';
import State from 'tools/state';
import ConfigurationBase from 'areas/service-boards/configurationBase';
import React from 'react';
import ReactDOM from 'react-dom';
import SortingPanel from "./sortingPanel/sortingPanel";
import FilteringPanel from "areas/services/widgets/filterPanel/filteringPanel";
import {BoxView} from 'controls/react/layout/boxView';

export function SelectiveServicesWidgetConfiguration(config) {
	Utils.apply(this, config);
	this.widgetConfigurationFormEl.empty().load('include/gsboard/SelectiveServicesForm.jsp', $.proxy(function () {
		this.initComponent();
	}, this));
};

export {SelectiveServicesWidgetConfiguration as default}

jQuery.extend(SelectiveServicesWidgetConfiguration.prototype, ConfigurationBase.prototype, {
	/**
	 * @cfg {Object} widgetObj
	 */
	/**
	 * Main init function
	 */
	initComponent: function () {
		this.accountServices = [];
		var widgetObj = this.widgetObj;

		$('.cw_widget_title').val(widgetObj.title);
		if (widgetObj.configuration.columns && widgetObj.configuration.showIncidentOverview) {
			
			$('.cw_selectiveservices_incident').removeClass('hide');
			//on edit reset all and set only if in configuration
			$('.cw_show_column').prop('checked', false);

			if (widgetObj.configuration.columns.status && !widgetObj.configuration.columns.status.hidden) {
				$('#cw_incidents_status').prop('checked', true);
			}
			if (widgetObj.configuration.columns.modified && !widgetObj.configuration.columns.modified.hidden) {
				$('#cw_incidents_modified').prop('checked', true);
			}
			if (widgetObj.configuration.columns.priorityName && !widgetObj.configuration.columns.priorityName.hidden) {
				$('#cw_incidents_priority').prop('checked', true);
			}
			if (widgetObj.configuration.columns.accountName && !widgetObj.configuration.columns.accountName.hidden) {
				$('#cw_incidents_account').prop('checked', true);
			}
			if (widgetObj.configuration.columns.incidentNumber && !widgetObj.configuration.columns.incidentNumber.hidden) {
				$('#cw_incidents_number').prop('checked', true);
			}
			if (widgetObj.configuration.columns.createdBy && !widgetObj.configuration.columns.createdBy.hidden) {
				$('#cw_incidents_reportedby').prop('checked', true);
			}
			if (widgetObj.configuration.columns.owner && !widgetObj.configuration.columns.owner.hidden) {
				$('#cw_incidents_owner').prop('checked', true);
			}
			if (widgetObj.configuration.columns.contentType && !widgetObj.configuration.columns.contentType.hidden) {
				$('#cw_incidents_type').prop('checked', true);
			}
			if (widgetObj.configuration.columns.subject && !widgetObj.configuration.columns.subject.hidden) {
				$('#cw_incidents_subject').prop('checked', true);
			}
			if (widgetObj.configuration.columns.assignedTo && !widgetObj.configuration.columns.assignedTo.hidden) {
				$('#cw_incidents_assignedto').prop('checked', true);
			}
			if (widgetObj.configuration.columns.created && !widgetObj.configuration.columns.created.hidden) {
				$('#cw_incidents_created').prop('checked', true);
			}
			if (widgetObj.configuration.columns.description && !widgetObj.configuration.columns.description.hidden) {
				$('#cw_incidents_description').prop('checked', true);
			}
		}
		$('#cw_incident_counter_check').prop('checked', widgetObj.configuration.showIncidentCounter);
		$('#cw_incident_overview_check').prop('checked', widgetObj.configuration.showIncidentOverview);
		$('#cw_show_closed_check').prop('checked', widgetObj.configuration.showClosedIncidents);

		this.removeListeners();
		this.attachListeners();

		this.initKendoComponents();
	},
	/**
	 * Removes listeners
	 */
	removeListeners: function () {
		this.widgetConfigurationFormEl.off('click', '#cw_all_accounts');
		this.widgetConfigurationFormEl.off('click', '#cw_all_services');
		this.widgetConfigurationFormEl.off('click', '#cw_dynamic_services');
		this.widgetConfigurationFormEl.off('click', '#cw_incident_overview_check');
	},
	/**
	 * Attaches listeners
	 */
	attachListeners: function () {
		this.widgetConfigurationFormEl.on('click', '#cw_all_accounts', $.proxy(this.onAllAccountsToggle, this));
		this.widgetConfigurationFormEl.on('click', '#cw_all_services', $.proxy(this.onAllServicesToggle, this));
		this.widgetConfigurationFormEl.on('click', '#cw_dynamic_services', $.proxy(this.onDynamicServicesToggle, this));
		this.widgetConfigurationFormEl.on('click', '#cw_incident_overview_check', $.proxy(this.onOverviewClick, this));
	},

	initSortingPanel: function () {
		if (!this.sortingItems) {
			this.sortingItems = [{type: 'NAME', sorting: 'ASC'}]
		}

		ReactDOM.render(<SortingPanel items={this.sortingItems}
									  onChange={(values) => this.sortingItems = values}/>, document.getElementById('sorting_panel'))
	},

	initFilteringPanel: function () {
		ReactDOM.render(<FilteringPanel
			config={this.widgetObj.configuration}
			filterType={'selectiveServicesFilterTypes'}
			onChange={(values) => {
				this.filtering = values;
			}}/>, document.getElementById('formating_panel'))

		setTimeout(() => {
			let stateValues = [];
			let states = this.widgetObj.configuration.serviceStateList;
			if (states) {
				for (let i = 0; i < states.length; i++) {
					stateValues.push(states[i].value);
				}
			}
			let stateSelector = $('.selective-service__filtering-panel__reset-field').find('select').data('kendoSortedMultiSelect');
			let dataSource = stateSelector.dataSource.data();
			let newStates = [];
			for (let j = 0; j < dataSource.length; j++) {
				if (stateValues.indexOf(dataSource[j].value) !== -1) {
					newStates.push(dataSource[j]);
				}
			}
			stateSelector.value(newStates);
		});
	},

	saveAdditionalFields: function (config) {
		config.sorting = this.sortingItems;

		Object.assign(config, this.filtering);

		delete config.sortingOption;
		delete config.sortingDirection;
	},

	loadFields: function () {
		let config = this.widgetObj.configuration;

		if (config.sorting) {
			this.sortingItems = config.sorting;
		}
		else if(config.sortingOption && config.sortingDirection) {
			this.sortingItems = [{ type: config.sortingOption, sorting: config.sortingDirection }]
		}

		this.initSortingPanel();
		this.initFilteringPanel();
	},
	/**
	 * Iniitalizes kendo components
	 */
	initKendoComponents: function () {
		var widgetObj = this.widgetObj;

		this.loadFields();

		LocalEventsManager.bind('setServicesList', $.proxy(this.setServicesList, this));

		this.servicesFormObject = {
			accountId: 0,
			serviceId: 0,
			serviceName: ''
		};
		this.servicesAccounts = $('#cw_st_accountids').kendoSortedMultiSelect({
			index: 0,
			dataSource: new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: Settings.serverPath + 'accounts/' + this.serviceBoardAccountId + '/subaccounts/allLevels/lite',
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				schema: {
					parse: $.proxy(function(data) {
						this.allAccountsId = Utils.guid();
						data.unshift({
							id: this.allAccountsId,
							name: '-All Accounts-'
						});
						return data;
					}, this)
				},
				error: ErrorHandler.kendoServerError,
				sort: {field: 'name', dir: 'asc'}
			}),
			dataTextField: 'name',
			dataValueField: 'id',
			template: '<span id="${ data.id }">${ data.name }</span>',
			placeholder: lang.reports.messages.SELECT_ACCOUNT,
			change: $.proxy(this.onAccountChange, this),
			select: $.proxy(function (e) {
				this.selectedAccountName = e.item.text();
			}, this),
			itemTemplate: '<span data-id="${id}"> ${name}</span>',
			value: widgetObj.configuration.accountsList ? widgetObj.configuration.accountsList : Cookies.CeesoftCurrentAccountId,
			dataBound: $.proxy(function () {
				if (this.mode !== 'create') {
					this.setComponents(widgetObj);
				}
				$('#cw_st_accountids').data('kendoSortedMultiSelect').ul.find('li.k-item.k-state-selected').trigger('click').trigger('click');
			}, this)
		}).data('kendoSortedMultiSelect');

		this.servicesList = $('#cw_st_serviceids').kendoSortedMultiSelect({
			dataTextField: 'name',
			dataValueField: 'id',
			template: '<span id="${ data.id }">${ data.name }</span>',
			tagTemplate: '<span title="${data.name}">${data.name}</span>',
			placeholder: lang.reports.messages.SELECT_SERVICES,
			select: $.proxy(function (e) {
				if (e.dataItem.id === this.allServicesId) {
					let currentDatasource = e.sender.dataSource.data();
					let ids = [];
					for (var i = 0; i < currentDatasource.length; i++) {
						if (currentDatasource[i].id !== this.allServicesId) {
							ids.push(currentDatasource[i].id);
						}
					}
					this.servicesList.value(ids);
				}
			}, this),
			change: () => this.setRequiredServices(),
			value: widgetObj.configuration.servicesList ? widgetObj.configuration.servicesList :[],
			filter: 'contains'
		}).data('kendoSortedMultiSelect');

		this.showDescription = $('#cw_show_description');
		this.showInformation = $('#cw_show_information');
		this.showAccountName = $('#cw_show_account_name');
		if (this.mode === 'update' || this.wasUpdate) {
			this.showDescription.prop('checked', widgetObj.configuration.showDescription);
			this.showInformation.prop('checked', widgetObj.configuration.showInformation);
			this.showAccountName.prop('checked', widgetObj.configuration.showAccountName);
		}
		if (!this.servicesAccounts.value().length) {
			$('#cw_st_accountids').parent().find('.k-multiselect-wrap').addClass('required_multiselect');
		}
		if (!this.servicesList.value().length && !$('#cw_dynamic_services').is(':checked')) {
			$('#cw_st_serviceids').parent().find('.k-multiselect-wrap').addClass('required_multiselect');
		}
	},
	/*
	 * Handler function for getting the widget configuration values
	 * */
	getValues: function () {
		var title = $('.cw_widget_title').val().trim();
		var servicesListCombo = $('#cw_st_serviceids').data('kendoSortedMultiSelect');

		var statusCheck = $('#cw_incidents_status'),
			modifiedCheck = $('#cw_incidents_modified'),
			priorityCheck = $('#cw_incidents_priority'),
			accountCheck = $('#cw_incidents_account'),
			numberCheck = $('#cw_incidents_number'),
			reportedByCheck = $('#cw_incidents_reportedby'),
			ownerCkeck = $('#cw_incidents_owner'),
			typeCheck = $('#cw_incidents_type'),
			subjectCheck = $('#cw_incidents_subject'),
			assignedToCheck = $('#cw_incidents_assignedto'),
			createdCheck = $('#cw_incidents_created'),
			descriptionCheck = $('#cw_incidents_description');

		var widgetObj = {
			id: this.mode === 'update' || this.wasUpdate ? this.widgetObj.id : Utils.guid(),
			type: 'services',
			title: title,
			configuration: {
				accountsList: this.servicesAccounts.value(),
				servicesList: this.servicesList.value(),
				showDescription: $('#cw_show_description').is(":checked"),
				showInformation: $('#cw_show_information').is(":checked"),
				showAccountName: $('#cw_show_account_name').is(":checked"),
				showIncidentCounter: $('#cw_incident_counter_check').is(':checked'),
				showIncidentOverview: $('#cw_incident_overview_check').is(':checked'),
				showClosedIncidents: $('#cw_show_closed_check').is(':checked'),
				ignoreUserTags: $('#cw_ignore_user_tags').is(':checked'),
				showUntagged: $('#cw_untagged').is(':checked'),
				columns: {
					status: {
						hidden: statusCheck.is(':not(:checked)')
					},
					modified: {
						hidden: modifiedCheck.is(':not(:checked)')
					},
					priorityName: {
						hidden: priorityCheck.is(':not(:checked)')
					},
					accountName: {
						hidden: accountCheck.is(':not(:checked)')
					},
					incidentNumber: {
						hidden: numberCheck.is(':not(:checked)')
					},
					createdBy: {
						hidden: reportedByCheck.is(':not(:checked)')
					},
					owner: {
						hidden: ownerCkeck.is(':not(:checked)')
					},
					contentType: {
						hidden: typeCheck.is(':not(:checked)')
					},
					subject: {
						hidden: subjectCheck.is(':not(:checked)')
					},
					assignedTo: {
						hidden: assignedToCheck.is(':not(:checked)')
					},
					created: {
						hidden: createdCheck.is(':not(:checked)')
					},
					description: {
						hidden: descriptionCheck.is(':not(:checked)')
					}
				}
			}
		};

		if ($('#cw_dynamic_services').is(':checked')) {
			var accountsListCombo = $('#cw_st_accountids').data('kendoSortedMultiSelect');
			//widgetObj.configuration.accountsList = accountsListCombo.value();
			widgetObj.configuration.dynamicServices = true;
		} else {
			widgetObj.configuration.servicesList = servicesListCombo.value();
		}

		this.saveAdditionalFields(widgetObj.configuration);

		return widgetObj;
	},
	/*
	 * Checks if form is valid
	 * */
	isValid: function () {
		var valid = true;
		this.validationMessage = '';

		var accountsListCombo = $('#cw_st_accountids').data('kendoSortedMultiSelect');
		let dynamicServicesCheck = $('#cw_dynamic_services').is(':checked');

		if (!accountsListCombo.value().length) {
			valid = false;
			this.validationMessage = lang.widget.SELECT_ACCOUNT;
		} else if (!this.servicesList.value().length && !dynamicServicesCheck) {
			valid = false;
			this.validationMessage = lang.widget.SELECT_SERVICE;
		}

		return {
			valid: valid,
			validationMessage: this.validationMessage
		};
	},
	/*
	 * Handler function for toggle all accounts
	 * @param {Object} e The click event
	 */
	onAllAccountsToggle: function (e) {
		if ($(e.currentTarget).is(':checked')) {
			$('#cw_st_accountids').data('kendoSortedMultiSelect').ul.find('li').not('.k-state-selected').trigger('click');
		}
	},
	/*
	 * Handler function for toggle all services
	 * @param {Object} e The click event
	 */
	onAllServicesToggle: function (e) {
		if ($(e.currentTarget).is(':checked')) {
			$('#cw_st_serviceids_listbox').find('li').not('.k-state-selected').trigger('click');
		}
	},
	/*
	 * Handler function for toggle use of dynamic services
	 * @param {Object} e The click event
	 */
	onDynamicServicesToggle: function (e) {
		var allServices = $('#cw_all_services'), dynamicServicesCheck = $(e.currentTarget).is(':checked');
		ReactDOM.unmountComponentAtNode($('#cw_dynamic_services_info').get(0));
		if (dynamicServicesCheck) {
			this.servicesList.value('');
			this.renderDynamicServiceInfo();
		}
		this.setRequiredServices();

		allServices.prop('checked', false).prop('disabled', dynamicServicesCheck);
		this.servicesList.enable(!dynamicServicesCheck);
	},
	renderDynamicServiceInfo: function () {
		let message = lang.widget.DYNAMIC_SERVICE_INFO;
		ReactDOM.render(<BoxView type={"info-2"} border={true}>
			<div>{message}</div>
		</BoxView>, $('#cw_dynamic_services_info').get(0));
	},
	/*
	 * Handler function for clicking the incident overview checkbox
	 * @param {Object} e The click event
	 * */
	onOverviewClick: function (e) {
		var target = $(e.currentTarget), incidentColumns = $('.cw_selectiveservices_incident').closest('.cw_field');
		if (target.is(':checked')) {
			incidentColumns.removeClass('hide');
		} else {
			incidentColumns.addClass('hide');
		}
	},
	/**
	 * Handler function for account dropdown change event used generically
	 * @param {Object} e The change event object
	 */
	onAccountChange: function (e) {
		var previous = this._savedOld;
		var selectedAccounts = this.servicesAccounts.value();
		var removeServices = [];
		var newServicesDS = [];
		var diff = [];
		var selectedAccountId, url, uid, item, selectedServices, foundSelectedKey, currentAccounts, currentAccount, id;

		if (!selectedAccounts.length) {
			$('#cw_st_accountids').parent().find('.k-multiselect-wrap').addClass('required_multiselect');
		} else {
			$('#cw_st_accountids').parent().find('.k-multiselect-wrap').removeClass('required_multiselect');
		}
		
		if (e.sender.value().indexOf(this.allAccountsId) !== -1) {
			let currentDatasource = e.sender.dataSource.data();
			let ids = [];
			for (var i = 0; i < currentDatasource.length; i++) {
				if (currentDatasource[i].id !== this.allAccountsId) {
					ids.push(currentDatasource[i].id);
				}
			}
			e.sender.value(ids);
			this.isAccountChangeTriggered = true;
			e.sender.trigger('change');
		} else {
			if (previous) {
				diff = $(previous).not(selectedAccounts).get();
			}
			this._savedOld = selectedAccounts.slice(0);

			if (diff.length > 0) {
				for (let k = 0; k < diff.length; k++) {
					selectedAccountId = diff[k];
					if (this.accountServices[selectedAccountId]) {
						selectedServices = this.servicesList.value();
						for (var i = 0, length = this.accountServices[selectedAccountId].length; i < length; i++) {
							id = this.accountServices[selectedAccountId][i].id;
							if (id) {
								item = this.servicesList.dataSource.get(id);
								if (item) {
									foundSelectedKey = selectedServices.indexOf(item.id);
									if (foundSelectedKey !== -1) {
										selectedServices.splice(foundSelectedKey, 1);
									}
									removeServices.push(item);
								}
							}
						}
						newServicesDS = $(this.servicesList.dataSource.data()).not(removeServices).get();
						if (!this.allServicesId) {
							this.allServicesId = Utils.guid();
							newServicesDS.unshift({
								id: this.allServicesId,
								name: '-All Services-'
							});
						}
						this.servicesList.setDataSource(newServicesDS);
						this.servicesList.value(selectedServices);
						$('#cw_st_serviceids').parent().find('.k-input').blur().blur();
						delete this.accountServices[selectedAccountId];
						this.setRequiredServices();
					}
				}
			} else {
				if (selectedAccounts && selectedAccounts.length) {
					if (this.isAccountChangeTriggered) {
						currentAccounts = selectedAccounts;
					} else {
						currentAccounts = [selectedAccounts[selectedAccounts.length - 1]];
					}
					for (var j = 0; j < currentAccounts.length; j++) {
						var scope = this;
						url = Settings.serverPath + 'accounts/' + currentAccounts[j] + '/services/lite?hasModel=true';
						Utils.ajax(url, 'GET', {}, function (result) {
							var length, oldValues;
							length = result.length;
							if (length) {
								currentAccount = this.url.split('accounts/')[1].split('/')[0];
								scope.accountServices[currentAccount] = result;
								oldValues = scope.servicesList.value();
								for (var i = 0; i < length; i++) {
									result[i].name = result[i].accountName + '/' + result[i].name;
								}
								if (!scope.allServicesId) {
									scope.allServicesId = Utils.guid();
									result.unshift({
										id: scope.allServicesId,
										name: '-All Services-'
									});
								}
								scope.servicesList.setDataSource(result);

								if (oldValues.length) {
									scope.servicesList.value(oldValues);
								}
							}
							LocalEventsManager.trigger('setServicesList');
						}, $.proxy(function () {
							LocalEventsManager.trigger('setServicesList');
						}, this));
					}
				}
			}
		}
	},
	setRequiredServices: function() {
		let dynamicServicesCheck = $('#cw_dynamic_services').is(':checked');
		if (!this.servicesList.value().length && !dynamicServicesCheck) {
			$('#cw_st_serviceids').parent().find('.k-multiselect-wrap').addClass('required_multiselect');
		} else {
			$('#cw_st_serviceids').parent().find('.k-multiselect-wrap').removeClass('required_multiselect');
		}
	},
	/*
	 * Handler function for setting the selective services components values
	 * @param {Object} widgetObj The widget configuration object
	 * */
	setComponents: function (widgetObj) {
		if (widgetObj.configuration.dynamicServices) {
			$('#cw_dynamic_services').prop('checked', true);
			$('#cw_all_services').prop('disabled', true);
			$('#cw_st_serviceids').data('kendoSortedMultiSelect').enable(false);
		} else {
			//not sure if we need this code anymore
			// var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/services/lite';
			// Utils.ajax(url, 'POST', JSON.stringify(widgetObj.configuration.servicesList), $.proxy(function (result) {
			// 	var length = result.length, accounts = {}, services = [], option;
			// 	if (result) {
			// 		for (var i = 0; i < length; i++) {
			// 			accounts[result[i].accountId] = result[i].accountId;
			// 			services.push(result[i].id);
			// 		}
			// 	}
			//
			// 	if (accounts) {
			// 		for (var i in accounts) {
			// 			option = $('span[data-id="' + accounts[i] + '"]');
			// 			if (option.length) {
			// 				option.trigger('click');
			// 			}
			// 		}
			// 	}
			// }, this));
		}
	},
	/*
	* Sets services list value
	* @param {Object} config
	* */
	setServicesList: function (config) {
		this.widgetObj.configuration.servicesList && this.widgetObj.configuration.servicesList.length && this.servicesList.value(this.widgetObj.configuration.servicesList);
		this.setRequiredServices();
	}
});
