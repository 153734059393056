import React, {useEffect} from "react";
import {Provider, useDispatch, useSelector} from "react-redux";
import getStore from "./store";
import FormSections from "./formSections";

import {getInitialValues} from './selectors/getInitialValues';

import PropTypes from 'prop-types';
import {setCreateModeAction} from "./actions/setCreateModeAction";
import {setCallbackAction} from "./actions/setCallbackAction";
import {setFieldsInErrorAction} from "./actions/setFieldsInErrorAction";

import {Map} from 'immutable';
import {setShowValidationAction} from "./actions/setShowValidation";
import {initWithDataAction} from "./actions/initWithDataAction";
import {isFormValid} from 'controls/react/form/formBuilder/selectors/isFormValid';
import {select} from 'redux-saga/effects';
import {getReduxFormValues} from 'controls/react/form/formBuilder/selectors/getReduxFormValues';
import {setDisabledAction} from 'controls/react/form/formBuilder/actions/setDisabledAction';

export default function FormBuilder(props) {
	const store = React.useMemo(() => {
		return getStore();
	}, []);

    return <Provider store={store} >
        <InnerFormBuilder {...props} />
    </Provider>;
}

function InnerFormBuilder(props) {
    const dispatch = useDispatch();
    const initialValues = useSelector(getInitialValues);
	const isValid = useSelector(isFormValid);
	const values = useSelector(getReduxFormValues)

	React.useEffect(() => {
		props.onValidChange(isValid);
	}, [props.onValidChange, isValid])

	React.useEffect(() => {
		props.onPlainChange?.(values);
	}, [props.onPlainChange, values])

    useEffect(() => {
        dispatch(setCallbackAction('onChange', props.onChange))
    }, [props.onChange]);
    
	useEffect(() => {
        try {
            const {id} = props.data;

            dispatch(initWithDataAction(id, props.data, props.values, props.persistedValues, props.timezone));
        } catch(e) {
            console.warn('Empty properties object')
        }
    }, [props.data]);

    useEffect(() => {
        dispatch(setCreateModeAction(props.createMode))
    }, [props.createMode]);

    useEffect(() => {
        dispatch(setCallbackAction('onData', props.onData))
    }, [props.onData]);

    useEffect(() => {
        dispatch(setCallbackAction('onPrevalidate', props.onPrevalidate))
    }, [props.onPrevalidate]);

  

    useEffect(() => {
        dispatch(setFieldsInErrorAction(props.fieldsInError))
    }, [props.fieldsInError]);

    useEffect(() => {
        dispatch(setShowValidationAction(props.showValidation))
    }, [props.showValidation]);

    React.useEffect(() => {
    	dispatch(setDisabledAction(props.disabled))
	}, [props.disabled])

    return <FormSections initialValues={initialValues} multipleLink={props.multipleLink} />;
}

FormBuilder.propTypes = InnerFormBuilder.propTypes = {
	onValidChange: PropTypes.func,
    data: PropTypes.object.isRequired,
    values: PropTypes.object,
    fieldsInError: PropTypes.instanceOf(Map),
    createMode: PropTypes.bool.isRequired,
    onData: PropTypes.func.isRequired,
    onPrevalidate: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    showValidation: PropTypes.bool.isRequired,
    multipleLink: PropTypes.element,
	disabled: PropTypes.bool
};

InnerFormBuilder.defaultProps = {
	onValidChange: () => {}
};
