import {delay, put, select} from 'redux-saga/effects';
import {registerField, change} from 'redux-form';
import {getItemValue} from '../selectors/getItemValue'
import {VIRTUAL_DEPENDENCIES, formBuilderForm} from '../constants';
import { checkFieldsStateAction } from '../actions/checkFieldsStateAction';

export function* addVirtualFields() {
    yield delay(200);

    const fields = yield select(getItemValue, 'fields');

    for (const [key, value] of fields.entries()) {
        if (VIRTUAL_DEPENDENCIES.has(key)) {
            const virtualFields = VIRTUAL_DEPENDENCIES.get(key);
            for (const virtualField of virtualFields) {
                yield put(registerField(formBuilderForm, `properties.${virtualField}`, 'Field'));
                yield put(change(formBuilderForm, `properties.${virtualField}`, value.get(virtualField).toJS()));
            }
        }
    }

    yield put(checkFieldsStateAction())
}
