import {Application, Cookies, LocalEventsManager, RemoteEventsManager} from "core";
import {CeeViewDataSource, Renderer, State, UserPrefs, Utils} from "tools";
import {
	CustomNotification, Dependencies,
	Dialog,
	EventsToolbar,
	GridMenu,
	ModalWindow,
	MultiSelectGridFilter,
	PreferencesPanel
} from "controls";
import {Settings} from "settings";
import {AssetGroupsListWindow} from "areas/assetgroups/assetGroupsListWindow";
import {AssetColumns} from "areas/assets/assetColumns";
import {AssetMergeForm} from "areas/administration/assetMergeForm";
import MaintenancePeriod from 'controls/maintenancePeriod';
import {IncidentsRouter} from "../incidents/bundleDescription";
import {openAssetConsoleWindow} from "./helpers";
import {openTagsFormWindow} from "controls/tagsForm";
import {NavigationAction} from 'appStore';
import {SetInformationWindow} from 'controls/setInformationWindow';
import React from "react";
import ReactDOM from "react-dom";
import {EventSummaryRouter} from "areas/views/bundleDescription"

let AssetHealthSummary = function (config) {
	Utils.apply(this, config);
	this.module = {
		name: 'AssetHealthSummary',
		initialConfig: config
	};
    this.hasEvents = true;
    this.subscriberId = Utils.guid();
	this.loadUserPreferences();
};
const i = require('core/localization').translator({
	'Dependency check':{
		no:'Avhengighet sjekk'
	}
});
export default AssetHealthSummary;

jQuery.extend(AssetHealthSummary.prototype, Application.prototype, {
	/**
	 * This is main init function
	 */
	initComponent: function () {
		Application.prototype.initComponent.call(this);

		this.indicatorSelector = '.asset_summary_view .cw_new_tag_indicator';

		if (!this.childMode) {

		}
		this.actionNotification = new CustomNotification({
			appendToElement: '.cw_health_summary_list',
		});
		this.showGridView = true;
		this.id = Utils.guid();
		State.markedCheckboxes = [];
		State.gridPosLoaded = false;
		this.initKendoComponents();
	},
	/**
	 * Account application related initializations can be done here
	 */
	initKendoComponents: function () {
		this.firstRender = true;
		this.checkboxStates = [];
		this.defaultColumns = {
			id: {
				hidden: false,
				width: 40
			},
			assetIndicator: {
				hidden: false,
				width: 100
			},
			name: {
				hidden: false,
				width: 240
			},
			accountName: {
				hidden: !State.includeSubaccounts,
				width: 150
			},
			targets: {
				hidden: false,
				width: 240
			},
			numberOfIncidents: {
				hidden: false,
				width: 90
			},
			reasonsPercent: {
				hidden: false,
				width: 100
			},
			serviceQualifierCount: {
                hidden: false,
                width: 150
            },
            monitorMetricCount: {
                hidden: false,
                width: 150
            },
            externalMetricCount: {
                hidden: false,
                width: 150
            },
			acknowledged: {
				hidden: false,
				width: 220
			},
			acknowledgedBy: {
				hidden: false,
				width: 150
			},
			acknowledgeDate: {
				hidden: false,
				width: 150
			},
			systemType: {
				hidden: false,
				width: 120
			},
			assetType: {
				hidden: false,
				width: 120
			},
			description: {
				hidden: false,
				width: 120
			}
		};
		this.gridMessages = {
			clear: lang.CLEAR,
			info: lang.grid.filter.SHOW_ITEMS,
			filter: lang.FILTER
		};
		var filterMessages = lang.grid.filter;
		this.removeListeners();
		this.attachListeners();

		if (!this.isFromAssetGroup) {
			State.mainApp.contextMenu.setActionButton('ASSET');
		}

		/*if (!this.isView) {
			$('#nav li').removeClass('current').parent().find('#asset_health').addClass('current');
		}*/
		var ahSummarySort, ahSummaryFilter;
		var defaultFsView = UserPrefs.get('defaultFsView');
		if (Utils.isGuid(defaultFsView)) {
			var pref = JSON.parse(UserPrefs.get(defaultFsView));
			pref = pref || {
				sort: [],
				filter: []
			};
			ahSummarySort = pref.sort;
			ahSummaryFilter = pref.filter;
			this.ahSummaryColumns = pref.columns;
		} else {
			if (this.isFromAssetGroup) {
				if (UserPrefs.get('ahGroupSummarySort') && UserPrefs.get('ahGroupSummarySort') !== '[]') {
					ahSummarySort = JSON.parse(UserPrefs.get('ahGroupSummarySort'));
				}
			} else {
				if (UserPrefs.get('ahSummarySort') && UserPrefs.get('ahSummarySort') !== '[]') {
					ahSummarySort = JSON.parse(UserPrefs.get('ahSummarySort'));
				}
			}
			if (this.isFromAssetGroup) {
				if (UserPrefs.get('ahGroupSummaryFilter')) {
					ahSummaryFilter = JSON.parse(UserPrefs.get('ahGroupSummaryFilter'));
				}
			} else {
				if (UserPrefs.get('ahSummaryFilter')) {
					ahSummaryFilter = JSON.parse(UserPrefs.get('ahSummaryFilter'));
				}
			}
		}

		//if no sorting set default grid sorting
		if (!ahSummarySort) {
			ahSummarySort = [{
					field: 'assetIndicator',
					dir: 'asc',
					compare: null
				}];
		}

		var stateFilters;
		this.preferences = {
			sort: [{
					field: 'assetIndicator',
					dir: 'asc'
				}],
			filter: []
		};
		switch (this.source) {
            case 'buckets':
            case 'buckets_footer':
            	const accountId = this.accountList ? this.accountList[0] : Cookies.CeesoftCurrentAccountId;
                this.mainUrl = Settings.serverPath + 'accounts/' + accountId + '/health/assets';
                this.parametersUrl = '?includeSubaccounts=' + State.includeSubaccounts + '&_dc=' + Utils.timestamp();
                //url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/health/assets?includeSubaccounts=' + State.includeSubaccounts + '&_dc=' + timestamp();
                stateFilters = this.convertStateToFilter(this.healthIndexRangeType, this.healthIndexMandatoryType);
                break;
            case 'account_assets':
                this.mainUrl = Settings.serverPath + 'accounts/' + this.accountList[0] + '/health/assets';
                this.parametersUrl = '?_dc=' + Utils.timestamp();
                stateFilters = this.convertStateToFilter(this.healthIndexRangeType);
                //this.assetSummaryUrl = this.mainUrl + this.parametersUrl;
                //url = Settings.serverPath + 'accounts/' + this.accountList[0] + '/health/assets?_dc=' + timestamp();
                break;
            case 'serviceDetailsParts':
                this.mainUrl = Settings.serverPath + 'accounts/' + this.accountList[0] + '/services/' + this.serviceId + '/summary/assets';
                this.parametersUrl = '?includeSubaccounts=' + State.includeSubaccounts + '&_dc=' + Utils.timestamp();
                //url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/services/' + this.serviceId + '/summary/assets?includeSubaccounts=' + State.includeSubaccounts + '&_dc=' + timestamp();
                stateFilters = this.convertStateToFilter(this.healthIndexRangeType);
                break;
			case 'account_services':
			    this.mainUrl = Settings.serverPath + 'accounts/' + this.accountList[0] + '/health/services/assets';
			    this.parametersUrl = '?_dc=' + Utils.timestamp();

				stateFilters = this.convertStateToFilter(this.healthIndexRangeType);

				//url = Settings.serverPath + 'accounts/' + this.accountList[0] + '/health/services/assets?_dc=' + timestamp();
				break;
			case 'service_assets':
			    this.mainUrl = Settings.serverPath + 'accounts/' + this.accountList[0] + '/health/services/' + this.serviceId + '/assets';
			    this.parametersUrl = '?_dc=' + Utils.timestamp();

				stateFilters = this.convertStateToFilter(this.healthIndexRangeType);

				//url = Settings.serverPath + 'accounts/' + this.accountList[0] + '/health/services/' + this.serviceId + '/assets?_dc=' + timestamp();
				break;
			case 'application':
				this.mainUrl = Settings.serverPath + 'applications/' + this.applicationId + '/assets';
				this.parametersUrl = '?includeSubaccounts=' + State.includeSubaccounts + '&_dc=' + Utils.timestamp();
				stateFilters = this.convertStateToFilter(this.healthIndexRangeType);
				break
			default:
				this.preferences = null;
				this.mainUrl = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/health/assets';
				this.parametersUrl = '?includeSubaccounts=' + State.includeSubaccounts + '&_dc=' + Utils.timestamp();
				//url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/health/assets?includeSubaccounts=' + State.includeSubaccounts + '&_dc=' + timestamp();
				break;
		};
		if (this.isFromAssetGroup) {
			this.mainUrl = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/health/assetGroups/' + this.selectedAssetGroupId + '/assets';
			this.parametersUrl = '?_dc=' + Utils.timestamp();
		}
		this.assetSummaryUrl = this.mainUrl + this.parametersUrl;
		if (this.preferences) {
			ahSummarySort = this.preferences.sort || ahSummarySort;
			ahSummaryFilter = this.preferences.filter || ahSummaryFilter;
		}
		var dir;
		//this will fix the column sorting on page reload
		for (var i = 0, length = ahSummarySort.length; i < length; i++) {
			if (ahSummarySort[i].field === 'assetIndicator') {
				dir = ahSummarySort[i].dir;
				ahSummarySort[i].compare = function (a, b) {
					return Utils.customCompare(a, b, 'assetIndicatorSorter', '-1', dir);
				};
			}
		}
		ahSummaryFilter = UserPrefs.restoreDateFilter(ahSummaryFilter);

		/*if (this.source === 'buckets') {
			ahSummaryFilter = {};
		}*/
		var ahSC;
		if (this.isFromAssetGroup) {
			ahSC = JSON.parse(UserPrefs.get('ahGroupSummaryColumns'));
		} else {
			ahSC = JSON.parse(UserPrefs.get('ahSummaryColumns'));
		}

        if (ahSC !== null) {
            if (typeof ahSC === "string" || (typeof ahSC === "object" && ahSC.length === 0)) {
                ahSC = null;
            }
        }
        var ahSummaryColumns = this.ahSummaryColumns || ahSC || this.defaultColumns;
		ahSummaryColumns = Utils.completeColumns(ahSummaryColumns, this.defaultColumns);
        this.currentPrefColumns = ahSummaryColumns;

        if (stateFilters) {
            ahSummaryFilter.push(stateFilters);
        }

        this.assetsDataSource = new CeeViewDataSource({
            transport: {
                read: {
                    url: $.proxy(function () {
                        return this.assetSummaryUrl;
                    }, this),
                    contentType: "application/json; charset=utf-8",
                    type: 'POST',
                    dataType: 'json',
                    cache: false
                },
                parameterMap: $.proxy(function (data, type) {
					if (data.filter?.filters) {
						data.filter.filters = Utils.changeDateFilterToString(data.filter.filters);
					}
                	this.gridPayload = data;
					this.gridPayload.tags = this.filteringTags || [];
					this.gridPayload.showUntagged = this.showUntagged || false;
					this.gridPayload.assetGroupIds = this.assetGroupIds || [];

					if (this.ignoreUserTags) {
						this.gridPayload.ignoreUserTags = true;
					}
                    return kendo.stringify(data);
                }, this)
            },
            pageSize: 100,
            schema: {
                model: {
                    id: 'id',
                    fields: {
                        numberOfIncidents: {
                            type: 'number'
                        },
                        serviceQualifierCount: {
                            type: 'number'
                        },
                        monitorMetricCount: {
                            type: 'number'
                        },
                        externalMetricCount: {
                            type: 'number'
                        },
                        acknowledged: {
                            type: 'boolean'
                        },
                        reasonsPercent: {
                            type: 'number'
                        },
	                    modified: {
                        	type: 'date'
	                    },
	                    acknowledgeDate: {
                        	type: 'date'
	                    }
                    }
                },
	            parse: $.proxy(function(result) {
		            this.filterOptions = result.filterOptions;
		            return result;
	            }, this),
                data: function (result) {
                    var data = result.items;
                    for (var i = 0; i < data.length; i++) {
                        if (data[i].acknowledgedBy) {
                            data[i].acknowledgedMouseover = lang.ACKNOWLEDGED_BY + ' ' + data[i].acknowledgedBy;
                        } else {
                            data[i].acknowledgedMouseover = '';
                        }
	                    data[i].acknowledgeDate = new Date(data[i].acknowledgeTime);
	                    data[i].modified = new Date(data[i].modified);
                    }
                    return AssetColumns.parseData(data);
                },
                total: function (response) {
                    this.visibleItems = response.visible;
                    this.totalItems = response.total;
                    return response.visible;
                }
            },
            serverSorting: true,
            serverPaging: true,
            serverFiltering: true,
            filter: ahSummaryFilter,
            sort: ahSummarySort,
            change: $.proxy(function (e) {
	            if ($('.cw_health_summary_list').find('.status').length) {
		            $('.cw_health_summary_list').find('.status').remove();
	            }

                if (e.items && e.items.length) {
                    $('.cw_health_summary_list').find('.k-grid-content').removeClass('hide');
                }

				if (JSON.stringify(this.currentGridFilter) !== JSON.stringify(this.assetsDataSource.filter())) {
					this.filteringChanged = true;
				} else {
	            	this.filteringChanged = false;
				}
				let restoreCheckboxes = true;
				if (this.filteringChanged && this.selectedIds) {
					this.selectedIds = null;
					State.markedCheckboxes = [];
					$('.cw_grid_check_all').prop('checked', false);
					$('.cw_grid_check').prop('checked', false);
					this.onAssetCheck();
					restoreCheckboxes = false;
				}
				this.currentGridFilter = this.assetsDataSource.filter();

				if (restoreCheckboxes) {
					var restoreCheckboxStates = () => {
						if (this.grid) {
							Utils.setCheckboxStates(this.grid, this.checkboxStates);
						}
					};
					setTimeout($.proxy(restoreCheckboxStates, this), 200);
				}
            }, this)
        });

        let assetColumns = AssetColumns.get(this, ahSummaryColumns, this.assetsDataSource, $('.cw_health_summary_list'));

		var gridHeight = this.isFromAssetGroup ? this.treeListHeight - 40 : '';
        this.grid = $('.cw_health_summary_list').kendoCustomGrid({
            autoBind: true,
            dataSource: this.assetsDataSource,
			height: gridHeight,
            resizable: true,
            selectable: 'row',
            cacheScrollCheckboxes: true,
            scrollable: {
                virtual: true
            },
            reorderable: true,
            sortable: {
                mode: 'multiple',
                allowUnsort: true
            },
            filterable: {
                extra: false,
                operators: {
                    string: {
                        startswith: filterMessages.STARTS_WITH,
                        neq: filterMessages.NEQ,
                        eq: filterMessages.EQ,
                        contains: filterMessages.CONTAINS
                    },
                    number: {
                        eq: filterMessages.EQ,
                        neq: filterMessages.NEQ,
                        gte: filterMessages.GTE,
                        gt: filterMessages.GT,
                        lte: filterMessages.LTE,
                        lt: filterMessages.LT
                    },
                    date: {
	                    eq: filterMessages.EQ,
	                    gte: filterMessages.IAE,
	                    gt: filterMessages.IA,
	                    lte: filterMessages.IBE,
	                    lt: filterMessages.IB
                    }
                }
                //messages: this.gridMessages
            },
            //columns: Utils.rearrangeColumns(AssetColumns.get(this, ahSummaryColumns, this.assetsDataSource, $('.cw_health_summary_list')), ahSummaryColumns),
            columns: assetColumns,
            columnMenu: true,
            change: $.proxy(this.onRowExpand, this),
			dataBound: $.proxy(this.onSummaryDataBound, this)
        }).data('kendoCustomGrid')

        $('.k-virtual-scrollable-wrap').scroll(function() {
        	let grid = $('.cw_health_summary_list').data('kendoCustomGrid');
            State.dynamicGridPos = grid?.wrapper.find(".k-scrollbar").scrollTop();
        });

        // Add Kendo tooltip to the header of the columns
        Utils.gridColumnHeaderTooltip(this.grid);
        if (!this.isFromAssetGroup) {
			this.adjustSectionHeight();
		}
        if (this.grid) {
			this.grid.thead.find("[data-field='id']>.k-header-column-menu").remove();
			this.grid.thead.find("[data-field='description']>.k-header-column-menu").remove();
		}

        this.gridMenu = new GridMenu({
            renderTo: this.isFromAssetGroup ? 'cw_asset_grid_menu' : 'cw_ah_grid_menu',
            items: [{
                id: 'cw_summary_create',
                icon: 'plus-sign',
                text: lang.CREATE,
				fn: this.onNewAsset,
                scope: this,
                role: 'ASSET_CREATE'
            }, {
                id: 'cw_summary_delete',
                icon: 'bin',
                text: lang.DELETE,
                fn: this.onAssetDelete,
                scope: this,
                disabled: true,
                role: 'ASSET_DELETE'
            }, {
                id: 'cw_update_assetgroup',
                icon: 'plus-sign',
                text: lang.assethealth.UPDATE_ASSET_GROUP,
                fn: this.onUpdateAssetGroup,
                scope: this,
                role: 'ASSET_UPDATE',
                disabled: true
            }, {
                id: 'cw_incidents_mark_seen',
                icon: 'ok',
                text: lang.incidents.MARK_AS_SEEN,
                fn: this.onMarkAsSeen,
                scope: this,
                disabled: true
            }, {
                id: 'cw_incidents_create',
                icon: 'construction-cone',
                text: lang.incidents.CREATE_INCIDENT,
                fn: this.onCreateIncident,
                scope: this,
                role: 'INCIDENT_CREATE',
                disabled: true
            }, {
                id: 'cw_merge_assets',
                icon: 'git-merge',
                text: lang.MERGE_ASSETS,
                fn: this.onMergeAssets,
                scope: this,
                disabled: true
            }, {
                id: 'cw_set_tags',
                icon: 'tag',
                text: lang.SET_TAGS,
                fn: $.proxy(function () {
                    return openTagsFormWindow('asset', this);
                }, this),
                scope: this,
                disabled: true
            }, {
                id: 'cw_end_maintenance',
                icon: 'bin',
                text: lang.END_MAINTENANCE,
                fn: this.onEndMaintenance,
                scope: this,
                disabled: true,
                role: 'ASSET_MAINTENANCE_UPDATE'
            }, {
                id: 'cw_set_in_maintenance',
                icon: 'wrench',
                text: lang.SET_IN_MAINTENANCE,
                fn: this.onSetInMaintenance,
                scope: this,
                disabled: true,
                role: 'ASSET_MAINTENANCE_CREATE'
            }, {
                id: 'cw_event_summary_export_csv',
                icon: 'file-export',
                text: lang.EXPORT_CSV,
                fn: this.onExportCsv,
                scope: this,
                disabled: false
            }, {
				id: 'cw_set_information',
				icon: 'info-sign',
				text: lang.SET_INFORMATION,
				fn: this.onSetInformation,
				scope: this,
				disabled: true
			}]
        });
        var searchValue;
        if (this.isFromAssetGroup) {
			searchValue = UserPrefs.get('defaultGroupSummaryFsView') ? '' : (UserPrefs.get('ahGroupSummarySearchPhrase') || '');
		} else {
			searchValue = UserPrefs.get('defaultSummaryFsView') ? '' : (UserPrefs.get('ahSummarySearchPhrase') || '');
		}
        this.gridFilterPanel = new PreferencesPanel({
            renderTo: 'cw_asset_summary_filters',
            grid: this.grid,
            modulePrefName: this.isFromAssetGroup ? 'GroupAssetHealth' : 'AssetHealth',
            defaultPrefViewKey: this.isFromAssetGroup ? 'defaultGroupSummaryFsView' : 'defaultSummaryFsView',
            prefListKey: this.isFromAssetGroup ? 'ahGroupSummaryFSViews' : 'ahSummaryFSViews',
            userPref: State.currentApp.userPref,
            onRemove: $.proxy(State.currentApp.saveUserPreferences, State.currentApp),
            searchFields: ['name', 'description', 'acknowledgedBy', 'assetType', 'systemType', 'targets', 'information'],
            searchValue: searchValue,
            defaultColumns: this.grid?.columns,
			dontLoadDefault: this.source === 'buckets' || this.preferences
		});

        if (this.isFromAssetGroup) {
        	this.hideViewMgmt();
		}
        //this.grid.dataSource.data().trigger('change');
        if (this.source === 'buckets') {
            this.gridFilterPanel.reset();
        }
        var eventStatus = JSON.parse(UserPrefs.get('eventsStatus')) || {
                startFrom: 0,
                playing: true
            };
        this.eventsToolbar = new EventsToolbar({
            renderTo: $('#cw_assets_events_toolbar'),
            callBackFn: $.proxy(this.onEventsTimeout, this),
	        subscriberId: this.subscriberId,
            startFrom: eventStatus.startFrom,
            playing: eventStatus.playing
        });
        this.removeMask();
	},
	hideViewMgmt() {
		$('.cw_view_button').addClass('hide');
		$('.cw_filter_reset').addClass('hide');
		$('.cw_filter_list').addClass('hide');
	},
    /**
     * Handler function for converting the state from bucket to filter
     * @param {String} state The current state
     */
    convertStateToFilter: function (state, mandatoryState) {
        var filterValue, filterObj = {};
        var mandatoryFields;

        if( state == null ){
        	state = []
        } else if( typeof state  === 'string'){
        	state = [state];
        }

        if (state && state.length) {
        	if (mandatoryState && mandatoryState.length) {
        		mandatoryFields = true;
        		filterObj = {
        			filters: [{
        				filters: [],
						logic: 'or'
					}],
					logic: 'and'
				}
			} else {
        		mandatoryFields = false;
				filterObj = {
					filters: [],
					logic: 'or'
				};
			}
        	for (var i = 0; i < state.length; i++) {
				switch (state[i]) {
					case 'CRITICAL':
						filterValue = 3;
						break;
					case 'MAJOR':
						filterValue = 2;
						break;
					case 'MINOR':
						filterValue = 1;
						break;
					case 'OK':
						filterValue = 0;
						break;
					case 'DOWN':
						filterValue = -1;
						break;
					case 'WARNING':
						filterValue = -2;
						break;
					case 'MAINTENANCE':
						filterValue = -3;
						break;
					case 'NONE':
						filterValue = 'none';
						break;
				}
				if (mandatoryFields) {
					filterObj.filters[0].filters.push({
						field: 'assetIndicator',
						operator: 'eq',
						value: filterValue
					});
				} else {
					if (filterValue === 'none') {
						filterObj.filters = [{
							field: 'assetIndicator',
							operator: 'neq',
							value: 3
						}, {
							field: 'assetIndicator',
							operator: 'neq',
							value: 2
						}, {
							field: 'assetIndicator',
							operator: 'neq',
							value: 1
						}, {
							field: 'assetIndicator',
							operator: 'neq',
							value: 0
						}];
						filterObj.logic = 'and';
					} else {
						filterObj.filters.push({
							field: 'assetIndicator',
							operator: 'eq',
							value: filterValue
						});
					}
				}
			}
			if (mandatoryFields) {
        		if (state.length) {
					filterObj.filters.push({
						filters: [],
						logic: 'or'
					})
				}
				for (var j = 0; j < mandatoryState.length; j++) {
					switch (mandatoryState[j]) {
						case 'DOWN':
							filterValue = -1;
							break;
						case 'WARNING':
							filterValue = -2;
							break;
						case 'IDLE':
							filterValue = 6;
							break;
					}
					if (state.length) {
						filterObj.filters[1].filters.push({
							field: 'assetIndicator',
							operator: 'eq',
							value: filterValue
						});
					} else {
						filterObj.filters[0].filters.push({
							field: 'assetIndicator',
							operator: 'eq',
							value: filterValue
						});
					}

				}
			}
        } else {
            filterObj = {
                filters: [],
                logic: 'or'
            };
        }
        if (this.addMonitorErrorFilter) {
        	if (mandatoryFields) {
        		filterObj.filters[1].filters.push({
					field: 'assetIndicator',
					operator: 'eq',
					value: '-4'
				});
			} else {
        		filterObj.filters.push({
					field: 'assetIndicator',
					operator: 'eq',
					value: '-4'
				});
			}
		}
		// if (this.addDataUnavailableFilter) {
		// 	if (mandatoryFields) {
		// 		filterObj.filters[1].filters.push({
		// 			field: 'monitorDataUnavailable',
		// 			operator: 'eq',
		// 			value: true
		// 		});
		// 	} else {
		// 		filterObj.filters.push({
		// 			field: 'monitorDataUnavailable',
		// 			operator: 'eq',
		// 			value: true
		// 		});
		// 	}
		// }
        return filterObj;
    },
	getFilterableForMonitor: function (element) {
		setTimeout($.proxy(function() {
            var currentField = this.scope.currentFilteredMonitor;
            var multiselect = new MultiSelectGridFilter({
                element: element,
                field: currentField,
                grid: this.gridSelector.data('kendoCustomGrid'),
                dataSource: [{
                    text: lang.CRITICAL,
                    color: 1,
                    value: '3'
                }, {
                    text: lang.MAJOR,
                    color: 2,
                    value: '2'
                }, {
                    text: lang.MINOR,
                    color: 3,
                    value: '1'
                }, {
                    text: lang.OK,
                    color: 5,
                    value: '0'
                }, {
                    text: lang.UNAVAILABLE,
                    value: '6',
                    color: 6
                }],
                itemTemplate: '<span class="cw_status_indicator cw_status_widget_color cw_color#=data.color#"></span><span>${data.text}</span>',
                tagTemplate: '<span class="cw_status_indicator cw_status_widget_color cw_color#=data.color#"></span><span>${data.text}</span>'
            });
		}, this), 0);
	},
	/**
	 * Removes listeners
	 */
	removeListeners: function () {
		$('.cw_health_summary_list').off();
		$('#cw_health_summary_find').off();
		this.onAssetSavedHandler = $.proxy(this.onAssetSaved, this);
		LocalEventsManager.unbind('assetsaved', this.onAssetSavedHandler);
		LocalEventsManager.unbind('assetsAddedToGroup', this.onAssetsAdded);
        $('body').off('click', '.cw_monitorname');
        $('body').off('click', '.cw_agentname');
        LocalEventsManager.unbind('maintenance_period_saved');
	},
	/**
	 * Attaches listeners
	 */
	attachListeners: function () {
		let grid = $('.cw_health_summary_list');

		grid.on('click', '.cw_grid_check', $.proxy(this.onAssetCheck, this));
		grid.on('click', '.cw_grid_check_all', $.proxy(this.onAssetCheckAll, this));
		grid.on('click', '.cw_assetconsole_details', $.proxy(this.onOpenAssetConsole, this));
		grid.on('click', '.k-grid-content .cw_indicator', $.proxy(this.onAgentStateClick, this));
		grid.on('click', '.cw_pillbox', $.proxy(this.onAssetStateClick, this));
		$('#cw_health_summary_find').on('keyup', $.proxy(this.onSearch, this));
		LocalEventsManager.bind('assetsaved', this.onAssetSavedHandler);
		LocalEventsManager.bind('assetsAddedToGroup', $.proxy(this.onAssetsAdded, this));
        $('body').on('click', '.cw_monitorname', $.proxy(this.onMonitorNameClick, this));
        $('body').on('click', '.cw_agentname', $.proxy(this.onAgentNameClick, this));
        LocalEventsManager.bind('maintenance_period_saved', $.proxy(this.onMaintenanceSaved, this));
	},
    /**
     * Handler for the click event on the monitor name
     * @param {Object} e The click event
     */
    onMonitorNameClick: function (e) {
        e.preventDefault();
        var target = $(e.currentTarget);
        var monitorId = target.data('id');
        var monitorType = target.data('type');
        this.openMonitorView(monitorId, monitorType, true, e);
    },
	/*
	 * Handler function for clicking agent state click
	 */
	onAgentStateClick: function (e) {
		this.setHighlightItem(e);
		State.mainApp.loadModule('ManagementAgents', null, {}, e);
	},
	onAssetStateClick: function (e) {
		const target = $(e.currentTarget);
		const uid = target.closest('tr').data('uid');
		const record = this.grid.dataSource.getByUid(uid);
		State.mainApp.navigate(EventSummaryRouter.list({additionalQuery: {assetIds: record.id}}))
	},
	/**
	 * Handler function for open asset console button
	 * @param {Object} e The click event object
	 */
	onOpenAssetConsole: function (e) {
		e.stopPropagation();
		var target = $(e.currentTarget), row = target.closest('tr'),
				item = $('.cw_health_summary_list').data('kendoCustomGrid').dataItem(row),
				assetId = item.id, assetName = item.name, assetDescription = item.description || '',
				accountId = item.accId;
		var monitorType = target.parent().attr('data-type');
		for (var i = 0; i < item.monitors.length; i++) {
			if (item.monitors[i].type === monitorType) {
				var monitorId = item.monitors[i].id;
			}
		}
		switch (monitorType) {
			case 'SYSTEM':
				this.assetConsoleWindow = openAssetConsoleWindow({
					type: monitorType,
					title: lang.widget.SYSTEM_MONITOR_CONSOLE,
					assetId: assetId,
					monitorId: monitorId,
					accountId: accountId
				});
				break;
			case 'IBMSVC':
				this.assetConsoleWindow = openAssetConsoleWindow({
					type: monitorType,
					title: lang.widget.ibm.TITLE,
					width: 800,
					height: 626,
					assetId: assetId,
					monitorId: monitorId,
					assetName: assetName,
					assetDescription: assetDescription,
					accountId: accountId
				});
				break;
		}
	},
	/**
	 * Handler function for create incident button
	 */
	onCreateIncident: function (e) {
		var assetsCheckboxes = this.grid.wrapper.find('.cw_grid_check');
		var assets = [], accountList = [];

		if (this.selectedItems) {
			for (let i = 0; i < this.selectedItems.length; i++) {
				assets.push({
					id: this.selectedItems[i].id,
					name: this.selectedItems[i].name
				});
				if (!Utils.getFromListById(accountList, this.selectedItems[i].accountId)) {
					accountList.push({
						id: this.selectedItems[i].accountId,
						name: this.selectedItems[i].accountName
					});
				}
			}
		} else {
			for (var i = 0, length = assetsCheckboxes.length; i < length; i++) {
				if ($(assetsCheckboxes[i]).is(':checked')) {
					var record = this.assetsDataSource.get($(assetsCheckboxes[i]).data('id'));
					assets.push({
						id: record.id,
						name: record.name
					});
					if (!Utils.getFromListById(accountList, record.accId)) {
						accountList.push({
							id: record.accId,
							name: record.accountName
						});
					}
				}
			}
		}

		if (!Utils.getFromListById(accountList, Cookies.CeesoftCurrentAccountId)) {
			accountList.push({
				id: Cookies.CeesoftCurrentAccountId,
				name: Cookies.CeesoftCurrentAccountName
			});
		}
		if (assets.length) {
			State.mainApp.navigate(IncidentsRouter.createNew('ASSET'), {
				extra: {
					assets: assets,
					accountList: accountList,
					isFromAssetHealthSummary: true
				}
			});
		} else {
			var status = $('#cw_service_health_tabs').find('.status');
			status.find('p').addClass('error');
			status.find('p').text(lang.assethealth.messages.PLEASE_SELECT_ASSET);
			status.slideDown().delay(2000).slideUp();
		}
	},
	/**
	 * Handler function for the Mark as seen button
	 */
	onMarkAsSeen: function () {
		var assetsCheckboxes = this.grid.wrapper.find('.cw_grid_check');
		var assets = [];
		for (var i = 0, length = assetsCheckboxes.length; i < length; i++) {
			if ($(assetsCheckboxes[i]).is(':checked')) {
				assets.push($(assetsCheckboxes[i]).data('id'));
			}
		}
		if (assets.length) {
			var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/health/acknowledgeAssetHealth';
			Utils.ajax(url, 'POST', JSON.stringify(this.selectedIds || assets), $.proxy(function (result) {
				if (result.success) {
                    this.assetsDataSource.read();
				} else {
					Utils.showInfo(lang.ALERT, result.message, result.details);
				}
			}, this));
		} else {
			var status = $('#cw_service_health_tabs').find('.status');
			status.find('p').addClass('error');
			status.find('p').text(lang.assethealth.messages.PLEASE_SELECT_ASSET);
			status.slideDown().delay(2000).slideUp();
		}
	},
	/**
	 * Handler function for the click event on the Update asset group menu item. Shows the list with available assets groups.
	 */
	onUpdateAssetGroup: function () {
		var checkboxes = this.grid.wrapper.find('.cw_grid_check:checked');
		var assets = [];
		var accountId;

		if (this.selectedItems) {
			for (let i = 0; i < this.selectedItems.length; i++) {
				accountId = this.selectedItems[i].accountId;
				assets.push(this.selectedItems[i].id);
			}
		} else {
			for (var i = 0, length = checkboxes.length; i < length; i++) {
				var assetId = $(checkboxes[i]).attr('data-id');
				var record = this.grid.dataSource.get(assetId);
				accountId = record.accId;
				assets.push(assetId);
			}
		}

		this.selectAssetGroupWindow = new ModalWindow({
			id: 'select_assets_window',
			title: lang.account.ASSET_GROUPS,
			width: 400,
			resizable: false,
			minHeight: 200,
			height: 540,
			modal: true,
			actions: [],
			url: 'include/AssetHealth/AssetGroupsListWindow.jsp',
			refresh: $.proxy(function () {
				var assetGroupWin = new AssetGroupsListWindow({
					accountId: accountId,
					assets: assets
				});
			}, this)
		});
		this.selectAssetGroupWindow.open();
	},
	/**
	 * Handler function for the click event on the merge assets menu item
	 */
	onMergeAssets: function (e) {
		e.stopPropagation();
		var assetsList = [];
		var selectedAssets = this.grid.wrapper.find('.cw_grid_check:checked').closest('tr');
		var dataSource = this.grid.dataSource;

		if (this.selectedItems) {
			for (let i = 0; i < this.selectedItems.length; i++) {
				if (this.selectedItems[i].assetType !== 'AGENT') {
					assetsList.push({
						id: this.selectedItems[i].id,
						name: this.selectedItems[i].name,
						assetType: this.selectedItems[i].assetType
					});
				}
			}
		} else {
			if (selectedAssets.length) {
				for (var i = 0; i < selectedAssets.length; i++) {
					var uid = $(selectedAssets[i]).attr('data-uid');
					var asset = dataSource.getByUid(uid);
					if (asset && asset.assetType !== 'AGENT') {
						assetsList.push({
							id: asset.id,
							name: asset.name,
							assetType: asset.assetType
						});
					}
				}
			}
		}
		if (State.mainApp.session.hasRole('ASSET_CREATE')) {
			var modalWindow = new ModalWindow({
				title: lang.MERGE_ASSETS,
				width: 750,
				minHeight: 500,
				height: 500,
				url: 'include/Administration/AssetMergeForm.jsp',
				refresh: $.proxy(function () {
					new AssetMergeForm({
						id: 'merge_assets',
						mode: 'create',
						assetsList: assetsList
					});
				}, this),
				close: $.proxy(function () {
					$('#cw_health_summary_list').data('kendoCustomGrid').refresh();
				}, this)
			});
			modalWindow.open();
		} else {
			Utils.showInfo(lang.INFO, lang.messages.NO_RIGHTS);
		}
	},
    /**
     * Handler function for set in maintenance button
     */
    onSetInMaintenance: function () {
        var checkboxes = this.grid.element.find('.cw_grid_check:checked');
        var assets = [];
        for (var i = 0, length = checkboxes.length; i < length; i++) {
            assets.push($(checkboxes[i]).attr('data-id'));
        }
        new MaintenancePeriod({
            layout: 'window',
            mode: 'create',
            type: 'asset',
            entities: this.selectedIds || assets,
            //entityId: this.serviceId,
            accountId: this.accountId
        });
    },
    /**
     * Handler function for clicking export to csv option in grid menu
     */
    onExportCsv: function () {
        var url = this.mainUrl + '/exportCSV' + this.parametersUrl;
        Utils.exportGridCsv(this.grid, url);
    },
    /**
     * Handler function for end maintenance button
     */
    onEndMaintenance: function () {
        var checkboxes = this.grid.element.find('.cw_grid_check:checked');
        var assets = [];
        for (var i = 0, length = checkboxes.length; i < length; i++) {
            assets.push($(checkboxes[i]).attr('data-id'));
        }
        var url = Settings.serverPath + 'maintenance/endMultipleAssets';
        Utils.ajax(url, 'POST', JSON.stringify(this.selectedIds || assets), $.proxy(function (result) {
            if (result.success) {
                this.clearCheckboxes();
                this.actionNotification.setOptions({
                    message: lang.management.messages.END_MAINTENANCE_SUCCEEDED,
                    status: 'success'
                }).show();
                this.grid.dataSource.read();
            } else {
                this.actionNotification.setOptions({
                    message: result.message,
                    status: 'error'
                }).show();
            }
        }, this));
    },
	/**
	 * Handler function for the change(select) event on the grid
	 * @param {Object} e The change event object
	 */
	onRowExpand: function (e) {
		var selectedRow = this.grid.select();
		var myRow = selectedRow[0];
		var messageEl = $(myRow).find('.to_expand');
		if ($(messageEl).hasClass('cw_message_expanded')) {
			$(messageEl).removeClass('cw_message_expanded').addClass('ellipsis');
		} else {
			$('.cw_health_summary_list').find('.k-grid-content').find('td.cw_message_expanded').removeClass('cw_message_expanded').addClass('ellipsis');
			$(messageEl).addClass('cw_message_expanded').removeClass('ellipsis');
		}
	},

	onSummaryDataBound: function () {
		if (this.grid) {
		    this.highlightItem(this.grid);
		}

		if (!(State.mainApp.session.hasRole('ASSET_READ') && State.mainApp.session.hasRole('ASSET_LIST'))){
			$('.cw_grid_link').attr('style','cursor: default !important');
			$('.cw_grid_link').css('color', 'black');
		}
		var columnHeaderFilterables = $('.k-grid-header').find('.k-header-column-menu');
        columnHeaderFilterables.on('click', $.proxy(function(e) {
            var target = $(e.target);
            var currentColumnHeader = target.closest('th');
            var currentColumnName = currentColumnHeader.attr('data-field');
            this.currentFilteredMonitor = currentColumnName;
        }, this));

        if (!this.tooltipHealthSummary) {
			this.tooltipHealthSummary = $('.cw_health_summary_list').kendoTooltip({
				filter: '.asset_indicator .exclamation-mark, .asset_indicator .wrench',
				autoHide: true,
				position: 'bottom',
				show: function (e) {
					e.sender.popup.element.addClass('warning_tooltip');
				}
			}).data("kendoTooltip");
		}

		$('.cw_health_summary_list')
            .off('mouseover', '.asset_indicator .exclamation-mark, .asset_indicator .wrench')
            .on('mouseover', '.asset_indicator .exclamation-mark, .asset_indicator .wrench', e => {
                Utils.onMonitorErrorOver(e, {
                    toolTip: this.tooltipHealthSummary
                });
		    });

        if (this.isFromAssetGroup) {
        	let url = this.grid.dataSource.options.transport.read.url;
        	if (typeof url === 'string') {
				this.assetGroupId = url.split('assetGroups/')[1]?.split('/')[0];
			}
		}
	},
	/**
	 * Event handler for the keypressed event on the search input
	 * @param {Object} e The event object
	 */
	onSearch: function (e) {
		var val = $(e.currentTarget).val();
		this.assetsDataSource.filter([]);

		if ($.trim(val) !== '') {
			this.assetsDataSource.filter({
				logic: 'or',
				filters: [{
						field: 'targets',
						operator: function (item) {
							var status = false;
							var lowerCaseValue = val.toLowerCase();
							for (var i = 0, length = item.length; i < length; i++) {
								if (item[i].toLowerCase().indexOf(lowerCaseValue) !== -1) {
									status = true;
									break;
								}
							}
							return status;
						}
					}, {
						field: 'name',
						operator: 'contains',
						value: val
					}, {
						field: 'systemType',
						operator: 'contains',
						value: val
					}, {
						field: 'assetType',
						operator: 'contains',
						value: val
					}, {
						field: 'acknowledgedBy',
						operator: 'contains',
						value: val
					}]
			});
		}
	},
	/**
	 * Handler function for the click event on the status component
	 * @param {Object} e The click event object
	 */
	onService: function (e) {
		var serviceId = $(e.currentTarget).attr('data-uid');
		State.mainApp.loadModule('ServiceDetails', serviceId, {}, e);
	},
	/**
	 * Handler for the asset checkbox click
	 * @param {Object} e The click event
	 */
	onAssetCheck: function (e) {
		var checkboxes = this.grid.wrapper.find('.cw_grid_check');
		var count = 0;
		var accountId = Cookies.CeesoftCurrentAccountId;
		var isSameAccount = true;
		var selectAllIndex;
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			var checkboxId = $(checkboxes[i]).attr('data-id');
			var index = State.markedCheckboxes.indexOf(checkboxId);
			if (this.selectedIds) {
				selectAllIndex = this.selectedIds.indexOf(checkboxId);
			}
			if ($(checkboxes[i]).is(':checked')) {
				count++;
				var record = this.grid.dataSource.get($(checkboxes[i]).attr('data-id'));
				if (accountId) {
					if (accountId !== record.accId) {
						isSameAccount = false;
					}
				}
				if (index === -1) {
					State.markedCheckboxes.push(checkboxId);
				}
				if (selectAllIndex === -1) {
					this.selectedIds.push(checkboxId);
					this.selectedItems.push({
						accountId: record.accId,
						assetType: record.assetType,
						id: record.id,
						name: record.name
					});
				}
			} else {
				if (index !== -1) {
					State.markedCheckboxes.splice(index, 1);
				}
				if (selectAllIndex !== undefined && selectAllIndex !== -1) {
					this.selectedIds.splice(selectAllIndex, 1);
					this.selectedItems.splice(selectAllIndex, 1);
				}
			}
		}
		if (count) {
			this.gridMenu.enableItem('cw_set_tags');
			this.gridMenu.enableItem('cw_incidents_create');
			this.gridMenu.enableItem('cw_incidents_mark_seen');
			this.gridMenu.enableItem('cw_summary_delete');
			this.gridMenu.enableItem('cw_set_in_maintenance');
			this.gridMenu.enableItem('cw_end_maintenance');
			this.gridMenu.enableItem('cw_set_information');
			if (count === 1) {
				this.gridMenu.enableItem('cw_summary_edit');
			} else {
				this.gridMenu.disableItem('cw_summary_edit');
			}
			if (isSameAccount) {
				this.gridMenu.enableItem('cw_update_assetgroup');
				this.gridMenu.enableItem('cw_merge_assets');
			} else {
				this.gridMenu.disableItem('cw_update_assetgroup');
				this.gridMenu.disableItem('cw_merge_assets');
			}
		} else {
			this.gridMenu.disableItem('cw_set_tags');
			this.gridMenu.disableItem('cw_summary_delete');
			this.gridMenu.disableItem('cw_update_assetgroup');
			this.gridMenu.disableItem('cw_incidents_create');
			this.gridMenu.disableItem('cw_incidents_mark_seen');
			this.gridMenu.disableItem('cw_summary_edit');
			this.gridMenu.disableItem('cw_merge_assets');
			this.gridMenu.disableItem('cw_set_in_maintenance');
			this.gridMenu.disableItem('cw_end_maintenance');
			this.gridMenu.disableItem('cw_set_information');
		}
		this.checkboxStates = Utils.getCheckboxStates(this.grid);
	},
	/**
	 * Handler for the check all checkbox click
	 * @param {Object} e The click event
	 */
	onAssetCheckAll: function (e) {
		var checkAll = this.grid.wrapper.find('.cw_grid_check_all').is(':checked');
		var checkboxes = this.grid.wrapper.find('.cw_grid_check');
		var i, length = checkboxes.length;
		this.filteringChanged = false;
		for (i = 0; i < length; i++) {
			$(checkboxes[i]).prop('checked', checkAll);
		}
		if (checkAll && checkboxes.length) {
			this.gridMenu.enableItem('cw_set_tags');
			this.gridMenu.enableItem('cw_incidents_create');
			this.gridMenu.enableItem('cw_incidents_mark_seen');
			this.gridMenu.enableItem('cw_summary_delete');
			this.gridMenu.enableItem('cw_merge_assets');
            this.gridMenu.enableItem('cw_set_in_maintenance');
            this.gridMenu.enableItem('cw_end_maintenance');
			this.gridMenu.enableItem('cw_set_information');
			if (checkboxes.length === 1) {
				this.gridMenu.enableItem('cw_summary_edit');
			} else {
				this.gridMenu.disableItem('cw_summary_edit');
			}
			var accountId;
			var isSameAccount = true;
			for (var i = 0, length = checkboxes.length; i < length; i++) {
                var checkboxId = $(checkboxes[i]).attr('data-id');
                var index = State.markedCheckboxes.indexOf(checkboxId);
				if ($(checkboxes[i]).is(':checked')) {
					var record = this.grid.dataSource.get($(checkboxes[i]).attr('data-id'));
					if (accountId) {
						if (accountId !== record.accId) {
							isSameAccount = false;
						}
					} else {
						accountId = record.accId;
					}
                    if (index === -1) {
                        State.markedCheckboxes.push(checkboxId);
                    }
				}
			}
			if (isSameAccount) {
				this.gridMenu.enableItem('cw_update_assetgroup');
			} else {
				this.gridMenu.disableItem('cw_update_assetgroup');
			}
			this.getAllIds();
		} else {
			State.markedCheckboxes = [];
			this.gridMenu.disableItem('cw_set_tags');
			this.gridMenu.disableItem('cw_summary_delete');
			this.gridMenu.disableItem('cw_incidents_create');
			this.gridMenu.disableItem('cw_incidents_mark_seen');
			this.gridMenu.disableItem('cw_update_assetgroup');
			this.gridMenu.disableItem('cw_summary_edit');
			this.gridMenu.disableItem('cw_merge_assets');
            this.gridMenu.disableItem('cw_set_in_maintenance');
            this.gridMenu.disableItem('cw_end_maintenance');
			this.gridMenu.disableItem('cw_set_information');
            this.selectedIds = null;
			this.selectedItems = null;
		}
		this.checkboxStates = Utils.getCheckboxStates(this.grid);
	},
	getAllIds: function () {
		if (this.isFromAssetGroup) {
			this.mainUrl = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/health/assetGroups/' + this.assetGroupId + '/assets';
		}
		let url = this.mainUrl + '/selectAll/' + this.parametersUrl;
		Utils.ajax(url, 'POST', JSON.stringify(this.gridPayload), $.proxy(function (result) {
			if (result.success) {
				this.selectedItems = result.data;
				this.selectedIds = this.selectedIds || [];
				for (let i = 0; i < this.selectedItems.length; i++) {
					this.selectedIds.push(this.selectedItems[i].id);
				}
			}
		}, this));
	},
	/**
	 * Handler for the asset edit button
	 */
	onEditAsset: function (e) {
		var checkboxes = this.grid.wrapper.find('.cw_grid_check');
		var assets = [], assetId, assetName;
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			if ($(checkboxes[i]).is(':checked')) {
				assets.push($(checkboxes[i]).data('id'));
			}
		}
		if (assets.length === 1) {
			assetId = assets[0];
			assetName = $('input[data-id=' + assetId + ']').closest('tr').find('.cw_grid_link').text();
			var record = this.assetsDataSource.get(assetId);
			State.mainApp.loadModule('AssetDetails', assetId, {
				id: assetId,
				name: assetName,
				accountName: record.accountName,
				accountId: record.accId
			}, e);
		}
	},

	onNewAsset(e) {
		State.mainApp.onNewAsset(e, this.assetGroupId);
	},

	/**
	 * Handler function for delete sla button
	 * @param {Object} e The clicke event object
	 */
	onAssetDelete: function (e) {
		var assets = [];
		this.nonDeletableAssets = [];
		if (!$(e.currentTarget).hasClass('disabled')) {
			var checkboxes = $('.cw_health_summary_list').find('.cw_grid_check:checked');
			var item, checkbox;
			var	dataSource = this.grid.dataSource;
			for (var i = 0, length = checkboxes.length; i < length; i++) {
				checkbox = $(checkboxes[i]);
				if (checkbox.is(':checked')) {
					item = dataSource.getByUid(checkbox.closest('tr').data('uid'));
					if (item.assetType === 'AGENT') {
						this.nonDeletableAssets.push(item.id);
					} else {
						assets.push(item.id);
					}
				}
			}
			const deletableSelectedIds = this.selectedItems?.filter(x => x.assetType != 'AGENT').map(x => x.id);
			assets = deletableSelectedIds?.length ? deletableSelectedIds : assets;
			this.assetsLength = assets.length;
			this.selectedAssets = assets;
			if (this.assetsLength) {
				this.assetIndex = 0;
				this.gridMenu.disableItem('cw_summary_edit');
				this.gridMenu.disableItem('cw_summary_delete');

				var dialog = new Dialog({
					title: lang.INFO,
					msg: lang.account.messages.ASSETS_REMOVE_CONFIRMATION,
					icon: 'ERROR',
					actionText: 'DELETE',
					fn: $.proxy(function (value, button) {
						if (button === 'ok') {
							kendo.ui.progress($('.cw_health_summary_list'), true);
							this.checkAssetDependencies();
						}
					}, this),
					windowClose: $.proxy(function () {
						this.gridMenu.enableItem('cw_summary_delete');
					}, this)
				});
				dialog.show();
			}
		}
	},
	/**
	 * Checks for asset dependencies
	 */
	checkAssetDependencies: function () {
		var assetId = this.selectedAssets[this.assetIndex++], record, assetName
		if (assetId) {
			if (this.nonDeletableAssets.indexOf(assetId) === -1) {
				record = this.assetsDataSource.get(assetId);
				this.selectedAssetId = assetId;
				assetName = record?.name || assetId;

				var dependenciesUrl = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/assets/' + this.selectedAssetId + '/dependencies';
				Utils.ajax(dependenciesUrl, 'GET', {}, $.proxy(function (data) {
					if (data.length) {
						this.openDependenciesPopup(data, assetName, assetId);
						setTimeout(this.adjustTableHeight, 100);
					} else {
						var deleteUrl = Settings.serverPath + 'assets/' + assetId;
						Utils.ajax(deleteUrl, 'DELETE', {}, $.proxy(function (result) {
							if (result.success) {
								this.checkAssetDependencies();
								this.assetDeleted = true;
							} else {
								this.resetCheckingAssets();
								Utils.showInfo(lang.ALERT, result.message, result.details);
							}
						}, this));
					}
				}, this));
			} else {
				if (this.dependenciesWindow) {
					this.dependenciesWindow.close();
				}
				var dialog = new Dialog({
					title: lang.ALERT,
					msg: lang.assethealth.messages.NON_DELETABLE_ASSET_MESSAGE,
					icon: 'WARNING',
					fn: $.proxy(function () {
						return this.checkAssetDependencies();
					}, this)
				});
				dialog.show();
			}
		} else {
			kendo.ui.progress($('.cw_health_summary_list'), false);
            this.assetsDataSource.read();

			if (this.dependenciesWindow) {
				this.dependenciesWindow.close();
			}
			if (this.assetDeleted) {
				this.assetDeleted = false;
				this.actionNotification.setOptions({
					message: lang.account.messages.ASSETS_SUCCESS_DELETED,
					status: 'success'
				}).show();
			}
		}
	},
	/**
	 * Resets the checking assets
	 */
	resetCheckingAssets: function () {
		this.selectedAssets = [];
		this.assetIndex = 0;

		kendo.ui.progress($('.cw_health_summary_list'), false);
        this.assetsDataSource.read();
	},
	/**
	 * Creates and opens the asset dependencies window
	 *
	 * @param {Array} dependencies - The asset dependencies array
	 * @param {String} assetName The asset name
	 * @param {String} assetId The id of the asset
	 */
	openDependenciesPopup: function (dependencies, assetName, assetId) {
		if (this.dependencyWindow) {
			this.dependenciesWindow.close();
		}
		this.dependenciesWindow = new ModalWindow({
			title: i('Dependency check'),
			width: 960,
			height: 640,
			minWidth: 220,
			actions: [],
			url: 'include/Dependencies.jsp',
			//resize: this.adjustTableHeight,
			onClose: $.proxy(function () {
				this.clearCheckboxes();
			}, this),
			refresh: $.proxy(function () {
				$('#cw_dependencies_deleteall').removeClass('hide');
				this.dependencyWindow = new Dependencies({
					accountId: this.assetAccountId,
					dependencies: dependencies,
					name: assetName,
					container: '.cw_health_summary_list',
					infoTemplate: lang.account.messages.ASSET_DELETE_INFO,
					deleteAll: $.proxy(function (e) {
						var deleteUrl = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/assets/' + assetId + '/dependencies/deleteAll';
						Utils.ajax(deleteUrl, 'DELETE', {}, $.proxy(function (result) {
							if (result.success) {
								this.checkAssetDependencies();
							} else {
								Utils.showInfo(lang.ALERT, result.message, result.details);
								this.resetCheckingAssets();
							}
						}, this));
					}, this)
				});
			}, this)
		});
		this.dependenciesWindow.open();
	},
	clearCheckboxes: function () {
		var checkAll = $('.cw_health_summary_list').find('.cw_grid_check_all');
		var	checkboxes = $('.cw_health_summary_list').find('.cw_grid_check');
		var length = checkboxes.length;
		var i;
		checkAll.prop('checked', false);
		for (i = 0; i < length; i++) {
			$(checkboxes[i]).prop('checked', false);
		}
		State.markedCheckboxes = [];
	},
	/**
	 * Method by Andy
	 *
	 */
	adjustSectionHeight: function () {
		var section = $('.cw_assethealth_module').find('.cw_section_full');
		var sectionHeight = section.height();
		section.find('.cw_section_content').css('height', sectionHeight);
		section.find('.k-grid-content').css('height', sectionHeight - 40);
	},
	/**
	 * Saves user preferences
	 */
	saveUserPreferences: function () {
		if (!this.isView) {
			if (this.isFromAssetGroup) {
				this.saveUserPrefs({
					category: 'GroupAssetHealth',
					filterPanel: this.gridFilterPanel,
					grid: this.grid,
					eventsToolbar: this.eventsToolbar,
					keys: {
						searchPhrase: 'ahGroupSummarySearchPhrase',
						columns: 'ahGroupSummaryColumns',
						sort: 'ahGroupSummarySort',
						filter: 'ahGroupSummaryFilter',
						FsViews: 'ahGroupSummaryFSViews',
						defaultFsView: 'defaultGroupSummaryFsView'
					}
				});
			} else {
				this.saveUserPrefs({
					category: 'AssetHealth',
					filterPanel: this.gridFilterPanel,
					grid: this.grid,
					eventsToolbar: this.eventsToolbar,
					keys: {
						searchPhrase: 'ahSummarySearchPhrase',
						columns: 'ahSummaryColumns',
						sort: 'ahSummarySort',
						filter: 'ahSummaryFilter',
						FsViews: 'ahSummaryFSViews',
						defaultFsView: 'defaultSummaryFsView'
					}
				});
			}
		}
	},
	/**
	 * Loads user preferences
	 */
	loadUserPreferences: function () {
		this.userPref = [];
		let moduleName = this.isFromAssetGroup ? 'GroupAssetHealth' : 'AssetHealth';
		UserPrefs.load(moduleName, $.proxy(function (result) {
			if (result.success) {
				this.userPref = result.data;

				this.initComponent();
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},
	/**
	 * Handler function for the asset saved event
	 */
	onAssetSaved: function () {
        this.assetsDataSource.read();
	},
	/**
	 * Handler function for the assetsAddedToGroup event
	 */
	onAssetsAdded: function () {
		this.actionNotification.setOptions({
			message: lang.assethealth.messages.ASSETS_SUCCESS_ADDED_TO_GROUP,
			status: 'success'
		}).show();
	},
	/*
	 * Handler function for reloading sections which are using tags
	 * */
	reloadByTags: function () {
		this.grid.dataSource.read();
	},
    /**
     * Called after one or multiple are set in maintenance
     */
    onMaintenanceSaved: function () {
        this.actionNotification.setOptions({
            message: lang.service.messages.MAINTENANCE_SUCCESS_SAVED,
            status: 'success',
        }).show();
        this.grid.dataSource.read();
    },
	/**
	 * Refresh grid when events are ready
	 * @param {Array} events Array of event objects
	 */
    onEventsTimeout: function (events) {
		var summaryGrid = $('.cw_health_summary_list').data('kendoCustomGrid');
		if (summaryGrid) {
            this.assetsDataSource.read();
		}
	},
	onSetInformation() {
		$('.window_area').append('<div id="set-information-window-container"></div>');
    	ReactDOM.render(<SetInformationWindow
			onClose={this.onSetInformationClose}
			onUpdate={(value) => this.onSetInformationUpdate(value)}
		></SetInformationWindow>, $('#set-information-window-container').get(0))
	},
	onSetInformationClose() {
    	ReactDOM.unmountComponentAtNode($('#set-information-window-container').get(0));
	},
	onSetInformationUpdate(informationValue) {
    	let currentSelectedIds;
    	if (this.selectedIds) {
    		currentSelectedIds = this.selectedIds;
		} else {
			let selectedCheckboxes = $('.cw_health_summary_list').find('.cw_grid_check:checked');
			currentSelectedIds = [];
			for (let i = 0; i < selectedCheckboxes.length; i++) {
				currentSelectedIds.push($(selectedCheckboxes[i]).attr('data-id'));
			}
		}

		let url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/assets/information';
		let postObj = {
			assetIds: currentSelectedIds,
			information: informationValue
		};
		Utils.ajax(url, 'POST', JSON.stringify(postObj), $.proxy(function (result) {
			if (result.success) {
				this.actionNotification.setOptions({
					message: lang.messages.INFO_SUCCESSFULLY_UPDATE,
					status: 'success'
				}).show();
				this.clearCheckboxes();
				this.grid.dataSource.read();
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
			this.onSetInformationClose();
		}, this));
	},
	/**
	 * Subscribes to the events
	 */
	subscribe: function () {
		var subscriptionObj = [{
				eventType: 'AssetHealth',
				accountId: Cookies.CeesoftCurrentAccountId,
				includeSubaccounts: State.includeSubaccounts
			}, {
				eventType: 'Administration',
				actionTypes: ['ASSET_CREATE', 'ASSET_UPDATE', 'ASSET_DELETE'],
				accountId: Cookies.CeesoftCurrentAccountId,
				includeSubaccounts: State.includeSubaccounts
			}];
       RemoteEventsManager.subscribe(this.subscriberId, subscriptionObj);
	},
	/**
	 * Called when application is unloaded/destroyed
	 */
	destroy: function () {
		if (this.eventsToolbar) {
			this.eventsToolbar.destroy();
		}
        if (this.tooltipOverview) {
            this.tooltipOverview.destroy();
        }
		this.saveUserPreferences();
        Application.prototype.destroy.call(this);
	}
});
