import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {b} from './common';
import { TOGGLE_DAY } from './reducer';
import RRule from 'rrule';

export const DAYS_OF_WEEK = [
	{text: 'Mon', value: RRule.MO},
	{text: 'Tue', value: RRule.TU},
	{text: 'Wed', value: RRule.WE},
	{text: 'Thu', value: RRule.TH},
	{text: 'Fri', value: RRule.FR},
	{text: 'Sat', value: RRule.SA},
	{text: 'Sun', value: RRule.SU}
];

function Day(props) {
	const handleClick = React.useCallback(() => {
		props.dispatch({ type: TOGGLE_DAY, value: props.value })
	}, [props.dispatch, props.value]);

	return <div className={classnames(b('day'), {selected: props.selected})} onClick={handleClick}>{props.text}</div>
}

Day.propTypes = {
	value: PropTypes.string.isRequired,
	dispatch: PropTypes.func.isRequired,
	selected: PropTypes.bool.isRequired,
	text: PropTypes.string.isRequired
};

function Days(props) {
	if (props.frequency !== RRule.WEEKLY) {
		return null;
	}

	return <div className={classnames(b('days'), b('row'))}>{DAYS_OF_WEEK.map(({text, value}) => {
		return <Day key={value} text={text} value={value} selected={props.days?.includes(value)} dispatch={props.dispatch}/>
	})}</div>
}

Days.propTypes = {
	days: PropTypes.any,
	frequency: PropTypes.number,
	dispatch: PropTypes.func.isRequired
};

export default Days;
