import './eventStateChart.less';

import React from "react";
import {getEventsState} from "./api";
import {translator} from "core";
import {apiFetch} from "framework/api";
import {ModalWindow} from 'controls';
import EventSummaryView from 'areas/views/eventSummaryView'
import {Severity} from 'framework/entities/healthData'
import {EventSummaryRouter} from "areas/views/bundleDescription"
import {ApplicationState} from "framework/applicationState";
import { RemoteEventsManager } from 'core';

let i = translator({
  "No data available": {
    "no": "Ingen data"
  },
  "There is at least one event that have a system warning": {
    "no": "Det er min en Hendelse med system advarsel"
  },
  "total-label-critical": {
    "en": "Total events in Critical state",
    "no": "Total Hendelser i kritisk tilstand"
  },
  "total-label-major": {
    "en": "Total events in Major state",
    "no": "Total Hendelser i alvorlig tilstand"
  },
  "total-label-minor": {
    "en": "Total events in Minor state",
    "no": "Total Hendelser i advarsel tilstand"
  },
  "total-label-none": {
    "en": "Total events in Ok state",
    "no": "Total Hendelser i OK tilstand"
  },
  "total-label-inactive": {
    "en": "Total events in inactive state",
    "no": "Total Hendelser i inaktiv tilstand"
  },
  "Event summary": {
    "no": "Hendelseoversikt"
  }
});

let b = require('b_').with('event-state-chart');

export class EventStateChart extends React.PureComponent {
	constructor (props) {
		super(props);
		this.state = {
			dataLoaded: false,
			data: {}
		}
	}

	render() {
		if(!this.state.dataLoaded)
			return null;

		return (
			<div className={b('bars-chart')}>
				<div className={b('bars')}>
					{this.state.data.sections.map((item) =>
						<div key={item.severity}
							 className={b(`bar ${item.cssClass} ${item.count ? '' : 'zero-count-bar'}`)}
							 title={i('total-label-' + item.severity)}
							 onClick={item.count ? () => this.onSeverityClick(item.severity) : null}
						>
							<span className={b('value')}>{item.count}</span>
							<span className={b('legend')}>{item.label}</span>
							{item.flag && <span className={b('service-indicator pointer')}
							                    onClick={() => this.onSeverityClick(item.severity, true)}
												title={i('There is at least one event that have a system warning')}
							></span>}
						</div>)
					}
				</div>
				<div className={b('progress-container')}>
					{this.state.data.sections.filter(x => x.count != 0).map((item) =>
						<div key={item.severity}
							 onClick={() => this.onSeverityClick(item.severity)}
							 className={b(`progress-bar ${item.cssClass}`)}
							 style={{width: item.count / this.state.data.totalCount * 100 + '%'}}
							 title={i('total-label-' + item.severity)}
						>
						</div>)
					}
				</div>
			</div>)
	}

	componentDidMount() {
		if (this.props.enableSubscription) {
			this.subscribe();
		}
		this.prepareViewData();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevProps.data != this.props.data){
			this.prepareViewData()
		}
	}

	componentWillUnmount() {
		if (this.props.enableSubscription) {
			this.unsubscribe();
		}
	}

	subscribe() {
		let config = this.props.config;
		let subscriptionObj = [{
			eventType: 'EventSummary',
			accountId: config.accountId,
			includeSubaccounts: config.includeSubaccounts,
			types: config.eventTypes
		}];
		this.subscription = RemoteEventsManager.subscribeCallback(subscriptionObj, this.onEvents);
	}

	onEvents = events => {
		this.prepareViewData();
	}

	unsubscribe() {
		this.subscription?.unsubscribe();
	}

	async prepareViewData(){
		let eventsData = await this.loadEventsData();

		let viewData = {
			sections: [{
				severity: Severity.Critical,
				cssClass: 'is-critical',
				label: i('Critical'),
				count: eventsData.critical,
				flag: eventsData.criticalFlag
			}, {
				severity: Severity.Major,
				cssClass: 'is-major',
				label: i('Major'),
				count: eventsData.major,
				flag: eventsData.majorFlag
			}, {
				severity: Severity.Minor,
				cssClass: 'is-minor',
				label: i('Minor'),
				count: eventsData.minor,
				flag: eventsData.minorFlag
			}],
			maintenanceCount: eventsData.maintenance,
			accountName: eventsData.accountName,
			totalCount: eventsData.critical + eventsData.major + eventsData.minor + eventsData.ok
		};

		this.setState({
			dataLoaded: true,
			data: viewData
		});
	}

	componentWillUnmount(){
		if(this.modalWindow)
			this.modalWindow.close()
	}

	async loadEventsData() {
		if (this.props.data) {
			return this.props.data;
		}

		let result = await apiFetch(getEventsState({
			tags: this.props.config.tags,
			eventTypes: this.props.config.eventTypes,
			showUntagged: this.props.config.showUntagged,
			includeSubaccounts: this.props.config.includeSubaccounts,
			accountId: this.props.config.accountId
		}))

		if(result.success){
			return result.data
		}

		return []
	}

	onSeverityClick = (severity, onlyWarnings) => {
		if(this.props.customRedirect && this.props.customRedirect())
			return

		let additionalData = {
			severity: [severity],
			includeSubaccounts: this.props.config != null
				? this.props.config.includeSubaccounts
				: ApplicationState.includeSubaccounts,
			showUntagged: this.props.config?.showUntagged == true,
			onlyWarnings,
			accountId: this.props.config?.accountId,
			showMenu: (this.props.config?.showEventsInPopup && this.props.config?.showMenu) ?? false
		}

		if(this.props.config?.tags && this.props.config.tags.length > 0){
			additionalData.tags = this.props.config.tags
		}

		if(this.props.config?.eventTypes){
			additionalData.eventTypes = this.props.config.eventTypes
		}

		if(this.props.config?.showEventsInPopup){
			const modalConfig = {
				embedded: true,
				disableEventsToolbar: true,
				hideIdColumn: !(this.props.config?.showEventsInPopup && this.props.config?.showMenu),
				doNotLoadPrefs: true
			}

			this.modalWindow = new ModalWindow({
				id: 'eventSummary_modal',
				title: i('Event summary'),
				width: '80%',
				url: 'include/Summary/EventSummaryView.jsp',
				height: 720,
				refresh: function () {
					new EventSummaryView({...additionalData,...modalConfig}).init()
				}
			});
			this.modalWindow.open();
		}
		else {
			State.mainApp.navigate(EventSummaryRouter.list(additionalData), {newTab: this.props.redirectConfig?.linkInNewTab})
		}
	}
}
