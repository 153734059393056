import PropTypes from "prop-types";
import React from "react";

import {Section} from "controls/react/layout/section";
import {Toolbar} from "controls/react/layout/toolbar";
import {IconButton} from "controls/react/form/iconButton";

export const DefaultWidgetWrapper = props => {
	let showWidgetHeader = props.dashboardSettings.showWidgetHeader || !props.designer.config.chromeless;
	let isReadOnly = props.dashboardSettings.readOnly;

	return (
		<Section contentPadding={false} height={"fit"} containerClass={props.containerClass}>
			{showWidgetHeader &&
			<Toolbar title={props.config.title}>
				{props.toolbarAtTheEnd}
				{!isReadOnly && <IconButton iconName={"pencil"}
				                            embedded={true}
				                            onClick={props.actions.editWidget}/>}
				{!isReadOnly && <IconButton iconName={"remove"}
				                            embedded={true}
				                            onClick={props.actions.deleteWidget}/>}
			</Toolbar>
			}
			{props.children}
		</Section>
	)
}

DefaultWidgetWrapper.propTypes = {
	toolbarAtTheEnd: PropTypes.node,
	containerClass: PropTypes.string,
	dashboardSettings: PropTypes.object,
	config: PropTypes.object,
	actions: PropTypes.object
}
