import {Application, LocalEventsManager, Cookies, RemoteEventsManager, ErrorHandler} from 'core';
import {CustomNotification, Dialog, EventsToolbar, GridMenu, ModalView, MultiToggle, PreferencesPanel} from 'controls';
import {Utils, UserPrefs, State} from 'tools';
import Settings from 'settings';

let RoleView = function (config) {
	Utils.apply(this, config);
	this.hasEvents = false;
	this.subscriberId = Utils.guid();
	this.initComponent();
};

export default RoleView;

jQuery.extend(RoleView.prototype, Application.prototype, {
	/**
	 * Main view application related initializations
	 */
	initComponent: function () {
		Application.prototype.initComponent.call(this);

		this.actionNotification = new CustomNotification({
			appendToElement: '.window_area',
			status: 'success',
			style: 'top:15px; right:15px; left:15px;',
		});

		this.defaultColumns = {
			id: {
				hidden: false,
				width: 40
			},
			name: {
				hidden: false,
				width: 180
			},
			type: {
				hidden: false,
				width: 180
			},
			description: {
				hidden: false
			}
		};
		this.loadUserPreferences();

		// update content button
		State.mainApp.contextMenu.setActionButton('USER_CATEGORY');
		this.cardGridToggle = new MultiToggle({
			id: 'cw_uc_view_toggle',
			cssClass: 'cw_multi_toggle right',
			value: 'false',
			items: [{
				id: 'cw_grid_view',
				title: lang.GRID_VIEW,
				selected: false,
				cssClass: 'glyphicons justify',
				fn: this.onViewToggle,
				scope: this,
				value: 'true'
			}, {
				id: 'cw_card_view',
				title: lang.CARD_VIEW,
				selected: true,
				cssClass: 'glyphicons show-big-thumbnails',
				fn: this.onViewToggle,
				scope: this,
				value: 'false'
			}]
		});
		this.gridMenu = new GridMenu({
			renderTo: 'cw_uc_grid_menu',
			items: [{
				id: 'cw_create',
				icon: 'plus-sign',
				text: lang.CREATE,
				fn: this.onCreate,
				scope: this,
				role: 'USER_CATEGORY_CREATE'
			}, {
				id: 'cw_delete',
				icon: 'bin',
				text: lang.DELETE,
				fn: this.onDelete,
				scope: this,
				disabled: true,
				role: 'USER_CATEGORY_DELETE'
			}]
		});
		this.removeMask();
		this.removeListeners();
		this.attachListeners();
	},
	/**
	 * Handler for the view toggle buttons click
	 * @param {String} value 'true' or 'false'
	 */
	onViewToggle: function (value) {
		if (value === 'true') {
			if (this.grid && this.gridViewLoaded) {
				this.showGridView = true;
				$('#cw_uc_list').addClass('hide');
				$('#cw_uc_roles_grid').removeClass('hide');
				this.grid.refresh();
			} else {
				this.initGridView();
				this.grid.refresh();
			}
		} else {
			$('#cw_uc_roles_grid').addClass('hide');
			if (this.innerView) {
				this.showGridView = false;
				$('#cw_uc_list').removeClass('hide');
				//$('#cw_filter_reset').addClass('inactive');
			} else {
				this.initCardView();
			}
		}
	},
	/**
	 * Removes the listeners for the form's buttons
	 */
	removeListeners: function () {
		$('#uc_grid').off();
		this.onSavedHandler = $.proxy(this.onSaved, this);
		LocalEventsManager.unbind('usercategorysaved', this.onSavedHandler);
	},
	/**
	 * Attaches the listeners for the form's buttons
	 */
	attachListeners: function () {
		$('#uc_grid').on('click', '.cw_grid_check', $.proxy(this.onCheck, this));
		$('#uc_grid').on('click', '.cw_grid_check_all', $.proxy(this.onCheckAll, this));
		$('#uc_grid').on('click', '.cw_grid_link', $.proxy(this.onNameClick, this));
		LocalEventsManager.bind('usercategorysaved', this.onSavedHandler);
		$(window).off().on('resize', $.proxy(this.adjustSectionHeight, this));
	},
	/**
	 * Init card component
	 */
	initCardView: function () {
		$('#uc_group_name').hide();
		var deleteClass = State.mainApp.session.hasRole('USER_CATEGORY_DELETE') ? '' : ' hide';
		var updateClass = State.mainApp.session.hasRole('USER_CATEGORY_UPDATE') ? '' : ' hide';
		//var template ='';
		var template = '<li><div class="cw_card" id="${id}">';
		template += '<div class="cw_card_avatar">';
		template += '<div class="cw_avatar cw_avatar_uc"></div>';
		template += '</div>';
		template += '<div class="cw_card_content">';
		template += '<h2 class="card_title"><strong>' + lang.NAME + ':</strong> ${name} </h2>';
		if (State.mainApp.session.hasRole("USER_CATEGORY_READ")) {
			template += '<p class="card_extra"><strong>' + lang.DESCRIPTION + ':</strong> #if(description){# ${description} #}# </p><p class="card_extra"><strong>' + lang.TYPE + ': ${type}</strong> </p>';
		}
		template += '</div>';
		template += '<div class="cw_card_options">';
		template += '<span class="glyphicons bin ' + deleteClass + '"></span>';
		template += '<span class="glyphicons pencil ' + updateClass + '"></span>';
		template += '</div>';
		template += '</div></li>';
		this.showGridView = false;
		$('#cw_uc_view_toggle').find('li').last().addClass('is_selected');

		this.innerView = new ModalView({
			autoLoad: true,
			createButtonText: lang.account.ACCOUNT_NEW,
			viewDataSource: this.dataSource,
			template: template,
			id: 'cw_uc_list',
			cssClass: 'user_categories_ul',
			title: lang.USER_CATEGORIES,
			pager: true,
			onCreateButton: function (e) {
				if (State.mainApp.session.hasRole('USER_CATEGORY_CREATE')) {
					State.mainApp.loadModule('AdministrationUserCategoriesDetails');
				}
			},
			onEditButton: $.proxy(function (e) {
				if (State.mainApp.session.hasRole('USER_CATEGORY_UPDATE') || State.mainApp.session.hasRole('USER_CATEGORY_READ')) {
					var ucId = e.currentTarget.parentNode.parentNode.id;
					var record = this.dataSource.getByUid($(e.currentTarget).closest('li').data('uid'));
					State.mainApp.loadModule('AdministrationUserCategoriesDetails', record.id, {
						id: record.id,
						name: record.name,
						description: record.description,
						type: record.type,
						//tags: record.tags,
						mode: State.mainApp.session.hasRole('USER_CATEGORY_UPDATE') ? 'edit' : 'read'
					});
				}
			}, this),
			onDeleteButton: function (e) {
				if (State.mainApp.session.hasRole('USER_CATEGORY_DELETE')) {
					var id = e.currentTarget.parentNode.parentNode.id;
					var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/usercategories/' + id;
					var dialog = new Dialog({
						title: lang.INFO,
						msg: lang.account.messages.USER_CATEGORY_REMOVE_CONFIRMATION,
						icon: 'ERROR',
						actionText: 'DELETE',
						scope: this,
						fn: function (value, button) {
							if (button === 'ok') {
								Utils.ajax(url, 'DELETE', {}, $.proxy(function (result) {
									if (result.success) {
										this.actionNotification.setOptions({
											message: lang.account.messages.USER_CATEGORY_SUCCESS_DELETED,
											status: 'success'
										}).show();

										// reload data source
										this.viewDataSource.read();
									} else {
										Utils.showInfo(lang.ALERT, result.message, result.details, this.grid);
									}
								}, this));
							}
						},
						deleteFromGrid: this.grid
					});
					dialog.show();

				}
			}
		});
		setTimeout(function () {
			$('#cw_uc_list').removeClass('hide');
		}, 300);
	},
	/**
	 * Init grid component
	 * @param {boolean} showGrid Defaults to true
	 */
	initGridView: function (showGrid) {
		var filterMessages = lang.grid.filter,
			loadGrid = true;
		this.gridMessages = {
			clear: lang.CLEAR,
			info: lang.grid.filter.SHOW_ITEMS,
			filter: lang.FILTER
		};
		if (showGrid === false) {
			loadGrid = false;
			this.gridViewLoaded = false;
		}  else {
			this.gridViewLoaded = true;
		}
		if (loadGrid) {
			this.showGridView = true;
			$('#cw_uc_view_toggle').find('li').first().addClass('is_selected');
			$('#cw_filter_reset').removeClass('hide');
			$('#cw_uc_list').addClass('hide');
			$('#cw_uc_roles_grid').removeClass('hide');
		}

		var ucColumns = this.ucColumns || JSON.parse(UserPrefs.get('ucColumns')) || this.defaultColumns;
		ucColumns = Utils.completeColumns(ucColumns, this.defaultColumns);
		this.grid = $('#uc_grid').kendoCustomGrid({
			dataSource: this.dataSource,
			resizable: true,
			reorderable: true,
			selectable: 'row',
			autoBind: true,
			sortable: {
				mode: 'multiple',
				allowUnsort: true
			},
			filterable: {
				extra: false,
				operators: {
					string: {
						startswith: filterMessages.STARTS_WITH,
						neq: filterMessages.NEQ,
						eq: filterMessages.EQ
					}
				},
				messages: this.gridMessages
			},
			columns: Utils.rearrangeColumns([{
				field: 'id',
				title: lang.SELECTOR,
				sortable: false,
				filterable: false,
				menu: false,
				template: '<input type="checkbox" class="cw_grid_check" data-id="#= id #" />',
				headerTemplate: '<input type="checkbox" class="cw_grid_check_all" />',
				headerAttributes: {
					'class': 'text_center'
				},
				attributes: {
					'class': 'text_center'
				},
				hidden: ucColumns.id.hidden,
				width: ucColumns.id.width
			}, {
				field: 'name',
				title: lang.NAME,
				sortable: true,
				filterable: true,
				template: '<a class="cw_grid_link ellipsis to_expand" data-id="#= id #">#= name #</a>',
				hidden: ucColumns.name.hidden,
				width: ucColumns.name.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'description',
				title: lang.DESCRIPTION,
				sortable: true,
				filterable: true,
				template: '#= description?description:""#',
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				},
				hidden: ucColumns.description.hidden,
				width: ucColumns.description.width
			}], ucColumns),
			columnMenu: true,
			change: $.proxy(this.onRowExpand, this),
			dataBound: $.proxy(this.onDataBound, this)
		}).data('kendoCustomGrid');
		// Add Kendo tooltip to the header of the columns
		Utils.gridColumnHeaderTooltip(this.grid);
		this.adjustSectionHeight();
		this.grid.thead.find("[data-field='id']>.k-header-column-menu").remove();
		var searchValue = UserPrefs.get('defaultFsView') ? '' : (UserPrefs.get('ucSearchPhrase') || '');
		this.gridFilterPanel = new PreferencesPanel({
			renderTo: 'cw_uc_filters',
			grid: this.grid,
			modulePrefName: 'AdministrationUserCategories',
			defaultPrefViewKey: 'defaultFsView',
			prefListKey: 'ucFSViews',
			userPref: this.userPref,
			onRemove: $.proxy(this.saveUserPreferences, this),
			searchFields: ['name', 'description'],
			searchValue: searchValue,
			defaultColumns: this.defaultColumns
		});
	},
	/**
	 * Handler function for the change(select) event on the grid
	 * @param {Object} e The change event object
	 * @deprecated ?
	 */
	onRowExpand: function (e) {
		var selectedRow = this.grid.select();
		var myRow = selectedRow[0];
		var messageEl = $(myRow).find('.to_expand');
		if ($(messageEl).hasClass('cw_message_expanded')) {
			$(messageEl).removeClass('cw_message_expanded').addClass('ellipsis');
		} else {
			$('#uc_grid').find('.k-grid-content').find('td.cw_message_expanded').removeClass('cw_message_expanded').addClass('ellipsis');
			$(messageEl).addClass('cw_message_expanded').removeClass('ellipsis');
		}
	},
	/**
	 * Handler function for the grid data bound event
	 */
	onDataBound: function () {
		$('#uc_grid').find('.k-grid-content').kendoTooltip({
			filter: "td.tooltip:not(:empty)",
			content: function (event) {
				return event.target.text().split("\n").join("<br />");
			}
		});
	},
	/**
	 * Handler for the service checkbox click
	 * @param {Object} e The click event
	 */
	onCheck: function (e) {
		e.stopPropagation();
		var checkboxes = $('.cw_grid_check');
		var usercategories = [];
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			if ($(checkboxes[i]).is(':checked')) {
				usercategories.push($(checkboxes[i]).data('id'));
			}
		}
		if (usercategories.length) {
			this.gridMenu.enableItem('cw_delete');
			if (usercategories.length === 1) {
				this.gridMenu.enableItem('cw_update');
			} else {
				this.gridMenu.disableItem('cw_update');
			}
		} else {
			this.gridMenu.disableItem('cw_delete');
			this.gridMenu.disableItem('cw_update');
		}
	},
	/**
	 * Handler for the check all checkbox click
	 * @param {Object} e The click event
	 */
	onCheckAll: function (e) {
		var checkAll = $('.cw_grid_check_all').is(':checked');
		var checkboxes = $('.cw_grid_check');
		var i, length = checkboxes.length;
		for (i = 0; i < length; i++) {
			$(checkboxes[i]).prop('checked', checkAll);
		}
		this.onCheck(e);
	},
	/**
	 * Handler for the delete button click
	 * @param {Object} e The click event
	 */
	onDelete: function (e) {
		if (!$(e.currentTarget).hasClass('disabled')) {
			var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/usercategories/delete';
			var checkboxes = $('.cw_grid_check');
			var i, length = checkboxes.length, haveUC = false, checked = 0;
			var ids = [];
			for (i = 0; i < length; i++) {
				if ($(checkboxes[i]).is(':checked')) {
					var id = $(checkboxes[i]).attr('data-id');
					ids.push(id);
					haveUC = true;
					checked++;
				}
			}
			if (haveUC) {
				var dialog = new Dialog({
					title: lang.INFO,
					msg: checked > 1 ? lang.account.messages.USER_CATEGORIES_REMOVE_CONFIRMATION : lang.account.messages.USER_CATEGORY_REMOVE_CONFIRMATION,
					icon: 'ERROR',
					actionText: 'DELETE',
					fn: $.proxy(function (value, button) {
						if (button === 'ok') {
							this.gridMenu.disableItem('cw_delete');
							this.gridMenu.disableItem('cw_update');
							Utils.ajax(url, 'POST', JSON.stringify(ids), $.proxy(function (result) {
								if (result.success) {
									this.actionNotification.setOptions({
										message: checked > 1 ? lang.account.messages.USER_CATEGORIES_SUCCESS_DELETED : lang.account.messages.USER_CATEGORY_SUCCESS_DELETED,
										status: 'success'
									}).show();

									$('.cw_grid_check_all').prop('checked', false);
									// reload data source
									this.dataSource.read();
								} else {
									Utils.showInfo(lang.ALERT, result.message, result.details);
								}
							}, this));
						}
					}, this)
				});
				dialog.show();
			}
		}

	},

	/**
	 * Handler function for the click event on the category name
	 * @param {Object} e The click event object
	 */
	onNameClick: function (e) {
		var record = this.grid.dataSource.getByUid($(e.currentTarget).closest('tr').data('uid'));
		State.mainApp.loadModule('AdministrationUserCategoriesDetails', record.id, {
			id: record.id,
			name: record.name,
			description: record.description,
			type: record.type,
			//tags: record.tags,
			mode: State.mainApp.session.hasRole('USER_CATEGORY_UPDATE') ? 'edit' : 'read'
		});
	},
	/**
	 * Handler function for the Create menu item
	 */
	onCreate: function () {
		State.mainApp.loadModule('AdministrationUserCategoriesDetails');
	},
	/**
	 * Handler function for the click event on the role update menu item
	 * @param {Object} e The click event object
	 */
	onUpdate: function (e) {
		if (!$(e.currentTarget).hasClass('disabled')) {
			var record = this.grid.dataSource.getByUid($('.cw_grid_check:checked').closest('tr').data('uid'));
			State.mainApp.loadModule('AdministrationUserCategoriesDetails', record.id, {
				id: record.id,
				name: record.name,
				description: record.description,
				type: record.type,
				//tags: record.tags,
				mode: State.mainApp.session.hasRole('USER_CATEGORY_UPDATE') ? 'edit' : 'read'
			});
		}
	},
	/**
	 * Saves user preferences
	 */
	saveUserPreferences: function () {
		var preferences = [{
			key: 'showGridView',
			value: this.showGridView
		}];
		this.saveUserPrefs({
			category: 'AdministrationUserCategories',
			filterPanel: this.gridFilterPanel,
			grid: this.grid,
			eventsToolbar: this.eventsToolbar,
			preferences: preferences,
			keys: {
				searchPhrase: 'ucSearchPhrase',
				columns: 'ucColumns',
				sort: 'ucSort',
				filter: 'ucFilter',
				FsViews: 'ucFSViews'
			}
		});
	},
	createDataSource: function () {
		this.dataSource = new kendo.ceeview.DataSource({
			transport: {
				read: {
					url: Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/usercategories/list?',
					contentType: "application/json; charset=utf-8",
					type: "GET",
					dataType: "json",
					cache: false
				}
			},
			sort: this.ucSort || [{
				field: 'name',
				dir: 'asc',
				compare: null
			}],
			filter: this.ucFilter || [],
			error: ErrorHandler.kendoServerError
		});
	},
	/**
	 * Loads user preferences
	 */
	loadUserPreferences: function () {
		var defaultFsView, showGridView, pref;
		UserPrefs.load('AdministrationUserCategories', $.proxy(function (result) {
			if (result.success) {
				this.userPref = result.data;
				showGridView = UserPrefs.get('showGridView');
				defaultFsView = UserPrefs.get('defaultFsView');

				if (Utils.isGuid(defaultFsView)) {
					pref = JSON.parse(UserPrefs.get(defaultFsView));
					pref = pref || {
						sort: [],
						filter: []
					};
					this.ucSort = pref.sort;
					this.ucFilter = pref.filter;
					this.ucColumns = pref.columns;
				} else {
					if (UserPrefs.get('ucSort')) {
						this.ucSort = JSON.parse(UserPrefs.get('ucSort'));
					} else {
						this.ucSort = [{
							field: 'name',
							dir: 'asc',
							compare: null
						}];
					}
					if (UserPrefs.get('ucFilter')) {
						this.ucFilter = JSON.parse(UserPrefs.get('ucFilter'));
					} else {
						this.ucFilter = [];
					}
				}

				if (showGridView) {
					this.showGridView = showGridView === 'true' ? true : false;
				} else {
					this.showGridView = true;
				}
				this.cardGridToggle.select(this.showGridView ? 'cw_grid_view' : 'cw_card_view');

				this.createDataSource();
				var eventStatus = JSON.parse(UserPrefs.get('eventsStatus')) || {
					startFrom: 0,
					playing: true
				};
				this.eventsToolbar = new EventsToolbar({
					renderTo: $('#cw_uc_events_toolbar'),
					callBackFn: $.proxy(this.onEventsTimeout, this),
					subscriberId: this.subscriberId,
					startFrom: eventStatus.startFrom,
					playing: eventStatus.playing
				});
				if (this.showGridView) {
					this.initGridView();
				} else {
					this.initCardView();
					this.initGridView(false);
				}
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},
	/**
	 * Handler function for the user categories saved event
	 */
	onSaved: function () {
		this.dataSource.read();
	},
	/**
	 * Method by Andy
	 */
	adjustSectionHeight: function () {
		var section = $('.cw_section_full');
		var sectionHeight = section.height();
		section.find('.cw_section_content').css('height', sectionHeight);
		section.find('.k-grid-content').css('height', sectionHeight - 40);
	},
	/**
	 * Subscribes to the events
	 */
	subscribe: function () {

	},
	onEventsTimeout: function (events) {
		this.dataSource.read();
	},
	/**
	 * Destroy
	 */
	destroy: function () {
		this.saveUserPreferences();
		if (this.eventsToolbar) {
			this.eventsToolbar.destroy();
		}
		Application.prototype.destroy.call(this);
	}
});
