import {Application, Cookies, translator} from "core";
import {State, Utils} from "tools";

import Settings from "settings";
import {TeamPolicyView} from "areas/administration/teamPolicyView";
import {TeamProfileView} from "areas/administration/teamProfileView";
import {TeamScheduleView} from "areas/administration/teamScheduleView";
import moment from 'moment';
import {TeamNotificationPolicy} from "areas/administration/notifications/policy";
import {UserRouter} from "areas/administration/users/bundleDescription";
import {TeamRouter} from "areas/administration/teams/bundleDescription";
import {NavigationAction} from 'appStore';
import {CustomNotification} from "../../controls";
import {TeamApi} from "./teams/api";

const i = translator();

export let TeamDetails = function (config) {
	Utils.apply(this, config);
	jQuery.extend(this.data, config);

	this.langLocale = lang.momentsLangCode;
	this.days = lang.regionalSchedulerDays;
	moment.locale(this.langLocale);
	this.initComponent();
};

jQuery.extend(TeamDetails.prototype, Application.prototype, {
	/**
	 * This is main init function
	 */
	initComponent: async function () {
		var actionButtonText = lang.account.TEAM_CREATE;

		this.imageId = '';
		this.teamsLoaded = false;
		this.saved = false;

		this.cachedTabs = {
			schedule: false,
			policy: false
		};
		if (this.mode === 'update') {
			actionButtonText = lang.UPDATE;
		}
		this.tabStrip = $('#cw_team_notifications').kendoTabStrip({
			animation: false,
			activate: $.proxy(this.onTabActivate, this)
		}).data('kendoTabStrip');

		$('.cw_notification_rules_tab').on('click', (e) => {
			State.mainApp.navigate(TeamRouter.ruleset(this.id), {
				navigationAction: NavigationAction.POP_ONE
			});
			e.preventDefault();
			e.stopPropagation();
		})

		let initialTab = $('.cw_profile_tab');
		if(this.selectedTab) {
			initialTab = $(`[data-tab=${this.selectedTab}]`);
		}
		this.tabStrip.activateTab(initialTab);

		this.profileView = new TeamProfileView({
			mode: this.mode,
			id: this.id
		});

		this.profileView.bind('dataload', $.proxy(this.onDataLoad, this));

		$('#save_team').text(actionButtonText);
		this.removeListeners();
		this.attachListeners();
		this.modalNotification = new CustomNotification({
			appendToElement: '.cw_team_summary',
			status: 'success',
			type: 'icon',
			hideOnClick: true
		});
	},
	/**
	 * Called in order to remove existing listeners
	 */
	removeListeners: function () {
		$('#save_team').off();
		$('#cancel').off();
	},
	/**
	 * Attaches the listeners for the form's buttons
	 */
	attachListeners: function () {
		$('#cancel').on('click', $.proxy(this.onCancelButton, this));
		$('#save_team').on('click', $.proxy(this.onSaveButton, this));
		$('#team_name').on('keyup', $.proxy(this.onNameKeyUp, this));
	},
	onNameKeyUp: function () {
		const name = $('#team_name').val();
		Utils.setInvalidField(name, $('#team_name'), true);
		Utils.setPlaceholder($('#team_name'), i('Please input name...'));
	},
	/**
	 * Callback function that handles the save response
	 * @param {Object} result
	 */
	onDataSuccessfullySaved: function (result) {
		if (result.success) {
			State.mainApp.loadModule('AdministrationTeams');
		} else {
			this.profileView.statusNotification.setOptions({
				message: result.message,
				status: 'error'
			}).show();
		}
	},
	/**
	 * Handler function for the click event on Save button
	 * @param {Object} e The click event object
	 */
	onSaveButton: async function (e) {
		let name = $('#team_name').val();
		if(name.trim() !== '') {
			let nameExists = await Utils.checkIfNameExists(TeamApi.getSearchUrl(), name, this.modalNotification, true, this.policyView.name);
			if(nameExists) {
				return;
			}
		}
		this.profileView.clearInvalid();
		this.data = this.profileView.collectData();
		if (this.data.name.trim() === '') {
			this.profileView.statusNotification.setOptions({
				message: lang.account.messages.TEAM_NAME_STATUS,
				status: 'error'
			}).show();

			var teamNameInput = $('#team_name');
			teamNameInput.addClass('invalid');
			teamNameInput.focus();
		} else {
			//var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/teams/updateProfile';
			var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/teams';
			Utils.ajax(url, 'POST', JSON.stringify(this.data), $.proxy(this.onDataSuccessfullySaved, this));
		}
	},
	/**
	 * Handler function for the click event on Cancel button
	 */
	onCancelButton: function () {
		State.mainApp.loadModule('AdministrationTeams');
	},
	/**
	 * Handler function for tab activating
	 * @param {Object} e The activate event
	 */
	onTabActivate: function (e) {
		var tab = $(e.item).data('tab');

		switch (tab) {
			case 'rules':
				State.mainApp.navigate(TeamRouter.ruleset(this.id), {
					navigationAction: NavigationAction.POP_ONE
				});
				break;
		}

	},
	/**
	 * Event handler for the dataload event
	 * @param {Object} data The team data loaded
	 */
	onDataLoad: function (data) {
		this.data = data;
		this.policyView = new TeamPolicyView(jQuery.extend({
			mode: this.mode,
			id: this.id
		}, this.data));
		// this.scheduleView = new TeamScheduleView(jQuery.extend({
		// 	mode: this.mode,
		// 	id: this.id
		// }, this.data));
		// this.scheduleView.bind('schedulerdataloaded', $.proxy(this.onSchedulerDataLoaded, this));
		/*if (this.scheduleView) {
		 Utils.apply(this.scheduleView, data);
		 }
		 if (this.policyView) {
		 Utils.apply(this.policyView, data);
		 }*/
	},
	onSchedulerDataLoaded: function (schedulerData) {
		if (this.policyView) {
			Utils.apply(this.policyView, {
				excludeFilters: schedulerData.excludeFilters,
				notificationTypes: schedulerData.notificationTypes,
				severityThreshold: schedulerData.severityThreshold
			});
			this.policyView.setPolicies();
			this.policyView.typeSelector.value(schedulerData.notificationTypes);
			this.policyView.hiThresholdList.value(schedulerData.severityThreshold);
			this.policyView.filtersGrid.refresh();
		}
	},
	/**
	 *  The destroy method
	 */
	destroy: function () {
		Application.prototype.destroy.call(this);
		this.policyView && this.policyView.destroy();
		// this.scheduleView && this.scheduleView.destroy();

		this.profileView?.unbind('dataload');
	}
});


