export const SET_DATA = "SET_DATA";
export const SHOW_MULTIPLE_QUALIFIERS_WINDOW = "SHOW_MULTIPLE_QUALIFIERS_WINDOW";
export const SET_CREATE_MODE = "SET_CREATE_MODE";
export const LOAD_DATA = "LOAD_DATA";
export const INFO_CHANGED = "INFO_CHANGED";

export const ADD_VIRTUAL_FIELDS = "saga/ADD_VIRTUAL_FIELDS";
export const CHECK_FIELDS_STATE = "saga/CHECK_FIELDS_STATE";
export const INIT_WITH_DATA = "INIT_WITH_DATA";
export const ON_CHANGE = "ON_CHANGE";

export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
