import {Config} from "config";
const jquery = require('jquery');
const path = require('path');

export function getJSON(url) {
	return new Promise((resolve, reject) => {
		jquery.getJSON(url).done(resolve).fail((jqxhr, settings, err) => reject(err));
	});
}

export function getScript(url) {
	return new Promise((resolve, reject) => {
		jquery.getScript(url).done(resolve).fail((jqxhr, settings, err) => reject(err));
	});
}

export function getPath(monitorName, relativePath) {
	return path.join(Config.monitorsPath, monitorName, relativePath);
}
