export function reformatProperties(properties, fieldsToForm, groups = {}, plain = {}, info = {}) {
	for (const property of properties) {
		const {id} = property;

		if (property.properties) {
			reformatProperties(property.properties, fieldsToForm, groups, plain, info)
		} else {
			let group;

			if (!property.group) {
				group = `default_${fieldsToForm[id]}`;
			} else {
				group = property.group;
			}

			groups[group] = groups[group] || [];
			groups[group].push(id);
		}

		plain[id] = property;
		info[id] = {
			value: property.value,
			text: property.text
		}
	}

	return {groups, plain, info};
}
