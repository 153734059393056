import {Application, Cookies, translator} from "core";
import Utils from "tools/utils";
import {CustomNotification, Dialog, ExpandableTextarea} from "controls";
import {Settings} from "settings";

const i = translator();

let RoleDetails = function (config) {
	Utils.apply(this, config);
    this.hasEvents = false;
	this.initComponent();
};

export default RoleDetails;

jQuery.extend(RoleDetails.prototype, Application.prototype, {
	id: null,
	mode: 'create',
	/**
	 * Main init function
	 */
	initComponent: async function () {
        this.subscriberId = Utils.guid();

		Application.prototype.initComponent.call(this);
		this.actionNotification = new CustomNotification({
			appendToElement: '.window_area',
			status: 'success',
			style: 'top:15px; right:15px; left:15px;',
		});
		this.isV1 = true;
		this.isV2checked = false;
		this.removeListeners();
		this.attachListeners();
		this.initKendoComponents();
		if (this.mode !== 'create') {
			$('#cw_uc_save').text(lang.UPDATE);
			$('#cw_uc_name').val(this.name);
			$('#cw_cvid').val(this.id);
			//this.tagsControl.setRawValues(this.tags);
			//$('#cw_uc_type').val(this.type);
			this.descriptionHandler.value(this.description);
		} else {
			$('#cw_cvid').parent().addClass('hide');
			this.onNameKeyUp();
		}
		if (this.mode === 'read') {
			this.applyReadOnly();
		}
		this.removeMask();
	},
	/**
	 * Initialize kendo components
	 */
	initKendoComponents: function () {
        // this.tagsControl = new Tags({
        //     renderEl: $('#cw_tags'),
        //     enabled: this.mode === 'view' ? false : true
        // });

		var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/usercategories/';
		if(this.mode === 'create') {
            url+= 'roles/treelist';
		} else if(this.mode === 'read') {
			url += this.id + '/roles/treelist';
		} else {
            url += this.id + '/roles/treelist?update=true';
		}

		this.privilegesTreeList = $('#cw_uc_privileges_treelist').kendoCustomTreeList({
			dataSource: new kendo.ceeview.TreeListDataSource({
				transport: {
					read: {
						url: url,
						contentType: 'application/json; charset=utf-8',
						type: 'GET',
						dataType: 'json'
					}
				},
				schema: {
					model: {
						id: 'id',
						parentId: 'parentId',
						expanded: true
					}
				}
			}),
			filterable: false,
			sortable: true,
			columnMenu: false,
			columns: [{
					field: 'moduleName',
					title: lang.MODULE,
					width: 180
				}, {
					field: 'name',
					title: lang.GROUP,
					template: '<span data-groupid="#= id#">#= name#</span>',
					sortable: false,
					fitlerable: false,
					width: 240
				}, {
					field: 'menu',
					sortable: false,
					fitlerable: false,
					template: $.proxy(function (item) {
						var menu = item.menu, html = '';
						if (menu) {
							var disabled = (menu.editable && this.mode !== 'read') ? '' : ' disabled="disabled" ';
							var checked = menu.selected ? ' checked="checked" ' : '';
							html = '<input class="cw_menu_priv k-checkbox" type="checkbox" id="' + menu.id + '" ' + disabled + checked + ' title="' + menu.description + '"/><label class="k-checkbox-label" for="' + menu.id + '"></label>';
						}
						return html;
					}, this),
					title: lang.MENU,
					width: 70
				}, {
					field: 'read',
					title: lang.READ,
					sortable: false,
					fitlerable: false,
					template: $.proxy(function (item) {
						var read = item.read, html = '';
						if (read) {
							var disabled = (read.editable && this.mode !== 'read') ? '' : ' disabled="disabled" ';
							var checked = read.selected ? ' checked="checked" ' : '';
							html = '<input class="cw_read_priv k-checkbox" type="checkbox" id="' + read.id + '" ' + disabled + checked + ' title="' + read.description + '"/><label class="k-checkbox-label" for="' + read.id + '"></label>';
						}
						return html;
					}, this),
					width: 70
				}, {
					field: 'write',
					title: lang.WRITE,
					sortable: false,
					fitlerable: false,
					template: $.proxy(function (item) {
						var write = item.write, html = '';
						if (write) {
							var disabled = (write.editable && this.mode !== 'read') ? '' : ' disabled="disabled" ';
							var checked = write.selected ? ' checked="checked" ' : '';
							html = '<input class="cw_write_priv k-checkbox" type="checkbox" id="' + write.id + '" ' + disabled + checked + ' title="' + write.description + '"/><label class="k-checkbox-label" for="' + write.id + '"></label>';
						}
						return html;
					}, this),
					width: 70
				}, {
					field: 'roles',
					title: lang.DETAILS,
					sortable: false,
					fitlerable: false,
					template: $.proxy(function (data) {
						var roles = data.roles;
						if (roles) {
							var checkedNr = 0, totalNr = 0;
                            var privilege, uuid, checked, nativeRole, disabled;
							var html = '<button class="k-button">' + lang.DETAILS + '</button>';
							html += '<ul class="cw_list is_hidden">';
							//show editable privileges
							for (var i = 0; i < roles.length; i++) {
								privilege = roles[i];
								uuid = Utils.guid();
								checked = privilege.selected ? ' checked="checked" ' : '';
								nativeRole = privilege.nativeRole ? ' data-native="true" ' : ' data-native="false" ';
								disabled = (this.mode === 'read' || !privilege.editable) ? 'disabled="disabled"' : '';
								if (privilege.nativeRole) {
									html += '<li class="cw_item left w33"><input class="cw_uc_editable k-checkbox cw_basic_priv" type="checkbox" data-id="' + privilege.id + '" id="' + uuid + '" title="' + privilege.description + '"' + checked + disabled + nativeRole + '/><label class="k-checkbox-label" for="' + uuid + '" title="' + privilege.description + '">' + privilege.name + '</label></li>';
								}
								if (privilege.nativeRole && privilege.selected) {
                                    checkedNr++;
								}
								if (privilege.nativeRole) {
                                    totalNr++;
								}
							}
							html += '</ul>';
							var countTemplate = kendo.template(lang.account.messages.USER_CATEGORY_PRIVILEGES_INFO);
							var countHtml = countTemplate({
								checkedNr: checkedNr,
								totalNr: totalNr
							});
							html += '<span class="cw_priviledges_checked">' + countHtml + '</span>';
						}
						return html;
					}, this)
				}
			],
			dataBound: $.proxy(this.onDataBound, this)
		}).data('kendoCustomTreeList');
		this.descriptionHandler = new ExpandableTextarea({
			renderTo: $('.cw_uc_description')
		});
	},

	/**
	 * Attaches listeners on various view controls
	 */
	attachListeners: function () {
		$('#cw_uc_privileges_treelist').on('click', '.cw_menu_priv', $.proxy(this.onMenuPriv, this));
		$('#cw_uc_privileges_treelist').on('click', '.cw_read_priv', $.proxy(this.onReadPriv, this));
		$('#cw_uc_privileges_treelist').on('click', '.cw_write_priv', $.proxy(this.onWritePriv, this));
		$('#cw_uc_privileges_treelist').on('click', '.cw_basic_priv', $.proxy(this.onBasicPriv, this));
		$('#cw_uc_save').on('click', $.proxy(this.onSave, this));
		$('#cw_uc_name').on('keyup', $.proxy(this.onNameKeyUp, this));
		$('#cw_uc_cancel').on('click', $.proxy(this.onCancel, this));
		$('.cw_privileges_details').on('click', '.k-button', $.proxy(this.toggleDetailsSettings, this));
		$(window).off().on('resize', $.proxy(this.adjustGridHeight, this));
	},
	/**
	 * Removes listeners on various view controls
	 */
	removeListeners: function () {
		$('#cw_uc_privileges_treelist').off();
		$('#cw_uc_cancel').off();
		$('#cw_uc_save').off();
		$('.cw_privileges_details').off();
	},
	/**
	 * Called after data is bounded to the grid
	 * @param {Object} e The databound event object
	 */
	onDataBound: function (e) {
		this.isV1 = this.mode === 'edit' && !($('.cw_menu_priv:checked').length + $('.cw_read_priv:checked').length + $('.cw_write_priv:checked').length);
		this.adjustGridHeight();
	},
	/**
	 * Handler function for the cancel button
	 */
	onCancel: function () {
		State.mainApp.loadModule('AdministrationUserCategoriesView');
	},
	onNameKeyUp: function () {
		const name = $('#cw_uc_name').val();
		Utils.setInvalidField(name, $('#cw_uc_name'), false, 'required_form');
		Utils.setPlaceholder($('#cw_uc_name'), i('Please input name...'));
	},
	/**
	 * Handler function for the update/create button
	 */
	onSave: async function () {
		let name = $('#cw_uc_name').val();
		if(name.trim() !== '') {
			let url = Api.accountRoot() + 'usercategories/lite?name=';
			let nameExists = await Utils.checkIfNameExists(url, name, this.actionNotification, true, this.name);
			if(nameExists ) {
				return;
			}
		}
		var majorPrivileges = $('.cw_menu_priv:checked, .cw_read_priv:checked, .cw_write_priv:checked').map(function (index, item) {
			return $(item).attr('id');
		});
		var minorPrivileges = $('.cw_uc_editable:checked:not(:disabled)').map(function (index, item) {
			return $(item).attr('data-id');
		});
		var data = {
			id: this.id,
			name: name,
			description: this.descriptionHandler.value(),
			//tags: this.tagsControl.getRawValues(),
			roles: majorPrivileges.toArray().concat(minorPrivileges.toArray())
		};
		if (!data.name.trim()) {
			this.actionNotification.setOptions({
				message: lang.account.messages.REQUIRED_FIELDS,
				status: 'error'
			}).show();
		} else {
			if (this.isV1 && ($('.cw_menu_priv:checked, .cw_read_priv:checked, .cw_write_priv:checked').length || this.isV2checked)) {
				var dialog = new Dialog({
					title: lang.INFO,
					msg: lang.account.messages.PERMISSION_VERSION_CHECK,
					icon: 'WARNING',
					actionText: 'UPDATE',
					fn: $.proxy(function (value, button) {
						if (button === 'ok') {
							this.save(data);
						}
					}, this)
				});
				dialog.show();
			} else {
				this.save(data);
			}
		}
	},
	save: function (data) {
		var saveUrl = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/usercategories/';
		Utils.ajax(saveUrl, 'POST', JSON.stringify(data), $.proxy(function (result) {
			if (result.success) {
				this.onCancel();
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},
	toggleDetailsSettings: function (e) {
		$(e.currentTarget).parent().find('.cw_list').first().toggleClass('is_hidden');
		$(e.currentTarget).toggleClass('k-primary');
	},
	/**
	 * Handler function for the click event on a menu type checkbox
	 * @param {Object} e The click event object
	 */
	onMenuPriv: function (e) {
		var groups = [];
		var tr = $(e.currentTarget).closest('tr');
		var uid = tr.data('uid');
		var record = this.privilegesTreeList.dataSource.getByUid(uid);
		var menu = record.menu;
		var checked = $(e.currentTarget).is(':checked');
		if (checked) {
			this.isV2checked = true;
		}
		if (menu.roles) {
			var roles = menu.roles;
			for (var i = 0; i < roles.length; i++) {
				var checkbox = $('input[data-id="' + roles[i] + '"]');
				if (checkbox.length) {
					var groupId = checkbox.closest('tr').find('td:eq(1) span').data('groupid');
					groups[groupId] = groupId;

					checkbox.prop('checked', checked);
					if (checked) {
						checkbox.attr('disabled', 'disabled');
					} else {
						if (checkbox.attr('data-native')=== 'true'){
							checkbox.removeAttr('disabled');
						}
					}
				}
			}
		}
		this.updateBasicPrivCount(e);
		var groupKeys = Object.keys(groups);
		if (groupKeys.length) {
			for (var key in groups) {
				this.updateBasicPrivCount({
					currentTarget: '[data-groupid="' + groups[key] + '"]'
				});
			}
		}
	},
	/**
	 * Handler function for the click event on a read type checkbox
	 * @param {Object} e The click event object
	 */
	onReadPriv: function (e) {
		var groups = [];
		var tr = $(e.currentTarget).closest('tr');
		var uid = tr.data('uid');
		var record = this.privilegesTreeList.dataSource.getByUid(uid);
		var read = record.read;
		var checked = $(e.currentTarget).is(':checked');
		if (checked) {
			this.isV2checked = true;
		}
		if (read.roles) {
			var roles = read.roles;
			for (var i = 0; i < roles.length; i++) {
				var checkbox = $('input[data-id="' + roles[i] + '"]');
				if (checkbox.length) {
					var groupId = checkbox.closest('tr').find('td:eq(1) span').data('groupid');
					groups[groupId] = groupId;

					checkbox.prop('checked', checked);
					if (checked) {
						checkbox.attr('disabled', 'disabled');
					} else {
						if (checkbox.attr('data-native')=== 'true'){
							checkbox.removeAttr('disabled');
						}
					}
				}
			}
		}

		if (checked) {
			var menuCheck = tr.find('.cw_menu_priv');
			if (!menuCheck.is(':checked')) {
				menuCheck.trigger('click');
			}
		} else {
			var writeCheck = tr.find('.cw_write_priv');
			if (writeCheck.is(':checked')) {
				writeCheck.trigger('click');
			}
		}
		this.updateBasicPrivCount(e);
		var groupKeys = Object.keys(groups);
		if (groupKeys.length) {
			for (var key in groups) {
				this.updateBasicPrivCount({
					currentTarget: '[data-groupid="' + groups[key] + '"]'
				});
			}
		}
	},
	/**
	 * Handler function for the click event on awrite type checkbox
	 * @param {Object} e The click event object
	 */
	onWritePriv: function (e) {
		var groups = [];
		var tr = $(e.currentTarget).closest('tr');
		var uid = tr.data('uid');
		var record = this.privilegesTreeList.dataSource.getByUid(uid);
		var write = record.write;
		var checked = $(e.currentTarget).is(':checked');
		if (checked) {
			this.isV2checked = true;
		}
		if (write.roles) {
			var roles = write.roles;
			for (var i = 0; i < roles.length; i++) {
				var checkbox = $('input[data-id="' + roles[i] + '"]');
				checkbox.prop('checked', checked);
				if (checkbox.length) {
					var groupId = checkbox.closest('tr').find('td:eq(1) span').data('groupid');
					groups[groupId] = groupId;

					checkbox.prop('checked', checked);
					if (checked) {
						checkbox.attr('disabled', 'disabled');
					} else {
						if (checkbox.attr('data-native')=== 'true'){
							checkbox.removeAttr('disabled');
						}
					}
				}
			}
		}
		var menuCheck = tr.find('.cw_menu_priv');
		var readCheck = tr.find('.cw_read_priv');
		if (checked) {
			if (!menuCheck.is(':checked')) {
				menuCheck.trigger('click');
			}
			if (!readCheck.is(':checked')) {
				readCheck.trigger('click');
			}
		} else {
			if (readCheck.is(':checked')) {
				readCheck.trigger('click').trigger('click');
			}
		}
		this.updateBasicPrivCount(e);
		var groupKeys = Object.keys(groups);
		if (groupKeys.length) {
			for (var key in groups) {
				this.updateBasicPrivCount({
					currentTarget: '[data-groupid="' + groups[key] + '"]'
				});
			}
		}
	},
	/**
	 *	Handler function for the click event on a basic privileges checkbox
	 *	@param {Object} e The click event object
	 */
	onBasicPriv: function (e) {
		var parentCellElement = $(e.currentTarget).closest('td');
		var countTemplate = kendo.template(lang.account.messages.USER_CATEGORY_PRIVILEGES_INFO);
        var checked = parentCellElement.find('input[data-native="true"]:checked').length;
        var total = parentCellElement.find('input[data-native="true"]').length;
		var countHtml = countTemplate({
            checkedNr: checked,
            totalNr: total
		});
		parentCellElement.find('.cw_priviledges_checked').html(countHtml);

	},
	updateBasicPrivCount: function (e) {
		var parentCellElement = $(e.currentTarget).closest('tr').find('.cw_priviledges_checked').closest('td');
        var checked = parentCellElement.find('input[data-native="true"]:checked').length;
        var total = parentCellElement.find('input[data-native="true"]').length;
		var countTemplate = kendo.template(lang.account.messages.USER_CATEGORY_PRIVILEGES_INFO);
		var countHtml = countTemplate({
            checkedNr: checked,
            totalNr: total
		});
		parentCellElement.find('.cw_priviledges_checked').html(countHtml);
	},
	adjustGridHeight: function () {
		var section = $('.cw_section');

		var grid = $('.cw_privileges_details');
		var height = section.height() - 170;

		section.find('.cw_section_content').css('height', section.height());
		grid.css('height', height);
		grid.find('.k-grid-content').css('height', height - 30);
	},
	applyReadOnly: function () {
		$('#cw_uc_name').attr('readonly', 'readonly');
		this.descriptionHandler.readonly(true);
		$('.cw_menu_priv').attr('readonly', 'readonly');
		$('.cw_read_priv').attr('readonly', 'readonly');
		$('.cw_write_priv').attr('readonly', 'readonly');
		$('.cw_uc_editable').attr('readonly', 'readonly');
		$('.cw_window_actions').find('button').off().remove();
	},
	/**
	 * Destroy
	 */
	destroy: function () {
		Application.prototype.destroy.call(this);
	}
});
