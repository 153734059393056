import {makeAutoObservable} from "mobx";
import {CostBudget} from "./costBudget";
import {createModelSchemaWrapper, withParent} from "../../../framework/serializr-integration";
import {list, object, primitive} from "serializr";
import {ModelValidator, ValidatableModel} from "../../../framework/mobx-integration";

export class CostModel implements ValidatableModel<CostModel> {
	id: string;
	name: string;
	description: string;
	modelType: string;
	costProfileId: string;
	costBudgets: CostBudget[];

	validator = new ModelValidator(this);

	constructor() {
		makeAutoObservable(this);

		this.validator.required('name');
	}

	get valid() : boolean {
		return this.validator.valid;
	}

	removeBudget(child: CostBudget) {
		const index = this.costBudgets.findIndex(x => x.budgetId === child.budgetId);
		this.costBudgets.splice(index, 1);
	}
}

createModelSchemaWrapper(CostModel, {
	id: primitive(),
	name: primitive(),
	description: primitive(),
	modelType: primitive(),
	costProfileId: primitive(),
	costBudgets: list(withParent(object(CostBudget)))
});
