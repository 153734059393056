import {Api} from "tools";

export default class AttributesApi {
	static getAttributes(assetId) {
		return Api.fetch(`${Api.accountRoot()}assets/${assetId}/attributes`)
	}
	static saveAttribute(attribute, assetId, initialData) {
		let url =  `${Api.serverRoot()}assets/${assetId}/attributes`;
		if (initialData) {
			url += `?group=${initialData.group}&key=${initialData.key}`;
		}
		return Api.fetchPost(url, attribute)
	}
	static deleteAttribute (attributes, assetId) {
		return Api.fetchPost(`${Api.serverRoot()}assets/${assetId}/attributes/delete`, attributes)
	}
	static getGroups(assetId) {
		return Api.fetch(`${Api.accountRoot()}assets/${assetId}/attributes/groups`);
	}
}
