import React from 'react';
import Utils from 'tools/utils'
import Cookies from 'core/cookies'

function DateTimePicker(props) {
	const ref = React.useRef();
	const kendoRef = React.useRef();
	const onChangeRef = React.useRef();

	onChangeRef.current = React.useCallback((value) => {
		props.onChange(value);
	}, [props.onChange]);

	React.useEffect(() => {
		kendoRef.current = $(ref.current).kendoDateTimePicker({
			format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat || "EEE, dd MMM HH:mm:ss"),
			timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat || "HH:mm:ss"),
			value: props.value,
			change: () => onChangeRef.current(kendoRef.current.value())
		}).data('kendoDateTimePicker');
	}, []);

	React.useEffect(() => {
		if (kendoRef.current) {
			kendoRef.current.value(props.value);
		}
	}, [props.value]);

	return <div>
		<input ref={ref} />
	</div>
}

export default DateTimePicker;
