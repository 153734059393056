import Utils from 'tools/utils'
import State from 'tools/state'
import Configuration from 'configuration';
import Renderer from 'tools/renderer';
import Cookies from 'core/cookies';
import {AssetsRouter} from './bundleDescription';
import MultiSelectGridFilter from 'controls/multiSelectGridFilter';


export let AssetColumns = {
	get: function (scope, prefColumns, dataSource, gridSelector, isCheckboxColumnMissing) {
		var defaultColumns = {
			id: {
				hidden: false,
				width: 40
			},
			assetIndicator: {
				hidden: false,
				width: 100
			},
			name: {
				hidden: false,
				width: 240
			},
			accountName: {
				hidden: !State.includeSubaccounts,
				width: 150
			},
			targets: {
				hidden: false,
				width: 240
			},
			numberOfIncidents: {
				hidden: false,
				width: 90
			},
			reasonsPercent: {
				hidden: false,
				width: 100
			},
			serviceQualifierCount: {
				hidden: false,
				width: 150
			},
			monitorMetricCount: {
				hidden: false,
				width: 150
			},
			externalMetricCount: {
				hidden: false,
				width: 150
			},
			acknowledged: {
				hidden: false,
				width: 220
			},
			acknowledgedBy: {
				hidden: false,
				width: 150
			},
			acknowledgeDate: {
				hidden: false,
				width: 150
			},
			tags: {
				hidden: false,
				width: 120
			},
			systemType: {
				hidden: false,
				width: 120
			},
			assetType: {
				hidden: false,
				width: 120
			},
			description: {
				hidden: false,
				width: 120
			},
			externalIdentifiers: {
				hidden: false,
				width: 120
			},
			modified: {
				hidden: false,
				width: 120
			},
			information: {
				hidden: false,
				width: 200
			},
			isAgentAlive: {
				hidden: false,
				width: 120
			}
		};

		scope.firstEntry = Configuration.installedMonitors;
		if (scope.firstEntry) {
			for (var i = 0; i < scope.firstEntry.length; i++) {
				var currentMonitorType = scope.firstEntry[i];
				defaultColumns[currentMonitorType] = {
					hidden: false,
					width: 120
				}
			}
		}

		if (!prefColumns) {
			prefColumns = defaultColumns;
		} else {
			//fix for serviceboard
			if (!prefColumns.id) {
				prefColumns.id = {
					hidden: true,
					width: 0
				};
			}
			prefColumns = Utils.completeColumns(prefColumns, defaultColumns);
		}
		let column;
		for (column in prefColumns) {
			if (typeof prefColumns[column].width === 'undefined') {
				prefColumns[column].width = defaultColumns[column].width;
			}
		}

		let gridMessages = {
			clear: lang.CLEAR,
			info: lang.grid.filter.SHOW_ITEMS,
			filter: lang.FILTER
		};
		let filterMessages = lang.grid.filter;
		let columns = [{
			field: 'id',
			title: lang.SELECTOR,
			attributes: {
				'class': 'text_center'
			},
			headerAttributes: {
				'class': 'text_center'
			},
			menu: false,
			sortable: false,
			filterable: false,
			template: '<input type="checkbox" class="cw_grid_check" data-id="${id}" />',
			headerTemplate: '<input type="checkbox" class="cw_grid_check_all" />',
			locked: true,
			lockable: false,
			hidden: prefColumns.id.hidden,
			width: prefColumns.id.width
		}, {
			field: 'assetIndicator',
			title: lang.assethealth.SEVERITY,
			sortable: {
				compare: $.proxy(function (a, b) {
					return Utils.customCompare(a, b, 'assetIndicatorSorter', '-1', dataSource.sortNow);
				}, scope)
			},
			filterable: {
				operators: {
					string: {
						eq: filterMessages.ISIN,
						neq: filterMessages.ISNOTIN
					}
				},
				ui: $.proxy(Renderer.renderAssetIndicatorFilter, {
					gridSelector: gridSelector,
					serverSorting: true
				}),
				messages: gridMessages
			},
			headerAttributes: {
				"class": "text_center"
			},
			attributes: {
				'class': "text_center asset_indicator"
			},
			template: function (item) {
				return Renderer.monitor({
					agentState: item.agentState,
					assetType: item.assetType,
					monitorIndex: item.assetHI,
					monitorError: item.monitorError,
					monitorDataUnavailable: item.monitorDataUnavailable,
					monitorMaintenance: item.monitorMaintenance,
					type: 'asset_status',
					assetMaintenance: item.assetMaintenance
				});
			},
			locked: true,
			lockable: false,
			hidden: prefColumns.assetIndicator.hidden,
			width: prefColumns.assetIndicator.width
		}, {
			field: 'name',
			title: lang.NAME,
			template:
				State.mainApp?.session.hasRole('ASSET_READ') && State.mainApp?.session.hasRole('ASSET_LIST') && State.currentApp?.dashboardDesigner?.props?.mode !== 'designer'
					? row => `<a class="cw_grid_link ellipsis to_expand" href="#${AssetsRouter.details(row.id)}">${row.name}</a>`
					: '#= name #'
			,
			sortable: true,
			filterable: {
				messages: gridMessages
			},
			attributes: {
				'class': "tooltip ellipsis to_expand cw_grid_link cw_asset_name"
			},
			locked: true,
			lockable: false,
			hidden: prefColumns.name.hidden,
			width: prefColumns.name.width
		}, {
			field: 'accountName',
			title: lang.ACCOUNT,
			sortable: true,
			lockable: false,
			filterable: {
				ui: $.proxy(function (element) {
					return Renderer.filterGridByOwnElementDS.call(this, element, 'accountName');
				}, scope),
				messages: gridMessages,
				extra: false,
				operators: {
					string: {
						neq: filterMessages.NEQ,
						eq: filterMessages.EQ
					}
				}
			},
			hidden: scope.hasOwnAccountPref ? prefColumns.accountName.hidden : !State.includeSubaccounts,
			width: prefColumns.accountName.width,
			attributes: {
				'class': 'tooltip ellipsis to_expand'
			}
		}, {
			field: 'targets',
			title: lang.TARGET,
			sortable: false,
			lockable: false,
			filterable: {
				messages: gridMessages
			},
			attributes: {
				'class': 'tooltip ellipsis to_expand'
			},
			hidden: prefColumns.targets.hidden,
			width: prefColumns.targets.width
		}, {
			field: 'numberOfIncidents',
			title: lang.service.INCIDENT,
			sortable: true,
			lockable: false,
			filterable: {
				messages: gridMessages
			},
			attributes: {
				'class': 'tooltip ellipsis to_expand'
			},
			hidden: prefColumns.numberOfIncidents.hidden,
			width: prefColumns.numberOfIncidents.width
		}, {
			field: 'reasonsPercent',
			title: lang.REASON,
			filterable: {
				operators: {
					number: {
						//eq: filterMessages.EQ,
						//neq: filterMessages.NEQ,
						gte: filterMessages.GTE,
						gt: filterMessages.GT,
						lte: filterMessages.LTE,
						lt: filterMessages.LT
					}
				},
				ui: function (element) {
					element.kendoDropDownList({
						dataSource: [{
							text: 'All unassigned',
							value: 0
						}, {
							text: '20% assigned',
							value: 20
						}, {
							text: '40% assigned',
							value: 40
						}, {
							text: '60% assigned',
							value: 60
						}, {
							text: '80% assigned',
							value: 80
						}, {
							text: 'All assigned',
							value: 100
						}],
						dataTextField: 'text',
						dataValueField: 'value',
						change: Utils.onFilterDropDownChange,
						//optionLabel: lang.grid.FILTER_SELECT_VALUE
					});
				},
				messages: gridMessages
			},
			sortable: {
				compare: function (a, b) {
					var index;
					if (a.reasonsPercent === b.reasonsPercent) {
						if (a.totalReasons === b.totalReasons) {
							index = 0;
						} else if (a.totalReasons > b.totalReasons) {
							index = -1;
						} else {
							index = 1;
						}
					} else if (a.reasonsPercent > b.reasonsPercent) {
						index = -1;
					} else {
						index = 0;
					}
					return index;
				}
			},
			lockable: false,
			attributes: {
				'class': 'tooltip ellipsis to_expand text-center'
			},
			template: '#=ownedReasons# / #=totalReasons#',
			hidden: prefColumns.reasonsPercent.hidden,
			width: prefColumns.reasonsPercent.width
		}, {
			title: lang.SQ_METRICS,
			field: 'serviceQualifierCount',
			sortable: true,
			lockable: false,
			filterable: {
				messages: gridMessages
			},
			hidden: prefColumns.serviceQualifierCount.hidden,
			width: prefColumns.serviceQualifierCount.width,
			attributes: {
				'class': 'tooltip ellipsis to_expand text_center'
			}
		}, {
			title: lang.MONITOR_METRICS,
			field: 'monitorMetricCount',
			sortable: true,
			lockable: false,
			filterable: {
				messages: gridMessages
			},
			hidden: prefColumns.monitorMetricCount.hidden,
			width: prefColumns.monitorMetricCount.width,
			attributes: {
				'class': 'tooltip ellipsis to_expand text_center'
			}
		}, {
			title: lang.EXTERNAL_METRICS,
			field: 'externalMetricCount',
			sortable: true,
			lockable: false,
			filterable: {
				messages: gridMessages
			},
			hidden: prefColumns.externalMetricCount.hidden,
			width: prefColumns.externalMetricCount.width,
			attributes: {
				'class': 'tooltip ellipsis to_expand text_center'
			}
		}, {
			field: 'acknowledged',
			title: lang.service.ACKNOWLEDGED,
			sortable: true,
			lockable: false,
			filterable: {
				messages: {
					isTrue: lang.YES,
					isFalse: lang.NO,
					clear: lang.CLEAR,
					info: lang.grid.filter.SHOW_ITEMS,
					filter: lang.FILTER
				}
			},
			template: '#= acknowledged ? "' + lang.YES + '" : ""#',
			hidden: prefColumns.acknowledged.hidden,
			width: prefColumns.acknowledged.width,
			attributes: {
				'title': '#=acknowledgedMouseover || "" #'
			}
		}, {
			field: 'tags',
			title: lang.TAGS,
			sortable: true,
			lockable: false,
			filterable: {
				ui: $.proxy(function (element) {
					let multiselect = new MultiSelectGridFilter({
						element: element,
						field: 'tags',
						grid: scope.grid,
						itemTemplate: '#=data.text#',
						tagTemplate: '#=data.text#',
						dataSource: scope.filterOptions['tags']
					});
				}, scope),
				messages: this.gridMessages,
				extra: false,
				operators: {
					string: {
						eq: filterMessages.EQ,
						neq: filterMessages.NEQ
					}
				}
			},
			template: item => item.tags.join(','),
			hidden: prefColumns.tags.hidden,
			width: prefColumns.tags.width,
			attributes: {
				'class': 'tooltip ellipsis to_expand'
			}
		}];

		if (isCheckboxColumnMissing) {
			//remove checkbox column in Asset/Group Summary
			columns.splice(0, 1);
		}

		var monitorNameByType = Configuration.installedMonitorsNames;

		let installedMonitors = [...new Set(Configuration.installedMonitors)];

		for (var i = 0; i < installedMonitors.length; i++) {
			let currentMonitorType = installedMonitors[i];
			columns.push({
				field: currentMonitorType,
				title: monitorNameByType[currentMonitorType],
				template: ((currentMonitorType) => {
					return (item) => {
						let obj = {
							item: item,
							agentState: item.agentState,
							currentType: currentMonitorType,
							monitorMaintenanceHasPriority: true
						};
						if (currentMonitorType === 'SYSTEM' || currentMonitorType === 'IBMSVC') {
							obj.showAssetConsoleButton = true;
							obj.cssClass = 'inline_block';
						}
						let isMonitorColumn = true;
						return Renderer.monitor(obj, isMonitorColumn);
					}
				})(currentMonitorType),
				attributes: {
					"class": "text_center"
				},
				lockable: false,
				filterable: {
					operators: {
						string: {
							eq: filterMessages.ISIN,
							neq: filterMessages.ISNOTIN
						}
					},
					ui: $.proxy(scope.getFilterableForMonitor, {
						scope: scope,
						gridSelector: gridSelector
					}),
					messages: gridMessages
				},
				hidden: prefColumns[currentMonitorType].hidden,
				width: prefColumns[currentMonitorType].width,
				sortable: {
					compare: ((currentMonitorType) => {
						return (a, b) => {
							return Utils.customCompare(a, b, currentMonitorType, '6', dataSource.sortNow);
						}
					})(currentMonitorType)
				}
			});
		}

		columns.push({
			field: 'acknowledgedBy',
			title: lang.incidents.ACKNOWLEDGED_BY,
			sortable: true,
			lockable: false,
			filterable: {
				messages: gridMessages
			},
			template: '#=acknowledgedBy ? acknowledgedBy : "" #',
			hidden: prefColumns.acknowledgedBy.hidden,
			width: prefColumns.acknowledgedBy.width,
			attributes: {
				'class': 'tooltip ellipsis to_expand'
			}
		}, {
			field: 'acknowledgeDate',
			title: lang.incidents.TIME_OF_FLAG,
			sortable: true,
			lockable: false,
			headerAttributes: {
				'class': 'mw100'
			},
			template: '#= Renderer.browserDateRenderer(acknowledgeTime, Constants.DATETIME, "")#',
			filterable: {
				ui: function (element) {
					element.kendoDateTimePicker({
						format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
						timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat)
					});
				}
			},
			hidden: prefColumns.acknowledgeDate.hidden,
			width: prefColumns.acknowledgeDate.width,
			attributes: {
				'class': 'tooltip ellipsis to_expand'
			}
		}, {
			field: 'systemType',
			title: lang.account.SYSTEM_TYPE,
			sortable: true,
			lockable: false,
			filterable: {
				operators: {
					string: {
						neq: filterMessages.NEQ,
						eq: filterMessages.EQ
					}
				},
				ui: function (element) {
					element.kendoDropDownList({
						dataSource: [{
							text: lang.account.SYSTEM_TYPE_GENERIC,
							value: 'GENERIC'
						}, {
							text: lang.account.SYSTEM_TYPE_WINDOWS,
							value: 'WINDOWS'
						}, {
							text: lang.account.SYSTEM_TYPE_UNIX,
							value: 'UNIX'
						}],
						change: Utils.onFilterDropDownChange,
						optionLabel: lang.grid.FILTER_SELECT_VALUE,
						dataTextField: 'text',
						dataValueField: 'value'
					});
				},
				messages: gridMessages
			},
			hidden: prefColumns.systemType.hidden,
			width: prefColumns.systemType.width,
			attributes: {
				'class': 'tooltip ellipsis to_expand'
			}
		}, {
			field: 'assetType',
			title: lang.account.ASSET_TYPE,
			sortable: true,
			lockable: false,
			filterable: {
				operators: {
					string: {
						neq: filterMessages.NEQ,
						eq: filterMessages.EQ
					}
				},
				ui: function (element) {
					element.kendoDropDownList({
						dataSource: [{
							text: 'AGENT',
							value: 'AGENT'
						}, {
							text: 'STATIC',
							value: 'STATIC'
						}],
						change: Utils.onFilterDropDownChange,
						optionLabel: lang.grid.FILTER_SELECT_VALUE,
						dataTextField: 'text',
						dataValueField: 'value'
					});
				},
				messages: gridMessages
			},
			hidden: prefColumns.assetType.hidden,
			width: prefColumns.assetType.width,
			attributes: {
				'class': 'tooltip ellipsis to_expand'
			}
		}, {
			field: 'description',
			title: lang.DESCRIPTION,
			sortable: false,
			menu: false,
			filterable: false,
			lockable: false,
			attributes: {
				'class': 'tooltip ellipsis to_expand'
			},
			headerAttributes: {
				'class': 'mw100'
			},
			hidden: prefColumns.description.hidden,
			width: prefColumns.description.width
		}, {
			field: 'externalIdentifiers',
			title: lang.assethealth.EXTERNAL_TYPE,
			sortable: true,
			menu: false,
			filterable: true,
			lockable: false,
			attributes: {
			'class': 'tooltip ellipsis to_expand'
			},
			headerAttributes: {
				'class': 'mw100'
			},
			hidden: prefColumns.externalIdentifiers.hidden,
			width: prefColumns.externalIdentifiers.width
		}, {
			field: 'modified',
			title: lang.assethealth.LAST_UPDATE,
			headerAttributes: {
				'class': 'mw100'
			},
			template: '#= Renderer.browserDateRenderer(modified, Constants.DATETIME, "")#',
			filterable: {
				ui: function (element) {
					element.kendoDateTimePicker({
						format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
						timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat)
					});
				}
			},
			sortable: true,
			lockable: false,
			hidden: prefColumns.modified.hidden,
			width: prefColumns.modified.width,
			attributes: {
				'class': 'tooltip ellipsis to_expand'
			}
		}, {
			field: 'information',
			title: lang.INFORMATION,
			sortable: true,
			filterable: true,
			lockable: false,
			template: '#=information ? information : "" #',
			hidden: prefColumns.information.hidden,
			width: prefColumns.information.width,
			attributes: {
				'class': 'tooltip ellipsis to_expand'
			},
		});

		columns.forEach((column, index) => {
			let field = column.field;

			if (!prefColumns[field]) {
				prefColumns[field] = {};
			}

			if (prefColumns[field].index === undefined) {
				prefColumns[field].index = index;
			}
		});

		columns.sort((a, b) => {
			return prefColumns[a.field].index - prefColumns[b.field].index;
		});

		//console.log('columns', columns);

		return columns;
	},

	getIndicatorByIndex: function (healthIndex) {
		if (healthIndex <= -1)
			return '6';
		if (healthIndex < 25)
			return '3';
		if (healthIndex < 50)
			return '2';
		if (healthIndex < 75)
			return '1';
		if (healthIndex <= 100)
			return '0';

		//console.log('Health index is out of range!');
		return null;
	},

	indicatorToState(indicator) {
		switch (indicator) {
			case '0':
				return 'ACTIVE';
			case '1':
				return 'MINOR';
			case '2':
				return 'WARNING';
			case '3':
				return 'ERROR';
			default:
				return 'INVALID'
		}
	},

	parseData: function (data) {
		for (var i = 0, length = data.length; i < length; i++) {
			data[i].isAgentAlive = data[i].agentState === 'OK';
			if (data[i].ownedReasons || data[i].totalReasons) {
				data[i].reasonsPercent = (data[i].ownedReasons / data[i].totalReasons) * 100;
			} else {
				data[i].reasonsPercent = 100;
			}
			if (data[i].asetGroupNames && data[i].asetGroupNames.length) {
				data[i].Profiles = data[i].asetGroupNames.join(', ');
			} else {
				data[i].Profiles = ' ';
			}
			data[i].targets = data[i].targets.join(', ');

			data[i].assetIndicator = this.getIndicatorByIndex(data[i].assetHI);
			if (data[i].assetIndicator === '6')
				data[i].hasData = false;

			//assetHI can be 0 and -1 and we need to ignore them when sorting
			if (data[i].assetHI > -1) {
				data[i].assetIndicatorSorter = data[i].assetHI;
			} else {
				data[i].assetIndicatorSorter = '-1';
			}
			data[i].customFilter = data[i].assetIndicator;
			/*if (data[i].monitorDataUnavailable) {
				data[i].agentState = 'ERROR';
				data[i].customFilter = '-1';
			}*/
			if (data[i].agentState === 'WARNING') {
				data[i].assetIndicatorSorter = data[i].assetHI;
				data[i].customFilter = '-2';
			} else if (data[i].agentState === 'ERROR') {
				data[i].assetIndicatorSorter = data[i].assetHI;
				data[i].customFilter = '-1';
			} else if (data[i].agentState === 'MAINTENANCE') {
				data[i].assetIndicatorSorter = data[i].assetHI;
				data[i].customFilter = '-3';
			}

			if (data[i].monitors && data[i].monitors.length) {
				for (var j = 0; j < data[i].monitors.length; j++) {
					var currentMonitorType = data[i].monitors[j].type;
					data[i][currentMonitorType] = Utils.getHealthIndicatorStatus(data[i].monitors[j].healthIndex);
				}
			}
		}
		return data;
	}
};

export default AssetColumns;
