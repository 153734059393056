import {getWidgetDescription} from "controls/designer/features/widgets/allWidgets";
import {newGuid} from "tools/guid";

export async function convertServiceBoardToDashboard(dashboard, dashboardDesigner){
	dashboard.tag = dashboard.tag + ' (converted)';
	let dashboardSettings = dashboardDesigner.designer.dashboardSettings;
	dashboardSettings.showWidgetHeader = dashboard.headers;
	dashboardSettings.widgetWidth = dashboard.widgetWidth;
	dashboardSettings.widgetHeight = dashboard.widgetHeight;

	const {designer} = dashboardDesigner;
	const {graph} = designer;

	graph.background = dashboard.backgroundColor;

	const widgets = JSON.parse(dashboard.content);
	for(const widget of widgets){
		let widgetPosition = null;


		if(dashboard.layout == 'grid') {
			widgetPosition = {
				x: (dashboard.widgetWidth + 10) * (widget.col - 1),
				y: (dashboard.widgetHeight + 10) * (widget.row - 1),
				width: dashboard.widgetWidth * widget.size_x + ((widget.size_x - 1) * 10),
				height: dashboard.widgetHeight * widget.size_y + ((widget.size_y - 1) * 10)
			}
		}else {
			widgetPosition = {
				x: widget.widget.left,
				y: widget.widget.top,
				width: widget.widget.width,
				height: widget.widget.height
			}
		}

		if(widget.widget.type == 'custom_image'){
			graph.insertVertex(designer.graph.getDefaultParent(), null, null,
				widgetPosition.x, widgetPosition.y, widgetPosition.width, widgetPosition.height,
				"shape=image;html=1;verticalLabelPosition=bottom;labelBackgroundColor=#ffffff;" +
				"verticalAlign=top;imageAspect=0;image=" + widget.widget.configuration.imageId + ";imageContentType=image;"
			);
		}else {

			const widgetType = legacyTypeToDashboard[widget.widget.type];
			if (widgetType == null) {
				console.error(widget.widget.type + ' does not have a converter');
				continue;
			}

			const widgetDescription = await getWidgetDescription(widgetType);

			let widgetConfig = {
				...widget.widget,
				type: widgetType
			}

			if (!widgetDescription.legacyForm) {
				widgetConfig = {
					...widget.widget.configuration,
					type: widgetType,
					title: widget.widget.title,
					id: widget.widget.id,
				}
			}

			const cellValue = mxUtils.createUserObject({
				config: JSON.stringify(widgetConfig),
				type: 'widget',
				disableDataSourceSettings: true
			});

			graph.insertVertex(designer.graph.getDefaultParent(), null, cellValue,
				widgetPosition.x, widgetPosition.y, widgetPosition.width, widgetPosition.height,
				"shape=htmlContainerShape;containerId=" + newGuid() + ";strokeColor=#E6E6E6"
			);
		}
	}
}



const legacyTypeToDashboard = {
	'application_preview': 'application-preview',
	'application_state': 'application-state',
	'smpreview': 'service-preview',
	'summary': 'service-summary',
	'history': 'service-history',
	'services': 'service-selective',
	'grid_service': 'service-grid',
	'bar_ss': 'service-state',
	'metrics': 'service-single-graph',
	'multigraph': 'service-multi-graph',
	'gauge': 'service-gauge',
	'single_graph': 'metric-single-graph',
	'multi_graph_asset': 'metric-multi-graph-asset',
	'multi_graph_assetgroup': 'metric-multi-graph-asset-group',
	'graph_gauge': 'metric-gauge',
	'stacked_chart': 'metric-stacked-chart',
	'custom_metric': 'metric-custom',
	'asset_metrics': 'health-single-graph',
	'asset_multigraph': 'health-multi-graph',
	'asset_gauge': 'health-gauge',
	'grid_asset': 'asset-grid',
	'asset_console': 'asset-console',
	'bar_sh': 'asset-state',
	'sla': 'sla',
	'sla_history': 'sla-history',
	'bar_sla': 'sla-state',
	'sla_time': 'sla-time',
	'grid_sla': 'sla-grid',
	'kpi_metrics': 'kpi-single-graph',
	'kpi_multigraph': 'kpi-multi-graph',
	'kpi_gauge': 'kpi-gauge',
	'kpi_history': 'kpi-history',
	'grid_summary': 'summary-grid',
	'grid_reasons': 'reasons-grid',
	'grid_incidents': 'incidents-grid',
	'grid_event_summary': 'event-summary-grid',
	'custom_text': 'text',
	'custom_time': 'time',
	'custom_webPage': 'web-page',
	'service_presentation': 'dashboard'
}

