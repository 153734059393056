import ReactDOM from 'react-dom';
import React from 'react';
import produce from "immer";

import {TimeSettings} from "./timeSettings";
import {createLink, createObjectLink} from "core/react/links";


export {TimeSettingsWrapper, TimeSettingsWrapper as default}

class TimeSettingsWrapper {

	constructor(config) {
		Object.assign(this, config);

		this.value = {
			calendarSchedule: this.value,
			displayUnit: this.displayUnit
		}

		this.valueLink = createObjectLink(() => this.value, value => {
			this.value = value;
			this.change(value);
			this.initComponent()
		});

		this.initComponent();
	}

	initComponent() {
		ReactDOM.render(<TimeSettings valueLink={this.valueLink}
		                              minInterval={this.minInterval}
		                              minUnit={this.minUnit}
		                              invalid={this.invalid}
		/>, $(this.appendToElement).get(0));
	}
	//
	// onChange = (value, key) => {
	// 	if (key === 'unit') {
	// 		this.displayUnit = value;
	// 	} else {
	// 		this.value = value;
	// 	}
	// 	this.change(value, key);
	// 	this.initComponent();
	// }
}
