import React from "react";
import MultiSelect from "controls/react/form/multiSelect";
import DropDownList from "controls/react/kendoWrappers/dropDownList";
import translator from "core/localization/localization";
import classnames from 'classnames';
import ModalWindow from "controls/modalWindow";
import AssetForm from "areas/administration/assetForm";
import AuthenticationForm from "areas/administration/authenticationForm";

const i = translator({
    "Select...": {
        "no": "Velg..."
    }
});

class MultiSelectComponent extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        const {data} = this.props;

        if (data && data.length) {
            this.setData();
        }
    }

    componentDidUpdate(prevProps) {
        const {data} = this.props;

        if (data && data.length && prevProps.data !== data) {
            this.setData();
        }
    }

    setData() {
        this.setState({
            data: this.props.data.map((i) => i.value)
        })
    }

    render() {
        const {input: {onChange, value}, disabled} = this.props;
        const data = this.state.data || [];

        return <MultiSelect data={data} value={value} onChange={onChange} enabled={!disabled} />;
    }
}

export default class CommonDropDown extends React.PureComponent {
    render() {
        const {property} = this.props;

		const {input: {value}, data} = this.props;

		if (property.get('type') === 'CESelectionMulti') {
            return <MultiSelectComponent {...this.props} />;
        }

        switch(property.get('id')) {
            case 'assetId':
                return <AssetComponent {...this.props}/>;
            case 'targetId':
                return <TargetComponent  {...this.props}/>;
            case 'authId':
                return <AuthComponent {...this.props} />;
            default:
                return <CommonComponent {...this.props}/>;
        }
    }
}

class CommonComponent extends React.PureComponent {
    config = {
        dataTextField: 'text',
        dataValueField: 'value',
        autoBind: true
    };
    wrapperRef = React.createRef();

    state = {};

    getDataSource() {
        let {data} = this.props;

        if (data && data[0]) {
            if (typeof data[0].value === 'number') {
                this.isNumber = true;
                data = data.map((i) => {
                    return {
                        ...i,
                        value: '' + i.value
                    }
                })
            }
        }

        return data || []
    }

    getValue() {
        const {input: {value}} = this.props;
        return '' + value;
    }

    componentDidMount() {
        const {data} = this.props;

        if (data) {
            this.setDataAndValue();
        }

		this.updateWorkInProgress();
    }

    componentDidUpdate(prevProps) {
        const {data} = this.props;

        if (data && prevProps.data !== data) {
            this.setDataAndValue();
        }
        else {
            this.setState({ value: this.getValue() });
        }

        if (prevProps.workInProgress !== this.props.workInProgress) {
			this.updateWorkInProgress();
		}
    }

    emitOnChange(value) {
        const {input: {onChange}} = this.props;

        if (this.isNumber) {
            onChange(+value);
        }
        else {
            onChange(value);
        }
    }

    setDataAndValue() {
        const params = {};

        params.dataSource = this.getDataSource();

        const value = this.getValue();

        if (value) {
            params.value = value;
        }

        this.setState(params);

        if (!value) {
            this.setTheOnlyValue();
        }
    }

    setTheOnlyValue() {
        const {onInfoChanged, targetValue} = this.props;
        const value = this.getValue();

        if (value) {
            return;
        }

        const data = this.getDataSource();

        if (data.length !== 1) {
            return;
        }

        this.emitOnChange(data[0].value);
        onInfoChanged(data[0]);
    }

	updateWorkInProgress() {
		kendo.ui.progress($(this.wrapperRef.current), this.props.workInProgress);
	}

    onChange = (value) => {
        const {meta: {touched}} = this.props;

        if (!touched) {
            return this.emitOnChange(value);
        }

        let found = false;

        for (const item of this.getDataSource()) {
            if (item.value == value) {
                found = true;
                break;
            }
        }

        if (found) {
            this.emitOnChange(value);
        }
    }

    render() {
        const {meta: {invalid}, onInfoChanged, disabled, showAdd, onAdd, showFieldValidation, fieldInError} = this.props;

        const {value, dataSource} = this.state;

        return <DropDownList
            autoBind={true}
            value={dataSource && value}
            dataSource={dataSource}
            wrapperRef={this.wrapperRef}
            config={this.config}
            combo={true}
            onChange={this.onChange}
            containerClass={classnames({invalid: (invalid || fieldInError) && showFieldValidation})}
            showAdd={showAdd}
            onAdd={onAdd}
            onInfoChanged={onInfoChanged}
            disabled={disabled} />
    }
}

class AssetComponent extends React.PureComponent {
    onAdd = () => {
        const {assetAccountId, systemType, accountId} = this.props;

        const sqAssetWindow = new ModalWindow({
            title: lang.account.ASSET_CREATE,
            width: 400,
            minHeight: 567,
            height: 'auto',
            url: 'include/Administration/AssetForm.jsp',
            refresh: function () {
                new AssetForm({
                    id: null,
                    topAccountId: accountId,
                    accountId: assetAccountId,
					systemType: systemType,
					mode: 'create'
                });
            }
        });
        sqAssetWindow.open();
    };

    render() {
        const {disabled} = this.props;

        return <CommonComponent {...this.props} showAdd={!disabled} onAdd={this.onAdd}/>
    }
}

class TargetComponent extends React.PureComponent {
    onAdd = () => {
        if (!this.props.assetId) {
            return;
        }

        const {assetAccountId, systemType, accountId} = this.props;

        const sqAssetWindow = new ModalWindow({
            title: lang.account.ASSET_EDIT,
            width: 430,
            minHeight: 567,
            height: 'auto',
            url: 'include/Administration/AssetForm.jsp',
            refresh: () => {
                new AssetForm({
                    id: this.props.assetId,
                    topAccountId: accountId,
                    accountId: assetAccountId,
					systemType: systemType,
                    mode: 'update'
                });
            }
        });
        sqAssetWindow.open();
    };

    render() {
        return <CommonComponent {...this.props} showAdd={true} onAdd={this.onAdd}/>;
    }
}

class AuthComponent extends React.PureComponent {
    onAdd = () => {
        const authWindow = new ModalWindow({
            title: lang.CREATE,
            width: 400,
            minHeight: 410,
            height: 'auto',
            url: 'include/Administration/AuthenticationForm.jsp',
            refresh: () => {
                new AuthenticationForm({
                    id: null,
                    isFromDesigner: true,
                    designerAuthType: this.props.authTypes,
                    mode: 'create'
                });
            },
            onClose: () => {
                this.props.reloadData();
            }
        });
        authWindow.open();
    };

    render() {
        return <CommonComponent {...this.props} showAdd={true} onAdd={this.onAdd}/>;
    }
}
