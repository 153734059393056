import {Application, LocalEventsManager, Cookies, RemoteEventsManager} from 'core';
import {
	CustomNotification, Dependencies,
	EventsToolbar,
	GridMenu,
	ModalView,
	ModalWindow,
	MultiToggle,
	PreferencesPanel,
	Dialog
} from 'controls';
import {Utils, UserPrefs, State, Api} from 'tools';
import Settings from 'settings';
import {AssetForm} from "areas/administration/assetForm";
import {AssetMergeForm} from "areas/administration/assetMergeForm";
import {CeeViewDataSource} from "tools";
import MultiSelectGridFilter from 'controls/multiSelectGridFilter';
import {openTagsFormWindow} from "controls/tagsForm";

export let AssetsView = function (config) {
	Utils.apply(this, config);
	this.hasEvents = true;
	this.subscriberId = Utils.guid();
	this.initComponent();
};

window.Api = Api;

jQuery.extend(AssetsView.prototype, Application.prototype, {
	/**
	 * Assets view application related initializations
	 */
	initComponent: function () {
		Application.prototype.initComponent.call(this);
		this.indicatorSelector = $('.assets_view .cw_new_tag_indicator');
		this.getTags();

		this.actionNotification = new CustomNotification({
			appendToElement: '.window_area',
			status: 'success',
			style: 'top:15px; right:15px; left:15px;',
		});

		this.defaultColumns = {
			id: {
				hidden: false,
				width: 40
			},
			name: {
				hidden: false,
				width: 180
			},
			assetType: {
				hidden: false,
				width: 150
			},
			systemType: {
				hidden: false,
				width: 150
			},
			tags: {
				hidden: false,
				width: 200
			},
			description: {
				hidden: false
			}
		};
		this.loadUserPreferences();

		// update content button
		State.mainApp.contextMenu.setActionButton('ASSET');
		this.cardGridToggle = new MultiToggle({
			id: 'cw_assets_view_toggle',
			cssClass: 'cw_multi_toggle right',
			value: 'false',
			items: [{
				id: 'cw_grid_view',
				title: lang.GRID_VIEW,
				selected: false,
				cssClass: 'glyphicons justify',
				fn: this.onViewToggle,
				scope: this,
				value: 'true'
			}, {
				id: 'cw_card_view',
				title: lang.CARD_VIEW,
				selected: true,
				cssClass: 'glyphicons show-big-thumbnails',
				fn: this.onViewToggle,
				scope: this,
				value: 'false'
			}]
		});
		this.gridMenu = new GridMenu({
			renderTo: 'cw_assets_grid_menu',
			items: [{
				id: 'cw_create',
				icon: 'plus-sign',
				text: lang.CREATE,
				fn: State.mainApp.onNewAsset,
				scope: State.mainApp,
				role: 'ASSET_CREATE'
			}, {
				id: 'cw_delete',
				icon: 'bin',
				text: lang.DELETE,
				fn: this.onAssetDelete,
				scope: this,
				disabled: true,
				role: 'ASSET_DELETE'
			}, {
				id: 'cw_set_tags',
				icon: 'tag',
				text: lang.SET_TAGS,
				fn: $.proxy(function () {
					return openTagsFormWindow('asset', this);
				}, this),
				scope: this,
				disabled: true
			}, {
				id: 'cw_merge_assets',
				icon: 'git-merge',
				text: lang.MERGE_ASSETS,
				fn: this.onMergeAssets,
				scope: this,
				disabled: true
			}]
		});
		this.removeMask();
		this.removeListeners();
		this.attachListeners();
	},
	getTags: async function () {
		let tags = await Utils.getAccountTags();
		this.filterOptions = {
			tags: tags
		};
	},
	/**
	 * Handler for the view toggle buttons click
	 * @param {Object} value The target value
	 */
	onViewToggle: function (value) {
		if (value === 'true') {
			if (this.grid && this.gridViewLoaded) {
				this.showGridView = true;
				$('#cw_account_assets').addClass('hide');
				$('#cw_assets_grid').removeClass('hide');
				this.grid.refresh();
			} else {
				this.initGridView();
				this.grid.refresh();
			}
		} else {
			$('#cw_assets_grid').addClass('hide');
			if (this.innerView) {
				this.showGridView = false;
				$('#cw_account_assets').removeClass('hide');
				//$('#cw_filter_reset').addClass('inactive');
			} else {
				$('#cw_assets_grid').addClass('hide');
				this.initCardView();
			}
		}
	},
	/**
	 * Removes the listeners for the form's buttons
	 */
	removeListeners: function () {
		$('#assets_grid').off();
		this.onAssetSavedHandler = $.proxy(this.onAssetSaved, this);
		LocalEventsManager.unbind('assetsaved', this.onAssetSavedHandler);
	},
	/**
	 * Attaches the listeners for the form's buttons
	 */
	attachListeners: function () {
		$('#assets_grid').on('click', '.cw_grid_check', $.proxy(this.onCheck, this));
		$('#assets_grid').on('click', '.cw_grid_check_all', $.proxy(this.onCheckAll, this));
		$('#assets_grid').on('click', '.cw_grid_link', $.proxy(this.onAssetNameClick, this));
		LocalEventsManager.bind('assetsaved', this.onAssetSavedHandler);
	},
	/**
	 * Init card component
	 */
	initCardView: function () {
		var updateClass = State.mainApp.session.hasRole('ASSET_UPDATE') ? '' : ' hide';
		var deleteClass = State.mainApp.session.hasRole('ASSET_DELETE') ? '' : ' hide';
		$('#asset_group_name').hide();
		var template = '<li><div class="cw_card" id="${id}">';
		template += '<div class="cw_card_avatar">';
		if (State.mainApp.session.hasRole("ASSET_READ")) {
			template += '#if (Utils.isGuid(imageId)) {#';
			template += '<div class="cw_avatar cw_avatar_asset no_bg">';
			template += '<img src="${Api.images.urls.image(imageId)}" />';
			template += '</div>';
			template += '#} else if(imageId) { #';
			template += '<div class="cw_avatar cw_avatar_asset no_bg"><span class="glyphicons ${imageId}"></span></div>';
			template += '#} else {#';
			template += '<div class="cw_avatar cw_avatar_asset"></div>';
			template += '#}#';
		} else {
			template += '<div class="cw_avatar cw_avatar_asset"></div>';
		}
		template += '</div>';
		template += '<div class="cw_card_content"><h2 class="card_title"><strong>' + lang.NAME + ':</strong> ${name} </h2>';
		if (State.mainApp.session.hasRole("ASSET_READ")) {
			template += '<p class="card_extra card4"><strong>' + lang.DESCRIPTION + ':</strong> #if(description){# ${description} #}# </p>';
			template += '<div class="cw_card_bottom clearfix">';
			template += '<div class="left w50"><strong>' + lang.TYPE + ':</strong> #if(assetType){# ${assetType} #}# </div>';
			template += '<div class="right w50"><strong>' + lang.account.SYSTEM_TYPE + ':</strong> #if(systemType){# ${systemType} #}# </div>';
		}
		template += '</div></div><div class="cw_card_options"><span class="glyphicons bin ';
		template += deleteClass;
		template += '"></span><span class="glyphicons pencil ';
		template += updateClass;
		template += '"></span></div></li>';

		this.showGridView = false;
		$('#cw_assets_view_toggle').find('li').last().addClass('is_selected');

		var oThis = this;
		this.innerView = new ModalView({
			autoLoad: true,
			createButtonText: lang.account.ASSET_NEW,
			viewDataSource: this.dataSource,
			template: template,
			id: 'cw_account_assets',
			cssClass: 'assets_ul',
			title: lang.ASSETS,
			pager: false,
			onCreateButton: $.proxy(function (e) {
				if (State.mainApp.session.hasRole('ASSET_CREATE')) {
					var modalWindow = new ModalWindow({
						title: lang.account.ASSET_CREATE,
						width: 400,
						minHeight: 667,
						height: 'auto',
						url: 'include/Administration/AssetForm.jsp',
						refresh: function () {
							new AssetForm({
								id: null,
								groupId: null,
								mode: 'create'
							});
						}
					});
					modalWindow.open();
				}
			}, this),
			onEditButton: function (e) {
				if (State.mainApp.session.hasRole('ASSET_UPDATE') || State.mainApp.session.hasRole('ASSET_READ')) {
					var assetId = e.currentTarget.parentNode.parentNode.id;
					var modalWindow = new ModalWindow({
						title: lang.account.ASSET_DETAILS,
						width: 400,
						minHeight: 667,
						height: 'auto',
						url: 'include/Administration/AssetForm.jsp',
						onClose: function () {
							RemoteEventsManager.discard(assetId);
						},
						refresh: function () {
							new AssetForm({
								id: assetId,
								groupId: null,
								mode: 'update'
							});
						}
					});
					modalWindow.open();
				}
			},
			onDeleteButton: function (e) {
				if (State.mainApp.session.hasRole('ASSET_DELETE')) {
					var assetId = e.currentTarget.parentNode.parentNode.id;
					var record = this.viewDataSource.get(assetId);
					this.assetAccountId = record.accountId || Cookies.CeesoftCurrentAccountId;
					this.selectedAssetId = assetId;
					var assetName = record.name;
					var dependenciesUrl = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/assets/' + this.selectedAssetId + '/dependencies';
					Utils.ajax(dependenciesUrl, 'GET', {}, $.proxy(function (data) {
						if (data.length) {
							oThis.openDependenciesPopup(data, assetName, assetId);
						} else {
							var deleteUrl = Settings.serverPath + 'accounts/' + this.assetAccountId + '/assets/' + assetId;
							var dialog = new Dialog({
								title: lang.INFO,
								msg: lang.account.messages.ASSET_REMOVE_CONFIRMATION,
								icon: 'ERROR',
								actionText: 'DELETE',
								fn: $.proxy(function (value, button) {
									if (button === 'ok') {
										Utils.ajax(deleteUrl, 'DELETE', {}, $.proxy(function (result) {
											if (result.success) {
												oThis.actionNotification.setOptions({
													message: lang.account.messages.ASSET_SUCCESS_DELETED,
													status: 'success'
												}).show();

												// reload data source
												this.viewDataSource.read();
											} else {
												Utils.showInfo(lang.ALERT, result.message, result.details);
											}
										}, this));
									}
								}, this)
							});
							dialog.show();
						}
					}, this));

				}
			}
		});
		setTimeout(function () {
			$('#cw_account_assets').removeClass('hide');
		}, 300);
	},
	/**
	 * Handler function for the click event on the merge assets menu item
	 */
	onMergeAssets: function (e) {
		e.stopPropagation();
		var assetsList = [];
		var selectedAssets = $('.cw_grid_check:checked').closest('tr');
		var dataSource = this.grid.dataSource;
		if (selectedAssets.length) {
			for (var i = 0; i < selectedAssets.length; i++) {
				var uid = $(selectedAssets[i]).attr('data-uid');
				var asset = dataSource.getByUid(uid);
				if (asset && asset.assetType !== 'AGENT') {
					assetsList.push({
						id: asset.id,
						name: asset.name,
						assetType: asset.assetType
					});
				}
			}
		}
		if (State.mainApp.session.hasRole('ASSET_CREATE')) {
			var modalWindow = new ModalWindow({
				title: lang.MERGE_ASSETS,
				width: 750,
				minHeight: 500,
				height: 500,
				url: 'include/Administration/AssetMergeForm.jsp',
				refresh: $.proxy(function () {
					new AssetMergeForm({
						id: 'merge_assets',
						mode: 'create',
						assetsList: assetsList
					});
				}, this)
			});
			modalWindow.open();
		} else {
			Utils.showInfo(lang.INFO, lang.messages.NO_RIGHTS);
		}
	},
	/**
	 * Creates and opens the asset dependencies window
	 * @param {Array} dependencies - The asset dependencies array
	 * @param {String} assetName The asset name
	 */
	openDependenciesPopup: function (dependencies, assetName, assetId) {
		if (this.dependencyWindow) {
			this.dependenciesWindow.close();
		}
		this.dependenciesWindow = new ModalWindow({
			title: lang.INFO,
			width: 960,
			height: 640,
			minWidth: 220,
			actions: [],
			url: 'include/Dependencies.jsp',
			//resize: this.adjustTableHeight,
			onClose: $.proxy(function () {
				this.clearCheckboxes();
			}, this),
			refresh: $.proxy(function () {
				$('#cw_dependencies_deleteall').removeClass('hide');
				this.dependencyWindow = new Dependencies({
					accountId: this.assetAccountId || Cookies.CeesoftCurrentAccountId,
					dependencies: dependencies,
					name: assetName,
					container: '#assets_grid',
					infoTemplate: lang.account.messages.ASSET_DELETE_INFO,
					deleteAll: $.proxy(function (e) {
						var deleteUrl = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/assets/' + assetId + '/dependencies/deleteAll';
						Utils.ajax(deleteUrl, 'DELETE', {}, $.proxy(function (result) {
							if (result.success) {
								this.checkAssetDependencies();
							} else {
								Utils.showInfo(lang.ALERT, result.message, result.details);
								this.resetCheckingAssets();
							}
						}, this));
					}, this)
				});
			}, this)
		});
		this.dependenciesWindow.open();
	},
	/*
	* Handler function for clearing the checkboxes
	* */
	clearCheckboxes: function () {
		var checkAll = $('#cw_assets_grid').find('.cw_grid_check_all'),
			checkboxes = $('#cw_assets_grid').find('.cw_grid_check'),
			i, length = checkboxes.length;
		checkAll.prop('checked', false);
		for (i = 0; i < length; i++) {
			$(checkboxes[i]).prop('checked', false);
		}
	},
	/**
	 * Init grid component
	 * @param {boolean} showGrid Indicates if the grid should be shown or not. Defaults to true
	 */
	initGridView: function (showGrid) {
		var filterMessages = lang.grid.filter,
			loadGrid = true;
		this.gridMessages = {
			clear: lang.CLEAR,
			info: lang.grid.filter.SHOW_ITEMS,
			filter: lang.FILTER
		};
		if (showGrid === false) {
			loadGrid = false;
			this.gridViewLoaded = false;
		} else {
			this.gridViewLoaded = true;
		}
		if (loadGrid) {
			this.showGridView = true;
			$('#cw_assets_view_toggle').find('li').first().addClass('is_selected');
			$('#cw_filter_reset').removeClass('hide');
			$('#cw_account_assets').addClass('hide');
			$('#cw_assets_grid').removeClass('hide');
		}

		var assetsColumns = this.assetsColumns || JSON.parse(UserPrefs.get('assetsColumns')) || this.defaultColumns;
		assetsColumns = Utils.completeColumns(assetsColumns, this.defaultColumns);

		this.grid = $('#assets_grid').kendoCustomGrid({
			dataSource: this.dataSource,
			resizable: true,
			reorderable: true,
			selectable: 'row',
			autoBind: true,
			sortable: {
				mode: 'multiple',
				allowUnsort: true
			},
			filterable: {
				extra: false,
				operators: {
					string: {
						startswith: filterMessages.STARTS_WITH,
						neq: filterMessages.NEQ,
						eq: filterMessages.EQ
					}
				},
				messages: this.gridMessages
			},
			columns: Utils.rearrangeColumns([{
				field: 'id',
				title: lang.SELECTOR,
				sortable: false,
				filterable: false,
				menu: false,
				template: '<input type="checkbox" class="cw_grid_check" data-id="#= id #" />',
				headerTemplate: '<input type="checkbox" class="cw_grid_check_all" />',
				headerAttributes: {
					'class': 'text_center'
				},
				attributes: {
					'class': 'text_center'
				},
				hidden: assetsColumns.id.hidden,
				width: assetsColumns.id.width
			}, {
				field: 'name',
				title: lang.NAME,
				sortable: true,
				filterable: true,
				template: '<a class="cw_grid_link ellipsis to_expand" data-id="#= id #"> #= name #</a>',
				hidden: assetsColumns.name.hidden,
				width: assetsColumns.name.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'assetType',
				title: lang.account.ASSET_TYPE,
				sortable: true,
				filterable: true,
				hidden: assetsColumns.assetType.hidden,
				width: assetsColumns.assetType.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'systemType',
				title: lang.account.SYSTEM_TYPE,
				sortable: true,
				filterable: true,
				hidden: assetsColumns.systemType.hidden,
				width: assetsColumns.systemType.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'tags',
				title: lang.TAGS,
				sortable: true,
				filterable: {
					ui: $.proxy(function (element) {
						let multiselect = new MultiSelectGridFilter({
							element: element,
							field: 'tags',
							grid: this.grid,
							itemTemplate: '#=data.text#',
							tagTemplate: '#=data.text#',
							dataSource: this.filterOptions.tags,
							static: true
						});
					}, this),
					messages: this.gridMessages,
					extra: false,
					operators: {
						string: {
							eq: filterMessages.EQ,
							neq: filterMessages.NEQ
						}
					}
				},
				hidden: assetsColumns.tags.hidden,
				width: assetsColumns.tags.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'description',
				title: lang.DESCRIPTION,
				sortable: false,
				filterable: true,
				template: '#= description #',
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				},
				hidden: assetsColumns.description.hidden,
				width: assetsColumns.description.width
			}], assetsColumns),
			columnMenu: true,
			dataBound: $.proxy(this.onDataBound, this),
			change: $.proxy(this.onRowExpand, this)
		}).data('kendoCustomGrid')
		// Add Kendo tooltip to the header of the columns
		Utils.gridColumnHeaderTooltip(this.grid);
		this.adjustSectionHeight();
		this.grid.thead.find("[data-field='id']>.k-header-column-menu").remove();
		var searchValue = UserPrefs.get('defaultFsView') ? '' : (UserPrefs.get('assetsSearchPhrase') || '');
		this.gridFilterPanel = new PreferencesPanel({
			renderTo: 'cw_assets_filters',
			grid: this.grid,
			modulePrefName: 'AdministrationAssets',
			defaultPrefViewKey: 'defaultFsView',
			prefListKey: 'assetsFSViews',
			userPref: this.userPref,
			onRemove: $.proxy(this.saveUserPreferences, this),
			searchFields: ['name', 'systemType', 'assetType', 'description'],
			searchValue: searchValue,
			defaultColumns: this.defaultColumns,

		});
	},
	/**
	 * Handler for the service checkbox click
	 * @param {Object} e The click event
	 */
	onCheck: function (e) {
		e.stopPropagation();
		var checkboxes = $('.cw_grid_check');
		var assets = [];
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			if ($(checkboxes[i]).is(':checked')) {
				assets.push($(checkboxes[i]).data('id'));
			}
		}
		if (assets.length) {
			this.gridMenu.enableItem('cw_delete');
			this.gridMenu.enableItem('cw_merge_assets');
			if (assets.length === 1) {
				this.gridMenu.enableItem('cw_update');
			} else {
				this.gridMenu.disableItem('cw_update');
			}
			this.gridMenu.enableItem('cw_set_tags');
		} else {
			this.gridMenu.disableItem('cw_delete');
			this.gridMenu.disableItem('cw_update');
			this.gridMenu.disableItem('cw_set_tags');
			this.gridMenu.disableItem('cw_merge_assets');
		}
	},
	/**
	 * Handler for the check all checkbox click
	 * @param {Object} e The click event
	 */
	onCheckAll: function (e) {
		var checkAll = $('.cw_grid_check_all').is(':checked');
		var checkboxes = $('.cw_grid_check');
		var i, length = checkboxes.length;
		for (i = 0; i < length; i++) {
			$(checkboxes[i]).prop('checked', checkAll);
		}
		if (checkAll && checkboxes.length) {
			this.gridMenu.enableItem('cw_delete');
			this.gridMenu.enableItem('cw_merge_assets');
			if (checkboxes.length === 1) {
				this.gridMenu.enableItem('cw_update');
			} else {
				this.gridMenu.disableItem('cw_update');
			}
		} else {
			this.gridMenu.disableItem('cw_delete');
			this.gridMenu.disableItem('cw_merge_assets');
			this.gridMenu.disableItem('cw_update');
		}
	},
	/**
	 * Handler function for the change(select) event on the grid
	 */
	onRowExpand: function () {
		var selectedRow = this.grid.select();
		var myRow = selectedRow[0];
		var messageEl = $(myRow).find('.to_expand');
		if ($(messageEl).hasClass('cw_message_expanded')) {
			$(messageEl).removeClass('cw_message_expanded').addClass('ellipsis');
		} else {
			$('#assets_grid').find('.k-grid-content').find('td.cw_message_expanded').removeClass('cw_message_expanded').addClass('ellipsis');
			$(messageEl).addClass('cw_message_expanded').removeClass('ellipsis');
		}
	},
	onDataBound: function () {
		$('#assets_grid').find('.k-grid-content').kendoTooltip({
			filter: "td.tooltip:not(:empty)",
			content: function (event) {
				return event.target.text().split("\n").join("<br />");
			}
		});
	},
	/**
	 * Handler for the delete button click
	 * @param {Object} e The click event
	 */
	onAssetDelete: function (e) {
		var assets = [];
		if (!$(e.currentTarget).hasClass('disabled')) {
			var checkboxes = $('#assets_grid').find('.cw_grid_check:checked');
			for (var i = 0, length = checkboxes.length; i < length; i++) {
				if ($(checkboxes[i]).is(':checked')) {
					assets.push($(checkboxes[i]).data('id'));
				}
			}
			this.assetsLength = assets.length;
			this.selectedAssets = assets;
			if (this.assetsLength) {
				this.assetIndex = 0;
				this.gridMenu.disableItem('cw_update');
				this.gridMenu.disableItem('cw_delete');

				var dialog = new Dialog({
					title: lang.INFO,
					msg: lang.account.messages.ASSETS_REMOVE_CONFIRMATION,
					icon: 'ERROR',
					actionText: 'DELETE',
					fn: $.proxy(function (value, button) {
						if (button === 'ok') {
							kendo.ui.progress($('#assets_grid'), true);
							this.checkAssetDependencies();
						}
					}, this),
					deleteFromGrid: this.grid
				});
				dialog.show();
			}
		}
	},
	/*
	* Handler function for checking the assset dependencies
	* */
	checkAssetDependencies: function () {
		var assetId = this.selectedAssets[this.assetIndex++], record, assetName, cb;
		if (assetId) {
			cb = $('.cw_health_summary_list').find('.cw_grid_check:checked');
			record = this.dataSource.get(assetId);

			this.assetAccountId = record.accountId || Cookies.CeesoftCurrentAccountId;
			this.selectedAssetId = assetId;
			assetName = record.name;

			var dependenciesUrl = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/assets/' + this.selectedAssetId + '/dependencies';
			Utils.ajax(dependenciesUrl, 'GET', {}, $.proxy(function (data) {
				if (data.length) {
					this.openDependenciesPopup(data, assetName, assetId);
					setTimeout(this.adjustTableHeight, 100);
				} else {
					var deleteUrl = Settings.serverPath + 'accounts/' + this.assetAccountId + '/assets/' + assetId;
					Utils.ajax(deleteUrl, 'DELETE', {}, $.proxy(function (result) {
						if (result.success) {
							this.checkAssetDependencies();
						} else {
							this.resetCheckingAssets();
							//Utils.showInfo(lang.ALERT, result.message, result.details);
							Utils.showInfo(lang.ALERT, lang.account.messages.ASSET_DELETE_TIED_AGENT, result.details);
						}
					}, this));
				}
			}, this));
		} else {
			kendo.ui.progress($('#assets_grid'), false);
			this.dataSource.read();
			if (this.dependenciesWindow) {
				this.dependenciesWindow.close();
			}
			this.actionNotification.setOptions({
				message: lang.account.messages.ASSETS_SUCCESS_DELETED,
				status: 'success'
			}).show();
		}
	},
	/**
	 * Resets the checking assets
	 */
	resetCheckingAssets: function () {
		this.selectedAssets = [];
		this.assetIndex = 0;

		kendo.ui.progress($('#assets_grid'), false);
		this.grid.dataSource.read();
	},
	/**
	 * Handler function for the click event on the asset name
	 * @param e {Object} e The click event object
	 */
	onAssetNameClick: function (e) {
		var assetId = $(e.currentTarget).data('id');
		var mode = 'view';
		if (State.mainApp.session.hasRole('ASSET_UPDATE')) {
			mode = 'update';
		}
		var modalWindow = new ModalWindow({
			title: lang.account.ASSET_DETAILS,
			width: 500,
			minHeight: 580,
			url: 'include/Administration/AssetForm.jsp',
			onClose: function () {
				if (mode === 'update') {
					RemoteEventsManager.discard(assetId);
				}
			},
			refresh: function () {
				new AssetForm({
					id: assetId,
					groupId: null,
					mode: mode
				});
			}
		});
		modalWindow.open();
		e.stopPropagation();
	},
	/**
	 * Handler function for the click event on the asset update menu item
	 * @param e {Object} e The click event object
	 */
	onAssetUpdate: function (e) {
		if (!$(e.currentTarget).hasClass('disabled')) {
			var assetId = $('.cw_grid_check:checked').attr('data-id');
			if (State.mainApp.session.hasRole('ASSET_UPDATE') || State.mainApp.session.hasRole('ASSET_READ')) {
				var modalWindow = new ModalWindow({
					title: lang.account.ASSET_DETAILS,
					width: 400,
					minHeight: 580,
					url: 'include/Administration/AssetForm.jsp?assetId=' + assetId,
					onClose: function () {
						RemoteEventsManager.discard(assetId);
					},
					refresh: function () {
						new AssetForm({
							id: assetId,
							groupId: null,
							mode: 'update'
						});
					}
				});
				modalWindow.open();
			}
		}
	},
	/**
	 * Saves user preferences
	 */
	saveUserPreferences: function () {
		var preferences = [{
			key: 'showGridView',
			value: this.showGridView
		}];
		this.saveUserPrefs({
			category: 'AdministrationAssets',
			filterPanel: this.gridFilterPanel,
			grid: this.grid,
			eventsToolbar: this.eventsToolbar,
			preferences: preferences,
			keys: {
				searchPhrase: 'assetsSearchPhrase',
				columns: 'assetsColumns',
				sort: 'assetsSort',
				filter: 'assetsFilter',
				FsViews: 'assetsFSViews'
			}
		});
	},
	/**
	 * Creates the data source for the Asset grid
	 */
	createDataSource: function () {
		this.dataSource = new CeeViewDataSource({
			transport: {
				read: {
					url: $.proxy(function () {
						var ignoreUiProperties = this.showGridView ? 'ignoreUiProperties=true&' : '';
						return Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/assets?' + ignoreUiProperties + 'dc=' + Utils.timestamp();
					}, this),
					dataType: 'json',
					contentType: 'application/json; charset=utf-8',
					type: 'GET',
					cache: false
				}
			},
			schema: {
				parse: function (data) {
					for (var i = 0, length = data.length; i < length; i++) {
						var name = data[i].name;
						if (name) {
							data[i].name = name.replace(/</g, '&lt;').replace(/>/g, '&gt;');
						}
						if (data[i].tags) {
							data[i].tags = data[i].tags.join(', ');
						}
					}
					return data;
				}
			},
			sort: this.assetsSort || [{
				field: 'name',
				dir: 'asc',
				compare: null
			}],
			filter: this.assetsFilter || []
		});
	},
	/**
	 * Loads user preferences
	 */
	loadUserPreferences: function () {
		var defaultFsView, showGridView, pref;
		UserPrefs.load('AdministrationAssets', $.proxy(function (result) {
			if (result.success) {
				this.userPref = result.data;
				showGridView = UserPrefs.get('showGridView');
				defaultFsView = UserPrefs.get('defaultFsView');

				if (Utils.isGuid(defaultFsView)) {
					pref = JSON.parse(UserPrefs.get(defaultFsView));
					pref = pref || {
						sort: [],
						filter: []
					};
					this.assetsSort = pref.sort;
					this.assetsFilter = pref.filter;
					this.assetsColumns = pref.columns;
				} else {
					if (UserPrefs.get('assetsSort')) {
						this.assetsSort = JSON.parse(UserPrefs.get('assetsSort'));
					} else {
						this.assetsSort = [{
							field: 'name',
							dir: 'asc',
							compare: null
						}];
					}
					if (UserPrefs.get('assetsFilter')) {
						this.assetsFilter = JSON.parse(UserPrefs.get('assetsFilter'));
					} else {
						this.assetsFilter = [];
					}
				}

				if (showGridView) {
					this.showGridView = showGridView === 'true' ? true : false;
				} else {
					this.showGridView = true;
				}
				this.cardGridToggle.select(this.showGridView ? 'cw_grid_view' : 'cw_card_view');

				this.createDataSource();
				var eventStatus = JSON.parse(UserPrefs.get('eventsStatus')) || {
					startFrom: 0,
					playing: true
				};
				this.eventsToolbar = new EventsToolbar({
					renderTo: $('#cw_assets_events_toolbar'),
					callBackFn: $.proxy(this.onEventsTimeout, this),
					subscriberId: this.subscriberId,
					startFrom: eventStatus.startFrom,
					playing: eventStatus.playing
				});
				if (this.showGridView) {
					this.initGridView();
				} else {
					this.initCardView();
					this.initGridView(false);
				}
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},
	/**
	 * Handler function for the asset saved event
	 */
	onAssetSaved: function () {
		this.dataSource.read();
	},
	/**
	 * Method by Andy
	 */
	adjustSectionHeight: function () {
		var section = $('#cw_assets_grid');
		var sectionHeight = section.height();
		section.find('.cw_section_content').css('height', sectionHeight);
		section.find('.k-grid-content').css('height', sectionHeight - 40);
	},
	/*
	 * Handler function for reloading sections which are using tags
	 * */
	reloadByTags: function () {
		this.grid.dataSource.read();
	},
	/**
	 * Subscribes to the events
	 */
	subscribe: function () {
		var subscriptionObj = [{
			eventType: 'Administration',
			//Entity types: ACCOUNT, AGENT, ASSET, ASSET_GROUP, AUTHENTICATION, MONITOR, NOTIFICATION_POLICY, TEAM, TEAM_CALENDAR, USER
			entityType: 'ASSET',
			//Optional. If entityId is specified, only events for this entity are received
			//entityId:"1b8a6722-97a5-4e85-adf1-fcd2c958ebe5"
			//Optional. If accountId is specified, events for all entities of type "entityType" in this account are received
			accountId: Cookies.CeesoftCurrentAccountId
		}];
		this.isDataSourceSubscribed = true;
		RemoteEventsManager.subscribe(this.subscriberId, subscriptionObj);
	},
	/*
	* Handler function for reloading datasource
	* */
	onEventsTimeout: function (events) {
		this.dataSource.read();
	},
	/**
	 * Destroy
	 */
	destroy: function () {
		this.saveUserPreferences();
		if (this.eventsToolbar) {
			this.eventsToolbar.destroy();
		}
		Application.prototype.destroy.call(this);
	}
});
