export function getDescriptions(){
	return [
		{
			url: '/users/:id/profile',
			getModuleImport: () => import('./legacyLoader'),
			params: {
				tab: 'profile'
			},
			react: true
		}
	];
}

export class UserRouter {
	static profile(id) {
		return `/users/${id}/profile`;
	}
	static ruleset(id) {
		return `/users/${id}/ruleset`;
	}
	static channels(id) {
		return `/users/${id}/ruleset/channels`;
	}
	static policy(id) {
		return `/users/${id}/ruleset/policy`;
	}
	static notification(type, id, index, triggerType) {
		return `/${type}/${id}/notifications/rulesets/${index}?triggerType=${triggerType}`
	}
}
