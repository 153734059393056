import {HealthDataHolder} from "framework/entities/healthData";
import {Geotag} from "framework/entities/geotag";
import {TimeSelection, TimeSelectionString} from "controls/react/form/timePeriodSelector";

export enum EventType {
	Geolocation = 'Geolocation',
	AssetHealth = 'AssetHealth',
	CostBudget = 'CostBudget',
	CostReport = 'CostReport'
}

export class Event {
	wId: string;
	eventId: string;
	eventType: EventType
	time: number;
}

export class AssetHealthEvent extends Event{
	assetHealth: {
		assetId: string;
	} & HealthDataHolder
	//those are not all fields, i've added only ones I used

	static is(e: Event): e is AssetHealthEvent{
		return e.eventType == EventType.AssetHealth;
	}

	static subscription(assetIds: string[]){
		return {
			eventType: EventType.AssetHealth,
			assetIds: assetIds
		}
	}
}

export class GeolocationEvent extends Event{
	assetId: string;
	geotag: Geotag;

	static is(e: Event): e is GeolocationEvent{
		return e.eventType == EventType.Geolocation;
	}

	static subscription(assetIds: string[]){
		return {
			eventType: EventType.Geolocation,
			assetIds: assetIds
		}
	}
}

export class CostBudgetEvent extends Event{
	modelId: string;

	static is(e: Event): e is CostBudgetEvent{
		return e.eventType == EventType.CostBudget;
	}

	static subscriptionByBudget(budgetId: string, itemIds: string[]){
		return {
			eventType: EventType.CostBudget,
			budgetId: budgetId,
			itemIds: itemIds
		}
	}

	static subscriptionByModel(modelId: string, itemIds: string[] ){
		return {
			eventType: EventType.CostBudget,
			modelId: modelId,
			itemIds: itemIds
		}
	}
}

export class CostReportEvent extends Event{
	modelId: string;
	startDate: string;
	endDate: string;

	static is(e: Event): e is CostReportEvent{
		return e.eventType == EventType.CostReport;
	}

	static subscription(modelId: string, timePeriod: TimeSelectionString ){
		return {
			eventType: EventType.CostReport,
			modelId: modelId,
			timePeriod: timePeriod
		}
	}
}
