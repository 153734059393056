import {CustomNotification, GridMenu, PlayEventsButton} from "controls";
import {Application, Cookies, ErrorHandler, RemoteEventsManager} from "core";
import {CeeViewDataSource, Renderer, State, UserPrefs, Utils} from "tools";
import {Settings} from "settings";
import PreferencesPanel from 'controls/preferencesPanel';
import {getSeverityState} from 'controls/stateRenderer/severityState';

export function AssetMonitorsView(config) {
	Utils.apply(this, config);
	this.module = {
		name: 'Reasons',
		initialConfig: config
	};
    this.hasEvents = true;
	this.initComponent();
};

export {AssetMonitorsView as default}

jQuery.extend(AssetMonitorsView.prototype, Application.prototype, {
	/**
	 * Main init function
	 */
	initComponent: function () {
		Application.prototype.initComponent.call(this);

		this.init();
	},
	/*
	 * Handler function used for initialization the view
	 * */
	init: function() {
		switch (this.monitorType) {
			case 'system_monitor':
				this.serverMonitorType = 'SYSTEM';
				break;
			case 'nimsoft':
				this.serverMonitorType = 'NIMSOFT';
				break;
			case 'process':
				this.serverMonitorType = 'PROCESSES';
				break;
			case 'service':
				this.serverMonitorType = 'WINSERVICE';
				break;
			case 'external':
				this.serverMonitorType = 'EXTERNAL';
				break;
			case 'eventlog':
				this.serverMonitorType = 'WINEVENTS';
				break;
			case 'prtg':
				this.serverMonitorType = 'PRTG';
				break;
			case 'nagios':
				this.serverMonitorType = 'NAGIOS';
				break;
			case 'vmware':
				this.serverMonitorType = 'VMWARE';
				break;
			case 'hyperv':
				this.serverMonitorType = 'HYPERV';
				break;
			case 'vmm':
				this.serverMonitorType = 'VMM';
				break;
			case 'mscluster':
				this.serverMonitorType = 'MSCLUSTER';
			case 'filesearch':
				this.serverMonitorType = 'FILESEARCH';
				break;
			case 'snmp':
				this.serverMonitorType = 'SNMP';
				break;
			case 'xenserver':
				this.serverMonitorType = 'XENSERVER';
				break;
			case 'ibm':
				this.serverMonitorType = 'IBMSVC';
				break;
			case 'remotesystem':
				this.serverMonitorType = 'REMOTESYSTEM';
				break;
            case 'log':
                this.serverMonitorType = 'LOGMONITOR';
                break;
			case 'mailgtw':
				this.serverMonitorType = 'MAILGTW';
				break;
			case 'asset_status':
				this.serverMonitorType = 'all';
				break;
		}
		this.allowAssignActions = true;
		this.reasonsGrids = [];
		this.removeListeners();
		this.attachListeners();
		this.defaultColumns = {
			id: {
				hidden: false,
				width: 40
			},
			monitorHI: {
				hidden: false,
				width: 120
			},
			name: {
				hidden: false,
				width: 180
			},
			typeText: {
				hidden: false,
				width: 160
			},
			agentName: {
				hidden: false,
				width: 160
			},
			targetType: {
				hidden: false,
				width: 160
			},
			targetName: {
				hidden: false
			}
		};
		this.initKendoComponents();
		this.removeMask();
	},
	/**
	 * Removes listeners
	 */
	removeListeners: function () {
		$('#cw_monitors_list').off();
	},
	/**
	 * Attaches listeners
	 */
	attachListeners: function () {
		$('#cw_monitors_list').on('click', '.cw_grid_check', $.proxy(this.onReasonsCheck, this));
		$('#cw_monitors_list').on('click', '.cw_grid_check_all', $.proxy(this.onReasonsCheckAll, this));
		$('#cw_monitors_list').on('click', '.cw_monitor_grid_check', $.proxy(this.onMonitorsCheck, this));
		$('#cw_monitors_list').on('click', '.cw_monitor_grid_check_all', $.proxy(this.onMonitorsCheckAll, this));
		$('#cw_monitors_list').on('click', '.cw_monitor_name', $.proxy(this.onMonitorClick, this));
		$('#cw_monitors_list').on('click', '.cw_agent_name', $.proxy(this.onAgentNameClick, this));
		$('#cw_monitors_list').on('click', '.cw_target_name', $.proxy(this.onTargetNameClick, this));
	},
    /**
     * Handler for the click event on the monitor name
     * @param {Object} e The click event
     */
    onMonitorNameClick: function (e) {
        e.preventDefault();
        var target = $(e.currentTarget);

        var monitorId = target.data('id');
        var monitorType = target.data('type');

        this.openMonitorView(monitorId, monitorType, true, e);
    },
	/**
	 * Called when one or multiple events are received from the server
	 * @param {Object} data The event data
	 */
	onEvent: function (data) {
		//var reasons = data.reasons;
        // this.updateAssigned(reasons);

		this.allowAssignActions = true;
		this.monitorsDataSource.read();
	},
	/**
	 * Initializes Kendo components
	 */
	initKendoComponents: function () {
		var monitorsSort, url;
		if (UserPrefs.get('monitorsSort')) {
			monitorsSort = JSON.parse(UserPrefs.get('monitorsSort'));
		} else {
			monitorsSort = [{
				field: 'name',
				dir: 'asc',
				compare: null
			}];
		}

		if (this.serverMonitorType === 'all') {
			url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/assets/' + this.assetId + '/health/monitors';
		} else {
			url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/assets/' + this.assetId + '/health/monitors/types/' + this.serverMonitorType + '';
		}

		if (this.preferences) {
			monitorsSort = this.preferences.sort || monitorsSort;
		}

		this.monitorsDataSource = new CeeViewDataSource({
			transport: {
				read: {
					url: url,
					contentType: "application/json; charset=utf-8",
					type: "GET",
					dataType: "json",
                    cache: false
				}
			},
			sort: monitorsSort,
			error: ErrorHandler.kendoServerError
		});

		var monitorsColumns = JSON.parse(UserPrefs.get('monitorsColumns')) || this.defaultColumns;
		monitorsColumns = Utils.completeColumns(monitorsColumns, this.defaultColumns);
		var monitorTemplate = State.mainApp.session.hasRole('MONITOR_READ') ? '<a class="cw_grid_link cw_monitor_name" data-id="${id}" data-type="${type}">${name}</a>': '${name}';
		var agentTemplate = State.mainApp.session.hasRole('AGENT_READ') ? '<span class="cw_grid_link cw_agent_name" data-agentid="#=agentId#">#=agentName?agentName:""#</span>': '#=agentName?agentName:""#';
		this.monitorsGrid = $('#cw_monitors_list').kendoCustomGrid({
			autoBind: true,
			selectable: 'row',
			dataSource: this.monitorsDataSource,
			resizable: true,
			sortable: {
				mode: 'multiple',
				allowUnsort: true
			},
			columnMenu: true,
			detailInit: $.proxy(this.onMonitorRowExpand, this),
			columns: [{
				field: 'id',
				title: lang.SELECTOR,
				sortable: false,
				filterable: false,
				menu: false,
				template: '<input #= agentState.state !== "OK" ? "disabled" : ""# type="checkbox" class="cw_monitor_grid_check" data-id="${id}" data-type="${type}" />',
				headerTemplate: '<input type="checkbox" class="cw_monitor_grid_check_all" />',
				attributes: {
					'class': 'text_center'
				},
				headerAttributes: {
					'class': 'text_center'
				},
				hidden: false,
				width: monitorsColumns.id.width
			}, {
				field: 'monitorHI',
				title: lang.assethealth.SEVERITY,
				//template: "#=Renderer.monitor(monitorHI, 'system_monitor', dataUnavailable, false, 'inline_block', agentState.state)#",
                template: function (item) {
                    return Renderer.monitor({
                        monitorIndex: item.monitorHI,
                        agentState: item.agentState.state,
                        dataUnavailable: item.dataUnavailable,
                        type: 'system_monitor',
						monitorType: item.type,
                        cssClass: 'inline_block',
						monitorError: item.monitorError,
						monitorMaintenance: item.monitorMaintenance,
						monitorMaintenanceHasPriority: true,
                        monitorDataUnavailable: item.monitorDataUnavailable
                    });
                },
				attributes: {
					'class': 'text_center'
				},
				headerAttributes: {
					'class': 'text_center'
				},
				hidden: false,
				width: monitorsColumns.monitorHI.width
			}, {
				field: 'name',
				template: monitorTemplate,
				title: lang.assethealth.MONITOR_NAME,
				hidden: monitorsColumns.name.hidden,
				width: monitorsColumns.name.width
			}, {
				field: 'typeText',
				title: lang.assethealth.MONITOR_TYPE,
				hidden: monitorsColumns.typeText.hidden,
				width: monitorsColumns.typeText.width
			}, {
				field: 'agentName',
				title: lang.assethealth.AGENT_NAME,
				hidden: monitorsColumns.agentName.hidden,
				width: monitorsColumns.agentName.width,
				template: agentTemplate
			}, {
				field: 'targetType',
				title: lang.TARGET_TYPE,
				hidden: monitorsColumns.targetType.hidden,
				width: monitorsColumns.targetType.width
			}, {
				field: 'targetName',
				title: lang.assethealth.TARGET_NAME,
				hidden: monitorsColumns.targetName.hidden,
				width: monitorsColumns.targetName.width,
				template: '#= targetType === "ASSET"? \'<a class="cw_grid_link cw_target_name">\' + targetName + \'</a>\' : ""#'
			}],
			dataBound: $.proxy(this.onMonitorsDataBound, this)
		}).data('kendoCustomGrid');
		// Add Kendo tooltip to the header of the columns
		Utils.gridColumnHeaderTooltip(this.monitorsGrid);
		this.adjustSectionHeight();

		this.monitorsFilterPanel = new PreferencesPanel({
			renderTo: 'cw_monitors_filters',
			grid: this.monitorsGrid,
			searchFields: ['name', 'typeText', 'agentName', 'targetType', 'targetName'],
			hideViews: true
		});

		this.monitorsGrid.thead.find("[data-field='id']>.k-header-column-menu").remove();
		if (this.serverMonitorType === 'NIMSOFT') {
			this.gridMenu = new GridMenu({
				renderTo: 'cw_reasons_grid_menu',
				items: [{
					id: 'cw_reset_hi',
					icon: 'restart',
					text: lang.assethealth.RESET_HI,
					fn: this.onResetHIButton,
					scope: this,
					cssClass: '',
					disabled: true
				}, {
					id: 'cw_assign_in_progress',
					icon: 'binoculars',
					text: lang.summary.ASSIGN_IN_PROGRESS,
					fn: this.onAssignInProgressButton,
					scope: this,
					cssClass: '',
					disabled: true
				}, {
					id: 'cw_assign',
					icon: 'new-window-alt',
					text: lang.summary.ASSIGN,
					fn: this.onAssignButton,
					scope: this,
					cssClass: '',
					disabled: true
				}]
			});
		} else {
			this.gridMenu = new GridMenu({
				renderTo: 'cw_reasons_grid_menu',
				items: [{
					id: 'cw_reset_hi',
					icon: 'restart',
					text: lang.assethealth.RESET_HI,
					fn: this.onResetHIButton,
					scope: this,
					cssClass: '',
					disabled: true
				}]
			});
		}
		var playEventsButton = new PlayEventsButton({
			renderTo: $('#cw_reasons_play_events'),
			grid: this.monitorsGrid,
			view: this
		});
	},
	/**
	 * Handler function for the grid data bound event
	 * @param {Object} e The databound event
	 */
	onMonitorsDataBound: function (e) {
		var data = this.monitorsGrid.dataSource.data();
		if (data.length === 1) {
			this.monitorsGrid.expandRow(this.monitorsGrid.tbody.find("tr.k-master-row").first());
			setTimeout(function () {
				$('.k-detail-row').css('display', 'none');
			}, 30);

			setTimeout(function () {
				$('.k-detail-row').css('display', 'table-row');
			}, 60);
		}

		this.highlightMonitor(this.monitorsGrid);
        this.setErrorToolTip($('#cw_monitors_list'));
	},
	onMonitorRowExpand: function (e) {
		var reasonsGridPrefs = {
			sort: [],
			filter: []
		};
		var filterMessages = lang.grid.filter;
		this.expandedDivId = Utils.guid();
		var prefs = JSON.parse(UserPrefs.get('ReasonsGridPrefs'));
		var uid = e.masterRow.data('uid');
		var monitorRecord = this.monitorsDataSource.getByUid(uid)
		var monitorId = monitorRecord.id;
		var agentId = monitorRecord.agentId;
		var agentState = monitorRecord.agentState.state;
		var monitorHI = monitorRecord.monitorHI;
		if (prefs) {
			for (var i = 0, length = prefs.length; i < length; i++) {
				if (prefs[i].monitorId === monitorId) {
					reasonsGridPrefs = {
						sort: prefs[i].sort,
						filter: prefs[i].filter
					};
					break;
				}
			}
		} else {
			reasonsGridPrefs.sort = [{
				field: 'timestamp',
				dir: 'desc'
			}];
		}
		this.reasonsGrid = $('<div id="' + this.expandedDivId + '" class="cw_summary_inner_grid" data-monitorid="' + monitorId + '" data-agentstate="'+ agentState + '"></div>').appendTo(e.detailCell).kendoCustomGrid({
			dataSource: new CeeViewDataSource({
				transport: {
					read: {
						url: Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/assets/' + this.assetId + '/monitors/' + monitorId + '/reasons?agentId=' + (agentId || ''),
						contentType: 'application/json; charset=utf-8',
						type: 'GET',
						dataType: 'json',
                        cache: false
					}
				},
				sort: reasonsGridPrefs.sort,
				filter: reasonsGridPrefs.filter,
				change: $.proxy(function (e) {
					if (e.items.length === 0) {
						if ($('#' + this.expandedDivId).next().length) {
							$('#' + this.expandedDivId).next().remove();
						}
						$('#' + this.expandedDivId).addClass('hide');
						$('#' + this.expandedDivId).after('<div class="cw_reasons_status" style="height:60px"></div>');
						var message = lang.assethealth.messages.NO_REASONS;
						var agentState = $('#' + this.expandedDivId).attr('data-agentstate');
						if (agentState === 'ERROR') {
							message = lang.assethealth.messages.AGENT_DOWN;
						}

						this.statusNotification = new CustomNotification({
							message: message,
							animationTime: 0,
							appendToElement: '#' + this.expandedDivId + '+.cw_reasons_status',
							status: 'info',
							hideOnClick: false,
							style: 'position:relative;top:5px;right:0;left:0'
						}).show();
					} else {
						if ($('#' + this.expandedDivId).next().length) {
							$('#' + this.expandedDivId).next().remove();
						}
						$('#' + this.expandedDivId).removeClass('hide');
					}
				}, this),
				error: ErrorHandler.kendoServerError
			}),
			filterable: false,
			sortable: {
				mode: 'multiple',
				allowUnsort: true
			},
			selectable: 'row',
			resizable: true,
			columns: [{
				title: ' ',
				field: 'id',
				sortable: false,
				menu: false,
				attributes: {
					'class': 'text_center'
				},
				headerAttributes: {
					'class': 'text_center'
				},
				template: '#if (!assigned) {#<input type="checkbox" class="cw_grid_check" data-id="${id}" />#}#',
				headerTemplate: '<input type="checkbox" class="cw_grid_check_all" />',
				width: 40
			}, {
				field: 'severity',
				title: lang.assethealth.SEVERITY,
				//template: '#= Renderer.severity(severity)#',
				template: item => getSeverityState(item.severity),
				headerTemplate: '<span class="cw_status_indicator empty hide"></span>',
				sortable: true,
				attributes: {
					"class": "text_center"
				},
				width: 40
			}, {
				field: 'timestamp',
				title: lang.service.TIME,
				template: '#= Renderer.browserDateRenderer(timestamp, Constants.DATETIME)#',
				sortable: true,
				width: 180
			}, {
				field: 'subsystem',
				title: lang.assethealth.SUBSYSTEM,
				sortable: true,
				width: 120
			}, {
				title: lang.service.STATUS,
				field: 'assigned',
				sortable: true,
				attributes: {
					"class": "text_center"
				},
				template: '#if (assigned) {#<span class="glyphicons new-window-alt" data-id="${id}"></span>#}#',
				width: 100
			}, {
				field: 'text',
				title: lang.DESCRIPTION,
				attributes: {
					'class': 'expand ellipsis'
				},
				sortable: false

			}],
			columnMenu: true,
			change: $.proxy(this.onReasonRowExpand, this),
			dataBound: $.proxy(function (e) {
			}, this)
		}).data('kendoCustomGrid');
		// Add Kendo tooltip to the header of the columns
		Utils.gridColumnHeaderTooltip(this.reasonsGrid);
		// hide arrow indicator for check column
		$('#' + this.expandedDivId + ' .k-header-column-menu').eq(0).hide();
		if (this.serverMonitorType !== 'NIMSOFT') {
			this.reasonsGrid.hideColumn('id');
			this.reasonsGrid.hideColumn('assigned');
		}
		this.reasonsGrids.push(this.reasonsGrid);
	},
	/**
	 * Handler function for the change(select) event on the grid
	 * @param {Object} e The change event object
	 */
	onReasonRowExpand: function (e) {
		var selectedRow = $(e.sender.select());
		selectedRow.find('.expand').toggleClass('ellipsis');
	},
	/**
	 * Handler funtion for the click event on the Assign button
	 */
	onAssignButton: function () {
		var reasons = [];
		var status = $('#content_area').find('.status');
		$('.cw_grid_check').each(function (index, element) {
			var checkbox = $(this);
			if (checkbox.is(':checked')) {
				reasons.push(checkbox.data('id'));
			}
		});
		if (reasons.length) {
			this.disableActionButtons();
			this.allowAssignActions = false;
			var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/assets/' + this.assetId + '/monitors/' + this.monitorId + '/assignAlarms';
			Utils.ajax(url, 'POST', JSON.stringify(reasons), $.proxy(function (result) {
				if (result.success) {
					// this.reasonsDataSource.read();
					status.find('p').addClass('success');
					status.find('p').text(lang.account.messages.REASONS_ASSIGNED);
					status.slideDown().delay(2000).slideUp();
					this.updateLoadingAssignments(reasons);
				} else {
					status.find('p').addClass('error');
					status.find('p').text(result.message);
					status.slideDown().delay(2000).slideUp();
				}
			}, this));
		}
	},
	/**
	 * Handler funtion for the click event on the Assign button
	 */
	onAssignInProgressButton: function () {
		var reasons = [];
		var status = $('#content_area').find('.status');
		$('.cw_grid_check').each(function (index, element) {
			var checkbox = $(this);
			if (checkbox.is(':checked')) {
				reasons.push(checkbox.data('id'));
			}
		});

		if (reasons.length) {
			this.disableActionButtons();
			this.allowAssignActions = false;
			var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/assets/' + this.assetId + '/monitors/' + this.monitorId + '/assignAlarmsInProgress';
			Utils.ajax(url, 'POST', JSON.stringify(reasons), $.proxy(function (result) {
				if (result.success) {
					// this.reasonsDataSource.read();
					status.find('p').addClass('success');
					status.find('p').text(lang.account.messages.REASONS_ASSIGNED);
					status.slideDown().delay(2000).slideUp();
					this.updateLoadingAssignments(reasons);
				} else {
					status.find('p').addClass('error');
					status.find('p').text(result.message);
					status.slideDown().delay(2000).slideUp();
				}
			}, this));
		}
	},
	/**
	 * Handler funtion for the click event on the Reset health index button
	 */
	onResetHIButton: function () {
		var checkboxes = $('.cw_monitor_grid_check'), i, length = checkboxes.length, monitors = [],
			checkbox, id;
		for (i = 0; i < length; i++) {
			checkbox = $(checkboxes[i]);
			if (checkbox.is(':checked')) {
				id = checkbox.data('id');
				var found = false;
				for (var j = 0; j < monitors.length; j++) {
					if (monitors[j].monitorId === id && monitors[j].assetId === this.assetId) {
						found = true;
						break;
					}
				}
				if (!found) {
					monitors.push({
						monitorId: id,
						assetId: this.assetId
					});
				}

			}
		}

		Utils.resetMonitorsHI(monitors, function () {
			$('.cw_monitor_grid_check').prop('checked', false);
			$('.cw_monitor_grid_check_all').prop('checked', false);
		});
	},
	/**
	 * Handler for the service checkbox click
	 */
	onReasonsCheck: function (e) {
		var checkboxes = $('.cw_grid_check');
		var checked = false;
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			if ($(checkboxes[i]).is(':checked')) {
				checked = true;
				break;
			}
		}
		if (this.allowAssignActions) {
			if (checked) {
				this.enableActionButtons();
			} else {
				this.disableActionButtons();
			}
		}
	},
	/**
	 * Handler for the check all reasons checkbox click
	 * @param {Object} e The click event
	 */
	onReasonsCheckAll: function (e) {
		var checkAll = $(e.currentTarget).is(':checked');
		var checkboxes = $(e.currentTarget).closest('table').closest('td').find('.cw_grid_check');
		var i, length = checkboxes.length;
		for (i = 0; i < length; i++) {
			$(checkboxes[i]).prop('checked', checkAll);
		}
		this.onReasonsCheck();
	},
	/**
	 * Handler for the monitor checkbox click
	 */
	onMonitorsCheck: function (e) {
		var checkboxes = $('.cw_monitor_grid_check');
		var checked = false;

		for (var i = 0, length = checkboxes.length; i < length; i++) {
			if ($(checkboxes[i]).is(':checked')) {
				checked = true;
				break;
			}
		}
		if (checked) {
			this.gridMenu.enableItem('cw_reset_hi');
		} else {
			this.gridMenu.disableItem('cw_reset_hi');
		}
	},
	/**
	 * Handler for the check all monitors checkbox click
	 * @param {Object} e The click event
	 */
	onMonitorsCheckAll: function (e) {
		var checkAll = $(e.currentTarget).is(':checked');
		var checkboxes = $('#cw_monitors_list').find('.cw_monitor_grid_check:not(:disabled)');
		var i, length = checkboxes.length;

		for (i = 0; i < length; i++) {
			$(checkboxes[i]).prop('checked', checkAll);
		}
		if (checkAll && checkboxes.length) {
			this.gridMenu.enableItem('cw_reset_hi');
		} else {
			this.gridMenu.disableItem('cw_reset_hi');
		}
	},
	/**
	 * Handler for the click event on the monitor name
	 * @param {Object} e The click event
	 */
	onMonitorClick: function (e) {
		e.preventDefault();
		var target = $(e.currentTarget);

		var monitorId = target.data('id');
		var monitorType = target.data('type');

		this.openMonitorView(monitorId, monitorType, false, e);
	},
	/*
	 * Handler function for click event on target name
	 * @param {Object} e The event click object
	 */
	onTargetNameClick: function (e) {
		var uid = $(e.currentTarget).closest('tr').data('uid'), item = this.monitorsGrid.dataSource.getByUid(uid);
		State.mainApp.loadModule('AssetDetails', item.targetId, {
			id: item.targetId,
			name: item.targetName,
			accountName: this.accountName,
			accountId: this.accountId
		}, e);
	},
	/**
	 * Disables the assign buttons
	 */
	disableActionButtons: function () {
		if (this.gridMenu) {
			this.gridMenu.disableItem('cw_assign');
			this.gridMenu.disableItem('cw_assign_in_progress');
		}
	},
	/**
	 * Enables the assign buttons
	 */
	enableActionButtons: function () {
		if (this.gridMenu) {
			this.gridMenu.enableItem('cw_assign');
			this.gridMenu.enableItem('cw_assign_in_progress');
		}
	},
	/**
	 * Updates the assigned icon
	 * @param {Array} reasons Array of reason objects
	 */
	updateAssigned: function (reasons) {
		var assignCell, assignedCell;
		for (var i = 0, length = reasons.length; i < length; i++) {
			if (reasons[i].assigned) {
				assignCell = $('input[data-id="' + reasons[i].id + '"]').closest('td');
				assignedCell = assignCell.prev();
				assignedCell.empty().html('<span class="glyphicons new-window-alt"></span>');
			}
		}
	},
	/**
	 * Updates the reasons row with loading indicator
	 * @param {Array} reasons Array of reasons GUIDs
	 */
	updateLoadingAssignments: function (reasons) {
		var assignCell, assignedCell;
		for (var i = 0, length = reasons.length; i < length; i++) {
			assignCell = $('input[data-id="' + reasons[i] + '"]').closest('td');
			assignedCell = assignCell.prev();
			assignCell.find('input').addClass('hide');
			assignedCell.empty().html('<span class="cw_inline_spinner"></span>');
		}
	},
	/**
	 * Method by Andy
	 */
	adjustSectionHeight: function () {
		var section = $('.cw_section_full');
		var sectionHeight = section.height();
		section.find('.cw_section_content').css('height', sectionHeight - 29);
		section.find('.k-grid-content').css('height', sectionHeight - 40 - 29);
	},
	/**
	 * Saves user preferences
	 */
	saveUserPreferences: function () {
		this.saveUserPrefs({
			category: 'ReasonsMonitors',
			grid: this.monitorsGrid,
			keys: {
				searchPhrase: 'reasonsSearchPhrase',
				columns: JSON.stringify(Utils.getGridColumns(this.monitorsGrid)),
				sort: 'monitorsSort'
			}
		});
	},
    /**
     * Subscribes to the events
     */
    subscribe: function () {
		let subscriberId = Utils.guid();
        var subscriptionObj = [{
            eventType: 'AssetHealth',
            assetId: this.assetId
        }];
       RemoteEventsManager.subscribe(subscriberId, subscriptionObj);
    },
	/**
	 * The destroy method
	 */
	destroy: function () {
		this.saveUserPreferences();
        Application.prototype.destroy.call(this);
	}
});
