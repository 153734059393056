import React from 'react';
import PropTypes from 'prop-types';
import {b} from './common';
import Lang from "core/localization/lang";
import classnames from 'classnames';

function RRuleError(props) {
	if (!props.error) {
		return null;
	}

	return <div className={classnames(b('row'), b('error'))}>
		<div>
			<span className={'glyphicons exclamation-sign'} />
			{Lang.rulegenerator.ERROR}
		</div>
	</div>;
}

RRuleError.propTypes = {
	error: PropTypes.bool
};

export default RRuleError;
