import React from 'react';
import PropTypes from 'prop-types';

import {Section} from "controls/react/layout/section";
import {FormEntry} from "controls/react/form/formEntry";
import {AntInput, AntTextArea} from "controls/react/ant/antInput";
import {useStateLink} from "core/react/links";
import {Toolbar, ToolbarItemPosition, ToolbarItemsSet} from "controls/react/layout";
import {AntCheckbox} from "controls/react/ant/antCheckbox";
import {AntModal} from "controls/react/ant/antModal";
import {TagsSelect} from "controls/react/tagsSelect";
import {AntSelect} from "controls/react/ant/antSelect";
import {AntInputNumber} from "controls/react/ant/antInputNumber";
import {IconButton} from "controls/react/form/iconButton";
import {Window} from "controls/react/kendoWrappers/window";
import {ActionButtons} from "controls/react/form/actionButtons";
import Button from "controls/react/form/button";
import State from "tools/state";
import {topLeftCornerPosition} from 'controls/modalWindow';
import CustomNotification from 'controls/customNotification';
import {LayoutType} from "controls/designer/features/adaptiveLayout/layoutType"
import {PresentationApi} from "areas/dashboards/api";

const i = require('core/localization').translator({
  "Lock to screen": {
    "no": "Fest til skjerm"
  },
  "Slide": {
    "no": "Skyv"
  },
  "Off": {
    "no": "Av"
  },
  "Visibility settings": {
    "no": "Innstillinger for synlighet"
  },
  "Position": {
    "no": "Posisjon"
  },
  "Information options": {
    "no": "Informasjonsinnstillinger"
  },
  "Show name": {
    "no": "Vis navn"
  },
  "Show event time": {
    "no": "Vis oppdateringsstid"
  },
  "Widget default settings": {
    "no": "Widget standard innstillinger"
  },
  "Width (px)": {
    "no": "Bredde (px)"
  },
  "Height (px)": {
    "no": "Høyde (px)"
  },
  "Show headers": {
    "no": "Vis overskrift"
  },
  "Public mode": {
    "no": "Åpent modus"
  },
  "Public mode tooltip": {
    "en": "Public mode allows anyone who has a link to open dashboard without credentials. It still will be opened in read-only mode",
    "no": "Åpent modus tillater alle å åpne dashbordet uten pålogging. Den vil fremdeles åpnes i skrivebeskyttet modus"
  },
  "Dashboard info": {
    "en": "Dashboard replace our serviceboards as a presentation tool. It provides the same widgets, in addition to the shapes our Presentation component gave. It gives greater flexibility in terms of design and layout.",
    "no": "Dashboard erstatter tjenestetavle og presentasjon som presentasjonsverktøy. Det gir de samme widgetene som tjenestetavler, i tillegg til figurene som presentasjonskomponenten ga. Det gir større fleksibilitet når det gjelder design og utforming."
  },
  "Allow redirect in public/private mode": {
    "no": "Tillatt omdirigering i Public/Privat visning"
  },
  "Allow redirect tooltip": {
    "en": "Login is required in public mode",
    "no": "Det kreves login på public visning"
  },
  "Legacy serviceboard": {
    "en": "This will create a legacy serviceboard. Ceeview will no longer support serviceboard from release 2.15.",
    "no": "Dette vil  opprette en tjenestetavle. Ceeview vil ikke støtte tjenestetavler fra versjon 2.15."
  },
  "Name exists": {
    "en": "An identical name exist on this account, please use another name.",
    "no": "Et identisk navn finnes på denne kontoen, bruk et annet navn."
  },
  "Show update time": {
    "no": "Vis oppdateringstid"
  },
  "Scrollable": {
    "no": "Skroll"
  },
  "Scale": {
    "no": "Skaler"
  },
  "Responsive": {
    "no": "Responsiv"
  },
  "Layout tooltip": {
    "en": "Scrollable: If content do not fit resolution scrollbars are added\nScale: Content is scaled to fit the resolution\nResponsive: Content will be shifted depending on width of resolution to show all widgets vertically",
    "no": "Skroll: Hvis innholdet ikke passer oppløsningen, legges det til skroll\nSkaler: Innholdet er skalert til passe inn i oppløsningen\nResponsiv: Innholdet vil bli forskjøvet avhengig av oppløsningsbredden for å kunne vise alle widgets vertikalt"
  }
});

const layoutTypeDatasource = [{
	id: LayoutType.Scroll,
	name: i('Scrollable')
},{
	id: LayoutType.Scale,
	name: i('Scale')
},{
	id: LayoutType.Adaptive,
	name: i('Responsive')
}];

export const PropertiesForm = props => {

	let modalNotification = React.useRef(new CustomNotification({
		appendToElement: '#popup',
		status: 'success',
		type: 'icon',
		hideOnClick: true
	}));

	const propertiesLink = useStateLink(props.properties || {});

	const layoutTypeLink = propertiesLink.get('layoutType')
		.changing(({rootStore, value, initialValue, e}) => {
			if(props.properties.id)
				return;

			if(value == LayoutType.Adaptive && rootStore.widgetWidth == 400 && rootStore.widgetHeight == 320){
				rootStore.widgetWidth = 360;
				rootStore.widgetHeight = 360;
			}else if(rootStore.widgetWidth == 360 && rootStore.widgetHeight == 360){
				rootStore.widgetWidth = 400;
				rootStore.widgetHeight = 320;
			}
		});

	const nameLink = propertiesLink.get('tag')
		.required();

	const onOk = React.useCallback(async () => {
		if (nameLink.invalid){
			modalNotification.current.setOptions({
				message: lang.account.messages.REQUIRED_FIELDS,
				status: 'error'
			}).show();
			return;
		}

		let name = propertiesLink.value.tag;
		let nameExists = await Utils.checkIfNameExists(PresentationApi.getSearchUrl(), name, modalNotification.current, true, props.properties?.tag);
		if(nameExists) {
			return;
		}
		props.onSave(propertiesLink.value);
	}, [props.onSave]);

	const titleIcon = {
		class: 'question-sign',
		tooltip: i('Dashboard info')
	}

	const createLegacyServiceBoard = React.useCallback(() => {
		props.onCancel();
		State.mainApp.onNewServiceboard();
	}, [props.onCancel]);


	return (
		<Window title={i('Properties')}
				titleIcon={titleIcon}
		        width={900}
				position={topLeftCornerPosition}
		        modal={true}
		        onClose={props.onCancel}>
			<Section appearance={"none"}>

				<Section layout={"equal"} direction={"row"}>
					<Section appearance={"frame"} title={i('Configuration')} childrenPadding={true}>
						<div id="popup"></div>
						<FormEntry valueLink={nameLink} label={i('Name')}>
							<AntInput placeholder={i('Please input name...')}/>
						</FormEntry>
						<FormEntry label={i('Description')} vertical={true}>
							<AntTextArea valueLink={propertiesLink.get('description')}/>
						</FormEntry>
						<FormEntry label={i('Tags')} vertical={true}>
							<TagsSelect {...propertiesLink.get('tags').props}/>
						</FormEntry>
						<FormEntry label={i('Layout')} title={i('Layout tooltip')}>
							<IconButton iconName={"question-sign"}
										title={i('Layout tooltip')}/>
							<AntSelect dataList={layoutTypeDatasource}
									   valueLink={propertiesLink.get('layoutType')}/>
						</FormEntry>
						<FormEntry title={i('Allow redirect tooltip')}>
							<AntCheckbox
								valueLink={propertiesLink.get('allowRedirects')}>{i('Allow redirect in public/private mode')}</AntCheckbox>
						</FormEntry>
					</Section>
					<Section appearance={"none"}>
						<Section appearance={"frame"} title={i('Visibility settings')} childrenPadding={true}>
							<FormEntry label={i('Public mode')}>
								<IconButton iconName={"question-sign"}
								            title={i('Public mode tooltip')}
								            embedded/>
								<AntCheckbox valueLink={propertiesLink.get('isPublic')}>{i('Enabled')}</AntCheckbox>
							</FormEntry>
							<FormEntry label={i('Information options')}>
								<AntSelect dataList={informationDataList}
								           valueLink={propertiesLink.get('informationOption')}/>
							</FormEntry>
							<FormEntry label={i('Position')}>
								<AntSelect dataList={positionDataList} valueLink={propertiesLink.get('position')}/>
							</FormEntry>
							<FormEntry>
								<AntCheckbox valueLink={propertiesLink.get('showName')}>{i('Show name')}</AntCheckbox>
							</FormEntry>
							<FormEntry>
								<AntCheckbox
									valueLink={propertiesLink.get('showUpdateTime')}>{i('Show update time')}</AntCheckbox>
							</FormEntry>
						</Section>
						<Section appearance={"frame"} title={i('Widget default settings')} childrenPadding={true}>
							<FormEntry>
								<AntCheckbox valueLink={propertiesLink.get('showWidgetHeader')}>{i('Show headers')}</AntCheckbox>
							</FormEntry>
							<FormEntry label={i('Width (px)')}>
								<AntInputNumber valueLink={propertiesLink.get('widgetWidth')}/>
							</FormEntry>
							<FormEntry label={i('Height (px)')}>
								<AntInputNumber valueLink={propertiesLink.get('widgetHeight')}/>
							</FormEntry>
						</Section>
					</Section>
				</Section>

				<Toolbar>
					<ActionButtons onSave={onOk}
					               onCancel={props.onCancel}
					               update={props.properties.id != null}/>
				</Toolbar>

			</Section>

		</Window>
	)
}

PropertiesForm.propTypes = {
	onSave: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
}

const visibilityDataList = [{
	name: i('Public'),
	id: 'public'
},{
	name: i('Private'),
	id: 'private'
}];

const informationDataList = [{
	name: i('Lock to screen'),
	id: 'locked'
},{
	name: i('Slide'),
	id: 'slide'
},{
	name: i('Off'),
	id: 'off'
}];

const positionDataList = [{
	name: i('Left'),
	id: 'left'
},{
	name: i('Right'),
	id: 'right'
},{
	name: i('Center'),
	id: 'center'
}];
