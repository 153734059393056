import React from 'react';
import PropTypes from 'prop-types';
import {b} from './common';
import Lang from "core/localization/lang";
import DropDownList from "controls/react/kendoWrappers/dropDownList";
import RRule from "rrule";
import {SET_BY_MONTH, SET_BY_MONTH_DAY} from "./reducer";
import classnames from 'classnames';

const MONTHS = [{
	text: Lang.months.JANUARY,
	value: '1'
}, {
	text: Lang.months.FEBRUARY,
	value: '2'
}, {
	text: Lang.months.MARCH,
	value: '3'
}, {
	text: Lang.months.APRIL,
	value: '4'
}, {
	text: Lang.months.MAY,
	value: '5'
}, {
	text: Lang.months.JUNE,
	value: '6'
}, {
	text: Lang.months.JULY,
	value: '7'
}, {
	text: Lang.months.AUGUST,
	value: '8'
}, {
	text: Lang.months.SEPTEMBER,
	value: '9'
}, {
	text: Lang.months.OCTOBER,
	value: '10'
}, {
	text: Lang.months.NOVEMBER,
	value: '11'
}, {
	text: Lang.months.DECEMBER,
	value: '12'
}];

function OnMonthDay(props) {
	const DAYS = React.useMemo(() => {
		return Array(31).fill(null).map((_, index) => ({
			value: index + 1,
			text: index + 1
		}))
	}, []);

	const handleByMonthDay = React.useCallback((value) => {
		props.dispatch({type: SET_BY_MONTH_DAY, value})
	}, [props.dispatch]);

	const handleByMonth = React.useCallback((value) => {
		props.dispatch({type: SET_BY_MONTH, value})
	}, [props.dispatch]);

	if (props.frequency !== RRule.YEARLY) {
		return null;
	}

	return <div className={classnames(b('row'), b('on-day'))}>
		<div className={b('text')}>{Lang.rulegenerator.ON_DAY}</div>
		<DropDownList dataSource={MONTHS} value={props.bymonth} onChange={handleByMonth} />
		<DropDownList dataSource={DAYS} value={props.bymonthday} onChange={handleByMonthDay} />
	</div>;
}

OnMonthDay.propTypes = {
	dispatch: PropTypes.func.isRequired,
	frequency: PropTypes.number,
	bymonthday: PropTypes.any,
	bymonth: PropTypes.any,
};

export default OnMonthDay;
