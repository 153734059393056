import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {Designer as DesignerControl} from 'controls/designer/designer';
import PresentationDesignerExtension from "areas/dashboards/graph-editor-extensions/presentationDesignerExtension";
import {PresentationApi} from "areas/dashboards/api";
import {PropertiesForm} from "areas/dashboards/propertiesForm";
import {Page} from "controls/react/layout/page";
import State from 'tools/state';
import {BoxView} from "controls/react/layout/boxView";

import "./dashboardDesigner.less"
import {newGuid} from "tools/guid";
import Cookies from 'core/cookies';
import Settings from 'settings';
import {LayoutType} from "controls/designer/features/adaptiveLayout/layoutType";


const i = require('core/localization').translator({
	'info-message': {
		'en': 'To get started add a shape ',
		'no': 'For å komme igang legg til en form'
	},
	'info-message2':{
		'en':' or widget ',
		no: ' eller widget'
	},
	'info-message3':{
		'en' : ' to the canvas.',
		no: ' til arbeidsbakgrunnen'
	}
});

const b = require('b_').with('dashboard-designer');

export class DashboardDesigner extends React.PureComponent{
	containerRef = React.createRef();

	skipDirtyCheck = false;

	render(){
		return <div ref={this.containerRef}></div>
	}

	async componentDidMount(){
		if(this.props.dashboard == null) {
			this.dashboard = await this.loadDashboard();
		}else{
			this.dashboard = this.props.dashboard;
		}

		if(this.dashboard == null)
			return;

		this.props.dashboardLoaded && this.props.dashboardLoaded(this.dashboard);

		var config = {
			accountId: this.dashboard.accountId,
			container: this.containerRef.current,
			onLoaded: this.designerLoaded,
			data: {
				xml: this.dashboard?.type == 'DASHBOARD' || this.dashboard?.type == 'PRESENTATION'
					? this.dashboard.content
					: ''
			},
			padding: 5,
			mode: 'presentation',
			layoutType: this.props.layoutType,
			disableDynamicContent: this.props.dashboard != null,
			features: {
				serviceModelImporting: true,
				widgets: true,
				shapesCollection: true,
				moveToFrontOnExpand: true,
				scrollToBottomIfExpanded: true,
				redirectOnClick: true,
				adaptiveLayout: true,
				presentationMode: true,
				snapToGrid: true,
				preview: true
			},
			forceHideWindows: !this.props.id && !this.props.xml,
			extensionsManager: new PresentationDesignerExtension(),
			removeInfoMessage: this.removeInfoMessage,
			...this.props.config
		};


		if(this.props.mode == 'viewer'){
			config.chromeless = true;
			config.toolbar = false;
		}

		this.designer = new DesignerControl(config);
	}

	loadDashboard = async () => {
		if(!this.props.id && !this.props.xml) {
			return {
				tag: "",
				description: "",
				informationOption: 'locked',
				position: 'left',
				isPublic: true,
				showName: true,
				showUpdateTime: true,
				type: 'DASHBOARD',
			};
		}

		const result = await PresentationApi.read(this.props.id, this.props.sessionId);
		if(!result.success){
			return null;
		}

		return result.data;
	}

	componentWillUnmount(){
		this.removeInfoMessage();
		this.designer?.destroy();
	}

	designerLoaded = designer => {
		this.initialDashboard = JSON.stringify({
			...this.dashboard,
			content: designer.initialXml
		});

		this.designer = designer;
		this.props.onLoaded && this.props.onLoaded(this, this.dashboard);
		this.updateContainerClasses();

		this.designer.editorUi.editor.graph.addListener(mxEvent.CELLS_FOLDED, this.onCellFolded);
	}

	onCellFolded = () => {
		if(this.designer.dashboardSettings.layoutType == LayoutType.Scale) {
			setTimeout(() => this.designer?.fit(), 0);
		}
	}

	editProperties = () => {
		return new Promise(resolve => {
			const container = document.createElement('div');
			document.body.appendChild(container);

			const unmount = () =>{
				ReactDOM.unmountComponentAtNode(container);
			}

			const onCancel = () => {
				this.skipDirtyCheck = true;
				resolve(false);
				unmount();
			}

			const onSaveWrapper = properties => {
				this.updateProperties(properties);
				resolve(true);
				unmount();
				this.renderInfoMessage();
			}
			const onCreateLegacyServiceboard = () => {
				resolve(false);
				unmount();
				this.skipDirtyCheck = true;
				State.mainApp.onNewServiceboard();
			}

			const properties = this.getProperties();

			ReactDOM.render(<PropertiesForm onSave={onSaveWrapper}
			                                onCancel={onCancel}
			                                onCreateLegacyServiceboard={onCreateLegacyServiceboard}
			                                properties={properties}/>,
				container);
		});
	}

	renderInfoMessage() {
		if( this.props.id === undefined && !this.props.xml ){
			this.infoRoot = document.createElement('div');
			this.infoRoot.className='application-info-message';
			document.querySelector('body').append(this.infoRoot);
			ReactDOM.render(<BoxView type={"info-2"} rounded={true}
			                         border={true}>
								{i('info-message')}
								<i className="material-icons">bubble_chart</i>
								{i('info-message2')}
								<svg viewBox="64 64 896 896" focusable="false" style={{margin: '5px'}} data-icon="plus-square" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M328 544h152v152c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V544h152c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H544V328c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v152H328c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8z"></path><path d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zm-40 728H184V184h656v656z"></path></svg>
								{i('info-message3')}
							</BoxView>, this.infoRoot);
		}
	}

	removeInfoMessage = () => {
		if(this.infoRoot) {
			ReactDOM.unmountComponentAtNode(this.infoRoot);
		}
	}

	updateProperties = properties => {
		for(const key of Object.keys(this.designer.dashboardSettings)){
			this.designer.dashboardSettings[key] = properties[key];
		}

		for(const key of Object.keys(this.dashboard)){
			if(properties[key] != undefined) {
				this.dashboard[key] = properties[key];
			}
		}

		if(!properties.id){
			this.designer.graph.setGridEnabled(true);
		}

		this.designer.graph.refresh();
		this.updateContainerClasses();
	}

	updateContainerClasses(){
		let classes = {
			'widget-settings_header-static': this.designer.dashboardSettings.showWidgetHeader,
			'widget-settings_header-on-hover': !this.designer.dashboardSettings.showWidgetHeader && this.props.mode == 'designer',
			'widget-settings_header-hidden': !this.designer.dashboardSettings.showWidgetHeader && this.props.mode != 'designer'
		};

		for(let className of Object.keys(classes)){
			this.containerRef.current.classList.remove(className)
		}

		this.containerRef.current.classList.add(classnames(classes));
	}

	getProperties(){
		let properties = {};

		for(const key of Object.keys(this.designer.dashboardSettings)){
			properties[key] = this.designer.dashboardSettings[key];
		}

		for(const key of Object.keys(this.dashboard)){
			properties[key] = this.dashboard[key];
		}

		return properties;
	}

	getDashboardForSaving(skipCleanUp) {
		if (!skipCleanUp) {
			this.designer.cleanUp();
		}

		let dashboard = {
			...this.dashboard,
			content: this.designer.getXml()
		};

		if(dashboard.type != 'DASHBOARD'){
			if(dashboard.type == 'LEGACY'){
				delete dashboard.id;
			}
			dashboard.type = 'DASHBOARD';
		}

		return dashboard;
	}

	isDashboardDirty() {
		if (this.props.mode === 'viewer' || this.skipDirtyCheck) {
			return false;
		}

		if (this.designer && this.designer.isDirty())
			return true;

		let skipCleanUp = true;
		let currentDashboard = this.getDashboardForSaving(skipCleanUp);
		return JSON.stringify(currentDashboard) !== this.initialDashboard;
	}

	isDirty(){
		return this.designer.isDirty();
	}
}

DashboardDesigner.propTypes = {
	id: PropTypes.any,
	dashboard: PropTypes.object,
	mode: PropTypes.oneOf(["viewer", "designer"]),
	onLoaded: PropTypes.func
}
