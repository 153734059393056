import React from 'react';
import ReactDOM from 'react-dom';

import Utils from 'tools/utils';
import {GenericMetricsWidgetConfiguration} from "./genericMetricsWidgetConfiguration/widgetConfiguration";
import Settings from 'settings';
import Cookies from 'core/cookies'
import Api from 'tools/api';

export function GenericMetricsWidgetConfigurationWrapper(config) {
	Utils.apply(this, config);

	this._initComponent();
	return this;
};

export {GenericMetricsWidgetConfigurationWrapper as default};

jQuery.extend(GenericMetricsWidgetConfigurationWrapper.prototype, {
	/**
	 * @cfg {jQuery element} renderTo Mandatory. The jQuery element where the form is rendered
	 */
	renderTo: null,
	/*
	 * Init the component
	 * */
	_initComponent: async function () {
		if (!this.widgetObj.widgetObjComplete) {
			await this.completeWidgetObj();
		}
		let container = this.widgetConfigurationFormEl
		container.empty().html('<div id="cw_metrics_form"></div>');
		this.renderTo = $('#cw_metrics_form');

		this.showAdvanced = false;
		if (this.widgetObj.instanceConfiguration) {
			for (let i = 0; i < this.widgetObj.instanceConfiguration.metricsItems.length; i++) {
				let current = this.widgetObj.instanceConfiguration.metricsItems[i];
				if (current.color || current.conversion || current.customUnit) {
					this.showAdvanced = true;
				}
			}
		}

		this._render();
	},

	async completeWidgetObj () {
		this.widgetObj.id = this.widgetObj.id || Utils.guid();
		let data = this.widgetObj.configuration.metrics || [];
		let url = `${Settings.serverPath}accounts/${Cookies.CeesoftCurrentAccountId}/metrics/registeredMetrics`;
		let result = await Api.fetchPost(url, data);
		if (this.widgetObj.configuration.metricsItems) {
			for (var i = 0; i < this.widgetObj.configuration.metricsItems.length; i++) {
				for (var j = 0; j < result.length; j++) {
					if (this.widgetObj.configuration.metricsItems[i].metricId === result[j].metricId) {
						result[j].customUnit = this.widgetObj.configuration.metricsItems[i].customUnit;
						result[j].conversion = this.widgetObj.configuration.metricsItems[i].conversion;
						result[j].color = this.widgetObj.configuration.metricsItems[i].color;
					}
				}
			}
		}
		this.widgetObj.configuration.metricsItems = result;
		for (var i = 0; i < this.widgetObj.configuration.metricsItems.length; i++) {
			this.widgetObj.configuration.metricsItems[i].id = Utils.guid();
		}
		this.hideThreshold = this.widgetObj.type === 'multi_graph_asset';
	},

	/*
	 * Renders the form
	 * */
	_render: function () {
		ReactDOM.render(<GenericMetricsWidgetConfiguration
			config={this.widgetObj}
			isExisting={!!this.widgetObj.widgetContentDiv}
			hideThreshold={this.hideThreshold}
			showAdvanced={this.showAdvanced}
			onChange={(values) => {
				this.values = values;
			}}/>, this.renderTo.get(0));
	},

	getValues: function () {
		return this.values;
	},

	isValid: function () {
		var valid = true;
		this.validationMessage = '';

		var accountCombo = $('#cw_account').data('kendoComboBox');

		const {type, configuration: {accountId, metrics, assetGroups, metricCategories, period, startDate, endDate}} = this.values;

		if (!Utils.isGuid(accountId)) {
			valid = false;
			this.validationMessage = lang.widget.SELECT_ACCOUNT;
		}

		if (type === 'multi_graph_assetgroup' || type == 'metric-multi-graph-asset-group') {
			if (!assetGroups || !assetGroups.length) {
				valid = false;
				this.validationMessage = lang.widget.SELECT_ASSET_GROUPS;
			}

			if (!metricCategories || !metricCategories.length) {
				valid = false;
				this.validationMessage = lang.widget.SELECT_METRIC_CATEGORIES;
			}
		} else {
			if (!metrics || !metrics.length) {
				valid = false;
				this.validationMessage = lang.widget.SELECT_METRICS;
			} else if (period === 'CUSTOM' && (!startDate || !endDate)) {
				valid = false;
				this.validationMessage = lang.widget.SELECT_CUSTOM_PERIOD_DATE;
			}
		}

		return {
			valid: valid,
			validationMessage: this.validationMessage
		};
	},

});
