import React from "react";
import {store} from "./designer/qualifierWizard/reduxStore";
import {SET_SERVICE_ID, SET_ELEMENT_ID, SET_QUALIFIER_ID, GET_TEST, SET_QUALIFIER_WINDOW_SIZE, generateAction} from "./designer/qualifierWizard/reduxActions";
import {ConfigErrorWindow} from "./designer/qualifierWizard/configErrorWindow";

import {Provider} from "react-redux";

export default class ExternalConfigErrorWindow extends React.PureComponent {
    constructor(props) {
        super(props);

        const {serviceId, elementId, qualifierId, top, left, width, height} = this.props;

        store.dispatch(generateAction(SET_SERVICE_ID, {serviceId}));
        store.dispatch(generateAction(SET_ELEMENT_ID, {elementId}));
        store.dispatch(generateAction(SET_QUALIFIER_ID, {qualifierId}));
    }

    componentDidMount() {
        store.dispatch(generateAction(GET_TEST));
    }

    render() {
        const {name, top, left, width, height} = this.props;

        const params = {name, position: {top: +top, left: +left, width: +width, height: +height}};

        return <Provider store={store}>
            <ConfigErrorWindow {...params} />
        </Provider>
    }
}