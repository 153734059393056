import React, { useCallback, useEffect, useMemo, useState, useRef } from "react";

import { MenuButton } from "controls/react";
import { Checkbox } from "controls/react/form";
import { GridNative } from "controls/react/kendoWrappers/gridNative";
import {Section} from 'controls/react/layout/section';
import {Toolbar, ToolbarItemPosition} from 'controls/react/layout/toolbar';
import { translator } from "core";
import { fromJS } from "immutable";
import { metricSelectorGridColumns } from "../common/dataSources/metricSelectorGridColumns";
import { metricSelectorGridSchema } from "../common/dataSources/metricSelectorGridSchema";
import { MetricWindow } from "../common/metricWindow";
import { ConversionWindow } from "../common/conversionWindow";
import { useStore } from "./useStore";
import {newGuid} from "tools/guid";
import Settings from "settings";
import Cookies from 'core/cookies';
import Button from "controls/react/form/button";
import IconButton from "controls/react/form/iconButton";

const b = require('b_').with('metric-selector');

const i = translator({
  "Select metrics": {
    "no": "Velg metrikker"
  },
  "Show advanced": {
    "no": "Vis avansert"
  },
  "Hide advanced": {
    "no": "Skjul avansert"
  }
});

MetricSelector.propTypes = {};

/**
 * @return {null}
 */
export function MetricSelector(props) {
	let showAdvanced = false;
	const [type] = useStore(["type"]);
	const [accountId] = useStore(["configuration", "accountId"]);
	const [selectedIds, setIds] = useStore(["configuration", "metrics"]);
	const [selectedMetrics, setSelectedMetrics] = useStore(["configuration", "metricsItems"]);
	const [openWindow, setOpenWindow] = useState(false);
	const [openConversionWindow, setOpenConversionWindow] = useState(false);
	const [conversionMetricId, setConversionMetricId] = useState();
	const [conversionWindowOffset, setConversionWindowOffset] = useState();
	const [conversionFormula, setConversionFormula] = useState();
	const [conversionDecimals, setConversionDecimals] = useState();
	const [allColumns, setAllColumns] = useState(showAdvanced);
	const [toggleClicked, setToggleClicked] = useState(false);
	const checkedRowsRef = useRef([]);
	const handleDeleteRef = useRef(null);

	const [gridMetrics, setGridMetrics] = useState([]);

	if (!toggleClicked) {
		for (let i = 0; i < gridMetrics.length; i++) {
			if (gridMetrics[i].color || gridMetrics[i].conversion || gridMetrics[i].customUnit) {
				showAdvanced = true;
				break;
			}
		}
		if (showAdvanced !== allColumns) {
			setAllColumns(showAdvanced);
		}
	}

	const refreshGridToken = useMemo(() => newGuid(), [allColumns]);

	const plainSelectedIds = React.useMemo(() => {
		if (selectedMetrics) {
			return selectedMetrics.toJS().map((metric) => metric.metricId)
		}
	}, [selectedMetrics]);

	useEffect(() => {
		if (selectedMetrics) {
			const metrics = selectedMetrics.toJS();
			setGridMetrics(metrics);
			setIds(metrics.map((metric) => metric.metricId));
		}
	}, [selectedMetrics]);

	handleDeleteRef.current = useCallback(() => {
		const metrics = selectedMetrics.toJS();

		for (const checkedId of checkedRowsRef.current) {
			const index = metrics.findIndex((metric) => metric.metricId === checkedId);
			metrics.splice(index, 1);
		}

		setSelectedMetrics(fromJS(metrics));
	}, [selectedMetrics]);

	const menuItems = useMemo(() => {
		return [{
			icon: 'plus',
			text: i('Add'),
			fn: () => {
				setOpenWindow(true);
			}
		}, {
			icon: 'bin',
			text: i('Delete'),
			fn: () => {
				handleDeleteRef.current();
			}
		}];
	}, []);

	const openMetricWindow = useCallback(() => {
		if (accountId) {
			setOpenWindow(true);
		}
	}, [accountId]);

	const onClose = useCallback(() => {
		setOpenWindow(false);
	});

	const onConversionClose = useCallback(() => {
		setOpenConversionWindow(false);
	});

	const onConversionUpdate = useCallback((formula, decimals) => {
		let row = $('[metric-id=' + conversionMetricId + ']').closest('tr');
		let grid = row.closest('.k-grid').data('kendoGrid');
		let dataItem = grid.dataItem(row);
		dataItem.set('conversion', formula);
		dataItem.set('decimals', decimals);
		let metrics = selectedMetrics.toJS();
		for (let i = 0; i < metrics.length; i++) {
			if (metrics[i].metricId === conversionMetricId) {
				metrics[i].conversion = formula;
				metrics[i].decimals = decimals;
			}
		}
		setSelectedMetrics(fromJS(metrics));
		setOpenConversionWindow(false);
	});

	const onAdd = useCallback((items) => {
		async function fetchSelectedItems() {
			const url = `${Settings.serverPath}accounts/${accountId}/metrics/registeredMetrics/search`;

			const options = {
				method: 'POST',
				body: JSON.stringify({ skip: 0, take: 1000, filter: {
					"logic": "and",
					"filters": [{
						"logic": "or",
						"filters": items.map((id) => ({
							"operator": "eq",
							"value": id,
							"field": "metricId"
						}))
				 	}]
				}}),
				headers: {
					"Content-Type": "application/json; charset=utf-8",
					"Auth-Token": Cookies.sessionId
				},
				throwOnError: false,
			};

			const response = await fetch(url, options);

			const result = await response.json();

			setSelectedMetrics(fromJS(result.items));
		};

		if (items.length || type !== 'metric-single-graph') {
			fetchSelectedItems();
		} else {
			setSelectedMetrics(fromJS([]));
		}

		setOpenWindow(false);
	});

	const selectedMetricsRef = useRef();
	useEffect(() => {
		selectedMetricsRef.current = selectedMetrics;
	}, [selectedMetrics]);

	const onGridChanged = useCallback((e) => {
		const {values, model} = e;

		const updatedItem = model.toJSON();
		const newSelectedMetrics = selectedMetricsRef.current.toJS();

		const index = newSelectedMetrics.findIndex((i) => i.metricId === updatedItem.metricId);

		if (index === -1) {
			return;
		}

		newSelectedMetrics.splice(index, 1, {...updatedItem, ...values});

		setSelectedMetrics(fromJS(newSelectedMetrics));
	}, [selectedMetrics]);

	const onDataBound = useCallback((e) => {
		$('.cw_conversion').closest('td').addClass('pointer').on('click', (e) => {
			onConversion(e);
		});
		$('.cw_metric_item_delete').on('click', (e) => {
			onMetricItemDelete(e);
		});
		let grid = $(e.sender.wrapper).data('kendoGrid');
		let gridDataSource = grid.dataSource.data().toJSON();
		if (!gridDataSource.length) {
			if (!grid.wrapper.find('.k-grid-norecords').length) {
				grid.wrapper.find('.k-grid-content-expander').before('<div class="k-grid-norecords cw_no_records_container pointer"><span class="cw_no_records_info">' + lang.widget.messages.SELECT_NEW_METRICS + '<span></span></div>');
				grid.wrapper.find('.k-grid-content').css('overflow-y', 'hidden');
				grid.wrapper.find('.k-grid-norecords').on('click', setOpenWindow);
			}
		} else {
			grid.wrapper.find('.k-grid-norecords').remove();
			grid.wrapper.find('.k-grid-content').css('overflow-y', 'scroll');
		}
	});

	const onConversion = (e) => {
		let target = $(e.target);
		let formula = target.text();
		let decimals = target.attr('decimals');
		let targetOffset = target.offset();
		let metricId = target.closest('tr').find('.cw_grid_check').attr('metric-id');
		setConversionMetricId(metricId);
		setConversionWindowOffset(targetOffset);
		setConversionFormula(formula);
		setConversionDecimals(decimals);
		setTimeout(() => {setOpenConversionWindow(true)}, 100);
	}

	const onMetricItemDelete = (e) => {
		let selectedMetrics = selectedMetricsRef.current.toJS();
		let metricId = $(e.target).closest('tr').find('.cw_grid_check').attr('metric-id');
		for (let i = 0; i < selectedMetrics.length; i++) {
			if (selectedMetrics[i].metricId === metricId) {
				selectedMetrics.splice(i, 1);
			}
		}
		setSelectedMetrics(fromJS(selectedMetrics));
	}

	const onAdvancedToggle = (e) => {
		setToggleClicked(true);
		if ($(e.target).hasClass('selected_advanced_metrics_toggle')) {
			setAllColumns(false);
		} else {
			setAllColumns(true);
		}
	}

	const handleRowSelected = useCallback((ids) => {
		checkedRowsRef.current = ids;
	});

	if (type === 'multi_graph_assetgroup' || type === 'metric-multi-graph-asset-group') {
		return null;
	}

	let advancedToggleContainerClass = allColumns ? 'advanced_metrics_toggle selected_advanced_metrics_toggle' : 'advanced_metrics_toggle';
	let advancedToggleMouseover = allColumns? i('Hide advanced') : i('Show advanced');

	let gridHeight = 200;
	$('#cw_metrics_form .k-grid-content').css('height', gridHeight);

	return (
		<Section containerClass={b()} contentPadding={false}>
			<Toolbar title={lang.widget.SELECTED_METRICS}>
				<MenuButton items={menuItems} />
				<IconButton iconName="list"
							embedded
							containerClass={advancedToggleContainerClass}
							onClick={(e) => onAdvancedToggle(e)}
							title={advancedToggleMouseover}
							position={ToolbarItemPosition.AT_THE_END}
				/>
				<Button title={i('Select metrics')}
						primary
						onClick={openMetricWindow}/>
			</Toolbar>
			<GridNative
				key={refreshGridToken}
				selectionField={"metricId"}
				onRowsSelected={handleRowSelected}
				columns={metricSelectorGridColumns(allColumns)}
				schema={metricSelectorGridSchema(allColumns)}
				dataSourceArray={gridMetrics}
				resizable={true}
				editable={true}
				height={gridHeight}
				oneRowSelection={type === 'metric-single-graph' || type === 'single_graph'}
				dataBound={onDataBound}
				onSave={onGridChanged} />
			{openWindow && accountId && <MetricWindow
				open={openWindow}
				onClose={onClose}
				onAdd={onAdd}
				accountId={accountId}
				singleMetricSelection={type === 'metric-single-graph' || type === 'single_graph'}
				defaultSelectedMetricIds={plainSelectedIds} />}
			{openConversionWindow && <ConversionWindow
				metricId={conversionMetricId}
				onUpdate={onConversionUpdate}
				onClose={onConversionClose}
				offset={conversionWindowOffset}
				formula={conversionFormula}
				decimals={conversionDecimals}
			/>}
		</Section>
	);
}
