export function getDescriptions(){
	return [{
		url: '/data-registry',
		getModuleImport: () => import('./dataRegistry'),
		react: true
	},];
}

export class ManagementRouter {
	static dataRegistryList() {
		return '/data-registry'
	}
}
