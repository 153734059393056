import {put} from "redux-saga/effects"
import {setDataAction} from "../actions/setDataAction";
import { addVirtualFieldsAction } from "../actions/addVirtualFieldsAction";
import { onChangeAction } from "../actions/onChangeAction"

export function* initWithData({id, data, values, persistedValues, timezone}) {
	yield put(setDataAction(id, data, values, persistedValues, timezone));
	yield put(onChangeAction({ ...data.configuration.values, warning: data.configuration.warning }));
	yield put(addVirtualFieldsAction());
}
