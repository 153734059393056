import {MonitorConfiguration} from "areas/assets/monitors/configurations";
import {Utils} from "tools";

export default function FragmentDetails(config) {
	Utils.apply(this, config);
};

jQuery.extend(FragmentDetails.prototype, MonitorConfiguration.prototype, {
	initComponent: function () {
		this._fragmentViews = [{
				id: 'original',
				name: 'Original'
			}];

	},
	initKendoComponents: function () {
		this.viewElement.find('.cw_fragment_nav .cw_fragments_list').kendoSortable({
			filter: '.is_sortable',
			hint: function (element) {
				var hintElement = $('<div class="cw_fragment_clone"></div>').append('<span class="cw_fragment_name">' + element.text() + '</span>').append('<span class="glyphicons bin"></span>');
				return hintElement;
			}
		});
	},
	removeListeners: function () {
		this.viewElement.find('.cw_fragment_nav').off();
	},
	attachListeners: function () {
		this.viewElement.find('.cw_fragment_nav').on('click', '.cw_item', $.proxy(this.onFragmentClick, this));
		this.viewElement.find('.cw_fragment_nav').on('click', '#cw_fragment_add', $.proxy(this.onFragmentAdd, this));
		this.viewElement.find('.cw_fragment_nav').on('click', '.bin', $.proxy(this.onFragmentRemove, this));
		$('button.expand').on('click', $.proxy(this.onExpand, this));

	},
	onExpand: function() {
		var expandFragmetsBtn = $('button.expand');
		expandFragmetsBtn.find('.cw_fragments_indicator').toggleClass('chevron-up chevron-down');
		$('.cw_fragment_nav').toggleClass('expanded');
	},
	setViewElement: function (viewElement) {
		this.viewElement = viewElement;
	},
	onFragmentClick: function (e) {
		if (this.isValidView()) {
			var innerItem = $(e.currentTarget);
			if (innerItem.attr('id') !== 'cw_fragment_add') {
				var fragmentId = innerItem.attr('data-id');
				innerItem.parent().find('.is_selected').removeClass('is_selected');
				innerItem.addClass('is_selected');
				this.loadFragmentView(fragmentId);
			}
		}
	},
	onFragmentName: function (e) {
		//console.log('onFragmentName');
	},
	onFragmentAdd: function (e) {
		let target;
		var tabPanel = this.viewElement.find('.cw_fragment_nav');
		tabPanel.removeClass('hide');
		this.viewElement.find('.window_area').addClass('has_fragments');
		var fragmentId = Utils.guid();
		if (e) {
			target = $(e.currentTarget);
		} else {
			target = this.viewElement.find('.cw_fragment_add');
		}
		target.closest('.cw_fragment_nav').find('.cw_fragments_list').append('<li class="cw_item is_sortable" data-id="' + fragmentId + '"><span class="cw_fragment_name">' + 'New fragment' + '</span><span class="glyphicons bin"></span></li>');
		this._fragmentViews.push({
			id: fragmentId,
			name: lang.assethealth.NEW_FRAGMENT,
			fragmentMode: 'new'
		});
		this.viewElement.find('li[data-id="' + fragmentId + '"]').trigger('click');
		var fragmentsNo = $('.cw_fragments_list li').length;
		if (($('.cw_fragment_nav').width() - 120 < fragmentsNo * 130) && (!$('.cw_fragment_nav ').hasClass('expanded'))){
			$('.cw_fragment_nav ').addClass('expanded');
		}
	},
	getFragment: function (id) {
		for (var i = 0; i < this._fragmentViews.length; i++) {
			if (this._fragmentViews[i].id === id) {
				return this._fragmentViews[i];
			}
		}
		return null;
	},
	onFragmentRemove: function (e) {
		e.stopPropagation();
		e.stopImmediatePropagation();
		var fragmentId = $(e.currentTarget).closest('.cw_item').attr('data-id');
		for (var i = 0; i < this._fragmentViews.length; i++) {
			if (this._fragmentViews[i].id === fragmentId) {
				this._fragmentViews.splice(i, 1);
				this.monitorConfiguration.fragments.splice(i - 1, 1);
				break;
			}
		}
		$(e.currentTarget).closest('.cw_item').prev().trigger('click');
		$(e.currentTarget).closest('.cw_item').remove();
		if (this._fragmentViews.length === 1) {
			this.viewElement.find('.cw_fragment_nav .cw_fragments_list').addClass('hide');
		} else if (this._fragmentViews.length < 1) {
			this.viewElement.find('.window_area').removeClass('has_fragments');
		}
	},
	populateFragments: function () {
		for (var i = 0; i < this.monitorConfiguration.fragments.length; i++) {
			var fragment = this.monitorConfiguration.fragments[i];
			this.viewElement.find('.cw_fragment_nav .cw_fragments_list').append('<li class="cw_item is_sortable" data-id="' + fragment.id + '"><span class="cw_fragment_name">' + fragment.name + '</span><span class="glyphicons bin"></span></li>');
			this._fragmentViews.push({
				id: fragment.id,
				name: fragment.name
			});
		}
	}
});
