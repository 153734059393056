import React from 'react';
import PropTypes from 'prop-types';
import useDataSource from './useDataSource';
import {TableContext} from './constants';
import {ACTIONS, VARS} from './reducer';

function withDataSource(WrappedComponent) {
	function WithDataSourceComponent(props) {
		const {dataSource, ...restProps} = props;
		const {state, dispatch} = React.useContext(TableContext);

		const filters = state[VARS.FILTERS];
		const sorting = state[VARS.SORTING];
		const sortingOrder = Object.keys(sorting);
		const page = state[VARS.PAGE]
		
		const sorters = React.useMemo(() => {
			return sortingOrder.map((field) => ({
				field,
				dir: sorting[field]
			}));
		}, [sorting])

		React.useEffect(() => {
			dispatch({ type: ACTIONS.SET_PAGE, value: 0 });
		}, [dataSource])
		
		const [data, filterOptions, loadingState, filteredBy] = useDataSource(dataSource, page, filters, sorters);
		const indexKey = state[VARS.INDEX_KEY];

		React.useEffect(() => {
			const ids = data.map((item) => item[indexKey]);
			dispatch({ type: ACTIONS.SET_ALL_IDS, value: ids });
		}, [data, indexKey]);

		const handleNextPage = React.useCallback(() => {
			dispatch({ type: ACTIONS.SET_PAGE, value: (page + 1) });
		}, [page]);

		React.useEffect(() => {
			dispatch({ type: ACTIONS.SET_FILTER_OPTIONS, value: filterOptions });
		}, [filterOptions]);

		React.useEffect(() => {
			dispatch({ type: ACTIONS.SET_FILTER_BY, value: filteredBy });
		}, [filteredBy])

		

		return <WrappedComponent
			data={data}
			loadingState={loadingState}
			onNextPage={handleNextPage}
			currentPage={page}
			{...restProps} />
	}

	WithDataSourceComponent.propTypes = {
		dataSource: PropTypes.object
	}

	return WithDataSourceComponent;
}

export default withDataSource;
