import {Api, State} from 'tools'
import Widget from '../widget'
import Settings from 'settings'
import {Cookies} from "core";

export function ImageWidget(config) {
	Widget.call(this, config);

	this.requestPath = Settings.serverPath;
	if (this.sessionId) {
		this.requestPath = Settings.serverPath + 'sessions/' + this.sessionId + '/';
		this.mode = 'detached';
	}
	this.initComponent();
};

export {ImageWidget as default}

jQuery.extend(ImageWidget.prototype, Widget.prototype, {
	/**
	 * Main init function
	 */
	initComponent: function () {
		this.render();
	},
	/**
	 * Renders the widget
	 */
	render: function () {
		var widgetContentDiv = $('#' + this.id).find('.cw_section_content');
		widgetContentDiv.addClass('custom-image');
		var url = Api.images.urls.image(this.instanceConfiguration.imageId, this.instanceConfiguration.accountId, this.sessionId);
		var transparentClass = this.instanceConfiguration.isTransparent ? 'is_transparent' : '';
		var widgetWidth, widgetHeight;
		if (this.instanceConfiguration.stretch) {
			widgetWidth = widgetContentDiv.width();
			widgetHeight = widgetContentDiv.height() - 5;
		}
		widgetContentDiv.html('<div class="cw_custom_image_widget"><img class="cw_widget_image" src="' + url + '" width="' + widgetWidth + '" height="' + widgetHeight + '"></div>');
		widgetContentDiv.closest('.k-window').addClass(transparentClass);
		if (this.instanceConfiguration.removeBorder && this.serviceBoardData.layout === 'free') {
			$('#' + this.id).closest('.k-window').css({
				'box-shadow': 'none',
				'border-radius': '0'
			});
		}
	},

	onResize: function () {
		this.render();
	},

	/**
	 * Called when an event is received from the server
	 */
	onEvent: function () {
		this.render();
	},
	/**
	 * Destroy
	 */
	destroy: function () {

	}
});
