export const RedirectType = Object.freeze({
	Default: 'Default',
	Custom: 'Custom',
	Dashboard: 'Dashboard',
	None: 'None'
})

export const defaultRedirectConfig = {
	type: RedirectType.Default
}
