import {Application, LocalEventsManager, Cookies, RemoteEventsManager, ErrorHandler} from 'core';
import {
	CustomNotification, Dependencies,
	Dialog,
	EventsToolbar,
	GridMenu,
	ModalView,
	ModalWindow,
	MultiToggle,
	PreferencesPanel
} from 'controls';
import {Utils, UserPrefs, State} from 'tools';
import Settings from 'settings';
import UserForm from './userForm';
import Api from 'tools/api';
import {CalendarsAPI} from "./calendar/api";

export let CalendarsView = function (config) {
	Utils.apply(this, config);
	this.hasEvents = true;
	this.subscriberId = Utils.guid();
	this.initComponent();
};

export default CalendarsView;

jQuery.extend(CalendarsView.prototype, Application.prototype, {
	/**
	 * Users view application related initializations
	 */
	initComponent: function () {
		Application.prototype.initComponent.call(this);

		this.actionNotification = new CustomNotification({
			appendToElement: '.window_area',
			status: 'success',
			style: 'top:15px; right:15px; left:15px;',
		});

		this.defaultColumns = {
			id: {
				hidden: false,
				width: 40
			},
			name: {
				hidden: false,
				width: 200
			},
			calendarType: {
				hidden: false,
				width: 200
			},
			description: {
				hidden: false
			}
		};
		this.loadUserPreferences();

		// update content button
		State.mainApp.contextMenu.setActionButton('CALENDAR');
		this.cardGridToggle = new MultiToggle({
			id: 'cw_calendars_view_toggle',
			cssClass: 'cw_multi_toggle right',
			value: 'false',
			items: [{
				id: 'cw_grid_view',
				title: lang.GRID_VIEW,
				selected: false,
				cssClass: 'glyphicons justify',
				fn: this.onViewToggle,
				scope: this,
				value: 'true'
			}, {
				id: 'cw_card_view',
				title: lang.CARD_VIEW,
				selected: true,
				cssClass: 'glyphicons show-big-thumbnails',
				fn: this.onViewToggle,
				scope: this,
				value: 'false'
			}]
		});
		this.gridMenu = new GridMenu({
			renderTo: 'cw_calendars_grid_menu',
			items: [{
				id: 'cw_create',
				icon: 'plus-sign',
				text: lang.CREATE,
				fn: State.mainApp.onNewCalendar,
				scope: State.mainApp,
				role: 'CALENDAR_CREATE'
			}, {
				id: 'cw_delete',
				icon: 'bin',
				text: lang.DELETE,
				fn: this.onCalendarDelete,
				scope: this,
				disabled: true,
				role: 'CALENDAR_DELETE'
			}]
		});
		this.removeMask();
		this.removeListeners();
		this.attachListeners();
	},
	/**
	 * Handler for the view toggle buttons click
	 * @param {Object} e The click event
	 */
	onViewToggle: function (value) {
		if (value === 'true') {
			if (this.grid && this.gridViewLoaded) {
				this.showGridView = true;
				$('#cw_calendars_list').addClass('hide');
				$('#cw_calendars_grid').removeClass('hide');
				this.grid.refresh();
			} else {
				this.initGridView();
				this.grid.refresh();
			}
		} else {
			$('#cw_calendars_grid').addClass('hide');
			if (this.innerView) {
				this.showGridView = false;
				$('#cw_calendars_list').removeClass('hide');
			}
		}
	},
	/**
	 * Removes the listeners for the form's buttons
	 */
	removeListeners: function () {
		$('#calendars_grid').off();
		this.onCalendarSavedHandler = $.proxy(this.onCalendarSaved, this);
		LocalEventsManager.unbind('calendarsaved', this.onCalendarSavedHandler);
	},
	/**
	 * Attaches the listeners for the form's buttons
	 */
	attachListeners: function () {
		$('#calendars_grid').on('click', '.cw_grid_check', $.proxy(this.onCheck, this));
		$('#calendars_grid').on('click', '.cw_grid_check_all', $.proxy(this.onCheckAll, this));
		$('#calendars_grid').on('click', '.cw_grid_link', $.proxy(this.onCalendarNameClick, this));
		LocalEventsManager.bind('calendarsaved', this.onCalendarSavedHandler);
	},
	/**
	 * Init grid component
	 * @param {boolean} showGrid Defaults to true
	 */
	initGridView: function (showGrid) {
		var filterMessages = lang.grid.filter,
			loadGrid = true;
		this.gridMessages = {
			clear: lang.CLEAR,
			info: lang.grid.filter.SHOW_ITEMS,
			filter: lang.FILTER
		};
		if (showGrid === false) {
			loadGrid = false;
			this.gridViewLoaded = false;
		} else {
			this.gridViewLoaded = true;
		}
		if (loadGrid) {
			this.showGridView = true;
			$('#cw_calendars_view_toggle').find('li').first().addClass('is_selected');
			$('#cw_filter_reset').removeClass('hide');
			$('#cw_calendars_list').addClass('hide');
			$('#cw_calendars_grid').removeClass('hide');
		}

		var calendarColumns = this.calendarColumns || JSON.parse(UserPrefs.get('calendarColumns')) || this.defaultColumns;
		calendarColumns = Utils.completeColumns(calendarColumns, this.defaultColumns);

		this.grid = $('#calendars_grid').kendoCustomGrid({
			dataSource: this.dataSource,
			resizable: true,
			reorderable: true,
			selectable: 'row',
			autoBind: true,
			sortable: {
				mode: 'multiple',
				allowUnsort: true
			},
			filterable: {
				extra: false,
				operators: {
					string: {
						startswith: filterMessages.STARTS_WITH,
						neq: filterMessages.NEQ,
						eq: filterMessages.EQ
					}
				},
				messages: this.gridMessages
			},
			columns: Utils.rearrangeColumns([{
				field: 'id',
				title: lang.SELECTOR,
				sortable: false,
				filterable: false,
				menu: false,
				template: '<input type="checkbox" class="cw_grid_check" data-id="#= id #" />',
				headerTemplate: '<input type="checkbox" class="cw_grid_check_all" />',
				headerAttributes: {
					'class': 'text_center'
				},
				attributes: {
					'class': 'text_center'
				},
				hidden: calendarColumns.id.hidden,
				width: calendarColumns.id.width
			}, {
				field: 'name',
				title: lang.NAME,
				sortable: true,
				filterable: true,
				template: '<a class="cw_grid_link ellipsis to_expand" data-id="#= id #">#= name #</a>',
				hidden: calendarColumns.name.hidden,
				width: calendarColumns.name.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'calendarType',
				title: lang.TYPE,
				sortable: true,
				filterable: true,
				hidden: calendarColumns.calendarType.hidden,
				width: calendarColumns.calendarType.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'description',
				title: lang.DESCRIPTION,
				sortable: true,
				filterable: true,
				hidden: calendarColumns.description.hidden,
				//width: calendarColumns.description.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}], calendarColumns),
			columnMenu: true,
			change: $.proxy(this.onRowExpand, this),
			dataBound: $.proxy(this.onDataBound, this)
		}).data('kendoCustomGrid');
		// Add Kendo tooltip to the header of the columns
		Utils.gridColumnHeaderTooltip(this.grid);
		this.adjustSectionHeight();
		this.grid.thead.find("[data-field='id']>.k-header-column-menu").remove();
		var searchValue = UserPrefs.get('defaultFsView') ? '' : (UserPrefs.get('calendarsSearchPhrase') || '');
		this.gridFilterPanel = new PreferencesPanel({
			renderTo: 'cw_calendars_filters',
			grid: this.grid,
			modulePrefName: 'AdministrationCalendars',
			defaultPrefViewKey: 'defaultFsView',
			prefListKey: 'calendarsFSViews',
			userPref: this.userPref,
			onRemove: $.proxy(this.saveUserPreferences, this),
			searchFields: ['name', 'calendarType', 'description'],
			searchValue: searchValue,
			defaultColumns: this.defaultColumns
		});
	},
	/**
	 * Handler function for the change(select) event on the grid
	 * @param {Object} e The change event object
	 */
	onRowExpand: function (e) {
		var selectedRow = this.grid.select();
		var myRow = selectedRow[0];
		var messageEl = $(myRow).find('.to_expand');
		if ($(messageEl).hasClass('cw_message_expanded')) {
			$(messageEl).removeClass('cw_message_expanded').addClass('ellipsis');
		} else {
			$('#calendars_grid').find('.k-grid-content').find('td.cw_message_expanded').removeClass('cw_message_expanded').addClass('ellipsis');
			$(messageEl).addClass('cw_message_expanded').removeClass('ellipsis');
		}
	},
	/**
	 * Handler function for the grid data bound event
	 */
	onDataBound: function () {
		$('#calendars_grid').find('.k-grid-content').kendoTooltip({
			filter: "td.tooltip:not(:empty)",
			content: function (event) {
				return event.target.text().split("\n").join("<br />");
			}
		});
		if (this.initialCalendarsLength && this.dataSource.data().length < this.initialCalendarsLength) {
			this.actionNotification.setOptions({
				message: lang.administration.CALENDARS_DELETED,
				status: 'success'
			}).show();
		}
	},
	/**
	 * Handler for the service checkbox click
	 * @param {Object} e The click event
	 */
	onCheck: function (e) {
		e.stopPropagation();
		var checkboxes = $('.cw_grid_check');
		var users = [];
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			if ($(checkboxes[i]).is(':checked')) {
				users.push($(checkboxes[i]).data('id'));
			}
		}
		if (users.length) {
			this.gridMenu.enableItem('cw_delete');
		} else {
			this.gridMenu.disableItem('cw_delete');
		}
	},
	/**
	 * Handler for the check all checkbox click
	 * @param {Object} e The click event
	 */
	onCheckAll: function (e) {
		var checkAll = $('.cw_grid_check_all').is(':checked');
		var checkboxes = $('.cw_grid_check');
		var i, length = checkboxes.length;
		for (i = 0; i < length; i++) {
			$(checkboxes[i]).prop('checked', checkAll);
		}
		if (checkAll && checkboxes.length) {
			this.gridMenu.enableItem('cw_delete');
		} else {
			this.gridMenu.disableItem('cw_delete');
		}
	},
	/**
	 * Handler for the delete button click
	 * @param {Object} e The click event
	 */
	onCalendarDelete: function (e) {
		var checkboxes = $('.cw_grid_check');
		var calendars = [];
		var checked = 0;
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			if ($(checkboxes[i]).is(':checked')) {
				calendars.push($(checkboxes[i]).data('id'));
				checked++;
			}
		}
		this.selectedCalendars = calendars;
		this.initialCalendarsLength = this.dataSource.data().length;
		if (calendars.length) {
			this.calendarIndex = 0;
			var dialog = new Dialog({
				title: lang.INFO,
				msg: checked > 1 ? lang.administration.CALENDARS_REMOVE_CONFIRMATION : lang.administration.CALENDAR_REMOVE_CONFIRMATION,
				icon: 'ERROR',
				actionText: 'DELETE',
				fn: $.proxy(function (value, button) {
					if (button === 'ok') {
						kendo.ui.progress($('#calendars_grid'), true);
						this.checkCalendarDependencies();
					}
				}, this),
				deleteFromGrid: this.grid
			});
			dialog.show();
		}
	},
	checkCalendarDependencies() {
		var calendarId = this.selectedCalendars[this.calendarIndex++];
		var record, calendarName;
		if (calendarId) {
			record = this.dataSource.get(calendarId);
			calendarName = record.name;

			var dependenciesUrl = Settings.serverPath + 'calendars/' + calendarId + '/dependencies';
			Utils.ajax(dependenciesUrl, 'GET', {}, $.proxy(function (data) {
				if (data.length) {
					this.openDependenciesPopup(data, calendarName, calendarId);
					//setTimeout(this.adjustTableHeight, 100);
				} else {
					var deleteUrl = Settings.serverPath + 'calendars/delete';
					Utils.ajax(deleteUrl, 'POST', JSON.stringify([calendarId]), $.proxy(function (result) {
						if (result.success) {
							this.checkCalendarDependencies();
							//this.grid.dataSource.read();
						} else {
							this.resetCheckingCalendars();
							Utils.showInfo(lang.ALERT, result.message, result.details);
							//this.gridMenu.enableItem('cw_delete');
						}
					}, this));
				}
			}, this));
		} else {
			kendo.ui.progress($('#calendars_grid'), false);
			this.grid.dataSource.read();
			if (this.dependenciesWindow) {
				this.dependenciesWindow.close();
			}
			//this.gridMenu.enableItem('cw_delete');
		}
	},

	resetCheckingCalendars: function () {
		this.selectedCalendars = [];
		this.calendarIndex = 0;

		kendo.ui.progress($('#calendars_grid'), false);
		this.grid.dataSource.read();
	},

	openDependenciesPopup: function (dependencies, calendarName, calendarId) {
		if (this.dependencyWindow) {
			this.dependenciesWindow.close();
		}

		this.dependenciesWindow = new ModalWindow({
			title: lang.INFO,
			width: 960,
			height: 640,
			minWidth: 220,
			actions: [],
			url: 'include/Dependencies.jsp',
			//resize: this.adjustTableHeight,
			onClose: $.proxy(function () {
				this.clearCheckboxes();
				this.checkCalendarDependencies();
			}, this),
			refresh: $.proxy(function () {
				$('#cw_dependencies_deleteall').removeClass('hide');
				this.dependencyWindow = new Dependencies({
					accountId: Cookies.CeesoftCurrentAccountId,
					dependencies: dependencies,
					name: calendarName,
					container: '#calendars_grid',
					selectedChecks: calendarId,
					infoTemplate: kendo.template(lang.administration.CALENDARS_DEPENDENCIES_MESSAGE)({
						calendarName: calendarName
					}),
					deleteAll: $.proxy(function (e) {
						var deleteUrl = Settings.serverPath + 'calendars/' + calendarId + '/dependencies/deleteAll';
						Utils.ajax(deleteUrl, 'DELETE', {}, $.proxy(function (result) {
							if (result.success) {
								this.checkCalendarDependencies();
							} else {
								Utils.showInfo(lang.ALERT, result.message, result.details);
								this.resetCheckingCalendars();
							}
						}, this));
					}, this)
				});
				$('#cw_dependencies_deleteall').text(lang.administration.CALENDARS_REMOVE_DEPENDENCIES);
			}, this)
		});
		this.dependenciesWindow.open();
	},
	clearCheckboxes: function () {
		var checkAll = $('#calendars_grid').find('.cw_grid_check_all');
		var	checkboxes = $('#calendars_grid').find('.cw_grid_check');
		var	i, length = checkboxes.length;
		checkAll.prop('checked', false);
		for (i = 0; i < length; i++) {
			$(checkboxes[i]).prop('checked', false);
		}
	},
	/**
	 * Handler function for the click event on the users name
	 * @param {Object} e The click event object
	 */
	onCalendarNameClick: function (e) {
		let target = $(e.target);
		let id = target.closest('tr').find('.cw_grid_check').attr('data-id');
		let item = this.dataSource.get(id);
		let name = item.name;
		let calendarType = item.calendarType;
		State.mainApp.loadModule('AdministrationCalendarsDetails', null, {
			id: id,
			calendarType: calendarType,
			mode: 'update'
		}, e);
		e.stopPropagation();
	},
	/**
	 * Saves user preferences
	 */
	saveUserPreferences: function () {
		var preferences = [{
			key: 'showGridView',
			value: this.showGridView
		}];
		this.saveUserPrefs({
			category: 'AdministrationCalendars',
			filterPanel: this.gridFilterPanel,
			grid: this.grid,
			eventsToolbar: this.eventsToolbar,
			preferences: preferences,
			keys: {
				searchPhrase: 'calendarsSearchPhrase',
				columns: 'calendarColumns',
				sort: 'calendarsSort',
				filter: 'calendarsFilter',
				FsViews: 'calendarsFSViews'
			}
		});
	},
	createDataSource: function () {
		this.dataSource = new kendo.ceeview.DataSource({
			transport: {
				read: {
					url: Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/calendars',
					contentType: "application/json; charset=utf-8",
					type: "GET",
					dataType: "json",
					cache: false
				}
			},
			schema: {
				model: {
					id: 'id',
					fields: {
						name: {
							type: 'string'
						},
						calendarType: {
							type: 'string'
						},
						description: {
							type: 'string'
						}
					}
				},
				parse: $.proxy(function (result) {
					if (result.success) {
						return result.data;
					}
				}, this)
			},
			sort: this.calendarsSort,
			filter: this.calendarsFilter
		});
	},
	/**
	 * Loads user preferences
	 */
	loadUserPreferences: function () {
		var defaultFsView, showGridView, pref;
		UserPrefs.load('AdministrationCalendars', $.proxy(function (result) {
			if (result.success) {
				this.userPref = result.data;
				showGridView = UserPrefs.get('showGridView');
				defaultFsView = UserPrefs.get('defaultFsView');

				if (Utils.isGuid(defaultFsView)) {
					pref = JSON.parse(UserPrefs.get(defaultFsView));
					pref = pref || {
							sort: [],
							filter: []
						};
					this.calendarsSort = pref.sort;
					this.calendarsFilter = pref.filter;
					this.calendarColumns = pref.columns;
				} else {
					if (UserPrefs.get('calendarsSort')) {
						this.calendarsSort = JSON.parse(UserPrefs.get('calendarsSort'));
					} else {
						this.calendarsSort = [{
							field: 'name',
							dir: 'asc',
							compare: null
						}];
					}
					if (UserPrefs.get('calendarsFilter')) {
						this.calendarsFilter = JSON.parse(UserPrefs.get('calendarsFilter'));
					} else {
						this.calendarsFilter = [];
					}
				}

				if (showGridView) {
					this.showGridView = showGridView === 'true' ? true : false;
				} else {
					this.showGridView = true;
				}
				this.cardGridToggle.select(this.showGridView ? 'cw_grid_view' : 'cw_card_view');

				this.createDataSource();
				var eventStatus = JSON.parse(UserPrefs.get('eventsStatus')) || {
						startFrom: 0,
						playing: true
					};
				this.eventsToolbar = new EventsToolbar({
					renderTo: $('#cw_calendars_events_toolbar'),
					callBackFn: $.proxy(this.onEventsTimeout, this),
					subscriberId: this.subscriberId,
					startFrom: eventStatus.startFrom,
					playing: eventStatus.playing
				});
				if (this.showGridView) {
					this.initGridView();
				} else {
					this.initGridView(false);
				}
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},
	/**
	 * Method by Andy
	 */
	adjustSectionHeight: function () {
		var section = $('.cw_section_full');
		var sectionHeight = section.height();
		section.find('.cw_section_content').css('height', sectionHeight);
		section.find('.k-grid-content').css('height', sectionHeight - 40);
	},
	/**
	 * Handler function for the timeout counter
	 * @param {Object|Array} events A single event or an array of events
	 */
	onEventsTimeout: function (events) {
		this.dataSource.read();
	},
	/**
	 * Destroy
	 */
	destroy: function () {
		this.saveUserPreferences();
		if (this.eventsToolbar) {
			this.eventsToolbar.destroy();
		}
		Application.prototype.destroy.call(this);
	}
});
