import { Checkbox, DropDownList, FormEntry, MultiSelect, MultiToggle } from "controls/react/form";
import { CollapsibleFrame } from "controls/react/layout/collapsibleFrame";
import { translator } from "core";
import React, { useMemo, useCallback } from 'react';
import { Utils } from "tools";
import { aggregationTypeDataSource } from "../common/dataSources/aggregationTypeDataSource";
import { informationalDataSource } from "../common/dataSources/informationalDataSource";
import { legendDataSource } from "../common/dataSources/legendDataSource";
import { periodsDataSource } from "../common/dataSources/periodsDataSource";
import { getSelectorTypeDataSource, LEGEND_TYPE } from "../common/dataSources/selectorTypeDataSource";
import { timezoneDataSource } from "../common/dataSources/timezoneDataSource";
import {useContextField} from 'core/react/reducerHelpers';
import Lang from "core/localization/lang";
import { DateTimePicker } from "controls/react/kendoWrappers";

const b = require('b_').with('display-settings-frame');

DisplaySettingsFrame.propTypes = {

};

const i = translator({
	"Description type": {
		"no": "Beskrivelsetype"
	},
	"Aggregation": {
		"no": "Aggregering"
	}
});

function DisplaySettingsFrame(props) {
	const periodLink = useContextField(props.context, "configuration.period");
	const startDateLink = useContextField(props.context, "configuration.startDate");
	const endDateLink = useContextField(props.context, "configuration.endDate");
	const hideTimeLink = useContextField(props.context, "configuration.hideTimeSelector");
	const aggregationTypeLink = useContextField(props.context, "configuration.aggregationType");
	const ignoreMissingDataLink = useContextField(props.context, "configuration.ignoreMissingData");
	const useRawDataLink = useContextField(props.context, "configuration.useRawData");
	const timezoneLink = useContextField(props.context, "configuration.timezone");
	const legendTypeLink = useContextField(props.context, "configuration.legendType");
	const informationalTemplateLink = useContextField(props.context, "configuration.informationalTemplate");
	const labelTemplateLink = useContextField(props.context, "configuration.labelTemplate");

	const timezoneDS = useMemo(() => timezoneDataSource(), []);
	const selectorTypeDataSource = useMemo(() => getSelectorTypeDataSource(""), []);

	return (
		<div className={b()}>
			<CollapsibleFrame expanded={props.expanded}
			                  title={Lang.widget.DISPLAY_SETTINGS}>
				<FormEntry label={i('Period')}>
					<div className={b('nowrap')}>
						<MultiToggle items={periodsDataSource} {...periodLink.props} />
						<div className={b('buffer')}/>
						{!props.hideTimeSelector && <Checkbox label={Lang.widget.HIDE_TIME_SELECTOR} {...hideTimeLink.props} />}
					</div>
				</FormEntry>
				{periodLink.value === 'CUSTOM' &&
				<div className={b('custom-time-selector')}>
					<DateTimePicker containerClass={'inline'} value={startDateLink.value ? new Date(startDateLink.value) : ''} {...startDateLink.props}/>
					<DateTimePicker containerClass={'inline'} value={endDateLink.value ? new Date(endDateLink.value) : ''} min={startDateLink.value ? new Date(startDateLink.value) : new Date(0)} {...endDateLink.props}/>
				</div>}
				<FormEntry label={i('Aggregation')}>
					<DropDownList dataSource={aggregationTypeDataSource} {...aggregationTypeLink.props} />
				</FormEntry>
				<div className={b('nowrap')}>
					<div className={b('buffer')}>
						<Checkbox label={Lang.serviceBoard.IGNORE_MISSING_DATA} {...ignoreMissingDataLink.props} />
					</div>
					<div className={b('buffer')}>
						<Checkbox label={Lang.serviceBoard.RAW_DATA} {...useRawDataLink.props} />
					</div>
				</div>
				<FormEntry label={i('Timezone')}>
					<DropDownList dataSource={timezoneDS} {...timezoneLink.props} autoBind={true} combo={true} plainArray={true} />
				</FormEntry>
				{legendTypeLink.props.value === LEGEND_TYPE.INFORMATIONAL && <FormEntry label={i('Informational')} vertical={true}>
					<MultiSelect
						data={informationalDataSource}
						containerClass={'w100'}
						{...informationalTemplateLink.props}
					/>
				</FormEntry>}
				{/*{legendTypeLink.props.value === LEGEND_TYPE.LEGEND && <FormEntry label={i('Legend')} vertical={true}>*/}
				{/*	<MultiSelect*/}
				{/*		data={legendDataSource}*/}
				{/*		containerClass={'w100'}*/}
				{/*		{...labelTemplateLink.props}*/}
				{/*	/>*/}
				{/*</FormEntry>}*/}
			</CollapsibleFrame>
		</div>
	);
}

export default DisplaySettingsFrame;
