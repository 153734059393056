import React from "react";

import { useSelector } from "react-redux";
import { getItemValue } from "./selectors/getItemValue";

import Group from "./group";

export default function Form(props) {
    const {formIndex, formGroups} = props;

    const groups = useSelector(state => getItemValue(state, 'groups'));
    const enabled = useSelector(state => getItemValue(state, ['formEnabled', formIndex]));

    if (!groups) {
        return null;
    }

    return <div className="qualifier-wizard-form__form">
        {!enabled && <div className="qualifier-wizard-form__overlay" />}
        {formGroups.map((groupId, index) => (
            <Group fields={groups.get(groupId)} groupId={groupId} key={index} multipleLink={props.multipleLink}/>
        ))}
    </div>
}