function expandGroups(components, groups) {
    let newComponents = [];

    for (const component of components) {
        if (component in groups) {
            newComponents = [...newComponents, ...groups[component]];
        } else {
            newComponents.push(component)
        }
    }

    return newComponents;
};

export function mapFieldToFormDependencies(forms, groups, formToDependencyFields) {
    const out = {};

    for (let [i, components] of forms.entries()) {
        const dependencies = formToDependencyFields[i];
        const fields = expandGroups(components, groups);

        for (const field of fields) {
            out[field] = dependencies;
        }
    }

    return out;
};
