import Utils from 'tools/utils';
import Cookies from 'core/cookies';
import Widget from 'areas/service-boards/widget';
import Settings from 'settings';
import CustomNotification from 'controls/customNotification';
import Highcharts from 'highcharts';
import RemoteEventsManager from 'core/remoteEventsManager';
import Renderer from 'tools/renderer';
import Configuration from 'configuration';
import moment from 'moment';
import {translator as i} from "core";
import {State} from 'tools';
import {ServicesRouter} from 'areas/services/bundleDescription';
import ErrorCodes from 'tools/errorCodes';
import {getLegendLabelFormatter, getTrendValueWidgetWrapper} from "vendor-init/hightcharts-intergation";

export function MultigraphWidget(config) {
	Widget.call(this, config);

	this.requestPath = Settings.serverPath;
	if (this.sessionId) {
		this.requestPath = Settings.serverPath + 'sessions/' + this.sessionId + '/';
	}

	this.instanceConfiguration.timezone = this.instanceConfiguration.timezone === "<User Time>" ? Cookies.CeesoftTimezone : this.instanceConfiguration.timezone;

	this.initComponent();
};

export {MultigraphWidget as default};


jQuery.extend(MultigraphWidget.prototype, Widget.prototype, {
	/**
	 * Main init function
	 */
	initComponent: function () {
		if (!this.renderTo) {
			this.renderTo = $('#' + this.id).find('.cw_section_content');
		} else {
			this.renderTo = $('#' + this.renderTo);
		}
		this.widgetContentDiv = this.renderTo;

		//this.hasTimeSelector = true;
		this.hasToggleTimeSelector = true;
		this.hasMissingData = true;
		this.hasRegression = true;

		if (!this.customControls) {
			this.customControls = {
				target: '#' + this.id,
				toggleClick: function (value) {
					if ($('#' + this.id).find('.k-i-toggle').length) {
						$('#' + this.id).find('.k-i-toggle').trigger('click');
					} else {
						$('#' + this.id).closest('.k-window').find('.k-i-toggle').trigger('click');
					}
				}
			};
		}
		this.createCustomControls();

		//backward compatibility
		if (this.instanceConfiguration) {
			this.instanceConfiguration.type = this.instanceConfiguration.type || 'SERVICE';
		}
		this.intervals = {};

		this.subscribe();
		if (this.instanceConfiguration.type === 'SERVICE') {
			this.getData();
		} else {
			this.getNames();
		}
		this.statusNotification = new CustomNotification({
			animationTime: 0,
			appendToElement: this.widgetContentDiv,
		});

		this.setTitleIfEmpty(i('Multigraph'));
	},
	/**
	 * Get chart data
	 */
	getData: function () {
		var url, startDate, endDate, width = this.renderTo.width() * 0.6, period;

		width = Math.max(width, 300);
		this.roundRobinPoints = width;

		period = Utils.getPeriodInterval({
			period: this.zoomPeriod || this.instanceConfiguration.period,
			startDate: this.zoomStartDate || this.instanceConfiguration.startDate,
			endDate: this.zoomEndDate || this.instanceConfiguration.endDate,
			width: width
		});
		this.interval = period.interval;

		var urlPath = '', data = [];
		if (this.instanceConfiguration.type === 'SERVICE') {
			urlPath = 'serviceQualifiers';
			data = this.instanceConfiguration.qualifiersList || [];
		} else if (this.instanceConfiguration.type === 'MONITOR') {
			urlPath = 'monitorQualifiers';
			data = this.metricsData || [];
		}

		if (this.zoomPeriod === 'CUSTOM' || this.instanceConfiguration.period === 'CUSTOM') {
			startDate = new Date(this.zoomStartDate || parseInt(this.instanceConfiguration.startDate));
			endDate = new Date(this.zoomEndDate || parseInt(this.instanceConfiguration.endDate));

			url = this.requestPath + 'metrics/' + urlPath + '?ignoreMissingData=' + this.instanceConfiguration.ignoreMissingData + '&timeZone=' + this.instanceConfiguration.timezone + '&interval=' + this.interval + '&startDate=' + startDate.getTime() + '&endDate=' + endDate.getTime();
		} else {
			url = this.requestPath + 'metrics/' + urlPath + '?ignoreMissingData=' + this.instanceConfiguration.ignoreMissingData + '&timeZone=' + this.instanceConfiguration.timezone + '&interval=' + this.interval + '&timeSelector=' + this.instanceConfiguration.period;
		}

		kendo.ui.progress(this.renderTo, true);
		Utils.ajax(url, 'POST', JSON.stringify(data), $.proxy(function (result) {
			var qualifier, isBooleanMetric, set = [], length = result.length, dataSet, min, max, errors = [], data

			this.chartData = [];
			this.qualifierSeriesKeys = [];
			this.qualifierNames = [];
			this.booleanMetricsArray = [];
			this.booleanMetricsOnly = true;

			if (!length) {
				if (!this.onZoom) {
					this.renderTo.empty();
				}
				kendo.ui.progress(this.widgetContentDiv, false);
				this.statusNotification.setOptions({
					message: result.message,
					status: 'error'
				}).show();
			} else {
				var k = 0, fullName, name, emptyItems = 0;
				this.noData = [];
				this.qualifierData = {};

				for (var i = 0; i < length; i++) {
					if ((this.instanceConfiguration.type === 'SERVICE' && result[i].found && result[i].hasMetrics) || (this.instanceConfiguration.type === 'MONITOR' && result[i].hasMetrics)) {
						this.intervals[result[i].qualifier.id] = 1;
						if (this.instanceConfiguration.type === 'SERVICE') {
							this.qualifierNames[result[i].qualifier.id] = result[i].qualifier.accountName + '' + result[i].qualifier.serviceName + '' + result[i].qualifier.name;
						} else {
							var configuration = Utils.getFromListById(this.instanceConfiguration.qualifiers, result[i].qualifier.id).configuration;
							var name = Utils.getFromListById(this.instanceConfiguration.qualifiers, result[i].qualifier.id).name;
							result[i].qualifier.configuration = configuration;
							result[i].qualifier.name = name;
							this.qualifierNames[result[i].qualifier.id] = name;
						}
						dataSet = result[i].data;

						this.qualifierData[result[i].qualifier.id] = dataSet;

						this.qualifierSeriesKeys[result[i].qualifier.id] = k++;

						data = [];
						this.aggregate = false;
						qualifier = result[i].qualifier;

						isBooleanMetric = qualifier.configuration.metricType === 'BOOLEAN';
						if (isBooleanMetric) {
							this.booleanMetricsArray.push(result[i].qualifier.id);
						} else {
							this.booleanMetricsOnly = false;
						}

						if (dataSet[0].vH !== undefined) {
							this.aggregate = true;
						}

						if (isBooleanMetric) {
							min = 0;
							max = 1;
						} else {
							if (dataSet[0].vL !== undefined && this.instanceConfiguration.chartType === 'range') {
								min = Utils.getMinFromObjectArray(dataSet, 'vL');
								max = Utils.getMaxFromObjectArray(dataSet, 'vH');
							} else {
								min = Utils.getMinFromObjectArray(dataSet, 'v');
								max = Utils.getMaxFromObjectArray(dataSet, 'v');
							}
						}

						for (var j = 0, dataSetLength = dataSet.length; j < dataSetLength; j++) {
							set = [];

							var noDataState;
							if (dataSet[j].v === null) {
								this.noData.push([dataSet[j].t, min, max]);
								noDataState = true;
							} else {
								if (noDataState) {
									this.noData.push([dataSet[j].t, min, max]);
									noDataState = false;
								} else {
									if (!this.noData.length || this.noData[this.noData.length - 1][1] !== null) {
										this.noData.push([dataSet[j].t, null, null]);
									}
								}
							}

							if (dataSet[j].e[0] !== 0) {
								set = {
									x: dataSet[j].t,
									t: dataSet[j].t,
									y: Utils.aggregate(this.instanceConfiguration.aggregationType, dataSet[j]),
									marker: {
										enabled: true,
										fillColor: '#FF0000',
										symbol: 'circle',
										radius: 4
									}
								};
							} else if (this.aggregate && this.instanceConfiguration.chartType === 'range') {
								set = [dataSet[j].t, dataSet[j].vL, dataSet[j].vH];
							} else {
								set = [dataSet[j].t, Utils.aggregate(this.instanceConfiguration.aggregationType, dataSet[j])];
							}

							data.push(set);
						}

						qualifier.assetName = qualifier.configuration.assetName;

						fullName = this.generateMultigraphLabel(qualifier);
						name = fullName.length > 60 ? fullName.substr(0, 57) + '...' : fullName;

						this.chartData.push({
							regression: this.instanceConfiguration.showRegression,
							marker: {
								enabled: false
							},
							regressionSettings: {
								marker: {
									enabled: false
								},
								name: name,
								fullName: fullName,
								guid: Utils.guid(),
								type: 'linear',
								color: 'rgba(255,165,0, 1)'
							},
							dataGrouping: {
								approximation: this.instanceConfiguration.aggregationType || "average"
							},
							step: isBooleanMetric,
							fullName: fullName,
							qualifierId: qualifier.id,
							qualifierServiceId: qualifier.serviceId,
							name: name,
							data: data
						});

						if (this.aggregate) {
							this.intervals[qualifier.id] = this.interval;
						}
					} else {
						emptyItems++;
					}
				}

				if (!name) {
					for (let k = 0; k < this.chartData.length; k++) {
						this.chartData[k].showInLegend = false;
					}
				}

				if (this.noData.length) {
					this.chartData.push({
						type: 'arearange',
						color: '#CCCCCC',
						data: this.noData,
						name: 'NODATA_AREA',
						zIndex: 1,
						showInLegend: false
					});
				}

				if (emptyItems === length) {
					if (!this.onZoom) {
						this.renderTo.empty();
					}
					kendo.ui.progress(this.widgetContentDiv, false);
					this.statusNotification.setOptions({
						message: result.message || lang.messages.NO_DATA_AVAILABLE,
						status: 'error'
					}).show();
				} else {
					this.render(dataSet);
				}
			}
		}, this), null, 60000, $.proxy(function () {
			kendo.ui.progress(this.widgetContentDiv, false);
			this.statusNotification.setOptions({
				message: lang.messages.NO_DATA_AVAILABLE,
				status: 'error'
			}).show();
		}, this));
	},
	getNames: function () {
		this.names = [];
		var data = [];
		this.metricsData = [];
		for (var i = 0, length = this.instanceConfiguration.qualifiers.length; i < length; i++) {
			var qualifier = this.instanceConfiguration.qualifiers[i];
			data.push({
				accountId: qualifier.accountId,
				assetId: qualifier.assetId,
				monitorId: qualifier.monitorId
			});
			this.metricsData.push({
				id: qualifier.id,
				accountId: qualifier.accountId,
				assetId: qualifier.assetId,
				monitorId: qualifier.monitorId
			});
		}
		var url = this.requestPath + 'monitors/names';
		Utils.ajax(url, 'POST', JSON.stringify(data), $.proxy(function (result) {
			if (result.success) {
				this.names = result.data;
				this.getData();
			} else {
				this.showErrorMessage(result.message);
			}
		}, this));

	},
	/**
	 * Search the data for a timestamp point and returns its error code
	 * @param {Number} timestamp
	 * @return {String} errorCode
	 */
	getErrorCode: function (timestamp, qualifierId) {
		if (this.qualifierData[qualifierId]) {
			var data = this.qualifierData[qualifierId];
			for (var i = 0, length = data.length; i < length; i++) {
				if (data[i].t && data[i].t === timestamp) {
					var item = data[i];

					if (item.e && item.e.length && item.e[0] !== 0) {
						return item;
					} else {
						break;
					}
				}
			}
		}
	},
	/*
	 * Handler function which renders the chart
	 */
	render: function (dataSet) {
		var oThis = this, exporting = jQuery.extend(true, {}, Configuration.highcharts.exporting);
		this.dataSet = dataSet;
		this.errors = [];
		this.saveDisabledSeries();
		for (var i = 0, length = dataSet.length; i < length; i++) {
			if (dataSet[i].e[0] !== 0) {
				this.errors.push({
					x: dataSet[i].t,
					t: dataSet[i].t,
					y: 0,
					marker: {
						enabled: true,
						fillColor: '#FF0000',
						symbol: 'circle',
						radius: 4,
						states: {
							hover: {
								radius: 6
							}
						}
					}
				});
			} else {
				if (!this.errors.length || this.errors[this.errors.length - 1].y !== null) {
					this.errors.push({
						x: dataSet[i].t,
						t: dataSet[i].t,
						y: null
					});
				}
			}
		}
		if (this.onZoom) {
			exporting.enabled = true;
			exporting.buttons.popUpBtn = {
				onclick: $.proxy(function () {
					this.zoomStartDate = null;
					this.zoomEndDate = null;
					this.zoomPeriod = null;
					this.onZoom = false;
					this.unsubscribe();
					this.getData();
					this.subscribe();

					if (this.customControls && typeof this.customControls.zoom === 'function') {
						this.customControls.zoom.call(this, {
							startDate: this.instanceConfiguration.startDate,
							endDate: this.instanceConfiguration.endDate,
							period: this.instanceConfiguration.period
						});
					}
				}, this),
				align: 'left',
				width: 5,
				x: 5,
				y: 5,
				text: lang.RESET,
				theme: {
					'stroke-width': 1,
					stroke: '#aaa',
					fill: '#fff',
					r: 0,
					states: {
						hover: {
							fill: '#eee'
						},
						select: {
							fill: '#ccc'
						}
					}
				}
			};
		}

		Highcharts.setOptions({
			global: {
				useUTC: true,
				timezoneOffset: -moment.tz(this.instanceConfiguration.timezone).utcOffset()
			}
		});

		let container = this.renderTo;
		let height;
		let dashboardWindow = container.closest('.section__content');
		if (dashboardWindow.length) {
			height = dashboardWindow.height();
		} else {
			height = container.closest('.cw_section_content').height();
		}

		let header = dashboardWindow.closest('.html-shape-container').find('.toolbar_appearance_section-header').first();
		let missingHeader = false;
		if (header.css('display') === 'none' || header.width() === -30) {
			missingHeader = true;
		}
		if (this.instanceConfiguration.timeSelectorPosition === 'BOTTOM') {
			height = height - 30;
			this.moveTimeSelectorBottom(missingHeader);
		} else if (this.instanceConfiguration.timeSelectorPosition === 'TOP' && missingHeader)  {
			height = height - 30;
			this.renderTo.css('margin-top', '35px');
		}

		this.chart = new Highcharts.Chart({
			chart: {
				renderTo: this.renderTo[0],
				height: height,
				type: 'line',
				zoomType: 'x',
				events: {
					load: function () {
					},
					selection: $.proxy(function (event) {
						if (event.xAxis) {
							this.onZoom = true;
							this.zoomStartDate = parseInt(event.xAxis[0].min, 10);
							this.zoomEndDate = parseInt(event.xAxis[0].max, 10);
							this.zoomPeriod = 'CUSTOM';

							this.unsubscribe();

							if (this.customControls && typeof this.customControls.zoom === 'function') {
								this.customControls.zoom.call(this, {
									startDate: this.instanceConfiguration.startDate,
									endDate: this.instanceConfiguration.endDate,
									period: this.instanceConfiguration.period
								});
							}
						}

						event.preventDefault();
						this.getData();
					}, this)
				}
			},
			title: {
				text: ' '
			},
			legend: {
				title: {
					align: 'center'
				},
				x: 2,
				y: 20,
				floating: false,
				useHTML: true,
				borderWidth: 0,
				layout: 'horizontal',
				align: 'center',
				verticalAlign: 'bottom',
				itemStyle: {
					fontSize: "10px"
				},
				style: {
					fontSize: "10px"
				},
				labelFormatter: function () {
					return getLegendLabelFormatter(this);
				}
			},
			credits: {
				enabled: false
			},
			exporting: exporting,
			xAxis: {
				type: 'datetime',
				labels: {
					staggerLines: 1
				},
				dateTimeLabelFormats: {
					millisecond: '%H:%M:%S',
					second: '%H:%M:%S',
					minute: '%H:%M',
					hour: '%H:%M',
					day: '%e. %b',
					week: '%e. %b',
					month: '%b \'%y',
					year: '%Y'
				},
				visible: this.instanceConfiguration.showXAxis
			},
			yAxis: {
				softMin: 0,
				minPadding: 0,
				title: {
					text: null
				},
				labels: {
					formatter: function () {
						var v = this.value;
						if (oThis.booleanMetricsOnly) {
							switch (this.value) {
								case 1:
									v = lang.UP;
									break;
								case 0:
									v = lang.DOWN;
									break;
								default:
									v = ' ';
									break;
							}
						}
						return v;
					},
				},
				visible: this.instanceConfiguration.showYAxis
			},
			tooltip: {
				crosshairs: true,
				snap: 1,
				shared: true,
				useHTML: true,
				formatter: function (e) {
					var length = this.points.length, tooltip = [], errorObj, userOptions, v;
					for (var i = 0; i < length; i++) {
						let y = this.points[i].y;
						if (oThis.booleanMetricsArray.indexOf(this.points[0]?.series.options.qualifierId) !== -1) {
							y = parseInt(y) === 1 ? lang.UP : lang.DOWN;
						}
						if (this.points[i].series.options.fullName) {
							tooltip.push(this.points[i].series.options.fullName + ': ' + y);
						} else {
							tooltip.push(this.points[i].series.options.name + ': ' + y);
						}
					}
					var s = Renderer.browserDateRenderer(this.x, "datetime", '', oThis.instanceConfiguration.timezone) + '<br/>' + tooltip.join('<br/>');
					if (oThis.instanceConfiguration.showRegression) {
						const trendString = getTrendValueWidgetWrapper(this, oThis);
						if(trendString){
							return s += '<br />' + trendString;
						}
						return s;
					} else {
						let qualifierId, qualifierServiceId;

						for (var i = 0; i < length; i++) {
							errorObj = oThis.getErrorCode(this.x, this.points[i].series.options.qualifierId);
							qualifierId = this.points[i].series.options.qualifierId;
							qualifierServiceId = this.points[i].series.options.qualifierServiceId;
						}
						var s = Renderer.browserDateRenderer(this.x, "datetime", '', oThis.instanceConfiguration.timezone) + '<br/>' + tooltip.join('<br/>');
						var v = '<br />';
						if (!errorObj) {
							return s;
						} else {
							this.points[0].point.color = '#FF0000';

							let t0 = errorObj.t0;
							let toTime, url;
							if (t0) {
								toTime = t0 + oThis.interval;
								url = oThis.requestPath + 'services/' + qualifierServiceId + '/notifications/qualifiers/' + qualifierId + '/errorDetails?fromTime=' + t0 + '&toTime=' + toTime + '&timeZone=' + oThis.instanceConfiguration.timezone;
							}

							this.point = this.points[0];
							if (!this.point.point.options.errorPointData) {
								return Utils.ajax(url, 'GET', {}, false, false)
									.then($.proxy(function (result) {
										console.log(result);
										if (result.data) {
											v += result.data.message;
											v += '<br />' + '<div class="cw_widget_tooltip">' + result.data.serviceLogMessage + '</div>';
											this.point.point.options.errorPointData = result.data;
											return s + '<br />' + v;
										}
										else {
											return v += errorObj.errorCode;
										}
									}, this));
							} else {
								v += this.point.point.options.errorPointData.message;
								v += '<br />' + '<div class="cw_widget_tooltip">' + this.point.point.options.errorPointData.serviceLogMessage + '</div>';
								return s + '<br />' + v;
							}
						}
					}
				}
			},
			plotOptions: {
				series: {
					events: {
						click: $.proxy(this.onChartPointClick, this)
					},
					marker: {
						enabled: false
					}
				},
				line: {
					events: {
						// click: $.proxy(this.onPointClick, this),
						legendItemClick: $.proxy(this.onLegendClick, this)
					}
				}
			},
			series: this.chartData
		});

		Highcharts.setOptions({
			global: {
				useUTC: false
			}
		});
		this.restoreDisabledSeries();
		this.releaseEvents();
	},

	onChartPointClick: function (e) {
		var name = e.currentTarget.name;
		if (State.currentApp?.dashboardDesigner?.props?.mode !== 'designer' && this.redirectConfig?.doDefaultRedirect) {
			if (name !== 'NODATA' && this.instanceConfiguration.type !== 'MONITOR') {
				if (e.point.marker && e.point.marker.fillColor === '#FF0000') {
					if (e.point.options.errorPointData) {
						var data = e.point.options.errorPointData;
							State?.mainApp.loadModule('ServiceLogDetails', '', {
								serviceId: e.point.series.options.qualifierServiceId,
								serviceName: this.instanceConfiguration.serviceName,
								notificationId: data.notificationId,
								type: data.type,
								status: 'STATUS',
								message: data.serviceLogMessage
							}, null, null, this.redirectConfig?.defaultRedirectInNewTab);
					}
					// else TODO: - do ajax request
				} else if (!this.isViewer) {
					State.mainApp?.navigate(ServicesRouter.viewer(e.point.series.options.qualifierServiceId, {
						serviceElementId: this.instanceConfiguration.serviceElementId,
						serviceQualifierId: this.instanceConfiguration.serviceQualifierId
					}), {newTab: this.redirectConfig?.defaultRedirectInNewTab});
				}
			}
			if (this.instanceConfiguration.qualifierType === 'datacollector.health.HealthIndexConfiguration' ||
				this.instanceConfiguration.qualifierType === 'datacollector.health.MonitorHealthIndexConfiguration') {
				this.timeStamp = e.point.options.x;
				this.openReasonsWindow();
			}
		}
	},

	/*
	 * handler for clicking a point
	 */
	onPointClick: function (e) {
		var timeStamp = e.point.options.x, serieName = e.point.series.name;
	},
	/*
	 * Handler for clicking legend
	 */
	onLegendClick: function (e) {
		var clickedLegend = e.target.name;
	},
	/**
	 * Called when a metric event is received
	 * @param {Object} data The event data object
	 */
	onEvent: function (data) {
		if (this.chart) {
			var doRoundRobin, length = this.chartData.length, dataSet, seriesDataSet, qualifierKey;
			//console.log('Metrics/Multigraph');
			//console.log(data);

			qualifierKey = this.qualifierSeriesKeys[data.qualifierId];
			if (qualifierKey === undefined) {
				// series doesn't exist, created it
				qualifierKey = Object.keys(this.qualifierSeriesKeys).length;
				this.qualifierSeriesKeys[data.qualifierId] = qualifierKey;
				this.chartData[qualifierKey] = {
					data: [],
					name: this.qualifierNames[data.qualifierId],
					fullName: this.qualifierNames[data.qualifierId],
					marker: {
						enabled: false
					},
					regression: this.instanceConfiguration.showRegression,
					regressionSettings: {
						marker: {
							enabled: false
						},
						name: this.qualifierNames[data.qualifierId],
						fullName: this.qualifierNames[data.qualifierId],
						guid: Utils.guid(),
						type: 'linear',
						color: 'rgba(255,165,0, 1)'
					},
					dataGrouping: {
						approximation: this.instanceConfiguration.aggregationType || "average"
					}
				};

				this.chart.addSeries(this.chartData[qualifierKey], true);
			}

			dataSet = this.chartData[qualifierKey].data;
			seriesDataSet = this.chart.series[qualifierKey];

			doRoundRobin = true;
			if (dataSet.length < this.roundRobinPoints) {
				doRoundRobin = false;
			}

			if (!this.aggregate) {
				var color = '#7cb5ec';
				var isError = false;
				if (data.metric.e[0] !== 0) {
					color = '#FF0000';
					isError = true;
				}
				seriesDataSet.addPoint({
					x: data.metric.t,
					t: data.metric.t,
					y: Utils.aggregate(this.instanceConfiguration.aggregationType, data.metric),
					marker: {
						enabled: isError ? true : false,
						fillColor: color,
						symbol: 'circle',
						radius: 4,
						states: {
							hover: {
								radius: 6
							}
						}
					}
				}, true, doRoundRobin);
			} else {
				seriesDataSet.addPoint([data.metric.t, data.metric.vL, data.metric.vH], true, doRoundRobin);
			}

			if (doRoundRobin) {
				dataSet.splice(0, 1);
			}
			dataSet.push(data.metric);
		} else {
			this.getData();
		}
	},
	/**
	 * Triggered after widget resize
	 * @param {Object} event The resize event
	 * @param {Object} ui The UI element - see http://api.jqueryui.com/resizable/
	 */
	onResize: function (event, ui) {
		kendo.ui.progress(this.renderTo, true);
		this.unsubscribe();
		this.subscribe();
		this.getData();
		setTimeout(() => {
			this.createCustomControls();
		});
	},
	/*
	* Handler function for release events
	* */
	releaseEvents: function () {
		if (this.aggregate) {
			this.recalculateIntervalsConsideringData();
		}
		RemoteEventsManager.releaseEvents(this.subscriberId, {
			intervals: this.intervals
		});
	},

	recalculateIntervalsConsideringData: function() {
		if (!this.chartData.length || !this.chartData[0].length) {
			return;
		}

		this.chartData.forEach(chart => {
			if (!chart.qualifierId) {
				return;
			}
			const period = Utils.getPeriodInterval({
				period: this.zoomPeriod || this.instanceConfiguration.period,
				startDate: this.zoomStartDate || this.instanceConfiguration.startDate,
				endDate: this.zoomEndDate || this.instanceConfiguration.endDate,
				width: this.roundRobinPoints
			}, chart.data[0][0]);
			this.intervals[chart.qualifierId] = period.interval;
		});
	},
	/**
	 * Subscribes to qualifier emtrics events
	 * @param {Boolean} reload If true, reloads chart data after a successful subscription
	 */
	subscribe: function (reload) {
		var subscriptionObj = [{
			eventType: 'MultipleMetric',
			releaseEvents: true,
			qualifierIds: this.instanceConfiguration.qualifiersList
		}];
		this.subscriberId = this.id;
		RemoteEventsManager.subscribe(this.subscriberId, subscriptionObj);
		/*if (reload) {
			this.getData();
		}*/
	},
	/**
	 * Destroy
	 */
	destroy: function () {
		Widget.prototype.destroy.call(this);
	}
});
