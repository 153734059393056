import React from 'react';
import PropTypes from 'prop-types';
import {b} from './common';
import Lang from "core/localization/lang";
import DateTimePicker from './dateTimePicker'
import {SET_END_TIME} from "./reducer";
import DropDownList from "controls/react/kendoWrappers/dropDownList";
import RRule from "rrule";

const END_STATE = [{
	text: Lang.rulegenerator.NEVER,
	value: 'never'
}, {
	text: Lang.rulegenerator.ON_DATE,
	value: 'on_date'
}];

function EndTime(props) {
	const onChange = React.useCallback((value) => {
		if (value) {
			props.dispatch({type: SET_END_TIME, value})
		}
	}, []);

	const onStateChange = React.useCallback((value) => {
		if (value === 'never') {
			props.dispatch({type: SET_END_TIME, value: null})
		}
		else {
			props.dispatch({type: SET_END_TIME, value: new Date()})
		}
	}, []);

	return <div className={b('row')}>
		<div className={b('label')}>
			{Lang.rulegenerator.END}
		</div>
		<DropDownList dataSource={END_STATE} value={props.until ? 'on_date': 'never'} onChange={onStateChange} />
		{props.until && <DateTimePicker value={props.until} onChange={onChange} />}
	</div>;
}

EndTime.propTypes = {
	until: PropTypes.instanceOf(Date),
	dispatch: PropTypes.func.isRequired
};

export default EndTime;
