import {Utils, Renderer, State, UserPrefs} from 'tools';
import {Application, Cookies} from 'core';
import PreferencesPanel from 'controls/preferencesPanel';
import {Dialog, GridMenu, CustomNotification} from 'controls';
import Settings from 'settings';

import MultiSelectGridFilter from 'controls/multiSelectGridFilter';

import {translator} from 'core';
import {gridGetSelectedIds} from "../../tools/utils";
import {ReportsRouter} from './bundleDescription';
import {openTagsFormWindow} from "controls/tagsForm";
import ReactDOM from "react-dom";
import React from 'react'
import ReportDuplicateWindow from './reportDuplicateWindow'
import EventsToolbar from 'controls/eventsToolbar';
import ReportApi from './api';

const i = translator({
	'Duplicate': {
		no: 'Dupliser'
	},
	'Duplicate report': {
		no: 'Dupliser rapport'
	},
	'Reports have succesfully been created': {
		no: 'Rapporten er opprettet'
	}
});

export default function Reports() {
	this.module = {
		name: 'Reports'
	};
	this.subscriberId = Utils.guid();
	this.initComponent();
};
jQuery.extend(Reports.prototype, Application.prototype, {
	initComponent: function () {
		Application.prototype.initComponent.call(this);
		this.getTags();
		this.showGridView = true;
		this.removeListeners();
		this.attachListeners();
		this.loadUserPreferences();
		State.mainApp.contextMenu.setActionButton('REPORT');
		this.removeMask();
		this.actionNotification = new CustomNotification({
			appendToElement: '.window_area',
		});
	},
	/**
	 * Called in order to remove existing listeners
	 */
	removeListeners: function () {
		$('#cw_reports_list').off();
	},
	/**
	 * Attaches the listeners
	 */
	attachListeners: function () {
		$('#cw_reports_list').on('click', '.cw_grid_check_all', $.proxy(this.onReportCheckAll, this));
		$('#cw_reports_list').on('click', '.cw_generate_report', $.proxy(this.onGenerateReportClick, this));
		$(window).on('resize', $.proxy(this.adjustSectionHeight, this));
	},
	getTags: async function () {
		let tags = await Utils.getAccountTags();
		this.filterOptions = {
			tags: tags
		};
	},
	/**
	 * Initializes kendo components
	 */
	initKendoComponents: function () {
		var filterMessages = lang.grid.filter;
		let eventStatus = JSON.parse(UserPrefs.get('eventsStatus')) || {
			startFrom: 0,
			playing: true
		};
		this.eventsToolbar = new EventsToolbar({
			renderTo: $('#cw_reports_events_toolbar'),
			callBackFn: $.proxy(this.onEventsTimeout, this),
			subscriberId: this.subscriberId,
			startFrom: eventStatus.startFrom,
			playing: eventStatus.playing
		});
		this.gridMessages = {
			isTrue: '<span class="glyphicons ok cw_true"></span>',
			isFalse: '<span class="glyphicons remove cw_false"></span>',
			clear: lang.CLEAR,
			info: lang.grid.filter.SHOW_ITEMS,
			filter: lang.FILTER
		};
		this.defaultColumns = {
			id: {
				hidden: false,
				width: 40
			},
			name: {
				hidden: false,
				width: 240
			},
			accountName: {
				hidden: !State.includeSubaccounts,
				width: 150
			},
			reportType: {
				hidden: false,
				width: 140
			},
			created: {
				hidden: false,
				width: 140
			},
			modified: {
				hidden: false,
				width: 140
			},
			language: {
				hidden: false,
				width: 150
			},
			tags: {
				hidden: false,
				width: 150
			},
			timeZone: {
				hidden: false,
				width: 220
			},
			reportPeriod: {
				hidden: false,
				width: 280
			},
			description: {
				hidden: false,
				width: 300
			},
			scheduleCount: {
				hidden: false,
				width: 100
			}
		};
		var reportsSort, reportsFilter;
		var defaultFsView = UserPrefs.get('defaultFsView');
		if (Utils.isGuid(defaultFsView)) {
			var pref = JSON.parse(UserPrefs.get(defaultFsView));
			pref = pref || {
				sort: [],
				filter: []
			};
			reportsSort = pref.sort;
			reportsFilter = pref.filter;
			this.reportsColumns = pref.columns;
		} else {
			if (UserPrefs.get('reportsSort')) {
				reportsSort = JSON.parse(UserPrefs.get('reportsSort'));
			} else {
				reportsSort = [{
					field: 'accountName',
					dir: 'desc',
					compare: null
				}];
			}
			if (UserPrefs.get('reportsFilter')) {
				reportsFilter = JSON.parse(UserPrefs.get('reportsFilter'));
			} else {
				reportsFilter = [];
			}
		}
		this.reportsDataSource = new kendo.ceeview.DataSource({
			transport: {
				read: {
					url: ReportApi.getExistingReportsUrl(Cookies.CeesoftCurrentAccountId, State.includeSubaccounts.toString()),
					contentType: "application/json; charset=utf-8",
					type: "GET",
					dataType: "json",
					cache: false
				}
			},
			schema: {
				model: {
					id: 'id',
					fields: {
						reportType: {
							type: 'string'
						},
						created: {
							type: 'date'
						},
						modified: {
							type: 'date'
						},
						scheduleCount: {
							type: 'number'
						}
					}
				},
				parse: $.proxy(function (data) {
					this.reportTypes = [];
					for (var i = 0, length = data.length; i < length; i++) {
						if (data[i - 1]) {
							if (data[i].reportType !== data[i - 1].reportType) {
								this.reportTypes.push({
									text: data[i].reportType,
									value: data[i].reportType
								});
							}
						} else {
							this.reportTypes.push({
								text: data[i].reportType,
								value: data[i].reportType
							});
						}
						data[i].created = new Date(data[i].created);
						data[i].modified = new Date(data[i].modified);
						if (data[i].tags) {
							data[i].tags = data[i].tags.join(', ');
						}
					}
					return data;
				}, this)
			},
			sort: reportsSort,
			filter: reportsFilter
		});
		var reportsColumns = this.reportsColumns || JSON.parse(UserPrefs.get('reportsColumns')) || this.defaultColumns;
		reportsColumns = Utils.completeColumns(reportsColumns, this.defaultColumns);
		this.grid = $('#cw_reports_list').kendoCustomGrid({
			dataSource: this.reportsDataSource,
			resizable: true,
			reorderable: true,
			selectable: 'row',
			sortable: {
				mode: "multiple",
				allowUnsort: true
			},
			filterable: {
				extra: false,
				operators: {
					string: {
						startswith: filterMessages.STARTS_WITH,
						neq: filterMessages.NEQ,
						eq: filterMessages.EQ,
						contains: filterMessages.CONTAINS
					},
					number: {
						eq: filterMessages.EQ,
						neq: filterMessages.NEQ,
						gte: filterMessages.GTE,
						gt: filterMessages.GT,
						lte: filterMessages.LTE,
						lt: filterMessages.LT
					}
				},
				messages: this.gridMessages
			},
			pageable: false,
			columns: Utils.rearrangeColumns([{
				field: 'id',
				title: lang.SELECTOR,
				sortable: false,
				filterable: false,
				menu: false,
				template: '<input type="checkbox" class="cw_grid_check" data-id="${id}" data-name="${name}" data-timezone="${timeZone}" />',
				headerTemplate: '<input type="checkbox" class="cw_grid_check_all" />',
				attributes: {
					'class': 'text_center'
				},
				headerAttributes: {
					'class': 'text_center'
				},
				hidden: reportsColumns.id.hidden,
				width: reportsColumns.id.width
			}, {
				field: 'name',
				title: lang.NAME,
				template: item => {
					return `<a class="cw_grid_link ellipsis to_expand" href="#${ReportsRouter.details(item.id)}">${item.name}</a>`
				},
				sortable: true,
				filterable: true,
				hidden: reportsColumns.name.hidden,
				width: reportsColumns.name.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'accountName',
				title: lang.ACCOUNT,
				sortable: true,
				filterable: {
					ui: $.proxy(function (element) {
						return Renderer.filterGridByOwnElementDS.call(this, element, 'accountName');
					}, this),
					messages: this.gridMessages,
					extra: false,
					operators: {
						string: {
							neq: filterMessages.NEQ,
							eq: filterMessages.EQ
						}
					}
				},
				hidden: !State.includeSubaccounts || reportsColumns.accountName.hidden,
				width: reportsColumns.accountName.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'reportType',
				title: lang.reports.REPORT_TYPE,
				sortable: true,
				filterable: {
					ui: $.proxy(this.getFilterableForReportType, {scope: this, field: 'reportType'}),
					messages: this.gridMessages
				},
				hidden: reportsColumns.reportType.hidden,
				width: reportsColumns.reportType.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'scheduleCount',
				title: lang.SCHEDULE,
				sortable: true,
				filterable: true,
				hidden: reportsColumns.scheduleCount.hidden,
				width: reportsColumns.scheduleCount.width
			}, {
				field: 'language',
				title: lang.LANGUAGE,
				sortable: true,
				filterable: true,
				template: '#= language #',
				hidden: reportsColumns.language.hidden,
				width: reportsColumns.language.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'tags',
				title: lang.TAGS,
				sortable: true,
				filterable: {
					ui: $.proxy(function (element) {
						let multiselect = new MultiSelectGridFilter({
							element: element,
							field: 'tags',
							grid: this.grid,
							itemTemplate: '#=data.text#',
							tagTemplate: '#=data.text#',
							dataSource: this.filterOptions.tags,
							static: true
						});
					}, this),
					messages: this.gridMessages,
					extra: false,
					operators: {
						string: {
							eq: filterMessages.EQ,
							neq: filterMessages.NEQ
						}
					}
				},
				hidden: reportsColumns.tags.hidden,
				width: reportsColumns.tags.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'created',
				title: lang.reports.CREATED,
				template: '#= Renderer.browserDateRenderer(created, "datetime")#',
				sortable: true,
				filterable: {
					extra: true,
					ui: function (el) {
						el.kendoDateTimePicker({
							format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
							timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat)
						});
					}
				},
				hidden: reportsColumns.created.hidden,
				width: reportsColumns.created.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'modified',
				title: lang.reports.MODIFIED,
				template: '#= Renderer.browserDateRenderer(modified, "datetime")#',
				sortable: true,
				filterable: {
					extra: true,
					ui: function (el) {
						el.kendoDateTimePicker({
							format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
							timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat)
						});
					}
				},
				hidden: reportsColumns.modified.hidden,
				width: reportsColumns.modified.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'timeZone',
				title: lang.TIMEZONE,
				filterable: true,
				sortable: true,
				template: '#= timeZone #',
				hidden: reportsColumns.timeZone.hidden,
				width: reportsColumns.timeZone.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'reportPeriod',
				title: lang.reports.GENERATE_REPORT,
				filterable: false,
				sortable: false,
				template: function () {
					var html = '';
					html += '<strong class="left"></strong>';
					html += '<span class="cw_label cw_generate_report" data-period="LASTQUARTER">' + lang.QUARTER + '</span>';
					html += '<span class="cw_label cw_generate_report" data-period="LASTMONTH">' + lang.MONTH + '</span>';
					html += '<span class="cw_label cw_generate_report" data-period="LASTWEEK">' + lang.WEEK + '</span>';
					html += '<span class="cw_label cw_generate_report" data-period="LAST7DAYS">' + lang.reports.LAST_7DAYS + '</span>';
					html += '<span class="cw_label cw_generate_report" data-period="YESTERDAY">' + lang.reports.YESTERDAY + '</span>';
					html += '<span class="cw_label cw_generate_report" data-period="TODAY">' + lang.reports.TODAY + '</span>';
					return html;
				},
				attributes: {
					"class": "pointer ellipsis to_expand"
				},
				hidden: reportsColumns.reportPeriod.hidden,
				width: reportsColumns.reportPeriod.width
			}, {
				field: 'description',
				title: lang.DESCRIPTION,
				filterable: true,
				sortable: true,
				template: '#= description?description: "" #',
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				},
				hidden: reportsColumns.description.hidden
			}], reportsColumns),
			columnMenu: true,
			change: $.proxy(this.onRowSelect, this),
			dataBound: $.proxy(this.onGridDataBound, this)
		}).data('kendoCustomGrid')
		// Add Kendo tooltip to the header of the columns
		Utils.gridColumnHeaderTooltip(this.grid);
		this.adjustSectionHeight();
		this.grid.thead.find("[data-field='id']>.k-header-column-menu").remove();
		this.gridMenu = new GridMenu({
			grid: this.grid,
			renderTo: 'cw_reports_grid_menu',
			items: [{
				icon: 'plus-sign',
				text: lang.CREATE,
				fn: this.onCreateReport,
				role: 'REPORT_CREATE',
				scope: this
			}, {
				icon: 'bin',
				text: lang.DELETE,
				fn: this.onDeleteReport,
				role: 'REPORT_DELETE',
				scope: this,
				enabledIf: GridMenu.AtLeastOneItemSelected
			}, {
				icon: 'download-alt',
				text: lang.GENERATE,
				fn: this.onReportDownload,
				scope: this,
				enabledIf: GridMenu.AtLeastOneItemSelected
			},{
				icon: 'copy',
				text: i('Duplicate'),
				fn: () => this.copyReport(),
				enabledIf: GridMenu.ExactlyOneItemSelected
			},{
				icon: 'tag',
				text: lang.SET_TAGS,
				fn: $.proxy(function () {
					return openTagsFormWindow('report', this);
				}, this),
				scope: this,
				enabledIf: GridMenu.AtLeastOneItemSelected,
				role: 'REPORT_UPDATE'
			}]
		});
		var searchValue = UserPrefs.get('defaultFsView') ? '' : (UserPrefs.get('reportsSearchPhrase') || '');
		this.gridFilterPanel = new PreferencesPanel({
			renderTo: 'cw_reports_filters',
			grid: this.grid,
			modulePrefName: 'Reports',
			defaultPrefViewKey: 'defaultFsView',
			prefListKey: 'reportsFSViews',
			userPref: this.userPref,
			onRemove: $.proxy(this.saveUserPreferences, this),
			searchFields: ['name', 'language', 'timeZone', 'description'],
			searchValue: searchValue,
			defaultColumns: this.defaultColumns
		});
	},

	async copyReport(){
		let ids = gridGetSelectedIds(this.grid);
		const reportName = this.grid.dataSource.get(ids[0])?.name
		const reportDuplicateRootCont = document.createElement('div');
		document.body.appendChild(reportDuplicateRootCont);
		ReactDOM.render(<ReportDuplicateWindow title={`${i('Duplicate report')} ${reportName}`}
					reportId={ids[0]}
					onReportsDuplicate={() => this.onReportsDuplicate()}
					onClose={()=>{
						ReactDOM.unmountComponentAtNode(reportDuplicateRootCont)
						document.body.removeChild(reportDuplicateRootCont)
					}}/>, reportDuplicateRootCont)
	},

	onReportsDuplicate: function () {
		this.actionNotification.setOptions({
			message: i('Reports have succesfully been created'),
			status: 'success'
		}).show();
		this.grid.dataSource.read();
	},

	onEventsTimeout: function () {
		this.checkboxStates = Utils.getCheckboxStates(this.grid);
		this.grid.dataSource.read();
	},

	reloadByTags: function () {
		this.grid.dataSource.read();
	},

	getFilterableForReportType: function (element) {
		var menu = $(element).parent();
		element.removeAttr("data-bind");
		var operatorEl = menu.find("[data-role=dropdownlist]");
		operatorEl.find('option[value="contains"]').remove();
		operatorEl.find('option[value="startswith"]').remove();
		operatorEl.attr('data-index', '1');

		var multiSelect = element.kendoSortedMultiSelect({
			dataSource: this.scope.reportTypes,
			dataTextField: 'text',
			dataValueField: 'value',
			optionLabel: lang.grid.FILTER_SELECT_VALUE,
		}).data('kendoSortedMultiSelect');
		menu.find('[type=submit]').on('click', {
			widget: multiSelect,
			operatorElement: operatorEl
		}, $.proxy(this.scope.filterByReportType, {
			scope: this.scope,
			dataSource: this.scope.reportsDataSource,
			field: this.field
		}));
		menu.find('[type=reset]').on('click', $.proxy(function (e) {
			multiSelect.value([]);
		}, this));
		//fix for default value
		setTimeout($.proxy(function () {
			var currentFilterOperator;
			var multiSelectValues = [];
			var currentField = this.field;
			var currentFilters = JSON.parse(UserPrefs.get('reportsFilter')).filters;
			if (currentFilters && currentFilters.length) {
				for (var i = 0; i < currentFilters.length; i++) {
					if (currentFilters[i].filters && currentFilters[i].filters.length) {
						for (var j = 0; j < currentFilters[i].filters.length; j++) {
							if (currentFilters[i].filters[j].field === currentField) {
								multiSelectValues.push(currentFilters[i].filters[j].value);
								currentFilterOperator = currentFilters[i].filters[j].operator
							}
						}
					} else {
						if (currentFilters[i].field === currentField) {
							multiSelectValues.push(currentFilters[i].value);
							currentFilterOperator = currentFilters[i].operator;
						}
					}
				}
			}
			var dropdown = operatorEl.data('kendoDropDownList');
			if (currentFilterOperator) {
				dropdown.select(function (dataItem) {
					return dataItem.value === currentFilterOperator;
				});
			} else {
				dropdown.select(1);
			}
			if (multiSelectValues && multiSelectValues.length) {
				multiSelect.value(multiSelectValues);
			}
		}, this), 100);
	},
	/**
	 * Handler function for refreshing the grid when custom report type filter selected
	 */
	filterByReportType: function (e) {
		var indicators = e.data.widget.value();
		var operator = e.data.operatorElement.data('kendoDropDownList').value();
		var logic = (operator === 'eq') ? 'or' : 'and';
		var currentFilter = this.dataSource.filter();
		var filter = {logic: logic, filters: []};
		for (var i = 0; i < indicators.length; i++) {
			filter.filters.push({field: this.field, operator: operator, value: indicators[i]});
		}
		if (currentFilter && currentFilter.filters) {
			for (var i = 0; i < currentFilter.filters.length; i++) {
				if (currentFilter.filters[i].filters) {
					for (var j = 0; j < currentFilter.filters[i].filters.length; j++) {
						if (currentFilter.filters[i].filters[j].field === this.field) {
							currentFilter.filters[i].filters.splice(j, 1);
							j--;
						}
					}
				}
				if (currentFilter.filters[i].field === this.field) {
					currentFilter.filters.splice(i, 1);
					i--;
				}
			}
			if (filter.filters.length) {
				currentFilter.filters.push(filter);
			}
			this.dataSource.filter(currentFilter);
		} else {
			this.dataSource.filter(filter);
		}
	},

	onReportCheckAll: function (e) {
		var checkAll = $('.cw_grid_check_all').is(':checked');
		var checkboxes = $('.cw_grid_check');
		for (let i = 0; i < checkboxes.length; i++) {
			$(checkboxes[i]).prop('checked', checkAll);
		}
	},
	/**
	 * Handler function for the click event on the create report button
	 */
	onCreateReport: function (e) {
		State.mainApp.navigate(ReportsRouter.createNew());
	},
	/**
	 * Handler function for the click event on the create report button
	 */
	onReportEdit: function (e) {
		var checkboxes = $('.cw_grid_check');
		var reports = [], editReport, reportId;
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			if ($(checkboxes[i]).is(':checked')) {
				reports.push({
					id: $(checkboxes[i]).data('id'),
					name: $(checkboxes[i]).data('name')
				});
			}
		}
		if (reports.length === 1) {
			reportId = reports[0].id;
			var name = reports[0].name;
			var record = this.reportsDataSource.get(reportId);
			State.mainApp.navigate(ReportsRouter.view(reportId));
		}
	},
	/**
	 * Handler function for delete sla button
	 */
	onDeleteReport: function () {
		var checkboxes = $('.cw_grid_check');
		var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/reports/delete';
		var reports = [], checked = 0;
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			if ($(checkboxes[i]).is(':checked')) {
				reports.push($(checkboxes[i]).data('id'));
				checked++;
			}
		}
		if (reports.length) {
			var dialog = new Dialog({
				title: lang.INFO,
				msg: checked > 1 ? lang.reports.messages.REPORTS_REMOVE_CONFIRMATION : lang.reports.messages.REPORT_REMOVE_CONFIRMATION,
				icon: 'ERROR',
				actionText: 'DELETE',
				fn: $.proxy(function (value, button) {
					if (button === 'ok') {
						Utils.ajax(url, 'POST', JSON.stringify(reports), $.proxy(function (result) {
							if (result.success) {
								var status = $('.status');
								status.find('p').addClass('success');
								status.find('p').text(lang.reports.messages.REPORT_DELETED);
								status.slideDown().delay(2000).slideUp();
								// reload data source
								this.reportsDataSource.read();
							} else {
								Utils.showInfo(lang.ALERT, result.message, result.details, this.grid);
							}
						}, this));
					}
				}, this),
				deleteFromGrid: this.grid
			});
			dialog.show();
		}
	},
	/**
	 * Handler function for report download button
	 */
	onReportDownload: function () {
		var checkboxes = $('.cw_grid_check');
		var reports = [];
		this.downloadItems = [];
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			if ($(checkboxes[i]).is(':checked')) {
				this.downloadItems.push(this.grid.dataItem($(checkboxes[i]).closest('tr')));
			}
		}
		if (this.downloadItems.length) {
			this.openDownloadReportForm();
		}
	},
	/**
	 * Opens the date selector form before report download
	 */
	openDownloadReportForm: function () {
		var html;
		this.isSaved = false;
		var id = Utils.guid();
		this.selector = '#' + id;
		if (!$(this.selector).length) {
			$('body').append('<div id="' + id + '"></div>');
		}
		html = '<div class="cw_service">';
		html += '<div class="status"><p></p></div>';
		html += '<div class="form">';
		html += '<div id="cw_period" class="cw_field"><label class="cw_inline">' + lang.PERIOD + '</label>';
		html += '<ul id="cw_default_period" class="cw_multi_toggle left ">';
		html += '<li class="first_btn"><input class="skip_dirty_check" type="radio" value="LASTQUARTER" name="cw_report_period">' + lang.QUARTER + '</li>';
		html += '<li><input class="skip_dirty_check" type="radio" value="LASTMONTH" name="cw_report_period">' + lang.MONTH + '</li>';
		html += '<li><input class="skip_dirty_check" type="radio" value="LASTWEEK" name="cw_report_period">' + lang.WEEK + '</li>';
		html += '<li class="last_btn"><input type="radio" class="skip_dirty_check" value="CUSTOM" name="cw_report_period">' + lang.CUSTOM + '</li>';
		html += '</ul>';
		html += '</div>';
		html += '<div class="cw_field hide" id="cw_report_period"><input id="cw_report_date_from" type="text" /><span> - </span><input id="cw_report_date_to" type="text" /></div>';
		html += '<input type="checkbox" class="k-checkbox" id="cw_override_cached_report">';
		html += '<label for="cw_override_cached_report" class="k-checkbox-label">' + lang.reports.OVERRIDE_CACHED_REPORT +'</label>';
		html += '<div class="actions">';
		html += '<button class="k-button right" id="cancel">' + lang.CANCEL + '</button>';
		html += '<button class="k-button k-primary go_right" id="report_download">' + lang.reports.DOWNLOAD_REPORT + '</button>';
		html += '</div>';
		html += '</div>';
		this.window = $(this.selector).kendoWindow({
			animation: false,
			width: '480px',
			height: '160px',
			title: lang.reports.DOWNLOAD_REPORT,
			modal: true,
			visible: false,
			close: $.proxy(function () {
				$(this.selector).data("kendoWindow").destroy();
			}, this)
		}).data('kendoWindow');
		this.window.content(html);
		// this.commentPositionList = $('#position').kendoDropDownList({
		// 	dataSource: {
		// 		data: [{
		// 			text: 'None',
		// 			value: 'NONE'
		// 		}, {
		// 			text: 'First page',
		// 			value: 'FIRST_PAGE'
		// 		}, {
		// 			text: 'Last page',
		// 			value: 'LAST_PAGE'
		// 		}],
		// 	},
		// 	dataTextField: 'text',
		// 	dataValueField: 'value'
		// }).data('kendoDropDownList');
		this.reportFromDate = $('#cw_report_date_from').kendoDatePicker({
			format: 'dd/MM/yyyy',
			change: $.proxy(this.onFromChange, this)
		}).data('kendoDatePicker');
		this.reportToDate = $('#cw_report_date_to').kendoDatePicker({
			format: 'dd/MM/yyyy',
			change: $.proxy(this.onToChange, this)
		}).data('kendoDatePicker');
		//if only one item selected, import comment spot and comment values
		// if (this.downloadItems.length === 1) {
		//    $('#cw_report_comment').val(this.downloadItems[0].commentText);
		//    this.commentPositionList.value(this.downloadItems[0].commentMode);
		// }
		$('#report_download').off().on('click', $.proxy(this.onDownloadButton, this));
		$('#cancel').off().on('click', $.proxy(this.onCancelButton, this));
		$('#cw_default_period').off().on('click', 'li', $.proxy(this.onDefaultPeriod, this));
		this.window.open().center();
	},
	/**
	 * Handler function for the click event on the default period selector
	 * @param {Object} e The click event object
	 */
	onDefaultPeriod: function (e) {
		var type = $(e.currentTarget).find('input').val();
		var today = new Date(), lastMonth, year, daysInMonth, lastSunday, lastMonday, defaultFrom, defaultTo;
		$(e.currentTarget).parent().find('li').removeClass('is_selected');
		$(e.currentTarget).addClass('is_selected');
		// reset the min and max values for date pickers
		this.reportFromDate.min(new Date(1900, 0, 1));
		this.reportFromDate.max(new Date(2099, 11, 31));
		this.reportToDate.min(new Date(1900, 0, 1));
		this.reportToDate.max(new Date(2099, 11, 31));
		switch (type) {
			case 'LASTQUARTER':
				if (today.getMonth() < 3) {
					defaultFrom = new Date(today.getFullYear() - 1, 9, 1);
					defaultTo = new Date(today.getFullYear() - 1, 11, 31);
				} else if (today.getMonth() > 2 && today.getMonth() < 6) {
					defaultFrom = new Date(today.getFullYear(), 0, 1);
					defaultTo = new Date(today.getFullYear(), 2, 31);
				} else if (today.getMonth() > 5 && today.getMonth() < 9) {
					defaultFrom = new Date(today.getFullYear(), 3, 1);
					defaultTo = new Date(today.getFullYear(), 5, 30);
				} else {
					defaultFrom = new Date(today.getFullYear(), 6, 1);
					defaultTo = new Date(today.getFullYear(), 8, 30);
				}
				$('#cw_report_period').addClass('hide');
				break;
			case 'LASTMONTH':
				lastMonth = today.getMonth() === 0 ? 11 : today.getMonth() - 1;
				year = today.getMonth() === 0 ? today.getFullYear() - 1 : today.getFullYear();
				daysInMonth = new Date(year, lastMonth + 1, 0).getDate();
				defaultFrom = new Date(year, lastMonth, 1);
				defaultTo = new Date(year, lastMonth, daysInMonth);
				$('#cw_report_period').addClass('hide');
				break;
			case 'LASTWEEK':
				lastSunday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay());
				lastMonday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() - 6);
				defaultFrom = lastMonday;
				defaultTo = lastSunday;
				$('#cw_report_period').addClass('hide');
				break;
			case 'CUSTOM':
				defaultFrom = '';
				defaultTo = '';
				$('#cw_report_period').removeClass('hide');
				break;
		}
		// sets the selected default values to the date pickers
		this.reportFromDate.value(defaultFrom);
		this.reportToDate.value(defaultTo);
	},
	/**
	 * Handler function for the click event on Download button
	 * @deprecated (will be moved in another form)
	 */
	onDownloadButton: function () {
		var valid = true, message = '', url = '', reportObj, reportId, timezone, fromYear, fromMonth, fromDay, toYear,
			toMonth, toDay;
		var status = $(this.selector).find('.status');
		var fromDate = this.reportFromDate.value();
		var toDate = this.reportToDate.value(), from = new Date(fromDate);
		var to = new Date(toDate);
		if (!fromDate || !toDate) {
			valid = false;
			message = lang.account.messages.REQUIRED_FIELDS;
		}
		if (!valid) {
			$('#cw_period').find('label').addClass('invalid');
			status.find('p').text(message);
			status.find('p').addClass('error');
			status.slideDown().delay(4000).slideUp();
		} else {
			var dialog = new Dialog({
				title: lang.INFO,
				msg: lang.reports.REPORT_DOWNLOAD_BROWSER,
				buttons: {
					ok: true
				},
				icon: 'INFO',
				actionText: 'OK'
			});
			dialog.show();
			fromYear = from.getFullYear();
			fromMonth = (from.getMonth() + 1) < 10 ? '0' + (from.getMonth() + 1) : (from.getMonth() + 1);
			fromDay = from.getDate() < 10 ? '0' + from.getDate() : from.getDate();
			toYear = to.getFullYear();
			toMonth = (to.getMonth() + 1) < 10 ? '0' + (to.getMonth() + 1) : (to.getMonth() + 1);
			toDay = to.getDate() < 10 ? '0' + to.getDate() : to.getDate();
			for (var i = 0, length = this.downloadItems.length; i < length; i++) {
				timezone = this.downloadItems[i].timeZone;
				reportId = this.downloadItems[i].id;
				//url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/reports/' + reportId + '/generate';
				url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/reports/' + reportId + '/submitJob';
				reportObj = {
					startDate: fromYear + "" + fromMonth + "" + fromDay,
					endDate: toYear + "" + toMonth + "" + toDay,
					timeZone: timezone,
					override: $('#cw_override_cached_report').is(':checked')
					//dateFormat: Cookies.CeesoftUserDateFormat,
					// commentMode: this.commentPositionList.value(),
					// commentText: $('#cw_report_comment').val()
				};
				Utils.ajax(url, 'POST', JSON.stringify(reportObj), function (result) {
					var jobId, downloadUrl, filename;
					if (result.success) {
						jobId = result.data.id;
						let reportName = result.data.reportName;
						let reportCurrentDate = Utils.getTodayDate();
						let reportDownloadName = reportName + '-' + reportCurrentDate;
						downloadUrl = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/reports/jobs/' + jobId + '/download' + '?filename=' + reportDownloadName;
						jQuery.ajax({
							cache: false,
							url: downloadUrl,
							type: 'GET',
							async: true,
							data: {},
							dataType: "json",
							contentType: "application/json; charset=utf-8",
							crossDomain: true,
							statusCode: {
								500: function (result, status, obj) {
									if (error && !this.calledErrorCallback) {
										error.call(this, result, status, obj);
									}
								}
							},
							beforeSend: function (x) {
								if (x && x.overrideMimeType) {
									x.overrideMimeType("application/json;charset=UTF-8");
									if (Cookies.sessionId) {
										x.setRequestHeader('Auth-Token', Cookies.sessionId);
									}
								}
							},
							error: function (result, status, obj) {
								if (result.statusText === 'OK') {
									window.open(downloadUrl);
								}
							}
						});
					} else {
						Utils.showInfo(lang.ALERT, result.message, result.details);
					}
				});
			}
			this.onCancelButton();
		}
	},
	/**
	 * Saves user preferences
	 */
	saveUserPreferences: function () {
		this.saveUserPrefs({
			category: 'Reports',
			filterPanel: this.gridFilterPanel,
			grid: this.grid,
			eventsToolbar: this.eventsToolbar,
			keys: {
				searchPhrase: 'reportsSearchPhrase',
				columns: 'reportsColumns',
				sort: 'reportsSort',
				filter: 'reportsFilter',
				FsViews: 'reportsFSViews'
			}
		});
	},
	/**
	 * Loads user preferences
	 */
	loadUserPreferences: function () {
		this.userPref = [];
		UserPrefs.load('Reports', $.proxy(function (result) {
			if (result.success) {
				this.userPref = result.data;
				this.initKendoComponents();
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},
	/**
	 * Handler function for the click event on Cancel button
	 * @param {Object} e The click event object
	 */
	onCancelButton: function (e) {
		var modalWindow = $(this.selector).data("kendoWindow");
		modalWindow.close();
		modalWindow.destroy();
	},
	/**
	 * Handler function for the change event on From date picker
	 * @param {Object} e The click event object
	 */
	onFromChange: function (e) {
		var startDate = this.reportFromDate.value(), endDate = this.reportToDate.value();
		if (startDate) {
			startDate = new Date(startDate);
			startDate.setDate(startDate.getDate());
			this.reportToDate.min(startDate);
		} else if (endDate) {
			this.reportFromDate.max(new Date(endDate));
		} else {
			endDate = new Date();
			this.reportFromDate.max(endDate);
			this.reportToDate.min(endDate);
		}
	},
	/**
	 * Handler function for the change event on To date picker
	 * @param {Object} e The click event object
	 */
	onToChange: function (e) {
		var endDate = this.reportToDate.value(), startDate = this.reportFromDate.value();
		if (endDate) {
			endDate = new Date(endDate);
			endDate.setDate(endDate.getDate());
			this.reportFromDate.max(endDate);
		} else if (startDate) {
			this.reportToDate.min(new Date(startDate));
		} else {
			endDate = new Date();
			this.reportFromDate.max(endDate);
			this.reportToDate.min(endDate);
		}
	},
	/**
	 * Handler function for the click on a report name
	 * @param {Object} e The click event object
	 */
	onReportNameClick: function (e) {
		var item = $(e.currentTarget).closest('tr');
		this.downloadItems = [];
		this.downloadItems.push(this.grid.dataItem(item));
		var reportId = this.downloadItems[0].id;
		var record = this.reportsDataSource.get(reportId);
		if (this.downloadItems.length) {
			State.mainApp.navigate(ReportsRouter.view(reportId));
		}
		e.stopPropagation();
	},
	/**
	 * Handler function for the click on generate report
	 * @param {Object} e The click event object
	 */
	onGenerateReportClick: function (e) {
		var target = $(e.currentTarget);
		var period = target.data('period');
		var item = target.closest('tr').find('input:checkbox');
		var reportId = item.data('id');
		var name = item.data('name');
		var record = this.reportsDataSource.get(reportId);
		State.mainApp.navigate(ReportsRouter.view(reportId, {period: period, generateFromGrid: true}));

	},
	/**
	 * Handler function for the selection of one grid row
	 */
	onRowSelect: function () {
		var selectedRow = this.grid.select();
		var myRow = selectedRow[0];
		var messageEl = $(myRow).find('.to_expand');
		if ($(messageEl).hasClass('cw_message_expanded')) {
			$(messageEl).removeClass('cw_message_expanded').addClass('ellipsis');
		} else {
			$('#cw_reports_list').find('.k-grid-content').find('td.cw_message_expanded').removeClass('cw_message_expanded').addClass('ellipsis');
			$(messageEl).addClass('cw_message_expanded').removeClass('ellipsis');
		}
	},
	onGridDataBound: function () {
		$('#cw_reports_list').find('.k-grid-content').kendoTooltip({
			filter: "td.tooltip:not(:empty)",
			content: function (event) {
				return event.target.text().split("\n").join("<br />");
			}
		});
	},
	/**
	 * Destroy method
	 */
	destroy: function () {
		if (this.eventsToolbar) {
			this.eventsToolbar.destroy();
		}
		this.saveUserPreferences();
	},
	/**
	 * Method by Andy
	 */
	adjustSectionHeight: function () {
		var section = $('.cw_section_full');
		var sectionHeight = section.height();
		section.find('.cw_section_content').css('height', sectionHeight);
		section.find('.k-grid-content').css('height', sectionHeight - 40);
	}
});
