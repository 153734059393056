import React from 'react';
import PropTypes from 'prop-types';
import {b} from './common';
import Lang from "core/localization/lang";
import DropDownList from "controls/react/kendoWrappers/dropDownList";
import RRule from "rrule";
import {
	MONTHLY_MODE, RESET_MONTHLY_MODE,
	SET_BY_MONTH_DAY,
	SET_BY_POS, SET_WEEK_DAY
} from "./reducer";

const DAYS_OF_WEEK = [
	{text: 'Mon', value: RRule.MO.toString()},
	{text: 'Tue', value: RRule.TU.toString()},
	{text: 'Wed', value: RRule.WE.toString()},
	{text: 'Thu', value: RRule.TH.toString()},
	{text: 'Fri', value: RRule.FR.toString()},
	{text: 'Sat', value: RRule.SA.toString()},
	{text: 'Sun', value: RRule.SU.toString()}
];

const STRING_TO_RRULE_DAY = {
	'MO': RRule.MO,
	'TU': RRule.TU,
	'WE': RRule.WE,
	'TH': RRule.TH,
	'FR': RRule.FR,
	'SA': RRule.SA,
	'SU': RRule.SU
};

const SET_POS = [{
	text: Lang.rulegenerator.FIRST,
	value: '1'
}, {
	text: Lang.rulegenerator.SECOND,
	value: '2'
}, {
	text: Lang.rulegenerator.THIRD,
	value: '3'
}, {
	text: Lang.rulegenerator.FOURTH,
	value: '4'
}, {
	text: Lang.rulegenerator.LAST,
	value: '-1'
}]

function MonthlyPanel(props) {
	const [monthlyMode, setMonthlyMode] = React.useState();

	React.useEffect(() => {
		if (props.bymonthday) {
			setMonthlyMode(MONTHLY_MODE.ON_DAY)
		}
		else {
			setMonthlyMode(MONTHLY_MODE.ON_THE_DAY)
		}
	}, [props.bymonthday, props.bysetpos]);

	const DAYS = React.useMemo(() => {
		return Array(31).fill(null).map((_, index) => ({
			value: index + 1,
			text: index + 1
		}))
	}, []);

	const byweekday = React.useMemo(() => {
		if (props.byweekday && Array.isArray(props.byweekday)) {
			return props.byweekday[0].toString();
		}
		else if (props.byweekday) {
			return props.byweekday.toString();
		}
	}, [props.byweekday]);

	const handleByMonthDay = React.useCallback((value) => {
		props.dispatch({type: RESET_MONTHLY_MODE, mode: MONTHLY_MODE.ON_DAY});
		props.dispatch({type: SET_BY_MONTH_DAY, value})
	}, [props.dispatch]);

	const handleBySetPos = React.useCallback((value) => {
		props.dispatch({type: RESET_MONTHLY_MODE, mode: MONTHLY_MODE.ON_THE_DAY});
		props.dispatch({type: SET_BY_POS, value})
	});

	const handleByWeekDay = React.useCallback((value) => {
		props.dispatch({type: RESET_MONTHLY_MODE, mode: MONTHLY_MODE.ON_THE_DAY});
		props.dispatch({type: SET_WEEK_DAY, value: STRING_TO_RRULE_DAY[value] })
	});

	const changeMode = React.useCallback((e) => {
		setMonthlyMode(e.currentTarget.value);
		props.dispatch({type: RESET_MONTHLY_MODE, mode: e.currentTarget.value})
	}, []);

	if (props.frequency !== RRule.MONTHLY) {
		return null;
	}

	return <>
		<div className={b('row')}>
			<input type={'radio'} name={'monthlyMode'} value={MONTHLY_MODE.ON_DAY} checked={monthlyMode === MONTHLY_MODE.ON_DAY} onChange={changeMode}/>
			<label htmlFor={'on_day'}>{Lang.rulegenerator.ON_DAY}</label>
			<DropDownList dataSource={DAYS} value={props.bymonthday} onChange={handleByMonthDay} />
		</div>
		<div className={b('row')}>
			<input type={'radio'} name={'monthlyMode'} value={MONTHLY_MODE.ON_THE_DAY}  checked={monthlyMode === MONTHLY_MODE.ON_THE_DAY} onChange={changeMode}/>
			<label htmlFor={'on_the_day'}>{Lang.rulegenerator.ON_THE}</label>
			<DropDownList dataSource={SET_POS} value={props.bysetpos} onChange={handleBySetPos} />
			<DropDownList dataSource={DAYS_OF_WEEK} value={byweekday} onChange={handleByWeekDay} />
		</div>
	</>;
}

MonthlyPanel.propTypes = {
	dispatch: PropTypes.func.isRequired,
	bymonthday: PropTypes.any,
	bysetpos: PropTypes.any,
	byweekday: PropTypes.any
};

export default MonthlyPanel;
