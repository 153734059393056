export const monitorToModule = {
	SYSTEM: 'MonitorSystem',
	NIMSOFT: 'MonitorNimsoft',
	PROCESSES: 'MonitorProcess',
	WINSERVICE: 'MonitorService',
	EXTERNAL: 'MonitorExternal',
	WINEVENTS: 'MonitorEventlog',
	VMWARE: 'MonitorVMware',
	VMM: 'MonitorVmm',
	HYPERV: 'MonitorHyperv',
	NAGIOS: 'MonitorNagios',
	FILESEARCH: 'MonitorFilesearch',
	SNMP: 'MonitorSnmp',
	XEN_SERVER: 'MonitorXenServer',
	REMOTEISERIES: 'MonitorRemoteISeries',
	IBMSVC: 'MonitorIbmStorwize',
	GROOVY: 'MonitorGroovy',
	LOG: 'MonitorLog',
	MSCLUSTER: 'MonitorMScluster',
	MAILGTW: 'MonitorMailGateway',
	PRTG: 'MonitorPRTG',
	SOLARWINDS: 'MonitorSolarWinds',
	CONNECTIVITY: 'MonitorConnectivity',
	REMOTESYSTEM: 'MonitorRemoteSystem',
	EATONUPS: 'MonitorEatonUps',
	MODBUS: 'MonitorModbus'
};

export default monitorToModule;
