const shortSchemaFields = {
	metricId: {editable: false},
	assetName: {editable: false},
	categoryNode: {editable: false},
	registryType: {editable: false},
	registryOwnerName: {editable: false},
	instanceName: {editable: false},
	unitType: {editable: false}
}

const fullSchemaFields = {
	customUnit: {editable: true},
	conversion: {editable: false},
	color: {editable: true}
}
export function metricSelectorGridSchema(full = false) {
	let schema = {model: {id: 'metricSelectorSchemaId'}};
	if (full) 
		schema.model.fields = {...shortSchemaFields,...fullSchemaFields}	
	else
		schema.model.fields = shortSchemaFields
	return schema;
}
