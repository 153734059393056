export function getDescriptions(){
	return [
		{
			url: '/:type/:id/ruleset',
			getModuleImport: () => import('./users/ruleset'),
			params: {
			},
			react: true
		},
		{
			url: '/:type/:id/ruleset/channels',
			getModuleImport: () => import('./users/scheduler/schedulerView'),
			params: {
			},
			react: true
		},
		{
			url: '/:type/:id/ruleset/policy',
			getModuleImport: () => import('./users/policyView/policyViewLegacyWrapper'),
			params: {
			},
			react: true
		},
	];
}
