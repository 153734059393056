import UrlBuilder from "tools/urlBuilder";

export function getDescriptions(){
	return [
		{
			jspUrl: 'include/Sla/SlaDetails.jsp',
			bodyCssClass: 'cw_sla_module',
			url: '/slas/:id',
			getModuleImport: () => import('./slaDetails')
		}
	];
}

export class SlaRouter {
	static details(slaId) {
		return`/slas/${slaId}`;
	}

	static createNew(){
		return '/slas/new';
	}
}
