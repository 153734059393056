import {Utils} from "tools";
import ReactDOM from "react-dom";
import React from "react";

import {WidgetConfigurationStore} from './widgetConfigurationStore';

export default class WidgetConfigurationReactContainer {
	#configurationComponent = null;
	#widgetConfig = null;
	#validation = null;

	constructor(configurationComponent, widgetConfig) {
		this.#configurationComponent = configurationComponent;
		this.#widgetConfig = widgetConfig;

		if (!this.#widgetConfig.id) {
			this.#widgetConfig.id = Utils.guid();
		}

		this.render();
	}

	getValues () {
		return this.#widgetConfig;
	}

	render() {
		this.node = document.createElement('div');

		document.getElementById("cw_widget_form")
			.appendChild(this.node);

		const WidgetConfiguration = this.#configurationComponent;

		ReactDOM.render(
			<WidgetConfigurationStore config={this.#widgetConfig}
			                          onChange={config => this.#widgetConfig = config}
			                          onValidate={validation => this.#validation = validation}>
				<WidgetConfiguration/>
			</WidgetConfigurationStore>,
			this.node
		);
	}

	destroy() {
		ReactDOM.unmountComponentAtNode(this.node);
	}

	isValid () {
		for(let name in this.#validation){
			if( !this.#validation.hasOwnProperty(name) )
				continue;

			if(this.#validation[name] != null ){
				return {
					valid: false,
					validationMessage: this.#validation[name].message
				}
			}
		}

		return {
			valid: true,
			validationMessage: ''
		};
	}
}
