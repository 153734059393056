import { createLogger } from 'redux-logger';
import { Iterable } from 'immutable';

export const logger = createLogger({
    collapsed: true,
    stateTransformer: (state) => {
        let newState = {};

        for (var i of Object.keys(state)) {
            if (Iterable.isIterable(state[i])) {
                newState[i] = state[i].toJS();
            } else {
                newState[i] = state[i];
            }
        }

        return newState;
    }
});
