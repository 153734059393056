import {Api} from "tools";
import { useEffect, useState } from 'react';

class AccountsApi {
	static getBaseUrl(accountId = null, sessionId = null) {
		return Api.sessionAndAccountRoot(accountId, sessionId);
	}

	static getLiteUrl(accountId, sessionId) {
		return `${AccountsApi.getBaseUrl(accountId, sessionId)}subaccounts/allLevels/lite`;
	}

	static getAccount(accountId, sessionId) {
		return Api.fetch(`${AccountsApi.getBaseUrl(accountId, sessionId)}`);
	}

	static getAccountsLite(accountId, sessionId, cache){
		return Api.fetch(AccountsApi.getLiteUrl(accountId, sessionId), null, cache, "accounts-lite" + accountId);
	}

	static getAccountConfigurationUrl(id) {
		return `${Api.serverRoot()}accounts/${id}?update=true`;
	}

	static getAccountConfiguration(id) {
		return Api.fetch(AccountsApi.getAccountConfigurationUrl(id));
	}

	static saveAccountConfiguration(accountObj) {
		return Api.fetchPost(`${Api.serverRoot()}accounts`, accountObj)
	}

	static getConfigurations(id) {
		return Api.fetch(`${Api.serverRoot()}accounts/${id}/configurations`)
	}

	static deleteSingleConfiguration(id, configurationId) {
		return Api.fetchDelete(`${Api.serverRoot()}accounts/${id}/configurations/${configurationId}`)
	}

	static deleteMultipleConfigurations(id, ids) {
		return Api.fetchPost(`${Api.serverRoot()}accounts/${id}/configurations/delete`, ids)
	}

	static getRegistrations(id) {
		return Api.fetch(`${Api.serverRoot()}accounts/${id}/configurations/registrations`)
	}

	static getConfigurationDetails(id, factoryName) {
		return Api.fetch(`${Api.serverRoot()}accounts/${id}/configurations/registrations/${factoryName}`)
	}

	static getProfiles(id, configurationId) {
		return Api.fetch(`${Api.serverRoot()}accounts/${id}/configurations/${configurationId}/profiles/registrations`)
	}

	static getProfileDetails(id, configurationId, factoryName) {
		return Api.fetch(`${Api.serverRoot()}accounts/${id}/configurations/${configurationId}/profiles/registrations/${factoryName}`)
	}

	static saveProfileDetails(id, configurationId, profileObj) {
		return Api.fetchPost(`${Api.serverRoot()}accounts/${id}/configurations/${configurationId}/profiles`, profileObj)
	}

	static deleteSingleProfile(id, configurationId, profileId) {
		return Api.fetchDelete(`${Api.serverRoot()}accounts/${id}/configurations/${configurationId}/profiles/${profileId}`)
	}

	static deleteMultipleProfiles(id, configurationId, ids) {
		return Api.fetchPost(`${Api.serverRoot()}accounts/${id}/configurations/${configurationId}/profiles/delete`, ids)
	}

	static saveConfiguration(accountId, continueEditing, configObj) {
		var url = `${Api.serverRoot()}accounts/${accountId}/configurations`;
		if (continueEditing) {
			url += '?continueEditing=true';
		}
		return Api.fetchPost(url, configObj)
	}

	static getPropertyData(id, configurationId, propertyId, propertyObj) {
		return Api.fetchPost(`${Api.serverRoot()}accounts/${id}/configurations/${configurationId}/properties/${propertyId}/data`, propertyObj)
	}

	static refreshProfilesGrid(id, configurationId) {
		return Api.fetch(`${Api.serverRoot()}accounts/${id}/configurations/${configurationId}/profiles`)
	}

	static getConfigurationData(id, configurationId) {
		return Api.fetch(`${Api.serverRoot()}accounts/${id}/configurations/${configurationId}?update=true`)
	}

	static getAuthToken(id) {
		return Api.fetchPost(`${Api.serverRoot()}accounts/${id}/authenticationToken`)
	}

	static deleteAuthToken(id) {
		return Api.fetchDelete(`${Api.serverRoot()}accounts/${id}/authenticationToken`)
	}

	static editProfile(id, configurationId, profileId) {
		return Api.fetch(`${Api.serverRoot()}accounts/${id}/configurations/${configurationId}/profiles/${profileId}?update=true`)
	}

	static testConfiguration(id, configurationId, configurationObj) {
		return Api.fetchPost(`${Api.serverRoot()}accounts/${id}/configurations/${configurationId}/test`, configurationObj)
	}

	static postValidateConfiguration(id, configurationId, configurationObj) {
		return Api.fetchPost(`${Api.serverRoot()}accounts/${id}/configurations/${configurationId}/postValidate`, configurationObj)
	}

	static testProfile(id, configurationId, profileId, profileObj) {
		return Api.fetchPost(`${Api.serverRoot()}accounts/${id}/configurations/${configurationId}/profiles/${profileId}/test`, profileObj)
	}

	static postValidateProfile(id, configurationId, profileId, profileObj) {
		return Api.fetchPost(`${Api.serverRoot()}accounts/${id}/configurations/${configurationId}/profiles/${[profileId]}/postValidate`, profileObj)
	}

	static releaseConfiguration(id, configurationId) {
		return Api.fetch(`${Api.serverRoot()}accounts/${id}/configurations/${configurationId}/release`)
	}

	static releaseProfile(id, configurationId, profileId) {
		return Api.fetch(`${Api.serverRoot()}accounts/${id}/configurations/${configurationId}/profiles/${profileId}/release`)
	}
	static integrateAdModule() {
		return Api.fetchPost(`management/modules/install?INTEGRATION_LDAP`, ['INTEGRATION_LDAP'])
	}
}
export function useAccountsLite(accountId){
	const [accountsList, setAccountsList] = useState([]);
	useEffect(() => {
	   AccountsApi.getAccountsLite(accountId).then(loadedAccounts => {
		  setAccountsList(loadedAccounts)
	   })
	}, [accountId])
	return accountsList;
  }
export {AccountsApi}
