import React from "react";
import PropTypes from 'prop-types';

import {DropDownList} from "controls/react/kendoWrappers/dropDownList";
import {CeeViewDataSource} from "tools/ceeViewDataSource";
import {AgentAPI} from "tools/apis/agentAPI";
import {ErrorHandler} from "core/errorHandler";
import {translator} from "core/localization";
import ModalWindow from 'controls/modalWindow';
import TeamForm from 'areas/administration/teamForm';
import Utils from 'tools/utils';
import withRequired from 'controls/react/dropdowns/withRequired';

const i = translator();


export class AgentDropDown extends React.PureComponent {
	constructor(props) {
		super(props);

		this.dataSource = AgentDropDown.getDataSource(this.props.accountId, this.props.sharedAgents);
	}

	onAdd() {
		if (State.mainApp.session.hasRole('TEAM_CREATE')) {
			var width = 400;
			var scope = this;
			this.newTeamWindow = new ModalWindow({
				title: lang.account.TEAM_CREATE,
				width: width,
				height: 567,
				minHeight: 567,
				url: 'include/Administration/TeamForm.jsp',
				refresh: function () {
					new TeamForm({
						id: null,
						mode: 'create',
						accountMode: scope.props.accountMode,
						isFromAccount: true,
						accountId: scope.props.accountId
					});
				}
			});
			this.newTeamWindow.open();
		} else {
			Utils.showInfo(lang.INFO, lang.messages.NO_RIGHTS);
		}
	}

	static getDataSource(accountId, sharedAgents) {
		return new CeeViewDataSource({
			transport: {
				read: {
					url: AgentAPI.getLiteUrl(accountId, sharedAgents),
					contentType: 'application/json; charset=utf-8',
					type: 'GET',
					dataType: 'json',
					cache: false
				}
			},
			sort: {field: 'name', dir: 'asc'},
			error: ErrorHandler.kendoServerError
		});
	}

	componentDidUpdate(prevProps) {
		if (prevProps.accountId !== this.props.accountId) {
			this.ref.setDataSource(AgentDropDown.getDataSource(this.props.accountId, this.props.sharedAgents))
		}
	}

	render() {
		return <DropDownList kendoRef={(ref) => this.ref = ref}
		                     dataTextField={'name'}
		                     dataValueField={'id'}
		                     dataSource={this.dataSource}
		                     autoBind
		                     onDataBound={this.props.onDataBound}
		                     onChange={this.props.onChange}
		                     value={this.props.value}
		                     disabled={this.props.disabled}
		                     showSelectText
		                     onAdd={() => this.onAdd()}
		                     combo={true}
		                     invalid={this.props.invalid}
		                     type='agent'/>
	}
}

AgentDropDown.propTypes = {
	accountId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	value: PropTypes.string,
	onChange: PropTypes.func,
	onDataBound: PropTypes.func
}

export default withRequired(AgentDropDown);
