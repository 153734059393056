import {Api} from "tools/api";
import {ApiRequest} from "framework/api";
import { Asset } from "framework/entities/asset";
import {ApiRequestContext} from "tools/apiRequestContext";

class AssetsApi {
	static getDynamicSearchUrl(includeSubaccounts: string, accountId?: string) {
		return Api.accountRoot(accountId) + 'assets/search/lite?includeSubaccounts=' + includeSubaccounts;
	}

	static getRootUrl(accountId?: string, sessionId?: string){
		return Api.sessionAndAccountRoot(accountId, sessionId) + 'assets/';
	}

	static getAssetUrl(assetId: string, accountId?: string, sessionId?: string){
		return AssetsApi.getRootUrl(accountId, sessionId) + assetId + '/';
	}

	static getAssetDetailsUrl(assetId: string) {
		return Api.accountRoot() + 'assets/' + assetId + '/assetDetails';
	}

	static getAssetsLite({accountId, sessionId, cache, includeSubaccounts = false }
		                     : {accountId?: string, sessionId?: string, cache?: object, includeSubaccounts?: boolean}) {
		return Api.fetch(
			AssetsApi.getRootUrl(accountId, sessionId) + 'lite?includeSubaccounts=' + includeSubaccounts,
			null,
			cache,
			'assets' + accountId);
	}

	static getHealthIndex(monitorId:string = null, assetId:string, accountId?: string, sessionId?: string) {
		let url = AssetsApi.getAssetUrl(assetId, accountId, sessionId) + 'health';

		if (monitorId != null && monitorId !== '' ) {
			url += '/monitors/' + monitorId;
		}

		return Api.fetch(url);
	}

	static getMonitorsUrl(assetId: string, accountId?: string, sessionId?: string) {
		return AssetsApi.getAssetUrl(assetId, accountId, sessionId) + 'health/monitors'
	}

	static getMonitors(assetId:string, context: ApiRequestContext) {
		return Api.fetch(
			AssetsApi.getMonitorsUrl(assetId, context?.accountId, context?.sessionId),
			null,
			context?.cache,
			"asset-monitors" + assetId
		);
	}
}

class AssetGroupsApi{
	static getRootUrl(accountId?: string, sessionId?: string){
		return Api.sessionAndAccountRoot(accountId, sessionId) + 'assetGroups/';
	}

	static getGroups(accountId?: string, sessionId?: string, cache?: object) {
		return Api.fetch(
			AssetGroupsApi.getRootUrl(accountId, sessionId),
			null,
			cache,
			'assetGroups' + accountId
		);
	}

	static getGroupMembers(assetGroupId: string){
		return Api.fetch(
			AssetGroupsApi.getRootUrl() + assetGroupId + '/members'
		);
	}

	static getDynamicSearchUrl(includeSubaccounts: string) {
		return Api.accountRoot() + 'assetGroups/search/lite?includeSubaccounts=' + includeSubaccounts;
	}
}

export {AssetsApi, AssetGroupsApi}

export function getAssetGroups(accountId:string = null){
	return new ApiRequest({
		url: 'assetGroups',
		accountId,
		accountBased: true
	});
}

export function getAssetGroupAssets(groupId: string, accountId: string = null){
	return new ApiRequest<Asset[]>({
		url: `assetGroups/${groupId}/members`,
		accountId: accountId,
		accountBased: true
	});
}

export function getAsset(assetId: string, accountId: string = null){
	return new ApiRequest<Asset>({
		url: `assets/${assetId}`,
		accountId: accountId,
		accountBased: true
	});
}

export enum AssetsDataset{
	lite,
	full
}

export function getAssets(dataset: AssetsDataset = AssetsDataset.lite, accountId: string = null){
	const url = dataset == AssetsDataset.lite
			? 'assets/lite'
			: 'assets';

	return new ApiRequest<Asset[]>({
		url: url,
		accountId: accountId,
		accountBased: true
	})
}

export function getAssetsWithGeotag(accountId: string = null){
	return new ApiRequest<Asset[]>({
		url: 'assets/lite?geotag=true',
		accountId: accountId,
		accountBased: true
	})
}
