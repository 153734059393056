import LocalEventsManager from 'core/localEventsManager';
import Settings from 'settings';
import Application from 'core/application';
import RemoteEventsManager from 'core/remoteEventsManager';
import CustomNotification from 'controls/customNotification';
import GridMenu from 'controls/gridMenu';
import ModalWindow from 'controls/modalWindow';
import ServiceBoardForm from './serviceBoardForm';
import Dialog from 'controls/dialog';
import {Renderer, Api, UserPrefs, State, Utils} from 'tools';
import Cookies from 'core/cookies';
import ErrorHandler from 'core/errorHandler';

require('gridster/dist/jquery.gridster');

import WidgetForm from './widgetForm';
import WidgetReactContainer from "./widgetReactContainer";

import {DashboardWidget} from 'controls/designer/features/widgets/custom/dashboardWidget'
import {ApplicationWidget} from 'areas/application/widgets/applicationWidget'
import ApplicationStateWidget from 'areas/application/widgets/stateWidget';
import TextWidget from './widgets/textWidget';
import ImageWidget from './widgets/imageWidget';
import {TimeWidget} from "./widgets/timeWidget";
import {WebPageWidget} from './widgets/webPageWidget/webPageWidget';
import GenericGaugeWidget from './widgets/genericGaugeWidget';
import GaugeWidget from './widgets/gaugeWidget';
import MetricsWidget from './widgets/metricsWidget';
import GenericMetricsWidget from './widgets/genericMultigraphWidget';
import MultigraphWidget from './widgets/multigraphWidget';

import SlaGridWidget from 'areas/sla/widgets/gridWidget';
import SlaHistoryWidget from 'areas/sla/widgets/historyWidget';
import SlaWidget from 'areas/sla/widgets/slaWidget';
import SlaStateWidget from 'areas/sla/widgets/stateWidget';
import SlaTimeWidget from 'areas/sla/widgets/timeWidget';

import ServiceHistoryWidget from 'areas/services/widgets/historyWidget';
import ServicePreviewWidget from 'areas/services/widgets/previewWidget';
import ServiceSummaryWidget from 'areas/services/widgets/summaryWidget';
import SelectiveServicesWidget from 'areas/services/widgets/selectiveServicesWidget';
import ServiceStateWidget from 'areas/services/widgets/stateWidget';
import ServiceGridWidget from 'areas/services/widgets/gridWidget';

import AssetConsoleWidget from 'areas/assets/widgets/consoleWidget';
import AssetGridWidget from 'areas/assets/widgets/gridWidget';
import AssetStateWidget from 'areas/assets/widgets/stateWidget';
import AssetMetricsWidget from 'areas/assets/widgets/metricsWidget';
import AssetGaugeWidget from 'areas/assets/widgets/gaugeWidget';
import AssetMultigraphWidget from 'areas/assets/widgets/multigraphWidget';

import KpiMetricsWidget from 'areas/kpi/widgets/metricsWidget';
import KpiGaugeWidget from 'areas/kpi/widgets/gaugeWidget';
import KpiMultigraphWidget from 'areas/kpi/widgets/multigraphWidget';
import KpiHistoryWidget from 'areas/kpi/widgets/historyWidget';

import GridEventsSummaryWidget from './widgets/grids/eventsSummaryWidget';
import GridIncidentsWidget from './widgets/grids/incidentsWidget';
import GridReasonsWidget from './widgets/grids/reasonsWidget';
import GridSummaryWidget from './widgets/grids/summaryWidget';
import StackChartComponent from "areas/service-boards/widgets/stackChartWidget/stackChartComponent";
import WidgetSettings from "areas/service-boards/widgets/widgetSettings";
import {translator} from "core";
import {ServiceBoardApi} from "./api";

import "./serviceBoardView.less"
import CustomMetricWidget from 'areas/service-boards/widgets/customMetrics/customMetricWidget';
import {BarchartWidget} from 'areas/service-boards/widgets/barchartMetrics/barchartWidget';

const i = translator({
	"Home » {0}": {
		"no": "Hjem » {0}"
	},
	"NOTIFIER": {
		"en": "View mode: Click edit to modify content",
		"no": "Visnings: Klikk rediger for å endre innhold"
	},
	'Duplicate {0}': { //{0} is dashboard name
		no: 'Dupliser {0}'
	}
});

export default class ServiceBoardView extends Application {
	async init (id, config) {
		Utils.apply(this, config);
		if (id) {
			this.id = id;
		}
		this.hasEvents = true;
		this.subscriberId = Utils.guid();
		this.userPref = [];
		this.lastUpdates = [];
		this.headers = true;
		State.loadedDashboards = {};
		this.loadUserPreferences();
	}

	attachListeners () {
		$('#cw_sb_link_container').find('input').on('click', function (e) {
			$(e.currentTarget).select();
		});
		$('#cw_sb_layout_cancel').on('click', $.proxy(this.onCancelLayout, this));
		$('#cw_sb_layout_update').on('click', $.proxy(this.onUpdateLayout, this));
		$(window).on('resize.sbview', () => {
			window.resizeTimer && clearTimeout(window.resizeTimer);
			window.resizeTimer = setTimeout(this.onWindowResize.bind(this), 250);
		});
		$('#cw_sb_layout').on('click', '.k-i-toggle', $.proxy(this.onWidgetToggleClick, this));
		$('#cw_sb_layout').on('click', '.cw_close_settings', $.proxy(this.onToggleWidgetActions, this));
		$('.logo').on('click', 'a', $.proxy(this.onLogoClick, this));

		this.bindedHandler = $.proxy(this.loadLastUpdate, this);

		LocalEventsManager.bind('loadLastUpdate', this.bindedHandler);
	}
	/**
	 * Removes listeners
	 */
	removeListeners () {
		$(window).off('resize.sbview');
		$('#cw_sb_link_container').find('input').off();
		$('.logo').off('click');
		LocalEventsManager.unbind('loadLastUpdate', this.bindedHandler);
	}
	/**
	 * Creates the service events Kendo UI grid and also gets the service events
	 * data from the server
	 */
	initKendoComponents () {
		this.tooltip = $("#cw_sb_link_container").find('.info-sign').kendoTooltip({
			width: 160,
			position: 'left',
			content: lang.serviceBoard.messages.COPY_LINK
		}).data("kendoTooltip");
	}
	/**
	 * Service Board aplication related initializations
	 */
	initComponent (sbId) {
		if (!this.id && sbId) {
			this.id = sbId;
		}
		this.adjustHeaderWidth();

		$('.cw_sboard_area').attr('id', this.id);

		if (this.sessionId) {
			this.requestPath = Settings.serverPath + 'sessions/' + this.sessionId + '/';
		} else {
			this.requestPath = Settings.serverPath;
		}

		if (State.serviceBoardFormMode === 'create') {
			this.openWidgetForm = true;
		}

		Application.prototype.initComponent.call(this);
		this.actionNotification = new CustomNotification({
			appendToElement: '.window_area',
			status: 'success',
			style: 'top:15px; right:50px; left:50px;',
			icon: 'glyphicons  ok-circle',
			animationTime: 0
		});
		this.resolution = this.resolution || {};

		this.openWidgetForm = this.openWidgetForm || false;
		/*
		 * FIX: mainApp is not available in standalone/guest SB
		 * */
		if (State.mainApp && State.mainApp.session && State.mainApp.session.hasRole('SERVICEBOARD_UPDATE')) {
			this.mode = 'edit';

			this.layoutCurrentlyEditable = this.layoutCurrentlyEditable != undefined ? this.layoutCurrentlyEditable : State.serviceBoardFormMode;

			if (!this.layoutCurrentlyEditable && !this.openWidgetForm && State.mainApp.session.hasRole('SERVICEBOARD_UPDATE')) {
				this.notifier = $('<div class="view_mode_notifier"></div>').text(i('NOTIFIER'));
				$('.cw_window_actions').append(this.notifier);
				$('#cw_sb_layout_update').text(i('Edit'));
			}
			if (!State.mainApp.session.hasRole('SERVICEBOARD_UPDATE')) {
				$('#cw_sb_layout_update').addClass('hide');
			}
		}

		//better solution to be found
		if ($('#service_summary').hasClass('current')) {
			this.isSummary = true;
		}

		if (this.isSummary) {
			this.mode = 'view';
		}
		//disable context button
		if (this.mode !== 'edit') {
			$('.cw_multi_content button').addClass('no_rights').attr('disabled', 'disabled');
		}

		if (this.isSummary) {
			//$('#nav li').removeClass('current').parent().find('#service_summary').addClass('current');
			$('.cw_sboard_area').find('.window_titlebar').addClass('hide');
		} else {
			if (this.mode === 'edit') {
				$('.cw_window_actions').removeClass('hide');
			}

			//$('#nav li').removeClass('current').parent().find('#board').addClass('current');
			if ($('#cw_sb_menu').length) {

				this.sbGridMenu = new GridMenu({
					renderTo: 'cw_sb_menu',
					items: [{
						id: 'cw_sb_add_widget',
						icon: 'plus-sign',
						text: lang.serviceBoard.ADD_WIDGET,
						fn: this.onWidgetAdd,
						scope: this,
						//disabled: true,
						disabled: () => !this.layoutCurrentlyEditable,
						role: 'SERVICEBOARD_UPDATE'
					}, {
						id: 'cw_sb_customize',
						icon: 'display',
						text: lang.serviceBoard.CUSTOMIZE_SERVICE_BOARD,
						fn: this.onServiceBoardEdit,
						scope: this,
						disabled: () => !this.layoutCurrentlyEditable,
						role: 'SERVICEBOARD_UPDATE'
					}, {
						id: 'cw_sb_link',
						icon: 'link',
						text: lang.serviceBoard.SERVICEBOARD_LINK,
						fn: this.onServiceBoardLinkButton,
						scope: this,
						disabled: false
					}, {
						id: 'cw_sb_copy',
						iconType: 'material',
						icon: 'content_copy',
						text: lang.COPY,
						fn: this.onServiceBoardCopy,
						scope: this,
						disabled: () => !this.layoutCurrentlyEditable,
					}]
				});
			}
		}

		this.linkDialogOn = false;
		this.removeListeners();
		this.attachListeners();
		this.widgets = [];
		this.hintPos = {
			col: -1,
			row: -1
		};
		// update content button  if not detached mode
		if (State.mainApp) {
			var button = $('.cw_context_action');

			if (this.isSummary) {
				State.mainApp.contextMenu.setActionButton('ACCOUNT');
			} else {
				if (State.mainApp.session && State.mainApp.session.hasRole('SERVICEBOARD_UPDATE')) {
					button.off();
					button.text(lang.serviceBoard.ADD_WIDGET);
					button.on('click', $.proxy(this.onWidgetAdd, this));
				} else if (State.mainApp.contextMenu) {
					State.mainApp.contextMenu.setActionButton('ACCOUNT');
				}

			}
		}

		// load service board
		let id;
		if (this.id) {
			id = this.id;
		} else {
			if (this.config) {
				id = this.config.id;
			}
		}
		if (id && (!this.loadByEvent || this.layout === 'free')) {
			this.load(id);
		}
		this.removeMask();
	}
	adjustHeaderWidth() {
		$('.window_title').css('width', '70%');
		$('.cw_window_actions').css('width', '30%');
	}

	/**
	 * Called when there are no service boards available
	 */
	showEmptyTemplate () {
		var html = '<div class="new_card"><h2>';
		html += lang.serviceBoard.messages.CREATE_SERVICE_BOARD_INFO;
		html += '</h2><button id="empty_create_service_board" class="k-button k-primary create_sm">';
		html += lang.serviceBoard.messages.CREATE_SERVICE_BOARD;
		html += '</button></div>';
		$('#cw_sb_layout').append(html);
		$('#empty_create_service_board').off();
		$('#empty_create_service_board').on('click', $.proxy(this.onServiceBoardCreate, this));
	}
	/**
	 * Handler function for the click event on the Add Widget button
	 */
	onWidgetAdd () {
		var modalWindow = new ModalWindow({
			title: lang.serviceBoard.ADD_WIDGET,
			width: 820,
			minWidth: 820,
			height: 780,
			minHeight: 780,
			// height: 705,
			actions: [],
			// resizable: false,
			url: 'include/gsboard/WidgetForm.jsp',
			resizeEnd: function () {
				State.widgetForm && State.widgetForm.resize && State.widgetForm.resize();
			},
			refresh: $.proxy(function () {
				State.widgetForm = new WidgetForm({
					id: null,
					serviceBoardAccountId: this.accountId,
					mode: 'create',
					defaultWidth: this.widgetWidth,
					defaultHeight: this.widgetHeight
				});
			}, this)
		});
		modalWindow.open();
	}
	/**
	 * Handler function for the service boards list - select event
	 * @param {Object} e The select event
	 */
	onServiceBoardSelect (e) {
		var sbId = $(e.item).find('span').attr('data');
		this.destroy();
		//this.openEventSource();
		this.load(sbId);
	}
	/**
	 * Handler function for click event on the Customize ServiceBoard button
	 * @param {Object} e The click event
	 */
	onServiceBoardEdit (e) {
		if (this.id) {
			var modalWindow = new ModalWindow({
				title: lang.serviceBoard.CUSTOMIZE_SERVICE_BOARD,
				width: 900,
				height: 650,
				minHeight: 650,
				url: 'include/gsboard/ServiceBoardForm.jsp?sbId=' + this.id + '&public=' + this.isPublic + '&layout=' + this.layout,
				refresh: $.proxy(function () {
					var form = new ServiceBoardForm({
						id: this.id,
						tag: this.tag,
						description: this.description,
						isPublic: this.isPublic,
						informationOption: this.informationOption,
						position: this.position,
						headers: this.headers,
						mode: 'update',
						type: this.type,
						layout: this.layout,
						widgetWidth: this.widgetWidth,
						widgetHeight: this.widgetHeight,
						resolution: this.resolution,
						showName: this.showName,
						showUpdateTime: this.showUpdateTime,
						tags: this.tags,
						backgroundColor: this.backgroundColor
					});
				}, this)
			});
			modalWindow.open();
		}
	}
	/**
	 * Handler function for click event on the Create Service Board button
	 */
	onServiceBoardCreate () {
		if (State.mainApp.session && State.mainApp.session.hasRole('SERVICEBOARD_CREATE')) {
			var modalWindow = new ModalWindow({
				title: lang.serviceBoard.messages.CREATE_SERVICE_BOARD,
				width: 420,
				height: 650,
				minHeight: 650,
				url: 'include/gsboard/ServiceBoardForm.jsp',
				refresh: function () {
					var form = new ServiceBoardForm({
						id: 'new_service_board',
						isPublic: true,
						mode: 'create'
					});
				}
			});
			modalWindow.open();
		}
	}
	/**
	 * Handler function for click event on the Link button
	 * @param {Object} e The click event
	 */
	onServiceBoardLinkButton (e) {
		if (!$('#cw_sb_link_container').hasClass('is_open')) {
			e.stopPropagation();
			var input = $('#cw_sb_link_container').find('input');
			$('#cw_sb_link_container').addClass('is_open');

			var url = location.protocol + '//' + location.host + location.pathname + 'dashboard/' + this.id;

			input.val(url).select();
		}
	}
	/**
	 * Loads a service board from the server, based on a given id
	 * @param {String} id The id of the service board
	 */
	async load (id) {
		this.id = id;
		State.serviceBoardFormMode = null;
		let result = await Api.fetch(this.requestPath + 'accounts/dashboards/' + id);

		if (result.success) {
			Utils.apply(this, result.data || {});

			if (typeof this.initialized === 'function') {
				this.initialized({
					title: this.tag || i('New asset group')
				});
			}

			$('.cw_page_title').find('.cw_sb_name').text(i('Home » {0}', this.tag));
			this.widgets = [];
			if (this.renderName) {
				this.onRenderName();
			}

			this.content = this.content || result.data.content;

			this.serviceBoardData = result.data;
			this.renderContent(this.content);

			if (this.gridster) {
				this.initialContent = this.gridster.serialize();
			} else {
				this.initialContent = this.serialize();
			}

			//this.initialGridster = JSON.stringify(this.gridster.serialize());
			if (this.showName === false) {
				$('.cw_sb_name').addClass('hide');
			}
			if (this.showUpdateTime === false) {
				$('.cw_sboard_last_update').addClass('hide');
			}
			this.setPanelPosition();
			this.widgetsNumber = jQuery.parseJSON(this.content).length;
			if (this.mode === 'edit' && this.layoutCurrentlyEditable) {
				this.onEditLayoutItem();
			}
			if (!this.headers) {
				this.setHeaderItemsHover();
			}
		} else {
			//property set by Standalone (may be called from Guest too - TODO)
			if (this.loadedBy === 'standalone') {
				this.config.eventmanager.trigger('serviceboardGoToLogin', {
					message: result.message,
					scope: this.config.scope
				});
			} else if (this.isSummary) {
				this.loadModule('Summary');
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}
	}

	updateItemsToMobile (items, width, defaultWidgetWidth, defaultWidgetHeight) {
		const maxWidgets = Math.floor((width - 20) / (defaultWidgetWidth + 2 * this.widgetMargins)) || 1;
		const widgetWidth = Math.floor((width - 20) / maxWidgets) - 2 * this.widgetMargins;
		const widgetHeight = Math.floor(widgetWidth * defaultWidgetHeight / defaultWidgetWidth);

		let currentColumn = 0; // Means nothing added to the row
		let currentRow = 1;

		let index = 0;

		for (let item of items) {
			item.size_x = this.originalItems[index].size_x;
			item.size_y = this.originalItems[index].size_y;

			if (item.size_x > maxWidgets) {
				item.size_x = maxWidgets;
			}

			if ((currentColumn + item.size_x) > maxWidgets) {
				currentRow++;
				currentColumn = 0;
			}

			item.col = currentColumn + 1;
			item.row = currentRow;

			currentColumn += item.size_x;

			index++;
		}

		return {widgetWidth, widgetHeight}
	}

	/**
	 * Renders a serviceboard based on JSON object
	 * @param {Object} serviceBoard
	 */
	renderContent (serviceBoard) {
		var lastUpdates = JSON.parse(UserPrefs.get('serviceboardLastUpdate'));
		LocalEventsManager.trigger('loadLastUpdate', {
			lastUpdates: lastUpdates
		});
		var dragHandler;
		var found = false;
		var index = 0;
		if (this.lastUpdates && this.lastUpdates.length) {
			for (var i = 0; i < this.lastUpdates.length; i++) {
				if (this.lastUpdates[i].id === this.id) {
					found = true;
					index = i;
					break;
				}
			}
		} else {
			this.lastUpdate = Renderer.dateRenderer(new Date().getTime(), 'datetime');
			this.lastUpdates.push({
				id: this.id,
				lastUpdate: this.lastUpdate
			});
		}
		if (found) {
			this.lastUpdate = this.lastUpdates[index].lastUpdate;
		} else {
			this.lastUpdate = Renderer.dateRenderer(new Date().getTime(), 'datetime');
			this.lastUpdates.push({
				id: this.id,
				lastUpdate: this.lastUpdate
			});
		}
		$('.cw_sboard_last_update').empty().append(lang.serviceBoard.LAST_UPDATE + this.lastUpdate);

		this.originalItems = jQuery.parseJSON(serviceBoard || "[]");
		this.items = jQuery.parseJSON(serviceBoard || "[]");

		let items = this.items;

		const width = $('.sboard_scroll').width();

		$('#cw_sb_layout').empty();

		if (this.backgroundColor) {
			$(`.cw_sboard_area[id="${this.id}"]`).css('background-color', this.backgroundColor);
			$(`.sboard_scroll`).css('background-color', this.backgroundColor);
		}
		else {
			$(`.cw_sboard_area[id="${this.id}"]`).css('background-color', null);
			$(`.sboard_scroll`).css('background-color', null);
		}

		let widgetWidth = this.widgetWidth || 400;
		let widgetHeight = this.widgetHeight || 340;

		var oThis = this;
		// This is where the gridster is initialized

		var maxCols = 999, extraCols = 999;
		this.widgetMargins = 5;

		if (this.headers) {
			dragHandler = '.cw_section_titlebar, .cw_section_title, .react-widget-title .title-text';
		} else {
			dragHandler =  '.cw_move_widget';
		}

		for (let item of items) {
			if (item.widget && item.widget.type === 'gauge') {
				item.size_x = 1;
				item.size_y = 1;
			}
		}

		if ((this.resolution.type === 'MOBILE' || this.resolution.type == 'RESPONSIVE') && (this.isGuest || this.loadedBy === 'standalone')) {
			const out = this.updateItemsToMobile(this.items, width, widgetWidth, widgetHeight);

			widgetWidth = out.widgetWidth;
			widgetHeight = out.widgetHeight;
		}

		this.gridster = $('#cw_sb_layout').gridster({
			widget_selector: '.cw_section',
			// width and height are calculated based on resolution
			autogenerate_stylesheet: true,
			widget_base_dimensions: [widgetWidth, widgetHeight],
			widget_margins: [this.widgetMargins, this.widgetMargins],
			autogrow_cols: true,
			max_cols: maxCols,
			extra_cols: extraCols,
			resize: {
				enabled: !this.isSummary,
				axes: ['both'],
				min_size: [1, 1],
				resize: $.proxy(this.onGridsterWidgetResize, this),
				// See http://gridster.net/#resize_stop_opt for params
				stop: function (e, ui, w) {
					// restrict the number of columns and/or rows for a widget
					// in this case it is used for both columns and rows
					var max = Math.max(this.resize_last_sizex, this.resize_last_sizey);
					oThis.onGridsterWidgetResizeStop(e, ui, w, max);
				}
			},
			draggable: {
				handle: dragHandler,
				start: $.proxy(this.onGridsterWidgetDrag, this),
				stop: $.proxy(this.onGridsterWidgetDrop, this)
			},
			serialize_params: $.proxy(this.serializeParams, this),
			avoid_overlapped_widgets: true
		}).data('gridster');

		this.disableWidgets();

		$('.sb-custom-theme').attr('id', this.id);

		this.isConvertedFromFree = false;
		for (var i = 0; i < items.length; i++) {
			if (items[i] && items[i].widget) {

				if (items[i].widget.width || items[i].widget.height || items[i].widget.top || items[i].widget.left || this.isConvertedFromFree) {
					//only way to detect if sb just converted from free?
					this.isConvertedFromFree = true;
					items[i].size_x = parseInt(items[i].widget.width / this.widgetWidth);
					items[i].size_y = parseInt(items[i].widget.height / this.widgetHeight);
					//items[i].col = parseInt(items[i].widget.left / this.widgetWidth) + 1;
					//items[i].row = parseInt(items[i].widget.top / this.widgetHeight) + 1;
				}

				// this is where the widget wrapper is created (this is the actual gridster widget!)
				items[i].$widget = this.gridster.add_widget(this.getWidgetHtml(items[i].widget), parseInt(items[i].size_x), parseInt(items[i].size_y), parseInt(items[i].col), items[i].row);

				// this is where we embed the ceeview widget content within the gridster widget]
				var isFirstRendering = true;
				this.addWidget(items[i].widget, items[i].size_x, items[i].size_y, isFirstRendering);
			}

		}

		if (this.mode !== 'edit') {
			this.disableWidgets();
		}

		if (!this.layoutCurrentlyEditable) {
			this.disableWidgets();
		}

		if (this.openWidgetForm) {
			this.openWidgetForm = false;
			this.onEditLayoutItem();
			this.onWidgetAdd();
		} else if (this.mode !== 'edit') {
			$('.cw_window_actions').find('.k-button').addClass('hide');
		}
		this.toggleHeaders();
		if (this.headers) {
			this.turnHeadersOn();
		} else {
			this.turnHeadersOff();
		}

		this.removeMask();
		if ($('#main_loading_mask').length) {
			$('#main_loading_mask').remove();
		}
		var gridsterData = $('#cw_sb_layout').data('gridster');
		var lastOccupiedColumn = 0, lastOccupiedRow = 0;
		$('.cw_widget').each(function (index) {
			var currentWidgetColumn = $(this).attr('data-col');
			var currentWidgetRow = $(this).attr('data-row');
			if (currentWidgetColumn > lastOccupiedColumn) {
				lastOccupiedColumn = currentWidgetColumn;
			}
			if (currentWidgetRow > lastOccupiedRow) {
				lastOccupiedRow = currentWidgetRow;
			}
		});
	}
	/**
	 * Calls the resize method of the widget and saves the SB
	 * @param {Object} e The event object
	 * @param {Object} ui The widget position object
	 * @param {Object} w The jQuery HTMLElement object
	 */
	onGridsterWidgetResize (e, ui, w) {
		var wrapper = w.closest('.gridster'),
			wrapperTop = wrapper.offset().top,
			wrapperHeight = wrapper.height(),
			scrollableDiv = wrapper.parent(),
			widgetHeight = w.height(),
			widgetTop = ui.position.top,
			widgetPrevTop = ui.prev_position.top;
		if (scrollableDiv.height() < wrapperHeight) {
			scrollableDiv.scrollTop(widgetTop + widgetHeight + 100);
		}
	}
	/**
	 * Calls the resize method of the widget and saves the SB
	 * @param {Object} e The event object
	 * @param {Object} ui The widget position object
	 * @param {Object} w The jQuery HTMLElement object
	 * @param {Number} max Max size
	 */
	onGridsterWidgetResizeStop (e, ui, w, max) {
		var widgetId = w.attr('id');
		var widget = this.getWidget(widgetId);
		if (widget.type === 'asset_console') {
			this.gridster.resize_widget(w, max, max, function () {
				widget.onResize(max);
			});
		} else {
			widget.onResize();
		}
		var widgetDiv = $('#' + widgetId);
		if ((this.resolution.type === 'MOBILE' || this.resolution.type == 'RESPONSIVE')
			&& (this.isGuest || this.loadedBy === 'standalone')) {
			var widgetLeftPos = widgetDiv.position().left;
			var widgetTopPos = widgetDiv.position().top;
			var scrollDiv = $('.cw_sboard_area');
			scrollDiv.scrollLeft(widgetLeftPos - this.widgetWidth);
			scrollDiv.scrollTop(widgetTopPos - this.widgetHeight);
		}
		//this.saveServiceBoard();
	}
	/**
	 * Sets the z-index of the widget on drag
	 * @param {Object} e The event object
	 * @param {Object} ui The widget position object
	 */
	onGridsterWidgetDrag (e, ui) {
		var widgetWrapper = ui.$player;
		var widget = widgetWrapper.find('.cw_section_content');
		var widgetId = widgetWrapper.attr('id');
		widgetWrapper.css({
			"z-index": 999
		});
		widget.addClass('hide');
		widgetWrapper.append(this.draggableHint(widgetId));

		this.draggingElement = $(e.target).closest('.cw_widget');
	}
	/**
	 * Sets the z-index of the widget on drop
	 * @param {Object} e The event object
	 * @param {Object} ui The widget position object
	 */
	onGridsterWidgetDrop (e, ui) {
		var widgetWrapper = ui.$player;
		var widget = widgetWrapper.find('.cw_section_content');
		var widgetId = widgetWrapper.attr('id');
		var clone = widgetWrapper.find('div[data-id=' + widgetId + ']');
		widgetWrapper.css({
			"z-index": 10
		});
		clone.remove();
		widget.removeClass('hide');
		if (this.resolution.type === 'LOCKED') {
			var widgetLeftPos = widgetWrapper.position().left;
			var widgetTopPos = widgetWrapper.position().top;
			var scrollDiv = $('.cw_sboard_area');
			scrollDiv.scrollLeft(widgetLeftPos - this.widgetWidth);
			scrollDiv.scrollTop(widgetTopPos - this.widgetHeight);
		}
		setTimeout($.proxy(this.calculateBoardResolution, this), 100);
	}
	setHeaderItemsHover () {
		var container = $('.cw_widget');
		container.find('.cw_section_titlebar').addClass('hide');

		container.on('mouseover', function (e) {
			var target = $(e.currentTarget);
			target.find('.cw_section_titlebar').removeClass('hide');
		});
		container.on('mouseleave', function (e) {
			var target = $(e.currentTarget);
			target.find('.cw_section_titlebar').addClass('hide');
		});
	}
	renderWidgetPanel (config) {
		let $widgetPanel = $('<div/>').attr('id', config.id).addClass('cw_section cw_widget');

		if (config.type.indexOf('grid') !== -1) {
			//$widgetPanel.attr('data-min-sizex', 2);
		} else if (config.type.indexOf('console') !== -1) {
			$widgetPanel.attr('data-max-sizex', 2);
			$widgetPanel.attr('data-max-sizey', 2);
		} else if (config.type === 'gauge') {
			$widgetPanel.attr('data-max-sizex', 1);
			$widgetPanel.attr('data-max-sizey', 1);
		}

		if (config.type === 'history') {
			$widgetPanel.addClass('cw_selector');
		}

		if (config.configuration && config.configuration.removeHeader !== undefined) {
			$widgetPanel.addClass('skip-header-config');

			if (config.configuration.removeHeader) {
				$widgetPanel.addClass('no_header');
			}
		}

		return $widgetPanel;
	}

	renderSectionTitleBar (config) {
		let $section_titlebar = $('<div/>').addClass('cw_section_titlebar');

		$section_titlebar.append($('<span/>').addClass('cw_section_title').text(config.title));

		if (State.mainApp && State.mainApp.session && State.mainApp.session.hasRole('SERVICEBOARD_UPDATE') && !this.isSummary) {
			$section_titlebar.append($('<span/>').addClass('k-icon k-i-delete cw_remove_widget').attr('title', lang.DELETE));
			$section_titlebar.append($('<span/>').addClass('k-icon k-i-pencil cw_edit_widget').attr('title', lang.serviceBoard.EDIT_WIDGET));
		}
		if (!this.headers) {
			$section_titlebar.append($('<span/>').addClass('k-icon k-i-dimension cw_move_widget')
				.attr('title', lang.serviceBoard.MOVE));
		}

		if (['grid_service', 'grid_asset', 'grid_sla', 'grid_incidents', 'grid_reasons', 'grid_event_summary'].indexOf(config.type) !== -1 && !this.isGuest) {
			$section_titlebar.append(
				$('<div/>').addClass('right').append(
					$('<input/>').addClass('cw_search_box').attr('placeholder', lang.SEARCH).attr('id', Utils.guid()).attr('type', 'text')
				)
			);
		}

		if ([
				'metrics', 'single_chart', 'graph_metric', 'multi_graph_asset', 'single_graph', 'multi_graph_assetgroup',
				'multigraph', 'asset_metrics', 'asset_multigraph', 'grid_event_summary', 'kpi_metrics', 'kpi_multigraph',
				'kpi_history', 'services'].indexOf(config.type) !== -1) {
			$section_titlebar.append($('<span/>').addClass('cw_toggle_options k-icon k-i-toggle').attr('title', lang.serviceBoard.CHART_SETTINGS));
		}

		if (config.type === 'grid_sla') {
			let multiToggle = '<ul class="cw_multi_toggle small right">';
			multiToggle += '<li class="first_btn"><input type="radio" name="cw_slas_period" value="DAY" />' + lang.DAY + '</li>';
			multiToggle += '<li><input type="radio" name="cw_slas_period" value="WEEK" checked="checked"/>' + lang.WEEK + '</li>';
			multiToggle += '<li><input type="radio" name="cw_slas_period" value="MONTH" />' + lang.MONTH + '</li>';
			multiToggle += '<li class="last_btn"><input type="radio" name="cw_slas_period" value="ALL" />' + lang.ALL + '</li>';
			multiToggle += '</ul>';

			$section_titlebar.append($(multiToggle));
		}

		return $section_titlebar;
	}

	renderWidgetActions (config) {
		let $widget_actions = $('<div/>').addClass('cw_widget_actions right');

		if (State.mainApp && State.mainApp.session && State.mainApp.session.hasRole('SERVICEBOARD_UPDATE') && !this.isSummary) {
			let widget_buttons = '<div class="cw_widget_buttons">';
			widget_buttons += '<button class="k-button k-primary k-button-icontext cw_edit_widget">' + lang.serviceBoard.EDIT_WIDGET + '</button>';
			widget_buttons += '<button class="k-button k-primary k-button-icontext cw_remove_widget">' + lang.REMOVE + '</button>';
			widget_buttons += '<button class="k-button k-button-icontext cw_close_settings"></span>' + lang.CLOSE + '</button>';
			widget_buttons += '</div>';

			$widget_actions.append($(widget_buttons));
		}

		return $widget_actions;
	}
	/**
	 * Creates the actual HTML for the gridster widget
	 * @param {Object} config The widget config object
	 */
	getWidgetHtml (config) {
		let $widgetPanel = this.renderWidgetPanel(config);

		$widgetPanel.append(this.renderSectionTitleBar(config));
		$widgetPanel.append($('<div/>').addClass('cw_section_content'));

		return $widgetPanel;
	}
	/**
	 * Adds a widget on a column
	 * The ceeview widget is embeded into the gridster widget which acts like a wrapper
	 * @param {Object} config The widget config object
	 * @param {Integer} size_x Number of rows occupied
	 * @param {Integer} size_y Number of columns occupied
	 * @param {Boolean} isFirstRendering Flag to know when the method was called
	 */
	addWidget (config, size_x, size_y, isFirstRendering) {
		var widget;
		var widgetDiv = $('#' + config.id);
		if (this.resolution.type === 'LOCKED' && !isFirstRendering) {
			var widgetLeftPos = widgetDiv.position().left;
			$('.cw_sboard_area').scrollLeft(widgetLeftPos);
		}
		if (config.type === 'asset_console') {
			config.size = size_x;
		}
		config.serviceBoardData = this.serviceBoardData;

		widget = this.getWidgetByConfig(config);

		if( widget == null )
			return;

		this.widgets.push(widget);
		widget.setHeight(config.height || '280px');

		// attaches widget listeners
		if (State.mainApp && State.mainApp.session && State.mainApp.session.hasRole('SERVICEBOARD_UPDATE') && !this.isSummary) {
			widgetDiv.find('.cw_remove_widget').off().on('click', $.proxy(this.onRemoveWidget, this));
			widgetDiv.find('.cw_edit_widget').off().on('click', $.proxy(this.onEditWidget, this));
		}
	}
	/**
	 * Updates an existing widget
	 * @param {Object} widgetObj
	 */
	updateWidget (widgetObj) {
		var widget = this.getWidget(widgetObj.id);
		if (widget.unsubscribe) {
			widget.unsubscribe();
		}
		widget.destroy();
		var widgetDiv = $('#' + widgetObj.id);
		widgetDiv.find('.cw_section_title').html(widgetObj.title);
		widgetDiv.find('.cw_section_content').empty();

		//save some old configuration
		var specificConfiguration = {};
		if (widget.hasConfiguration) {
			specificConfiguration = widget.getConfiguration();
		}

		if (widgetObj.configuration) {
			widgetObj.configuration = $.merge(widgetObj.configuration, specificConfiguration);
		}

		widgetObj.serviceBoardData = this.serviceBoardData;
		widget = this.getWidgetByConfig(widgetObj);

		for (var i = 0; i < this.widgets.length; i++) {
			if (this.widgets[i].id === widget.id) {
				this.widgets[i] = widget;
				break;
			}
		}
	}
	getWidgetByConfig (config) {
		var widget;
		if (this.sessionId) {
			config.sessionId = this.sessionId;
		}

		if (!config.configuration.accountId) {
			config.configuration.accountId = this.accountId;
		}

		switch (config.type) {
			case 'service_presentation':
				widget = new DashboardWidget(config);
				break;
			case 'application_preview':
				widget = new ApplicationWidget(config);
				break;
			case 'application_state':
				widget = new ApplicationStateWidget(config);
				break;
			case 'smpreview':
				widget = new ServicePreviewWidget(config);
				break;
			case 'summary':
				widget = new ServiceSummaryWidget(config);
				break;
			case 'history':
				config.isServiceboardHistoryWidget = true;
				widget = new ServiceHistoryWidget(config);
				break;
			case 'services':
				config.customControls = {
					target: '#' + config.id,
					toggleClick: function (value) {
						if ($('#' + this.id).find('.k-i-toggle').length) {
							$('#' + this.id).find('.k-i-toggle').trigger('click');
						} else {
							$('#' + this.id).closest('.k-window').find('.k-i-toggle').trigger('click');
						}
					}
				};
				widget = new SelectiveServicesWidget(config);
				break;
			case 'grid_service':
				widget = new ServiceGridWidget(config);
				break;
			case 'bar_ss':
				widget = new ServiceStateWidget(config);
				break;


			case 'sla':
				widget = new SlaWidget(config);
				break;
			case 'sla_history':
				widget = new SlaHistoryWidget(config);
				break;
			case 'sla_time':
				widget = new SlaTimeWidget(config);
				break;
			case 'bar_sla':
				widget = new SlaStateWidget(config);
				break;
			case 'grid_sla':
				widget = new SlaGridWidget(config);
				break;
			case 'bar_sh':
				widget = new AssetStateWidget(config);
				break;
			case 'grid_asset':
				widget = new AssetGridWidget(config);
				break;
			case 'asset_console':
				widget = new AssetConsoleWidget(config);
				break;
			case 'asset_metrics':
				widget = new AssetMetricsWidget(config);
				break;
			case 'asset_gauge':
				widget = new AssetGaugeWidget(config);
				break;
			case 'asset_multigraph':
				widget = new AssetMultigraphWidget(config);
				break;
			case 'kpi_metrics':
				widget = new KpiMetricsWidget(config);
				break;
			case 'kpi_gauge':

				widget = new KpiGaugeWidget(config);
				break;
			case 'kpi_multigraph':
				widget = new KpiMultigraphWidget(config);
				break;
			case 'kpi_history':
				widget = new KpiHistoryWidget(config);
				break;


			case 'grid_summary':
				widget = new GridSummaryWidget(config);
				break;
			case 'grid_incidents':
				widget = new GridIncidentsWidget(config);
				break;
			case 'grid_reasons':
				widget = new GridReasonsWidget(config);
				break;
			case 'grid_event_summary':
				config.customControls = {
					target: '#' + config.id,
					toggleClick: function (value) {
						if ($('#' + this.id).find('.k-i-toggle').length) {
							$('#' + this.id).find('.k-i-toggle').trigger('click');
						} else {
							$('#' + this.id).closest('.k-window').find('.k-i-toggle').trigger('click');
						}
					}
				};
				widget = new GridEventsSummaryWidget(config);
				break;
			case 'graph_metric':
			case 'multi_graph_asset':
			case 'single_graph':
			case 'multi_graph_assetgroup':
				if (config.type === 'multi_graph_asset' || config.type === 'multi_graph_assetgroup') {
					config.configuration.hideThreshold = true;
				}
				widget = new GenericMetricsWidget(config);
				break;
			case 'stacked_chart':
				widget = new WidgetReactContainer(StackChartComponent, WidgetSettings, config, 'stack_chart__wrapper');
				break;
			case 'custom_metric':
				widget = new WidgetReactContainer(CustomMetricWidget, WidgetSettings, config, 'custom_metric_widget__wrapper');
				break;
			case 'barchart_metric':
				widget = new WidgetReactContainer(BarchartWidget, WidgetSettings, config, 'barchart_metric_widget__wrapper');
				break;
			case 'graph_gauge':
				config.customControls = {
					target: '#' + config.id,
					toggleClick: function (value) {
						if ($('#' + this.id).find('.k-i-toggle').length) {
							$('#' + this.id).find('.k-i-toggle').trigger('click');
						} else {
							$('#' + this.id).closest('.k-window').find('.k-i-toggle').trigger('click');
						}
					}
				};
				widget = new GenericGaugeWidget(config);
				break;


			case 'gauge':
				if (config.configuration.type === 'MONITOR') {
					config.customControls = {
						target: '#' + config.id,
						toggleClick: function (value) {
							if ($('#' + this.id).find('.k-i-toggle').length) {
								$('#' + this.id).find('.k-i-toggle').trigger('click');
							} else {
								$('#' + this.id).closest('.k-window').find('.k-i-toggle').trigger('click');
							}
						}
					};
					if (!config.configuration.labelTemplate) {
						config.configuration.labelTemplate = ['<Asset>', '<Name>', '<Type>', '<Category>', '<Instance>'];
					}
					widget = new GenericGaugeWidget(config);
				} else {
					widget = new GaugeWidget(config);
				}
				break;
			case 'metrics':
				if (config.configuration.type === 'MONITOR') {
					config.customControls = {
						target: '#' + config.id,
						toggleClick: function (value) {
							if ($('#' + this.id).find('.k-i-toggle').length) {
								$('#' + this.id).find('.k-i-toggle').trigger('click');
							} else {
								$('#' + this.id).closest('.k-window').find('.k-i-toggle').trigger('click');
							}
						}
					};
					if (!config.configuration.labelTemplate) {
						config.configuration.labelTemplate = ['<Asset>', '<Name>', '<Type>', '<Category>', '<Instance>'];
					}
					widget = new GenericMetricsWidget(config);
				} else {
					widget = new MetricsWidget(config);
				}
				break;
			case 'multigraph':
				if (config.configuration.type === 'MONITOR') {
					config.customControls = {
						target: '#' + config.id,
						toggleClick: function (value) {
							if ($('#' + this.id).find('.k-i-toggle').length) {
								$('#' + this.id).find('.k-i-toggle').trigger('click');
							} else {
								$('#' + this.id).closest('.k-window').find('.k-i-toggle').trigger('click');
							}
						}
					};
					if (!config.configuration.labelTemplate) {
						config.configuration.labelTemplate = ['<Asset>', '<Name>', '<Type>', '<Category>', '<Instance>'];
					}
					widget = new GenericMetricsWidget(config);
				} else {
					widget = new MultigraphWidget(config);
				}
				break;
			case 'custom_text':
				widget = new TextWidget(config);
				break;
			case 'custom_image':
				widget = new ImageWidget(config);
				break;
			case 'custom_time':
				widget = new WidgetReactContainer(TimeWidget, null, config);
				break;
			case 'custom_webPage':
				widget = new WidgetReactContainer(WebPageWidget, null, config);
				break;
			default:
				console.warn('Widget with type "' + config.type + '" was not found');
		}

		widget.serviceBoard = this;

		if(widget.init){
			widget.init();
		}
		return widget;
	}
	/**
	 * Called to create the hint when dragging
	 * @param {Object} widgetId The id of the widget
	 * @return {Object} hint The hint object
	 */
	draggableHint (widgetId) {
		var widgetDiv = $('#' + widgetId).find('cw_section_content'), clone_height = widgetDiv.height(),
			clone_width = widgetDiv.width(), widget = this.getWidget(widgetId), clone, iconClass, icon;
		switch (widget.type) {
			case 'smpreview':
				iconClass = 'git-merge';
				break;
			case 'sla':
				iconClass = 'circle-arrow-top';
				break;
			case 'sla_history':
				iconClass = 'charts';
				break;
			case 'metrics':
				iconClass = 'stats';
				break;
			case 'summary':
				iconClass = 'align_right';
				break;
			case 'history':
				iconClass = 'ruller';
				break;
			case 'gauge':
				iconClass = 'dashboard';
				break;
			case 'bar_sh':
				iconClass = 'charts';
				break;
			case 'bar_ss':
				iconClass = 'charts';
				break;
			case 'bar_sla':
				iconClass = 'charts';
				break;
			case 'grid_summary':
				iconClass = 'table';
				break;
			case 'grid_service':
				iconClass = 'table';
				break;
			case 'grid_asset':
				iconClass = 'table';
				break;
			case 'grid_event_summary':
				iconClass = 'table';
				break;
			case 'grid_sla':
				iconClass = 'table';
				break;
			case 'grid_reasons':
				iconClass = 'table';
				break;
			case 'asset_console':
				iconClass = 'heart';
				break;
		}
		icon = '<span class="widget_placeholder_icon glyphicons ' + iconClass + '"></span>';
		clone = document.createElement('div');
		clone.setAttribute('data-id', widget.id);
		clone.innerHTML = icon;
		clone.style.maxWidth = clone_width + 'px';
		clone.style.height = clone_height + 'px';

		return clone;
	}
	/**
	 * Handler for the drag event of a widget
	 * @param {Object} e The event
	 */
	draggableOnDragEnd (e) {
		$(e.currentTarget).parent().show();
		$('#widget_hint').remove();
	}
	/**
	 * Saves a service board content
	 */
	saveServiceBoard () {
		var data, url, resolution, resConfig = {};
		if (!this.isSummary) {
			resConfig.type = this.resolution.type;
			resolution = this.getCanvasResolution();
			resConfig.width = resolution.width;
			resConfig.height = resolution.height;

			data = {
				backgroundColor: this.backgroundColor,
				resolution: resConfig,
				tag: $('#sb_tag').length ? $('#sb_tag').val() : this.tag,
				tags: this.tags,
				id: this.id,
				type: this.type,
				headers: this.headers,
				description: $('#sb_description').length ? $('#sb_description').val() : this.description,
				isPublic: this.isPublic,
				layout: this.layout,
				content: JSON.stringify(this.setWidgetConfiguration(this.serialize())),
				widgetWidth: this.widgetWidth,
				widgetHeight: this.widgetHeight,
				showName: this.showName,
				informationOption: this.informationOption,
				position: this.position,
				showUpdateTime: this.showUpdateTime
			};

			//this.markString = data.content;
			url = Settings.serverPath + 'accounts/dashboards/';
			Utils.ajax(url, 'POST', JSON.stringify(data), $.proxy(this.onDataSuccessfullySaved, this));
		}
	}
	serialize () {
		var compare = function compare(a, b) {
			var value = 0;
			if (a.col < b.col) {
				value = -1;
			} else if (a.col > b.col) {
				value = 1;
			}
			return value;
		};
		if ($('#cw_sb_layout').data('gridster')) {
			var serviceBoard = $('#cw_sb_layout').data('gridster').serialize();
			return serviceBoard.sort(compare);
		} else {
			//smart fix for stupid bug
			return JSON.parse(this.markString) || [];
		}
	}
	/**
	 * Serialize parameters for the grisdter object
	 * @param {Object} w the jQuery wrapped HTMLElement
	 * @param {Object} wgt The grid coords object with keys col, row, size_x and
	 * size_y
	 */
	serializeParams (w, wgt) {
		var widgetId = w.attr('id');
		var widget = this.getWidget(widgetId);

		if (widget) {
			return {
				widget: {
					id: widgetId,
					title: widget.title,
					type: widget.type,
					configuration: widget.configuration
				},
				col: wgt.col,
				row: wgt.row,
				size_x: wgt.size_x,
				size_y: wgt.size_y
			};
		}
	}
	/**
	 * Returns a widget object based on its id
	 * @param {String} widgetId
	 * @return {Object} widget
	 */
	getWidget (widgetId) {
		return this.widgets.find(x => x.id == widgetId);
	}
	/**
	 * Handler function for the click action on the widget actions button
	 * @param {Object} e The click event
	 */
	onToggleWidgetActions (e) {
		var widgetActions = $(e.currentTarget).closest('.cw_section').find('.cw_widget_actions');
		widgetActions.toggleClass('expanded');
	}
	/**
	 * Handler function for the remove widget button
	 * @param {Object} e The click event
	 */
	onRemoveWidget (e) {
		var widget = $(e.currentTarget).closest('.cw_widget');
		widget.find('.cw_widget_actions').toggleClass('expanded');
		var widgetId = widget.attr('id');
		var dialog = new Dialog({
			title: lang.INFO,
			msg: lang.serviceBoard.messages.WIDGET_REMOVE,
			scope: this,
			fn: function (value, button) {
				if (button === 'ok') {
					var widget = this.getWidget(widgetId);
					$('#cw_sb_layout').data('gridster').remove_widget('#' + widgetId);
					// remove listeners and tasks
					if (widget.unsubscribe) {
						widget.unsubscribe();
					}
					widget.destroy();

					this.widgets = this.widgets.filter((widget) => widget.id !== widgetId);

					$('#' + widgetId).remove();
					//why save after remove?
					//setTimeout($.proxy(this.saveServiceBoard, this), 500);
				}
			}
		});
		dialog.show();
	}
	/**
	 * Handler function for the remove widget button
	 * @param {Object} e The click event
	 */
	onEditWidget (e) {
		var widget, target = $(e.currentTarget);
		if (target.closest('.cw_widget').length) {
			widget = target.closest('.cw_widget');
			widget.find('.cw_widget_actions').toggleClass('expanded');
		} else {
			widget = target.closest('.k-window').find('.cw_widget');
		}

		var widgetId = widget.attr('id');
		var modalWindow = new ModalWindow({
			mode: 'update',
			title: lang.serviceBoard.EDIT_WIDGET,
			width: 820,
			minWidth: 820,
			height: 780,
			minHeight: 780,
			// height: 705,
			actions: [],
			// resizable: false,
			url: 'include/gsboard/WidgetForm.jsp',
			refresh: $.proxy(function () {
				State.widgetForm = new WidgetForm({
					id: widgetId,
					serviceBoardAccountId: this.accountId,
					mode: 'update',

				});
			}, this)
		});
		modalWindow.open();
	}
	/**
	 * Handler function for the click event on the Edit layout menu item
	 */
	onEditLayoutItem () {
		$('.cw_window_actions').find('.k-button').removeClass('hide');
		this.enableWidgets();
		this.gridsterSerialization = this.gridster.serialize();
		this.markString = JSON.stringify(this.serialize());
		this.mode = 'edit';
		if ($('#cw_sb_edit_layout').length) {
			setTimeout($.proxy(function () {
				this.sbGridMenu.removeItem('cw_sb_edit_layout');
			}, this), 50);
		}
		//show resolution
		this.calculateBoardResolution();

		$('.cw_multi_content button').removeClass('no_rights').removeAttr('disabled');
	}
	/**
	 * Handler function for the click event on the Cancel button
	 */
	onCancelLayout () {
		this.skipDirtyCheck = true;
		State.mainApp.loadModule('ServiceBoard');
	}
	/**
	 * Handler function for the click event on the Update button
	 */
	onUpdateLayout () {
		if (!this.layoutCurrentlyEditable) {
			$('#cw_sb_layout_update').text(i('Update'));
			if (this.notifier) {
				this.notifier.addClass('hide');
			}
			this.enableWidgets();
			this.layoutCurrentlyEditable = true;
			return;
		}

		this.skipDirtyCheck = true;
		this.saveServiceBoard();
		State.mainApp.loadModule('ServiceBoard');
	}
	/**
	 * Loads user preferences
	 */
	loadUserPreferences () {
		this.userPref = [];
		UserPrefs.load('ServiceboardLastUpdate', $.proxy(function (result) {
			if (result.success) {
				this.userPref = result.data;
				this.initComponent();
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this), this.sessionId);
	}
	/**
	 * Loads recent icons
	 * @param {Object} e The object sent by event manager
	 */
	loadLastUpdate (e) {
		if (e.lastUpdates && e.lastUpdates.length) {
			this.lastUpdates = e.lastUpdates;
		} else {
			this.lastUpdates = [];
		}
	}
	/**
	 * Enables widgets drag&drop, widgets resizing and configuration
	 */
	enableWidgets () {
		this.gridster.enable();
		if (!this.isSummary) {
			this.gridster.enable_resize();
		}
		if (this.sbGridMenu) {
			this.sbGridMenu.enableItem('cw_sb_add_widget');
			this.sbGridMenu.enableItem('cw_sb_customize');
			this.sbGridMenu.enableItem('cw_sb_copy');
		}

		$('#cw_sb_layout').find('.cw_move_widget, .cw_remove_widget, .cw_edit_widget').removeClass('hide');
		$('#cw_sb_layout').find('.cw_section_titlebar').addClass('cursor_move');

	}
	/**
	 * Enables widgets drag&drop, widgets resizing and configuration
	 */
	disableWidgets () {
		this.gridster.disable();
		if (this.gridster.resize_api) {
			this.gridster.disable_resize();
		}
		if (this.sbGridMenu) {
			this.sbGridMenu.disableItem('cw_sb_add_widget');
			this.sbGridMenu.disableItem('cw_sb_customize');
			this.sbGridMenu.disableItem('cw_sb_copy');
		}
		$('#cw_sb_layout').find('.cw_move_widget, .cw_edit_widget, .cw_remove_widget').addClass('hide');
		$('#cw_sb_layout').find('.cw_section_titlebar').removeClass('cursor_move');
	}
	/**
	 * Callback function for save
	 * @param {Object} result
	 */
	onDataSuccessfullySaved (result) {
		if (!result.success) {
			Utils.showInfo(lang.ALERT, result.message, result.details);
		} else {
			this.actionNotification.setOptions({
				message: lang.serviceBoard.messages.SERVICE_BOARD_UPDATED,
				status: 'success'
			}).show();
		}
	}
	/**
	 * Handler function for the click on the include subaccounts checkbox
	 * @param {Boolean} includeSubaccounts
	 */
	onIncludeSubaccounts (includeSubaccounts) {
		var i, length = this.widgets.length, type, widget, save = false;
		for (let i = 0; i < length; i++) {
			widget = this.widgets[i];
			if (widget.refresh) {
				widget.configuration.includeSubaccounts = includeSubaccounts;
				widget.refresh();
				save = true;
			}
		}
		if (save) {
			this.saveServiceBoard();
		}
	}
	/**
	 * Checks not to be in edit mode when doing an exit
	 * @param {String} moduleName
	 * @param {String} id
	 * @param {Object} config
	 * @param {String} role
	 */
	tryExit (moduleName, id, config, role) {
		if (role && State.mainApp.session && !State.mainApp.session.hasRole(role)) {
			//Todo: show privileges warning
		} else {
			State.mainApp.loadModule(moduleName, id, config);
		}
	}
	/**
	 * Method for calculating the board resolution
	 * @param {Boolean} isPreviousResolutionCalculated
	 */
	calculateBoardResolution (isPreviousResolutionCalculated) {
		var resolution = this.getCanvasResolution(isPreviousResolutionCalculated);

		$('.cw_sboard_resolution').removeClass('hide').text(resolution.width + ' x ' + resolution.height + ' px');
	}
	/*
	 * Handler function for getting canvas resolution
	 * @returns {Object} Canvas resolution
	 */
	getCanvasResolution (isPreviousResolutionCalculated) {
		var canvas = $('#cw_sb_layout');
		var canvasWidth = canvas.width();
		var canvasHeight = canvas.height() || $('.cw_sboard_area').height();
		var windowAreaWidth = $('.window_area').width();
		var windowAreaHeight = $('.window_area').height();
		if (canvasWidth < windowAreaWidth) {
			canvasWidth = windowAreaWidth;
		}
		if (canvasHeight < windowAreaHeight) {
			canvasHeight = windowAreaHeight;
		}
		if (isPreviousResolutionCalculated) {
			canvasWidth = this.resolution.width || canvasWidth;
			canvasHeight = this.resolution.height || canvasHeight;
		}
		return {
			width: canvasWidth,
			height: canvasHeight
		};
	}
	onWidgetToggleClick (e) {
		var customContainer, timeSelector;
		if ($(e.currentTarget).closest('.k-window').find('.cw_widget_settings').length) {
			customContainer = $(e.currentTarget).closest('.k-window').find('.cw_widget_settings');
		} else {
			customContainer = $(e.currentTarget).closest('.cw_widget').find('.cw_widget_settings');
			timeSelector = $(e.currentTarget).closest('.cw_widget').find('.cw_custom_time_selector');
		}
		if (customContainer.length) {
			if (customContainer.is(':visible')) {
				customContainer.slideUp(150);
				if (timeSelector) {
					timeSelector.addClass('hide');
				}
			} else {
				customContainer.slideDown(350);
				//timeSelector.removeClass('hide');
			}
		}
		var widgetActions = $(e.currentTarget).closest('.k-window').find('.cw_widget_actions');
		widgetActions.removeClass('expanded');

		e.preventDefault();
	}
	/**
	 * Handler function for the logo click. Prevents the user exiting the module by clicking the logo
	 * @param {Object} e The click event object
	 */
	onLogoClick (e) {
		e.preventDefault();
		if (Cookies.CeesoftLandingPage) {
			config = {
				id: Cookies.CeesoftLandingPage,
				isSummary: true
			};
			this.tryExit('ServiceBoardView', Cookies.CeesoftLandingPage, config);
		} else {
			this.tryExit('Summary');
		}
	}
	/**
	 * Trigger widget width resize
	 */
	onWindowResize () {
		this.calculateBoardResolution();
		if ($('#cw_sb_layout').data('gridster')) {
			$('#cw_sb_layout').data('gridster').recalculate_faux_grid();
		}
		for (var i = 0; i < this.widgets.length; i++) {
			if (this.widgets[i].onWidthResize) {
				this.widgets[i].onWidthResize();
			}
		}
		if ((this.resolution.type === 'MOBILE' || this.resolution.type == 'RESPONSIVE') && (this.isGuest || this.loadedBy === 'standalone')) {
			location.reload();
		}
		$('#modal').attr('style', 'overflow:hidden !important');
	}

	sortWidgetsByColumn (widgets) {
		//sort row widgets in html by column, in order to be able to get easily the last one
		widgets.sort(function (a, b) {
			var firstWidgetCol = parseInt(a.getAttribute('data-col'));
			var secondWidgetCol = parseInt(b.getAttribute('data-col'));
			if (firstWidgetCol > secondWidgetCol) {
				return 1;
			}
			if (firstWidgetCol < secondWidgetCol) {
				return -1;
			}
			return 0;
		});
		widgets.detach().appendTo($('#cw_sb_layout'));
	}

	toggleHeaders(){
		if(this.headerOn){
			$('#cw_sb_layout').find('.cw_widget.no_header:not(.force-no-header)')
				.removeClass('no_header')
				.each((i, e) => {
					$(e).height($(e).height() + 30);
				});
		}

		let disableHeadersOn = $('#cw_sb_layout').find('.cw_widget:not(.no_header).force-no-header');
		if(!this.headerOn){
			disableHeadersOn.add($('#cw_sb_layout').find('.cw_widget:not(.no_header)'))
		}

		disableHeadersOn
			.addClass('no_header')
			.each((i, e) => {
				$(e).height($(e).height() - 30);
			});
	}
	/*
	 * Handler function for showing the headers
	 */
	turnHeadersOn (e) {
		$('#cw_sb_layout').find('.cw_widget').not('.skip-header-config').removeClass('no_header');
	}
	/*
	 * Handler function for hiding the headers
	 */
	turnHeadersOff (e) {
		$('#cw_sb_layout').find('.cw_widget').not('.skip-header-config').addClass('no_header');
	}
	/*
	 * Handler function for refreshing serviceboard on event
	 */
	onEventRefresh () {
		//reset container width to allow recalculation of gridster extra rows
		$('#cw_sb_layout').width($('.cw_sboard_area').width());
		this.subscribe();
		this.load(this.id);
	}
	/*
	 * Handler function for checking and setting widget specific configuration
	 * @param {Object} obj The gridster serialized object
	 */
	setWidgetConfiguration (obj) {
		for (var i = 0, length = obj.length; i < length; i++) {
			if (obj[i]) {
				this.getWidgetConfiguration(obj[i].widget);
			}
		}
		return obj;
	}
	/*
	 * Handler function for getting widget specific configuration
	 * @param {Object} obj The widget configuration object
	 */
	getWidgetConfiguration (obj) {
		var widget = this.getWidget(obj.id);
		if (widget && widget.getConfiguration) {
			obj.configuration = $.extend(obj.configuration, widget.getConfiguration());
		}
	}
	/*
	 * Handler function for handling the events
	 * */
	handleEvents (events) {
		if (!this.sbDestroyed) {
			var widgetEvents = this.parseEvents(events), widget, wId;

			LocalEventsManager.trigger('boardUpdate');

			if (widgetEvents[this.id]) {
				//on own serviceboard update and based on that when an event is received (to refresh the gridster)
				setTimeout($.proxy(function () {
					this.forceDestroy(true);
					this.resetLayoutSize();
					this.onEventRefresh();
				}, this), 400);
			} else {
				for (wId in widgetEvents) {
					widget = this.getWidget(wId);
					if (widget) {
						if (widget.onEvents) {
							widget.onEvents(widgetEvents[wId]);
						} else {
							for (var i = 0, length = widgetEvents[wId].length; i < length; i++) {
								if (widget.onEvent) {
									widget.onEvent(widgetEvents[wId][i]);
								}
							}
						}
					}
				}
			}
		}
	}

	parseEvents (events) {
		var eventsByWidgetId = [], eventsArray = [], event;

		if (events.length) {
			eventsArray = events;
		} else {
			eventsArray.push(events);
		}

		for (var i = 0, length = eventsArray.length; i < length; i++) {
			event = eventsArray[i];

			if (event.eventType === 'ServiceBoard') {
				// we need just to reload
				var event = {};
				event[this.id] = true;
				return event;
			}

			if (!eventsByWidgetId[event.wId]) {
				eventsByWidgetId[event.wId] = [];
			}

			eventsByWidgetId[event.wId].push(event);

			if (event.serviceBoardId) {
				eventsByWidgetId[event.serviceBoardId] = 1;
			}
			this.lastUpdate = Renderer.dateRenderer(new Date().getTime(), 'datetime');
			for (var j = 0; j < this.lastUpdates.length; j++) {
				if (this.id === this.lastUpdates[j].id) {
					this.lastUpdates[j].lastUpdate = this.lastUpdate;
				}
			}
			$('.cw_sboard_last_update').empty().append(lang.serviceBoard.LAST_UPDATE + this.lastUpdate);
		}
		return eventsByWidgetId;
	}
	/*
	 * Handler function for rendering serviceboard name
	 */
	onRenderName () {
		if ($('.cw_serviceboard_name')) {
			$('.cw_serviceboard_name').remove();
		}
		$('body').prepend('<div class="cw_serviceboard_name w500 ellipsis" title="' + this.tag + '">' + '<span class="cw_sb_name left">' + this.tag + '</span>' + '<span class="cw_sboard_last_update right"></span></span>');
	}

	setPanelPosition () {
		if (this.informationOption === 'off') {
			$('.cw_serviceboard_name').addClass('hide');
		} else if (this.informationOption === 'locked') {
			$('.cw_serviceboard_name').css({'margin-top': '0px', 'cursor': 'default !important'}).off();
		} else {
			$('.cw_serviceboard_name').css('margin-top', '-30px').off();
			$('.cw_serviceboard_name').on('mouseover', $.proxy(this.onNameOver, this)).on('mouseout', $.proxy(this.onNameOut, this));
		}
		if (this.position === 'left') {
			$('.cw_serviceboard_name').removeClass('w500').css({'left': '9%'});
		}
		if (this.position === 'right') {
			$('.cw_serviceboard_name').removeClass('w500').css({'right': '2%', 'left': 'auto'});
		}
	}
	/*
	 * Handler function for hovering the serviceboard name
	 */
	onNameOver () {
		$('.cw_serviceboard_name').css('margin-top', '0px');
	}
	/*
	 * Handler function for mouseout serviceboard name
	 */
	onNameOut () {
		$('.cw_serviceboard_name').css('margin-top', '-30px');
	}

	/*
	 * Handler function for resetting layout size
	 */
	resetLayoutSize () {
		$('#cw_sb_layout').width($('body').width());
	}
	/*
	 * Subscriber
	 * */
	subscribe () {
		var subscriptionObj = [{
			eventType: 'ServiceBoard',
			actionTypes: ['UPDATE'],
			serviceBoardId: this.id
		}];
		RemoteEventsManager.subscribe(this.subscriberId, subscriptionObj);
	}
	/**
	 * Forces the destroy methodes to be called
	 * @param {Boolean} stopClosingES Set to true if the eventsource should be stoppped
	 */
	forceDestroy (stopClosingES) {
		$(window).off('resize.sbview');
		for (var i = 0, length = this.widgets.length; i < length; i++) {
			if (this.widgets[i].destroy) {
				this.widgets[i].destroy();
				if (this.layout === 'free') {
					var window = $('#' + this.widgets[i].id).closest('.k-window-content').data('kendoWindow');
					if (window != null) {
						window.destroy();
					}
				}
			}
		}
		if ($('#widget_wrapper').length) {
			$('#widget_wrapper').remove();
		}
		if ($('#cw_sb_layout').data('gridster')) {
			$('#cw_sb_layout').data('gridster').remove_all_widgets();
			$('#cw_sb_layout').data('gridster').destroy();
		}
		Application.prototype.destroy.call(this);
	}

	onServiceBoardCopy () {
		this.copyModalWindow = new ModalWindow({
			title: i('Duplicate {0}', this.tag),
			width: 400,
			height: 235,
			resizable: false,
			url: 'include/gsboard/CopyServiceBoardForm.jsp',
			refresh: $.proxy(function () {
				$('#copy_service_board').on('click', $.proxy(this.copyServiceBoard, this));
				$('#cancel').on('click', $.proxy(function() {
					this.copyModalWindow.close();
				}, this));
				$('#cw_copy_sb_name').focus().on('keydown', function() {
					if ($(this).length) {
						$('#copy_service_board').attr('disabled', false);
					} else {
						$('#copy_service_board').attr('disabled', true);
					}
				});
				this.accountsDatasource = new kendo.ceeview.DataSource({
					transport: {
						read: {
							url: Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/subaccounts/allLevels',
							contentType: 'application/json; charset=utf-8',
							type: 'GET',
							dataType: 'json',
							cache: false
						}
					},
					error: ErrorHandler.kendoServerError
				});
				this.accountsList = $('#cw_copy_sb_account').kendoDropDownList({
					dataTextField: 'name',
					dataValueField: 'id',
					dataSource: this.accountsDatasource,
					value: Cookies.CeesoftCurrentAccountId,
					change: function() {
						if (this.value() !== Cookies.CeesoftCurrentAccountId) {
							$('#cw_subaccount_warning').removeClass('hide');
						} else {
							$('#cw_subaccount_warning').addClass('hide');
						}
					}
				}).data('kendoDropDownList');

			}, this)
		});
		this.copyModalWindow.open();
	}

	async copyServiceBoard() {
		const boardReq = await Api.fetch(`${Api.serverRoot()}accounts/dashboards/${this.selectedId}`);
		if (boardReq.success) {
			let copyAccountId = this.accountsList.value();
			let widgets = boardReq.data;
			let parsedWidgets = JSON.parse(widgets);
			for (let i = 0; i  < parsedWidgets.length; i++) {
				if (parsedWidgets[i].widget && parsedWidgets[i].widget.configuration) {
					parsedWidgets[i].widget.configuration.accountId = copyAccountId;
				}
			}
			widgets = JSON.stringify(parsedWidgets);
			let properties = boardReq.data;
			let copiedSbPayload = Object.assign({}, properties);
			copiedSbPayload.content = widgets;
			copiedSbPayload.id = null;
			copiedSbPayload.tag = $('#cw_copy_sb_name').val();
			delete copiedSbPayload.accountId;
			const saveCopiedWidgetsReq = await Api.fetchPost(`${Api.serverRoot()}accounts/${copyAccountId}/dashboards`, copiedSbPayload);
			this.copyModalWindow.close();
			this.actionNotification.setOptions({
				message: lang.serviceBoard.messages.COPY_SERVICEBOARD_SUCCESS,
				status: 'success'
			}).show();
		}
	}

	dirtyCheck() {
		let currentContent;
		if (this.gridster) {
			currentContent = this.gridster.serialize();
 		} else {
			currentContent = this.serialize();
		}

		let initialContent = this.cleanConfigForDirtyCheck(this.initialContent);
		currentContent = this.cleanConfigForDirtyCheck(currentContent);

		return {
			isDirty: !this.skipDirtyCheck && !this.isSummary && JSON.stringify(initialContent) !== JSON.stringify(currentContent),
			message: lang.messages.COMMON_DIRTY_CONFIRMATION
		}
	}

	cleanConfigForDirtyCheck(data) {
		for (let i = 0; i < data.length; i++) {
			let widget = data[i].widget;
			if (widget) {
				delete widget.zindex;
				for (let key in widget) {
					if (!widget[key] || widget[key].length === 0) {
						delete widget[key];
					}
				}
				let configuration = widget.configuration;
				if (configuration) {
					for (let key in configuration) {
						if (!configuration[key] || configuration[key].length === 0) {
							delete configuration[key];
						}
					}
				}
			}
		}
		return data;
	}

	/**
	 * Destroy
	 */
	destroy () {
		$('.logo').off('click');
		var preferences = [{
			key: 'serviceboardLastUpdate',
			value: JSON.stringify(this.lastUpdates)
		}];
		this.saveUserPrefs({
			category: 'ServiceboardLastUpdate',
			preferences: preferences
		});
		// this.saveServiceBoard();

		this.forceDestroy();
		this.sbDestroyed = true;
	}
}
