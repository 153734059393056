import React from 'react';
import './overlay.less';
import {useSelector} from 'react-redux';
import {isDisabled} from 'controls/react/form/formBuilder/selectors/isDisables';

export const b = require('b_').with('formBuilder-overlay');

function Overlay() {
	const _isDisabled = useSelector(isDisabled);

	if (!_isDisabled) {
		return null;
	}

	return <div className={b()} />
}

export default Overlay;
