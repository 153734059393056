export let Config = {
	ceeview: {
		templates: {
			responsibleTeam: '#= accountName ? (name+ " (" + accountName + ")") : name#'
		}
	},

	monitors: [
		'connectivity',
		'eventlog',
		'external',
		'groovy',
		'hyperv',
		'ibmstorwize',
		'log',
		'mailgateway',
		'mscluster',
		'nagios',
		'nimsoft',
		'process',
		'prtg',
		'service',
		'solarwinds',
		'vmm',
		'vmware'
	],

	monitorsPath: '/ceeview/assets/js/ceeview/assethealth/monitors/'
	// // Other configurations
	// let browser = {
	// 	supportsEventSource: Boolean(window.EventSource),
	// 	pollingInterval: 30000
	// };
	//
	// // allows cross-domain request - IE fix
	// jQuery.support.cors = true;
};

// allows cross-domain request - IE fix
jQuery.support.cors = true;
export default Config;

