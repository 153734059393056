import Utils from 'tools/utils'
import Cookies from 'core/cookies'

export default function RuleGenerator(config) {
	Utils.apply(this, config);
	this.initComponent();
};

jQuery.extend(RuleGenerator.prototype, {
	/**
	 * @config {String} renderTo The id of the tag where the menu is rendered
	 */
	renderTo: '',
	/**
	 * Main init class
	 */
	initComponent: function () {
		var containerId = '#' + this.renderTo;
		var htmlCode = '<div class="cw_field cw_repeat">';
		htmlCode += '<label>' + lang.rulegenerator.REPEAT + '</label>';
		htmlCode += '<div class="w100 left"><input id="cw_rule_frequency" type="text"/><label style="padding:0 15px;">' + lang.rulegenerator.EVERY + '</label><input id="cw_rule_interval" type="text" style="width:50px" /><div id="cw_interval_text" ></div></div>';
		htmlCode += '<div id="cw_weekly_frequency" class="cw_btn_group hide" role="group">';
		htmlCode += '<button type="button" class="cw_week_day k-button" data-value="MO">Mon</button>';
		htmlCode += '<button type="button" class="cw_week_day k-button" data-value="TU">Tue</button>';
		htmlCode += '<button type="button" class="cw_week_day k-button" data-value="WE">Wed</button>';
		htmlCode += '<button type="button" class="cw_week_day k-button" data-value="TH">Thu</button>';
		htmlCode += '<button type="button" class="cw_week_day k-button" data-value="FR">Fri</button>';
		htmlCode += '<button type="button" class="cw_week_day k-button" data-value="SA">Sat</button>';
		htmlCode += '<button type="button" class="cw_week_day k-button" data-value="SU">Sun</button>';
		htmlCode += '</div>';
		htmlCode += '</div>';
		htmlCode += '<div class="cw_field hide" id="cw_monthly_frequency">';
		htmlCode += '<div class="cw_field"><input type="radio" name="cw_on_day" class="cw_on_day" value="1" checked="checked" id="cw_on_day1"><label for="cw_on_day1">' + lang.rulegenerator.ON_DAY + '</label><input id="cw_rule_day_select" type="text"/></div>';
		htmlCode += '<div class="cw_field"><input type="radio" name="cw_on_day" class="cw_on_day" value="2" id="cw_on_day2"><label for="cw_on_day2">' + lang.rulegenerator.ON_THE + '</label>';
		htmlCode += '<input id="cw_rule_month_order_select" type="text"/><input id="cw_rule_month_day_select" type="text"/></div>';
		htmlCode += '</div>';
		htmlCode += '<div class="cw_field hide" id="cw_yearly_frequency">';
		htmlCode += '<label>' + lang.rulegenerator.ON + '</label><input id="cw_rule_month_select" type="text"/><input id="cw_rule_year_month_day_select" type="text"/>';
		htmlCode += '</div>'
		htmlCode += '<div class="cw_field">';
		htmlCode += '<label>' + lang.rulegenerator.END + '</label>';
		htmlCode += '<div class="w100 left"><input id="cw_rule_end" type="text" class="left"/><div class="cw_picker hide"><input id="cw_rule_end_pick" /></div></div>';
		htmlCode += '</div>';
		htmlCode += '<div class="cw_field"><label>' + lang.rulegenerator.RRULE + '</label><input class="cw_inline is_readonly" id="cw_rrule_text" type="text" readonly/></div>';
		$(containerId).append(htmlCode);
		this.interval = 1;
		if (this.noMinutely) {
			this.frequency = 'HOURLY';
		} else {
			this.frequency = 'MINUTELY';
		}
		this.weekDays = [];
		this.until = '';
		this.setParameters();
		this.setRule();
		this.removeListeners();
		this.attachListeners();
		this.initKendoComponents();
	},
	/**
	 * Removes listeners
	 */
	removeListeners: function () {
		$('#' + this.renderTo).find('span').first().off();
	},
	/**
	 * Attaches listeners
	 */
	attachListeners: function () {
		$('#cw_rule_interval').on('keyup', $.proxy(this.onIntervalChange, this));
		$('.cw_week_day').on('click', $.proxy(this.onDayClick, this));
		$('.cw_on_day').on('click', $.proxy(this.onDayChange, this));
	},
	initKendoComponents: function () {
		var dataSource = [{
			text: lang.rulegenerator.HOURLY,
			value: 'HOURLY'
		}, {
			text: lang.rulegenerator.DAILY,
			value: 'DAILY'
		}, {
			text: lang.rulegenerator.WEEKLY,
			value: 'WEEKLY'
		}, {
			text: lang.rulegenerator.MONTHLY,
			value: 'MONTHLY'
		}, {
			text: lang.rulegenerator.YEARLY,
			value: 'YEARLY'
		}];
		if (!this.noMinutely) {
			dataSource.unshift({
				text: lang.rulegenerator.MINUTELY,
				value: 'MINUTELY'
			});
		}
		this.ruleFrequency = $('#cw_rule_frequency').kendoDropDownList({
			dataTextField: 'text',
			dataValueField: 'value',
			dataSource: dataSource,
			change: $.proxy(function (e) {
				this.isFrequencyTriggered = false;
				this.onFrequencyChange(e);
			}, this),
			autoBind: true,
			enable: true
		}).data('kendoDropDownList');
		this.ruleEndDropDown = $('#cw_rule_end').kendoDropDownList({
			dataTextField: 'text',
			dataValueField: 'value',
			dataSource: [{
				text: lang.rulegenerator.NEVER,
				value: 'NEVER'
			}, {
				text: lang.rulegenerator.ON_DATE,
				value: 'ONDATE'
			}],
			change: $.proxy(this.onRuleEndChange, this),
			autoBind: true,
			enable: true
		}).data('kendoDropDownList');
		this.ruleInterval = $('#cw_rule_interval').kendoNumericTextBox({
			decimals: 0,
			min: 1,
			max: 100,
			value: 1,
			spinners: false,
			format: 'n0',
		}).data('kendoNumericTextBox');
		this.monthDays = [];
		for (var i = 1; i <= 31; i++) {
			this.monthDays.push({
				text: i,
				value: i.toString()
			});
		}
		this.daySelect = $('#cw_rule_day_select').kendoDropDownList({
			dataTextField: 'text',
			dataValueField: 'value',
			dataSource: this.monthDays,
			autoBind: true,
			enable: true,
			change: $.proxy(function (e) {
				this.byMonthDay = e.sender.value();
				this.setRule();
			}, this),
		}).data('kendoDropDownList');
		this.monthDaySelect = $('#cw_rule_year_month_day_select').kendoDropDownList({
			dataTextField: 'text',
			dataValueField: 'value',
			dataSource: this.monthDays,
			autoBind: true,
			enable: true,
			change: $.proxy(function (e) {
				this.byMonthDay = e.sender.value();
				this.setRule();
			}, this),
		}).data('kendoDropDownList');
		this.monthOrderSelect = $('#cw_rule_month_order_select').kendoDropDownList({
			dataTextField: 'text',
			dataValueField: 'value',
			dataSource: [{
				text: lang.rulegenerator.FIRST,
				value: '1'
			}, {
				text: lang.rulegenerator.SECOND,
				value: '2'
			}, {
				text: lang.rulegenerator.THIRD,
				value: '3'
			}, {
				text: lang.rulegenerator.FOURTH,
				value: '4'
			}, {
				text: lang.rulegenerator.LAST,
				value: '-1'
			}],
			autoBind: true,
			enable: true,
			change: $.proxy(function (e) {
				this.bySetPos = e.sender.value();
				this.setRule();
			}, this),
		}).data('kendoDropDownList');
		this.monthSelect = $('#cw_rule_month_select').kendoDropDownList({
			dataTextField: 'text',
			dataValueField: 'value',
			dataSource: [{
				text: lang.months.JANUARY,
				value: '1'
			}, {
				text: lang.months.FEBRUARY,
				value: '2'
			}, {
				text: lang.months.MARCH,
				value: '3'
			}, {
				text: lang.months.APRIL,
				value: '4'
			}, {
				text: lang.months.MAY,
				value: '5'
			}, {
				text: lang.months.JUNE,
				value: '6'
			}, {
				text: lang.months.JULY,
				value: '7'
			}, {
				text: lang.months.AUGUST,
				value: '8'
			}, {
				text: lang.months.SEPTEMBER,
				value: '9'
			}, {
				text: lang.months.OCTOBER,
				value: '10'
			}, {
				text: lang.months.NOVEMBER,
				value: '11'
			}, {
				text: lang.months.DECEMBER,
				value: '12'
			}],
			autoBind: true,
			enable: true,
			change: $.proxy(function (e) {
				this.byMonth = e.sender.value();
				this.setRule();
			}, this),
		}).data('kendoDropDownList');
		var dataSource = [{
				text: lang.MONDAY,
				value: 'MO'
			}, {
				text: lang.TUESDAY,
				value: 'TU'
			}, {
				text: lang.WEDNESDAY,
				value: 'WE'
			}, {
				text: lang.THURSDAY,
				value: 'TH'
			}, {
				text: lang.FRIDAY,
				value: 'FR'
			}, {
				text: lang.SATURDAY,
				value: 'SA'
			}, {
				text: lang.SUNDAY,
				value: 'SU'
			}];
		if (this.type !== 'calendar') {
			dataSource.push({
				text: lang.WEEK,
				value: 'SU,MO,TU,WE,TH,FR,SA'
			}, {
				text: lang.WEEKEND,
				value: 'SU,SA'
			});
		}
		this.monthDaySelect = $('#cw_rule_month_day_select').kendoDropDownList({
			dataTextField: 'text',
			dataValueField: 'value',
			dataSource: dataSource,
			autoBind: true,
			enable: true,
			change: $.proxy(function (e) {
				this.byDay = e.sender.value();
				this.setRule();
			}, this),
		}).data('kendoDropDownList');

		this.datePicker = $('#cw_rule_end_pick').kendoDateTimePicker({
			format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
			timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserDateTimeFormat),
			min: this.untilDate ? this.untilDate : new Date(),
			change: $.proxy(this.onDateChange, this)
		}).data('kendoDateTimePicker');
	},
	onFrequencyChange: function (e) {
		var selectedValue;
		if (this.isFrequencyTriggered) {
			selectedValue = $('#cw_rule_frequency').data('kendoDropDownList').value()
		} else {
			selectedValue = e.sender.element[0].value;
		}
		//this.ruleFrequency = selectedValue;
		$('#cw_monthly_frequency').addClass('hide');
		$('#cw_weekly_frequency').addClass('hide');
		$('#cw_yearly_frequency').addClass('hide');
		this.setParameters();
		this.updateFieldsByFrequency(selectedValue);
		this.setRule();
	},
	updateFieldsByFrequency: function (frequencyValue) {
		switch (frequencyValue) {
			case 'MINUTELY':
				$('#cw_interval_text').text(lang.rulegenerator.MINUTE);
				this.frequency = 'MINUTELY';
				break;
			case 'HOURLY':
				$('#cw_interval_text').text(lang.rulegenerator.HOUR);
				this.frequency = 'HOURLY';
				break;
			case 'DAILY':
				$('#cw_interval_text').text(lang.rulegenerator.DAY);
				this.frequency = 'DAILY';
				break;
			case 'WEEKLY':
				$('#cw_interval_text').text(lang.rulegenerator.WEEK);
				this.frequency = 'WEEKLY';
				$('#cw_weekly_frequency').removeClass('hide');
				if (!this.isFrequencyTriggered) {
					$('.cw_week_day').removeClass('is_selected');
				}
				break;
			case 'MONTHLY':
				$('#cw_interval_text').text(lang.rulegenerator.MONTH);
				this.frequency = 'MONTHLY';
				//this.byMonthDay = '1';
				if ($('#cw_on_day1').is(':checked')) {
					this.byMonthDay = '1';
				}
				$('#cw_monthly_frequency').removeClass('hide');
				break;
			case 'YEARLY':
				$('#cw_interval_text').text(lang.rulegenerator.YEAR);
				this.frequency = 'YEARLY';
				this.byMonthDay = '1';
				this.byMonth = '1';
				$('#cw_yearly_frequency').removeClass('hide');
				break;
		}
	},
	setParameters: function () {
		this.byMonthDay = 0;
		this.bySetPos = '';
		this.byDay = '';
		//this.until = '';
		this.byMonth = '';
		//this.setRule();
	},
	onRuleEndChange: function (e) {
		var selectedValue = e.sender.element[0].value;
		this.ruleEnd = selectedValue;
		switch (selectedValue) {
			case 'NEVER':
				$('.cw_picker').addClass('hide');
				this.until = '';
				this.setRule();
				break;
			case 'ONDATE':
				$('.cw_picker').removeClass('hide');
				let min = this.datePicker.min();
				let hours = min.getHours() + 1;
				let value = new Date(new Date(min).setHours(hours));
				this.datePicker.value(value);
				this.until = value.toISOString();
				this.setRule();
				break;
		}
	},
	onIntervalChange: function () {
		this.interval = $('#cw_rule_interval').val();
		this.setRule();
	},
	setRule: function () {
		if (this.byMonthDay != 0) {
			this.rrule = 'FREQ=' + this.frequency + ';BYMONTHDAY=' + this.byMonthDay + ';INTERVAL=' + this.interval;
			if (this.byMonth != '') {
				this.rrule = 'FREQ=' + this.frequency + ';BYMONTH=' + this.byMonth + ';BYMONTHDAY=' + this.byMonthDay + ';INTERVAL=' + this.interval;
			}
		} else if (this.byDay != '') {
			this.rrule = 'FREQ=' + this.frequency + ';BYDAY=' + this.byDay + ';INTERVAL=' + this.interval;
			if (this.bySetPos != '') {
				this.rrule = 'FREQ=' + this.frequency + ';BYSETPOS=' + this.bySetPos + ';BYDAY=' + this.byDay + ';INTERVAL=' + this.interval;
			}
		} else {
			this.rrule = 'FREQ=' + this.frequency + ';INTERVAL=' + this.interval;
		}
		if (this.until != '') {
			let until = this.until.replace(/[-:]/g, '');
			let dotSplit = until.split('.');
			if (dotSplit.length > 1) {
				until = dotSplit[0] + 'Z';
			} else {
				until = dotSplit[0];
			}
			//this.until = this.until.split('.')[0] + 'Z';
			//this.until = this.until.substring(0, this.until.length - 4) + 'Z';
			this.rrule += ';UNTIL=' + until;
		}
		$('#cw_rrule_text').val(this.rrule);
	},
	parseRule: function (string, options) {
		options = options ?? {}

		var obj = {}
		var arr = string.split(';');
		for (let x = 0; x < arr.length; x++) {
			let temp = arr[x].split('=');

			let key = temp[0];
			if(options.lowerCaseKeys){
				key = key.toLowerCase()
			}

			if(options.bydayToByweekday && key == "byday"){
				key = 'byweekday'
			}

			if(key == 'interval') {
				obj[key] = parseInt(temp[1])
			}else {
				obj[key] = temp[1];
			}
		}
		return obj;
	},
	onDayClick: function (e) {
		if (this.mode === 'update') {
			var array = [];
			$('.cw_week_day.is_selected').each(function (i, item) {
				array.push($(item).attr('data-value'));
			});
			this.weekDays = array;
		}
		var target = $(e.currentTarget);
		if (target.hasClass('is_selected')) {
			target.removeClass('is_selected');
			var item = target.attr('data-value');
			var index = this.weekDays.indexOf(item);
			if (index > -1) {
				this.weekDays.splice(index, 1);
			}
		} else {
			target.addClass('is_selected');
			this.weekDays.push(target.attr('data-value'));
		}
		this.byDay = this.weekDays.toString();
		this.setRule();
	},
	onDateChange: function () {
		var timezoneOffset = (new Date()).getTimezoneOffset() * 60000;
		var datePickerValue = this.datePicker.value();
		if (datePickerValue) {
			$('#cw_rule_end_pick').removeClass('invalid');
			this.until = (new Date(datePickerValue.getTime() - timezoneOffset)).toISOString();
			this.setRule();
		} else {
			$('#cw_rule_end_pick').addClass('invalid');
		}
	},
	onDayChange: function (e, type) {
		var target = e ? $(e.currentTarget).val() : type;
		if (target === '1') {
			this.byMonthDay = this.daySelect.value();
			this.bySetPos = '';
			this.byDay = '';
		}
		if (target === '2') {
			this.byMonthDay = '';
			this.bySetPos = this.monthOrderSelect.value();
			this.byDay = this.monthDaySelect.value();
		}
		this.setRule();
	},
	getRule: function () {
		var rrule = $('#cw_rrule_text').val();
		return rrule;
	},
	setRrule: function (rrule) {
		$('#cw_rrule_text').val(rrule);
	}
});
