import {Switch} from 'controls';

export default class DiskSwitch {
	constructor(prefix) {
		this.prefix = prefix;
	}

	create(removeSystemMaskCb, addSectionMaskCb) {
		const diskEnable = $(`#cw_${this.prefix}disk_enable`);

		this.diskSwitch = new Switch({
			renderTo: diskEnable,
			isEnabled: this.mode !== 'view',
			change: $.proxy(function (result) {
				var labelHandler = diskEnable.parent().find('span.label');
				labelHandler.text(lang.ACTIVE);
				if (result.value) {
					removeSystemMaskCb($(`.cw_${this.prefix}system_disk`));
					labelHandler.removeClass('opacity');
				} else {
					addSectionMaskCb($(`.cw_${this.prefix}system_disk`));
					labelHandler.addClass('opacity');
				}
			}, this)
		});

		return this.diskSwitch;
	}
}
