import {UrlBuilder} from "tools";

export function getDescriptions(){
	return [
		{
			jspUrl: 'include/Summary/EventSummaryView.jsp',
			url: '/summary/events',
			getModuleImport: () => import('./eventSummaryView')
		}
	];
}

export class EventSummaryRouter {
	static list(extra = null){
		let url = new UrlBuilder('/summary/events')
		if(extra) {
			url = url.add(extra)
		}
		return url.build();
	}
}