import {Cookies} from "core";
import UrlBuilder from "tools/urlBuilder";

const root = '/kpi';

export function getDescriptions(){
	return [
		{
			jspUrl: 'include/Kpi/Details.jsp',
			url: root + '/:id',
			bodyCssClass: 'cw_kpi_module',
			getModuleImport: () => import('./details')
		}
	];
}

export class KpiRouter {
	static details(kpiId) {
		return root + '/' + kpiId
	}

	static createNew() {
		return root + '/new';
	}
}
