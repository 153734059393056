import {takeEvery, takeLatest, debounce} from 'redux-saga/effects';

import {ADD_VIRTUAL_FIELDS, CHECK_FIELDS_STATE, LOAD_DATA, INIT_WITH_DATA, ON_CHANGE} from '../actions/constants';
import {addVirtualFields} from './addVirtualFields';
import {checkFieldState} from './checkFieldState';
import {loadData} from './loadData';
import {CHECK_FORM_STATE} from '../actions/checkFormStateAction';
import {checkFormState} from './checkFormState';

import {actionTypes} from 'redux-form';
import {ASSET_SAVED} from "../actions/assetSavedAction";
import {assetSaved} from "./assetSaved";
import {AUTHENTICATION_SAVED} from "../actions/authenticationSavedAction";
import {authentificationSaved} from "./authenticationSaved";

import {onChange} from './onChange';
import {onFieldChanged} from "./onFieldChanged";

import {initWithData} from './initWithData';
import {resetFieldValues} from "./resetFieldValues";

export function* rootSaga() {
    yield takeEvery(ADD_VIRTUAL_FIELDS, addVirtualFields);
    yield takeEvery(CHECK_FIELDS_STATE, checkFieldState);
    yield debounce(300, CHECK_FORM_STATE, checkFormState);
    yield takeEvery(LOAD_DATA, loadData);
    yield takeEvery(ASSET_SAVED, assetSaved);
    yield takeEvery(AUTHENTICATION_SAVED, authentificationSaved);
    yield takeEvery(actionTypes.CHANGE, onFieldChanged);
	yield takeEvery(actionTypes.CHANGE, resetFieldValues);
    yield takeLatest(actionTypes.CHANGE, checkFormState);
    yield takeLatest(actionTypes.CHANGE, checkFieldState);
	yield debounce(50, actionTypes.CHANGE, onChange);
	yield takeEvery(ON_CHANGE, onChange);
	yield takeEvery(INIT_WITH_DATA, initWithData);
}
