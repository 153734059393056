import {Utils, applyRequired} from 'tools/utils';
import Cookies from 'core/cookies';
import Settings from 'settings';

import ErrorHandler from 'core/errorHandler';
import ConfigurationBase from 'areas/service-boards/configurationBase';
import translator from 'core/localization/localization';

export function HistoryWidgetConfiguration(config) {
	Utils.apply(this, config);
	this.initComponent();
};

export default HistoryWidgetConfiguration;

const i = translator ({
	"Information": {
	  "no": "Informasjon"
	}
  });

jQuery.extend(HistoryWidgetConfiguration.prototype, ConfigurationBase.prototype, {
	/**
	 * @cfg {Object} widgetObj
	 */
	/**
	 * Main init function
	 */
	initComponent: function () {
		var html;
		var widgetObj = this.widgetObj;

		html = '<div class="cw_field">';
		html += '<label class="cw_inline">' + lang.TITLE + ': </label>';
		html += '<input type="text" class="cw_widget_title cw_inline" value="' + (widgetObj.title || '') + '" />';
		html += '</div>';
		html += '<div class="cw_field" id="cw_history_account">';
		html += '<label class="cw_inline">' + lang.ACCOUNT + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_history_accountid" type="text" value="' + (widgetObj.configuration.accountId || '') + '" /></div>';
		html += '</div>';
		html += '<div class="cw_field" id="cw_history_service">';
		html += '<label class="cw_inline">' + lang.widget.SERVICE + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_history_sid" type="text" value="' + (widgetObj.configuration.serviceId || '') + '" /></div>';
		html += '</div>';
		html += '<div class="cw_field" id="cw_history_se">';
		html += '<label class="cw_inline">' + lang.designer.SERVICE_ELEMENT + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_history_seid" type="text" value="' + (widgetObj.configuration.serviceElementId || '') + '" /></div>';
		html += '</div>';
		html += '<div class="cw_field">';
		html += '<label class="cw_inline">' + lang.widget.PERIOD + ': </label>';
		html += '<ul id="cw_history_period_control" class="cw_multi_toggle right">';
		html += '<li class="first_btn"><input type="radio" name="cw_history_period" id="cw_history_period_lasthour" value="LASTHOUR" />' + lang.periodMultiToggle.HOUR + '</li>';
		html += '<li class="is_selected"><input type="radio" name="cw_history_period" id="cw_history_period_lastday" value="LASTDAY" checked="checked" />' + lang.periodMultiToggle.DAY + '</li>';
		html += '<li><input type="radio" name="cw_history_period" id="cw_history_period_lastweek" value="LAST7DAYS" />' + lang.periodMultiToggle.WEEK + '</li>';
		html += '<li><input type="radio" name="cw_history_period" id="cw_history_period_lastmonth" value="LAST30DAYS" />' + lang.periodMultiToggle.MONTH + '</li>';
		html += '<li class="last_btn"><input type="radio" name="cw_history_period" id="cw_history_period_custom" value="CUSTOM" />' + lang.CUSTOM + '</li>';
		html += '</ul>';
		html += '<div class="cw_hide_time_selector"><input type="checkbox" class="cw_include k-checkbox" id="cw_hide_time_selector"' + (widgetObj.configuration.hideTimeSelector ? 'checked="checked"' : '') + '></span>';
		html += '<label for="cw_hide_time_selector" class="k-checkbox-label">' + lang.widget.HIDE_TIME_SELECTOR + '</label></div>';
		html += '</div>';
		html += '<div class="cw_field cw_no_label hide" id="cw_history_dates">';
		html += '<input id="cw_history_startdate" type="text" /> - <input id="cw_history_enddate" type="text" />';
		html += '</div>';
		html += '<div class="cw_field">';
		html += '<label class="cw_inline">' + lang.TIMEZONE + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_history_timezone" type="text" value="' + (widgetObj.configuration.timezone || Cookies.CeesoftTimezone) + '"/></div>';
		html += '<div class="cw_separator"><span class="cw_separator_title">' + i('Information') + '</span>';
		html += '<div class="cw_exclude_downtime"><input type="checkbox" class="cw_include k-checkbox" id="cw_exclude_downtime"' + (widgetObj.configuration.excludeDowntime ? 'checked="checked"' : '') + '></span>';
		html += '<label for="cw_exclude_downtime" class="k-checkbox-label">' + lang.widget.SHOW_DOWNTIME + '</label></div>';
		html += '<div class="cw_exclude_breaches"><input type="checkbox" class="cw_include k-checkbox" id="cw_exclude_breaches"' + (widgetObj.configuration.excludeBreaches ? 'checked="checked"' : '') + '></span>';
		html += '<label for="cw_exclude_breaches" class="k-checkbox-label">' + lang.widget.SHOW_BREACHES + '</label></div>';
		html += '<div class="cw_show_footer"><input type="checkbox" class="cw_include k-checkbox" id="cw_show_footer"' + (widgetObj.configuration.showFooter ? 'checked="checked"' : '') + '></span>';
		html += '<label for="cw_show_footer" class="k-checkbox-label">' + lang.widget.HISTORY_SHOW_FOOTER + '</label></div>';
		html += '</div></div></div>';
		this.widgetConfigurationFormEl.empty().append(html);

		if (widgetObj.configuration.period) {
			$("input[value=" + widgetObj.configuration.period + "]").prop('checked', true);
			$("#cw_history_period_control li").removeClass('is_selected');
			$("input[value=" + widgetObj.configuration.period + "]").parent().addClass('is_selected');
		}

		this.removeListeners();
		this.attachListeners();

		this.initKendoComponents();

		const requiredFields = ['#cw_history_accountid', '#cw_history_sid', '#cw_history_seid'];
		applyRequired(requiredFields);

		if (widgetObj.configuration.period === 'CUSTOM') {
			$('#cw_history_period_control li').removeClass('is_selected');
			$('#cw_history_period_custom').parent().addClass('is_selected');
			$('#cw_history_dates').removeClass('hide');
			$('#cw_history_startdate').data('kendoDateTimePicker').value(new Date(widgetObj.configuration.startDate));
			$('#cw_history_enddate').data('kendoDateTimePicker').value(new Date(widgetObj.configuration.endDate));
		}
	},
	/**
	 * Removes listeners
	 */
	removeListeners: function () {
		$('#cw_history_period_control').find('li').off();
		$('.cw_multi_toggle li').off();
	},
	/**
	 * Attaches listeners
	 */
	attachListeners: function () {
		$('#cw_history_period_control').find('li').on('click', $.proxy(this.onHistoryTypeSelection, this));
		$('.cw_multi_toggle li').on('click', $.proxy(this.onToggleButtons, this));
	},
	/**
	 * Iniitalizes kendo components
	 */
	initKendoComponents: function () {
		var widgetObj = this.widgetObj;

		this.createAccountComponent({
			element: $('#cw_history_accountid'),
			container: $('#cw_history_account'),
			nextElement: $('#cw_history_sid'),
			events: {
				dataSourceChange: $.proxy(function (e, length) {
					this.emptyComponent({
						containers: [{
							container: $('#cw_history_service'),
							label: lang.widget.SERVICE
						}, {
							container: $('#cw_history_se'),
							label: lang.designer.SERVICE_ELEMENT
						}],
						length: length
					});
				}, this)
			}
		});
		$('#cw_history_sid').kendoComboBox({
			dataSource: new kendo.ceeview.DataSource({
				change: $.proxy(function (e) {
					this.emptyComponent({
						containers: [{
							container: $('#cw_history_se'),
							label: lang.designer.SERVICE_ELEMENT
						}],
						length: e.items.length
					});
				}, this),
				transport: {
					read: {
						url: $.proxy(function () {
							return Settings.serverPath + 'accounts/' + (this.widgetAccountId || this.widgetObj.configuration.accountId) + '/services/lite?hasModel=true';
						}, this),
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				error: ErrorHandler.kendoServerError,
				sort: {field: 'name', dir: 'asc'}
			}),
			dataTextField: 'name',
			dataValueField: 'id',
			select: $.proxy(this.onServiceModelSelect, this),
			template: '<span id="${ data.id }">${ data.name }</span>',
			autoBind: widgetObj.configuration.accountId,
			placeholder: lang.SELECT,
			dataBound: $.proxy(function () {
				if (widgetObj.configuration.serviceId) {
					$('#cw_history_sid').data('kendoComboBox').value(widgetObj.configuration.serviceId);
				}
			}, this)
		}).data('kendoComboBox');
		let seDataSource = [];
		if (widgetObj.configuration.serviceId) {
			seDataSource = new kendo.ceeview.DataSource({
				change: $.proxy(function (e) {
					this.emptyComponent({
						containers: [{
							container: $('#cw_history_se'),
							label: lang.designer.SERVICE_ELEMENT
						}],
						length: e.items.length
					});
				}, this),
				transport: {
					read: {
						url: Settings.serverPath + 'services/' + widgetObj.configuration.serviceId + '/elements/lite',
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				error: ErrorHandler.kendoServerError,
				sort: {field: 'name', dir: 'asc'}
			});
		}
		$('#cw_history_seid').kendoComboBox({
			dataSource:  seDataSource,
			dataTextField: 'name',
			dataValueField: 'id',
			template: '<span id="${ data.id }">${ data.name }</span>',
			enable: widgetObj.configuration.serviceId,
			placeholder: lang.SELECT,
			autoBind: true
		}).data('kendoComboBox');
		$('#cw_history_seid').data('kendoComboBox').value(widgetObj.configuration.serviceElementId);
		$("#cw_history_startdate").kendoDateTimePicker({
			format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
			timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat)
		});
		$("#cw_history_enddate").kendoDateTimePicker({
			format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
			timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat)
		});
		$('#cw_history_timezone').kendoComboBox({
			dataSource: new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: Settings.serverPath + 'sessions/timezones/?addUserTime=true',
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				error: ErrorHandler.kendoServerError
			}),
			autoBind: true
		}).data('kendoComboBox');
	},
	/*
	 * Handler function for getting the widget configuration values
	 * */
	getValues: function () {
		var title = $('.cw_widget_title').val().trim();
		var widgetObj = {
			id: this.widgetObj.id || Utils.guid(),
			type: 'history',
			title: title,
			configuration: {
				accountId: this.widgetAccountId || $('#cw_history_accountid').data('kendoComboBox').value(),
				serviceId: $('#cw_history_sid').data('kendoComboBox').value(),
				serviceElementId: $('#cw_history_seid').data('kendoComboBox').value(),
				period: $('input[name=cw_history_period]:checked').val(),
				startDate: $('#cw_history_startdate').data('kendoDateTimePicker').value(),
				endDate: $('#cw_history_enddate').data('kendoDateTimePicker').value(),
				timezone: $('#cw_history_timezone').data('kendoComboBox').value(),
				hideTimeSelector: $('#cw_hide_time_selector').is(':checked'),
				excludeBreaches: $('#cw_exclude_breaches').is(':checked'),
				excludeDowntime: $('#cw_exclude_downtime').is(':checked'),
				showFooter: $('#cw_show_footer').is(':checked')
			}
		};

		return widgetObj;
	},
	/*
	 * Checks if form is valid
	 * */
	isValid: function () {
		var valid = true;
		this.validationMessage = '';

		var sCombo = $('#cw_history_sid').data('kendoComboBox');
		var seCombo = $('#cw_history_seid').data('kendoComboBox');
		var accountCombo = $('#cw_history_accountid').data('kendoComboBox');

		if (!Utils.isGuid(accountCombo.value())) {
			valid = false;
			this.validationMessage = lang.widget.SELECT_ACCOUNT;
		}
		if (!Utils.isGuid(seCombo.value())) {
			valid = false;
			this.validationMessage = lang.widget.SELECT_SERVICE_ELEMENT;
		}
		if (!Utils.isGuid(sCombo.value())) {
			valid = false;
			this.validationMessage = lang.widget.SELECT_SERVICE_MODEL;
		}

		return {
			valid: valid,
			validationMessage: this.validationMessage
		};
	},
	/**
	 * Handler function for the click event on the History dates radio
	 * @param {Object} e The click event object
	 */
	onHistoryTypeSelection: function (e) {
		var id = $(e.currentTarget).find('input').attr('id');
		if (id === 'cw_history_period_custom') {
			$('#cw_history_dates').removeClass('hide');
		} else {
			$('#cw_history_dates').addClass('hide');
		}
	},
	/**
	 * Handler function for the clicke event on radio toggle buttons
	 * @param {Object} e The click event
	 */
	onToggleButtons: function (e) {
		var li = $(e.currentTarget);
		if (!li.find('input').is(':checked')) {
			var inputBtn = li.find('input');
			var checked = inputBtn.is(':checked');
			li.parent().find('.is_selected').removeClass('is_selected');
			if (checked === 'checked') {
				inputBtn.prop('checked', false);
				li.removeClass('is_selected');
			} else {
				inputBtn.prop('checked', true);
				li.addClass('is_selected');
			}
		}
	},
	/**
	 * Handler function for the select event of the metrics service dropdown list
	 * @param {Object} e The select event
	 */
	onServiceModelSelect: function (e) {
		var serviceElementCombo = $("#cw_history_seid").data('kendoComboBox');
		var smId = e.item.find('span').attr('id');
		if (smId) {	
			serviceElementCombo.enable(true);
			serviceElementCombo.value('');		
			var newSeDataSource = new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: Settings.serverPath + 'services/' + smId + '/elements/lite',
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				error: ErrorHandler.kendoServerError
			});
			serviceElementCombo.setDataSource(newSeDataSource);
			serviceElementCombo.dataSource.read();
			
		} else {
			serviceElementCombo.text('');
			serviceElementCombo.enable(false);
		}
	},
});
