import ReactDOM from 'react-dom';
import React from 'react';

import {MonitorConfiguration} from "areas/assets/monitors";
import {CeeViewDataSource, DataSourceSeverity, Renderer, State, Utils} from "tools";
import {Cookies, ErrorHandler} from "core";
import {Settings} from "settings";
import {CustomNotification, GridMenu, Switch, Tags} from "controls";
import DiskView from 'areas/assets/monitors/system/diskView/diskView';
import translator from 'core/localization';
import {DISK_VIEW} from "areas/assets/monitors/system/shared";
import DocumentationButton from 'controls/react/form/documentationButton';

const i = translator({
	'Only available for windows assets': {
		no: 'Kun tilgjengelig for windows ressurser'
	},
	'Please input name...': {
		no: 'Skriv inn navn...'
	}
});

export default function Details(config) {
	/*
	 @values create, view, update
	 */
	this.mode = 'view';
	this.showAdvanced = false;
	Utils.apply(this, config);
	this.module = {
		name: 'AssetHealthSystemMonitor',
		initialConfig: config
	};
	this.accountContext = this.accountId || Cookies.CeesoftCurrentAccountId;
	State.mainApp.addLoadingMask();
	this.initComponent();
}

jQuery.extend(Details.prototype, MonitorConfiguration.prototype, {
	/**
	 * Module related initializations can be done here
	 */
	initComponent: function () {
		this.update = true;
		this.data = {};
		if (!State.mainApp.session.hasRole('MONITOR_UPDATE')) {
			this.mode = 'view';
			this.update = false;
		}
		this.descriptionTooltip = 'data-tooltip="generic" data-title="'+ lang.monitor.description + '"';

		MonitorConfiguration.prototype.initComponent.call(this);

		this.gridDiskStatuses = {
			custom: lang.CUSTOM,
			'default': lang.DEFAULT,
			none: lang.NONE,
			missing: lang.MISSING
		};

		this.diskView = new DiskView(DISK_VIEW.DISK, this.mode, this.getContextVars.bind(this));
		this.sharedDiskView = new DiskView(DISK_VIEW.SHARED_DISK, this.mode, this.getContextVars.bind(this));

		$('#cw_shared_disk_default_container').css('margin', '');

		this.notification = new CustomNotification({
			appendToElement: '.cw_side_settings',
			status: 'success',
			type: 'icon',
			hideOnClick: true
		});

		this.removeListeners();
		this.attachListeners();
		if (this.mode === 'create') {
			this.addSettingsMask();
			// this.hideSystemMetrics();
			this.initKendoComponents();
			$('#cw_save').text(lang.CREATE);
			State.mainApp.removeLoadingMask();
			this.setSave();
		} else {
			this.load();
		}
		if (this.mode === 'view') {
			this.descriptionHandler.readonly(true);
		}
	},

	getContextVars: function() {
		const context = {
			accountContext: this.accountContext,
			assetId: this.assetId,
			id: this.id
		};

		if (this.isGroupSelected) {
			context.targetId = this.assetGroupList.value();
			context.targetType = 'ASSET_GROUP';
			context.configurationAssetId = this.assetListTwo.value() || this.configuration.configurationAssetId;
		} else {
			context.agentId = this.agentsList.value();
			context.targetId = this.assetId;
			context.targetType = 'ASSET';
			context.configurationAssetId = context.targetId;
		}

		return context;
	},

	/**
	 * Initializes Kendo components
	 */
	initKendoComponents: function () {
        this.tagsControl = new Tags({
            renderEl: $('#cw_tags'),
            enabled: this.mode === 'view' ? false : true
        });

		this.actionNotification = new CustomNotification({
			appendToElement: '.window_area',
			type: 'icon',
			hideOnClick: true,
			style: 'z-index:30; left:0; right:0; top:40px'
		});
		this.actionSettingsNotification = new CustomNotification({
			appendToElement: '.cw_config_sysdetails .cw_section_content',
			type: 'icon',
			hideOnClick: true
		});
		this.actionDrivesNotification = new CustomNotification({
			appendToElement: '.cw_disk_list',
			type: 'icon',
			hideOnClick: true
		});

		this.menu = new GridMenu({
			renderTo: 'cw_menu',
			items: [{
				id: 'cw_advanced',
				icon: 'list', //circle-minus
				text: lang.ADVANCED,
				fn: this.onAdvanced,
				scope: this,
				disabled: this.mode !== 'update'
			}]
		});

		this.tabStrip = $('#cw_system_details_tab_nav').kendoTabStrip({
			animation: false
		}).data('kendoTabStrip');
		this.tabStrip.activateTab($('.cw_sdnav_cpu_memory'));

		if (this.mode === 'view') {
			this.setReadonlyInputs(true);
			$('.cw_calculations').removeClass('hide');
			//$('.cw_events').removeClass('hide');
		} else {
			if (this.mode === 'create') {
				$('#cw_next').attr('disabled', true).parent().removeClass('hide');
				this.menu.enable(false);
				$('.cw_multi_toggle').removeClass('is_readonly');
			} else if (this.mode === 'update') {
				$('#cw_save').prop('disabled', false).html(lang.UPDATE).parent().removeClass('hide');
				$('.cw_calculations').removeClass('hide');
				//$('.cw_events').removeClass('hide');
			}
			this.setReadonlyInputs(false);
		}

		this.monitorErrorList = $('#cw_monitor_error').kendoDropDownList({
			dataTextField: 'text',
			dataValueField: 'value',
			dataSource: DataSourceSeverity,
			change: $.proxy(function (e) {
				this.monitorError = e.sender.value();
			}, this),
			open: function (e) {
				e.sender.popup.wrapper.addClass('cw_indicator_dropdown');
			},
			autoBind: true,
			enable: this.mode === 'view' ? false : true,
			template: '<span class="cw_status_indicator cw_status_widget_color cw_color${data.color}"></span><span>${data.text}</span>',
			valueTemplate: '<span class="cw_inline_status_indicator cw_status_widget_color cw_color${data.color}"></span><span>${data.text}</span>',
			dataBound: $.proxy(function (e) {
				if (this.mode === 'create') {
					e.sender.select(0);
					e.sender.trigger('change');
				} else if (this.mode === 'update') {
					var index = Utils.getSeverityIndex(this.monitorError, DataSourceSeverity);
					$('#cw_monitor_error').data('kendoDropDownList').select(index);
				}
			}, this)
		}).data('kendoDropDownList');
		this.monitorErrorList.list.addClass('cw_indicator_dropdown');

		$('.cw_inline[data-tooltip="trigger"]').kendoTooltip({
			width: 300,
			content: ' Trigger is the value that starts a closer inspection of a situation; most situations are not a problem so it is no point using resources on them. For instance 60% usage of memory or 21 % usage of disk is of no concern. When a trigger is activated it will normally result in a minor (yellow) state for the given asset. Also referred to as minor threshold.'
		}).data("kendoTooltip");
		$('.cw_inline[data-tooltip="critical"]').kendoTooltip({
			width: 300,
			content: 'When the critical trigger point is reached it will result in a critical (red) state for the given asset. It can be a percentage or value. Also referred to as critical threshold. Note that averaging, timeat, short trend and long trending will affect the trigger point.'
		}).data("kendoTooltip");
		$('.cw_inline[data-tooltip="timeat"]').kendoTooltip({
			width: 300,
			content: 'If timeat is set a, breach of the critical trigger point will first be considered as a major (orange) for the total of millisecond before becoming a critical (red). If timeat is not set (-1) it will go directly to a critical state.'
		}).data("kendoTooltip");
		$('.cw_inline[data-tooltip="projected"]').kendoTooltip({
			width: 300,
			content: 'Project time, this is used only for trend calculation. If set to 60000 it means that a short trend will give a critical state if the trend will breach the limit in less than 60000 milliseconds (60 seconds). Short trend is based on data from the last hour with extra weight on the newest data.'
		}).data("kendoTooltip");
		$('.k-checkbox-label[data-tooltip="short-trend"]').kendoTooltip({
			width: 300,
			content: 'Short trend uses data from the last hour to predict if a the critical threshold will be breached'
		}).data("kendoTooltip");
		$('.k-checkbox-label[data-tooltip="long-trend"]').kendoTooltip({
			width: 400,
			content: 'The long term trend use the average data from same weekday/hour for the last 4 weeks. It uses 10 X projected time (10 X 60000 milliseconds from our above example). This means that it will give critical alarm of the monitored entity if it will reach critical state within 10 minutes. (For instance a growing disk that will be 98% full I current growth continues)'
		}).data("kendoTooltip");
		$('.k-checkbox-label[data-tooltip="trigger-adjust"]').kendoTooltip({
			width: 400,
			content: "This setting adjust the trigger threshold based on average values and variance in the values. This is also based on data from the same weekday/hour 4 weeks back. The theory behind is that in a normal pattern further checks are not required. However, if the value goes outside the normal data pattern it should be investigated further. Thus the trigger threshold can be adjusted either up or down according to the needs. Example: If we almost fill a disk every Wednesday at 0300 due to cleanup routines then we will not raise a critical state for it. "
		}).data("kendoTooltip");
		$('.k-checkbox-label[data-tooltip="log"]').kendoTooltip({
			width: 300,
			content: ' Enabling logging means that values, static and dynamic calculations will be written to a .cvs file for each data series.'
		}).data("kendoTooltip");
		this.createDetailsBox();
		this.createCpuBox();
		this.createMemoryBox();

		this.diskView.createDisksBox(this.removeSystemMask, this.addSectionMask);
		this.sharedDiskView.createDisksBox(this.removeSystemMask, this.addSectionMask);

		this.diskView.setDisks({
			"enabled": false,
			"metrics": [],
			"instances": [],
			"enableUnknownInstances": false
		});

		this.sharedDiskView.setDisks({
			"enabled": false,
			"metrics": [],
			"instances": []
		});

		this.createTimeBox();
		this.renderDocumentationButton();
	},
	/**
	 * Called in order to remove existing listeners
	 */
	removeListeners: function () {
		$('#cw_switch').off();
		$('#save_monitor').off();
		$('#cancel').off();
		$('#cw_name').off();
		$('#cw_save').off();
		$('.cw_system_monitor').off();
		$('.cw_grid_check .cw_disk_list').off();
		$('.cw_grid_check_all .cw_disk_list').off();
	},
	/**
	 * Attaches the listeners for the form's buttons
	 */
	attachListeners: function () {
		$('#cw_switch').on('click', 'li', $.proxy(this.onMonitorSwitch, this));
		$('.cw_cancel').on('click', $.proxy(this.onCancel, this));
		$('.cw_configuration_cancel').on('click', $.proxy(this.onConfigurationCancel, this));
		$('#cw_next').on('click', $.proxy(this.onNext, this));
		$('#cw_name').on('keyup', $.proxy(this.setSave, this));
		$('#cw_save').on('click', $.proxy(this.onSave, this));
		var systeMonitorHandler = $('.cw_system_monitor');
		systeMonitorHandler.on('keydown', '.value', $.proxy(Utils.onInputDigitRestrict, this));
		systeMonitorHandler.on('blur', '.value', $.proxy(Utils.onSliderValueBlur, this));
		systeMonitorHandler.on('keydown', '.cw_digit_restricted', $.proxy(Utils.onInputDigitRestrict, this));
		$(window).on('resize', $.proxy(this.onBrowserResize, this));

	},
	/**
	 * Handler function for loading and initializating the monitor and components
	 */
	load: function () {
		var loadUrl = Settings.serverPath + 'accounts/' + this.accountContext + '/monitors/SYSTEM/' + this.id + '/?update=' + this.update;
		this.showLoading('.window_area');
		Utils.ajax(loadUrl, 'GET', {}, $.proxy(function (result) {
			if (result.success) {
				var data = result.data;
				this.data = result.data;
				this.accountContext = data.accountId;
				this.configuration = data;
				this.id = data.id;
				Renderer.monitorCVID(this.id);
				$('#cw_name').val(data.name);
				this.descriptionHandler.value(data.description);
				this.monitorError = data.monitorErrorSeverity;
				this.initKendoComponents();
                this.tagsControl.setRawValues(data.tags);
				var toggleHandler = $('#cw_switch .cw_multi_toggle');
				toggleHandler.removeClass('is_readonly');
				if (data.targetType === 'ASSET') {
					toggleHandler.find('li.first_btn').trigger('click');
					this.agentsList.value(data.agentId);
					this.selectedAgentId = data.agentId;
					this.getAssetName();
					this.agentsList.enable(false);
				} else {
					toggleHandler.find('li.last_btn').trigger('click');
					this.assetGroupList.value(data.targetId);
					this.assetGroupList.trigger('change');
					this.assetGroupList.enable(false);
					this.assetId = data.configurationAssetId;
					//this.diskMenu.disableItem('cw_reload');
				}
				toggleHandler.addClass('is_readonly');
				this.setDetails(data);
				this.setCPU(data.cpu);
				this.setMemory(data.memory);

				this.diskView.setDisks(data.disk);
				this.sharedDiskView.setDisks(data.shares);

				this.setTime(data.time);
				this.setReboot(data.reboot);
				// this.showSystemMetrics();
				this.hideLoading('.window_area');
				if (this.mode === 'update') {
					$('#cw_save').prop('disabled', false).parent().removeClass('hide');
				}
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
			State.mainApp.removeLoadingMask();
		}, this));
	},
	renderDocumentationButton: function () {
		ReactDOM.render(<DocumentationButton monitorType={'system'}>
			</DocumentationButton>, $('.cw_documentation').get(0));
	},
	/**
	 * Handler function to set details in form
	 * @param {Object} data The object with details
	 */
	setDetails: function (data) {
		if (!data.calculations) {
			data.calculations = {};
		}
		if (!data.event) {
			data.event = {};
		}
		$('#cw_calculation_average').val(data.calculations.averagingTimeInSeconds);
		$('#cw_calculation_log').prop('checked', data.calculations.log);
		$('#cw_calculation_shorttrend').prop('checked', data.calculations.shortTrend);
		$('#cw_calculation_trigger').prop('checked', data.calculations.triggerAdjust);
		$('#cw_calculation_longtrend').prop('checked', data.calculations.longTrend);
		$('#cw_enable_dynamic_memory').prop('checked', data.memory.useHyperVMaxMemory);
		//$('#cw_events_enable').prop('checked', data.event.enabled);
		$('#cw_event_penalty').val(data.event.penalty);
		$('#cw_event_expiration').val(data.event.expiration);
	},
	/**
	 * Handler function to set CPU in form
	 * @param {Object} data the Object with CPU details
	 */
	setCPU: function (data) {
		this.cpuSwitch.setValue(data.enabled).trigger('change');
		if (data.cpuUsage) {
            this.cpuUsageSwitch.setValue(data.cpuUsage.enabled).trigger('change');
            if (data.cpuUsage.severity === -1) {
                this.cpuPenalty.enable(false);
                this.cpuPenalty.wrapper.closest('.cw_field').addClass('is_hidden');
            }
            this.cpuPenalty.value(data.cpuUsage.severity);
            if (data.cpuUsage.warning === -1) {
                this.cpuTriggerSlider.enable(false);
                this.cpuTriggerSlider.wrapper.closest('.cw_field').addClass('is_hidden');
            }
            if (data.cpuUsage.critical === -1) {
                this.cpuCriticalSlider.enable(false);
                this.cpuCriticalSlider.wrapper.closest('.cw_field').addClass('is_hidden');
            }
            this.cpuTriggerSlider.value(data.cpuUsage.warning);
            this.cpuCriticalSlider.value(data.cpuUsage.critical);
            this.cpuTriggerSlider.trigger('change');
            this.cpuCriticalSlider.trigger('change');
            if (data.cpuUsage.projected === -1) {
                this.cpuHandler.find('.cw_projected').attr('readonly', true);
                this.cpuHandler.find('.cw_projected').closest('.is_advanced').addClass('is_hidden');
            }
            this.cpuHandler.find('.cw_projected').val(data.cpuUsage.projected);
            if (data.cpuUsage.timeat === -1) {
                this.cpuHandler.find('.cw_timeat').attr('readonly', true);
                this.cpuHandler.find('.cw_timeat').closest('.is_advanced').addClass('is_hidden');
            }
            this.cpuHandler.find('.cw_timeat').val(data.cpuUsage.timeat);
            if (data.cpuQueue.severity === -1) {
                this.cpuQueueHandler.find('.cw_penalty').attr('readonly', true);
                this.cpuQueueHandler.find('.cw_penalty').closest('.is_advanced').addClass('is_hidden');
            }
		}
		if (data.cpuQueue) {
            this.cpuQueueSwitch.setValue(data.cpuQueue.enabled).trigger('change');

            this.cpuQueuePenalty.value(data.cpuQueue.severity);
            if (data.cpuQueue.critical === -1) {
                this.cpuQueueHandler.find('.cw_critical').attr('readonly', true);
                this.cpuQueueHandler.find('.cw_critical').closest('.is_advanced').addClass('is_hidden');
            }
            this.cpuQueueHandler.find('.cw_critical').val(data.cpuQueue.critical);
            if (data.cpuQueue.projected === -1) {
                this.cpuQueueHandler.find('.cw_projected').attr('readonly', true);
                this.cpuQueueHandler.find('.cw_projected').closest('.is_advanced').addClass('is_hidden');
            }
            this.cpuQueueHandler.find('.cw_projected').val(data.cpuQueue.projected);
            if (data.cpuQueue.timeat === -1) {
                this.cpuQueueHandler.find('.cw_timeat').attr('readonly', true);
                this.cpuQueueHandler.find('.cw_timeat').closest('.is_advanced').addClass('is_hidden');
            }
            this.cpuQueueHandler.find('.cw_timeat').val(data.cpuQueue.timeat);
            if (data.cpuQueue.warning === -1) {
                this.cpuQueueHandler.find('.cw_trigger').attr('readonly', true);
                this.cpuQueueHandler.find('.cw_trigger').closest('.is_advanced').addClass('is_hidden');
            }
            this.cpuQueueHandler.find('.cw_trigger').val(data.cpuQueue.warning);
        }
	},
	/**
	 * Handler function to set MEMORY in form
	 * @param {Object} data the Object with MEMORY details
	 */
	setMemory: function (data) {
		this.memorySwitch.setValue(data.enabled).trigger('change');
		if (data.memoryUsage) {
            this.memoryUsageSwitch.setValue(data.memoryUsage.enabled).trigger('change');
            if (data.memoryUsage.severity === -1) {
                this.memoryPenalty.enable(false);
                this.memoryPenalty.wrapper.closest('.cw_field').addClass('is_hidden');
            }
            this.memoryPenalty.value(data.memoryUsage.severity);
            if (data.memoryUsage.warning === -1) {
                this.memoryTriggerSlider.enable(false);
                this.memoryTriggerSlider.wrapper.closest('.cw_field').addClass('is_hidden');
            }
            if (data.memoryUsage.critical === -1) {
                this.memoryCriticalSlider.enable(false);
                this.memoryCriticalSlider.wrapper.closest('.cw_field').addClass('is_hidden');
            }
            this.memoryTriggerSlider.value(data.memoryUsage.warning);
            this.memoryCriticalSlider.value(data.memoryUsage.critical);
            this.memoryTriggerSlider.trigger('change');
            this.memoryCriticalSlider.trigger('change');
            if (data.memoryUsage.projected === -1) {
                this.memoryHandler.find('.cw_projected').attr('readonly', true);
                this.memoryHandler.find('.cw_projected').closest('.is_advanced').addClass('is_hidden');
            }
            this.memoryHandler.find('.cw_projected').val(data.memoryUsage.projected);
            if (data.memoryUsage.timeat === -1) {
                this.memoryHandler.find('.cw_timeat').attr('readonly', true);
                this.memoryHandler.find('.cw_timeat').closest('.is_advanced').addClass('is_hidden');
            }
            this.memoryHandler.find('.cw_timeat').val(data.memoryUsage.timeat);
            if (data.pagefileUsage.severity === -1) {
                this.pagefilePenalty.enable(false);
                this.pagefilePenalty.wrapper.closest('.cw_field').addClass('is_hidden');
            }
		}
        if (data.pagefileUsage) {
            this.pagefileSwitch.setValue(data.pagefileUsage.enabled).trigger('change');

            this.pagefilePenalty.value(data.pagefileUsage.severity);
            if (data.pagefileUsage.warning === -1) {
                this.pagefileTriggerSlider.enable(false);
                this.pagefileTriggerSlider.wrapper.closest('.cw_field').addClass('is_hidden');
            }
            if (data.pagefileUsage.critical === -1) {
                this.pagefileCriticalSlider.enable(false);
                this.pagefileCriticalSlider.wrapper.closest('.cw_field').addClass('is_hidden');
            }
            this.pagefileTriggerSlider.value(data.pagefileUsage.warning);
            this.pagefileCriticalSlider.value(data.pagefileUsage.critical);
            this.pagefileTriggerSlider.trigger('change');
            this.pagefileCriticalSlider.trigger('change');
            if (data.pagefileUsage.projected === -1) {
                this.pagefileHandler.find('.cw_projected').attr('readonly', true);
                this.pagefileHandler.find('.cw_projected').closest('.is_advanced').addClass('is_hidden');
            }
            this.pagefileHandler.find('.cw_projected').val(data.pagefileUsage.projected);
            if (data.pagefileUsage.timeat === -1) {
                this.pagefileHandler.find('.cw_timeat').attr('readonly', true);
                this.pagefileHandler.find('.cw_timeat').closest('.is_advanced').addClass('is_hidden');
            }
            this.pagefileHandler.find('.cw_timeat').val(data.pagefileUsage.timeat);
        }
	},
    /**
     * Handler function to set time in form
     * @param {Object} data the Object with time details
     */
    setTime: function (data) {
        this.timeSwitch.setValue(data.enabled).trigger('change');
        $('#cw_ntp_server').val(data.ntpServer);

        if (data.offset) {
            this.offsetSwitch.setValue(data.offset.enabled).trigger('change');

            if (data.offset.severity === -1) {
                this.offsetPenalty.enable(false);
                this.offsetPenalty.wrapper.closest('.cw_field').addClass('is_hidden');
            }
            this.offsetPenalty.value(data.offset.severity);

            if (data.offset.critical === -1) {
                this.offsetHandler.find('.cw_critical').attr('readonly', true);
                this.offsetHandler.find('.cw_critical').closest('.is_advanced').addClass('is_hidden');
            }
            this.offsetHandler.find('.cw_critical').val(data.offset.critical);

            if (data.offset.warning === -1) {
                this.offsetHandler.find('.cw_trigger').attr('readonly', true);
                this.offsetHandler.find('.cw_trigger').closest('.is_advanced').addClass('is_hidden');
            }
            this.offsetHandler.find('.cw_trigger').val(data.offset.warning);

            if (data.offset.projected === -1) {
                this.offsetHandler.find('.cw_projected').attr('readonly', true);
                this.offsetHandler.find('.cw_projected').closest('.is_advanced').addClass('is_hidden');
            }
            this.offsetHandler.find('.cw_projected').val(data.offset.projected);

            if (data.offset.timeat === -1) {
                this.offsetHandler.find('.cw_timeat').attr('readonly', true);
                this.offsetHandler.find('.cw_timeat').closest('.is_advanced').addClass('is_hidden');
            }
            this.offsetHandler.find('.cw_timeat').val(data.offset.timeat);
        }

        if (data.delay) {
            this.delaySwitch.setValue(data.delay.enabled).trigger('change');

            if (data.delay.severity === -1) {
                this.delayPenalty.enable(false);
                this.delayPenalty.wrapper.closest('.cw_field').addClass('is_hidden');
            }
            this.delayPenalty.value(data.delay.severity);

            if (data.delay.critical === -1) {
                this.delayHandler.find('.cw_critical').attr('readonly', true);
                this.delayHandler.find('.cw_critical').val(data.offset.critical);
            }
            this.delayHandler.find('.cw_critical').val(data.delay.critical);

            if (data.delay.warning === -1) {
                this.delayHandler.find('.cw_trigger').attr('readonly', true);
                this.delayHandler.find('.cw_trigger').closest('.is_advanced').addClass('is_hidden');
            }
            this.delayHandler.find('.cw_trigger').val(data.delay.warning);

            if (data.delay.projected === -1) {
                this.delayHandler.find('.cw_projected').attr('readonly', true);
                this.delayHandler.find('.cw_projected').closest('.is_advanced').addClass('is_hidden');
            }
            this.delayHandler.find('.cw_projected').val(data.delay.projected);

            if (data.delay.timeat === -1) {
                this.delayHandler.find('.cw_timeat').attr('readonly', true);
                this.delayHandler.find('.cw_timeat').closest('.is_advanced').addClass('is_hidden');
            }
            this.delayHandler.find('.cw_timeat').val(data.delay.timeat);
		}
    },
	setReboot: function(data) {
		if (data) {
			this.rebootSwitch.setValue(data.enabled).trigger('change');
			this.rebootSeverity.value(data.rebootSeverity);
			this.rebootHandler.find('#cw_reboot_timeout').val(data.timeoutHours);
		}
	},

	/**
	 * Handler function for setting the advanced
	 */
	onAdvanced: function (e) {
		var target = $(e.currentTarget);
		target.find('.glyphicons').toggleClass('list show-thumbnails-with-lines');
		$('.cw_system_cpu').toggleClass('advanced_mode');
		$('.cw_system_memory').toggleClass('advanced_mode');
		$('.cw_dynamic_memory').toggleClass('advanced_mode');
		if ($('.cw_system_cpu').hasClass('advanced_mode')) {
			target.parent().find('.item-label').text(lang.DEFAULT);
		} else {
			target.parent().find('.item-label').text(lang.ADVANCED);
		}
		$('.is_advanced:not(.is_hidden)').toggleClass('hide', this.showAdvanced);
		this.showAdvanced = !this.showAdvanced;

		this.diskView.applyAdvanced(this.showAdvanced);
		this.sharedDiskView.applyAdvanced(this.showAdvanced);
	},
	/**
	 * Handler function for setting readonly disk components
	 * @param bool {Boolean} boolean value for setting readonly
	 * */
	setReadOnlyDisksComponents: function (bool) {
		bool = !bool;
		var settingsHandler = $('.cw_disk_settings');
		settingsHandler.find('input.cw_trigger').each(function () {
			var slider = $(this).data('kendoSlider');
			if (slider) {
				slider.enable(bool);
			}
		});
		settingsHandler.find('input.cw_critical').each(function () {
			var slider = $(this).data('kendoSlider');
			if (slider) {
				slider.enable(bool);
			}
		});
		settingsHandler.find('input.cw_penalty').each(function () {
			var dropdown = $(this).data('kendoDropDownList');
			if (dropdown) {
				dropdown.enable(bool);
			}
		});
		settingsHandler.find('.cw_switch').each(function () {
			var Switch = $(this).data('Switch');
			if (Switch) {
				Switch.enable(bool);
			}
		});
	},
	/**
	 * Handler function for setting readonly inputs
	 * @param bool {Boolean} boolean value for setting readonly
	 * */
	setReadonlyInputs: function (bool) {
		$('.is_readonly').attr('readonly', bool);
		$('.is_disabled').attr('disabled', bool);
	},
	/**
	 * Handler function for setting readonly inputs
	 * @param bool {Boolean} boolean value for setting readonly
	 * */
	setReadonlyDiskInputs: function (bool) {
		var diskSettings = $('.cw_disk_settings');
		diskSettings.find('.is_readonly').attr('readonly', bool);
		diskSettings.find('.is_disabled').attr('disabled', bool);
	},
	/**
	 * Handler function for detail box creation
	 */
	createDetailsBox: function () {
		this.agentDataSource = new CeeViewDataSource({
			transport: {
				read: {
					url: Settings.serverPath + 'accounts/' + this.accountContext + '/agents/lite?includeShared=' + this.sharedAgents + '&includeSubAgents=true',
					contentType: 'application/json; charset=utf-8',
					type: 'GET',
					dataType: 'json',
                    cache: false
				}
			},
			error: ErrorHandler.kendoServerError,
			sort: {field: 'name', dir: 'asc'}
		});
		this.agentsList = $('#cw_agent').kendoComboBox({
			dataSource: this.agentDataSource,
			change: $.proxy(this.onAgentSelect, this),
			dataTextField: 'name',
			dataValueField: 'id',
			autoBind: false,
			placeholder: lang.SELECT
		}).data('kendoComboBox');
		if (this.mode === 'update') {	this.agentsList.wrapper.children('span').on('click', $.proxy(Utils.onAgentNameClick,this)).find('input').addClass('cw_link_elements');	this.agentsList.enable(false);}

		var url = Settings.serverPath + 'accounts/' + this.accountContext + '/assets/types/AGENT/lite';
		this.assetDataSource = new CeeViewDataSource({
			transport: {
				read: {
					url: url,
					contentType: 'application/json; charset=utf-8',
					type: 'GET',
					dataType: 'json',
                    cache: false
				}
			},
			error: ErrorHandler.kendoServerError
		});
		this.assetGroupDataSource = new CeeViewDataSource({
			transport: {
				read: {
					url: $.proxy(function () {
                        return Settings.serverPath + 'accounts/' + this.accountContext + '/assetGroups';
					}, this),
					contentType: 'application/json; charset=utf-8',
					type: 'GET',
					dataType: 'json',
                    cache: false
				}
			},
			error: ErrorHandler.kendoServerError,
			sort: {field: 'name', dir: 'asc'}
		});
		this.assetGroupList = $("#cw_asset_group").kendoComboBox({
			dataTextField: 'name',
			dataValueField: 'id',
			suggest: true,
			placeholder: lang.designer.SELECT_ASSET_GROUP,
			autoBind: false,
			dataSource: this.assetGroupDataSource,
			change: $.proxy(this.onAssetGroupListChange, this)
		}).data('kendoComboBox');
		if (this.mode === 'update') {	this.assetGroupList.wrapper.children('span').on('click', $.proxy(Utils.onAssetGroupNameClick,this)).find('input').addClass('cw_link_elements');}

		this.assetListTwo = $('#cw_asset_two').kendoComboBox({
			dataTextField: 'name',
			dataValueField: 'id',
			suggest: true,
			placeholder: lang.designer.SELECT_ASSET,
			autoBind: true,
			enable: this.mode === 'view' ? false : true,
			dataSource: new CeeViewDataSource({
				data: []
			}),
			change: $.proxy(this.onAssetListTwoChange, this)
		}).data('kendoComboBox');
	},
	/**
	 * Handler function for creating CPU box
	 */
	createCpuBox: function () {
		var cpuEnable = $('#cw_cpu_enable');
		this.cpuSwitch = new Switch({
			renderTo: cpuEnable,
			isEnabled: this.mode !== 'view',
			change: $.proxy(function (result) {
				var labelHandler = cpuEnable.parent().find('span.label');
				labelHandler.text(lang.ACTIVE);
				if (result.value) {
					labelHandler.removeClass('opacity');
					this.removeSystemMask($('.cw_system_cpu'));
				} else {
					labelHandler.addClass('opacity');
					this.addSectionMask($('.cw_system_cpu'));
				}
			}, this)
		});
		this.cpuHandler = $('.cw_cpu');
		this.cpuUsageSwitch = new Switch({
			renderTo: this.cpuHandler.find('.cw_switch_mini'),
			isEnabled: this.mode !== 'view',
			change: $.proxy(function (result) {
				this.setCpuMainCheck();
				if (result.value) {
					this.removeSystemMask(this.cpuHandler);
				} else {
					this.addSeparatorMask(this.cpuHandler);
				}
			}, this)
		});
		this.cpuPenalty = this.cpuHandler.find('input.cw_penalty').kendoDropDownList({
			dataSource: DataSourceSeverity,
			dataTextField: 'text',
			dataValueField: 'value',
			optionLabel: lang.grid.FILTER_SELECT_VALUE,
			template: '<span class="cw_status_indicator cw_status_widget_color cw_color${data.color}"></span><span>${data.text}</span>',
			valueTemplate: '<span class="cw_inline_status_indicator cw_status_widget_color cw_color${data.color}"></span><span>${data.text}</span>',
			enable: this.mode !== 'view'
		}).data('kendoDropDownList');
		this.cpuQueueHandler = $('.cw_cpu_queue');
		this.cpuQueueSwitch = new Switch({
			renderTo: this.cpuQueueHandler.find('.cw_switch_mini'),
			isEnabled: this.mode !== 'view',
			change: $.proxy(function (result) {
				this.setCpuMainCheck();
				if (result.value) {
					this.removeSystemMask(this.cpuQueueHandler);
				} else {
					this.addSeparatorMask(this.cpuQueueHandler);
				}
			}, this)
		});
		this.cpuQueuePenalty = this.cpuQueueHandler.find('input.cw_penalty').kendoDropDownList({
			dataSource: DataSourceSeverity,
			dataTextField: 'text',
			dataValueField: 'value',
			optionLabel: lang.grid.FILTER_SELECT_VALUE,
			template: '<span class="cw_status_indicator cw_status_widget_color cw_color${data.color}"></span><span>${data.text}</span>',
			valueTemplate: '<span class="cw_inline_status_indicator cw_status_widget_color cw_color${data.color}"></span><span>${data.text}</span>',
			enable: this.mode !== 'view'
		}).data('kendoDropDownList');
		this.cpuTriggerSlider = this.cpuHandler.find('.cw_trigger').kendoSlider({
			tickPlacement: 'none',
			change: $.proxy(this.onSliderChange, this),
			showButtons: false,
			min: 0,
			max: 100,
			smallStep: 1,
			largeStep: 100
		}).data("kendoSlider");
		this.cpuCriticalSlider = this.cpuHandler.find('.cw_critical').kendoSlider({
			tickPlacement: 'none',
			change: $.proxy(this.onSliderChange, this),
			showButtons: false,
			min: 0,
			max: 100,
			smallStep: 1,
			largeStep: 100
		}).data("kendoSlider");
		if (this.mode === 'view') {
			this.cpuTriggerSlider.enable(false);
			this.cpuCriticalSlider.enable(false);
		} else {
			this.cpuTriggerSlider.enable(true);
			this.cpuCriticalSlider.enable(true);
		}
	},
	/**
	 * Handler function for memory box
	 */
	createMemoryBox: function () {
		var memoryEnable = $('#cw_memory_enable');
		this.memorySwitch = new Switch({
			renderTo: memoryEnable,
			isEnabled: this.mode !== 'view',
			change: $.proxy(function (result) {
				var labelHandler = memoryEnable.parent().find('span.label');
				labelHandler.text(lang.ACTIVE);
				if (result.value) {
					this.removeSystemMask($('.cw_system_memory'));
					labelHandler.removeClass('opacity');
				} else {
					this.addSectionMask($('.cw_system_memory'));
					labelHandler.addClass('opacity');
				}
			}, this)
		});
		this.memoryHandler = $('.cw_memory');
		this.memoryUsageSwitch = new Switch({
			renderTo: this.memoryHandler.find('.cw_switch_mini'),
			isEnabled: this.mode !== 'view',
			change: $.proxy(function (result) {
				this.setMemoryMainCheck();
				if (result.value) {
					this.removeSystemMask(this.memoryHandler);
				} else {
					this.addSeparatorMask(this.memoryHandler);
				}
			}, this)
		});
		this.memoryPenalty = this.memoryHandler.find('.cw_penalty').kendoDropDownList({
			dataSource: DataSourceSeverity,
			dataTextField: 'text',
			dataValueField: 'value',
			optionLabel: lang.grid.FILTER_SELECT_VALUE,
			template: '<span class="cw_status_indicator cw_status_widget_color cw_color${data.color}"></span><span>${data.text}</span>',
			valueTemplate: '<span class="cw_inline_status_indicator cw_status_widget_color cw_color${data.color}"></span><span>${data.text}</span>',
			enable: this.mode !== 'view',
		}).data('kendoDropDownList');
		this.pagefileHandler = $('.cw_pagefile');
		this.pagefileSwitch = new Switch({
			renderTo: this.pagefileHandler.find('.cw_switch_mini'),
			isEnabled: this.mode !== 'view',
			change: $.proxy(function (result) {
				this.setMemoryMainCheck();
				if (result.value) {
					this.removeSystemMask(this.pagefileHandler);
				} else {
					this.addSeparatorMask(this.pagefileHandler);
				}
			}, this)
		});
		this.pagefilePenalty = this.pagefileHandler.find('.cw_penalty').kendoDropDownList({
			dataSource: DataSourceSeverity,
			dataTextField: 'text',
			dataValueField: 'value',
			optionLabel: lang.grid.FILTER_SELECT_VALUE,
			template: '<span class="cw_status_indicator cw_status_widget_color cw_color${data.color}"></span><span>${data.text}</span>',
			valueTemplate: '<span class="cw_inline_status_indicator cw_status_widget_color cw_color${data.color}"></span><span>${data.text}</span>',
			enable: this.mode !== 'view'
		}).data('kendoDropDownList');
		this.memoryTriggerSlider = this.memoryHandler.find('.cw_trigger').kendoSlider({
			tickPlacement: 'none',
			change: $.proxy(this.onSliderChange, this),
			showButtons: false,
			min: 0,
			max: 100,
			smallStep: 1,
			largeStep: 100,
			enable: this.mode !== 'view'
		}).data("kendoSlider");
		this.memoryCriticalSlider = this.memoryHandler.find('.cw_critical').kendoSlider({
			tickPlacement: 'none',
			change: $.proxy(this.onSliderChange, this),
			showButtons: false,
			min: 0,
			max: 100,
			smallStep: 1,
			largeStep: 100,
			enable: this.mode !== 'view'
		}).data("kendoSlider");
		this.pagefileTriggerSlider = this.pagefileHandler.find('.cw_trigger').kendoSlider({
			tickPlacement: 'none',
			change: $.proxy(this.onSliderChange, this),
			showButtons: false,
			min: 0,
			max: 100,
			smallStep: 1,
			largeStep: 100,
			enable: this.mode !== 'view'
		}).data("kendoSlider");
		this.pagefileCriticalSlider = this.pagefileHandler.find('.cw_critical').kendoSlider({
			tickPlacement: 'none',
			change: $.proxy(this.onSliderChange, this),
			showButtons: false,
			min: 0,
			max: 100,
			smallStep: 1,
			largeStep: 100,
			enable: this.mode !== 'view'
		}).data("kendoSlider");
		if (this.mode === 'view') {
			this.memoryTriggerSlider.enable(false);
			this.memoryCriticalSlider.enable(false);
			this.pagefileTriggerSlider.enable(false);
			this.pagefileCriticalSlider.enable(false);
		} else {
			this.memoryTriggerSlider.enable(true);
			this.memoryCriticalSlider.enable(true);
			this.pagefileTriggerSlider.enable(true);
			this.pagefileCriticalSlider.enable(true);
		}
	},
    /**
	 *
     */
    createTimeBox: function () {
	    this.timeHandler = $('.cw_time');
	    this.timeSwitch = new Switch({
		    renderTo: this.timeHandler.find('.cw_switch_mini'),
		    isEnabled: this.mode !== 'view',
		    change: $.proxy(function (result) {
			    this.setTimeMainCheck();
			    if (result.value) {
				    this.removeSystemMask(this.timeHandler);
			    } else {
				    this.addSeparatorMask(this.timeHandler);
			    }
		    }, this)
	    });
        this.offsetHandler = $('.cw_offset');
        this.offsetSwitch = new Switch({
            renderTo: this.offsetHandler.find('.cw_switch_mini'),
            isEnabled: this.mode !== 'view',
            change: $.proxy(function (result) {
                this.setTimeMainCheck();
                if (result.value) {
                    this.removeSystemMask(this.offsetHandler);
                } else {
                    this.addSeparatorMask(this.offsetHandler);
                }
            }, this)
        });
        this.offsetPenalty = this.offsetHandler.find('input.cw_penalty').kendoDropDownList({
            dataSource: DataSourceSeverity,
            dataTextField: 'text',
            dataValueField: 'value',
            optionLabel: lang.grid.FILTER_SELECT_VALUE,
            template: '<span class="cw_status_indicator cw_status_widget_color cw_color${data.color}"></span><span>${data.text}</span>',
            valueTemplate: '<span class="cw_inline_status_indicator cw_status_widget_color cw_color${data.color}"></span><span>${data.text}</span>',
            enable: this.mode !== 'view'
        }).data('kendoDropDownList');

        this.delayHandler = $('.cw_delay');
        this.delaySwitch = new Switch({
            renderTo: this.delayHandler.find('.cw_switch_mini'),
            isEnabled: this.mode !== 'view',
            change: $.proxy(function (result) {
                this.setTimeMainCheck();
                if (result.value) {
                    this.removeSystemMask(this.delayHandler);
                } else {
                    this.addSeparatorMask(this.delayHandler);
                }
            }, this)
        });
        this.delayPenalty = this.delayHandler.find('input.cw_penalty').kendoDropDownList({
            dataSource: DataSourceSeverity,
            dataTextField: 'text',
            dataValueField: 'value',
            optionLabel: lang.grid.FILTER_SELECT_VALUE,
            template: '<span class="cw_status_indicator cw_status_widget_color cw_color${data.color}"></span><span>${data.text}</span>',
            valueTemplate: '<span class="cw_inline_status_indicator cw_status_widget_color cw_color${data.color}"></span><span>${data.text}</span>',
            enable: this.mode !== 'view'
        }).data('kendoDropDownList');


	    this.rebootHandler = $('.cw_reboot');
	    this.rebootSeverity = this.rebootHandler.find('input.cw_reboot_severity').kendoDropDownList({
		    dataSource: DataSourceSeverity,
		    dataTextField: 'text',
		    dataValueField: 'value',
		    optionLabel: lang.grid.FILTER_SELECT_VALUE,
		    template: '<span class="cw_status_indicator cw_status_widget_color cw_color${data.color}"></span><span>${data.text}</span>',
		    valueTemplate: '<span class="cw_inline_status_indicator cw_status_widget_color cw_color${data.color}"></span><span>${data.text}</span>',
		    enable: this.mode !== 'view'
	    }).data('kendoDropDownList');
	    this.rebootSwitch = new Switch({
		    renderTo: this.rebootHandler.find('.cw_switch_mini'),
		    isEnabled: this.mode !== 'view',
		    change: $.proxy(function (result) {
			    if (result.value) {
				    this.removeSystemMask(this.rebootHandler);
			    } else {
				    this.addSeparatorMask(this.rebootHandler);
			    }
		    }, this)
	    });

	},
	/**
	 * Handler function for sliders change event
	 * */
	onSliderChange: function (e) {
		if (this.mode !== 'view') {
			var section = $(e.sender.wrapper).closest('.cw_separator');
			var triggerSlider = section.find('input.cw_trigger').data('kendoSlider');
			var criticalSlider = section.find('input.cw_critical').data('kendoSlider');
			var values = {
				warning: triggerSlider.value(),
				critical: criticalSlider.value()
			};
			if (values.critical < values.warning) {
				setTimeout(function () {
					criticalSlider.value(values.warning);
					criticalSlider.trigger('change');
				}, 100);
			}
		}
		e.sender.wrapper.closest('.cw_dropdown_container').find('.value').val(e.sender.value());
	},
	/**
	 * Handler function for Toggling monitor type
	 * e {Event} event handler
	 * */
	onMonitorSwitch: function (e) {
		var target = $(e.currentTarget), assetId;
		if (target.closest('.cw_multi_toggle').hasClass('is_readonly')) {
			return;
		}
		if (!target.hasClass('disabled')) {
			if (!target.hasClass('is_selected')) {
				target.parent().find('li').removeClass('is_selected');
				target.addClass('is_selected');
				var input = target.find('input[type=radio]');
				input.prop('checked', !input.is(':checked'));
				this.setEmptyDisksConfiguration();
				if (target.find('input[value=GROUP]').length) {
					this.isGroupSelected = true;
					$('#cw_select_asset').addClass('hide');
					$('#cw_select_asset_group').removeClass('hide');
					$('#cw_select_agent').addClass('hide');
					$('#cw_select_asset_group_options').removeClass('hide');
					$('#cw_asset_name').closest('.cw_field').addClass('hide');
				} else {
					$('#cw_asset_name').val('');
					this.isGroupSelected = false;
					this.assetDataSource.filter({});
					$('#cw_select_asset_group').addClass('hide');
					$('#cw_select_asset_group_options').addClass('hide');
					$('#cw_select_agent').removeClass('hide');
					$('#cw_asset_name').closest('.cw_field').removeClass('hide');
				}
				this.setSave();
			}
		}
	},
	/**
	 * Handler event for the change event of the agentList dropdownlist
	 * @param {Object} e The change object event
	 */
	onAgentSelect: function (e) {
		var selectedAgentId = e.sender.value();
		if (!selectedAgentId) {
			selectedAgentId = e.agentId;
		}
		this.selectedAgentId = selectedAgentId;
		if (Utils.isGuid(this.selectedAgentId)) {
			this.setEmptyDisksConfiguration();
			this.setNext();
			this.getAssetName();
		} else {
			$('#cw_next').attr('disabled', true);
		}
	},
	/**
	 * Handler function for getting asset configuration
	 */
	getConfiguration: function () {
		var url;
		if (this.mode === 'create') {
			url = Settings.serverPath + 'accounts/' + this.accountContext + '/monitors/SYSTEM/systemConfiguration?assetId=' + this.assetId;
		} else {
			url = Settings.serverPath + 'accounts/' + this.accountContext + '/monitors/SYSTEM/' + this.id + '/systemConfiguration?assetId=' + this.assetId;
		}
		Utils.ajax(url, 'GET', {}, $.proxy(function (result) {
			if (result.success) {
				this.configuration = result.data;
				$('#cw_next').prop('disabled', false).parent().addClass('hide');
				$('#cw_switch .cw_multi_toggle').addClass('is_readonly');
				this.agentsList.enable(false);
				this.assetGroupList.enable(false);
				this.setSave();
				this.setDetails(result.data);
				this.setCPU(result.data.cpu);
				this.setMemory(result.data.memory);

				this.diskView.setDisks(result.data.disk);
				this.sharedDiskView.setDisks(result.data.shares);

				this.setTime(result.data.time);
				this.setReboot(result.data.reboot);
				this.hideLoading('.cw_summary_content');
			} else {
				$('#cw_save').prop('disabled', true);
				this.actionSettingsNotification.setOptions({
					message: result.message,
					status: 'error'
				}).show();
			}
		}, this));
	},
	/**
	 * Handler event for the change event of the asset group list
	 * @param {Object} e The change object event
	 */
	onAssetGroupListChange: function (e) {
		var sender = e.sender;
		if (sender.value()) {
			this.setNext();
			this.initAssetListTwo(sender.value());
		} else {
			$('#cw_next').attr('disabled', true);
		}
	},
	/**
	 * Handler function called when clicking save button
	 */
	onSave: async function () {
		let initialName = this.name || this.data.name;
		let nameExists = await Utils.checkIfMonitorExists($('#cw_name').val().trim(), "SYSTEM", this.notification, initialName);
		if(nameExists) {
			return;
		}
		var data = {};
		if (this.mode === 'update') {
			data.id = this.id;
		} else {
			data.id = null;
		}
		if (this.isGroupSelected) {
			data.targetId = this.assetGroupList.value();
			data.targetType = 'ASSET_GROUP';
			data.configurationAssetId = this.assetListTwo.value() || this.configuration.configurationAssetId;
		} else {
			data.agentId = this.agentsList.value();
			data.targetId = this.assetId;
			data.targetType = 'ASSET';
			data.configurationAssetId = data.targetId;
		}
		data.name = $('#cw_name').val().trim();
		data.description = this.descriptionHandler.value().trim();
		data.type = 'SYSTEM';
		data.calculations = {
			longTrend: $('#cw_calculation_longtrend').is(':checked'),
			shortTrend: $('#cw_calculation_shorttrend').is(':checked'),
			triggerAdjust: $('#cw_calculation_trigger').is(':checked'),
			log: $('#cw_calculation_log').is(':checked'),
			averagingTimeInSeconds: parseInt($('#cw_calculation_average').val()) || 0//seconds
		};
		data.tags = this.tagsControl.getRawValues();
		data.monitorErrorSeverity = this.monitorError;
		data.reboot = {
			enabled: this.rebootSwitch.getValue(),
			rebootSeverity: this.rebootSeverity.value() ,
			timeoutHours:this.rebootHandler.find('#cw_reboot_timeout').val(),
		};
		// var eventEnabled = $('#cw_events_enable').is(':checked');
		// if (eventEnabled) {
		// 	data.event = {
		// 		enabled: true,
		// 		penalty: parseInt($('#cw_event_penalty').val()) || 0,
		// 		expiration: parseInt($('#cw_event_expiration').val()) || 0 //seconds
		// 	};
		// } else {
		// 	data.event = {
		// 		enabled: false,
		// 		penalty: this.configuration.event.penalty,
		// 		expiration: this.configuration.event.expiration
		// 	};
		// }
		if (this.cpuSwitch.getValue()) {
			data.cpu = {
				enabled: this.cpuSwitch.getValue(),
				cpuUsage: {
					enabled: this.cpuUsageSwitch.getValue(),
					severity: this.cpuPenalty.value(),
					warning: parseInt(this.cpuTriggerSlider.value()) || 0,
					critical: parseInt(this.cpuCriticalSlider.value()) || 0,
					projected: parseInt(this.cpuHandler.find('.cw_projected').val()) || 0,
					timeat: parseInt(this.cpuHandler.find('.cw_timeat').val()) || 0
				},
				cpuQueue: {
					enabled: this.cpuQueueSwitch.getValue(),
					severity: this.cpuQueuePenalty.value(),
					warning: parseInt(this.cpuQueueHandler.find('.cw_trigger').val()) || 0,
					critical: parseInt(this.cpuQueueHandler.find('.cw_critical').val()) || 0,
					projected: parseInt(this.cpuQueueHandler.find('.cw_projected').val()) || 0,
					timeat: parseInt(this.cpuQueueHandler.find('.cw_timeat').val()) || 0
				}
			};
		} else {
			data.cpu = this.configuration ? this.configuration.cpu || {} : {};
			data.cpu.enabled = false;
		}
		if (this.memorySwitch.getValue()) {
			data.memory = {
				enabled: true,
				useHyperVMaxMemory: $('#cw_enable_dynamic_memory').is(':checked'),
				memoryUsage: {
					enabled: this.memoryUsageSwitch.getValue(),
					severity: this.memoryPenalty.value(),
					warning: parseInt(this.memoryTriggerSlider.value()) || 0,
					critical: parseInt(this.memoryCriticalSlider.value()) || 0,
					projected: parseInt(this.memoryHandler.find('.cw_projected').val()) || 0,
					timeat: parseInt(this.memoryHandler.find('.cw_timeat').val()) || 0
				},
				pagefileUsage: {
					enabled: this.pagefileSwitch.getValue(),
					severity: this.pagefilePenalty.value(),
					warning: parseInt(this.pagefileTriggerSlider.value()) || 0,
					critical: parseInt(this.pagefileCriticalSlider.value()) || 0,
					projected: parseInt(this.pagefileHandler.find('.cw_projected').val()) || 0,
					timeat: parseInt(this.pagefileHandler.find('.cw_timeat').val()) || 0
				}
			};
		} else {
			data.memory = this.configuration ? this.configuration.memory || {} : {};
			data.memory.enabled = false;
		}


		// !!!

		data.disk = this.diskView.getData();
		data.shares = this.sharedDiskView.getData();

        if (this.timeSwitch.getValue()) {
            data.time = {
                enabled: this.timeSwitch.getValue(),
                ntpServer: $('#cw_ntp_server').val(),
                offset: {
                    enabled: this.offsetSwitch.getValue(),
                    severity: this.offsetPenalty.value(),
                    warning: parseInt(this.offsetHandler.find('.cw_trigger').val()) || 0,
                    critical: parseInt(this.offsetHandler.find('.cw_critical').val()) || 0,
                    projected: parseInt(this.offsetHandler.find('.cw_projected').val()) || 0,
                    timeat: parseInt(this.offsetHandler.find('.cw_timeat').val()) || 0
                },
                delay: {
                    enabled: this.delaySwitch.getValue(),
                    severity: this.delayPenalty.value(),
                    warning: parseInt(this.delayHandler.find('.cw_trigger').val()) || 0,
                    critical: parseInt(this.delayHandler.find('.cw_critical').val()) || 0,
                    projected: parseInt(this.delayHandler.find('.cw_projected').val()) || 0,
                    timeat: parseInt(this.delayHandler.find('.cw_timeat').val()) || 0
                }
            };
        } else {
            data.time = this.configuration ? this.configuration.time || {} : {};
            data.time.enabled = false;
        }

        //delete data.disk.isCustomized;
        var url = Settings.serverPath + 'accounts/' + this.accountContext + '/monitors/SYSTEM';
        Utils.ajax(url, 'POST', JSON.stringify(data), $.proxy(function (result) {
            if (result.success) {
                this.redirectView();
            } else {
                this.actionNotification.setOptions({
                    message: result.message,
                    status: 'error'
                }).show();
            }
        }, this));
	},
	/**
	 * Handler function called when clicking cancel button
	 */
	onCancel: function () {
        if (this.isFromAllGrid) {
            State.mainApp.loadModule('AssetHealthAllMonitors');
        } else {
            State.mainApp.loadModule('AssetHealthSystemMonitor');
        }
	},
	/*
	* Handler function for canceling the configuration
	* */
	onConfigurationCancel: function () {
		if (this.mode === 'create') {
			State.mainApp.loadModule('AssetHealthSystemMonitor');
		} else {
			$('#cw_next').prop('disabled', false).parent().addClass('hide');
		}
	},
	/**
	 * Handler function called when clicking next button
	 */
	onNext: function () {
		this.showLoading('.cw_summary_content');
		this.removeSettingsMask();
		$('.cw_calculations').removeClass('hide');
		//$('.cw_events').removeClass('hide');
		$('#cw_save').prop('disabled', false).parent().removeClass('hide');
		this.assetListTwo.enable(false);
		this.getConfiguration();
	},
	/**
	 * Handler function for setting button availability
	 */
	setNext: function () {
		if (this.mode !== 'create') {
			return;
		}

		if (
			this.agentsList.value() ||
			(this.assetGroupList.value() && this.assetListTwo.value())
		) {
			$('#cw_next').prop('disabled', false).parent().removeClass('hide');
		} else {
			$('#cw_next').attr('disabled', true);
		}
	},
	/*
	 * Handler function for emptying disks configuration
	 * */
	setEmptyDisksConfiguration: function () {
		$('.cw_system_disk').find('.cw_disk_configurations').empty();
	},
	/**
	 * Handler function for setting button availability
	 */
	setSave: function () {
		Utils.setPlaceholder($('#cw_name'), i('Please input name...'));
		Utils.setInvalidField($('#cw_name').val(), $('#cw_name'));
		Utils.setInvalidField(this.agentsList.value(), $('#cw_agent'), false, 'required_input');
		Utils.setInvalidField(this.assetGroupList.value(), $('#cw_asset_group'), false, 'required_input');
		Utils.setInvalidField(this.assetListTwo.value(), $('#cw_asset_two'), false, 'required_input');
		if ($('#cw_name').val() !== '' && ((this.agentsList && this.agentsList.value()) || (this.assetGroupList.value() && this.assetListTwo.value()))) {
			$('#cw_save').attr('disabled', false);
		} else {
			$('#cw_save').attr('disabled', true);
		}
	},
	/**
	 * Handler function for adding separator mask
	 */
	addSeparatorMask: function (selector) {
		selector.append('<div class="cw_mask" style="background-color:#dee8ec"></div>');
	},
	/**
	 * Handler function for adding settings mask
	 */
	addSettingsMask: function () {
		$('.cw_mask').removeClass('hide');
		$('.cw_cancel').addClass('hide');
		$('.cw_window_actions').find('#cw_save').addClass('hide');
	},
	/**
	 * Handler function for adding section mask
	 * @param selector {Object} jQuery selector for identifying the section
	 */
	addSectionMask: function (selector) {
		selector.find('.cw_section_content').append('<div class="cw_mask" style="background-color: rgba(255, 255, 255, .6); opacity:0.6;"></div>');
	},
	/**
	 * Handler function for hiding System Metrics ( CPU / MEMORY / DISK)
	 */
	hideSystemMetrics: function () {
		$('.cw_data').addClass('hide');
	},
	/**
	 * Handler function for hiding System Metrics ( CPU / MEMORY / DISK)
	 */
	showSystemMetrics: function () {
		$('.cw_data').removeClass('hide');
	},
	/**
	 * Handler function for removing section mask
	 * @param {Object} jQuery selector for identifying the section
	 */
	removeSystemMask: function (selector) {
		selector.find('.cw_mask').remove();
	},
	/**
	 * Handler function for removing settings mask
	 */
	removeSettingsMask: function () {
		$('.cw_mask').addClass('hide');
		$('.cw_side_settings').attr('style', '');
		$('.cw_side_settings').find('.actions').addClass('hide');
		$('.cw_cancel').removeClass('hide');
		$('.cw_window_actions').find('#cw_save').removeClass('hide');
	},
	/**
	 * Handler function for checking and setting the main cpu switch based on section switches
	 * */
	setCpuMainCheck: function () {
		if (this.mode === 'create') {
			var cpuSwitches = $('.cw_system_cpu').find('.cw_section_content').find('.cw_switch.is_checked');
			this.cpuSwitch.setValue(cpuSwitches.length ? true : false);
		}
	},
	/**
	 * Handler function for checking and setting the main memory switch based on section switches
	 * */
	setMemoryMainCheck: function () {
		if (this.mode === 'create') {
			var memorySwitches = $('.cw_system_memory').find('.cw_section_content').find('.cw_switch.is_checked');
			this.memorySwitch.setValue(memorySwitches.length ? true : false);
		}
	},
    /**
     * Handler function for checking and setting the main time switch based on section switches
     * */
    setTimeMainCheck: function () {
        if (this.mode === 'create') {
            var memorySwitches = $('.cw_system_time').find('.cw_section_content').find('.cw_switch.is_checked');
            this.timeSwitch.setValue(false);
        }
    },
	/*
	 * Handler function for showing loading indicator
	 * @param {String} target Element identifier
	 */
	showLoading: function (target) {
		var element = $(target);
		kendo.ui.progress(element, true);
	},
	/*
	 * Handler function for hiding loading indicator
	 * @param {String} target Element identifier
	 */
	hideLoading: function (target) {
		var element = $(target);
		kendo.ui.progress(element, false);
	},
	/*
	 * Handler function for redirecting after success save
	 */
	redirectView: function () {
		var successText = (this.mode === 'create' ? lang.assethealth.messages.MONITOR_SUCCESS_CREATED : lang.assethealth.messages.MONITOR_SUCCESS_UPDATED);
		State.mainApp.loadModule('AssetHealthSystemMonitor', '', {
			savedMonitorNotification: successText
		});
	},

	updateShareDiskTabState: function () {
		const tab = $('.cw_sdnav_shared_disk');

		if (this.systemType !== 'WINDOWS') {
			this.tabStrip.disable(tab);
			tab.attr('title', i('Only available for windows assets'))
		}
		else {
			this.tabStrip.enable(tab);
			tab.attr('title', '')
		}
	},

	/*
	* Handler function for asset two change event
	* @param {Object} e The change event object
	* */
	onAssetListTwoChange: function (e) {
		var sender = e.sender;
		if (this.mode === 'create') {
			if (sender.value()) {
				this.setNext();
				this.assetId = sender.value();
			} else {
				$('#cw_next').attr('disabled', true);
			}
		} else {
			if (sender.value()) {
				this.assetId = sender.value();
				this.getConfiguration();
			}
		}

		const item = sender.dataSource.get(sender.value())

		if (item) {
			this.systemType = item.systemType;
			this.updateShareDiskTabState();
		}
	},
	onBrowserResize: function () {
		var memoryHandler = $('.cw_memory');
		var memoryTriggerSlider = memoryHandler.find('input.cw_trigger').data('kendoSlider');
		var memoryCriticalSlider = memoryHandler.find('input.cw_critical').data('kendoSlider').resize();
		if (memoryTriggerSlider) {
			memoryTriggerSlider.resize();
		}
		if (memoryCriticalSlider) {
			memoryCriticalSlider.resize();
		}
		var pagefileHandler = $('.cw_pagefile');
		var pagefileTriggerSlider = pagefileHandler.find('input.cw_trigger').data('kendoSlider');
		var pagefileCriticalSlider = pagefileHandler.find('input.cw_critical').data('kendoSlider');
		if (pagefileTriggerSlider) {
			pagefileTriggerSlider.resize();
		}
		if (pagefileCriticalSlider) {
			pagefileCriticalSlider.resize();
		}
		var systemCpuHandler = $('.cw_system_cpu');
		var systemCpuTriggerSlider = systemCpuHandler.find('input.cw_trigger').data('kendoSlider');
		var systemCpuCriticalSlider = systemCpuHandler.find('input.cw_critical').data('kendoSlider');
		if (systemCpuTriggerSlider) {
			systemCpuTriggerSlider.resize();
		}
		if (systemCpuCriticalSlider) {
			systemCpuCriticalSlider.resize();
		}
		var diskUsageHandler = $('.cw_disk_usage');
		var diskUsageTriggerSlider = diskUsageHandler.find('input.cw_trigger').data('kendoSlider');
		var diskUsageCriticalSlider = diskUsageHandler.find('input.cw_critical').data('kendoSlider');
		if (diskUsageTriggerSlider) {
			diskUsageTriggerSlider.resize();
		}
		if (diskUsageCriticalSlider) {
			diskUsageCriticalSlider.resize();
		}
	}
});
