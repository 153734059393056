import { ApplicationState } from "./applicationState";
import {deserialize, serialize} from "serializr";
import {runInAction} from "mobx";
import UrlBuilder from "tools/urlBuilder";

export const serverRoot = '/rest/';

export declare type Constructor<T> = new (...args: any[]) => T;

export class ApiRequest<T> {
	url: string;
	payload?: any;
	method?: 'POST' | 'GET' | 'PUT';

	accountId?: string;
	accountBased?: boolean = false;

	includeSubaccounts: boolean;

	responseType?: Constructor<T>;

	public constructor(init?: Partial<ApiRequest<T>>) {
		Object.assign(this, init);
	}
}

export enum HttpStatus{
	Ok = 200,
	Unauthorized= 401,
	Forbidden = 403,
	InternalServerError= 500
}

export class ApiResponse<T> {
	data?: T;
	code?: number = 0;
	status: HttpStatus = HttpStatus.Ok;
	message?: string;

	get success(){
		return this.status == HttpStatus.Ok;
	}

	public constructor(init?: Partial<ApiResponse<T>>) {
		Object.assign(this, init);
	}
}

export async function apiFetch<TResult>(apiRequest: ApiRequest<TResult>): Promise<ApiResponse<TResult>> {
	let request = buildRequest(apiRequest);

	let response = await fetch(request);

	let result: ApiResponse<TResult>;

	if (!response.ok) {
		return new ApiResponse<TResult>({
			status: response.status
		});
	}

	let text = await response.text();
	try {
		const resultTemp = JSON.parse(text);

		if (resultTemp.success === false) {
			return new ApiResponse<TResult>({
				status: HttpStatus.InternalServerError,
				message: resultTemp.message
			});
		}

		if(resultTemp.success === true){
			resultTemp.status = HttpStatus.Ok;
			delete resultTemp.success;
		}

		if (resultTemp.data === undefined) { // in case response is just plain data
			return new ApiResponse<TResult>({
				data: resultTemp as TResult,
				status: HttpStatus.Ok
			})
		} else {
			if(apiRequest.responseType != null ) {
				runInAction( () => {
					resultTemp.data = deserialize(apiRequest.responseType, resultTemp.data);
				});
			}

			return new ApiResponse<TResult>(resultTemp);
		}
	} catch(e) {
		console.error(e);
		return new ApiResponse<TResult>({
			status: HttpStatus.InternalServerError,
			message: text,
		});
	}
}


function buildRequest<TResult>(apiRequest: ApiRequest<TResult>) {
	const init = {
		credentials: 'include' as const,
		headers: {
			"Content-Type": "application/json; charset=utf-8",
			"Auth-Token": ApplicationState.apiToken
		}
	} as RequestInit;

	if(apiRequest.payload){
		init.method = 'POST';
		let payload = apiRequest.payload;
		//we are checking if payload is configured for serialization and serialize it automatically
		if(payload.serializeInfo || payload.constructor?.serializeInfo){
			payload = serialize(payload);
		}
		init.body = JSON.stringify(payload);
	}

	if(apiRequest.method){
		init.method = apiRequest.method;
	}

	let url = ApplicationState.apiUrl;
	if (apiRequest.accountBased) {
		url += 'accounts/' + (apiRequest.accountId ?? ApplicationState.accountId) + '/';
	}

	url += apiRequest.url;

	if(apiRequest.includeSubaccounts != null){
		url = new UrlBuilder(url)
			.add("includeSubaccounts", apiRequest.includeSubaccounts)
			.build()
	}

	return new Request(url, init);
}

// async fetch(url, options, cache, key) {
// 	if(typeof url != 'string'){ //assuming that this is UrlBuilder
// 		url = url.build();
// 	}
// 	if( key == null && cache != null ){
// 		key = url;
// 	}
// 	if (cache != null && cache[key] != null)
// 		return cache[key];
//
// 	options = {
// 		credentials: 'include',
// 		headers: {
// 			"Content-Type": "application/json; charset=utf-8",
// 			"Auth-Token": Cookies.sessionId
// 		},
// 		throwOnError: false,
// 		...options
// 	};
//
// 	let response = await fetch(url, options);
// 	let result = null;
//
// 	if (!response.ok) {
// 		let text = await response.text();
// 		try {
// 			//Response can be JSON even if http code is not 200 OK
// 			result = JSON.parse(text);
// 		} catch {
// 			result = {
// 				success: false,
// 				message: text
// 			}
// 		}
// 	}else{
// 		if (options.plainText) {
// 			result = await response.text();
// 		} else {
// 			try {
// 				result = await response.json();
// 			} catch {
// 				result = {
// 					success: false,
// 					message: 'Cannot deserialize json'
// 				}
// 			}
// 		}
// 	}
//
// 	if (options.throwOnError) {
// 		if (result.success === false && !result.data) {
// 			console.log('Backend error: ' + result.message);
// 			throw Error(result.message);
// 		}
// 	}
//
// 	if (cache != null) {
// 		cache[key] = result;
// 	}
//
// 	return result;
// },
//
// fetchPost(url, data, options){
// 	return this.fetch(
// 		url,
// 		Object.assign({}, options, {
// 			method: 'POST',
// 			body: JSON.stringify(data)
// 		}));
// },
//
// fetchDelete(url, options = {}){
// 	return this.fetch(
// 		url,
// 		{
// 			...options,
// 			method: 'DELETE'
// 		}
// 	);
// },
