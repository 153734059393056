import React, { useMemo, useEffect, useRef } from "react";

import {useSelector} from "react-redux";
import {FormSection, reduxForm} from "redux-form";
import {formBuilderForm} from './constants';
import { getForms } from "./selectors/getForms";

import "./form.less"
import Form from "./form";
import { getItemValue } from "./selectors/getItemValue";
import { isWorkInProgress } from "./selectors/isWorkInProgress";
import ErrorMessage from 'controls/react/form/formBuilder/errorMessage';

import Overlay from './overlay'

function FormSections(props) {
    const ref = useRef();
    const forms = useSelector(getForms);
    const workInProgress = useSelector(isWorkInProgress);

    const aggregatedWorkInProgress = useMemo(() => {
        if (!workInProgress) {
            return false;
        }

        const plainWorkInProgress = workInProgress.toJS();

        for (const [key, value] of Object.entries(plainWorkInProgress)) {
            if (value) {
                return true;
            }
        }

        return false;
    }, [workInProgress])

    useEffect(() => {
        kendo.ui.progress($(ref.current), aggregatedWorkInProgress);
    }, [aggregatedWorkInProgress])

    if (!forms) {
        return null;
    }

    return <FormSection name="properties">
            <div className="qualifier-wizard" ref={ref}>
				<ErrorMessage />
				<Overlay />
                {forms.map((formGroups, index) => (
                    <Form formIndex={index} formGroups={formGroups} key={index} multipleLink={props.multipleLink}/>
                ))}
            </div>
        </FormSection>
}

export default reduxForm({ form: formBuilderForm, enableReinitialize: true })(FormSections);
