import State from 'tools/state';
import Settings from 'settings';
import Cookies from 'core/cookies';
import moment from 'moment-timezone';

/**
 * @class ceeview.management.RunScriptWindow
 * @namespace ceeview.assethealth
 * @constructor
 * @param {Object} config
 */
export default function RunScriptWindow (config) {
    Utils.apply(this, config);
    // initializing data inside this class
    this.initComponent();
};

RunScriptWindow.prototype = {
    /**
     * cfg {Array} agents The agents list as an array of objects with the name and id attributes
     */
    agents: [],

    /**
     * Main init function
     */
    initComponent: function() {
        var window = $('#modal').data('kendoWindow');

        window.bind("resize", () => this.onWindowResize());

        var modalHeight = $('#modal').parent().height() - 80;
        var template = '';

        this.groovyEditor = ace.edit('cw_run_script');
        this.groovyEditor.setTheme('ace/theme/crimson_editor');
        this.groovyEditor.getSession().setMode('ace/mode/groovy');
        this.groovyEditor.focus();
        this.groovyEditor.gotoLine(1);

        $('#cw_rs_execute').off().on('click', $.proxy(this.onExecute, this));
        $('#cw_rs_close').off().on('click', $.proxy(this.onClose, this));
        $('#modal').find('.form').height(modalHeight);
        $('.cw_clear_output').off().on('click', $.proxy(this.onClearOutput, this));

        if(State.runScriptCode) {
            this.groovyEditor.setValue(State.runScriptCode);
        }

        $(".cw_groovy_script").kendoSplitter({
            orientation: "horizontal",
            panes: [ { size: "70%" }, { size: "30%" } ],
            resize: () => this.onSplitterResize()
        });
    },

    onSplitterResize: function() {
        $('.actions').css('top', $('#modal').parent().height() - 50);
    },

    onWindowResize: function () {
        let height = $('#modal').parent().height();
        $('.cw_groovy_script').height(height - 100);
    },

    /**
     *  Handler function for the grid select event. Enables the select button.
     */
    onExecute: function() {
        //console.log(Cookies.CeesoftUserDateTimeFormat);

        var scriptCode = this.groovyEditor.getValue();
        State.runScriptCode = scriptCode;
        var url = Settings.serverPath + 'agents/runScript';
        var agentIds = [];
        for (var i=0; i< this.agents.length; i++){
            agentIds.push(this.agents[i].id);
        }
        var data = {
            script: scriptCode,
            agentIds: agentIds,
            ignoreEmptyHeaders: $('#cw_ignore_headers').is(':checked')
        };

        this.showSpinner();

        Utils.ajax(url, 'POST', JSON.stringify(data), $.proxy(function(result) {
            this.hideSpinner();
            if (!result.length) {
                return;
            }
            $('.cw_clear_output').attr('disabled', false);
            var output = '';
            moment.locale(Cookies.CeesoftUserLocale);

            for (var i = 0; i< result.length; i++) {
                if (!result[i].timestamp) {
                    result[i].timestamp = Date.now();
                }

                for (var j=0; j<this.agents.length; j++) {
                    if (result[i].agentId === this.agents[j].id) {
                        result[i].name = this.agents[j].name;
                    }
                }

                output += '---------------- '
                    + moment(result[i].timestamp).format(Renderer.parseDateFormat(Cookies.CeesoftUserDateTimeFormat))
                    + ' ----------------\n';
                output += result[i].name + '\n';
                output += '---------------------------------------------------\n';
                output += result[i].response + '\n' + '\n';
            }
            output = output + '\n' + $('.cw_run_script_results').val();

            $('.cw_run_script_results').val(output);
        }, this));
    },

    showSpinner: function () {
        var width = $('.cw_groovy_script').outerWidth();
        var height = $('.cw_groovy_script').outerHeight();

        $('.cw_overlay').height(height + 30);
        $('.cw_overlay').width(width + 30);
        $('.cw_overlay').css('display', 'flex');
    },

    hideSpinner: function () {
        $('.cw_overlay').css('display', 'none');
    },

    onClose: function() {
        var window = $('#modal').data('kendoWindow');
        window.close();
        window.destroy();

        if (this.groovyEditor) {
            this.groovyEditor.destroy()
        }
    },

    onClearOutput: function () {
		$('.cw_run_script_results').val('');
		$('.cw_clear_output').attr('disabled', true);
    }
};



