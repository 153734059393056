import React from 'react';
import ReactDOM from "react-dom";
import {Utils, applyRequired} from 'tools/utils';
import Cookies from 'core/cookies';
import Settings from 'settings';

import MultiToggle from 'controls/multiToggle';
import ErrorHandler from 'core/errorHandler';
import ConfigurationBase from 'areas/service-boards/configurationBase';
import {CeeViewDataSource} from "tools";
import {AntSelect} from "controls/react/ant/antSelect";
import FormEntry from "controls/react/form/formEntry";


export function GaugeWidgetConfiguration(config) {
	Utils.apply(this, config);
	this.initComponent();
};

export {GaugeWidgetConfiguration as default}

jQuery.extend(GaugeWidgetConfiguration.prototype, ConfigurationBase.prototype, {
	/**
	 * @cfg {Object} widgetObj
	 */
	/**
	 * Main init function
	 */
	initComponent: function () {
		var minValue;
		this.widgetObj.configuration.type = this.widgetObj.configuration.type || 'SERVICE';

		if (this.wasUpdate) {
			minValue = this.widgetObj.configuration.minValue || 0;
		} else {
			minValue = this.widgetObj.configuration.minValue || '';
		}
		this.widgetObj.configuration.minValue = minValue;
		this.widgetObj.configuration.maxValue = this.widgetObj.configuration.maxValue === undefined ? '' : this.widgetObj.configuration.maxValue;
		this.widgetObj.configuration.breachValue = this.widgetObj.configuration.breachValue === undefined ? '' : this.widgetObj.configuration.breachValue;

		var widgetObj = this.widgetObj;
		var inverted = widgetObj.configuration.inverted;
		var html = '';
		html += '<div class="cw_field">';
		html += '<label class="cw_inline">' + lang.TITLE + ': </label>';
		html += '<input type="text" class="cw_widget_title cw_inline" value="' + (widgetObj.title || '') + '" />';
		html += '</div>';
		html += '<div class="cw_field" id="cw_gauge_account">';
		html += '<label class="cw_inline">' + lang.ACCOUNT + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_gauge_accountid" type="text" value="' + (widgetObj.configuration.accountId || '') + '" /></div>';
		html += '</div>';
		html += '<div class="cw_field" id="cw_gauge_asset">';
		html += '<label class="cw_inline">' + lang.ASSET + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_gauge_assetid" type="text" value="' + (widgetObj.configuration.assetId || '') + '" /></div>';
		html += '</div>';
		html += '<div class="cw_field" id="cw_gauge_mon_type">';
		html += '<label class="cw_inline">' + lang.assethealth.MONITOR_TYPE + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_gauge_monitor_type" type="text" value="' + (widgetObj.configuration.monitorType || '') + '" /></div>';
		html += '</div>';
		html += '<div class="cw_field" id="cw_gauge_monitor">';
		html += '<label class="cw_inline">' + lang.assethealth.MONITOR_NAME + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_gauge_monitor_id" type="text" value="' + (widgetObj.configuration.monitorId || '') + '" /></div>';
		html += '</div>';
		html += '<div class="cw_field"><label class="cw_inline">' + lang.TYPE + ': </label><span id="cw_gauge_type"></span></div>';
		html += '<div class="cw_field cw_tooltip" data-title="' + lang.messages.GAUGE_MIN_VALUE + '" id="cw_gauge_min_field" class="' + (inverted ? "" : "hide") + '"><label class="cw_inline">' + lang.widget.MIN_VALUE + ': </label><input id="cw_gauge_min" type="text" value="' + widgetObj.configuration.minValue + '" /></div>';
		html += '<div class="cw_field cw_tooltip" data-title="' + lang.messages.GAUGE_MAX_VALUE + '"><label class="cw_inline">' + lang.widget.MAX_VALUE + ': </label><input id="cw_gauge_max" type="text" value="' + widgetObj.configuration.maxValue + '" /></div>';
		html += '<div class="cw_field cw_tooltip" data-title="' + lang.messages.GAUGE_BREACH_VALUE + '"><label class="cw_inline">' + lang.widget.BREACH_VALUE + ': </label><input id="cw_gauge_breach" type="text" value="' + widgetObj.configuration.breachValue + '" /></div>';
		html += '<div class="cw_field cw_tooltip" data-title="' + lang.messages.GAUGE_DATA_REPRESENTATION + '">';
		html += '<input type="checkbox" id="cw_gauge_invert" class="k-checkbox" ' + (inverted ? 'checked' : '') + ' />';
		html += '<label for="cw_gauge_invert" class="k-checkbox-label">' + lang.widget.INVERT + '</label>';
		html += '</div>';
		html += '<div class="cw_field">';
		html += '<span class="cw_inline_full">' + lang.LEGEND + ': </span>';
		html += '<input id="cw_gauge_legend" type="text" />';
		html += '</div>';

		this.widgetConfigurationFormEl.empty().append(html);
		this.gaugeType = new MultiToggle({
			id: 'cw_gauge_type',
			type: 'label',
			cssClass: 'cw_multi_toggle true',
			value: 'gauge',
			items: [{
				id: 'cw_toggle_gauge',
				title: lang.GAUGE,
				label: lang.GAUGE,
				selected: this.mode === 'update' || this.wasUpdate ? (widgetObj.configuration.gaugeType === 'gauge' ? true : false) : true,
				fn: this.onGaugeTypeToggle,
				scope: this,
				value: 'gauge'
			}, {
				id: 'cw_toggle_solidgauge',
				title: lang.SOLID_GAUGE,
				label: lang.SOLID_GAUGE,
				selected: this.mode === 'update' || this.wasUpdate ? (widgetObj.configuration.gaugeType === 'solidgauge' ? true : false) : false,
				fn: this.onGaugeTypeToggle,
				scope: this,
				value: 'solidgauge'
			}]
		});

		this.legendDataSource = [{
			name: '<Account>',
			id: '<Account>'
		}, {
			name: '<Asset>',
			id: '<Asset>'
		}, {
			name: '<MonitorType>',
			id: '<MonitorType>'
		}, {
			name: '<Monitorname>',
			id: '<Monitorname>'
		}];

		if (this.mode === 'update' || this.wasUpdate) {
			if (!widgetObj.configuration.labelTemplate) {
				this.legendMultiList = [];
			} else {
				this.legendMultiList = widgetObj.configuration.labelTemplate;
			}
		}
		this.renderLegendMultiSelect();
		this.initKendoComponents();

		const requiredFields = ['#cw_gauge_accountid', '#cw_gauge_assetid', '#cw_gauge_monitor_type', '#cw_gauge_monitor_id', '#cw_gauge_min', '#cw_gauge_max', '#cw_gauge_breach'];
		applyRequired(requiredFields);
	},
	renderLegendMultiSelect: function() {
		ReactDOM.render(<FormEntry label={lang.LEGEND} vertical style={{maxWidth: '600px'}}>
			<AntSelect
				dataList={this.legendDataSource}
				mode={"multiple"}
				skipSorting={true}
				onChange={value => this.onLegendChange(value)}
				defaultValue={this.legendMultiList || []}
				placeholder={lang.SELECT} />
		</FormEntry>,  $('#cw_gauge_legend').parent().get()[0]);
	},
	onLegendChange: function(value) {
		this.legendMultiList = value || [];
	},
	/**
	 * Iniitalizes kendo components
	 */
	initKendoComponents: function () {
		var widgetObj = this.widgetObj;

		this.createAccountComponent({
			element: $('#cw_gauge_accountid'),
			container: $('#cw_gauge_account'),
			nextElement: $('#cw_gauge_sid, #cw_gauge_assetid'),
			otherElements: [$('#cw_gauge_monitor_type'), $('#cw_gauge_monitor_id')],
			events: {
				dataSourceChange: $.proxy(function (e, length) {
					this.emptyComponent({
						containers: [{
							container: $('#cw_gauge_asset'),
							label: lang.ASSET
						}, {
							container: $('#cw_gauge_mon_type'),
							label: lang.assethealth.MONITOR_TYPE
						}, {
							container: $('#cw_gauge_monitor'),
							label: lang.assethealth.MONITOR_NAME
						}],
						length: length
					});
				}, this)
			}
		});

		$('#cw_gauge_assetid').kendoComboBox({
			dataSource: new CeeViewDataSource({
				change: $.proxy(function (e) {
					this.emptyComponent({
						containers: [{
							container: $('#cw_gauge_mon_type'),
							label: lang.assethealth.MONITOR_TYPE
						}, {
							container: $('#cw_gauge_monitor'),
							label: lang.assethealth.MONITOR_NAME
						}],
						length: e.items.length
					});
				}, this),
				transport: {
					read: {
						url: $.proxy(function () {
							return Settings.serverPath + 'accounts/' + (this.widgetAccountId || widgetObj.configuration.accountId) + '/assets/lite';
						}, this),
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				error: ErrorHandler.kendoServerError
			}),
			dataTextField: 'name',
			dataValueField: 'id',
			select: $.proxy(this.onGaugeAssetSelect, this),
			template: '<span id="${ data.id }">${ data.name }</span>',
			enable: (this.mode === 'update' || this.wasUpdate) ? true : false,
			autoBind: (this.mode === 'update' || this.wasUpdate) ? true : false,
			//autoBind: false,
			change: $.proxy(function (e) {
				var eraseError = true;
				this.errorFields = Utils.comboBoxValueCheck(e, eraseError);
			}, this),
			dataBound: $.proxy(function (e) {
				if (this.mode === 'update' || this.wasUpdate) {
					if (!this.isAccountChanged) {
						this.errorFields = Utils.comboBoxValueCheck(e);
					}
				}
				if (this.isAccountChanged) {
					Utils.focusNextComboBox(e);
				}
			}, this),
			// text: lang.SELECT,
			placeholder: lang.SELECT
		}).data('kendoComboBox');

		if (this.mode === 'update' || this.wasUpdate) {
			if (widgetObj.configuration.accountId && widgetObj.configuration.assetId) {
				var monitorTypesDataSource = new CeeViewDataSource({
					change: $.proxy(function (e) {
						this.emptyComponent({
							containers: [{
								container: $('#cw_gauge_monitor'),
								label: lang.assethealth.MONITOR_NAME
							}],
							length: e.items.length
						});
					}, this),
					transport: {
						read: {
							url: Settings.serverPath + 'accounts/' + widgetObj.configuration.accountId + '/assets/' + widgetObj.configuration.assetId + '/health/monitors/types/',
							contentType: "application/json; charset=utf-8",
							type: "GET",
							dataType: "json",
							cache: false
						}
					},
					error: ErrorHandler.kendoServerError
				});
			}
		}

		$('#cw_gauge_monitor_type').kendoComboBox({
			dataSource: (this.mode === 'update' || this.wasUpdate) ? monitorTypesDataSource : [],
			dataTextField: 'text',
			dataValueField: 'value',
			select: $.proxy(this.onGaugeMonitorTypeSelect, this),
			template: '<span data-value="${ data.value }">${ data.text }</span>',
			enable: (this.mode === 'update' || this.wasUpdate) ? true : false,
			// text: lang.SELECT,
			placeholder: lang.SELECT,
			autoBind: (this.mode === 'update' || this.wasUpdate) ? true : false,
			//autoBind: true,
			change: $.proxy(function (e) {
				var eraseError = true;
				this.errorFields = Utils.comboBoxValueCheck(e, eraseError);
			}, this),
			dataBound: $.proxy(function (e) {
				if (this.mode === 'update' || this.wasUpdate) {
					if (!this.isAssetSelected) {
						this.errorFields = Utils.comboBoxValueCheck(e);
					}
				}
				if (this.isAssetSelected) {
					Utils.focusNextComboBox(e);
				}
			}, this)
		}).data('kendoComboBox');

		if (this.mode === 'update' || this.wasUpdate) {
			if (widgetObj.configuration.accountId && widgetObj.configuration.assetId && widgetObj.configuration.monitorType) {
				var monitorsDataSource = new CeeViewDataSource({
					transport: {
						read: {
							url: Settings.serverPath + 'accounts/' + widgetObj.configuration.accountId + '/assets/' + widgetObj.configuration.assetId + '/health/monitors/types/' + widgetObj.configuration.monitorType + '/lite',
							contentType: "application/json; charset=utf-8",
							type: "GET",
							dataType: "json",
							cache: false
						}
					},
					error: ErrorHandler.kendoServerError
				});
			}
		}

		$('#cw_gauge_monitor_id').kendoComboBox({
			dataSource: (this.mode === 'update' || this.wasUpdate) ? monitorsDataSource : [],
			dataTextField: 'name',
			dataValueField: 'id',
			select: $.proxy(this.onGaugeMonitorSelect, this),
			template: '<span id="${ data.id }">${ data.name }</span>',
			enable: (this.mode === 'update' || this.wasUpdate) ? true : false,
			// text: lang.SELECT,
			placeholder: lang.SELECT,
			autoBind: (this.mode === 'update' || this.wasUpdate) ? true : false,
			change: $.proxy(function (e) {
				var eraseError = true;
				this.errorFields = Utils.comboBoxValueCheck(e, eraseError);
			}, this),
			dataBound: $.proxy(function (e) {
				if (this.mode === 'update' || this.wasUpdate) {
					if (!this.isMonitorTypeSelected) {
						this.errorFields = Utils.comboBoxValueCheck(e);
					}
				}
				if (this.isMonitorTypeSelected) {
					Utils.focusNextComboBox(e);
				}
			}, this)
		}).data('kendoComboBox');


		this.minValue = $('#cw_gauge_min').kendoNumericTextBox({
			format: 'n0',
			spinners: false
		}).data('kendoNumericTextBox');

		this.maxValue = $('#cw_gauge_max').kendoNumericTextBox({
			format: 'n0',
			spinners: false
		}).data('kendoNumericTextBox');

		this.breachValue = $('#cw_gauge_breach').kendoNumericTextBox({
			format: 'n0',
			spinners: false
		}).data('kendoNumericTextBox');

		$("#cw_gauge_startdate").kendoDateTimePicker({
			format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
			timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat)
		});
		$("#cw_gauge_enddate").kendoDateTimePicker({
			format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
			timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat)
		});
		$('#cw_gauge_timezone').kendoDropDownList({
			dataSource: new CeeViewDataSource({
				transport: {
					read: {
						url: Settings.serverPath + 'sessions/timezones/?addUserTime=true',
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				error: ErrorHandler.kendoServerError
			}),
			autoBind: true
		});
	},
	/**
	 * Handler function for the click event on qualifier type toggle
	 * @param {String} value The associated value of the clicked item
	 */
	onPeriodToggle: function (value) {
		if (value === 'CUSTOM') {
			$('#cw_gauge_dates').removeClass('hide');
		} else {
			$('#cw_gauge_dates').addClass('hide');
		}
	},
	/**
	 * Handler function for the click event on the ignore missing data chckbox
	 * @param {type} e
	 */
	onIgnoreCheckbox: function (e) {
		var checkbox = $(e.currentTarget);
		checkbox.toggleClass('check unchecked');
	},
	/**
	 * Handler function for the select event of the metrics asset combobox
	 * @param {Object} e The select event
	 */
	onGaugeAssetSelect: function (e) {
		if (e.item && e.item.hasClass('k-state-hover')) {
			var monitorTypeCombo = $("#cw_gauge_monitor_type").data('kendoComboBox');
			var monitorCombo = $("#cw_gauge_monitor_id").data('kendoComboBox');
			var assetId = e.item.find('span').attr('id');
			if (assetId) {
				this.isAssetSelected = true;
				this.isInputChanged = true;
				monitorTypeCombo.enable(true);
				monitorTypeCombo.value('');
				var newMonitorTypeDataSource = new CeeViewDataSource({
					change: $.proxy(function (e) {
						this.emptyComponent({
							containers: [{
								container: $('#cw_gauge_mon_type'),
								label: lang.assethealth.MONITOR_TYPE
							}, {
								container: $('#cw_gauge_monitor'),
								label: lang.assethealth.MONITOR_NAME
							}],
							length: e.items.length
						});
					}, this),
					transport: {
						read: {
							url: Settings.serverPath + 'accounts/' + this.widgetAccountId + '/assets/' + assetId + '/health/monitors/types/',
							contentType: "application/json; charset=utf-8",
							type: "GET",
							dataType: "json",
							cache: false
						}
					},
					error: ErrorHandler.kendoServerError
				});
				monitorTypeCombo.setDataSource(newMonitorTypeDataSource);
				monitorTypeCombo.dataSource.read();
				monitorCombo.text('');
				monitorCombo.enable(false);
			} else {
				monitorTypeCombo.text('');
				monitorTypeCombo.enable(false);
				monitorCombo.text('');
				monitorCombo.enable(false);

			}
		}
	},
	/**
	 * Handler function for the select event of the metrics monitor type combobox
	 * @param {Object} e The select event
	 */
	onGaugeMonitorTypeSelect: function (e) {
		if (e.item && e.item.hasClass('k-state-hover')) {
			var monitorCombo = $("#cw_gauge_monitor_id").data('kendoComboBox');
			var monitorType = e.item.find('span').attr('data-value');
			var assetId = $("#cw_gauge_assetid").data('kendoComboBox').value();
			if (monitorType) {
				this.isMonitorTypeSelected = true;
				this.isInputChanged = true;
				monitorCombo.enable(true);
				monitorCombo.value('');
				var newMonitorDataSource = new CeeViewDataSource({
					change: $.proxy(function (e) {
						this.emptyComponent({
							containers: [{
								container: $('#cw_gauge_monitor'),
								label: lang.assethealth.MONITOR_NAME
							}],
							length: e.items.length
						});
						/*if (e.items.length === 1) {
						 var select = function () {
						 //select first element
						 var item = monitorCombo.ul.children().eq(0);
						 monitorCombo.select(0);
						 monitorCombo.trigger('select', {
						 item: item
						 });
						 };
						 setTimeout(select, 300);
						 }*/
					}, this),
					transport: {
						read: {
							url: Settings.serverPath + 'accounts/' + this.widgetAccountId + '/assets/' + assetId + '/health/monitors/types/' + monitorType + '/lite',
							contentType: "application/json; charset=utf-8",
							type: "GET",
							dataType: "json",
							cache: false
						}
					},
					error: ErrorHandler.kendoServerError
				});
				monitorCombo.setDataSource(newMonitorDataSource);
				monitorCombo.dataSource.read();
			} else {
				monitorCombo.text('');
				monitorCombo.enable(false);
			}
		}
	},
	/**
	 * Handler function for the select event of the metrics monitor combobox
	 * @param {Object} e The select event
	 */
	onGaugeMonitorSelect: function (e) {
		var monitorId = e.item.find('span').attr('id');
		var assetId = $("#cw_gauge_assetid").data('kendoComboBox').value();
		//var monitorId = $("#cw_gauge_monitor_id").data('kendoDropDownList').value();

	},
	findRecord: function (dataSource, id) {
		var record = null;
		var length = dataSource.total();
		for (var i = 0; i < length; i++) {
			var r = dataSource.at(i);
			if (r.id === id) {
				record = r;
				break;
			}
		}
		return record;
	},
	/*
	 * Handler function for getting the widget configuration values
	 * */
	getValues: function () {
		var title = $('.cw_widget_title').val().trim();
		var widgetObj = {
			id: this.mode === 'update' || this.wasUpdate ? this.widgetObj.id : Utils.guid(),
			type: 'asset_gauge',
			title: title,
			configuration: {
				accountId: this.widgetAccountId || $('#cw_gauge_accountid').data('kendoComboBox').value(),
				gaugeType: this.gaugeType.getValue(),
				inverted: $('#cw_gauge_invert').is(':checked'),
				minValue: parseFloat($('#cw_gauge_min').val() || 0),
				breachValue: parseFloat($('#cw_gauge_breach').val()),
				maxValue: parseFloat($('#cw_gauge_max').val()),
				labelTemplate: this.legendMultiList
			}
		};

		var assetCombo = $('#cw_gauge_assetid').data('kendoComboBox');
		var assetId = assetCombo.value();
		var assetName = assetCombo.text();
		var monitorTypeCombo = $('#cw_gauge_monitor_type').data('kendoComboBox');
		var monitorType = monitorTypeCombo.value();
		var monitorTypeName = monitorTypeCombo.text();
		var monitorCombo = $('#cw_gauge_monitor_id').data('kendoComboBox');
		var monitorId = monitorCombo.value();
		var monitorName = monitorCombo.text();

		var config = {
			assetId: assetId,
			assetName: assetName,
			monitorType: monitorType,
			monitorTypeName: monitorTypeName,
			monitorId: monitorId,
			monitorName: monitorName
		};

		jQuery.extend(widgetObj.configuration, config);
		return widgetObj;
	},
	isValid: function () {
		var valid = true;
		var validationMessage = '';
		var min = $('#cw_gauge_min').val();
		var breach = $('#cw_gauge_breach').val();
		var max = $('#cw_gauge_max').val();
		var inverted = $('#cw_gauge_invert').is(':checked');

		var accountCombo = $('#cw_gauge_accountid').data('kendoComboBox');
		var assetCombo = $('#cw_gauge_assetid').data('kendoComboBox');
		var monitorTypeCombo = $('#cw_gauge_monitor_type').data('kendoComboBox');
		var monitorCombo = $('#cw_gauge_monitor_id').data('kendoComboBox');

		if (!Utils.isGuid(accountCombo.value())) {
			valid = false;
			validationMessage = lang.widget.SELECT_ACCOUNT;
		}
		if (!Utils.isGuid(monitorCombo.value())) {
			valid = false;
			validationMessage = lang.widget.SELECT_MONITOR;
		}
		if (!monitorTypeCombo.value()) {
			valid = false;
			validationMessage = lang.widget.SELECT_MONITOR_TYPE;
		}
		if (!Utils.isGuid(assetCombo.value())) {
			valid = false;
			validationMessage = lang.widget.SELECT_ASSET;
		}
		if (inverted && (isNaN(min) || min.trim() === '')) {
			valid = false;
			validationMessage = lang.widget.messages.FILL_IN_NUMERIC_MIN;
		}
		if (isNaN(breach) || breach.trim() === '') {
			valid = false;
			validationMessage = lang.widget.messages.FILL_IN_NUMERIC_BREACH;
		}
		if (isNaN(max) || max.trim() === '') {
			valid = false;
			validationMessage = lang.widget.messages.FILL_IN_NUMERIC_MAX;
		}
		if (!isNaN(max) && !isNaN(breach)) {
			max = parseFloat(max);
			breach = parseFloat(breach);
			if (max < breach) {
				valid = false;
				validationMessage = lang.widget.messages.BREACH_VALUE_MAX;
			}
			if (inverted && !isNaN(min)) {
				if (max <= min) {
					valid = false;
					validationMessage = lang.widget.messages.MIN_VALUE_MAX;
				}
			}
		}
		if (this.errorFields && this.errorFields.length) {
			valid = false;
			if (this.errorFields.length > 1) {
				validationMessage = lang.widget.SELECT_MISSING_FIELDS;
			} else {
				validationMessage = lang.widget.SELECT_MISSING_FIELD;
			}
		}

		return {
			valid: valid,
			validationMessage: validationMessage
		};
	}
});
