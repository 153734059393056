import ReactDOM from 'react-dom';
import React from 'react';

import DocumentationButton from "./documentationButton";

export {DocumentationButtonWrapper, DocumentationButtonWrapper as default}

class DocumentationButtonWrapper {
	constructor(config) {
		Object.assign(this, config);
		this.initComponent();
	}

	initComponent() {
		ReactDOM.render(<DocumentationButton monitorType={this.monitorType}>
		</DocumentationButton>, $(this.appendToElement).get(0));
	}
}
