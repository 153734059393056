const addFormConfig = {
	'standalone': true,
	'configuration': {
		'forms': [
			{
				'id': 'grpGeneral',
				'components': [
					'name',
					'authId',
					'server',
					'sharePath',
				]
			}
		],
		'properties': [
			{
				'type': 'CETextInput',
				'caption': 'Name',
				'dependsOn': null,
				'id': 'name',
				'required': true,
				'visible': true,
				'createOnly': false
			},
			{
				'type': 'CETextInput',
				'caption': 'Server',
				'dependsOn': null,
				'id': 'server',
				'required': true,
				'visible': true,
				'createOnly': false
			},
			{
				"type": "CEAuthentication",
				"caption": "Authentication",
				"dependsOn": null,
				"id": "authId",
				"required": true,
				"visible": true,
				"createOnly": false,
				"authTypes": [
					"WMI", "GENERIC"
				],
			},
			{
				'type': 'CETextInput',
				'caption': 'Path',
				'dependsOn': null,
				'id': 'sharePath',
				'required': true,
				'visible': true,
				'createOnly': false
			},
		],
		'values': {},
	},
}

export default addFormConfig;
