import React from 'react';
import PropTypes from 'prop-types';
import 'rsuite-table/lib/less/index.less';
import withDataSource from './withDataSource'
import {LoadingState, TableContext} from './constants';
import './custom.less'
import withReducer from './withReducer';
import IndexCell from './indexCell';
import RHeaderCell from './headerCell';
import withSelection from './withSelection';
import withComponentLoaded from "controls/react/rsuite/withComponentLoaded";
import CommonCell from 'controls/react/rsuite/commonCell';
import {withPlugins} from "controls/react/rsuite/withPlugins";
import {VARS} from "controls/react/rsuite/reducer";
import classnames from 'classnames'
import withReset from './withReset';

function useRenderColumns(columns, draggable, Column, HeaderCell, Cell, singleMetricSelection, onResize) {
	const {state} = React.useContext(TableContext);

	return React.useMemo(() => {
		return columns.map((column) => {
			const filter =  (state[VARS.FILTERS] || {}).hasOwnProperty(column.field);
			const filterBy =  (state[VARS.FILTER_BY] || []).includes(column.field);
			return <Column {...column} minWidth={column.width} key={column.field} fixed={column.locked && "left"} onResize={onResize}>
				<HeaderCell className={classnames({['filtered']: filter || filterBy})}>
					<RHeaderCell dataKey={column.field} column={column} singleMetricSelection={singleMetricSelection}/>
				</HeaderCell>
				{column.index ? <IndexCell dataKey={column.field} draggable={draggable} singleMetricSelection={singleMetricSelection} />: <CommonCell dataKey={column.field} column={column} />}
			</Column>
		})
	}, [columns, state[VARS.FILTER_BY]])
}

const defaultRowHeight = 46;

function RTable(props) {
	const {Table, Column, HeaderCell, Cell, infinite} = props;

	const columns = useRenderColumns(props.columns, props.draggable, Column, HeaderCell, Cell, props.singleMetricSelection, props.onResizeColumn);

	const isLoading = props.loadingState === LoadingState.LOADING;

	const previousScroll = React.useRef({
		x: 0,
		y: 0
	});

	const handleScroll = React.useCallback((x, y) => {
		const previousY = previousScroll.current.y;

		previousScroll.current.x = x;
		previousScroll.current.y = y;

		if (Math.abs(previousY - y) < 1) {
			return;
		}

		let contextHeight = props.data.length * props.rowHeight;
		let top = Math.abs(y);

		if (contextHeight - top - props.height < 200) {
			infinite && props.onNextPage();
		}
	}, [props.data, props.height]);
	
	return <div>
		<Table
			headerHeight={props.headerHeight}
			rowHeight={props.rowHeight}
			virtualized={true}
			shouldUpdateScroll={props.currentPage === 0}
			height={props.height}
			data={props.data}
			onScroll={handleScroll}
			loading={isLoading}
			affixHorizontalScrollbar >
			{columns}
		</Table>
	</div>;
}

// loading={isLoading}

RTable.propTypes = {
	loadingState: PropTypes.string.isRequired,
	data: PropTypes.any,
	onNextPage: PropTypes.func.isRequired,
	onResizeColumn: PropTypes.func,

	columns: PropTypes.array.isRequired,
	height: PropTypes.number,
	headerHeight: PropTypes.number,
	rowHeight: PropTypes.number,

	draggable: PropTypes.bool,
	infinite: PropTypes.bool
};

RTable.defaultProps = {
	headerHeight: 65,
	height: 400,
	rowHeight: defaultRowHeight,
	draggable: false,
	infinite: true
}

export default withReducer(
	withReset(
		withSelection(
			withDataSource(
				withComponentLoaded(
					withPlugins(
						RTable
					)
				)
			)
		)
	)
);
