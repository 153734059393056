import {CustomNotification, Dialog, GridMenu, PreferencesPanel, Switch} from 'controls';
import {Api, CeeViewDataSource, DataSourceSeverity, State, Utils} from 'tools';
import DiskSwitch from 'areas/assets/monitors/system/diskView/diskSwitch';
import DiskGrid from 'areas/assets/monitors/system/diskView/diskGrid';
import DiskFilterPanel from 'areas/assets/monitors/system/diskView/diskFilterPanel';
import DiskMenu from 'areas/assets/monitors/system/diskView/diskMenu';
import UnknownInstancesSwitch from 'areas/assets/monitors/system/diskView/unknownInstancesSwitch';
import updateInstances from 'areas/assets/monitors/system/diskView/updateInstances';
import updateMetrics from 'areas/assets/monitors/system/diskView/updateMetrics';
import DiskDefaultConfigGrid from 'areas/assets/monitors/system/diskView/diskDefaultConfigGrid';
import {Settings} from 'settings';
import getCustomConfigGridDataSource from 'areas/assets/monitors/system/diskView/getCustomConfigGridDataSource';
import getDiskStatus from 'areas/assets/monitors/system/diskView/getDiskStatus';
import './diskView.less';
import ReactDOM from 'react-dom';
import React from 'react';
import AddForm from './addForm';
import DiskCustomConfigGrid from 'areas/assets/monitors/system/diskView/diskCustomConfigGrid';
import translator from 'core/localization';
import {DISK_VIEW} from "areas/assets/monitors/system/shared";

const i = translator({
	'Shared disk information': {
		no: 'Delt disk informasjon'
	},
	'Connected to ': {
		no: 'Tilkoblet til '
	},
	'Not able to connect to ': {
		no: 'Klarte ikke å koble til '
	}
});

function stripSlash(value) {
	return ['/', '\\'].includes(value[0]) ? value.substring(1): value;
}

export default class DiskView {
	diskSwitch = null;
	diskGrid = null;

	constructor(type, mode, getContextVarsFunc) {
		this.type = type;
		this.prefix = type === DISK_VIEW.SHARED_DISK ? 'shared_' : '';
		this.mode = mode;
		this.getContextVarsFunc = getContextVarsFunc;
		this.customDisks = [];
		this.disksDeleted = [];
	}

	createDisksBox(removeSystemMaskCb, addSectionMaskCb) {
		this.diskSwitch = new DiskSwitch(this.prefix, this.mode).create(removeSystemMaskCb, addSectionMaskCb);
		this.diskGrid = new DiskGrid(this.type, this.prefix, this.mode, this.onEdit.bind(this), this.onShareAdd.bind(this)).create();
		this.diskFilterPanel = new DiskFilterPanel(this.prefix, this.mode).create(this.diskGrid);
		this.diskMenu = new DiskMenu(this.type, this.prefix, this.mode).create(
			this.onDiskReload.bind(this),
			this.onDiskMonitor.bind(this),
			this.onDiskConfigRemove.bind(this),
			this.onDiskAssetRemove.bind(this),
			this.onShareAdd.bind(this),
			this.onShareDelete.bind(this),
			this.onShareEdit.bind(this)
		);
		this.unknownInstancesSwitch = new UnknownInstancesSwitch(this.prefix, this.mode).create();
	}

	async handleTest(values) {
		const context = this.getContextVarsFunc();

		const url = `${Api.accountRoot(context.accountContext)}monitors/SYSTEM/testShare`;

		const params = this.mode === 'create' ? {
			'agentId': context.agentId,
			'targetId': context.targetId,
			'targetType': context.targetType,
			'configurationAssetId': context.configurationAssetId,

			'authId': values.authId,
			'server': values.server,
			'sharePath': values.sharePath,
			'timeoutSeconds': 120
		} : {
			'monitorId': context.id,
			'authId': values.authId,
			'server': values.server,
			'sharePath': values.sharePath,
			'timeoutSeconds': 120
		}

		const data = await Api.fetchPost(url, params)

		if (data.success) {
			const statusNotification = new CustomNotification({
				appendToElement: this.div,
				status: 'success',
				type: 'icon',
				icon: 'glyphicons ok-circle',
				// style: 'top:15px; right:15px; left:15px;',
				hideOnClick: true
			});
			statusNotification.setOptions({
				message: i('Connected to ') + values.server + '/' + stripSlash(values.sharePath),
			}).show();
		} else {
			const statusNotification = new CustomNotification({
				appendToElement: this.div,
				status: 'error',
				type: 'icon',
				icon: 'glyphicons exclamation-sign',
				hideOnClick: true
			});
			statusNotification.setOptions({
				message: data.message,
			}).show();
		}
	}

	onShareEdit(e) {
		const checkboxes = $(`.cw_${this.prefix}disk_list .cw_grid_check:checked`);
		const checkbox = $(checkboxes[0]);
		const uid = checkbox.closest('tr').data('uid');
		const item = this.diskGrid.dataSource.getByUid(uid);

		this.showEditWindow(uid, item);
	}

	onEdit(e) {
		const uid = $(e.currentTarget).closest('tr').data('uid');
		const item = this.diskGrid.dataSource.getByUid(uid);

		this.showEditWindow(uid, item);
	}

	showEditWindow(uid, item) {
		this.createDialogWindow();

		this.div = document.createElement('div');

		ReactDOM.render(
			<AddForm uid={uid} values={item.toJSON()} onSave={this.handleSave.bind(this)}
					 onClose={this.handleClose.bind(this)} onTest={this.handleTest.bind(this)}/>,
			this.div
		);

		this.window.content(this.div);
		this.window.center().open();
	}

	onShareDelete() {
		const checkboxes = $(`.cw_${this.prefix}disk_list .cw_grid_check:checked`);

		for (let i = 0, length = checkboxes.length; i < length; i++) {
			const checkbox = $(checkboxes[i]);
			const uid = checkbox.closest('tr').data('uid');
			const item = this.diskGrid.dataSource.getByUid(uid);
			this.diskGrid.dataSource.remove(item);
		}
	}

	createDialogWindow() {
		this.windowNodeId = Utils.guid();
		this.selector = '#' + this.windowNodeId;
		if (!$(this.selector).length) {
			$('body').append('<div id="' + this.windowNodeId + '" class="disk-view__window"></div>');
		}

		$(this.selector).kendoWindow({
			width: '400px',
			height: '230px',
			title: i('Shared disk information'),
			modal: true,
			visible: false,
			close: () => {
				ReactDOM.unmountComponentAtNode(this.div);
				$(this.selector).data('kendoWindow').destroy();
			}
		}).data('kendoWindow');

		this.window = $(this.selector).data('kendoWindow');
	}

	onShareAdd() {
		this.createDialogWindow();

		this.div = document.createElement('div');

		ReactDOM.render(
			<AddForm values={{}} onSave={this.handleSave.bind(this)} onClose={this.handleClose.bind(this)}
					 onTest={this.handleTest.bind(this)}/>,
			this.div
		);

		this.window.content(this.div);
		this.window.center().open();
	}

	handleClose() {
		this.window.destroy();
	}

	handleSave(values, uid) {
		console.log('values', values, uid)

		if (uid) {
			const item = this.diskGrid.dataSource.getByUid(uid);

			Object.keys(values).forEach((key) => {
				item.set(key, values[key]);
			});
		} else {
			this.diskGrid.dataSource.add({
				status: 'default',
				'enabled': true,
				'metrics': [],
				'name': values.name,
				'authId': values.authId,
				'server': values.server,
				'sharePath': values.sharePath
			});
		}

		this.handleClose();
	}

	showLoading(target) {
		kendo.ui.progress($(target), true);
	}

	hideLoading(target) {
		kendo.ui.progress($(target), false);
	}

	onDiskReload() {
		this.showLoading('.cw_disk_list');

		const {accountContext, assetId, actionDrivesNotification} = this.getContextVarsFunc();

		var url = Settings.serverPath + 'accounts/' + accountContext + '/monitors/SYSTEM/systemConfiguration/rediscover?assetId=' + assetId;

		Utils.ajax(url, 'GET', {}, (result) => {
			if (!result.success) {
				actionDrivesNotification.setOptions({
					message: result.message,
					status: 'error'
				}).show();
				return;
			}

			var instances = [], guid, length, searchItem, status;
			var diskData = this.diskGrid.dataSource.data(), diskLength = diskData.length;

			if (result.data.disk && result.data.disk.instances) {
				instances = result.data.disk.instances;
				length = instances.length;
			}
			//parse new instances(disks) and add to grid only if are not found
			for (var i = 0; i < length; i++) {
				searchItem = Utils.getFromJsonArray(diskData, 'name', instances[i].name);
				if (searchItem) {
					if (!instances[i].present) {
						searchItem.status = 'missing';
					}
				} else {
					guid = Utils.guid();
					if (this.unknownInstancesSwitch.getValue()) {
						status = getDiskStatus(instances[i]);
					} else {
						status = 'none';
					}

					this.diskGrid.dataSource.add({
						id: guid,
						enabled: false,
						name: instances[i].name,
						status: status
					});
				}
			}

			//parse only if we have instances to see if any instances were removed
			// and remove them from configuration
			if (length) {
				var diskId, item;
				for (var j = 0; j < diskLength; j++) {
					searchItem = Utils.getFromJsonArray(instances, 'name', diskData[j].name);
					if (!searchItem) {
						diskId = diskData[j].id;
						$('#' + diskId).remove();
						item = this.diskGrid.dataSource.get(diskId);
						item.status = 'missing';
					}
				}
			}
			this.diskGrid.refresh();

			actionDrivesNotification.setOptions({
				message: result.message,
				status: 'success'
			}).show();

			this.hideLoading('.cw_disk_list');
		});
	}

	onDiskMonitor(e) {
		var target = $(e.currentTarget), checkboxes = $(`.cw_${this.prefix}disk_list .cw_grid_check:checked`), checkbox,
			uid, item;
		var customFunction = {};
		var newCustomDisk;
		switch (target.attr('id')) {
			case 'cw_default_monitoring':
				customFunction = (disk) => {
					disk.status = 'default';
					disk.enabled = true;

					const currentCustomGridData = this.diskCustomConfigGrid.dataSource.data().toJSON();

					for (var i = 0; i < currentCustomGridData.length; i++) {
						if (currentCustomGridData[i].driveName === disk.name) {
							currentCustomGridData.splice(i, 1);
							i--;
						}
					}
					var newDataSource = getCustomConfigGridDataSource(currentCustomGridData);
					this.diskCustomConfigGrid.setDataSource(newDataSource);
					var diskGridItems = this.diskGrid.dataSource.data();
					for (var j = 0; j < diskGridItems.length; j++) {
						if (diskGridItems[j].name === disk.name) {
							diskGridItems[j].metrics = [];
						}
					}
					var index = this.customDisks.indexOf(disk.name);
					if (index !== -1) {
						this.customDisks.splice(index, 1);
					}
				};
				break;
			case 'cw_custom_monitoring':
				customFunction = (disk) => {
					disk.status = 'custom';
					disk.enabled = true;
					if (this.customDisks.indexOf(disk.name) === -1) {
						const currentCustomGridData = this.diskCustomConfigGrid.dataSource.data().toJSON();

						//var diskItems = this.diskGrid.dataSource.data().toJSON();
						var diskItems = this.diskGrid.dataSource.data();
						for (var i = 0; i < diskItems.length; i++) {
							if (disk.name === diskItems[i].name) {
								var existingMetrics = diskItems[i].metrics;
								if (existingMetrics.length) {
									newCustomDisk = diskItems[i].metrics;
								} else {
									newCustomDisk = this.diskDefaultConfigGrid.dataSource.data().toJSON();
									diskItems[i].metrics = newCustomDisk;
								}
							}
						}
						for (var j = 0; j < newCustomDisk.length; j++) {
							newCustomDisk[j].driveName = disk.name;
						}
						var newCustomGridData = currentCustomGridData.concat(newCustomDisk);
						var newDataSource = getCustomConfigGridDataSource(newCustomGridData);
						this.diskCustomConfigGrid.setDataSource(newDataSource);
						this.customDisks.push(disk.name);
					}
				};
				break;
			case 'cw_non_monitoring':
				customFunction = (disk) => {
					disk.status = 'none';
					disk.enabled = false;

					const currentCustomGridData = this.diskCustomConfigGrid.dataSource.data().toJSON();

					for (var i = 0; i < currentCustomGridData.length; i++) {
						if (currentCustomGridData[i].driveName === disk.name) {
							currentCustomGridData.splice(i, 1);
							i--;
						}
					}
					var newDataSource = getCustomConfigGridDataSource(currentCustomGridData);
					this.diskCustomConfigGrid.setDataSource(newDataSource);
					var diskGridItems = this.diskGrid.dataSource.data();
					for (var j = 0; j < diskGridItems.length; j++) {
						if (diskGridItems[j].name === disk.name) {
							diskGridItems[j].metrics = [];
						}
					}
					var index = this.customDisks.indexOf(disk.name);
					if (index !== -1) {
						this.customDisks.splice(index, 1);
					}
				};
				break;
		}
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			checkbox = $(checkboxes[i]);
			uid = checkbox.closest('tr').data('uid');
			item = this.diskGrid.dataSource.getByUid(uid);
			customFunction(item);
		}
		this.diskGrid.refresh();
		$(`.cw_${this.prefix}disk_list .cw_grid_check_all`).prop('checked', false);
	}

	onDiskConfigRemove() {
		var dialog = new Dialog({
			title: lang.INFO,
			msg: lang.messages.ACTION_REMOVE_DISKS,
			icon: 'INFO',
			actionText: 'OK',
			fn: (value, button) => {
				if (button !== 'ok') {
					return;
				}
				var checkboxes = $('.cw_disk_list').find('.cw_grid_check:checked');
				//var checkboxes = $('.cw_grid_check:checked');
				var length = checkboxes.length;
				var uid, item;
				for (var i = 0; i < length; i++) {
					uid = $(checkboxes[i]).closest('tr').data('uid');
					item = this.diskGrid.dataSource.getByUid(uid);
					this.diskGrid.dataSource.remove(item);
					var currentCustomGridData = this.diskCustomConfigGrid.dataSource.data().toJSON();
					for (var j = 0; j < currentCustomGridData.length; j++) {
						if (currentCustomGridData[j].driveName === item.name) {
							currentCustomGridData.splice(j, 1);
							j--;
						}
					}
					var newDataSource = getCustomConfigGridDataSource(currentCustomGridData);
					this.diskCustomConfigGrid.setDataSource(newDataSource);
					this.disksDeleted.push({
						deleted: true,
						name: item.name
					});
				}
			}
		});
		dialog.show();
	}

	onDiskAssetRemove() {
		var item, uid;
		var checkboxes = $('.cw_grid_check:checked');

		const {accountContext, assetId, actionDrivesNotification} = this.getContextVarsFunc();

		var url = Settings.serverPath + 'accounts/' + accountContext + '/monitors/SYSTEM/systemConfiguration/deleteDisk?assetId=' + assetId + '&disk=';

		for (var i = 0; i < checkboxes.length; i++) {
			uid = $(checkboxes[i]).closest('tr').data('uid');
			item = this.diskGrid.dataSource.getByUid(uid);
			url += item.name;
		}
		Utils.ajax(url, 'GET', {}, $.proxy(function (result) {
			if (result.success) {
				actionDrivesNotification.setOptions({
					message: lang.monitor.system.REMOVE_DISK_ASSET,
					status: 'success'
				}).show();
			} else {
				actionDrivesNotification.setOptions({
					message: result.message,
					status: 'error'
				}).show();
			}
		}, this));
	}

	setDisks(data) {
		const instances = data.instances;
		const length = instances.length;
		let guid;
		let status;

		this.diskSwitch.setValue(data.enabled).trigger('change');

		this.unknownInstancesSwitch.setValue(data.enableUnknownInstances).trigger('change');
		this.diskMenu.enableItem('cw_reload');

		this.setDiskSettings(data);

		const disks = instances.map((instance) => ({
			...instance,
			id: Utils.guid(),
			status: getDiskStatus(instance)
		}))

		this.diskGrid.dataSource.data(disks);
	}

	setDiskSettings(defaultConfiguration) {
		defaultConfiguration = updateMetrics(defaultConfiguration);
		defaultConfiguration = updateInstances(defaultConfiguration);

		this.diskDefaultConfigGrid = new DiskDefaultConfigGrid(this.type, this.prefix, this.mode, this.onShareAdd.bind(this)).create(defaultConfiguration);
		this.diskCustomConfigGrid = new DiskCustomConfigGrid(this.type, this.prefix, this.mode).create(defaultConfiguration, this.customDisks);


		// $('.cw_numeric_input').on('keydown', $.proxy(Utils.onInputDigitRestrict, this));

		this.diskCustomGridSearchPanel = new PreferencesPanel({
			searchInput: $(`#cw_${this.prefix}custom_grid_search`),
			grid: this.diskCustomConfigGrid,
			searchFields: ['driveName', 'name', 'grouping']
		});
	}

	getData() {
		var diskMetricItems = this.diskDefaultConfigGrid?.dataSource.data().toJSON() || [];
		var diskItems = this.diskGrid.dataSource.data().toJSON();
		var diskCustomItems = this.diskCustomConfigGrid?.dataSource.data().toJSON() || [];

		for (var a = 0; a < diskItems.length; a++) {
			diskItems[a].metrics = [];
			var currentDiskName = diskItems[a].name;
			for (var b = 0; b < diskCustomItems.length; b++) {
				if (currentDiskName === diskCustomItems[b].driveName) {
					diskItems[a].metrics.push(diskCustomItems[b])
				}
			}
		}

		for (var l = 0; l < diskMetricItems.length; l++) {
			if (diskMetricItems[l].enabled) {
				if (diskMetricItems[l].metricOnly) {
					diskMetricItems[l].metricOnly = false;
				} else {
					diskMetricItems[l].metricOnly = true;
				}
			}
		}
		for (var j = 0; j < diskItems.length; j++) {
			for (var k = 0; k < diskItems[j].metrics.length; k++) {
				if (diskItems[j].metrics[k].enabled) {
					if (diskItems[j].metrics[k].metricOnly) {
						diskItems[j].metrics[k].metricOnly = false;
					} else {
						diskItems[j].metrics[k].metricOnly = true;
					}
				}
			}
		}
		for (var l = 0; l < diskMetricItems.length; l++) {
			delete diskMetricItems[l].severityColor;
			delete diskMetricItems[l].severityText;
		}
		for (var j = 0; j < diskItems.length; j++) {
			delete diskItems[j].id;
			delete diskItems[j].status;
			for (var k = 0; k < diskItems[j].metrics.length; k++) {
				delete diskItems[j].metrics[k].driveName;
				delete diskItems[j].metrics[k].severityColor;
				delete diskItems[j].metrics[k].severityText;
			}
		}
		if (this.disksDeleted.length) {
			diskItems = diskItems.concat(this.disksDeleted);
			this.disksDeleted = [];
		}

		return {
			enableUnknownInstances: this.unknownInstancesSwitch.getValue(),
			metrics: diskMetricItems,
			instances: diskItems,
			enabled: this.diskSwitch.getValue()
		};
	}

	applyAdvanced(showAdvanced) {
		if (showAdvanced) {
			this.diskDefaultConfigGrid.showColumn('projected');
			this.diskCustomConfigGrid.showColumn('projected');
		} else {
			this.diskDefaultConfigGrid.hideColumn('projected');
			this.diskCustomConfigGrid.hideColumn('projected');
		}
	}
}
