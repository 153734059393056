export function handleOneRowSelection(grid, props) {
	return (ids) => {
		if (!props.oneRowSelection) {
			return;
		}

		if (ids.length === 1 || (ids.sender && props.selectedItems && props.selectedItems.length === 1)) {
			grid.wrapper.find('.cw_grid_check:not(:checked)').attr('disabled', true)
		}
		else {
			grid.wrapper.find('.cw_grid_check').attr('disabled', false)
		}
	}
}
