import Element from './element';
import Api from 'tools/api';
import {SlaApi} from 'api';
import Cookies from 'core/cookies';
import State from 'tools/state';
import Utils from 'tools/utils';
import Settings from 'settings';
import {SlaRouter} from 'areas/sla/bundleDescription';
import {translator} from "../../core/localization/localization";

const i = translator({
	"SLA: {0}": {
		"no": "SLA: {0}"
	},
});


export default class Sla extends Element {
	slas = [];
	slaIds = [];
	indicators = {};

	constructor(designer, cell) {
		super(designer, cell);

		this.slaIds = this.datasource.slas;
	}

	getEntriesToLoad() {
		return this.slaIds.map(x => ({
			slaId: x.id,
			accountId: x.accountId
		}));
	}

	onEntriesLoaded(entries) {
		this.slas = entries;
	}

	updateState() {
		if(this.slas.length < this.slaIds.length)
			return;

		const aggregatedPriority = this.slas.reduce( (currentMin, x) =>
			Math.min(indicatorToPriority(x.indicator), currentMin), 100 );

		this.setState(PRIORITY_TO_STATE[aggregatedPriority]);
	}

	getSubscriptions(subscriptions) {
		return {
			slas: this.slaIds.map(x => x.id)
		};
	}

	consumeEvent(event) {
		if (event.eventType != 'Sla')
			return false;

		const sla = this.slas.find( x=> x.slaId == event.calculation.slaId);
		if(sla && sla.indicator != event.calculation.indicator){
			sla.indicator = event.calculation.indicator;
			return true;
		}
	}

	getTooltipInternal(accountName) {
		if (this.slas?.length == 0)
			return null;

		return i('SLA: {0}', this.slas.map(x => x.slaName).join(", "));
	}

	redirect() {
		var readUrl = Settings.serverPath + 'accounts/' + this.accountId + '/slas/' + this.slaIds[0].id + '/serviceId';
		Utils.ajax(readUrl, 'GET', {}, $.proxy(function (result) {
			if (result.success) {
				var serviceId = result.data;
				State.mainApp.navigate(SlaRouter.details(this.slaIds[0].id));
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	}

	empty(){
		return this.slaIds.length == 0;
	}

	getLabel(){
		if(this.cell.getDatasource()?.showAsLabel){
			return this.slas[0]?.slaName;
		}

		return null;
	}
}


const INDICATOR_TO_PRIORITY = {
	'OK': 3,
	'WARNING': 2,
	'FAILED': 1
};

const PRIORITY_TO_STATE = [
	'INVALID',
	'ERROR',
	'WARNING',
	'ACTIVE'
];

function indicatorToPriority(indicator) {
	return INDICATOR_TO_PRIORITY[indicator] || 0;
}
