import {Renderer} from 'tools';
import {getTooltipMetrics, getTooltipMetricY} from 'areas/service-boards/widgets/customMetrics/chartDataStore';
import Utils from 'tools/utils'

export default function formatter(e) {
	const metricIds = getTooltipMetrics(this.point.x);
	const tooltip = [];

	for (const metricId of metricIds) {
		const series = e.chart.series.find((item) => item.userOptions.id === metricId);
		const {userOptions} = series;
		let y = getTooltipMetricY(metricId, this.point.x);
		if (userOptions.decimals) {
			y = Utils.truncateDecimals(y, userOptions.decimals);
		}

		if (!userOptions.customUnit) {
			tooltip.push(userOptions.name + ': ' + y + ' ' + (userOptions.unitTypeSymbol || ''));
		} else {
			tooltip.push(userOptions.name + ': ' + y + ' ' + userOptions.customUnit);
		}
	}

	return Renderer.browserDateRenderer(this.point.x, "datetime", '') + '<br/>' + tooltip.join('<br/>');

	// console.log(metricIds);
	//
	// const point = {
	// 	point: this.point,
	// 	series: this.point.series,
	// 	y: this.point.y
	// };
	//
	// let tooltip = [];
	//
	// var y = point.y;
	//
	// const userOptions = point.series.userOptions;
	//
	// if (!point.series.options.customUnit || point.series.options.customUnit === '') {
	// 	tooltip.push(point.series.options.fullName + ': ' + y + ' ' + (point.series.options.unitTypeSymbol || ''));
	// } else {
	// 	tooltip.push(point.series.options.fullName + ': ' + y + ' ' + point.series.options.customUnit);
	// }
	//
	// var s = Renderer.browserDateRenderer(this.x, "datetime", '', oThis.instanceConfiguration.timezone) + '<br/>' + tooltip.join('<br/>');
}
