import {Application, LocalEventsManager, Cookies, RemoteEventsManager, ErrorHandler} from 'core';
import {
	CustomNotification, Dependencies,
	Dialog,
	EventsToolbar,
	GridMenu,
	ModalView,
	ModalWindow,
	MultiToggle,
	PreferencesPanel
} from 'controls';
import {Utils, UserPrefs, State} from 'tools';
import Settings from 'settings';
import UserForm from './userForm';
import {UserRouter} from "./users/bundleDescription";
import MultiSelectGridFilter from 'controls/multiSelectGridFilter';
import ReactDOM from 'react-dom';
import React from 'react';
import DocumentationButton from 'controls/react/form/documentationButton';

export let UsersView = function (config) {
	Utils.apply(this, config);
	this.hasEvents = true;
	this.subscriberId = Utils.guid();
	this.initComponent();
};

export default UsersView;

jQuery.extend(UsersView.prototype, Application.prototype, {
	/**
	 * Users view application related initializations
	 */
	initComponent: function () {
		Application.prototype.initComponent.call(this);


		this.actionNotification = new CustomNotification({
			appendToElement: '.window_area',
			status: 'success',
			style: 'top:15px; right:15px; left:15px;',
		});

		this.defaultColumns = {
			id: {
				hidden: false,
				width: 40
			},
			username: {
				hidden: false,
				width: 260
			},
			userCategoryName: {
				hidden: false,
				width: 200
			},
			firstName: {
				hidden: false,
				width: 200
			},
			lastName: {
				hidden: false,
				width: 200
			},
			email: {
				hidden: false,
				width: 400
			},
			authType: {
				hidden: false,
				width: 200
			},
			lockUser: {
				hidden: false,
				width: 150
			},
			hasNotificationPolicy: {
				hidden: false,
				width: 150
			}
		};
		this.loadUserPreferences();

		// update content button
		State.mainApp.contextMenu.setActionButton('USER');
		this.cardGridToggle = new MultiToggle({
			id: 'cw_users_view_toggle',
			cssClass: 'cw_multi_toggle right',
			value: 'false',
			items: [{
				id: 'cw_grid_view',
				title: lang.GRID_VIEW,
				selected: false,
				cssClass: 'glyphicons justify',
				fn: this.onViewToggle,
				scope: this,
				value: 'true'
			}, {
				id: 'cw_card_view',
				title: lang.CARD_VIEW,
				selected: true,
				cssClass: 'glyphicons show-big-thumbnails',
				fn: this.onViewToggle,
				scope: this,
				value: 'false'
			}]
		});
		this.gridMenu = new GridMenu({
			renderTo: 'cw_users_grid_menu',
			items: [{
				id: 'cw_create',
				icon: 'plus-sign',
				text: lang.CREATE,
				fn: State.mainApp.onNewUser,
				scope: State.mainApp,
				role: 'USER_CREATE'
			}, {
				id: 'cw_delete',
				icon: 'bin',
				text: lang.DELETE,
				fn: this.onUserDelete,
				scope: this,
				disabled: true,
				role: 'USER_DELETE'
			}]
		});

		this.renderDocumentationButton();
		this.removeMask();
		this.removeListeners();
		this.attachListeners();
	},
	/**
	 * Handler for the view toggle buttons click
	 * @param {Object} e The click event
	 */
	onViewToggle: function (value) {
		if (value === 'true') {
			if (this.grid && this.gridViewLoaded) {
				this.showGridView = true;
				$('#cw_users_list').addClass('hide');
				$('#cw_users_grid').removeClass('hide');
				this.grid.refresh();
			} else {
				this.initGridView();
				this.grid.refresh();
			}
		} else {
			$('#cw_users_grid').addClass('hide');
			if (this.innerView) {
				this.showGridView = false;
				$('#cw_users_list').removeClass('hide');
				//$('#cw_filter_reset').addClass('inactive');
			} else {
				this.initCardView();
			}
		}
	},
	/**
	 * Removes the listeners for the form's buttons
	 */
	removeListeners: function () {
		$('#users_grid').off();
		this.onUserSavedHandler = $.proxy(this.onUserSaved, this);
		LocalEventsManager.unbind('usersaved', this.onUserSavedHandler);
	},
	/**
	 * Attaches the listeners for the form's buttons
	 */
	attachListeners: function () {
		$('#users_grid').on('click', '.cw_grid_check', $.proxy(this.onCheck, this));
		$('#users_grid').on('click', '.cw_grid_check_all', $.proxy(this.onCheckAll, this));
		$('#users_grid').on('click', '.cw_grid_link', $.proxy(this.onUserNameClick, this));
		LocalEventsManager.bind('usersaved', this.onUserSavedHandler);
	},
	/**
	 * Init card component
	 */
	initCardView: function () {
		var deleteClass = State.mainApp.session.hasRole('USER_DELETE') ? '' : 'hide';
		var updateClass = State.mainApp.session.hasRole('USER_UPDATE') ? '' : 'hide';
		/*var template = "<li><div class='cw_card' id='${id}'><div class='cw_card_avatar'>";
		 template += "# if (pictureId && State.mainApp.session.hasRole('USER_READ')) { #<div class='cw_avatar no_bg'><img src='";
		 template += Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId;
		 template += "/users/${id}/picture/${pictureId}?_dc=${pictureId}&nocache=${Math.random()}";
		 template += "' /> </div>#} else {#<div class='cw_avatar cw_avatar_user'></div>#}#</div>";
		 */
		var template = "<li><div class='cw_card' id='${id}'>";
		template += "           <div class='cw_card_avatar'>";
		template += "			# if (State.mainApp.session.hasRole('USER_READ')){ ";
		template += "				if (Utils.isGuid(pictureId)) {#";
		template += "					<div class='cw_avatar cw_avatar_user no_bg'>";
		template += "						<img src='" + Settings.serverPath + "sessions/" + Cookies.sessionId + "/accounts/" + Cookies.CeesoftCurrentAccountId + "/users/${id}/picture/${pictureId}?_dc=${pictureId}&nocache=${Math.random()}' />";
		template += "					</div>";
		template += "				#} else if(pictureId) { #";
		template += "					<div class='cw_avatar cw_avatar_user no_bg'><span class='glyphicons ${pictureId}'></span></div>";
		template += "				#} else {#";
		template += "					<div class='cw_avatar cw_avatar_user'></div>";
		template += "				#}";
		template += "			} else { #";
		template += "				<div class='cw_avatar cw_avatar_user'></div>";
		template += "			#}#";
		template += "			</div>";
		template += "			<div class='cw_card_content'><h2 class='card_title'># if (firstName) { #";
		template += "${firstName}#}## if (lastName) { # ${lastName}</h2>#}#";
		if (State.mainApp.session.hasRole('USER_READ')) {
			template += "<p class='card_extra'><strong>";
			template += lang.USERNAME;
			template += ":</strong> ${username}</p><p class='card_extra'><strong>";
			template += lang.EMAIL;
			template += ":</strong> # if (email) { #${email}#}#</p>";
		}
		template += "</div><div class='cw_card_options'><span class='glyphicons bin ";
		template += deleteClass;
		template += "'></span><span class='glyphicons pencil ";
		template += updateClass;
		template += "'></span></div></div></li>";
		this.showGridView = false;
		$('#cw_users_view_toggle').find('li').last().addClass('is_selected');

		var self = this;
		// create users view
		this.innerView = new ModalView({
			autoLoad: true,
			createButtonText: lang.account.USER_NEW,
			viewDataSource: this.dataSource,
			template: template,
			id: 'cw_users_list',
			cssClass: 'users_ul',
			title: lang.USERS,
			pager: true,
			onCreateButton: function (e) {
				if (State.mainApp.session.hasRole('USER_CREATE')) {
					var modalWindow = new ModalWindow({
						title: lang.account.USER_CREATE,
						width: 480,
						height: 632,
						url: 'include/Administration/UserForm.jsp',
						refresh: function () {
							new UserForm({
								id: null,
								mode: 'create'
							});
						}
					});
					modalWindow.open();
				}
			},
			onEditButton: function (e) {
				if (State.mainApp.session.hasRole('USER_UPDATE')) {
					var userId = e.currentTarget.parentNode.parentNode.id;
					State.mainApp.loadModule('AdministrationUserDetails', userId, {
						mode: 'update',
						id: userId
					}, e);
				}
			},
			onDeleteButton: function (selector) {
				if (State.mainApp.session.hasRole('USER_DELETE')) {
					var users = [];
					var id = selector.currentTarget.parentNode.parentNode.id;
					var dialog = new Dialog({
						title: lang.INFO,
						msg: lang.account.messages.USER_REMOVE_CONFIRMATION,
						icon: 'ERROR',
						scope: this,
						actionText: 'DELETE',
						fn: function (value, button) {
							if (button === 'ok') {
								users.push(id);
								self.userIndex = 0;
								self.usersLength = users.length;
								self.selectedUsers = users;

								self.checkUserDependencies();
							}
						}
					});
					dialog.show();
				}
			}
		});
		//$('#cw_filter_reset').addClass('inactive');
		//$('#cw_users_grid').addClass('hide');
		setTimeout(function () {
			$('#cw_users_list').removeClass('hide');
		}, 300);
	},
	/*
	 * Handler function for checking dependencies
	 * */
	checkUserDependencies: function () {
		var userId = this.selectedUsers[this.userIndex++], record, userName, cb;
		if (userId) {
			cb = this.grid.element.find('.cw_grid_check:checked');
			record = this.grid.dataSource.get(userId);

			this.selectedUserId = userId;
			userName = record.username;

			var dependenciesUrl = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/users/' + this.selectedUserId + '/dependencies';
			Utils.ajax(dependenciesUrl, 'GET', {}, $.proxy(function (data) {
				if (data.length) {
					this.openDependenciesPopup(data, userName, userId);
					setTimeout(this.adjustSectionHeight, 100);
				} else {
					var deleteUrl = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/users/' + userId;
					Utils.ajax(deleteUrl, 'DELETE', {}, $.proxy(function (result) {
						if (result.success) {
							if (this.innerView) {
								this.innerView.viewDataSource.read();
							}
							this.checkUserDependencies();
							this.userDeleted = true;
						} else {
							this.resetCheckingUsers();
							Utils.showInfo(lang.ALERT, result.message, result.details);
						}
					}, this));
				}
			}, this));
		} else {
			if (this.innerView) {
				this.innerView.viewDataSource.read();
			}
			if (this.dependenciesWindow) {
				this.dependenciesWindow.close();
			}
			if (this.userDeleted) {
				if (this.grid) {
					this.grid.dataSource.read();
				}
				this.userDeleted = false;
				this.actionNotification.setOptions({
					message: lang.account.messages.USER_SUCCESS_DELETED,
					status: 'success'
				}).show();
			}
		}
	},
	/**
	 * Resets the checking users
	 */
	resetCheckingUsers: function () {
		this.selectedUsers = [];
		this.userIndex = 0;
	},
	/**
	 * Creates and opens the user dependencies window
	 *
	 * @param {Array} dependencies - The user dependencies array
	 * @param {String} userName The user name
	 * @param {String} userId The id of the user
	 */
	openDependenciesPopup: function (dependencies, userName, userId) {
		if (this.dependencyWindow) {
			this.dependenciesWindow.close();
		}
		this.dependenciesWindow = new ModalWindow({
			title: lang.INFO,
			width: 960,
			height: 640,
			minWidth: 220,
			actions: [],
			url: 'include/Dependencies.jsp',
			onClose: $.proxy(function () {
				this.clearCheckboxes();
			}, this),
			refresh: $.proxy(function () {
				this.dependencyWindow = new Dependencies({
					accountId: Cookies.CeesoftCurrentAccountId,
					dependencies: dependencies,
					name: userName,
					infoTemplate: lang.account.messages.USER_DELETE_INFO
				});
			}, this)
		});
		this.dependenciesWindow.open();
	},
	/*
	* Handler function for clearing all checkboxes
	* */
	clearCheckboxes: function () {
		var checkAll = $('#users_grid').find('.cw_grid_check_all'),
			checkboxes = $('#users_grid').find('.cw_grid_check'), length = checkboxes.length;
		checkAll.prop('checked', false);
		for (var i = 0; i < length; i++) {
			$(checkboxes[i]).prop('checked', false);
		}
	},
	/**
	 * Init grid component
	 * @param {boolean} showGrid Defaults to true
	 */
	initGridView: function (showGrid) {
		var filterMessages = lang.grid.filter,
			loadGrid = true;
		this.gridMessages = {
			clear: lang.CLEAR,
			info: lang.grid.filter.SHOW_ITEMS,
			filter: lang.FILTER
		};
		if (showGrid === false) {
			loadGrid = false;
			this.gridViewLoaded = false;
		} else {
			this.gridViewLoaded = true;
		}
		if (loadGrid) {
			this.showGridView = true;
			$('#cw_users_view_toggle').find('li').first().addClass('is_selected');
			$('#cw_filter_reset').removeClass('hide');
			$('#cw_users_list').addClass('hide');
			$('#cw_users_grid').removeClass('hide');
		}

		var usersColumns = this.usersColumns || JSON.parse(UserPrefs.get('usersColumns')) || this.defaultColumns;
		usersColumns = Utils.completeColumns(usersColumns, this.defaultColumns);

		let scope = this;
		this.grid = $('#users_grid').kendoCustomGrid({
			dataSource: this.dataSource,
			resizable: true,
			reorderable: true,
			selectable: 'row',
			autoBind: true,
			sortable: {
				mode: 'multiple',
				allowUnsort: true
			},
			filterable: {
				extra: false,
				operators: {
					string: {
						startswith: filterMessages.STARTS_WITH,
						neq: filterMessages.NEQ,
						eq: filterMessages.EQ
					}
				},
				messages: this.gridMessages
			},
			columns: Utils.rearrangeColumns([{
				field: 'id',
				title: lang.SELECTOR,
				sortable: false,
				filterable: false,
				menu: false,
				template: '<input type="checkbox" class="cw_grid_check" data-id="#= id #" />',
				headerTemplate: '<input type="checkbox" class="cw_grid_check_all" />',
				headerAttributes: {
					'class': 'text_center'
				},
				attributes: {
					'class': 'text_center'
				},
				hidden: usersColumns.id.hidden,
				width: usersColumns.id.width
			}, {
				field: 'username',
				title: lang.USERNAME,
				sortable: true,
				filterable: true,
				template: '<a class="cw_grid_link ellipsis to_expand" data-id="#= id #">#= username #</a>',
				hidden: usersColumns.username.hidden,
				width: usersColumns.username.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'userCategoryName',
				title: lang.USER_CATEGORY,
				sortable: true,
				filterable: true,
				template: '#= userCategoryName #',
				hidden: usersColumns.userCategoryName.hidden,
				width: usersColumns.userCategoryName.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'firstName',
				title: lang.FIRST_NAME,
				sortable: true,
				filterable: true,
				hidden: usersColumns.firstName.hidden,
				width: usersColumns.firstName.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'lastName',
				title: lang.LAST_NAME,
				sortable: true,
				filterable: true,
				hidden: usersColumns.lastName.hidden,
				width: usersColumns.lastName.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'email',
				title: lang.EMAIL,
				sortable: true,
				filterable: true,
				hidden: usersColumns.email.hidden,
				width: usersColumns.email.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'authType',
				title: lang.TYPE,
				sortable: true,
				filterable: true,
				hidden: usersColumns.authType.hidden,
				width: usersColumns.authType.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'lockUser',
				title: lang.ENABLED,
				sortable: true,
				filterable: {
					operators: {
						string: {
							eq: filterMessages.ISIN,
							neq: filterMessages.ISNOTIN
						}
					},
					ui: element => {
						let multiselect = new MultiSelectGridFilter({
							element: element,
							field: 'lockUser',
							grid: scope.grid,
							dataSource: [{
								text: lang.ACTIVE,
								value: false
							}, {
								text: lang.INACTIVE,
								value: true
							}],
							itemTemplate: '#=data.text#',
							tagTemplate: '#=data.text#'
						});
					},
					messages: this.gridMessages
				},
				hidden: usersColumns.lockUser.hidden,
				width: usersColumns.lockUser.width,
				template: (item) => {
					if (item.lockUser) {
						return lang.INACTIVE;
					} else {
						return `<span class="operational">${lang.ACTIVE}</span>`
					}
				},
				attributes: {
					'class': 'text_center'
				}
			}, {
				field: 'hasNotificationPolicy',
				title: lang.ACTION_TRIGGER,
				sortable: true,
				filterable: {
					operators: {
						string: {
							eq: filterMessages.ISIN,
							neq: filterMessages.ISNOTIN
						}
					},
					ui: element => {
						let multiselect = new MultiSelectGridFilter({
							element: element,
							field: 'hasNotificationPolicy',
							grid: scope.grid,
							dataSource: [{
								text: lang.ACTIVE,
								value: true
							}, {
								text: lang.INACTIVE,
								value: false
							}],
							itemTemplate: '#=data.text#',
							tagTemplate: '#=data.text#'
						});
					},
					messages: this.gridMessages
				},
				hidden: usersColumns.hasNotificationPolicy.hidden,
				width: usersColumns.hasNotificationPolicy.width,
				template: (item) => {
					if (item.hasNotificationPolicy) {
						return `<span class="operational">${lang.ACTIVE}</span>`
					} else {
						return lang.INACTIVE;
					}
				},
				attributes: {
					'class': 'text_center'
				}
			}], usersColumns),
			columnMenu: true,
			change: $.proxy(this.onRowExpand, this),
			dataBound: $.proxy(this.onDataBound, this)
		}).data('kendoCustomGrid')
		// Add Kendo tooltip to the header of the columns
		Utils.gridColumnHeaderTooltip(this.grid);
		this.adjustSectionHeight();
		this.grid.thead.find("[data-field='id']>.k-header-column-menu").remove();
		var searchValue = UserPrefs.get('defaultFsView') ? '' : (UserPrefs.get('usersSearchPhrase') || '');
		this.gridFilterPanel = new PreferencesPanel({
			renderTo: 'cw_users_filters',
			grid: this.grid,
			modulePrefName: 'AdministrationUsers',
			defaultPrefViewKey: 'defaultFsView',
			prefListKey: 'usersFSViews',
			userPref: this.userPref,
			onRemove: $.proxy(this.saveUserPreferences, this),
			searchFields: ['username', 'userCategoryName', 'firstName', 'lastName', 'email'],
			searchValue: searchValue,
			defaultColumns: this.defaultColumns
		});
	},
	/**
	 * Handler function for the change(select) event on the grid
	 * @param {Object} e The change event object
	 */
	onRowExpand: function (e) {
		var selectedRow = this.grid.select();
		var myRow = selectedRow[0];
		var messageEl = $(myRow).find('.to_expand');
		if ($(messageEl).hasClass('cw_message_expanded')) {
			$(messageEl).removeClass('cw_message_expanded').addClass('ellipsis');
		} else {
			$('#users_grid').find('.k-grid-content').find('td.cw_message_expanded').removeClass('cw_message_expanded').addClass('ellipsis');
			$(messageEl).addClass('cw_message_expanded').removeClass('ellipsis');
		}
	},
	/**
	 * Handler function for the grid data bound event
	 */
	onDataBound: function () {
		$('#users_grid').find('.k-grid-content').kendoTooltip({
			filter: "td.tooltip:not(:empty)",
			content: function (event) {
				return event.target.text().split("\n").join("<br />");
			}
		});
	},
	getFilterableForNotification: function (element) {
		var menu = $(element).parent();
		menu.find(".k-filter-help-text").text(lang.grid.filter.SHOW_ITEMS);
		element.removeAttr("data-bind");
		var operatorEl = menu.find("[data-role=dropdownlist]");
		operatorEl.find('option[value="gte"]').remove();
		operatorEl.find('option[value="gt"]').remove();
		operatorEl.find('option[value="lte"]').remove();
		operatorEl.find('option[value="lt"]').remove();
		operatorEl.attr('data-index', '0');
		var multiSelect = element.kendoSortedMultiSelect({
			dataSource: [{
				text: lang.ACTIVE,
				value: true
			}, {
				text: lang.INACTIVE,
				value: false
			}],
			dataTextField: 'text',
			dataValueField: 'value',
			optionLabel: lang.grid.FILTER_SELECT_VALUE
		}).data('kendoSortedMultiSelect');
		menu.find('[type=submit]').on('click', {
			widget: multiSelect,
			operatorElement: operatorEl
		}, $.proxy(this.scope.filterByStatus, {
			scope: this.scope,
			dataSource: this.scope.dataSource,
			field: this.field
		}));
		menu.find('[type=reset]').on('click', $.proxy(function (e) {
			multiSelect.value([]);
		}, this));
		//fix for default value
		setTimeout(function () {
			operatorEl.data('kendoDropDownList').select(1);
		}, 100);
	},
	filterByStatus: function (e) {
		var indicators = e.data.widget.value();
		var operator = e.data.operatorElement.data('kendoDropDownList').value();
		var logic = (operator === 'eq') ? 'or' : 'and';
		var currentFilter = this.dataSource.filter();
		var filter = {logic: logic, filters: []};
		for (var i = 0; i < indicators.length; i++) {
			filter.filters.push({field: this.field, operator: operator, value: indicators[i]});
		}
		if (currentFilter && currentFilter.filters) {
			for (var i = 0; i < currentFilter.filters.length; i++) {
				if (currentFilter.filters[i].filters) {
					for (var j = 0; j < currentFilter.filters[i].filters.length; j++) {
						if (currentFilter.filters[i].filters[j].field === this.field) {
							currentFilter.filters[i].filters.splice(j, 1);
							j--;
						}
					}
				}
				if (currentFilter.filters[i].field === this.field) {
					currentFilter.filters.splice(i, 1);
					i--;
				}
			}
			if (filter.filters.length) {
				currentFilter.filters.push(filter);
			}
			this.dataSource.filter(currentFilter);
		} else {

			this.dataSource.filter(filter);
		}
	},
	/**
	 * Handler for the service checkbox click
	 * @param {Object} e The click event
	 */
	onCheck: function (e) {
		e.stopPropagation();
		var checkboxes = $('.cw_grid_check');
		var users = [];
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			if ($(checkboxes[i]).is(':checked')) {
				users.push($(checkboxes[i]).data('id'));
			}
		}
		if (users.length) {
			this.gridMenu.enableItem('cw_delete');
			if (users.length === 1) {
				this.gridMenu.enableItem('cw_update');
			} else {
				this.gridMenu.disableItem('cw_update');
			}
		} else {
			this.gridMenu.disableItem('cw_delete');
			this.gridMenu.disableItem('cw_update');
		}
	},
	/**
	 * Handler for the check all checkbox click
	 * @param {Object} e The click event
	 */
	onCheckAll: function (e) {
		var checkAll = $('.cw_grid_check_all').is(':checked');
		var checkboxes = $('.cw_grid_check');
		var i, length = checkboxes.length;
		for (i = 0; i < length; i++) {
			$(checkboxes[i]).prop('checked', checkAll);
		}
		if (checkAll && checkboxes.length) {
			if (checkboxes.length === 1) {
				this.gridMenu.enableItem('cw_update');
			} else {
				this.gridMenu.disableItem('cw_update');
			}
			this.gridMenu.enableItem('cw_delete');
		} else {
			this.gridMenu.disableItem('cw_delete');
			this.gridMenu.disableItem('cw_update');
		}
	},
	/**
	 * Handler for the delete button click
	 * @param {Object} e The click event
	 */
	onUserDelete: function (e) {
		if (!$(e.currentTarget).hasClass('disabled')) {
			var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/users/delete';
			var checkboxes = $('.cw_grid_check');
			var i, length = checkboxes.length, haveUsers = false, checked = 0;
			var users = [];
			for (i = 0; i < length; i++) {
				if ($(checkboxes[i]).is(':checked')) {
					var id = $(checkboxes[i]).attr('data-id');
					users.push(id);
					haveUsers = true;
					checked++;
				}
			}
			if (haveUsers) {
				var dialog = new Dialog({
					title: lang.INFO,
					msg: checked > 1 ? lang.account.messages.USERS_REMOVE_CONFIRMATION : lang.account.messages.USER_REMOVE_CONFIRMATION,
					icon: 'ERROR',
					actionText: 'DELETE',
					fn: $.proxy(function (value, button) {
						if (button === 'ok') {
							this.gridMenu.disableItem('cw_delete');
							this.gridMenu.disableItem('cw_update');

							this.userIndex = 0;
							this.usersLength = users.length;
							this.selectedUsers = users;

							this.checkUserDependencies();
						}
					}, this),
					deleteFromGrid: this.grid
				});
				dialog.show();
			}
		}
	},
	/**
	 * Handler function for the click event on the users name
	 * @param {Object} e The click event object
	 */
	onUserNameClick: function (e) {
		var mode = 'view';
		if (State.mainApp.session.hasRole('USER_UPDATE')) {
			mode = 'update';
		}
		var uid = $(e.currentTarget).closest('tr').data('uid');
		var user = this.dataSource.getByUid(uid);

		State.mainApp.navigate(UserRouter.profile(user.id), {extra: {isFromUsersGrid: true, mode}});

		e.stopPropagation();
	},
	/**
	 * Saves user preferences
	 */
	saveUserPreferences: function () {
		var preferences = [{
			key: 'showGridView',
			value: this.showGridView
		}];
		this.saveUserPrefs({
			category: 'AdministrationUsers',
			filterPanel: this.gridFilterPanel,
			grid: this.grid,
			eventsToolbar: this.eventsToolbar,
			preferences: preferences,
			keys: {
				searchPhrase: 'usersSearchPhrase',
				columns: 'usersColumns',
				sort: 'usersSort',
				filter: 'usersFilter',
				FsViews: 'usersFSViews'
			}
		});
	},
	createDataSource: function () {
		this.dataSource = new kendo.ceeview.DataSource({
			transport: {
				read: {
					url: Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/users/',
					contentType: "application/json; charset=utf-8",
					type: "GET",
					dataType: "json",
					cache: false
				}
			},
			sort: this.usersSort || [{
				field: 'name',
				dir: 'asc',
				compare: null
			}],
			filter: this.usersFilter || [],
			error: ErrorHandler.kendoServerError
		});
	},
	/**
	 * Loads user preferences
	 */
	loadUserPreferences: function () {
		var defaultFsView, showGridView, pref;
		UserPrefs.load('AdministrationUsers', $.proxy(function (result) {
			if (result.success) {
				this.userPref = result.data;
				showGridView = UserPrefs.get('showGridView');
				defaultFsView = UserPrefs.get('defaultFsView');

				if (Utils.isGuid(defaultFsView)) {
					pref = JSON.parse(UserPrefs.get(defaultFsView));
					pref = pref || {
						sort: [],
						filter: []
					};
					this.usersSort = pref.sort;
					this.usersFilter = pref.filter;
					this.usersColumns = pref.columns;
				} else {
					if (UserPrefs.get('usersSort')) {
						this.usersSort = JSON.parse(UserPrefs.get('usersSort'));
					} else {
						this.usersSort = [{
							field: 'name',
							dir: 'asc',
							compare: null
						}];
					}
					if (UserPrefs.get('usersFilter')) {
						this.usersFilter = JSON.parse(UserPrefs.get('usersFilter'));
					} else {
						this.usersFilter = [];
					}
				}

				if (showGridView) {
					this.showGridView = showGridView === 'true' ? true : false;
				} else {
					this.showGridView = true;
				}
				this.cardGridToggle.select(this.showGridView ? 'cw_grid_view' : 'cw_card_view');

				this.createDataSource();
				var eventStatus = JSON.parse(UserPrefs.get('eventsStatus')) || {
					startFrom: 0,
					playing: true
				};
				this.eventsToolbar = new EventsToolbar({
					renderTo: $('#cw_users_events_toolbar'),
					callBackFn: $.proxy(this.onEventsTimeout, this),
					subscriberId: this.subscriberId,
					startFrom: eventStatus.startFrom,
					playing: eventStatus.playing
				});
				if (this.showGridView) {
					this.initGridView();
				} else {
					this.initCardView();
					this.initGridView(false);
				}
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},
	/**
	 * Handler function for the user saved event
	 */
	onUserSaved: function () {
		this.dataSource.read();
	},
	/**
	 * Method by Andy
	 */
	adjustSectionHeight: function () {
		var section = $('.cw_section_full');
		var sectionHeight = section.height();
		section.find('.cw_section_content').css('height', sectionHeight);
		section.find('.k-grid-content').css('height', sectionHeight - 40);
	},
	/**
	 * Subscribes to the events
	 */
	subscribe: function () {
		this.isDataSourceSubscribed = true;
		var subscriptionObj = [{
			eventType: 'Administration',
			//Entity types: ACCOUNT, AGENT, ASSET, ASSET_GROUP, AUTHENTICATION, MONITOR, NOTIFICATION_POLICY, TEAM, TEAM_CALENDAR, USER
			entityType: 'USER',
			//Optional. If entityId is specified, only events for this entity are received
			//entityId:"1b8a6722-97a5-4e85-adf1-fcd2c958ebe5"
			//Optional. If accountId is specified, events for all entities of type "entityType" in this account are received
			accountId: Cookies.CeesoftCurrentAccountId
		}];
		RemoteEventsManager.subscribe(this.subscriberId, subscriptionObj);
	},
	/**
	 * Handler function for the timeout counter
	 * @param {Object|Array} events A single event or an array of events
	 */
	onEventsTimeout: function (events) {
		this.dataSource.read();
	},
	renderDocumentationButton: function () {
		ReactDOM.render(<DocumentationButton type={'actiontrigger'}>
			</DocumentationButton>, $('.cw_documentation').get(0));
	},
	/**
	 * Destroy
	 */
	destroy: function () {
		this.saveUserPreferences();
		if (this.eventsToolbar) {
			this.eventsToolbar.destroy();
		}
		Application.prototype.destroy.call(this);
	}
});
