import {newGuid} from "tools/guid";

const className = 'html-shape-container';

export function enableFeature(designer){
	registerShape(designer);
	addCopyPaste(designer);
}

function addCopyPaste(designer){
	const original = designer.graph.cloneCells;

	designer.graph.cloneCells = function() {
		let clones = original.apply(this, arguments);

		for(const cell of clones){
			if (!isHtmlContainerCell(designer.graph, cell)) {
				continue;
			}

			designer.graph.setCellStyles(
				'containerId',
				newGuid(),
				[cell]
			);
		}

		return clones;
	}
}

export function isHtmlContainerCell(graph, cell) {
	const styles = graph.getCellStyle(cell);
	return styles.shape == 'htmlContainerShape';
}

export function getHtmlContainer(graph, cell){
	const state = graph.view.getState(cell);
	return {
		id: state.containerId,
		container: document.getElementById(state.containerId)
	};
}

function registerShape(designer){
	if(mxCellRenderer.defaultShapes["htmlContainerShape"] != null)
		return;

	function HtmlContainerShape(){
		mxShape.call(this);
	}

	HtmlContainerShape.prototype = new mxShape();
	HtmlContainerShape.prototype.constructer = HtmlContainerShape;

	HtmlContainerShape.prototype.paintVertexShape =  function(c, x, y, w, h){
		this.initContainer(c);

		this.calculateTransform(c, x, y, w, h );

		this.updateSize(w, h);

		this.updateStyles();

		c.root.appendChild(this.container);
	}

	HtmlContainerShape.prototype.updateStyles = function() {
		let divStyle = this.div.style;
		let shapeStyle = this.state.style;

		divStyle.backgroundColor = shapeStyle.fillColor || 'transparent';

		divStyle.opacity = shapeStyle.opacity != undefined
			? shapeStyle.opacity / 100.0
			: 1;

		let borderStyle = 'solid';

		if(shapeStyle.dashed == 1){
			if(shapeStyle.dashPattern == null){
				borderStyle = 'dashed';
			}else{
				borderStyle = 'dotted';
			}
		}

		if(shapeStyle.strokeColor){
			divStyle.border = (shapeStyle.strokeWidth || '1') + 'px ' + borderStyle + ' ' + shapeStyle.strokeColor;
		}else{
			divStyle.border = 'none';
		}


		//divStyle.overflowY = 'auto';
	}

	HtmlContainerShape.prototype.updateSize = function(w, h) {
		this.fo.setAttribute('width', Math.round(Math.max(1, w)));
		this.fo.setAttribute('height', Math.round(Math.max(1, h)));

		this.div.style.width = w + 'px';
		this.div.style.height = h + 'px';
	}

	HtmlContainerShape.prototype.calculateTransform = function(c, x, y, w, h) {
		const s = c.state;
		var tr = (s.scale != 1) ? 'scale(' + s.scale + ')' : '';

		if (s.rotation != 0 && c.rotateHtml)
		{
			tr += 'rotate(' + (s.rotation) + ',' + (w / 2) + ',' + (h / 2) + ')';
			var pt = c.rotatePoint((x + w / 2) * s.scale, (y + h / 2) * s.scale,
				s.rotation, s.rotationCx, s.rotationCy);
			x = pt.x - w * s.scale / 2;
			y = pt.y - h * s.scale / 2;
		}
		else
		{
			x *= s.scale;
			y *= s.scale;
		}

		this.container.setAttribute('transform', 'translate(' + (Math.round(x) + c.foOffset) + ',' +
			(Math.round(y) + c.foOffset) + ')' + tr);
	}

	HtmlContainerShape.prototype.initContainer = function(c){
		if(this.container == null) {
			this.div = document.createElement('div');

			this.div.classList.add(className);
			this.div.id = newGuid();
			this.state.containerId = this.div.id;
			this.div.style.display = 'flex';
			this.div.style.flexDirection = 'column';
			this.div.style.position = 'fixed';

			this.fo = c.createElement('foreignObject');
			this.fo.setAttribute('style', 'overflow:visible;');
			this.fo.setAttribute('pointer-events', 'all');
			this.fo.appendChild(this.div);

			c.createDiv()

			this.container = c.createElement('g');
			this.container.appendChild(this.fo);
		}
	}

	mxCellRenderer.registerShape('htmlContainerShape', HtmlContainerShape);
}

