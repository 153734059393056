import React from 'react';
import PropTypes from 'prop-types';
import translator from 'core/localization/localization';
import DropDownList from 'controls/react/kendoWrappers/dropDownList';
import {Draggable} from 'react-beautiful-dnd'
import classnames from 'classnames';

const getItemStyle = (isDragging, draggableStyle) => ({
	userSelect: "none",
	background: isDragging ? "white" : "white",
	opacity: isDragging ? 0.5: 1,
	...draggableStyle
});

const i = translator({
	'Sorting': {
		no: 'Sortering'
	},
	'Type': {
		no: 'Type'
	}
});

const TYPES = [
	{
		text: 'Name',
		value: 'NAME'
	},
	{
		text: 'State duration',
		value: 'STATE_DURATION'
	},
	{
		text: 'State',
		value: 'STATE'
	},
	{
		text: 'Incident',
		value: 'INCIDENT'
	}
];

function getTypes(currentType, existingItems) {
	return TYPES.filter((type) => {
		for (const existingItem of existingItems) {
			if (type.value === existingItem.type) {
				return type.value === currentType;
			}
		}

		return true;
	});
}

function SortingItem(props) {
	const onTypeChange = React.useCallback((value) => {
		props.onChange(props.id, { ...props.item, type: value })
	}, [props.onChange]);

	const onSortingChange = React.useCallback((value) => {
		props.onChange(props.id, { ...props.item, sorting: value })
	}, [props.onChange]);

	const types = React.useMemo(() => {
		if (props.items) {
			return getTypes(props.item.type, Object.values(props.items));
		}
		else {
			return [];
		}

	}, [props.items, props.item]);

	React.useEffect(() => {
		if (props.item.type === '') {
			onTypeChange(types[0].value)
		}
	}, [props.item]);

	const onRemove = React.useCallback(() => {
		if (props.index > 0) {
			props.onRemove(props.id);
		}
	}, [props.index, props.id, props.onRemove]);

	return <Draggable draggableId={props.id} index={props.index}>
		{(provided, snapshot) => (
			<div
				ref={provided.innerRef}
				{...provided.draggableProps}
				{...provided.dragHandleProps}
				style={getItemStyle(
					snapshot.isDragging,
					provided.draggableProps.style
				)}
				className={'sorting-panel__sorting-item'}
			>
				<div className={'draggable-icon'} />
				<div className={'sorting-panel__label'}>{i('Type')}</div>
				<div className={'sorting-panel__input'}>
					<DropDownList
						value={props.item.type}
						onChange={onTypeChange}
						dataSource={types}/>
				</div>
				<div className={classnames('sorting-panel__label', 'sorting-panel__padding')}>{i('Sorting')}</div>
				<div className={'sorting-panel__input'}>
					<DropDownList
						value={props.item.sorting}
						onChange={onSortingChange}
						dataSource={[
						{
							text: 'ASC',
							value: 'ASC'
						}, {
							text: 'DESC',
							value: 'DESC'
						}
					]}/>
				</div>
				<div className={classnames('sorting-panel__remove-icon', {'glyphicons bin': props.index > 0})} onClick={onRemove} />
			</div>)}
	</Draggable>
}

SortingItem.propTypes = {
	item: PropTypes.shape({
		type: PropTypes.string.isRequired,
		sorting: PropTypes.string.isRequired,
	}),
	id: PropTypes.string.isRequired,
	index: PropTypes.number.isRequired,
	onChange: PropTypes.func.isRequired,
	onRemove: PropTypes.func.isRequired
};

export default SortingItem;
