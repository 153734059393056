import * as ace from 'brace';
import 'brace/mode/groovy';
import 'brace/theme/crimson_editor';
import CeeviewNumericField from "controls/react/form/ceeviewNumericField";
import StateTextBox from "controls/react/form/stateTextBox";
import TextArea from "controls/react/form/textArea";
import Window from "controls/react/kendoWrappers/window";
import React from "react";

function CETextSingleInput(props) {
	const {input: {onChange, value}, meta: {invalid}, showFieldValidation, fieldInError, disabled, property} = props;

	const ref = React.useRef();

	React.useEffect(() => {
		kendo.ui.progress($(ref.current), props.workInProgress);
	}, [props.workInProgress]);

	const type = React.useMemo(() => {
		return property.get('numeric');
	}, []);

	const checkAndCallChangeRef = React.useRef();

	checkAndCallChangeRef.current = React.useCallback((newValue) => {
		if (newValue === '' || newValue === 0 || newValue != value) {
			onChange(newValue);
		}
	}, [onChange, value]);

	const handleChange = React.useCallback((value) => {
		checkAndCallChangeRef.current(value);
	}, []);

	if (type) {
		return <CeeviewNumericField
			allowEmpty
			decimals={8}
			wrapperRef={ref}
			value={"" + value}
			onChange={handleChange}
			invalid={showFieldValidation && (invalid || fieldInError)}
			readOnly={disabled} />
	}
	else {
		return <StateTextBox
			wrapperRef={ref}
			value={"" + value}
			onChange={handleChange}
			invalid={showFieldValidation && (invalid || fieldInError)}
			readOnly={disabled} />
	}
}

function CETextMultiInput(props) {
	const {input: {onChange, value}, meta: {invalid}, showFieldValidation, fieldInError, disabled, property} = props;

	const handleChange = React.useCallback((newValue) => {
		onChange(newValue);
	}, [onChange, value])

	return <TextArea value={"" + value} onChange={handleChange} invalid={showFieldValidation && (invalid || fieldInError)} disabled={disabled}/>
}

export class CETextEditor extends React.PureComponent  {
	ref = React.createRef();

	state = {
		openEditor: false
	};

	onClose = () => {
		this.setState({
			openEditor: false
		});
	};

	displayWindow = () => {
		this.setState({
			openEditor: true
		});
	};

	render() {
		return <div ref={this.ref}>
			<button className={"multiedit__button k-button"} onClick={this.displayWindow}>Edit / View</button>
			{this.state.openEditor && <ScriptWindow {...this.props} onClose={this.onClose} anchor={this.ref.current} />}
		</div>
	}
}

class ScriptWindow extends React.PureComponent {
	editorRef = React.createRef();

	componentDidMount() {
		this.groovyEditor = ace.edit(this.editorRef.current);
		this.groovyEditor.setTheme('ace/theme/crimson_editor');
		this.groovyEditor.getSession().setMode('ace/mode/groovy');
		this.groovyEditor.setValue("" + this.props.input.value);
		this.groovyEditor.gotoLine(0, 0, true);
		this.groovyEditor.on("change", () => {
			this.props.input.onChange(this.groovyEditor.getValue())
		});
	}

	render() {
		const {input: {onChange, value}, meta, property, anchor, ...others} = this.props;

		const offset = $(anchor).offset();

		let top = offset.top - 400;
		let left = offset.left - 600;

		top = (top < 100) ? 100: top;
		left = (left < 100) ? 100: left;

		return <Window title={property.get('caption')} modal={false} top={top} left={left} width={"400px"} height={"400px"} onClose={this.props.onClose}>
			<div className={"script-window__content"}>
				<div className={"textarea-content"} ref={this.editorRef} />
				<footer className="cw_sqwiz_nav">
					<button className={"k-button"} onClick={this.props.onClose}>Close</button>
				</footer>
			</div>
		</Window>
	}
}

export default class CETextInput extends React.PureComponent {
	constructor(props) {
		super(props);
		this.multiLine = props.property.get('multiLine');
	}

	componentDidUpdate(prevProps) {
		const {data} = this.props;
		if (!data || !data.success) {
			return;
		}

		// if (!prevProps.data || prevProps.data.version !== data.version) {
		// 	const {input: {value, onChange}, meta} = this.props;
		//
		// 	if (!value && data.data && !meta.dirty) {
		// 		onChange(data.data);
		// 	}
		// }
	}

	render() {
		if(this.multiLine) {
			return <CETextMultiInput {...this.props}/>
		}
		else {
			return <CETextSingleInput {...this.props}/>
		}
	}
}
