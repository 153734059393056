import Utils from 'tools/utils';
import Widget from 'areas/service-boards/widget';
import Settings from 'settings';
import RemoteEventsManager from 'core/remoteEventsManager';
import Renderer from 'tools/renderer';
import ErrorHandler from 'core/errorHandler';
import State from 'tools/state';
import GridSearch from 'controls/gridSearch';
import {translator as i} from "core";
import MultiSelectGridFilter from 'controls/multiSelectGridFilter';
import {ServicesRouter} from 'areas/services/bundleDescription';
import {getServiceState} from 'controls/stateRenderer/serviceState'
import {getHealthStateService} from 'controls/stateRenderer/healthState'
import {getSlaState} from 'controls/stateRenderer/slaState';
import {getGridStateForSaving, updateHiddenColumns} from "controls/react/kendoWrappers/grid";
import {throttle} from 'lodash';

export function ServiceGridWidget(config) {
	Widget.call(this, config);

	this.requestPath = Settings.serverPath;
	if (this.sessionId) {
		this.requestPath = Settings.serverPath + 'sessions/' + this.sessionId + '/';
	}

	this.hasConfiguration = true;
	this.configuration.includeSubaccounts = this.configuration.includeSubaccounts || false;

};

export {ServiceGridWidget as default};

jQuery.extend(ServiceGridWidget.prototype, Widget.prototype, {
	init: async function () {
		const widgetContentDiv = this.getContentDiv();
		widgetContentDiv.parent().addClass('cw_section_servicegrid');
		this.gridId = Utils.guid();
		widgetContentDiv.empty().append('<div id="' + this.gridId + '" class="services_grid"></div>');
		this.gridMessages = {
			isTrue: '<span class="glyphicons service_state ok-sign"></span>',
			isFalse: '<span class="glyphicons service_state remove-sign"></span>',
			clear: lang.CLEAR,
			info: lang.grid.filter.SHOW_ITEMS,
			filter: lang.FILTER
		};
		this.subscribe();
		this.setTitleIfEmpty(i('Service'));
		if (!this.sessionId) {
			$('#' + this.gridId).off();
			if (State.mainApp && State.currentApp?.dashboardDesigner?.props?.mode !== 'designer') {
				if (State.mainApp.session.hasRole('SERVICE_READ') && State.mainApp.session.hasRole('SERVICE_MODEL_READ')) {
					$('#' + this.gridId).on('click', '.cw_grid_link', $.proxy(this.onServiceNameClick, this));
					$('#' + this.gridId).on('click', '.cw_service_state .cw_status_widget_color', $.proxy(this.onServiceStateClick, this));
				}
				if (State.mainApp.session.hasRole('SLA_READ')) {
					$('#' + this.gridId).on('click', '.cw_service_sla .status_icon', $.proxy(this.onSlaIndicatorClick, this));
				}
				if (State.mainApp.session.hasRole('ASSET_READ')) {
					$('#' + this.gridId).on('click', '.cw_service_health .cw_status_widget_color', $.proxy(this.onHealthIndicatorClick, this));
				}
				if (State.mainApp.session.hasRole('INCIDENT_READ')) {
					$('#' + this.gridId).on('click', '.cw_incidents_link', $.proxy(this.onIncidentsLink, this));
				}
			}
		}
		$(window).off('resize', $.proxy(this.onResize, this));
		$(window).on('resize', $.proxy(this.onResize, this));
		State.gridPosLoaded = false;
	},

	getContentDiv: function () {
		if(this.placeholders) {
			let contentDiv = $(this.placeholders.content);
			contentDiv.addClass('cw_section_content');
			return contentDiv;
		}
		return $('#' + this.id).find('.cw_section_content');
	},

	getSearchTextbox: function () {
		if (this.placeholders) {
			return $(this.placeholders.search);
		}
		return $('#' + this.id).find('.cw_search_box');
	},
	/**
	 * Initializes Kendo components
	 */
	initKendoComponents: function () {

		this.tags = [];
		if (this.configuration.tags) {
			for (var i = 0; i < this.configuration.tags.length; i++) {
				if (this.configuration.tags[i].name) {
					this.tags.push(this.configuration.tags[i].name);
				}
				else {
					this.tags.push(this.configuration.tags[i]);
				}
			}
		}

		var filterMessages = lang.grid.filter;

		var gridSort = this.configuration.sort || this.persistedState?.sort || [{
			field: 'serviceIndicator',
			dir: 'asc',
			compare: null
		}];

		var gridFilter = this.configuration.filter || this.persistedState?.filter;

		let url = `${this.requestPath}accounts/${this.configuration.accountId}/services/serviceDetails?includeSubaccounts=${this.configuration.includeSubaccounts.toString()}`
		this.dataSource = new kendo.ceeview.DataSource({
			transport: {
				read: {
					url: url,
					contentType: "application/json; charset=utf-8",
					type: "POST",
					dataType: "json",
					cache: false
				},
				parameterMap: $.proxy(function (data) {
					if (data.filter?.filters) {
						data.filter.filters = Utils.changeDateFilterToString(data.filter.filters);
					}
					data.ignoreUserTags = this.configuration.ignoreUserTags;
					data.showUntagged = this.configuration.showUntagged;

					let tags = this.configuration.tags;
					let tagsArray = [];
					if (tags) {
						for (let i = 0; i < tags.length; i++) {
							if (tags[i].name) {
								tagsArray.push(tags[i].name);
							} else if (typeof tags[i] === 'string') {
								tagsArray.push(tags[i]);
							}
						}
					}
					this.configuration.tags = tagsArray;
					data.tags = this.configuration.tags;
					if (this.configuration.referenceServiceId) {
						data.referenceServiceId = this.configuration.referenceServiceId;
					}
					return kendo.stringify(data);
				}, this)
			},
			pageSize: 100,
			schema: {
				model: {
					id: 'id',
					fields: {
						srvHealthIndex: {
							type: 'number'
						},
						incidentCount: {
							type: 'number'
						},
						srvSlaIndex: {
							type: 'number'
						},
						serviceIndicator: {
							type: 'number'
						},
						coverage: {
							type: 'number'
						},
						stateDuration: {
							type: 'number'
						},
						hasDocuments: {
							type: 'boolean'
						},
						timestamp: {
							type: 'number'
						},
						shared: {
							type: 'boolean'
						}
					}
				},
				parse: $.proxy(function(result) {
					this.filterOptions = result.filterOptions;
					return result;
				}, this),
				data: function (result) {
					var response = result.items;
					for (var i = 0, length = response.length; i < length; i++) {
						response[i].timestamp = Utils.timestamp();
						var states = ['OPERATIONAL', 'NON_OPERATIONAL', 'IN_MAINTENANCE'];
						var labels = [lang.ACTIVE, lang.service.OUTSIDE_OPERATION_PERIOD, lang.service.IN_MAINTENANCE];
						var index = states.indexOf(response[i].operatingState);
						if (index > -1) {
							response[i].Operational = labels[index];
						} else {
							response[i].Operational = '';
						}
					}
					return response;
				},
				total: function (response) {
					this.visibleItems = response.visible;
					this.totalItems = response.total;
					return response.visible;
				}
			},
			serverSorting: true,
			serverPaging: true,
			serverFiltering: true,
			sort: gridSort,
			filter: gridFilter,
			error: ErrorHandler.kendoServerError
		});

		let servicesColumns = this.configuration.columns || {
			serviceIndicator: {
				hidden: false
			},
			name: {
				hidden: false
			},
			accountName: {
				hidden: false
			},
			srvHealthIndex: {
				hidden: false
			},
			srvSlaIndex: {
				hidden: false
			},
			incidentCount: {
				hidden: false
			},
			coverage: {
				hidden: false
			},
			Operational: {
				hidden: false
			},
			responsibleTeamName: {
				hidden: false
			},
			stateDuration: {
				hidden: false
			},
			tags: {
				hidden: false
			},
			shared: {
				hidden: false
			},
			description: {
				hidden: false
			}
		};

		if (!servicesColumns.Operational) {
			servicesColumns.Operational = {
				hidden: false
			};
		}

		if (!servicesColumns.coverage) {
			servicesColumns.coverage = {
				hidden: false
			};
		}

		if (!servicesColumns.accountName) {
			servicesColumns.accountName = {
				hidden: false
			}
		}

		if(servicesColumns.srvStateIndex){
			servicesColumns.serviceIndicator = servicesColumns.srvStateIndex;
			delete servicesColumns.srvStateIndex;
		}

		if(this.persistedState?.columns?.srvStateIndex){
			this.persistedState.columns.serviceIndicator = this.persistedState?.columns.srvStateIndex;
		}

		servicesColumns = updateHiddenColumns(this.persistedState?.columns, servicesColumns);

		var scope = this;

		this.grid = $('#' + this.gridId).kendoCustomGrid({
			dataSource: this.dataSource,
			cacheScrollCheckboxes: true,
			scrollable: {
				virtual: true
			},
			resizable: true,
			height: this.isDashboardMode() ? '100%' : null,
			selectable: 'row',
			reorderable: true,
			sortable: {
				mode: "multiple",
				allowUnsort: true
			},
			filterable: {
				extra: false,
				operators: {
					string: {
						startswith: filterMessages.STARTS_WITH,
						neq: filterMessages.NEQ,
						eq: filterMessages.EQ
					},
					number: {
						eq: filterMessages.EQ,
						neq: filterMessages.NEQ,
						gte: filterMessages.GTE,
						gt: filterMessages.GT,
						lte: filterMessages.LTE,
						lt: filterMessages.LT
					}
				},
				messages: this.gridMessages
			},
			columns: Utils.rearrangeColumns([{
				field: 'serviceIndicator',
				title: lang.widget.STATUS,
				headerTemplate: '<span class="cw_status_indicator empty hide"></span>',
				 template: item => getServiceState(item.serviceState, '', item.operatingState, item.agentStatus, item.qualifierError, item.assetMaintenance),
				attributes: {
					'class': 'cw_service_state text_center'
				},
				headerAttributes: {
					'class': 'text_center'
				},
				sortable: {
					compare: $.proxy(function (a, b) {
						return Utils.customCompare(a, b, 'serviceState', 3, this.dataSource.sortNow);
					}, this)
				},
				filterable: {
					operators: {
						string: {
							eq: filterMessages.ISIN,
							neq: filterMessages.ISNOTIN
						}
					},
					ui: function (element) {
						let menu = $(element).parent();
						let operatorEl = menu.find("[data-role=dropdownlist]");

						let multiselect = new MultiSelectGridFilter({
							element: element,
							field: 'serviceIndicator',
							grid: scope.grid,
							dataSource: new kendo.data.DataSource({data: [{
									text: lang.DOWN,
									value: 1,
									config: {serviceState: 'INACTIVE', agentStatus: 'ACTIVE', qualifierError: false}
								}, {
									text: lang.WARNING,
									value: 2,
									config: {serviceState: 'WARNING', agentStatus: 'ACTIVE', qualifierError: false}
								}, {
									text: lang.UP,
									value: 3,
									config: {serviceState: 'ACTIVE', agentStatus: 'ACTIVE', qualifierError: false}
								}, {
									text: lang.UNAVAILABLE,
									value: 4,
									config: {serviceState: 'INVALID', agentStatus: 'ACTIVE', qualifierError: false}
								}, {
									text: lang.account.messages.AGENT_WARNING,
									imgClass: 'glyphicons exclamation-sign',
									value: 5,
									config: {serviceState: '', agentStatus: 'AGENT_DOWN', qualifierError: false}
								}, {
									text: lang.account.messages.AGENT_MAINTENANCE,
									imgClass: 'glyphicons exclamation-sign',
									value: 6,
									config: {serviceState: '', agentStatus: 'AGENT_MAINTENANCE', qualifierError: false}
								}, {
									text: lang.account.messages.ASSET_MAINTENANCE,
									imgClass: 'glyphicons exclamation-sign',
									value: 7,
									config: {serviceState: '', agentStatus: '', qualifierError: '', assetMaintenance: true}
								}, {
									text: lang.account.messages.QUALIFIER_ERROR,
									imgClass: 'glyphicons exclamation-sign',
									value: 8,
									config: {serviceState: '', agentStatus: 'ACTIVE', qualifierError: true}
								}], serverSorting: true}),
							itemTemplate: item => getServiceState(item.config.serviceState, item.text, null, item.config.agentStatus, item.config.qualifierError, item.config.assetMaintenance),
							tagTemplate: item => getServiceState(item.config.serviceState, item.text, null, item.config.agentStatus, item.config.qualifierError, item.config.assetMaintenance),
						});
					},
					messages: this.gridMessages
				},
				valueRenderer: function (value) {
					return Renderer.summaryStateFilter({value: value, text: ''});
				},
				hidden: servicesColumns.serviceIndicator.hidden,
				width: 40
			}, {
				field: 'name',
				title: lang.NAME,
				sortable: true,
				filterable: true,
				template: '<a class="cw_grid_link" data-id="#= id #">#= name #</a>',
				hidden: servicesColumns.name.hidden,
				width: 240,
				attributes: {
					"class": "expand ellipsis"
				}
			}, {
				field: 'accountName',
				title: lang.ACCOUNT,
				sortable: true,
				filterable: true,
				hidden: servicesColumns.accountName.hidden,
				width: 150,
				attributes: {
					"class": "expand ellipsis"
				},
				filterable: {
					ui: function (element) {
						var multiselect = new MultiSelectGridFilter({
							element: element,
							field: 'accountName',
							grid: scope.grid,
							itemTemplate: '#=data.text#',
							tagTemplate: '#=data.text#',
							dataSource: scope.filterOptions['accountName']
						});
					},
					messages: this.gridMessages,
					extra: false,
					operators: {
						string: {
							eq: filterMessages.EQ,
							neq: filterMessages.NEQ
						}
					}
				},
			}, {
				field: 'srvHealthIndex',
				title: lang.summary.SERVICE_HEALTH,
				//template: '<span class="cw_status_indicator cw_status_widget_color cw_color#=Utils.severityToColor(srvHealthIndex)# # if (srvHealthIndex !== 6) { # pointer # } #"></span>',
				template: item => getHealthStateService(item.srvHealthIndex),
				attributes: {
					'class': 'cw_service_health text_center'
				},
				sortable: {
					compare: $.proxy(function (a, b) {
						return Utils.customCompare(a, b, 'srvHealthIndex', 6, this.dataSource.sortNow);
					}, this)
				},
				filterable: {
					ui: function (element) {
						element.kendoDropDownList({
							dataSource: [{
								text: lang.CRITICAL,
								value: 3
							}, {
								text: lang.MAJOR,
								value: 2
							}, {
								text: lang.MINOR,
								value: 1
							}, {
								text: lang.OK,
								value: 0
							}, {
								text: lang.UNAVAILABLE,
								value: 6
							}],
							dataTextField: 'text',
							dataValueField: 'value',
							optionLabel: lang.grid.FILTER_SELECT_VALUE,
							change: Utils.onFilterDropDownChange,
							//template: '<span class="cw_status_indicator pointer cw_status_widget_color cw_color#=Utils.severityToColor(data.value)#"></span><span>${data.text}</span>'
							template: item => getHealthStateService(item.value, item.text),
						});
					}
				},
				hidden: servicesColumns.srvHealthIndex.hidden,
				width: 130
			}, {
				field: 'srvSlaIndex',
				title: lang.widget.SLA,
				//template: '#= Renderer.serviceSla(srvSlaIndex)#',
				template: item => getSlaState(item.srvSlaIndex),
				attributes: {
					'class': 'cw_service_sla text_center'
				},
				sortable: {
					compare: $.proxy(function (a, b) {
						return Utils.customCompare(a, b, 'srvSlaIndex', 4, this.dataSource.sortNow);
					}, this)
				},
				filterable: {
					operators: {
						number: {
							eq: filterMessages.ISIN,
							neq: filterMessages.ISNOTIN
						}
					},
					ui: function (element) {
						element.kendoDropDownList({
							dataSource: [{
								text: lang.service.IN_COMPLIANCE,
								icon: 'glyphicons status_icon circle-arrow-top',
								value: 1
							}, {
								text: lang.WARNING,
								icon: 'glyphicons status_icon circle-arrow-right',
								value: 2
							}, {
								text: lang.service.BREACHED,
								icon: 'glyphicons status_icon circle-arrow-down',
								value: 3
							}, {
								text: lang.summary.NO_SLA_AVAILABLE,
								icon: 'cw_status_indicator cw_status_widget_color status_icon cw_color6',
								value: 4
							}],
							dataTextField: 'text',
							dataValueField: 'value',
							optionLabel: lang.grid.FILTER_SELECT_VALUE,
							change: Utils.onFilterDropDownChange,
							template: '<span class="${data.icon}"></span> <span>${data.text}</span>'
						});
					}
				},
				hidden: servicesColumns.srvSlaIndex.hidden,
				width: 80
			}, {
				field: 'incidentCount',
				title: lang.service.INCIDENT,
				sortable: true,
				filterable: true,
				template: '<a class="# if (incidentCount) {#pointer #}#cw_incidents_link" data-filter="ACCOUNT">#= incidentCount#</a>',
				attributes: {
					'class': 'text_center'
				},
				hidden: servicesColumns.incidentCount.hidden,
				width: 100
			}, {
				field: 'coverage',
				title: lang.service.COVERAGE,
				sortable: true,
				filterable: true,
				attributes: {
					'class': 'expand ellipsis text_center'
				},
				hidden: servicesColumns.coverage.hidden,
				width: 140
			}, {
				field: 'Operational',
				title: lang.service.MODE,
				sortable: true,
				filterable: {
					operators: {
						string: {
							eq: filterMessages.ISIN,
							neq: filterMessages.ISNOTIN
						}
					},
					ui: function (element) {
						var multiselect = new MultiSelectGridFilter({
							element: element,
							field: 'Operational',
							grid: scope.grid,
							dataSource: [{
								text: lang.ACTIVE,
								value: lang.ACTIVE
							}, {
								text: lang.service.OUTSIDE_OPERATION_PERIOD,
								value: lang.service.OUTSIDE_OPERATION_PERIOD
							}, {
								text: lang.service.IN_MAINTENANCE,
								value: lang.service.IN_MAINTENANCE
							}],
							itemTemplate: '#=data.text#',
							tagTemplate: '#=data.text#'
						});
					},
					messages: this.gridMessages
				},
				hidden: servicesColumns.Operational.hidden,
				width: 150,
				attributes: {
					'class': '#if (operatingState === "OPERATIONAL") {# operational #} else if (operatingState === "NON_OPERATIONAL") {# non_operational #} else {# maintenance #}# text_center'
				}
			}, {
				field: 'responsibleTeamName',
				title: lang.TEAM,
				sortable: true,
				filterable: true,
				hidden: servicesColumns.responsibleTeamName.hidden,
				template: '<span>#= responsibleTeamName ? responsibleTeamName : "-" #</span>',
				width: 200,
				attributes: {
					"class": "expand ellipsis"
				},
				filterable: {
					ui: function (element) {
						var multiselect = new MultiSelectGridFilter({
							element: element,
							field: 'responsibleTeamName',
							grid: scope.grid,
							itemTemplate: '#=data.text#',
							tagTemplate: '#=data.text#',
							dataSource: scope.filterOptions['responsibleTeamName']
						});
					},
					messages: this.gridMessages,
					extra: false,
					operators: {
						string: {
							eq: filterMessages.EQ,
							neq: filterMessages.NEQ
						}
					}
				},
			}, {
				field: 'stateDuration',
				title: lang.service.CURRENT_STATE,
				sortable: true,
				filterable: {
					ui: function (element) {
						element.kendoDropDownList({
							dataSource: [{
								text: '1 ' + lang.HOUR,
								value: 3600000
							}, {
								text: '5 ' + lang.HOURS,
								value: 18000000
							}, {
								text: '10 ' + lang.HOURS,
								value: 36000000
							}, {
								text: '1 ' + lang.DAY,
								value: 84600000
							}],
							dataTextField: 'text',
							dataValueField: 'value',
							optionLabel: lang.grid.FILTER_SELECT_VALUE
						});
					}
				},
				//backward compatibility
				hidden: servicesColumns.stateDuration ? servicesColumns.stateDuration.hidden : false,
				template: '#=Renderer.duration(stateDuration)#',
				attributes: {
					'class': 'cw_state_duration'
				},
				width: 160
			}, {
				field: 'tags',
				title: lang.TAGS,
				sortable: true,
				filterable: {
					ui: function (element) {
						let multiselect = new MultiSelectGridFilter({
							element: element,
							field: 'tags',
							grid: scope.grid,
							itemTemplate: '#=data.text#',
							tagTemplate: '#=data.text#',
							dataSource: scope.filterOptions['tags']
						});
					},
					messages: this.gridMessages,
					extra: false,
					operators: {
						string: {
							eq: filterMessages.EQ,
							neq: filterMessages.NEQ
						}
					}
				},
				template: item => item.tags.join(','),
				hidden: servicesColumns.tags ? servicesColumns.tags.hidden : false,
				width: 150,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'shared',
				title: lang.SHARED,
				template: '#=shared?lang.SHARED:""#',
				valueRenderer: function (value) {
					return value ? lang.YES : lang.NO;
				},
				hidden: servicesColumns.shared ? servicesColumns.shared.hidden : false,
				width: 100,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				},
				//groupable: false
			}, {
				field: 'description',
				title: lang.DESCRIPTION,
				sortable: false,
				filterable: true,
				template: '#= description #',
				attributes: {
					"class": "expand ellipsis"
				},
				hidden: servicesColumns.description.hidden
			}], this.persistedState?.columns ),
			columnMenu: true,
			change: $.proxy(this.onRowExpand, this),
			dataBound: $.proxy(function() {
				setTimeout(() => this.adjustSectionHeight(this.id), 200);
			}, this)
		}).data('kendoCustomGrid');
		// Add Kendo tooltip to the header of the columns

		for (let column in servicesColumns) {
			//workaround for grid hidden property not effective
			if (servicesColumns[column].hidden) {
				this.grid.hideColumn(column);
			} else {
				this.grid.showColumn(column);
			}
		}

		$('.k-virtual-scrollable-wrap').scroll($.proxy(function () {
			State.dynamicGridPos = $('#' + this.gridId).data('kendoCustomGrid').wrapper.find(".k-scrollbar").scrollTop();
		}, this));

		Utils.gridColumnHeaderTooltip(this.grid);
		this.grid.thead.find("[data-field='id']>.k-header-column-menu").remove();

		this.initSearchTextbox();
	},

	initSearchTextbox: function() {
		const searchTextbox = this.getSearchTextbox();
		if(searchTextbox) {
			this.gridSearch = new GridSearch({
				input: searchTextbox,
				dataSource: this.dataSource,
				fields: ['name', 'responsibleTeamName', 'description', 'accountName']
			});
		}
	},
	/**
	 * Called when an event is received from the server
	 */
	onEvent: function () {
		this.refresh();
	},
	/**
	 * Handler function for the change(select) event on the grid
	 * @param {Object} e The change event object
	 */
	onRowExpand: function (e) {
		var selectedRow = $(e.sender.select());
		selectedRow.find('.expand').toggleClass('ellipsis');
	},

	/**
	 * Handler for the service name click
	 * @param {Object} e The click event
	 */
	onServiceNameClick: function (e) {
		var serviceId = $(e.currentTarget).attr('data-id');
		var accountId = this.dataSource.get(serviceId).accountId;
		var accountName = this.dataSource.get(serviceId).accountName;
		if(this.redirectConfig?.doDefaultRedirect)
			State.currentApp.tryExit('ServiceDetails', serviceId, {
				accountId: accountId,
				accountName: accountName
			}, undefined, this.redirectConfig?.defaultRedirectInNewTab);
		e.stopPropagation();
	},
	/**
	 * Handler function for the click event on the asset health from buckets/grid
	 * @param {Object} e The click event object
	 */
	onServiceStateClick: function (e) {
		e.stopPropagation();
		var target = $(e.currentTarget);
		var serviceUid = target.closest('tr').attr('data-uid');
		var record = this.dataSource.getByUid(serviceUid);
		if(this.redirectConfig?.doDefaultRedirect)
			State.mainApp?.navigate(ServicesRouter.viewer(record.id), {newTab: this.redirectConfig?.defaultRedirectInNewTab});
	},
	/**
	 * Handler function for the click event on the sla indicator
	 * @param {Object} e The click event
	 */
	onSlaIndicatorClick: function (e) {
		var target = $(e.currentTarget);
		var serviceUid = target.closest('tr').attr('data-uid');
		var record = this.dataSource.getByUid(serviceUid);

		if(this.redirectConfig?.doDefaultRedirect)
			State.currentApp.tryExit('SLAs', '', {
				isView: true,
				serviceId: record.serviceId,
				preferences: {
					sort: [{
						field: 'statusIndex',
						dir: 'desc'
					}],
					filter: []
				}

			}, undefined, this.redirectConfig?.defaultRedirectInNewTab);
	},
	/**
	 * Handler function for the click event on the health indicator
	 * @param {Object} e The click event
	 */
	onHealthIndicatorClick: function (e) {
		var target = $(e.currentTarget);
		var serviceUid = target.closest('tr').attr('data-uid');
		var record = this.dataSource.getByUid(serviceUid);

		var accounts = [record.accountId];
		var accountName = record.accountName;
		var serviceId = record.id;
		if(this.redirectConfig?.doDefaultRedirect)
			State.currentApp.tryExit('AssetHealthSummary', '', {
				serviceId: serviceId,
				source: 'service_assets',
				accountList: accounts,
				includeSubaccounts: State.includeSubaccounts,
				accountName: accountName,
				isView: true,
				preferences: {
					sort: [{
						field: 'statusIndex',
						dir: 'desc'
					}],
					filter: []
				}
			}, undefined, this.redirectConfig?.defaultRedirectInNewTab);
	},
	/**
	 * Handler function for the click event on the incidents link
	 * @param {Object} e The click event
	 */
	onIncidentsLink: function (e) {
		var target = $(e.currentTarget);
		var serviceUid = target.closest('tr').attr('data-uid');
		var record = this.dataSource.getByUid(serviceUid);
		if (target.text() !== '0' && this.redirectConfig?.doDefaultRedirect) {
			State.currentApp.tryExit('Incidents', record.accountId, {
				filter: 'SERVICE',
				serviceAccountId: record.accountId,
				serviceId: record.id
			}, undefined, this.redirectConfig?.defaultRedirectInNewTab);
		}
	},

	/**
	 * Refresh the widget
	 */
	refresh: function () {
		this.grid.dataSource.transport.options.read.url = this.requestPath + 'accounts/' + this.configuration.accountId + '/services/serviceDetails?includeSubaccounts=' + this.configuration.includeSubaccounts.toString();
		this.grid.dataSource.read();
	},
	/**
	 * Triggered after widget resize
	 * @param {Object} event The resize event
	 * @param {Object} ui The UI element - see http://api.jqueryui.com/resizable/
	 */
	onResize: function (event, ui) {
		this.adjustSectionHeight(this.id);
	},
	/**
	 * Method by Andy
	 * @param {String} id The section id
	 */
	adjustSectionHeight: function (id) {
		let gridHeight, gridContentHeight;
		if (this.isDashboardMode() && !this.isWithoutHeader()) {
			gridHeight = $('#' + id + ' > .section > .section__content').height()
		} else {
			gridHeight = $('#' + id).height();
		}
		gridContentHeight = gridHeight - 30;

		let section = $('#' + id);
		section.find('.cw_section_content').css('height', gridHeight);
		section.find('.k-grid-content').css('height', gridContentHeight);

		let shownContent = section.find('.k-virtual-scrollable-wrap');
		let contentWidth = section.find('.k-grid-content').width();
		let actualContentHeight = section.find('.k-grid-content table').height();
		let shownContentHeight = shownContent.height();
		if (actualContentHeight < shownContentHeight) {
			section.find('.k-scrollbar-vertical').addClass('hide');
			shownContent.css('width', contentWidth + 18 + 'px');
		} else {
			section.find('.k-scrollbar-vertical').removeClass('hide');
		}
	},

	getSubscriptionConfig() {
		if( this.referenceServiceId ) {
			return [{
				eventType: 'ServiceSummary',
				referenceServiceId: this.referenceServiceId
			}, {
				eventType: 'ServiceAdmin',
				actionTypes: ['SERVICE_UPDATE', 'SERVICE_DELETE'],
				referenceServiceId: this.referenceServiceId
			}];
		}
		return [{
			eventType: 'ServiceSummary',
			accountId: this.configuration.accountId,
			includeSubaccounts: this.configuration.includeSubaccounts
		}, {
			eventType: 'ServiceAdmin',
			actionTypes: ['SERVICE_UPDATE', 'SERVICE_DELETE'],
			accountId: this.configuration.accountId,
			includeSubaccounts: this.configuration.includeSubaccounts
		}];
	},
	/**
	 * Subscribes to server events
	 */
	subscribe: function () {
		const throttledHandler = throttle(e => this.onEvent(e), 10_000);
		this.subscription = RemoteEventsManager.subscribeCallback(this.getSubscriptionConfig(), throttledHandler);
		this.initKendoComponents();
	},

	unsubscribe: function () {
		this.subscription?.unsubscribe();
	},
	/**
	 * Destroy
	 */
	destroy: function () {
		this.actions?.updateSettings({
			test: "kva"
		});
		$(window).off('resize', $.proxy(this.onResize, this));
		Widget.prototype.destroy.call(this);
	},

	getStateForSaving(){
		return getGridStateForSaving(this.grid);
	}
});
