import {autorun, computed, IReactionDisposer, makeObservable, observable, reaction} from "mobx";
import {KeysMatching} from "../tools/types";

const i = require('core/localization').translator();

export interface ValidatableModel<TModel extends object>{
	validator: ModelValidator<TModel>
}

export interface BindingProps{
	value: any,
	onChange: (value: any ) => void,
	invalid: boolean,
	errors: string[]
}

export function linkModel<TModel extends object, U extends keyof TModel>(model: TModel, property: U){
	let validatableModel = model as ValidatableModel<TModel>
	let result = {
		value: model[property],
		onChange: (value: any) => model[property] = value
	} as BindingProps

	if(validatableModel.validator){
		result.invalid = !validatableModel.validator.isFieldValid(property);
		result.errors = validatableModel.validator.getErrors(property)
	}

	return result;
}

class ValidationRule{
	callback: () => boolean;
	message: string;
	valid: boolean;

	constructor(init?: Partial<ValidationRule>) {
		Object.assign(this, init);
		makeObservable(this, {
			valid: observable
		})
	}
}

class FieldValidator {
	required: boolean = false;
	rules: ValidationRule[] = [];
	autorunDisposer: IReactionDisposer;

	get valid(){
		return this.rules.every(x => x.valid)
	}

	get errors(){
		return this.rules.filter(x => !x.valid).map( x => x.message);
	}

	constructor(init?: Partial<FieldValidator>) {
		Object.assign(this, init);

		makeObservable(this, {
			valid: computed,
			errors: computed,
			rules: observable
		});

		this.autorunDisposer = autorun(() => {
			this.rules.forEach( x => x.valid = x.callback())
		});
	}
}

export class ModelValidator<TModel extends object> {
	model: TModel;

	validations: {
		[TField in keyof TModel]?: FieldValidator;
	}

	private fieldsWithSubValidators: string[] = [];

	get valid(): boolean{
		const selfValid = Object.entries<FieldValidator>(this.validations)
			.every(([fieldName, validator]) => validator.valid);

		// @ts-ignore
		const subValidatorsValid = this.fieldsWithSubValidators.every(field => this.model[field].validator.valid);

		return subValidatorsValid && selfValid;
	}

	constructor(model: TModel) {
		this.validations = {};
		this.model = model;

		makeObservable(this, {
			validations: observable,
			valid: computed
		});

		Object.entries(this.model).forEach(([fieldName, fieldValue]) => {
			if( fieldValue && typeof fieldValue == 'object' && ('validator' in fieldValue)) {
				this.fieldsWithSubValidators.push(fieldName)
			}
		}, true);
	}

	add<TField extends keyof TModel>(field: TField,
	                                 validationCallback: () => boolean,
	                                 message: string = ""): ModelValidator<TModel> {
		if (this.validations[field] == null) {
			this.validations[field] = new FieldValidator();
		}

		const validator = new ValidationRule({
			callback: validationCallback,
			message: message
		});

		this.validations[field].rules.push(validator);

		return this;
	}

	required<TField extends keyof TModel>(field: TField, applyIf?: () => boolean, message?: string): ModelValidator<TModel> {
		if (message == null) {
			message = i('Please fill in the field')
		}

		this.add(field, () => {
			if(applyIf != null && !applyIf())
				return true;

			const value = this.model[field];
			if(value == null)
				return false;

			if(typeof value === 'string' && value.trim() == ''){
				return false;
			}

			return true;
		}, message)

		return this;
	}

	between<TField extends KeysMatching<TModel, number>>(field: TField, from: number, to: number, includeBorders: boolean = true, message?: string) {
		if (!message) {
			message = i('The input value should be between {0} and {1}', from, to);
		}

		this.add(field, () => {
			const value = this.model[field];
			if(includeBorders) {
				return value >= from && value <= to;
			} else {
				return value > from && value < to;
			}
		}, message);
		return this;
	}

	// validate<TField extends keyof TModel>(field: TField) {
	// 	const value = this.model[field];
	// 	const v = this.validations[field];
	//
	// 	for (const customValidator of v.rules) {
	// 		if (!customValidator.callback()) {
	// 			v.result.push(customValidator.message);
	// 		}
	// 	}
	//
	// 	return v.result.length == 0;
	// }

	isFieldValid<TField extends  keyof TModel>(field: TField) : boolean{
		// if(field == null){
		// 	const selfValid = Object.keys(this.validations)
		// 		.reduce((prev:boolean, key) => this.isValid(key as TField) && prev, true);
		//
		// 	return Object.keys(this.model).reduce((prev:boolean, key) => {
		// 		const value = this.model[key as TField];
		// 		if( value && typeof value == 'object' && ('validator' in value)){
		// 			// @ts-ignore
		// 			return value.validator.isValid() && prev;
		// 		}else {
		// 			return prev;
		// 		}
		// 	}, true) && selfValid;
		//
		// }else {
		if(this.validations[field] == null)
			return true;

		return this.validations[field].valid
		//}
	}

	getErrors<TField extends  keyof TModel>(field: TField) : string[] {
		if(this.validations[field] == null)
			return [];

		return this.validations[field].errors;
	}

	hasValidation<TField extends  keyof TModel>(field: TField) : boolean {
		return this.validations[field]?.rules.length > 0;
	}


	destroy(){
		Object.entries<FieldValidator>(this.validations)
			.forEach(([fieldName, validator]) => validator.autorunDisposer());
	}
}
