import Utils from 'tools/utils'
import Chosen from 'controls/chosen'
import Cookies from 'core/cookies'
import Settings from 'settings'
import Lang from "core/localization/lang";

/**
 * @class Tags This is the class that renders tags controls within forms
 * in application
 * @namespace ceeview.controls
 * @constructor
 */
export let Tags = function (config) {
	this.enabled = true;
	this.type = 'DEFAULT'; //ASSET_GROUP
	Utils.apply(this, config);
	this.accountId = this.accountId || Cookies.CeesoftCurrentAccountId;

	this._initComponent();
	$(this.renderEl).data('tags', this);
};

export default Tags;

Tags.prototype = {
	autoBind: true,
	_initComponent: function () {
		this._render();

	},

	_render: function () {
		this.control = new Chosen({
			dataSource: this.autoBind ? this.getDataSource() : [],
			renderEl: this.renderEl,
			placeholder: this.placeholder || Lang.messages.NO_TAGS,
			dataTextField: 'name',
			dataValueField: 'id',
			enabled: this.enabled,
			change: e =>  {
				if (this.change) {
					this.change.call(this, event);
				}
			},

			onChange: value => {
				if(this.onChange){
					this.onChange(this.getRawValues());
				}
			}
		});
	},
	/*
	 * Handler function for getting the user tags datasource
	 * @return kendo dataSource
	 * */
	getDataSource: function () {
		if(!this.accountId)
			return [];

		var url = Settings.serverPath + 'accounts/' + this.accountId;
		if (this.type === 'ASSET_GROUP') {
			url += '/assetGroups/' + this.assetGroupId;
		}
		url += '/tags?includeSubaccounts=' + !!this.includeSubaccounts;

		return new kendo.ceeview.DataSource({
			transport: {
				read: {
					url: url,
					contentType: "application/json; charset=utf-8",
					type: "GET",
					dataType: "json",
					cache: false
				}
			},
			schema: {
				data: function (response) {
					var result;
					if (response.success) {
						result = response.data;
					} else if (response.length) {
						result = response;
					} else {
						result = [];
					}

					for (var i = 0, length = result.length; i < length; i++) {
						result[i] = {
							text: result[i],
							value: result[i],
							editable: false,
							removable: false,
							destroyable: false
						};
					}

					return result;
				}
			}
		});
	},

	enable: function (bool) {
		this.control.enable(bool);
	},

	setValues: function (array) {
		this.control.setValues(array);
	},

	setRawValues: function (rawArray) {
		this.setValues(rawArray.map(x => {
			return {
				id: x,
				name: x.trim(),
				editable: true
			}
		}));
	},

	getValues: function () {
		return this.control.getValues();
	},

	getRawValues: function () {
		return this.control.getValues().map( x => x.name);
	},

	readonly: function (bool) {
		this.control.readonly(bool);
	},

	setOptions: function (options) {
		if(this.accountId != options.accountId || this.includeSubaccounts != options.includeSubaccounts){
			this.accountId = options.accountId;
			this.includeSubaccounts = options.includeSubaccounts;
			this.control.setDataSource(this.getDataSource());
		}

		Utils.apply(this, options);

		return this;
	}
};
