import {fromJS} from 'immutable';

import {prepareFormMaps} from './utils/prepareFormMaps';
import {reformatFormsAndGroups} from './utils/reformatFormsAndGroups';
import {prepareInitialForm} from './utils/prepareInitialForm';
import {mapFieldToFormDependencies} from './utils/mapFieldToFormDependencies';
import {reformatProperties} from './utils/reformatProperties';
import {mapFieldsToForm} from './utils/mapFieldsToForm';
import {populateAllDependencies} from './utils/populateAllDependencies';

import {VIRTUAL_DEPENDENCIES, VALID_DEPENDENCY_TYPES} from '../constants';

function invertDependencies(dependencies) {
	const out = {};

	for (const [key, deps] of Object.entries(dependencies)) {
		for (const dep of deps) {
			out[dep] = out[dep] || [];
			out[dep].push(key);
		}
	}

	return out;
}

export function setData(state, action) {
    const {data, values, persistedValues, timezone} = action;
    const standalone = data.standalone;
    const {properties, forms: originalForms} = data.configuration;
    const itemId = data.id;

    state = state.setIn(['itemId'], itemId)
        .setIn(['items', itemId, 'original'], fromJS(data))
        .setIn(['items', itemId, 'preparedForm'], prepareInitialForm(state, data, values, persistedValues))
		.setIn(['timezone'], timezone)
		.setIn(['standalone'], standalone);

    const {groups, plain: fields, info} = reformatProperties(properties, mapFieldsToForm(originalForms));
    const {forms, groupToForm} = reformatFormsAndGroups(originalForms, fields);

    const {fieldToForm, formToFields, formToDependencyFields} = prepareFormMaps(groups, groupToForm, fields);

    const fieldToFormDependencies = mapFieldToFormDependencies(forms, groups, formToDependencyFields);

    const dependencies = {};
	const directDependencies = {};

    for (let property of Object.values(fields)) {
        const {id, dependsOn} = property;

        if (dependsOn) {
			directDependencies[id] = dependencies[id] = [dependsOn];
        } else {
			directDependencies[id] = dependencies[id] = [];
        }
    }

    for (let key of Object.keys(dependencies)) {
    	const direct = populateAllDependencies(key, directDependencies, directDependencies[key]);
        const fieldDependencies = populateAllDependencies(key, dependencies, dependencies[key]);
        const formDependencies = fieldToFormDependencies[key];
        const uniqueFields = Array.from(new Set([].concat(fieldDependencies, formDependencies)));

        directDependencies[key] = direct;
        dependencies[key] = uniqueFields.filter((field) => {
            if(VIRTUAL_DEPENDENCIES.has(key) && VIRTUAL_DEPENDENCIES.get(key).includes(field)) {
                return true;
            }
            if(fields[field] && VALID_DEPENDENCY_TYPES.includes(fields[field].type)) {
                return true;
            }

            return false;
        });
    }

	const directDownStreamDependencies = invertDependencies(directDependencies);

    return state.setIn(['items', itemId, 'dependencies'], fromJS(dependencies))
		.setIn(['items', itemId, 'dependentFields'], fromJS(directDownStreamDependencies))
        .setIn(['items', itemId, 'fieldToForm'], fromJS(fieldToForm))
        .setIn(['items', itemId, 'formToFields'], fromJS(formToFields))
        .setIn(['items', itemId, 'formToDependencyFields'], fromJS(formToDependencyFields))
        .setIn(['items', itemId, 'fields'], fromJS(fields))
        .setIn(['items', itemId, 'info'], fromJS(info))
        .setIn(['items', itemId, 'groups'], fromJS(groups))
        .setIn(['items', itemId, 'forms'], fromJS(forms));
};

