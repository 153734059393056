import ReactDOM from 'react-dom';
import React from "react";

import {Window} from "controls/react/kendoWrappers";
import {ReasonsGrid} from "areas/application/reasonsGrid";

export async function showReasonsGridWindow(loadReasonsCallback, windowTitle, windowLeft, windowTop, windowId) {
	const container = document.createElement('div');
	container.id = windowId;
	document.body.appendChild(container);

	const resolveWrapper = () => {
		ReactDOM.unmountComponentAtNode(container);
	}

	const defaultSort = [{
		field: 'severity',
		dir: 'asc'
	}, {
		field: 'timestamp',
		dir: 'desc',
		compare: null
	}];

	const windowWidth = 800;
	const windowHeight = 400;

	if (windowTop + windowHeight > window.innerHeight) {
		windowTop = windowTop - (windowTop + windowHeight - window.innerHeight) - 35; //35 to cover kendo window header and border/paddings
	}
	if (windowLeft + windowWidth > window.innerWidth) {
		windowLeft = windowLeft - (windowLeft + windowWidth - window.innerWidth);
	}

	ReactDOM.render(
		<Window width={windowWidth}
				height={windowHeight}
				left={windowLeft}
				top={windowTop}
				modal={true}
				title={windowTitle}
				contentClass={'k-window-content__flex'}
				actions={['Close']}
				onClose={resolveWrapper}>
			<ReasonsGrid loadReasonsCallback={loadReasonsCallback}
						 readOnly={true}
						 defaultSort={defaultSort}
						 userPrefsKey={"ReasonsPopup"}
			/>
		</Window>,
		container
	);

	State.mainApp.registerForOnNavigate(() => {
		resolveWrapper();
	});
}
