import {GridMenu} from 'controls';
import {DISK_VIEW} from "areas/assets/monitors/system/shared";

export default class DiskMenu {
	constructor(type, prefix, mode) {
		this.type = type;
		this.prefix = prefix;
		this.mode = mode;
	}

	create(onDiskReload, onDiskMonitor, onDiskConfigRemove, onDiskAssetRemove, onShareAdd, onShareDelete, onShareEdit) {
		const items = [];

		this.type === DISK_VIEW.SHARED_DISK && items.push({
			id: 'cw_add',
			icon: 'plus-sign',
			text: lang.ADD,
			fn: onShareAdd,
			scope: this,
			disabled: this.mode === 'view'
		});

		this.type === DISK_VIEW.SHARED_DISK && items.push({
			id: 'cw_edit',
			icon: 'pencil',
			text: lang.EDIT,
			fn: onShareEdit,
			scope: this,
			disabled: true
		});

		this.type === DISK_VIEW.SHARED_DISK && items.push({
			id: 'cw_delete',
			icon: 'bin',
			text: lang.DELETE,
			fn: onShareDelete,
			scope: this,
			disabled: true
		});

		items.push({
			id: 'cw_default_monitoring',
			icon: 'disk-saved',
			text: lang.DEFAULT_MONITORING,
			fn: onDiskMonitor,
			scope: this,
			disabled: true
		});

		items.push({
			id: 'cw_custom_monitoring',
			icon: 'disk-import',
			text: lang.CUSTOM_MONITORING,
			fn: onDiskMonitor,
			scope: this,
			disabled: true
		})

		if (this.type === DISK_VIEW.DISK) {
			items.push({
				id: 'cw_reload',
				icon: 'restart',
				text: lang.REDISCOVER_DISK,
				fn: onDiskReload,
				scope: this,
				disabled: this.mode === 'view'
			})

			items.push({
				id: 'cw_non_monitoring',
				icon: 'disk-remove',
				text: lang.NONE_MONITORING,
				fn: onDiskMonitor,
				scope: this,
				disabled: true
			})
			items.push(
				{
					id: 'cw_remove_disk_config',
					icon: 'bin',
					text: lang.monitor.system.REMOVE_DISK_CONFIG,
					fn: onDiskConfigRemove,
					scope: this,
					disabled: true
				})
			items.push(
				{
					id: 'cw_remove_disk_asset',
					icon: 'bin',
					text: lang.monitor.system.REMOVE_DISK_ASSET,
					fn: onDiskAssetRemove,
					scope: this,
					disabled: true
				}
			)
		}

		this.diskMenu = new GridMenu({
			renderTo: `cw_${this.prefix}disk_menu`,
			items
		});

		$(`.cw_${this.prefix}disk_list`)
			.on('click', `.cw_grid_check`, this.onDriveCheck.bind(this))
			.on('click', `.cw_grid_check_all`, this.onDriveCheckAll.bind(this));

		return this.diskMenu;
	}

	onDriveCheck(e) {
		e && e.stopPropagation();

		const checkedItems = $(`.cw_${this.prefix}disk_list .cw_grid_check:checked`);

		if (checkedItems.length) {
			this.diskMenu.enableItem('cw_default_monitoring');
			this.diskMenu.enableItem('cw_custom_monitoring');
			this.diskMenu.enableItem('cw_non_monitoring');
			this.diskMenu.enableItem('cw_remove_disk_config');

			if (checkedItems.length === 1) {
				this.diskMenu.enableItem('cw_edit');
				this.diskMenu.enableItem('cw_delete')
			}
			else {
				this.diskMenu.disableItem('cw_edit');
				this.diskMenu.disableItem('cw_delete')
			}

			if (checkedItems.length === 1 && this.isGroupSelected) {
				this.diskMenu.enableItem('cw_remove_disk_asset');
			} else {
				this.diskMenu.disableItem('cw_remove_disk_asset');
			}
		} else {
			this.diskMenu.disableItem('cw_default_monitoring');
			this.diskMenu.disableItem('cw_custom_monitoring');
			this.diskMenu.disableItem('cw_non_monitoring');
			this.diskMenu.disableItem('cw_remove_disk_config');
			this.diskMenu.disableItem('cw_remove_disk_asset');
			this.diskMenu.disableItem('cw_edit');
			this.diskMenu.disableItem('cw_delete')
		}
	}

	onDriveCheckAll(e) {
		e.stopPropagation();
		const target = $(e.currentTarget);
		const checked = target.is(':checked');

		$(`.cw_${this.prefix}disk_list .cw_grid_check`).prop('checked', checked);

		this.onDriveCheck();
	}

}
