import {ApiRequest} from "framework/api";
import {Api} from "tools/api";
import {Budget} from "areas/cost/budget/budgetTypes";
import {CostBudgetData} from "./models/costBudget";
import {CostProfile} from './models/costProfile'
import {UrlBuilder} from "../../tools";

export class CostsApi {
	static listUrl(accountId: string = null) {
		return Api.accountRoot(accountId) + 'cost/profiles/';
	}

	static list(accountId: string) {
		return Api.fetch(CostsApi.listUrl(accountId));
	}

	static delete(costIds: string[]) {
		return Api.fetchPost(CostsApi.listUrl() + 'delete', costIds);
	}

	static save(costProfile: CostProfile) {
		return Api.fetchPost(CostsApi.listUrl(), costProfile);
	}

	// TODO check if Budget is correct type
	static async saveLink(budget: Budget, creationType: 'NEW'|'COST_COLLECTOR'|'DUPLICATE') {
		const url = CostsApi.listUrl() + creationType;
		return Api.fetchPost(url, budget);
	}

	static details(costId: string) {
		return Api.fetch(CostsApi.listUrl() + costId )
	}

	static costModelList(accountId: string, modelId: string = null) {
		let url = Api.accountRoot(accountId) + 'cost/models'
		if (modelId) {
			url += `?linkModelId=${modelId}`
		}
		return Api.fetch(url);
	}

	static subAccountList(accountId: string) {
		return Api.fetch(Api.accountRoot(accountId) + 'subaccounts/allLevels/lite');
	}

	static subAccountProfilesList(accountId: string) {
		return Api.fetch(Api.accountRoot(accountId) + 'cost/profiles/lite');
	}

	static subAccountProfileModelsList(accountId: string, profileId: string, currentModelId: string = null) {
		let url = Api.accountRoot(accountId) + `cost/profiles/${profileId}/models`;
		if (!!currentModelId) {
			url += `?linkModelId=${currentModelId}`;
		}
		return Api.fetch(url);
	}

	static costModelProfilesList(accountId: string, modelId: string) {
		const url = Api.accountRoot(accountId) + `cost/models/${modelId}/profiles`;
		return Api.fetch(url);
	}

	static costModelDetails(modelId: string, currency: string, startDate: string) {
		return Api.fetch(Api.accountRoot()+`cost/models/${modelId}?currency=${currency}&startDate=${startDate}`);
	}

	static cloudConnections(accountId: string) {
		return Api.fetch(`${Api.server}accounts/${accountId}/cost/connections`);
	}
}

export function listCostProfiles(accountId: string){
	return new ApiRequest<CostProfile[]>({
		url: 'cost/profiles',
		accountBased: true,
		accountId: accountId
	})
}

export function getCostProfile(profileId: string, modelId?: string, accountId?: string, currency?: string) {
	const url = new UrlBuilder(`cost/profiles/${profileId}`)
		.add('modelId', modelId, !!modelId)
		.add('currency', currency, !!currency)
		.build();
	return new ApiRequest<CostProfile>({
		url,
		accountId,
		accountBased: true,
		responseType: CostProfile
	});
}

export function getCostBudget(profileId: string, modelId: string, budgetId: string, accountId?: string) {
	return new ApiRequest<CostBudgetData>({
		url: `cost/profiles/${profileId}/models/${modelId}/budgets/${budgetId}`,
		accountId: accountId,
		accountBased: true,
		responseType: CostBudgetData
	})
}

export function getCurrencies(accountId?: string){
	return new ApiRequest({
		url: 'cost/currencies',
		accountId: accountId,
		accountBased: true
	})
}

export function list(accountId?: string, costType?: string) {
	let url = 'cost/profiles/';
	costType && (url = `${url}types/${costType}`);
	return new ApiRequest<CostProfile[]>({
		url,
		accountId,
		accountBased: true,
		includeSubaccounts: true,
	})
}
