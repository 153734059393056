import RepositoryWindow from 'controls/repositoryWindow';
import Utils from 'tools/utils';
import Cookies from 'core/cookies';
import Settings from 'settings';
import CustomNotification from 'controls/customNotification';
import LocalEventsManager from 'core/localEventsManager';
import Renderer from 'tools/renderer';
import GridMenu from 'controls/gridMenu';
import EventsToolbar from 'controls/eventsToolbar';
import PreferencesPanel from 'controls/preferencesPanel';
import State from 'tools/state';
import UserPrefs from 'tools/userPrefs';
import Application from 'core/application';
import MaintenancePeriod from 'controls/maintenancePeriod';
import ModalWindow from 'controls/modalWindow';
import RemoteEventsManager from 'core/remoteEventsManager';
import Dependencies from 'controls/dependencies.js';
import {Url} from 'tools/api';
import AgentForm from 'areas/management/agentForm.js';
import Dialog from 'controls/dialog';
import RunScriptWindow from 'areas/management/runScriptWindow.js';
import ErrorHandler from 'core/errorHandler';
import {ServiceDesignerRouter} from 'areas/services/designer/bundleDescription';
import {AgentsRouter} from './agents/bundleDescription';
import {getAgentStateAgents} from 'controls/stateRenderer/agentState';
import Api from 'tools/api';
import MonitorsApi from "areas/assets/monitors/api";
import {topLeftCornerPosition} from 'controls/modalWindow';

export let AgentsView = function (config) {
	Utils.apply(this, config);
	this.module = {
		name: 'AgentsView',
		initialConfig: config
	};
	this.hasEvents = true;
	this.subscriberId = Utils.guid();
	this.initComponent();
};

export default AgentsView;

jQuery.extend(AgentsView.prototype, Application.prototype, {
	/**
	 * @cfg {Object} customFilter (Optional) Filter object for the grid. If set, it is applied before other filters
	 */
	/**
	 * Main init function
	 */
	initComponent: function () {
		Application.prototype.initComponent.call(this);


		this.showGridView = true;
		this.actionNotification = new CustomNotification({
			appendToElement: '#cw_agents_list',
		});

		State.mainApp.contextMenu.setActionButton('ASSET');

		this.removeListeners();
		this.attachListeners();
		if (!State.agentStatusTypes || State.agentStatusTypes.length === 0) {
			this.getStatusTypes();
		} else {
			this.loadUserPreferences();
		}
		this.removeMask();
	},
	initKendoComponents: function () {
		var agentsFilter, agentsSort, filterMessages = lang.grid.filter, url;
		var defaultFsView = UserPrefs.get('defaultFsView');

		this.defaultColumns = {
			id: {
				hidden: false,
				width: 40
			},
			agentStateSort: {
				hidden: false,
				width: 60
			},
			name: {
				hidden: false,
				width: 300
			},
			parentName: {
				hidden: false,
				width: 300
			},
			accountName: {
				hidden: !State.includeSubaccounts,
				width: 160
			},
			typeText: {
				hidden: false,
				width: 150
			},
			lastConnected: {
				hidden: false,
				width: 220
			},
			agentStatus: {
				hidden: false,
				width: 160
			},
			autoUpdate: {
				hidden: false,
				width: 160
			},
			allowSubAgents: {
				hidden: false,
				width: 160
			},
			allowDataCollection: {
				hidden: false,
				width: 200
			},
			shared: {
				hidden: false,
				width: 160
			},
			version: {
				hidden: false,
				width: 300
			},
			description: {
				hidden: false
			}
		};
		this.gridMessages = {
			isTrue: '<span class="glyphicons ok cw_true"></span>' + lang.YES,
			isFalse: '<span class="glyphicons remove cw_false"></span>' + lang.NO,
			clear: lang.CLEAR,
			info: lang.grid.filter.SHOW_ITEMS,
			filter: lang.FILTER
		};

		if (Utils.isGuid(defaultFsView)) {
			var pref = JSON.parse(UserPrefs.get(defaultFsView));
			if (pref) {
				agentsSort = pref.sort;
				agentsFilter = pref.filter;
				this.agentsColumns = pref.columns;
			} else {
				agentsSort = [];
				agentsFilter = [];
			}

		} else {
			if (UserPrefs.get('agentsSort')) {
				agentsSort = JSON.parse(UserPrefs.get('agentsSort'));
			} else {
				agentsSort = [{
					field: 'alive',
					dir: 'desc',
					compare: null
				}];
			}
			if (UserPrefs.get('agentsFilter')) {
				agentsFilter = JSON.parse(UserPrefs.get('agentsFilter'));
			} else {
				agentsFilter = [];
			}
		}
		agentsFilter = this.customFilter || agentsFilter;
		var agentsColumns = this.agentsColumns || JSON.parse(UserPrefs.get('agentsColumns')) || this.defaultColumns;
		agentsColumns = Utils.completeColumns(agentsColumns, this.defaultColumns);

		url = Settings.serverPath + 'accounts/';
		if (this.byAccountId) {
			url += this.byAccountId + '/agents/summary?includeSubaccounts=false';
		} else if (this.byTagName) {
			url += Cookies.CeesoftCurrentAccountId + '/summary/tags/' + this.byTagName + '/agents?includeSubaccounts=' + State.includeSubaccounts.toString();
		} else {
			url += Cookies.CeesoftCurrentAccountId + '/agents/summary?includeSubaccounts=' + State.includeSubaccounts.toString();
		}

		this.agentsDataSource = new kendo.ceeview.DataSource({
			transport: {
				read: {
					url: url,
					dataType: 'json',
					contentType: 'application/json; charset=utf-8',
					type: 'GET',
					cache: false
				}
			},
			sort: agentsSort,
			filter: agentsFilter,
			schema: {
				model: {
					id: 'id',
					fields: {
						alive: {
							type: 'boolean'
						},
						agentStateSort: {
							type: 'string'
						},
						allowSubAgents: {
							type: 'boolean'
						},
						allowDataCollection: {
							type: 'boolean'
						},
						typeText: {
							type: 'string'
						},
						lastConnected: {
							type: 'date'
						},
						agentStatus: {
							type: 'string'
						},
						autoUpdate: {
							type: 'boolean'
						},
						shared: {
							type: 'boolean'
						}
					}
				},
				parse: function (data) {
					for (var i = 0, length = data.length; i < length; i++) {
						data[i].aliveIndex = data[i].agentState === 'OK' ? 1 : 0;
						data[i].alive = data[i].agentState === 'OK' ? true : false;
						data[i].lastConnected = new Date(data[i].lastConnect);
						if (data[i].agentStatus === 'MAINTENANCE') {
							data[i].agentStateSort = 'MAINTENANCE';
						} else {
							data[i].agentStateSort = data[i].agentState;
						}
					}

					return data;
				}
			}
		});
		var agentStateTemplate = '';
		if (State.mainApp.session.hasRole('AGENT_READ')) {
			//agentStateTemplate += '#=Renderer.agentState(agentState, agentStatus, lastConnect)#';
			agentStateTemplate = item => getAgentStateAgents(item.agentState, item.agentStatus, item.lastConnect);
		}

		this.grid = $('#cw_agents_list').kendoCustomGrid({
			dataSource: this.agentsDataSource,
			resizable: true,
			reorderable: true,
			selectable: 'row',
			sortable: {
				mode: 'multiple',
				allowUnsort: true
			},
			filterable: {
				extra: false,
				operators: {
					string: {
						startswith: filterMessages.STARTS_WITH,
						neq: filterMessages.NEQ,
						eq: filterMessages.EQ,
						contains: filterMessages.CONTAINS
					},
					number: {
						eq: filterMessages.EQ,
						neq: filterMessages.NEQ
					}
				},
				messages: this.gridMessages
			},
			columns: Utils.rearrangeColumns([{
				field: 'id',
				title: lang.SELECTOR,
				sortable: false,
				filterable: false,
				template: '<input #= serverAgent ? "disabled" : "" # type="checkbox" class="#= !serverAgent ? "cw_grid_check" : "" #" data-id="#= id #" data-hasSubAgents="#= hasSubAgents #" data-name="#=name#" />',
				headerTemplate: '<input type="checkbox" class="cw_grid_check_all" />',
				attributes: {
					'class': 'text_center'
				},
				headerAttributes: {
					'class': 'text_center'
				},
				hidden: agentsColumns.id.hidden,
				width: agentsColumns.id.width
			}, {
				field: 'agentStateSort',
				title: lang.STATE,
				template: agentStateTemplate,
				headerTemplate: '',
				attributes: {
					'class': 'text_center'
				},
				filterable: {
					ui: $.proxy(this.getFilterableForState, {scope: this, field: 'agentStateSort'}),
					messages: this.gridMessages
				},
				valueRenderer: function (value) {
					var data = ['remove-sign', 'ok-sign'];
					return '<span class="glyphicons status_icon ' + data[value] + '"></span>';
				},
				hidden: agentsColumns.agentStateSort.hidden,
				width: agentsColumns.agentStateSort.width
			}, {
				field: 'name',
				title: lang.NAME,
				template: item => {
					return `<a class="cw_grid_link ellipsis to_expand" href="#${AgentsRouter.details(item.id)}">${item.name}</a>`;
				},
				hidden: agentsColumns.name.hidden,
				width: agentsColumns.name.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'parentName',
				title: lang.PARENT_AGENT,
				template: '#= parentName ? "<a class=\'cw_grid_link cw_parent_agent\' data-id=\'" + parentId + "\'>" + parentName + "</a>" : ""#',
				hidden: agentsColumns.parentName.hidden,
				width: agentsColumns.parentName.width,
				filterable: {
					ui: $.proxy(function (element) {
						return Renderer.filterGridByOwnElementDS.call(this, element, 'parentName');
					}, this),
					messages: this.gridMessages,
					extra: false,
					operators: {
						string: {
							neq: filterMessages.NEQ,
							eq: filterMessages.EQ
						}
					}
				},
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'accountName',
				title: lang.ACCOUNT,
				sortable: true,
				filterable: {
					ui: $.proxy(function (element) {
						return Renderer.filterGridByOwnElementDS.call(this, element, 'accountName');
					}, this),
					messages: this.gridMessages,
					extra: false,
					operators: {
						string: {
							neq: filterMessages.NEQ,
							eq: filterMessages.EQ
						}
					}
				},
				template: '<span>#= accountName || "N/A" #</span>',
				hidden: !State.includeSubaccounts || agentsColumns.accountName.hidden,
				width: agentsColumns.accountName.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'typeText',
				title: lang.TYPE,
				sortable: true,
				template: '<span title="#=typeDescription#" class="cw_type_text">#=typeText#</span>',
				filterable: {
					ui: $.proxy(function (element) {
						return Renderer.filterGridByOwnElementDS.call(this, element, 'typeText');
					}, this),
					messages: this.gridMessages,
					extra: false,
					operators: {
						string: {
							neq: filterMessages.NEQ,
							eq: filterMessages.EQ
						}
					}
				},
				hidden: agentsColumns.typeText.hidden,
				width: agentsColumns.typeText.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'lastConnected',
				title: lang.agents.LAST_CONNECTED,
				sortable: true,
				filterable: true,
				template: '<span>#= Renderer.browserDateRenderer(lastConnect, "datetime") #</span>',
				hidden: agentsColumns.lastConnected.hidden,
				width: agentsColumns.lastConnected.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'agentStatus',
				title: lang.STATUS,
				filterable: {
					ui: $.proxy(function (element) {
						var menu = $(element).parent(), dataSource = [], data = this.grid.dataSource.data(),
							length = data.length, filter = this.grid.dataSource.filter(), values = [];
						element.removeAttr("data-bind");

						if (filter) {
							for (var i = 0, length = filter.filters.length; i < length; i++) {
								if (filter.filters[i].field === 'agentStatus') {
									values.push({
										text: filter.filters[i].value,
										value: filter.filters[i].value
									});
								}
							}
						}

						element.kendoSortedMultiSelect({
							dataSource: State.agentStatusTypes || [],
							value: values,
							open: function (e) {
								var dropDown = e.sender, wrapper;
								setTimeout(function () {
									wrapper = dropDown.ul.parent().parent();
									wrapper.addClass('cw_dropdown_reset');
								}, 50);
							},
							dataTextField: 'text',
							dataValueField: 'value',
							optionLabel: lang.grid.FILTER_SELECT_VALUE,
							itemTemplate: '<span>${data.text}</span>',
							tagTemplate: '<span class="cw_multiselect_tag" title="${data.text}">${data.text}</span>',
							dataBound: $.proxy(function (e) {
								menu.find('[type=submit]').off().on('click', $.proxy(function () {
									if (length) {
										var logic = menu.find("[data-role=dropdownlist]").data('kendoDropDownList').value(),
											filter, values = this.multiselectValues;
										if (logic === 'eq') {
											filter = {logic: "or", filters: []};
										} else {
											filter = {logic: "and", filters: []};
										}

										$.each(values, function (i, v) {
											filter.filters.push({field: "agentStatus", operator: logic, value: v});
										});

										this.grid.dataSource.filter(filter);
									}
								}, this));

							}, this),
							change: $.proxy(function (e) {
								this.multiselectValues = e.sender.value();
							}, this)
						}).data('kendoSortedMultiSelect');
					}, this),
					messages: this.gridMessages,
					extra: false,
					operators: {
						string: {
							neq: filterMessages.NEQ,
							eq: filterMessages.EQ
						}
					}
				},
				template: function (record) {
					var state = '';
					var lookUpArray = State.agentStatusTypes || [];
					for (var i = 0; i < lookUpArray.length; i++) {
						if (lookUpArray[i].value === record.agentStatus) {
							state = lookUpArray[i].text;
							break;
						}
					}
					var columnColor = '';
					if (record.agentStatus === 'MAINTENANCE') {
						columnColor += 'cw_badge is_inverted ';
						if (record.agentState === 'OK') {
							columnColor += 'is_ok';
						} else {
							columnColor += 'is_critical';
						}
					}

					return '<span class="ellipsis to_expand ' + columnColor + '">' + state + '</span>';
				},
				hidden: agentsColumns.agentStatus.hidden,
				width: agentsColumns.agentStatus.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'autoUpdate',
				title: lang.agents.AUTO_UPDATE,
				template: '<span class="glyphicons #= agentsAutoUpdate || autoUpdate ? \'ok cw_true\' : \'remove cw_false\' #"></span>',
				hidden: agentsColumns.autoUpdate.hidden,
				width: agentsColumns.autoUpdate.width
			}, {
				field: 'allowSubAgents',
				title: lang.agents.ALLOW_SUBAGENTS,
				template: '<span class="glyphicons #= allowSubAgents ? \'ok cw_true\' : \'remove cw_false\' #"></span>',
				hidden: agentsColumns.allowSubAgents.hidden,
				width: agentsColumns.allowSubAgents.width
			}, {
				field: 'allowDataCollection',
				title: lang.agents.ALLOW_DATA_COLLECTION,
				template: '<span class="glyphicons #= allowDataCollection ? \'ok cw_true\' : \'remove cw_false\' #"></span>',
				hidden: agentsColumns.allowDataCollection.hidden,
				width: agentsColumns.allowDataCollection.width
			}, {
				field: 'shared',
				title: lang.agents.SHARED,
				template: '<span class="glyphicons #= shared ? \'ok cw_true\' : \'remove cw_false\' #"></span>',
				hidden: agentsColumns.shared.hidden,
				width: agentsColumns.shared.width
			}, {
				field: 'version',
				title: lang.agents.AGENT_VERSION,
				hidden: agentsColumns.version.hidden,
				width: agentsColumns.version.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'description',
				title: lang.DESCRIPTION,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				},
				hidden: agentsColumns.description.hidden,
				width: agentsColumns.description.width
			}], agentsColumns),
			columnMenu: true,
			change: $.proxy(this.onRowExpand, this),
			dataBound: function () {
				$('#cw_agents_list').find('.k-grid-content').kendoTooltip({
					filter: "td.tooltip:not(:empty)",
					content: function (event) {
						return event.target.text().split("\n").join("<br />");
					}
				});
				if (!(State.mainApp.session.hasRole('AGENT_READ') && State.mainApp.session.hasRole('ASSET_LIST'))) {
					$('.cw_grid_link').attr('style', 'cursor: default !important').css('color', 'black');
				}
				$('.cw_type_text').each(function () {
					var tooltip = $(this).attr('title');
					$(this).closest('td').attr('title', tooltip);
				});
				if (this.initialAgentsLength && this.agentsDataSource.data().length < this.initialAgentsLength) {
					this.actionNotification.setOptions({
						message: lang.account.messages.AGENT_SUCCESS_DELETED,
						status: 'success'
					}).show();
				}
			}
		}).data('kendoCustomGrid')
		// Add Kendo tooltip to the header of the columns
		Utils.gridColumnHeaderTooltip(this.grid);
		this.adjustSectionHeight();
		this.grid.thead.find("[data-field='id']>.k-header-column-menu").remove();

		this.gridMenu = new GridMenu({
			renderTo: 'cw_agent_grid_menu',
			items: [{
				id: 'cw_agent_delete',
				icon: 'bin',
				text: lang.DELETE,
				fn: this.deleteAgentClick,
				scope: this,
				disabled: false,
				role: 'AGENT_DELETE'
			}, {
				id: 'cw_agent_connect',
				icon: 'plus-sign',
				text: lang.agents.CONNECT_AGENT,
				fn: this.connectAgentClick,
				scope: this,
				disabled: false,
				role: 'AGENT_CREATE'
			}, {
				id: 'cw_agent_update',
				icon: 'refresh',
				text: lang.agents.UPDATE_AGENT,
				fn: this.updateAgentClick,
				scope: this,
				disabled: false,
				role: 'AGENT_UPDATE'
			}, {
				id: 'cw_agent_restart',
				icon: 'restart',
				text: lang.agents.RESTART,
				fn: $.proxy(function () {
					this.restartAgents({
						cache: false
					});
				}, this),
				role: 'AGENT_UPDATE',
				scope: this,
				disabled: false
			}, {
				id: 'cw_agent_restart_cache',
				icon: 'restart',
				text: lang.agents.RESTART_CACHE,
				fn: $.proxy(function () {
					this.restartAgents({
						cache: true
					});
				}, this),
				role: 'AGENT_UPDATE',
				scope: this,
				disabled: false
			}, {
				id: 'cw_agent_run_script',
				icon: 'play',
				text: lang.agents.RUN_SCRIPT,
				fn: this.onRunScript,
				scope: this,
				role: 'AGENT_SCRIPT',
				disabled: false
			}, {
				id: 'cw_set_in_maintenance',
				icon: 'wrench',
				text: lang.SET_IN_MAINTENANCE,
				fn: this.onSetInMaintenance,
				scope: this,
				disabled: false,
				role: 'AGENT_MAINTENANCE_CREATE'
			}, {
				id: 'cw_end_maintenance',
				icon: 'bin',
				text: lang.END_MAINTENANCE,
				fn: this.onEndMaintenance,
				scope: this,
				disabled: false,
				role: 'AGENT_MAINTENANCE_UPDATE'
			}, {
				id: 'cw_install_bundle',
				icon: 'disk-saved',
				text: lang.agents.INSTALL_BUNDLES,
				fn: this.onInstallBundle,
				scope: this,
				disabled: false,
				role: 'AGENT_UPDATE'
			}, {
				id: 'cw_uninstall_bundle',
				icon: 'disk-remove',
				text: lang.agents.UNINSTALL_BUNDLES,
				fn: this.onUninstallBundle,
				scope: this,
				disabled: false,
				role: 'AGENT_UPDATE'
			}]
		});
		var searchValue = UserPrefs.get('defaultFsView') ? '' : (UserPrefs.get('agentsSearchPhrase') || '');
		this.gridFilterPanel = new PreferencesPanel({
			renderTo: 'cw_agents_filters',
			dontLoadDefault: this.accountName ? true : false,
			grid: this.grid,
			modulePrefName: 'Agents',
			defaultPrefViewKey: 'defaultFsView',
			prefListKey: 'agentsFSViews',
			userPref: this.userPref,
			onRemove: $.proxy(this.saveUserPreferences, this),
			searchFields: ['name', 'version', 'accountName', 'description'],
			searchValue: searchValue,
			defaultColumns: this.defaultColumns
		});
		this.removeMask();
	},
	/**
	 * Removes listeners
	 */
	removeListeners: function () {
		$('#cw_agents_list').off();
		LocalEventsManager.unbind('maintenance_period_saved');
	},
	/**
	 * Attaches listeners
	 */
	attachListeners: function () {
		let container = $('#cw_agents_list')
		container.on('click', '.cw_grid_check', $.proxy(this.onAgentCheck, this));
		container.on('click', '.cw_grid_check_all', $.proxy(this.onAgentCheckAll, this));
		container.on('click', '.cw_grid_check_all_sub', $.proxy(this.onAgentCheckAllSubAgents, this));
		container.on('click', '.cw_parent_agent', (e) => this.onParentAgent(e));


		LocalEventsManager.bind('maintenance_period_saved', $.proxy(this.onMaintenanceSaved, this));
	},
	/**
	 * Gets agent status types
	 */
	getStatusTypes: function () {
		var url = Settings.serverPath + 'agents/statusTypes';
		Utils.ajax(url, 'GET', {}, $.proxy(function (result) {
			State.agentStatusTypes = result;
			this.loadUserPreferences();
		}, this));
	},
	/**
	 * Handler for the agent checkbox click
	 * @param {Object} e The click event
	 */
	onAgentCheck: function (e) {
		var checkboxes = $('.cw_grid_check');
		var agents = [], item, agentIsDown = false, agentIsSystem = false, agentsAutoUpdate = false, agentsChecked;
		if (e && e.shiftKey && $(e.currentTarget).is(':checked')) {
			var prevRow = $(e.currentTarget).closest('tr').prev();
			var prevInput = prevRow.find('input:first');
			while (prevRow.length && !prevInput.is(':checked')) {
				prevInput.prop('checked', true);
				prevRow = prevRow.prev();
				prevInput = prevRow.find('input:first');
			}
		}
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			if ($(checkboxes[i]).is(':checked')) {
				item = this.grid.dataItem($(checkboxes[i]).closest('tr'));
				if (!agentIsDown) {
					agentIsDown = item.agentState === 'ERROR';
				}
				agentIsSystem = item.serverAgent;
				agentsAutoUpdate = item.agentsAutoUpdate;

				agents.push(item);
			}
		}
	},
	/*
	 * Restarts agents
	 * @param {Boolean} cache Restart agent(s) with or without cache
	 * @values true / false
	 */
	restartAgents: function (obj) {
		var agentsCheckboxes = $('.cw_grid_check:checked'), i,
			length = agentsCheckboxes.length, agents = [];

		for (i = 0; i < length; i++) {
			agents.push($(agentsCheckboxes[i]).data('id'));
		}
		this.restartHandler = new Utils.onAgentRestart({
			cache: obj.cache,
			agents: agents,
			appendToElement: '#cw_agents_list'
		});
	},
	/**
	 * Handler for the check all checkbox click
	 * @param {Object} e The click event
	 */
	onAgentCheckAll: function (e) {
		var checkAll = $('.cw_grid_check_all').is(':checked');
		var checkboxes = $('.cw_grid_check');
		var i, length = checkboxes.length;
		for (i = 0; i < length; i++) {
			$(checkboxes[i]).prop('checked', checkAll);
		}
		if (checkAll && checkboxes.length) {
			this.onAgentCheck();
		} else {
			this.gridMenu.enable(false);
		}
	},
	/**
	 * Handler for the check all subagents checkbox click
	 * @param {Object} e The click event
	 */
	onAgentCheckAllSubAgents: function (e) {
		var checkAllSub = $('.cw_grid_check_all_sub').is(':checked');
		var checkboxes = $('.cw_grid_check_all_sub').closest('.cw_agents_inner_grid').find('.cw_grid_check');
		var i, length = checkboxes.length;
		for (i = 0; i < length; i++) {
			$(checkboxes[i]).prop('checked', checkAllSub);
		}
		if (checkAllSub && checkboxes.length) {
			this.onAgentCheck();
		} else {
			this.gridMenu.enable(false);
		}
	},

	onParentAgent(e) {
		let agentId = $(e.target).data('id');
		State.mainApp.navigate(AgentsRouter.details(agentId));
	},

	resetCheckingAgents: function () {
		this.selectedAgents = [];
		this.agentIndex = 0;

		kendo.ui.progress($('#cw_agents_list'), false);
		this.grid.dataSource.read();
	},
	/**
	 * Handler for checking agent dependencies
	 */
	checkAgentDependencies: function () {
		var agentId = this.selectedAgents[this.agentIndex++], record, agentName;
		if (agentId) {
			record = this.agentsDataSource.get(agentId);
			this.agentAccountName = record.accountName;
			this.agentAccountId = record.accountId;
			agentName = record.name;

			var dependenciesUrl = Settings.serverPath + 'agents/' + agentId + '/dependencies';
			Utils.ajax(dependenciesUrl, 'GET', {}, $.proxy(function (data) {
				if (data.length) {
					this.openDependenciesPopup(data, agentName, agentId);
					setTimeout(this.adjustTableHeight, 100);
				} else {
					var deleteUrl = Settings.serverPath + 'agents/' + agentId;
					Utils.ajax(deleteUrl, 'DELETE', {}, $.proxy(function (result) {
						if (result.success) {
							this.checkAgentDependencies();
						} else {
							this.resetCheckingAgents();
							Utils.showInfo(lang.ALERT, result.message, result.details);

							//this.gridMenu.enableItem('cw_agent_update');
							//this.gridMenu.enableItem('cw_agent_delete');
						}
					}, this));
				}
			}, this));
		} else {
			kendo.ui.progress($('#cw_agents_list'), false);
			this.grid.dataSource.read();
			if (this.dependenciesWindow) {
				this.dependenciesWindow.close();
			}
			//this.gridMenu.enableItem('cw_agent_update');
			//this.gridMenu.enableItem('cw_agent_delete');
		}
	},
	/**
	 * Handler function for creating new agent
	 */
	connectAgentClick: function () {
		if (State.mainApp.session.hasRole('AGENT_CREATE')) {
			var modalWindow = new ModalWindow({
				title: lang.agents.INITIATE_AGENT,
				id: 'agent_modal',
				width: 420,
				height: 580,
				minHeight: 580,
				position: topLeftCornerPosition,
				url: 'include/Management/AgentForm.jsp',
				refresh: function () {
					var form = new AgentForm({
						id: 'new_agent',
						isPublic: true,
						mode: 'create'
					});
				}
			});
			modalWindow.open();
		}
	},
	/**
	 * Handler function for delete sla button
	 */
	updateAgentClick: function () {
		var checkboxes = $('.cw_grid_check');
		var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/agents/update';
		var agents = [];
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			if ($(checkboxes[i]).is(':checked')) {
				agents.push($(checkboxes[i]).data('id'));
			}
		}
		if (agents !== '') {
			this.gridMenu.disableItem('cw_agent_update');
			this.gridMenu.disableItem('cw_agent_delete');
			Utils.ajax(url, 'POST', JSON.stringify(agents), $.proxy(function (result) {
				if (result.success) {
					this.actionNotification.setOptions({
						message: lang.agents.messages.AGENTS_REQUEST,
						status: 'success'
					}).show();
					// reload data source
					this.agentsDataSource.read();
					this.gridMenu.enableItem('cw_agent_update');
					this.gridMenu.enableItem('cw_agent_delete');
				} else {
					Utils.showInfo(lang.ALERT, result.message, result.details);
				}
			}, this));
		}
	},
	/**
	 * Handler function for delete agent button
	 */
	deleteAgentClick: function () {
		var checkboxes = $('#cw_agents_list').find('.cw_grid_check'), dialog,
			agents = [], length, agentsLength;
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			if ($(checkboxes[i]).is(':checked')) {
				agents.push($(checkboxes[i]).data('id'));
			}
		}
		agentsLength = agents.length;
		this.selectedAgents = agents;
		this.initialAgentsLength = this.agentsDataSource.data().length;

		if (agentsLength) {
			this.agentIndex = 0;

			dialog = new Dialog({
				title: lang.INFO,
				msg: lang.account.messages.AGENT_REMOVE_CONFIRMATION,
				icon: 'ERROR',
				actionText: 'DELETE',
				fn: $.proxy(function (value, button) {
					if (button === 'ok') {
						kendo.ui.progress($('#cw_agents_list'), true);
						this.checkAgentDependencies();
					}
				}, this)
			});
			dialog.show();
		}
	},
	/**
	 * Creates and opens the agent dependencies window
	 *
	 * @param {Array} dependencies - The agent dependencies array
	 * @param {String} agentName The agent name
	 */
	openDependenciesPopup: function (dependencies, agentName, agentId) {
		if (this.dependencyWindow) {
			this.dependenciesWindow.close();
		}

		this.dependenciesWindow = new ModalWindow({
			title: lang.INFO,
			width: 960,
			height: 640,
			minWidth: 220,
			actions: [],
			url: 'include/Dependencies.jsp',
			//resize: this.adjustTableHeight,
			onClose: $.proxy(function () {
				this.clearCheckboxes();
			}, this),
			refresh: $.proxy(function () {
				$('#cw_dependencies_deleteall').removeClass('hide');
				this.dependencyWindow = new Dependencies({
					accountId: this.agentAccountId,
					dependencies: dependencies,
					name: agentName,
					container: '#cw_agents_list',
					selectedChecks: agentId,
					infoTemplate: lang.agents.messages.DELETE_INFO,
					cancel: () => this.resetCheckingAgents(),
					deleteAll: $.proxy(function (e) {
						var deleteUrl = Settings.serverPath + 'agents/' + agentId + '/dependencies/deleteAll';
						Utils.ajax(deleteUrl, 'DELETE', {}, $.proxy(function (result) {
							if (result.success) {
								this.checkAgentDependencies();
							} else {
								Utils.showInfo(lang.ALERT, result.message, result.details);
								this.resetCheckingAgents();
							}
						}, this));
					}, this)
				});
			}, this)
		});
		this.dependenciesWindow.open();
	},
	openBundlesWindow: function (agents, action) {
		if (agents.length) {
			if (this.repositoryWindow) {
				this.repositoryWindow.close();
			}
			this.repositoryWindow = new ModalWindow({
				title: lang.agents.REPOSITORY,
				width: 960,
				height: 640,
				minWidth: 220,
				actions: ['Close'],
				url: 'include/Controls/RepositoryWindow.jsp',
				refresh: $.proxy(function () {
					this.repWindow = new RepositoryWindow({
						agents: agents,
						action: action
					});
				}, this)
			});
			this.repositoryWindow.open();
		}
	},
	onInstallBundle: function () {
		var checkboxes = $('#cw_agents_list').find('.cw_grid_check'), agents = [];
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			if ($(checkboxes[i]).is(':checked')) {
				agents.push({
					id: $(checkboxes[i]).data('id'),
					name: $(checkboxes[i]).data('name')
				});
			}
		}
		this.openBundlesWindow(agents, 'install');
	},
	onUninstallBundle: function () {
		var checkboxes = $('#cw_agents_list').find('.cw_grid_check'), agents = [];
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			if ($(checkboxes[i]).is(':checked')) {
				agents.push({
					id: $(checkboxes[i]).data('id'),
					name: $(checkboxes[i]).data('name')
				});
			}
		}
		this.openBundlesWindow(agents, 'uninstall');
	},
	/**
	 * Handler funciton for the click event on an agent dependency name
	 * @param {Object} e The click event object
	 */
	onDependencyName: async function (e) {
		var dependencyId = $(e.currentTarget).attr('data-id'),
			dependency = this.dependenciesGrid.dataSource.get(dependencyId);
		var serviceName;
		e.preventDefault();
		this.dependenciesWindow.close();
		switch (dependency.type) {
			case 'SERVICE_QUALIFIER':
				try {
					serviceName = $(e.currentTarget).text().split('/')[0];
				} catch (ex) {
					serviceName = '';
				}
				State.mainApp.navigate(ServiceDesignerRouter.root(dependency.parentId));
				// State.mainApp.loadModule('Designer', Utils.guid(), {
				// 	mode: 'update',
				// 	serviceId: dependency.parentId,
				// 	serviceName: serviceName,
				// 	serviceAccountId: this.agentAccountId,
				// 	highlight: {
				// 		qualifierId: dependency.id
				// 	}
				// }, e);
				break;
			case 'ACCOUNT':
				State.mainApp.loadModule('AdministrationAccounts', null, {}, e);
				break;
				break;
			case 'AGENT':
			case 'SUBAGENT':
				State.mainApp.loadModule('AgentsView', null, {}, e);
				break;
			case 'MONITOR':
				var monitorId = dependency.id;
				let result = await Api.fetch(MonitorsApi.getMonitorType(dependency.accountId, monitorId));
				if (result.success) {
					var type = result.data.type;
					var monitorTypes = ['SYSTEM', 'NIMSOFT', 'PROCESSES', 'WINSERVICE', 'EXTERNAL', 'WINEVENTS', 'PRTG', 'VMWARE', 'NAGIOS'];
					var modules = ['AssetHealthSystemMonitor', 'AssetHealthSystemNimsoft', 'AssetHealthProcess', 'AssetHealthService', 'AssetHealthExternal', 'AssetHealthEventLog', 'AssetHealthPRTG', 'AssetHealthVMware', 'AssetHealthNagios'];
					var module = modules[monitorTypes.indexOf(type)];
					State.mainApp.loadModule(module, monitorId, {
						highlightMonitorId: monitorId
					}, e);
				} else {
					Utils.showInfo(lang.ALERT, result.message, result.details);
				}
				break;
			case 'SERVICE':
				State.mainApp.loadModule('Service', null, {}, e);
				break;
			case 'SERVICE_ELEMENT':
				try {
					serviceName = $(e.currentTarget).text().split('/')[0];
				} catch (ex) {
					serviceName = '';
				}

				State.mainApp.navigate(ServiceDesignerRouter.root(dependency.parentId));
				break;
			case 'SLA':
				State.mainApp.loadModule('SLAs', null, {}, e);
				break;
		}
	},
	/**
	 * Handle for the click event on the agent configuration button
	 */
	agentConfigurationClick: function (e) {
		State.mainApp.loadModule('AgentConfiguration', this.agent.id, {
			id: this.agent.id,
			agentName: this.agent.name
		}, e);
	},

	onRowExpand: function () {
		var selectedRow = this.grid.select();
		var myRow = selectedRow[0];
		var messageEl = $(myRow).find('.to_expand');
		if ($(messageEl).hasClass('cw_message_expanded')) {
			$(messageEl).removeClass('cw_message_expanded').addClass('ellipsis');
		} else {
			$('#cw_agents_list').find('.k-grid-content').find('td.cw_message_expanded').removeClass('cw_message_expanded').addClass('ellipsis');
			$(messageEl).addClass('cw_message_expanded').removeClass('ellipsis');
		}
	},
	/**
	 * Always show account name column
	 * @param {Boolean} includeSubaccounts
	 */
	onIncludeSubaccounts: function (includeSubaccounts) {
		Application.prototype.onIncludeSubaccounts.call(this, includeSubaccounts);
		this.grid.showColumn('accountName');
	},
	/**
	 * Opens Run Script window
	 */
	onRunScript: function () {
		var checkboxes = $('#cw_agents_list').find('.cw_grid_check'), agents = [];
		var windowArea = $('.window_area');
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			if ($(checkboxes[i]).is(':checked')) {
				agents.push({
					id: $(checkboxes[i]).data('id'),
					name: $(checkboxes[i]).closest('tr').find('.cw_grid_link:first').text()
				});
			}
		}

		var windowWidth = windowArea.width() - 300;
		var windowHeight = windowArea.height() - 240;

		this.runScriptWindow = new ModalWindow({
			title: lang.agents.RUN_SCRIPT,
			width: windowWidth,
			height: windowHeight,
			resizable: true,
			//minHeight: windowHeight,
			modal: true,
			actions: ['Close'],
			url: 'include/Management/RunScriptWindow.jsp',
			refresh: $.proxy(function () {
				var runScriptWindow = new RunScriptWindow({
					agents: agents
				});
			}, this)
		});
		this.runScriptWindow.open();
	},
	/**
	 * Handler function for set in maintenance button
	 */
	onSetInMaintenance: function () {
		var checkboxes = this.grid.element.find('.cw_grid_check:checked');
		var agents = [];
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			agents.push($(checkboxes[i]).attr('data-id'));
		}
		var maintenancePeriod = new MaintenancePeriod({
			layout: 'window',
			mode: 'create',
			type: 'agent',
			entities: agents,
			accountId: this.accountId || Cookies.CeesoftCurrentAccountId
		});
	},
	/**
	 * Handler function for set in maintenance button
	 */
	onEndMaintenance: function () {
		var checkboxes = this.grid.element.find('.cw_grid_check:checked');
		var agents = [];
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			agents.push($(checkboxes[i]).attr('data-id'));
		}
		var url = Settings.serverPath + 'maintenance/endMultipleAgents';
		Utils.ajax(url, 'POST', JSON.stringify(agents), $.proxy(function (result) {
			if (result.success) {
				this.actionNotification.setOptions({
					message: lang.management.messages.END_MAINTENANCE_SUCCEEDED,
					status: 'success'
				}).show();
				this.clearCheckboxes();
				this.grid.dataSource.read();
			} else {
				this.actionNotification.setOptions({
					message: result.message,
					status: 'error'
				}).show();
			}
		}, this));
	},
	/**
	 * Called after one or multiple are set in maintenance
	 */
	onMaintenanceSaved: function () {
		this.actionNotification.setOptions({
			message: lang.service.messages.MAINTENANCE_SUCCESS_SAVED,
			status: 'success',
		}).show();
		this.grid.dataSource.read();
	},
	getFilterableForState: function (element) {
		var menu = $(element).parent();
		menu.find(".k-filter-help-text").text(lang.grid.filter.SHOW_ITEMS);
		//menu.find("[data-role=dropdownlist]").remove();
		element.removeAttr("data-bind");
		var operatorEl = menu.find("[data-role=dropdownlist]");
		operatorEl.find('option[value="contains"]').remove();
		operatorEl.find('option[value="startswith"]').remove();
		operatorEl.find('option[value="eq"]').text(lang.grid.filter.ISIN);
		operatorEl.find('option[value="neq"]').text(lang.grid.filter.ISNOTIN);
		operatorEl.attr('data-index', '1');
		var multiSelect = element.kendoSortedMultiSelect({
			dataSource: [{
				text: lang.UP,
				cssClass: 'glyphicons status-icon ok-sign',
				stateClass: '',
				value: 'OK'
			}, {
				text: lang.DOWN,
				cssClass: 'glyphicons status-icon remove-sign',
				stateClass: '',
				value: 'ERROR'
			}, {
				text: lang.WARNING,
				cssClass: 'glyphicons status-icon exclamation-sign',
				stateClass: '',
				value: 'WARNING'
			}, {
				text: lang.MAINTENANCE,
				cssClass: 'glyphicons status-icon wrench',
				stateClass: '',
				value: 'MAINTENANCE'
			}],
			dataTextField: 'text',
			dataValueField: 'value',
			optionLabel: lang.grid.FILTER_SELECT_VALUE,
			itemTemplate: '<div class="cw_filter_item"><div class="cw_status_indicator is_round no_shadow #=data.stateClass#"><span class="#= data.cssClass #"></span></div><span class="cw_filter_label">${data.text}</span></div>',
			tagTemplate: '<div class="cw_filter_tag"><div class="cw_status_indicator is_round no_shadow #=data.stateClass#"><span class="#= data.cssClass #"></span></div><span class="cw_filter_label">${data.text}</span></div>'

		}).data('kendoSortedMultiSelect');
		menu.find('[type=submit]').on('click', {
			widget: multiSelect,
			operatorElement: operatorEl
		}, $.proxy(this.scope.filterByState, {
			scope: this.scope,
			dataSource: this.scope.agentsDataSource,
			field: this.field
		}));
		menu.find('[type=reset]').on('click', $.proxy(function (e) {
			multiSelect.value([]);
		}, this));
		//fix for default value
		setTimeout(function () {
			operatorEl.data('kendoDropDownList').select(1);
		}, 100);
	},
	filterByState: function (e) {
		var indicators = e.data.widget.value();
		var operator = e.data.operatorElement.data('kendoDropDownList').value();
		var logic = (operator === 'eq') ? 'or' : 'and';
		var currentFilter = this.dataSource.filter();
		var filter = {logic: logic, filters: []};
		for (var i = 0; i < indicators.length; i++) {
			filter.filters.push({field: this.field, operator: operator, value: indicators[i]});
		}
		if (currentFilter && currentFilter.filters) {
			for (var i = 0; i < currentFilter.filters.length; i++) {
				if (currentFilter.filters[i].filters) {
					for (var j = 0; j < currentFilter.filters[i].filters.length; j++) {
						if (currentFilter.filters[i].filters[j].field === this.field) {
							currentFilter.filters[i].filters.splice(j, 1);
							j--;
						}
					}
				}
				if (currentFilter.filters[i].field === this.field) {
					currentFilter.filters.splice(i, 1);
					i--;
				}
			}
			if (filter.filters.length) {
				currentFilter.filters.push(filter);
			}
			this.dataSource.filter(currentFilter);
		} else {

			this.dataSource.filter(filter);
		}
	},
	onEventsTimeout: function (events) {
		this.grid.dataSource.read();
	},
	/**
	 * Called when one or multiple events are received from the server
	 * @param {Object} data The event data
	 */
	onEvent: function (data) {
		if (this.eventsToolbar) {
			this.eventsToolbar.addEvents(data);
		}
	},
	/**
	 * Clears all checkboxes
	 */
	clearCheckboxes: function () {
		var checkAll = $('#cw_agents_list').find('.cw_grid_check_all'),
			checkboxes = $('#cw_agents_list').find('.cw_grid_check'),
			i, length = checkboxes.length;
		checkAll.prop('checked', false);
		for (i = 0; i < length; i++) {
			$(checkboxes[i]).prop('checked', false);
		}
	},
	/**
	 * Saves user preferences
	 */
	saveUserPreferences: function () {
		var preferences = [];
		if (!this.customFilter) {
			preferences.push({
				key: 'agentsFilter',
				value: JSON.stringify(this.grid.dataSource.filter() || [])
			});
		}

		this.saveUserPrefs({
			category: 'Agents',
			filterPanel: this.gridFilterPanel,
			grid: this.grid,
			eventsToolbar: this.eventsToolbar,
			preferences: preferences,
			keys: {
				searchPhrase: 'agentsSearchPhrase',
				columns: 'agentsColumns',
				sort: 'agentsSort',
				FsViews: 'agentsFSViews'
			}
		});
	},
	/**
	 * Loads user preferences
	 */
	loadUserPreferences: function () {
		this.userPref = [];
		UserPrefs.load('Agents', $.proxy(function (result) {
			if (result.success) {
				this.userPref = result.data;
				var eventStatus = JSON.parse(UserPrefs.get('eventsStatus')) || {
					startFrom: 0,
					playing: true
				};
				this.eventsToolbar = new EventsToolbar({
					renderTo: $('#cw_agents_events_toolbar'),
					callBackFn: $.proxy(this.onEventsTimeout, this),
					subscriberId: this.subscriberId,
					startFrom: eventStatus.startFrom,
					playing: eventStatus.playing
				});
				this.initKendoComponents();
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},
	/**
	 * Method by Andy
	 */
	adjustTableHeight: function () {
		var table = $('.cw_agent_dependencies'),
			tableHeight = $('#modal').parent().height() - 56 - 65;
		table.css('height', tableHeight);
		table.find('.k-grid-content').css('height', tableHeight - 27);
	},
	/**
	 * Method by Andy
	 *
	 */
	adjustSectionHeight: function () {
		var section = $('.cw_section_full:visible'),
			sectionHeight = section.height();
		section.find('.cw_section_content').css('height', sectionHeight);
		section.find('.k-grid-content').css('height', sectionHeight - 40);
	},
	/**
	 * Subscribes to the events
	 */
	subscribe: function () {
		this.isDataSourceSubscribed = true;

		var subscriptionObj = [{
			eventType: 'AgentState',
			accountId: Cookies.CeesoftCurrentAccountId,
			includeSubaccounts: State.includeSubaccounts
		}];
		RemoteEventsManager.subscribe(this.subscriberId, subscriptionObj);
	},
	/**
	 * Called when application is unloaded/destroyed
	 */
	destroy: function () {
		if (this.eventsToolbar) {
			this.eventsToolbar.destroy();
		}
		this.saveUserPreferences();
		Application.prototype.destroy.call(this);
	}
});
