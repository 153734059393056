import './link.less';

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames'


let b = require('b_').with('c-link');

export const Link = (props) => {
	return (
		<span className={classnames(b(), props.containerClass)}
		   onClick={props.onClick}>
			{props.children}
		</span>
	);
};

export default Link;

Link.propTypes = {
	containerClass: PropTypes.string,
	onClick: PropTypes.func,
	children: PropTypes.node
}
