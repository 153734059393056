import './antSelect.less'

import difference from 'lodash/difference'
import React, {FunctionComponent, useCallback, useMemo, useState} from 'react';
import {observer} from 'mobx-react';
import Select, {RefSelectProps, SelectProps, SelectValue} from 'antd/lib/select';
import {Tag, Input, Tooltip} from 'antd';

import Utils from 'tools/utils';

const {Option} = Select;
import {
	EditOutlined
} from '@ant-design/icons';
import {captureValueLink, renderSelectOptions} from "controls/react/ant/utils";
import {translator} from 'core/localization/localization';
import { Link } from 'core/react/links';


export interface AntSelectProps<VT extends AntSelectValue> extends SelectProps<VT>{
	sortValues?: boolean,
	nameField?: string,
	valueField?: string,
	dataList?: Array<any>
	valueLink?: Link<VT>,
	errors?: string[],
	invalid?: boolean,
	editable?: boolean,
	nonRemovableMessage?: string,
	isAttributesView?: boolean,
	skipSorting?: boolean,
	onBlur?: () => void,
	componentRef?: React.RefCallback<RefSelectProps>
}

export type AntSelectValue = SelectValue

export const AntOption = Option;

export const AntSelect = observer(<VT extends AntSelectValue>(props: AntSelectProps<VT>) => {
	props = captureValueLink(props);

	const [uncontrolled, setUncontrolled] = useState(props.value === undefined);
	const [uncontrolledValue, setUncontrolledValue] = useState(props.defaultValue);

	let {errors, invalid, dataList, nameField, valueField, value, sortValues, onChange, editable, nonRemovableMessage, componentRef, ...others} = props;
	nameField = nameField || 'name';
	valueField = valueField || 'id';

	if(uncontrolled){
		value = uncontrolledValue;
	}

	const onChangeWrapper = useCallback( values => {
		if(uncontrolled){
			setUncontrolledValue(values);
		}
		if(props.mode == 'tags') {
			//adding new item
			for (let v of values) {
				let found = dataList.find(element => element.id === v || element.name === v);
				if (!found && !Utils.isGuid(v)) {
					let id = Utils.guid();
					if (!props.isAttributesView) {
						dataList.push({
							id: id,
							name: v,
							address: v,
							editable: true,
							destroyable: true,
							removable: true
						});
					} else {
						dataList.push({
							id: id,
							name: v
						});
					}
					values[values.indexOf(v)] = id;
				}
			}
		}
		onChange(values, dataList);
	}, [uncontrolled]);

	//we use sorting only for tags or multiple selection
	// @ts-ignore
	const sortedValue = useSortedValues(value, dataList, nameField, valueField,
		props.mode == "tags" || props.mode == "multiple" && !props.skipSorting ? sortValues : false);

	const onLabelUpdate = React.useCallback((currentValue, newValue, id) => {
		//adding new tag to an existing tags list or it wont be shown
		const existingTagIndex = dataList.findIndex( item => item.id == id);
		dataList[existingTagIndex].name = newValue;
		dataList[existingTagIndex].address = newValue;
		onChangeWrapper(sortedValue);
	}, [dataList, onChangeWrapper, sortedValue]);

	const tagRender = React.useCallback((item) => {
		let element = dataList.find(element => element.id === item.value);
			return (
				<EditableTag {...item}
							 editable={editable}
							 removable={element ? element.removable : true}
							 tooltip={element ? element.tooltip : ''}
							 nonRemovableMessage={nonRemovableMessage}
							 updateValue={onLabelUpdate}
				/>
			);

	}, [dataList, editable, nonRemovableMessage, onLabelUpdate]);

	const componentRefSet = React.useCallback(ref => {
		if(ref != null){
			ref.onBlur = props.onBlur;
			componentRef && componentRef(ref)
		}
	}, [props.onBlur, componentRef])

	// @ts-ignore
	return (
		<Select {...others}
		        ref={componentRefSet}
				tagRender={props.mode == "tags" ? tagRender: null}
			// @ts-ignore
				value={sortedValue}
				optionFilterProp={props.mode == 'multiple' ? 'children' : ''}
		        onChange={onChangeWrapper}>
			{props.children}

			{dataList && renderSelectOptions(dataList, {nameField, valueField})}
		</Select>
	);

});

export const AntSelectUncontrolled = observer(<VT extends SelectValue>(props: AntSelectProps<VT>) => {
	return <AntSelect {...props}/>
})

function useSortedValues<VT>(values:VT[], dataList:any[], nameField:string, valueField:string, shouldSortValues:boolean) : VT[]{
	return useMemo(() => {
		values = values || [];
		if (!shouldSortValues || !dataList)
			return values;

		//we need to sort selected values by names and not by values
		//so we are looking for names in children collection
		let selectedObjects = values.map(value => {
			let object = dataList.find(node => node[valueField] == value);
			return object == null ?
				{[valueField]: value}
				: object;
		});

		selectedObjects.sort((a,b) => {
			const lvalue = a[nameField]?.toLowerCase();
			const rvalue = b[nameField]?.toLowerCase();

			if(lvalue > rvalue)
				return 1;

			if(lvalue < rvalue)
				return -1;

			return 0;
		});

		return selectedObjects.map( x => x[valueField]);
	}, [values, dataList, nameField, valueField, shouldSortValues])
}

export const EditableTag = (props:any) => {
	const {onClose, value, label, editable, nonRemovableMessage, tooltip, removable} = props;
	const [editMode, setEditMode] = useState(false);

	const setValue = useCallback( e => {
		setEditMode(false);
		props.updateValue(label, e.target.value, value);
	}, []);

	const preventPropagationToParent = useCallback(e => {
		//ant select control removes tag on backspace
		//enter forces all tags list to be opened, we dont need it
		if(e.keyCode == 8 || e.keyCode == 13){ //backspace or enter
			e.stopPropagation();
		}
	}, []);

	return (
		<div>
			{!editMode && <Tag onClose={onClose} closable={removable !== undefined ? removable : true}>
				<Tooltip title={tooltip ? tooltip : removable ? '' : nonRemovableMessage}>{label}</Tooltip>
				{editable && <EditOutlined onClick={() => {setEditMode(true)}}/>}
			</Tag>}
			{editMode && <Input size="small"
								defaultValue={label}
								allowClear
								onKeyDown={preventPropagationToParent}
								onBlur={setValue}
								onPressEnter={setValue}
								onMouseDown={e => e.stopPropagation()} //changing position in input by clicking would not work without that
								style={{width: '100px'}}/>}
		</div>
	);
}

export const textValueFields = Object.freeze({
	nameField: 'text',
	valueField: 'value'
});

