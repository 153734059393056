import {StateColors} from 'tools/states';
import Api from 'tools/api';
import {executeUpdate} from "controls/designer/utils";
import {ReasonsGridApi} from "areas/application/reasonsGridApi";
import {showReasonsGridWindow} from "areas/application/reasonsGridWindow";
import {newGuid} from "tools/guid";
import {translator} from "core/localization/localization";
import {sharedLocalization} from "controls/designer/localization";
import {healthDataLocalization} from "framework/entities/healthData";

const i = translator();


export default class Element {
	cell = null;
	editorUi = null;
	graph = null;
	accountIds = [];

	constructor(designer, cell) {
		cell.designerElement = this;

		this.designer = designer;
		this.editorUi = designer.editorUi;
		this.cell = cell;
		this.graph = this.editorUi.editor.graph;

		this.datasource = this.cell.getDatasource();

		this.graph.addListener('CELL_CLICKED', this.onCellClicked);

		this.guid = newGuid();
	}

	setState(state) {
		this.lastState = state;
		let color = this.cell.getAttribute('state-color-' + state.toLowerCase());
		if (!color) {
			color = StateColors[state];
		}
		this.setBgColor(color);
	}

	readValue(name, json = true) {
		return this.cell.readValue(name, json);
	}

	refreshStateColor() {
		this.setState(this.lastState);
	}

	setBgColor(color) {
		if (this.graph.destroyed)
			return;

		this.graph.setCellStyles(
			this.cell.getBackgroundStyleName(),
			color,
			[this.cell]
		);


		//when data for an element is loaded and it is updated we force invalidate it
		//That fixies the case when cell is not changed as a result of update (for example it is already has required color)
		//but we need to show new label but getLabel wont be called unless styles are changed.
		//There should be a proper way to invalidate cell but no luck so far
		this.graph.setCellStyles(
			'forceInvalidate',
			newGuid(),
			[this.cell]
		);
	}

	async getTooltip() {
		let accounts = await Api.accounts.list(this.accountId, null, this.editorUi.requestCache);

		const accountName = accounts.find( x=> x.id == this.accountId)
			?.name;

		return this.getTooltipInternal(accountName);
	}

	getLabel() {
		return null;
	}

	_cellsForCleanUp = [];
	registerForCleanUp(cell){
		this._cellsForCleanUp.push(cell);
	}

	cleanUp() {
		this.graph.removeCells(this._cellsForCleanUp, false);
		this._cellsForCleanUp = [];
		this._relativeCells = [];
	}

	_relativeCells = [];
	registerRelativeCell(cell, shiftCallback){
		this._relativeCells.push({cell, shiftCallback});
		this._cellsForCleanUp.push(cell);
	}

	cellMoved(){
		if(this._relativeCells.length == 0)
			return;

		const parentGeometry = this.cell.getGeometry();

		executeUpdate(this.graph, () => {
			for (let entry of this._relativeCells) {
				const {dx, dy} = entry.shiftCallback(this.cell, parentGeometry);
				const currentGeometry = entry.cell.getGeometry();

				const newGeometry = new mxGeometry(
					parentGeometry.x + dx,
					parentGeometry.y + dy,
					currentGeometry.width,
					currentGeometry.height,
				);

				this.graph.getModel().setGeometry(entry.cell, newGeometry);
			}
		});
	}

	getEntriesToLoad() {
		return [];
	}

	updateIcon(iconName, iconPack = 'glyphicons') {
		this._updateImageInternal('icon', iconName, iconName);
	}

	updateImage(path) {
		this._updateImageInternal('image', null, path);
	}

	_updateImageInternal(type, iconsPack, image) {
		const graph = this.editorUi.editor.graph;
		graph.getModel().beginUpdate();
		try {
			graph.setCellStyles(mxConstants.STYLE_IMAGE_CONTENT_TYPE, type, [this.cell]);
			graph.setCellStyles(mxConstants.STYLE_IMAGE_ICONS_PACK, iconsPack, [this.cell]);
			graph.setCellStyles(mxConstants.STYLE_IMAGE, image, [this.cell]);
		} finally {
			graph.getModel().endUpdate();
		}
	}

	removeIcon() {
		var graph = this.editorUi.editor.graph;
		graph.getModel().beginUpdate();
		try {
			graph.setCellStyles(mxConstants.STYLE_IMAGE_CONTENT_TYPE, "no-content", [this.cell]);
		} finally {
			graph.getModel().endUpdate();
		}
	}

	addContentLabel(label, additionalUserData = {}) {
		if(label == null)
			label = '';

		const parentGeometry = this.cell.getGeometry();
		const labelGeometry = new mxGeometry(0, 0, parentGeometry.width, parentGeometry.height);
		if (parentGeometry.relative)
		{
			labelGeometry.x = Math.min(parentGeometry.sourcePoint.x, parentGeometry.targetPoint.x);
			labelGeometry.y = Math.min(parentGeometry.sourcePoint.y, parentGeometry.targetPoint.y);
			labelGeometry.width = Math.abs(parentGeometry.sourcePoint.x - parentGeometry.targetPoint.x);
			labelGeometry.height = Math.abs(parentGeometry.sourcePoint.y - parentGeometry.targetPoint.y);
		}

		const userData = mxUtils.createUserObject({
			healthIndexLabel: true,
			generatedCell: true,
			label: label,
			...additionalUserData
		});

		let parentStyles = this.designer.graph.getCellStyle(this.cell);

		this.contentLabelCell = this.graph.insertVertex(this.cell,
			null,
			userData,
			labelGeometry.x, labelGeometry.y, labelGeometry.width, labelGeometry.height,
			"text;html=1;strokeColor=none;align=center;verticalAlign=middle;selectable=0;forceShowInViewMode;"
			+ "whiteSpace=wrap;verticalLabelPosition=middle;labelPosition=center;fontColor=" + parentStyles.fontColor + ";"
			+ "fontSize=" + parentStyles.fontSize + ";" + mxUtils.getReadonlyStyles()
		);

		this.registerForCleanUp(this.contentLabelCell);
	}

	removeAllChildren() {
		this.graph.removeCells(this.graph.getChildVertices(this.cell), false);
	}

	isDefaultIconSet(defaultIconName){
		var style = this.graph.getCellStyle(this.cell);
		var currentContentType = mxUtils.getValue(style, mxConstants.STYLE_IMAGE_CONTENT_TYPE, 'no-content');
		var currentImage = mxUtils.getValue(style, mxConstants.STYLE_IMAGE, null);
		return currentContentType == 'no-content' || currentImage == defaultIconName
	}

	empty() {
		return true;
	}

	stylesChanged(e) {
		if(this.contentLabelCell == null)
			return;

		['fontSize', 'fontColor'].forEach(styleName => {
			const styleIndex = e.properties.keys.findIndex(x => x == styleName);
			if (styleIndex == -1)
				return;

			this.designer.graph.setCellStyles(styleName, e.properties.values[styleIndex], [this.contentLabelCell]);
		});
	}

	checkForWarnings(elementsToCheck, options) {
		if (!this.designer.config.chromeless)
			return;

		if(this.cell.isServiceRoot() && this.editorUi.config.data.model){
			elementsToCheck = elementsToCheck.concat(this.editorUi.config.data.model.nodes);
		}

		let maintenanceNode = elementsToCheck.find(x => this.isMaintenanceNode(x));

		if (maintenanceNode != null) {
			this.addMaintenanceIcon(maintenanceNode, options);
			return;
		}

		let warningNode = elementsToCheck.find(x => this.isWarningNode(x));

		if (warningNode != null) {
			this.addWarningIcon(warningNode, options);
		}
	}

	createStatusIcon(value, iconName, options = {}) {
		options = {
			position: 'topRight',
			backgroundColor: StateColors.INVALID,
			iconColor: '#FFFFFF',
			borderColor: null,
			...options
		};
		var point = this.cell.getGeometry();

		let shiftCallback = null;
		if (options.position == 'topRight') {
			shiftCallback = (parentCell) => {
				let x = parentCell.getGeometry().width + (parentCell.isServiceRoot() ? -4 : -11);
				if (options.shiftIcon) {
					x -= 25;
				}
				return {
					dx: x,
					dy: -12
				}
			};
		}else{
			shiftCallback = () => {
				let x = -12;
				if (options.shiftIcon) {
					x += 25;
				}
				return {
					dx: x,
					dy: -12
				};
			}
		}

		const {dx, dy} = shiftCallback(this.cell, point);

		const geometry = new mxGeometry(
			point.x + dx,
			point.y + dy,
			24,
			24);

		const icon =  this.graph.insertVertex(this.cell.parent, null, mxUtils.createUserObject(value),
			geometry.x, geometry.y, geometry.width, geometry.height,
			`shape=image;html=1;verticalLabelPosition=bottom;rounded=1;verticalAlign=top;imageAspect=0;`
			+ (options.borderColor != null ? mxConstants.STYLE_IMAGE_BORDER + `=${options.borderColor};` : '' )
			+ (options.iconColor != null ? mxConstants.STYLE_ICON_COLOR + `=${options.iconColor};` : '' )
			+ mxConstants.STYLE_IMAGE_CONTENT_TYPE + '=icon;'
			+ mxConstants.STYLE_IMAGE_ICONS_PACK + '=glyphicons;'
			+ mxConstants.STYLE_IMAGE_BACKGROUND + `=${options.backgroundColor};`
			+ 'image=' + iconName + ';'
			+ mxConstants.STYLE_IMAGE_PADDING + '=15;'
			+ "locked=1;"
			+ mxConstants.STYLE_IMAGE_FRAME + '=ellipse;',
		);

		this.registerRelativeCell(icon, shiftCallback);

		return icon;
	}

	isMaintenanceNode(node) {
		return (node.agentStatus == 'AGENT_MAINTENANCE'
			|| node.assetMaintenance === true
			|| node.monitorMaintenance === true
			|| node.operatingState === 'IN_MAINTENANCE') && !node.qualifierError
	}

	isWarningNode(node) {
		return node.agentStatus == 'AGENT_DOWN'
			|| node.qualifierError == true
	}

	addWarningIcon(node, options) {
		let tooltipParts = [];

		if (node.agentStatus === 'AGENT_DOWN')
			tooltipParts.push(Lang.account.messages.AGENT_WARNING);
		if (node.qualifierError)
			tooltipParts.push(Lang.account.messages.QUALIFIER_ERROR);
		if (node.assetMaintenance)
			tooltipParts.push(Lang.account.messages.ASSET_MAINTENANCE);

		let value = {
			label: "",
			tooltip: tooltipParts.join(' / ')
		}

		this.warningIcon = this.createStatusIcon(value, "exclamation-mark", options);
	}

	addMaintenanceIcon(node, options) {
		let tooltipParts = [];

		if (node.agentStatus === 'AGENT_MAINTENANCE')
			tooltipParts.push(Lang.account.messages.AGENT_MAINTENANCE);
		if (node.operatingState === 'IN_MAINTENANCE')
			tooltipParts.push(Lang.SERVICE_MAINTENANCE);
		if (node.assetMaintenance)
			tooltipParts.push(Lang.account.messages.ASSET_MAINTENANCE);
		if(node.monitorMaintenance){
			tooltipParts.push(healthDataLocalization('Monitor in maintenance'))
		}

		const value = {
			label: '',
			tooltip: tooltipParts.join(' / ')
		};

		this.maintenanceIcon = this.createStatusIcon(value, 'wrench', options);
	}


	onCellClicked = async (graph, e) => {
		if(!this.showReasonsIcon || e.properties.cell != this.showReasonsIcon)
			return;

		const response = await ReasonsGridApi.getReasonsGeneric(this.getLoadReasonsPayload());

		const {clientX, clientY} = e.properties.mouseEvent.evt;

		if(response.success !== false){
			showReasonsGridWindow(
				() => [response.items, false],
				i('Health reasons'), clientX, clientY, newGuid()
			)
		}
	}

	addExpandCollapseIcon(expanded) {
		if(!this.designer.config.chromeless) {
			return;
		}

		var point = this.cell.getGeometry();

		const geometry = new mxGeometry(
			point.x + (this.cell.isServiceRoot() ? -18 : -14),
			point.y - 20,
			30,
			30);

		this.expandCollapseIcon = this.graph.insertVertex(this.graph.getDefaultParent(), null, null,
			geometry.x, geometry.y, geometry.width, geometry.height,
			'shape=image;html=1;iconColor=' + StateColors['INVALID'] + ';imageAspect=0;'
			+ mxConstants.STYLE_IMAGE_CONTENT_TYPE + '=icon;'
			+ mxConstants.STYLE_IMAGE_ICONS_PACK + '=glyphicons;'
			+ 'selectable=0;'
			+ (expanded ? 'image=square-empty-minus;' : 'image=square-empty-plus;')
			+ mxConstants.STYLE_IMAGE_PADDING + '=15;'
			+ "locked=1;"
			+ mxConstants.STYLE_IMAGE_FRAME + '=ellipse;',
		);
		this.registerForCleanUp(this.expandCollapseIcon);
	}

	destroy() {
		this.destroyed = true;

		this.graph.removeListener(this.onCellClicked);
		this.graph.removeListener(this.onGraphCellClicked);

		this.cleanUp();
	}
}

export function readJsonValue(value) {
	if (!value)
		return [];

	if(value.startsWith('json:')) {
		try {
			return JSON.parse(value.substring(5))
		}
		catch(e) {
			console.error('failed to parse value: ', value);
			return value;
		}
	}

	return value;
}
