import {BudgetSplit, budgetValuesKeys, BudgetYearValues, CostType} from "areas/cost/budget/budgetTypes";
import {createModelSchemaWrapper, optionalExt, withParent} from "../../../framework/serializr-integration";
import {deserialize, list, object, primitive, serialize} from "serializr";
import {action, autorun, computed, makeAutoObservable, makeObservable, observable, runInAction, toJS} from "mobx";
import Utils from "../../../tools/utils";
import {CostBudgetData} from "./costBudget";
import {Month, months} from "areas/cost/budget/month";
import {KeysMatching} from "../../../tools/types";

const IGNORED_TYPES = [CostType.METRIC, CostType.EXPRESSION];

const toPrecision = (value: number, precision: number) => {
	const decimalMultiplier = Math.pow(10, precision);
	return Math.round(value * decimalMultiplier) / decimalMultiplier;
}

export class BudgetItemYearValues {
	editable: boolean;
	jan?: number;
	feb?: number;
	mar?: number;
	apr?: number;
	may?: number;
	jun?: number;
	jul?: number;
	aug?: number;
	sep?: number;
	oct?: number;
	nov?: number;
	dec?: number;
	total?: number;

	parent?: CostBudgetItem;

	constructor(props?: Partial<BudgetItemYearValues>) {
		makeAutoObservable(this);
		if (props) {
			Object.assign(this, props);
		}
	}

	clearMonths() {
		months.forEach(m => this[m] = null);
	}

	monthsSum() {
		return months.reduce((sum, month) => { return sum + (this[month] || 0) }, 0);
	}

	setTotal(newTotal: number | null, {startMonth, displayDecimals, reset}: {startMonth: Month, displayDecimals: number, reset: boolean}) {
		if(!newTotal) {
			this.clearMonths();
			this.total = null;
			return;
		}

		if (reset || !this.total || this.parent?.split === BudgetSplit.EVEN) {
			this.clearMonths();
			this.distributeThroughMonths(newTotal, displayDecimals, startMonth);
		} else {
			const threshold = Math.round(Math.abs(newTotal - (this.total || 0)) * Math.pow(10, displayDecimals));
			if (threshold >= 1) {
				this.distributeThroughMonths(newTotal - (this.total || 0), displayDecimals, startMonth);
			}
		}
		this.total = toPrecision(this.monthsSum(), displayDecimals);
	}

	distributeThroughMonths(distributeValue: number, precision: number, startMonth: Month) {
		const decimalMultiplier = Math.pow(10, precision);
		const monthIndex = months.findIndex(x => x == startMonth);
		const sortedMonths = months.slice(monthIndex).concat(months.slice(0, monthIndex));
		const intMonths = sortedMonths.map(m => Math.floor(this[m] * decimalMultiplier));
		const intDiff = Math.round(distributeValue * decimalMultiplier);
		const newIntMonths = this.distributeValue(intDiff, intMonths);
		sortedMonths.forEach((x, i) => {
			this[x] = newIntMonths[i] / decimalMultiplier;
		})
	}

	distributeValue(value: number, data: number[]) {
		let array = data;
		const n = array.length;
		const sum = () => array.reduce((acc, x) => acc + x, 0);
		const resultSum = sum() + value;
		const positiveN = () => array.filter(x => x > 0).length;

		if(value > 0) {
			const splitValue = Math.floor(value / n);
			const remainder = value - splitValue * n
			array = array.map((x, i) => x + (i < remainder ? splitValue + 1 : splitValue));
		} else {
			let remainder = value;

			while (-remainder >= positiveN()) {
				const splitValue = Math.ceil(remainder / positiveN());
				array = array.map(x => Math.max(x + splitValue, 0));
				remainder = resultSum - sum();
			}
			array = array.map(x => {
				if (x > 0 && remainder < 0) {
					remainder++;
					return x - 1;
				}
				return x;
			});
		}
		return array;
	}

	setMonth(month: Month, value: number, {displayDecimals}: {displayDecimals: number}) {
		this[month] = value;

		this.total = toPrecision(this.monthsSum(), displayDecimals);
		this.parent.split = BudgetSplit.NONE;
	}

	splitEqual({displayDecimals, startMonth}: {displayDecimals: number, startMonth: Month}) {
		this.setTotal(this.total, {startMonth, displayDecimals, reset: true});
	}

	setPercentage(percentage: number) {
		BudgetItemYearValues.numberKeys.forEach(key => {
			if(this[key]) {
				this[key] = percentage * this[key] / 100;
			}
		})
	}

	static monthsKeys = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'] as KeysMatching<BudgetItemYearValues, number>[];
	static numberKeys = [...BudgetItemYearValues.monthsKeys, 'total'] as KeysMatching<BudgetItemYearValues, number>[];
}

createModelSchemaWrapper(BudgetItemYearValues, {
	editable: primitive(),
	jan: optionalExt(primitive()),
	feb: optionalExt(primitive()),
	mar: optionalExt(primitive()),
	apr: optionalExt(primitive()),
	may: optionalExt(primitive()),
	jun: optionalExt(primitive()),
	jul: optionalExt(primitive()),
	aug: optionalExt(primitive()),
	sep: optionalExt(primitive()),
	oct: optionalExt(primitive()),
	nov: optionalExt(primitive()),
	dec: optionalExt(primitive()),
	total: optionalExt(primitive())
});

export class MetricVariant {
	conversion: string;
	customUnit: string;
	instanceName: string;
	metricId: string;
	metricStore: string;
	registryIdentifier: string;
	registryOwnerId: string;
	registryType: string;
	registryOwnerName: string;
	categoryNode: string;
	periods: string[];
}

createModelSchemaWrapper(MetricVariant, {
	conversion: primitive(),
	customUnit: primitive(),
	instanceName: primitive(),
	metricId: primitive(),
	metricStore: primitive(),
	registryIdentifier: primitive(),
	registryOwnerId: primitive(),
	registryType: primitive(),
	registryOwnerName: primitive(),
	categoryNode: primitive(),
	periods: list(primitive())
});

export interface CostBudgetLink {
	costTargetId?: string;
	costTargetType?: CostType;
	costModelId?: string;
	hasHierarchy?: boolean;
	hierarchyIds: string[];
	targetAccountId: string;
	targetProfileId: string;
	percentage?: number;
}

export class CostBudgetItem implements CostBudgetLink {
	id: string;
	uiId: string;
	name: string;
	split: BudgetSplit = BudgetSplit.NONE;
	costType: CostType;

	cost: BudgetItemYearValues;
	budget: BudgetItemYearValues;
	listingPrice: BudgetItemYearValues;

	periodEstimate?: number;
	currentEstimate?: number;

	costTargetId?: string;
	costTargetType?: CostType;
	costModelId?: string;
	hasHierarchy?: boolean;
	hierarchyIds: string[];
	hierarchyTargetId: string;
	percentage?: number;
	targetAccountId: string;
	targetProfileId: string;

	items: CostBudgetItem[] = [];

	parent: CostBudgetItem | CostBudgetData;


	hasMetrics: boolean;
	metrics: MetricVariant[];
	hasEvents: boolean;

	constructor() {
		this.uiId = Utils.guid();
		makeObservable(this, {
			uiId: observable,
			id: observable,
			name: observable,
			split: observable,
			costType: observable,
			cost: observable,
			budget: observable,
			listingPrice: observable,
			currentEstimate: observable,
			periodEstimate: observable,
			costTargetId: observable,
			costTargetType: observable,
			costModelId: observable,
			targetAccountId: observable,
			targetProfileId: observable,
			hierarchyIds: observable,
			hierarchyTargetId: observable,
			items: observable,
			splitEnabled: computed,
			isCostLink: computed,
			level: computed,
			addItem: action,
			setPercentage: action
		});

		autorun(() => {
			if(this.items.length > 0) {
				BudgetItemYearValues.numberKeys.forEach(key => {
					(this.cost[key] as number) = this.sumFromItems(i => i.cost[key] as number | null) ?? (this.cost[key] as number);
					(this.budget[key] as number) = this.sumFromItems(i => i.budget[key] as number | null) ?? (this.budget[key] as number);
					(this.listingPrice[key] as number) = this.sumFromItems(i => i.listingPrice[key] as number | null) ?? (this.listingPrice[key] as number);
				})
				this.periodEstimate = this.sumFromItems(i => i.periodEstimate);
				this.currentEstimate = this.sumFromItems(i => i.currentEstimate);
			}
		})
	}

	get splitEnabled() {
		if (this.isCostLink) return false;
		return this.budget.editable || this.cost.editable;
	}

	get isCostLink() {
		return this.costTargetType && this.costTargetType !== 'NONE';
	}

	get isDeletedCostLink() {
		return this.costTargetType && this.costTargetType === 'NONE' && this.targetAccountId && this.targetProfileId;
	}

	get isAssetLink() {
		return this.costTargetType && this.costTargetType === 'ASSET' && !this.costModelId && this.costTargetId;
	}

	get level() : number {
		if(this.parent && 'level' in this.parent) {
			return this.parent.level + 1;
		}
		return 0;
	}

	get inLink() : boolean {
		if(!this.parent || this.parent.isBudgetData) return false;

		const parent = this.parent as CostBudgetItem;
		return parent.isCostLink || parent.inLink;
	}

	get addChildrenEnabled() {
		return !(this.isCostLink || this.inLink);
	}

	get isBudgetData() {
		return false;
	}

	addItem(item: CostBudgetItem) {
		if (this.costType === CostType.COST_RESOURCE) {
			this.convertToGroup();
		}
		item.parent = this;
		this.items.push(item);
	}

	private convertToGroup() {
		this.id = Utils.guid();
		this.costType = CostType.COST_GROUP;
		this.cost.editable = false;
		this.budget.editable = false;
	}

	removeItem(item: CostBudgetItem) {
		const index = this.items.findIndex(i => i.id === item.id);
		this.items.splice(index, 1);
		if (this.costType === CostType.COST_GROUP && this.items.length === 0) {
			this.convertToResource();
		}
	}

	private convertToResource() {
		this.id = Utils.guid();
		this.costType = CostType.COST_RESOURCE;
		this.cost.editable = true;
		this.budget.editable = false;
	}

	remove() {
		this.parent.removeItem(this);
	}

	update(props: Partial<CostBudgetItem>) {
		Object.assign(this, props);
	}

	editDisabled(subAccounts: string[]) {
		return this.isCostLink && !subAccounts?.includes(this.targetAccountId);
	}

	setPercentage = (percentage: number) => {
		this.cost.setPercentage(percentage);
		this.listingPrice.setPercentage(percentage);
		this.currentEstimate = percentage * this.currentEstimate / 100;
		this.periodEstimate = percentage * this.periodEstimate / 100;
		this.items.forEach(item => item.setPercentage(percentage));
	}

	static newEmptyResource(name: string) {
		const item = new CostBudgetItem();
		Object.assign(item, {
			name,
			id: Utils.guid(),
			split: BudgetSplit.NONE,
			cost: new BudgetItemYearValues({editable: true, parent: item}),
			budget: new BudgetItemYearValues({editable: true, parent: item}),
			listingPrice: new BudgetItemYearValues({editable: false, parent: item}),
			costType: CostType.COST_RESOURCE
		});
		return item;
	}

	static newLink(args: Partial<CostBudgetItem>) {
		const item = CostBudgetItem.newEmptyResource(args.name);
		const {items, ...rest} = args;
		const cost = new BudgetItemYearValues({ ...args.cost, editable: false, parent: item});
		const listingPrice = new BudgetItemYearValues({ ...args.listingPrice, editable: false, parent: item});
		Object.assign(item, { ...rest, cost, listingPrice });

		if (args.hasHierarchy) {
			const convertedItems = CostBudgetItem.prepareLinkItems(item, items, args.hierarchyIds);
			item.items = convertedItems;
		}

		item.setPercentage(args.percentage);
		return item;
	}

	static prepareLinkItems(parent: CostBudgetItem, items: CostBudgetItem[], hierarchyIds: string[]) {
		if (!items) {
			return [];
		}
		return items?.map((i) => {
			if (hierarchyIds.length > 0 && !hierarchyIds.includes(i.id)) {
				return null
			}
			const item = CostBudgetItem.newEmptyResource(i.name);
			item.budget.editable = false;
			Object.assign(item, {
				hierarchyTargetId: i.id,
				costType: i.costType,
				cost: new BudgetItemYearValues({...i.cost, parent: item, editable: false}),
				listingPrice: new BudgetItemYearValues({...i.listingPrice, parent: item, editable: false}),
				items: CostBudgetItem.prepareLinkItems(item, i.items, hierarchyIds),
				parent: parent
			});

			return item;
		}).filter(x => x);
	}

	setSplit(value: BudgetSplit, {displayDecimals, startMonth}: {displayDecimals: number, startMonth: Month}) {
		this.split = value;
		if (value != BudgetSplit.EVEN) {
			return;
		}
		budgetValuesKeys.forEach(prefix => {
			if (!this[prefix].editable) {
				return;
			}
			this[prefix].splitEqual({displayDecimals, startMonth});
		})
	}

	private sumFromItems(accessor: (item: CostBudgetItem) => number | null) {
		return this.items
			.filter(x => !(IGNORED_TYPES.includes(x.costType) || IGNORED_TYPES.includes(x.costTargetType)))
			.map(accessor)
			.filter(x => x != null)
			.reduce((sum, item) => sum + item, null);
	}
}

createModelSchemaWrapper(CostBudgetItem, {
	id: primitive(),
	name: primitive(),
	split: primitive(),
	costType: primitive(),
	cost: withParent(object(BudgetItemYearValues)),
	budget: withParent(object(BudgetItemYearValues)),
	listingPrice: withParent(object(BudgetItemYearValues)),
	items: list(withParent(object(CostBudgetItem))),
	costTargetId: primitive(),
	costTargetType: primitive(),
	costModelId: primitive(),
	hasHierarchy: primitive(),
	hierarchyIds: list(primitive()),
	hierarchyTargetId: primitive(),
	percentage: primitive(),
	currentEstimate: primitive(),
	periodEstimate: primitive(),
	targetAccountId: primitive(),
	targetProfileId: primitive(),
	hasMetrics: primitive(),
	hasEvents: primitive(),
	metrics: list(object(MetricVariant))
})
