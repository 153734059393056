import Utils from 'tools/utils'
import Cookies from 'core/cookies'
import Widget from 'areas/service-boards/widget'
import Settings from 'settings'
import CustomNotification from 'controls/customNotification'
import RemoteEventsManager from 'core/remoteEventsManager'
import Highcharts from 'highcharts'
import Configuration from 'configuration';
import moment from 'moment';
import Renderer from 'tools/renderer';
import {getLegendLabelFormatter, getTrendValueWidgetWrapper} from "vendor-init/hightcharts-intergation";


export function MetricsWidget(config) {
	Widget.call(this, config);

	this.requestPath = Settings.serverPath;
	if (this.sessionId) {
		this.requestPath = Settings.serverPath + 'sessions/' + this.sessionId + '/';
	}
	this.configuration.timezone = this.configuration.timezone === "<User Time>" ? Cookies.CeesoftTimezone : this.configuration.timezone;
	this.subscriberId = this.id;
	//this.initComponent();
	this.loadErrorCodes();
};

export {MetricsWidget as default};

jQuery.extend(MetricsWidget.prototype, Widget.prototype, {
	/**
	 * Main init function
	 */
	initComponent: function () {
		var widgetContentDiv;
		this.isViewer = this.isViewer || false;
		this.subscribed = false;

		this.hasTimeSelector = true;
		this.hasMissingData = true;
		this.hasRegression = true;
		this.hasChartTypeSelector = true;

		this.customControls = {
			target: '#' + this.id,
			toggleClick: function (value) {
				if ($('#' + this.id).find('.k-i-toggle').length) {
					$('#' + this.id).find('.k-i-toggle').trigger('click');
				} else {
					$('#' + this.id).closest('.k-window').find('.k-i-toggle').trigger('click');
				}
			}
		};
		this.createCustomControls();

		if (!this.renderTo) {
			widgetContentDiv = $('#' + this.id).find('.cw_section_content');
			$('#' + this.id).find('.cw_widget_settings').find('.cw_missing_data').detach();
		} else {
			widgetContentDiv = $('#' + this.renderTo);
			widgetContentDiv.closest('.k-window').find('.cw_widget_settings').find('.cw_missing_data').detach();
		}

		this.widgetContentDiv = widgetContentDiv;
		var widgetDiv = $('#' + this.id);
		this.serviceNameLoaded = false;
		this.serviceElementNameLoaded = false;
		this.onZoom = false;
		widgetDiv.off();
		widgetDiv.on('drop', $.proxy(this.onDrop, this));

		if (!this.noTitleNeeded) {
			//Provided backward compatibility
			this.getData();
		}
		this.updatePeriodSelector();
		this.statusNotification = new CustomNotification({
			animationTime: 0,
			appendToElement: widgetContentDiv,
		});
	},
	/**
	 * Updates the widget title
	 */
	updateTitle: function () {
		var title, strTitle;
		var titleSpan = this.isKendoWindow ? $('#' + this.id).closest('.k-window').find('.k-window-title') : $('#' + this.id).find('.cw_section_title');
		var shownProfileText = this.configuration.profileText || '';
		var shownCategoryText = this.configuration.categoryText || '';
		var shownRuleTypeText = this.configuration.ruleTypeText || '';
		var shownSampleRateText = this.configuration.sampleRateText || '';
		if (shownProfileText || shownCategoryText || shownRuleTypeText || shownSampleRateText) {
			title = [this.configuration.profileText, this.configuration.categoryText, this.configuration.ruleTypeText, this.configuration.sampleRateText].join(' &raquo; ');
			strTitle = title.split('&raquo;').join('\u00bb');
		} else {
			title = lang.SINGLEGRAPH;
			strTitle = lang.SINGLEGRAPH;
		}
		title = this.title || title;
		strTitle = this.title || strTitle;
		titleSpan.html(title);
		titleSpan.attr('title', strTitle);
	},
	/**
	 * Gets the inistial data to populate the chart
	 */
	getData: function () {
		var widgetContentDiv = $('#' + this.id).find('.cw_section_content'), startDate, endDate, interval;
		var width = widgetContentDiv.width() - 150, target, url;
		if (!$('#' + this.id).length && this.customControls && this.customControls.target) {
			width = this.customControls.target.width();
		}
		// Quickfix by bogdan because in the viewer the widgetContentDiv.width() is
		// zero.
		if (width <= 0) {
			width = 300;
		}

		this.roundRobinPoints = width;

		if (this.zoomPeriod === 'CUSTOM' || this.instanceConfiguration.period === 'CUSTOM') {
			startDate = new Date(this.zoomStartDate || this.instanceConfiguration.startDate);
			endDate = new Date(this.zoomEndDate || this.instanceConfiguration.endDate);
			url = this.requestPath + 'kpi/data?ignoreMissingData=' + this.instanceConfiguration.ignoreMissingData + '&timeZone=' + this.instanceConfiguration.timezone + '&interval=' + this.interval + '&startDate=' + startDate.getTime() + '&endDate=' + endDate.getTime();
		} else {
			url = this.requestPath + 'kpi/data?ignoreMissingData=' + this.instanceConfiguration.ignoreMissingData + '&timeZone=' + this.instanceConfiguration.timezone + '&interval=' + this.interval + '&timeSelector=' + this.instanceConfiguration.period;
		}

		var data = this.configuration.metrics || [];

		var widgetContentDiv;
		if (!this.renderTo) {
			widgetContentDiv = $('#' + this.id).find('.cw_section_content');
		} else {
			widgetContentDiv = $('#' + this.renderTo);
		}

		widgetContentDiv.addClass('kpi-metricsWidget');

		kendo.ui.progress(widgetContentDiv, true);
		Utils.ajax(url, 'POST', JSON.stringify(data), $.proxy(function (result) {
			this.updateTitle();
			if (result.success || result.data || Object.keys(result).length) {
				result = result[0];
				this.data = [];
				var seriesItem, name, fullName;
				this.seriesItem = {};
				fullName = this.generateMultigraphLabel(result);
				name = fullName.length > 60 ? fullName.substr(0, 57) + '...' : fullName;

				this.seriesItem.name = name;
				this.seriesItem.fullName = fullName;
				this.seriesItem.visible = true;

				if (result.data && result.data.length) {
					this.render(result.data);
				} else if (result.length) {
					this.render(result);
				} else {
					if (this.onZoom) {
						kendo.ui.progress(widgetContentDiv, false);
						this.statusNotification.setOptions({
							message: lang.widget.messages.NO_ZOOM_AVAILABLE,
							status: 'error',
							style: 'left: 70px'
						}).show();
					} else {
						widgetContentDiv.empty().append('<div class="metric_chart"></div>');
						kendo.ui.progress(widgetContentDiv, false);
						this.statusNotification.setOptions({
							message: result.message,
							status: 'error'
						}).show();
					}
				}
			} else {
				if (!this.onZoom) {
					widgetContentDiv.empty().append('<div class="metric_chart"></div>');
				}
				kendo.ui.progress(widgetContentDiv, false);
				this.statusNotification.setOptions({
					message: result.message || lang.serviceBoard.messages.NO_DATA,
					status: 'error'
				}).show();
			}
		}, this), null, 60000, $.proxy(function () {
			kendo.ui.progress(widgetContentDiv, false);
			this.statusNotification.setOptions({
				message: lang.messages.NO_DATA_AVAILABLE,
				status: 'error'
			}).show();
		}, this));

	},
	/**
	 * Creates the chart based on the received dataset
	 * @param {Object} dataSet
	 * @param {String} noDataMessage (Optional) The message to display when there is no data. Default to ''.
	 */
	render: function (dataSet, noDataMessage) {
		noDataMessage = noDataMessage || '';
		var valueLabelsTemplate, valueLabelsFormat, series, tooltip, tooltipLow, tooltipHigh, set, i,
			noDataState, v, averageSet, min, max, widgetContentDiv, zoom, length;
		if (!this.renderTo) {
			widgetContentDiv = $('#' + this.id).find('.cw_section_content');
		} else {
			widgetContentDiv = $('#' + this.renderTo);
		}
		widgetContentDiv.empty().append('<div class="metric_chart"></div>');
		this.dataSet = dataSet;

		if (!this.subscribed) {
			this.subscribe(dataSet);
			this.subscribed = true;
		}

		this.data = [];
		this.average = [];
		this.noData = [];

		min = Utils.getMinFromObjectArray(this.dataSet, 'value');
		this.min = min;
		max = Utils.getMaxFromObjectArray(this.dataSet, 'value');
		this.max = max;

		if (this.configuration.chartType === 'area') {
			min = 0;
		}
		if (this.dataSet.length) {
			noDataState = (this.dataSet[0].value === null);
		}

		for (let i = 0, length = dataSet.length; i < length; i++) {
			set = [];
			if (dataSet[i].value === null) {
				this.noData.push([dataSet[i].time, min, max]);
				noDataState = true;
			} else {
				if (noDataState) {
					this.noData.push([dataSet[i].time, min, max]);
					noDataState = false;
				} else {
					if (!this.noData.length || this.noData[this.noData.length - 1][1] !== null) {
						this.noData.push([dataSet[i].time, null, null]);
					}
				}
			}

			set = [dataSet[i].time, dataSet[i].value];
			if (this.configuration.chartType === 'range') {
				set = [dataSet[i].time, min, max];
				this.average.push([dataSet[i].time, dataSet[i].value]);
			}

			if (set.length) {
				this.data.push(set);
			}
		}

		this.count = dataSet.length || 1;

		var oThis = this;
		series = [{
			regression: this.configuration.showRegression,
			regressionSettings: {
				type: 'linear',
				color: 'rgba(255,165,0,1)',
				name: this.seriesItem.name,
				fullName: this.seriesItem.fullName
			},
			dataGrouping: {
				approximation: this.instanceConfiguration.aggregationType || "average"
			},
			data: this.data,
			step: false,
			zIndex: 2,
			name: this.seriesItem.name,
			fullName: this.seriesItem.fullName,
			showInLegend: this.seriesItem.name ? true : false
		}, {
			type: 'arearange',
			color: '#CCCCCC',
			data: this.noData,
			name: 'NODATA_AREA',
			zIndex: 1,
			showInLegend: false
		}];

		if (this.configuration.chartType === 'range') {
			series.push({
				dataGrouping: {
					approximation: this.instanceConfiguration.aggregationType || "average"
				},
				data: this.average,
				zIndex: 1,
				color: '#2f7ed8',
				lineWidth: 1,
				type: 'spline',
				showInLegend: false
			});
		}

		var exporting = jQuery.extend(true, {}, Configuration.highcharts.exporting);
		if (this.onZoom) {
			exporting.enabled = true;
			exporting.buttons.popUpBtn = {
				onclick: $.proxy(function () {
					this.zoomStartDate = null;
					this.zoomEndDate = null;
					this.zoomPeriod = null;
					this.onZoom = false;

					this.subscribed = false;
					this.getData();

					if (this.customControls && typeof this.customControls.zoom === 'function') {
						this.customControls.zoom.call(this, {
							startDate: this.configuration.startDate,
							endDate: this.configuration.endDate,
							period: this.configuration.period
						});
					}
				}, this),
				align: 'left',
				width: 5,
				x: 5,
				y: 5,
				text: lang.RESET,
				theme: {
					'stroke-width': 1,
					stroke: '#aaa',
					fill: '#fff',
					r: 0,
					states: {
						hover: {
							fill: '#eee'
						},
						select: {
							fill: '#ccc'
						}
					}
				}
			};
		}
		var plotingOptionsErrorLine = {
			color: '#FF0000',
			lineWidth: 4,
			states: {
				hover: {
					lineWidth: 5
				}
			},
			marker: {
				enabled: true,
				fillColor: '#FF0000',
				symbol: 'circle',
				radius: 4,
				states: {
					hover: {
						radius: 6
					}
				}
			}
		};

		var plotingOptionsBooleanLine = {
			marker: {
				enabled: false,
				states: {
					hover: {
						fillColor: '#4572A7',
						lineWidth: 1,
						lineColor: '#4572A7',
						radius: 4,
						symbol: 'circle'
					}
				}
			}
		};

		zoom = dataSet.length > 1 ? 'x' : false;

		Highcharts.setOptions({
			global: {
				useUTC: true,
				timezoneOffset: -moment.tz(this.configuration.timezone).utcOffset()
			}
		});

		let container = widgetContentDiv.find('.metric_chart');
		let height;
		let dashboardWindow = container.closest('.section__content');
		if (dashboardWindow.length) {
			height = dashboardWindow.height();
		} else {
			height = widgetContentDiv.height();
		}

		let header = dashboardWindow.closest('.html-shape-container').find('.toolbar_appearance_section-header').first();
		let missingHeader = false;
		if (header.css('display') === 'none' || header.width() === -30) {
			missingHeader = true;
		}
		if (this.instanceConfiguration.timeSelectorPosition === 'BOTTOM') {
			height = height - 30;
			this.moveTimeSelectorBottom(missingHeader);
		} else if (this.instanceConfiguration.timeSelectorPosition === 'TOP' && missingHeader)  {
			height = height - 30;
			this.renderTo.css('margin-top', '35px');
		}

		this.chart = new Highcharts.Chart({
			chart: {
				renderTo: container[0],
				height: height,
				type: this.instanceConfiguration.chartType,
				zoomType: zoom,
				marginBottom: 50,
				events: {
					load: function () {
						kendo.ui.progress(widgetContentDiv, false);
					},
					selection: $.proxy(function (event) {
						if (event.xAxis) {
							this.onZoom = true;
							this.zoomStartDate = parseInt(event.xAxis[0].min, 10);
							this.zoomEndDate = parseInt(event.xAxis[0].max, 10);
							this.zoomPeriod = 'CUSTOM';

							this.unsubscribe();

							if (this.customControls && typeof this.customControls.zoom === 'function') {
								this.customControls.zoom.call(this, {
									startDate: this.zoomStartDate,
									endDate: this.zoomEndDate,
									period: this.zoomPeriod
								});
							}
						}
						event.preventDefault();
						this.getData();
					}, this)
				}
			},
			title: {
				text: ' '
			},
			legend: {
				title: {
					text: '', //lang.assethealth.MONITOR_NAME,
					align: 'center'
				},
				x: 2,
				y: 20,
				floating: false,
				borderWidth: 0,
				layout: 'horizontal',
				align: 'center',
				verticalAlign: 'bottom',
				useHTML: true,
				itemDistance: 15,
				itemStyle: {
					fontSize: "10px"
				},
				style: {
					fontSize: "10px"
				},
				labelFormatter: function () {
					return getLegendLabelFormatter(this);
				}
			},
			credits: {
				enabled: false
			},
			exporting: exporting,
			plotOptions: {
				spline: {
					marker: {
						enabled: false,
						states: {
							hover: {
								fillColor: '#4572A7',
								lineWidth: 1,
								lineColor: '#4572A7',
								radius: 4,
								symbol: 'circle'
							}
						}
					}
				},
				scatter: {
					marker: {
						radius: 0,
						states: {
							hover: {
								enabled: false,
								lineColor: 'rgb(100,100,100)'
							}
						}
					},
					states: {
						hover: {
							marker: {
								enabled: false
							}
						}
					}
				},
				line: this.instanceConfiguration.chartType === 'line' ? plotingOptionsBooleanLine : plotingOptionsErrorLine,
				areaspline: {
					marker: {
						enabled: false,
						states: {
							hover: {
								fillColor: '#4572A7',
								lineWidth: 1,
								lineColor: '#4572A7',
								radius: 4,
								symbol: 'circle'
							}
						}
					}
				},
				series: {
					cursor: 'pointer',
					events: {
						click: $.proxy(this.onChartPointClick, this)
					},
					marker: {
						enabled: false
					}
				}
			},
			lang: {
				noData: noDataMessage
			},
			noData: {
				style: {
					fontWeight: 'bold',
					fontSize: '15px',
					color: '#303030'
				}
			},
			xAxis: {
				type: 'datetime',
				labels: {
					staggerLines: 1
				},
				dateTimeLabelFormats: {
					millisecond: Utils.widgetDateFormat(Cookies.CeesoftUserDateTimeFormat, 'metric', 'millisecond'),
					second: Utils.widgetDateFormat(Cookies.CeesoftUserDateTimeFormat, 'metric', 'second'),
					minute: Utils.widgetDateFormat(Cookies.CeesoftUserDateTimeFormat, 'metric', 'minute'),
					hour: Utils.widgetDateFormat(Cookies.CeesoftUserDateTimeFormat, 'metric', 'hour'),
					day: Utils.widgetDateFormat(Cookies.CeesoftUserDateTimeFormat, 'metric', 'day'),
					week: Utils.widgetDateFormat(Cookies.CeesoftUserDateTimeFormat, 'metric', 'week'),
					month: Utils.widgetDateFormat(Cookies.CeesoftUserDateTimeFormat, 'metric', 'month'),
					year: Utils.widgetDateFormat(Cookies.CeesoftUserDateTimeFormat, 'metric', 'year')
				},
				visible: this.instanceConfiguration.showXAxis
			},
			yAxis: {
				softMin: 0,
				minPadding: 0,
				title: {
					text: null
				},
				//plotLines: plotLines,
				labels: {
					formatter: function () {
						return this.value;
					}
				},
				visible: this.instanceConfiguration.showYAxis
			},
			tooltip: {
				crosshairs: true,
				snap: 1,
				style: {
					padding: '5px',
					width: '250px',
					height: '160px'
				},
				useHTML: true,
				// shared: this.aggregate ? false : true,
				formatter: function () {
					var userOptions = this.series.userOptions || {};
					if (userOptions.isRegressionLine) {
						return getTrendValueWidgetWrapper(userOptions, oThis);
					} else {
						var s = Renderer.browserDateRenderer(this.x, 'datetime', '', oThis.configuration.timezone);
						var v = '<br />';
						v += lang.VALUE + ': ' + this.y;
						return s + v + '<br />';
					}
				}
			},
			series: series
		});

		Highcharts.setOptions({
			global: {
				useUTC: false
			}
		});
	},
	getMax: function (t) {
		var max = 0;
		for (var i = 0, length = this.dataSet.length; i < length; i++) {
			if (this.dataSet[i].time === t) {
				max = this.dataSet[i].vH;
				break;
			}
		}
		return max;
	},
	/**
	 * Gets a point from the data set at a given timestamp
	 * @param {Number} t The timestamp
	 * @return {Object} The data point
	 */
	getPoint: function (t) {
		for (var i = 0, length = this.dataSet.length; i < length; i++) {
			if (this.dataSet[i].time === t) {
				return this.dataSet[i];
			}
		}
		return {
			vH: 0,
			vL: 0,
			v: 0
		};
	},
	/**
	 * Gets the minimum value of a given timestamp
	 * @param {Number} t The timestamp
	 * @return {Number} min The min value
	 */
	getMin: function (t) {
		var min = 99999;
		for (var i = 0, length = this.dataSet.length; i < length; i++) {
			if (this.dataSet[i].time === t) {
				min = this.dataSet[i].vL;
				break;
			}
		}
		return min;
	},
	/**
	 * Search the data for a timestamp point and returns its error code
	 * @param {Number} timestamp
	 * @return {String} errorCode
	 */
	getErrorCode: function (timestamp) {
		var errorCode = '';
		var t0;
		for (var i = 0, length = this.dataSet.length; i < length; i++) {
			if (this.dataSet[i].time === timestamp) {
				for (var j = 0, length1 = this.dataSet[i].e.length; j < length1; j++) {
					if (errorCode !== '') {
						errorCode += ', ';
					}
					t0 = this.dataSet[i].t0;

					errorCode = ErrorCodes.get(this.dataSet[i].e[j]).text;
				}
				break;
			}
		}
		return {errorCode: errorCode, t0: t0};
	},
	/*
	 * Handler function for handling multiple events
	 * @param {Array} events The array of events
	 * */
	onEvents: function (events) {
		if (this.chart) {
			for (var i = 0, length = events.length; i < length; i++) {
				this.onEvent(events[i]);
			}
		}
	},
	/**
	 * Called when a metric event is received
	 * @param {Object} data The event data object
	 */
	onEvent: function (data) {
		//console.log('KpiCharts/onEvent');
		//console.log(data);

		var doRoundRobin = true;
		if (this.dataSet.length < this.roundRobinPoints) {
			doRoundRobin = false;
		}

		if (!this.configuration.chartType !== 'range') {
			this.chart.series[0].addPoint([data.time, data.value], true, doRoundRobin);

		} else {
			if (data.value < this.min) {
				this.min = data.value;
			} else if (data.value > this.max) {
				this.max = data.value;
			}

			this.chart.series[0].addPoint([data.time, this.min, this.max], true, doRoundRobin);
			this.chart.series[3].addPoint([data.time, data.value], true, doRoundRobin);
		}

		if (doRoundRobin) {
			this.dataSet.splice(0, 1);
		}
		this.dataSet.push({
			time: data.time,
			value: data.value
		});
		if (data.e && data.e[0] !== 0) {
			this.chart.series[1].addPoint({
				x: data.time,
				y: 0,
				marker: {
					enabled: true,
					fillColor: '#FF0000',
					symbol: 'circle',
					radius: 4,
					states: {
						hover: {
							radius: 6
						}
					}
				}
			}, true, doRoundRobin);
		} else {
			this.chart.series[1].addPoint({
				x: data.time,
				y: null
			}, true, doRoundRobin);
		}
	},
	/**
	 * Handler function for the chart point click
	 * @param {object} e The click event
	 */
	onChartPointClick: function (e) {
		var name = e.currentTarget.name;
		if (name !== 'NODATA') {

		}
	},
	/**
	 * Triggered after widget resize
	 * @param {Object} event The resize event
	 * @param {Object} ui The UI element - see http://api.jqueryui.com/resizable/
	 */
	onResize: function (event, ui) {
		kendo.ui.progress(this.widgetContentDiv, true);
		this.unsubscribe();
		this.subscribed = false;
		this.getData();
		setTimeout(() => {
			this.createCustomControls();
		});
	},
	/**
	 * Handler function for the drop event
	 */
	onDrop: function () {
		this.destroy();

		this.subscribed = false;
		this.getData();
	},
	/**
	 * Updates multi toggle component position. When widget size is lower than 440
	 * px, period selector moves on top of the section content
	 */
	updatePeriodSelector: function () {
		var widgetDiv = $('#' + this.id);
		if (widgetDiv.width() < 440) {
			widgetDiv.find('.cw_multi_toggle').css({
				'right': '45px',
				'z-index': 100
			});
			widgetDiv.find('.cw_section_titlebar').css('overflow', 'visible');
		} else {
			widgetDiv.find('.cw_multi_toggle').css({
				'position': 'relative',
				'top': 'none',
				'right': 'none'
			});
			widgetDiv.find('.cw_section_titlebar').css('overflow', 'hidden');
		}
	},
	/**
	 * Subscribes to server metric events
	 * @param {Object} data The object with data for subscription
	 */
	subscribe: function (data) {
		//custom controls 'Period multi toggle' is subscribing with boolean parameter

		if (typeof data === 'boolean') {
			this.getData();
			return;
		}

		var subscriptionObj = [{
			eventType: 'Kpi',
			filters: [{
				profileId: this.configuration.profileId,
				accountId: this.configuration.kpiAccountId === 'aggregated' ? null : this.configuration.kpiAccountId, //kpi account id
				configurationId: data[0].configurationId
			}]
		}];
		RemoteEventsManager.subscribe(this.subscriberId, subscriptionObj);
	},
	/**
	 * Destroy
	 */
	destroy: function (dontUnsubscribe) {
		if (!dontUnsubscribe) {
			Widget.prototype.destroy.call(this);
		}
		/*if (this.chart && $(this.chart.renderTo).length) {
			this.chart.destroy();
		}*/
	}
});
