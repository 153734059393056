import BaseFormatPanel from 'controls/designer/graph-editor/js/baseFormatPanel';
import {
	addDesignerWindowStateStorage
} from './helperDesignerWindowPositions';

(function () {
	Format.prototype.createWindow = async function (str, align, valign, style, overflow) {
		this.formatWindow = $(this.container).kendoWindow(
			await addDesignerWindowStateStorage(this.editorUi.designer,'properties', {
				minWidth: this.editorUi.formatWidth,
			})
		).data("kendoWindow");

		this.editorUi.addWindowButtonToToolbar('formatPanel',
			lang.designer.labels.PROPERTIES,
			this.formatWindow,
			() =>  $('<a href="javascript:void(0)" class="geButton geToolbar__properties"><i class="glyphicons cogwheel"></i></a>')[0]
		);

		var action = this.editorUi.actions.addAction(
			'formatPanelShow',
			() => this.formatWindow.open(),
			null,
			null,
			null,
			lang.designer.labels.PROPERTIES
		);
	};

	const originalRefresh = Format.prototype.refresh;
	Format.prototype.refresh = async function () {
		await originalRefresh.apply(this, arguments);

		if( this.formatWindow.wrapper.is(":hidden") )
			return;

		if(this.editorUi.editor == null)
			return

		var cells = this.editorUi.editor.graph.getSelectionCells();
		let title = lang.designer.propertiesToolbar.TITLE_NO_SELECTION;
		if (cells.length == 1 && cells[0].isServiceEntity() && !cells[0].isServiceLink()) {
			title = lang.designer.propertiesToolbar.TITLE_SELECTION.replace("{0}",
				this.editorUi.editor.graph.convertValueToString(cells[0]));
		}

		this.formatWindow.setOptions({title: title});
		$(this.container)
			.closest('.k-window')
			.find('.k-window-title')
			.attr('title', title);
	},

	Format.prototype.destroy = function () {
		if (this.formatWindow != null)
			this.formatWindow.destroy();

		$(document).off('model-changed.sd', this.update);
		window.removeEventListener('resize', this.onResize);
	}

	Format.prototype.show = function () {
		this.formatWindow.open();
	}

	var defaultFormatInit = Format.prototype.init;
	Format.prototype.init = async function () {
		await this.createWindow();

		defaultFormatInit.apply(this, arguments);

		$(document).on('model-changed.sd', this.update);
	}

	var defaultEditorUiDestroy = EditorUi.prototype.destroy;
	EditorUi.prototype.destroy = function () {
		defaultEditorUiDestroy.apply(this, arguments);
		if (this.format != null) {
			this.format.destroy();
		}
	}

	BaseFormatPanel.prototype.createRow = function (label, content, title = "") {
		var row = $(
			'<div class="panel-row">' +
				(label ? '<div class="label" title="' + title + '">' + label + ':</div>' : '' )+
			'<div class="form-element clearfix"></div>' +
			'</div>');
		let root = this.root;
		if( root == null ){
			root = $('<div class=panel/>');
			this.container.appendChild(root[0]);
		};

		root.append(row);

		if (content != null)
			row.find('.form-element').append(content);

		return row;
	}

	BaseFormatPanel.prototype.createDropdownRow = function (label, config, title) {
		var row = this.createRow(label, '<div class="_select">', title);

		return row.find('._select').kendoDropDownList(config || {
			dataTextField: 'text',
			dataValueField: 'value',
			dataSource: [],
			index: 0
		}).data("kendoDropDownList");
	}

	BaseFormatPanel.prototype.createComboBoxRow = function (label, placeholder) {
		var row = this.createRow(label, '<select class="_select" placeholder="' + placeholder + '">');

		return row.find('._select').kendoComboBox({
			dataTextField: 'text',
			dataValueField: 'value',
			dataSource: [],
			index: 0
		}).data("kendoComboBox");
	}


})();
