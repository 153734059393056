import Utils from 'tools/utils';
import Cookies from 'core/cookies';

import LocalEventsManager from 'core/localEventsManager';
import ErrorHandler from 'core/errorHandler';
import ModalWindow from 'controls/modalWindow';
import AssetForm from 'areas/administration/assetForm';
import Settings from 'settings';
import {translator} from "../../core";
import {CustomNotification} from 'controls';
import {ManagementApi} from "./api";

const i = translator();

export default function AgentForm (config) {
    Utils.apply(this, config);
    this.initComponent();
};

jQuery.extend(AgentForm.prototype, {
    /**
     * This is main init function
     */
    initComponent: async function () {
        this.removeListeners();
        this.attachListeners();
        this.initKendoComponents();
        if (this.mode === 'create') {
        	this.checkSave();
        }
		this.modalNotification = new CustomNotification({
			appendToElement: '.connect_agent',
			status: 'success',
			type: 'icon',
			hideOnClick: true
		});
    },
    /**
     * Called in order to remove existing listeners
     */
    removeListeners: function () {
        $('#cw_agent_create').off();
        $('#cw_agent_cancel').off();
    },
    /**
     * Attaches the listeners for the form's buttons
     */
    attachListeners: function () {
        $('#cw_agent_create').on('click', $.proxy(this.onSaveButton, this));
        $('#cw_agent_cancel').on('click', $.proxy(this.onCancelButton, this));
        $('.cw_create_asset_new').on('click', $.proxy(this.onCreateAssetClick, this));
        $('#agent_name, #cw_agent_hostname, #cw_agent_port').on('keyup', $.proxy(this.checkSave, this));
        LocalEventsManager.bind('assetsaved', $.proxy(this.onAssetSaved, this));
    },
	checkSave: function () {
		const name = $('#agent_name').val();
		const host = $('#cw_agent_hostname').val();
		const port = $('#cw_agent_port').val();
		Utils.setInvalidField(name, $('#agent_name'), true);
		Utils.setInvalidField(host, $('#cw_agent_hostname'), false, 'required_textbox');
		Utils.setInvalidField(this.assetsList.value(), $('#cw_agent_asset'), false, 'required_input');
		Utils.setInvalidField(port, $('#cw_agent_port'), false, 'required_multiselect');
		Utils.setPlaceholder($('#agent_name'), i('Please input name...'));
		if (name && host && port.toString() && this.assetsList.value()) {
			$('#cw_agent_create').attr('disabled', false);
		} else {
			$('#cw_agent_create').attr('disabled', true);
		}
	},
    /**
     * This is the init function for the kendo components.
     */
    initKendoComponents: function () {
        this.assetsList = $('#cw_agent_asset').kendoComboBox({
            autoBind: true,
            dataTextField: 'name',
            dataValueField: 'id',
            placeholder: lang.designer.SELECT_ASSET,
            enable: true,
	        change: () => this.checkSave(),
            dataSource: new kendo.ceeview.DataSource({
                transport: {
                    read: {
                        url: Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/assets/typeExcludes/AGENT/lite',
                        contentType: 'application/json; charset=utf-8',
                        type: 'GET',
                        dataType: 'json',
                        cache: false
                    }
                },
                error: ErrorHandler.kendoServerError
            }),
            delay: 500
        }).data('kendoComboBox');
        this.protocolsList = $('#cw_agent_protocol').kendoDropDownList({
            autoBind: true,
            dataTextField: 'text',
            dataValueField: 'value',
            placeholder: lang.agents.messages.SELECT_PROTOCOL,
            enable: true,
            dataSource: {
                data: [
                    {text: 'HTTP', value: 'http://'},
                    {text: 'HTTPS', value: 'https://'}
                ]
            }
        }).data('kendoDropDownList');
        this.port = $('#cw_agent_port').kendoNumericTextBox({
            decimals: 0,
            format: '#####',
            min: 1,
            max: 65535,
            spinners: false,
	        change: () => this.checkSave()
        }).data('kendoNumericTextBox');
    },
    /*
     * Handler function for clicking create asset
     * */
    onCreateAssetClick: function () {
        var width = 400;
        this.sqAssetWindow = new ModalWindow({
            title: lang.account.ASSET_CREATE,
            width: width,
            minHeight: 567,
            height: 'auto',
            url: 'include/Administration/AssetForm.jsp',
            refresh: function () {
                new AssetForm({
                    id: null,
                    mode: 'create'
                });
            }
        });
        this.sqAssetWindow.open();
    },
    /*
     * Handler function called when asset is saved by eventmanager
     * @param {Object} data The object with saved data (asset, targets)
     * */
    onAssetSaved: function (data) {
        var asset = data.asset;
        var combo = $('#cw_agent_asset').data('kendoComboBox');

        //add asset
        var dataSource = combo.dataSource.data();
        dataSource.push({
            id: asset.id,
            name: asset.name
        });
        combo.value(asset.id);
    },
    /**
     * Callback function that handles the save response
     * @param {Object} result
     */
    onDataSuccessfullySaved: function (result) {
        if (result.success) {
            this.onCancelButton();
            var status = $('.status');
            status.find('p').addClass('success');
            var successText = (this.mode === 'create' ? lang.account.messages.AGENT_SUCCESS_CREATED : lang.account.messages.AGENT_SUCCESS_UPDATED);
            status.find('p').text(successText);
            status.slideDown().delay(2000).slideUp();
        } else {
            var modalStatus = $('#agent_modal').find('.status');
            modalStatus.find('p').addClass('error');
            modalStatus.find('p').text(result.message);
            modalStatus.slideDown().delay(2000).slideUp();
        }
    },
    /**
     * Handler function for the click event on Save button
     * @param {Object} e The click event object
     */
    onSaveButton: async function (e) {
		let nameExists = await Utils.checkIfNameExists(ManagementApi.getSearchUrl(), $('#agent_name').val(), this.modalNotification, true);
		if(nameExists) {
			return;
		}
        var hostname = $('#cw_agent_hostname').val().trim();
        var connectionUrl = this.protocolsList.value() + hostname + ':' + this.port.value();
        this.data = {
            name: $('#agent_name').val().trim(),
            description: $('#agent_description').val().trim(),
            id: null,
            parentId: null,
            allowDataCollection: $('#cw_allow_data_collection').is(':checked'),
            shared: $('#cw_agent_shared_toggle').is(':checked'),
            allowSubAgents: $('#cw_allow_sub_agents').is(':checked'),
            autoUpdate: $('#cw_agent_auto_update_toggle').is(':checked'),
            assetId: this.assetsList.value(),
            url: connectionUrl
        };
        var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/agents';
        Utils.ajax(url, 'POST', JSON.stringify(this.data), $.proxy(this.onDataSuccessfullySaved, this));
    },
    /**
     * Handler function for the click event on Cancel button
     * @param {Object} e The click event object
     */
    onCancelButton: function (e) {
        var modalWindow = $('#agent_modal').data("kendoWindow");
        modalWindow.close();
        modalWindow.destroy();
    }
});
