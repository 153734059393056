import Utils from 'tools/utils';
import Cookies from 'core/cookies';
import Settings from 'settings';
import CustomNotification from 'controls/customNotification';

import RemoteEventsManager from 'core/remoteEventsManager';
import LocalEventsManager from 'core/localEventsManager';
import ExpandableTextarea from 'controls/expandableTextarea';
import moment from 'moment';


export default function MaintenancePeriod(config) {
	Utils.apply(this, config);
	this.initComponent();
};

MaintenancePeriod.prototype = {
	/**
	 * @cfg {String} id The id of the maintenance period (only in update mode)
	 */
	/**
	 * @cfg {String} entityId The id of the entity to be set in maintenance
	 */
	/**
	 * @cfg {String} accountId The id of the service's account
	 */
	/**
	 * @cfg {String} layout 'window' or 'popup'
	 */
	/**
	 * @cfg {String} mode 'create' or 'update'
	 */
	/**
	 * @cfg {Array} services Optional. Array with entities IDs
	 */
	/**
	 * @cfg {String} type The type of the entity ("service/agent")
	 */
	/**
	 * @private
	 */
	_types: ['agent', 'service', 'asset'],
	_paths: ['agents', 'services', 'assets'],
	layout: 'popup',
	_multipleSavePaths: ['multipleAgents', 'multipleServices', 'multipleAssets'],
	/**
	 * Main init function
	 */
	initComponent: function () {
		var html;
		this.isSaved = false;
		this.windowId = Utils.guid();
		this.selector = '#' + this.windowId;
		if (!$(this.selector).length) {
			$('body').append('<div id="' + this.windowId + '"></div>');
		}

		this.modalNotification = new CustomNotification({
			appendToElement: '#cw_service_details_tab_nav-4',
			status: 'success',
			type: 'icon',
			hideOnClick: true
		});

		this.descriptionHandler = new ExpandableTextarea({
			label: lang.DESCRIPTION,
			renderTo: $('.cw_maintenance_description')
			//keyUp: $.proxy(this.onDetailChange, this)
		});

		if (this.layout === 'window') {
			$(this.selector).kendoWindow({
				animation: false,
				width: '730px',
				height: '200px',
				position:{
					top: 0,
					left: 0
				},
				title: lang.MAINTENANCE,
				modal: true,
				visible: false,
				activate: $.proxy(function () {
					$(this.selector).find('input:text:not([readonly])').first().focus();
				}, this),
				close: $.proxy(function () {
					$(this.selector).data("kendoWindow").destroy();
					if (this.mode === 'update') {
						RemoteEventsManager.discard(this.id);
					}
				}, this)
			});
			html = '<div class="cw_maintenance">';
			html += '	<div class="form">';
			html += '		<div class="left w50">';
			html += '			<div class="cw_field">';
			html += '				<label class="cw_inline">' + lang.reports.DATE_FROM + '</label>';
			html += '				<div class="cw_dropdown_container">';
			html += '					<input id="cw_maintenance_from" />';
			html += '				</div>';
			html += '			</div>';
			html += '			<div class="cw_field">';
			html += '				<label class="cw_inline">' + lang.reports.DATE_TO + '</label>';
			html += '				<div class="cw_dropdown_container">';
			html += '					<input id="cw_maintenance_to" />';
			html += '				</div>';
			html += '			</div>';
			html += '		</div>';
			html += '		<div class="right w45 cw_maintenance_description"></div>';
			html += '       <div class="cw_field" id="cw_add_exclude_periods">';
			html += '           <input type="checkbox" id="cw_exclude_periods_checkbox" class="k-checkbox">';
			html += '           <label for="cw_exclude_periods_checkbox" class="k-checkbox-label">' + lang.service.ADD_EXCLUDE_PERIODS + '</label>';
			html += '       </div>';
			html += '	</div>';
			html += '	<div class="status"><p></p></div>';
			html += '</div>';
			html += '<div class="cw_actions">';
			html += '	<button class="k-button right" id="cancel_maintenance">' + lang.CANCEL + '</button>';
			html += '	<button class="k-button k-primary right" id="save_maintenance">' + lang.CREATE + '</button>';
			html += '</div>';
			this.window = $(this.selector).data('kendoWindow');
			this.window.content(html);

			this.descriptionHandler = new ExpandableTextarea({
				label: lang.DESCRIPTION,
				renderTo: $('.cw_maintenance_description')
				//keyUp: $.proxy(this.onDetailChange, this)
			});

			this.window.open().center();
		} else {
			$('.cw_maintenance').removeClass('is_hidden');
			if (this.mode === 'update') {
				$('#save_maintenance').html(lang.UPDATE);
				$('#cw_add_exclude_periods').addClass('hide');
			} else if (this.mode === 'create') {
				$('#save_maintenance').html(lang.CREATE);
				$('#cw_add_exclude_periods').removeClass('hide');
			}
		}
		if (this.type === 'agent' || this.type === 'asset' || !State.mainApp.session.hasRole('SLA_LIST')) {
			$('#cw_add_exclude_periods').addClass('hide');
		}
		this.removeListeners();
		this.attachListeners();
		this.initKendoComponents();
	},
	/**
	 * Removes listeners
	 */
	removeListeners: function () {
		$('#cancel_maintenance').off();
		$('#save_maintenance').off();
	},
	/**
	 * Attaches listeners
	 */
	attachListeners: function () {
		$('#cancel_maintenance').on('click', $.proxy(this.onClose, this));
		$('#save_maintenance').on('click', $.proxy(this.onSave, this));
	},
	initKendoComponents: function () {
		this.minutesBrowserTimeZoneDiff = new Date().getTimezoneOffset();
		this.msBrowserTimeZoneDiff = this.minutesBrowserTimeZoneDiff * 60000;
		this.minutesUserTimeZoneDiff = moment.tz.zone(Cookies.CeesoftTimezone).utcOffset(new Date());
		this.msUserTimeZoneDiff = this.minutesUserTimeZoneDiff * 60000;
		this.minutesSelectedTimeZoneDiff = moment.tz.zone(this.timezone || Cookies.CeesoftTimezone).utcOffset(new Date()); //in minutes
		this.msSelectedTimeZoneDiff = this.minutesSelectedTimeZoneDiff * 60000; //convert minutes to milliseconds

		this.fromInMs = new Date().getTime() - this.msSelectedTimeZoneDiff + this.msBrowserTimeZoneDiff;
		var fromInReadable = new Date(this.fromInMs);
		this.diffTime = 1 * 60 * 1000; //1 min x 60 seconds x 1000 milliseconds
		var toInMs = new Date(this.fromInMs + this.diffTime);
		var toInReadable = new Date(toInMs);

		if (this.mode === 'create') {
			//round to nearest future hour
			let hour = fromInReadable.getHours();
			//fromInReadable.setHours(hour + 1);
			//fromInReadable.setMinutes(0, 0, 0);
			toInReadable.setHours(hour + 1);
			toInReadable.setMinutes(0, 0, 0);
		}

		this.fromField = $('#cw_maintenance_from').kendoDateTimePicker({
			format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
			timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserDateTimeFormat),
			//parseFormats: ['HH', 'HH:mm'],
			change: $.proxy(this.onFromChange, this),
			open: $.proxy(this.onFromOpen, this),
			interval: 15,
			//value: fromInReadable
			value: this.mode === 'create' ? lang.NOW : fromInReadable
		}).data('kendoDateTimePicker');
		if (this.fromField.value()) {
			this.cachedFromValue = this.fromField.value();
		} else if (this.fromField.options.value === lang.NOW) {
			this.cachedFromValue = new Date();
		}
		this.toField = $('#cw_maintenance_to').kendoDateTimePicker({
			format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
			timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserDateTimeFormat),
			//parseFormats: ['HH', 'HH:mm'],
			change: $.proxy(this.onToChange, this),
			open: $.proxy(this.onToOpen, this),
			interval: 15,
			value: toInReadable
		}).data('kendoDateTimePicker');
		this.cachedToValue = this.toField.value();
		if (this.mode === 'update') {
			this.load();
		} else {
			this.fromField.enable(true);
			this.toField.enable(true);
			this.descriptionHandler.value('');
			this.fromField.min(new Date(this.fromInMs));
			this.toField.min(new Date(this.fromInMs));
		}
	},
	load: function () {
		var url = Settings.serverPath + 'accounts/' + this.accountId + '/' + this._paths[this._types.indexOf(this.type)] + '/' + this.entityId + '/maintenance/' + this.id + '?update=true';
		Utils.ajax(url, 'GET', {}, $.proxy(function (result) {
			if (result.success) {
				let fromTimezoneData = result.data.fromTime - this.msSelectedTimeZoneDiff + this.msBrowserTimeZoneDiff;
				let toTimezoneData = result.data.toTime - this.msSelectedTimeZoneDiff + this.msBrowserTimeZoneDiff;
				this.fromField.value(new Date(fromTimezoneData));
				this.toField.value(new Date(toTimezoneData));
				this.cachedFromValue = this.fromField.value();
				this.cachedToValue = this.toField.value();
				this.descriptionHandler.value(result.data.reason);
				if (this.mode === 'update') {
					if (new Date(result.data.toTime) < new Date()) {
						//historic period
						this.isHistoricPeriod = true;
						this.fromField.enable(false);
						this.toField.enable(false);
						this.modalNotification.setOptions({
							message: lang.messages.MAINTENANCE_HISTORIC_NOT_EDITABLE,
							status: 'info'
						}).show();
					} else {
						this.isHistoricPeriod = false;
						this.fromField.enable(true);
						this.toField.enable(true);
						this.fromField.min(new Date(this.fromInMs));
						this.toField.min(new Date(this.fromInMs));
					}
				}
			} else {
				this.modalNotification.setOptions({
					message: result.message,
					status: 'error'
				}).show();
			}
		}, this));
	},
	/**
	 * Handler function for the FROM datepicker change events. Syncs with TO field
	 */
	onFromChange: function (e) {
		var startDate;
		if (this.fromField.value()) {
			startDate = this.fromField.value();
		} else if (this.fromField.options.value === lang.NOW) {
			startDate = new Date();
		}
		var	endDate = this.toField.value();
		if (!startDate) {
			var startDateText = e.sender.element.val();
			var time = startDateText.split(' ').pop();
			var doublePointsLength = time.split(':').length - 1;
			if (doublePointsLength === 0) {
				startDateText += ':00:00';
				this.fromField.value(startDateText);
			} else if (doublePointsLength === 1) {
				startDateText += ':00';
				this.fromField.value(startDateText);
			} else {
				//if bad data put back cached value
				this.fromField.value(this.cachedFromValue);
			}
			if (startDate < new Date()) {
				this.fromField.value(this.cachedFromValue);
			} else {
				this.cachedFromValue = startDate;
			}
			return;
		}
		$('#cw_maintenance_from').removeClass('invalid');
		if (startDate) {
			let startDateInMsTzAdjusted = new Date(startDate.getTime()) - this.msSelectedTimeZoneDiff + this.msBrowserTimeZoneDiff;
			this.toField.min(new Date(startDateInMsTzAdjusted));
			if (endDate && startDate.getTime() > endDate.getTime()) {
				endDate = new Date(startDate.getTime() + this.diffTime);
				this.toField.value(endDate);
				this.cachedToValue = this.toField.value();
			}
		} else if (endDate) {
			//this.fromField.max(new Date(endDate.getTime() - 15*60*1000));
		} else {
			let nowInMsTzAdjusted = new Date().getTime() - this.msSelectedTimeZoneDiff + this.msBrowserTimeZoneDiff;
			endDate = new Date(nowInMsTzAdjusted + this.diffTime);
			//this.fromField.max(endDate);
			this.toField.min(endDate);
		}

	},
	/**
	 * Handler function for the TO datepicker change events. Syncs with FROM field
	 */
	onToChange: function (e) {
		$('#cw_maintenance_to').removeClass('invalid');
		var endDate = this.toField.value();
		var startDate;
		if (this.fromField.value()) {
			startDate = this.fromField.value();
		} else if (this.fromField.options.value === lang.NOW) {
			startDate = new Date();
		}
		let startDateInMsTzAdjusted = new Date(startDate.getTime()) - this.msSelectedTimeZoneDiff + this.msBrowserTimeZoneDiff;
		this.toField.min(new Date(startDateInMsTzAdjusted + this.diffTime));
		if (!endDate) {
			var endDateText = e.sender.element.val();
			var time = endDateText.split(' ').pop();
			var doublePointsLength = time.split(':').length - 1;
			if (doublePointsLength === 0) {
				endDateText += ':00:00';
				this.toField.value(endDateText);
			} else if (doublePointsLength === 1) {
				endDateText += ':00';
				this.toField.value(endDateText);
			} else {
				//if bad data put back cached value
				this.toField.value(this.cachedToValue);
			}
			if (this.toField.value() < startDate) {
				this.toField.value(this.cachedToValue);
			} else {
				this.cachedToValue = this.toField.value();
			}
			return;
		}
	},
	/**
	 * Handler function for open event for the FROM field
	 */
	onFromOpen: function () {
		var startDate;
		if (!this.fromField.value() && this.fromField.options.value === lang.NOW) {
			startDate = new Date();
			this.fromField.value(startDate);
			this.cachedFromValue = startDate;
		}

		/*var startDate = this.fromField.value();
		if (!startDate) {
			var from = kendo.date.today();
			this.fromField.value(from);
			this.cachedFromValue = this.fromField.value();
		}*/
	},
	/**
	 * Handler function for open event for the TO field
	 */
	onToOpen: function () {
		var startDate;
		if (this.fromField.value()) {
			startDate = this.fromField.value();
		} else if (this.fromField.options.value === lang.NOW) {
			startDate = new Date();
		}
		var	endDate = this.toField.value();
		var from, to;
		if (!endDate) {
			if (startDate) {
				this.toField.value(new Date(startDate.getTime));
				this.cachedToValue = this.toField.value();
			} else {
				from = kendo.date.today();
				to = new Date(from.getTime);
				this.fromField.value(from);
				this.cachedFromValue = startDate;
				this.toField.value(to);
				this.cachedToValue = this.toField.value();
			}
		}
	},
	/**
	 * Handler function for the click event on the Cancel/close button
	 */
	onClose: function () {
		if (this.layout === 'window') {
			var modalWindow = $(this.selector).data("kendoWindow");
			modalWindow.close();
		} else {
			$('.cw_maintenance').addClass('is_hidden');
			LocalEventsManager.trigger('cancelperiod');

			if (this.mode === 'update') {
				RemoteEventsManager.discard(this.id);
			}
		}
	},
	/**
	 * Handler function for the click event on the save button
	 */
	onSave: function () {
		var fromDate = this.fromField.value();
		var toDate = this.toField.value();
		//var now = this.fromInMs;
		var now = new Date();
		var isValid = true, saveUrl;
		//validate from field
		if (!fromDate) {
			if (this.fromField.options.value === lang.NOW) {
				let nowInMsTzAdjusted = new Date().getTime() - this.msSelectedTimeZoneDiff + this.msBrowserTimeZoneDiff;
				fromDate = new Date(nowInMsTzAdjusted);
			} else {
				isValid = false;
				$('#cw_maintenance_from').prev().addClass('invalid');
			}
		} else {
			$('#cw_maintenance_from').prev().removeClass('invalid');
		}
		// validate to field
		if (!toDate) {
			isValid = false;
			$('#cw_maintenance_to').prev().addClass('invalid');
		} else {
			$('#cw_maintenance_to').prev().removeClass('invalid');
		}

		if (!isValid) {
			this.modalNotification.setOptions({
				message: lang.messages.REQUIRED_FIELDS,
				status: 'error'
			}).show();
		} else {
			var fromTimeTimestamp = fromDate.getTime();
			var fromTimeTzAdjusted = fromTimeTimestamp + this.msSelectedTimeZoneDiff - this.msUserTimeZoneDiff;
			var fromTime = new Date(fromTimeTzAdjusted);
			var toTimeTimestamp = toDate.getTime();
			var toTimeTzAdjusted = toTimeTimestamp + this.msSelectedTimeZoneDiff - this.msUserTimeZoneDiff;

			var toTime = new Date(toTimeTzAdjusted);
			if (false && now > fromTime && !this.isHistoricPeriod) {
				this.modalNotification.setOptions({
					message: lang.service.messages.MAINTENANCE_PERIOD_IN_THE_PAST,
					status: 'error'
				}).show();
			} else {
				var data = {
					ownerIds: this.entities,
					fromTime: Utils.customDateToString(fromTime, 'YYYY-MM-DD HH:mm:ss'),
					toTime: Utils.customDateToString(toTime, 'YYYY-MM-DD HH:mm:ss'),
					reason: this.descriptionHandler.value().trim(),
					updateSlaExcludes: $('#cw_exclude_periods_checkbox').prop('checked')
				};
				if (this.entities) {
					saveUrl = Settings.serverPath + 'maintenance/' + this._multipleSavePaths[this._types.indexOf(this.type)];
					data.ownerIds = this.entities;
				} else {
					saveUrl = Settings.serverPath + 'accounts/' + this.accountId + '/' + this._paths[this._types.indexOf(this.type)] + '/' + this.entityId + '/maintenance';
					data.id = this.id ? this.id : null;
				}
				Utils.ajax(saveUrl, 'POST', JSON.stringify(data), $.proxy(function (result) {
					if (result.success) {
						LocalEventsManager.trigger('maintenance_period_saved', {data: data});
						LocalEventsManager.trigger('summaryMaintenancePeriods');
						this.onClose();
					} else {
						//Utils.showInfo(lang.ALERT, result.message, result.details);
						this.modalNotification.setOptions({
							message: result.message,
							status: 'error'
						}).show();
					}
				}, this));
			}
		}
	}
};
