import {UserSettings} from "tools";
import {addStateStorage} from "controls/react/kendoWrappers/window";

export async function getDesignerWindowState(designer, windowName) {
	//autolayout is enabled only for service designer for the 2.14 release
	const designerType = designer.getDesignerType();
	const autoLayout = designerType == 'designerUserSettings'
		? await UserSettings.get(designer.getDesignerType(), 'autoLayout', true)
		: false

	let state = await UserSettings.get(designer.getDesignerType(), windowName);

	if (autoLayout || !state) {
		const autoState = getAutoWindowPositions(windowName);

		autoState.visible = state?.visible ?? true;

		state = autoState;
	}

	if(designer.config.forceHideWindows){
		state.visible = false;
	}

	state.position.top = Math.max(0, state.position.top)
	state.position.left = Math.max(0, state.position.left)
	return state;
}

export async function setDesignerWindowState(designer, windowName, state) {
	await UserSettings.set(designer.getDesignerType(), { [windowName]: state });
}

export async function addDesignerWindowStateStorage(designer, windowName, config){

	// const onResize = async () => {
	// 	const position = await getDesignerWindowState('qualifierWindow');
	// 	this.window.setOptions(position);
	// }
	//
	// window.addEventListener('resize', onResize);


	config = await addStateStorage(config,
		() => getDesignerWindowState(designer, windowName),
		state => setDesignerWindowState(designer, windowName, state)
	);

	let windowRef = null;
	let autolayoutEnabledChanged = false;

	const originalOpen = config.open;
	config.open = async e => {
		originalOpen && await originalOpen(e);
		windowRef = e.sender;
		if(autolayoutEnabledChanged){
			reloadWindowPosition(designer, windowRef, windowName)
		}

		// const overlay = document.querySelector('.k-overlay');
		// if(overlay == null)
		// 	return;
		//
		// const overlayZIndex = parseInt(overlay.style.zIndex);
		// if(isNaN(overlayZIndex))
		// 	return;
		//
		// e.sender.wrapper[0].style.zIndex = overlayZIndex - 1;
	}

	designer.graph.addListener('autolayoutChanged',(graph, e) => {
		const {autolayoutConfig} = e.properties;
		if(autolayoutConfig.enabled){
			autolayoutEnabledChanged = true;
			reloadWindowPosition(designer, windowRef, windowName);
		}
	});

	return config;
}

async function reloadWindowPosition(designer, windowRef, windowName){
	if(!windowRef)
		return;

	const state = await getDesignerWindowState(designer, windowName);
	windowRef.setOptions(state);
}

function getAutoWindowPositions(panel) {
	const $container = $('.geDiagramContainer');
	const containerPos = $container.offset();
	const width = $container.outerWidth();
	const height = $container.outerHeight();
	const defaultSize = getDefaultSize(panel);

	switch(panel) {
		case 'qualifierWindow': {
			const properties = getAutoWindowPositions('properties');

			const top = containerPos.top;
			const left = properties.position.left - defaultSize.width - 20;

			return {
				...defaultSize,
				position: {
					top, left
				}
			}
		}
		case 'properties': {
			const top = containerPos.top;
			const left = containerPos.left + width - defaultSize.width - 40;

			return {
				...defaultSize,
				position: {
					top, left
				}
			}
		}

		case 'assets': {
			const propertiesDefaultSize = getDefaultSize("properties");
			const top = containerPos.top;
			const left = containerPos.left + width - defaultSize.width - 40 - propertiesDefaultSize.width - 5;

			return {
				...defaultSize,
				position: {
					top, left
				}
			}
		}

		case 'assetGroups': {
			const propertiesDefaultSize = getDefaultSize("properties");
			const assetsDefaultSize = getDefaultSize("assets");
			const top = containerPos.top;
			const left = containerPos.left + width - defaultSize.width - 40 - propertiesDefaultSize.width - 5 - assetsDefaultSize.width - 5;

			return {
				...defaultSize,
				position: {
					top, left
				}
			}
		}

		case 'shapesCollection': {
			const top = containerPos.top;
			const left = containerPos.left;

			return {
				...defaultSize,
				position: {
					top, left
				}
			}
		}
	}
}

function getDefaultSize(panel) {
	switch(panel) {
		case 'qualifierWindow':
			return {
				width: 300,
				height: 540
			}

		case 'assetGroups':
		case 'assets':
			return {
				width: 350,
				height: 540
			}

		case 'properties':
			return {
				width: 300,
				height: 540
			}

		case 'shapesCollection':
			return {
				width: 700,
				height: 400
			}
	}
}
