import Utils from 'tools/utils';
import Cookies from 'core/cookies';
import Widget from 'areas/service-boards/widget';
import Settings from 'settings';
import ChunkProgressBar from 'controls/chunkProgressBar';
import State from 'tools/state';
import RemoteEventsManager from 'core/remoteEventsManager';
import AccountsApi from 'areas/administration/accounts/api';

import {translator} from 'core';

const i = translator({
  "SLA » {0}": {
    "no": "SLA » {0}"
  }
});

export function StateWidget(config) {
	Widget.call(this, config);

	this.subscriptionId = null;
	this.requestPath = Settings.serverPath;
	if (this.sessionId) {
		this.requestPath = Settings.serverPath + 'sessions/' + this.sessionId + '/';
	}
	this.configuration.includeSubaccounts = this.configuration.includeSubaccounts || false;
	this.initComponent();
};

export {StateWidget as default};

jQuery.extend(StateWidget.prototype, Widget.prototype, {
	/**
	 * Main init function
	 */
	initComponent: function () {
		this.selector = $('#' + this.id);

		this.renderContent();
		this.removeListeners();
		this.attachListeners();
	},
	/**
	 * Attaches listeners
	 */
	attachListeners: function () {
		this.selector.on('drop', $.proxy(this.onDrop, this));
		if (!this.sessionId && State.currentApp?.dashboardDesigner?.props?.mode !== 'designer') {
			$('#' + this.id).on('click', '.cw_bar, .cw_progress_bar', $.proxy(this.onSlaIndicatorClick, this));
		}
	},
	/**
	 * Removes listeners
	 */
	removeListeners: function () {
		this.selector.off();
	},
	/**
	 * Renders widget content
	 */
	renderContent: function () {
		var html = '<div class="cw_summary_sla_chart cw_bars_chart">';
		html += '<div class="cw_bars cw_sla_bars">';
		html += '<div class="cw_bar is_critical no_bg"><span class="value">0</span><span class="legend">' + lang.BREACHED + '</span></div>';
		html += '<div class="cw_bar is_major no_bg"><span class="value">0</span><span class="legend">' + lang.WARNING + '</span></div>';
		html += '<div class="cw_bar is_ok no_bg"><span class="value">0</span><span class="legend">' + lang.COMPLIANT + '</span></div>';
		html += '</div>';
		html += '<div class="cw_progress"></div>';
		html += '</div>';
		this.selector.find('.cw_section_content').empty().append(html);
		if (this.isDashboard) {
			this.selector.find('.cw_progress').addClass('cw_dashboard_progress');
		}

		this.subscribe();
		this.renderHealthChart();
	},
	/**
	 * Populates the Service Health section
	 */
	renderHealthChart: function () {
		var url = this.requestPath + 'accounts/' + this.configuration.accountId + '/summary/slaAggregate?includeSubaccounts=' + this.configuration.includeSubaccounts.toString();
		Utils.ajax(url, 'POST', JSON.stringify({}), $.proxy(function (result) {
			var sla, items;
			if (result.success) {
				sla = result.data;
				this.accountName = result.data.accountName;
				this.updateTitle();
				var okPercentage, warningsPercentage, breachesPercentage;
				if (sla.total > 0) {
					okPercentage = sla.ok * 100 / sla.total;
					warningsPercentage = sla.warnings * 100 / sla.total;
					breachesPercentage = sla.breaches * 100 / sla.total;
					items = [{
						percentageWidth: breachesPercentage,
						cssClass: 'is_critical',
						title: lang.widget.messages.TOTAL_BREACHED_SLA + ' ' + sla.breaches
					}, {
						percentageWidth: warningsPercentage,
						cssClass: 'is_major',
						title: lang.widget.messages.TOTAL_WARNING_SLA + ' ' + sla.warnings
					}, {
						percentageWidth: okPercentage,
						cssClass: 'is_ok',
						title: lang.widget.messages.TOTAL_OK_SLA + ' ' + sla.ok
					}];
				} else {
					okPercentage = 0;
					warningsPercentage = 0;
					breachesPercentage = 0;
					items = [{
						percentageWidth: 100,
						cssClass: 'is_idle'
					}];
				}
				var slaChunkProgresBar = new ChunkProgressBar({
					items: items,
					renderTo: $('#' + this.id).find('.cw_progress')
				});
				var barBreached = $('#' + this.id).find('.is_critical'),
					barWarning = $('#' + this.id).find('.is_major'),
					barCompliant = $('#' + this.id).find('.is_ok');

				//counts
				barBreached.find('.value').text(sla.breaches);
				barBreached.removeClass('no_bg pointer').addClass(sla.breaches ? 'pointer' : 'no_bg');
				barWarning.find('.value').text(sla.warnings);
				barWarning.removeClass('no_bg pointer').addClass(sla.warnings ? '' : 'no_bg');
				barCompliant.find('.value').text(sla.ok);
				barCompliant.removeClass('no_bg pointer').addClass(sla.ok ? 'pointer' : 'no_bg');

				items.forEach(x => {
					$('#' + this.id).find('.' + x.cssClass).attr('title', x.title);
				});
			} else {
				this.showErrorMessage(result.message);
			}
		}, this));
	},
	/**
	 * Handler function for the click event on the sla indicator
	 * @param {Object} e The click event
	 */
	onSlaIndicatorClick: function (e) {
		if(!this.redirectConfig?.doDefaultRedirect)
			return;

		var target = $(e.currentTarget);
		var hasIndicator = false, hasSlas = true;
		var indicator;
		if (target.hasClass('cw_bar')) {
			hasIndicator = true;
			if (target.hasClass('is_critical')) {
				indicator = 'FAILED';
			}
			if (target.hasClass('is_major')) {
				indicator = 'WARNING';
			}
			if (target.hasClass('is_ok')) {
				indicator = 'OK';
			}
		} else if (target.hasClass('cw_progress_bar')) {
			hasIndicator = true;
			if (target.hasClass('is_critical')) {
				indicator = 'FAILED';
			}
			if (target.hasClass('is_major')) {
				indicator = 'WARNING';
			}
			if (target.hasClass('is_ok')) {
				indicator = 'OK';
			}
		}

		if (target.find('.value').text() === '0') {
			hasSlas = false;
		}
		if (hasSlas) {
			if (hasIndicator) {
				State.currentApp.tryExit('SLAs', '', {
					indicator: indicator,
					isView: true,
					accountId: this.configuration.accountId
				}, null, this.redirectConfig?.defaultRedirectInNewTab);
			} else {
				State.currentApp.tryExit('SLAs', '', {
					isView: true,
					accountId: this.configuration.accountId
				}, null, this.redirectConfig?.defaultRedirectInNewTab);
			}
		}
	},
	/**
	 * Called when an event is received from the server
	 * @param {Object} data The event data object
	 */
	onEvent: function () {
		this.renderHealthChart();
	},
	/**
	 * Triggered after widget resize
	 */
	onResize: function () {
	},
	/**
	 * handler function for the drop event
	 * @deprecated
	 */
	onDrop: function () {
		this.destroy();
		this.subscribe();
	},
	/**
	 * Refresh the widget
	 */
	refresh: function () {
		this.renderHealthChart();
	},
	/**
	 * Subscribes to server sla events
	 */
	subscribe: function () {
		//var url = this.requestPath + 'events/summary/sla?accountId=' + this.configuration.accountId + '&eventSourceId=' + ceeview.eventSource.eventSourceId + '&subscriberId=' + this.id + '&includeSubaccounts=' + this.configuration.includeSubaccounts.toString();
		//RemoteEventsManager.subscribe(subscriptionObj);
		this.subscriberId = this.id;
		var subscriptionObj = [{
			eventType: 'Sla',
			accountId: this.configuration.accountId,
			includeSubaccounts: State.includeSubaccounts
		}];
		RemoteEventsManager.subscribe(this.subscriberId, subscriptionObj);
	},

	updateTitle: async function (){
		this.setTitleIfEmpty(i('SLA » {0}', this.accountName))
	},

	destroy: function () {
		Widget.prototype.destroy.call(this);
	}
});
