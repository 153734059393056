import { Cookies } from "core/cookies";
import {makeAutoObservable} from "mobx";
import State from "tools/state";

class ApplicationStateInner {
	apiToken: string;
	accountId: string;
	accountName: string;

	timezone: string;

	dateFormat: string;
	timeFormat: string;

	locale: string;

	apiUrl: string = '/rest/';

	constructor() {
		makeAutoObservable(this);
	}

	get includeSubaccounts() {
		return State.includeSubaccounts
	}

	initFromCookies() {
		this.apiToken = Cookies.get('sessionId');
		this.accountId = Cookies.get('CeesoftCurrentAccountId');
		this.accountName = Cookies.get('CeesoftCurrentAccountName');
		this.dateFormat = Cookies.get('CeesoftUserDateFormat');
		this.timeFormat = Cookies.get('CeesoftUserTimeFormat');
		this.locale = Cookies.get('CeesoftUserLocale');
		this.timezone = Cookies.get('CeesoftTimezone')
	}
}

export const ApplicationState = new ApplicationStateInner();
