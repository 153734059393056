import Element from './element';
import AssetColumns from 'areas/assets/assetColumns';
import {AssetsApi} from 'api';
import Cookies from 'core/cookies';
import State from 'tools/state';
import ArrayHelpers from "tools/helpers/array";
import {translator} from "core/localization/localization";
import {AssetsRouter} from "areas/assets/bundleDescription";
import {indicatorToState} from "tools/states";
import {showReasonsGridWindow} from "areas/application/reasonsGridWindow";
import {newGuid} from "tools/guid";
import {ReasonsGridApi} from "areas/application/reasonsGridApi";
import {sharedDictionary} from './localization';

const i = translator({
	'Assets: {0}': {
		no: "Ressurser: {0}"
	},

	'Asset: {0}': {
		no: "Ressurs: {0}"
	},

	'Monitors: {0}': {
		no: "Monitorer: {0}"
	},
}, sharedDictionary);

export default class Asset extends Element {
	assetIds = [];
	monitorIds = [];

	monitors = [];

	systemTypeToImage = {
		'GENERIC': "assets-static/designer/custom-icons/asset-generic.svg",
		'WINDOWS': 'assets-static/designer/custom-icons/asset-windows.svg',
		'UNIX': 'assets-static/designer/custom-icons/asset-linux.svg'
	}

	constructor(designer, cell) {
		super(designer, cell)

		this.assetGroupEntry = this.readValue("assetGroupEntry", false);

		let assetIdValue = this.datasource.assets;
		let monitorIdValue = this.datasource.monitors;

		if(monitorIdValue?.length > 0) {
			this.monitorIds = monitorIdValue.map(x => ({
				monitorId: x.id,
				assetId: x.assetId,
				accountId: x.accountId
			}))
		}else {
			this.monitorIds = assetIdValue.map(x => ({
				assetId: x.id,
				accountId: x.accountId
			}));
		}

		if(this.assetGroupEntry){
			this.monitors.push(this.readValue("asset"));
		}
		this.graph.addListener('CELL_CLICKED', this.onGraphCellClicked);
	}

	getEntriesToLoad() {
		 if(this.assetGroupEntry)
		 	return [];

		return this.monitorIds.map( x => ({...x}));
	}

	onEntriesLoaded(entries) {
		this.monitors = entries;
	}

	getHealthIndex(){
		const minHI =  Math.min.apply(null, this.monitors?.map(x => x.monitorHI || x.healthIndex));
		if(minHI == null)
			return -1;

		return minHI;
	}

	getLabel(){
		if(this.datasource.showAsLabel && this.monitors?.length){
			let label = this.monitors[0].assetName +
				(!this.readValue("applicationMode", false) && this.monitors[0].monitorName ? ' / ' + this.monitors[0].monitorName : '');

			if(this.assetGroupEntry == 'true' && label.length > 14) {
				label = label.substr(0, 14) + '...';
			}
			return label;
		}

		return null;
	}

	updateState() {
		if(this.monitors.length < this.monitorIds.length)
			return;

		const showHI = this.datasource.showHI;

		if (this.readValue("applicationMode", false) == "true" && !showHI) {
			if(this.isDefaultIconSet(Sidebar.prototype.defaultAssetIcon)) {
				this.updateImage(this.systemTypeToImage[this.monitors[0].systemType]);
			}
		}

		const healthIndex = this.getHealthIndex();

		if (showHI) {
			this.removeIcon();

			this.addContentLabel(healthIndex === -1 ? '' : healthIndex, {
				noDataSource: true,
				entityType: 'asset',
				assetId: this.monitorIds[0].assetId,
			});
		}

		this.checkForWarnings(this.monitors);

		if(this.datasource.showReasons){
			this.showReasonsIcon = this.createStatusIcon({
				label: "",
				tooltip: i("Click to see Health reasons")
			}, 'article', {
				position: 'topLeft',
				backgroundColor: '#FFFFFF',
				borderColor: '#000000',
				iconColor: '#000000'
			});
		}

		var assetIndicator = AssetColumns.getIndicatorByIndex(this.isAnyAssetInMaintenance() ? -1 : healthIndex);
		this.setState(indicatorToState(assetIndicator));
	}

	isAnyAssetInMaintenance(){
		return this.monitors?.some(x => x.assetMaintenance);
	}

	getSubscriptions(subscriptions) {
		if( this.assetGroupEntry == 'true')
			return {};

		return {
			assets: this.monitors.map(x => x.assetId)
		};
	}

	consumeEvent(event) {
		if( this.assetGroupEntry == 'true')
			return false;

		if( event.eventType != 'AssetHealth')
			return false;

		let stateChanged = false;
		const assetHealth = event.assetHealth;

		const localMonitor = this.monitors.find(x => assetHealth.assetId == x.assetId && x.monitorId == null);
		if(localMonitor && localMonitor.healthIndex != assetHealth.assetHI) {
			localMonitor.healthIndex = assetHealth.assetHI;
			stateChanged = true;
		}

		if(localMonitor && localMonitor.assetMaintenance != assetHealth.assetMaintenance) {
			localMonitor.assetMaintenance = assetHealth.assetMaintenance;
			stateChanged = true;
		}

		for(const eventMonitor of assetHealth.monitors){
			const localMonitor = this.monitors.find( x => assetHealth.assetId == x.assetId && eventMonitor.id == x.monitorId);
			if(localMonitor && localMonitor.monitorHI != eventMonitor.healthIndex){
				localMonitor.monitorHI = eventMonitor.healthIndex;
				stateChanged = true;
			}
		}

		return stateChanged;
	}

	getTooltipInternal(accountName) {
		if (this.monitors?.length == 0)
			return null;

		if(this.monitors[0].monitorName){
			return i('Monitors: {0}', this.monitors.map(x => x.assetName + '\\' + x.monitorName).join(', '));
		}else {
			const string = this.monitors.length == 1 ? 'Asset: {0}' : 'Assets: {0}';

			return i(string, this.monitors.map(x => x.assetName).join(', '));
		}
	}

	onGraphCellClicked = (graph, e) => {
		if(e.properties.mouseEvent.evt.button != 0)
			return;

		if(this.cell == e.properties.cell
			&& e.properties.mouseEvent.labelClicked
			&& this.designer.config.chromeless)
		{
			this.redirect();
		}
	}

	getLoadReasonsPayload(){
		return {
			assetIds: this.monitorIds.map(x => x.assetId),
			monitorIds: this.monitorIds.filter(x => x.monitorId != null).map(x => x.monitorId)
		}
	}

	destroy() {
		super.destroy();

		this.graph.removeListener(this.onGraphCellClicked);
	}

	redirect() {
		State.mainApp.navigate(AssetsRouter.details(this.monitors[0].assetId) );
	}

	empty(){
		return this.monitorIds.length == 0;
	}
}
