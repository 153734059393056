import {translator} from "core/localization/localization";

const i = translator({
	'Metric': {
		no: 'Metrikk'
	},
	'User Input': {
		no: 'Tilpasset'
	}
});

export const TOTAL_METRIC_TYPE = {
	NONE: 'none',
	METRIC: 'metric',
	USER_INPUT: 'user_input'
}

export default function totalMetricDataSource() {
	return [
		{
			text: i('None'),
			value: TOTAL_METRIC_TYPE.NONE
		},
		{
			text: i('Metric'),
			value: TOTAL_METRIC_TYPE.METRIC
		},
		{
			text: i('User Input'),
			value: TOTAL_METRIC_TYPE.USER_INPUT
		}
	];
}
