import React from 'react';
import ReactDOM from "react-dom";
import {Utils} from "tools";
import {Settings} from "settings";
import {Cookies} from "core";
import {CustomNotification, ExpandableTextarea, Tags} from "controls";
import {AntSelect} from "controls/react/ant/antSelect";
import FormEntry from "controls/react/form/formEntry";
import {TagsSelect} from "controls/react/tagsSelect";
import ActionTriggerSwitcher from './users/actionTriggerSwitcher';

export let TeamProfileView = function (config) {
	Utils.apply(this, config);
	this._events = [];
	this.initComponent();
};

jQuery.extend(TeamProfileView.prototype, kendo.Observable.prototype, {
	/**
	 * This is main init function
	 */
	initComponent: function () {
		this.update = true;
		this.tagsDataSource = [];
		if (this.mode === 'view') {
			this.update = false;
		}
		this.usersLoaded = false;
		this.dataLoaded = false;
		this.descriptionHandler = new ExpandableTextarea({
			renderTo: $('.cw_team_summary .cw_description')
		});
		Utils.onLiveChange('#team_name', '#card_name', lang.account.TEAM_NAME);
		Utils.onLiveChange(this.descriptionHandler.textarea, '#card_description', lang.account.TEAM_DESCRIPTION);

		this.statusNotification = new CustomNotification({
			appendToElement: '.window_area',
			style: 'top:15px; right:15px; left:15px;',
			status: 'success',
			type: 'icon',
			hideOnClick: true
		});

		if (this.mode === 'update' || this.mode === 'view') {
			this.load();
		} else {
			var focus = function () {
				$('#team_name').focus();
			};
			setTimeout(focus, 1000);
			this.renderTags();
		}
		this.loadUsers();
		if (this.mode === 'view') {
			$('#team_name').addClass('is_readonly');
			$('#save_team').addClass('hide');
			$('.is_readonly').attr('disabled', true);
			this.descriptionHandler.readonly(true);
		}

		const actionTrigger = $('<div id="user_details__action_trigger" class="cw_field" />')
		$('.cw_team_summary .form .cw_user_panel').append(actionTrigger);

		ReactDOM.render(<ActionTriggerSwitcher type={"teams"} id={this.id}/>, actionTrigger[0]);

	},
	renderTags() {
		ReactDOM.render(<FormEntry label={lang.TAGS} vertical style={{maxWidth: '600px'}}>
			<TagsSelect
				mode={'tags'}
				disabled={this.mode === 'view'}
				accountId={Cookies.CeesoftCurrentAccountId}
				includeSubaccounts={false}
				onChange={value => this.onTagsChange(value)}
				defaultValue={this.tags}
				placeholder={lang.messages.TAG_CHOOSE} />
		</FormEntry>, $('.cw_team_tags').parent().get()[0]);
	},
	onTagsChange(value) {
		this.tags = value || [];
	},
	/**
	 * Loads users into dropdown multi select control
	 */
	loadUsers: function () {
		var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/users/lite';
		Utils.ajax(url, 'GET', {}, $.proxy(function (result) {
			var users = result;
			for (let user of users) {
				user.name = user.username;
			}
			ReactDOM.render(<FormEntry label={lang.SELECT_USERS} vertical style={{maxWidth: '100%'}}>
				<AntSelect
					dataList={users}
					mode={"multiple"}
					sortValues={true}
					onChange={value => this.onUsersChange(value)}
					defaultValue={this.usersList}
					disabled={this.mode === 'view'}
					placeholder={lang.SELECT} />
			</FormEntry>,  $('#users_multi_select').parent().get()[0]);
			this.usersLoaded = true;
		}, this));
	},
	onUsersChange: function(value) {
		this.usersList = value;
	},
	/**
	 * Removes the invalid CSS class for all controls
	 */
	clearInvalid: function () {
		$('#team_name').removeClass('invalid');
	},
	/**
	 * Loads the form data from the server and set the values into component fields
	 */
	load: function () {
		var loadUrl = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/teams/' + this.id + '/?update=' + this.update;
		Utils.ajax(loadUrl, 'GET', {}, $.proxy(function (result) {
			if (result.success) {
				this.descriptionHandler.value(result.data.description);
				$('#card_description').text(result.data.description);
				$('#card_name').text(result.data.name);
				$('#team_name').val(result.data.name).focus();
				$('#cw_cvid').val(result.data.id);
				this.dataLoaded = true;
				this.usersList = result.data.members;
				this.trigger('dataload', result.data);
				this.tags = result.data.tags;
				this.renderTags();
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},
	/*
	* Handler function for collecting the data for saving
	* */
	collectData: function () {
		var data = {
			name: $('#team_name').val().trim(),
			description: this.descriptionHandler.value().trim(),
			id: this.id ? this.id : null,
			members: this.usersList || [],
			tags: this.tags
		};
		return data;
	}
});


