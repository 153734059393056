import React, {useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import {Window} from 'controls/react/kendoWrappers';
import {Button, Switch} from 'controls/react/form';
import {Toolbar, ToolbarItemPosition} from 'controls/react/layout';
import {translator} from 'core';
import {getColumns} from '../genericMetricsWidgetConfiguration/dataSources/metricGridColumns';
import RTable from 'controls/react/rsuite/RTable';
import produce from 'immer';
import { Radio } from 'antd';

import './../genericMetricsWidgetConfiguration/widgetConfiguration.less'
import MetricDataSource from 'areas/service-boards/widgets/common/dataSources/metricDataSource';
import {BoxView} from "controls/react/layout/boxView";

const b = require('b_').with('metric-window');

const i = translator({
  "Metrics": {
    "no": "Metrikker"
  },
  "Show selected metrics only": {
    "no": "Vis valgte metrikker"
  },
  "Categorize by": {
    "no": "Kategoriser"
  },
  "Update": {
    "no": "Oppdater"
  },
  "This widget supports maximum 6 metrics": {
    "no": "Denne widgeten støtter maks 6 metrikker"
  }
});

const VARS = {
	DEFAULT_METRIC_IDS: 'defaultMetricIds',
	CURRENT_METRIC_IDS: 'currentMetricIds',
	SHOW_SELECTED_MODE: 'showSelectedMode'
};

const ACTIONS = {
	TOGGLE_SHOW_SELECTED_MODE: 'TOGGLE_SHOW_SELECTED_MODE',
	TOGGLE_METRIC: 'TOGGLE_METRIC',
	SET_SELECTION: 'SET_SELECTION'
};

export const MetricCategoryLayout = {
	Asset: 'asset',
	Service: 'service',
	Monitor: 'monitor'
}

function setSelection(draft, ids) {
	draft[VARS.CURRENT_METRIC_IDS] = ids;
}

export const reducer = produce((draft, action) => {
	switch (action.type) {
		case ACTIONS.TOGGLE_SHOW_SELECTED_MODE:
			draft[VARS.SHOW_SELECTED_MODE] = !draft[VARS.SHOW_SELECTED_MODE];
			break;
		case ACTIONS.SET_SELECTION:
			setSelection(draft, action.ids);
			break;
	}
});

export function MetricWindow(props) {
	const [gridHeight, setGridHeight] = React.useState(420);
	const [metricLayout, setMetricLayout] = React.useState(MetricCategoryLayout.Asset);

	const [state, dispatch] = React.useReducer(reducer, {
		[VARS.DEFAULT_METRIC_IDS]: props.defaultSelectedMetricIds || [],
		[VARS.CURRENT_METRIC_IDS]: props.defaultSelectedMetricIds || [],
		[VARS.SHOW_SELECTED_MODE]: false
	});

	const handleShowSelected = useCallback(() => dispatch({ type: ACTIONS.TOGGLE_SHOW_SELECTED_MODE }), []);

	const handleSelection = React.useCallback((ids) => {
		dispatch({ type: ACTIONS.SET_SELECTION, ids });
	}, []);

	const {
		[VARS.CURRENT_METRIC_IDS]: selectedIds,
		[VARS.SHOW_SELECTED_MODE]: showSelectedMode,
		[VARS.DEFAULT_METRIC_IDS]: defaultSelectedMetrics
	} = state;

	const selectedIdsRef = React.useRef();
	React.useEffect(() => {
		selectedIdsRef.current = selectedIds
		dataSource.selectedIds = selectedIds
	}, [selectedIds]);

	const dataSource = React.useMemo(() => {
		if (!props.accountId) {
			return;
		}

		return new MetricDataSource(props.accountId, showSelectedMode, selectedIdsRef.current);
	}, [showSelectedMode, props.accountId]);

	const columns = useMemo(() => getColumns(metricLayout), [metricLayout]);

	const onAdd = useCallback(() => {
		if(selectedIds.length > 6) {
			return;
		}
		props.onAdd(selectedIds);
	}, [props.onAdd, selectedIds]);

	const {open} = props;

	const timeoutRef = React.useRef();

	const handleResize = React.useCallback((e) => {
		if (timeoutRef.current) {
			window.clearTimeout(timeoutRef.current);
		}

		timeoutRef.current = setTimeout(() => {
			timeoutRef.current = null;
			setGridHeight(e.height - 50);
		}, 300);
	}, []);

	if (!open || !dataSource) {
		return null;
	}

	const changeLayout = useCallback((layout) => {
		setMetricLayout(layout);
	});

	const headerToolbox = useMemo(() => {
		return [
			{ label: i('Asset'), value: MetricCategoryLayout.Asset },
			{ label: i('Monitor'), value: MetricCategoryLayout.Monitor },
			{ label: i('Service'), value: MetricCategoryLayout.Service }
		];
	})

	return (
		<Window modal={true} width={1000} height={500} onClose={props.onClose}
				onResize={handleResize}
		        title={i('Metrics')}
		        zIndex={100000}>
			<div className={b()}>
				<Toolbar title={i('Categorize by')} containerClass={b('header-toolbar')}>
					<Radio.Group position={ToolbarItemPosition.AFTER_TITLE}
						options={headerToolbox}
						onChange={e => changeLayout(e.target.value)}
						value={metricLayout}
						optionType="button"
						buttonStyle="solid" />
				</Toolbar>
				<div className={b('grid-wrapper')}>
					{selectedIds.length > 6 && <BoxView type="error">{i('This widget supports maximum 6 metrics')}</BoxView>}
					<RTable dataSource={dataSource}
							columns={columns}
							height={gridHeight}
							indexKey={"metricId"}
							defaultSelection={defaultSelectedMetrics}
							onSelect={handleSelection}
							singleMetricSelection={props.singleMetricSelection}/>
				</div>
				<Toolbar containerClass={b('toolbar')}>
					<div position={ToolbarItemPosition.BEFORE_TITLE} className={b('switcher')}>
						<div>
							{i('Show selected metrics only')}
						</div>
						<Switch
							containerClass={b('switchContainer')}
							onChange={handleShowSelected}
							value={showSelectedMode} />
					</div>
					<Button title={i('Update')}
						primary={true}
						onClick={onAdd}
						position={ToolbarItemPosition.AT_THE_END} />
					<Button title={i('Cancel')}
						primary={false}
						onClick={props.onClose}
						position={ToolbarItemPosition.AT_THE_END} />
				</Toolbar>
			</div>
		</Window>
	);
}

MetricWindow.propTypes = {
	accountId: PropTypes.string.isRequired,
	defaultSelectedMetricIds: PropTypes.arrayOf(PropTypes.string),
	singleMetricSelection: PropTypes.bool,
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onAdd: PropTypes.func.isRequired,
};

MetricWindow.defaultProps = {
	singleMetricSelection: false
};
