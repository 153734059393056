import {Map} from 'immutable';
import {SET_CREATE_MODE, SET_DATA, SET_ERROR_MESSAGE} from '../actions/constants';
import {setData} from './setData';
import {setCreateMode} from './setCreateMode';
import {SET_FIELD_ENABLED} from '../actions/setFieldEnabledAction';
import {setFieldEnabled} from './setFieldEnabled';
import {SET_DATA_CALL_PARAMS} from '../actions/setDataCallParams';
import {setDataCallParams} from './setDataCallParams';
import {SET_FIELD_DATA} from '../actions/setFieldDataAction';
import {setFieldData} from './setFieldData';
import {SET_FORM_ENABLED} from '../actions/setFormEnabledAction';
import {setFormEnabled} from './setFormEnabled';
import {SET_CALLBACK} from "../actions/setCallbackAction";
import {setCallback} from "./setCallback";
import {SET_FIELDS_IN_ERROR} from "../actions/setFieldsInErrorAction";
import {setFieldsInError} from "./setFieldsInError";
import {SET_SHOW_VALIDATION} from "../actions/setShowValidation";
import {setShowValidation} from "./setShowValidation";
import {SET_FIELD_IN_ERROR} from "../actions/setFieldInErrorAction";
import {setFieldInError} from "./setFieldInError";
import { SET_WORK_IN_PROGRESS } from '../actions/setWorkInProgressAction';
import setWorkInProgress from './setWorkInProgress';
import setErrorMessage from './setErrorMessage';
import {SET_DISABLED} from 'controls/react/form/formBuilder/actions/setDisabledAction';
import setDisabled from './setDisabled'

export function rootReducer(state = Map(), action) {
    const {type} = action;

    const map = {
        [SET_DATA]: setData,
        [SET_CREATE_MODE]: setCreateMode,
        [SET_FIELD_ENABLED]: setFieldEnabled,
        [SET_FORM_ENABLED]: setFormEnabled,
        [SET_DATA_CALL_PARAMS]: setDataCallParams,
        [SET_CALLBACK]: setCallback,
        [SET_FIELD_DATA]: setFieldData,
        [SET_FIELDS_IN_ERROR]: setFieldsInError,
        [SET_FIELD_IN_ERROR]: setFieldInError,
        [SET_SHOW_VALIDATION]: setShowValidation,
        [SET_WORK_IN_PROGRESS]: setWorkInProgress,
		[SET_ERROR_MESSAGE]: setErrorMessage,
		[SET_DISABLED]: setDisabled
    };

    const fn = map[type];

    if (!fn) {
        return state;
    }

    try {
        return fn(state, action);
    } catch(e) {
        console.error(action.type, e);
        return state;
    }
}
