import {makeAutoObservable, reaction} from "mobx";
import moment, {Moment} from "moment";
import {ModelType} from "areas/cost/budget/budgetTypes";
import {createModelSchemaWrapper, withParent} from "../../../framework/serializr-integration";
import {custom, list, object, primitive} from "serializr";
import {CostBudgetItem} from "./costBudgetItem";
import {ModelValidator, ValidatableModel} from "../../../framework/mobx-integration";
import {CostModel} from "./costModel";

export class CostBudgetData {
	profileId: string;
	modelId: string;
	id: string; // it's modelId actually
	accountId: string;
	items: CostBudgetItem[];
	modelType: ModelType;

	constructor() {
		makeAutoObservable(this);
		reaction(() => this.id, (value) => this.modelId = value); // temp fix for modelId
	}

	addItem(item: CostBudgetItem) {
		item.parent = this;
		this.items.push(item);
	}

	removeItem(item: CostBudgetItem) {
		const index = this.items.findIndex(i => i.id === item.id);
		this.items.splice(index, 1);
	}

	get isBudgetData() {
		return true;
	}
}

createModelSchemaWrapper(CostBudgetData, {
	profileId: primitive(),
	accountId: primitive(),
	items: list(withParent(object(CostBudgetItem))),
	modelType: primitive()
})

export class CostBudget implements ValidatableModel<CostBudget> {
	budgetId: string;
	currency: string;
	startDate: Moment;
	costBudget?: CostBudgetData;
	parent: CostModel;

	validator = new ModelValidator(this);

	constructor() {
		makeAutoObservable(this);
	}

	get endDate() {
		return moment(this.startDate).add(1, 'year').add(-1, 'day');
	}

	get formattedStartDate() {
		return this.startDate.format('DD-MM-YYYY');
	}

	get formattedEndDate() {
		return this.endDate.format('DD-MM-YYYY');
	}

}

createModelSchemaWrapper(CostBudget, {
	budgetId: primitive(),
	currency: primitive(),
	startDate: custom((value: Moment) => {
		return value.format('YYYY-MM-DD');
	}, (jsonValue, context, oldValue) => {
		return moment(jsonValue);
	}),
	costBudget: object(CostBudgetData)
})
