
import {translator} from "core/localization";

let i = translator({
  "Create Service": {
    "no": "Opprett Tjeneste"
  },
  "Create SLA": {
    "no": "Lag SLA"
  },
  "Create Account": {
    "no": "Lag Konto"
  },
  "Create User": {
    "no": "Lag Bruker"
  },
  "Create Team": {
    "no": "Opprett Team"
  },
  "Create Asset": {
    "no": "Lag Ressurs"
  },
  "Create Assetgroup": {
    "no": "Lag Ressursgruppe"
  },
  "Create Authentication": {
    "no": "Lag Autentisering"
  },
  "Create Dashboard": {
    "no": "Opprett Dashboard"
  },
  "Create Report": {
    "no": "Lag Rapport"
  },
  "Create Presentation": {
    "no": "Opprett Presentasjon"
  },
  "Create Automation": {
    "no": "Opprett Automatisering"
  },
  "Create Filter": {
    "no": "Opprett Filter"
  },
  "Create Calendar": {
    "no": "Opprett Kalender"
  },
  "Create Role": {
    "no": "Opprett Rolle"
  },
  "Create Application": {
    "no": "Opprett Applikasjon"
  },
  "Create Connectivity": {
    "no": "Opprett Tilkobling"
  },
  "Create External": {
    "no": "Opprett Ekstern"
  },
  "Create Hyper-V": {
    "no": "Opprett Hyper-V"
  },
  "Create IBM SVC": {
    "no": "Opprett IBM SVC"
  },
  "Create Mail Gateway": {
    "no": "Opprett Epost mottak"
  },
  "Create MS Cluster": {
    "no": "Opprett MS Cluster"
  },
  "Create Nagios": {
    "no": "Opprett Nagios"
  },
  "Create Process": {
    "no": "Opprett Prosess"
  },
  "Create PRTG": {
    "no": "Opprett PRTG"
  },
  "Create SolarWinds": {
    "no": "Opprett SolarWinds"
  },
  "Create VMM": {
    "no": "VMM"
  },
  "Create VMware": {
    "no": "Opprett VMware"
  },
  "Create Eventlog": {
    "no": "Opprett Hendelselogg"
  },
  "Create Nimsoft": {
    "no": "Opprett Nimsoft"
  },
  "Create DbWatch": {
    "no": "Opprett DbWatch"
  },
  "Create SQL Response": {
    "no": "Opprett SQL Response"
  },
  "Create Eaton UPS": {
    "no": "Opprett Eaton UPS"
  },
  "Create File Search": {
    "no": "Opprett File Search"
  },
  "Create Log": {
    "no": "Opprett Logg"
  },
  "Create Metric": {
    "no": "Opprett Metric"
  },
  "Create Modbus": {
    "no": "Opprett Modbus"
  },
  "Create Remote IBM": {
    "no": "Opprett Remote IBM"
  },
  "Create Remote System": {
    "no": "Opprett Remote System"
  },
  "Create Script": {
    "no": "Opprett Script"
  },
  "Create SNMP": {
    "no": "Opprett SNMP"
  },
  "Create SNMP Trap": {
    "no": "Opprett SNMP Trap"
  },
  "Create System": {
    "no": "Opprett System"
  },
  "Create URL": {
    "no": "Opprett URL"
  },
  "Create XenServer": {
    "no": "Opprett XenServer"
  },
  'Create Container': {
    "no": 'Opprett Container'
  },
  'Create Cost Model': {
  	'no': 'Opprett Kostnad Modell'
  },
	"Create Incident": {
  	"no": "Lag sak"
	},
  "Create Cost Store": {
    "no": "Opprett Kostnad Base"
  },
	"Create Interface Traffic": {
		"no": "Opprett Interface Trafikk"
	}
});

export const ContextMenuConstants = {
	entities: ['SERVICE', 'SLA', 'ACCOUNT', 'USER', 'TEAM', 'ASSET', 'GROUP', 'AUTHENTICATION', 'SERVICEBOARD', 'REPORT', 'AUTOMATION', 'HEALTHFILTER', 'CALENDAR',
		'USER_CATEGORY', 'APPLICATION', 'COST_MODEL', 'COST_STORE', 'INCIDENT'],
	texts: [i('Create Service'), i('Create SLA'), i('Create Account'), i('Create User'), i('Create Team'), i('Create Asset'), i('Create Assetgroup'), i('Create Authentication'),
		i('Create Dashboard'), i('Create Report'), i('Create Automation'), i('Create Filter'), i('Create Calendar'), i('Create Role'), i('Create Application'),
		i('Create Cost Model'), i('Create Cost Store'), i('Create Incident')],
	functions: ['onNewService', 'onNewSla', 'onNewAccount', 'onNewUser', 'onNewTeam', 'onNewAsset', 'onNewAssetGroup', 'onNewAuthentication', 'onNewDashboard', 'onNewReport',
		'onNewAutomation', 'onNewFilter', 'onNewCalendar', 'onNewRole', 'onNewApplication', 'onNewCostModel', 'onNewCostStore', 'onNewIncident'],
	monitorEntities: ['CONNECTIVITY', 'EXTERNAL', 'HYPERV', 'IBMSVC', 'INTERFACETRAFFIC','MAILGTW', 'MSCLUSTER', 'NAGIOS', 'PROCESSES', 'PRTG', 'SOLARWINDS', 'VMM', 'VMWARE', 'WINEVENTS',
		'WINSERVICE', 'NIMSOFT', 'DBWATCH', 'DATABASE', 'EATONUPS', 'FILESEARCH', 'LOGMONITOR', 'METRICMONITOR', 'MODBUS', 'REMOTEISERIES', 'REMOTESYSTEM', 'SCRIPT', 'SNMP', 'SNMPTRAP',
		'SYSTEM', 'URL', 'XEN_SERVER', 'CONTAINER'],
	monitorTexts: [i('Create Connectivity'), i('Create External'), i('Create Hyper-V'), i('Create IBM SVC'), i('Create Interface Traffic'), i('Create Mail Gateway'), i('Create MS Cluster'), i('Create Nagios'),
		i('Create Process'), i('Create PRTG'), i('Create SolarWinds'), i('Create VMM'), i('Create VMware'), i('Create Eventlog'), i('Create Service'), i('Create Nimsoft'),
		i('Create DbWatch'), i('Create SQL Response'), i('Create Eaton UPS'), i('Create File Search'), i('Create Log'), i('Create Metric'), i('Create Modbus'),
		i('Create Remote IBM'), i('Create Remote System'), i('Create Script'), i('Create SNMP'), i('Create SNMP Trap'), i('Create System'), i('Create URL'), i('Create XenServer'),
		i('Create Container')
	]
};

export default ContextMenuConstants;
