import {put, select} from 'redux-saga/effects';
import {getItemValue} from '../selectors/getItemValue';
import {getReduxFormValue} from '../selectors/getReduxFormValue';
import {setFormEnabledAction} from '../actions/setFormEnabledAction';
import {getReduxFormValues} from '../selectors/getReduxFormValues';
import {performPreValidateAction} from '../actions/performPreValidateAction';
import {getCallback} from '../selectors/getCallback';

export function* checkFormState() {
    const forms = yield select(getItemValue, 'formToDependencyFields');
    const preValidate = yield select(getItemValue, ['original', 'configuration', 'hasPreValidate']);

    if (!forms) {
        return;
    }

    const size = forms.size;

    for (const [formIndex, fields] of forms.entries()) {
        const isLastForm = formIndex === size - 1;
        let enabled = true;

		const isCurrentFormEnabled = yield select(getItemValue, ['formEnabled', formIndex]);

		if (formIndex >= 1) {
            const isPreviousFormEnabled = yield select(getItemValue, ['formEnabled', formIndex - 1]);

            if (!isPreviousFormEnabled) {
                yield put(setFormEnabledAction(formIndex, false));
                continue;
            }
        }

        for (const field of fields) {
            const value = yield select(getReduxFormValue, field);

            if (value === undefined || value === null || value === "") {
                enabled = false;
                break;
            }
        }

        if (preValidate && enabled && isLastForm) {
            yield put(performPreValidateAction());

            const onPrevalidate = yield select(getCallback, 'onPrevalidate');
            const properties = yield select(getReduxFormValues);

            if (onPrevalidate) {
                enabled = yield onPrevalidate(properties);
            }
        }

        if (isCurrentFormEnabled !== enabled) {
			yield put(setFormEnabledAction(formIndex, enabled));
		}
    }
}
