import Utils from 'tools/utils';
import Settings from 'settings';
import CustomNotification from 'controls/customNotification';
import {AgentsRouter} from 'areas/management/agents/bundleDescription';

import State from 'tools/state';


export default function RepositoryWindow(config) {
	Utils.apply(this, config);
	this.initComponent();
};

RepositoryWindow.prototype = {
	/**
	 * @cfg {Array} agents Array with agents objects :  id and name
	 */
	/**
	 * @cfg {String} action 'install'/'uninstall'
	 */
	/**
	 * Main init function
	 */
	initComponent: function () {
		$('#cw_repository_action').text(this.action === 'install' ? lang.agents.INSTALL_BUNDLES : lang.agents.UNINSTALL_BUNDLES);
		if (!this.infoText) {
			this.infoText = (this.action === 'install' ? lang.agents.messages.INSTALL_BUNDLES_INFO : lang.agents.messages.UNINSTALL_BUNDLES_INFO);
		}
		//Removed by Bernt's request
		/*infoText += ': ' + this.agents.map(function (item) {
			return item.name;
		}).join(', ');*/
		$('.cw_repository_information').find('.cw_section_title').text(this.infoText);
		this.attachListeners();
		this.initKendoComponents();
	},
	/**
	 * Attaches listeners
	 */
	attachListeners: function () {
		$('#cw_repository_close').off().on('click', this.onClose);
		$('#cw_repository_action').off().on('click', $.proxy(this.onAction, this));
		$('#cw_repository_back').off().on('click', $.proxy(this.onBack, this));
		$('.cw_repository_search').off().on('keyup', $.proxy(this.onBundlesSearchKeyUp, this));
		$('.cw_errors_search').off().on('keyup', $.proxy(this.onErrorSearchKeyUp, this));
		$('.cw_deploy_errors_list').off().on('click', '.cw_grid_link', $.proxy(this.onAgentName, this));
		$('#modal').data('kendoWindow').bind('resize', this.adjustTableHeight);
		$('.cw_repository_list').off().on('click', '.cw_grid_check_all', $.proxy(this.onCheckAll, this));
	},
	/**
	 * Initializes kendo components
	 */
	initKendoComponents: function () {
		this.modalNotification = new CustomNotification({
			appendToElement: '#modal',
			status: 'success',
			type: 'icon',
			icon: 'glyphicons ok-circle',
			hideOnClick: true
		});

		var url = Settings.serverPath + 'agents/';
		var selectedAgent = this.agents[0];
		if (this.action === 'uninstall') {
			if (this.agents.length > 1) {
				url += 'bundles';
			} else {
				url += selectedAgent.id + '/installedBundles';
			}
		} else {
			url += 'bundles';
		}
		url += '';

		this.grid = $('.cw_repository_list').kendoCustomGrid({
			dataSource: new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: url,
						contentType: 'application/json; charset=utf-8',
						type: 'GET',
						dataType: 'json',
						cache: false
					}
				},
				sort: {field: 'presentationName', dir: 'asc'}
			}),
			resizable: true,
			columns: [{
				field: 'id',
				title: lang.SELECTOR,
				sortable: false,
				filterable: false,
				menu: false,
				template: '<input type="checkbox" class="cw_grid_check" />',
				headerTemplate: '<input type="checkbox" class="cw_grid_check_all" />',
				headerAttributes: {
					'class': 'text_center'
				},
				attributes: {
					'class': 'text_center'
				},
				width: 40
			}, {
				field: 'presentationName',
				title: lang.NAME,
				width: 240
			}, {
				field: 'version',
				template: function (model) {
					return model.agentVersion || model.version || '';
				},
				title: lang.VERSION
			}],
			dataBound: $.proxy(this.adjustTableHeight, this)
		}).data('kendoCustomGrid');
		// Add Kendo tooltip to the header of the columns
		Utils.gridColumnHeaderTooltip(this.grid);
		this.errorsGrid = $('.cw_deploy_errors_list').kendoCustomGrid({
			dataSource: new kendo.ceeview.DataSource({
				data: []
			}),
			resizable: true,
			columns: [{
				field: 'symbolicName',
				title: lang.NAME,
				width: 240
			}, {
				field: 'version',
				title: lang.VERSION,
				width: 140
			}, {
				field: 'agentName',
				title: lang.AGENT,
				template: '<a class="cw_grid_link" data-id="${agentId}">${agentName}</a>'
			}],
			dataBound: $.proxy(this.adjustTableHeight, this)
		}).data('kendoCustomGrid');
		// Add Kendo tooltip to the header of the columns
		Utils.gridColumnHeaderTooltip(this.errorsGrid);
	},
	onCheckAll: function (e) {
		var checkAll = $('.cw_grid_check_all').is(':checked');
		var checkboxes = $('.cw_repository_list').find('.cw_grid_check');
		var i, length = checkboxes.length;
		for (let i = 0; i < length; i++) {
			$(checkboxes[i]).prop('checked', checkAll);
		}
	},
	/**
	 * Handler function for the close event
	 * @param {Object} event The close event object
	 */
	onClose: function (event) {
		$(event.currentTarget).closest('.k-window-content').data('kendoWindow').close();
	},
	/**
	 * Handler function for the bundleas search key up event
	 * @param {Object} e the keyup event object
	 */
	onBundlesSearchKeyUp: function (e) {
		var value = $(e.currentTarget).val();
		this.grid.dataSource.filter([{
			logic: 'or',
			filters: [{
				field: 'presentationName',
				operator: 'contains',
				value: value
			}, {
				field: 'version',
				operator: 'contains',
				value: value
			}]

		}]);
	},
	/**
	 * Handler function for the bundleas search key up event
	 * @param {Object} e the keyup event object
	 */
	onErrorSearchKeyUp: function (e) {
		var value = $(e.currentTarget).val();
		this.errorsGrid.dataSource.filter([{
			logic: 'or',
			filters: [{
				field: 'symbolicName',
				operator: 'contains',
				value: value
			}, {
				field: 'version',
				operator: 'contains',
				value: value
			}, {
				field: 'agentName',
				operator: 'contains',
				value: value
			}]

		}]);
	},
	/**
	 * Handler function for the action button
	 */
	onAction: function () {
		var bundles = [];
		var checkboxes = $('.cw_repository_list').find('.cw_grid_check:checked');
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			var record = this.grid.dataSource.getByUid($(checkboxes[i]).closest('tr').data('uid'));
			bundles.push({
				symbolicName: record.symbolicName,
				version: record.agentVersion || record.version
			});
		}
		var data = {
			agentIds: this.agents.map(function (item) {
				return item.id;
			}),
			bundles: bundles
		};
		var count = bundles.length;
		var url = Settings.serverPath + 'agents/' + (this.action === 'install' ? 'installBundles' : 'uninstallBundles') + '';
		kendo.ui.progress($('.cw_repository_list'), true);
		Utils.ajax(url, 'POST', JSON.stringify(data), $.proxy(function (result) {
			kendo.ui.progress($('.cw_repository_list'), false);
			if (result.success) {
				var message;
				if (count === 1) {
					message = this.action === 'install' ? lang.agents.messages.BUNDLE_INSTALLED : lang.agents.messages.BUNDLE_UNINSTALLED;
				} else {
					message = this.action === 'install' ? lang.agents.messages.BUNDLES_INSTALLED : lang.agents.messages.BUNDLES_UNINSTALLED;
				}
				this.modalNotification.setOptions({
					message: message,
					status: 'success'
				}).show();

			} else {
				this.showErrorsPanel(result.data, result.message, result.details);
			}
		}, this), function (result, status) {
			kendo.ui.progress($('.cw_repository_list'), false);
			Utils.showInfo(lang.ALERT, lang.messages.SERVER_ERROR);
		});
	},
	/**
	 * Handler function for the click event on the agent name
	 * @param {Object} e The click event object
	 */
	onAgentName: function (e) {
		e.preventDefault();
		this.onClose(e);
		var agentId = $(e.currentTarget).attr('data-id');
		State.mainApp.navigate(AgentsRouter.details(agentId));
	},
	/**
	 * Handler function for the back button
	 */
	onBack: function () {
		$('.cw_deploy_errors_panel').addClass('hide');
		$('#cw_repository_back').addClass('hide');
		$('.cw_repository_panel').removeClass('hide');
		$('#cw_repository_close').removeClass('hide');
		$('#cw_repository_action').removeClass('hide');
		this.adjustTableHeight();
	},
	/**
	 * Displays and populates the error panel
	 * @param {Array} bundles Array with bundle objects
	 * @param {String} message the generic message error
	 */
	showErrorsPanel: function (bundles, message) {
		$('.cw_repository_panel').addClass('hide');
		$('#cw_repository_close').addClass('hide');
		$('#cw_repository_action').addClass('hide');
		$('.cw_deploy_errors_information').find('.cw_section_title').text(message);
		$('.cw_deploy_errors_panel').removeClass('hide');
		$('#cw_repository_back').removeClass('hide');
		for (var i = 0; i < bundles.length; i++) {
			var agentId = bundles[i].agentId;
			var agentName = '';
			for (var j = 0, length = this.agents.length; j < length; j++) {
				if (agentId === this.agents[j].id) {
					agentName = this.agents[j].name;
					break;
				}
			}
			bundles[i].agentName = agentName;
		}
		this.errorsGrid.dataSource.data(bundles);
	},
	adjustTableHeight: function () {
		var selector = '';
		if ($('.cw_repository_list').is(':visible')) {
			selector = '.cw_repository_list';
		} else {
			selector = '.cw_deploy_errors_list';
		}
		var table = $(selector);
		var modal = $('#modal').closest('.k-window');
		var tableOffset = table.offset();
		var modalOffset = modal.offset();
		var tableHeight = modal.height() - tableOffset.top + modalOffset.top - 57 + 15; // 15 - bottom padding 57 - footer height

		table.css('height', tableHeight);
		table.find('.k-grid-content').css('height', tableHeight - 29);
	}
};

