import {translator} from "core/localization/localization";

const i = translator();

export const SKIP_FIELDS = [
	'cw_search_box', 'cw_grid_check', 'cw_notes_grid_check',
	'cw_notes_grid_check_all', 'cw_grid_check_all', 'skip_dirty_check',
	'cw_filter_list', 'cw_incident_check', 'cw_monitor_grid_check',
	'cw_monitor_grid_check_all', 'cw_generate_report', 'cw_incident_check',
	'cw_incident_check_all'];
export const SKIP_IDS = ['cw_note_public', 'cw_assetdetails_aggregation_type', 'cw_assetdetails_type_selector', 'cw_assetdetails_entries_selector']
const SKIP_TYPES = ['radio']

export class GenericDirtyCheck {
	constructor() {
		this.changeHandler = (e) => {
			if (SKIP_IDS.includes(e.target.id)) {
				return;
			}

			if (SKIP_TYPES.includes(e.target.getAttribute('type'))) {
				return;
			}

			//inline editing of a grid probably hanled by grid itself. Added because of "notes' in Revisions
			const gridCell = e.target.closest('[role=gridcell]');
			if(gridCell && gridCell.getAttribute('data-role') == 'editable'){
				return;
			}

			const classes = Array.from(e.target.classList);

			for (const classItem of classes) {
				if (SKIP_FIELDS.includes(classItem)) {
					return;
				}
			}
			this.dirty = true;
		};

		$('#content_area').on('change', this.changeHandler);
		$('#content_area').on('dblclick', this.changeHandler);
		$('#content_area').on('click', (e) => {
			if (e.target.id && e.target.id.match(/cancel|save/)) {
				this.dirty = false;
			}

			for (const item of [i('Save'), i('Update'), i('Cancel'), i('Create')]) {
				if (e.target.innerText?.includes(item)) {
					this.dirty = false;
					break;
				}
			}
		});
	}

	async dirtyCheck() {
		return new Promise((resolve) => {
			setTimeout(() => { // this should be executed in the next tick
				resolve({
					isDirty: this.dirty,
					message: lang.messages.COMMON_DIRTY_CONFIRMATION
				})
			}, 0);
		})
	}

	reset(){
		this.dirty = false;
	}

	markDirty(dirty){
		this.dirty = dirty;
	}

	destroy() {
		$('#content_area').off('change', this.changeHandler);
	}
}
