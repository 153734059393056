import React from 'react';
import PropTypes from 'prop-types';
import DropDownList from "controls/react/kendoWrappers/dropDownList";
import TextBox from "controls/react/form/textBox";
import {i, b} from "./common";
import Lang from "core/localization/lang";
import { SET_FREQUENCY, SET_INTERVAL } from './reducer';
import RRule from 'rrule';

const REPEAT_INTERVAL = [
{
	text: Lang.rulegenerator.SECONDLY,
	value: '' + RRule.SECONDLY
},
{
	text: Lang.rulegenerator.MINUTELY,
	value: '' + RRule.MINUTELY
},
{
	text: Lang.rulegenerator.HOURLY,
	value: '' + RRule.HOURLY
}, {
	text: Lang.rulegenerator.DAILY,
	value: '' + RRule.DAILY
}, {
	text: Lang.rulegenerator.WEEKLY,
	value: '' + RRule.WEEKLY
}, {
	text: Lang.rulegenerator.MONTHLY,
	value: '' + RRule.MONTHLY
}, {
	text: Lang.rulegenerator.YEARLY,
	value: '' + RRule.YEARLY
}];

function RepeatPanel(props) {
	const handleFrequencyChange = React.useCallback((value) => {
		props.dispatch({type: SET_FREQUENCY, value: +value});
	}, [props.dispatch]);

	const handleIntervalChange = React.useCallback((value) => {
		props.dispatch({type: SET_INTERVAL, value: +value});
	}, [props.dispatch]);

	return <div className={b('row')}>
		<div className={b('label')}>
			{Lang.rulegenerator.REPEAT}
		</div>
		<DropDownList dataSource={REPEAT_INTERVAL} value={'' + props.frequency} onChange={handleFrequencyChange} />
		<div className={b('text')}>{Lang.rulegenerator.EVERY}</div>
		<TextBox value={props.internal} onChange={handleIntervalChange}/>
		<div className={b('text')}>
			{props.frequency === RRule.SECONDLY && Lang.rulegenerator.SECONDS}
			{props.frequency === RRule.MINUTELY && Lang.rulegenerator.MINUTE}
			{props.frequency === RRule.HOURLY && Lang.rulegenerator.HOUR}
			{props.frequency === RRule.DAILY && Lang.rulegenerator.DAY}
			{props.frequency === RRule.WEEKLY && Lang.rulegenerator.WEEK}
			{props.frequency === RRule.MONTHLY && Lang.rulegenerator.MONTH}
			{props.frequency === RRule.YEARLY && Lang.rulegenerator.YEAR}
		</div>
	</div>

}

RepeatPanel.propTypes = {
	frequency: PropTypes.string,
	internal: PropTypes.string,
	dispatch: PropTypes.func.isRequired
};

export default RepeatPanel;
