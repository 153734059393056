import React from "react";
import TextBox from "controls/react/form/textBox"

export default class CELabel extends React.PureComponent {
    render() {
        const {input: {onChange, value}, meta, data, ...others} = this.props;

        return <TextBox value={data && data.data} readOnly={true} />
    }
}
