import Utils from 'tools/utils';
import Cookies from 'core/cookies';
import Widget from 'areas/service-boards/widget';
import Settings from 'settings';
import RemoteEventsManager from 'core/remoteEventsManager';
import Renderer from 'tools/renderer';
import State from 'tools/state';
import ChunkProgressBar from 'controls/chunkProgressBar';
import AccountsApi from 'areas/administration/accounts/api';

import {translator} from 'core';

const i = translator({
  "Service state service » {0}": {
    "no": "Tjenestetilstand tjeneste » {0}"
  }
});

export function StateWidget(config) {
	Widget.call(this, config);

	this.subscriptionId = null;
	this.requestPath = Settings.serverPath;
	if (this.sessionId) {
		this.requestPath = Settings.serverPath + 'sessions/' + this.sessionId + '/';
	}
	this.configuration.includeSubaccounts = this.configuration.includeSubaccounts || false;
	this.initComponent();
};

export {StateWidget as default}

jQuery.extend(StateWidget.prototype, Widget.prototype, {
	initComponent: function () {
		this.renderContent();
		this.removeListeners();
		this.attachListeners();
	},

	attachListeners: function () {
		$('#' + this.id).on('drop', $.proxy(this.onDrop, this));
		if (!this.sessionId && State.currentApp?.dashboardDesigner?.props?.mode !== 'designer') {
			$('#' + this.id).on('click', '.cw_qualifiers_breaches', $.proxy(this.onServiceUpBreachesClick, this));
			$('#' + this.id).on('click', '.cw_agents_maintenance', $.proxy(this.onServiceUpMaintenanceClick, this));
			$('#' + this.id).on('click', '.cw_warning_breaches', $.proxy(this.onServiceWarningBreachesClick, this));
			$('#' + this.id).on('click', '.cw_warning_maintenance', $.proxy(this.onServiceWarningMaintenanceClick, this));
			$('#' + this.id).on('click', '.cw_bar, .cw_progress_bar', $.proxy(this.onServiceStateClick, this));
		}

	},

	removeListeners: function () {
		$('#' + this.id).off();
	},

	renderContent: function () {
		var html = '<div class="cw_service_state_chart cw_bars_chart">';
		html += '<div class="cw_bars cw_service_state_bars">';
		html += '<div class="cw_bar is_critical no_bg"><span class="value">0</span><span class="legend">' + lang.DOWN + '</span></div>';
		html += '<div class="cw_bar is_major no_bg"><span class="value">0</span><span class="legend">' + lang.WARNING + '</span></div>';
		html += '<div class="cw_bar is_ok no_bg"><span class="value">0</span><span class="legend">' + lang.UP + '</span></div>';
		html += '<div class="cw_bar is_idle inactive no_bg"><span class="value">0</span><span class="legend">' + lang.INACTIVE + '</span></div>';
		html += '</div>';
		html += '<div class="cw_progress">';
		html += '</div>';
		html += '</div>';
		$('#' + this.id).find('.cw_section_content').empty().append(html);
		if (this.isDashboard) {
			$('#' + this.id).find('.cw_progress').addClass('cw_dashboard_progress');
		}

		this.subscribe();
		this.renderHealthChart();
	},

	renderHealthChart: function () {
		var url = this.requestPath + 'accounts/' + this.configuration.accountId + '/summary/stateAggregate?includeSubaccounts=' + this.configuration.includeSubaccounts.toString();
		var stateAggregate;

		let tags = this.configuration.tags;
		let tagsArray = [];
		if (tags) {
			for (let i = 0; i < tags.length; i++) {
				if (tags[i].name) {
					tagsArray.push(tags[i].name);
				} else if (typeof tags[i] === 'string') {
					tagsArray.push(tags[i]);
				}
			}
		}
		this.configuration.tags = tagsArray;

		var data = {
			tags: this.configuration.tags,
			showUntagged: this.configuration.showUntagged,
			ignoreUserTags: this.configuration.ignoreUserTags
		};
		Utils.ajax(url, 'POST', JSON.stringify(data), $.proxy(function (result) {
			// this.hideErrorMessage();

			if (!result.success) {
				return this.showErrorMessage(lang.serviceBoard.messages.NO_DATA);
			}
			stateAggregate = result.data;
			this.accountName = result.data.accountName;
			var percentageUp, percentageDown, percentageWarning, percentageInactive;
			var up = stateAggregate.total - stateAggregate.down - stateAggregate.warning - stateAggregate.inactive;
			var items = [{percentageWidth: 100, cssClass: 'is_idle'}];
			this.updateTitle();
			if (stateAggregate.total === 0) {
				percentageUp = 100;
			} else {
				percentageUp = up * 100 / stateAggregate.total;
				percentageWarning = stateAggregate.warning * 100 / stateAggregate.total;
				percentageDown = stateAggregate.down * 100 / stateAggregate.total;
				percentageInactive = stateAggregate.inactive * 100 / stateAggregate.total;
				items = [{
					percentageWidth: percentageDown,
					cssClass: 'is_critical',
					title: stateAggregate.down + ' ' + (stateAggregate.down === 1 ? lang.messages.SERVICE_STATE_DOWN_TITLE : lang.messages.SERVICES_STATE_DOWN_TITLE)
				}, {
					percentageWidth: percentageWarning,
					cssClass: 'is_major',
					title: stateAggregate.warning + ' ' + (stateAggregate.warning === 1 ? lang.messages.SERVICE_STATE_WARNING_TITLE : lang.messages.SERVICES_STATE_WARNING_TITLE)
				}, {
					percentageWidth: percentageUp,
					cssClass: 'is_ok',
					title: up + ' ' + (up === 1 ? lang.messages.SERVICE_STATE_UP_TITLE : lang.messages.SERVICES_STATE_UP_TITLE)
				}, {
					percentageWidth: percentageInactive,
					cssClass: 'is_idle',
					title: stateAggregate.inactive + ' ' + (stateAggregate.inactive === 1 ? lang.messages.SERVICE_STATE_INACTIVE_TITLE : lang.messages.SERVICES_STATE_INACTIVE_TITLE)
				}];
			}
			var handler = $('#' + this.id);
			var stateChunkProgresBar = new ChunkProgressBar({
				items: items,
				renderTo: handler.find('.cw_progress')
			});
			var barUp = handler.find('.is_ok');
			var barDown = handler.find('.is_critical');
			var barWarning = handler.find('.is_major');
			var barInactive = handler.find('.is_idle');

			barUp.find('.value').text(up);
			if (up) {
				barUp.css('cursor', 'pointer');
			}
			barUp.removeClass('no_bg').addClass(up ? '' : 'no_bg');
			barDown.find('.value').text(stateAggregate.down);
			if (stateAggregate.down) {
				barDown.css('cursor', 'pointer');
			}
			barDown.removeClass('no_bg').addClass(stateAggregate.down ? '' : 'no_bg');
			barWarning.find('.value').text(stateAggregate.warning);
			if (stateAggregate.warning) {
				barWarning.css('cursor', 'pointer');
			}
			barWarning.removeClass('no_bg').addClass(stateAggregate.warning ? '' : 'no_bg');
			barInactive.find('.value').text(stateAggregate.inactive);
			if (stateAggregate.inactive) {
				barInactive.css('cursor', 'pointer');
			}
			barInactive.removeClass('no_bg').addClass(stateAggregate.inactive ? '' : 'no_bg');

			items.forEach(x => {
				handler.find('.' + x.cssClass).attr('title', x.title);
			});

			Renderer.serviceBarsIcons(stateAggregate, '#' + this.id + ' .cw_service_state_chart');
			if (State.currentApp?.dashboardDesigner?.props?.mode !== 'designer') {
				handler.find('.cw_agent_error').on('click', $.proxy(this.onServiceStateClick, this));
			}
		}, this), () => {
			// this.showErrorMessage(lang.serviceBoard.messages.NO_DATA);
		});
	},

	updateTitle: async function(){
		this.setTitleIfEmpty(i('Service state service » {0}', this.accountName))
	},

	onServiceStateClick: function (e) {
		e.preventDefault();
		e.stopPropagation();
		var target = $(e.currentTarget),
			hasServices = true,
			hasStatus = false,
			serviceState;
		if (target.hasClass('cw_bar')) {
			hasStatus = true;
			if (target.hasClass('is_critical')) {
				serviceState = 'DOWN';
			}
			if (target.hasClass('is_ok')) {
				serviceState = 'UP';
			}
			if (target.hasClass('is_major')) {
				serviceState = 'WARNING';
			}
			if (target.hasClass('is_idle')) {
				serviceState = 'INACTIVE';
			}
		} else if (target.hasClass('cw_progress_bar')) {
			hasStatus = true;
			if (target.hasClass('is_critical')) {
				serviceState = 'DOWN';
			} else if (target.hasClass('is_ok')) {
				serviceState = 'UP';
			} else if (target.hasClass('is_major')) {
				serviceState = 'WARNING';
			} else if (target.hasClass('is_idle')) {
				serviceState = 'INACTIVE';
			}
		} else if (target.hasClass('cw_agent_error')) {
			hasStatus = true;
			if (target.hasClass('cw_down_agent_error')) {
				serviceState = 'DOWN_AGENT_DOWN';
			} else if (target.hasClass('cw_up_agent_error')) {
				serviceState = 'UP_AGENT_DOWN';
			} else if (target.hasClass('cw_warning_agent_error')) {
				serviceState = 'WARNING_AGENT_DOWN';
			}
		}

		if (target.find('.value').text() === '0') {
			hasServices = false;
		}
		if (hasServices && this.redirectConfig?.doDefaultRedirect) {
			if (hasStatus) {
				State.currentApp.tryExit('Service', '', {
					serviceState: serviceState,
					tags: this.configuration.tags,
					isView: true,
					accountId: this.configuration.accountId
				}, undefined, this.redirectConfig?.defaultRedirectInNewTab);
			} else {
				State.currentApp.tryExit('Service', '', {
					isView: true,
					tags: this.configuration.tags,
					accountId: this.configuration.accountId
				}, undefined, this.redirectConfig?.defaultRedirectInNewTab);
			}
		}
	},
	/*
	 * Handler function for clicking on service up bucket breaches indicator
	 * @param {Object} e The click event object
	 * */
	onServiceUpBreachesClick: function (e) {
		e.stopPropagation();
		if(this.redirectConfig?.doDefaultRedirect)
			State.currentApp.tryExit('Service', '', {
				serviceState: 'UP_BREACHES',
				isView: true,
				accountId: this.configuration.accountId
			}, undefined, this.redirectConfig?.defaultRedirectInNewTab);
	},
	/*
	 * Handler function for clicking on service up bucket agent in maintenance indicator
	 * @param {Object} e The click event object
	 * */
	onServiceUpMaintenanceClick: function (e) {
		e.stopPropagation();
		if(this.redirectConfig?.doDefaultRedirect)
			State.currentApp.tryExit('Service', '', {
				serviceState: 'UP_AGENTS_MAINTENANCE',
				isView: true,
				accountId: this.configuration.accountId
			}, undefined, this.redirectConfig?.defaultRedirectInNewTab);
	},
	/*
	 * Handler function for clicking on service warning bucket breaches indicator
	 * @param {Object} e The click event object
	 * */
	onServiceWarningBreachesClick: function (e) {
		e.stopPropagation();
		if(this.redirectConfig?.doDefaultRedirect)
			State.currentApp.tryExit('Service', '', {
				serviceState: 'WARNING_BREACHES',
				isView: true,
				accountId: this.configuration.accountId
			}, undefined, this.redirectConfig?.defaultRedirectInNewTab);
	},
	/*
	 * Handler function for clicking on service warning bucket agent in maintenance indicator
	 * @param {Object} e The click event object
	 * */
	onServiceWarningMaintenanceClick: function (e) {
		e.stopPropagation();
		if(this.redirectConfig?.doDefaultRedirect)
			State.currentApp.tryExit('Service', '', {
				serviceState: 'WARNING_AGENTS_MAINTENANCE',
				isView: true,
				accountId: this.configuration.accountId
			}, undefined, this.redirectConfig?.defaultRedirectInNewTab);
	},
	/**
	 * Called when an event is received from the server
	 */
	onEvents: function (data) {
		this.renderHealthChart();
	},
	/**
	 * Triggered after widget resize
	 */
	onResize: function () {
	},
	/**
	 * handler function for the drop event
	 * @deprecated
	 */
	onDrop: function () {
		this.destroy();
		this.subscribe();
	},
	/**
	 * Refresh the widget
	 */
	refresh: function () {
		this.renderHealthChart();
	},
	/**
	 * Subscribes to server health events
	 */
	subscribe: function () {
		this.subscriberId = this.id;
		var subscriptionObj = [{
			eventType: 'ServiceStatus',
			accountId: this.configuration.accountId,
			includeSubaccounts: this.configuration.includeSubaccounts
		}, {
			eventType: 'ServiceAdmin',
			actionTypes: ['SERVICE_DELETE'],
			accountId: this.configuration.accountId,
			includeSubaccounts: this.configuration.includeSubaccounts
		}];
		subscriptionObj.push({
			eventType: 'ServiceSummary',
			accountId: Cookies.CeesoftCurrentAccountId,
			includeSubaccounts: State.includeSubaccounts
		});
		RemoteEventsManager.subscribe(this.subscriberId, subscriptionObj);
	},
	/**
	 * Destroy
	 */
	destroy: function () {
		Widget.prototype.destroy.call(this);
	}
});
