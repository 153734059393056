import State from 'tools/state';
import Cookies from 'core/cookies';
import Settings from "settings";
import Api from 'tools/api';
import lang from "core/localization/lang-en";
import {PresentationApi} from "areas/dashboards/api";

export function getDescriptions(){
	return [
		{
			jspUrl: 'include/gsboard/ServiceBoardView.jsp',
			bodyCssClass: 'cw_serviceboard_module',
			url: '/serviceboard/:id',
			getModuleImport: async (configuration) => {
				let id = configuration.id;
				const result = await PresentationApi.read(id);
				const dashboard = result.data;

				if (dashboard.layout === 'grid') {
					return import('./serviceBoardView');
				} else if (dashboard.layout === 'free') {
					return import('./absoluteServiceBoardView');
				}
			},
		},{
			url: '/dashboards',
			jspUrl: 'include/ServiceBoard.jsp',
			bodyCssClass: 'cw_serviceboard_module',
			getModuleImport: () => import('./serviceBoard')
		}
	];
}

export class ServiceBoardRouter {
	static details(serviceBoardId) {
		return`/serviceboard/${serviceBoardId}`;
	}

	static list(){
		return '/dashboards'
	}
}
