import { _getCurrentItemId } from "./getCurrentItemId";
import { getInternalState } from "./getInternalState";

export const _getItemValue = (state, property) => {
    const itemId = _getCurrentItemId(state);
    
    if (!Array.isArray(property)) {
        property = [property];
    }
    
    return state.getIn(['items', itemId, ...property]);
};

export function getItemValue(state, property) {
    return _getItemValue(getInternalState(state), property);
}