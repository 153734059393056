import React from 'react';

import classnames from 'classnames';

import {translator} from "core";
import Lang from "core/localization/lang";
import FormEntry from "controls/react/form/formEntry";
import DropDownList from "controls/react/kendoWrappers/dropDownList";
import NumericTextBox from "controls/react/form/numericTextBox";
import ScheduleWindow from "controls/react/form/formBuilder/subforms/fields/ceInterval/scheduleWindow";
import TextArea from "controls/react/form/textArea";
import {NumericTextBox} from 'controls/react/form';
import {Section} from "controls/react/layout";
import {sharedLocalization} from "./shared-components/sharedLocalization";
import IconButton from 'controls/react/form/iconButton';
import Cookies from 'core/cookies';
import TextBox from "controls/react/form/textBox"
import RRule from 'rrule';
import Utils from 'tools/utils';
import Form from "../../../controls/react/form/formBuilder/form";

const i = translator({
	'Time settings': {no: 'Tidsinnstillinger'},
	'Interval': {no: 'Intervall'},
	'Schedule': {no: 'Tidsplan'},
	'RRULE': {no: 'Gjentagelse'},
	'Interval mouseover': {
		'en': 'Minimum interval for this monitor is {0} {1}',
		'no': 'Minimum intervall for denne monitoren er {0} {1}'
	}
}, sharedLocalization);

const MODE = {
	INTERVAL: 'INTERVAL',
	SCHEDULE: 'SCHEDULE'
};

const MODE_LIST = [
	{text: i('Interval'), value: MODE.INTERVAL},
	{text: i('Schedule'), value: MODE.SCHEDULE}
];

const unitDataSource = [
	{text: i('Seconds'), value: 'SECONDS'},
	{text: i('Minutes'), value: 'MINUTES'},
	{text: i('Hours'), value: 'HOURS'}
];

const frequency = {
	'MINUTES': 'MINUTELY',
	'HOURS': 'HOURLY',
	'SECONDS': 'SECONDLY'
};

const BEGIN_VEVENT = "BEGIN:VEVENT";
const END_VEVENT = "END:VEVENT";


export const TimeSettings = props => {
	const {valueLink} = props;
	const scheduleLink = valueLink.get('calendarSchedule');
	const unitLink = valueLink.get('displayUnit');

	const [showWindow, setShowWindow] = React.useState();
	const [mode, setMode] = React.useState();

	React.useEffect(() => {
		setMode( scheduleLink.value == parseInt(scheduleLink.value)
			? MODE.INTERVAL
			: MODE.SCHEDULE)
	}, [scheduleLink.value])

	const handleSave = React.useCallback((value) => {
		setShowWindow(false);
		scheduleLink.update(value);
	}, []);

	const handleMode = React.useCallback((newMode) => {
		let newValue;

		if (newMode === MODE.INTERVAL) {
			let newRule = RRule.fromString(scheduleLink.value.replace(BEGIN_VEVENT, "").replace(END_VEVENT, ""));
			const unit = Utils.getKeyByValue(frequency, RRule.FREQUENCIES[newRule.options.freq]) || 'MINUTES';

			newValue = {
				displayUnit: unit,
				calendarSchedule: newRule.options.interval
			}
		} else {
			const rrule = new RRule({
				freq: RRule[frequency[unitLink.value]],
				interval: scheduleLink.value,
				dtstart: new Date()
			});
			newValue = {
				calendarSchedule: `${BEGIN_VEVENT}\r\n${rrule.toString()}\r\n${END_VEVENT}`,
				displayUnit: null
			}
		}

		valueLink.update(x => {
			Object.assign(x, newValue)
		});
	}, []);

	return <Section appearance={'frame'} title={i('Time settings')} childrenPadding={true}>
		<FormEntry label={i('Type')}>
			<DropDownList dataSource={MODE_LIST} value={mode} onChange={handleMode}/>
		</FormEntry>

		{mode == MODE.INTERVAL &&
			<Section appearance={'none'} layout={'equal'} direction={'row'}>
				<FormEntry label={i('Interval')}>
					<IconButton iconName={'question-sign'}
					            title={i('Interval mouseover', props.minInterval, props.minUnit?.toLowerCase())}
					            embedded/>
					<NumericTextBox {...scheduleLink.props}
					                spinners={false}
					                decimals={0}
					                invalid={props.invalid}/>
					<DropDownList dataSource={unitDataSource}
					              {...unitLink.props}/>
				</FormEntry>
			</Section>
		}

		{mode == MODE.SCHEDULE && <FormEntry label={i('RRULE')} vertical>
			<IconButton iconName={'pencil'}
			            onClick={() => setShowWindow(true)}
			            embedded/>
			<TextArea {...scheduleLink.props} />
		</FormEntry>}

		{showWindow && <ScheduleWindow rule={scheduleLink.value}
		                               onSave={handleSave}
		                               onCancel={() => setShowWindow(false)}
		                               timezone={Cookies.CeesoftTimezone}/>}
	</Section>
}

export function isTimesettingValid(config, minInterval, minUnit){

	if(config.calendarSchedule == null || config.calendarSchedule == ""){
		return false;
	}else if (parseInt(config.calendarSchedule) == config.calendarSchedule){
		return Utils.convertToMilliseconds(config.calendarSchedule, config.displayUnit)
			>= Utils.convertToMilliseconds(minInterval, minUnit);
	}

	return true;
}
