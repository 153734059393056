import React from 'react';

import classnames from 'classnames';

import Lang from "core/localization/lang";
import FormEntry from "controls/react/form/formEntry";
import DropDownList from "controls/react/kendoWrappers/dropDownList";
import CeeviewNumericField from "controls/react/form/ceeviewNumericField";
import TextBox from "controls/react/form/textBox";
import {b} from './common';
import ScheduleWindow from "./scheduleWindow";
import defaultSchedule from "./defaultSchedule";
import TextArea from "controls/react/form/textArea";

function CEInterval(props) {
	const {input: {onChange, value}, timezone, intervalCaption, scheduleCaption} = props;
	const [showWindow, setShowWindow] = React.useState();

	const MODE = {
		INTERVAL: 'INTERVAL',
		SCHEDULE: 'SCHEDULE'
	};

	const MODE_LIST = [
		{text: intervalCaption, value: MODE.INTERVAL},
		{text: scheduleCaption, value: MODE.SCHEDULE}
	];

	const dropDownData = React.useMemo(() => {
		const {interval} = props;

		if (interval) {
			return [MODE_LIST[0]]
		}
		else {
			return MODE_LIST;
		}
	}, []);

	const isInterval = value == parseInt(value);

	const handleSave = React.useCallback((value) => {
		setShowWindow(false);
		onChange(value);
	}, []);

	const handleIntervalChange = React.useCallback((value) => {
		onChange('' + value);
	}, []);

	const handleCancel = React.useCallback((value) => {
		setShowWindow(false);
	}, []);

	const handleOpen = React.useCallback(() => {
		setShowWindow(true);
	}, []);

	const handleMode = React.useCallback((value) => {
		if (value === MODE.INTERVAL) {
			onChange('30');
		}
		else {
			onChange(defaultSchedule(props.timezone))
		}
	}, []);

	return <>
		<FormEntry label={Lang.TYPE}>
			<DropDownList dataSource={dropDownData} value={isInterval ? MODE.INTERVAL: MODE.SCHEDULE} onChange={handleMode} />
		</FormEntry>
		{isInterval && <FormEntry label={Lang.designer.INTERVAL}>
			<CeeviewNumericField value={value} onChange={handleIntervalChange} />
		</FormEntry>}
		{!isInterval && <FormEntry
			containerClass={b('title')}
			label={<>
				<div className={'title'}>{Lang.rulegenerator.RRULE}</div>
				<div className={classnames("glyphicons pencil", b('pencil'))} onClick={handleOpen} />
				</>}
			vertical={true}>
			<TextArea value={value} onChange={onChange} title={value} />
		</FormEntry>}
		{showWindow && <ScheduleWindow rule={value} onSave={handleSave} onCancel={handleCancel} timezone={timezone}/>}
	</>

}

export default CEInterval;
