import React from 'react';
import {FormBuilder} from 'controls/react/form';
import addFormConfig from 'areas/assets/monitors/system/diskView/addFormConfig';
import {Api} from 'tools';
import './addForm.less';
import {Toolbar} from 'controls/react/layout/toolbar';
import {Button} from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import Lang from 'core/localization/lang';
import {Overlay} from 'controls/react/layout/overlay';

const b = require('b_').with('diskView__add_form');

export default function AddForm(props) {
	const [values, setValues] = React.useState();
	const [showSpinner, setShowSpinner] = React.useState(false);

	const compiledFormConfig = React.useMemo(() => {
		const config = cloneDeep(addFormConfig);

		config.configuration.values = {
			...props.values
		}

		return config;
	}, [props.values]);

	const handleData = React.useCallback(async (field, values) => {
		switch (field) {
			case 'authId': {
				const data = await Api.fetch(`${Api.accountRoot()}authentications/list?type=GENERIC&type=WMI`);
				return data.map((item) => ({
					text: item.name,
					value: item.id
				}))
			}
			default:
				return {
					data: null
				}
		}
	}, []);

	const handleSave = React.useCallback(() => {
		const out = {
			...values
		};

		delete out.authTypes;
		delete out.warning;

		props.onSave(out, props.uid);
	}, [values, props.uid]);

	const handleTest = React.useCallback(async () => {
		const out = {
			...values
		};

		delete out.authTypes;
		delete out.warning;

		setShowSpinner(true);
		await props.onTest(out);
		setShowSpinner(false);
	}, [values]);

	const validForm = React.useMemo(() => {
		const {name, server, authId, sharePath} = values || {};
		return name && server && authId && sharePath;
	}, [values]);

	return <div className={b()}>
		{showSpinner && <Overlay show={true}
		                         spinner={true}
		/>}
		<FormBuilder
			data={compiledFormConfig}
			createMode={true}
			onData={handleData}
			onChange={setValues}
		/>
		<div className={b('actions')}>
			<Button type={"primary"} onClick={handleTest} disabled={!validForm}>Test</Button>
			<div className={b('buffer')}></div>
			<Button type={"primary"} onClick={handleSave} disabled={!validForm}>{props.uid ? Lang.UPDATE: Lang.CREATE}</Button>
			<Button onClick={props.onClose}>Cancel</Button>
		</div>
	</div>
}
