export default function convertMetricsLabel (name) {
	var label;
	switch (name) {
		case 'DISKAVAILABLE':
			label = lang.monitor.system.DISK_AVAILABLE;
			break;
		case 'DISKLATENCY':
			label = lang.monitor.system.DISK_LATENCY;
			break;
		case 'DISKREADLATENCY':
			label = lang.monitor.system.DISK_READ_LATENCY;
			break;
		case 'DISKWRITELATENCY':
			label = lang.monitor.system.DISK_WRITE_LATENCY;
			break;
		case 'DISKQUEUELENGTH':
			label = lang.monitor.system.DISK_QUEUE_LENGTH;
			break;
		case 'DISKREADQUEUELENGTH':
			label = lang.monitor.system.DISK_READ_QUEUE_LENGTH;
			break;
		case 'DISKWRITEQUEUELENGTH':
			label = lang.monitor.system.DISK_WRITE_QUEUE_LENGTH;
			break;
		case 'DISKUSEDMB':
			label = lang.monitor.system.DISK_USAGE_MB;
			break;
		case 'DISKUSAGE':
			label = lang.monitor.system.DISK_USAGE_PERCENT;
			break;
		case 'DISKFREEMB':
			label = lang.monitor.system.DISK_FREE_MB;
			break;
		case 'DISKFREEPCT':
			label = lang.monitor.system.DISK_FREE_PERCENT;
			break;
		case 'DISKINODESFREE':
			label = lang.monitor.system.DISK_INODES_FREE;
			break;
		case 'DISKINODESUSED':
			label = lang.monitor.system.DISK_INODES_USED;
			break;
		case 'DISKINODESUSEDPCT':
			label = lang.monitor.system.DISK_INODES_USED_PERCENT;
			break;
		case 'DISKINODESFREEPCT':
			label = lang.monitor.system.DISK_INODES_FREE_PERCENT;
			break;
		case 'DISKSIZE':
			label = lang.monitor.system.DISK_SIZE;
			break;
	}
	return label;
}
