import React from 'react';
import PropTypes from 'prop-types';
import {useContextReducer} from 'core/react/reducerHelpers';
import {reducerWithLogger, reducer} from './reducer';
import ConfigurationFrame from '../common/CommonConfigurationFrame';
import DisplaySettingsFrame from './displaySettingsFrame'
import CustomMetricEditor from './customMetricEditor';
import MetricSelector from './metricSelector';
import Cookies from 'core/cookies';
import Context from './context';

import './customMetrics.less'
import {LEGEND_TYPE} from 'areas/service-boards/widgets/common/dataSources/selectorTypeDataSource';

const b = require('b_').with('custom-metric-widget-configuration');

const DEFAULT_FIELD_VALUES = {
	"configuration.period": "LASTDAY",
	"configuration.chartType": "",
	"type": "custom_metric",
	"configuration.labelTemplate": ['<Asset>', '<Name>', '<Type>', '<Category>', '<Instance>'],
	"configuration.legendType": LEGEND_TYPE.LEGEND,
	"configuration.aggregationType": "average",
	"configuration.timezone": Cookies.CeesoftTimezone
};

function cleanup(originalConfig) {
	const config = JSON.parse(JSON.stringify(originalConfig));
	delete config.serviceBoardData;
	return config;
}

function CustomMetricConfiguration(props) {
	const contextValue = useContextReducer(reducer, cleanup(props.config.config || props.config || {}), DEFAULT_FIELD_VALUES);

	React.useEffect(() => {
		props.onChange(contextValue.state);
	}, [contextValue.state, props.onChange]);

	return <Context.Provider value={contextValue}>
		<div className={b()}>
			<ConfigurationFrame context={Context} expanded={!!contextValue.state.isNew} />
			<DisplaySettingsFrame context={Context} expanded={!!contextValue.state.isNew} hideTimeSelector={true}/>
			<CustomMetricEditor context={Context} expanded={true} />
			<MetricSelector context={Context}
							showAdvanced={props.showAdvanced}
			/>
		</div>
	</Context.Provider>
}

CustomMetricConfiguration.propTypes = {
	config: PropTypes.shape({}).isRequired,
	onChange: PropTypes.func.isRequired
}

export default CustomMetricConfiguration;
