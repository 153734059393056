import Lang from 'core/localization/lang'

export default function getDefaultConfig() {
	return {
		chart: {},
		title: {
			text: ' '
		},
		legend: {
			enabled: true,
			title: {
				align: 'center'
			},
			x: 2,
			y: 20,
			floating: false,
			useHTML: true,
			borderWidth: 0,
			layout: 'horizontal',
			align: 'center',
			verticalAlign: 'bottom',
			itemStyle: {
				fontSize: "10px"
			},
			style: {
				fontSize: "10px"
			},
			itemMarginTop: -10
		},
		credits: {
			enabled: false
		},
		exporting: {
			buttons: {
				contextButton: {
					enabled: false
				},
				printButton: {
					enabled: false
				},
				exportButton: {
					enabled: false
				}
			},
			enabled: true
		},
		xAxis: {
			type: 'datetime',
			labels: {
				staggerLines: 1
			},
			dateTimeLabelFormats: {
				millisecond: '%H:%M:%S',
				second: '%H:%M:%S',
				minute: '%H:%M',
				hour: '%H:%M',
				day: '%e. %b',
				week: '%e. %b',
				month: '%b \'%y',
				year: '%Y'
			}
		},
		yAxis: {
			softMin: 0,
			minPadding: 0,
			title: {
				text: null
			}
		},
		plotOptions: {
			series: {
				marker: {
					enabled: false
				}
			},
			line: {
				events: {}
			}
		},
		series: [{}]
	}
};
