import {Api} from "tools";

export class ReasonsGridApi {
	static delete(reasonsIds) {
		return Api.fetchPost(Api.accountRoot() + 'health/assetReasons/delete', reasonsIds);
	}

	static acknowledge(data) {
		return Api.fetchPost(Api.accountRoot() + 'health/acknowledgeAssetHealthEvents', data);
	}

	static unacknowledge(data) {
		return Api.fetchPost(Api.accountRoot() + 'health/unacknowledgeAssetHealthEvents', data);
	}

	static unassign(data) {
		return Api.fetchPost(Api.accountRoot() + '/summary/eventSummary/unassign', data);
	}

	static getReasonsGeneric({assetIds, assetGroupIds, monitorIds}, filter, accountId) {
		return Api.fetchPost(Api.accountRoot(accountId) + 'health/assetReasons', {assetIds, assetGroupIds, monitorIds, filter});
	}

	static getAssetGroupsReasons(assetGroupIds = [], filter, accountId) {
		return ReasonsGridApi.getReasonsGeneric({assetGroupIds}, filter, accountId);
	}
}
