import Utils from 'tools/utils';
import ErrorHandler from 'core/errorHandler';
import Cookies from 'core/cookies';
import Settings from 'settings';
import State from 'tools/state';
import GridMenu from 'controls/gridMenu';
import {MetricWindow} from 'areas/service-boards/widgets/common/metricWindow';
import React from 'react';
import ReactDOM from "react-dom";

export default function ConfigurationBase(config) {
	Utils.apply(this, config);
	this.initComponent();
};

ConfigurationBase.prototype = {
	/*
	 * Handler function which adds the account component for a widget
	 * @values {Object} config Object with configuration
	 * @property element {Object} Element used to render component
	 * @property container {Object} Element container where the component is placed
	 * @property nextElement {Object} Next dropdown element
	 */
	createAccountComponent: function (config) {
		var accountCombo = config.element.kendoComboBox({
			dataSource: new kendo.ceeview.DataSource({
				change: function (e) {
					var length = e.items.length;
					if (config.events && typeof config.events.dataSourceChange === 'function') {
						config.events.dataSourceChange.call(this, e, length);
					}
				},
				transport: {
					read: {
						url: Settings.serverPath + 'accounts/' + (config.serviceBoardAccountId || this.serviceBoardAccountId) + '/subaccounts/allLevels/lite',
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				error: ErrorHandler.kendoServerError,
				sort: {field: 'name', dir: 'asc'}
			}),
			dataTextField: 'name',
			dataValueField: 'id',
			template: '<span id="${ data.id }">${ data.name }</span>',
			placeholder: lang.SELECT,
			enable: true,
			autoBind: (this.mode === 'update' || this.wasUpdate) ? true : false,
			select: $.proxy(function (e) {
				//For comboboxes we need to check if e.item is valid
				//if we write/search invalid accounts e.item is null
				if (e.item && e.item.hasClass('k-state-hover')) {
					var id = e.item.find('span').attr('id'), nextCombo;
					if (id) {
						this.isAccountChanged = true;
						this.widgetAccountId = id;
						this.widgetAccountName = e.item.find('span').text();
						if (config.nextElement && config.nextElement.length) {
							var readDataSource = true;
							this.enableNextCombo(config.nextElement, readDataSource);
						}
						if (config.otherElements && config.otherElements.length) {
							for (var i = 0; i < config.otherElements.length; i++) {
								var nextElement = $(config.otherElements[i]);
								if (nextElement.attr('data-role') === 'dropdownlist') {
									nextCombo = $(config.otherElements[i]).data('kendoDropDownList');
								}
								if (nextElement.attr('data-role') === 'combobox') {
									nextCombo = $(config.otherElements[i]).data('kendoComboBox');
								}
								if (nextCombo) {
									nextCombo.text('');
									nextCombo.enable(false);
								}
							}
						}
						if (config.events && config.events.select && typeof config.events.select === 'function') {
							config.events.select.call(this, e, id);
						}
					}
				}
			}, this),
			change: $.proxy(function (e) {
				var eraseError = true;
				this.errorFields = Utils.comboBoxValueCheck(e, eraseError);
				if (config.events && config.events.change && typeof config.events.change === 'function') {
					config.events.change.call(this, e);
				}
			}, this),
			dataBound: $.proxy(function (e) {
				var items = e.sender.dataItems(), length = items.length;
				if (this.mode !== 'update' && !this.wasUpdate) {
					if (e.sender.value() && config.nextElement && config.nextElement.length) {
						this.enableNextCombo(config.nextElement);
					}
					this.widgetAccountId = Cookies.CeesoftCurrentAccountId;
					this.widgetAccountName = Cookies.CeesoftCurrentAccountName;

					if (!length) {
						if (config.events && config.events.dataSourceChange && typeof config.events.dataSourceChange === 'function') {
							config.events.dataSourceChange.call(this, e, 0);
						}
					}
				} else {
					if (!config.isMultigraph) {
						this.errorFields = Utils.comboBoxValueCheck(e);
					}
				}
				if (config.events && config.events.dataBound && typeof config.events.dataBound === 'function') {
					config.events.dataBound.call(this, e);
				}
				var currentDataSource = e.sender.dataSource.data();
				var dataSourceIds = [];
				for (var i = 0; i < currentDataSource.length; i++) {
					dataSourceIds.push(currentDataSource[i].id);
				}
			}, this)
		}).data('kendoComboBox');

		if (this.mode === 'create') {
			accountCombo.value(config.serviceBoardAccountId || this.serviceBoardAccountId);
		} else {
			accountCombo.value(this.widgetObj.configuration.accountId);
		}
		return accountCombo;
	},
	/*
	 * Handler function which sets an empty component when datasource is empty
	 * @param {Array} elements Array of jQuery elements
	 * @param {Boolean} readDataSource Flag to show if the datasource should be read
	 */
	enableNextCombo: function (elements, readDataSource) {
		var nextCombo;
		for (var i = 0; i < elements.length; i++) {
			var nextElement = $(elements[i]);
			if (nextElement.attr('data-role') === 'dropdownlist') {
				nextCombo = $(elements[i]).data('kendoDropDownList');
			}
			if (nextElement.attr('data-role') === 'combobox') {
				nextCombo = $(elements[i]).data('kendoComboBox');
			}
			if (nextCombo) {
				nextCombo.text('');

				if(!nextCombo.input.attr("placeholder")){
					nextCombo.input.attr('placeholder', lang.SELECT);
				}

				nextCombo.enable(true);

				if (readDataSource) {
					nextCombo.dataSource.read();
					nextCombo.value('');
				}
			}
		}
	},
	/*
	 * Handler function which sets an empty component when datasource is empty
	 * @values {Object} config Object with configuration
	 * @property containers {Array} Array of jQuery elements
	 */
	emptyComponent: function (config) {
		var containers = config.containers, length = containers.length;
		if (!config.length) {
			for (var i = 0; i < length; i++) {
				if (!containers[i].container.hasClass('hide')) {
					containers[i].container.addClass('hide');
					containers[i].container.after('<div class="cw_field cw_dropdown_placeholder"><label class="cw_inline">' + containers[i].label + ': </label><div class="cw_no_items" style="padding-left:130px;">' + lang.serviceBoard.messages.NO_ITEMS_AVAILABLE + '</div></div>');
				}
			}
		} else {
			for (var i = 0; i < length; i++) {
				containers[i].container.removeClass('hide');
				if (this.isInputChanged) {
					var element = containers[i].container.find('input')[0];
					$(element).text('');
				}
				containers[i].container.next('.cw_dropdown_placeholder').remove();
			}
		}
	},

	getWindowPosition: function () {
		let boundingRect;
		if ($('.ant-modal').length) {
			boundingRect = $('.ant-modal')[0].getBoundingClientRect();
		} else {
			$('.k-window-content').each(function () {
				let window = $(this).data('kendoWindow');
				if (window.options.modal) {
					boundingRect = $(this).parent('.k-window')[0].getBoundingClientRect();
				}
			})
		}

		if (boundingRect == null) {
			boundingRect = {
				height: 800,
				left: 200,
				top: 100
			}
		}
		return boundingRect;
	},
	/*
	 * Renders the available metrics window
	 * */
	renderAvailableMetricsWindow: function (singleMetricSelection) {
		this.singleMetricSelection = singleMetricSelection;
		if ($('#cw_available_metrics_window').length === 0) {
			$('body').append($('<div id="cw_available_metrics_window"></div>'));
		}

		const node = $('#cw_available_metrics_window').get(0);

		const onClose = () => {
			ReactDOM.unmountComponentAtNode(node);
		}

		const accountId = this.widgetAccountId || this.widgetObj.configuration.accountId;

		const onAdd = async (ids) => {
			if (ids.length || !this.singleMetricSelection) {
				ReactDOM.unmountComponentAtNode(node);

				const url = `${Settings.serverPath}accounts/${accountId}/metrics/registeredMetrics/search`;

				const options = {
					method: 'POST',
					body: JSON.stringify({ skip: 0, take: 1000, filter: {
						"logic": "and",
						"filters": [{
							"logic": "or",
							"filters": ids.map((id) => ({
								"operator": "eq",
								"value": id,
								"field": "metricId"
							}))
						}]
					}}),
					headers: {
						"Content-Type": "application/json; charset=utf-8",
						"Auth-Token": Cookies.sessionId
					},
					throwOnError: false,
				};
				const response = await fetch(url, options);
				const result = await response.json();
				this.cachedSelected = result.items;
				this.onAvailableMetricsAdd();
			} else {
				ReactDOM.unmountComponentAtNode(node);
				this.cachedSelected = [];
				this.onAvailableMetricsAdd();
			}
		}

		const plainSelectedIds = Object.keys(this.selectedMetrics);

		return ReactDOM.render(<MetricWindow
			open={true}
			onClose={onClose}
			onAdd={onAdd}
			accountId={accountId}
			singleMetricSelection={singleMetricSelection === true}
			defaultSelectedMetricIds={plainSelectedIds} />, node);
	},
	checkCachedSelected: function () {
		for (let i = 0; i < this.cachedSelected.length; i++) {
			let metricId = this.cachedSelected[i].metricId;
			$('[data-metricid=' + metricId + ']').prop('checked', true);
		}
		if (this.cachedSelected.length === this.availableMetricsGrid.dataSource.total()) {
			$('.cw_grid_check_all').prop('checked', true);
		} else {
			$('.cw_grid_check_all').prop('checked', false);
		}
	},
	/**
	 * Handler function for the selection of one service log grid row
	 */
	onRowExpand: function (e) {
		var selectedRow = this.availableMetricsGrid.select();
		var myRow = selectedRow[0];
		var messageEl = $(myRow).find('.to_expand');
		if ($(messageEl).hasClass('cw_message_expanded')) {
			$(messageEl).removeClass('cw_message_expanded').addClass('ellipsis');
		} else {
			$('#cw_available_metrics').find('.k-grid-content').find('td.cw_message_expanded').removeClass('cw_message_expanded').addClass('ellipsis');
			$(messageEl).addClass('cw_message_expanded').removeClass('ellipsis');
		}
	},
	/*
	 * Shows available metrics window if it has been closed
	 * */
	onShowAvailableMetricsWindow: function () {
		if ($('#cw_asset').data('kendoComboBox').selectedIndex > -1) {
			this.renderAvailableMetricsWindow();
		} else {
			$('#cw_asset').data('kendoComboBox').wrapper.addClass('invalid');
		}
	},
	/*
	 * Searches the available metrics grid
	 * @param {Object} e The key up event object
	 * */
	onAvailableMetricsSearch: function (e) {
		var value = $(e.currentTarget).val();
		var dataSource = $('#cw_available_metrics').data('kendoCustomGrid').dataSource;
		if (value) {
			dataSource.filter({
				filters: [{
					field: 'categoryNode',
					operator: 'contains',
					value: value
				}, {
					field: 'identifier',
					operator: 'contains',
					value: value
				}, {
					field: 'instanceName',
					operator: 'contains',
					value: value
				}],
				logic: 'or'
			});
		} else {
			dataSource.filter({});
		}
	},
	onCheck: function (e) {
		var target = $(e.currentTarget);
		var id = target.parent().parent().data('uid');
		if (target.is(':checked')) {
			$('.k-grid-content, .k-grid-content-locked').find("[data-uid='" + id + "']").removeClass('k-alt').addClass("k-state-selected");
		} else {
			$('.k-grid-content, .k-grid-content-locked').find("[data-uid='" + id + "']").removeClass("k-state-selected");
		}
	},
	/*
	 * Adds the available metric to selected metric grid
	 * */
	onAvailableMetricsAdd: function () {
		let grid = $('#cw_selected_metrics').data('kendoCustomGrid')
		if (this.singleMetricSelection) {
			let dataSource = new kendo.data.DataSource({
				data: [],
				schema: this.gridSchema
			});
			grid.setDataSource(dataSource);
			this.selectedMetrics = {};
		}
		let selectedDataSource = grid.dataSource;
		for (let i = 0; i < this.cachedSelected.length; i++) {
			let item = this.cachedSelected[i];
			item.customUnit = '';
			item.conversion = '';
			selectedDataSource.add(item);
			this.selectedMetrics[item.metricId] = item;
		}
		/*if (selectedDataSource.data().length > 1 && this.hideMultigraphItems) {
			this.hideMultigraphItems();
		}*/
	},
	onAvailableMetricsCancel: function () {
		this.availableMetricsWindow.close();
	},

	onGridCheck: function (e) {
		let target = $(e.target);
		let availableDataSource = $('#cw_available_metrics').data('kendoCustomGrid').dataSource;
		var checkboxId = target.attr('data-id');
		var checkboxMetricId = target.attr('data-metricid');
		var item = availableDataSource.get(checkboxId);
		var idFound = false;
		var index;
		for (let j = 0; j < this.cachedSelected.length; j++) {
			if (checkboxMetricId === this.cachedSelected[j].metricId) {
				idFound = true;
				index = j;
			}
		}
		if (target.is(':checked')) {
			if (!idFound) {
				this.cachedSelected.push(item);
			}
		} else {
			if (index !== undefined) {
				this.cachedSelected.splice(index, 1);
			}
		}
	},
	onGridCheckAll: function (e) {
		var target = $(e.target);
		let availableDataSource = $('#cw_available_metrics').data('kendoCustomGrid').dataSource;
		let items = availableDataSource.data();
		for (let i = 0; i < items.length; i++) {
			var currentId = items[i].metricId;
			var currentFound = false;
			var index;
			for (let j = 0; j < this.cachedSelected.length; j++) {
				if (currentId === this.cachedSelected[j].metricId) {
					currentFound = true;
					index = j;
				}
			}
			if (currentFound) {
				if (!target.is(':checked')) {
					this.cachedSelected.splice(index, 1);
				}
			} else {
				if (target.is(':checked')) {
					this.cachedSelected.push(items[i]);
				}
			}
		}
	},
	/*
	* Adjusts the section height
	*/
	adjustSectionHeight: function () {
		var section = $('#modal');
		var form = $('#cw_metrics_form');
		var formHeight = form.height();
		var sectionHeight = section.height();
		section.find('.cw_section_content').css('height', sectionHeight - formHeight - 40 - 35);
		section.find('.k-grid-content').first().css('height', sectionHeight - formHeight - 40 - 20 - 40);
		section.find('.k-grid-content-locked').first().css('height', sectionHeight - formHeight - 40 - 20 - 40);
	},
	/*
	* Adjusts the section height
	*/
	adjustWindowSectionHeight: function () {
		var section = $('#cw_available_metrics_window');
		var sectionHeight = section.height();
		section.find('#cw_available_metrics').css('height', sectionHeight - 120);
		section.find('.k-grid-content').css('height', sectionHeight - 100 - 40);
		section.find('.k-grid-content-locked').css('height', sectionHeight - 100 - 40);
	}
};
