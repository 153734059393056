import './timePeriodSelector.less'

import { observer } from 'mobx-react';
import React from 'react';
import moment from 'moment';

import {AntSelect} from "controls/react/ant/antSelect";
import {DataListEntry} from "framework/entities/dataList";
import {AntDatePicker, AntRangePicker} from "controls/react/ant/antDatePicker";
import {Toolbar} from "controls/react/layout/toolbar";

const i = require('core/localization/localization').translator({
	'LAST30DAYS': {
		en: 'Last 30 days',
		no: 'Siste 30 dager',
	},
	'LAST7DAYS':{
		en: 'Last 7 days',
		no: 'Siste 7 dager'
	},
	'LASTDAY': {
		en: 'Yesterday',
		no: 'Siste dag'
	},
	'LASTHOUR': {
		en: 'Hour'
	},
	'CURRENT_MONTH':{
		en: 'Current month',
		no: 'Nåværende måned'
	},
	'LAST_MONTH': {
		en: 'Last month',
		no: 'Nåværende måned'
	},
	'CURRENT_YEAR': {
		en: 'Current year',
		no: 'Nåværende år'
	},
	'CUSTOM': {
		en: 'Custom',
		no: 'Tilpasset'
	},
	'CURRENT_WEEK': {
		en: 'Current week',
		no: 'Nåværende uke'
	},
	'LAST_YEAR': {
		en: 'Last year',
		no: 'Forrige år'
	},
	'YEAR': {
		en: 'Year',
		no: 'År'
	},
	'MONTH': {
		en: 'Month',
		no: 'Måned'
	}
});

export enum TimePeriod{
	Last30Days = 'LAST30DAYS',
	Last7Days = 'LAST7DAYS',
	LastDay = 'LASTDAY',
	LastHour = 'LASTHOUR',
	CurrentMonth = 'CURRENT_MONTH',
	CurrentWeek = 'CURRENT_WEEK',
	CurrentYear = 'CURRENT_YEAR',
	LastMonth = 'LAST_MONTH',
	LastYear = 'LAST_YEAR',
	Year = 'YEAR',
	Month = 'MONTH',
	Custom = 'CUSTOM'
}

export type TimeSelection = {
	period: TimePeriod;
	startDate?: number;
	endDate?: number;
	year?: number;
	months?: number;
}

export type TimeSelectionString = {
	period: TimePeriod;
	startDate?: string;
	endDate?: string;
	year?: number;
	months?: number;
}


export class TimePeriodSelectorProps{
	periods: TimePeriod[];
	value: TimeSelection;
	onChange: (selection: TimeSelection) => void;
}

export function toStringTimeSelection(period: TimeSelection) : TimeSelectionString{
	return {
		period: period.period,
		startDate: period.startDate != null ? moment(period.startDate).format("YYYY-MM-DD") : null,
		endDate: period.endDate != null ? moment(period.endDate).format("YYYY-MM-DD") : null,
		year: period.year,
		months: period.months
	}
}

export class TimePeriodSelectorState{
	dataList: DataListEntry<TimePeriod>[];
}

const b = require('b_').with('time-period-selector');

export class TimePeriodSelector extends React.Component<TimePeriodSelectorProps, TimePeriodSelectorState>{
	constructor(props: TimePeriodSelectorProps) {
		super(props);

		this.state = {
			dataList: this.getDataListFromPeriodsList(props.periods)
		}
	}

	get startDate(){
		return this.props.value.startDate != null ? moment(this.props.value.startDate) : null
	}

	get endDate(){
		return this.props.value.endDate != null ? moment(this.props.value.endDate) : null;
	}

	render(){
		return <div className={b()}>
			<AntSelect dataList={this.state.dataList}
			           value={this.props.value.period}
			           onChange={this.periodChanged} />
			{this.props.value.period == TimePeriod.Custom &&
				<AntRangePicker value={[this.startDate, this.endDate ]} onChange={this.dateRangeChanged}/>
			}
		</div>
	}


	periodChanged = (period: TimePeriod) => {
		this.triggerOnChanged({period, startDate: null, endDate: null})
	}

	dateRangeChanged = (values: moment.Moment[]) => {
		this.triggerOnChanged({startDate: values[0].valueOf(), endDate: values[1].valueOf()})
	}

	triggerOnChanged(value: Partial<TimeSelection>)
	{
		this.props.onChange({
			period: value.period ?? this.props.value.period,
			startDate: value.startDate === undefined ? this.props.value.startDate : value.startDate,
			endDate: value.endDate === undefined ? this.props.value.endDate : value.endDate
		});
	}

	componentDidUpdate(prevProps: Readonly<TimePeriodSelectorProps>) {
		if (prevProps.periods != this.props.periods) {
			this.setState({
				dataList: this.getDataListFromPeriodsList(this.props.periods)
			})
		}
	}

	getDataListFromPeriodsList(periods: TimePeriod[]) {
		return periods.map(x => ({
			id: x,
			name: i(x)
		}));
	}
}
