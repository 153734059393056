import Utils from 'tools/utils'
import Cookies from 'core/cookies'
import Widget from 'areas/service-boards/widget'
import Settings from 'settings'
import CustomNotification from 'controls/customNotification'
import State from 'tools/state'
import RemoteEventsManager from 'core/remoteEventsManager'
import Renderer from 'tools/renderer'
import {SlaRouter} from 'areas/sla/bundleDescription';

import {translator} from 'core';
import stripZeros from 'areas/sla/widgets/stripZeros';

const i = translator({
  "SLA » {0}": {
    "no": "SLA » {0}"
  }
});

export function SlaWidget(config) {
	Widget.call(this, config);

	this.requestPath = Settings.serverPath;
	if (this.sessionId) {
		this.requestPath = Settings.serverPath + 'sessions/' + this.sessionId + '/';
	}
	if (!this.accountName) {
		this.accountName = this.configuration.accountName || Cookies.CeesoftCurrentAccountName;
	}
	this.initComponent();

};

export {SlaWidget as default};

jQuery.extend(SlaWidget.prototype, Widget.prototype, {
	noSubscribe: false,
	/**
	 * Main init function
	 */
	initComponent: function () {
		$('#' + this.id).off();
		$('#' + this.id).on('drop', $.proxy(this.onWidthResize, this));
		if (State.currentApp?.dashboardDesigner?.props?.mode !== 'designer') {
			$('#' + this.id).on('click', '.cw_sla_percentage', $.proxy(this.onSlaPercentageClick, this));
		}
		this.isDetailsView = this.configuration.isDetailsView || false;
		this.serviceNameLoaded = false;
		this.slaNameLoaded = false;
		if (this.renderTo) {
			this.widgetContentDiv = $('#' + this.renderTo);
		} else {
			this.widgetContentDiv = $('#' + this.id).find('.cw_section_content');
		}
		kendo.ui.progress(this.widgetContentDiv, true);
		if (this.renderTo) {
			this.getData();
			this.slaName = this.configuration.slaName;
			this.serviceName = this.configuration.serviceName;
		} else {
			this.getServiceName();
			this.getSlaName();
		}
		if (!this.noSubscribe) {
			this.subscribe();
		}
		this.statusNotification = new CustomNotification({
			animationTime: 0,
			appendToElement: this.widgetContentDiv,
		});
		setTimeout(this.adjustSectionHeight, 200, this.id);
	},
	/**
	 * Gets from the server the service name
	 */
	getServiceName: function () {
		var url = this.requestPath + 'services/' + this.configuration.serviceId + '/name';
		Utils.ajax(url, 'GET', {}, $.proxy(function (result) {
			if (result.success) {
				this.serviceName = result.data;
				this.serviceNameLoaded = true;
				this.updateTitle();
			} else {
				kendo.ui.progress(this.widgetContentDiv, false);
				this.showErrorMessage(result.message);
			}
		}, this), function () {
			kendo.ui.progress(this.widgetContentDiv, false);
		});
	},
	/**
	 * Gets from the server the SLA name
	 */
	getSlaName: function () {
		var url = this.requestPath + 'services/' + this.configuration.serviceId + '/slas/' + this.configuration.slaId + '/name';
		Utils.ajax(url, 'GET', {}, $.proxy(function (result) {
			if (result.success) {
				this.slaName = result.data;
				this.slaNameLoaded = true;
				this.updateTitle();
			} else {
				kendo.ui.progress(this.widgetContentDiv, false);
				this.showErrorMessage(result.message);
			}
		}, this), function () {
			kendo.ui.progress(this.widgetContentDiv, false);
		});
	},
	/**
	 * Updates the title and the service name
	 */
	updateTitle: function () {
		if (this.slaNameLoaded && this.serviceNameLoaded) {
			this.setTitleIfEmpty(i('SLA » {0}', this.slaName))
			this.widgetContentDiv.find('.sla_service_name').find('strong').html(this.serviceName);
		}
		this.getData();
	},
	/**
	 * Renders the widget content based on SLA result object
	 * @param {Object} slaObj
	 */
	render: function (slaObj) {
		var indicatorClass = '';
		//var currentDate = new Date(slaObj.start + Math.floor((slaObj.end - slaObj.start) * slaObj.periodCompletion / 100));
		var currentDate = new Date(slaObj.completionTime);
		switch (slaObj.indicator) {
			case 'FAILED':
				indicatorClass = 'circle-arrow-down';
				// progressColor = Constants.RED;
				break;
			case 'OK':
				indicatorClass = 'circle-arrow-top';
				// progressColor = Constants.GREEN;
				break;
			case 'WARNING':
				indicatorClass = 'circle-arrow-right';
				// progressColor = Constants.ORANGE;
				break;
		}

		let decimals = this.configuration.decimals;

		let updatedCompletion = (slaObj.completionTime - slaObj.start) / (slaObj.end - slaObj.start) * 100;
		let cw_completion_period = stripZeros(updatedCompletion, decimals);
		let complianceGoal = stripZeros(slaObj.threshold, decimals);
		let slaPercentage = stripZeros(slaObj.percentage, decimals);

		this.widgetContentDiv.empty().addClass('sla_area');
		this.widgetContentDiv.append('<span class="glyphicons ' + indicatorClass + '"></span>' +
			'<span class="cw_compliance_goal right">' + complianceGoal + '%</span>' +
			'<h1 class="cw_sla_percentage">' + slaPercentage + '%</h1>' +
			'<div class="cw_sla_details"><span class="cw_period_tag">' + slaObj.periodTag + '</span>' +
			'<span class="sla_service_name">' + lang.widget.SERVICE + ': <strong class="cw_link">' + this.serviceName + '</strong></span>' +
			'<div class="cw_progress"><div class="cw_progress_slice" style="width:' + cw_completion_period + '%;">' +
			'<span class="cw_completion_tooltip">' + Renderer.browserDateRenderer(currentDate, "datetime", null, this.timeZone || null) + '</span></div></div>' +
			'<div class="cw_sla_details__time">' +
			'<span>' + Renderer.browserDateRenderer(slaObj.start, "datetime", null, this.timeZone || null) + '</span>' +
			'<span class="text_right">' + Renderer.browserDateRenderer(slaObj.end, "datetime", null, this.timeZone || null) + '</span>' +
			'</div></div>');

		let slaArea = $('.sla_area');
		if (slaArea.closest('.html-shape-container').length) {
			slaArea.find('.glyphicons').css('top', '50px');
			slaArea.find('.cw_compliance_goal').css('top', '50px');
		}

		if (!this.isDetailsView) {
			$('#' + this.id).find('.cw_sla_percentage').addClass('pointer');
		}
		this.onWidthResize();
		kendo.ui.progress(this.widgetContentDiv, false);
		if (State.currentApp?.dashboardDesigner?.props?.mode !== 'designer') {
			this.widgetContentDiv.on('click', '.cw_link', $.proxy(this.onServiceClick, this));
		}
	},
	/**
	 * Retrieves data from the server
	 */
	getData: function () {
		var url = this.requestPath + 'services/' + this.configuration.serviceId + '/slas/' + this.configuration.slaId + '/calculation';
		Utils.ajax(url, 'GET', {}, $.proxy(function (result) {
			if (result.success) {
				this.render(result.data);
			} else {
				kendo.ui.progress(this.widgetContentDiv, false);
				this.showErrorMessage(result.message);
			}
		}, this), $.proxy(function () {
			kendo.ui.progress(this.widgetContentDiv, false);
			this.statusNotification.setOptions({
				message: lang.messages.NO_DATA_AVAILABLE,
				status: 'error'
			}).show();
		}, this), 60000, $.proxy(function () {
			kendo.ui.progress(this.widgetContentDiv, false);
			this.statusNotification.setOptions({
				message: lang.messages.NO_DATA_AVAILABLE,
				status: 'error'
			}).show();
		}, this));
	},
	/**
	 * Handler for the sla percentage click event
	 * @param {object} e The click event
	 */
	onSlaPercentageClick: function (e) {
		if (!this.isDetailsView && this.redirectConfig?.doDefaultRedirect) {
			State.mainApp?.navigate(SlaRouter.details(this.configuration.slaId), {newTab: this.redirectConfig?.defaultRedirectInNewTab});
		}
	},
	onServiceClick: function (e) {
		const newTabSettings = this.redirectConfig?.doDefaultRedirect
			? [null, {newTab: this.redirectConfig?.defaultRedirectInNewTab}]
			: []
		State.mainApp?.loadModule('ServiceDetails', this.configuration.serviceId, {}, e, ...newTabSettings);
	},
	/**
	 * Called when an event is received from the server
	 * @param {Array} events The event data object
	 */
	onEvent: function (events) {
		var data = {}, length = events.length;
		if (length) {
			data = events[length - 1];
		} else {
			data = events;
		}

		this.render(data.calculation);
	},
	/**
	 * Triggered on window resize
	 */
	onWidthResize: function () {
		var area;
		if (this.renderTo) {
			area = $('#' + this.renderTo);
		} else {
			area = $('#' + this.id).find('.cw_section_content');
		}

		var areaWidth = area.width();
		var areaHeight = area.height();
		var slaPercentage = $('.cw_sla_percentage');
		if (areaWidth <= 260) {
			slaPercentage.css('font-size', '36px');
		} else if ((areaWidth > 260) && (areaWidth <= 280)) {
			slaPercentage.css('font-size', '48px');
		} else if ((areaWidth > 280) && (areaWidth <= 300)) {
			slaPercentage.css('font-size', '52px');
		} else if ((areaWidth > 300) && (areaWidth <= 320)) {
			slaPercentage.css('font-size', '56px');
		} else if ((areaWidth > 320) && (areaWidth <= 350)) {
			slaPercentage.css('font-size', '60px');
		} else if (areaWidth > 350) {
			slaPercentage.css('font-size', '70px');
		}
		if (areaHeight < 230) {
			slaPercentage.css({
				'font-size': '36px',
				'top': '30px'
			});
		}
	},
	adjustSectionHeight: function (id) {
		var section = $('#' + id);
		var sectionHeight = section.height();
		section.find('.cw_section_content').css('height', sectionHeight);
		section.find('.k-grid-content').css('height', sectionHeight - 30);
	},
	/**
	 * Subscribes to SLA events
	 */
	subscribe: function () {
		var subscriptionObj = [{
			eventType: 'Sla',
			slaId: this.configuration.slaId
		}];
		this.subscriberId = this.id;
		RemoteEventsManager.subscribe(this.subscriberId, subscriptionObj);
	},
	/**
	 * Destroy
	 */
	destroy: function () {
		Widget.prototype.destroy.call(this);
	}
});
