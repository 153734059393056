import Application from 'core/application';
import PreferencesPanel from 'controls/preferencesPanel';
import Settings from 'settings';
import Cookies from 'core/cookies';

import ErrorHandler from 'core/errorHandler';
import UserPrefs from 'tools/userPrefs';

import State from 'tools/state';
import { AgentsRouter } from './agents/bundleDescription';
import { SlaRouter } from 'areas/sla/bundleDescription';
import MonitorAPI from "tools/apis/monitorAPI";
import monitorToModule from "tools/mappings/monitorToModule";
import {AutomationRouter} from "../automation/bundleDescription";
import {ServiceBoardApi} from "../service-boards/api";

import Api from "tools/api"
import {Dialog} from "../../controls";
import {Utils} from "../../tools";

import {translator} from "core";
import {AssetsApi} from "../assets";
import {AccountsApi} from "../administration/accounts/api";
import {AdministrationApi} from "areas/administration/api";
import {ServicesApi} from "areas/services/api";
import {IncidentApi} from "areas/incidents/api";
import {ManagementApi} from "areas/management/api";
import {SlaApi} from "areas/sla/api";
import {AutomationApi} from "areas/automation/api";
import {ServicesRouter} from 'areas/services/bundleDescription';
import {ServiceModelsApi} from 'areas/services/api';
import {ServiceBoardRouter} from 'areas/service-boards/bundleDescription';
import {DashboardsRouter} from "areas/dashboards/bundleDescription";
import MonitorsRouter from "areas/assets/monitors/router";

const i = translator({
	'NOT_FOUND': {
		'en': "CVID {CVID} was not found, please check newer dates for the delete operation for this CVID.",
		'no': "CVID {CVID} ble ikke funnet, sjekk nyere datoer for slettehandlingen for denne CVID."
	}
});

const Operations = {
	CREATE: 'CREATE',
	UPDATE: 'UPDATE',
	DELETE: 'DELETE',
	RESTART: 'RESTART',
	LOGIN: 'LOGIN',
	LOGOUT: 'LOGOUT'
};

const Modules = {
	ADMINISTRATOR_AGENT: 'ADMINISTRATOR_AGENT',
	ADMINISTRATOR_ACCOUNT: 'ADMINISTRATOR_ACCOUNT',
	ADMINISTRATOR_USER: 'ADMINISTRATOR_USER',
	ADMINISTRATOR_USERCATEGORY: 'ADMINISTRATOR_USERCATEGORY',
	ADMINISTRATOR_ASSET: 'ADMINISTRATOR_ASSET',
	ADMINISTRATOR_ASSETGROUP: 'ADMINISTRATOR_ASSETGROUP',
	ADMINISTRATOR_ASSETGROUP_MEMBERSHIP: 'ADMINISTRATOR_ASSETGROUP_MEMBERSHIP',
	ADMINISTRATOR_AUTHENTICATION: 'ADMINISTRATOR_AUTHENTICATION',
	ADMINISTRATOR_TEAM: 'ADMINISTRATOR_TEAM',
	ADMINISTRATOR_TEAM_MEMBERSHIP: 'ADMINISTRATOR_TEAM_MEMBERSHIP',
	ADMINISTRATOR_TEAM_CALENDAR: 'ADMINISTRATOR_TEAM_CALENDAR',
	ADMINISTRATOR_MONITOR: 'ADMINISTRATOR_MONITOR',
	SLA: 'SLA',
	SLA_EXCLUDE: 'SLA_EXCLUDE',
	SERVICEMODEL: 'SERVICEMODEL',
	SERVICEBOARD: 'SERVICEBOARD',
	INCIDENT: 'INCIDENT',
	SERVICE: 'SERVICE',
	MONITOR: 'MONITOR',
	SERVICE_MAINTENANCE: 'SERVICE_MAINTENANCE',
	AGENT_MAINTENANCE: 'AGENT_MAINTENANCE',
	ASSET_MAINTENANCE: 'ASSET_MAINTENANCE',
	AGENT_MANAGEMENT: 'AGENT_MANAGEMENT',
	ACCESS: 'ACCESS',
	AUTOMATION: 'AUTOMATION'
};

const DISABLED_MODULE_LINKS = [Modules.AGENT_MAINTENANCE, Modules.ASSET_MAINTENANCE, Modules.SERVICE_MAINTENANCE];

/**
 * @class ceeview.management.ManagementAudit This class is used as an entry point in
 * the Audit Tab.
 * @namespace ceeview.management
 * @extends Application
 * @constructor
 */
export default function ManagementAudit (config) {
    Utils.apply(this, config);
    this.module = {
        name: 'ManagementAudit',
        initialConfig: config
    };
    this.initComponent();
};

jQuery.extend(ManagementAudit.prototype, Application.prototype, {
    /**
     * @property
     * @type string
     */
    type: 'Application',

    /**
     * Main init function
     */
    initComponent: function () {
        Application.prototype.initComponent.call(this);


        this.showGridView = true;
        this.loadUserPreferences();
        this.removeListeners();
        this.attachListeners();
        this.trailUrl = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/auditTrail?timeSelector=LAST7DAYS&includeSubaccounts=' + State.includeSubaccounts.toString();

    },

    /**
     * Called in order to remove existing listeners
     */
    removeListeners: function () {
        $('.cw_audit_timerange').off();
    },

    /**
     * Attaches the listeners
     */
    attachListeners: function () {
		$('.cw_audit_timerange').on('click', 'li', $.proxy(this.onSetInterval, this));
		$('#cw_audit_trails').on('click', '.cw_grid_link', (e) => this.onModuleClick(e));
    },

	async onModuleClick(e) {
		const row = $(e.currentTarget).closest('tr');
		const item = this.grid.dataItem(row).toJSON();

		let appModule;

		const moduleMap = {
			[Modules.SERVICEBOARD]: 'ServiceBoardView',
			[Modules.ADMINISTRATOR_ASSET]: 'AssetDetails',
			[Modules.ADMINISTRATOR_ACCOUNT]: 'AccountDetails',
			[Modules.ADMINISTRATOR_USER]: 'AdministrationUserDetails',
			[Modules.ADMINISTRATOR_USERCATEGORY]: 'AdministrationUserCategoriesDetails',
			[Modules.ADMINISTRATOR_ASSETGROUP]: 'AssetGroupDetails',
			[Modules.ADMINISTRATOR_ASSETGROUP_MEMBERSHIP]: 'AssetGroupDetails',
			[Modules.ADMINISTRATOR_AUTHENTICATION]: 'AdministrationAuthentications',
			[Modules.ADMINISTRATOR_TEAM]: 'AdministrationTeamDetails',
			[Modules.ADMINISTRATOR_TEAM_MEMBERSHIP]: 'AdministrationTeamDetails',
			[Modules.SERVICE]: 'ServiceDetails',
			[Modules.INCIDENT]: 'Incidents',
			[Modules.MONITOR]: 'MonitorSystem',
		};

		const module = item.moduleId;

		switch(module) {
			default:
				appModule = moduleMap[module];
				break;
			case Modules.ADMINISTRATOR_AGENT:
				if ((await this.checkItemExists(module, item))) {
					return State.mainApp.navigate(AgentsRouter.details(item.objectId));
				}
				break;
			case Modules.SLA:
			case Modules.SLA_EXCLUDE:
				if ((await this.checkItemExists(module, item))) {
					return State.mainApp.navigate(SlaRouter.details(item.objectId));
				}
				break;
			case Modules.AUTOMATION:
				if ((await this.checkItemExists(module, item))) {
					return State.mainApp.navigate(AutomationRouter.details(item.objectId));
				}
				break;
			case Modules.ADMINISTRATOR_MONITOR:
				const monitors = await MonitorAPI.getAllMonitors();
				const foundMonitor = monitors.find((monitor) => monitor.id === item.objectId);
				if (foundMonitor) {
					let monitorType = foundMonitor.type;
					if (['FILESEARCH', 'SNMP', 'SNMPTRAP', 'XEN_SERVER', 'REMOTESYSTEM', 'REMOTEISERIES', 'METRICMONITOR', 'LOGMONITOR', 'DATABASE', 'EATONUPS', 'MODBUS', 'URL'].indexOf(monitorType) !== -1) {
						State.mainApp.navigate(MonitorsRouter.details(monitorType, item.objectId));
					} else {
						appModule = monitorToModule[foundMonitor.type];
					}
				} else {
					return this.showNotFoundDialog(item.objectId);
				}
				break;
			case Modules.SERVICEMODEL:
				let modelId = item.objectId;
				const result = await ServiceModelsApi.getServiceFromModel(modelId);
				if (result.success) {
					let serviceId = result.data.serviceId;
					State.mainApp.navigate(ServicesRouter.viewer(serviceId));
				}
				break;
			case Modules.SERVICEBOARD:
				if ((await this.checkItemExists(module, item))) {
					const result = await Api.fetch(DashboardsRouter.getPropertiesUrl(item.objectId));
					if (result.success) {
						let dashboardProperties = result.data;
						if (dashboardProperties.type === 'DASHBOARD') {
							State.mainApp.navigate(DashboardsRouter.viewer(item.objectId));
						} else if (dashboardProperties.type === 'LEGACY') {
							State.mainApp.navigate(ServiceBoardRouter.details(item.objectId));
						}
					}
				}
				break;
		}

		if (!appModule) {
			return;
		}

		const found = await this.checkItemExists(module, item);

		if (!found) {
			return;
		}

		State.mainApp.loadModule(appModule, item.objectId, {
			id: item.objectId,
			mode: 'update',
		});
	},

	showNotFoundDialog(id) {
		var dialog = new Dialog({
			title: lang.INFO,
			msg: i('NOT_FOUND').replace('{CVID}', id),
			icon: 'ERROR',
			actionText: 'OK',
			fn: () => {}
		});
		dialog.show();
	},

	checkItemExists: async function (module, item) {
		let url;
		let method = 'GET';

		switch (module) {
			case Modules.SERVICEBOARD:
				url = ServiceBoardApi.getServiceBoardUrl(item.objectId);
				break;
			case Modules.ADMINISTRATOR_ASSET:
				url = AssetsApi.getAssetDetailsUrl(item.objectId);
				break;
			case Modules.ADMINISTRATOR_ACCOUNT:
				url = AccountsApi.getAccountConfigurationUrl(item.objectId);
				break;
			case Modules.ADMINISTRATOR_USER:
				url = AdministrationApi.getUserUrl(item.objectId);
				break;
			case Modules.ADMINISTRATOR_ASSETGROUP:
			case Modules.ADMINISTRATOR_ASSETGROUP_MEMBERSHIP:
				url = AdministrationApi.getAssetGroupUrl(null, item.objectId, 'true');
				break;
			case Modules.ADMINISTRATOR_AUTHENTICATION:
				url = AdministrationApi.getAuthenticationUrl(null, item.objectId, 'true');
				break;
			case Modules.ADMINISTRATOR_TEAM:
			case Modules.ADMINISTRATOR_TEAM_MEMBERSHIP:
				url = AdministrationApi.getTeamUrl(null, item.objectId, 'true');
				break;
			case Modules.SERVICE:
				url = ServicesApi.getServiceUpdateUrl(item.objectId, 'true');
				break;
			case Modules.INCIDENT:
				url = IncidentApi.getIncidentUrl(null, item.objectId, 'true');
				break;
			case Modules.ADMINISTRATOR_AGENT:
				url = ManagementApi.getAgentUrl(null, item.objectId);
				break;
			case Modules.SLA:
			case Modules.SLA_EXCLUDE:
				url = SlaApi.getSlaUrl(null, item.objectId, 'true');
				break;
			case Modules.AUTOMATION:
				url = AutomationApi.getAutomationUrl(null, item.objectId, 'true');
				break;
		}

		if (url) {
			try {
				await Api.fetch(url, {throwOnError: true});
			}
			catch(e) {
				this.showNotFoundDialog(item.objectId);
				return false;
			}
		}

		return true;
	},
    /**
     * Handler for the period toggle
     * @param {Object} e The click event object
     */
    onSetInterval: function (e) {
        var interval = $(e.currentTarget).find('input').val();
        $('.cw_audit_timerange').find('li').removeClass('is_selected');
        $(e.currentTarget).addClass('is_selected');

        this.trailUrl = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/auditTrail?timeSelector=' + interval + '&includeSubaccounts=' + State.includeSubaccounts.toString();
        this.auditDataSource.options.transport.read.url = this.trailUrl;
        this.auditDataSource.page(1);
        //this.auditDataSource.read();
    },

    /**
     * Loads user preferences
     */
    loadUserPreferences: function () {
        this.userPref = [];
        UserPrefs.load('Management', $.proxy(function (result) {
            if (result.success) {
                this.userPref = result.data;
                this.initKendoComponents();
                this.removeMask();
            } else {
                Utils.showInfo(lang.ALERT, result.message, result.details);
            }
        }, this));
    },

    /**
     * Initialize kendo components
     */
    initKendoComponents: function () {
        var auditSort, auditFilter,
            defaultFsView = UserPrefs.get('defaultFsView'),
            filterMessages = lang.grid.filter,
            pref;
        this.defaultColumns = {
            date: {
                hidden: false,
                width: 220
            },
            module: {
                hidden: false,
                width: 200
            },
            accountName: {
                hidden: !State.includeSubaccounts,
                width: 150
            },
            userDescription: {
                hidden: false,
                width: 200
            },
            operation: {
                hidden: false,
                width: 110
            },
            objectId: {
                hidden: false,
                width: 160
            },
            objectName: {
                hidden: false,
                width: 160
            },
            description: {
                hidden: false
            }
        };

        if (Utils.isGuid(defaultFsView)) {
            pref = JSON.parse(UserPrefs.get(defaultFsView));
            pref = pref || {
                    sort: [],
                    filter: []
                };
            auditSort = pref.sort;
            auditFilter = pref.filter;
            this.auditColumns = pref.columns;
        } else {
            if (UserPrefs.get('auditSort')) {
                auditSort = JSON.parse(UserPrefs.get('auditSort'));
            } else {
                auditSort = [{
                    field: 'date',
                    dir: 'desc',
                    compare: null
                }];
            }
            if (UserPrefs.get('auditFilter')) {
                auditFilter = JSON.parse(UserPrefs.get('auditFilter'));
            } else {
                auditFilter = [];
            }
        }
        this.gridMessages = {
            isTrue: '<span class="glyphicons service_state ok-sign"></span>',
            isFalse: '<span class="glyphicons service_state remove-sign"></span>',
            clear: lang.CLEAR,
            info: lang.grid.filter.SHOW_ITEMS,
            filter: lang.FILTER
        };
        this.auditDataSource = new kendo.ceeview.DataSource({
            transport: {
                read: {
                    url: this.trailUrl,
                    contentType: "application/json; charset=utf-8",
                    type: 'POST',
                    dataType: 'json',
                    cache: false
                },
                parameterMap: function (data, type) {
	                var i, length, from, fromYear, fromMonth, fromDay;
	                if (type === 'read') {
		                if (data.filter && data.filter.filters.length) {
							data.filter.filters = Utils.changeDateFilterToString(data.filter.filters);
			                length = data.filter.filters.length;
			                for (i = 0; i < length; i++) {
				                if (data.filter.filters[i].field === 'date') {
					                from = new Date(data.filter.filters[i].value);
					                fromYear = from.getFullYear().toString();
					                fromMonth = (from.getMonth() + 1) < 10 ? '0' + (from.getMonth() + 1) : (from.getMonth() + 1).toString();
					                fromDay = from.getDate() < 10 ? '0' + from.getDate() : from.getDate().toString();
					                data.filter.filters[i].value = fromYear + fromMonth + fromDay;
				                }
			                }
		                }
		                if (data.pageSize < 0) {
			                console.log('Page size: ' + data.pageSize);
		                }
		                if (data.take < 0) {
			                console.log('Page size: ' + data.take);
		                }
		                return kendo.stringify(data);
	                }
                }
            },
            schema: {
                data: function (response) {
                    return response.items;
                },
                total: function (response) {
                    return response.total;
                },
                model: {
                    id: 'id',
                    fields: {
                        date: {
                            type: 'date'
                        }
                    }
                },
                parse: function (data) {
                    var i, length, value, utcTime;
                    length = data.items.length;
                    for (i = 0; i < length; i++) {
                        value = new Date(data.items[i].time);
                        data.items[i].date = value;
                    }
                    return data;
                }
            },
            serverSorting: true,
            serverPaging: true,
            serverFiltering: true,
            sort: auditSort,
            filter: auditFilter,
            error: ErrorHandler.kendoServerError
        });

        var auditColumns = this.auditColumns || JSON.parse(UserPrefs.get('auditColumns')) || this.defaultColumns;
        auditColumns = Utils.completeColumns(auditColumns, this.defaultColumns);

        var sectionHeight = $('.cw_section_full').height();
        var gridContent = sectionHeight - 60;
        this.auditDataSource.pageSize(Math.floor(gridContent / 22));

        this.grid = $('#cw_audit_trails').kendoCustomGrid({
            dataSource: this.auditDataSource,
			cacheScrollCheckboxes: true,
            resizable: true,
			reorderable: true,
            autoBind: false,
            selectable: 'row',
            scrollable: {
                virtual: true
            },
            filterable: {
                messages: this.gridMessages
            },
            sortable: {
                mode: 'multiple',
                allowUnsort: true
            },
            columns: Utils.rearrangeColumns([{
                field: 'date',
                title: lang.widget.TIME,
                template: '#= Renderer.browserDateRenderer(time, Constants.DATETIME)#',
                sortable: true,
                filterable: {
                    extra: false,
                    operators: {
                        date: {
                            eq: filterMessages.EQ,
                            gte: filterMessages.IAE,
                            gt: filterMessages.IA,
                            lte: filterMessages.IBE,
                            lt: filterMessages.IB
                        }
                    },
	                ui: function (element) {
		                element.kendoDatePicker({
			                format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
			                disableDates: function (date) {
				                return date >= new Date();
			                }
		                }).data('kendoDatePicker');
	                }
                },
                hidden: auditColumns.date.hidden,
                width: auditColumns.date.width,
	            attributes: {
		            'class': 'tooltip ellipsis to_expand'
	            }
            }, {
                field: 'module',
                title: lang.MODULE,
				template: '#= module #',
                sortable: true,
                filterable: {
                    extra: false,
                    operators: {
                        string: {
                            eq: filterMessages.EQ
                        }
                    },
                    messages: this.gridMessages,
                    ui: function (element) {
                        element.kendoDropDownList({
                            dataSource: new kendo.ceeview.DataSource({
                                transport: {
                                    read: {
                                        url: Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/auditTrail/modules',
                                        contentType: "application/json; charset=utf-8",
                                        type: 'GET',
                                        dataType: 'json',
                                        cache: false
                                    }
                                },
                                error: ErrorHandler.kendoServerError
                            }),
                            dataTextField: 'text',
                            dataValueField: 'value',
                            change: Utils.onFilterDropDownChange,
                            optionLabel: lang.grid.FILTER_SELECT_VALUE
                        });
                    }
                },
                hidden: auditColumns.module.hidden,
                width: auditColumns.module.width,
	            attributes: {
		            'class': 'tooltip ellipsis to_expand'
	            }
            }, {
                field: 'accountName',
                title: lang.ACCOUNT,
                template: '#= accountName #',
                sortable: true,
                hidden: !State.includeSubaccounts,
                filterable: {
                    ui: $.proxy(function(element) {
                        return Renderer.filterGridByOwnElementDS.call(this, element, 'accountName');
                    }, this),
                    messages: this.gridMessages,
                    extra: false,
                    operators: {
                        string: {
                            neq: filterMessages.NEQ,
                            eq: filterMessages.EQ
                        }
                    }
                },
                width: auditColumns.accountName.width,
	            attributes: {
		            'class': 'tooltip ellipsis to_expand'
	            }
            }, {
                field: 'userDescription',
                title: lang.USER,
                template: '#= userDescription #',
                sortable: true,
                filterable: {
                    extra: false,
                    operators: {
                        string: {
                            contains: filterMessages.CONTAINS
                        }
                    },
                    messages: this.gridMessages
                },
                hidden: auditColumns.userDescription.hidden,
                width: auditColumns.userDescription.width,
                attributes: {
                    "class": "expand ellipsis"
                }
            }, {
                field: 'operation',
                title: lang.OPERATION,
                template: '#= operation #',
                sortable: true,
                filterable: {
                    extra: false,
                    operators: {
                        string: {
                            eq: filterMessages.EQ
                        }
                    },
                    messages: this.gridMessages,
                    ui: function (element) {
                        element.kendoDropDownList({
                            dataSource: new kendo.ceeview.DataSource({
                                transport: {
                                    read: {
                                        url: Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/auditTrail/operations',
                                        contentType: "application/json; charset=utf-8",
                                        type: 'GET',
                                        dataType: 'json',
                                        cache: false
                                    }
                                },
                                error: ErrorHandler.kendoServerError
                            }),
                            dataTextField: 'text',
                            dataValueField: 'value',
                            change: Utils.onFilterDropDownChange,
                            optionLabel: lang.grid.FILTER_SELECT_VALUE
                        });
                    }
                },
                hidden: auditColumns.operation.hidden,
                width: auditColumns.operation.width,
	            attributes: {
		            'class': 'tooltip ellipsis to_expand'
	            }
            }, {
                field: 'objectId',
                title: 'CVID',
                template: '#= objectId #',
                sortable: true,
                filterable: {
                    extra: false,
                    operators: {
                        string: {
                            eq: filterMessages.EQ
                        }
                    },
                    messages: this.gridMessages
                },
	            attributes: {
		            'class': 'tooltip ellipsis to_expand'
	            }
            },  {
	            field: 'objectName',
	            title: lang.NAME,
				template: (data) => {
					if (
						[Operations.CREATE, Operations.UPDATE, Operations.RESTART].includes(data.operationId) &&
						!DISABLED_MODULE_LINKS.includes(data.moduleId)) {
						return `<span class="cw_grid_link">${data.objectName}</span>`;
					}
					else {
						return data.objectName;
					}
				},
	            sortable: true,
	            filterable: {
		            extra: false,
		            operators: {
			            string: {
				            contains: filterMessages.CONTAINS
			            }
		            },
		            messages: this.gridMessages
	            },
	            attributes: {
		            'class': 'tooltip ellipsis to_expand'
	            },
	            hidden: auditColumns.objectName.hidden,
	            width: auditColumns.objectName.width
            }, {
                field: 'description',
                title: lang.DESCRIPTION,
                template: '#= description #',
	            attributes: {
		            'class': 'tooltip ellipsis to_expand'
	            },
                sortable: false,
                filterable: {
                    extra: false,
                    operators: {
                        string: {
                            contains: filterMessages.CONTAINS
                        }
                    },
                    messages: this.gridMessages
                },
                hidden: auditColumns.description.hidden,
                width: auditColumns.description.width
            }], auditColumns),
            columnMenu: true,
            change: $.proxy(this.onAuditRowExpand, this),
            dataBound: $.proxy(this.onGridDataBound, this)
        }).data('kendoCustomGrid')
        // Add Kendo tooltip to the header of the columns
        Utils.gridColumnHeaderTooltip(this.grid);
        this.adjustSectionHeight();
        var searchValue = UserPrefs.get('defaultAuditFsView') ? '' : (UserPrefs.get('auditSearchPhrase') || '');
        this.gridFilterPanel = new PreferencesPanel({
            renderTo: 'cw_audit_filters',
            grid: this.grid,
            counter: false,
            modulePrefName: 'Management',
            defaultPrefViewKey: 'defaultAuditFsView',
            prefListKey: 'auditFSViews',
            userPref: this.userPref,
            onRemove: $.proxy(State.currentApp.saveUserPreferences, State.currentApp),
            searchFields: ['description', 'accountName'],
            searchValue: searchValue,
            defaultColumns: this.defaultColumns
		});
    },

    /**
     * Handler function for the selection of one service log grid row
     */
    onAuditRowExpand: function (e) {
	    var selectedRow = this.grid.select();
	    var myRow = selectedRow[0];
	    var messageEl = $(myRow).find('.to_expand');
	    if ($(messageEl).hasClass('cw_message_expanded')) {
		    $(messageEl).removeClass('cw_message_expanded').addClass('ellipsis');
	    } else {
		    $('#cw_audit_trails').find('.k-grid-content').find('td.cw_message_expanded').removeClass('cw_message_expanded').addClass('ellipsis');
		    $(messageEl).addClass('cw_message_expanded').removeClass('ellipsis');
	    }
    },

    /**
     * Handler function for the selection of one service log grid row
     */
    onGridDataBound: function (e) {
	    $('#cw_audit_trails').find('.k-grid-content').kendoTooltip({
		    filter: "td.tooltip:not(:empty)",
		    content: function (event) {
			    return event.target.text().split("\n").join("<br />");
		    }
	    });
        var dataSource = this.grid.dataSource;
        if (dataSource.filter()) {
            $('.cw_window_actions').find('.restart').removeClass('inactive');
        }
    },

    /**
     * Method by Andy
     *
     */
    adjustSectionHeight: function () {
        var section = $('.cw_management_module .cw_section_full:visible');
        var sectionHeight = section.height();
        section.find('.cw_section_content').css('height', sectionHeight);
        section.find('.k-grid-content').css('height', sectionHeight - 40);
    },

    /**
     * Saves user preferences
     */
    saveUserPreferences: function () {
        this.saveUserPrefs({
            category: 'Management',
            filterPanel: this.gridFilterPanel,
            grid: this.grid,
            keys: {
                searchPhrase: 'auditSearchPhrase',
                columns: 'auditColumns',
                sort: 'auditSort',
                filter: 'auditFilter',
                FsViews: 'auditFSViews',
                defaultFsView: 'defaultAuditFsView'
            }
        });
    },

    /**
     * Called when application is unloaded/destroyed
     */
    destroy: function () {
        Application.prototype.destroy.call(this);
        this.saveUserPreferences();
    }
});
