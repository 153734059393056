import './timeWidgetConfiguration.less';

import React from 'react';
import {DateFormatDropDown, TimeFormatDropDown, TimeZonesDropDown} from "controls/react";
import {Lang} from "core";
import {FormEntry, TextBox} from 'controls/react/form';
import {Section} from 'controls/react/layout/section';
import {WidgetConfigurationContext} from "../../widgetConfigurationStore";
import {i} from 'core/localization';
import {addLinks} from "core/react/links";


@addLinks
export default class TimeWidgetConfiguration extends React.Component {
	static contextType = WidgetConfigurationContext;

	render() {
		let {configLink} = this.props;
		if(!configLink){
			configLink = this.linkContext('config');
		}

		return <Section appearance={"none"}
		                contentPadding={false}
		                containerClass={'time-widget-configuration'}
		                childrenPadding={true}>
			<FormEntry label={i('Title')}>
				<TextBox {...configLink.get('title').props}/>
			</FormEntry>
			<FormEntry label={i('Timezone')}>
				<TimeZonesDropDown {...configLink.get('configuration.timezone').props}/>
			</FormEntry>
			<FormEntry label={Lang.serviceBoard.DATEFORMAT}>
				<DateFormatDropDown {...configLink.get('configuration.dateformat').props}/>
			</FormEntry>
			<FormEntry label={Lang.serviceBoard.TIMEFORMAT}>
				<TimeFormatDropDown {...configLink.get('configuration.timeformat').props}/>
			</FormEntry>
		</Section>
	}
}
