import {put, select} from 'redux-saga/effects';
import {getItemValue} from '../selectors/getItemValue';
import {formBuilderForm, RESETABLE_TYPES} from '../constants';
import {change} from 'redux-form';
import {getReduxFormValue} from '../selectors/getReduxFormValue';

const ENABLED_FOR = [
	// "datacollector.ws.configuration.soap.SOAPRequestNoAuth",
	// "datacollector.ws.configuration.soap.SOAPRequest"
];

const ALLOW_FIELDS = [
	'properties.assetId'
];

const SKIP_FIELDS = [
	"authTypes"
]

export function* resetFieldValues({meta: {field}}) {
	const strippedField = field.substr(11);

	if(SKIP_FIELDS.includes(strippedField)) {
		return;
	}

	const dependentFields = (yield select(getItemValue, 'dependentFields')).toJS();
	const className = (yield select(getItemValue, ['original', 'className']));

	if (!ALLOW_FIELDS.includes(field) && !ENABLED_FOR.includes(className)) {
		return;
	}

	const fieldsToReset = dependentFields[strippedField];

	if (!fieldsToReset) {
		return;
	}

	for (const fieldToReset of fieldsToReset) {
		const fieldConfig = yield select(getItemValue, ['fields', fieldToReset]);
		const type = fieldConfig.get('type');
		const currentValue = yield select(getReduxFormValue, fieldToReset);

		if (currentValue && RESETABLE_TYPES.has(type)) {
			console.warn('resetFieldValues field', fieldToReset)
			yield put(change(formBuilderForm, `properties.${fieldToReset}`, "", true));
		}
	}
}
