import './antInput.less'

import React, {ChangeEventHandler} from "react";
import { observer } from 'mobx-react';
import Input, {InputProps, TextAreaProps} from "antd/lib/input";

const {TextArea} = Input;

import {addEnterEscHandlers, captureValueLink, ChangeEventHandlerWrapped, wrapOnChange} from "controls/react/ant/utils";


interface AntInputProps extends Omit<InputProps, 'onChange'>{
	valueLink?: any,
	invalid?: boolean,
	errors?: Array<any>,
	onChange?: ChangeEventHandlerWrapped<HTMLInputElement>
	ref?: any,
	onPressEnter?: () => void,
	onPressEsc?: () => void,
}

export const AntInput:React.FunctionComponent<AntInputProps> = observer(React.forwardRef((props, ref) => {
	let {errors, invalid, ...restProps} = captureValueLink(props);

	const wrappedProps = wrapOnChange(restProps);

	const wrappedProps2 = addEnterEscHandlers(wrappedProps);

	return <Input ref={ref} {...wrappedProps2}/>
}))

interface AntTextAreaProps extends Omit<TextAreaProps, 'onChange'>{
	valueLink?: any,
	invalid?: boolean,
	errors?: Array<any>,
	onChange?: ChangeEventHandlerWrapped<HTMLTextAreaElement>
}

export const AntTextArea:React.FunctionComponent<AntTextAreaProps> = observer(props => {
	let {errors, invalid, ...antNativeProps} = captureValueLink(props);

	const wrappedProps = wrapOnChange(antNativeProps);

	return <TextArea {...wrappedProps}/>
});
