import React from 'react';
import {Cookies, translator} from 'core';
import PropTypes from 'prop-types';
import {Section} from "controls/react/layout/section";
import {FormEntry, Switch} from 'controls/react/form';
import './actionTriggerSwitcher.less';
import {Utils} from 'tools';
import {DataReader, getItemId} from "./notificationDataReader";
import Label from "controls/react/form/formBuilder/subforms/label";

const i = translator ({
	'tooltip': {
		'en': 'This toggle sets the action trigger for this user or team active or inactive',
		'no': 'Denne vekselen setter handlingsutløser for denne brukeren eller teamet aktivt eller inaktivt'
	},
	'tooltip2': {
		'en': 'Action trigger can only be enabled after create of user',
		'no': 'Action trigger can only be enabled after create of user'
	},
	'Action trigger': {
		'no': 'Handlingsutl\u00F8ser'
	}
});

const b = require('b_').with('action-trigger-switcher');

function ActionTriggerSwitcher(props) {
	const [reader, setReader] = React.useState(null);
	const [refresh, setRefresh] = React.useState();

	React.useEffect(() => {
		(async () => {
			const rawDataReader = new DataReader(Cookies.CeesoftCurrentAccountId, props.type, getItemId(props.id));
			await rawDataReader.read();

			setReader(rawDataReader);
		})();
	}, []);

	const handleEnabled = React.useCallback((value) => {
		setRefresh(Utils.guid())
		reader.setEnabled(value);
		reader.save();
	}, [reader]);


	if (!reader) {
		return null;
	}

	return <Section appearance={'none'}>
		<FormEntry label={<Label caption={i('Action trigger')} tooltip={props.id ? i('tooltip') : i('tooltip2')} />} containerClass={b('form-entry')} >
			<Switch onChange={handleEnabled} value={reader?.data.isEnabled} updateable isEnabled={props.id ? true : false}/>
		</FormEntry>
	</Section>
}

ActionTriggerSwitcher.propTypes = {
	type: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired
}

export default ActionTriggerSwitcher;
