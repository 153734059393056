import React from 'react';
import ReactDOM from "react-dom";
import {Utils, applyRequired} from 'tools/utils';
import Settings from 'settings';
import Api from 'tools/api';
import ConfigurationBase from 'areas/service-boards/configurationBase';
import Configuration from 'configuration';
import FormEntry from "controls/react/form/formEntry";
import {TagsSelect} from "controls/react/tagsSelect";
import {AntSelect} from "controls/react/ant/antSelect";

export function GridWidgetConfiguration(config) {
	Utils.apply(this, config);
	this.initComponent();
};

export {GridWidgetConfiguration as default};

jQuery.extend(GridWidgetConfiguration.prototype, ConfigurationBase.prototype, {
	/**
	 * @cfg {Object} widgetObj
	 */
	/**
	 * Main init function
	 */
	initComponent: function () {
		var html;
		var widgetObj = this.widgetObj;

		html = '<div class="cw_field"><label class="cw_inline">' + lang.TITLE + ': </label><input type="text" class="cw_widget_title cw_inline" value="' + (widgetObj.title || '') + '" /></div>';
		html += '<div class="cw_field" id="cw_grid_asset_account"><label class="cw_inline">' + lang.ACCOUNT + ': </label><div class="cw_dropdown_container"><input id="cw_grid_asset_accountid" type="text" value="' + (widgetObj.configuration.accountId || '') + '" /></div></div>';
		html += '<div class="cw_field">';
		html += '<input type="checkbox" checked id="cw_asset_include_subaccounts_check" class="cw_show_column k-checkbox"></span>';
		html += '<label for="cw_asset_include_subaccounts_check" class="k-checkbox-label">' + lang.INCLUDE_SUBACCOUNTS + '</label>';
		html += '</div>';
		html += '<div class="cw_separator">';
		html += '<span class="cw_separator_title">' + lang.FILTERING + '</span>';
		html += '<div class="left w100" id="cw_assetgroups"></div>';
		html += '</div>';
		html +='<div class="cw_separator">';
		html += '<span class="cw_separator_title">' + lang.TAGS + '</span>';
		html += '<div class="w100 left">';
		html += '<div class="cw_checkoxes_with_labels"><input type="checkbox" id="cw_untagged" class="k-checkbox" /><label for="cw_untagged" class="k-checkbox-label">' + lang.SHOW_UNTAGGED + '</label></div></div>';
		html += '<div class="left w100" id="cw_tags"></div>';
		html += '</div>'

		html += '<ul class="cw_list_columns" style="margin-top: 15px">';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_head">' + lang.widget.COLUMN_VISIBILITY + '</div>';
		html += '</li>';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_ass_state" class="cw_show_column k-checkbox"><label for="cw_ass_state" class="k-checkbox-label">' + lang.widget.STATUS + '</label>';
		html += '</div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_ass_name" class="cw_show_column k-checkbox"><label for="cw_ass_name" class="k-checkbox-label">' + lang.NAME + '</label>';
		html += '</div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_ass_account" class="cw_show_column k-checkbox"><label for="cw_ass_account" class="k-checkbox-label">' + lang.ACCOUNT + '</label>';
		html += '</div>';
		html += '</li>';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_ass_target" class="cw_show_column k-checkbox"><label for="cw_ass_target" class="k-checkbox-label">' + lang.TARGET + '</label>';
		html += '</div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_ass_incident" class="cw_show_column k-checkbox"><label for="cw_ass_incident" class="k-checkbox-label">' + lang.service.INCIDENT + '</label>';
		html += '</div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_ass_reason" class="cw_show_column k-checkbox"><label for="cw_ass_reason" class="k-checkbox-label">' + lang.REASON + '</label>';
		html += '</div>';
		html += '</li>';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_ass_sq_metrics" class="cw_show_column k-checkbox"><label for="cw_ass_sq_metrics" class="k-checkbox-label">' + lang.SQ_METRICS + '</label>';
		html += '</div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_ass_monitor_metrics" class="cw_show_column k-checkbox"><label for="cw_ass_monitor_metrics" class="k-checkbox-label">' + lang.MONITOR_METRICS + '</label>';
		html += '</div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_ass_external_metrics" class="cw_show_column k-checkbox"><label for="cw_ass_external_metrics" class="k-checkbox-label">' + lang.EXTERNAL_METRICS + '</label>';
		html += '</div>';
		html += '</li>';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_ass_ack" class="cw_show_column k-checkbox"><label for="cw_ass_ack" class="k-checkbox-label">' + lang.service.ACKNOWLEDGED + '</label>';
		html += '</div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_ass_ack_by" class="cw_show_column k-checkbox"><label for="cw_ass_ack_by" class="k-checkbox-label">' + lang.incidents.ACKNOWLEDGED_BY + '</label>';
		html += '</div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_ass_ack_date" class="cw_show_column k-checkbox"><label for="cw_ass_ack_date" class="k-checkbox-label">' + lang.incidents.TIME_OF_FLAG + '</label>';
		html += '</div>';
		html += '</li>';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_ass_tags" class="cw_show_column k-checkbox"><label for="cw_ass_tags" class="k-checkbox-label">' + lang.TAGS + '</label>';
		html += '</div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_ass_external_type" class="cw_show_column k-checkbox"><label for="cw_ass_external_type" class="k-checkbox-label">' + lang.assethealth.EXTERNAL_TYPE + '</label>';
		html += '</div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_ass_last_update" class="cw_show_column k-checkbox"><label for="cw_ass_last_update" class="k-checkbox-label">' + lang.assethealth.LAST_UPDATE + '</label>';
		html += '</div>';
		html += '</li>';

		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_ass_information" class="cw_show_column k-checkbox"><label for="cw_ass_information" class="k-checkbox-label">' + lang.INFORMATION + '</label>';
		html += '</div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_ass_system_type" class="cw_show_column k-checkbox"><label for="cw_ass_system_type" class="k-checkbox-label">' + lang.account.SYSTEM_TYPE + '</label>';
		html += '</div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_ass_type" class="cw_show_column k-checkbox"><label for="cw_ass_type" class="k-checkbox-label">' + lang.account.ASSET_TYPE + '</label>';
		html += '</div>';
		html += '</li>';

		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_ass_description" class="cw_show_column k-checkbox"><label for="cw_ass_description" class="k-checkbox-label">' + lang.DESCRIPTION + '</label>';
		html += '</div>';

		var monitorNames = Configuration.installedMonitorsNames;
		var currentMonitor, currentMonitorName;
		let currentIndex = 1;
		//append the dynamic monitor columns
		for (var i = 0; i < Configuration.installedMonitors.length; i++) {
			currentMonitor = Configuration.installedMonitors[i];
			currentMonitorName = monitorNames[currentMonitor];
			if (currentIndex % 3 === 0) {
				var rowStartCount = currentIndex;
				html += '<li class="cw_list_row">';
			}
			html += '<div class="cw_list_item dashboard-form-list-item cw_dynamic_monitor_item"><input type="checkbox" checked id="' + currentMonitor + '" class="cw_show_column k-checkbox"><label for="' + currentMonitor + '" class="k-checkbox-label">' + currentMonitorName + '</label>';
			html += '</div>';
			if (currentIndex === rowStartCount + 2) {
				html += '</li>';
			}
			currentIndex++;
		}

		this.widgetConfigurationFormEl.empty().append(html);

		//fix border-right issue
		var lastListRow = $('.cw_list_row').last();
		if (lastListRow.find('.cw_list_item').length < 3) {
			lastListRow.css('border-right', '1px solid #d8d8d8');
		}

		if (widgetObj.configuration.columns) {
			//on edit reset all and set only if in configuration
			$('.cw_show_column').prop('checked', false);

			if (widgetObj.configuration.columns.assetIndicator && !widgetObj.configuration.columns.assetIndicator.hidden) {
				$('#cw_ass_state').prop('checked', true);
			}
			if (widgetObj.configuration.columns.name && !widgetObj.configuration.columns.name.hidden) {
				$('#cw_ass_name').prop('checked', true);
			}
			if (widgetObj.configuration.columns.accountName && !widgetObj.configuration.columns.accountName.hidden) {
				$('#cw_ass_account').prop('checked', true);
			}
			if (widgetObj.configuration.columns.targets && !widgetObj.configuration.columns.targets.hidden) {
				$('#cw_ass_target').prop('checked', true);
			}
			if (widgetObj.configuration.columns.numberOfIncidents && !widgetObj.configuration.columns.numberOfIncidents.hidden) {
				$('#cw_ass_incident').prop('checked', true);
			}
			if (widgetObj.configuration.columns.reasonsPercent && !widgetObj.configuration.columns.reasonsPercent.hidden) {
				$('#cw_ass_reason').prop('checked', true);
			}
			if (widgetObj.configuration.columns.serviceQualifierCount && !widgetObj.configuration.columns.serviceQualifierCount.hidden) {
				$('#cw_ass_sq_metrics').prop('checked', true);
			}
			if (widgetObj.configuration.columns.monitorMetricCount && !widgetObj.configuration.columns.monitorMetricCount.hidden) {
				$('#cw_ass_monitor_metrics').prop('checked', true);
			}
			if (widgetObj.configuration.columns.externalMetricCount && !widgetObj.configuration.columns.externalMetricCount.hidden) {
				$('#cw_ass_external_metrics').prop('checked', true);
			}
			if (widgetObj.configuration.columns.acknowledged && !widgetObj.configuration.columns.acknowledged.hidden) {
				$('#cw_ass_ack').prop('checked', true);
			}
			if (widgetObj.configuration.columns.acknowledgedBy && !widgetObj.configuration.columns.acknowledgedBy.hidden) {
				$('#cw_ass_ack_by').prop('checked', true);
			}
			if (widgetObj.configuration.columns.acknowledgeDate && !widgetObj.configuration.columns.acknowledgeDate.hidden) {
				$('#cw_ass_ack_date').prop('checked', true);
			}
			if (widgetObj.configuration.columns.tags && !widgetObj.configuration.columns.tags.hidden) {
				$('#cw_ass_tags').prop('checked', true);
			}
			if (widgetObj.configuration.columns.externalIdentifiers && !widgetObj.configuration.columns.externalIdentifiers.hidden) {
				$('#cw_ass_external_type').prop('checked', true);
			}
			if (widgetObj.configuration.columns.modified && !widgetObj.configuration.columns.modified.hidden) {
				$('#cw_ass_last_update').prop('checked', true);
			}
			if (widgetObj.configuration.columns.information && !widgetObj.configuration.columns.information.hidden) {
				$('#cw_ass_information').prop('checked', true);
			}
			if (widgetObj.configuration.columns.systemType && !widgetObj.configuration.columns.systemType.hidden) {
				$('#cw_ass_system_type').prop('checked', true);
			}
			if (widgetObj.configuration.columns.assetType && !widgetObj.configuration.columns.assetType.hidden) {
				$('#cw_ass_type').prop('checked', true);
			}
			if (widgetObj.configuration.columns.description && !widgetObj.configuration.columns.description.hidden) {
				$('#cw_ass_description').prop('checked', true);
			}

			for (var j = 0; j < Configuration.installedMonitors.length; j++) {
				var currentMonitor = Configuration.installedMonitors[j];
				if (widgetObj.configuration.columns[currentMonitor] && !widgetObj.configuration.columns[currentMonitor].hidden) {
					$('#' + currentMonitor).prop('checked', true);
				}
			}
		}
		if (widgetObj.configuration.includeSubaccounts === undefined || widgetObj.configuration.includeSubaccounts === true) {
			$('#cw_asset_include_subaccounts_check').prop('checked', true);
		} else {
			$('#cw_asset_include_subaccounts_check').prop('checked', false);
		}

		this.initKendoComponents();

		const requiredFields = ['#cw_grid_asset_accountid'];
		applyRequired(requiredFields);

		if (widgetObj.configuration.showUntagged) {
			$('#cw_untagged').prop('checked', true);
		}

		this.removeListeners();
		this.attachListeners();
	},
	/**
	 * Removes listeners
	 */
	removeListeners: function () {
		$('#cw_asset_include_subaccounts_check').off();
	},
	/**
	 * Attaches listeners
	 */
	attachListeners: function () {
		$('#cw_asset_include_subaccounts_check').on('click', $.proxy(this.onIncludeSubaccountsToggle, this));
	},
	/**
	 * Iniitalizes kendo components
	 */
	initKendoComponents: function () {
		this.tagsList = this.widgetObj.configuration.tags || [];
		this.assetGroupsList = this.widgetObj.configuration.assetGroups || [];
		this.createAccountComponent({
			element: $('#cw_grid_asset_accountid'),
			container: $('#cw_grid_asset_account'),
			avoidDefaultAccount: true,
			events: {
				change: $.proxy(async function () {
					this.assetGroupsList.length = 0;
					this.tagsList.length = 0;
					await this.setAssetGroupDataSource();
					this.setTagsDataSource();
				}, this),
				dataBound: $.proxy(async function () {
					await this.setAssetGroupDataSource();
					this.setTagsDataSource();
				}, this)
			}
		});
	},
	/**
	 * Handler function for setting the data source to asset group multiselect depending of what account is selected
	 * @param {Boolean} assignValue Flag which shows if only loads the data to select or also assign values to it
	 */
	setAssetGroupDataSource: async function () {
		const selectedAccount = $('#cw_grid_asset_accountid').data('kendoComboBox').value();
		if (!selectedAccount) {
			return;
		}
		const includeSubaccounts = $('#cw_asset_include_subaccounts_check').is(':checked');
		const url = Settings.serverPath + 'accounts/' + selectedAccount + '/assetGroups?includeSubaccounts=' + includeSubaccounts;
		const result = await Api.fetch(url);
		this.assetGroups = result;
		ReactDOM.render(<FormEntry style={{maxWidth: '600px'}} label={lang.ASSET_GROUPS} vertical>
			<AntSelect
				dataList={this.assetGroups}
				mode={"multiple"}
				sortValues={true}
				onChange={value => this.onAssetGroupsChange(value)}
				defaultValue={this.assetGroupsList}
				placeholder={this.assetGroups.length ? lang.account.messages.ASSET_GROUP_CHOOSE : lang.account.messages.ASSET_GROUP_NO_GROUPS} />
		</FormEntry>,  $('#cw_assetgroups').get()[0]);
	},

	onAssetGroupsChange: function(value) {
		this.assetGroupsList = value || [];
	},
	/**
	 * Handler function for setting the data source to tags multiselect depending of what account is selected
	 * @param {Boolean} assignValue Flag which shows if only loads the data to select or also assign values to it
	 */
	setTagsDataSource: function (assignValue) {
		const selectedAccount = $('#cw_grid_asset_accountid').data('kendoComboBox').value();
		const includeSubaccounts = $('#cw_asset_include_subaccounts_check').is(':checked');
		ReactDOM.render(<FormEntry style={{maxWidth: '600px'}} label={lang.TAGS} vertical>
			<TagsSelect
				mode={'tags'}
				accountId={selectedAccount}
				includeSubaccounts={includeSubaccounts}
				onChange={value => this.onTagsChange(value)}
				defaultValue={this.tagsList} />
		</FormEntry>, $('#cw_tags').get()[0]);
	},
	onTagsChange(value) {
		this.tagsList = value || [];
	},
	/**
	 * Handler function for toggling on/off the include subaccounts checkbox
	 */
	onIncludeSubaccountsToggle: async function () {
		this.tagsList.length = 0;
		this.assetGroupsList.length= 0;
		await this.setAssetGroupDataSource();
		this.setTagsDataSource();
	},
	/*
	 * Handler function for getting the widget configuration values
	 * */
	getValues: function () {
		var title = $('.cw_widget_title').val().trim();
		var assStateCheck = $('#cw_ass_state'),
			assNameCheck = $('#cw_ass_name'),
			assAccountCheck = $('#cw_ass_account'),
			assTargetCheck = $('#cw_ass_target'),
			assIncidentCheck = $('#cw_ass_incident'),
			assReasonCheck = $('#cw_ass_reason'),
			assSqMetrics = $('#cw_ass_sq_metrics'),
			assMonitorMetrics = $('#cw_ass_monitor_metrics'),
			assExternalMetrics = $('#cw_ass_external_metrics'),
			assAcknowledgedCheck = $('#cw_ass_ack'),
			assAknowledgedByCheck = $('#cw_ass_ack_by'),
			assAcknowledgedDate = $('#cw_ass_ack_date'),
			assTags = $('#cw_ass_tags'),
			assExternalType = $('#cw_ass_external_type'),
			assLastUpdate = $('#cw_ass_last_update'),
			assInformation = $('#cw_ass_information'),
			assSystemTypeCheck = $('#cw_ass_system_type'),
			assTypeCheck = $('#cw_ass_type'),
			assDescriptionCheck = $('#cw_ass_description');

		var widgetObj = {
			id: this.mode === 'update' || this.wasUpdate ? this.widgetObj.id : Utils.guid(),
			type: 'grid_asset',
			title: title ? title : lang.ASSETS,
			configuration: {
				accountId: $('#cw_grid_asset_accountid').data('kendoComboBox').value(),
				includeSubaccounts: $('#cw_asset_include_subaccounts_check').is(':checked'),
				columns: {
					assetIndicator: {
						hidden: assStateCheck.is(':not(:checked)')
					},
					name: {
						hidden: assNameCheck.is(':not(:checked)')
					},
					accountName: {
						hidden: assAccountCheck.is(':not(:checked)')
					},
					targets: {
						hidden: assTargetCheck.is(':not(:checked)')
					},
					numberOfIncidents: {
						hidden: assIncidentCheck.is(':not(:checked)')
					},
					reasonsPercent: {
						hidden: assReasonCheck.is(':not(:checked)')
					},
					serviceQualifierCount: {
						hidden: assSqMetrics.is(':not(:checked)')
					},
					monitorMetricCount: {
						hidden: assMonitorMetrics.is(':not(:checked)')
					},
					externalMetricCount: {
						hidden: assExternalMetrics.is(':not(:checked)')
					},
					acknowledged: {
						hidden: assAcknowledgedCheck.is(':not(:checked)')
					},
					acknowledgedBy: {
						hidden: assAknowledgedByCheck.is(':not(:checked)')
					},
					acknowledgeDate: {
						hidden: assAcknowledgedDate.is(':not(:checked)')
					},
					tags: {
						hidden: assTags.is(':not(:checked)')
					},
					externalIdentifiers: {
						hidden: assExternalType.is(':not(:checked)')
					},
					modified: {
						hidden: assLastUpdate.is(':not(:checked)')
					},
					information: {
						hidden: assInformation.is(':not(:checked)')
					},
					systemType: {
						hidden: assSystemTypeCheck.is(':not(:checked)')
					},
					assetType: {
						hidden: assTypeCheck.is(':not(:checked)')
					},
					description: {
						hidden: assDescriptionCheck.is(':not(:checked)')
					}
				},
				tags: this.tagsList,
				showUntagged: $('#cw_untagged').is(':checked'),
				assetGroups: this.assetGroupsList
			}
		};
		$('.cw_dynamic_monitor_item').each(function () {
			var input = $(this).find('input');
			var itemId = input.attr('id');
			widgetObj.configuration.columns[itemId] = {
				hidden: input.is(':not(:checked)')
			}
		});

		return widgetObj;
	},
	/*
	 * Checks if form is valid
	 * */
	isValid: function () {
		var valid = true;
		var validationMessage = '';

		var accountCombo = $('#cw_grid_asset_accountid').data('kendoComboBox');
		if (!Utils.isGuid(accountCombo.value())) {
			valid = false;
			validationMessage = lang.widget.SELECT_ACCOUNT;
		}

		return {
			valid: valid,
			validationMessage: validationMessage
		};
	}
});
