module.exports = (function(H){
    H.Tooltip.prototype.refresh = function (pointOrPoints, mouseEvent) {
        var tooltip = this,
            label,
            options = tooltip.options,
            x,
            y,
            point = pointOrPoints,
            anchor,
            textConfig = {},
            pointConfig = [],
            formatter = options.formatter || tooltip.defaultFormatter,
            shared = tooltip.shared,
            currentSeries;

        if (!options.enabled) {
            return;
        }

        clearTimeout(this.hideTimer);

        // get the reference point coordinates (pie charts use tooltipPos)
        tooltip.followPointer = H.splat(point)[0].series.tooltipOptions
            .followPointer;
        anchor = tooltip.getAnchor(point, mouseEvent);
        x = anchor[0];
        y = anchor[1];

        // shared tooltip, array is sent over
        if (shared && !(point.series && point.series.noSharedTooltip)) {
            H.each(point, function (item) {
                item.setState('hover');

                pointConfig.push(item.getLabelConfig());
            });

            textConfig = {
                x: point[0].category,
                y: point[0].y
            };
            textConfig.points = pointConfig;
            point = point[0];

            // single point tooltip
        } else {
            textConfig = point.getLabelConfig();
        }
        this.len = pointConfig.length; // #6128

        var textPromise = formatter.call(textConfig, tooltip);

        //the call might return plain text and not deferred object
        //since we added deferred only to our callback.
        //So we check if it is text and wrap it if needed
        if( !textPromise.done ){
            var text = textPromise;
            textPromise = $.Deferred();
            textPromise.resolve(text);
        }


        // register the current series
        currentSeries = point.series;
        this.distance = H.pick(currentSeries.tooltipOptions.distance, 16);

        textPromise.done($.proxy(function( text){
            // update the inner HTML
            if (text === false) {
                this.hide();
            } else {

                label = tooltip.getLabel();

                // show it
                if (tooltip.isHidden) {
                    label.attr({
                        opacity: 1
                    }).show();
                }

                // update text
                if (tooltip.split) {
                    this.renderSplit(text, splat(pointOrPoints));
                } else {

                    // Prevent the tooltip from flowing over the chart box (#6659)

                    if (!options.style.width) {

                        label.css({
                            width: this.chart.spacingBox.width
                        });

                    }


                    label.attr({
                        text: text && text.join ? text.join('') : text
                    });

                    // Set the stroke color of the box to reflect the point
                    label.removeClass(/highcharts-color-[\d]+/g)
                        .addClass(
                            'highcharts-color-' +
                            H.pick(point.colorIndex, currentSeries.colorIndex)
                        );


                    label.attr({
                        stroke: (
                            options.borderColor ||
                            point.color ||
                            currentSeries.color ||
                            '#666666'
                        )
                    });


                    tooltip.updatePosition({
                        plotX: x,
                        plotY: y,
                        negative: point.negative,
                        ttBelow: point.ttBelow,
                        h: anchor[2] || 0
                    });
                }

                this.isHidden = false;
            }
        },this));

    }
});