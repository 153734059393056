import React, {memo} from 'react';
import {ToolbarItemPosition} from 'controls/react/layout';
import DocumentationAPI from "./documentationApi";
import Api from 'tools/api';
import {translator} from 'core/localization/localization';
import classnames from 'classnames';

let b = require('b_').with('icon-button');

const i = translator({
	"Click to view online documentation.": {
		"no": "Klikk for å se online dokumentasjon."
	}
});

export class DocumentationButton extends React.PureComponent{
	constructor(props) {
		super(props);
	}

	render() {
		let iconStyle = {
			'fontSize': '17px'
		};
		const classes = classnames('cw_button_help k-button k-button-icon right', this.props.containerClass);

		return (
			<div className={classes}  title={i('Click to view online documentation.')} onClick={() => this.onClick()} style={this.props.style}>
				<span style={iconStyle} className="glyphicons file-cloud"></span>
			</div>
		);
	}

	async onClick() {
		let url;
		if(this.props.monitorType) {
			url = DocumentationAPI.getMonitorDocumentationUrl(this.props.monitorType);
		}
		if(this.props.type == 'actiontrigger') {
			url = DocumentationAPI.getActionTriggerDocumentationUrl();
		}

		let result = await Api.fetch(url);
		if (result.success) {
			window.open(result.data, '_blank')
		}
	}
};

export default DocumentationButton;
