import Element from './element';
import {translator} from "../../core/localization/localization";
import {indicatorToState} from "tools/states";
import {formatNumber} from "tools/helpers/math";
import {ShowCurrencyMode} from "areas/dashboards/graph-editor-extensions/costDatasource";
import {newGuid} from "tools/guid";
import _ from "lodash";
import {CostValueType} from "areas/cost/models/costValueType";
import {CostBudgetEvent, CostReportEvent, EventType} from "framework/entities/events";
import {toStringTimeSelection} from "controls/react/form/timePeriodSelector";

const i = translator({
	"COST: {0}": {
		"no": "COST: {0}"
	},
});


export class Cost extends Element {

	constructor(designer, cell) {
		super(designer, cell);

		this.costConfig = this.datasource.cost;
	}

	getEntriesToLoad() {
		return [{
			costProfileId: this.costConfig.costProfileId,
			costItemId: this.costConfig.costItemId,
			valueType: this.costConfig.valueType,
			timePeriod: toStringTimeSelection(this.costConfig.timePeriod),
			currency: this.costConfig.currency,
			costModelId: this.costConfig.modelId
		}];
	}

	onEntriesLoaded(entries) {
		this.cost = entries.length ? entries[0] : null;
	}

	updateState() {
		if(this.cost == null)
			return;

		let value = this.getValue()

		if(this.costConfig.showCurrency == ShowCurrencyMode.Symbol) {
			value += ' ' + (this.cost.currencySymbol ?? '')
		}

		if(this.costConfig.showCurrency == ShowCurrencyMode.Text) {
			value += ' ' + (this.cost.currencyText ?? '')
		}

		if(this.costConfig.showCurrency == ShowCurrencyMode.Code) {
			value += ' ' + (this.cost.currencyCode ?? '')
		}

		this.addContentLabel(value);
	}

	getValue(){
		const decimalsNumber = this.costConfig.decimalsNumber || 0;
		return formatNumber(this.cost.value, decimalsNumber);
	}

	getSubscriptionsDirectly() {
		if(this.costConfig.valueType == CostValueType.Budget){
			return [CostBudgetEvent.subscriptionByModel(this.costConfig.modelId, [this.costConfig.costItemId] )];
		}else{
			return [CostReportEvent.subscription(this.costConfig.modelId, toStringTimeSelection(this.costConfig.timePeriod))];
		}
	}

	consumeEvent(event) {
		if (event.eventType != EventType.CostBudget && event.eventType != EventType.CostReport)
			return false;
		return {
			reload: true
		}
	}

	getTooltipInternal(accountName) {
		if (this.cost == null)
			return null;

		let result = this.cost.costProfileName + '/' + this.cost.costModelName + '/' + this.cost.costItemName;

		result += '\n'

		result += this.getValue() + " " + this.cost.currencySymbol

		return result
	}

	redirect() {
		if(!this.cost)
			return;
	}

	empty(){
		return this.costConfig?.costProfileId == null;
	}

	getLabel(){
		if (!this.cost) {
			return null;
		}
		if(this.cell.getDatasource()?.showAsLabel){
			return this.cost.costProfileName + '/' + this.cost.costItemName;
		}

		return null;
	}
}


const INDICATOR_TO_PRIORITY = {
	'OK': 3,
	'WARNING': 2,
	'FAILED': 1
};

const PRIORITY_TO_STATE = [
	'INVALID',
	'ERROR',
	'WARNING',
	'ACTIVE'
];

function indicatorToPriority(indicator) {
	return INDICATOR_TO_PRIORITY[indicator] || 0;
}
