import React from 'react';

import ExtensionsManager from "./graph-editor-extensions/extensionsManager";
import {Designer as DesignerControl} from 'controls/designer/designer';
import {ReactHelpers} from "tools/helpers";
import Utils from 'tools/utils';
import State from 'tools/state';
import {ApplicationsApi} from "areas/application/api";
import {iterateParents} from "controls/designer/utils";

export default class ApplicationDesigner extends React.PureComponent{
	containerRef = React.createRef();

	render(){
		//TODO dirty hack to make canvas fit widget. Have no idea what is wrong and it is 3am day before release. Sorry :)
		const style = this.props.mode == 'viewer' ? {
			height: '381px'
		} : {};

		return <div style={style} ref={this.containerRef}></div>
	}

	componentDidMount(){
		if (!this.props.mode) {
			this.isAccountChanged = Utils.changeAccount(this.props.application.accountId, this.props.application.accountName);
		}

		var config = {
			container: this.containerRef.current,
			mode: 'application',
			userPref: this.props.userSettings,
			connectionArrowsEnabled: false,
			extensionsManager: new ExtensionsManager(),
			features: {
				redirectOnClick: this.props.redirectOnClick === true,
			},
			data: {xml: this.props.application.xml},
			sessionId: this.props.sessionId,
			accountId: this.props.accountId,
			overrides: {
				assetGroup: {
					redirect: (assetGroupElement) => {
						let options = {
							isView: true,
							isFromAssetGroupWidget: true,
							assetGroupNameFilter: assetGroupElement.assetGroups[0].assetGroupName,
							applicationId: this.props.application.id,
							source: 'application'
						};
						State.mainApp.loadModule('AssetGroupSummaryView', '', options);
					}
				},
			},
			onLoaded: designer => {
				if (this.isAccountChanged) {
					this.showAccountChangedStatus();
				}
				this.ui = designer.editorUi;
				this.graph = this.ui.editor.graph;
				this.trackSelectionChange();
				this.props.onLoaded && this.props.onLoaded(designer);

				if(this.props.onCellClicked){
					designer.graph.addListener("CELL_CLICKED", (graph, e) => {
						this.props.onCellClicked(this, e.properties.cell, e.properties.mouseEvent);
					});
				}
			}
		}

		if(this.props.mode == 'preview'){
			config.chromeless = true;
			config.toolbar = false;
			config.padding = this.props.contentPadding;
			config.fit = this.props.fit === false ? false : true;
		}

		if(this.props.mode == 'viewer'){
			config.chromeless = true;
			config.toolbar = false;
			config.padding = 10;
			config.fit = this.props.fit === false ? false : true;
		}

		this.config = config;
		this.designer = new DesignerControl(config);
	}

	componentDidUpdate = (prevProps) => {
		if (prevProps.application !== this.props.application) {
			if(prevProps.application.xml != this.props.application.xml) {
				this.config.data.xml = this.props.application.xml;
				this.designer?.destroy();
				this.designer = new DesignerControl(this.config);
			}
			if(prevProps.application.applicationHI != this.props.application.applicationHI){
				this.designer.statesManager.showStates();
			}
		}
	}

	componentWillUnmount(){
		this.designer?.destroy();
	}

	trackSelectionChange = () => {
		this.graph.getSelectionModel().addListener(mxEvent.CHANGE, () =>
			this.props.onSelectionChanged  && this.props.onSelectionChanged(this.ui));
	}

	getApplicationForSaving(){
		this.designer.cleanUp();
		let application = ReactHelpers.copy(this.props.application);
		application.xml = this.designer.getXml();
		return application;
	}

	showAccountChangedStatus() {
		$('.geEditor').append('<div id="canvas_status" class="status canvas" style="margin-top:60px; "><p></p></div>');
		var status = $('#canvas_status');
		status.find('p').text(lang.designer.messages.ACCOUNT_CONTEXT_CHANGED);
		status.find('p').addClass('warning');
		status.slideDown().delay(2000).slideUp();
	}

	async loadReasonsForCell(cell) {
		const [datasource, cellWithDatasource] = iterateParents(cell,currentCell => currentCell.getDatasource());

		if (!datasource)
			return;

		const severity = cellWithDatasource.getAttribute('severity');

		if (datasource.type == 'assetGroup' && datasource.assetGroups.length > 0) {
			return await ApplicationsApi.getAssetGroupReasons(this.props.application.id, datasource.assetGroups[0].id, severity);
		}

		if (datasource.type == 'asset' && datasource.assets.length > 0) {
			return await ApplicationsApi.getAssetReasons(this.props.application.id, datasource.assets[0].id);
		}
	}

	isDirty(){
		return this.designer.isDirty();
	}
}
