export function prepareInitialForm(state, data, savedValues = {}, persistedValues) {
	const {configuration: {values, warning}} = data;

	persistedValues = persistedValues ? persistedValues.toJS() : {};

	let currentValues = {
		...values,
		...savedValues,
		warning,
	};

	for (const [field, value] of Object.entries(persistedValues)) {
		if (!currentValues[field]) {
			currentValues = {
				...currentValues,
				[field]: value
			}
		}
	}

	return {
		properties: currentValues
	}
};
