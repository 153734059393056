export function getDescriptions() {
	return [
		{
			jspUrl: 'include/AssetHealth/AssetDetails.jsp',
			url: '/assets/:id',
			getModuleImport: () => import('./assetDetails')
		}
	];
}

export class AssetsRouter {
	static details(assetId, tab) {
		let url = '/assets/' + assetId;
		if (tab && tab=='metric') {
			url += '?tabIndex=4';
		}
		return url;
	}
}
