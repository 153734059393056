export function getDescriptions(){
	return [{
		url: '/applications',
		getModuleImport: () => import('./list'),
		react: true
	},{
		url: '/applications/:id',
		getModuleImport: () => import('./details'),
		react: true
	},{
		url: '/applications/:id/designer',
		getModuleImport: () => import('./designer'),
		react: true
	}];
}

export class ApplicationsRouter{
	static list(){
		return '/applications'
	}

	static details(applicationId){
		return '/applications/' + applicationId;
	}

	static designer(applicationId){
		return '/applications/' + applicationId + '/designer';
	}
}
