import Utils from 'tools/utils';
import Widget from 'areas/service-boards/widget';
import Settings from 'settings';

import RemoteEventsManager from 'core/remoteEventsManager';
import Renderer from 'tools/renderer';
import State from 'tools/state';
import Configuration from 'configuration';
import AssetColumns from 'areas/assets/assetColumns';
import GridSearch from 'controls/gridSearch';
import {CeeViewDataSource} from "tools";
import {openAssetConsoleWindow} from "../helpers";
import {getGridStateForSaving, updateHiddenColumns} from "controls/react/kendoWrappers/grid";

export function GridWidget(config) {
	Widget.call(this, config);

	this.requestPath = Settings.serverPath;
	if (this.sessionId) {
		this.requestPath = Settings.serverPath + 'sessions/' + this.sessionId + '/';
	}
}

export {GridWidget as default};

jQuery.extend(GridWidget.prototype, Widget.prototype, {
	/**
	 * Main init function
	 */

	init: function(){
		this.configuration.includeSubaccounts = this.configuration.includeSubaccounts || false;
		if (!Configuration.installedMonitors.length) {
			var url = this.requestPath + 'monitors/types';
			Utils.ajax(url, 'GET', {}, $.proxy(function (result) {
				for (var i = 0; i < result.length; i++) {
					Configuration.installedMonitors.push(result[i].value);
					Configuration.installedMonitorsNames[result[i].value] = result[i].text;
					Configuration.installedMonitorSettings[result[i].value] = result[i];
				}
				this.initComponent();
			}, this));
		} else {
			this.initComponent();
		}
	},

	initComponent: function () {
		var widgetContentDiv = $('#' + this.id).find('.cw_section_content');
		widgetContentDiv.parent().addClass('cw_section_assetgrid');
		this.gridId = Utils.guid();
		widgetContentDiv.empty().append('<div id="' + this.gridId + '" class="" ></div>');
		this.gridMessages = {
			clear: lang.CLEAR,
			info: lang.grid.filter.SHOW_ITEMS,
			filter: lang.FILTER
		};

		this.subscribe();
		this.updateTitle();
		$('#' + this.gridId).off();
		if (!this.sessionId) {
			if (State.mainApp && State.mainApp.session.hasRole('MONITOR_READ') && State.currentApp?.dashboardDesigner?.props?.mode !== 'designer') {
				$('#' + this.gridId).on('click', '.cw_pillbox', $.proxy(this.onIndexClick, this));
			}
			$('#' + this.gridId).on('click', '.cw_assetconsole_details', $.proxy(this.onOpenAssetConsole, this));
		}
		$(window).off('resize', $.proxy(this.onResize, this));
		$(window).on('resize', $.proxy(this.onResize, this));
		State.gridPosLoaded = false;
	},
	/**
	 * Initializes Kendo components
	 */
	initKendoComponents: function () {
		var filterMessages = lang.grid.filter;
		var gridSort = this.persistedState?.sort || this.configuration.sort || [{
			field: 'assetIndicator',
			dir: 'asc',
			compare: null
		}];
		var gridFilter = this.persistedState?.filter || this.configuration.filter;
		var savedColumns = this.configuration.columns;

		if (savedColumns.externalType) {
			savedColumns.externalIdentifiers = savedColumns.externalType;
			delete savedColumns.externalType;
		}
		if (savedColumns.lastUpdate) {
			savedColumns.modified = savedColumns.lastUpdate;
			delete savedColumns.lastUpdate;
		}

		savedColumns = updateHiddenColumns(this.persistedState?.columns, savedColumns);

		var url = this.requestPath + 'accounts/' + this.configuration.accountId + '/health/assets?includeSubaccounts=' + this.configuration.includeSubaccounts;
		this.assetsDataSource = new CeeViewDataSource({
			transport: {
				read: {
					url: $.proxy(function () {
						return url;
					}, this),
					contentType: "application/json; charset=utf-8",
					type: 'POST',
					dataType: 'json',
					cache: false
				},
				parameterMap: $.proxy(function (data, type) {
					if (data.filter?.filters) {
						data.filter.filters = Utils.changeDateFilterToString(data.filter.filters);
					}
					data.ignoreUserTags = true;
					data.showUntagged = this.configuration.showUntagged;

					let tags = this.configuration.tags;
					let tagsArray = [];
					if (tags) {
						for (let i = 0; i < tags.length; i++) {
							if (tags[i].name) {
								tagsArray.push(tags[i].name);
							} else if (typeof tags[i] === 'string') {
								tagsArray.push(tags[i]);
							}
						}
					}
					this.configuration.tags = tagsArray;
					data.tags = this.configuration.tags;

					data.assetGroupIds = this.configuration.assetGroups;
					return kendo.stringify(data);
				}, this)
			},
			pageSize: 100,
			schema: {
				model: {
					id: 'id',
					fields: {
						numberOfIncidents: {
							type: 'number'
						},
						serviceQualifierCount: {
							type: 'number'
						},
						monitorMetricCount: {
							type: 'number'
						},
						externalMetricCount: {
							type: 'number'
						},
						acknowledged: {
							type: 'boolean'
						},
						reasonsPercent: {
							type: 'number'
						}
					}
				},
				data: function (result) {
					var data = result.items;
					for (var i = 0; i < data.length; i++) {
						if (data[i].acknowledgedBy) {
							data[i].acknowledgedMouseover = lang.ACKNOWLEDGED_BY + ' ' + data[i].acknowledgedBy;
						} else {
							data[i].acknowledgedMouseover = '';
						}
					}
					return AssetColumns.parseData(data);
				},
				total: function (response) {
					this.visibleItems = response.visible;
					this.totalItems = response.total;
					return response.visible;
				}
			},
			sort: gridSort,
			filter: gridFilter,
			serverSorting: true,
			serverPaging: true,
			serverFiltering: true,
			change: $.proxy(function (e) {
				if (e.items.length === 0) {
					if ($('#' + this.gridId).find('.status').length) {
						$('#' + this.gridId).find('.status').remove();
					}
				} else {
					if ($('#' + this.gridId).find('.status').length) {
						$('#' + this.gridId).find('.status').remove();
					}
					$('#' + this.gridId).find('.k-grid-content').removeClass('hide');
				}
			}, this)
		});

		this.hasOwnAccountPref = true;
		this.grid = $('#' + this.gridId).kendoCustomGrid({
			dataSource: this.assetsDataSource,
			cacheScrollCheckboxes: true,
			scrollable: {
				virtual: true
			},
			reorderable: true,
			resizable: true,
			scrollable: true,
			height: this.isDashboardMode() ? '100%' : null,
			selectable: 'row',
			sortable: {
				mode: 'multiple',
				allowUnsort: true
			},
			filterable: {
				extra: false,
				operators: {
					string: {
						startswith: filterMessages.STARTS_WITH,
						neq: filterMessages.NEQ,
						eq: filterMessages.EQ,
						contains: filterMessages.CONTAINS
					},
					number: {
						gte: filterMessages.GTE,
						gt: filterMessages.GT,
						lte: filterMessages.LTE,
						lt: filterMessages.LT
					}
				},
				messages: this.gridMessages
			},
			columns: AssetColumns.get(this, savedColumns, this.assetsDataSource, $('#' + this.gridId)),
			columnMenu: true,
			change: $.proxy(this.onRowExpand, this),
			dataBound: $.proxy(function() {
				let widgetContentDiv = $('#' + this.id).find('.cw_section_content');
				widgetContentDiv.css('overflow', 'hidden');
				if (!this.isGridRefreshed) {
					setTimeout($.proxy(function() {
						this.grid.refresh();
						this.isGridRefreshed = true;
					}, this), 0)
				}
				setTimeout(() => this.adjustSectionHeight(this.id), 200);
			}, this)
		}).data('kendoCustomGrid');
		// Add Kendo tooltip to the header of the columns

		$('.k-virtual-scrollable-wrap').scroll(function () {
			State.dynamicGridPos = $('#' + this.gridId).data('kendoCustomGrid').wrapper.find(".k-scrollbar").scrollTop();
		});

		Utils.gridColumnHeaderTooltip(this.grid);
		this.grid.thead.find("[data-field='acknowledged']>.k-header-column-menu").remove();
		this.grid.thead.find("[data-field='id']>.k-header-column-menu").remove();

		let searchBox = $('#' + this.id).find('.cw_search_box');
		let searchId = searchBox.attr('id');
		this.gridSearch = new GridSearch({
			id: searchId,
			value: this.configuration.searchValue || '',
			dataSource: this.assetsDataSource,
			fields: ['name', 'TargetsList', 'systemType', 'assetType', 'acknowledgedBy', 'description', 'information']
		});
	},
	/**
	 * Creates the multiselect filter component for monitor fields
	 * @param {Object} element The DOM element where the multiselect is rendered
	 */
	getFilterableForMonitor: function (element) {
		var menu = $(element).parent();
		menu.find(".k-filter-help-text").text(lang.assethealth.messages.SELECT_ASSETS);
		//menu.find("[data-role=dropdownlist]").remove();
		element.removeAttr("data-bind");
		var operatorEl = menu.find("[data-role=dropdownlist]");
		operatorEl.find('option[value="contains"]').remove();
		operatorEl.find('option[value="startswith"]').remove();
		operatorEl.find('option[value="eq"]').text(lang.grid.filter.ISIN);
		operatorEl.find('option[value="neq"]').text(lang.grid.filter.ISNOTIN);
		operatorEl.attr('data-index', '1');
		var multiSelect = element.kendoSortedMultiSelect({
			dataSource: [{
				text: lang.CRITICAL,
				color: 1,
				value: '3'
			}, {
				text: lang.MAJOR,
				color: 2,
				value: '2'
			}, {
				text: lang.MINOR,
				color: 3,
				value: '1'
			}, {
				text: lang.OK,
				color: 5,
				value: '0'
			}, {
				text: lang.UNAVAILABLE,
				value: '6',
				color: 6
			}],
			dataTextField: 'text',
			dataValueField: 'value',
			optionLabel: lang.grid.FILTER_SELECT_VALUE,
			itemTemplate: '<span class="cw_status_indicator cw_status_widget_color cw_color#=data.color#"></span><span>${data.text}</span>',
			tagTemplate: '<span class="cw_status_indicator cw_status_widget_color cw_color#=data.color#"></span><span>${data.text}</span>'
		}).data('kendoSortedMultiSelect');
		menu.find('[type=submit]').on('click', {
			widget: multiSelect,
			operatorElement: operatorEl
		}, $.proxy(this.scope.filterByMonitorIndicator, {
			scope: this.scope,
			dataSource: this.scope.assetsDataSource,
			field: this.field
		}));
		menu.find('[type=reset]').on('click', $.proxy(function (e) {
			multiSelect.value([]);
		}, this));
		//fix for default value
		setTimeout(function () {
			operatorEl.data('kendoDropDownList').select(1);
		}, 100);
	},
	/**
	 * Filters the datasource based on multiselect filter
	 * @param {Object} e The kendo object
	 */
	filterByMonitorIndicator: function (e) {
		var indicators = e.data.widget.value();
		var operator = e.data.operatorElement.data('kendoDropDownList').value();
		var logic = (operator === 'eq') ? 'or' : 'and';
		var currentFilter = this.dataSource.filter();
		var filter = {logic: logic, filters: []};
		for (var i = 0; i < indicators.length; i++) {
			filter.filters.push({field: this.field, operator: operator, value: indicators[i]});
		}
		if (currentFilter && currentFilter.filters) {
			for (var i = 0; i < currentFilter.filters.length; i++) {
				if (currentFilter.filters[i].filters) {
					for (var j = 0; j < currentFilter.filters[i].filters.length; j++) {
						if (currentFilter.filters[i].filters[j].field === this.field) {
							currentFilter.filters[i].filters.splice(j, 1);
							j--;
						}
					}
				}
				if (currentFilter.filters[i].field === this.field) {
					currentFilter.filters.splice(i, 1);
					i--;
				}
			}
			if (filter.filters.length) {
				currentFilter.filters.push(filter);
			}
			this.dataSource.filter(currentFilter);
		} else {

			this.dataSource.filter(filter);
		}
	},
	/**
	 * Called when multiple events are received from the server
	 */
	onEvent: function () {
		this.refresh();
	},
	/**
	 * Handler function for the change(select) event on the grid
	 * @param {Object} e The change event object
	 */
	onRowExpand: function (e) {
		var selectedRow = $(e.sender.select());
		selectedRow.find('.expand').toggleClass('ellipsis');
	},
	/**
	 * Handler function for the click event on the status component
	 * @param {Object} e The click event object
	 */
	onIndexClick: function (e) {
		if(!this.redirectConfig?.doDefaultRedirect)
			return;

		var monitorId = [];
		var assetUid = $(e.currentTarget).closest('tr').attr('data-uid');
		var monitorType = $(e.currentTarget).attr('data-type');
		var record = this.assetsDataSource.getByUid(assetUid);
		for (var i = 0; i < record.monitors.length; i++) {
			if (record.monitors[i].type === monitorType) {
				monitorId.push(record.monitors[i].id);
			}
		}

		State.mainApp?.loadModule('Reasons', '', {
			assetId: record.id,
			assetName: record.name,
			assetTargets: Renderer.assetTargets(record.targets),
			monitorType: monitorType,
			monitorId: monitorId,
			isAgentAlive: record.isAgentAlive
		}, null, null, this.redirectConfig?.defaultRedirectInNewTab);
	},
	/**
	 * Updates the widget title
	 */
	updateTitle: function () {
		if (!this.title) {
			//var titleSpan = $('#' + this.id).find('.cw_section_title');
			var titleSpan = this.isKendoWindow ? $('#' + this.id).closest('.k-window').find('.k-window-title') : $('#' + this.id).find('.cw_section_title');
			titleSpan.text(lang.ASSETS);
		}
	},
	/**
	 * Triggered after widget resize
	 * @param {Object} event The resize event
	 * @param {Object} ui The UI element - see http://api.jqueryui.com/resizable/
	 */
	onResize: function (event, ui) {
		// TODO the resize functionality
		this.adjustSectionHeight(this.id);
	},
	/**
	 * Handler function for open asset console button
	 * @param {Object} e The click event object
	 */
	onOpenAssetConsole: function (e) {
		var target = $(e.currentTarget), row = target.closest('tr'),
			item = $('#' + this.gridId).data('kendoCustomGrid').dataItem(row),
			assetId = item.id, assetName = item.name, assetDescription = item.description || '',
			accountId = item.accId;
		var monitorType = target.parent().attr('data-type');
		for (var i = 0; i < item.monitors.length; i++) {
			if (item.monitors[i].type === monitorType) {
				var monitorId = item.monitors[i].id;
			}
		}
		switch (monitorType) {
			case 'SYSTEM':
				this.assetConsoleWindow = openAssetConsoleWindow({
					type: monitorType,
					title: lang.widget.SYSTEM_MONITOR_CONSOLE,
					assetId: assetId,
					monitorId: monitorId,
					accountId: accountId
				});
				break;
			case 'IBMSVC':
				this.assetConsoleWindow = openAssetConsoleWindow({
					type: monitorType,
					title: lang.widget.ibm.TITLE,
					width: 800,
					height: 626,
					assetId: assetId,
					monitorId: monitorId,
					assetName: assetName,
					assetDescription: assetDescription,
					accountId: accountId
				});
				break;
		}
		e.stopPropagation();
	},
	/**
	 * Filters the grid based on the indicator multiselect filter
	 * @param {Object} e
	 */
	filterByIndicator: function (e) {
		var indicators = e.data.widget.value();
		var filter = {logic: 'or', filters: []};
		for (var i = 0; i < indicators.length; i++) {
			filter.filters.push({field: 'assetIndicator', operator: 'eq', value: indicators[i]});
		}
		this.grid.dataSource.filter(filter);
	},
	/**
	 * Refresh the widget
	 */
	refresh: function () {
		this.grid.dataSource.read();
	},
	/**
	 * Adjusts the grid height
	 * @param {String} id the section element id
	 */
	adjustSectionHeight: function (id) {
		let heightAdjust;
		if (this.isDashboardMode() && !this.isWithoutHeader()) {
			heightAdjust = 70;
		} else {
			heightAdjust = 30;
		}
		let section = $('#' + id);
		let sectionHeight = section.height();
		let contentHeight = sectionHeight - heightAdjust;
		section.find('.cw_section_content').css('height', sectionHeight);
		section.find('.k-grid-content-locked').css('height', contentHeight);
		section.find('.k-grid-content').css({
			'height': contentHeight,
			'width': '100%'
		});
		section.find('.k-grid-header-wrap').css('width', '100%');

		let shownContent = section.find('tbody');
		let contentWidth = section.find('.k-grid-content').width();
		let actualContentHeight = section.find('.k-grid-content table').height();
		let shownContentHeight = shownContent.height();
		if (actualContentHeight < shownContentHeight) {
			section.find('.k-scrollbar-vertical').addClass('hide');
			shownContent.css('width', contentWidth + 18 + 'px');
		} else {
			section.find('.k-scrollbar-vertical').removeClass('hide');
		}
	},
	/**
	 * Subscribes to server events
	 */
	subscribe: function () {
		var subscriptionObj = [{
			eventType: 'AssetHealth',
			accountId: this.configuration.accountId,
			includeSubaccounts: this.configuration.includeSubaccounts
		}, {
			eventType: 'Administration',
			actionTypes: ['ASSET_CREATE', 'ASSET_UPDATE', 'ASSET_DELETE'],
			accountId: this.configuration.accountId,
			includeSubaccounts: this.configuration.includeSubaccounts
		}];
		this.subscriberId = this.id;
		RemoteEventsManager.subscribe(this.subscriberId, subscriptionObj);
		this.initKendoComponents();
	},
	/**
	 * Destroy
	 */
	destroy: function () {
		$(window).off('resize', $.proxy(this.onResize, this));
		Widget.prototype.destroy.call(this);
	},

	getStateForSaving(){
		return getGridStateForSaving(this.grid)

	}
});
