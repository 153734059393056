export const SET_QUALIFIER_INFO = "SET_QUALIFIER_INFO";
export const SET_FORM_VALID = "SET_FORM_VALID";
export const SET_QUALIFIER_VALUES = "SET_QUALIFIER_VALUES";
export const LOAD_TEMPLATE = "LOAD_TEMPLATE";
export const SET_QUALIFIER_DATA = "SET_QUALIFIER_DATA";
export const SET_SERVICE_TIMEZONE = "SET_SERVICE_TIMEZONE";
export const LOAD_QUALIFIER = "LOAD_QUALIFIER";
export const SELECT_CATEGORY_ITEM = "SELECT_CATEGORY_ITEM";
export const RESET = "RESET";
export const NEXT_STEP = "NEXT_STEP";
export const BACK_STEP = "BACK_STEP";
export const SET_STEP = "SET_STEP";
export const SET_SERVICE_ID = "SET_SERVICE_ID";
export const SET_ELEMENT_ID = "SET_ELEMENT_ID";
export const SET_QUALIFIER_ID = "SET_QUALIFIER_ID";
export const RESET_STORE = "RESET_STORE";
export const TEST_CONFIGURATION = "TEST_CONFIGURATION";
export const SET_IN_PROGRESS = "SET_IN_PROGRESS";
export const SET_VALIDATION_STATUS = "SET_VALIDATION_STATUS";
export const SET_VALIDATION_DATA = "SET_VALIDATION_DATA";
export const SHOW_FIELD_VALIDATION = "SHOW_FIELD_VALIDATION";
export const SAVE_VALUES = "SAVE_VALUES";
export const SET_QUALIFIER_WINDOW_SIZE = "SET_QUALIFIER_WINDOW_SIZE";
export const SAVE_SIZE_POSITION = "SAVE_SIZE_POSITION";
export const HIDE_VALIDATION = "HIDE_VALIDATION";
export const SHOW_WINDOW = "SHOW_WINDOW";
export const LOAD_WINDOW_SIZE = "LOAD_WINDOW_SIZE";
export const GET_TEST = "GET_TEST";
export const SHOW_QUALIFIER_ERROR = "SHOW_QUALIFIER_ERROR";

export const BEGIN_SAVE_QUALIFIER = "BEGIN_SAVE_QUALIFIER";

export const SAVE_QUALIFIER = "SAVE_QUALIFIER";

export const SET_SHOW_VALIDATION = "SET_SHOW_VALIDATION";

export const SHOW_MULTIPLE_QUALIFIERS_WINDOW = "SHOW_MULTIPLE_QUALIFIERS_WINDOW";

export const SET_ASSET_FORM = "SET_ASSET_FORM";

export const SET_FIHISH_BUTTON_DISABLED = "SET_FIHISH_BUTTON_DISABLED";

export const OPEN_HELP = "OPEN_HELP";

export const PREVALIDATE_COMPLETE = "PREVALIDATE_COMPLETE";
export const PREVALIDATE = "PREVALIDATE";

export function generateAction(name, params) {
    const trace = Error('Stack trace');

    return {
        type: name,
        ...params,
        trace: trace.stack
    }
}
