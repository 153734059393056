import {CeeViewDataSource, DataSourceSeverity} from 'tools';
import ensureThresholdsAreConsistent from 'areas/assets/monitors/system/diskView/ensureThresholdsAreConsistent';
import convertMetricsLabel from 'areas/assets/monitors/system/diskView/convertMetricsLabel';
import translator from 'core/localization';
import gridDataBound from "./onDiskGridDataBound";
import {DISK_VIEW} from "areas/assets/monitors/system/shared";

const i = translator({
	'No shared disks are added. Click here or in menu to add a shared disk.': {
		no: 'Ingen delte disker er lagt til. Klikk her eller i menyen for å legge til en delt disk.'
	},
	'Monitoring is not available for this entry': {
		no: 'Monitorering er ikke tilgjengelig for dette monitorpunktet'
	},
});

export default class DiskDefaultConfigGrid {
	constructor(type, prefix, mode, onAdd) {
		this.prefix = prefix;
		this.mode = mode;
		this.type = type;
		this.onAdd = onAdd;
	}

	create(defaultConfiguration) {
		this.diskDefaultConfigGrid = $(`#cw_${this.prefix}disk_default_grid`).kendoCustomGrid({
			noRecords: {
				template: () => {
					return this.type === DISK_VIEW.SHARED_DISK ?
						`<div class="cw_link shared_disk_no_record">${i('No shared disks are added. Click here or in menu to add a shared disk.')}</div>`:
						'No records available';
				}
			},
			dataSource: new CeeViewDataSource({
				data: defaultConfiguration.metrics,
				sort: {
					field: 'grouping',
					dir: 'desc'
				},
				schema: {
					model: {
						fields: {
							name: {
								editable: false
							},
							grouping: {
								editable: false
							},
							unitTypeSymbol: {
								editable: false
							}
						}
					},
					parse: function (result) {
						for (var i = 0; i < result.length; i++) {
							if (result[i].severity) {
								for (var j = 0; j < DataSourceSeverity.length; j++) {
									if (DataSourceSeverity[j].value === result[i].severity) {
										result[i].severityColor = DataSourceSeverity[j].color;
										result[i].severityText = DataSourceSeverity[j].text;
									}
								}
							}
						}
						return result;
					}
				}
			}),
			resizable: true,
			editable: true,
			sortable: {
				mode: 'multiple',
				allowUnsort: true
			},
			save: (e) => {
				setTimeout(() => {
					ensureThresholdsAreConsistent(e);
				}, 0);
			},
			columns: [{
				field: 'name',
				title: lang.NAME,
				template: function (item) {
					return convertMetricsLabel(item.name);
				},
				width: 250
			}, {
				field: 'grouping',
				title: lang.TYPE,
				width: 150
			}, {
				field: 'unitTypeSymbol',
				title: lang.UNIT,
				width: 100,
				attributes: {
					'title': function (data) {
						return data.unitType || ""
					}
				},
			}, {
				field: 'enabled',
				title: lang.METRIC,
				template: function (data) {
					return data.enabled ? lang.YES : lang.NO;
				},
				width: 100,
				editor: (container, options) => {
					var input = $('<input data-text-field="text" data-value-field="value"/>');
					input.appendTo(container);
					input.kendoDropDownList({
						value: options.model.enabled,
						dataSource: [{
							value: true,
							text: lang.YES
						}, {
							value: false,
							text: lang.NO
						}],
						enable: this.mode !== 'view',
						change: function (e) {
							var item = e.sender.dataItem();
							options.model.set('enabled', item.value);
							if (item.value && options.model.name !== 'DISKSIZE') {
								if (options.model.metricOnly) {
									this.element.closest('td').nextAll('.cw_monitoring_toggle').css({
										'pointer-events': 'initial',
										'background-color': ''
									});
								} else {
									this.element.closest('td').next('.cw_monitoring_toggle').css({
										'pointer-events': 'initial',
										'background-color': ''
									});
								}
							} else {
								this.element.closest('td').nextAll('.cw_monitoring_toggle').css({
									'pointer-events': 'none',
									'background-color': '#e1e1e1'
								});
								options.model.set('metricOnly', false);
							}
						}
					});
				}
			}, {
				field: 'metricOnly',
				title: lang.MONITOR,
				template: item => {
					return item.name === 'DISKSIZE' ? `<span class="cw_not_available">N/A</span>`:
						item.metricOnly ? lang.YES : lang.NO;
				},
				attributes: {
					'class': 'cw_monitoring_toggle'
				},
				width: 100,
				editor: (container, options) => {
					var input = $('<input data-text-field="text" data-value-field="value"/>');
					input.appendTo(container);
					input.kendoDropDownList({
						value: options.model.metricOnly,
						dataSource: [{
							value: true,
							text: lang.YES
						}, {
							value: false,
							text: lang.NO
						}],
						enable: this.mode !== 'view',
						change: function (e) {
							var item = e.sender.dataItem();
							options.model.set('metricOnly', item.value);
							if (item.value) {
								this.element.closest('td').nextAll('.cw_metric_toggle').css({
									'pointer-events': 'initial',
									'background-color': ''
								});
							} else {
								this.element.closest('td').nextAll('.cw_metric_toggle').css({
									'pointer-events': 'none',
									'background-color': '#e1e1e1'
								});
							}
						}
					});
				}
			}, {
				field: 'warning',
				title: lang.WARNING,
				attributes: {
					'id': 'warning',
					'class': 'cw_monitoring_toggle cw_metric_toggle cw_numeric_input'
				},
				template: item => {
					return item.name === 'DISKSIZE' ? `<span class="cw_not_available">N/A</span>` : item.warning;
				},
				width: 100
			}, {
				field: 'critical',
				title: lang.CRITICAL,
				attributes: {
					'id': 'critical',
					'class': 'cw_monitoring_toggle cw_metric_toggle cw_numeric_input'
				},
				template: item => {
					return item.name === 'DISKSIZE' ? `<span class="cw_not_available">N/A</span>` : item.critical;
				},
				width: 100
			}, {
				field: 'projected',
				title: lang.PROJECTED,
				hidden: true,
				template: item => {
					return item.name === 'DISKSIZE' ? `<span class="cw_not_available">N/A</span>` : item.projected;
				},
				attributes: {
					'class': 'cw_monitoring_toggle cw_metric_toggle cw_numeric_input'
				},
				width: 100
			}, {
				field: 'severity',
				title: lang.assethealth.SEVERITY,
				template: item => {
					return item.name === 'DISKSIZE' ? `<span class="cw_not_available">N/A</span>` :
						`<span class="cw_status_widget_color cw_color${item.severityColor}"></span><span>${item.severityText}</span>`;
				},
				attributes: {
					'class': 'cw_monitoring_toggle cw_metric_toggle'
				},
				//width: 150,
				editor: (container, options) => {
					var input = $('<input data-text-field="text" data-value-field="value"/>');
					input.appendTo(container);
					input.kendoDropDownList({
						value: options.model.severity,
						dataSource: DataSourceSeverity,
						enable: this.mode !== 'view',
						template: '<span class="cw_status_indicator cw_status_widget_color cw_color${data.color}"></span><span>${data.text}</span>',
						valueTemplate: '<span class="cw_inline_status_indicator cw_status_widget_color cw_color${data.color}"></span><span>${data.text}</span>',
						change: function (e) {
							var item = e.sender.dataItem();
							options.model.set('severity', item.value);
							options.model.set('severityText', item.text);
							options.model.set('severityColor', item.color);
						}
					});
				}
			}],
			dataBound: function () {
				gridDataBound(this.dataSource);
				$('.cw_not_available').attr('title', i('Monitoring is not available for this entry'))
					.css('pointer-events', 'auto');
			}
		}).data('kendoCustomGrid');

		if (this.diskDefaultConfigGrid) {
			$('.shared_disk_no_record', this.diskDefaultConfigGrid.wrapper).on('click', () => this.onAdd());
		}

		return this.diskDefaultConfigGrid;
	}
}
