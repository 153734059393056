import {Utils, applyRequired} from 'tools/utils';
import Settings from 'settings';

import ErrorHandler from 'core/errorHandler';
import State from 'tools/state';
import ConfigurationBase from 'areas/service-boards/configurationBase'
import {CeeViewDataSource} from "tools";


export function ConsoleWidgetConfiguration(config) {
	Utils.apply(this, config);
	this.initComponent();
};

export {ConsoleWidgetConfiguration as default};

jQuery.extend(ConsoleWidgetConfiguration.prototype, ConfigurationBase.prototype, {
	/**
	 * @cfg {Object} widgetObj
	 */
	/**
	 * Main init function
	 */
	initComponent: function () {
		var html;
		var widgetObj = this.widgetObj;

		html = '<div class="cw_field">';
		html += '<label class="cw_inline">' + lang.TITLE + ': </label>';
		html += '<input type="text" class="cw_widget_title cw_inline" value="' + (widgetObj.title || '') + '" />';
		html += '</div>';
		html += '<div class="cw_field" id="cw_asset_console_account">';
		html += '<label class="cw_inline">' + lang.ACCOUNT + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_asset_console_accountid" type="text" value="' + (widgetObj.configuration.accountId || '') + '" /></div>';
		html += '</div>';
		html += '<div class="cw_field" id="cw_ac_asset">';
		html += '<label class="cw_inline">' + lang.ASSET + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_asset_list" type="text" value="' + (widgetObj.configuration.assetId || '') + '" /></div>';
		html += '</div>';
		html += '<div class="cw_field" id="cw_ac_monitor">';
		html += '<label class="cw_inline">' + lang.MONITOR + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_monitor_list" type="text" value="' + (widgetObj.configuration.monitorId || '') + '" /></div>';
		html += '</div>';

		this.widgetConfigurationFormEl.empty().append(html);

		this.removeListeners();
		this.attachListeners();

		this.initKendoComponents();
		const requiredFields = ['#cw_asset_console_accountid', '#cw_asset_list', '#cw_monitor_list'];
		applyRequired(requiredFields);
	},
	/**
	 * Removes listeners
	 */
	removeListeners: function () {

	},
	/**
	 * Attaches listeners
	 */
	attachListeners: function () {

	},
	/**
	 * Iniitalizes kendo components
	 */
	initKendoComponents: function () {
		this.createAccountComponent({
			element: $('#cw_asset_console_accountid'),
			container: $('#cw_asset_console_account'),
			//nextElement: $('#cw_asset_list'),
			events: {
				dataSourceChange: $.proxy(function (e, length) {
					this.emptyComponent({
						containers: [{
							container: $('#cw_ac_asset'),
							label: lang.ASSET
						}, {
							container: $('#cw_ac_monitor'),
							label: lang.MONITOR
						}],
						length: length
					});
				}, this)
			}
		});
		$('#cw_asset_list').kendoComboBox({
			change: $.proxy(function (e) {
				this.selectedAssetId = e.sender.value();
				this.assetConsoleMonitorList.value('');
				if (Utils.isGuid(this.selectedAssetId)) {
					this.assetConsoleMonitorList.enable(true);
					this.assetConsoleMonitorList.dataSource.read();
				}
			}, this),
			dataBound: $.proxy(function (e) {
				var value = e.sender.value();
				if (Utils.isGuid(value)) {
					this.selectedAssetId = value;
					this.assetConsoleMonitorList.enable(true);
					this.assetConsoleMonitorList.dataSource.read();
				}
			}, this),
			dataSource: new CeeViewDataSource({
				change: $.proxy(function (e) {
					this.emptyComponent({
						containers: [{
							container: $('#cw_ac_monitor'),
							label: lang.MONITOR
						}],
						length: e.items.length
					});
				}, this),
				transport: {
					read: {
						url: $.proxy(function () {
							return Settings.serverPath + 'accounts/' + this.serviceBoardAccountId + '/assets/types/AGENT/lite?includeSubaccounts=' + State.includeSubaccounts;
						}, this),
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				error: ErrorHandler.kendoServerError,
				sort: {field: 'name', dir: 'asc'}
			}),
			dataTextField: 'name',
			dataValueField: 'id',
			autoBind: this.mode === true || this.wasUpdate,
			placeholder: lang.SELECT
		}).data('kendoComboBox');

		this.assetConsoleMonitorList = $('#cw_monitor_list').kendoComboBox({
			dataSource: new CeeViewDataSource({
				transport: {
					read: {
						url: $.proxy(function () {
							return Settings.serverPath + 'accounts/' + this.widgetAccountId + '/assets/' + this.selectedAssetId + '/health/monitors/types/SYSTEM';
						}, this),
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				error: ErrorHandler.kendoServerError,
				sort: {field: 'name', dir: 'asc'}
			}),
			dataTextField: 'name',
			dataValueField: 'id',
			autoBind: false,
			placeholder: lang.SELECT
		}).data('kendoComboBox');
	},
	/*
	 * Handler function for getting the widget configuration values
	 * */
	getValues: function () {
		var assetList = $('#cw_asset_list').data('kendoComboBox');
		var monitorList = $('#cw_monitor_list').data('kendoComboBox');
		var title = $('.cw_widget_title').val().trim();
		var widgetObj = {
			id: this.mode === 'update' || this.wasUpdate ? this.widgetObj.id : Utils.guid(),
			type: 'asset_console',
			title: title ? title : lang.ASSET + ' \u00bb ' + assetList.text(),
			configuration: {
				accountId: this.widgetAccountId || $('#cw_asset_console_accountid').data('kendoComboBox').value(),
				assetId: assetList.value(),
				monitorId: monitorList.value()
			}
		};

		return widgetObj;
	},
	/*
	 * Checks if form is valid
	 * */
	isValid: function () {
		var valid = true;
		this.validationMessage = '';

		if (!Utils.isGuid($('#cw_asset_console_accountid').data('kendoComboBox').value())) {
			valid = false;
			this.validationMessage = lang.widget.SELECT_ACCOUNT;
		}
		if (!Utils.isGuid($('#cw_asset_list').data('kendoComboBox').value())) {
			valid = false;
			this.validationMessage = lang.widget.SELECT_ASSET;
		}
		if (!Utils.isGuid($('#cw_monitor_list').data('kendoComboBox').value())) {
			valid = false;
			this.validationMessage = lang.widget.SELECT_MONITOR;
		}

		return {
			valid: valid,
			validationMessage: this.validationMessage
		};
	}
});
