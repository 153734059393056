import React from "react";
import {connect} from 'react-redux'
import {Field} from "redux-form";
import CESelection from './fields/ceSelection';
import connectDependencies from './fields/connectDependencies';
import CETextInput, { CETextEditor } from './fields/ceTextInput';
import CESeparator from './fields/ceSeparator';
import CERange from './fields/ceRange';
import FormEntry from "controls/react/form/formEntry";
import {CEAlgorithm, CELabel} from "./fields";
import CECheckBox from "./fields/ceCheckBox";
import classnames from "classnames";
import WildCard from "./wildCard";

import {ADDABLE_SELECTORS} from '../constants';
import {getItemValue} from '../selectors/getItemValue';
import { isCreateMode } from "../selectors/isCreateMode";

import Label from './label';
import CEInterval from "./fields/ceInterval/ceInterval";
import CEFileChooser from "./fields/ceFileChooser";

const mapStateToProps = (state, ownProps) => {
    const {field} = ownProps;

    return {
        property: getItemValue(state, ['fields', field]),
        isCreateMode: isCreateMode(state),
        isAddable: ADDABLE_SELECTORS.has(field),
        editable: getItemValue(state, ['enabledFields', field])
    }
};

@connect(mapStateToProps, null)
export default class PropertyField extends React.PureComponent {
    render() {
        const {property, isCreateMode, isAddable, editable} = this.props;

        let component;
        const formEntryOptions = {};

        const filenameField = 'fileName';
        const {id, visible, type, tooltip, caption, required, multiLine, directoriesOnly} = property.toJSON();
        const disabled =  property.get('createOnly') && !isCreateMode;

        if (!visible) {
            return null;
        }

        switch (type) {
            case 'CESelection':
                component = CESelection;
                break;
            case 'CESelectionMulti':
                component = CESelection;
                break;
            case 'CERange':
                component = CERange;
                break;
            case 'CETextInput':
                component = CETextInput;
                multiLine && (formEntryOptions.vertical = true);
                break;
            case 'CETextEditor':
                component = CETextEditor;
                break;
            case 'CELabel':
                component = CELabel;
                break;
            case 'CESeparator':
                return <CESeparator key={id}/>;
            case 'CEAlgorithm':
                return <Field
                    key={id}
                    component={connectDependencies(CEAlgorithm)}
                    name={id}
                    disabled={disabled}
                    targetValue={id}/>;
            case 'CEAuthentication':
                component = CESelection;
                break;
			case 'CESchedule':
				const {interval, intervalCaption, scheduleCaption} = property.toJSON();

				return <Field
					key={id}
					component={connectDependencies(CEInterval)}
					name={id}
					disabled={disabled}
					interval={interval}
					targetValue={id}
					intervalCaption={intervalCaption}
					scheduleCaption={scheduleCaption}/>;
				break;
			case 'CEFileChooser':
				return <Field
					key={id}
					component={connectDependencies(CEFileChooser)}
					name={id}
					caption={caption}
					disabled={disabled}
					filenameField={filenameField}
					directoriesOnly={directoriesOnly}
					targetValue={id}/>;
				break;
            case 'CECheckBox':
                const checkBox = <Field
                    component={connectDependencies(CECheckBox)}
                    name={id}
                    targetValue={id}
                    disabled={disabled || !editable}
                    property={property} />;

                return <div className={classnames("qualifier-wizard__field-cecheckbox", {"qualifier-wizard__field-disabled": disabled || !editable})}>
                    {checkBox}
                    <label title={tooltip}>{caption}</label>
                </div>;
            default:
                console.log(`Type: ${type} is not implemented`);
                return null;
        }

        const label = <Label caption={caption} tooltip={tooltip} />

        const classes = classnames(
            'qualifier-wizard__field',
            `qualifier-wizard__field-${type.toLowerCase()}`,
            {
                'required-field': required,
                'is-addable': isAddable
            }
        );

        return <FormEntry key={id} label={label} containerClass={classes} {...formEntryOptions}>
            <Field
                component={connectDependencies(component)}
                name={id}
                targetValue={id}
                property={property}
                disabled={disabled || !editable}
                validate={(value) => {
                    if (required) {
                        if (!value) {
                            return "Field is required";
                        }
                    }
                }}/>
            <WildCard required={required} field={id} />
        </FormEntry>
    }
}
