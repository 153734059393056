import React from "react";
import PropertyFields from "./subforms/propertyFields";
import Section from "controls/react/layout/section";
import {useSelector} from 'react-redux'
import Warning from "./subforms/warning";
import {Field} from 'redux-form';
import {getItemValue} from "./selectors/getItemValue";
import './group.less'
import Lang from 'core/localization/lang';

import translator from "core/localization/localization";

const i = translator({
	'message': {
		'en': 'Warning threshold narrows down the UP values from breach definition. The values matching will set the servicequalifier to UP, while the remaing values will give a WARNING',
		'no': 'Advarselterskel begrensere OPPE verdiene fra bruddefinisjonen. Verdiene som samsvarer setter tjenestepunktet som OPPE. Gjenværende verdier vil gi ADVARSEL.'
	}
});

export default function Group(props) {
    const {groupId, fields, multipleLink} = props;

    const group = useSelector(state => getItemValue(state, ['fields', groupId]))
    const algorithmData = useSelector(state => getItemValue(state, ['fieldData', 'algorithm']));

    if (group && !group.get('visible')) {
        return null;
    }

    const caption = group?.get('caption');
    const isBreahGroup = groupId === "grpBreachThreshold";
    const isMeasuredTarget = groupId === "grpMeasuredTarget";
	const tooltip = group?.get('tooltip');

    const isAlgorithmHasAvailableOnly = algorithmData && algorithmData.length === 1 && algorithmData[0].value === "AvailableAlgorithm";

    let title;

    if (tooltip) {
    	title = <>{caption}<i className="qw-group-margin-left glyphicons pointer question-sign" title={tooltip}/></>;
	}
    else {
    	title = caption;
	}

    return <React.Fragment>
        <Section appearance={'frame'} title={title}>
            <PropertyFields fields={fields} />
        </Section>
        {isBreahGroup && algorithmData && !isAlgorithmHasAvailableOnly && <Section appearance={'frame'} title={<>{Lang.designer.SERVICE_QUALIFIERS_SETTINGS_WARNING_THRESHOLD}<i className="qw-group-margin-left glyphicons pointer question-sign" title={i('message')}/></>}>
            <Field name="warning" component={Warning} />
        </Section>}
        {isMeasuredTarget && multipleLink}
    </React.Fragment>
}


