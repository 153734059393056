import Element from './element';
import Api from 'tools/api';
import AssetColumns from 'areas/assets/assetColumns';
import AssetsApi from 'areas/assets/api';
import Cookies from 'core/cookies';
import State from 'tools/state';
import ArrayHelpers from "tools/helpers/array";
import {AssetGroupRouter} from "areas/assetgroups/bundleDescription";

import {translator} from "core/localization/localization";
import {indicatorToState, StateColors} from "tools/states";
import {sharedDictionary} from "areas/application/localization";
import {sharedDictionary as designersharedDictionary} from './localization'
import {
	defaultHealthOrder,
	defaultSeveritiesOrder,
	Severity,
	SeverityColor
} from "tools/severity";
import {executeUpdate} from "controls/designer/utils";

const i = translator({
	"Total {0} assets": {
		"no": "Totalt {0} ressurser"
	},
	'Asset groups: {0}': {
		no: "Ressursgrupper: {0}"
	},
	'Asset group: {0}': {
		no: "Ressursgruppe: {0}"
	}
}, sharedDictionary, designersharedDictionary);

const containerStyleFields = {
	assetGroupContainerBackground: 'fillColor', // mxConstants.STYLE_FILLCOLOR,
	assetGroupContainerBackgroundOpacity: 'fillOpacity' // mxConstants.STYLE_FILL_OPACITY
};

export default class AssetGroup extends Element {
	assetGroupId = null;
	assetCells = [];
	assetGroups = [];

	constructor(designer, cell) {
		super(designer, cell)

		this.assetGroupIds = this.datasource.assetGroups;

		if (!Array.isArray(this.assetGroupIds)) {
			this.assetGroupIds = [{
				id: this.assetGroupIds,
				accountId: this.accountIds
			}]
		}

		this.graph.addListener('CELL_CLICKED', this.onGraphCellClicked);


		this.graph.getModel().addListener(mxEvent.EXECUTE, this.checkIfContainerResized);
	}

	getEntriesToLoad() {
		return this.assetGroupIds.map( x =>  ({
			assetGroupId: x.id,
			accountId: x.accountId,
			includeAssets: true //now there is a possibility to expand any group on the fly so we need assets for all of them
		}));
	}

	onEntriesLoaded(entries) {
		this.assetGroups = entries;
	}

	getLabel(){
		if(this.datasource.showAsLabel && this.assetGroups?.length){
			return this.assetGroups[0].assetGroupName;
		}

		return null;
	}

	updateState() {
		if (this.assetGroups.length < this.assetGroupIds.length)
			return;

		if (this.designer.config.mode == 'application') {
			this.addExpandCollapseIcon(this.isExpanded());
		}

		if (this.isExpanded()) {
			this.addContainerCell();
			this.moveAssetGroupLabelToTheRight();
			this.showAssets();

			if(this.datasource.assetGroup.showSummary) {
				this.showSummary();
			}
			this.showTotalAssetsCount()
		}

		if(!this.datasource.showHI) {
			if(this.isDefaultIconSet(Sidebar.prototype.defaultAssetGroupIcon)) {
				this.updateIcon('folder-heart')
			}
		}else{
			this.removeIcon();

			let healthIndex = this.getHealthIndex()
			if (healthIndex === -1) {
				healthIndex = '';
			}

			this.addContentLabel(healthIndex, {
				noDataSource: true,
				entityType: 'assetGroup',
				assetGroupId: this.assetGroupIds[0].id,
			});
		}

		this.checkForWarnings(this.assetGroups);

		if(this.datasource.showReasons){
			this.showReasonsIcon = this.createStatusIcon({
				label: "",
				tooltip: i("Click to see Health reasons")
			}, 'article', {
				position: 'topLeft',
				backgroundColor: '#FFFFFF',
				borderColor: '#000000',
				iconColor: '#000000'
			});
		}

		var assetIndicator = AssetColumns.getIndicatorByIndex(this.getHealthIndex());
		this.setState(indicatorToState(assetIndicator));
	}

	isExpanded(){
		const overridedValue = this.graph.assetGroupExpandedOverride[this.cell.id];
		if(overridedValue !== undefined){
			return overridedValue;
		}

		return this.datasource.expanded == true
	}

	getSubscriptions(subscriptions) {
		return {
			assetGroups: this.assetGroupIds.map(x => x.id)
		};
	}

	consumeEvent(event) {
		if(event.eventType != 'AssetGroupHealth' && event.eventType != 'AssetHealth')
			return false;

		if(event.eventType == 'AssetHealth'){
			const assetInTheGroup = this.assetGroups[0].assets.find( x => x.assetId == event.assetHealth.assetId);
			if(assetInTheGroup == null)
				return false
		}

		if (this.designer.config.mode == 'application'){
			return {
				reload: true
			}
		}

		if(event.eventType == 'AssetHealth')
			return false

		let stateChanged = false;

		const groupHealth = event.assetGroupHealth;
		const localGroup = this.assetGroups.find(x => groupHealth.assetGroupId == x.assetGroupId);

		if(localGroup && localGroup.groupHI != groupHealth.groupHI) {
			localGroup.groupHI = groupHealth.groupHI;
			stateChanged = true;
		}

		if(localGroup && localGroup.assetMaintenance != groupHealth.assetMaintenance) {
			localGroup.assetMaintenance = groupHealth.assetMaintenance;
			stateChanged = true;
		}

		return stateChanged;
	}

	getTooltipInternal(accountName) {
		if (this.assetGroups?.length == 0)
			return null;

		const string = this.assetGroups.length == 1 ? 'Asset group: {0}' : 'Asset groups: {0}'
		return i(string, this.assetGroups.map(x => x.assetGroupName).join(", "));
	}

	redirect() {
		if(this.designer.config.overrides?.assetGroup?.redirect){
			this.designer.config.overrides?.assetGroup?.redirect(this)
		}else {
			let options = {
				isView: true,
				isFromAssetGroupWidget: true,
				assetGroupNameFilter: this.assetGroups[0].assetGroupName
			};
			State.mainApp.loadModule('AssetGroupSummaryView', '', options);
		}
	}

	showAssets(){
		this.assetCells.length !=0 && this.graph.removeCells(this.assetCells, false);
		this.assetCells = [];


		//  container-border - xPadding - asset - xMargin - asset - xMargin - asset - xMargin - Cell - asset - container-border
		const geometry = this.containerCell.getGeometry();
		const xPadding = 30;
		const yPadding = 15;
		const assetCellWidth = 50;
		const assetCellHeight = 50;
		const xMargin = 70;
		const yMargin = 30;

		const itemsPerRow = Math.floor((geometry.width - xPadding * 2 +xMargin ) / (assetCellWidth + xMargin));
		if(itemsPerRow <= 0)
			return;

		const rowsCount = Math.floor((geometry.height - yPadding + yMargin - 20 ) /( assetCellHeight + yMargin ));
		if(rowsCount <= 0)
			return;

		mxUtils.placeElementsInGrid(this.assetGroups[0].assets.slice(0, itemsPerRow * rowsCount),
			new mxGeometry(xPadding, yPadding, assetCellHeight, assetCellWidth), itemsPerRow , xMargin, yMargin,
			(asset, geometry) => {
				const userData = mxUtils.createUserObject({
					datasource:{
						type: 'asset',
						assets: [{
							id: asset.assetId,
							accountId: this.assetGroupIds[0].accountId
						}],
						showAsLabel: this.datasource.showAsLabel,
						showHI: this.datasource.showHI
					},
					applicationMode: 'true',
					generatedCell: 'true',
					assetGroupEntry: true,
					asset: 'json:' + JSON.stringify(asset)
				});

				const newCell = this.graph.insertVertex(this.containerCell, null, userData,
					geometry.x, geometry.y, geometry.width, geometry.height,
					Sidebar.prototype.ceeviewAssetStyle + mxUtils.getReadonlyStyles());

				this.assetCells.push(newCell);
			});
	}

	showSummary(){
		this.summaryContainerCell = this.graph.insertVertex(this.containerCell, null,
			null,
			0,0,0,0,
			"rounded=1;whiteSpace=wrap;html=1;strokeColor=none;" + mxUtils.getReadonlyStyles()
			)
		this.summaryContainerCell.setGeometry(this.getSummaryContainerCellGeometry())

		let {groupAssetHealth} = this.assetGroups[0];
		groupAssetHealth = groupAssetHealth || {
			critical: 0,
			maintenance: 0,
			major: 0,
			minor: 0,
			ok: 0
		};

		defaultSeveritiesOrder.forEach((severity, j) => {
			const propertyName = severity.toLowerCase();

			const assetsCount = groupAssetHealth[propertyName] || 0;
			const userData = mxUtils.createUserObject({
				datasource: {
					type: 'assetGroup',
					assetGroups: [{
						id: this.assetGroupIds[0].id,
					}]
				},
				generatedCell: 'true',
				noDataSource: 'true',
				tooltip: i('State - asset - ' + severity, assetsCount),
				assetGroupId: this.assetGroupIds[0].id,
				label: assetsCount,
				severity: severity
			});

			this.graph.insertVertex(this.summaryContainerCell,
				null,
				userData,
				50*(j),
				0,
				50,
				20,
				"text;html=1;strokeColor=none;align=center;verticalAlign=middle;" +
				mxConstants.STYLE_FILLCOLOR + "=" + SeverityColor[severity] + ";" +
				mxConstants.STYLE_FONTCOLOR + "=white;" +
				"whiteSpace=wrap;verticalLabelPosition=middle;labelPosition=center;forceShowInViewMode;selectable=0;"
				+ mxUtils.getReadonlyStyles()
			);
		});
	}

	getSummaryContainerCellGeometry(){
		const geometry = this.containerCell.getGeometry();
		return new mxGeometry((geometry.width - 50*5)/2,
			geometry.height - 20,
			250,
			20,);
	}

	showTotalAssetsCount(){
		const options = this.designer.dashboardSettings.allowRedirects ? {
			className : 'handCursor', color: '#4774FF'
			} : {
			className: '', color: 'black'
		}
		this.totalCell = this.graph.insertVertex(this.containerCell,
			null,
			'<label class="' + options.className + '">'+i('Total {0} assets', this.assetGroups[0].assets.length)+'</label>',
			0, 0, 0, 0,
			"text;html=1;strokeColor=none;align=center;verticalAlign=middle;"+
				"whiteSpace=wrap;verticalLabelPosition=middle;labelPosition=center;fontColor=" + options.color + ";forceShowInViewMode"
				+ mxUtils.getReadonlyStyles()
		);

		this.totalCell.setGeometry(this.getTotalCellGeometry());
	}

	getTotalCellGeometry(){
		const geometry = this.containerCell.getGeometry();
		return new mxGeometry(0, geometry.height - (this.datasource.assetGroup.showSummary ? 39 : 25), geometry.width, 10);
	}

	containerStyles() {
		const parentStyles = this.graph.getCellStyle(this.cell);
		let styles = 'rounded=1;whiteSpace=wrap;html=1;cloneable=0;movable=0;deletable=0;rotatable=0;editable=0;';

		for (const [k, v] of Object.entries(containerStyleFields)) {
			const value = mxUtils.getValue(parentStyles, k, null);
			if(value) {
				styles += `${v}=${value};`;
			}
		}
		return styles;
	}

	addContainerCell() {
		const width = this.datasource.containerCellWidth || 350;
		const height = this.datasource.containerCellHeight || 280;

		const geometry = new mxGeometry(this.cell.getGeometry().x - 10,
			this.cell.getGeometry().y + this.cell.getGeometry().height - 10,
			width, height);

		const containerCellUserData = mxUtils.createUserObject({
			generatedCell: 'true',
			entityType: 'assetGroup',
			noDataSource: 'true',
			assetGroupId: this.assetGroupIds[0].id,
			label: ''
		});

		const parent = this.graph.getDefaultParent();
		const vertex = this.graph.createVertex(parent, null, containerCellUserData,
			geometry.x, geometry.y, geometry.width, geometry.height,
			this.containerStyles());

		this.containerCell = this.graph.addCell(vertex, parent, parent.getIndex(this.cell) + 1);

		this.graph.orderCells(false, [this.containerCell]);

		this.registerRelativeCell(this.containerCell, (parentCell) => ({
			dx: -10,
			dy: parentCell.getGeometry().height - 10
		}));
	}

	checkIfContainerResized = (graphModel, ev) => {
		if(this.datasource.expanded != true)
			return;

		const {change} = ev.properties

		if(!(change instanceof mxGeometryChange) || change.cell != this.containerCell)
			return;

		let datasource = this.cell.getDatasource();
		datasource.containerCellWidth = this.containerCell.getGeometry().width;
		datasource.containerCellHeight = this.containerCell.getGeometry().height;

		this.cell.setDatasource(datasource);

		executeUpdate(this.graph, () => {
			this.totalCell?.setGeometry(this.getTotalCellGeometry());
			this.summaryContainerCell?.setGeometry(this.getSummaryContainerCellGeometry());

			this.showAssets();
		});
	}

	stylesChanged(e) {
		super.stylesChanged(e);

		if(this.datasource.expanded != true)
			return;

		e.properties.keys.forEach((key, index) => {
			const styleKey = containerStyleFields[key];
			if (!styleKey) {
				return;
			}
			executeUpdate(this.graph, () => {
				this.graph.setCellStyles(styleKey, e.properties.values[index], [this.containerCell]);
			});
		});
	}

	getHealthIndex(){
		const healthIndex = Math.min.apply(null, this.assetGroups?.map(x => x.groupHI))
		if(healthIndex == null){
			return -1;
		}

		return healthIndex;
	}

	moveAssetGroupLabelToTheRight() {
		this.graph.setCellStyles(mxConstants.STYLE_LABEL_POSITION, mxConstants.ALIGN_RIGHT, this.graph.getSelectionCells());
		this.graph.setCellStyles(mxConstants.STYLE_VERTICAL_LABEL_POSITION, mxConstants.ALIGN_MIDDLE, this.graph.getSelectionCells());
		this.graph.setCellStyles(mxConstants.STYLE_ALIGN, mxConstants.ALIGN_LEFT, this.graph.getSelectionCells());
		this.graph.setCellStyles(mxConstants.STYLE_VERTICAL_ALIGN, mxConstants.ALIGN_MIDDLE, this.graph.getSelectionCells());
	}

	onGraphCellClicked = (graph, e) => {
		if(e.properties.mouseEvent.evt.button != 0)
			return;

		if (this.totalCell != null
			&& e.properties.cell == this.totalCell
			&& this.designer.config.chromeless
		) {
			this.redirect();
		}else if(this.expandCollapseIcon != null && e.properties.cell == this.expandCollapseIcon){

			let styles = this.graph.getCellStyle(this.expandCollapseIcon);
			if(styles.image.indexOf('square-empty-minus') !== -1){
				this.graph.assetGroupExpandedOverride[this.cell.id] = false;
				graph.setCellStyles(mxConstants.STYLE_IMAGE, 'square-empty-plus', [this.expandCollapseIcon]);
			}else{
				this.graph.assetGroupExpandedOverride[this.cell.id] = true;
				graph.setCellStyles(mxConstants.STYLE_IMAGE, 'square-empty-minus', [this.expandCollapseIcon]);
			}

			this.cleanUp();
			this.updateState();

			graph.designer.fit();

		}else if(this.cell == e.properties.cell
			&& e.properties.mouseEvent.labelClicked
			&& this.designer.config.chromeless){
			this.redirect();
		}
	}

	destroy() {
		super.destroy();

		this.graph.getModel().removeListener(this.checkIfContainerResized);
		this.graph.removeListener(this.onGraphCellClicked);
	}

	empty(){
		return this.assetGroupIds.length == 0;
	}
}
