import './formEntry.less'

import React from 'react';
import classnames from 'classnames';
import PropTypes from "prop-types";
import {observer} from 'mobx-react';

import ContextField from './contextField';
import IconButton from './iconButton';
import {linkModel, ValidatableModel} from "framework/mobx-integration";

let b = require('b_').with('form-entry');

export interface FormEntryBaseProps{
	label?: 'string' | React.ReactNode,
	title?: 'string',
	children: React.ReactNode | React.ReactNode[],
	vertical?: boolean,
	disabled?: boolean,
	required?: boolean,
	valueLink?: any,
	width?: 'default' | 'fit' | 'half',
	errors?: string[],
	containerClass?: string,

	id?: string,
	style?: any
}

export interface FormEntryProps<TModel extends object, TModelField extends keyof TModel> extends FormEntryBaseProps{
	model?: TModel,
	modelField?: TModelField,
	onChange?: (value: TModel[TModelField]) => void
}

const FormEntry = observer(<TModel extends ValidatableModel<TModel>, TModelField extends keyof TModel>(props: FormEntryProps<TModel, TModelField>) => {
	let {icon, children} = checkForIcon(props);

	let errors = props.errors || [];

	const blockClasses : {[index: string] : any} = {
		vertical: props.vertical,
		horizontal: !props.vertical,
		width: props.width
	}

	let bindingProps = null;
	let hasValidation = false;

	if(props.valueLink) {
		hasValidation = props.valueLink.hasValidation;
		bindingProps = props.valueLink.props;
	}

	if(props.model && props.modelField){
		hasValidation = props.model.validator?.hasValidation(props.modelField);
		bindingProps = linkModel(props.model, props.modelField);
	}

	if(bindingProps){
		blockClasses['has-validation'] = hasValidation;
		blockClasses.validation = bindingProps.invalid ? 'failed' : 'succeeded';

		props.onChange && (bindingProps.onChange = props.onChange);

		errors = bindingProps.errors;

		delete bindingProps.invalid; //form entry takes care of validation so we dont need an input to act on validation
		delete bindingProps.errors;
		children[0] = React.cloneElement(children[0], bindingProps);
	}

	if(props.required && bindingProps == null){
		blockClasses['required'] = true;
	}

	const rootClasses = classnames(
		b(blockClasses),
		props.containerClass
	);

	const title = errors.length ? errors.join('. ') : props.title;

	const isLongLabel = props.label && typeof(props.label) == 'string' && props.label.length >= 20;
	const isVeryLongLabel = (isLongLabel && icon) ? true : false;

	return (
		<div id={props.id} className={rootClasses} style={props.style} title={title}>
			{props.label &&
			<label className={b('label')}>
					<span className={b('label-text', {"smaller": isLongLabel, "very_small": isVeryLongLabel})}>
						{props.label}
					</span>
				{icon}
			</label>
			}
			<div className={b('field')}>
				{!props.context && children}
				{props.context &&
				<ContextField context={props.context} path={props.path}>
					{children[0]}
				</ContextField>
				}
			</div>
		</div>
	);
});

function checkForIcon(props:FormEntryBaseProps){
	let icon = null;
	let children = React.Children.toArray(props.children);

	if(children.length > 0 ) {
		if (children[0].type == IconButton) {
			icon = children.shift();
			icon = React.cloneElement(icon, {
				containerClass: classnames(icon.props.containerClass, b('icon'))
			});
		}
	}
	return {icon, children};
}

export {FormEntry, FormEntry as default}

// FormEntry.propTypes = {
// 	label: PropTypes.oneOfType([
// 		PropTypes.string,
// 		PropTypes.node
// 	]),
// 	title: PropTypes.string,
// 	children: PropTypes.node.isRequired,
// 	containerClass: PropTypes.string,
// 	vertical: PropTypes.bool,
// 	disabled: PropTypes.bool,
// 	context: PropTypes.object,
// 	path: PropTypes.string,
// 	required: PropTypes.bool,
// 	valueLink: PropTypes.object,
// 	model: PropTypes.object,
// 	modelField: PropTypes.string,
// 	width: PropTypes.oneOf(['default', 'fit', 'half']),
// 	errors: PropTypes.arrayOf(PropTypes.string)
// };
//
// FormEntry.defaultProps = {
// 	width: 'default'
// }
