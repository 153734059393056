import React from 'react';
import classes from 'classnames';
import PropTypes from 'prop-types';

export const Button = (props) => {
	return (
		<button id={props.id || ''}
				className={classes(props.className, 'k-button', {'k-primary': props.primary})}
		        onClick={() => props.onClick()}
		        title={props.hoverText}
		        style={props.style}
		        disabled={props.disabled}>
			{props.title}
		</button>
	);
};

export default Button;

Button.propTypes = {
	title: PropTypes.string.isRequired,
	primary: PropTypes.bool,
	onClick: PropTypes.func.isRequired,
	className: PropTypes.string,
	disabled: PropTypes.bool
};

Button.defaultProps = {
	disabled: false
};
