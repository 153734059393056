import React from 'react';
import ReactDOM from "react-dom";
import {Utils, applyRequired} from 'tools/utils';
import {translator} from 'core';
import ConfigurationBase from 'areas/service-boards/configurationBase';
import FormEntry from "controls/react/form/formEntry";
import {TagsSelect} from "controls/react/tagsSelect";

export function GridWidgetConfiguration(config) {
	Utils.apply(this, config);
	this.initComponent();
};

export {GridWidgetConfiguration as default};

jQuery.extend(GridWidgetConfiguration.prototype, ConfigurationBase.prototype, {

	initComponent: function () {
		var html;
		var widgetObj = this.widgetObj;

		html = '<div class="cw_field"><label class="cw_inline">' + lang.TITLE + ': </label><input type="text" class="cw_widget_title cw_inline" value="' + (widgetObj.title || '') + '" /></div>';
		html += '<div class="cw_field" id="cw_grid_sla_account"><label class="cw_inline">' + lang.ACCOUNT + ': </label><div class="cw_dropdown_container"><input id="cw_grid_sla_accountid" type="text" value="' + (widgetObj.configuration.accountId || '') + '" /></div></div>';
		html += '<div class="cw_field">';
		html += '<input type="checkbox" checked id="cw_sla_include_subaccounts_check" class="cw_show_column k-checkbox" />';
		html += '<label for="cw_sla_include_subaccounts_check" class="k-checkbox-label">' + lang.INCLUDE_SUBACCOUNTS + '</label>';
		html += '</div>';
		html += '<ul class="cw_list_columns">';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_head">' + lang.widget.COLUMN_VISIBILITY + '</div>';
		html += '</li>';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_sla_status" class="cw_show_column k-checkbox"><label for="cw_sla_status" class="k-checkbox-label">' + lang.widget.STATUS + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_sla_name" class="cw_show_column k-checkbox"><label for="cw_sla_name" class="k-checkbox-label">' + lang.NAME + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_sla_account" class="cw_show_column k-checkbox"><label for="cw_sla_account" class="k-checkbox-label">' + lang.ACCOUNT + '</label></div>';
		html += '</li>';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_sla_service" class="cw_show_column k-checkbox"><label for="cw_sla_service" class="k-checkbox-label">' + lang.SERVICE + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_sla_period" class="cw_show_column k-checkbox"><label for="cw_sla_period" class="k-checkbox-label">' + lang.service.PERIOD + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_sla_tags" class="cw_show_column k-checkbox"><label for="cw_sla_tags" class="k-checkbox-label">' + lang.TAGS + '</label></div>';
		html += '</li>';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_sla_goal" class="cw_show_column k-checkbox"><label for="cw_sla_goal" class="k-checkbox-label">' + lang.service.COMPLIANCE_GOAL + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_sla_compliance" class="cw_show_column k-checkbox"><label for="cw_sla_compliance" class="k-checkbox-label">' + lang.service.ACTUAL_COMPLIANCE + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_sla_team" class="cw_show_column k-checkbox"><label for="cw_sla_team" class="k-checkbox-label">' + lang.service.RESPONSIBLE_TEAM + '</label></div>';
		html += '</li>';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_sla_down_time" class="cw_show_column k-checkbox"><label for="cw_sla_down_time" class="k-checkbox-label">' + lang.slas.DOWNTIME + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_sla_max_allowed_down_time" class="cw_show_column k-checkbox"><label for="cw_sla_max_allowed_down_time" class="k-checkbox-label">' + lang.slas.MAX_DOWNTIME + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_sla_description" class="cw_show_column k-checkbox"><label for="cw_sla_description" class="k-checkbox-label">' + lang.DESCRIPTION + '</label></div>';
		html += '</li>';
		html += '</ul>';

		html +='<div class="cw_separator">';
		html += '<span class="cw_separator_title">' + lang.TAGS + '</span>';
		html += '<div class="w100 left">';
		html += '<div class="cw_checkoxes_with_labels"><input type="checkbox" id="cw_untagged" class="k-checkbox" /><label for="cw_untagged" class="k-checkbox-label">' + lang.SHOW_UNTAGGED + '</label></div></div>';
		html += '<div class="left w100" id="cw_tags"></div>';
		html += '</div>'

		this.widgetConfigurationFormEl.empty().append(html);

		if (widgetObj.configuration.columns) {
			//on edit reset all and set only if in configuration
			$('.cw_show_column').prop('checked', false);

			if (widgetObj.configuration.columns.statusIndex && !widgetObj.configuration.columns.statusIndex.hidden) {
				$('#cw_sla_status').prop('checked', true);
			}
			if (widgetObj.configuration.columns.name && !widgetObj.configuration.columns.name.hidden) {
				$('#cw_sla_name', this.widgetConfigurationFormEl).prop('checked', true);
			}
			if (widgetObj.configuration.columns.accountName && !widgetObj.configuration.columns.accountName.hidden) {
				$('#cw_sla_account').prop('checked', true);
			}
			if (widgetObj.configuration.columns.serviceName && !widgetObj.configuration.columns.serviceName.hidden) {
				$('#cw_sla_service').prop('checked', true);
			}
			if (widgetObj.configuration.columns.periodTag && !widgetObj.configuration.columns.periodTag.hidden) {
				$('#cw_sla_period').prop('checked', true);
			}
			if (widgetObj.configuration.columns.tags && !widgetObj.configuration.columns.tags.hidden) {
				$('#cw_sla_tags').prop('checked', true);
			}
			if (widgetObj.configuration.columns.compliancePercentage && !widgetObj.configuration.columns.compliancePercentage.hidden) {
				$('#cw_sla_goal').prop('checked', true);
			}
			if (widgetObj.configuration.columns.actualCompliance && !widgetObj.configuration.columns.actualCompliance.hidden) {
				$('#cw_sla_compliance').prop('checked', true);
			}
			if (widgetObj.configuration.columns.responsibleTeamName && !widgetObj.configuration.columns.responsibleTeamName.hidden) {
				$('#cw_sla_team').prop('checked', true);
			}
			if (widgetObj.configuration.columns.downTime && !widgetObj.configuration.columns.downTime.hidden) {
				$('#cw_sla_down_time').prop('checked', true);
			}
			if (widgetObj.configuration.columns.maxAllowedDownTime && !widgetObj.configuration.columns.maxAllowedDownTime.hidden) {
				$('#cw_sla_max_allowed_down_time').prop('checked', true);
			}
			if (widgetObj.configuration.columns.description && !widgetObj.configuration.columns.description.hidden) {
				$('#cw_sla_description').prop('checked', true);
			}
		}

		if (widgetObj.configuration.includeSubaccounts === undefined || widgetObj.configuration.includeSubaccounts === true) {
			$('#cw_sla_include_subaccounts_check').prop('checked', true);
		} else {
			$('#cw_sla_include_subaccounts_check').prop('checked', false);
		}

		this.initKendoComponents();
		const requiredFields = ['#cw_grid_sla_accountid'];
		applyRequired(requiredFields);
	},
	setTagsDataSource: function () {
		let selectedAccount = $('#cw_grid_sla_accountid').data('kendoComboBox').value();
		let includeSubaccounts = $('#cw_sla_include_subaccounts_check').is(':checked');
		ReactDOM.render(<FormEntry label={lang.TAGS} vertical style={{maxWidth: '600px'}}>
			<TagsSelect
				mode={'tags'}
				accountId={selectedAccount}
				includeSubaccounts={includeSubaccounts}
				onChange={value => this.onTagsChange(value)}
				defaultValue={this.tagsList} />
		</FormEntry>, $('#cw_tags').get()[0]);
	},
	onTagsChange(value) {
		this.tagsList = value || [];
	},
	/**
	 * Iniitalizes kendo components
	 */
	initKendoComponents: function () {
		this.tagsList = this.widgetObj.configuration.tags;
		this.createAccountComponent({
			element: $('#cw_grid_sla_accountid'),
			container: $('#cw_grid_sla_account'),
			events: {
				change: function () {
					this.tagsList = [];
					this.setTagsDataSource();
				},
				dataBound: function () {
					this.setTagsDataSource();
				}
			}
		});
	},
	/*
	 * Handler function for getting the widget configuration values
	 * */
	getValues: function () {
		var title = $('.cw_widget_title').val().trim();
		var slaStatusCheck = $('#cw_sla_status'),
			slaNameCheck = $('#cw_sla_name', this.widgetConfigurationFormEl),
			slaAccountCheck = $('#cw_sla_account'),
			slaServiceCheck = $('#cw_sla_service'),
			slaPeriodCheck = $('#cw_sla_period'),
			slaTagsCheck = $('#cw_sla_tags'),
			slaGoalCheck = $('#cw_sla_goal'),
			slaComplianceCheck = $('#cw_sla_compliance'),
			slaTeamCkeck = $('#cw_sla_team'),
			slaDownTimeCheck = $('#cw_sla_down_time'),
			slaMaxedAllowedDownTimeCheck = $('#cw_sla_max_allowed_down_time'),
			slaDescriptionCheck = $('#cw_sla_description');
		var widgetObj = {
			id: this.mode === 'update' || this.wasUpdate ? this.widgetObj.id : Utils.guid(),
			type: 'grid_sla',
			title: title ? title : lang.widget.SLA,
			configuration: {
				accountId: this.widgetAccountId || $('#cw_grid_sla_accountid').data('kendoComboBox').value(),
				includeSubaccounts: $('#cw_sla_include_subaccounts_check').is(':checked'),
				columns: {
					statusIndex: {
						hidden: slaStatusCheck.is(':not(:checked)')
					},
					name: {
						hidden: slaNameCheck.is(':not(:checked)')
					},
					accountName: {
						hidden: slaAccountCheck.is(':not(:checked)')
					},
					serviceName: {
						hidden: slaServiceCheck.is(':not(:checked)')
					},
					periodTag: {
						hidden: slaPeriodCheck.is(':not(:checked)')
					},
					tags: {
						hidden: slaTagsCheck.is(':not(:checked)')
					},
					compliancePercentage: {
						hidden: slaGoalCheck.is(':not(:checked)')
					},
					actualCompliance: {
						hidden: slaComplianceCheck.is(':not(:checked)')
					},
					responsibleTeamName: {
						hidden: slaTeamCkeck.is(':not(:checked)')
					},
					downTime: {
						hidden: slaDownTimeCheck.is(':not(:checked)')
					},
					maxAllowedDownTime: {
						hidden: slaMaxedAllowedDownTimeCheck.is(':not(:checked)')
					},
					description: {
						hidden: slaDescriptionCheck.is(':not(:checked)')
					}
				},
				tags: this.tagsList,
				showUntagged: $('#cw_untagged').is(':checked')
			}
		};

		return widgetObj;
	},
	/*
	 * Checks if form is valid
	 * */
	isValid: function () {
		var valid = true;
		var validationMessage = '';

		var account = $('#cw_grid_sla_accountid').data('kendoComboBox');
		if (!Utils.isGuid(account.value())) {
			valid = false;
			this.validationMessage = lang.SELECT_ACCOUNT;
		}

		return {
			valid: valid,
			validationMessage: validationMessage
		};
	}
});
