import Dialog from 'controls/dialog'
import GridMenu from 'controls/gridMenu'
import UserPrefs from 'tools/userPrefs'
import Application from 'core/application'
import Utils from 'tools/utils';
import Cookies from 'core/cookies';
import Settings from 'settings';
import CustomNotification from 'controls/customNotification';

import Renderer from 'tools/renderer';
import State from 'tools/state';
import PreferencesPanel from 'controls/preferencesPanel';
import MultiSelectGridFilter from 'controls/multiSelectGridFilter';
import {openTagsFormWindow} from "controls/tagsForm";
import EventsToolbar from 'controls/eventsToolbar';
import {KpiRouter} from './bundleDescription';

export default function Kpi(config) {
	Utils.apply(this, config);
	this.module = {
		name: 'Kpi',
		initialConfig: config
	};
	this.subscriberId = Utils.guid();
	// initializing data inside this class
	this.initComponent();
};

jQuery.extend(Kpi.prototype, Application.prototype, {
	/**
	 * Kpi application related initializations can be done here
	 */
	initComponent: function () {
		Application.prototype.initComponent.call(this);

		this.getTags();

		if (State.mainApp.session.hasRole('KPI_CREATE')) {
			var button = $('.cw_context_action');
			button.text(lang.kpi.CREATE_KPI);
			button.off();
			button.on('click', $.proxy(this.onCreate, this));
		}

		this.actionNotification = new CustomNotification({
			appendToElement: '#cw_kpi_section .k-grid-content',
			type: 'icon',
			hideOnClick: true
		});

		this.removeListeners();
		this.attachListeners();
		// removes loading mask
		this.removeMask();
		this.loadUserPreferences();
	},
	getTags: async function () {
		let tags = await Utils.getAccountTags();
		this.filterOptions = {
			tags: tags
		};
	},
	/**
	 * Removes listeners
	 */
	removeListeners: function () {
		$('#cw_kpi_list').off();
	},
	/**
	 * Attaches listeners
	 */
	attachListeners: function () {
		$('#cw_kpi_list').on('click', '.cw_grid_check', $.proxy(this.onCheck, this));
		$('#cw_kpi_list').on('click', '.cw_grid_check_all', $.proxy(this.onCheckAll, this));
		$(window).on('resize', $.proxy(this.adjustSectionHeight, this));
	},
	/**
	 * Initializes kendo components
	 */
	initKendoComponents: function () {
		var filterMessages = lang.grid.filter, url;
		this.gridMessages = {
			isTrue: '<span class="glyphicons service_state ok-sign"></span>',
			isFalse: '<span class="glyphicons service_state remove-sign"></span>',
			clear: lang.CLEAR,
			info: lang.grid.filter.SHOW_ITEMS,
			filter: lang.FILTER
		};

		let eventStatus = JSON.parse(UserPrefs.get('eventsStatus')) || {
			startFrom: 0,
			playing: true
		};
		this.eventsToolbar = new EventsToolbar({
			renderTo: $('#cw_kpi_events_toolbar'),
			callBackFn: $.proxy(this.onEventsTimeout, this),
			subscriberId: this.subscriberId,
			startFrom: eventStatus.startFrom,
			playing: eventStatus.playing
		});

		/*
		 *	Selector
			KPI -Show KPI value
			Name - KPI Name
			Account - Account the KPI belongs to
			Trend - Up or Down (Can postpone)
			Description - txt
		 */

		this.defaultColumns = {
			id: {
				hidden: false,
				width: 40
			},
			name: {
				hidden: false,
				width: 110
			},
			accountName: {
				hidden: !State.includeSubaccounts,
				width: 150
			},
			tags: {
				hidden: false,
				width: 150
			},
			description: {
				hidden: false
			}
		};
		var kpiSort, kpiFilter;
		var defaultFsView = UserPrefs.get('defaultFsView');
		if (Utils.isGuid(defaultFsView)) {
			var pref = JSON.parse(UserPrefs.get(defaultFsView));
			pref = pref || {
				sort: [],
				filter: []
			};
			kpiSort = pref.sort;
			kpiFilter = pref.filter;
			this.kpiColumns = pref.columns;
		} else {
			if (UserPrefs.get('kpiSort')) {
				kpiSort = JSON.parse(UserPrefs.get('kpiSort'));
			} else {
				kpiSort = [{
					field: 'name',
					dir: 'asc',
					compare: null
				}];
			}
			if (UserPrefs.get('kpiFilter')) {
				kpiFilter = JSON.parse(UserPrefs.get('kpiFilter'));
			} else {
				kpiFilter = [];
			}
		}
		var kpiColumns = this.kpiColumns || JSON.parse(UserPrefs.get('kpiColumns')) || this.defaultColumns;
		kpiColumns = Utils.completeColumns(kpiColumns, this.defaultColumns);
		var dontLoadDefault = false;
		url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/kpi/?includeSubaccounts=' + State.includeSubaccounts.toString();

		this.dataSource = new kendo.ceeview.DataSource({
			transport: {
				read: {
					url: url,
					contentType: "application/json; charset=utf-8",
					type: "GET",
					dataType: "json",
					cache: false
				}
			},
			schema: {
				model: {
					id: 'id'
				},
				parse: function (result) {
					for (let i = 0; i < result.length; i++) {
						if (result[i].tags) {
							result[i].tags = result[i].tags.join(', ');
						}
					}
					return result;
				}
			},
			sort: kpiSort,
			filter: kpiFilter
		});
		this.grid = $('#cw_kpi_list').kendoCustomGrid({
			dataSource: this.dataSource,
			resizable: true,
			selectable: 'single',
			reorderable: true,
			sortable: {
				mode: 'multiple',
				allowUnsort: true
			},
			filterable: {
				extra: false,
				operators: {
					string: {
						startswith: filterMessages.STARTS_WITH,
						neq: filterMessages.NEQ,
						eq: filterMessages.EQ
					},
					number: {
						eq: filterMessages.EQ,
						neq: filterMessages.NEQ,
						gte: filterMessages.GTE,
						gt: filterMessages.GT,
						lte: filterMessages.LTE,
						lt: filterMessages.LT
					},
					date: {
						gte: filterMessages.IAE,
						gt: filterMessages.IA,
						lte: filterMessages.IBE,
						lt: filterMessages.IB
					}
				},
				messages: this.gridMessages
			},
			pageable: false,
			columns: Utils.rearrangeColumns([{
				field: 'id',
				title: lang.SELECTOR,
				sortable: false,
				filterable: false,
				menu: false,
				template: '<input type="checkbox" class="cw_grid_check" data-id="${id}" />',
				headerTemplate: '<input type="checkbox" class="cw_grid_check_all" />',
				attributes: {
					'class': 'text_center'
				},
				headerAttributes: {
					'class': 'text_center'
				},
				hidden: kpiColumns.id.hidden,
				width: kpiColumns.id.width
			}, {
				field: 'name',
				title: lang.NAME,
				template: item => `<a class="cw_grid_link ellipsis to_expand" href="#${KpiRouter.details(item.id, item.accountId)}">${item.name}</a>`,
				sortable: true,
				filterable: true,

				hidden: kpiColumns.name.hidden,
				width: kpiColumns.name.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'tags',
				title: lang.TAGS,
				sortable: true,
				filterable: {
					ui: $.proxy(function (element) {
						let multiselect = new MultiSelectGridFilter({
							element: element,
							field: 'tags',
							grid: this.grid,
							itemTemplate: '#=data.text#',
							tagTemplate: '#=data.text#',
							dataSource: this.filterOptions.tags,
							static: true
						});
					}, this),
					messages: this.gridMessages,
					extra: false,
					operators: {
						string: {
							eq: filterMessages.EQ,
							neq: filterMessages.NEQ
						}
					}
				},
				hidden: kpiColumns.tags.hidden,
				width: kpiColumns.tags.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'accountName',
				title: lang.ACCOUNT,
				sortable: true,
				filterable: {
					ui: $.proxy(function (element) {
						return Renderer.filterGridByOwnElementDS.call(this, element, 'accountName');
					}, this),
					messages: this.gridMessages,
					extra: false,
					operators: {
						string: {
							neq: filterMessages.NEQ,
							eq: filterMessages.EQ
						}
					}
				},
				hidden: !State.includeSubaccounts || kpiColumns.accountName.hidden,
				width: kpiColumns.accountName.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'description',
				title: lang.DESCRIPTION,
				sortable: true,
				filterable: true,
				hidden: kpiColumns.description.hidden,
				width: kpiColumns.description.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}], kpiColumns),
			change: $.proxy(this.onRowExpand, this),
			dataBound: $.proxy(function () {
				var tooltip = $('#cw_kpi_list').find('.k-grid-content').data('kendoTooltip');
				if (tooltip) {
					tooltip.destroy();
				}
				$('#cw_kpi_list').find('.k-grid-content').kendoTooltip({
					filter: "td.tooltip:not(:empty)",
					content: function (event) {
						return event.target.text().split("\n").join("<br />");
					}
				});
			}, this),
			columnMenu: true
		}).data('kendoCustomGrid')
		// Add Kendo tooltip to the header of the columns
		Utils.gridColumnHeaderTooltip(this.grid);
		this.adjustSectionHeight();
		this.grid.thead.find("[data-field='id']>.k-header-column-menu").remove();
		this.gridMenu = new GridMenu({
			renderTo: 'cw_grid_menu',
			items: [{
				id: 'cw_create',
				icon: 'plus-sign',
				text: lang.CREATE,
				fn: this.onCreate,
				scope: this,
				disabled: false,
				role: 'KPI_CREATE'
			}, {
				id: 'cw_delete',
				icon: 'bin',
				text: lang.DELETE,
				fn: this.onDelete,
				scope: this,
				disabled: true,
				role: 'KPI_DELETE'
			}, {
				id: 'cw_set_tags',
				icon: 'tag',
				text: lang.SET_TAGS,
				fn: $.proxy(function () {
					return openTagsFormWindow('kpi', this);
				}, this),
				scope: this,
				disabled: true,
				role: 'KPI_UPDATE'
			}]
		});
		var searchValue = UserPrefs.get('defaultFsView') ? '' : (UserPrefs.get('kpiSearchPhrase') || '');
		this.gridFilterPanel = new PreferencesPanel({
			renderTo: 'cw_kpi_filters',
			grid: this.grid,
			modulePrefName: 'Kpi',
			defaultPrefViewKey: 'defaultFsView',
			dontLoadDefault: dontLoadDefault,
			prefListKey: 'kpiFSViews',
			userPref: this.userPref,
			onRemove: $.proxy(this.saveUserPreferences, this),
			searchFields: ['name', 'description'],
			searchValue: searchValue,
			defaultColumns: this.defaultColumns
		});
	},
	onEventsTimeout: function () {
		this.checkboxStates = Utils.getCheckboxStates(this.grid);
		this.grid.dataSource.read();
	},
	reloadByTags: function () {
		this.grid.dataSource.read();
	},
	/**
	 * Handler function for the change(select) event on the grid
	 * @param {Object} e The change event object
	 */
	onRowExpand: function () {
		var selectedRow = this.grid.select();
		var myRow = selectedRow[0];
		var messageEl = $(myRow).find('.to_expand');
		if ($(messageEl).hasClass('cw_message_expanded')) {
			$(messageEl).removeClass('cw_message_expanded').addClass('ellipsis');
		} else {
			$('#cw_kpi_list').find('.k-grid-content').find('td.cw_message_expanded').removeClass('cw_message_expanded').addClass('ellipsis');
			$(messageEl).addClass('cw_message_expanded').removeClass('ellipsis');
		}
	},
	/**
	 * Handler for the incident edit button
	 */
	onEdit: function (e) {
		var checkboxes = $('.cw_grid_check'), kpis = [], kpiId, i, data, length = checkboxes.length;
		for (i = 0; i < length; i++) {
			if ($(checkboxes[i]).is(':checked')) {
				kpis.push($(checkboxes[i]).data('id'));
			}
		}
		if (kpis.length === 1) {
			kpiId = kpis[0];
			data = this.grid.dataSource.getByUid($('input[data-id=' + kpiId + ']').closest('tr').data('uid'));
			State.mainApp.navigate(KpiRouter.details(kpiId));
		}
	},
	/**
	 * Saves user preferences
	 */
	saveUserPreferences: function () {
		this.saveUserPrefs({
			category: 'Kpi',
			filterPanel: this.gridFilterPanel,
			grid: this.grid,
			eventsToolbar: this.eventsToolbar,
			keys: {
				searchPhrase: 'kpiSearchPhrase',
				columns: 'kpiColumns',
				sort: 'kpiSort',
				filter: 'kpiFilter',
				FsViews: 'kpiFSViews'
			}
		});
	},
	/**
	 * Loads user preferences
	 */
	loadUserPreferences: function () {
		this.userPref = [];
		UserPrefs.load('Kpi', $.proxy(function (result) {
			if (result.success) {
				this.userPref = result.data;
				this.initKendoComponents();
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},
	/*
	 * Handler function for incident create
	 */
	onCreate: function (e) {
		State.mainApp.navigate(KpiRouter.createNew());
	},
	/**
	 * Handler for the delete incident button
	 */
	onDelete: function () {
		var checkboxes = $('.cw_grid_check');
		var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/kpi/delete';
		var kpis = [], checkbox;
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			checkbox = $(checkboxes[i]);
			if (checkbox.is(':checked')) {
				kpis.push(checkbox.data('id'));
			}
		}
		if (kpis.length) {
			var dialog = new Dialog({
				title: lang.INFO,
				msg: lang.kpi.messages.KPI_REMOVE_CONFIRMATION,
				icon: 'ERROR',
				actionText: 'DELETE',
				fn: $.proxy(function (value, button) {
					if (button === 'ok') {
						this.gridMenu.disableItem('cw_delete');
						Utils.ajax(url, 'POST', JSON.stringify(kpis), $.proxy(function (result) {
							if (result.success) {
								this.actionNotification.setOptions({
									message: lang.kpi.messages.SUCCESS_DELETED
								}).show();
								this.dataSource.read();
							} else {
								Utils.showInfo(lang.ALERT, result.message, result.details, this.grid);
							}
						}, this));
					}
				}, this),
				deleteFromGrid: this.grid
			});
			dialog.show();
		}
	},
	/**
	 * Handler for the checkbox click
	 * @param {Object} e The click event
	 */
	onCheck: function (e) {
		e.stopPropagation();
		var checkboxes = $('.cw_grid_check'), checkbox, kpis = [];
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			checkbox = $(checkboxes[i]);
			if (checkbox.is(':checked')) {
				kpis.push(checkbox.data('id'));
			}
		}
		var kpiLength = kpis.length;
		if (kpiLength) {
			this.gridMenu.enableItem('cw_delete');
			this.gridMenu.enableItem('cw_set_tags');
			if (kpiLength === 1) {
				this.gridMenu.enableItem('cw_edit');
			} else {
				this.gridMenu.disableItem('cw_edit');
			}
		} else {
			this.gridMenu.disableItem('cw_delete');
			this.gridMenu.disableItem('cw_edit');
			this.gridMenu.disableItem('cw_set_tags');
		}
	},
	/**
	 * Handler for the check all checkbox click
	 * @param {Object} e The click event
	 */
	onCheckAll: function (e) {
		var checkAll = $('.cw_grid_check_all').is(':checked');
		$('.cw_grid_check').prop('checked', checkAll);

		var checkboxes = $('.cw_grid_check');

		if (checkAll && checkboxes.length) {
			this.gridMenu.enableItem('cw_delete');
			this.gridMenu.enableItem('cw_set_tags');
			if (checkboxes.length === 1) {
				this.gridMenu.enableItem('cw_edit');
			} else {
				this.gridMenu.disableItem('cw_edit');
			}
		} else {
			this.gridMenu.disableItem('cw_edit');
			this.gridMenu.disableItem('cw_delete');
			this.gridMenu.disableItem('cw_set_tags');
		}
	},

	/**
	 * Method by Andy
	 */
	adjustSectionHeight: function () {
		var section = $('.cw_section_full');
		var sectionHeight = section.height();
		section.find('.cw_section_content').css('height', sectionHeight);
		section.find('.k-grid-content').css('height', sectionHeight - 40);
	},
	/**
	 * Called when application is unloaded/destroyed
	 */
	destroy: function () {
		if (this.eventsToolbar) {
			this.eventsToolbar.destroy();
		}
		this.saveUserPreferences();
	}
});
