import './userShapes.less';

import {UserSettings} from "tools/userSettings";
import {LocalEventsManager} from "core/localEventsManager";

const i = require('core/localization').translator({
  "Add to user shapes": {
    "no": "Legg til brukerform"
  }
});

export const USER_SHAPES_COLLECTION_UPDATED_EVENT = 'user-shapes-collection-updated';
const b = require('b_').with('shapes-collection-user-shape');

function createXmlFromImages(images) {
	var doc = mxUtils.createXmlDocument();
	var library = doc.createElement('mxlibrary');
	mxUtils.setTextContent(library, JSON.stringify(images));
	doc.appendChild(library);

	return mxUtils.getXml(doc);
}

export async function loadUserShapes(){
	const xml = await UserSettings.get('graphEditor.scratchpad', 'all');
	if(xml == null)
		return [];

	const doc = mxUtils.parseXml(xml);
	return JSON.parse(mxUtils.getTextContent(doc.documentElement));
}

export async function saveUserShapes(shapes, triggerEvent = false){
	var doc = mxUtils.createXmlDocument();
	var library = doc.createElement('mxlibrary');
	mxUtils.setTextContent(library, JSON.stringify(shapes));
	doc.appendChild(library);

	const xml = mxUtils.getXml(doc);
	await UserSettings.set('graphEditor.scratchpad', {
		all: xml
	});
	triggerEvent && LocalEventsManager.trigger(USER_SHAPES_COLLECTION_UPDATED_EVENT);
}

export async function renderUserShapes(designer, container, sidebar){
	const {graph} = designer;
	const images = await loadUserShapes();

	for (var img of images) {
		var [cells] = stringToCells(designer, graph.decompress(img.xml));

		if (cells.length > 0) {
			let deleteIconElement = document.createElement('a');
			deleteIconElement.innerHTML = '<span class="glyphicons remove"></span>';
			deleteIconElement.className = b('delete');
			deleteIconElement.title = i('Delete');

			const shapeElement = sidebar.createVertexTemplateFromCells(
				cells, img.w, img.h, img.title || '', true, false, false);

			const titleElement = document.createElement('div');
			titleElement.innerHTML = (img.title || 'No title') + ' <span class="glyphicons pencil"></span>';
			titleElement.className = b('title');

			const wrapper = document.createElement('div');
			wrapper.className = b();
			wrapper.appendChild(shapeElement);
			wrapper.appendChild(titleElement);
			wrapper.appendChild(deleteIconElement);

			container.appendChild(wrapper);

			let imgInner = img;
			deleteIconElement.addEventListener("click", async e => {
				let shapes = await loadUserShapes();
				let shapeIndex = shapes.findIndex( x => x.xml == imgInner.xml)
				shapes.splice(shapeIndex, 1);
				await saveUserShapes(shapes);

				wrapper.remove();
			});

			titleElement.addEventListener("click", async e => {
				let input = document.createElement('input');
				input.value = imgInner.title || '';
				e.currentTarget.innerHTML = '';
				e.currentTarget.appendChild(input);
				input.focus();

				const saveChanges = async () => {
					let shapes = await loadUserShapes();
					let shape = shapes.find( x => x.xml == imgInner.xml);
					imgInner.title = shape.title = input.value;
					await saveUserShapes(shapes);
				}

				const updateTitleNode = () => {
					titleElement.innerText = imgInner.title || 'No title';
				}

				input.addEventListener('keydown', async e => {
					if( e.key == 'Enter' ){
						await saveChanges();
					}

					if( e.key == 'Escape' || e.key == 'Enter'){
						updateTitleNode();
					}
				});

				input.addEventListener('blur', async e => {
					await saveChanges();
					updateTitleNode()
				});
			});
		}
	}
}

export function stringToCells(designer, xml) {
	const editor = designer.editorUi.editor;
	var doc = mxUtils.parseXml(xml);
	var node = editor.extractGraphModel(doc.documentElement);
	var cells = [];

	if (node != null) {
		var codec = new mxCodec(node.ownerDocument);
		var model = new mxGraphModel();
		codec.decode(node, model);

		var parent = model.getChildAt(model.getRoot(), 0);

		for (var j = 0; j < model.getChildCount(parent); j++) {
			cells.push(model.getChildAt(parent, j));
		}
	}

	return [cells, doc];
}

export function initUserShapes(designer){
	const {graph} = designer;

	designer.ui.actions.addAction("user-shapes-add", async () => {
		let cells = graph.getSelectionCells();

		if(cells.length == 0)
			return;

		var bounds = graph.view.getBounds(cells);
		var s = graph.view.scale;
		bounds.x /= s;
		bounds.y /= s;
		bounds.width /= s;
		bounds.height /= s;
		bounds.x -= graph.view.translate.x;
		bounds.y -= graph.view.translate.y;

		cells = graph.cloneCells(mxUtils.sortCells(graph.model.getTopmostCells(cells)));

		// Translates cells to origin
		for (var i = 0; i < cells.length; i++) {
			var geo = graph.getCellGeometry(cells[i]);

			if (geo != null) {
				geo.translate(-bounds.x, -bounds.y);
			}
		}

		var xml = graph.compress(mxUtils.getXml(graph.encodeCells(cells)));
		var entry = {xml: xml, w: bounds.width, h: bounds.height};


		let images = await loadUserShapes();
		images.push(entry);
		await saveUserShapes(images, true);

	}, null, null, null, i('Add to user shapes'));
}
