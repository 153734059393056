import {Localization} from "core";

export const sharedLocalization = new Localization({
	"Log entry": {
		no: "Loggmelding",
	},
	"Create log entry": {
		no: "Opprett loggmelding"
	}
});
