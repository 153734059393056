import React from "react";
import {translator} from "core";
import {Window, FormLayout, Section, Toolbar} from 'controls/react/layout';
import {MenuButton} from "controls/react";
import {Grid, TextBox, FilterResetButton} from 'controls/react/form';
import {GridMenu} from "controls";
import {Utils, UserPrefs} from "tools";
import {AttributeWindow} from './attributeWindow'
import AttributesApi from './attributesApi'
import {fullTextSearch} from "controls/react/kendoWrappers/grid";
import {saveUserPrefs} from "tools/userPrefs";
import Lang from 'core/localization/lang';

let i = translator({
  "Attributes": {
    "no": "Attributer"
  },
  "No entries message": {
    "en": "This asset has no attributes",
    "no": "Denne ressursen har ingen attributter"
  },
  "Groupable message": {
    "en": "Drag a column header and drop it here to group by that column",
    "no": "Dra en kolonne hit for å gruppere tabellen med den kolonnen"
  }
});

export default class AttributesTab extends React.PureComponent {
	constructor(props) {
		super(props);
		this.menuItems = this.getMenuItems();
		this.state = {
			attributes: [],
			searchValue: null,
			attributeColumns: this.getAttributeColumns(this.getDefaultColumns())
		};
		this.gridMenuRef = React.createRef();
	}

	render() {
		this.settingsObj = this.settingsObj || {};
		let gridSettings = this.getGridSettings();
		return (
			<>
				<Toolbar title={i('Attributes')}>
					<MenuButton items={this.menuItems} ref={this.gridMenuRef}/>
					<TextBox placeholder={i('Search')}
							 large={true}
							 value={this.state.searchValue}
							 onChange={(value) => this.onSearchChange(value)}
							 ref={searchInput => this.searchInput = searchInput}
					/>
					<FilterResetButton
							grid={this.grid}
							searchInput={this.searchInput}
					>
					</FilterResetButton>

				</Toolbar>
				<Grid dataSourceArray={this.state.attributes}
					  selectable={true}
					  sortable={true}
					  sort={gridSettings.gridSort && gridSettings.gridSort.length ? gridSettings.gridSort : [{field: 'group', dir: 'asc'}]}
					  groupable={this.getGridGroupable()}
					  group={gridSettings.gridGroup}
					  forceGroup={true}
					  resizable={true}
					  filterable={this.getGridFilterable()}
					  filter={gridSettings.gridFilter}
					  noRecords={{
						  template: `${i('No entries message')}`
					  }}
					  columns={this.state.attributeColumns}
					  schema={this.getGridSchema()}
					  onRowClicked={this.onRowClicked}
					  onRowsSelected={rows => this.onRowsSelected(rows)}
					  ref={grid => this.grid = grid}
				/>
				{this.state.showAttributeWindow && <AttributeWindow
					attributeData={this.state.attributeData}
					initialData={this.state.initialData}
					existingAttributes={this.state.attributes}
					mode={this.state.mode}
					assetId={this.props.assetId}
					onCancel={() => this.onAttributeWindowCancel()}
					onSave={(attribute, initialData) => this.onAttributeWindowSave(attribute, initialData)}
				></AttributeWindow>}
			</>
		)
	}

	componentDidMount() {
		this.loadUserPreferences();
		this.getAttributes();
		this.gridMenuRef.current.setGrid(this.grid);
	}

	async loadUserPreferences() {
		await UserPrefs.load('Attributes');
		let settingsObj = {};

		let userPrefsGroup = JSON.parse(UserPrefs.get('attributeGroup'));
		if (userPrefsGroup) {
			settingsObj.attributeGroup = userPrefsGroup;
		}

		let userPrefsFilter = JSON.parse(UserPrefs.get('attributeFilter'));
		if (userPrefsFilter) {
			settingsObj.attributeFilter = userPrefsFilter;
		}

		let userPrefsSort = JSON.parse(UserPrefs.get('attributeSort'));
		if (userPrefsSort) {
			settingsObj.attributeSort = userPrefsSort;
		}

		let userPrefsSearchValue = UserPrefs.get('searchValue');

		let userPrefsColumns = JSON.parse(UserPrefs.get('attributeColumns'));
		if (userPrefsColumns) {
			let attributeColumns = this.getAttributeColumns(userPrefsColumns);
			//2 set state calls needed due to kendo grouping bad behaviour if trying to set grouping while changing columns
			this.setState({
				attributeColumns: attributeColumns
			}, function(){
				this.settingsObj = settingsObj;
				this.setState({
					searchValue: userPrefsSearchValue
				});
				//erase the user prefs after setting them; needed in order to reflect the grid changes that do not re-render the react component at re-rendering
				setTimeout( () => {this.settingsObj = {};}, 1000)
			})
		}
	}

	getGridSettings() {
		//to be refactored in a generic way or even moved into grid component
		let gridGroup;
		if (this.settingsObj.attributeGroup) {
			gridGroup = this.settingsObj.attributeGroup;
		} else if (this.grid) {
			let dataSource = this.grid.kendoGrid.dataSource;
			let dataSourceGroup = dataSource.group();
			if (dataSourceGroup && dataSourceGroup.length) {
				gridGroup = dataSourceGroup;
			} else {
				gridGroup = null;
			}
		} else {
			gridGroup = null;
		}

		let gridFilter;
		if (this.settingsObj.attributeFilter) {
			gridFilter = this.settingsObj.attributeFilter.filters ? this.settingsObj.attributeFilter : {};
		} else if (this.state.searchValue !== null) {
			gridFilter = this.getGridSearch();
		} else if (this.grid) {
			let dataSource = this.grid.kendoGrid.dataSource;
			let dataSourceFilter = dataSource.filter();
			if (dataSourceFilter) {
				gridFilter = dataSourceFilter;
			} else {
				gridFilter = null;
			}
		} else {
			gridFilter = null;
		}

		let gridSort;
		if (this.settingsObj.attributeSort) {
			gridSort = this.settingsObj.attributeSort;
		} else if (this.grid) {
			let dataSource = this.grid.kendoGrid.dataSource;
			let dataSourceSort = dataSource.sort();
			if (dataSourceSort && dataSourceSort.length) {
				gridSort = dataSourceSort;
			} else {
				gridSort = null;
			}
		} else {
			gridSort = null;
		}

		return {
			gridGroup: gridGroup,
			gridFilter: gridFilter,
			gridSort: gridSort
		}
	}



	componentWillUnmount() {
		saveUserPrefs({
			category: 'Attributes',
			grid: this.grid.kendoGrid,
			preferences: [{
				key: 'searchValue',
				value: this.state.searchValue
			}],
			keys: {
				columns: 'attributeColumns',
				group: 'attributeGroup',
				filter: 'attributeFilter',
				sort: 'attributeSort'
			}
		})
	}

	async getAttributes() {
		let result = await AttributesApi.getAttributes(this.props.assetId);
		if (result.success) {
			let attributes = result.data;
			this.setState({
				attributes: attributes
			});
		} else {
			Utils.showInfo(lang.ALERT, result.message, result.details);
		}
	}

	onRowClicked = (dataItem) => {
		this.editAttribute(dataItem);
	}

	onRowsSelected(rows){
		this.selectedRows = rows;
	}

	createAttribute() {
		this.setState({
			showAttributeWindow: true,
			attributeData: null,
			initialData: null,
			mode: 'create'
		});
	}

	onEditAttribute() {
		let items = this.grid.getCheckedItems();
		let item = items[0];
		this.editAttribute(item);
	}

	editAttribute(item) {
		delete item.id;
		this.setState({
			showAttributeWindow: true,
			attributeData: item,
			initialData: item,
			mode: 'update'
		});
	}

	async deleteAttribute() {
		let items = this.grid.getCheckedItems();
		for (let i = 0; i < items.length; i++) {
			delete items[i].value;
			delete items[i].id;
		}
		let result = await AttributesApi.deleteAttribute(items, this.props.assetId);
		if (result.success) {
			this.getAttributes();
		} else {
			Utils.showInfo(lang.ALERT, result.message, result.details);
		}
	}

	onAttributeWindowSave = async (attribute, initialData) => {
		let result;
		if (this.state.mode === 'create') {
			result = await AttributesApi.saveAttribute(attribute, this.props.assetId);
		} else if (this.state.mode === 'update') {
			result = await AttributesApi.saveAttribute(attribute, this.props.assetId, initialData)
		}

		if (result.success) {
			this.setState({
				showAttributeWindow: null
			});
			this.getAttributes();
		} else {
			Utils.showInfo(lang.ALERT, result.message, result.details);
		}
	}

	onAttributeWindowCancel = () => {
		this.setState({
			showAttributeWindow: null
		});
	}

	getGridSearch() {
		let searchFilter = fullTextSearch(
			['value', 'key', 'group'],
			this.state.searchValue
		);
		return searchFilter
	}

	onSearchChange(value) {
		this.setState({
			searchValue: value
		});
	}

	getGridFilterable() {
		let filterMessages = lang.grid.filter;
		let gridMessages = {
			clear: lang.CLEAR,
			filter: lang.FILTER
		};
		return  {
			extra: false,
			operators: {
				string: {
					startswith: filterMessages.STARTS_WITH,
					neq: filterMessages.NEQ,
					eq: filterMessages.EQ,
					contains: filterMessages.CONTAINS
				}
			},
			messages: gridMessages
		}
	}

	getGridSchema() {
		return {
			model: {
				fields: {
					value: {
						type: 'string'
					},
					key: {
						type: 'string'
					},
					group: {
						type: 'string'
					}
				}
			},
		}
	}

	getGridGroupable() {
		return {
			messages: {
				empty: i('Groupable message')
			}
		}
	}

	getDefaultColumns() {
		return {
			value: {
				width: '33%'
			},
			key: {
				width: '33%'
			},
			group: {
				width: '33%'
			}
		}
	}

	getAttributeColumns(columns) {
		return [{
			field: 'key',
			title: i('Key'),
			template: '<span class="cw_grid_link">#=key#</span>',
			width: columns.key.width
		}, {
			field: 'value',
			title: i('Value'),
			width: columns.value.width
		}, {
			field: 'group',
			title: i('Group'),
			width: columns.group.width
		}];
	}

	getMenuItems() {
		return [{
			icon: 'plus-sign',
			text: i('Create'),
			fn: () => this.createAttribute()
		}, {
			icon: 'pencil',
			text: i('Edit'),
			fn: () => this.onEditAttribute(),
			enabledIf: GridMenu.ExactlyOneItemSelected

		}, {
			icon: 'bin',
			text: i('Delete'),
			disabled: true,
			fn: () => this.deleteAttribute(),
			enabledIf: GridMenu.AtLeastOneItemSelected
		}];
	}
}

