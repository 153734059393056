module.exports = (function (Highcharts) {
	Highcharts.setOptions({
		global: {
			useUTC: false
		}
	});

	Highcharts.SparkLine = function (options, callback) {
		var defaultOptions = {
			chart: {
				renderTo: (options.chart && options.chart.renderTo) || this,
				backgroundColor: null,
				borderWidth: 0,
				type: 'area',
				margin: [2, 0, 2, 0],
				style: {
					overflow: 'visible'
				},
				skipClone: true
			},
			title: {
				text: ''
			},
			credits: {
				enabled: false
			},
			xAxis: {
				labels: {
					enabled: false
				},
				title: {
					text: null
				},
				startOnTick: false,
				endOnTick: false,
				tickPositions: []
			},
			yAxis: {
				endOnTick: false,
				startOnTick: false,
				labels: {
					enabled: false
				},
				title: {
					text: null
				},
				tickPositions: [0]
			},
			legend: {
				enabled: false
			},
			plotOptions: {
				series: {
					animation: false,
					lineWidth: 1,
					shadow: false,
					states: {
						hover: {
							lineWidth: 1
						}
					},
					marker: {
						radius: 1,
						states: {
							hover: {
								radius: 2
							}
						}
					},
					fillOpacity: 0.25
				},
				column: {
					negativeColor: '#910000',
					borderColor: 'silver'
				}
			}
		};
		options = Highcharts.merge(defaultOptions, options);

		return new Highcharts.Chart(options, callback);
	};
});