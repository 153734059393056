import {Switch} from 'controls';

export default class UnknownInstancesSwitch {
	constructor(prefix, mode) {
		this.prefix = prefix;
		this.mode = mode;
	}

	create() {
		this.unknownInstancesSwitch = new Switch({
			renderTo: $(`#cw_${this.prefix}unknown_instances`),
			isEnabled: this.mode !== 'view'
		});

		return this.unknownInstancesSwitch;
	}
}
