import {Cookies} from "core";
import {UrlBuilder} from "tools";

const root = '/services';

export function getDescriptions(){
	return [
		{
			name: 'service details',
			jspUrl: 'include/Service/ServiceDetails.jsp',
			url: root + '/:id',
			checkAccess: app => {
				if (!app.session.hasRole('SERVICE_READ')) {
					return lang.service.messages.SERVICE_READ_NO_RIGHTS;
				}
			},
			getModuleImport: () => import('./details')
		},
		{
			name: 'service designer',
			jspUrl: 'include/Service/Viewer.jsp',
			url: root + '/:id/viewer',
			bodyCssClass: 'cw_viewer_module',
			getModuleImport: () => import('./viewer')
		}
	];
}

export class ServicesRouter {
	static details(serviceId) {
		return new UrlBuilder(root + '/' + serviceId)
			.build();
	}

	static viewer(serviceId, extra = {}){
		return new UrlBuilder(root + '/' + serviceId + '/viewer')
			.add(extra)
			.build();
	}
}
