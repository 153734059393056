import React from "react";
import classnames from "classnames";
import {useSelector} from 'react-redux'
import {translator} from "core";
import {formValueSelector} from "redux-form";
import { formBuilderForm } from "../constants";

const i = translator({
    "Required field": {
        "no": "Påkrevd felt"
    }
});

const selector = formValueSelector(formBuilderForm)

export default function WildCard(props) {
    const {field, required} = props;
    const hasValue = useSelector(state => selector(state, `properties.${field}`))

    const showWildcard = required && hasValue === '';

    return <div className={classnames("required-wildcard", {hidden: !showWildcard})} title={i('Required field')}>*</div>
}
