import lang from "core/localization/lang-en";

export const StateColors = Object.freeze({
	ERROR: '#e45f48',
	INACTIVE: '#e45f48',
	WARNING: '#f3b749',
	MINOR: '#f7e400',
	ACTIVE: '#7cc623',
	INVALID: "#c4c4c4",
	NO_STATE_COLOR: 'none'
});

export function indicatorToState(indicator) {
	switch (indicator) {
		case '0':
		case 0:
			return 'ACTIVE';
		case '1':
		case 1:
			return 'MINOR';
		case '2':
		case 2:
			return 'WARNING';
		case '3':
		case 3:
			return 'ERROR';
		default:
			return 'INVALID'
	}
}

export function renderSeverityTag(severityValue, item, severityType){
	var html = '', exclamationClass = '', exclamation = '', title = '', cssClass = '', relativePos = '';

	severityType = severityType || '';

	switch (severityValue) {
		case 'NONE':
			cssClass = 'is_ok';
			break;
		case 'MINOR':
			cssClass = 'is_minor';
			break;
		case 'MAJOR':
			cssClass = 'is_major';
			break;
		case 'CRITICAL':
			cssClass = 'is_critical';
			break;
		case null:
			cssClass = 'is_idle';
			break;
	}

	if (item) {
		if (item.monitorError) {
			exclamationClass = 'exclamation-mark';
			title = 'title="' + lang.account.messages.MONITOR_ERROR + '"';
			exclamation = 'data-reason="true" data-monitortype="' + (item.monitorType || '') + '" data-monitorerror="true"';
		}
		if (item.dataUnavailable) {
			exclamationClass = 'exclamation-mark';
			title = 'title="' + lang.account.messages.MONITOR_ERROR_UNAVAILABLE + '"';
		}
	}

	html += '<span class="cw_service_indicator cw_grid_link cw_status ' + cssClass + ' ' + severityType + '">';

	if (item && item.hasEvent == false && item.severity !== 'NONE') {
		html += '<span class="cw_historic_event"></span>';
		relativePos = 'relative';
	}
	html += '<span ' + title + ' class="cw_indicator glyphicons ' + exclamationClass + ' ' + relativePos + '" ' + exclamation + '"></span>';
	html += '</span>';

	if (!severityValue && severityValue !== null) {
		html = '';
	}

	return html;
}
