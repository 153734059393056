import "./widgetForm.less"

import Utils from 'tools/utils';
import State from 'tools/state';
import CustomNotification from 'controls/customNotification';
import Cookies from 'core/cookies';
import Settings from 'settings';

import WidgetConfigurationReactContainer from './widgetConfigurationReactContainer';

import ServicePreviewWidgetConfiguration from 'areas/services/widgets/previewWidgetConfiguration';
import ServiceHistoryWidgetConfiguration from 'areas/services/widgets/historyWidgetConfiguration';
import ServiceSummaryWidgetConfiguration from 'areas/services/widgets/summaryWidgetConfiguration';
import SelectiveServicesWidgetConfiguration from 'areas/services/widgets/selectiveServicesWidgetConfiguration';
import ServiceGridWidgetConfiguration from 'areas/services/widgets/gridWidgetConfiguration';
import ServiceStateWidgetConfiguration from 'areas/services/widgets/stateWidgetConfiguration';

import {DashboardWidgetConfiguration} from 'controls/designer/features/widgets/custom/dashboardWidgetConfiguration';
import {ApplicationWidgetConfiguration} from 'areas/application/widgets/applicationWidgetConfiguration';
import TextWidgetConfiguration from './widgets/textWidgetConfiguration';
import ImageWidgetConfiguration from './widgets/imageWidgetConfiguration';
import TimeWidgetConfiguration from "./widgets/timeWidget/timeWidgetConfiguration";
import WebPageWidgetConfiguration from "./widgets/webPageWidget/webPageWidgetConfiguration";
import GaugeWidgetConfiguration from './widgets/gaugeWidgetConfiguration';
import GenericGaugeWidgetConfiguration from './widgets/genericGaugeWidgetConfiguration';
import GenericMetricsWidgetConfigurationLegacy from 'areas/service-boards/widgets/genericMetricsWidgetConfiguration.legacy';
import GenericMetricsWidgetConfiguration from 'areas/service-boards/widgets/genericMetricsWidgetConfiguration';
import CustomMetricConfiguration from 'areas/service-boards/widgets/customMetricWidgetConfigurationWrapper';

import MetricsWidgetConfiguration from './widgets/metricsWidgetConfiguration';
import MultigraphWidgetConfiguration from './widgets/multigraphWidgetConfiguration';

import AssetConsoleWidgetConfiguration from 'areas/assets/widgets/consoleWidgetConfiguration';
import AssetGridWidgetConfiguration from 'areas/assets/widgets/gridWidgetConfiguration';
import AssetStateWidgetConfiguration from 'areas/assets/widgets/stateWidgetConfiguration';
import AssetGaugeWidgetConfiguration from 'areas/assets/widgets/gaugeWidgetConfiguration';
import AssetMetricsWidgetConfiguration from 'areas/assets/widgets/metricsWidgetConfiguration';
import AssetMultigraphWidgetConfiguration from 'areas/assets/widgets/multigraphWidgetConfiguration';

import SlaWidgetConfiguration from 'areas/sla/widgets/slaWidgetConfiguration';
import SlaHistoryWidgetConfiguration from 'areas/sla/widgets/historyWidgetConfiguration';
import SlaStateWidgetConfiguration from 'areas/sla/widgets/stateWidgetConfiguration';
import SlaTimeWidgetConfiguration from 'areas/sla/widgets/timeWidgetConfiguration';
import SlaGridWidgetConfiguration from 'areas/sla/widgets/gridWidgetConfiguration';

import KpiMetricsWidgetConfiguration from 'areas/kpi/widgets/metricsWidgetConfiguration';
import KpiGaugeWidgetConfiguration from 'areas/kpi/widgets/gaugeWidgetConfiguration';
import KpiMultigraphWidgetConfiguration from 'areas/kpi/widgets/multigraphWidgetConfiguration';
import KpiHistoryWidgetConfiguration from 'areas/kpi/widgets/historyWidgetConfiguration';

import GridSummaryWidgetConfiguration from './widgets/grids/summaryWidgetConfiguration'
import GridReasonsWidgetConfiguration from './widgets/grids/reasonsWidgetConfiguration'
import GridIncidentsWidgetConfiguration from './widgets/grids/incidentsWidgetConfiguration'
import GridEventsSummaryWidgetConfiguration from './widgets/grids/eventsSummaryWidgetConfiguration'



export default function WidgetForm(config) {
	Utils.apply(this, config);
	this.initComponent();
};

WidgetForm.prototype = {
	initComponent: function () {
		/*
		 * @cfg {String} widgetAccountId
		 * Used globaly for setting the next dropdown in a widget form
		 */
		this.widgetAccountId = Cookies.CeesoftCurrentAccountId;
		this.validationMessage = '';
		this.wasUpdate = false;
		this.removeListeners();
		this.attachListeners();

		this.panelbar = $('.cw_wt_panelbar').kendoPanelBar({
			expandMode: "single",
			select: $.proxy(this.onTypeSelect, this)
		}).data('kendoPanelBar');

		if (this.mode === 'update') {
			$('#save_widget').text(lang.UPDATE);
			this.load();
		} else {
			this.panelbar.expand($('#cw_nav_graph'));
			this.panelbar.select($('#cw_multi_graph_asset'));
			this.onTypeSelect();
		}

		this.widgetNotification = new CustomNotification({
			appendToElement: '#cw_widget_status',
			style: 'left: 255px;',
		});

		if(State.currentApp.layout === 'free') {
			$('.widget-form__size-form').show();
		}


		$('#modal').attr('style', 'overflow: hidden; padding-bottom: 0px;');
	},

	removeListeners: function () {
		$('#save_widget, #cancel').off();
	},

	attachListeners: function () {
		$('#cancel').on('click', $.proxy(this.onCancelButton, this));
		$('#save_widget').on('click', $.proxy(this.onSaveButton, this));
	},

	onCancelButton: function (e) {
		var nullParam = null;
		var eraseErrorFields = true;
		Utils.comboBoxValueCheck(nullParam, nullParam, eraseErrorFields);
		this.destroy();

		var modal = $('#modal').data("kendoWindow");
		modal.close();
		modal.destroy();
	},

	onSaveButton: function () {
		var widgetObj, serviceCombo, serviceName, serviceId, slaCombo, slaServiceCombo, slaName, title, accountList,
			wgt, decimals;
		var item = $('.cw_wt_panelbar').data('kendoPanelBar').select();
		var type = item.attr('id'), periodsField;
		var colId = $('#cw_sb_layout').children().first().attr('id');
		var nullParam = null;
		var eraseErrorFields = true;
		Utils.comboBoxValueCheck(nullParam, nullParam, eraseErrorFields);
		$('.cw_widget_settings').css('display', 'none');
		if (this.isValid(type)) {
			widgetObj = this.form.getValues();
			widgetObj.width = $('.widget-form__form-container [name=width]').val();
			widgetObj.height = $('.widget-form__form-container [name=height]').val();
			widgetObj.locked = $('.widget-form__form-container [name=locked]').prop('checked') != '';
			widgetObj.frame = $('.widget-form__form-container [name=frame]').prop('checked') != '';
			//widgetObj.opacity = $('.widget-form__form-container [name=opacity]').val();

			if (this.mode === 'update' || this.wasUpdate) {
				State.currentApp.updateWidget(widgetObj);
			} else {
				if (State.currentApp.layout === 'grid') {
					if (widgetObj.type.indexOf('grid') !== -1) {
						$('#cw_sb_layout').data('gridster').add_widget(State.currentApp.getWidgetHtml(widgetObj), 2);
					} else if (widgetObj.type.indexOf('console') !== -1) {
						$('#cw_sb_layout').data('gridster').add_widget(State.currentApp.getWidgetHtml(widgetObj), 2, 2);
					} else {
						const value = State.currentApp.getWidgetHtml(widgetObj);

						$('#cw_sb_layout').data('gridster').add_widget(value);
					}
				}

				State.currentApp.addWidget(widgetObj);
				State.currentApp.toggleHeaders();
				if (!State.currentApp.headers && State.currentApp.setHeaderItemsHover) {
					State.currentApp.setHeaderItemsHover();
				}
			}
			$('#cancel').trigger('click');
		} else {
			this.widgetNotification.setOptions({
				message: this.validationMessage,
				status: 'error'
			}).show();
		}
	},

	isValid: function (type) {
		var valid = true, validationObject;
		this.validationMessage = '';

		validationObject = this.form.isValid();

		valid = validationObject.valid;
		this.validationMessage = validationObject.validationMessage;

		return valid;
	},

	onTypeSelect: function (e) {
		var html;
		var item = this.panelbar.select();
		var sectionTitle = '';
		if (item.parent().is('ul.k-group')) {
			sectionTitle = $(item).find('.k-link').text();
		} else {
			if (e) {
				var group = $(e.item);
				var firstElement = group.find('.k-group').find('li').first();
				this.panelbar.select(firstElement);
				this.onTypeSelect();
				return;
			} else {
				sectionTitle = $(item).find('.k-header').text();
			}
		}
		var wnd = $('#modal').parent();
		wnd.find('.modal_title').remove();
		wnd.find('.k-window-title').append('<span class="modal_title"> &raquo; ' + sectionTitle + '</span>');
		var type = $(item).attr('id');
		var widget = $('#cw_widget_form');
		//fixing issue when switching tabs on update
		if (this.mode === 'update') {
			this.mode = 'new';
			this.wasUpdate = true;
		}

		if (this.form && this.form.destroy) {
			this.form.destroy();
		}

		this.widgetTemplates({type: $(item).data('type')}, false);
	},

	load: function () {
		var widgetObj = null, html, length = State.currentApp.widgets.length;
		for (var i = 0; i < length; i++) {
			if (State.currentApp.widgets[i].id === this.id) {
				widgetObj = State.currentApp.widgets[i];
				break;
			}
		}

		if (widgetObj) {
			//Utils.apply(this, widgetObj);
			var widget = $('#cw_widget_form');

			this.widgetAccountId = widgetObj.configuration.accountId;
			this.widgetAccountName = widgetObj.configuration.accountName;
			this.widgetTemplates(widgetObj, true);
			this.panelbar.enable($('.cw_wt_panelbar li'), false);
		}
	},
	/**
	 * Handler function which generates html code for a widget - on create and on edit(load)
	 * @param {Object} widgetConfig Widget configuration object
	 * @values type - widget type
	 * @param {Boolean} expand Used for expanding while editing the panelbar
	 */
	widgetTemplates: function (widgetConfig, expand) {
		if (!widgetConfig.configuration) {
			widgetConfig.configuration = {
				accountId: Cookies.CeesoftCurrentAccountId,
				timezone: Cookies.CeesoftTimezone
			};
		}

		const container = $("#cw_widget_form");
		container.empty();

		// change type for legacy widget type
		if (widgetConfig.type === "graph_metric") {
			if (widgetConfig.configuration.metricsItems.length > 1) {
				widgetConfig.type = "multi_graph_asset";
			}
			else {
				widgetConfig.type = "single_graph";
			}
		}
		if(widgetConfig.type){
			$('.widget-form__form-container [name=width]').val(widgetConfig.width || this.defaultWidth);
			$('.widget-form__form-container [name=height]').val(widgetConfig.height || this.defaultHeight);
			$('.widget-form__form-container [name=locked]').prop('checked', widgetConfig.locked == true);
			$('.widget-form__form-container [name=frame]').prop('checked', widgetConfig.frame !== false);
			//$('.widget-form__form-container [name=opacity]').val(widgetConfig.opacity || 100);
		}

		switch (widgetConfig.type) {
			case 'smpreview':
				if (expand) {
					this.panelbar.expand($('#cw_nav_service'));
					this.panelbar.select($('#cw_wt_preview'));
				}
				this.form = new ServicePreviewWidgetConfiguration({
					serviceBoardAccountId: this.serviceBoardAccountId,
					widgetObj: widgetConfig,
					widgetConfigurationFormEl: container,
					mode: this.mode,
					wasUpdate: this.wasUpdate
				});
				break;
			case 'summary':
				if (expand) {
					this.panelbar.expand($('#cw_nav_service'));
					this.panelbar.select($('#cw_wt_summary'));
				}
				this.form = new ServiceSummaryWidgetConfiguration({
					serviceBoardAccountId: this.serviceBoardAccountId,
					widgetObj: widgetConfig,
					widgetConfigurationFormEl: container,
					mode: this.mode,
					wasUpdate: this.wasUpdate
				});
				break;
			case 'history':
				if (expand) {
					this.panelbar.expand($('#cw_nav_service'));
					this.panelbar.select($('#cw_wt_history'));
				}
				this.form = new ServiceHistoryWidgetConfiguration({
					serviceBoardAccountId: this.serviceBoardAccountId,
					widgetObj: widgetConfig,
					widgetConfigurationFormEl: container,
					mode: this.mode,
					wasUpdate: this.wasUpdate
				});
				break;
			case 'services':
				if (expand) {
					this.panelbar.expand($('#cw_nav_service'));
					this.panelbar.select($('#cw_wt_bc_services_state'));
				}
				this.form = new SelectiveServicesWidgetConfiguration({
					serviceBoardAccountId: this.serviceBoardAccountId,
					widgetObj: widgetConfig,
					widgetConfigurationFormEl: container,
					mode: this.mode,
					wasUpdate: this.wasUpdate
				});
				break;
			case 'grid_service':
				if (expand) {
					this.panelbar.expand($('#cw_nav_service'));
					this.panelbar.select($('#cw_wt_grid_service'));
				}
				this.form = new ServiceGridWidgetConfiguration({
					serviceBoardAccountId: this.serviceBoardAccountId,
					widgetObj: widgetConfig,
					widgetConfigurationFormEl: container,
					mode: this.mode,
					wasUpdate: this.wasUpdate
				});
				break;
			case 'bar_ss':
				if (expand) {
					this.panelbar.expand($('#cw_nav_service'));
					this.panelbar.select($('#cw_wt_bc_state'));
				}
				this.form = new ServiceStateWidgetConfiguration({
					serviceBoardAccountId: this.serviceBoardAccountId,
					widgetObj: widgetConfig,
					widgetConfigurationFormEl: container,
					mode: this.mode,
					wasUpdate: this.wasUpdate,
					widgetType: 'bar_ss'
				});
				break;
			case 'metrics':
				//convert old monitor metrics type to generic multigraph
				if (widgetConfig.configuration.type === 'MONITOR') {
					if (expand) {
						this.panelbar.expand($('#cw_nav_graph'));
						this.panelbar.select($('#cw_graph_metric'));
					}
					widgetConfig.configuration.metrics = [widgetConfig.configuration.qualifier.id];
					var data = widgetConfig.configuration.metrics || [];
					var url = Settings.serverPath + 'accounts/' + this.widgetAccountId + '/metrics/registeredMetrics';
					Utils.ajax(url, 'POST', JSON.stringify(data), $.proxy(function (result) {
						widgetConfig.configuration.metricsItems = result;
						for (var i = 0; i < widgetConfig.configuration.metricsItems.length; i++) {
							widgetConfig.configuration.metricsItems[i].id = Utils.guid();
						}

						$('#cw_widget_form').empty().html('<div id="cw_metrics_form"></div>');
						widgetConfig.widgetObjComplete = true;
						this.form = new GenericMetricsWidgetConfiguration({
							renderTo: $('#cw_metrics_form'),
							serviceBoardAccountId: this.serviceBoardAccountId,
							widgetObj: widgetConfig,
							widgetConfigurationFormEl: container,
							mode: this.mode,
							wasUpdate: this.wasUpdate,
							type: 'graph_metric'
						});
					}, this));
				} else {
					if (expand) {
						this.panelbar.expand($('#cw_nav_service'));
						this.panelbar.select($('#cw_wt_metrics'));
					}
					this.form = new MetricsWidgetConfiguration({
						serviceBoardAccountId: this.serviceBoardAccountId,
						widgetObj: widgetConfig,
						widgetConfigurationFormEl: container,
						mode: this.mode,
						wasUpdate: this.wasUpdate
					});
				}
				break;
			case 'service_presentation':
				if (expand) {
					this.panelbar.expand($('#cw_nav_presentation'));
					this.panelbar.select($('#cw_wt_presentation'));
				}
				this.form = new DashboardWidgetConfiguration({
					serviceBoardAccountId: this.serviceBoardAccountId,
					widgetObj: widgetConfig,
					widgetConfigurationFormEl: container,
					mode: this.mode,
					wasUpdate: this.wasUpdate
				});
				break;
			case 'application_preview':
				if (expand) {
					this.panelbar.expand($('#cw_nav_application'));
					this.panelbar.select($('#cw_wt_application_preview'));
				}
				this.form = new ApplicationWidgetConfiguration({
					serviceBoardAccountId: this.serviceBoardAccountId,
					widgetObj: widgetConfig,
					widgetConfigurationFormEl: container,
					mode: this.mode,
					wasUpdate: this.wasUpdate
				});
				break;
			case 'application_state':
				if (expand) {
					this.panelbar.expand($('#cw_nav_application'));
					this.panelbar.select($('#cw_wt_application_state'));
				}
				this.form = new ServiceStateWidgetConfiguration({
					serviceBoardAccountId: this.serviceBoardAccountId,
					widgetObj: widgetConfig,
					widgetConfigurationFormEl: container,
					mode: this.mode,
					wasUpdate: this.wasUpdate,
					widgetType: 'application_state'
				});
				break;
			case 'gauge':
				//convert old monitor metrics type to generic gauge
				if (widgetConfig.configuration.type === 'MONITOR') {
					if (expand) {
						this.panelbar.expand($('#cw_nav_graph'));
						this.panelbar.select($('#cw_graph_gauge'));
					}
					widgetConfig.configuration.metrics = [widgetConfig.configuration.qualifier.id];
					var data = widgetConfig.configuration.metrics || [];
					var url = Settings.serverPath + 'accounts/' + this.widgetAccountId + '/metrics/registeredMetrics';
					Utils.ajax(url, 'POST', JSON.stringify(data), $.proxy(function (result) {
						widgetConfig.configuration.metricsItem = result[0];
						widgetConfig.configuration.metricsItem.id = Utils.guid();
						$('#cw_widget_form').empty().html('<div id="cw_metrics_form"></div>');
						this.form = new GenericGaugeWidgetConfiguration({
							renderTo: $('#cw_metrics_form'),
							serviceBoardAccountId: this.serviceBoardAccountId,
							widgetObj: widgetConfig,
							widgetConfigurationFormEl: container,
							mode: this.mode,
							wasUpdate: this.wasUpdate,
							type: 'graph_gauge'
						});
					}, this));
				} else {
					if (expand) {
						this.panelbar.expand($('#cw_nav_service'));
						this.panelbar.select($('#cw_wt_gauge'));
					}
					this.form = new GaugeWidgetConfiguration({
						serviceBoardAccountId: this.serviceBoardAccountId,
						widgetObj: widgetConfig,
						widgetConfigurationFormEl: container,
						mode: this.mode,
						wasUpdate: this.wasUpdate
					});
				}
				break;
			case 'multigraph':
				//convert old monitor metrics type to generic multigraph
				if (widgetConfig.configuration.type === 'MONITOR') {
					if (expand) {
						this.panelbar.expand($('#cw_nav_graph'));
						this.panelbar.select($('#cw_graph_metric'));
					}
					widgetConfig.configuration.metrics = widgetConfig.configuration.qualifiersList;
					var data = widgetConfig.configuration.metrics || [];
					var url = Settings.serverPath + 'accounts/' + this.widgetAccountId + '/metrics/registeredMetrics';
					Utils.ajax(url, 'POST', JSON.stringify(data), $.proxy(function (result) {
						widgetConfig.configuration.metricsItems = result;
						for (var i = 0; i < widgetConfig.configuration.metricsItems.length; i++) {
							widgetConfig.configuration.metricsItems[i].id = Utils.guid();
						}

						$('#cw_widget_form').empty().html('<div id="cw_metrics_form"></div>');
						widgetConfig.widgetObjComplete = true;
						this.form = new GenericMetricsWidgetConfiguration({
							renderTo: $('#cw_metrics_form'),
							serviceBoardAccountId: this.serviceBoardAccountId,
							widgetObj: widgetConfig,
							widgetConfigurationFormEl: container,
							mode: this.mode,
							wasUpdate: this.wasUpdate,
							type: 'graph_metric'
						});
					}, this));
				} else {
					if (expand) {
						this.panelbar.expand($('#cw_nav_service'));
						this.panelbar.select($('#cw_wt_multigraph'));
					}
					this.form = new MultigraphWidgetConfiguration({
						serviceBoardAccountId: this.serviceBoardAccountId,
						widgetObj: widgetConfig,
						widgetConfigurationFormEl: container,
						mode: this.mode,
						wasUpdate: this.wasUpdate
					});
				}
				break;
			case 'stacked_chart':
				if (expand) {
					this.panelbar.expand($('#cw_nav_graph'));
					this.panelbar.select($('#cw_stacked_chart'));
				}
				this.form = new GenericMetricsWidgetConfigurationLegacy({
					serviceBoardAccountId: this.serviceBoardAccountId,
					widgetObj: widgetConfig,
					widgetConfigurationFormEl: container,
					mode: this.mode,
					wasUpdate: this.wasUpdate,
					type: 'stacked_chart'
				});
				break;
			case 'custom_metric':
				if (expand) {
					this.panelbar.expand($('#cw_nav_graph'));
					this.panelbar.select($('#cw_custom_metric'));
				}
				this.form = new CustomMetricConfiguration({
					serviceBoardAccountId: this.serviceBoardAccountId,
					widgetObj: widgetConfig,
					widgetConfigurationFormEl: container,
					mode: this.mode,
					wasUpdate: this.wasUpdate,
					type: 'custom_metric'
				});
				break;
			case 'single_graph':
			case 'multi_graph_asset':
			case 'multi_graph_assetgroup':
				if (expand) {
					this.panelbar.expand($('#cw_nav_graph'));
					if (widgetConfig.type === 'single_graph') {
						this.panelbar.select($('#cw_single_graph'));
					} else if (widgetConfig.type === 'multi_graph_asset') {
						this.panelbar.select($('#cw_multi_graph_asset'));
					} else if (widgetConfig.type === 'multi_graph_assetgroup') {
						this.panelbar.select($('#cw_multi_graph_assetgroup'));
					}
				}
				this.form = new GenericMetricsWidgetConfiguration({
					serviceBoardAccountId: this.serviceBoardAccountId,
					widgetObj: widgetConfig,
					widgetConfigurationFormEl: container,
					mode: this.mode,
					wasUpdate: this.wasUpdate,
					type: widgetConfig.type
				});
				break;
			case 'graph_metric':
				if (expand) {
					this.panelbar.expand($('#cw_nav_graph'));
					this.panelbar.select($('#cw_graph_metric'));
				}
				var data = widgetConfig.configuration.metrics || [];

				var url = Settings.serverPath + 'accounts/' + this.widgetAccountId + '/metrics/registeredMetrics';
				Utils.ajax(url, 'POST', JSON.stringify(data), $.proxy(function (result) {
					if (widgetConfig.configuration.metricsItems) {
						for (var i = 0; i < widgetConfig.configuration.metricsItems.length; i++) {
							for (var j = 0; j < result.length; j++) {
								if (widgetConfig.configuration.metricsItems[i].metricId === result[j].metricId) {
									result[j].customUnit = widgetConfig.configuration.metricsItems[i].customUnit;
									result[j].conversion = widgetConfig.configuration.metricsItems[i].conversion;
									result[j].color = widgetConfig.configuration.metricsItems[i].color;
								}
							}
						}
					}
					widgetConfig.configuration.metricsItems = result;
					for (var i = 0; i < widgetConfig.configuration.metricsItems.length; i++) {
						widgetConfig.configuration.metricsItems[i].id = Utils.guid();
					}
					$('#cw_widget_form').empty().html('<div id="cw_metrics_form"></div>');
					this.form = new GenericMetricsWidgetConfigurationLegacy({
						renderTo: $('#cw_metrics_form'),
						serviceBoardAccountId: this.serviceBoardAccountId,
						widgetObj: widgetConfig,
						widgetConfigurationFormEl: container,
						mode: this.mode,
						wasUpdate: this.wasUpdate,
						type: 'graph_metric'
					});
				}, this));
				break;
			case 'graph_gauge':
				if (expand) {
					this.panelbar.expand($('#cw_nav_graph'));
					this.panelbar.select($('#cw_graph_gauge'));
				}
				this.form = new GenericGaugeWidgetConfiguration({
					renderTo: $('#cw_metrics_form'),
					serviceBoardAccountId: this.serviceBoardAccountId,
					widgetObj: widgetConfig,
					widgetConfigurationFormEl: container,
					mode: this.mode,
					wasUpdate: this.wasUpdate,
					type: 'graph_gauge'
				});
				break;

			case 'grid_asset':
				if (expand) {
					this.panelbar.expand($('#cw_nav_asset'));
					this.panelbar.select($('#cw_wt_grid_asset'));
				}
				this.form = new AssetGridWidgetConfiguration({
					serviceBoardAccountId: this.serviceBoardAccountId,
					widgetObj: widgetConfig,
					widgetConfigurationFormEl: container,
					mode: this.mode,
					wasUpdate: this.wasUpdate
				});
				break;
			case 'asset_console':
				if (expand) {
					this.panelbar.expand($('#cw_nav_asset'));
					this.panelbar.select($('#cw_asset_console'));
				}
				this.form = new AssetConsoleWidgetConfiguration({
					serviceBoardAccountId: this.serviceBoardAccountId,
					widgetObj: widgetConfig,
					widgetConfigurationFormEl: container,
					mode: this.mode,
					wasUpdate: this.wasUpdate
				});
				break;
			case 'bar_sh':
				if (expand) {
					this.panelbar.expand($('#cw_nav_asset'));
					this.panelbar.select($('#cw_wt_bc_health'));
				}
				this.form = new AssetStateWidgetConfiguration({
					serviceBoardAccountId: this.serviceBoardAccountId,
					widgetObj: widgetConfig,
					widgetConfigurationFormEl: container,
					mode: this.mode,
					wasUpdate: this.wasUpdate
				});
				break;
			case 'asset_metrics':
				if (expand) {
					this.panelbar.expand($('#cw_nav_health'));
					this.panelbar.select($('#cw_asset_metrics'));
				}
				this.form = new AssetMetricsWidgetConfiguration({
					serviceBoardAccountId: this.serviceBoardAccountId,
					widgetObj: widgetConfig,
					widgetConfigurationFormEl: container,
					mode: this.mode,
					wasUpdate: this.wasUpdate
				});
				break;
			case 'asset_gauge':
				if (expand) {
					this.panelbar.expand($('#cw_nav_health'));
					this.panelbar.select($('#cw_asset_gauge'));
				}
				this.form = new AssetGaugeWidgetConfiguration({
					serviceBoardAccountId: this.serviceBoardAccountId,
					widgetObj: widgetConfig,
					widgetConfigurationFormEl: container,
					mode: this.mode,
					wasUpdate: this.wasUpdate
				});
				break;
			case 'asset_multigraph':
				if (expand) {
					this.panelbar.expand($('#cw_nav_health'));
					this.panelbar.select($('#cw_asset_multigraph'));
				}
				this.form = new AssetMultigraphWidgetConfiguration({
					serviceBoardAccountId: this.serviceBoardAccountId,
					widgetObj: widgetConfig,
					widgetConfigurationFormEl: container,
					mode: this.mode,
					wasUpdate: this.wasUpdate
				});
				break;


			case 'sla':
				if (expand) {
					this.panelbar.expand($('#cw_nav_sla'));
					this.panelbar.select($('#cw_wt_sla'));
				}
				this.form = new SlaWidgetConfiguration({
					serviceBoardAccountId: this.serviceBoardAccountId,
					widgetObj: widgetConfig,
					widgetConfigurationFormEl: container,
					mode: this.mode,
					wasUpdate: this.wasUpdate
				});
				break;
			case 'sla_history':
				if (expand) {
					this.panelbar.expand($('#cw_nav_sla'));
					this.panelbar.select($('#cw_wt_sla_history'));
				}
				this.form = new SlaHistoryWidgetConfiguration({
					serviceBoardAccountId: this.serviceBoardAccountId,
					widgetObj: widgetConfig,
					widgetConfigurationFormEl: container,
					mode: this.mode,
					wasUpdate: this.wasUpdate
				});
				break;
			case 'bar_sla':
				if (expand) {
					this.panelbar.expand($('#cw_nav_sla'));
					this.panelbar.select($('#cw_wt_bc_sla'));
				}
				this.form = new SlaStateWidgetConfiguration({
					serviceBoardAccountId: this.serviceBoardAccountId,
					widgetObj: widgetConfig,
					widgetConfigurationFormEl: container,
					mode: this.mode,
					wasUpdate: this.wasUpdate
				});
				break;
			case 'sla_time':
				if (expand) {
					this.panelbar.expand($('#cw_nav_sla'));
					this.panelbar.select($('#cw_wt_sla_time'));
				}
				this.form = new SlaTimeWidgetConfiguration({
					serviceBoardAccountId: this.serviceBoardAccountId,
					widgetObj: widgetConfig,
					widgetConfigurationFormEl: container,
					mode: this.mode,
					wasUpdate: this.wasUpdate
				});
				break;
			case 'grid_sla':
				if (expand) {
					this.panelbar.expand($('#cw_nav_sla'));
					this.panelbar.select($('#cw_wt_grid_sla'));
				}
				this.form = new SlaGridWidgetConfiguration({
					serviceBoardAccountId: this.serviceBoardAccountId,
					widgetObj: widgetConfig,
					widgetConfigurationFormEl: container,
					mode: this.mode,
					wasUpdate: this.wasUpdate
				});
				break;


			case 'kpi_metrics':
				if (expand) {
					this.panelbar.expand($('#cw_nav_kpi'));
					this.panelbar.select($('#cw_kpi_metrics'));
				}
				this.form = new KpiMetricsWidgetConfiguration({
					serviceBoardAccountId: this.serviceBoardAccountId,
					widgetObj: widgetConfig,
					widgetConfigurationFormEl: container,
					mode: this.mode,
					wasUpdate: this.wasUpdate
				});
				break;
			case 'kpi_gauge':
				if (expand) {
					this.panelbar.expand($('#cw_nav_kpi'));
					this.panelbar.select($('#cw_kpi_gauge'));
				}
				this.form = new KpiGaugeWidgetConfiguration({
					serviceBoardAccountId: this.serviceBoardAccountId,
					widgetObj: widgetConfig,
					widgetConfigurationFormEl: container,
					mode: this.mode,
					wasUpdate: this.wasUpdate
				});
				break;
			case 'kpi_multigraph':
				if (expand) {
					this.panelbar.expand($('#cw_nav_kpi'));
					this.panelbar.select($('#cw_kpi_multigraph'));
				}
				this.form = new KpiMultigraphWidgetConfiguration({
					serviceBoardAccountId: this.serviceBoardAccountId,
					widgetObj: widgetConfig,
					widgetConfigurationFormEl: container,
					mode: this.mode,
					wasUpdate: this.wasUpdate
				});
				break;
			case 'kpi_history':
				if (expand) {
					this.panelbar.expand($('#cw_nav_kpi'));
					this.panelbar.select($('#cw_kpi_history'));
				}
				this.form = new KpiHistoryWidgetConfiguration({
					serviceBoardAccountId: this.serviceBoardAccountId,
					widgetObj: widgetConfig,
					widgetConfigurationFormEl: container,
					mode: this.mode,
					wasUpdate: this.wasUpdate
				});
				break;


			case 'grid_summary':
				if (expand) {
					this.panelbar.expand($('#cw_nav_grid'));
					this.panelbar.select($('#cw_wt_grid_summary'));
				}
				this.form = new GridSummaryWidgetConfiguration({
					serviceBoardAccountId: this.serviceBoardAccountId,
					widgetObj: widgetConfig,
					widgetConfigurationFormEl: container,
					mode: this.mode,
					wasUpdate: this.wasUpdate
				});
				break;
			case 'grid_reasons':
				if (expand) {
					this.panelbar.expand($('#cw_nav_grid'));
					this.panelbar.select($('#cw_wt_grid_reasons'));
				}
				this.form = new GridReasonsWidgetConfiguration({
					serviceBoardAccountId: this.serviceBoardAccountId,
					widgetObj: widgetConfig,
					widgetConfigurationFormEl: container,
					mode: this.mode,
					wasUpdate: this.wasUpdate
				});
				break;
			case 'grid_incidents':
				if (expand) {
					this.panelbar.expand($('#cw_nav_grid'));
					this.panelbar.select($('#cw_wt_grid_incidents'));
				}
				this.form = new GridIncidentsWidgetConfiguration({
					serviceBoardAccountId: this.serviceBoardAccountId,
					widgetObj: widgetConfig,
					widgetConfigurationFormEl: container,
					mode: this.mode,
					wasUpdate: this.wasUpdate
				});
				break;
			case 'grid_event_summary':
				if (expand) {
					this.panelbar.expand($('#cw_nav_grid'));
					this.panelbar.select($('#cw_wt_grid_event_summary'));
				}
				this.form = new GridEventsSummaryWidgetConfiguration({
					serviceBoardAccountId: this.serviceBoardAccountId,
					widgetObj: widgetConfig,
					widgetConfigurationFormEl: container,
					mode: this.mode,
					wasUpdate: this.wasUpdate
				});
				break;
			case 'custom_text':
				if (expand) {
					this.panelbar.expand($('#cw_nav_custom'));
					this.panelbar.select($('#cw_wt_custom_text'));
				}
				this.form = new TextWidgetConfiguration({
					widgetObj: widgetConfig,
					widgetConfigurationFormEl: container,
					mode: this.mode,
					wasUpdate: this.wasUpdate
				});
				break;

			case 'custom_image':
				if (expand) {
					this.panelbar.expand($('#cw_nav_custom'));
					this.panelbar.select($('#cw_wt_custom_image'));
				}
				this.form = new ImageWidgetConfiguration({
					widgetConfigurationFormEl: container,
					widgetObj: widgetConfig
				});
				break;

			case 'custom_time':
				if (expand) {
					this.panelbar.expand($('#cw_nav_custom'));
					this.panelbar.select($('#cw_wt_custom_time'));
				}

				this.form = new WidgetConfigurationReactContainer(
					TimeWidgetConfiguration,
					applyDefaultConfig(
						widgetConfig,
						{
							timezone: Cookies.CeesoftTimezone,
							dateformat: Cookies.CeesoftUserDateFormat,
							timeformat: Cookies.CeesoftUserTimeFormat
						},
						'Time widget'
					)
				);
				break;

			case 'custom_webPage':
				if (expand) {
					this.panelbar.expand($('#cw_nav_custom'));
					this.panelbar.select($('#cw_wt_custom_webPage'));
				}

				this.form = new WidgetConfigurationReactContainer(
					WebPageWidgetConfiguration,
					applyDefaultConfig(
						widgetConfig,
						{
							refreshRate: 3600,
							url: ''
						},
						'Web page widget'
					)
				);
				break;
		}

		this.tooltip = $('.cw_tooltip').kendoTooltip({
			content: function (e) {
				return $(e.target).data('title');
			}
		}).data("kendoTooltip");
	},
	/*
	* Called when resize ends
	*/
	resize: function () {
		this.form && this.form.resize && this.form.resize();
	},
	/*
	* Destroy method
	* */
	destroy: function () {
		if (this.form && this.form.destroy) {
			this.form.destroy();
		}
	}
}

function applyDefaultConfig(widgetConfig, defaultConfiguration, defaultTitle){
	widgetConfig.title = widgetConfig.title || defaultTitle;
	widgetConfig.configuration = {
		...defaultConfiguration,
		...widgetConfig.configuration
	}

	return widgetConfig;
}
