import React from 'react';
import PropTypes from 'prop-types';

import './schedule.less'
import RepeatPanel from './repeatPanel';

import {b} from './common'
import Days from "./days";
import StartTime from './startTime';
import reducer, {SET_ERROR, SET_RULE, validate} from './reducer';

import RRule from 'rrule';
import EndTime from "./endTime";
import MonthlyPanel from "./monthlyPanel";
import OnMonthDay from "./onMonthDay";
import defaultSchedule from "./defaultSchedule";
import RRuleError from "controls/react/form/formBuilder/subforms/fields/ceInterval/error";
import moment from 'moment-timezone';

const BEGIN_VEVENT = "BEGIN:VEVENT";
const END_VEVENT = "END:VEVENT";

function toUTC(str, timezone) {
	const DATE_REGEXP = /(\d{8}T\d{6})([\s;])/gm;

	for (const [match, p1, p2] of str.matchAll(DATE_REGEXP)) {
		if (p1) {
			// tz(p1, timezone)
			const newDate = moment(p1).utc().format("YYYYMMDDTHHmmss") + "Z";
			str = str.replace(match, [newDate, p2].join(""));
		}
	}

	return str;
}

function fromUTC(str, timezone) {
	const DATE_REGEXP = /(\d{8}T\d{6}Z)/gm;

	for (const [match, p1] of str.matchAll(DATE_REGEXP)) {
		if (p1) {
			// tz(timezone)
			const newDate = moment(p1).local().format("YYYYMMDDTHHmmss");
			str = str.replace(match, newDate);
		}
	}

	return str;
}

function unwrapStr(str) {
	const beginIndex = str.indexOf(BEGIN_VEVENT);
	const endIndex = str.indexOf(END_VEVENT);
	let rruleStr;

	if (beginIndex >= 0 && endIndex >= 0) {
		rruleStr = str.substring(beginIndex + BEGIN_VEVENT.length, endIndex).trim();
	}
	else {
		rruleStr = str;
	}

	return rruleStr;
}

function Schedule(props) {
	const [rule, dispatch] = React.useReducer(reducer, {});

	React.useEffect(() => {
		let rrule;

		try {
			rrule = RRule.fromString(toUTC(unwrapStr(props.rule), props.timezone));
			dispatch({type: SET_RULE, value: rrule.origOptions});
		}
		catch(e) {
			rrule = RRule.fromString(unwrapStr(defaultSchedule(props.timezone)));
			dispatch({type: SET_RULE, value: rrule.origOptions});
			dispatch({type: SET_ERROR, value: true});
		}
	}, []);

	React.useEffect(() => {
		const {error, ...rrule} = rule;

		const outRule = new RRule(rrule);
		const str = fromUTC(outRule.toString(), props.timezone);

		if (str !== "") {
			props.onChange(`${BEGIN_VEVENT}\r\n${str}\r\n${END_VEVENT}`);
			props.onValidate(validate(rrule));
		}
	}, [rule]);

	return <div className={b()}>
		<RRuleError error={rule.error} />
		<StartTime dtstart={rule.dtstart} dispatch={dispatch} />
		<RepeatPanel frequency={rule.freq} internal={rule.interval} dispatch={dispatch} />
		<Days frequency={rule.freq} days={rule.byweekday} dispatch={dispatch} />
		<MonthlyPanel frequency={rule.freq} bymonthday={rule.bymonthday} bysetpos={rule.bysetpos} byweekday={rule.byweekday} dispatch={dispatch}/>
		<OnMonthDay frequency={rule.freq} bymonthday={rule.bymonthday} bymonth={rule.bymonth} dispatch={dispatch} />
		<EndTime until={rule.until} dispatch={dispatch} />
	</div>
}

Schedule.propTypes = {
	rule: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	onValidate: PropTypes.func,
	timezone: PropTypes.string.isRequired
};

Schedule.defaultProps = {
	onValidate: () => {}
};

export default Schedule;
