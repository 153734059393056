import {UrlBuilder} from './../../tools/urlBuilder';

export function getDescriptions(){
	return [{
		url: '/cost-:type',
		getModuleImport: () => import('./list'),
		react: true
	}, {
		url: '/costs/:id',
		getModuleImport: () => import('./costProfileDetails'),
		react: true
	}];
}

export class CostsRouter {
	static list() {
		return '/costs'
	}

	static listByType(type) {
		return '/' + type;
	}

	static details(costId, expandToRecord, recordModelId) {
		return new UrlBuilder(`/costs/${costId}`)
			.add('expandToRecord', expandToRecord, !!expandToRecord)
			.add('recordModelId', recordModelId, !!recordModelId)
			.build();
	}
}
