import './details.less';
import {CeeViewDataSource, DataSourceSeverity, Renderer, State, Utils} from "tools"
import {MonitorConfiguration} from "areas/assets/monitors/configurations";
import {
	CustomNotification,
	GridMenu,
	Tags
} from "controls"
import {Settings} from "settings"
import {Cookies} from "core/cookies"
import {ErrorHandler} from "core"
import {DocumentationButtonWrapper} from "controls/react/form/documentationButtonWrapper"
import {NavigationStore} from "framework/navigationStore";
import {apiFetch} from "framework/api";
import {copyMonitor} from "./api";
import {AuthenticationDropDown} from "controls/react";
import ReactDOM from 'react-dom';
import React from 'react';


const i18n = require('core/localization/localization').translator({
  "The key must be removed from the filter before it can be deleted": {
    "no": "Nøkkelen må fjernes fra filteret før den kan slettes"
  },
  "Duplicate severities are not allowed": {
    "no": "Lik alvorlighet er ikke tillatt"
  },
  "Duplicate name not allowed": {
    "no": "Duplikat navn ikke tillatt"
  },
  "From: ": {
    "no": "Fra: "
  },
  "Reply To: ": {
    "no": "Svar til: "
  },
  "Subject: ": {
    "no": "Tittel: "
  },
  "Body: ": {
    "no": "Meldingstekst"
  },
  "Test result": {
    "no": "Testresultat"
  },
  "Value": {
    "no": "Verdi"
  },
  "Expected Value": {
    "no": "Forventet verdi"
  },
  "Valid": {
    "no": "Gyldig"
  },
  "Suppression": {
    "no": "Undertrykk"
  },
  "Please input name...": {
    "no": "Skriv inn navn..."
  }
});

export default function Details(config) {
	/*
	 @values create, view, update
	 */
	Utils.apply(this, config);
	
	// this.accountContext = this.accountId || Cookies.CeesoftCurrentAccountId;
	// State.mainApp.addLoadingMask();
	this.init();
}

jQuery.extend(Details.prototype, MonitorConfiguration.prototype, {
	
	async init() {
		this.accountContext = this.accountId || Cookies.CeesoftCurrentAccountId;

		if (this.id) {
			this.mode = 'update'
		} else {
			this.mode = 'create'
		}
		this.update = true;
		this.data = {};
		this.checkForUnread = false;
		if (!State.mainApp.session.hasRole('MONITOR_UPDATE')) {
			this.mode = 'view';
			this.update = false;
		}
		this.descriptionTooltip = 'data-tooltip="generic" data-title="' + lang.monitor.description + '"';

		this.initComponent();

		this.actionNotification = new CustomNotification({
			appendToElement: '.cw_data',
			type: 'icon',
			hideOnClick: true
		});

		this.notification = new CustomNotification({
			appendToElement: '.cw_mg_settings',
			status: 'success',
			type: 'icon',
			hideOnClick: true
		});

		var actionButtonText = lang.CREATE;
		if (this.mode === 'update') {
			actionButtonText = lang.UPDATE;

			$('.update_only').removeClass('hide');
		}
		$('#cw_save').text(actionButtonText);

		this.removeListeners();
		this.attachListeners();
		this.removeMask();

		if (this.mode === 'update') {
			$('.cw_window_actions').removeClass('hide');
		} else {
			$('.cw_window_actions').removeClass('hide');
			$('#cw_next').removeClass('hide');

			$('.is_readonly').attr('readonly', false);
		}

		Utils.equalHeight('.cw_view_settings .cw_section_content', '.cw_view_data');

		if (this.mode === 'update' || this.mode === 'view'|| this.sourceId?.length) {
			await this.load();
		} else {
			await this.loadDefaults();
		}
		if (this.mode === 'view') {
			$('#cw_name').addClass('is_readonly');
			$('#cw_hostname').addClass('is_readonly');
			$('#cw_port').addClass('is_readonly');
			$('#cw_username').addClass('is_readonly');
			$('#cw_password').addClass('is_readonly');
			$('#cw_expiry_time').addClass('is_readonly');
			$('#cw_mail_read_number').addClass('is_readonly');
			$('#cw_mail_storm_count').addClass('is_readonly');
			$('#cw_authenticate').addClass('is_readonly');
			$('#cw_use_ssl').attr('disabled', 'disabled');
			$('#cw_delete_after_read').attr('disabled', 'disabled');
			$('#cw_check_for_unread').attr('disabled', 'disabled');
			this.descriptionHandler.readonly(true);
			$('.is_readonly').attr('readonly', true);
			this.removeListeners();
			$('#cw_cancel').on('click', $.proxy(this.onCancel, this));
		}

		this.renderAuthDropDown();
		if(this.data.profile?.authenticationId)
			this.connectType = "OAUTH2";
		else
			this.connectType = "GENERIC";
		this.toggleConnectTypeRadioButtons();
	},

	/**
	 * Initializes kendo controls
	 */
	initKendoComponents() {
		this.initAgentList();

		this.documentationButton = new DocumentationButtonWrapper({
			appendToElement: '.cw_documentation',
			monitorType: 'MAILGTW'
		});

		this.tagsControl = new Tags({
			renderEl: $('#cw_tags'),
			enabled: this.mode === 'view' ? false : true
		});

		this.protocolList = $('#cw_protocol').kendoDropDownList({
			dataSource: new CeeViewDataSource({
				transport: {
					read: {
						url: Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/monitors/MAILGTW/protocolTypes',
						contentType: 'application/json; charset=utf-8',
						type: 'GET',
						dataType: 'json',
						cache: false
					}
				},
				error: ErrorHandler.kendoServerError
			}),
			change: $.proxy(this.onProtocolSelect, this),
			dataTextField: 'text',
			dataValueField: 'value',
			optionLabel: lang.SELECT,
			enable: this.mode === 'view' ? false : true,
			dataBound: $.proxy(function (e) {
				if (this.mode === 'create') {
					e.sender.select(1);
					e.sender.trigger('change');
				} else if (this.mode === 'update') {
					$('#cw_protocol').data('kendoDropDownList').value(this.protocol);
				}
			}, this)
		}).data('kendoComboBox');

		this.mailReadControl = $('#cw_mail_read_number').kendoNumericTextBox({
			decimals: 0,
			min: 0,
			spinners: false,
			value: 20,
			format: 'n0'
		}).data('kendoNumericTextBox');

		this.mailStormCount = $('#cw_mail_storm_count').kendoNumericTextBox({
			decimals: 0,
			min: 100,
			spinners: false,
			value: 200,
			format: 'n0'
		}).data('kendoNumericTextBox');

		this.menu = new GridMenu({
			renderTo: 'cw_filters_menu',
			items: [{
				id: 'cw_create_filter',
				icon: 'plus',
				text: lang.CREATE,
				fn: this.onCreateFilter,
				scope: this,
				disabled: this.mode === 'view' ? true : false
			}]
		});

		this.monitorErrorOpenList = $('#cw_monitor_error_open').kendoDropDownList({
			dataTextField: 'text',
			dataValueField: 'value',
			dataSource: DataSourceSeverity,
			change: $.proxy(function (e) {
				this.monitorErrorOpen = e.sender.value();
			}, this),
			open: function (e) {
				e.sender.popup.wrapper.addClass('cw_indicator_dropdown');
			},
			autoBind: true,
			enable: this.mode === 'view' ? false : true,
			template: '<span class="cw_status_indicator cw_status_widget_color cw_color${data.color}"></span><span>${data.text}</span>',
			valueTemplate: '<span class="cw_inline_status_indicator cw_status_widget_color cw_color${data.color}"></span><span>${data.text}</span>',
			dataBound: $.proxy(function (e) {
				if (this.mode === 'create') {
					e.sender.select(0);
					e.sender.trigger('change');
				} else if (this.mode === 'update') {
					var index = Utils.getSeverityIndex(this.monitorErrorOpen, DataSourceSeverity);
					$('#cw_monitor_error_open').data('kendoDropDownList').select(index);
				}
			}, this)
		}).data('kendoDropDownList');

		this.monitorErrorConfigList = $('#cw_monitor_error_config').kendoDropDownList({
			dataTextField: 'text',
			dataValueField: 'value',
			dataSource: DataSourceSeverity,
			change: $.proxy(function (e) {
				this.monitorErrorConfig = e.sender.value();
			}, this),
			open: function (e) {
				e.sender.popup.wrapper.addClass('cw_indicator_dropdown');
			},
			autoBind: true,
			enable: this.mode === 'view' ? false : true,
			template: '<span class="cw_status_indicator cw_status_widget_color cw_color${data.color}"></span><span>${data.text}</span>',
			valueTemplate: '<span class="cw_inline_status_indicator cw_status_widget_color cw_color${data.color}"></span><span>${data.text}</span>',
			dataBound: $.proxy(function (e) {
				if (this.mode === 'create') {
					e.sender.select(0);
					e.sender.trigger('change');
				} else if (this.mode === 'update') {
					var index = Utils.getSeverityIndex(this.monitorErrorConfig, DataSourceSeverity);
					$('#cw_monitor_error_config').data('kendoDropDownList').select(index);
				}
			}, this)
		}).data('kendoDropDownList');
		this.monitorErrorConfigList.list.addClass('cw_indicator_dropdown');

		this.severity = $('#cw_mailgtw_severity').kendoDropDownList({
			dataTextField: 'text',
			dataValueField: 'value',
			dataSource: DataSourceSeverity,
			autoBind: true,
			enable: this.checkForUnread ? true : false,
			template: '<span class="cw_status_indicator cw_status_widget_color cw_color${data.color}"></span><span>${data.text}</span>',
			valueTemplate: '<span class="cw_inline_status_indicator cw_status_widget_color cw_color${data.color}"></span><span>${data.text}</span>'
		}).data('kendoDropDownList');
		this.intervalSchedule = $('#cw_mailgtw_interval_schedule').kendoNumericTextBox({
			decimals: 0,
			format: 'n0',
			spinners: false,
			enable: this.checkForUnread ? true : false
		}).data('kendoNumericTextBox');

		$(".window_area").kendoSplitter({
			orientation: "horizontal",
			panes: [{min: "415px"}, {min: "415px", size: "70%"}]
		});
	},

	onCheckForUnreadCheck(e) {
		var target = $(e.currentTarget);
		if (target.is(':checked')) {
			this.checkForUnread = true;
			this.severity.enable(true);
			this.intervalSchedule.enable(true);
		} else {
			this.checkForUnread = false;
			this.severity.enable(false);
			this.intervalSchedule.enable(false);
		}
	},

	/**
	 * Called in order to remove existing listeners
	 */
	removeListeners() {
		$('#cw_cancel').off();
		$('#cw_save').off();
		$('#cw_monitor_name, #cw_hostname').off();

		$('.cw_data').off();

		$('.cw_authenticate_input').off('blur');
		$('#cw_authenticate').off();
		$('#cw_auth_type').off();
	},

	/**
	 * Attaches the listeners for the form's buttons
	 */
	attachListeners() {
		$('#cw_cancel').on('click', $.proxy(this.onCancel, this));
		$('#cw_save').on('click', $.proxy(this.onSaveButton, this));
		$('#cw_check_for_unread').on('change', $.proxy(this.onCheckForUnreadCheck, this));
		$('#cw_name, #cw_hostname, #cw_port, #cw_username, #cw_password').on('keyup', $.proxy(this.onCheckInputs, this));

		$('.cw_data').on('click', '.cw_filter_remove', $.proxy(this.onRemoveFilter, this));
		$('.cw_data').on('click', '.cw_filter_minimize', $.proxy(this.onProcessGroupMinimize, this));
		$('.cw_data').on('click', '.cw_grid_check', $.proxy(this.onKeyCheckClick, this));
		$('.cw_data').on('click', '.cw_grid_check_all', $.proxy(this.onKeyCheckAllClick, this));
		$('.cw_data').on('click', '.cw_filter_test', $.proxy(this.onTestButton, this));

		$('.cw_authenticate_input').on('blur', $.proxy(this.setAuthentication, this));
		$('#cw_authenticate').on('click', $.proxy(this.onAuthenticate, this));
		$('#cw_auth_type').on('click', 'li', $.proxy(this.onConnectType, this));
	},

	onNameEditEnter(e) {
		let target = $(e.currentTarget);
		const container = target.parent();
		let filterName = container.find('.cw_filter_name');
		let inputName = container.find('.cw_name');

		if (filterName.text() !== 'New Filter') {
			inputName.val(filterName.text());
		}

		filterName.hide();
		inputName.show();
		target.hide();

		$('.cw_name', container).trigger('focus');
		$('.cw_name', container).on('blur', $.proxy(this.onNameEditLeave, this));
	},

	onNameEditLeave(e) {
		let container = $(e.currentTarget).parent();
		let filterName = container.find('.cw_filter_name');
		let currentName = container.find('.cw_name');

		if (currentName.val().trim() !== '') {
			filterName.text(currentName.val());
		}

		$(".cw_name", container).hide();
		$(".cw_filter_name", container).show();
		$(".pencil", container).show();

		this.sortFilters();
	},
	onConnectType(e){
		var target = $(e.currentTarget);
		if (!target.hasClass('is_selected')) {
			target.parent().find('li').removeClass('is_selected');
			target.addClass('is_selected');
			var input = target.find('input[type=radio]');
			input.prop('checked', !input.is(':checked'));
			this.connectType = input.prop('value');
			if (target.find('input[value=OAUTH2]').length) {
				$('#cw_password').addClass('hide');
				$('#cw_password').closest('.cw_field').addClass('hide');
				$('#cw_password').val('');
				$('#cw_auth').removeClass('hide');
				$('#cw_auth').closest('.cw_field').removeClass('hide');
				this.renderAuthDropDown();
			} else {
				$('#cw_password').removeClass('hide');
				$('#cw_password').closest('.cw_field').removeClass('hide');

				$('#cw_auth').addClass('hide');
				$('#cw_auth').closest('.cw_field').addClass('hide');
				if(this.data.profile?.authenticationId)
					this.data.profile.authenticationId = null;
			}
		}
		this.setAuthentication();
		this.setSave();
	},
	toggleConnectTypeRadioButtons(){
		if (this.connectType === 'OAUTH2') {
			$('#cw_auth_type').find('.last_btn').addClass('is_selected');
			$('#cw_password').addClass('hide');
			$('#cw_password').closest('.cw_field').addClass('hide');

			$('#cw_auth').removeClass('hide');
			$('#cw_auth').closest('.cw_field').removeClass('hide');			
		} else {
			$('#cw_auth_type').find('.first_btn').addClass('is_selected');
			$('#cw_password').removeClass('hide');
			$('#cw_password').closest('.cw_field').removeClass('hide');

			$('#cw_auth').addClass('hide');
			$('#cw_auth').closest('.cw_field').addClass('hide');
		}
	},

	renderAuthDropDown(){
		this.destroyAuthDropDown();
		const onAuthDDChanged = (val) => {			
			this.data.profile = {...this.data.profile,...{authenticationId: val}};
			this.setAuthentication();
			this.setSave();
		}
		ReactDOM.render(
			<AuthenticationDropDown
								authTypes={['GENERIC', 'OAUTH2']}
								accountId={this.accountId}
								onChange={onAuthDDChanged}
								value={this.data.profile?.authenticationId}/>,
			document.getElementById('cw_auth')
		);
	},
	destroyAuthDropDown(){
		if (document.getElementById('cw_auth').children.length > 0) {
			ReactDOM.unmountComponentAtNode(document.getElementById('cw_auth'));
		}
	},
	/*
	 * Handler function for creating a filter
	 * */
	onCreateFilter() {
		this.renderFilter({name: 'New Filter'});
	},

	/*
	 * Handler function for removing the filter
	 * @param {Object} e The click event object
	 * */
	onRemoveFilter(e) {
		var target = $(e.currentTarget), filterHandler = target.closest('.cw_mg_filter').data('MailFilter');

		filterHandler.destroy();
	},

	onProcessGroupMinimize(e) {
		var target = $(e.currentTarget);
		if (target.closest('.cw_mg_filter').find('.cw_section_content').hasClass('hide')) {
			target.removeClass('fullscreen');
			target.addClass('minus');
		} else {
			target.removeClass('minus');
			target.addClass('fullscreen');
		}
		target.closest('.cw_mg_filter').find('.cw_section_content').toggleClass('hide');
		if ($('.cw_process_selection').height() == 102) {
			$('.cw_toggle_process_configuration').trigger('click');
		}
	},

	/*
	 * Handler function for adding a new key
	 * @param {Object} e The event click object
	 * */
	onCreateKey(e) {
		var target = $(e.currentTarget), filterHandler = target.closest('.cw_mg_filter').data('MailFilter');
		var grid = filterHandler.grid, id = Utils.guid();

		grid.dataSource.add({
			id: id,
			name: '',
			keyword: '',
			separator: '',
			type: '',
			typeText: '',
			keySuppression: '',
			keySuppressionText: '',
			metric: '',
			metricText: '',
			value: ''
		});
		grid.refresh();
		grid.editCell(grid.element.find('input[data-id="' + id + '"]').parent().next());
	},

	/*
	 * Handler function for deleting filter keys
	 * */
	onDeleteKey(e) {
		var target = $(e.currentTarget), filterHandler = target.closest('.cw_mg_filter').data('MailFilter');
		var checkboxes = filterHandler.container.find('.cw_grid_check:checked');
		var oThis = this;
		filterHandler.severityKey.input.removeClass('invalid');
		filterHandler.assetKey.input.removeClass('invalid');
		var refferedKeys = [];
		//get all reffered keys
		checkboxes.each(function () {
			var id = $(this).data('id');
			var name = $(this).closest('tr').find('.cw_key_name').text();
			if ([filterHandler.severityKey.text(), filterHandler.assetKey.text()].indexOf(name) > -1) {
				refferedKeys.push(id);
			}
		});
		checkboxes.each(function () {
			var id = $(this).data('id'), data, item;
			var name = $(this).closest('tr').find('.cw_key_name').text();
			var keyReffered = false;

			if (filterHandler.severityKey.text() === name && name) {
				keyReffered = true;
				filterHandler.severityKey.input.addClass('invalid');
			} else {
				data = filterHandler.severityKey.dataSource.data();
				for (var i = 0, length = data.length; i < length; i++) {
					//and the key is not reffered
					if (data[i].id === id && refferedKeys.indexOf(id) === -1) {
						item = filterHandler.severityKey.dataSource.getByUid(data[i].uid);
						if (item) {
							filterHandler.severityKey.dataSource.remove(item);
							filterHandler.severityKey.refresh();
						}
						break;
					}
				}
			}
			if (filterHandler.assetKey.text() === name && name) {
				keyReffered = true;
				filterHandler.assetKey.input.addClass('invalid');
			} else {
				data = filterHandler.assetKey.dataSource.data();
				for (var i = 0, length = data.length; i < length; i++) {
					if (data[i].id === id && refferedKeys.indexOf(id) === -1) {
						item = filterHandler.assetKey.dataSource.getByUid(data[i].uid);
						if (item) {
							filterHandler.assetKey.dataSource.remove(item);
							filterHandler.assetKey.refresh();
						}
						break;
					}
				}
			}
			if (!keyReffered) {
				data = filterHandler.grid.dataSource.data();
				for (var i = 0, length = data.length; i < length; i++) {
					if (data[i].id === id) {
						item = filterHandler.grid.dataSource.getByUid(data[i].uid);
						if (item) {
							filterHandler.grid.dataSource.remove(item);
							filterHandler.grid.refresh();
						}
						break;
					}
				}
			} else {
				oThis.actionNotification.setOptions({
					message: i18n('The key must be removed from the filter before it can be deleted'),
					status: 'error'
				}).show();
			}
		});
	},

	/*
	 * Handler function for rule check
	 * @param {Object} e The click event object
	 */
	onKeyCheckClick(e) {
		var target = $(e.currentTarget), filterHandler = target.closest('.cw_mg_filter').data('MailFilter');
		var checkboxes = filterHandler.container.find('.cw_grid_check:checked');
		if (checkboxes.length) {
			filterHandler.menu.enableItem('cw_delete_filterkey');
		} else {
			filterHandler.menu.disableItem('cw_delete_filterkey');
		}
	},

	/*
	 * Handler function for rule check all
	 * @param {Object} e The click event object
	 */
	onKeyCheckAllClick(e) {
		var target = $(e.currentTarget), filterHandler = target.closest('.cw_mg_filter').data('MailFilter');
		var isChecked = $(e.currentTarget).is(':checked');
		if (isChecked) {
			filterHandler.container.find('.cw_grid_check:not(:checked)').prop('checked', true);
			filterHandler.menu.enableItem('cw_delete_filterkey');
		} else {
			filterHandler.container.find('.cw_grid_check:checked').prop('checked', false);
			filterHandler.menu.disableItem('cw_delete_filterkey');
		}
	},

	/**
	 * Handler function agent list initialization
	 */
	initAgentList() {
		var includePublic = 'true';
		this.agentDataSource = new CeeViewDataSource({
			transport: {
				read: {
					url: Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/agents/lite?includeShared=' + includePublic + '&includeSubAgents=true',
					contentType: 'application/json; charset=utf-8',
					type: 'GET',
					dataType: 'json',
					cache: false
				}
			},
			sort: {field: 'name', dir: 'asc'},
			error: ErrorHandler.kendoServerError
		});
		this.agentsList = $('#cw_agent').kendoComboBox({
			dataSource: this.agentDataSource,
			change: $.proxy(this.onAgentSelect, this),
			dataTextField: 'name',
			dataValueField: 'id',
			autoBind: true,
			enable: this.mode === 'view' ? false : true,
			placeholder: lang.SELECT,
			dataBound: $.proxy(function (e) {
				if (this.mode === 'update' || this.mode === 'view') {
					this.agentsList.value(this.selectedAgentId);
				}
			}, this)
		}).data('kendoComboBox');

		if (this.mode === 'update') {
			Utils.setClickableNameListener('agent', this.agentsList);
		}
	},

	/**
	 * Loads the form data from the server and set the values into component fields
	 */
	async load() {
		if (this.accountId && this.sourceId) {
			const result = await apiFetch(copyMonitor(this.accountId, this.sourceId));
			if (result.success) {
				result.data.name = this.name;
				result.data.id = undefined;
			}
			this.onDataLoaded(result);
			return;
		}
		var loadUrl = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/monitors/MAILGTW/' + this.id + '/?update=' + this.update;
		await Utils.ajax(loadUrl, 'GET', {}, $.proxy(function (result) {
			this.onDataLoaded(result);
		}, this));
	},

	onDataLoaded(result) {
		if (!result.success) {
			Utils.showInfo(lang.ALERT, result.message);
			return;
		}
		var data = result.data, profile = data.profile;
		this.data = result.data;
		this.monitorErrorOpen = profile.openErrorSeverity;
		this.monitorErrorConfig = profile.configErrorSeverity;
		this.checkForUnread = profile.checkForUnread;
		Renderer.monitorCVID(this.id);
		this.initKendoComponents();

		if (this.checkForUnread) {
			this.severity.value(profile.unreadSeverity);
			this.intervalSchedule.value(profile.unreadIterationLimit);
		} else {
			this.intervalSchedule.enable(false);
		}

		this.tagsControl.setRawValues(data.tags);

		this.protocol = profile.protocol;
		$('#cw_name').val(data.name);
		this.descriptionHandler.value(data.description);

		$('#cw_hostname').val(profile.server);
		$('#cw_port').val(profile.port);
		$('#cw_username').val(profile.user);
		$('#cw_password').val(profile.password);
		if (profile.lifeTime) {
			$('#cw_expiry_time').val(profile.lifeTime / 1000);
		}
		this.mailReadControl.value(profile.mailReadNumber);
		this.mailStormCount.value(profile.stormCount);
		$('#cw_use_ssl').prop('checked', profile.useSsl);
		$('#cw_delete_after_read').prop('checked', profile.deleteAfterRead);
		$('#cw_check_for_unread').prop('checked', profile.checkForUnread);

		this.selectedAgentId = data.agentId;
		if (this.mode !== 'view') {
			$('#cw_authenticate').prop('disabled', false);
		}

		this.agentsList.value(data.agentId);
		var filters = profile.filters;
		for (var i = 0, length = filters.length; i < length; i++) {
			this.renderFilter(filters[i]);
		}
		if (!length) {
			this.renderFilter();
		}
		this.sortFilters();
		$('.pencil').on('click', $.proxy(this.onNameEditEnter, this));
	},

	async loadDefaults() {
		let url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/monitors/MAILGTW/options';
		await Utils.ajax(url, 'GET', {}, $.proxy(function (result) {
			if (result.success) {
				let data = result.data;
				this.initKendoComponents();
				this.renderFilter();
				$('.pencil').on('click', $.proxy(this.onNameEditEnter, this));
				$('#cw_save').attr('disabled', true);
				if (data.lifeTime) {
					$('#cw_expiry_time').val(data.lifeTime / 1000);
				}
				this.intervalSchedule.value(data.unreadIterationLimit);
				if (!data.checkForUnread) {
					this.intervalSchedule.enable(false);
				}
				if (data.mailReadNumber) {
					this.mailReadControl.value(data.mailReadNumber);
				}
				if (data.stormCount) {
					this.mailStormCount.value(data.stormCount);
				}
			} else {
				Utils.showInfo(lang.ALERT, result.message);
			}
		}, this));
	},

	/**
	 * Handler function for setting button availability
	 */
	setSave() {
		Utils.setPlaceholder($('#cw_name'), i18n('Please input name...'));
		Utils.setInvalidField($('#cw_name').val(), $('#cw_name'));
		Utils.setInvalidField($('#cw_hostname').val(), $('#cw_hostname'));
		Utils.setInvalidField($('#cw_port').val(), $('#cw_port'));
		Utils.setInvalidField($('#cw_username').val(), $('#cw_username'));
		if(this.connectType === "GENERIC")
			Utils.setInvalidField($('#cw_password').val(), $('#cw_password'));
		Utils.setInvalidField(this.agentsList?.value(), $('#cw_agent'), false, 'required_multiselect');
		if ($('#cw_name').val() !== ''
		&& $('#cw_hostname').val() !== ''
		&& $('#cw_port').val()
		&& $('#cw_username').val()
		&& ($('#cw_password').val() !== '' || this.connectType !=='GENERIC')
		&& (this.data.profile.authenticationId !== null || this.connectType !=='OAUTH2')
		&& this.selectedAgentId && this.protocol) {
			$('#cw_save').prop('disabled', false);
		} else {
			$('#cw_save').prop('disabled', true);
		}
	},

	/**
	 * Handler function for setting button availability
	 */
	setAuthentication() {
		if ($('#cw_name').val() !== ''
		&& $('#cw_hostname').val() !== ''
		&& $('#cw_port').val()
		&& $('#cw_username').val()
		&& ($('#cw_password').val() !== '' || this.connectType !=='GENERIC')
		&& (this.data.profile?.authenticationId !== null || this.connectType !=='OAUTH2')
		&& this.selectedAgentId && this.protocol) {
			$('#cw_authenticate').prop('disabled', false);
		} else {
			$('#cw_authenticate').prop('disabled', true);
		}
	},

	/**
	 * Handler function for the click event on authentication button
	 * @param {Object} e The click event object
	 */
	onAuthenticate(e) {
		var data = {
			agentId: this.selectedAgentId,
			server: $('#cw_hostname').val(),
			port: $('#cw_port').val(),
			protocol: this.protocol,
			user: $('#cw_username').val(),
			useSsl: $('#cw_use_ssl').is(':checked'),
		};
		if(this.connectType==='GENERIC')
		{
			data.password = $('#cw_password').val();
		}
		if(this.connectType==='OAUTH2')
		{
			data.authenticationId = this.data.profile?.authenticationId;
		}
		kendo.ui.progress($('.cw_data'), true);
		var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/monitors/MAILGTW/loginVerification';
		Utils.ajax(url, 'POST', JSON.stringify(data), $.proxy(function (response) {
			kendo.ui.progress($('.cw_data'), false);
			this.actionNotification.setOptions({
				animationTime: !response.success ? 4000 : 2500,
				message: response.message,
				status: response.success ? 'success' : 'error'
			}).show();
			$('.window_area').scrollTop(0);
		}, this), $.proxy(function () {
			kendo.ui.progress($('.cw_data'), false);
			this.actionNotification.setOptions({
				animationTime: 4000,
				message: 'Unknown exception',
				status: 'error'
			}).show();
		}, this));
	},

	/**
	 * Handler function for agent selection
	 * @param {Object} e Object
	 */
	onAgentSelect(e) {
		var selectedAgentId = e.sender.value();
		if (Utils.isGuid(selectedAgentId)) {
			this.selectedAgentId = selectedAgentId;
		} else {
			this.selectedAgentId = null;
		}

		this.setSave();
	},

	/**
	 * Handler function for protocol selection
	 * @param {Object} e Object
	 */
	onProtocolSelect(e) {
		this.protocol = e.sender.value();

		this.setSave();
	},

	/**
	 * Callback function that handles the save response
	 * @param {Object} result
	 */
	onDataSuccessfullySaved(result) {
		if (result.success) {
			this.onCancel()
		} else {
			this.actionNotification.setOptions({
				message: result.message,
				status: 'error'
			}).show();
		}
		$('#save_monitor').removeAttr('disabled');
	},


	/**
	 * Handler function for the keyUp event on monitor name input
	 * @param {Object} e The keyUp event object
	 */
	onCheckInputs(e) {
		this.setSave();
		this.setAuthentication();
	},

	/**
	 * Handler function for the click event on Save button
	 * @param {Object} e The click event object
	 */
	async onSaveButton(e) {
		let initialName = this.name ? this.name : this.data.name;
		let nameExists = await Utils.checkIfMonitorExists($('#cw_name').val().trim(), "MAILGTW", this.notification, initialName);
		if (nameExists) {
			return;
		}
		var self = this, filters = [], profile = [];
		var filterValid = true;
		var hasDuplicateKeys = false;
		var duplicateKeysMessage = '';
		$('.cw_mg_filter').find('.is_invalid').removeClass('is_invalid');
		$('.cw_mg_filter').find('.invalid').removeClass('invalid');
		$('.cw_mg_filter').each(function () {
			var container = $(this);
			var filterHandler = container.data('MailFilter'), keys = [];

			var dataItems = filterHandler.grid.dataSource.data(), item;
			for (var i = 0, length = dataItems.length; i < length; i++) {
				item = dataItems[i];
				if (item.valid && item.added) {
					keys.push({
						name: item.name.trim(),
						keyword: item.keyword.trim(),
						separator: item.separator.trim(),
						type: item.type,
						typeText: item.typeText,
						keySuppression: item.keySuppression,
						keySuppressionText: item.keySuppressionText,
						asMetric: item.metric,
						asMetricText: item.metricText,
						value: item.value
					});
				}
			}

			var lastMailOverrides = container.find('.cw_mail_override').is(':checked');
			var lastMailProcessed = container.find('.cw_mail_processed').is(':checked');
			var lastReducesOldReasons = container.find('.cw_mail_last_mail_and_reason').is(':checked');
			var name = container.find('.cw_name').val();
			var asset = container.find('.cw_asset').val();
			var assetKey = filterHandler.assetKey.value() !== 'none' ? filterHandler.assetKey.text() : '';
			var severity = filterHandler.severity.value();
			var severityKey = filterHandler.severityKey.value() !== 'none' ? filterHandler.severityKey.text() : '';
			var severityMapping = {
				criticalPattern: container.find('.cw_mapping_critical').val(),
				majorPattern: container.find('.cw_mapping_major').val(),
				minorPattern: container.find('.cw_mapping_minor').val(),
				nonePattern: container.find('.cw_mapping_none').val()
			};
			if (severityKey && severityKey !== 'none') {
				if (severityMapping.criticalPattern === severityMapping.majorPattern) {
					hasDuplicateKeys = true;
					container.find('.cw_mapping_critical').addClass('invalid');
					container.find('.cw_mapping_major').addClass('invalid');

				}
				if (severityMapping.criticalPattern === severityMapping.minorPattern) {
					hasDuplicateKeys = true;
					container.find('.cw_mapping_critical').addClass('invalid');
					container.find('.cw_mapping_minor').addClass('invalid');
					duplicateKeysMessage = i18n('Duplicate severities are not allowed')
				}
				if (severityMapping.criticalPattern === severityMapping.nonePattern) {
					hasDuplicateKeys = true;
					container.find('.cw_mapping_critical').addClass('invalid');
					container.find('.cw_mapping_none').addClass('invalid');
				}
				if (severityMapping.minorPattern === severityMapping.majorPattern) {
					hasDuplicateKeys = true;
					container.find('.cw_mapping_minor').addClass('invalid');
					container.find('.cw_mapping_major').addClass('invalid');
				}
				if (severityMapping.nonePattern === severityMapping.majorPattern) {
					hasDuplicateKeys = true;
					container.find('.cw_mapping_none').addClass('invalid');
					container.find('.cw_mapping_major').addClass('invalid');
				}
				if (severityMapping.minorPattern === severityMapping.nonePattern) {
					hasDuplicateKeys = true;
					container.find('.cw_mapping_minor').addClass('invalid');
					container.find('.cw_mapping_none').addClass('invalid');
				}
				if (hasDuplicateKeys) {
					filterValid = false;
					duplicateKeysMessage = i18n('Duplicate severities are not allowed')
				}
				if (!severityMapping.criticalPattern) {
					filterValid = false;
					container.find('.cw_mapping_critical').addClass('invalid');
					duplicateKeysMessage = '';
				}
				if (!severityMapping.majorPattern) {
					filterValid = false;
					container.find('.cw_mapping_major').addClass('invalid');
					duplicateKeysMessage = '';
				}
				if (!severityMapping.minorPattern) {
					filterValid = false;
					container.find('.cw_mapping_minor').addClass('invalid');
					duplicateKeysMessage = '';
				}
				if (!severityMapping.minorPattern) {
					filterValid = false;
					container.find('.cw_mapping_none').addClass('invalid');
					duplicateKeysMessage = '';
				}
			}

			let assetMatchingCheckedOption = container.find('input[category="asset_matching"]:checked');
			let assetMatchingType = assetMatchingCheckedOption.attr('class');
			if (assetMatchingType === 'NAME') {
				let nameAndTargetChecked = container.find('.NAME_AND_TARGET').is(':checked');
				if (nameAndTargetChecked) {
					assetMatchingType = 'NAME_AND_TARGET';
				}
			}

			if (name && (asset || assetKey)) {
				filters.push({
					name: name.trim(),
					lastMailOverrides: lastMailOverrides,
					lastMailOnly: lastMailProcessed,
					lastReducesOldReasons: lastReducesOldReasons,
					description: container.find('.cw_description').val().trim(),
					severity: severity,
					severityKey: severityKey,
					severityMapping: severityKey ? severityMapping : {},
					asset: asset,
					assetKey: assetKey,
					assetMatching: assetMatchingType,
					messageTemplate: container.find('.cw_message_template').val(),
					keys: keys
				});
			} else {
				filterValid = false;
				if (!name) {
					container.find('.cw_name').addClass('invalid');
				} else {
					container.find('.cw_asset').addClass('invalid');
					filterHandler.assetKey.input.addClass('invalid');
				}
			}
		});

		if (filterValid) {
			profile = {
				name: $('#cw_name').val().trim(),
				description: this.descriptionHandler.value().trim(),
				server: $('#cw_hostname').val().trim(),
				user: $('#cw_username').val().trim(),
				password: this.connectType === 'GENERIC' ? $('#cw_password').val() : null,
				port: $('#cw_port').val().trim(),
				protocol: this.protocol,
				deleteAfterRead: $('#cw_delete_after_read').is(':checked'),
				useSsl: $('#cw_use_ssl').is(':checked'),
				lifeTime: $('#cw_expiry_time').val() * 1000 || 0, //seconds
				filters: filters,
				mailReadNumber: this.mailReadControl.value(),
				stormCount: this.mailStormCount.value(),
				openErrorSeverity: this.monitorErrorOpen,
				configErrorSeverity: this.monitorErrorConfig,
				checkForUnread: $('#cw_check_for_unread').is(':checked'),
				unreadIterationLimit: this.intervalSchedule.value(),
				unreadSeverity: this.severity.value(),
				authenticationId: this.connectType === 'OAUTH2'? this.data.profile?.authenticationId : null
			};

			var data = {
				id: this.id || null,
				name: $('#cw_name').val().trim(),
				description: this.descriptionHandler.value().trim(),
				agentId: this.selectedAgentId,
				targetType: 'ASSET_GROUP',
				type: 'MAILGTW',
				profile: profile,
				accountId: Cookies.CeesoftCurrentAccountId,
				tags: this.tagsControl.getRawValues()
			};

			var saveUrl = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/monitors/MAILGTW';
			Utils.ajax(saveUrl, 'POST', JSON.stringify(data), $.proxy(this.onDataSuccessfullySaved, this));
		} else {
			this.actionNotification.setOptions({
				message: duplicateKeysMessage || lang.messages.REQUIRED_FIELDS,
				status: 'error'
			}).show();
		}
	},

	onCancel(e) {
		this.destroy();
		this.destroyAuthDropDown();
		this._skipDirtyCheck = true;
		NavigationStore.pop();
	},

	/*
	 * Handler function for rendering filter
	 * @param {Object} The filter options returned by load. (Optional)
	 * */
	renderFilter(options) {
		var guid = Utils.guid(), index = this._filterIndex || 0;
		this._filterIndex = ++index;

		var filterHtml = kendo.template($('#cw_filter_html').html());

		$('.cw_mg_filters').prepend(filterHtml({
			index: index,
			guid: guid,
			name: options?.name
		}));
		const container = $(".cw_mg_filter[data-id='" + guid + "']");

		$('.cw_filter_name', container).text(options?.name);
		const nameInput = $(".cw_name", container);
		nameInput.val(options?.name);
		nameInput.hide();
		$('.pencil', container).on('click', $.proxy(this.onNameEditEnter, this));

		$('#cw_mailoverride' + index).on('click', $.proxy(this.onMailOverrideCheck, this));
		$('#cw_mailprocessed' + index).on('click', $.proxy(this.onMailProcessedCheck, this));
		$('#cw_mail_last_mail_and_reason' + index).on('click', $.proxy(this.onMailLastMailAndReasonCheck, this));
		$(`[name='asset_matching${index}']`).on('click', (e) => this.onAssetMatching(e));
		this.initFilterComponents({
			index: index,
			guid: guid,
			container: $('.cw_mg_filter[data-id="' + guid + '"]'),
			options: options
		});
		if (options && options.lastMailOnly === true) {
			$('#cw_mailoverride' + index).attr('disabled', 'disabled');
			$('#cw_mail_last_mail_and_reason' + index).attr('disabled', 'disabled');
		}

		if (options && options.lastMailOverrides === true) {
			$('#cw_mailprocessed' + index).attr('disabled', 'disabled');
			$('#cw_mail_last_mail_and_reason' + index).attr('disabled', 'disabled');
		}

		if (options && options.lastReducesOldReasons === true) {
			$('#cw_mailoverride' + index).attr('disabled', 'disabled');
			$('#cw_mailprocessed' + index).attr('disabled', 'disabled');
		}
	},

	filterNameComparator(a, b) {
		if ($(a).attr('data-name') < $(b).attr('data-name'))
			return -1;
		if ($(a).attr('data-name') > $(b).attr('data-name'))
			return 1;
		return 0;
	},

	sortFilters() {
		let filters = document.querySelectorAll("[data-name]");
		let filtersArray = Array.from(filters);
		let sorted = filtersArray.sort(this.filterNameComparator);
		sorted.forEach(e => document.querySelector(".cw_mg_filters").appendChild(e));
	},

	onMailOverrideCheck(e) {
		var target = $(e.currentTarget);
		if (target.is(':checked')) {
			target.parent().find('.cw_mail_processed').attr('disabled', 'disabled');
			target.parent().find('.cw_mail_last_mail_and_reason').attr('disabled', 'disabled');
		} else {
			target.parent().find('.cw_mail_processed').removeAttr('disabled')
			target.parent().find('.cw_mail_last_mail_and_reason').removeAttr('disabled');
		}
	},

	onMailProcessedCheck(e) {
		var target = $(e.currentTarget);
		if (target.is(':checked')) {
			target.parent().find('.cw_mail_override').attr('disabled', 'disabled');
			target.parent().find('.cw_mail_last_mail_and_reason').attr('disabled', 'disabled');
		} else {
			target.parent().find('.cw_mail_override').removeAttr('disabled');
			target.parent().find('.cw_mail_last_mail_and_reason').removeAttr('disabled');
		}
	},

	onMailLastMailAndReasonCheck(e) {
		var target = $(e.currentTarget);
		if (target.is(':checked')) {
			target.parent().find('.cw_mail_override').attr('disabled', 'disabled');
			target.parent().find('.cw_mail_processed').attr('disabled', 'disabled');
		} else {
			target.parent().find('.cw_mail_override').removeAttr('disabled');
			target.parent().find('.cw_mail_processed').removeAttr('disabled')
		}
	},

	onAssetMatching(e) {
		let target = $(e.currentTarget);
		let targetType = target.attr('class');
		let disableNameAndTarget = false;
		let nameAndTargetField = target.closest('.cw_field').find('.NAME_AND_TARGET');
		if (targetType === 'TARGET') {
			disableNameAndTarget = true;
			nameAndTargetField.prop('checked', false);
		}
		nameAndTargetField.prop('disabled', disableNameAndTarget);
	},

	onTestButton(e) {
		var target = $(e.currentTarget);
		if (!$('#modal').length) {
			$('.cw_windows_container').append('<div id="modal"></div>');
		}
		this.filterName = target.parent().find('.cw_filter_name').text();
		this.filterContainer = target.parent().parent();
		this.testInputWindow = $('#modal').kendoWindow({
			title: lang.TEST + ' ' + this.filterName,
			width: '500',
			height: '300',
			modal: true,
			close: $.proxy(function () {
				this.testValues = {
					from: $('#cw_from').val(),
					replyTo: $('#cw_reply_to').val(),
					subject: $('#cw_subject').val(),
					body: $('#cw_body').val()
				};
				$('#modal').data("kendoWindow").destroy();
				$('#modal').remove();
			}, this)
		});
		var testWindowContent = '<div class="cw_test_content form">';
		testWindowContent += '<div class="cw_field"><label class="cw_inline">' + i18n('From: ') + '</label><div class="cw_dropdown_container"><input id="cw_from" class="cw_inline" type="text"></div></div>';
		testWindowContent += '<div class="cw_field"><label class="cw_inline">' + i18n('Reply To: ') + '</label><div class="cw_dropdown_container"><input id="cw_reply_to" class="cw_inline" type="text"></div></div>';
		testWindowContent += '<div class="cw_field"><label class="cw_inline">' + i18n('Subject: ') + '</label><div class="cw_dropdown_container"><input id="cw_subject" class="cw_inline" type="text"></div></div>';
		testWindowContent += '<div class="cw_field cw_email_body expandable_area cw_description_expand"><label class="cw_inline_full">' + i18n('Body: ') + '</label><textarea id="cw_body" class="cw_inline is_expandable" style=""></textarea></div>';
		testWindowContent += '<div class="cw_actions"><button class="k-button k-primary right cw_test_button">' + i18n('Test') + '</button></div>';
		testWindowContent += '</div>';
		$('#modal').off().on('click', '.cw_test_button', $.proxy(this.onTestFilter, this));
		var modalWindow = $('#modal').data("kendoWindow");
		modalWindow.content(testWindowContent);
		modalWindow.center();
		if (this.testValues) {
			$('#cw_from').val(this.testValues.from);
			$('#cw_reply_to').val(this.testValues.replyTo);
			$('#cw_subject').val(this.testValues.subject);
			$('#cw_body').val(this.testValues.body);
		}
	},

	onTestFilter() {
		if (!$('#test_result_modal').length) {
			$('.cw_windows_container').append('<div id="test_result_modal"></div>');
		}
		this.testResultWindow = $('#test_result_modal').kendoWindow({
			title: i18n('Test result') + ' ' + this.filterName,
			width: '500',
			height: '300',
			position: {
				top: this.testInputWindow.offset().top - 30,
				left: this.testInputWindow.offset().left + this.testInputWindow.width() + 10
			},
			modal: true,
			close: function () {
				$('#test_result_modal').data("kendoWindow").destroy();
				$('#test_result_modal').remove();
			},
			activate: $.proxy(function () {
				var window = $('#test_result_modal').data("kendoWindow");
				if (window.length) {
					window.destroy();
					$('#test_result_modal').remove();
				}
				var data = this.getFilterTestData();
				var testUrl = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/monitors/MAILGTW/testFilter';
				Utils.ajax(testUrl, 'POST', JSON.stringify(data), $.proxy(function (result) {
					if (result.success) {
						this.onTestFilterSuccess(result.data);
					} else {
						Utils.showInfo(lang.ALERT, result.message);
					}
				}, this));
			}, this)
		});
		var modalWindow = $('#test_result_modal').data("kendoWindow");
		modalWindow.open();
		$('.k-overlay').css('z-index', '10002');
	},

	getFilterTestData() {
		var filterHandler = this.filterContainer.data('MailFilter'), keys = [];
		var dataItems = filterHandler.grid.dataSource.data(), item;
		for (var i = 0, length = dataItems.length; i < length; i++) {
			item = dataItems[i];
			if (item.valid && item.added) {
				keys.push({
					name: item.name.trim(),
					keyword: item.keyword.trim(),
					separator: item.separator.trim(),
					type: item.type,
					typeText: item.typeText,
					keySuppression: item.keySuppression,
					keySuppressionText: item.keySuppressionText,
					asMetric: item.metric,
					asMetricText: item.metricText,
					value: item.value
				});
			}
		}
		var lastMailOverrides = this.filterContainer.find('.cw_mail_override').is(':checked');
		var lastMailProcessed = this.filterContainer.find('.cw_mail_processed').is(':checked');
		var lastReducesOldReasons = this.filterContainer.find('.cw_mail_last_mail_and_reason').is(':checked');
		var name = this.filterContainer.find('.cw_name').val();
		var asset = this.filterContainer.find('.cw_asset').val();
		var assetKey = filterHandler.assetKey.value() !== 'none' ? filterHandler.assetKey.text() : '';
		var severity = filterHandler.severity.value();
		var severityKey = filterHandler.severityKey.value() !== 'none' ? filterHandler.severityKey.text() : '';
		var severityMapping = {
			criticalPattern: this.filterContainer.find('.cw_mapping_critical').val(),
			majorPattern: this.filterContainer.find('.cw_mapping_major').val(),
			minorPattern: this.filterContainer.find('.cw_mapping_minor').val(),
			nonePattern: this.filterContainer.find('.cw_mapping_none').val()
		};

		let assetMatchingCheckedOption = this.filterContainer.find('input[category="asset_matching"]:checked');
		let assetMatchingType = assetMatchingCheckedOption.attr('class');
		if (assetMatchingType === 'NAME') {
			let nameAndTargetChecked = this.filterContainer.find('.NAME_AND_TARGET').is(':checked');
			if (nameAndTargetChecked) {
				assetMatchingType = 'NAME_AND_TARGET';
			}
		}

		var filter = {
			name: name.trim(),
			lastMailOverrides: lastMailOverrides,
			lastMailOnly: lastMailProcessed,
			lastReducesOldReasons: lastReducesOldReasons,
			description: this.filterContainer.find('.cw_description').val().trim(),
			severity: severity,
			severityKey: severityKey,
			severityMapping: severityKey ? severityMapping : {},
			asset: asset,
			assetKey: assetKey,
			assetMatching: assetMatchingType,
			messageTemplate: this.filterContainer.find('.cw_message_template').val(),
			keys: keys
		};
		var data = {
			agentId: this.selectedAgentId,
			from: $('#cw_from').val(),
			replyTo: $('#cw_reply_to').val(),
			subject: $('#cw_subject').val(),
			testData: $('#cw_body').val(),
			filter: filter
		};
		return data;
	},

	onTestFilterSuccess(data) {
		var fields = data.fields;
		var removeIcon = '<span class="glyphicons cw_result_icon marginTopMinus20 remove-sign"></span>';
		var okIcon = '<span class="glyphicons cw_result_icon marginTopMinus20 ok-sign"></span>';
		var modalWindow = $('#test_result_modal').data("kendoWindow");
		$('.cw_test_content').find('.remove-sign').remove();
		$('.cw_test_content').find('.ok-sign').remove();
		modalWindow.content('<div id="test_result_grid"></div>');
		var resultGrid = $('#test_result_grid').kendoCustomGrid({
			dataSource: data.keys,
			resizable: true,
			sortable: true,
			pageable: false,
			columns: [{
				field: 'name',
				title: lang.NAME,
				sortable: false,
				filterable: false,
				menu: false,
				width: 110,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'value',
				title: i18n('Value'),
				sortable: false,
				filterable: false,
				width: 150,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'expectValue',
				title: i18n('Expected Value'),
				sortable: false,
				filterable: false,
				width: 150,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'valueOk',
				title: i18n('Valid'),
				sortable: false,
				filterable: false,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}],
			change: $.proxy(function () {
				var selectedRow = resultGrid.select();
				var myRow = selectedRow[0];
				var messageEl = $(myRow).find('.to_expand');
				if ($(messageEl).hasClass('cw_message_expanded')) {
					$(messageEl).removeClass('cw_message_expanded').addClass('ellipsis');
				} else {
					$('#test_result_grid').find('.k-grid-content').find('td.cw_message_expanded').removeClass('cw_message_expanded').addClass('ellipsis');
					$(messageEl).addClass('cw_message_expanded').removeClass('ellipsis');
				}
			}, this),
			dataBound: $.proxy(function () {
				$('#test_result_grid').find('.k-grid-content').kendoTooltip({
					filter: "td.tooltip:not(:empty)",
					content: function (event) {
						return event.target.text().split("\n").join("<br />");
					}
				});
			}, this),
			columnMenu: true
		}).data('kendoCustomGrid');
		if (fields.body === false) {
			$('#cw_body').parent().find('.cw_inline_full').append(removeIcon).find('.remove-sign').removeClass('marginTopMinus20');
		} else if (fields.body === true) {
			$('#cw_body').parent().find('.cw_inline_full').append(okIcon).find('.ok-sign').removeClass('marginTopMinus20');
		}
		if (fields.from === false) {
			$('#cw_from').parent().append(removeIcon);
		} else if (fields.from === true) {
			$('#cw_from').parent().append(okIcon);
		}
		if (fields.replyTo === false) {
			$('#cw_reply_to').parent().append(removeIcon);
		} else if (fields.replyTo === true) {
			$('#cw_reply_to').parent().append(okIcon);
		}
		if (fields.subject === false) {
			$('#cw_subject').parent().append(removeIcon);
		} else if (fields.subject) {
			$('#cw_subject').parent().append(okIcon);
		}
	},

	initFilterComponents(config) {
		var container = config.container, self = this, options = config.options || {};

		if (!options.severityKey) {
			container.find('.is_readonly').prop('disabled', true);
		}

		var severity = container.find('.cw_penalty').kendoDropDownList({
			dataSource: DataSourceSeverity,
			dataTextField: 'text',
			dataValueField: 'value',
			//optionLabel: lang.SELECT,
			value: 'CRITICAL',
			enable: this.mode === 'view' ? false : true,
			template: '<span class="cw_status_indicator cw_status_widget_color cw_color${data.color}"></span><span>${data.text}</span>',
			valueTemplate: '<span class="cw_inline_status_indicator cw_status_widget_color cw_color${data.color}"></span><span>${data.text}</span>'
		}).data('kendoDropDownList');

		var severityKeyCombo = container.find('.cw_severity_key').kendoComboBox({
			dataSource: new CeeViewDataSource({
				data: [{
					name: ' ' + lang.NONE,
					id: 'none'
				}],
				sort: {field: 'name', dir: 'asc'}
			}),
			dataTextField: 'name',
			dataValueField: 'id',
			template: '<span id="${ data.id }">${ data.name }</span>',
			autoBind: true,
			//optionLabel: lang.SELECT,
			placeholder: lang.SELECT,
			enable: this.mode === 'view' ? false : true,
			change: function (e) {
				if (e.sender.value() && e.sender.value() !== 'none') {
					container.find('.is_readonly').prop('disabled', false);
				} else {
					container.find('.is_readonly').prop('disabled', true);
				}
			}
		}).data('kendoComboBox');
		if (options.severityKey) {
			severityKeyCombo.value(options.severityKey);
		}

		var assetKeyCombo = container.find('.cw_asset_key').kendoComboBox({
			dataSource: new CeeViewDataSource({
				data: [{
					name: ' ' + lang.NONE,
					id: 'none'
				}],
				sort: {field: 'name', dir: 'asc'}
			}),
			dataTextField: 'name',
			dataValueField: 'id',
			template: '<span id="${ data.id }">${ data.name }</span>',
			autoBind: true,
			enable: this.mode === 'view' ? false : true,
			placeholder: lang.SELECT,
			//optionLabel: lang.SELECT
			change: $.proxy(function (e) {
				this.checkAssetFields(container);
			}, this)
		}).data('kendoComboBox');

		container.find('.cw_asset').on('change', $.proxy(function (e) {
			this.checkAssetFields(container);
		}, this));
		if (options.assetKey) {
			assetKeyCombo.value(options.assetKey);
		}
		if (options.messageTemplate) {
			container.find('.cw_message_template').val(options.messageTemplate);
		} else {
			container.find('.cw_message_template').val('${monitor}/${profile} (count=${count}, first seen=${first_seen}): ${<keyname>}');
		}

		var menu = new GridMenu({
			renderTo: 'cw_keys_menu' + config.index,
			items: [{
				id: 'cw_create_filterkey',
				icon: 'plus',
				text: lang.CREATE,
				fn: this.onCreateKey,
				scope: this,
				disabled: this.mode === 'view' ? true : false
			}, {
				id: 'cw_delete_filterkey',
				icon: 'bin',
				text: lang.DELETE,
				fn: this.onDeleteKey,
				scope: this,
				disabled: this.mode === 'view' ? true : false
			}]
		});
		var grid = container.find('.cw_keys_list').kendoCustomGrid({
			autoBind: true,
			editable: this.mode === 'view' ? false : true,
			dataSource: new CeeViewDataSource({
				data: [],
				schema: {
					model: {
						id: 'id',
						fields: {
							id: {
								editable: false
							}
						}
					}
				}
			}),
			filterable: false,
			sortable: true,
			save: $.proxy(this.onKeyGridSave, this),
			edit: $.proxy(this.onKeyGridEdit, this),
			columns: [{
				field: 'id',
				title: lang.SELECTOR,
				template: '<input type="checkbox" class="cw_grid_check" data-id="${id}" />',
				headerTemplate: '<input type="checkbox" class="cw_grid_check_all" />',
				attributes: {
					'class': 'text_center'
				},
				headerAttributes: {
					'class': 'text_center'
				},
				width: 40,
				sortable: false,
				filterable: false,
				menu: false,
				editable: false
			}, {
				field: 'name',
				title: lang.NAME,
				template: '<span class="cw_key_name ellipsis to_expand">#=name#</span>',
				width: 200,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'keyword',
				title: lang.KEYWORD,
				width: 150,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'separator',
				title: lang.SEPARATOR,
				width: 150,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'keySuppressionText',
				title: i18n('Suppression'),
				width: 230,
				editor: function (container, options) {
					var input = $('<input data-text-field="text" data-value-field="value"/>');
					input.appendTo(container);
					input.kendoDropDownList({
						value: options.model.type,
						optionLabel: lang.SELECT,
						dataSource: new CeeViewDataSource({
							transport: {
								read: {
									url: Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/monitors/MAILGTW/suppressionTypes',
									contentType: "application/json; charset=utf-8",
									type: "GET",
									dataType: "json",
									cache: false
								}
							}
						}),
						dataTextField: 'text',
						dataValueField: 'value',
						width: 100,
						change: function (e) {
							var item = e.sender.dataItem();

							options.model.set('keySuppression', item.value);
							options.model.set('keySuppressionText', item.text);
						}
					});
				},
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'typeText',
				title: lang.TYPE,
				width: 230,
				editor: function (container, options) {
					var input = $('<input data-text-field="text" data-value-field="value"/>');
					input.appendTo(container);
					input.kendoDropDownList({
						value: options.model.type,
						optionLabel: lang.SELECT,
						dataSource: new CeeViewDataSource({
							transport: {
								read: {
									url: Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/monitors/MAILGTW/keyTypes',
									contentType: "application/json; charset=utf-8",
									type: "GET",
									dataType: "json",
									cache: false
								}
							}
						}),
						dataTextField: 'text',
						dataValueField: 'value',
						width: 100,
						change: function (e) {
							var item = e.sender.dataItem();

							options.model.set('type', item.value);
							options.model.set('typeText', item.text);
						}
					});
				},
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'metricText',
				title: lang.widget.METRIC,
				width: 130,
				editor: function (container, options) {
					var input = $('<input data-text-field="text" data-value-field="value"/>');
					input.appendTo(container);
					input.kendoDropDownList({
						value: options.model.metric,
						optionLabel: lang.SELECT,
						dataSource: [{
							text: lang.TRUE,
							value: true
						}, {
							text: lang.FALSE,
							value: false
						}],
						dataTextField: 'text',
						dataValueField: 'value',
						width: 100,
						dataBound: function () {

						},
						change: function (e) {
							var item = e.sender.dataItem();

							options.model.set('metric', item.value);
							options.model.set('metricText', item.text);
						}
					});
				},
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'value',
				title: lang.assethealth.EXPECTED_VALUE
			}],
			change: $.proxy(function () {
				var selectedRow = grid.select();
				var myRow = selectedRow[0];
				var messageEl = $(myRow).find('.to_expand');
				if ($(messageEl).hasClass('cw_message_expanded')) {
					$(messageEl).removeClass('cw_message_expanded').addClass('ellipsis');
				} else {
					container.find('.cw_keys_list').find('.k-grid-content').find('td.cw_message_expanded').removeClass('cw_message_expanded').addClass('ellipsis');
					$(messageEl).addClass('cw_message_expanded').removeClass('ellipsis');
				}
			}, this),
			dataBound: $.proxy(this.onDataBound, this)
		}).data('kendoCustomGrid');
		// Add Kendo tooltip to the header of the columns
		Utils.gridColumnHeaderTooltip(this.grid);

		if (options) {
			var severityMapping = options.severityMapping;
			if (severityMapping && options.severityKey) {
				container.find('.cw_mapping_critical').val(severityMapping.criticalPattern || '');
				container.find('.cw_mapping_major').val(severityMapping.majorPattern || '');
				container.find('.cw_mapping_minor').val(severityMapping.minorPattern || '');
				container.find('.cw_mapping_none').val(severityMapping.nonePattern || '');
			}

			container.find('.cw_asset').val(options.asset || '');
			container.find('.cw_mail_override').prop('checked', Boolean(options.lastMailOverrides));
			container.find('.cw_mail_processed').prop('checked', Boolean(options.lastMailOnly));
			container.find('.cw_mail_last_mail_and_reason').prop('checked', Boolean(options.lastReducesOldReasons));
			container.find('.cw_name').val(options.name);
			container.find('.cw_description').val(options.description);
			severity.value(options.severity);

			if (options.assetMatching) {
				if (options.assetMatching === 'TARGET') {
					container.find('.TARGET').prop('checked', true);
				} else {
					container.find('.TARGET').prop('checked', false);
					container.find('.NAME').prop('checked', true);
					container.find('.NAME_AND_TARGET').prop('disabled', false);
					if (options.assetMatching === 'NAME_AND_TARGET') {
						container.find('.NAME_AND_TARGET').prop('checked', true);
					}
				}
			}

			var keys = options.keys || [], bool = {'true': lang.TRUE, 'false': lang.FALSE};
			var dataSourceKeys = [], guid;
			for (var i = 0, length = keys.length; i < length; i++) {
				guid = Utils.guid();
				keys[i].id = guid;
				keys[i].valid = true;
				keys[i].added = true;
				keys[i].metricText = bool[keys[i].asMetric];
				keys[i].metric = keys[i].asMetric;

				dataSourceKeys.push({
					id: guid,
					name: keys[i].name
				});
			}

			grid.dataSource.data(keys || []);

			severityKeyCombo.dataSource.data(dataSourceKeys);
			assetKeyCombo.dataSource.data(dataSourceKeys);
			var noneRecord = {
				id: 'none',
				name: ' ' + lang.NONE
			};
			severityKeyCombo.dataSource.add(noneRecord);
			assetKeyCombo.dataSource.add(noneRecord);
			severityKeyCombo.refresh();
			assetKeyCombo.refresh();
		}

		container.data('MailFilter', {
			guid: config.guid,
			container: config.container,
			severity: severity,
			severityKey: severityKeyCombo,
			assetKey: assetKeyCombo,
			menu: menu,
			grid: grid,
			destroy: this._destroyMailFilter
		});
		if (this.mode === 'view') {
			$('.cw_description').addClass('is_readonly');
			$('.cw_name').addClass('is_readonly');
			$('.cw_asset').addClass('is_readonly');
			$('#cw_mailoverride1').attr('disabled', 'disabled');
			$('#cw_mailprocessed1').attr('disabled', 'disabled');
			$('.is_readonly').attr('readonly', true);
		}

		this.checkAssetFields(container);
	},

	checkAssetFields(container) {
		const asset = container.find('.cw_asset').val();
		const filterHandler = container.data('MailFilter');
		const assetKey = filterHandler.assetKey.value() !== 'none' ? filterHandler.assetKey.text() : '';
		if (!asset && !assetKey) {
			container.find('.cw_asset_key').closest('.cw_dropdown_container').addClass('required');
			container.find('.cw_asset').closest('.cw_dropdown_container').addClass('required');
		} else {
			container.find('.cw_asset_key').closest('.cw_dropdown_container').removeClass('required');
			container.find('.cw_asset').closest('.cw_dropdown_container').removeClass('required');
		}
	},

	onDataBound() {
		$('.cw_keys_list').find('.k-grid-content').kendoTooltip({
			filter: "td.tooltip:not(:empty)",
			content: function (event) {
				return event.target.text().split("\n").join("<br />");
			}
		});
		if (this.mode === 'view') {
			$('.cw_grid_check_all').prop('disabled', 'disabled');
			$('.cw_grid_check').prop('disabled', 'disabled');
		}
	},

	onKeyGridEdit(e) {
		if (e.container.hasClass('invalid')) {
			e.container.removeClass('invalid');
			e.container.find('input').val('');
			e.model.name = '';
		}
	},

	onKeyGridSave(e) {
		setTimeout(function () {
			var model = e.model, container = $(e.container), data, item, items = e.sender.dataSource.data(),
				unique = true;
			model.name = model.name.trim();

			for (var i = 0, length = items.length; i < length; i++) {
				if (items[i].id !== model.id && items[i].name === model.name) {
					unique = false;
					break;
				}
			}

			if (unique) {
				e.container.removeClass('invalid');
				if (!model.added && model.name) {
					container.closest('tr').removeClass('cw_invalid_rule');
					model.added = true;

					var filterHandler = container.closest('.cw_mg_filter').data('MailFilter');
					filterHandler.severityKey.dataSource.add({
						id: model.id,
						name: model.name
					});
					filterHandler.severityKey.refresh();

					filterHandler.assetKey.dataSource.add({
						id: model.id,
						name: model.name
					});
					filterHandler.assetKey.refresh();
				} else if (model.added && model.name && model.typeText) {
					model.valid = true;
					var filterHandler = container.closest('.cw_mg_filter').data('MailFilter');

					data = filterHandler.severityKey.dataSource.data();
					for (var i = 0, length = data.length; i < length; i++) {
						if (data[i].id === model.id) {
							item = filterHandler.severityKey.dataSource.getByUid(data[i].uid);
							if (item) {
								item.name = model.name;
								filterHandler.severityKey.refresh();
							}
							break;
						}
					}

					data = filterHandler.assetKey.dataSource.data();
					for (var i = 0, length = data.length; i < length; i++) {
						if (data[i].id === model.id) {
							item = filterHandler.assetKey.dataSource.getByUid(data[i].uid);
							if (item) {
								item.name = model.name;
								filterHandler.assetKey.refresh();
							}
							break;
						}
					}
				}

				var nextCell = container.next();
				e.sender.editCell(nextCell);
				nextCell.find('input').focus();
			} else {
				model.name = i18n('Duplicate name not allowed');
				model.valid = false;
				e.container.addClass('invalid');
				e.sender.closeCell();
			}
		}, 0);
	},

	_destroyMailFilter() {
		this.container.remove();
	}
})
