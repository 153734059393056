import React from "react";
import { DatePicker, DatePickerProps } from "antd";
import {observer} from "mobx-react";
import {RangePickerProps} from "antd/lib/date-picker";
import b_ from "b_";
import classnames from "classnames";
import './antDatePicker.less';

const datePickerB = b_.with('ant-date-picker');

type AntDatePickerProps = DatePickerProps & {}

export const AntDatePicker = observer((props: AntDatePickerProps) => {
	const className = classnames(datePickerB(), props.className);
	return <DatePicker {...props} className={className} popupStyle={{zIndex: 10010}}/>;
});

const {RangePicker} = DatePicker;

type AntRangePickerProps = RangePickerProps & {};

export const AntRangePicker = (props: AntRangePickerProps) => {
	return <RangePicker {...props} popupStyle={{zIndex: 10010}}/>
}
