import {CeeViewDataSource, DataSourceSeverity} from 'tools';

export default function getCustomConfigGridDataSource(data) {
	return new CeeViewDataSource({
		data: data,
		sort: {
			field: 'grouping',
			dir: 'desc'
		},
		schema: {
			model: {
				fields: {
					driveName: {
						editable: false
					},
					name: {
						editable: false
					},
					grouping: {
						editable: false
					},
					unitTypeSymbol: {
						editable: false
					}
				}
			},
			parse: function (result) {
				for (var i = 0; i < result.length; i++) {
					if (result[i].severity) {
						for (var j = 0; j < DataSourceSeverity.length; j++) {
							if (DataSourceSeverity[j].value === result[i].severity) {
								result[i].severityColor = DataSourceSeverity[j].color;
								result[i].severityText = DataSourceSeverity[j].text;
							}
						}
					}
				}
				return result;
			}
		}
	});
}
