import {CeeViewDataSource, DataSourceSeverity} from 'tools';
import getCustomConfigGridDataSource from 'areas/assets/monitors/system/diskView/getCustomConfigGridDataSource';
import convertMetricsLabel from 'areas/assets/monitors/system/diskView/convertMetricsLabel';
import getDiskStatus from 'areas/assets/monitors/system/diskView/getDiskStatus';
import gridDataBound from "./onDiskGridDataBound";

import translator from 'core/localization';
import {DISK_VIEW} from "areas/assets/monitors/system/shared";

const i18n = translator({
	'No custom monitoring created. Add a shared disk to custom monitoring (via menu) to get started.': {
		no: 'Ingen tilpasset overvåking opprettet. Legg til en delt disk i tilpasset overvåking (via meny) for å komme i gang.'
	},
	'Monitoring is not available for this entry': {
		no: 'Monitorering er ikke tilgjengelig for dette monitorpunktet'
	},
});

export default class DiskCustomConfigGrid {
	constructor(type, prefix, mode) {
		this.type = type;
		this.prefix = prefix;
		this.mode = mode;
	}

	create(defaultConfiguration, customDisks) {
		const diskCustomConfig = [];
		const instances = defaultConfiguration.instances;

		for (var i = 0; i < instances.length; i++) {
			const status = getDiskStatus(instances[i]);

			if (status === 'custom') {
				customDisks.push(instances[i].name);

				for (var j = 0; j < instances[i].metrics.length; j++) {
					instances[i].metrics[j].driveName = instances[i].name;
					diskCustomConfig.push(instances[i].metrics[j]);
				}
			}
		}

		this.diskCustomConfigGrid = $(`#cw_${this.prefix}disk_custom_grid`).kendoCustomGrid({
			noRecords: {
				template: () => {
					return this.type === DISK_VIEW.SHARED_DISK ?
						`<div class="cw_link">${i18n('No custom monitoring created. Add a shared disk to custom monitoring (via menu) to get started.')}</div>`:
						'No records available';
				}
			},
			dataSource: getCustomConfigGridDataSource(diskCustomConfig),
			resizable: true,
			editable: true,
			sortable: {
				mode: 'multiple',
				allowUnsort: true
			},
			save: (e) => {
				this.cachedEvent = e;
				setTimeout(() => {
					var currentFieldId = $(this.cachedEvent.container).attr('id');
					var currentFieldName = Object.keys(this.cachedEvent.values)[0];
					var currentFieldValue = this.cachedEvent.values[currentFieldId];
					var currentDisk = this.cachedEvent.model.driveName;
					var currentName = this.cachedEvent.model.name;
					var diskGridItems = this.diskGrid.dataSource.data();
					for (var i = 0; i < diskGridItems.length; i++) {
						if (diskGridItems[i].name === currentDisk) {
							var diskMetrics = diskGridItems[i].metrics;
							for (var j = 0; j < diskMetrics.length; j++) {
								if (diskMetrics[j].name === currentName) {
									diskMetrics[j][currentFieldName] = currentFieldValue;
								}
							}
						}
					}

					this.ensureTresholdsAreConsistent(e);
				}, 0)
			},
			columns: [{
				field: 'driveName',
				title: lang.DRIVE,
				width: 100
			}, {
				field: 'name',
				title: lang.NAME,
				template: function (item) {
					return convertMetricsLabel(item.name);
				},
				width: 150
			}, {
				field: 'grouping',
				title: lang.TYPE,
				attributes: {
					'id': 'grouping'
				},
				width: 150
			}, {
				field: 'unitTypeSymbol',
				title: lang.UNIT,
				width: 100,
				attributes: {
					'title': function(data) {
						return data.unitType || "";
					}
				},
			}, {
				field: 'enabled',
				title: lang.METRIC,
				template: function (data) {
					return data.enabled ? lang.YES : lang.NO;
				},
				attributes: {
					'id': 'enabled'
				},
				width: 100,
				editor: $.proxy(function (container, options) {
					var input = $('<input data-text-field="text" data-value-field="value"/>');
					input.appendTo(container);
					input.kendoDropDownList({
						value: options.model.enabled,
						dataSource: [{
							value: true,
							text: lang.YES
						}, {
							value: false,
							text: lang.NO
						}],
						enable: this.mode === 'view' ? false : true,
						change: function (e) {
							var item = e.sender.dataItem();
							options.model.set('enabled', item.value);
							if (item.value && options.model.name !== 'DISKSIZE') {
								if (options.model.metricOnly) {
									this.element.closest('td').nextAll('.cw_monitoring_toggle').css({
										'pointer-events': 'initial',
										'background-color': ''
									});
								} else {
									this.element.closest('td').next('.cw_monitoring_toggle').css({
										'pointer-events': 'initial',
										'background-color': ''
									});
								}
							} else {
								this.element.closest('td').nextAll('.cw_monitoring_toggle').css({
									'pointer-events': 'none',
									'background-color': '#e1e1e1'
								});
								options.model.set('metricOnly', false);
							}
						}
					});
				}, this)
			}, {
				field: 'metricOnly',
				title: lang.MONITOR,
				template: item => {
					return item.name === 'DISKSIZE' ? `<span class="cw_not_available">N/A</span>`:
						item.metricOnly ? lang.YES : lang.NO;
				},
				attributes: {
					'id': 'metricOnly',
					'class': 'cw_monitoring_toggle'
				},
				width: 100,
				editor: $.proxy(function (container, options) {
					var input = $('<input data-text-field="text" data-value-field="value"/>');
					input.appendTo(container);
					input.kendoDropDownList({
						value: options.model.metricOnly,
						dataSource: [{
							value: true,
							text: lang.YES
						}, {
							value: false,
							text: lang.NO
						}],
						enable: this.mode === 'view' ? false : true,
						change: function (e) {
							var item = e.sender.dataItem();
							options.model.set('metricOnly', item.value);
							if (item.value) {
								this.element.closest('td').nextAll('.cw_metric_toggle').css({
									'pointer-events': 'initial',
									'background-color': ''
								});
							} else {
								this.element.closest('td').nextAll('.cw_metric_toggle').css({
									'pointer-events': 'none',
									'background-color': '#e1e1e1'
								});
							}
						}
					});
				}, this)
			}, {
				field: 'warning',
				title: lang.WARNING,
				template: item => {
					return item.name === 'DISKSIZE' ? `<span class="cw_not_available">N/A</span>` : item.warning;
				},
				attributes: {
					'id': 'warning',
					'class': 'cw_monitoring_toggle cw_metric_toggle cw_numeric_input'
				},
				width: 100
			}, {
				field: 'critical',
				title: lang.CRITICAL,
				template: item => {
					return item.name === 'DISKSIZE' ? `<span class="cw_not_available">N/A</span>` : item.critical;
				},
				attributes: {
					'id': 'critical',
					'class': 'cw_monitoring_toggle cw_metric_toggle cw_numeric_input'
				},
				width: 100
			}, {
				field: 'projected',
				title: lang.PROJECTED,
				hidden: true,
				template: item => {
					return item.name === 'DISKSIZE' ? `<span class="cw_not_available">N/A</span>` : item.projected;
				},
				attributes: {
					'class': 'cw_monitoring_toggle cw_metric_toggle cw_numeric_input'
				},
				width: 100
			}, {
				field: 'severity',
				title: lang.assethealth.SEVERITY,
				template: item => {
					return item.name === 'DISKSIZE' ? `<span class="cw_not_available">N/A</span>` :
						`<span class="cw_status_widget_color cw_color${item.severityColor}"></span><span>${item.severityText}</span>`;
				},
				attributes: {
					'class': 'cw_monitoring_toggle cw_metric_toggle'
				},
				//width: 150,
				editor: $.proxy(function (container, options) {
					var input = $('<input data-text-field="text" data-value-field="value"/>');
					input.appendTo(container);
					input.kendoDropDownList({
						value: options.model.severity,
						dataSource: DataSourceSeverity,
						enable: this.mode === 'view' ? false : true,
						template: '<span class="cw_status_indicator cw_status_widget_color cw_color${data.color}"></span><span>${data.text}</span>',
						valueTemplate: '<span class="cw_inline_status_indicator cw_status_widget_color cw_color${data.color}"></span><span>${data.text}</span>',
						change: function (e) {
							var item = e.sender.dataItem();
							options.model.set('severity', item.value);
							options.model.set('severityText', item.text);
							options.model.set('severityColor', item.color);
						}
					});
				}, this)
			}],
			dataBound: function () {
				gridDataBound(this.dataSource);
				$('.cw_not_available').attr('title', i18n('Monitoring is not available for this entry'))
					.css('pointer-events', 'auto');
			}
		}).data('kendoCustomGrid');

		return this.diskCustomConfigGrid;
	}
}
