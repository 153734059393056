import Settings from 'settings'
import WebSocketAdapter from 'core/webSocketAdapter'
import TaskManager from 'tools/taskManager';
import {CustomNotification} from "controls/customNotification";
import {newGuid} from "tools/guid";
import {clog} from "core/logging";

const _waitForConnection = 3000;
const _retryForConnection = 5000;
const _delay = 10; //seconds

let _protocol = 'ws';

const _href = window.location.href;
if (_href && _href.indexOf('https://') > -1) {
	_protocol = 'wss';
}

const _serverUrl = _protocol + '://' + Settings.eventPath;

const cachedSubscribers = {};

const i = require('core/localization').translator({
	"Connection lost message":{
		en: "Connection to Ceeview server is lost. The current page might contain stale data. Check your network settings",
		no: "Klarer ikke å koble til Ceeview serveren. Denne siden kan inneholde gamle data. Sjekk nettverksinnstillingene"
	}
});

class RemoteEventsManager {
	firstConnect = true;
	releaseEventCache = [];
	noConnectionNotification = null;
	supressNoConnectionNotification = false;
	_events = [];
	_callbacks = {};
	_onEventCallbacks = [];
	_retriesCount = 0

	connect (config) {
		this.config = config || {};
		this._sessionId = config.sessionId;

		if (this.firstConnect) {
			this.connectionOpenedTimeoutId = setTimeout(this._forceOpen, _waitForConnection);
		}

		this.webSocket = new WebSocketAdapter({
			url: _serverUrl,
			protocol: config.sessionId,
			onOpen: (event) => {
				this._retriesCount = 0
				clearTimeout(this.connectionOpenedTimeoutId);
				clearInterval(this.heartBeatIntervalId);
				clearTimeout(this.noConnectionNotificationTimer);

				if (!this.firstConnect) {
					this._reloadCachedSubscribers();
					this.restoreReleaseEvents();
				} else {
					this.config.onInitialized(event)
				}

				this.noConnectionNotification?.hide();

				this.startHeartbeat();

				this.firstConnect = false;
			},

			onEvent: event => {
				const eventData = JSON.parse(event.data);

				if (eventData.messageType === 'EVENT') {
					this._setTaskManager();

					const callback = this._callbacks[eventData.subscriberId || eventData.data.wId];
					if (callback) {
						callback(eventData.data);
					}

					this._handleEvent(eventData);

					if(!callback) {
						this._onEventCallbacks.forEach(x => x(eventData.data));
					}
				} else if (eventData.messageType === 'CONFIGURATION') {
					this._handleConfigEvent(eventData.properties);
				} else if (eventData.messageType === 'ERROR') {
					console.error('Error message', eventData);
				}
			},

			onClose: () => {
				clearTimeout(this.connectionOpenedTimeoutId);

				if (this.firstConnect) {
					this._forceOpen();
				}

				clearInterval(this.heartBeatIntervalId);

				this._taskManagerStop();

				this.createNoConnectionNotification();

				if(!this.supressNoConnectionNotification && this._retriesCount > 0) {
					this.noConnectionNotificationTimer = setTimeout( () => {
						this.noConnectionNotification.setOptions({
							message: i("Connection lost message"),
							status: 'error'
						}).show();
					}, 200);
				}

				this._retry();
			},

			onSessionExpired: (event) => {
				if (this.config.onSessionExpired) {
					this.config.onSessionExpired.call(this, event);
				}
			}
		});
	}

	createNoConnectionNotification() {
		if (this.noConnectionNotification)
			return;

		const notificationContent = document.createElement('div');

		this.noConnectionNotification = new CustomNotification({
			appendToElement: 'body',
			autoHideAfter: 0
		});

	}

	startHeartbeat() {
		this.heartBeatIntervalId = window.setInterval(this.sendHeartbeat, 900000);
	}

	sendHeartbeat = () => {
		this.webSocket.send({
			messageType: 'HEARTBEAT'
		});
	}

	subscribe(subscriberId, data) {
		if ( !this.webSocket) {
			return;
		}

		if(CEEVIEW_LOGGING_EVENTS_ENABLED) {
			clog('EVENTS', 'subscribe', subscriberId, data)
		}

		this.webSocket.send({
			messageType: 'SUBSCRIBE',
			subscriberId: subscriberId,
			subscriptions: data
		});

		this._cacheSubscriber(subscriberId, data);

		return {
			unsubscribe: () =>{
				this.unsubscribe(subscriberId);
			},
			subscriberId
		}
	}

	subscribeCallback(subscriptions, onEvent) {
		const subscriberId = newGuid();
		let unsubscriber = this.subscribe(subscriberId, subscriptions);
		if(!unsubscriber)
			return;

		this._callbacks[subscriberId] = onEvent;

		return unsubscriber;
	}

	unsubscribe(subscriberId) {
		if (this.webSocket) {
			this.webSocket.send({
				messageType: 'UNSUBSCRIBE',
				subscriberId: subscriberId
			});
		}
		if(CEEVIEW_LOGGING_EVENTS_ENABLED) {
			clog('EVENTS', 'unsubscribe', subscriberId)
		}

		this._uncacheSubscriber(subscriberId);
		delete this._callbacks[subscriberId];
	}
	/*
	* Handler function for clearing (UNLOCK-ing) the entity in database when editing
	* @param {}
	* */
	discard(entityId) {
		if (this.webSocket) {
			this.webSocket.send({
				messageType: 'UNLOCK',
				entityId: entityId
			});
		}
	}

	restoreReleaseEvents() {
		for (let {subscriberId, data} of this.releaseEventCache) {
			this.releaseEvents(subscriberId, data, true);
		}
	}

	/*
	 * Handler function for releasing events for metrics
	 * @param {Object} data The object that is sent to server
	 * */
	releaseEvents(subscriberId, data, skipCache = false) {
		const config = {
			messageType: 'RELEASE_EVENTS',
			subscriberId: subscriberId
		};
		Object.assign(config, data);

		if (!skipCache) {
			this.releaseEventCache.push({subscriberId, data});
		}

		this.webSocket.send(config);
	}

	closeConnection() {
		this.supressNoConnectionNotification = true;
		this.webSocket?.close();
	}

	//notify when any event arrived
	onEvent(callback){
		this._onEventCallbacks.push(callback);

		return () => {
			const index = this._onEventCallbacks.indexOf(callback);
			this._onEventCallbacks.splice(index, 1);
		}
	}

	_forceOpen = () => {
		if (this.config.onInitialized) {
			this.config.onInitialized.call(this, {});
			this.firstConnect = false;
		}
	}

	_retry() {
		this._retriesCount ++
		setTimeout($.proxy(function () {
			this.connect(this.config);
		}, this), _retryForConnection);
	}
	/*
	* Handler function for caching the subscriber and data
	* @param {UID} subscriberId The subscrieber id to be cached
	* @param {Object} data The subscriber data
	* */
	_cacheSubscriber(subscriberId, data) {
		cachedSubscribers[subscriberId] = data;
	}
	/*
	* Handler function for removing the cache for subscriber
	* @param {UID} subscriberId THe subscriber id to be removed
	* */
	_uncacheSubscriber(subscriberId) {
		delete cachedSubscribers[subscriberId];
	}
	/*
	* Handler function for resubscribing
	* */
	_reloadCachedSubscribers() {
		for (let subscriberId in cachedSubscribers) {
			this.subscribe(subscriberId, cachedSubscribers[subscriberId]);
		}
	}
	/*
	* Handler function for setting the task manager
	* */
	_setTaskManager() {
		if (_delay && !this._taskManager) {
			this._taskManager = new TaskManager();

			this._refreshTask = {
				run: $.proxy(this._pushEvents, this),
				interval: _delay * 1000
			};
			this._taskManager.start(this._refreshTask);
		}
	}
	/*
	 * Handler function for handling the event
	 * @param {Object} event The event
	 * */
	_handleEvent(eventData) {
		if (_delay) {
			this._events.push(eventData.data);
		} else {
			if (this.config.handleEvents) {
				this.config.handleEvents.call(this, eventData.data);
			}
		}
	}
	/*
	 * Handler function for handling the config event
	 * @param {Object} properties The received properties
	 * */
	_handleConfigEvent(properties) {
		if (this.config.handleConfigEvents) {
			this.config.handleConfigEvents.call(this, properties);
		}
	}
	/*
	* Handler function for sending array of events
	* */
	_pushEvents() {
		if (this._events.length) {
			if(CEEVIEW_LOGGING_EVENTS_ENABLED) {
				clog('EVENTS', 'pushing events', this._events)
			}
			if (this.config.handleEvents) {
				this.config.handleEvents.call(this, this._events);
			}
			this._events = [];
		} else {
			this._taskManagerStop();
		}
	}
	/*
	* Handler function for stopping the task manager
	* */
	_taskManagerStop() {
		if (this._taskManager) {
			this._taskManager.stopAll();
			this._taskManager = null;
		}
	}
}

const rem = new RemoteEventsManager();

export {rem as RemoteEventsManager, rem as default}
