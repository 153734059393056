import Tags from "controls/tags";
import {Utils} from "tools/utils";
import {Settings} from "settings";
import {Api} from "tools/api";

export function getTagsSectionHtml(){
	let html = '<div class="cw_separator">';
	html += '<span class="cw_separator_title">' + lang.TAGS + '</span>';
	html += '<div class="w100 left">';
	html += '<div class="cw_checkoxes_with_labels"><input type="checkbox" id="cw_untagged" class="k-checkbox" /><label for="cw_untagged" class="k-checkbox-label">' + lang.SHOW_UNTAGGED + '</label></div></div>';
	html += '<label class="cw_inline_full">' + lang.TAGS + '</label>';
	html += '<input class="left w100" id="cw_tags" type="text"/>';
	html += '</div>';

	return html;
}

export function initTagsSection(widgetObj){
	const tagsControl =  new Tags({
		renderEl: $('#cw_tags'),
		enabled: true,
		autoBind: false
	});

	if (widgetObj.configuration.showUntagged) {
		$('#cw_untagged').prop('checked', true);
	}

	return tagsControl;
}

export function getTagsValues(){
	return {
		tags: $('#cw_tags').data('tags').getValues(),
		showUntagged: $('#cw_untagged').is(':checked')
	}
}

export async function setTagsDatasource(accountId, includeSubaccounts, value){
	if (!Utils.isGuid(accountId)) {
		return;
	}

	const url = Settings.serverPath + 'accounts/' + accountId + '/tags?includeSubaccounts=' + includeSubaccounts;
	const result = await Api.fetch(url);

	let tags = [];
	for (let tag of result.data) {
		tags.push({
			text: tags,
			value: tags,
			editable: false,
			removable: false,
			destroyable: false
		});
	}

	let tagsControl = $('#cw_tags').data('tags');

	tagsControl.setDataSource(tags);
	tagsControl.setValues(value);
}
