import {Application, LocalEventsManager, Cookies, RemoteEventsManager, ErrorHandler} from 'core';
import {
	CustomNotification, Dependencies,
	Dialog,
	EventsToolbar,
	GridMenu,
	ModalView,
	ModalWindow,
	MultiToggle,
	PreferencesPanel
} from 'controls';
import {Utils, UserPrefs, State} from 'tools';
import {Settings} from 'settings';
import {TeamForm} from "areas/administration/teamForm";
import MultiSelectGridFilter from 'controls/multiSelectGridFilter';
import {UserRouter} from "areas/administration/users/bundleDescription";
import {TeamRouter} from "areas/administration/teams/bundleDescription";
import ReactDOM from 'react-dom';
import React from 'react';
import DocumentationButton from 'controls/react/form/documentationButton';
import {TeamApi} from "./teams/api";

export let TeamsView = function (config) {
	Utils.apply(this, config);
	this.hasEvents = true;
	this.subscriberId = Utils.guid();
	this.initComponent();
};

export default TeamsView;

jQuery.extend(TeamsView.prototype, Application.prototype, {
	/**
	 * teams view application related initializations
	 */
	initComponent: function () {
		Application.prototype.initComponent.call(this);
		this.indicatorSelector = $('.team_view .cw_new_tag_indicator');


		this.getTags();
		this.actionNotification = new CustomNotification({
			appendToElement: '.window_area',
			status: 'success',
			style: 'top:15px; right:15px; left:15px;',
		});

		this.defaultColumns = {
			id: {
				hidden: false,
				width: 40
			},
			name: {
				hidden: false,
				width: 260
			},
			tags: {
				hidden: false,
				width: 200
			},
			hasNotificationPolicy: {
				hidden: false,
				width: 150
			},
			description: {
				hidden: false
			}
		};
		this.loadUserPreferences();

		// update content button
		State.mainApp.contextMenu.setActionButton('TEAM');
		this.cardGridToggle = new MultiToggle({
			id: 'cw_teams_view_toggle',
			cssClass: 'cw_multi_toggle right',
			value: 'false',
			items: [{
				id: 'cw_grid_view',
				title: lang.GRID_VIEW,
				selected: false,
				cssClass: 'glyphicons justify',
				fn: this.onViewToggle,
				scope: this,
				value: 'true'
			}, {
				id: 'cw_card_view',
				title: lang.CARD_VIEW,
				selected: true,
				cssClass: 'glyphicons show-big-thumbnails',
				fn: this.onViewToggle,
				scope: this,
				value: 'false'
			}]
		});
		this.gridMenu = new GridMenu({
			renderTo: 'cw_teams_grid_menu',
			items: [{
				id: 'cw_create',
				icon: 'plus-sign',
				text: lang.CREATE,
				fn: State.mainApp.onNewTeam,
				scope: State.mainApp,
				role: 'TEAM_CREATE'
			}, {
				id: 'cw_delete',
				icon: 'bin',
				text: lang.DELETE,
				fn: this.onTeamDelete,
				scope: this,
				disabled: true,
				role: 'TEAM_DELETE'
			}]
		});

		this.renderDocumentationButton();
		this.removeMask();
		this.removeListeners();
		this.attachListeners();
	},
	getTags: async function () {
		let tags = await Utils.getAccountTags();
		this.filterOptions = {
			tags: tags
		};
	},
	/**
	 * Handler for the view toggle buttons click
	 * @param {String} value The value of the button clicked
	 */
	onViewToggle: function (value) {
		if (value === 'true') {
			if (this.grid && this.gridViewLoaded) {
				this.showGridView = true;
				$('#cw_teams_list').addClass('hide');
				$('#cw_teams_grid').removeClass('hide');
				this.grid.refresh();
			} else {
				this.initGridView();
				this.grid.refresh();
			}
		} else {
			$('#cw_teams_grid').addClass('hide');
			if (this.innerView) {
				this.showGridView = false;
				$('#cw_teams_list').removeClass('hide');
				//$('#cw_filter_reset').addClass('inactive');
			} else {
				this.initCardView();
			}
		}
	},
	/**
	 * Removes the listeners for the form's buttons
	 */
	removeListeners: function () {
		$('#teams_grid').off();
		this.onTeamSavedHandler = $.proxy(this.onTeamSaved, this);
		LocalEventsManager.unbind('teamSaved');
	},
	/**
	 * Attaches the listeners for the form's buttons
	 */
	attachListeners: function () {
		$('#teams_grid').on('click', '.cw_grid_check', $.proxy(this.onCheck, this));
		$('#teams_grid').on('click', '.cw_grid_check_all', $.proxy(this.onCheckAll, this));
		$('#teams_grid').on('click', '.cw_grid_link', $.proxy(this.onTeamNameClick, this));
		LocalEventsManager.bind('teamSaved', this.onTeamSavedHandler);
		$(window).off().on('resize', $.proxy(this.adjustSectionHeight, this));
	},
	/**
	 * Init card component
	 */
	initCardView: function () {
		var deleteClass = State.mainApp.session.hasRole('TEAM_DELETE') ? '' : 'hide';
		var updateClass = State.mainApp.session.hasRole('TEAM_UPDATE') ? '' : 'hide';
		var template = "<li><div class='cw_card' id='${id}'><div class='cw_card_avatar'><div class='cw_avatar cw_avatar_team'></div></div>";
		template += "<div class='cw_card_content'><h2 class='card_title'> ${name} </h2>";
		if (State.mainApp.session.hasRole('TEAM_READ')) {
			template += "<p class='card_description card_extra'><strong>" + lang.DESCRIPTION + ":</strong> ${description !== null ? description : \'\'} </p><p class='card_extra card4'><strong>" + lang.account.TEAM_USERS + ":</strong>";
			template += "#if(memberCount==0){#" + lang.account.messages.TEAM_NO_USERS + "#}#";
			template += "#if(memberCount==1){#" + lang.account.messages.TEAM_ONE_USER + "#}#";
			template += "#if(memberCount>1){#" + lang.account.messages.TEAM_USER_COUNT_FIRST + " ${memberCount} " + lang.account.messages.TEAM_USER_COUNT_SECOND + "#}#</p>";
		}
		template += "</div><div class='cw_card_options'><span class='glyphicons bin ";
		template += deleteClass;
		template += "'></span><span class='glyphicons pencil ";
		template += updateClass;
		template += "'></span></div></div></li>";
		var largeCard = $('#cw_teams_list').parent().parent().find('.cw_card_large');

		largeCard.off();
		largeCard.on('click', function (e) {
			$(this).toggleClass('hide');
		});
		$('.cw_close').off();
		$('.cw_close').on('click', function (e) {
			largeCard.addClass('hide');
			e.stopPropagation();
		});
		this.showGridView = false;
		$('#cw_teams_view_toggle').find('li').last().addClass('is_selected');

		this.innerView = new ModalView({
			autoLoad: true,
			createButtonText: lang.account.TEAM_NEW,
			viewDataSource: this.dataSource,
			template: template,
			id: 'cw_teams_list',
			cssClass: 'team_ul',
			title: lang.account.TEAMS,
			pager: true,
			dataBound: $.proxy(this.onDataBound, this),
			onCreateButton: function (e) {
				var largeCard = $('#cw_teams_list').parent().parent().find('.cw_card_large');
				if (State.mainApp.session.hasRole('TEAM_CREATE')) {
					var modalWindow = new ModalWindow({
						title: lang.account.TEAM_CREATE,
						width: 400,
						minHeight: 200,
						url: 'include/Administration/TeamForm.jsp',
						refresh: function () {
							new TeamForm({
								id: null,
								mode: 'create'
							});
						}
					});
					modalWindow.open();
				}
				e.stopPropagation();
				largeCard.addClass('hide');
			},
			onEditButton: function (e) {
				var largeCard = $('#cw_teams_list').parent().parent().find('.cw_card_large');
				if (State.mainApp.session.hasRole('TEAM_UPDATE') || State.mainApp.session.hasRole('TEAM_READ')) {
					var teamId = e.currentTarget.parentNode.parentNode.id;
					State.mainApp.navigate(TeamRouter.profile(teamId));
				}
				e.stopPropagation();
				largeCard.addClass('hide');
			},
			onDeleteButton: function (e) {
				var largeCard = $('#cw_teams_list').parent().parent().find('.cw_card_large');
				if (State.mainApp.session.hasRole('TEAM_DELETE')) {
					var id = e.currentTarget.parentNode.parentNode.id;
					var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/teams/' + id;
					var dialog = new Dialog({
						title: lang.INFO,
						msg: lang.account.messages.TEAM_REMOVE_CONFIRMATION,
						icon: 'ERROR',
						scope: this,
						actionText: 'DELETE',
						fn: function (value, button) {
							if (button === 'ok') {
								Utils.ajax(url, 'DELETE', {}, $.proxy(function (result) {
									if (result.success) {
										this.actionNotification.setOptions({
											message: lang.account.messages.TEAM_SUCCESS_DELETED,
											status: 'success'
										}).show();

										this.viewDataSource.read();
									} else {
										Utils.showInfo(lang.ALERT, result.message, result.details);
									}
								}, this));
							}
						}
					});
					dialog.show();
				}
				e.stopPropagation();
				largeCard.addClass('hide');
			},
			onCardClick: function (e) {
				this.id = e.currentTarget.id;
				this.teamName = $(e.currentTarget).find('.card_title').text();
				this.teamDescription = $(e.currentTarget).find('.card_description').text().replace($(e.currentTarget).find('.card_description').find('strong').text(), '');
				var oThis = this;
				var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/teams/' + this.id + '/members';
				Utils.ajax(url, 'GET', {}, function (result) {
					var largeCard = $('#cw_teams_list').parent().parent().find('.cw_card_large');
					var user;
					if (result) {
						var users = result;
						largeCard.find('.card_title').empty();
						largeCard.find('.card_title').append(oThis.teamName);
						largeCard.find('.card_description').empty();
						largeCard.find('.card_description').append('<strong>' + lang.DESCRIPTION + ':</strong>' + oThis.teamDescription);
						var usersDetails = '';
						for (var i = 0, length = users.length; i < length; i++) {
							user = users[i];
							usersDetails = usersDetails + ('<li>' + user.username + '</li>');
						}
						largeCard.find('.cw_large_details').empty();
						largeCard.find('.cw_large_details').append(usersDetails);

						var yOffset = 0;
						if ($('#' + oThis.id).position().top > $(window).height() * 0.6) {
							yOffset = 120;
						}
						var scrollTop = $('.list_content').scrollTop();

						largeCard.css('top', $('#' + oThis.id).position().top + 37 - yOffset - scrollTop + 'px');
						largeCard.css('left', $('#' + oThis.id).position().left + 'px');
						largeCard.css('height', '110px');
						largeCard.css('width', $('#' + oThis.id).width() + 2 + 'px');
						largeCard.removeClass('hide');
						largeCard.animate({
							height: '230px'
						}, 250, function () {
						});
					} else {
						Utils.showInfo(lang.ALERT, result.message, result.details);
					}
				});
			}
		});
		setTimeout(function () {
			$('#cw_teams_list').removeClass('hide');
		}, 300);
	},
	onDataBound: function () {
		$('#teams_grid').find('.k-grid-content').kendoTooltip({
			filter: "td.tooltip:not(:empty)",
			content: function (event) {
				return event.target.text().split("\n").join("<br />");
			}
		});
		if (this.savedTeamsNotification) {
			this.actionNotification.setOptions({
				message: this.savedTeamsNotification,
				status: 'success'
			}).show();
			this.savedTeamsNotification = null;
		}
	},
	/**
	 * Init grid component
	 * @param {boolean} showGrid Defaults to true
	 */
	initGridView: function (showGrid) {
		var filterMessages = lang.grid.filter,
			loadGrid = true;
		this.gridMessages = {
			clear: lang.CLEAR,
			info: lang.grid.filter.SHOW_ITEMS,
			filter: lang.FILTER
		};
		if (showGrid === false) {
			loadGrid = false;
			this.gridViewLoaded = false;
		} else {
			this.gridViewLoaded = true;
		}

		if (loadGrid) {
			this.showGridView = true;
			$('#cw_teams_view_toggle').find('li').first().addClass('is_selected');
			$('#cw_filter_reset').removeClass('hide');
			$('#cw_teams_list').addClass('hide');
			$('#cw_teams_grid').removeClass('hide');
		}

		var teamsColumns = this.teamColumns || JSON.parse(UserPrefs.get('teamsColumns')) || this.defaultColumns;
		teamsColumns = Utils.completeColumns(teamsColumns, this.defaultColumns);

		let scope = this;
		this.grid = $('#teams_grid').kendoCustomGrid({
			dataSource: this.dataSource,
			resizable: true,
			reorderable: true,
			selectable: 'row',
			autoBind: true,
			dataBound: $.proxy(this.onDataBound, this),
			sortable: {
				mode: 'multiple',
				allowUnsort: true
			},
			filterable: {
				extra: false,
				operators: {
					string: {
						startswith: filterMessages.STARTS_WITH,
						neq: filterMessages.NEQ,
						eq: filterMessages.EQ
					}
				},
				messages: this.gridMessages
			},
			columns: Utils.rearrangeColumns([{
				field: 'id',
				title: lang.SELECTOR,
				sortable: false,
				filterable: false,
				menu: false,
				template: '<input type="checkbox" class="cw_grid_check" data-id="#= id #" />',
				headerTemplate: '<input type="checkbox" class="cw_grid_check_all" />',
				headerAttributes: {
					'class': 'text_center'
				},
				attributes: {
					'class': 'text_center'
				},
				hidden: teamsColumns.id.hidden,
				width: teamsColumns.id.width
			}, {
				field: 'name',
				title: lang.NAME,
				sortable: true,
				filterable: true,
				template: '<a class="cw_grid_link ellipsis to_expand" data-id="#= id #">#= name #</a>',
				hidden: teamsColumns.name.hidden,
				width: teamsColumns.name.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'tags',
				title: lang.TAGS,
				sortable: true,
				filterable: {
					ui: $.proxy(function (element) {
						let multiselect = new MultiSelectGridFilter({
							element: element,
							field: 'tags',
							grid: this.grid,
							itemTemplate: '#=data.text#',
							tagTemplate: '#=data.text#',
							dataSource: this.filterOptions.tags,
							static: true
						});
					}, this),
					messages: this.gridMessages,
					extra: false,
					operators: {
						string: {
							eq: filterMessages.EQ,
							neq: filterMessages.NEQ
						}
					}
				},
				hidden: teamsColumns.tags.hidden,
				width: teamsColumns.tags.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'hasNotificationPolicy',
				title: lang.ACTION_TRIGGER,
				sortable: true,
				filterable: {
					operators: {
						string: {
							eq: filterMessages.ISIN,
							neq: filterMessages.ISNOTIN
						}
					},
					ui: element => {
						let multiselect = new MultiSelectGridFilter({
							element: element,
							field: 'hasNotificationPolicy',
							grid: scope.grid,
							dataSource: [{
								text: lang.ACTIVE,
								value: true
							}, {
								text: lang.INACTIVE,
								value: false
							}],
							itemTemplate: '#=data.text#',
							tagTemplate: '#=data.text#'
						});
					},
					messages: this.gridMessages
				},
				hidden: teamsColumns.hasNotificationPolicy.hidden,
				width: teamsColumns.hasNotificationPolicy.width,
				template: (item) => {
					if (item.hasNotificationPolicy) {
						return `<span class="operational">${lang.ACTIVE}</span>`
					} else {
						return lang.INACTIVE;
					}
				},
				attributes: {
					'class': 'text_center'
				}
			}, {
				field: 'description',
				title: lang.DESCRIPTION,
				sortable: false,
				filterable: true,
				template: '#= description || " " #',
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				},
				hidden: teamsColumns.description.hidden,
				width: teamsColumns.description.width
			}], teamsColumns),
			columnMenu: true,
			change: $.proxy(this.onRowExpand, this)
		}).data('kendoCustomGrid')
		// Add Kendo tooltip to the header of the columns
		Utils.gridColumnHeaderTooltip(this.grid);
		this.adjustSectionHeight();
		this.grid.thead.find("[data-field='id']>.k-header-column-menu").remove();
		var searchValue = UserPrefs.get('defaultFsView') ? '' : (UserPrefs.get('teamsSearchPhrase') || '');
		this.gridFilterPanel = new PreferencesPanel({
			renderTo: 'cw_teams_filters',
			grid: this.grid,
			modulePrefName: 'AdministrationTeams',
			defaultPrefViewKey: 'defaultFsView',
			prefListKey: 'teamsFSViews',
			userPref: this.userPref,
			onRemove: $.proxy(this.saveUserPreferences, this),
			searchFields: ['name', 'description'],
			searchValue: searchValue,
			defaultColumns: this.defaultColumns
		});
	},
	reloadByTags: function () {
		this.grid.dataSource.read();
	},
	getFilterableForNotification: function (element) {
		var menu = $(element).parent();
		menu.find(".k-filter-help-text").text(lang.grid.filter.SHOW_ITEMS);
		element.removeAttr("data-bind");
		var operatorEl = menu.find("[data-role=dropdownlist]");
		operatorEl.find('option[value="gte"]').remove();
		operatorEl.find('option[value="gt"]').remove();
		operatorEl.find('option[value="lte"]').remove();
		operatorEl.find('option[value="lt"]').remove();
		operatorEl.attr('data-index', '0');
		var multiSelect = element.kendoSortedMultiSelect({
			dataSource: [{
				text: lang.ACTIVE,
				value: true
			}, {
				text: lang.INACTIVE,
				value: false
			}],
			dataTextField: 'text',
			dataValueField: 'value',
			optionLabel: lang.grid.FILTER_SELECT_VALUE
		}).data('kendoSortedMultiSelect');
		menu.find('[type=submit]').on('click', {
			widget: multiSelect,
			operatorElement: operatorEl
		}, $.proxy(this.scope.filterByStatus, {
			scope: this.scope,
			dataSource: this.scope.dataSource,
			field: this.field
		}));
		menu.find('[type=reset]').on('click', $.proxy(function (e) {
			multiSelect.value([]);
		}, this));
		//fix for default value
		setTimeout(function () {
			operatorEl.data('kendoDropDownList').select(1);
		}, 100);
	},
	filterByStatus: function (e) {
		var indicators = e.data.widget.value();
		var operator = e.data.operatorElement.data('kendoDropDownList').value();
		var logic = (operator === 'eq') ? 'or' : 'and';
		var currentFilter = this.dataSource.filter();
		var filter = {logic: logic, filters: []};
		for (var i = 0; i < indicators.length; i++) {
			filter.filters.push({field: this.field, operator: operator, value: indicators[i]});
		}
		if (currentFilter && currentFilter.filters) {
			for (var i = 0; i < currentFilter.filters.length; i++) {
				if (currentFilter.filters[i].filters) {
					for (var j = 0; j < currentFilter.filters[i].filters.length; j++) {
						if (currentFilter.filters[i].filters[j].field === this.field) {
							currentFilter.filters[i].filters.splice(j, 1);
							j--;
						}
					}
				}
				if (currentFilter.filters[i].field === this.field) {
					currentFilter.filters.splice(i, 1);
					i--;
				}
			}
			if (filter.filters.length) {
				currentFilter.filters.push(filter);
			}
			this.dataSource.filter(currentFilter);
		} else {

			this.dataSource.filter(filter);
		}
	},
	/**
	 * Handler function for the change(select) event on the grid
	 */
	onRowExpand: function () {
		var selectedRow = this.grid.select();
		var myRow = selectedRow[0];
		var messageEl = $(myRow).find('.to_expand');
		if ($(messageEl).hasClass('cw_message_expanded')) {
			$(messageEl).removeClass('cw_message_expanded').addClass('ellipsis');
		} else {
			$('#teams_grid').find('.k-grid-content').find('td.cw_message_expanded').removeClass('cw_message_expanded').addClass('ellipsis');
			$(messageEl).addClass('cw_message_expanded').removeClass('ellipsis');
		}
	},
	/**
	 * Handler for the service checkbox click
	 * @param {Object} e The click event
	 */
	onCheck: function (e) {
		e.stopPropagation();
		var checkboxes = $('.cw_grid_check');
		var teams = [];
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			if ($(checkboxes[i]).is(':checked')) {
				teams.push($(checkboxes[i]).data('id'));
			}
		}
		if (teams.length === 1) {
			this.gridMenu.enableItem('cw_update');
		} else {
			this.gridMenu.disableItem('cw_update');
		}
		if (teams.length) {
			this.gridMenu.enableItem('cw_delete');
		} else {
			this.gridMenu.disableItem('cw_delete');
		}
	},
	/**
	 * Handler for the check all checkbox click
	 * @param {Object} e The click event
	 */
	onCheckAll: function (e) {
		var checkAll = $('.cw_grid_check_all').is(':checked');
		var checkboxes = $('.cw_grid_check');
		var i, length = checkboxes.length;
		for (i = 0; i < length; i++) {
			$(checkboxes[i]).prop('checked', checkAll);
		}
		if (checkAll && checkboxes.length === 1) {
			this.gridMenu.enableItem('cw_update');
			this.gridMenu.enableItem('cw_delete');
		} else {
			this.gridMenu.disableItem('cw_delete');
			this.gridMenu.disableItem('cw_update');
		}
	},
	/**
	 * Handler for the delete button click
	 * @param {Object} e The click event
	 */
	onTeamDelete: function (e) {
		if (!$(e.currentTarget).hasClass('disabled')) {
			var checkboxes = $('.cw_grid_check:checked');
			var i, length = checkboxes.length, dependenciesUrl, teams = [], deleteUrl, dialog;
			for (i = 0; i < length; i++) {
				if ($(checkboxes[i]).is(':checked')) {
					teams.push($(checkboxes[i]).attr('data-id'));
				}
			}
			if (teams.length) {
				this.gridMenu.disableItem('cw_agent_update');
				this.gridMenu.disableItem('cw_agent_delete');
				var teamId = teams[0];
				var record = this.dataSource.get(teamId);
				var teamName = record.name;
				dependenciesUrl = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/teams/' + teamId + '/dependencies';
				Utils.ajax(dependenciesUrl, 'GET', {}, $.proxy(function (data) {
					if (data.length) {
						this.openDependenciesPopup(data, teamName);
						setTimeout(this.adjustTableHeight, 100);
					} else {
						dialog = new Dialog({
							title: lang.INFO,
							msg: teams.length === 1 ? lang.account.messages.TEAM_REMOVE_CONFIRMATION : lang.account.messages.TEAMS_REMOVE_CONFIRMATION,
							icon: 'ERROR',
							actionText: 'DELETE',
							fn: $.proxy(function (value, button) {
								if (button === 'ok') {
									if (teams.length === 1) {
										deleteUrl = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/teams/' + teams[0];
										Utils.ajax(deleteUrl, 'DELETE', {}, $.proxy(function (result) {
											if (result.success) {
												var status = $('.status');
												status.find('p').addClass('success');
												status.find('p').text(lang.account.messages.TEAMS_REMOVE_CONFIRMATION);
												status.slideDown().delay(2000).slideUp();
												this.dataSource.read();
											} else {
												Utils.showInfo(lang.ALERT, result.message, result.details, this.grid);
											}
										}, this));
									} else {
										deleteUrl = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/teams/delete';
										Utils.ajax(deleteUrl, 'POST', JSON.stringify(teams), $.proxy(function (result) {
											if (result.success) {
												var status = $('.status');
												status.find('p').addClass('success');
												status.find('p').text(lang.account.messages.TEAMS_REMOVE_CONFIRMATION);
												status.slideDown().delay(2000).slideUp();
												this.dataSource.read();
											} else {
												Utils.showInfo(lang.ALERT, result.message, result.details, this.grid);
											}
										}, this));
									}
								}
							}, this),
							deleteFromGrid: this.grid
						});
						dialog.show();
					}
				}, this));
			}
		}
	},
	/**
	 * Creates and opens the team dependencies window
	 * @param {Array} dependencies - The team dependencies array
	 * @param {String} teamName The team name
	 */
	openDependenciesPopup: function (dependencies, teamName) {
		this.dependenciesWindow = new ModalWindow({
			title: lang.INFO,
			width: 960,
			height: 640,
			minWidth: 220,
			actions: [],
			url: 'include/Dependencies.jsp',
			onClose: $.proxy(function () {
				this.clearCheckboxes();
			}, this),
			refresh: function () {
				new Dependencies({
					accountId: Cookies.CeesoftCurrentAccountId,
					dependencies: dependencies,
					name: teamName,
					infoTemplate: lang.account.messages.TEAM_DELETE_INFO
				});
			}
		});
		this.dependenciesWindow.open();
	},
	/*
	 * Handler function for clearing the checkboxes
	 */
	clearCheckboxes: function () {
		var checkAll = $('#cw_asset_groups_grid').find('.cw_grid_check_all'),
			checkboxes = $('#cw_asset_groups_grid').find('.cw_grid_check'),
			i, length = checkboxes.length;
		checkAll.prop('checked', false);
		for (i = 0; i < length; i++) {
			$(checkboxes[i]).prop('checked', false);
		}
	},
	/**
	 * Handler function for the click event on the team name link
	 * @param {Object} e The click event object
	 */
	onTeamNameClick: function (e) {
		var mode = 'view';
		if (State.mainApp.session.hasRole('TEAM_UPDATE')) {
			mode = 'update';
		}
		var uid = $(e.currentTarget).closest('tr').data('uid');
		var team = this.grid.dataSource.getByUid(uid);

		State.mainApp.navigate(TeamRouter.profile(team.id));

		e.stopPropagation();
	},
	/**
	 * Saves user preferences
	 */
	saveUserPreferences: function () {
		var preferences = [{
			key: 'showGridView',
			value: this.showGridView
		}];
		this.saveUserPrefs({
			category: 'AdministrationTeams',
			filterPanel: this.gridFilterPanel,
			grid: this.grid,
			eventsToolbar: this.eventsToolbar,
			preferences: preferences,
			keys: {
				searchPhrase: 'teamsSearchPhrase',
				columns: 'teamsColumns',
				sort: 'teamsSort',
				filter: 'teamsFilter',
				FsViews: 'teamsFSViews'
			}
		});
	},
	createDataSource: function () {
		this.dataSource = new kendo.ceeview.DataSource({
			transport: {
				read: {
					url: Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/teams?',
					contentType: "application/json; charset=utf-8",
					type: "GET",
					dataType: "json",
					cache: false
				}
			},
			schema: {
				parse: function (result) {
					for (let i = 0; i < result.length; i++) {
						if (result[i].tags) {
							result[i].tags = result[i].tags.join(', ');
						}
					}
					return result;
				}
			},
			sort: this.teamsSort || [{
				field: 'name',
				dir: 'asc',
				compare: null
			}],
			filter: this.teamsFilter || [],
			error: ErrorHandler.kendoServerError
		});
	},
	/**
	 * Loads user preferences
	 */
	loadUserPreferences: function () {
		var defaultFsView, showGridView, pref;
		UserPrefs.load('AdministrationTeams', $.proxy(function (result) {
			if (result.success) {
				this.userPref = result.data;
				showGridView = UserPrefs.get('showGridView');
				defaultFsView = UserPrefs.get('defaultFsView');

				if (Utils.isGuid(defaultFsView)) {
					pref = JSON.parse(UserPrefs.get(defaultFsView));
					pref = pref || {
						sort: [],
						filter: []
					};
					this.teamsSort = pref.sort;
					this.teamsFilter = pref.filter;
					this.teamColumns = pref.columns;
				} else {
					if (UserPrefs.get('teamsSort')) {
						this.teamsSort = JSON.parse(UserPrefs.get('teamsSort'));
					} else {
						this.teamsSort = [{
							field: 'name',
							dir: 'asc',
							compare: null
						}];
					}
					if (UserPrefs.get('teamsFilter')) {
						this.teamsFilter = JSON.parse(UserPrefs.get('teamsFilter'));
					} else {
						this.teamsFilter = [];
					}
				}

				if (showGridView) {
					this.showGridView = showGridView === 'true' ? true : false;
				} else {
					this.showGridView = true;
				}
				this.cardGridToggle.select(this.showGridView ? 'cw_grid_view' : 'cw_card_view');

				this.createDataSource();
				var eventStatus = JSON.parse(UserPrefs.get('eventsStatus')) || {
					startFrom: 0,
					playing: true
				};
				this.eventsToolbar = new EventsToolbar({
					renderTo: $('#cw_teams_events_toolbar'),
					callBackFn: $.proxy(this.onEventsTimeout, this),
					subscriberId: this.subscriberId,
					startFrom: eventStatus.startFrom,
					playing: eventStatus.playing
				});
				if (this.showGridView) {
					this.initGridView();
				} else {
					this.initCardView();
					this.initGridView(false);
				}
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},
	/**
	 * Handler function for the team saved event
	 */
	onTeamSaved: function (config) {
		this.dataSource.read();
		this.actionNotification.setOptions({
			message: config.text,
			status: 'success'
		}).show();
	},
	/**
	 * Method by Andy
	 */
	adjustSectionHeight: function () {
		var section = $('.cw_section_full');
		var sectionHeight = section.height();
		section.find('.cw_section_content').css('height', sectionHeight);
		// removing the height of cw_section_titlebar and table header
		section.find('.k-grid-content').css('height', sectionHeight - 40);
	},
	/**
	 * Subscribes to the events
	 */
	subscribe: function () {
		this.isDataSourceSubscribed = true;
		var subscriptionObj = [{
			eventType: 'Administration',
			//Entity types: ACCOUNT, AGENT, ASSET, ASSET_GROUP, AUTHENTICATION, MONITOR, NOTIFICATION_POLICY, TEAM, TEAM_CALENDAR, USER
			entityType: 'TEAM',
			//Optional. If entityId is specified, only events for this entity are received
			//entityId:"1b8a6722-97a5-4e85-adf1-fcd2c958ebe5"
			//Optional. If accountId is specified, events for all entities of type "entityType" in this account are received
			accountId: Cookies.CeesoftCurrentAccountId
		}];
		RemoteEventsManager.subscribe(this.subscriberId, subscriptionObj);
	},
	onEventsTimeout: function (events) {
		this.dataSource.read();
	},
	renderDocumentationButton: function () {
		ReactDOM.render(<DocumentationButton type={'actiontrigger'}>
			</DocumentationButton>, $('.cw_documentation').get(0));
	},
	/**
	 * Destroy
	 */
	destroy: function () {
		this.saveUserPreferences();
		if (this.eventsToolbar) {
			this.eventsToolbar.destroy();
		}
		Application.prototype.destroy.call(this);
	}
});
