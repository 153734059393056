
import classnames from 'classnames';

export function getSlaState(slaHelper) {
	let slaIndicator;
	let html = '';
	let tooltip = '';
	let classArray = ['glyphicons', 'status_icon', 'pointer'];
	switch (typeof slaHelper) {
		case 'object':
			if (slaHelper && slaHelper.total) {
				slaIndicator = slaHelper.indicator;
			}
			break;
		case 'number':
		case 'string':
			slaIndicator = slaHelper;
			break;
	}
	switch (slaIndicator) {
		case 'FAILED':
		case 3:
			classArray.push('circle-arrow-down');
			tooltip = lang.BREACHED;
			break;
		case 'WARNING':
		case 2:
			classArray.push('circle-arrow-right');
			tooltip = lang.WARNING;
			break;
		case 'OK':
		case 1:
			classArray.push('circle-arrow-top');
			tooltip = lang.COMPLIANT;
			break;
		default:
			classArray = ['cw_status_indicator', 'cw_status_widget_color', 'cw_color6'];
	}
	let classes = classnames(classArray);
	html = `<span class="${classes}" title="${tooltip}">`;

	return html;
}
