import Settings from 'settings'
import Cookies from 'core/cookies'
import {UrlBuilder} from "tools";
import {ApplicationState} from "framework/applicationState";

function login(){
	const builder = new UrlBuilder(window.location.href);
	const isSso = 'sso' in builder.params;

	function detectIE() {
		var ua = window.navigator.userAgent;
		var msie = ua.indexOf('MSIE ');
		if (msie > 0) {
			return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
		}
		var trident = ua.indexOf('Trident/');
		if (trident > 0) {
			var rv = ua.indexOf('rv:');
			return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
		}
		return false;
	}

	function loadProgress() {
		var url = Settings.managementPath + 'progress';
		$.ajax({
			type: "GET",
			url: url,
			data: "",
			contentType: "application/json; charset=utf-8",
			dataType: "json",
			success: function (result) {
				if (result.data < 100 && result.data > 0) {
					$('.login_content').find('.cw_mask').removeClass('hide');
					$('.cw_progress_content').removeClass('hide');
					$('.cw_preloader').css('display', 'block');
					$('#cw_progress_value').text(result.data + '%');
				} else {
					$('.login_content').find('.cw_mask').addClass('hide');
					$('.cw_preloader').css('display', 'none');
					$('.cw_progress_content').addClass('hide');
				clearInterval(document.checkLoginProgress);
				}
			}
		});
	}

	function getSessionConfig() {
		var url = Settings.serverPath + 'sessions/config';
		$.ajax({
			type: "GET",
			url: url,
			data: "",
			contentType: "application/json; charset=utf-8",
			dataType: "json",
			success: function (result) {
				if (result.success) {
					if (result.data?.ssoType === 'AZURE') {
						$('#login_azure_container').removeClass('hide');
						$('#login_azure_button').on('click', () => {
							window.location = result.data.ssoRedirect;
						});
						$('#login_local_user').on('click', () => {
							$('#login_azure_container').addClass('hide');
							$('#login_form').removeClass('hide');
						});
					} else {
						$('#login_form').removeClass('hide');
						if (result.data.loginMessage) {
							let infoContent = $('#cw_info_content');
							infoContent.removeClass('hide');
							infoContent.find('p').html(result.data.loginMessage);
							if (result.data.loginMessageSeverity === 'ERROR') {
								infoContent.css({
									'background-color': '#f2dede',
									'color': '#963b3b'
								});
							} else if (result.data.loginMessageSeverity === 'WARNING') {
								infoContent.css({
									'background-color': '#fcf8e3',
									'color': '#efcd6c'
								});
							}
						}
					}
					checkIfParamException();
				}
			}
		});
	}

	function checkIfParamException() {
		if (builder.params.exception) {
			let status = $('.status');
			status.find('p').addClass('error');
			let exceptionText = builder.params.exception;
			exceptionText = exceptionText.replace(/%20/g, " ");
			status.find('p').text(exceptionText);
			status.show();
			$('#login_form').removeClass('hide');
			$('#login_azure_container').addClass('hide');
			$('.cw_field').addClass('hide');
			$('#login').addClass('hide');
			$('#return_to_login').removeClass('hide').on('click', function(e) {
				e.preventDefault();
				status.find('p').text('');
				status.find('p').removeClass('error');
				status.hide();
				$(this).addClass('hide');
				$('.cw_field').removeClass('hide');
				$('#login').removeClass('hide');
			})
		}
	}

	$(document).ready(function () {
		if (detectIE()) {
			$('#cw_login_form').addClass('hide');
			$('#cw_ie_forbidden').removeClass('hide');
			$('#login').attr('disabled', true);
		} else {
			//load language script
			var status = $('.status');
			status.find('p').text('');
			status.find('p').removeClass('error');
			status.hide();
			$('#username').val('');
			$('#password').val('');

			if (isSso) {
				let ssoParam = '?sso=' + (builder.params.sso || '');
				var url = Settings.ssoPath + 'login' + ssoParam;
				jQuery.ajax({
					url: url,
					type: "GET",
					dataType: "json",
					contentType: "application/json; charset=utf-8",
					success: function (result) {
						if (result.success) {
							createCookies(result);
							Utils.redirectTo(Settings.httpRoot, true);
						}
					}
				});
			}

			loadProgress();
			document.checkLoginProgress = setInterval(loadProgress, 5000);

			getSessionConfig();

			$('#login').on('click', function (button) {
				button.preventDefault();
				var username = $('#username').val();
				var password = $('#password').val();
				if (username && password) {
					$('#username').removeClass('invalid');
					$('#password').removeClass('invalid');
					var auth = {
						username: username,
						password: password
					};
					// $(this).hide();
					$('.login_content').find('.cw_mask').removeClass('hide');
					jQuery.ajax({
						url: Settings.serverPath + 'sessions/login/',
						//url: 'http://10.0.0.71:8080/ceeview/rest/sessions/login/',
						data: JSON.stringify(auth),
						type: "POST",
						dataType: "json",
						contentType: "application/json; charset=utf-8",
						crossDomain: true,
						beforeSend: function (x) {
							if (x && x.overrideMimeType) {
								x.overrideMimeType("application/json;charset=UTF-8");
							}
						},
						error: function (result, message) {
							$('#login').show();
							status.find('p').addClass('error');
							status.find('p').text(lang.account.messages.WARNING_USER_AUTH_CONNECTION_ISSUE);
							status.show().delay(2000).slideUp();
						},
						success: function (result) {
							var location;
							$('.login_content').find('.cw_mask').addClass('hide');
							if (result.success) {
								createCookies(result);
								Utils.redirectTo(Settings.httpRoot, true);
							} else {
								$('#login').show();
								switch (result.message) {
									case 'wizard':
										window.location = 'Wizard.jsp';
										break;
									case 'umchpwd':
										window.location = 'ChangePassword.jsp?user=' + $('#username').val();
										break;
									default:
										var status = $('.status');
										status.find('p').text(result.message);
										status.find('p').addClass('error');
										status.show().delay(2000).slideUp();
								}
							}
						}
					});
				} else {
					if (!username) {
						$('#username').addClass('invalid');
					}
					if (!password) {
						$('#password').addClass('invalid');
					}
				}
			});
			$('#username').blur(function () {
				if ($(this).val() === '') {
					$(this).addClass('invalid');
				} else {
					$(this).removeClass('invalid');
				}
			});
			$('#password').blur(function () {
				if ($(this).val() === '') {
					$(this).addClass('invalid');
				} else {
					$(this).removeClass('invalid');
				}
			});
		}
	});
};

export function createCookies(result) {
	var timeout = Settings.COOKIE_TIMEOUT;
	Cookies.create("CeesoftUsername", result.data.username, timeout);
	Cookies.create("CeesoftUserId", result.data.userId, timeout);
	Cookies.create("CeesoftAccountId", result.data.accountId, timeout);
	Cookies.create("CeesoftTimezone", result.data.timeZone, timeout);
	Cookies.create("CeesoftUserLocale", result.data.locale, timeout);
	Cookies.create("CeesoftUserDateTimeFormat", result.data.dateTimeFormat, timeout);
	Cookies.create("CeesoftUserDateFormat", result.data.dateFormat, timeout);
	Cookies.create("CeesoftUserTimeFormat", result.data.timeFormat, timeout);
	Cookies.create("CeesoftUserPictureId", result.data.pictureId, timeout);
	Cookies.create("CeesoftAccountName", result.data.accountName, timeout);
	Cookies.create("CeesoftUserEmail", result.data.email, timeout);

	if (result.data.loginAccountId && result.data.loginAccountName) {
		Cookies.create("CeesoftCurrentAccountId", result.data.loginAccountId, timeout);
		Cookies.create("CeesoftCurrentAccountName", result.data.loginAccountName, timeout);
	} else {
		Cookies.create("CeesoftCurrentAccountId", result.data.accountId, timeout);
		Cookies.create("CeesoftCurrentAccountName", result.data.accountName, timeout);
	}

	if (result.data.landingPage) {
		Cookies.create("CeesoftLandingPage", result.data.landingPage, timeout);
	} else {
		Cookies.erase("CeesoftLandingPage");
	}
	if (result.data.landingPageLayout) {
		Cookies.create("CeesoftLandingPageLayout", result.data.landingPageLayout, timeout);
	} else {
		Cookies.erase("CeesoftLandingPageLayout");
	}

	Cookies.create("CeesoftAccountLogoId", result.data.logoId, timeout);
	Cookies.erase("CeesoftParentAccountId");
	Cookies.erase("CeesoftParentAccountName");
	Cookies.create("sessionId", result.data.sessionId, Settings.COOKIE_TIMEOUT);

	ApplicationState.initFromCookies();
}

export default login;
