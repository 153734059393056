import {Application, LocalEventsManager, Cookies} from "core";
import {Chosen, CustomNotification, ExpandableTextarea, ImageUploader, Tags} from "controls";
import {CeeViewDataSource} from "tools";
import {Utils, Api} from "tools";
import RuleGenerator from 'controls/ruleGenerator';
import MultiToggle from 'controls/multiToggle';
import {combineDateAndTime, convertTimezone} from "tools/dateTimeUtils";
import Settings from 'settings'
import {newGuid} from "tools/guid";
import {ApplicationState} from "framework/applicationState";

export class CalendarEventForm {
	loadingForm = true;
	constructor(config) {
		Object.assign(this, config);

		//we need to convert time from the user's timezone to the selected in the event because in calendar we use user's
		if(this.event.id) {
			if (this.event.allDay) {
				this.event.start.setHours(0);
				this.event.start.setMinutes(0);
				this.event.end.setHours(0);
				this.event.end.setMinutes(0);
			} else {
				this.event.start = convertTimezone(convertTimezone(this.event.start, ApplicationState.timezone, 'UTC'), ApplicationState.timezone, this.event.timezone);
				this.event.end = convertTimezone(convertTimezone(this.event.end, ApplicationState.timezone, 'UTC'), ApplicationState.timezone, this.event.timezone);
			}
		}
		this.initComponent();
	}

	getName(){
		return $('#cw_calendar_event_name').val().trim();
	}

	initComponent () {
		this.formEl = $('#cw_calendar_event_form');
		this.removeListeners();
		this.attachListeners();

		if(this.mode === 'update'){
			$('#cw_event_organizer').val(this.event.organizer?.replace('mailto:', '') || 'N/A');
			$('#cw_event_created').val(this.event.created ? Renderer.browserDateRenderer(this.event.created, 'datetime') : 'N/A');
		}
		else{
			$('#cw_event_organizer').parent().hide();
			$('#cw_event_created').parent().hide();
		}


		this.initKendoComponents();
		if (this.mode === 'update') {
			$('#save_calendar_event').text(lang.UPDATE);
			$('#delete_calendar_event').removeClass('hide');
			this.load();
		} else {
			$('#save_calendar_event').text(lang.CREATE);
		}
		this.disabelUnacceesableDates();

		this.statusNotification = new CustomNotification({
			appendToElement: '.window_area',
			status: 'success',
			style: 'top:15px; right:15px; left:15px;',
			type: 'icon',
			hideOnClick: true
		});

		this.modalNotification = new CustomNotification({
			appendToElement: '#modal',
			status: 'success',
			type: 'icon',
			hideOnClick: true
		});
	}

	initKendoComponents () {
		this.isRecurringEvent = !!this.event.rruleObject;

		this.fromDate = $('#cw_event_from_day').kendoDatePicker({
			format: Utils.datePatternConverter(Cookies.CeesoftUserDateFormat),
			value: this.event.start,
			change: this.ensureIntervalValid
		}).data('kendoDatePicker');
		this.fromAllDayToggle = new MultiToggle({
			id: 'cw_from_all_day_toggle',
			cssClass: 'cw_multi_toggle left',
			value: false,
			items: [{
				id: 'cw_from_not_all_day',
				title: lang.CUSTOM,
				selected: true,
				cssClass: 'glyphicons stopwatch',
				fn: this.onFromAllDayClick,
				scope: this,
				value: false
			}, {
				id: 'cw_from_all_day',
				title: lang.service.SLA_ALL_DAY,
				selected: false,
				cssClass: 'glyphicons calendar all_day',
				fn: this.onFromAllDayClick,
				scope: this,
				value: true
			}]
		});

		this.fromTime = $('#cw_event_from_hour').kendoTimePicker({
			format: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat),
			value: this.event.start,
			change: this.ensureIntervalValid
		}).data('kendoTimePicker');

		$('#cw_event_from_day').closest('.k-datepicker').addClass('cw_event_form_date_picker');
		$('#cw_event_from_hour').closest('.k-timepicker').addClass('cw_event_form_hour_picker');
		$('#cw_from_all_day_toggle').addClass('cw_event_form_time_toggle');

		this.timezone = $('#cw_calendar_timezone').kendoDropDownList({
			dataSource: new CeeViewDataSource({
				transport: {
					read: {
						url: Settings.serverPath + 'sessions/timezones/',
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				}
			}),
			autoBind: true,
			value: this.event?.timezone ?? Cookies.CeesoftTimezone
		}).data('kendoDropDownList');

		this.toDate = $('#cw_event_to_day').kendoDatePicker({
			format: Utils.datePatternConverter(Cookies.CeesoftUserDateFormat),
			value: this.event.end,
			change: this.ensureIntervalValid
		}).data('kendoDatePicker');

		this.toAllDayToggle = new MultiToggle({
			id: 'cw_to_all_day_toggle',
			cssClass: 'cw_multi_toggle left',
			value: false,
			items: [{
				id: 'cw_to_not_all_day',
				title: lang.CUSTOM,
				selected: true,
				cssClass: 'glyphicons stopwatch',
				fn: this.onToAllDayClick,
				scope: this,
				value: false
			}, {
				id: 'cw_to_all_day',
				title: lang.service.SLA_ALL_DAY,
				selected: false,
				cssClass: 'glyphicons calendar all_day',
				fn: this.onToAllDayClick,
				scope: this,
				value: true
			}]
		});

		this.toTime = $('#cw_event_to_hour').kendoTimePicker({
			format: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat),
			value: this.event.end,
			change: this.ensureIntervalValid
		}).data('kendoTimePicker');

		$('#cw_event_to_day').closest('.k-datepicker').addClass('cw_event_form_date_picker');
		$('#cw_event_to_hour').closest('.k-timepicker').addClass('cw_event_form_hour_picker');
		$('#cw_to_all_day_toggle').addClass('cw_event_form_time_toggle');

		if (this.fromDate.value()) {
			this.toDate.min(this.fromDate.value());
		}

		if (this.disableRecurringEvent) {
			$('#cw_toggle_recurring_event').closest('.cw_field').addClass('hide');
		}
		this.loadingForm = false;
	}

	onFromAllDayClick(value) {
		let sameDate = this.isTheSameDay(this.fromDate.value(), this.toDate.value());
		if (value) {
			this.fromTime.enable(false);
			this.fromTimeDisabled = true;
			if (sameDate && !this.fromToSameAllDay) {
				this.fromToSameAllDay = true;
				this.toAllDayToggle.setSelectedItem(true);
				this.onToAllDayClick(true);
			}
		} else {
			this.fromTime.enable(true);
			this.fromTimeDisabled = false;
			this.fromToSameAllDay = false;
		}
	}

	onToAllDayClick(value) {
		if (value) {
			this.toTime.enable(false);
			this.toTimeDisabled = true;
			let sameDate = this.isTheSameDay(this.fromDate.value(), this.toDate.value());
			if (sameDate && !this.fromToSameAllDay) {
				this.fromToSameAllDay = true;
				this.fromAllDayToggle.setSelectedItem(true);
				this.onFromAllDayClick(true);
			}
		} else {
			this.toTime.enable(true);
			this.toTimeDisabled = false;
			this.fromToSameAllDay = false;
		}
	}

	ensureIntervalValid = () => {
		if (moment(this.toDate.value()).isBefore(this.fromDate.value(), 'day')) {
			this.toDate.value(this.generateToTime(this.fromDate.value()));
			this.toTime.value(this.generateToTime(this.fromTime.value()));
		}
		if (!this.toTime.value()) {
			this.toTime.value(this.generateToTime(this.fromTime.value()));
		}
		if (this.isTheSameDay(this.fromDate.value(), this.toDate.value()) && moment(this.toTime.value()).isBefore(this.fromTime.value(), 'minute')) {
			this.toTime.value(this.generateToTime(this.fromTime.value()));
		}
		this.disabelUnacceesableDates();
	}

	disabelUnacceesableDates = () => {
		this.toTime.max(new Date(1900, 0, 1, 23, 59));
		this.fromTime.max(new Date(1900, 0, 1, 23, 59));
		this.toTime.min(new Date(1900, 0, 1));
		this.fromTime.min(new Date(1900, 0, 1));

		if(!this.fromDate.value()){
			this.fromDate.value(new Date());
		}

		this.toDate.min(this.fromDate.value());
		if (this.isTheSameDay(this.fromDate.value(), this.toDate.value())) {
			this.toTime.min(this.fromTime.value())
		}
	}

	isTheSameDay(firstDate, secondDate) {
		return moment(firstDate).isSame(moment(secondDate), 'day');
	}

	generateToTime(fromTime) {
		return new Date(fromTime.getFullYear(), fromTime.getMonth(), fromTime.getDate(), fromTime.getHours() + 1);
	}

	load() {
		$('#cw_calendar_event_name').val(this.event.title);
		if (this.event.allDay) {
			this.fromAllDayToggle.setSelectedItem(true);
			this.onFromAllDayClick(true);
			this.toAllDayToggle.setSelectedItem(true);
			this.onToAllDayClick(true);
			this.toDate.value(new Date(this.event.end.setDate(this.event.end.getDate() - 1)));
		}

		if (this.event.rruleObject) {
			$('#cw_toggle_recurring_event').prop('checked', true);
			this.onToggleRecurring(null, true);
		}

		this.validate();
	}

	removeListeners () {
		$('#save_calendar_event').off();
		$('#cancel').off();
	}

	attachListeners () {
		$('#cancel').on('click', $.proxy(this.onCancelButton, this));
		$('#save_calendar_event').on('click', $.proxy(this.onSaveButton, this));
		$('#delete_calendar_event').on('click', $.proxy(this.onDeleteButton, this));
		$('#cw_toggle_recurring_event').on('click', $.proxy(this.onToggleRecurring, this));
		$('#cw_calendar_event_name').on('keyup', $.proxy(this.onNameKeyUp, this));
	}

	clearInvalid () {
		this.formEl.find('.js_calendar_event_name').removeClass('invalid');
	}

	onNameKeyUp () {
		this.validate()
	}

	validate() {
		Utils.setInvalidField(this.getName(), $('#cw_calendar_event_name'), false, 'required_name');
		const isValid = this.getName !== '' && !!this.fromDate.value() && !!this.toDate.value();
		$('#save_calendar_event').attr('disabled', !isValid);
		$('#delete_calendar_event').attr('disabled', this.toDate.value() < new Date());
	}

	onSaveButton () {
		if(!this.fromDate.value() || !this.toDate.value())
			return;

		let fromTimestamp = combineDateAndTime(this.fromDate.value(), this.fromTime.value(), this.fromTimeDisabled)
		let toTimestamp = combineDateAndTime(this.toDate.value(), this.toTime.value(), this.toTimeDisabled)

		if(toTimestamp.getHours() == 0 && toTimestamp.getMinutes() == 0 && this.isTheSameDay(fromTimestamp, toTimestamp)){
			toTimestamp.setDate(toTimestamp.getDate() + 1)
		}

		if(moment(fromTimestamp).isSameOrAfter(toTimestamp)){
			toTimestamp = moment(fromTimestamp).add('1', 'minutes').toDate()
		}

		let data = {
			id: this.event.id || newGuid(),
			title: this.getName().trim(),
			start: convertTimezone(convertTimezone(fromTimestamp, this.timezone.value(), ApplicationState.timezone), 'UTC', ApplicationState.timezone),
			end: convertTimezone(convertTimezone(toTimestamp, this.timezone.value(), ApplicationState.timezone), 'UTC', ApplicationState.timezone),
			timezone: this.timezone.value(),
			allDay: this.fromTimeDisabled && this.toTimeDisabled ? true : false,
			extendedProps: this.event.extendedProps,
			created: this.event.created,
			organizer: this.event.organizer,
			categories: this.event.categories
		}

		if (this.isRecurringEvent) {
			let stringRule = this.ruleGenerator.getRule()
			let rule = this.ruleGenerator.parseRule(stringRule, {
				lowerCaseKeys: true,
				bydayToByweekday: true
			})

			if(rule.byweekday){
				rule.byweekday = rule.byweekday.split(',')
			}

			if(rule.until) {
				rule.until = moment.tz(this.ruleGenerator.until.replace('Z',''), this.timezone.value())
					.utc()
					.format()
			}

			data.rruleObject = rule
		}

		$('#cancel').trigger('click');
		if (this.mode === 'create') {
			this.onSave(data);
		} else if (this.mode === 'update') {
			this.onEdit(data);
		}
	}

	onDeleteButton () {
		if (!this.isRecurringEvent) {
			$('#cancel').trigger('click');
		}

		this.onDelete(this.event.id);
	}

	onCancelButton () {
		var modalWindow = $('#modal').data("kendoWindow");
		modalWindow.close();
		modalWindow.destroy();
	}

	onToggleRecurring(e, isRecurring) {
		let target = e ? $(e.target) : '';
		var ruleGeneratorContainer = $('#cw_event_rule_generator');
		if (isRecurring || target.is(':checked')) {
			this.isRecurringEvent = true;
			ruleGeneratorContainer.removeClass('hide');
			if (!this.ruleGenerator) {
				let toDate = this.toDate.value();
				let toDateTime, toTime;
				if (this.toTimeDisabled) {
					toDateTime = new Date(toDate.setDate(toDate.getDate() + 1));
					this.additionalDayAdded = true;
					toDateTime = new Date(toDate.setHours(0));
					toDateTime = new Date(toDate.setMinutes(0));
				} else {
					toTime = this.toTime.value();
					toDateTime = new Date(toDate.setHours(toTime.getHours()));
					toDateTime = new Date(toDateTime.setMinutes(toTime.getMinutes()));
				}

				this.ruleGenerator = new RuleGenerator({
					renderTo: 'cw_event_rule_generator',
					noMinutely: true,
					mode: this.mode,
					type: 'calendar',
					untilDate: toDateTime
				});
				$('#cw_rule_end_pick').closest('.k-datetimepicker').css('width', '200px');
			}
			if (this.event.rruleObject) {
				this.fillRecurringData();
			}
		} else {
			this.isRecurringEvent = false;
			ruleGeneratorContainer.addClass('hide');
		}
	}

	fillRecurringData () {
		var daysArray = [], daysString;
		let frequencySelector = $('#cw_rule_frequency').data('kendoDropDownList');
		let frequencyValue = this.event.rruleObject.freq;
		frequencySelector.value(frequencyValue);
		frequencySelector.trigger('change');

		let intervalSelector = $('#cw_rule_interval').data('kendoNumericTextBox');
		intervalSelector.value(this.event.rruleObject.interval);
		this.ruleGenerator.onIntervalChange();

		if (this.event.rruleObject.until) {
			let ruleEndSelector = $('#cw_rule_end').data('kendoDropDownList');
			ruleEndSelector.value('ONDATE');
			ruleEndSelector.trigger('change');

			let ruleEndTimeSelector = $('#cw_rule_end_pick').data('kendoDateTimePicker');
			ruleEndTimeSelector.value(moment.utc(this.event.rruleObject.until).tz(this.timezone.value())
				.format());
			ruleEndTimeSelector.trigger('change');
		}
		if (this.event.rruleObject.byweekday) {
			for (let i = 0 ; i < this.event.rruleObject.byweekday.length; i++) {
				let day = this.event.rruleObject.byweekday[i];

				$('[data-value="' + day + '"]').addClass('is_selected');

				daysArray.push(day);
				daysString = daysArray.toString();
			}
			this.ruleGenerator.byDay = daysString;

			this.ruleGenerator.setRule();
		}
		if (this.event.rruleObject.bymonthday) {
			if (this.event.rruleObject.bymonth) {
				let monthSelector = $('#cw_rule_month_select').data('kendoDropDownList');
				monthSelector.value(this.event.rruleObject.bymonth);
				let daySelector = $('#cw_rule_year_month_day_select').data('kendoDropDownList');
				daySelector.value(this.event.rruleObject.bymonthday);
				this.ruleGenerator.byMonth = this.event.rruleObject.bymonth;

			} else {
				let daySelector = $('#cw_rule_day_select').data('kendoDropDownList');
				daySelector.value(this.event.rruleObject.bymonthday);
			}
			this.ruleGenerator.byMonthDay = this.event.rruleObject.bymonthday;
			this.ruleGenerator.setRule();
		}
	}

	closeForm() {
		$('#cancel').trigger('click');
	}
}
