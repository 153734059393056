import {Application, Cookies, LocalEventsManager} from "core";
import {CeeViewDataSource, Renderer, State, UserPrefs, Utils} from "tools";
import {Settings} from "settings";
import {CustomNotification, Dialog, GridMenu, PreferencesPanel} from "controls";
import MultiSelectGridFilter from 'controls/multiSelectGridFilter';
import EventsToolbar from 'controls/eventsToolbar';

export default function FiltersView(config) {
	Utils.apply(this, config);
	this.module = {
		name: 'AssetHealthFilters',
		initialConfig: config
	};
	this.loadUserPreferences();
};

jQuery.extend(FiltersView.prototype, Application.prototype, {
	/**
	 * @property
	 * @type string
	 */
	type: 'Application',
	/**
	 * This is main init function
	 */
	initComponent: function () {
		Application.prototype.initComponent.call(this);

		this.getTags();

		State.mainApp.contextMenu.setActionButton('HEALTHFILTER');

		this.actionNotification = new CustomNotification({
			appendToElement: '.cw_health_filters_list',
		});
		this.id = Utils.guid();
		this.initKendoComponents();
	},
	getTags: async function () {
		let tags = await Utils.getAccountTags();
		this.filterOptions = {
			tags: tags
		};
	},
	/**
	 * Account application related initializations can be done here
	 */
	initKendoComponents: function () {
		this.checkboxStates = [];
		let eventStatus = JSON.parse(UserPrefs.get('eventsStatus')) || {
			startFrom: 0,
			playing: true
		};
		this.eventsToolbar = new EventsToolbar({
			renderTo: $('#cw_filter_events_toolbar'),
			callBackFn: $.proxy(this.onEventsTimeout, this),
			subscriberId: this.subscriberId,
			startFrom: eventStatus.startFrom,
			playing: eventStatus.playing
		});
		this.defaultColumns = {
			id: {
				hidden: false,
				width: 40
			},
			name: {
				hidden: false,
				width: 200
			},
			accountName: {
				hidden: !State.includeSubaccounts,
				width: 150
			},
			active: {
				hidden: false,
				width: 100
			},
			filtered: {
				hidden: false,
				width: 150
			},
			created: {
				hidden: false,
				width: 150
			},
			monitorType: {
				hidden: false,
				width: 150
			},
			reasonSubsystem: {
				hidden: false,
				width: 150
			},
			identifierName: {
				hidden: false,
				width: 200
			},
			identifierInstance: {
				hidden: false,
				width: 200
			},
			targetType: {
				hidden: false,
				width: 200
			},
			reasonDescription: {
				hidden: false,
				width: 120
			},
			tags: {
				hidden: false,
				width: 150
			}
		};
		this.gridMessages = {
			isTrue: ' ' + lang.YES + ' ',
			isFalse: ' ' + lang.NO + ' ',
			clear: lang.CLEAR,
			info: lang.grid.filter.SHOW_ITEMS,
			filter: lang.FILTER
		};
		var filterMessages = lang.grid.filter;
		this.removeListeners();
		this.attachListeners();

		var ahFiltersSort, ahFiltersFilter;
		var defaultFsView = UserPrefs.get('defaultFsView');
		if (Utils.isGuid(defaultFsView)) {
			var pref = JSON.parse(UserPrefs.get(defaultFsView));
			pref = pref || {
				sort: [],
				filter: []
			};
			ahFiltersSort = pref.sort;
			ahFiltersFilter = pref.filter;
			this.ahViewColumns = pref.columns;
		} else {
			if (UserPrefs.get('ahFiltersSort') && UserPrefs.get('ahFiltersSort') !== '[]') {
				ahFiltersSort = JSON.parse(UserPrefs.get('ahFiltersSort'));
			}
			if (UserPrefs.get('ahFiltersFilter')) {
				ahFiltersFilter = JSON.parse(UserPrefs.get('ahFiltersFilter'));
			}
		}

		//if no sorting set default grid sorting
		if (!ahFiltersSort) {
			ahFiltersSort = [{
					field: 'timestamp',
					dir: 'desc',
					compare: null
				}];
		}

		let url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/hifilters?includeSubaccounts=' + State.includeSubaccounts;

		if (this.preferences) {
			ahFiltersSort = this.preferences.sort || ahFiltersSort;
			ahFiltersFilter = this.preferences.filter || ahFiltersFilter;
		}

		var dir;
		//this will fix the column sorting on page reload
		for (var i = 0, length = ahFiltersSort.length; i < length; i++) {
			if (ahFiltersSort[i].field === 'severity') {
				dir = ahFiltersSort[i].dir;
				ahFiltersSort[i].compare = function (a, b) {
					return Utils.customCompare(a, b, 'severityIndex', '0', dir);
				};
			}
		}
		ahFiltersFilter = UserPrefs.restoreDateFilter(ahFiltersFilter);

		this.dataSource = new CeeViewDataSource({
			transport: {
				read: {
					url: url,
					contentType: "application/json; charset=utf-8",
					type: "GET",
					dataType: "json",
                    cache: false
				}
			},
			schema: {
				model: {
					id: 'id',
					fields: {
						id: {
							type: 'string'
						},
						identifierName: {
							type: 'string'
						},
						active: {
							type: 'boolean'
						}
					}
				},
				parse: function (result) {
					for (let i = 0; i < result.length; i++) {
						if (result[i].tags) {
							result[i].tags = result[i].tags.join(', ');
						}
					}
					return result;
				}
			},
			filter: ahFiltersFilter,
			sort: ahFiltersSort,
			change: $.proxy(function (e) {
				var sort;
				if (e.items.length === 0) {
					if ($('.cw_health_filters_list').find('.status').length) {
						$('.cw_health_filters_list').find('.status').remove();
					}
				} else {
					if ($('.cw_health_filters_list').find('.status').length) {
						$('.cw_health_filters_list').find('.status').remove();
					}
					$('.cw_health_filters_list').find('.k-grid-content').removeClass('hide');
				}
				/*var restoreCheckboxStates = function () {
				 Utils.setCheckboxStates(this.grid, this.checkboxStates);
				 };
				 setTimeout($.proxy(restoreCheckboxStates, this), 200);
				 */
			}, this)
		});


		var ahSC = JSON.parse(UserPrefs.get('ahViewColumns'));
		if (ahSC !== null) {
			if (typeof ahSC === "string" || (typeof ahSC === "object" && ahSC.length === 0)) {
				ahSC = null;
			}
		}

		var ahViewColumns = this.ahViewColumns || ahSC || this.defaultColumns;
		ahViewColumns = Utils.completeColumns(ahViewColumns, this.defaultColumns);

		kendo.ui.progress($('.cw_health_filters_list'), true);

		this.grid = $('.cw_health_filters_list').kendoCustomGrid({
			dataSource: this.dataSource,
			resizable: true,
			scrollable: true,
			reorderable: true,
			selectable: 'row',
			sortable: {
				mode: 'multiple',
				allowUnsort: true
			},
			filterable: {
				extra: false,
				operators: {
					string: {
						startswith: filterMessages.STARTS_WITH,
						neq: filterMessages.NEQ,
						eq: filterMessages.EQ,
						contains: filterMessages.CONTAINS
					},
					number: {
						gte: filterMessages.GTE,
						gt: filterMessages.GT,
						lte: filterMessages.LTE,
						lt: filterMessages.LT
					},
					date: {
						gte: filterMessages.IAE,
						gt: filterMessages.IA,
						lte: filterMessages.IBE,
						lt: filterMessages.IB
					}
				},
				messages: this.gridMessages
			},
			columns: Utils.rearrangeColumns([{
					field: 'id',
					title: lang.SELECTOR,
					attributes: {
						'class': 'text_center'
					},
					headerAttributes: {
						'class': 'text_center'
					},
					menu: false,
					sortable: false,
					filterable: false,
					template: '<input type="checkbox" class="cw_grid_check" data-id="${id}" />',
					headerTemplate: '<input type="checkbox" class="cw_grid_check_all" />',
					hidden: ahViewColumns.id.hidden,
					width: ahViewColumns.id.width
				}, {
					field: 'name',
					title: lang.NAME,
					sortable: true,
					attributes: {
						'class': "tooltip ellipsis to_expand cw_grid_link"
					},
					hidden: ahViewColumns.name.hidden,
					width: ahViewColumns.name.width
				}, {
					field: 'accountName',
					title: lang.ACCOUNT,
					sortable: true,
					filterable: {
						ui: $.proxy(function (element) {
							return Renderer.filterGridByOwnElementDS.call(this, element, 'accountName');
						}, this),
						extra: false,
						operators: {
							string: {
								neq: filterMessages.NEQ,
								eq: filterMessages.EQ
							}
						}
					},
					attributes: {
						'class': 'tooltip ellipsis to_expand'
					},
					hidden: !State.includeSubaccounts || ahViewColumns.accountName.hidden,
					width: ahViewColumns.accountName.width
				}, {
					field: 'active',
					title: lang.ACTIVE,
					sortable: true,
					template: '#= active ? "' + lang.YES + '" : "' + lang.NO + '"#',
					hidden: ahViewColumns.active.hidden,
					width: ahViewColumns.active.width,
					attributes: {
						'class': 'tooltip ellipsis to_expand'
					}
				}, {
					field: 'filtered',
					title: lang.FILTERED,
					template: '#= Renderer.browserDateRenderer(filtered, Constants.DATETIME)#',
					sortable: true,
					filterable: {
						ui: function (element) {
							element.kendoDateTimePicker({
								format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
								timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat)
							});
						}
					},
					hidden: ahViewColumns.filtered.hidden,
					width: ahViewColumns.filtered.width,
					attributes: {
						'class': 'tooltip ellipsis to_expand'
					}
			    }, {
					field: 'created',
					title: lang.CREATED,
					template: '#= Renderer.browserDateRenderer(created, Constants.DATETIME)#',
					sortable: true,
					filterable: {
						ui: function (element) {
							element.kendoDateTimePicker({
								format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
								timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat)
							});
						}
					},
					hidden: ahViewColumns.created.hidden,
					width: ahViewColumns.created.width,
					attributes: {
						'class': 'tooltip ellipsis to_expand'
					}
				}, {
					field: 'monitorType',
					title: lang.assethealth.MONITOR_TYPE,
					sortable: true,
					template: '#= monitorType ? monitorType : "' + lang.ALL + '"#',
					hidden: ahViewColumns.monitorType.hidden,
					width: ahViewColumns.monitorType.width,
					attributes: {
						'class': 'tooltip ellipsis to_expand'
					}
				}, {
					field: 'reasonSubsystem',
					title: lang.assethealth.SUBSYSTEM,
					sortable: true,
					template: '#= reasonSubsystem ? reasonSubsystem : "' + lang.ALL + '"#',
					hidden: ahViewColumns.reasonSubsystem.hidden,
					width: ahViewColumns.reasonSubsystem.width,
					attributes: {
						'class': 'tooltip ellipsis to_expand'
					}
				}, {
					field: 'identifierName',
					title: lang.assethealth.IDENTIFIER_NAME,
					sortable: true,
					hidden: ahViewColumns.identifierName.hidden,
					width: ahViewColumns.identifierName.width,
					attributes: {
						'class': 'tooltip ellipsis to_expand'
					}
				}, {
					field: 'identifierInstance',
					title: lang.assethealth.IDENTIFIER_INSTANCE,
					sortable: true,
					hidden: ahViewColumns.identifierInstance.hidden,
					width: ahViewColumns.identifierInstance.width,
					attributes: {
						'class': 'tooltip ellipsis to_expand'
					}
				}, {
					field: 'targetType',
					title: lang.ACCOUNT_SELECTION,
					sortable: true,
					template: "#= lang.targetType[targetType]#",
					hidden: ahViewColumns.targetType.hidden,
					width: ahViewColumns.targetType.width,
					attributes: {
						'class': 'tooltip ellipsis to_expand'
					}
				}, {
					field: 'reasonDescription',
					title: lang.assethealth.REASON_FILTER,
					sortable: true,
					attributes: {
						'class': 'tooltip ellipsis to_expand'
					},
					hidden: ahViewColumns.reasonDescription.hidden,
					width: ahViewColumns.reasonDescription.width,
				}, {
					field: 'tags',
					title: lang.TAGS,
					sortable: true,
					filterable: {
						ui: $.proxy(function (element) {
							let multiselect = new MultiSelectGridFilter({
								element: element,
								field: 'tags',
								grid: this.grid,
								itemTemplate: '#=data.text#',
								tagTemplate: '#=data.text#',
								dataSource: this.filterOptions.tags,
								static: true
							});
						}, this),
						messages: this.gridMessages,
						extra: false,
						operators: {
							string: {
								eq: filterMessages.EQ,
								neq: filterMessages.NEQ
							}
						}
					},
					hidden: ahViewColumns.tags.hidden,
					width: ahViewColumns.tags.width,
					attributes: {
						'class': 'tooltip ellipsis to_expand'
					}
			}], ahViewColumns),
			columnMenu: true,
			change: $.proxy(this.onRowExpand, this),
			dataBound: $.proxy(this.onDataBound, this)
		}).data('kendoCustomGrid')
		// Add Kendo tooltip to the header of the columns
		Utils.gridColumnHeaderTooltip(this.grid);
		this.adjustSectionHeight();
		this.grid.thead.find("[data-field='id']>.k-header-column-menu").remove();
		this.gridMenu = new GridMenu({
			renderTo: 'cw_ah_grid_menu',
			items: [{
					id: 'cw_filter_create',
					icon: 'plus-sign',
					text: lang.CREATE,
					fn: this.onFilterAdd,
					scope: this,
					disabled: false,
					role: State.mainApp.session.hasV2Permissions ? 'HEALTHFILTER_CREATE': undefined
				}, {
					id: 'cw_filter_delete',
					icon: 'bin',
					text: lang.DELETE,
					fn: this.onFilterRemove,
					scope: this,
					disabled: true,
					role:  State.mainApp.session.hasV2Permissions ? 'HEALTHFILTER_DELETE': undefined
				}, {
					id: 'cw_filter_activate',
					icon: 'ok-circle',
					text: lang.ACTIVE,
					fn: this.onFilterActDeact,
					scope: this,
					disabled: true,
					role:  State.mainApp.session.hasV2Permissions ? 'HEALTHFILTER_UPDATE': undefined
				}, {
					id: 'cw_filter_deactivate',
					icon: 'remove-circle',
					text: lang.INACTIVE,
					fn: this.onFilterActDeact,
					scope: this,
					disabled: true,
					role:  State.mainApp.session.hasV2Permissions ? 'HEALTHFILTER_UPDATE': undefined
				}]

		});
		var searchValue = UserPrefs.get('defaultFiltersFsView') ? '' : (UserPrefs.get('ahFiltersSearchPhrase') || '');
		this.gridFilterPanel = new PreferencesPanel({
			renderTo: 'cw_asset_filters_filters',
			grid: this.grid,
			modulePrefName: 'HIFiltersHealth',
			defaultPrefViewKey: 'defaultFiltersFsView',
			prefListKey: 'ahFiltersFSViews',
			userPref: State.currentApp.userPref,
			onRemove: $.proxy(State.currentApp.saveUserPreferences, State.currentApp),
			searchFields: ['name', 'identifierName', 'identifierInstance', 'accountName', /*'monitorName',*/ 'reasonSubsystem', 'targetType', 'monitorType', 'reasonDescription'],
			searchValue: searchValue,
			defaultColumns: this.defaultColumns
		});
		this.removeMask();
	},
	onEventsTimeout: function () {
		this.checkboxStates = Utils.getCheckboxStates(this.grid);
		this.grid.dataSource.read();
	},
	/**
	 * Removes listeners
	 */
	removeListeners: function () {
		$('.cw_health_filters_list').off();
		this.onFilterSavedHandler = $.proxy(this.onFilterSaved, this);
		LocalEventsManager.unbind('usersaved', this.onFilterSavedHandler);
	},
	/**
	 * Attaches listeners
	 */
	attachListeners: function () {
		$('.cw_health_filters_list').on('click', '.cw_grid_link', $.proxy(this.onNameClick, this));
		$('.cw_health_filters_list').on('click', '.cw_grid_check', $.proxy(this.onFilterCheck, this));
		$('.cw_health_filters_list').on('click', '.cw_grid_check_all', $.proxy(this.onFilterCheckAll, this));
		//$('.cw_health_filters_list').on('click', '.cw_assetconsole_details', $.proxy(this.onOpenAssetConsole, this));
		//$('.cw_health_filters_list').on('click', '.k-grid-content .cw_status_indicator', $.proxy(this.onAgentStateClick, this));
		//$('.cw_health_filters_list').on('click', '.cw_monitor_link', $.proxy(this.onMonitorClick, this));
		LocalEventsManager.bind('hifiltersaved', this.onFilterSavedHandler);
	},
	/**
	 * Handler for the click event on the monitor name
	 * @param {Object} e The click event
	 */
	onMonitorClick: function (e) {
		e.preventDefault();
		var target = $(e.currentTarget);
		var monitorId = target.data('id'), monitorType = target.data('type');
		this.openMonitorView(monitorId, monitorType, false, e);
	},
	/**
	 * Event handler for the click event on the add monitor button
	 */
	onFilterAdd: function (e) {
		State.mainApp.loadModule('AssetHealthFiltersConfiguration', '', {
			mode: 'create'
		}, e);
	},
	/**
	 * Event handler for the click event on the edit monitor button
	 */
	onFilterEdit: function (e) {
		var checkbox = $('.cw_grid_check:checked'), data = this.grid.dataSource.getByUid(checkbox.closest('tr').data('uid'));

		State.mainApp.loadModule('AssetHealthFiltersConfiguration', data.id, {
			id: data.id,
			mode: 'edit'
		}, e);
	},
	reloadByTags: function () {
		this.grid.dataSource.read();
	},
	/**
	 * Event handler for the click event on the remove monitor button
	 */
	onFilterRemove: function () {
		var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/hifilters/delete';
		var checkboxes = $('.cw_health_filters_list').find('.cw_grid_check');
		var i, length = checkboxes.length, haveFilters = false, checked = 0;
		var ids = [];
		for (i = 0; i < length; i++) {
			if ($(checkboxes[i]).is(':checked')) {
				var id = $(checkboxes[i]).attr('data-id');
				ids.push(id);
				haveFilters = true;
				checked++;
			}
		}
		if (haveFilters) {
			var oldThis = this;
			var dialog = new Dialog({
				title: lang.INFO,
				msg: checked > 1 ? lang.assethealth.messages.FILTERS_REMOVE_CONFIRMATION : lang.assethealth.messages.FILTER_REMOVE_CONFIRMATION,
				icon: 'ERROR',
				actionText: 'DELETE',
				fn: $.proxy(function (value, button) {
					if (button === 'ok') {
						this.gridMenu.disableItem('cw_snmp_delete');
						this.gridMenu.disableItem('cw_snmp_edit');
						Utils.ajax(url, 'POST', JSON.stringify(ids), $.proxy(function (result) {
							if (result.success) {
								oldThis.actionNotification.setOptions({
									message: lang.assethealth.messages.FILTER_SUCCESS_DELETED,
									status: 'success'
								}).show();
								// reload data source
								$('.cw_health_filters_list').data('kendoCustomGrid').dataSource.read();
							} else {
								Utils.showInfo(lang.ALERT, result.message, result.details);
							}
						}), this);
					}
				}, this)
			});
			dialog.show();
		}
	},
	onFilterActDeact: function (e) {
		var checkboxes = $('.cw_health_filters_list').find('.cw_grid_check:checked');
		if (checkboxes.length) {
			var i, length = checkboxes.length;
			var ids = [];
			for (i = 0; i < length; i++) {
				var id = $(checkboxes[i]).attr('data-id');
				ids.push(id);
			}
			var active = $(e.currentTarget).attr('id') === 'cw_filter_activate';
			var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/hifilters/active/' + active;
			Utils.ajax(url, 'POST', JSON.stringify(ids), $.proxy(function (result) {
				if (result.success) {
					this.grid.dataSource.read();
					this.actionNotification.setOptions({
						message: lang.assethealth.messages.FILTER_SUCCESS_SAVED
					}).show();
				} else {
					Utils.showInfo(lang.ALERT, result.message, result.details);
				}
			}, this));

		}
	},
	onFilterSaved: function () {
		this.actionNotification.setOptions({
			message: lang.assethealth.messages.FILTER_SUCCESS_SAVED
		}).show();
		//this.dataSource.read();
	},
	/**
	 * Handler function for the change(select) event on the grid
	 * @param {Object} e The change event object
	 */
	onRowExpand: function (e) {
		var selectedRow = this.grid.select();
		var myRow = selectedRow[0];
		var messageEl = $(myRow).find('.to_expand');
		if ($(messageEl).hasClass('cw_message_expanded')) {
			$(messageEl).removeClass('cw_message_expanded').addClass('ellipsis');
		} else {
			$('.cw_health_filters_list').find('.k-grid-content').find('td.cw_message_expanded').removeClass('cw_message_expanded').addClass('ellipsis');
			$(messageEl).addClass('cw_message_expanded').removeClass('ellipsis');
		}
	},
	/**
	 * Handler function for the Filters grid data bound event
	 * @param {Object} e The databound event
	 */
	onDataBound: function () {
		var tooltip = $('.cw_health_filters_list').find('.k-grid-content').data('kendoTooltip');
		if (tooltip) {
			tooltip.destroy();
		}
		$('.cw_health_filters_list').find('.k-grid-content').kendoTooltip({
			filter: "td.tooltip:not(:empty)",
			content: function (event) {
				return event.target.text().split("\n").join("<br />");
			}
		});
		var data = this.grid.dataSource.data();
		if (data.length) {
			this.highlightItem(this.grid);
		}
		kendo.ui.progress($('.cw_health_filters_list'), false);
	},
	/**
	 * Handler for the asset checkbox click
	 * @param {Object} e The click event
	 */
	onFilterCheck: function (e) {
		var checkboxes = $('.cw_grid_check:checked');
		if (checkboxes.length) {
			this.gridMenu.enableItem('cw_filter_delete');
			this.gridMenu.enableItem('cw_filter_activate');
			this.gridMenu.enableItem('cw_filter_deactivate');
		} else {
			this.gridMenu.disableItem('cw_filter_delete');
			this.gridMenu.disableItem('cw_filter_activate');
			this.gridMenu.disableItem('cw_filter_deactivate');
		}
		if (checkboxes.length === 1) {
			this.gridMenu.enableItem('cw_filter_update');
		} else {
			this.gridMenu.disableItem('cw_filter_update');
		}
	},
	/**
	 * Handler for the check all checkbox click
	 * @param {Object} e The click event
	 */
	onFilterCheckAll: function (e) {
		var checkAll = $('.cw_grid_check_all').is(':checked');
		var checkboxes = $('.cw_grid_check');
		var i, length = checkboxes.length;
		for (i = 0; i < length; i++) {
			$(checkboxes[i]).prop('checked', checkAll);
		}
		this.onFilterCheck();
	},
	/**
	 * Handler for the asset checkbox click
	 * @param {Object} e The click event
	 */
	onNameClick: function (e) {
		var filterId = this.grid.dataItem($(e.currentTarget).closest('tr')).id;
		this.setHighlightItem(e);
		State.mainApp.loadModule('AssetHealthFiltersConfiguration', filterId, {
			id: filterId,
			mode: 'edit'
		}, e);
	},
	clearCheckboxes: function () {
		var checkAll = $('.cw_health_filters_list').find('.cw_grid_check_all'),
				checkboxes = $('.cw_health_filters_list').find('.cw_grid_check'),
				i, length = checkboxes.length;
		checkAll.prop('checked', false);
		for (i = 0; i < length; i++) {
			$(checkboxes[i]).prop('checked', false);
		}
	},
	/**
	 * Method by Andy
	 *
	 */
	adjustSectionHeight: function () {
		var section = $('.cw_assethealth_module').find('.cw_section_full');
		var sectionHeight = section.height();
		section.find('.cw_section_content').css('height', sectionHeight);
		section.find('.k-grid-content').css('height', sectionHeight - 40);
	},
	/**
	 * Saves user preferences
	 */
	saveUserPreferences: function () {
		this.saveUserPrefs({
			category: 'HIFiltersHealth',
			filterPanel: this.gridFilterPanel,
			grid: this.grid,
			eventsToolbar: this.eventsToolbar,
			keys: {
				searchPhrase: 'ahFiltersSearchPhrase',
				columns: 'ahViewColumns',
				sort: 'ahFiltersSort',
				filter: 'ahFiltersFilter',
				FsViews: 'ahFiltersFSViews',
				defaultFsView: 'defaultFiltersFsView'
			}
		});
	},
	/**
	 * Loads user preferences
	 */
	loadUserPreferences: function () {
		this.userPref = [];
		UserPrefs.load('HIFiltersHealth', $.proxy(function (result) {
			if (result.success) {
				this.userPref = result.data;
				this.initComponent();
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},
	/**
	 * Called when application is unloaded/destroyed
	 */
	destroy: function () {
		if (this.eventsToolbar) {
			this.eventsToolbar.destroy();
		}
		this.saveUserPreferences();
	}
});
