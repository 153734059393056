import {Application, Cookies, RemoteEventsManager, LocalEventsManager, ErrorHandler} from 'core';
import {State, UserPrefs, Utils} from 'tools';
import {
	CustomNotification,
	GridMenu,
	MultiToggle,
	EventsToolbar,
	PreferencesPanel,
	Dialog,
	ModalWindow, ModalView
} from 'controls';
import Settings from 'settings';
import {AccountForm} from "areas/administration/accountForm";
import {CeeViewDataSource} from "tools";
import MultiSelectGridFilter from 'controls/multiSelectGridFilter';
import {openTagsFormWindow} from "controls/tagsForm";

import Api from 'tools/api';
window.Api = Api;

export let AccountsView = function (config) {
	Utils.apply(this, config);
	this.module = {
		name: 'AdministrationAccounts',
		initialConfig: config
	};
	this.hasEvents = true;
	this.subscriberId = Utils.guid();
	this.initComponent();
};

export default AccountsView;

jQuery.extend(AccountsView.prototype, Application.prototype, {
	/**
	 * Accounts view application related initializations
	 */
	initComponent: function () {
		Application.prototype.initComponent.call(this);

		this.indicatorSelector = '.account_view .cw_new_tag_indicator';
		this.getTags();

		this.viewType = 'grid';
		this.gridLoaded = false;
		this.cardLoaded = false;

		this.actionNotification = new CustomNotification({
			appendToElement: '.window_area',
			status: 'success',
			style: 'top:15px; right:15px; left:15px;',
			type: 'icon',
			icon: 'glyphicons  ok-circle',
			hideOnClick: true
		});

		this.defaultColumns = {
			id: {
				hidden: false,
				width: 40
			},
			name: {
				hidden: false,
				width: 180
			},
			teamName: {
				hidden: false,
				width: 150
			},
			tags: {
				hidden: false,
				width: 200
			},
			comment: {
				hidden: false
			}
		};
		this.loadUserPreferences();

		// update content button
		State.mainApp.contextMenu.setActionButton('ACCOUNT');
		this.viewToggle = new MultiToggle({
			id: 'cw_accounts_view_toggle',
			cssClass: 'cw_multi_toggle right',
			value: 'false',
			items: [{
				id: 'cw_grid_view',
				title: lang.GRID_VIEW,
				selected: false,
				cssClass: 'glyphicons justify',
				fn: this.onViewToggle,
				scope: this,
				value: 'true'
			}, {
				id: 'cw_card_view',
				title: lang.CARD_VIEW,
				selected: true,
				cssClass: 'glyphicons show-big-thumbnails',
				fn: this.onViewToggle,
				scope: this,
				value: 'false'
			}]
		});
		this.gridMenu = new GridMenu({
			renderTo: 'cw_accounts_grid_menu',
			items: [{
				id: 'cw_create',
				icon: 'plus-sign',
				text: lang.CREATE,
				fn: State.mainApp.onNewAccount,
				scope: State.mainApp,
				role: 'ACCOUNT_CREATE'
			}, {
				id: 'cw_delete',
				icon: 'bin',
				text: lang.DELETE,
				fn: this.onAccountDelete,
				scope: this,
				disabled: true,
				role: 'ACCOUNT_DELETE'
			}, {
				id: 'cw_set_tags',
				icon: 'tag',
				text: lang.SET_TAGS,
				fn: $.proxy(function () {
					return openTagsFormWindow('account', this);
				}, this),
				scope: this,
				disabled: true
			}]
		});
		this.removeMask();
		this.removeListeners();
		this.attachListeners();
	},
	getTags: async function () {
		let tags = await Utils.getAccountTags();
		this.filterOptions = {
			tags: tags
		};
	},
	/**
	 * Handler for the view toggle buttons click
	 * @param {Object} e The click event
	 */
	onViewToggle: function (value) {
		if (value === 'true') {
			this.viewType = 'grid';
			$('#cw_account_accounts').addClass('hide');
			$('#cw_accounts_grid').removeClass('hide');

			if (this.grid) {
				$('.list_content').addClass('list_content_full');
			} else {
				$('.list_content').removeClass('list_content_full');
				this.createDataSource();
				this.gridLoaded = false;
				this.initGridView();
			}
		} else {
			this.viewType = 'card';
			$('#cw_accounts_grid').addClass('hide');
			if (this.innerView) {
				$('#cw_account_accounts').removeClass('hide');
			} else {
				$('#cw_accounts_grid').addClass('hide');
				this.createDataSource();
				this.cardLoaded = false;
				this.initCardView();
			}
		}
	},
	/**
	 * Removes the listeners for the form's buttons
	 */
	removeListeners: function () {
		$('#accounts_grid').off();
		this.onAccountSavedHandler = $.proxy(this.onAccountSaved, this);
		LocalEventsManager.unbind('accountsaved', this.onAccountSavedHandler);
	},
	/**
	 * Attaches the listeners for the form's buttons
	 */
	attachListeners: function () {
		$('#accounts_grid').on('click', '.cw_grid_check', $.proxy(this.onCheck, this));
		$('#accounts_grid').on('click', '.cw_grid_check_all', $.proxy(this.onCheckAll, this));
		$('#accounts_grid').on('click', '.cw_grid_link', $.proxy(this.onAccountNameClick, this));
		LocalEventsManager.bind('accountsaved', this.onAccountSavedHandler);
	},
	/**
	 * Init card component
	 */
	initCardView: function () {
		var deleteClass = State.mainApp.session.hasRole('ACCOUNT_DELETE') ? '' : ' hide';
		var updateClass = State.mainApp.session.hasRole('ACCOUNT_UPDATE') ? '' : ' hide';

		var template = '<li><div class="cw_card" id="${id}">';
		template += '<div class="cw_card_avatar">';
		if (State.mainApp.session.hasRole('ACCOUNT_READ')) {
			template += '# if (Utils.isGuid(logoId)) {#';
			template += '<div class="cw_avatar cw_avatar_account no_bg">';
			template += '<img src="${Api.images.urls.image(logoId, id)}" />';
			template += '</div>';
			template += '#} else if(logoId) { #';
			template += '<div class="cw_avatar cw_avatar_account no_bg"><span class="glyphicons ${logoId}"></span></div>';
			template += '#} else {#';
			template += '<div class="cw_avatar cw_avatar_account"></div>';
			template += '#}#';
		} else {
			template += '<div class="cw_avatar cw_avatar_account"></div>';
		}
		template += '</div>';
		template += '<div class="cw_card_content"><h2 class="card_title"><strong>' + lang.NAME + '</strong>: ${name} </h2>';
		if (State.mainApp.session.hasRole('ACCOUNT_READ')) {
			template += '<p class="card_extra"><strong>' + lang.DESCRIPTION + ':</strong> #if (comment){#${comment}#}#</p>';
		}
		template += '<p class="card_extra"><strong>' + lang.TEAM + ':</strong> #if (teamName){#${teamName}#}# </p>';
		template += `</div><div class="cw_card_options"><span class="glyphicons bin `;
		template += deleteClass;
		template += '"></span><span class="glyphicons pencil ';
		template += updateClass;
		template += '"></span></div></div></li>';

		$('#cw_accounts_view_toggle').find('li').last().addClass('is_selected');

		var oThis = this;
		this.innerView = new ModalView({
			id: 'cw_account_accounts',
			cssClass: 'account_ul',
			title: lang.ACCOUNTS,
			autoLoad: true,
			createButtonText: lang.account.ACCOUNT_NEW,
			viewDataSource: this.dataSource,
			dataBound: $.proxy(this.cardViewDataBound, this),
			template: template,
			pager: true,
			onCreateButton: function (e) {
				if (State.mainApp.session.hasRole('ACCOUNT_CREATE')) {
					var modalWindow = new ModalWindow({
						id: 'account_modal',
						title: lang.account.ACCOUNT_CREATE,
						width: 400,
						url: 'include/Administration/AccountForm.jsp',
						height: 720,
						minHeight: 720,
						refresh: function () {
							new AccountForm({
								id: null,
								mode: 'create'
							});
						}
					});
					modalWindow.open();
				}
			},
			onEditButton: function (e) {
				if (State.mainApp.session.hasRole('ACCOUNT_UPDATE') || State.mainApp.session.hasRole('ACCOUNT_READ')) {
					var accountId = e.currentTarget.parentNode.parentNode.id;
					State.mainApp.loadModule('AccountDetails', accountId, {
						id: accountId,
						mode: 'update',
						redirectBack: true
					}, e);
				}
			},
			onDeleteButton: function (e) {
				if (State.mainApp.session.hasRole('ACCOUNT_DELETE')) {
					var id = e.currentTarget.parentNode.parentNode.id;
					var url = Settings.serverPath + 'accounts/' + id;
					var dialog = new Dialog({
						title: lang.INFO,
						msg: lang.account.messages.ACCOUNT_REMOVE_CONFIRMATION,
						icon: 'ERROR',
						actionText: 'DELETE',
						fn: $.proxy(function (value, button) {
							if (button === 'ok') {
								Utils.ajax(url, 'DELETE', {}, $.proxy(function (result) {
									if (result.success) {
										oThis.actionNotification.setOptions({
											message: lang.account.messages.ACCOUNT_SUCCESS_DELETED,
											status: 'success'
										}).show();

										// reload data source
										this.viewDataSource.read();
									} else {
										Utils.showInfo(lang.ALERT, result.message, result.details);
									}
								}, this));
							}
						}, this)
					});
					dialog.show();
				}
			}
		});
		setTimeout(function () {
			$('#cw_account_accounts').removeClass('hide');
		}, 300);
	},
	/**
	 * Init grid component
	 */
	initGridView: function () {
		var filterMessages = lang.grid.filter;
		var columnChanges = [{
			oldName: 'team',
			newName: 'teamName'
		}];
		var currentFilters = this.accountsFilter?.filters;
		if (currentFilters) {
			Utils.replaceObsoleteFilters(currentFilters, columnChanges);
		}
		Utils.replaceObsoleteSort(this.accountsSort, columnChanges);

		this.gridMessages = {
			clear: lang.CLEAR,
			info: lang.grid.filter.SHOW_ITEMS,
			filter: lang.FILTER
		};
		var accountsColumns = this.accountsColumns || JSON.parse(UserPrefs.get('accountsColumns')) || this.defaultColumns;
		accountsColumns = Utils.completeColumns(accountsColumns, this.defaultColumns);
		Utils.replaceObsoleteColumns(accountsColumns, columnChanges);

		this.grid = $('#accounts_grid').kendoCustomGrid({
			dataSource: this.dataSource,
			resizable: true,
			reorderable: true,
			selectable: 'row',
			autoBind: !this.gridLoaded,
			sortable: {
				mode: 'multiple',
				allowUnsort: true
			},
			filterable: {
				extra: false,
				operators: {
					string: {
						startswith: filterMessages.STARTS_WITH,
						neq: filterMessages.NEQ,
						eq: filterMessages.EQ
					}
				},
				messages: this.gridMessages
			},
			columns: Utils.rearrangeColumns([{
				field: 'id',
				title: lang.SELECTOR,
				sortable: false,
				filterable: false,
				menu: false,
				template: '<input type="checkbox" class="cw_grid_check" data-uid="#= uid#" data-id="#= id #" />',
				headerTemplate: '<input type="checkbox" class="cw_grid_check_all" />',
				attributes: {
					'class': 'text_center'
				},
				headerAttributes: {
					'class': 'text_center'
				},
				hidden: accountsColumns.id.hidden,
				width: accountsColumns.id.width
			}, {
				field: 'name',
				title: lang.NAME,
				sortable: true,
				filterable: true,
				template: '<a class="cw_grid_link ellipsis to_expand" data-id="#= id #">#= name #</a>',
				hidden: accountsColumns.name.hidden,
				width: accountsColumns.name.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'teamName',
				title: lang.TEAM,
				sortable: true,
				filterable: true,
				template: '#= teamName || ""#',
				hidden: accountsColumns.teamName.hidden,
				width: accountsColumns.teamName.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'tags',
				title: lang.TAGS,
				sortable: true,
				filterable: {
					ui: $.proxy(function (element) {
						let multiselect = new MultiSelectGridFilter({
							element: element,
							field: 'tags',
							grid: this.grid,
							itemTemplate: '#=data.text#',
							tagTemplate: '#=data.text#',
							dataSource: this.filterOptions.tags,
							static: true
						});
					}, this),
					messages: this.gridMessages,
					extra: false,
					operators: {
						string: {
							eq: filterMessages.EQ,
							neq: filterMessages.NEQ
						}
					}
				},
				hidden: accountsColumns.tags.hidden,
				width: accountsColumns.tags.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'comment',
				title: lang.DESCRIPTION,
				sortable: false,
				filterable: true,
				template: '#= comment || " " #',
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				},
				hidden: accountsColumns.comment.hidden,
				width: accountsColumns.comment.width
			}], accountsColumns),
			columnMenu: true,
			change: $.proxy(this.onRowExpand, this),
			dataBound: $.proxy(this.onGridDataBound, this)
		}).data('kendoCustomGrid')
		// Add Kendo tooltip to the header of the columns
		Utils.gridColumnHeaderTooltip(this.grid);
		this.adjustSectionHeight();
		this.grid.thead.find("[data-field='id']>.k-header-column-menu").remove();
		var searchValue = UserPrefs.get('defaultFsView') ? '' : (UserPrefs.get('accountsSearchPhrase') || '');
		this.gridFilterPanel = new PreferencesPanel({
			renderTo: 'cw_accounts_filters',
			grid: this.grid,
			modulePrefName: 'AdministrationAccounts',
			defaultPrefViewKey: 'defaultFsView',
			prefListKey: 'accountsFSViews',
			userPref: this.userPref,
			onRemove: $.proxy(this.saveUserPreferences, this),
			searchFields: ['name', 'teamName', 'comment'],
			searchValue: searchValue,
			defaultColumns: this.defaultColumns,
		});
	},
	/*
	 * Handler function for card view databound event
	 * @param {Object} e The databound event
	 */
	cardViewDataBound: function () {
		this.cardLoaded = true;
		let data = this.dataSource.data();
		data.forEach((item) => {
			if (item.id === Cookies.CeesoftCurrentAccountId) {
				$(`#${item.id}`).find('.cw_card_options .glyphicons.bin').remove();
			}
		});
	},
	/**
	 * Handler for the grid Data Bound event
	 * @param {Object} e The dataBoundEvent
	 */
	onGridDataBound: function (e) {
		$('#accounts_grid').find('.k-grid-content').kendoTooltip({
			filter: "td.tooltip:not(:empty)",
			content: function (event) {
				return event.target.text().split("\n").join("<br />");
			}
		});
		this.gridLoaded = true;
	},
	/**
	 * Handler for the service checkbox click
	 * @param {Object} e The click event
	 */
	onCheck: function (e) {
		e.stopPropagation();
		var checkboxes = $('.cw_grid_check');
		var accounts = [], id, checkbox, tagAccounts = [];
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			checkbox = $(checkboxes[i]);
			if (checkbox.is(':checked')) {
				if (checkbox.attr('data-id') !== Cookies.CeesoftCurrentAccountId) {
					accounts.push($(checkboxes[i]).data('id'));
				}
				tagAccounts.push($(checkboxes[i]).data('id'));
			}
		}
		if (accounts.length || tagAccounts.length) {
			this.gridMenu.enableItem('cw_delete');
			this.gridMenu.enableItem('cw_set_tags');
			if (accounts.length === 1) {
				this.gridMenu.enableItem('cw_update');
			} else {
				this.gridMenu.disableItem('cw_update');
			}
		} else {
			this.gridMenu.disableItem('cw_delete');
			this.gridMenu.disableItem('cw_update');
			this.gridMenu.disableItem('cw_set_tags');
		}
	},
	/**
	 * Handler for the check all checkbox click
	 * @param {Object} e The click event
	 */
	onCheckAll: function (e) {
		var checkAll = $('.cw_grid_check_all').is(':checked');
		var checkboxes = $('.cw_grid_check');
		var i, length = checkboxes.length;
		for (i = 0; i < length; i++) {
			$(checkboxes[i]).prop('checked', checkAll);
		}
		if (checkAll && checkboxes.length) {
			this.gridMenu.enableItem('cw_set_tags');
			if (checkboxes.length === 1) {
				this.gridMenu.enableItem('cw_update');
			} else {
				this.gridMenu.disableItem('cw_update');
			}
			this.gridMenu.enableItem('cw_delete');
		} else {
			this.gridMenu.disableItem('cw_delete');
			this.gridMenu.disableItem('cw_update');
			this.gridMenu.disableItem('cw_set_tags');
		}
	},
	/**
	 * Handler function for the change(select) event on the grid
	 */
	onRowExpand: function () {
		var selectedRow = this.grid.select();
		var myRow = selectedRow[0];
		var messageEl = $(myRow).find('.to_expand');
		if ($(messageEl).hasClass('cw_message_expanded')) {
			$(messageEl).removeClass('cw_message_expanded').addClass('ellipsis');
		} else {
			$('#accounts_grid').find('.k-grid-content').find('td.cw_message_expanded').removeClass('cw_message_expanded').addClass('ellipsis');
			$(messageEl).addClass('cw_message_expanded').removeClass('ellipsis');
		}
	},
	/**
	 * Handler for the delete button click
	 * @param {Object} e The click event
	 */
	onAccountDelete: function (e) {
		if (!$(e.currentTarget).hasClass('disabled')) {
			var url = Settings.serverPath + 'accounts/delete';
			var checkboxes = $('.cw_grid_check');
			var i, length = checkboxes.length, haveAccounts = false, checked = 0, id;
			var ids = [];
			for (i = 0; i < length; i++) {
				if ($(checkboxes[i]).is(':checked')) {
					id = $(checkboxes[i]).attr('data-id');
					if (id !== Cookies.CeesoftCurrentAccountId) {
						ids.push(id);
						haveAccounts = true;
						checked++;
					}
				}
			}
			if (haveAccounts) {
				var dialog = new Dialog({
					title: lang.INFO,
					msg: checked > 1 ? lang.account.messages.ACCOUNTS_REMOVE_CONFIRMATION : lang.account.messages.ACCOUNT_REMOVE_CONFIRMATION,
					icon: 'ERROR',
					actionText: 'DELETE',
					fn: $.proxy(function (value, button) {
						if (button === 'ok') {
							this.gridMenu.disableItem('cw_delete');
							this.gridMenu.disableItem('cw_update');
							Utils.ajax(url, 'POST', JSON.stringify(ids), $.proxy(function (result) {
								if (result.success) {
									this.actionNotification.setOptions({
										message: lang.account.messages.ACCOUNT_SUCCESS_DELETED,
										status: 'success'
									}).show();

									$('.cw_grid_check_all').prop('checked', false);
									// reload data source
									this.dataSource.read();
								} else {
									Utils.showInfo(lang.ALERT, result.message, result.details, this.grid);
								}
							}, this));
						}
					}, this),
					deleteFromGrid: this.grid
				});
				dialog.show();
			} else {
				Utils.showInfo(lang.ALERT, lang.account.ACCOUNT_DELETE_CURRENT);
			}
		}
	},
	/**
	 * Handler function for the click event on the account name
	 * @param {Object} e The click event object
	 */
	onAccountNameClick: function (e) {
		var accountId = $(e.currentTarget).data('id');
		var accountName = $(e.currentTarget).text();
		var mode = 'view';
		if (State.mainApp.session.hasRole('ACCOUNT_UPDATE')) {
			mode = 'update';
		}

		State.mainApp.loadModule('AccountDetails', accountId, {
			id: accountId,
			name: accountName,
			mode: mode,
			redirectBack: true
		}, e);
		e.stopPropagation();
	},
	/**
	 * Saves user preferences
	 */
	saveUserPreferences: function () {
		var preferences = [{
			key: 'viewType',
			value: this.viewType
		}, {
			key: 'eventsStatus',
			value: JSON.stringify({
				playing: this.eventsToolbar.playing,
				startFrom: this.eventsToolbar.startFrom
			})
		}];
		if (this.viewType === 'grid') {
			preferences.push({
				key: 'accountsSearchPhrase',
				value: this.gridFilterPanel.getSearchValue()
			});
			preferences.push({
				key: 'accountsColumns',
				value: JSON.stringify(Utils.getGridColumns(this.grid))
			});
			preferences.push({
				key: 'accountsSort',
				value: JSON.stringify(this.dataSource.sort() || [])
			});
			preferences.push({
				key: 'accountsFilter',
				value: JSON.stringify(this.dataSource.filter() || [])
			});
			preferences.push({
				key: 'accountsFSViews',
				value: JSON.stringify(this.gridFilterPanel.fsList)
			});
			preferences.push({
				key: 'defaultFsView',
				value: this.gridFilterPanel.defaultFsViewId || ''
			});
			// add FS views
			for (var i = 0, length = this.userPref.length; i < length; i++) {
				// if is FS view
				if (Utils.isGuid(this.userPref[i].key)) {
					preferences.push(this.userPref[i]);
				}
			}
		}
		UserPrefs.save('AdministrationAccounts', preferences);
	},
	createDataSource: function () {
		var viewUrl = this.viewType === 'grid' ? '' : 'administration/';

		this.dataSource = new CeeViewDataSource({
			transport: {
				read: {
					url: function () {
						return Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/' + viewUrl + 'subaccounts/firstLevel';
					},
					contentType: "application/json; charset=utf-8",
					type: "GET",
					dataType: "json",
					cache: false
				}
			},
			schema: {
				parse: function (result) {
					for (let i = 0; i < result.length; i++) {
						if (result[i].tags) {
							result[i].tags = result[i].tags.join(', ');
						}
					}
					return result;
				}
			},
			sort: this.accountsSort || [{
				field: 'name',
				dir: 'asc',
				compare: null
			}],
			filter: this.accountsFilter || [],
			error: ErrorHandler.kendoServerError
		});
	},
	/**
	 * Loads user preferences
	 */
	loadUserPreferences: function () {
		var defaultFsView, viewType, pref;
		UserPrefs.load('AdministrationAccounts', $.proxy(function (result) {
			if (result.success) {
				this.userPref = result.data;
				this.viewType = UserPrefs.get('viewType') || 'grid';
				defaultFsView = UserPrefs.get('defaultFsView');

				if (Utils.isGuid(defaultFsView)) {
					pref = JSON.parse(UserPrefs.get(defaultFsView));
					pref = pref || {
						sort: [],
						filter: []
					};
					this.accountsSort = pref.sort;
					this.accountsFilter = pref.filter;
					this.accountsColumns = pref.columns;
				} else {
					if (UserPrefs.get('accountsSort')) {
						this.accountsSort = JSON.parse(UserPrefs.get('accountsSort'));
					} else {
						this.accountsSort = [{
							field: 'name',
							dir: 'asc',
							compare: null
						}];
					}
					if (UserPrefs.get('accountsFilter')) {
						this.accountsFilter = JSON.parse(UserPrefs.get('accountsFilter'));
					} else {
						this.accountsFilter = [];
					}
				}

				this.viewToggle.select(this.viewType === 'grid' ? 'cw_grid_view' : 'cw_card_view');

				this.createDataSource();
				var eventStatus = JSON.parse(UserPrefs.get('eventsStatus')) || {
					startFrom: 0,
					playing: true
				};
				this.eventsToolbar = new EventsToolbar({
					renderTo: $('#cw_account_events_toolbar'),
					callBackFn: $.proxy(this.onEventsTimeout, this),
					subscriberId: this.subscriberId,
					startFrom: eventStatus.startFrom,
					playing: eventStatus.playing
				});
				if (this.viewType === 'grid') {
					$('#cw_filter_reset').removeClass('hide');
					$('#cw_account_accounts').addClass('hide');
					$('#cw_accounts_grid').removeClass('hide');
					this.initGridView();
				} else {
					this.initCardView();
				}
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},
	/**
	 * Resets the datasource filters
	 */
	resetFilters: function () {
		if (this.dataSource.data().length) {
			this.dataSource.filter([]);
			this.dataSource.sort([]);
		}
	},
	/**
	 * Handler function for the account saved event
	 */
	onAccountSaved: function () {
		this.dataSource.read();
	},
	/**
	 * Method by Andy
	 */
	adjustSectionHeight: function () {
		var section = $('.cw_section_full');
		var sectionHeight = section.height();
		section.find('.cw_section_content').css('height', sectionHeight);
		section.find('.k-grid-content').css('height', sectionHeight - 40);
	},
	/*
	 * Handler function for reloading sections which are using tags
	 * */
	reloadByTags: function () {
		this.grid.dataSource.read();
	},
	onEventsTimeout: function (events) {
		this.dataSource.read();
	},
	/**
	 * Subscribes to the events
	 */
	subscribe: function () {
		this.isDataSourceSubscribed = true;
		var subscriptionObj = [{
			eventType: 'Administration',
			actionTypes: ['ACCOUNT_CREATE'],
			accountId: Cookies.CeesoftCurrentAccountId,
			includeSubaccounts: false
		}, {
			eventType: 'Administration',
			actionTypes: ['ACCOUNT_UPDATE', 'ACCOUNT_DELETE'],
			accountId: Cookies.CeesoftCurrentAccountId,
			includeSubaccounts: true
		}];
		RemoteEventsManager.subscribe(this.subscriberId, subscriptionObj);
	},
	/**
	 * Destroy
	 */
	destroy: function () {
		if (this.eventsToolbar) {
			this.eventsToolbar.destroy();
		}
		this.saveUserPreferences();
		Application.prototype.destroy.call(this);
	}
});
