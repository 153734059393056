import React from 'react';
import PropTypes from 'prop-types';
import translator from 'core/localization/localization';
import DropDownList from 'controls/react/kendoWrappers/dropDownList';
import {Draggable} from 'react-beautiful-dnd'
import classnames from 'classnames';
import {b} from './filteringPanelShared';
import {FILTERING_TYPES} from "areas/services/widgets/filterPanel/constants";
import ServiceState from "areas/services/widgets/filterPanel/serviceState";
import {ACTIONS} from "areas/services/widgets/filterPanel/reducer";
import StateDuration from "areas/services/widgets/filterPanel/stateDuration";
import IncidentNumber from "areas/services/widgets/filterPanel/incidentNumber";
import TagInput from "areas/services/widgets/filterPanel/tagInput";
import Monitor from "areas/services/widgets/filterPanel/monitor";
import Asset from "areas/services/widgets/filterPanel/asset";
import AssetGroup from "areas/services/widgets/filterPanel/assetGroup";

const getItemStyle = (isDragging, draggableStyle) => ({
	userSelect: "none",
	background: isDragging ? "white" : "white",
	opacity: isDragging ? 0.5: 1,
	...draggableStyle
});

const i = translator({
	'Type': {
		'no': 'Type'
	},
	SERVICE_STATE: {
		'en': 'Service state',
		'no': 'Tjenestetilstand'
	},
	STATE_DURATION: {
		'en': 'State duration',
		'no': 'Tilstandsvarighet'
	},
	INCIDENT_NUMBER: {
		'en': 'Incidents number',
		'no': 'Saksnummer'
	}
});

const TYPES = [
	{
		text: i(FILTERING_TYPES.SERVICE_STATE),
		value: FILTERING_TYPES.SERVICE_STATE
	},
	{
		text: i(FILTERING_TYPES.STATE_DURATION),
		value: FILTERING_TYPES.STATE_DURATION
	},
	{
		text: i(FILTERING_TYPES.INCIDENT_NUMBER),
		value: FILTERING_TYPES.INCIDENT_NUMBER
	},
	{
		text: i(FILTERING_TYPES.TAGS),
		value: FILTERING_TYPES.TAGS
	}
];

const REASONS_FILTER_TYPES = [{
	text: i(FILTERING_TYPES.ASSETS),
	value: FILTERING_TYPES.ASSETS,

}, {
	text: i(FILTERING_TYPES.ASSET_GROUPS),
	value: FILTERING_TYPES.ASSET_GROUPS,

}, {
	text: i(FILTERING_TYPES.MONITORS),
	value: FILTERING_TYPES.MONITORS,

}];

function getTypes(props, currentType, existingItems) {
	let types;
	switch (props.filterType) {
		case 'gridFilterTypes':
			types = REASONS_FILTER_TYPES;
			break;
		case 'selectiveServicesFilterTypes':
			types = TYPES;
			break;
	}
	return types.filter((type) => {
		for (const existingItem of existingItems) {
			if (type.value === existingItem.type) {
				return type.value === currentType;
			}
		}

		return true;
	});
}

function FilteringItem(props) {
	const {item} = props;

	const types = React.useMemo(() => {
		if (props.items) {
			return getTypes(props, item.type, Object.values(props.items));
		}
		else {
			return [];
		}

	}, [props.items, item]);

	React.useEffect(() => {
		if (item.type === '') {
			onTypeChange(types[0].value)
		}
	}, [item]);

	const onRemove = React.useCallback(() => {
		props.dispatch({ type: ACTIONS.REMOVE_ITEM, index: props.index });
	}, [props.index]);

	const onTypeChange = React.useCallback((value) => {
		props.dispatch({ type: ACTIONS.TYPE_CHANGE, index: props.index, value });
	}, [props.index]);

	const isRemovable = React.useMemo(() => {
		return props.items.length > 1 || (props.items.length > 1 && props.filterType === 'gridFilterTypes');
	}, [props.items]);

	return <div className={b('item')}>
		{/*<div className={b('label')}>{i('Type')}</div>*/}
		<div className={b('input')}>
			<DropDownList
				value={item.type}
				onChange={onTypeChange}
				dataSource={types}/>
		</div>
		{item.type === FILTERING_TYPES.SERVICE_STATE
			&& <ServiceState index={props.index} operator={item.operator} states={item.states} dispatch={props.dispatch} />}
		{item.type === FILTERING_TYPES.STATE_DURATION
			&& <StateDuration index={props.index} duration={item.duration} unit={item.unit} operator={item.operator} dispatch={props.dispatch} /> }
		{item.type === FILTERING_TYPES.INCIDENT_NUMBER
			&& <IncidentNumber index={props.index} operator={item.operator} incidents={item.incidents} dispatch={props.dispatch}/> }
		{(item.type === FILTERING_TYPES.TAGS && props.filterType !== 'gridFilterTypes') && <TagInput index={props.index} tags={item.tags} dispatch={props.dispatch} />}

		{item.type === FILTERING_TYPES.MONITORS && <Monitor index={props.index} monitors={item.monitors} dispatch={props.dispatch} />}
		{item.type === FILTERING_TYPES.ASSETS && <Asset index={props.index} assets={item.assets} dispatch={props.dispatch} />}
		{item.type === FILTERING_TYPES.ASSET_GROUPS && <AssetGroup index={props.index} assetGroups={item.assetGroups} dispatch={props.dispatch} />}
		<div className={classnames('pointer', b('remove-icon'), {'glyphicons bin': isRemovable})} onClick={onRemove} />

	</div>;
}

FilteringItem.propTypes = {
	item: PropTypes.any.isRequired,
	items: PropTypes.any.isRequired,
	index: PropTypes.number.isRequired,
	dispatch: PropTypes.func.isRequired
};

export default FilteringItem;
