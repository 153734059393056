import React from "react"
import { FormEntry, IconButton, NumericTextBox } from "controls/react/form";
import {AntSelect, textValueFields} from "controls/react/ant/antSelect";
import translator from 'core/localization';
import BudgetApi from 'controls/designer/features/widgets/cost/budget/api';
import DropDownList from 'controls/react/kendoWrappers/dropDownList';
import PropTypes from 'prop-types';
import {TimePeriodSelector, TimePeriod, AllTimePeriods} from "controls/react/form/timePeriodSelector";
import {apiFetch} from "framework/api";
import {getCurrencies} from "areas/cost/api";

let i = translator({
  "Cost Model": {
    "no": "Kostnad Modell"
  },
  "Cost Model Item": {
    "no": "Kostnad Modell Sak"
  },
  "Calculation": {
    "no": "Kalkulering"
  },
  "Budget": {
    "no": "Budsjett"
  },
  "Current month": {
    "no": "Nåværende måned"
  },
  "cost-display-label-tooltip": {
    "en": "Show the name of the cost item",
    "no": "Vis navnet til kostelementet"
  },
  "decimal-tooltip": {
    "en": "Number of decimals we will display the {0} with. If empty we display the {0} as is.",
    "no": "Antall desimaler vi viser {0} med. Hvis det er tomt, viser vi {0} som den er."
  },
  "Display label": {
    "no": "Visningsnavn"
  },
  "Decimals": {
    "no": "Desimal"
  },
  "None": {
    "no": "Ingen"
  },
  "Display": {
    "no": "Vise"
  },
  "Cost Sheet": {
    "no": "Kostnad Ark"
  },
  "Cost type": {
    "no": "Kost Type"
  },
  "Time range": {
    "no": "Tids ramme"
  },
  "Currency": {
    "no": "Valuta"
  },
  "Estimate": {
    "no": "Estimate"
  },
  "Show currency text": {
    "no": "Vis Valuta tekst"
  },
  "Show currency symbol": {
    "no": "Vis Valuta symbol"
  },
  "Show currency code": {
    "no": "Vis Valuta kode"
  },
  "Show currency": {
    "no": "Vis Valuta"
  }
});

export class CostDatasource extends React.PureComponent {
	timePeriodsForEstimate = [TimePeriod.CurrentMonth, TimePeriod.CurrentYear]
	allTimePeriods = [TimePeriod.LastDay, TimePeriod.Last7Days, TimePeriod.Last30Days,
		TimePeriod.LastYear, TimePeriod.CurrentMonth, TimePeriod.CurrentYear, TimePeriod.LastMonth, TimePeriod.Custom];

	static get propTypes() {
		return { datasource: PropTypes.object };
	}

	constructor(props) {
		super(props);

		this.valueTypeDataSource = this.getValueTypeDataSource();

		this.state = {
			costProfileDataSource: [],
			costProfileItemDataSource: [],
			costModelItemDataSource: [],
			displayLabelDS: [{
				text: i('None'),
				value: ''
			}, {
				text: i('Display'),
				value: 'DISPLAY'
			}],
			currencies:[]
		}
	}

	get link(){
		return this.props.datasource.get('cost');
	}

	render() {
		const costLink = this.link

		costLink.get('costProfileId')
			.changing(({ rootStore, value, initialValue, e }) => {
				rootStore.cost.costItemId = null;
				rootStore.cost.modelId = null;
			});

		costLink.get('modelId')
			.changing(({ rootStore, value, initialValue, e }) => {
				rootStore.cost.costItemId = null;
			});

		costLink.get('costItemId')
			.changed((e) => {
				const costItemId = costLink.value.costItemId;
				this.refreshLabelDisplayDataSource(costItemId);
			});

		costLink.get('valueType').changing(({rootStore, value}) => {
			if(value != 'ESTIMATE')
				return;

			const linkStore = costLink.getLinkStore(rootStore)
			if(linkStore.timePeriod.period != TimePeriod.CurrentYear) {
				linkStore.timePeriod.period = TimePeriod.CurrentMonth;
			}
		});

		return (
			<>
				<FormEntry label={i('Cost Model')} vertical={true}>
					<AntSelect dataList={this.state.costProfileDataSource}
						onSelect={value => this.onCostProfileChange(value)}
						valueLink={costLink.get('costProfileId')}
					/>
				</FormEntry>
				<FormEntry label={i('Cost Sheet')} vertical={true}>
					<AntSelect dataList={this.state.costModelItemDataSource}
						onSelect={value => this.onCostModelChange(value, costLink.get('costProfileId').value)}
						disabled={this.state.costModelItemDataSource.length === 0}
						valueLink={costLink.get('modelId')}
					/>
				</FormEntry>
				<FormEntry label={i('Cost Model Item')} vertical={true}>
					<AntSelect dataList={this.state.costProfileItemDataSource}
						disabled={this.state.costProfileItemDataSource.length === 0}
						valueLink={costLink.get('costItemId')}
					/>
				</FormEntry>
				<FormEntry label={i('Cost type')} vertical={true}>
					<AntSelect dataList={this.valueTypeDataSource}
						valueLink={costLink.get('valueType')}
					/>
				</FormEntry>
				<FormEntry label={i('Currency')} vertical={true}>
					<AntSelect dataList={this.state.currencies} valueLink={costLink.get('currency')} {...textValueFields}/>
				</FormEntry>
				<FormEntry label={i('Show currency')} vertical={true}>
					<AntSelect dataList={getShowCurrencyDataList()} valueLink={costLink.get('showCurrency')}/>
				</FormEntry>
				<FormEntry label={i('Time range')} vertical={true}>
					<TimePeriodSelector periods={this.getTimePeriods()} {...costLink.get('timePeriod').props}/>
				</FormEntry>
				<FormEntry label={i('Decimals')}>
					<IconButton embedded={true}
						iconName={'question-sign'}
						title={i('decimal-tooltip', this.getLabelDisplayParameter())} />
					<NumericTextBox spinners={true} min={0} max={20}
						{...costLink.get('decimalsNumber').props} />
				</FormEntry>
				<FormEntry label={i('Display label')}>
					<IconButton embedded={true}
						iconName={'question-sign'}
						title={i(`cost-display-label-tooltip`)} />
					<DropDownList dataSource={this.state.displayLabelDS}
						{...this.props.datasource.get('showAsLabel').props} />
				</FormEntry>
			</>

		)
	}

	getTimePeriods(){
		return this.link.value.valueType == 'ESTIMATE'
			? this.timePeriodsForEstimate
			: this.allTimePeriods;
	}

	async componentDidMount() {
		const result = await apiFetch(getCurrencies(this.props.datasource.value.accounts[0]))
		if(result.success){
			this.setState({
				currencies: result.data
			})
		}

		let costProfileDataSource = await BudgetApi.getBudgetList();
		if (costProfileDataSource.success) {
			this.setState({
				costProfileDataSource: costProfileDataSource.data
			});
		}

		let costProfileId = this.link?.value?.costProfileId
		if (costProfileId) {
			this.getCostModelItems(costProfileId);
			const modelId = this.link?.value?.modelId;
			if (modelId) {
				await this.getCostProfileItems(costProfileId, modelId);
				const costItemId = this.link?.value?.costItemId;
				if (costItemId) {
					this.refreshLabelDisplayDataSource(costItemId);
				}
			}
		}
	}

	onCostProfileChange(value) {
		if (value) {
			this.getCostModelItems(value);
		}
	}

	onCostModelChange(value, profileId) {
		if (value && profileId) {
			this.getCostProfileItems(profileId, value);
		}
	}

	async getCostProfileItems(id, modelId) {
		let costProfileItemDataSource = await BudgetApi.getBudgetItemsList(null, id, modelId, true, false);
		if (costProfileItemDataSource.success) {
			this.setState({
				costProfileItemDataSource: costProfileItemDataSource.data
			});
		}
	}

	async getCostModelItems(id) {
		let costModelItemDataSource = await BudgetApi.getCostModelsItemsList(null, id);
		if (costModelItemDataSource.success) {
			this.setState({
				costModelItemDataSource: costModelItemDataSource.data
			});
		}
	}

	getValueTypeDataSource() {
		return [{
			name: i('Budget'),
			id: 'BUDGET'
		}, {
			name: i('Cost'),
			id: 'COST'
		}, {
			name: i('Estimate'),
			id: 'ESTIMATE'
		}];
	}

	getLabelDisplayParameter() {
		return this.state.displayLabelDS.find(x => x.value == this.link.get('displayLabelType').value)?.text;
	}

	refreshLabelDisplayDataSource(value) {
		const itemText = this.state.costProfileItemDataSource.find(x => x.id == value)?.name;
		const current = this.state.displayLabelDS.find(x => x.value).text;
		if (!itemText || itemText == current) {
			return;
		}
		this.setState({
			displayLabelDS: [{
				text: i('None'),
				value: ''
			}, {
				// changing label
				text: itemText,
				value: 'DISPLAY'
			}]
		});
	}
}

export const ShowCurrencyMode = Object.freeze(({
	None: 'None',
	Text: 'Text',
	Symbol: 'Symbol',
	Code: 'Code'
}))

let showCurrenciesDataList = null;
function getShowCurrencyDataList(){
	if(showCurrenciesDataList == null) {
		showCurrenciesDataList = [{
			name: i('None'),
			id: ShowCurrencyMode.None
		}, {
			name: i('Show currency text'),
			id: ShowCurrencyMode.Text
		}, {
			name: i('Show currency symbol'),
			id: ShowCurrencyMode.Symbol
		}, {
			name: i('Show currency code'),
			id: ShowCurrencyMode.Code
		}];
	}

	return showCurrenciesDataList;
}
