import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

function setTooltips(wrapper) {
	for (const li of $(wrapper).find('li.k-button')) {
		const $li = $(li);

		const text = $li.children().first().text();

		$li.attr('title', text);
	}
}

export default class MultiSelect extends React.Component {
	constructor(props) {
		super(props);

		this.onChange = this.onChange.bind(this);
	}

	componentDidMount() {
		const onChange = this.onChange;
		const placeholder = this.props.placeholder;

		let config = {
			autoWidth: false,
			change: (e) => {
				onChange(this.kendoMultiSelect.value());
				setTooltips(this.kendoMultiSelect.wrapper);
			},
			dataBound: () => {
				const interval = setInterval(() => {
					if (!this.kendoMultiSelect || !this.kendoMultiSelect.wrapper) {
						return;
					}
					clearInterval(interval);
					setTooltips(this.kendoMultiSelect.wrapper);
				}, 500);
			},
			placeholder: placeholder
		};

		if(this.props.dataTextField || this.props.dataValueField){
			config.dataTextField = this.props.dataTextField || "text";
			config.dataValueField = this.props.dataValueField || "value";
		}

		if (this.props.template) {
			config.template = this.props.template;
		}

		if (this.props.tagTemplate) {
			config.tagTemplate = this.props.tagTemplate;
		}

		if (this.props.select) {
			config.select = this.props.select
		}

		if (this.props.options) {
			config = {
				...config,
				...this.props.options
			}
		}

		this.kendoMultiSelect = $(this.el).kendoSortedMultiSelect(config).data('kendoSortedMultiSelect');

		this.setData();
		this.setValue();

		if (this.props.width) {
			this.kendoMultiSelect.list.width(this.props.width);
		}
		this.kendoMultiSelect.enable(this.props.enabled);
	}

	setValue() {
		const {value} = this.props;

		if (!value) {
			return;
		}

		this.kendoMultiSelect.value(value);
	}

	setData() {
		this.kendoMultiSelect.setDataSource({
			data: this.props.data
		});

		this.setValue();
	}

	componentDidUpdate(prevProps) {
		if (this.props.value !== prevProps.value) {
			this.setValue();
		}

		if (this.props.data && this.props.data !== prevProps.data) {
			this.setData();
		}
		this.kendoMultiSelect.enable(this.props.enabled);
	}

	onChange(value) {
		this.props.onChange(value);
	}

	componentWillUnmount() {
		this.kendoMultiSelect.destroy();
	}

	render() {
		const {containerClass} = this.props;

		return (<div className={classnames(containerClass)}>
			<select id={this.props.id} multiple="multiple" ref={(el) => this.el = el}/>
		</div>);
	}
}

MultiSelect.propTypes = {
	data: PropTypes.array.isRequired,
	dataTextField: PropTypes.string,
	dataValueField: PropTypes.string,
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.array
	]),
	placeholder: PropTypes.string,
	enabled: PropTypes.bool,
	width: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	onChange: PropTypes.func.isRequired,
	containerClass: PropTypes.string,
	select: PropTypes.func,
	options: PropTypes.object,
	template: PropTypes.func,
	tagTemplate: PropTypes.func,
	filter: PropTypes.string
}
