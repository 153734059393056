window.CEEVIEW_LOGGING_ENABLED = true;
window.CEEVIEW_LOGGING_EVENTS_ENABLED = false;

export function clog(...args){
	if(!CEEVIEW_LOGGING_ENABLED || args.length == 0)
		return;

	console.log(getLogTimestamp(), ...args);
}

export function getLogTimestamp(){
	const currentDate = new Date();

	return currentDate.getMinutes() + ':' + currentDate.getSeconds() + '.' + currentDate.getMilliseconds();
}
