import './dropDownList.less'

import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import {i} from 'core/localization/localization';
import {Utils} from 'tools/utils';

export function nameIdDataSource(){
	return {
		dataTextField: "name",
		dataValueField: "id"
	}
}

export class DropDownList extends React.PureComponent {
	constructor(props) {
		super(props);

		this.selectRef = React.createRef();
		this.wrapperRef = this.props.wrapperRef || React.createRef();
	}

	componentDidMount() {
		//dropdown uses field named optionLabel for storing placeholder
		//but combobox uses placeholder
		//so here we combine values to provide uniform interface
		let placeholder = this.props.placeholder;
		let optionLabel = this.props.optionLabel;

		if (this.props.showSelectText && !placeholder) {
			placeholder = i('Select...');
		}

		if (placeholder && !optionLabel) {
			optionLabel = placeholder;
		}

		const defaultConfig = {
			dataSource: this.props.dataSource,
			enable: !this.props.disabled,
			autoBind: this.props.autoBind !== false,
			suggest: this.props.suggest === true,
			placeholder: placeholder,
			optionLabel: optionLabel
		};

		if (!this.props.plainArray) {
			defaultConfig.dataTextField = this.props.dataTextField || 'text';
			defaultConfig.dataValueField = this.props.dataValueField || 'value';
		}

		const config = this.props.config || defaultConfig;

		config.value = this.getValue();

		if (!config.change) {
			config.change = e => {
				let selectedValue = e.sender.value();
				let text = e.sender.text();
				if (this.kendoRef.selectedIndex === -1) {
					this.kendoRef.value(null);
				} else {
					this.props.onChange && this.props.onChange(selectedValue, e);
					this.props.onInfoChanged && this.props.onInfoChanged({text: text, value: selectedValue});
				}
			}
		}

		if (!config.dataBound) {
			config.dataBound = e => {
				if( this.props.onDataBound){
					this.props.onDataBound(e, JSON.parse(JSON.stringify(e.sender.dataSource.data())));
				}
			};
		}

		if (this.props.combo) {
			this.kendoRef = $(this.selectRef.current).kendoComboBox(config).data("kendoComboBox");
		} else {
			this.kendoRef = $(this.selectRef.current).kendoDropDownList(config).data("kendoDropDownList");
		}

		if (this.props.onSelect) {
			this.kendoRef.bind('select', this.props.onSelect);
		}

		if (this.props.kendoRef) {
			this.props.kendoRef(this.kendoRef);
		}

		this.kendoRef.enable(!this.props.disabled);
		if (this.props.disabled && this.props.clickable !== false || this.props.clickable === true) {
			this.makeNameClickable();
		}
	}

	getValue(){
		let value = this.props.value;
		if( this.props.index != null ) {
			value = this.props.config.dataSource[this.props.index].value;
		}

		return value;
	}

	componentDidUpdate(prevProps) {
		if (!this.kendoRef)
			return;

		if (prevProps.onSelect !== this.props.onSelect) {
			if (prevProps.onSelect) {
				this.kendoRef.unbind('select', prevProps.onSelect);
			}
			if (this.props.onSelect) {
				this.kendoRef.bind('select', this.props.onSelect);
			}
		}

		if (this.props.isFromAccount) {
			this.kendoRef.setDataSource(this.props.dataSource);
			this.kendoRef.refresh();
		} else {
			if (prevProps.dataSource != this.props.dataSource) {
				this.kendoRef.setDataSource(this.props.dataSource);
				this.kendoRef.refresh();
			}
		}
		this.kendoRef.value(this.props.value);
		this.kendoRef.enable(!this.props.disabled);
	}

	onAdd = (e) => {
		e.stopPropagation();

		this.props.onAdd();
	}

	makeNameClickable() {
		Utils.setClickableNameListener(this.props.type, this.kendoRef);
	}

	render() {
		const {showAdd, containerClass} = this.props;
		const invalid = this.props.invalid ? 'invalid' : '';
		return (
			<div className={classnames('drop-down-list', containerClass, invalid)} ref={this.wrapperRef} style={this.props.style}>
				<select id={this.props.id} ref={this.selectRef} />
				{showAdd && <div className="glyphicons plus-sign pointer" onClick={this.onAdd}/>}
			</div>
		);
	}
}

export const propTypes = {
	containerClass: PropTypes.string,
	value: PropTypes.any,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	onSelect: PropTypes.func,
	onAdd: PropTypes.func,
	onDataBound: PropTypes.func,
	showAdd: PropTypes.bool,
	dataSource: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.array
	]),
	dataTextField: PropTypes.string,
	dataValueField: PropTypes.string,
	showSelectText: PropTypes.bool,
	config: PropTypes.object,
	combo: PropTypes.bool,
	suggest: PropTypes.bool,
	autoBind: PropTypes.bool,
	placeholder: PropTypes.string,
	invalid: PropTypes.bool,
	optionLabel: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.string
	]),
	plainArray: PropTypes.bool
};

DropDownList.propTypes = propTypes;

export default DropDownList;
