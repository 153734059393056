const gridDataBound = function (dataSource) {
	var items = dataSource.data();
	for (var i = 0; i < items.length; i++) {
		var currentRow = $('[data-uid=' + items[i].uid + ']');
		if (items[i].enabled && items[i].name !== 'DISKSIZE') {
			currentRow.find('.cw_monitoring_toggle').css({
				'pointer-events': 'initial',
				'background-color': ''
			});
		} else {
			currentRow.find('.cw_monitoring_toggle').css({
				'pointer-events': 'none',
				'background-color': '#e1e1e1'
			});
		}
		if (items[i].metricOnly && items[i].name !== 'DISKSIZE') {
			currentRow.find('.cw_metric_toggle').css({
				'pointer-events': 'initial',
				'background-color': ''
			});
		} else {
			currentRow.find('.cw_metric_toggle').css({
				'pointer-events': 'none',
				'background-color': '#e1e1e1'
			});
		}
		if(items[i].name === 'DISKSIZE') {
			currentRow.find('.cw_readonly').css({
				'pointer-events': 'none',
				'background-color': '#e1e1e1'
			});
		}
	}
}
export default gridDataBound;
