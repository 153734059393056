import React from 'react';
import injectSheet from "react-jss";
import {Renderer} from "tools";
import {LocalEventsManager, StaticVars} from "core";
import Lang from 'core/localization/lang';

const styles = {
	container: {
		display: "flex",
		flex: "1",
		flexDirection: "column"
	},
	time: {
		fontSize: "32px",
		textAlign: "center",
		padding: "20px 0 20px 0"
	},
	date: {
		fontSize: "20px",
		textAlign: "center",
		padding: "10px 0 10px 0"
	},
	timezone: {
		fontSize: "16px",
		textAlign: "center",
		padding: "5px 0 5px 0",
		borderBottom: "1px solid"
	},
	lastUpdate: {
		fontSize: "16px",
		lineHeight: "30px",
		textAlign: "left",
		padding: "10px 0 10px 10px"
	}
};

class TimeWidget extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = Object.assign({}, this.getValues(), this.getBoardUpdateValue());

		LocalEventsManager.bind('boardUpdate', this.onBoardUpdated);
	}

	render() {
		const {classes} = this.props;

		return <div className={classes.container}>
			<div className={classes.time}>{this.state.time}</div>
			<div className={classes.date}>{this.state.date}</div>
			<div className={classes.timezone}>{this.state.timezone}</div>
			<div className={classes.lastUpdate}>
				{Lang.serviceBoard.LAST_UPDATE_SERVICE_BOARD}<br/>
				{this.state.lastUpdate}
			</div>
		</div>
	}

	getValues() {
		const now = Date.now();

		const {timeformat, dateformat, timezone} = this.props.config.configuration;

		return {
			time: Renderer.browserDateRenderer(now, StaticVars.TIME, timeformat, timezone),
			date: Renderer.browserDateRenderer(now, StaticVars.DATE, dateformat, timezone),
			timezone
		}
	}

	getBoardUpdateValue() {
		const now = Date.now();

		const {timeformat, dateformat, timezone} = this.props.config.configuration;

		return {
			lastUpdate: Renderer.browserDateRenderer(now, StaticVars.TIME, timeformat, timezone) +
			' ' + Renderer.browserDateRenderer(now, StaticVars.DATE, dateformat, timezone)
		};
	}

	onBoardUpdated = () => {
		this.setState(this.getBoardUpdateValue());
	}

	update() {
		this.setState(this.getValues());
	}

	componentDidMount() {
		const update = this.update.bind(this);
		this.interval = setInterval(update, 1000);
	}

	componentWillUnmount() {
		LocalEventsManager.unbind('boardUpdate', this.onBoardUpdated);
		clearInterval(this.interval);
	}
}

export default injectSheet(styles)(TimeWidget);
