import {makeAutoObservable} from "mobx";
import {State} from "tools";
import {getLastEntry} from "tools/helpers/array";
import {getUrlBuilderForCurrentHash} from "tools/urlBuilder";

class NavigationItem{
	config: object
}

class NavigationStoreInner{
	items: NavigationItem[] = [];

	constructor() {
		makeAutoObservable(this);
	}

	//async push (item: NavigationItem, action = NavigationAction.ADD){
		// return new Promise(resolve => {
		// 	const currentItem = this.items[this.items.length - 1];
		//
		// 	if (action != NavigationAction.CLEAR
		// 		&& item.config && currentItem?.config
		// 		&& item.config.moduleName == currentItem.config.moduleName
		// 		&& item.config.id == currentItem.config.id) {
		// 		return;
		// 	}
		//
		// 	this.changeContext(x => {
		// 		(action == NavigationAction.CLEAR) && (x.navigation.items = []);
		// 		(action == NavigationAction.POP_ONE) && (x.navigation.items.pop());
		// 		(action == NavigationAction.POP_TWO) && (x.navigation.items.pop() && x.navigation.items.pop());
		//
		//
		// 		const existingItemIndex = x.navigation.items
		// 			.findIndex(existingItem =>
		// 				existingItem.url && existingItem.url == item.url
		// 				|| existingItem.config?.moduleName && existingItem.config?.moduleName == item.config?.moduleName
		// 			);
		//
		// 		if (action == NavigationAction.REWIND_TO_THE_SAME) {
		// 			if (existingItemIndex != -1) {
		// 				x.navigation.items.splice(existingItemIndex, x.navigation.items.length);
		// 			}
		// 			x.navigation.items.push(item);
		// 		}else {
		// 			if(existingItemIndex == -1){
		// 				x.navigation.items.push(item);
		// 			}
		// 		}
		// 	}, () => {
		// 		resolve();
		// 	});
		// })
	//}

	pop = () => {
		State.mainApp.context.navigation.pop();
	}

	//navigationGoToItem = item => {
		// let itemIndex = -1;
		// let items = this.state.context.navigation.items;
		// for (let i = 0; i < items.length; i++) {
		// 	if (JSON.stringify(items[i]) === JSON.stringify(item)) {
		// 		itemIndex = i;
		// 	}
		// }
		//
		// if (itemIndex == -1)
		// 	return;
		//
		// this.changeContext(x => {
		// 	x.navigation.items.splice(itemIndex, x.navigation.items.length);
		// }, () => {
		// 	if (item.config) {
		// 		if (item.config.moduleName || !item.url) {
		// 			State.mainApp.loadModule(JSON.parse(JSON.stringify(item.config)));
		// 		} else {
		// 			State.mainApp.navigate(item.url);
		// 		}
		// 	} else {
		// 		State.mainApp.navigate(item.url);
		// 	}
		// });
//	}

	// navigationGoToUrl = url => {
	// 	//State.mainApp.navigate(url);
	// }
	//
	// navigationUpdateCurrentItem = params => {
	// 	this.changeContext(x => {
	// 		const items = x.navigation.items;
	// 		const currentItem = items[items.length - 1];
	// 		currentItem.config = {...currentItem.config, ...params};
	// 	})
	// }
	//
	// navigationUpdateCurrentItemUrl = params => {
	// 	const currentItem = getLastEntry(this.state.context.navigation.items);
	// 	if(currentItem?.url == null)
	// 		return;
	//
	// 	let newUrl = getUrlBuilderForCurrentHash();
	// 	Object.assign(newUrl.params, params);
	// 	newUrl = newUrl.build();
	// 	window.history.replaceState(null, null, '#' + newUrl);
	// 	this.changeContext(x => {
	// 		const currentItem = getLastEntry(x.navigation.items);
	// 		currentItem.url = '#' + newUrl;
	// 	});
	// }
}

export const NavigationStore = new NavigationStoreInner();

export enum NavigationAction {
	ADD = 'add',
	CLEAR = 'clear',
	POP_ONE = 'pop one',
	POP_TWO = 'pop two',
	REWIND_TO_THE_SAME = 'rewind to the same'
}

