import {setValueOnPath} from "./utils";

export class UrlBuilder{
	params = {};
	path = '';

	constructor(query){
		let parts = query.split('#');
		parts = parts[0].split('?')
		this.path = parts[0];

		if(this.path.length > 1 && this.path[this.path.length - 1] == '/'){
			this.path = this.path.substring(0, this.path.length - 1);
		}

		if(parts.length > 1){
			parts[1].split('&').forEach( p => {
				const parameterParts = p.split('=');
				setValueOnPath(this.params, parameterParts[0], parameterParts[1]);
			});
		}
	}

	add(key, value, condition = true) {
		if( typeof key == 'object'){
			return this.addObject(key);
		}

		if (condition === false || !key)
			return this;

		this.params[key] = value;

		return this;
	}

	addObject(object, prefix = '') {
		for(let [key, value] of Object.entries(object)){
			if(typeof value == 'object'){
				this.addObject(value, prefix + key + '.');
			}else{
				this.add(prefix + key, value);
			}
		}
		return this;
	}

	getQuery(){
		return Object.entries(this.params).map(([key, value])=> {
			if (!(value === null || value === undefined)) {
				return key + '=' + value;
			}
		}).join('&');
	}

	addPath(pathEntry){
		this.path = this.path + '/' + pathEntry;
		return this;
	}

	build(){
		let url = this.path;
		let query = this.getQuery();
		if(query != ''){
			url = url + '?' + query;
		}

		return url;
	}
}

export function getUrlBuilderForCurrentHash(){
	return new UrlBuilder(window.location.hash.split('#').pop());
}

export default UrlBuilder;
