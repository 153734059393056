import React from "react";
import {CeeviewNumericField} from "controls/react/form/ceeviewNumericField";

export default class CERange extends React.PureComponent {
    render() {
        const {input: {onChange, value}, meta, property, ...others} = this.props;
        const {min, max, increment} = property.toJSON();

        return <CeeviewNumericField value={+value}
                onChange={onChange}
                min={min}
                max={max}
                type='generic'
            />
    }
}
