import React from 'react';
import ReactDOM from "react-dom";
import {Utils, applyRequired} from 'tools/utils';
import Cookies from 'core/cookies';
import Settings from 'settings';

import MultiToggle from 'controls/multiToggle';
import ErrorHandler from 'core/errorHandler';
import ConfigurationBase from 'areas/service-boards/configurationBase';
import {AntSelect} from "controls/react/ant/antSelect";
import FormEntry from "controls/react/form/formEntry";
import aggregationTypeDataSource from 'areas/service-boards/widgets/common/dataSources/aggregationTypeDataSource';


export function MetricsWidgetConfiguration(config) {
	Utils.apply(this, config);
	this.initComponent();
};

export {MetricsWidgetConfiguration as default};

jQuery.extend(MetricsWidgetConfiguration.prototype, ConfigurationBase.prototype, {
	/**
	 * @cfg {Object} widgetObj
	 */

	/**
	 * Main init function
	 */
	initComponent: function () {
		var widgetObj = this.widgetObj;
		var html = '<div class="cw_field">';
		html += '<label class="cw_inline">' + lang.TITLE + ': </label>';
		html += '<input type="text" class="cw_widget_title cw_inline" value="' + (widgetObj.title || '') + '" />';
		html += '</div>';
		html += '<div class="cw_field" id="cw_metrics_account">';
		html += '<label class="cw_inline">' + lang.ACCOUNT + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_metrics_accountid" type="text" value="' + (widgetObj.configuration.accountId || '') + '" /></div>';
		html += '</div>';

		html += '<div class="cw_field" id="cw_metrics_profile">';
		html += '<label class="cw_inline">' + lang.PROFILE + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_metrics_profileid" type="text" value="' + (widgetObj.configuration.profileId || '') + '" /></div>';
		html += '</div>';
		html += '<div class="cw_field" id="cw_metrics_category">';
		html += '<label class="cw_inline">' + lang.CATEGORY + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_metrics_categoryid" type="text" value="' + (widgetObj.configuration.categoryId || '') + '" /></div>';
		html += '</div>';
		html += '<div class="cw_field" id="cw_metrics_rule_type">';
		html += '<label class="cw_inline">' + lang.kpi.RULE_TYPE + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_metrics_ruletypeid" type="text" value="' + (widgetObj.configuration.ruleTypeId || '') + '" /></div>';
		html += '</div>';
		html += '<div class="cw_field" id="cw_metrics_sample_rate">';
		html += '<label class="cw_inline">' + lang.kpi.SAMPLE_RATE + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_metrics_sample_rateid" type="text" value="' + (widgetObj.configuration.sampleRateId || '') + '" /></div>';
		html += '</div>';
		html += '<div class="cw_field" id="cw_metrics_kpi_accounts">';
		html += '<label class="cw_inline">' + lang.kpi.KPI_ACCOUNTS + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_metrics_kpi_accountsids" type="text" value="' + (widgetObj.configuration.kpiAccountId || '') + '" /></div>';
		html += '</div>';

		html += '<div class="cw_field">';
		html += '<label class="cw_inline">' + lang.widget.PERIOD + ': </label>';
		html += '<ul id="cw_metrics_period_control" class="cw_multi_toggle right"></ul>';
		html += '<div class="cw_hide_time_selector"><input type="checkbox" class="cw_include k-checkbox" id="cw_hide_time_selector"' + (widgetObj.configuration.hideTimeSelector ? 'checked="checked"' : '') + '></span>';
		html += '<label for="cw_hide_time_selector" class="k-checkbox-label">' + lang.widget.HIDE_TIME_SELECTOR + '</label></div>';
		html += '</div>';
		html += '<div class="cw_field cw_no_label hide" id="cw_metrics_dates">';
		html += '<input id="cw_metrics_startdate" type="text" /> - <input id="cw_metrics_enddate" type="text" />';
		html += '</div>';
		html += '<div class="cw_field" id="cw_chart_type_container">';
		html += '<label class="cw_inline">' + lang.TYPE + '</label>';
		html += '<div class="cw_dropdown_container">';
		html += '<input id="cw_chart_type" type="text" />';
		html += '</div>';
		html += '</div>';
		html += '<div class="cw_field">';
		html += '<label class="cw_inline">' + lang.AGGREGATION_TYPE + '</label>';
		html += '<div class="cw_dropdown_container">';
		html += '<input id="cw_aggregation_type" type="text" />';
		html += '</div>';
		html += '</div>';
		html += '<div class="cw_field">';
		html += '<input type="checkbox" class="cw_include k-checkbox" id="cw_ignore_missing_data" title="' + lang.serviceBoard.messages.MISSING_DATA_MESSAGE + '"></span>';
		html += '<label for="cw_ignore_missing_data" class="k-checkbox-label">' + lang.serviceBoard.IGNORE_MISSING_DATA + '</label>';
		html += '</div>';
		html += '<div class="cw_field">';
		html += '<input type="checkbox" class="cw_show_regression k-checkbox" id="cw_show_regression" title=""></span>';
		html += '<label for="cw_show_regression" class="k-checkbox-label">' + lang.SHOW_REGRESSION + '</label>';
		html += '</div>';
		html += '<div class="cw_field">';
		html += '<label class="cw_inline">' + lang.TIMEZONE + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_metrics_timezone" type="text" value="' + (widgetObj.configuration.timezone || Cookies.CeesoftTimezone) + '"/></div>';
		html += '</div>';
		html += '<div class="cw_field">';
		html += '<span class="cw_inline_full">' + lang.LEGEND + ': </span>';
		html += '<input id="cw_metrics_legend" type="text" />';
		html += '</div>';

		this.widgetConfigurationFormEl.empty().append(
			$(html).addClass('kpi-metricsWidgetConfiguration')
		);

		this.periodToggle = new MultiToggle({
			id: 'cw_metrics_period_control',
			cssClass: 'cw_multi_toggle right',
			value: widgetObj.configuration.period || 'LASTDAY',
			type: 'label',
			items: [{
				id: 'cw_metrics_period_lasthour',
				title: lang.periodMultiToggle.HOUR,
				label: lang.periodMultiToggle.HOUR,
				selected: false,
				fn: this.onPeriodToggle,
				scope: this,
				value: 'LASTHOUR'
			}, {
				id: 'cw_metrics_period_lastday',
				title: lang.periodMultiToggle.DAY,
				label: lang.periodMultiToggle.DAY,
				selected: true,
				fn: this.onPeriodToggle,
				scope: this,
				value: 'LASTDAY'
			}, {
				id: 'cw_metrics_period_lastweek',
				title: lang.periodMultiToggle.WEEK,
				label: lang.periodMultiToggle.WEEK,
				selected: false,
				fn: this.onPeriodToggle,
				scope: this,
				value: 'LAST7DAYS'
			}, {
				id: 'cw_metrics_period_lastmonth',
				title: lang.periodMultiToggle.MONTH,
				label: lang.periodMultiToggle.MONTH,
				selected: false,
				fn: this.onPeriodToggle,
				scope: this,
				value: 'LAST30DAYS'
			}, {
				id: 'cw_metrics_period_custom',
				title: lang.CUSTOM,
				label: lang.CUSTOM,
				selected: false,
				fn: this.onPeriodToggle,
				scope: this,
				value: 'CUSTOM'
			}]
		});

		this.legendDataSource = [{
			name: '<Account>',
			id: '<Account>'
		}, {
			name: '<KPI account>',
			id: '<KPI account>'
		}, {
			name: '<Profile>',
			id: '<Profile>'
		}, {
			name: '<RuleType>',
			id: '<RuleType>'
		}, {
			name: '<SampleRate>',
			id: '<SampleRate>'
		}];

		if (this.mode === 'update' || this.wasUpdate) {
			this.periodToggle.setSelectedItem(widgetObj.configuration.period);
			if (!widgetObj.configuration.labelTemplate) {
				this.legendMultiList = this.legendDataSource;
			} else {
				this.legendMultiList = widgetObj.configuration.labelTemplate;
			}

			$('#cw_hide_time_selector').prop('checked', widgetObj.configuration.hideTimeSelector);
			$('#cw_ignore_missing_data').prop('checked', widgetObj.configuration.ignoreMissingData);
			$('#cw_show_regression').prop('checked', widgetObj.configuration.showRegression);

			this.selectedProfileId = this.widgetObj.configuration.profileId;
			this.selectedCategoryId = this.widgetObj.configuration.categoryId;
			this.selectedRuleTypeId = this.widgetObj.configuration.ruleTypeId;
			this.selectedSampleRateId = this.widgetObj.configuration.sampleRateId;
			this.selectedKpiAccountId = this.widgetObj.configuration.kpiAccountId;
			this.widgetAccountId = this.widgetObj.configuration.accountId;
		}

		this.renderLegendMultiSelect();
		this.initKendoComponents();
		const requiredFields = ['#cw_metrics_accountid', '#cw_metrics_profileid', '#cw_metrics_categoryid', '#cw_metrics_ruletypeid', '#cw_metrics_sample_rateid', '#cw_metrics_kpi_accountsids'];
		applyRequired(requiredFields);
		if (widgetObj.configuration.period === 'CUSTOM') {
			$('#cw_metrics_dates').removeClass('hide');
			$('#cw_metrics_startdate').data('kendoDateTimePicker').value(new Date(widgetObj.configuration.startDate));
			$('#cw_metrics_enddate').data('kendoDateTimePicker').value(new Date(widgetObj.configuration.endDate));
		}
	},
	renderLegendMultiSelect: function() {
		ReactDOM.render(<FormEntry label={lang.LEGEND} vertical style={{maxWidth: '600px'}}>
			<AntSelect
				dataList={this.legendDataSource}
				mode={"multiple"}
				onChange={value => this.onLegendChange(value)}
				defaultValue={this.legendMultiList || []}
				placeholder={lang.SELECT} />
		</FormEntry>,  $('#cw_metrics_legend').parent().get()[0]);
	},
	onLegendChange: function(value) {
		this.legendMultiList = value || [];
	},
	/**
	 * Iniitalizes kendo components
	 */
	initKendoComponents: function () {
		var widgetObj = this.widgetObj;

		this.createAccountComponent({
			element: $('#cw_metrics_accountid'),
			container: $('#cw_metrics_account'),
			nextElement: $('#cw_metrics_profileid'),
			otherElements: [$('#cw_metrics_categoryid'), $('#cw_metrics_ruletypeid'), $('#cw_metrics_sample_rateid'), $('#cw_metrics_kpi_accountsids')],
			events: {
				dataSourceChange: $.proxy(function (e, length) {
					this.emptyComponent({
						containers: [{
							container: $('#cw_metrics_profile'),
							label: lang.PROFILE
						}, {
							container: $('#cw_metrics_category'),
							label: lang.CATEGORY
						}, {
							container: $('#cw_metrics_rule_type'),
							label: lang.TYPE
						}, {
							container: $('#cw_metrics_sample_rate'),
							label: lang.kpi.SAMPLE_RATE
						}, {
							container: $('#cw_metrics_kpi_accounts'),
							label: lang.kpi.KPI_ACCOUNTS
						}],
						length: length
					});
				}, this)
			}
		});
		this.chartType = $('#cw_chart_type').kendoDropDownList({
			dataSource: [{
				text: lang.AREA_CHART,
				value: 'area'
			}, {
				text: lang.AREASPLINE_CHART,
				value: 'areaspline'
			}, {
				text: lang.LINE_CHART,
				value: 'line'
			}, {
				text: lang.RANGE_CHART,
				value: 'arearange'
			}, {
				text: lang.SPLINE_CHART,
				value: 'spline'
			}],
			dataTextField: 'text',
			dataValueField: 'value',
			value: widgetObj.configuration.chartType || 'line'
		}).data('kendoDropDownList');

		this.aggregationType = $('#cw_aggregation_type').kendoDropDownList({
			dataSource: aggregationTypeDataSource,
			dataTextField: 'text',
			dataValueField: 'value',
			value: widgetObj.configuration.aggregationType || 'average'
		}).data('kendoDropDownList');

		$('#cw_metrics_profileid').kendoComboBox({
			dataSource: new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: $.proxy(function () {
							return Settings.serverPath + 'accounts/' + (this.widgetAccountId || widgetObj.configuration.accountId) + '/kpi/lite?ignoreTags=true';
						}, this),
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				change: $.proxy(function (e) {
					this.emptyComponent({
						containers: [{
							container: $('#cw_metrics_category'),
							label: lang.CATEGORY
						}, {
							container: $('#cw_metrics_rule_type'),
							label: lang.TYPE
						}, {
							container: $('#cw_metrics_sample_rate'),
							label: lang.kpi.SAMPLE_RATE
						}, {
							container: $('#cw_metrics_kpi_accounts'),
							label: lang.kpi.KPI_ACCOUNTS
						}],
						length: e.items.length
					});
				}, this),
				error: ErrorHandler.kendoServerError
			}),
			dataTextField: 'name',
			dataValueField: 'id',
			select: $.proxy(function (e) {
				if (e.item && e.item.hasClass('k-state-hover')) {
					this.isProfileChanged = true;
					this.isInputChanged = true;
					this.selectedProfileId = e.item.find('span').data('id');
					var categoryHandler = $('#cw_metrics_categoryid').data('kendoComboBox');
					var ruleTypeHandler = $('#cw_metrics_ruletypeid').data('kendoComboBox');
					var sampleRateHandler = $('#cw_metrics_sample_rateid').data('kendoComboBox');
					var kpiAccountsHandler = $('#cw_metrics_kpi_accountsids').data('kendoComboBox');
					if (this.selectedProfileId) {
						categoryHandler.enable(true);
						categoryHandler.dataSource.read();
						categoryHandler.text('');
					} else {
						categoryHandler.enable(false);
					}
					ruleTypeHandler.text('');
					ruleTypeHandler.enable(false);
					sampleRateHandler.text('');
					sampleRateHandler.enable(false);
					kpiAccountsHandler.text('');
					kpiAccountsHandler.enable(false);
				}
			}, this),
			template: '<span data-id="${ data.id }">${ data.name }</span>',
			enable: (this.mode === 'update' || this.wasUpdate) ? true : false,
			autoBind: (this.mode === 'update' || this.wasUpdate) ? true : false,
			change: $.proxy(function (e) {
				var eraseError = true;
				this.errorFields = Utils.comboBoxValueCheck(e, eraseError);
			}, this),
			dataBound: $.proxy(function (e) {
				if (this.mode === 'update' || this.wasUpdate) {
					if (!this.isAccountChanged) {
						this.errorFields = Utils.comboBoxValueCheck(e);
					}
				}
				if (this.isAccountChanged) {
					Utils.focusNextComboBox(e);
				}
			}, this),
			placeholder: lang.SELECT
		}).data('kendoComboBox');

		$('#cw_metrics_categoryid').kendoComboBox({
			dataSource: new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: $.proxy(function () {
							return Settings.serverPath + 'accounts/' + (this.widgetAccountId || widgetObj.configuration.accountId) + '/kpi/' + this.selectedProfileId + '/categories';
						}, this),
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				change: $.proxy(function (e) {
					this.emptyComponent({
						containers: [{
							container: $('#cw_metrics_rule_type'),
							label: lang.TYPE
						}, {
							container: $('#cw_metrics_sample_rate'),
							label: lang.kpi.SAMPLE_RATE
						}, {
							container: $('#cw_metrics_kpi_accounts'),
							label: lang.kpi.KPI_ACCOUNTS
						}],
						length: e.items.length
					});
				}, this),
				error: ErrorHandler.kendoServerError
			}),
			dataTextField: 'text',
			dataValueField: 'value',
			select: $.proxy(function (e) {
				if (e.item && e.item.hasClass('k-state-hover')) {
					this.isCategoryChanged = true;
					this.isInputChanged = true;
					this.selectedCategoryId = e.item.find('span').data('value');
					var ruleTypeHandler = $('#cw_metrics_ruletypeid').data('kendoComboBox');
					var sampleRateHandler = $('#cw_metrics_sample_rateid').data('kendoComboBox');
					var kpiAccountsHandler = $('#cw_metrics_kpi_accountsids').data('kendoComboBox');
					if (this.selectedCategoryId) {
						ruleTypeHandler.enable(true);
						ruleTypeHandler.dataSource.read();
						ruleTypeHandler.text('');
					} else {
						ruleTypeHandler.enable(false);
					}
					sampleRateHandler.text('');
					sampleRateHandler.enable(false);
					kpiAccountsHandler.text('');
					kpiAccountsHandler.enable(false);
				}
			}, this),
			template: '<span data-value="${ data.value }">${ data.text }</span>',
			enable: (this.mode === 'update' || this.wasUpdate) ? true : false,
			autoBind: (this.mode === 'update' || this.wasUpdate) ? true : false,
			change: $.proxy(function (e) {
				var eraseError = true;
				this.errorFields = Utils.comboBoxValueCheck(e, eraseError);
			}, this),
			dataBound: $.proxy(function (e) {
				if (this.mode === 'update' || this.wasUpdate) {
					if (!this.isProfileChanged) {
						this.errorFields = Utils.comboBoxValueCheck(e);
					}
				}
				if (this.isProfileChanged) {
					Utils.focusNextComboBox(e);
				}
			}, this),
			placeholder: lang.SELECT
		}).data('kendoComboBox');

		$('#cw_metrics_ruletypeid').kendoComboBox({
			dataSource: new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: $.proxy(function () {
							return Settings.serverPath + 'accounts/' + (this.widgetAccountId || widgetObj.configuration.accountId) + '/kpi/' + this.selectedProfileId + '/categories/' + this.selectedCategoryId + '/types';
						}, this),
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				schema: {
					model: {
						id: "value"
					}
				},
				change: $.proxy(function (e) {
					this.emptyComponent({
						containers: [{
							container: $('#cw_metrics_sample_rate'),
							label: lang.kpi.SAMPLE_RATE
						}, {
							container: $('#cw_metrics_kpi_accounts'),
							label: lang.kpi.KPI_ACCOUNTS
						}],
						length: e.items.length
					});
				}, this),
				error: ErrorHandler.kendoServerError
			}),
			dataTextField: 'text',
			dataValueField: 'value',
			select: $.proxy(function (e) {
				if (e.item && e.item.hasClass('k-state-hover')) {
					this.isRuleTypeChanged = true;
					this.isInputChanged = true;
					this.selectedRuleTypeId = e.item.find('span').data('value');
					var sampleRateHandler = $('#cw_metrics_sample_rateid').data('kendoComboBox');
					var kpiAccountsHandler = $('#cw_metrics_kpi_accountsids').data('kendoComboBox');
					if (this.selectedRuleTypeId) {
						sampleRateHandler.enable(true);
						sampleRateHandler.dataSource.read();
						sampleRateHandler.text('');
					} else {
						sampleRateHandler.enable(false);
					}
					kpiAccountsHandler.text('');
					kpiAccountsHandler.enable(false);
				}
			}, this),
			template: '<span data-value="${ data.value }">${ data.text }</span>',
			enable: (this.mode === 'update' || this.wasUpdate) ? true : false,
			autoBind: (this.mode === 'update' || this.wasUpdate) ? true : false,
			change: $.proxy(function (e) {
				var eraseError = true;
				this.errorFields = Utils.comboBoxValueCheck(e, eraseError);
			}, this),
			dataBound: $.proxy(function (e) {
				if (this.mode === 'update' || this.wasUpdate) {
					if (!this.isCategoryChanged) {
						this.errorFields = Utils.comboBoxValueCheck(e);
					}
				}
				if (this.isCategoryChanged) {
					Utils.focusNextComboBox(e);
				}
			}, this),
			placeholder: lang.SELECT
		}).data('kendoComboBox');

		$('#cw_metrics_sample_rateid').kendoComboBox({
			dataSource: new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: $.proxy(function () {
							return Settings.serverPath + 'accounts/' + (this.widgetAccountId || widgetObj.configuration.accountId) + '/kpi/' + this.selectedProfileId + '/types/' + this.selectedRuleTypeId + '/sampleRates';
						}, this),
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				change: $.proxy(function (e) {
					this.emptyComponent({
						containers: [{
							container: $('#cw_metrics_kpi_accounts'),
							label: lang.kpi.KPI_ACCOUNTS
						}],
						length: e.items.length
					});
				}, this),
				error: ErrorHandler.kendoServerError
			}),
			dataTextField: 'text',
			dataValueField: 'value',
			select: $.proxy(function (e) {
				if (e.item && e.item.hasClass('k-state-hover')) {
					this.isSampleRateChanged = true;
					this.isInputChanged = true;
					this.selectedSampleRateId = e.item.find('span').data('value');
					var kpiAccountsHandler = $('#cw_metrics_kpi_accountsids').data('kendoComboBox');
					if (this.selectedSampleRateId) {
						kpiAccountsHandler.enable(true);
						kpiAccountsHandler.dataSource.read();
						kpiAccountsHandler.text('');
					} else {
						kpiAccountsHandler.enable(false);
					}
				}
			}, this),
			template: '<span data-value="${ data.value }">${ data.text }</span>',
			enable: (this.mode === 'update' || this.wasUpdate) ? true : false,
			autoBind: (this.mode === 'update' || this.wasUpdate) ? true : false,
			change: $.proxy(function (e) {
				var eraseError = true;
				this.errorFields = Utils.comboBoxValueCheck(e, eraseError);
			}, this),
			dataBound: $.proxy(function (e) {
				if (this.mode === 'update' || this.wasUpdate) {
					if (!this.isRuleTypeChanged) {
						this.errorFields = Utils.comboBoxValueCheck(e);
					}
				}
				if (this.isRuleTypeChanged) {
					Utils.focusNextComboBox(e);
				}
			}, this),
			placeholder: lang.SELECT
		}).data('kendoComboBox');

		$('#cw_metrics_kpi_accountsids').kendoComboBox({
			dataSource: new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: $.proxy(function () {
							return Settings.serverPath + 'accounts/' + (this.widgetAccountId || widgetObj.configuration.accountId) + '/kpi/' + this.selectedProfileId + '/accounts';
						}, this),
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				schema: {
					parse: function (data) {
						data.unshift({
							value: 'aggregated',
							text: lang.AGGREGATED
						});

						return data;
					}
				},
				error: ErrorHandler.kendoServerError
			}),
			dataTextField: 'text',
			dataValueField: 'value',
			select: $.proxy(function (e) {
				this.selectedKpiAccountId = e.item.find('span').data('value');
			}, this),
			template: '<span data-value="${value}">${text}</span>',
			enable: (this.mode === 'update' || this.wasUpdate) ? true : false,
			autoBind: (this.mode === 'update' || this.wasUpdate) ? true : false,
			change: $.proxy(function (e) {
				var eraseError = true;
				this.errorFields = Utils.comboBoxValueCheck(e, eraseError);
			}, this),
			dataBound: $.proxy(function (e) {
				if (!this.isSampleRateChanged) {
					this.errorFields = Utils.comboBoxValueCheck(e);
				} else {
					Utils.focusNextComboBox(e);
				}
			}, this),
			placeholder: lang.SELECT
		}).data('kendoComboBox');

		$("#cw_metrics_startdate").kendoDateTimePicker({
			format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
			timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat)
		});
		$("#cw_metrics_enddate").kendoDateTimePicker({
			format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
			timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat)
		});
		$('#cw_metrics_timezone').kendoComboBox({
			dataSource: new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: Settings.serverPath + 'sessions/timezones/?addUserTime=true',
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				error: ErrorHandler.kendoServerError
			}),
			autoBind: true
		});
	},
	/**
	 * Handler function for the click event on qualifier type toggle
	 * @param {String} value The associated value of the clicked item
	 */
	onPeriodToggle: function (value) {
		if (value === 'CUSTOM') {
			$('#cw_metrics_dates').removeClass('hide');
		} else {
			$('#cw_metrics_dates').addClass('hide');
		}
	},
	findRecord: function (dataSource, id) {
		var record = null;
		var length = dataSource.total();
		for (var i = 0; i < length; i++) {
			var r = dataSource.at(i);
			if (r.id === id) {
				record = r;
				break;
			}
		}
		return record;
	},
	getValues: function () {
		var title = $('.cw_widget_title').val().trim();
		var period = this.periodToggle.getValue(),
			startDate = $('#cw_metrics_startdate').data('kendoDateTimePicker').value(),
			endDate = $('#cw_metrics_enddate').data('kendoDateTimePicker').value(),
			ignoreMissingData = $('#cw_ignore_missing_data').is(':checked'),
			showRegression = $('#cw_show_regression').is(':checked'),
			chartType = this.chartType.value(),
			timezone = $('#cw_metrics_timezone').data('kendoComboBox').value();
		var widgetObj = {
			id: this.mode === 'update' || this.wasUpdate ? this.widgetObj.id : Utils.guid(),
			type: 'kpi_metrics',
			title: title,
			configuration: {
				accountId: this.widgetAccountId || $('#cw_metrics_accountid').data('kendoComboBox').value(),
				period: period,
				startDate: startDate,
				endDate: endDate,
				ignoreMissingData: ignoreMissingData,
				showRegression: showRegression,
				chartType: chartType,
				aggregationType: this.aggregationType.value(),
				timezone: timezone || Cookies.CeesoftTimezone,
				hideTimeSelector: $('#cw_hide_time_selector').is(':checked'),
				labelTemplate: this.legendMultiList
			}
		};

		var config = {
			profileId: this.selectedProfileId,
			profileText: $('#cw_metrics_profileid').data('kendoComboBox').text(),
			categoryId: this.selectedCategoryId,
			categoryText: $('#cw_metrics_categoryid').data('kendoComboBox').text(),
			ruleTypeId: this.selectedRuleTypeId,
			ruleTypeText: $('#cw_metrics_ruletypeid').data('kendoComboBox').text(),
			sampleRateId: this.selectedSampleRateId,
			sampleRateText: $('#cw_metrics_sample_rateid').data('kendoComboBox').text(),
			kpiAccountId: this.selectedKpiAccountId
		};

		var metrics = [];
		metrics.push({
			profileId: config.profileId,
			type: config.ruleTypeId,
			sampleRate: config.sampleRateId
		});
		if (config.kpiAccountId === 'aggregated') {
			//metrics[0].accountId =  null;
			metrics[0].aggregated = true;

		} else {
			//metrics[0].accountId = config.kpiAccountId;
			metrics[0].aggregated = false;
		}
		widgetObj.configuration.metrics = metrics;
		jQuery.extend(widgetObj.configuration, config);
		//jQuery.extend(widgetObj.configuration, metrics);

		return widgetObj;
	},
	/**
	 * Validates the widget config form
	 * @return {Boolean} valid True if the form is valid, false otherwise
	 */
	isValid: function () {
		var valid = true;
		var validationMessage = '';
		var accountCombo = $('#cw_metrics_accountid').data('kendoComboBox');
		var profileCombo = $('#cw_metrics_profileid').data('kendoComboBox');
		var categoryCombo = $('#cw_metrics_categoryid').data('kendoComboBox');
		var ruleTypeCombo = $('#cw_metrics_ruletypeid').data('kendoComboBox');
		var sampleRateCombo = $('#cw_metrics_sample_rateid').data('kendoComboBox');
		var kpiAccountsCombo = $('#cw_metrics_kpi_accountsids').data('kendoComboBox');

		if (!Utils.isGuid(accountCombo.value())) {
			valid = false;
			validationMessage = lang.widget.SELECT_ACCOUNT;
		}
		if (!Utils.isGuid(this.widgetAccountId)) {
			valid = false;
			validationMessage = lang.widget.SELECT_ACCOUNT;
		}
		if (!Utils.isGuid(profileCombo.value())) {
			valid = false;
			validationMessage = lang.widget.SELECT_PROFILE;
		}
		if (!categoryCombo.value()) {
			valid = false;
			validationMessage = lang.widget.SELECT_CATEGORY;
		}
		if (!ruleTypeCombo.value()) {
			valid = false;
			validationMessage = lang.widget.SELECT_RULE_TYPE;
		}
		if (!sampleRateCombo.value()) {
			valid = false;
			validationMessage = lang.widget.SELECT_SAMPLE_RATE;
		}
		if (!kpiAccountsCombo.value()) {
			valid = false;
			validationMessage = lang.widget.SELECT_KPI_ACCOUNTS;
		}
		if (this.errorFields && this.errorFields.length) {
			valid = false;
			if (this.errorFields.length > 1) {
				validationMessage = lang.widget.SELECT_MISSING_FIELDS;
			} else {
				validationMessage = lang.widget.SELECT_MISSING_FIELD;
			}
		}

		return {
			valid: valid,
			validationMessage: validationMessage
		};
	},
});
