import {Utils, removeHiddenColumnsFromSorting} from 'tools/utils';
import Cookies from 'core/cookies';
import Widget from 'areas/service-boards/widget';
import Settings from 'settings';
import Application from 'core/application';
import RemoteEventsManager from 'core/remoteEventsManager';
import ErrorHandler from 'core/errorHandler';
import State from 'tools/state';
import GridSearch from 'controls/gridSearch';
import MultiSelectGridFilter from 'controls/multiSelectGridFilter';
import MultiToggle from 'controls/multiToggle';
import UserPrefs from 'tools/userPrefs';
import {getSeverityStateEvents} from 'controls/stateRenderer/severityState';
import EventSummaryView from 'areas/views/eventSummaryView';
import {getGridStateForSaving, updateHiddenColumns} from "controls/react/kendoWrappers/grid";
import {AgentsRouter} from 'areas/management/agents/bundleDescription';
import {translator} from "core/localization";
import {sharedLocalization} from "areas/services/localization"
import GridMenu from 'controls/gridMenu';
import CustomNotification from 'controls/customNotification';
import ReactDOM from "react-dom";
import React from 'react';
import ModalWindow from 'controls/modalWindow';
import {SetInformationWindow} from 'controls/setInformationWindow';
import {IncidentsRouter} from "areas/incidents/bundleDescription";

const i = require('core/localization').translator(sharedLocalization, {

});


export function EventsSummaryWidget(config) {
	Widget.call(this, config);

	this.requestPath = Settings.serverPath;
	if (this.sessionId) {
		this.requestPath = Settings.serverPath + 'sessions/' + this.sessionId + '/';
	}

	this.hasConfiguration = true;
	this.instanceConfiguration.includeSubaccounts = this.instanceConfiguration.includeSubaccounts || false;
};

export {EventsSummaryWidget as default}

jQuery.extend(EventsSummaryWidget.prototype, Widget.prototype, Application.prototype, {
	initComponent: function () {
		this.allowedIdentifiers = {
			'winservices.state': true,
			'winservices.status': true,
			'winservices.auto': true
		};
		var widgetContentDiv = $('#' + this.id).find('.cw_section_content');
		widgetContentDiv.parent().addClass('cw_section_event_summary_grid');
		this.createCustomControls();
		this.gridId = Utils.guid();
		this.menuId = this.id + '-menu';

		if (!this.renderTo) {
			widgetContentDiv = $('#' + this.id).find('.cw_section_content');
			$('#' + this.id).find('.cw_widget_settings').find('.cw_missing_data').detach();
		} else {
			widgetContentDiv = $('#' + this.renderTo);
			widgetContentDiv.closest('.k-window').find('.cw_widget_settings').find('.cw_missing_data').detach();
		}
		widgetContentDiv.empty().append('<div id="' + this.gridId + '" class="event_summary_grid"></div>');
		if(!document.getElementById(this.menuId))
			$('.cw_section_titlebar, .toolbar', '#' + this.id).prepend('<div id="'+this.menuId+'" style="margin-right: 5px"/>')
		this.gridMessages = {
			clear: lang.CLEAR,
			info: lang.grid.filter.SHOW_ITEMS,
			filter: lang.FILTER
		};

		this.subscribe();
		this.updateTitle();
		if (!this.sessionId && State.currentApp?.dashboardDesigner?.props?.mode !== 'designer') {
			$('#' + this.gridId).off();
			$('#' + this.gridId).on('click', '.cw_target_link', $.proxy(this.onTargetNameClick, this));
			$('#' + this.gridId).on('click', '.cw_source_link', $.proxy(this.onSourceNameClick, this));
		}
		$(window).off('resize', $.proxy(this.onResize, this));
		$(window).on('resize', $.proxy(this.onResize, this));
		State.gridPosLoaded = false;
		this.removeListeners();
		this.attachListeners();
		this.actionNotification = new CustomNotification({
			appendToElement: '#' + this.id,
		});
	},
	loadData: function () {
		this.typesLoaded = false;
		this.usersLoaded = false;
		this.loadTypes();
		this.loadUsers();
	},
	init: function () {
		this.userPref = [];
		UserPrefs.load('EventSummaryWidget', $.proxy(function (result) {
			if (result.success) {
				this.userPref = result.data;
				this.instanceConfiguration.period = UserPrefs.get('timePeriod');
				this.initComponent();
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this), this.sessionId);
	},
	/**
	 * Initializes Kendo components
	 */
	initKendoComponents: function () {
		if (this.usersLoaded && this.typesLoaded) {

			var filterMessages = lang.grid.filter;
			var eventSummaryColumns;
			var gridFilter = this.instanceConfiguration.filter || this.persistedState?.filter || [];

			let defaultColumns = {
				id: {
					hidden: false,
					width: 40
				},
				severity: {
					hidden: false
				},
				historic: {
					hidden: false
				},
				assignedToType: {
					hidden: false
				},
				acknowledged: {
					hidden: false
				},
				targetName: {
					hidden: false
				},
				agentName: {
					hidden: false
				},
				accountName: {
					hidden: false
				},
				type: {
					hidden: false
				},
				time: {
					hidden: false
				},
				timeDeleted: {
					hidden: true
				},
				message: {
					hidden: false
				},
				sourceName: {
					hidden: false
				},
				tags: {
					hidden: false
				},
				suppressionCount: {
					hidden: false
				},
				actionCount: {
					hidden: false
				},
				acknowledgedBy: {
					hidden: false
				},
				acknowledgedAt: {
					hidden: false
				},
				information: {
					hidden: false
				},
				inMaintenance: {
					hidden: false
				},
				agentId: {
					hidden: true
				},
				externalId: {
					hidden: true
				},
				externalOwner: {
					hidden: true
				},
				identifierInstance: {
					hidden: true
				},
				identifierName: {
					hidden: true
				},
				monitorTypeText: {
					hidden: true
				},
				monitorClass: {
					hidden: true
				},
				subsystem: {
					hidden: true
				},
				sourceId: {
					hidden: true
				},
				targetId: {
					hidden: true
				},
				assignedTo: {
					hidden: false
				},
				details: {
					hidden: false
				},
				timeCreated: {
					hidden: false
				}
			};
			if (this.instanceConfiguration.columns && !jQuery.isEmptyObject(this.instanceConfiguration.columns)) {
				eventSummaryColumns = this.instanceConfiguration.columns;
			} else {
				eventSummaryColumns =  defaultColumns;
			}
			eventSummaryColumns = updateHiddenColumns(this.persistedState?.columns, eventSummaryColumns);

			var gridSort = removeHiddenColumnsFromSorting(eventSummaryColumns, this.instanceConfiguration.sort || this.persistedState?.sort || [{
				field: 'time',
				dir: 'desc',
				compare: null
			}]);

			if (eventSummaryColumns.date) {
				eventSummaryColumns.time = eventSummaryColumns.date;
			}

			eventSummaryColumns.id = {hidden: !this.instanceConfiguration.showMenu}

			var url = this.requestPath + 'accounts/' + this.instanceConfiguration.accountId + '/summary/eventSummary?includeSubaccounts=' + this.instanceConfiguration.includeSubaccounts.toString() + '&timeSelector=' + (this.instanceConfiguration.period || 'NONE');
			this.dataSource = new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: url,
						contentType: 'application/json; charset=utf-8',
						type: 'POST',
						dataType: 'json',
						cache: false
					},
					parameterMap: $.proxy(function (data, type) {

						data.showUntagged = this.configuration.showUntagged;

						let tags = this.configuration.tags;
						let tagsArray = [];
						if (tags) {
							for (let i = 0; i < tags.length; i++) {
								if (tags[i].name) {
									tagsArray.push(tags[i].name);
								} else if (typeof tags[i] === 'string') {
									tagsArray.push(tags[i]);
								}
							}
						}
						this.configuration.tags = tagsArray;
						data.tags = this.configuration.tags;

						//workaround for infinite requests performed on very fast scrolling; to be further investigated
						var newScrollPos;
						if ($('#' + this.gridId).data('kendoCustomGrid')) {
							newScrollPos = $('#' + this.gridId).data('kendoCustomGrid').wrapper.find(".k-scrollbar").scrollTop();
						}
						if (newScrollPos !== undefined && newScrollPos > 0 && State.dynamicGridPos === newScrollPos) {
							State.stopAllRequests = true;
							window.stop();
							setTimeout(function() {
								State.stopAllRequests = false;
							}, 3000)
						} else {
							var i, length, value;
							if (type === 'read') {
								if (data.filter && data.filter.filters.length) {
									length = data.filter.filters.length;
									for (i = 0; i < length; i++) {
										if (data.filter.filters[i].field === 'time') {
											value = Utils.customDateToString(data.filter.filters[i].value, 'YYYY-MM-DD HH:mm:ss');
											data.filter.filters[i].value = value;
										}
									}
								}
							}
						}
						this.gridPayload = data;
						return kendo.stringify(data);
					}, this)
				},
				pageSize: 50,
				schema: {
					model: {
						fields: {
							severity: {
								type: 'string',
								editable: false
							},
							severityIndex: {
								type: 'number',
								editable: false
							},
							sourceName: {
								type: 'string',
								editable: false
							},
							accountName: {
								type: 'string',
								editable: false
							},
							type: {
								type: 'string',
								editable: false
							},
							message: {
								type: 'string',
								editable: false
							},
							targetName: {
								type: 'string',
								editable: false
							},
							agentName: {
								type: 'string',
								editable: false
							},
							action: {
								type: 'boolean',
								editable: true
							},
							actionCount: {
								type: 'number',
								editable: false
							},
							agentId: {
								type: 'string',
								editable: false
							},
							externalId: {
								type: 'string',
								editable: false
							},
							externalOwner: {
								type: 'string',
								editable: false
							},
							identifierInstance: {
								type: 'string',
								editable: false
							},
							identifierName: {
								type: 'string',
								editable: false
							},
							monitorTypeText: {
								type: 'string',
								editable: false
							},
							monitorClass: {
								type: 'string',
								editable: false
							},
							subsystem: {
								type: 'string',
								editable: false
							},
							targetId: {
								type: 'string',
								editable: false
							},
							information: {
								type: 'string',
								editable: false
							},
							acknowledged: {
								type: 'boolean',
								editable: false
							},
							acknowledgedBy: {
								type: 'string',
								editable: false
							},
							inMaintenance: {
								type: 'boolean',
								editable: false
							},
							suppressionCount: {
								type: 'number',
								editable: false
							},
							assignedTo: {
								type: 'string',
								editable: false
							},
							details: {
								type: 'string',
								editable: false
							},
							historic: {
								type: 'boolean'
							},
							tags: {
								type: 'string'
							}
						}
					},
					parse: response => {
						this.filterOptions = response.filterOptions;
						return response;
						},
					total: function (response) {
						this.visibleItems = response.visible;
						this.totalItems = response.total;

						return response.total;
					},
					data: $.proxy(function (data) {
						var grid = $('#' + this.gridId).data('kendoCustomGrid');
						if (grid) {
							State.dynamicGridPos = grid.wrapper.find(".k-scrollbar").scrollTop();
							for (var i = 0, length = data.items.length; i < length; i++) {
								var value = new Date(data.items[i].time);
								data.items[i].time = value;

								if (data.items[i].timeDeleted) {
									data.items[i].timeDeleted = new Date(data.items[i].timeDeleted);
								}
								data.items[i].timeCreated = new Date(data.items[i].timeCreated);

								if (data.items[i].inMaintenance) {
									data.items[i].severityIndex = 7;
								}

								if (data.items[i].assignedToType === 'ASSIGN_TEAM') {
									data.items[i].assignedToGlyph = 'parents';
									if (data.items[i].assignedTo) {
										data.items[i].assignedToMouseover = lang.TEAM + ': ' + data.items[i].assignedTo;
									} else {
										data.items[i].assignedToMouseover = lang.TEAM;
									}
								} else if (data.items[i].assignedToType === 'ASSIGN_USER') {
									data.items[i].assignedToGlyph = 'user';
									if (data.items[i].assignedTo) {
										data.items[i].assignedToMouseover = lang.USER + ': ' + data.items[i].assignedTo;
									} else {
										data.items[i].assignedToMouseover = lang.USER;
									}
								} else {
									data.items[i].assignedToGlyph = '';
									data.items[i].assignedToMouseover = '';
								}
								var targetName = data.items[i].targetName;
								if (targetName) {
									data.items[i].targetName = targetName.replace(/</g, '&lt;').replace(/>/g, '&gt;');
								}
								if (data.items[i].acknowledgedBy) {
									data.items[i].acknowledgedMouseover = lang.ACKNOWLEDGED_BY + ': ' + data.items[i].acknowledgedBy;
								} else {
									data.items[i].acknowledgedMouseover = '';
								}
							}
						}
						return data.items;
					}, this)
				},
				sort: gridSort,
				filter: gridFilter,
				serverSorting: true,
				serverPaging: true,
				serverFiltering: true,
				error: ErrorHandler.kendoServerError
			});
			var scope = this;
			this.grid = $('#' + this.gridId).kendoCustomGrid({
				dataSource: this.dataSource,
				cacheScrollCheckboxes: true,
				resizable: true,
				selectable: 'row',
				height: this.isDashboardMode() ? '100%' : null,
				autoBind: true,
				reorderable: true,
				sortable: {
					mode: 'multiple',
					allowUnsort: true
				},
				scrollable: {
					virtual: true
				},
				filterable: {
					extra: false,
					operators: {
						string: {
							contains: filterMessages.CONTAINS,
							eq: filterMessages.EQ,
							neq: filterMessages.NEQ
						},
						number: {
							eq: filterMessages.EQ,
							neq: filterMessages.NEQ
						},
						date: {
							eq: filterMessages.EQ,
							gte: filterMessages.IAE,
							gt: filterMessages.IA,
							lte: filterMessages.IBE,
							lt: filterMessages.IB
						}
					},
					messages: this.gridMessages
				},
				columns: Utils.rearrangeColumns([{
					field: 'id',
					title: lang.SELECTOR,
					menu: false,
					filterable: false,
					sortable: false,
					template: '<input type="checkbox" class="cw_grid_check" data-id="${id}" data-targetid="${targetId}" />',
					headerTemplate: '<input type="checkbox" class="cw_grid_check_all" />',
					attributes: {
						'class': 'text_center'
					},
					headerAttributes: {
						'class': 'text_center'
					},
					width: 40,
					//hidden: scope.instanceConfiguration.showMenu
				},
				{
					field: 'severity',
					title: lang.assethealth.SEVERITY,
					sortable: {
						compare: $.proxy(function (a, b) {
							return Utils.customCompare(a, b, 'severityIndex', 6, this.dataSource.sortNow);
						}, this)
					},
					filterable: {
						operators: {
							string: {
								eq: filterMessages.ISIN,
								neq: filterMessages.ISNOTIN
							}
						},
						ui: function (element) {
							var multiselect = new MultiSelectGridFilter({
								element: element,
								field: 'severity',
								grid: scope.grid,
								dataSource: new kendo.data.DataSource({ data: [{
									text: lang.CRITICAL,
									icon: '<span class="cw_status_indicator cw_es_indicator cw_status_widget_color is_critical"></span>',
									value: 'CRITICAL'
								}, {
									text: lang.MAJOR,
									icon: '<span class="cw_status_indicator cw_es_indicator cw_status_widget_color is_major"></span>',
									value: 'MAJOR'
								}, {
									text: lang.MINOR,
									color: 'is_minor',
									icon: '<span class="cw_status_indicator cw_es_indicator cw_status_widget_color is_minor"></span>',
									value: 'MINOR'
								}, {
									text: lang.OK,
									icon: '<span class="cw_status_indicator cw_es_indicator cw_status_widget_color is_ok"></span>',
									value: 'NONE'
								}, {
									text: lang.MAINTENANCE,
									icon: '<span class="cw_status_indicator cw_es_indicator cw_status_widget_color is_idle"><span class="glyphicons wrench"></span></span>',
									value: 'MAINTENANCE'
								},{
									text: lang.WARNING,
									icon: '<span class="cw_status_indicator cw_es_indicator cw_status_widget_color is_idle"><span class="glyphicons exclamation-mark"></span></span>',
									value: 'WARNING'
								}], serverSorting: true})
							});
						}
					},

					attributes: {
						'class': 'text_center'
					},
					//template: item => getEventIndicator(item),
					template: item => getSeverityStateEvents(item.severity, item.agentDown, item.inMaintenance, item.monitorError, item.historic, item.monitorMaintenance, item.maintenanceDetails),
					width: 60,
					hidden: eventSummaryColumns.severity ? eventSummaryColumns.severity.hidden : false,
				}, {
					field: 'historic',
					title: lang.HISTORIC,
					width: 100,
					template: '<span class="glyphicons #= historic ? \'ok cw_true\' : null #" title="#= historic ? lang.messages.HISTORIC_EVENT : null #"></span>',
					hidden: eventSummaryColumns.historic ? eventSummaryColumns.historic.hidden : true,
					attributes: {
						'class': 'text_center'
					},
				}, {
					field: 'assignedToType',
					title: lang.ASSIGNED_TO_TYPE,
					sortable: true,
					filterable: {
						operators: {
							string: {
								eq: filterMessages.EQ,
								neq: filterMessages.NEQ
							}
						},
						ui: function (element) {
							var multiselect = new MultiSelectGridFilter({
								element: element,
								field: 'assignedToType',
								grid: scope.grid,
								dataSource: [{
									text: lang.NONE,
									icon: '<span class="glyphicons"></span>',
									value: 'ASSIGN_NONE'
								}, {
									text: lang.USER,
									icon: '<span class="glyphicons user"></span>',
									value: 'ASSIGN_USER'
								}, {
									text: lang.TEAM,
									icon: '<span class="glyphicons parents"></span>',
									value: 'ASSIGN_TEAM'
								}]
							});
						}
					},
					template: '<span class="glyphicons #=assignedToGlyph#"></span>',
					attributes: {
						'class': 'tooltip ellipsis to_expand text_center assigned_type'
					},
					width: 140,
					hidden: eventSummaryColumns.assignedToType ? eventSummaryColumns.assignedToType.hidden : true,
				}, {
					field: 'acknowledged',
					title: lang.service.ACKNOWLEDGED,
					sortable: true,
					menu: false,
					filterable: true,
					template: '#if(acknowledged){#<span class="glyphicons ok"></span>#}#',
					hidden: eventSummaryColumns.acknowledged.hidden,
					width: 120,
					attributes: {
						'class': 'tooltip text_center acknowledged'
					}
				}, {
					field: 'targetName',
					title: lang.TARGET,
					sortable: true,
					filterable: true,
					hidden: eventSummaryColumns.targetName.hidden,
					width: 120,
					attributes: {
						'class': 'tooltip ellipsis to_expand'
					},
					template: '#if (targetName) {# <span class="cw_grid_link cw_target_link ellipsis to_expand" data-targetid="#= targetId #" data-type="#=type#"> #= targetName # </span> #} else {# <span class="cw_label"> #= lang.NA # </span> #}#'
				}, {
					field: 'agentName',
					title: lang.assethealth.AGENT_NAME,
					template: item => {
						return `<span data-agentid="${item.agentId}" class="${Utils.canEditAgent(item.agentId) ? 'cw_link cw_agent_name ellipsis to_expand' : ''}">${item.agentName ? item.agentName : ''}</span>`;
					},
					sortable: true,
					filterable: {
						operators: {
							string: {
								eq: filterMessages.EQ,
								neq: filterMessages.NEQ
							}
						},
						ui: function (element) {
							var multiselect = new MultiSelectGridFilter({
								element: element,
								field: 'agentName',
								grid: scope.grid,
								itemTemplate: '#=data.text#',
								tagTemplate: '#=data.text#',
								dataSource: scope.filterOptions['agentName']
							});
						}
					},
					hidden: eventSummaryColumns.agentName ? eventSummaryColumns.agentName.hidden : false,
					width: 120,
					attributes: {
						'class': 'expand ellipsis'
					}
				}, {
					field: 'accountName',
					title: lang.ACCOUNT,
					sortable: true,
					template: '<span>#= accountName || "N/A" #</span>',
					filterable: {
						ui: function (element) {
							new MultiSelectGridFilter({
								element: element,
								field: 'accountName',
								grid: scope.grid,
								itemTemplate: '#=data.text#',
								tagTemplate: '#=data.text#',
								dataSource: scope.filterOptions['accountName']
							});
						},
						messages: this.gridMessages,
						extra: false,
						operators: {
							string: {
								eq: filterMessages.EQ,
								neq: filterMessages.NEQ
							}
						}
					},
					hidden: eventSummaryColumns.accountName.hidden,
					width: 150,
					attributes: {
						'class': 'tooltip ellipsis to_expand'
					}
				}, {
					field: 'type',
					title: lang.TYPE,
					sortable: true,
					filterable: {
						operators: {
							string: {
								eq: filterMessages.EQ,
								neq: filterMessages.NEQ
							}
						},
						ui: function (element) {
							var multiselect = new MultiSelectGridFilter({
								element: element,
								field: 'type',
								grid: scope.grid,
								itemTemplate: '#=data.text#',
								tagTemplate: '#=data.text#',
								dataSource: scope.filterOptions['type']
							});
						}
					},
					template: '#= typeText#',
					hidden: eventSummaryColumns.type.hidden,
					width: 140,
					attributes: {
						'class': 'tooltip ellipsis to_expand'
					}
				}, {
					field: 'time',
					title: lang.widget.TIME,
					template: '#= Renderer.browserDateRenderer(time, Constants.DATETIME, "")#',
					sortable: true,
					filterable: {
						ui: function (element) {
							element.kendoDateTimePicker({
								format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
								timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat)
							});
						}
					},
					hidden: eventSummaryColumns.time.hidden,
					width: 140,
					attributes: {
						'class': 'tooltip ellipsis to_expand'
					}
				}, {
					field: 'timeDeleted',
					title: lang.TIME_DELETED,
					template: '#= Renderer.browserDateRenderer(timeDeleted, Constants.DATETIME, "")#',
					sortable: true,
					filterable: {
						ui: function (element) {
							element.kendoDateTimePicker({
								format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
								timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat)
							});
						}
					},
					hidden: eventSummaryColumns.timeDeleted ? eventSummaryColumns.timeDeleted.hidden : true,
					width: 140,
					attributes: {
						'class': 'tooltip ellipsis to_expand'
					}
				}, {
					field: 'message',
					title: lang.MESSAGE,
					sortable: true,
					filterable: true,
					hidden: eventSummaryColumns.message.hidden,
					attributes: {
						'class': 'tooltip ellipsis to_expand'
					},
					width: 280
				}, {
					field: 'sourceName',
					title: lang.NAME,
					sortable: true,
					filterable: true,
					template: '<span class="cw_grid_link cw_source_link" data-sourceid="#= sourceId #" data-type="#=type#"> #= sourceName # </span>',
					hidden: eventSummaryColumns.sourceName.hidden,
					width: 200,
					attributes: {
						'class': 'tooltip ellipsis to_expand'
					}
				}, {
					field: 'tags',
					title: lang.TAGS,
					sortable: true,
					filterable: {
						messages: this.gridMessages,
						extra: false,
						operators: {
							string: {
								contains: filterMessages.CONTAINS
							}
						}
					},

					hidden: eventSummaryColumns.tags ? eventSummaryColumns.tags.hidden : true,
					width: 120,
					attributes: {
						'class': 'tooltip ellipsis to_expand'
					}
				}, {
					field: 'suppressionCount',
					title: lang.SUPPRESSION,
					attributes: {
						'class': 'text_center tooltip ellipsis to_expand'
					},
					sortable: true,
					filterable: true,
					hidden: eventSummaryColumns.suppressionCount.hidden,
					width: 120,
				}, {
					field: 'actionCount',
					title: lang.ACTION_COUNT,
					sortable: true,
					menu: false,
					filterable: true,
					hidden: eventSummaryColumns.actionCount ? eventSummaryColumns.actionCount.hidden : true,
					width: 160,
					attributes: {
						'class': 'text_center action_count'
					}
				}, {
					field: 'information',
					title: lang.INFORMATION,
					sortable: true,
					menu: false,
					filterable: true,
					template: '#=information ? information : "" #',
					attributes: {
						'class': 'tooltip ellipsis to_expand'
					},
					hidden: eventSummaryColumns.information ? eventSummaryColumns.information.hidden : true,
					width: 160
				}, {
					field: 'acknowledgedBy',
					title: lang.incidents.ACKNOWLEDGED_BY,
					sortable: true,
					filterable: {
						operators: {
							string: {
								eq: filterMessages.EQ,
								neq: filterMessages.NEQ
							}
						},
						ui: function (element) {
							var multiselect = new MultiSelectGridFilter({
								element: element,
								field: 'acknowledgedBy',
								grid: scope.grid,
								itemTemplate: '#=data.text#',
								tagTemplate: '#=data.text#',
								dataSource: scope.filterOptions['acknowledgedBy']
							});
						},
						messages: this.gridMessages
					},
					template: '#=acknowledgedBy ? acknowledgedBy : "" #',
					hidden: eventSummaryColumns.acknowledgedBy.hidden,
					width: 160,
					attributes: {
						'class': 'tooltip ellipsis to_expand'
					}
				}, {
					field: 'acknowledgedAt',
					title: lang.incidents.TIME_OF_FLAG,
					sortable: true,
					menu: false,
					filterable: false,
					template: '#=Renderer.browserDateRenderer(acknowledgedAt, Constants.DATETIME)#',
					hidden: eventSummaryColumns.acknowledgedAt.hidden,
					width: 160,
					attributes: {
						'class': 'tooltip ellipsis to_expand'
					}
				}, {
					field: 'inMaintenance',
					title: lang.service.IN_MAINTENANCE,
					sortable: true,
					menu: false,
					filterable: true,
					template: '#if(inMaintenance){#<span class="glyphicons ok"></span>#}#',
					hidden: eventSummaryColumns.inMaintenance ? eventSummaryColumns.inMaintenance.hidden : true,
					width: 160
				}, {
					field: 'agentId',
					title: lang.serviceBoard.AGENT_ID,
					sortable: true,
					filterable: true,
					hidden: eventSummaryColumns.agentId ? eventSummaryColumns.agentId.hidden : true,
					width: 120,
					attributes: {
						'class': 'tooltip ellipsis to_expand'
					}
				}, {
					field: 'externalId',
					title: lang.account.ASSET_EXTERNAL_ID,
					sortable: true,
					filterable: true,
					hidden: eventSummaryColumns.externalId.hidden,
					width: 120,
					attributes: {
						'class': 'tooltip ellipsis to_expand'
					}
				}, {
					field: 'externalOwner',
					title: lang.account.ASSET_EXTERNAL_OWNER,
					sortable: true,
					filterable: true,
					hidden: eventSummaryColumns.externalOwner.hidden,
					width: 120,
					attributes: {
						'class': 'ellipsis to_expand'
					}
				}, {
					field: 'identifierInstance',
					title: lang.assethealth.IDENTIFIER_INSTANCE,
					sortable: true,
					filterable: true,
					hidden: eventSummaryColumns.identifierInstance.hidden,
					width: 120,
					attributes: {
						'class': 'tooltip ellipsis to_expand'
					}
				}, {
					field: 'identifierName',
					title: lang.assethealth.IDENTIFIER_NAME,
					sortable: true,
					filterable: true,
					hidden: eventSummaryColumns.identifierName.hidden,
					width: 120,
					attributes: {
						'class': 'tooltip ellipsis to_expand'
					}
				}, {
					field: 'monitorTypeText',
					title: lang.assethealth.MONITOR_TYPE,
					sortable: true,
					filterable: {
						operators: {
							string: {
								eq: filterMessages.EQ,
								neq: filterMessages.NEQ
							}
						},
						ui: function (element) {
							var multiselect = new MultiSelectGridFilter({
								element: element,
								field: 'monitorTypeText',
								grid: scope.grid,
								itemTemplate: '#=data.text#',
								tagTemplate: '#=data.text#',
								dataSource: scope.filterOptions['monitorTypeText']
							});
						},
						messages: this.gridMessages,
					},
					template: item => {
						return item.monitorTypeText || '';
					},
					hidden: eventSummaryColumns.monitorTypeText?.hidden || eventSummaryColumns.monitorType?.hidden,
					width: 120,
					attributes: {
						'class': 'tooltip ellipsis to_expand'
					}
				}, {
					field: 'monitorClass',
					title: lang.assethealth.MONITOR_CLASS,
					sortable: true,
					filterable: {
						operators: {
							string: {
								eq: filterMessages.EQ,
								neq: filterMessages.NEQ
							}
						},
						ui: function (element) {
							var multiselect = new MultiSelectGridFilter({
								element: element,
								field: 'monitorClassText',
								grid: scope.grid,
								itemTemplate: '#=data.text#',
								tagTemplate: '#=data.text#',
								dataSource: scope.filterOptions['monitorClassText']
							});
						},
						messages: this.gridMessages,
					},
					template: item => {
						return item.monitorClassText || '';
					},
					hidden: eventSummaryColumns.monitorClass?.hidden,
					width: 120,
					attributes: {
						'class': 'tooltip ellipsis to_expand'
					}
				}, {
					field: 'subsystem',
					title: lang.assethealth.SUBSYSTEM,
					sortable: true,
					//filterable: true,
					filterable: {
						operators: {
							string: {
								eq: filterMessages.EQ,
								neq: filterMessages.NEQ
							}
						},
						ui: function (element) {
							var multiselect = new MultiSelectGridFilter({
								element: element,
								field: 'subsystem',
								grid: scope.grid,
								itemTemplate: '#=data.text#',
								tagTemplate: '#=data.text#',
								dataSource: scope.filterOptions['subsystem']
							});
						},
						messages: this.gridMessages,
					},
					hidden: eventSummaryColumns.subsystem.hidden,
					width: 120,
					attributes: {
						'class': 'tooltip ellipsis to_expand'
					}
				}, {
					field: 'sourceId',
					title: lang.SOURCE_ID,
					sortable: true,
					filterable: true,
					attributes: {
						'class': 'tooltip ellipsis to_expand'
					},
					hidden: eventSummaryColumns.sourceId.hidden,
					width: 120,
				}, {
					field: 'targetId',
					title: lang.TARGET_ID,
					attributes: {
						'class': 'tooltip ellipsis to_expand'
					},
					sortable: true,
					filterable: true,
					hidden: eventSummaryColumns.targetId.hidden,
					width: 120,
				}, {
					field: 'assignedTo',
					title: lang.ASSIGNED_TO,
					sortable: true,
					filterable: true,
					hidden: eventSummaryColumns.assignedTo.hidden,
					width: 120,
					attributes: {
						'class': 'tooltip ellipsis to_expand'
					}
				}, {
					field: 'details',
					title: lang.DETAILS,
					sortable: true,
					menu: false,
					filterable: true,
					template: '#=details ? details : "" #',
					hidden: eventSummaryColumns.details ? eventSummaryColumns.details.hidden : true,
					attributes: {
						'class': 'tooltip ellipsis to_expand'
					},
					width: 200
				}, {
					field: 'timeCreated',
					title: lang.CREATED,
					template: '#= Renderer.browserDateRenderer(timeCreated, Constants.DATETIME, "")#',
					sortable: true,
					filterable: {
						ui: function (element) {
							element.kendoDateTimePicker({
								format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
								timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat)
							});
						}
					},
					hidden: eventSummaryColumns.timeCreated ? eventSummaryColumns.timeCreated.hidden : true,
					width: 140,
					attributes: {
						'class': 'tooltip ellipsis to_expand'
					}
				}], eventSummaryColumns),
				columnMenu: true,
				change: $.proxy(this.onRowExpand, this),
				dataBound: $.proxy(this.onGridDataBound, this)
			}).data('kendoCustomGrid');
			// Add Kendo tooltip to the header of the columns

			/*$('.k-virtual-scrollable-wrap').scroll(function () {
				console.log('scroll');
				State.dynamicGridPos = $('#' + scope.gridId).data('kendoCustomGrid').wrapper.find(".k-scrollbar").scrollTop();
			});*/

			Utils.gridColumnHeaderTooltip(this.grid);
			this.grid.thead.find("[data-field='id']>.k-header-column-menu").remove();
			var searchId = $('#' + this.id).find('.cw_search_box').attr('id');
			this.gridSearch = new GridSearch({
				id: searchId,
				dataSource: this.dataSource,
				fields: ['sourceName', 'accountName', 'type', 'message', 'targetName', 'externalId', 'externalOwner', 'identifierInstance', 'identifierName', 'monitorTypeText', 'monitorClass', 'subsystem', 'targetId', 'sourceId', 'agentName', 'agentId']
			});
      		if (eventSummaryColumns.accountName.hidden) {
				this.grid.hideColumn('accountName');
			}
			class FakeGridMenu {
				disableItem() {}
				enableItem() {}
			};
			$('#'+this.menuId).empty();
			this.gridMenu = !this.instanceConfiguration.showMenu ? new FakeGridMenu(): new GridMenu({
				renderTo: this.menuId,
				items: [{
					id: 'cw_event_summary_incidents_create',
					icon: 'construction-cone',
					text: lang.incidents.CREATE_INCIDENT,
					fn: this.onCreateIncident,
					scope: this,
					role: 'INCIDENT_CREATE',
					disabled: true
				}, {
					id: 'cw_event_summary_reason_acknowledge',
					icon: 'check',
					text: lang.ACKNOWLEDGE,
					fn: this.onAckUnack,
					scope: this,
					disabled: true,
					role: 'EVENTS_UPDATE'
				}, {
					id: 'cw_event_summary_reason_unacknowledge',
					icon: 'unchecked',
					text: lang.UNACKNOWLEDGE,
					fn: this.onAckUnack,
					role: 'EVENTS_UPDATE',
					scope: this,
					disabled: true
				}, {
					id: 'cw_event_summary_reset_hi',
					icon: 'restart',
					text: lang.assethealth.RESET_HI,
					fn: this.onResetHIButton,
					scope: this,
					cssClass: '',
					disabled: true,
					role: 'MONITOR_UPDATE'
				}, {
					id: 'cw_event_summary_create_filter',
					icon: 'filter',
					text: lang.assethealth.CREATE_FILTER,
					fn: this.onCreateFilter,
					scope: this,
					cssClass: '',
					disabled: true,
					role: State.mainApp.session.hasV2Permissions ? 'HEALTHFILTER_CREATE' : undefined
				}, {
					id: 'cw_event_summary_start_service',
					icon: 'play',
					text: lang.service.START_SERVICE,
					fn: this.onStartService,
					scope: this,
					disabled: true,
					role: 'MONITOR_UPDATE'
				}, {
					id: 'cw_event_summary_stop_service',
					icon: 'stop',
					text: lang.service.STOP_SERVICE,
					fn: this.onStopService,
					scope: this,
					disabled: true,
					role: 'MONITOR_UPDATE'
				}, {
					id: 'cw_event_summary_add_note',
					icon: 'plus-sign',
					text: lang.service.ADD_NOTE,
					fn: this.onAddServiceNote,
					role: 'SERVICE_UPDATE',
					scope: this,
					disabled: true
				}, {
					id: 'cw_event_summary_unassign',
					icon: 'undo',
					text: lang.UNASSIGN,
					fn: this.onUnassign,
					role: 'EVENTS_UPDATE',
					scope: this,
					disabled: true
				}, {
					id: 'cw_event_summary_assign',
					icon: 'object-align-horizontal',
					text: lang.ASSIGN,
					fn: this.onAssign,
					role: 'EVENTS_UPDATE',
					scope: this,
					disabled: true
				}, {
					id: 'cw_event_summary_export_csv',
					icon: 'file-export',
					text: lang.EXPORT_CSV,
					fn: this.onExportCsv,
					scope: this,
					disabled: false
				}, {
					id: 'cw_delete_reason',
					icon: 'remove-circle',
					text: lang.DELETE,
					fn: () => {
						this.onRemove();
					},
					role: 'EVENTS_DELETE',
					scope: this,
					disabled: true
				}, {
					id: 'cw_set_information',
					icon: 'info-sign',
					text: lang.SET_INFORMATION,
					fn: this.onSetInformation,
					role: 'EVENTS_UPDATE',
					scope: this,
					disabled: true
				}]
			});
		}
	},

	onAgentNameClick: function (e) {
		let target = $(e.currentTarget);
		let agentId = target.data('agentid');
		State.mainApp.navigate(AgentsRouter.details(agentId));
	},

	loadTypes: function () {
		var url = this.requestPath + 'accounts/' + this.instanceConfiguration.accountId + '/summary/eventSummary/types';
		Utils.ajax(url, 'GET', '', $.proxy(function (result) {
			this.eventSummaryTypes = result;
			this.typesLoaded = true;
			this.initKendoComponents();
		}, this));
	},
	loadUsers: function () {
		var url = this.requestPath + 'accounts/' + this.instanceConfiguration.accountId + '/summary/eventSummary/users';
		Utils.ajax(url, 'GET', '', $.proxy(function (result) {
			this.eventSummaryUsers = result;
			this.usersLoaded = true;
			this.initKendoComponents();
		}, this));
	},
	/**
	 * Called when an event is received from the server
	 * @param {Object} data The event data object
	 */
	onEvent: function (data) {
		this.refresh();
	},
	/**
	 * Handler function for the change(select) event on the grid
	 * @param {Object} e The change event object
	 */
	onRowExpand: function (e) {
		var selectedRow = this.grid.select();
		var myRow = selectedRow[0];
		var messageEl = $(myRow).find('.to_expand');
		if ($(messageEl).hasClass('cw_message_expanded')) {
			$(messageEl).removeClass('cw_message_expanded').addClass('ellipsis');
		} else {
			$('#' + this.gridId).find('.k-grid-content').find('td.cw_message_expanded').removeClass('cw_message_expanded').addClass('ellipsis');
			$(messageEl).addClass('cw_message_expanded').removeClass('ellipsis');
		}
	},

	onGridDataBound: function () {
		let tooltip = $('#' + this.gridId).find('.k-grid-content').data('kendoTooltip');
		if (tooltip) {
			tooltip.destroy();
		}
		$('#' + this.gridId).find('.cw_agent_name').on('click', $.proxy(this.onAgentNameClick, this));
		$('#' + this.gridId).find('.k-grid-content').kendoTooltip({
			filter: "td.tooltip:not(:empty)",
			content: (event) => {
				let grid = $('#' + this.gridId).data("kendoCustomGrid");
				if (grid) {
					let dataItem = $('#' + this.gridId).data("kendoCustomGrid").dataItem(event.target.closest('tr'));
					if (dataItem.assignedToMouseover && event.target.hasClass('assigned_type')) {
						return dataItem.assignedToMouseover;
					} else if (dataItem.acknowledgedMouseover && event.target.hasClass('acknowledged')) {
						return dataItem.acknowledgedMouseover;
					}{
						return event.target.text().split("\n").join("<br />");
					}
				}
			}
		});
		setTimeout(() => this.adjustSectionHeight(this.id), 200);
	},

	actionEditor: function (container, options) {
		var dataSource = [];
		if (options.model.type === 'ASSET_HEALTH_REASON') {
			if (State.mainApp && State.mainApp.session.hasRole('INCIDENT_CREATE')) {
				dataSource.push({
					text: lang.incidents.CREATE_INCIDENT,
					value: 'create_incident'
				});
			}
			if (State.mainApp && State.mainApp.session.hasRole('MONITOR_UPDATE')) {
				dataSource.push({
					text: lang.incidents.CREATE_INCIDENT,
					value: 'create_incident'
				}, {
					text: lang.assethealth.RESET_HI,
					value: 'reset_health_index'
				});
			}
			if (State.mainApp && (!State.mainApp.session.hasV2Permissions || State.mainApp.session.hasRole('HEALTHFILTER_CREATE'))) {
				dataSource.push({
					text: lang.assethealth.CREATE_FILTER,
					value: 'create_filter'
				});
			}
			if (State.mainApp && State.mainApp.session.hasRole('MONITOR_UPDATE')) {
				dataSource.push({
					text: lang.service.START_SERVICE,
					value: 'start_service'
				}, {
					text: lang.service.STOP_SERVICE,
					value: 'stop_service'
				});
			}
		}
		if (['SERVICE_ERROR', 'SERVICE', 'SERVICE_ELEMENT', 'SERVICE_QUALIFIER'].indexOf(options.model.type) > -1) {
			dataSource = [];
			if (State.mainApp && State.mainApp.session.hasRole('SERVICE_UPDATE')) {
				dataSource.push({
					text: lang.service.ADD_NOTE,
					value: 'add_log_entry'
				});
			}
			dataSource.push({
				text: lang.management.ACKNOWLEDGE_ALL,
				value: 'acknowledge_all'
			});
		}
		if (['SERVICE_ERROR', 'SERVICE', 'SERVICE_ELEMENT', 'SERVICE_QUALIFIER', 'ASSET_HEALTH_REASON'].indexOf(options.model.type) > -1) {
			options.model.action = undefined;
			var input = $('<input type="text" />');
			input.attr('name', options.field);
			input.appendTo(container);
			input.kendoDropDownList({
				optionLabel: {
					text: lang.SELECT_ACTION,
					value: ''
				},
				dataSource: dataSource,
				dataTextField: 'text',
				dataValueField: 'value',
				change: $.proxy(function (e) {
					this.performAction(e, container);
				}, this)
			});
			input.select(0);
		}
	},
	/*
	 * Handler function for sending actions for a service
	 * @param {Object} e The dropdown change event object
	 * @param {Object} container The jQuery element container
	 * */
	performAction: function (e, container) {
		var action = e.sender.value();
		var record = this.dataSource.getByUid(container.closest('tr').attr('data-uid'));

		switch (action) {
			case 'create_incident':
				State.mainApp && State.mainApp.loadModule('IncidentForm', null, {
					mode: 'new',
					assets: [{id: record.targetId, name: record.targetName}],
					contentCategory: 'ASSET',
					accountList: [{id: record.accountId, name: record.accountName}],
					reasonsIds: [record.id],
					parentModule: 'EventSummary'
				});
				break;
			case 'reason_acknowledge':
				this.acknowledgeReasons([record.targetId], record.targetName);
				break;
			case 'reset_health_index':
				var monitors = [{
					monitorId: record.sourceId,
					assetId: record.targetId
				}];
				Utils.resetMonitorsHI(monitors, function () {
					$('.cw_grid_check').prop('checked', false);
					$('.cw_grid_check_all').prop('checked', false);
				});
				break;
			case 'create_filter':
				if (State.mainApp &&  (!State.mainApp.session.hasV2Permissions || State.mainApp.session.hasRole('HEALTHFILTER_CREATE'))) {
					State.mainApp.loadModule('AssetHealthFiltersConfiguration', '', {
						mode: 'create',
						populate: {
							monitorId: record.sourceId,
							monitorType: record.monitorType,
							severity: record.severity,
							reasonDescription: record.message,
							reasonSubsytem: record.subsystem,
							identifierName: record.identifierName || '',
							identifierInstance: record.identifierInstance || '',
							accountId: record.accountId,
							accountName: record.accountName
						}
					});
				} else {
					//TODO: either show error message, either remove menu item from dropdown
				}
				break;
			case 'add_log_entry':
				this.selectedServiceId = record.targetId;
				this.onAddServiceNote();
				break;
			case 'acknowledge':
				this.acknowledgeLogs([record.sourceId]);
				break;
			case 'acknowledge_all':
				this.onAcknowledgeAll();
				break;
			case 'start_service':
				this.sendWinServiceAction('START', record);
				break;
			case 'stop_service':
				this.sendWinServiceAction('STOP', record);
				break;
			case 'show_all':
				$('#cw_event_summary_show_all').trigger('click');
				break;
			case 'show_not_acknowledged':
				$('#cw_event_summary_show_not_acknowledged').trigger('click');
				break;
		}
	},
	/*
	 * Handler function for sending actions for a service
	 * @param {Object} e The dropdown change event object
	 * @param {Object} container The jQuery element container
	 * */
	sendWinServiceAction: function (command, record) {
		var url, data, allowedIdentifiers = {
			'winservices.state': true,
			'winservices.status': true,
			'winservices.auto': true
		};

		if (command) {

			if (allowedIdentifiers[record.identifierName]) {
				data = {
					assetId: record.targetId,
					monitorId: record.sourceId,
					identifierName: record.identifierName,
					identifierInstance: record.identifierInstance
				};
				url = this.requestPath + 'accounts/' + this.instanceConfiguration.accountId + '/monitors/WINSERVICE/serviceCommand/' + command;
				Utils.ajax(url, 'POST', JSON.stringify(data), $.proxy(function (result) {
					if (result.success) {
						this.actionNotification.setOptions({
							message: result.message,
							status: 'info'
						}).show();
					} else {
						this.actionNotification.setOptions({
							message: result.message,
							status: 'error'
						}).show();
					}
				}, this));
			}
		}
	},
	/**
	 * Handler function for the click event on Add Service Note button
	 * @param {Object} e The click event
	 */
	onAddServiceNote: function (e) {
		const {top, left} = $('#'+this.menuId).offset();

		if (!this.selectedServiceId) {
			var checkbox = $('#' + this.gridId).find('.cw_grid_check:checked');
			var record = this.dataSource.getByUid($(checkbox).closest('tr').attr('data-uid'));
			this.selectedServiceId = record.targetId;
		}
		this.serviceNoteWindowId = Utils.guid();
		$('body').append('<div id="' + this.serviceNoteWindowId + '"></div>');

		var window = $('#' + this.serviceNoteWindowId).kendoWindow({
			width: '400px',
			height: '300px',
			title: i('Create log entry'),
			modal: true,
			visible: false,
			position: {
				top: top + 40,
				left: left + 40
			},
			close: $.proxy(function () {
				$('#' + this.serviceNoteWindowId).data("kendoWindow").destroy();
			}, this)
		}).data('kendoWindow');
		let html = '<div class="cw_service_note form">';
		html += '<div class="cw_field">';
		html += '<label class="cw_inline">' + 'Status' + '</label>';
		html += '<div class="cw_dropdown_container">';
		html += '<input id="cw_service_note_status" type="text" class="" />';
		html += '</div>';
		html += '</div>';
		html += '<div class="cw_comment_wrapper">';
		html += '<textarea id="cw_service_note_message" placeholder="' + lang.service.MESSAGE_TEXT + '" class="cw_comment_text"></textarea>';
		html += '</div>';
		html += '</div>';
		html += '<div class="status"><p></p></div>';
		html += '<div class="cw_actions absolute">';
		html += '<button class="k-button right" id="cancel_service_note">' + lang.CANCEL + '</button>';
		html += '<button class="k-button k-primary go_right" id="save_service_note">' + lang.SAVE + '</button>';
		html += '</div>';
		window.content(html);
		window.open();
		$('#cw_service_note_status').kendoDropDownList({
			dataSource: [{
				id: 'OK',
				text: lang.OK
			}, {
				id: 'ERROR',
				text: lang.ERROR
			}, {
				id: 'WARNING',
				text: lang.WARNING
			}, {
				id: 'FAILED',
				text: lang.FAILED
			}, {
				id: 'UNKNOWN',
				text: lang.UNKNOWN
			}],
			dataTextField: 'text',
			dataValueField: 'id'
		});
		$('#save_service_note').off();
		$('#save_service_note').on('click', $.proxy(this.onSaveServiceNote, this));
		$('#cancel_service_note').off();
		$('#cancel_service_note').on('click', $.proxy(this.onCancelServiceNote, this));
	},
	onSaveServiceNote: function (e) {
		var message = $('#cw_service_note_message').val();
		var state = $('#cw_service_note_status').data('kendoDropDownList').value();
		if (message) {
			var data = {
				message: message
			};
			var url = Settings.serverPath + 'services/' + this.selectedServiceId + '/notifications/?status=' + state;
			Utils.ajax(url, 'POST', JSON.stringify(data), $.proxy(function (result) {
				if (result.success) {
					this.onCancelServiceNote();
				} else {
					Utils.showInfo(lang.ALERT, result.message, result.details);
				}
				this.resetCheckboxes();
			}, this));
		} else {
			var status = $('.cw_service_note').find('.status');
			status.find('p').text(lang.service.messages.ENTER_MESSAGE);
			status.find('p').addClass('error');
			status.slideDown().delay(4000).slideUp();
		}
	},
	onCancelServiceNote: function (e) {
		this.selectedServiceId = null;
		var win = $('#' + this.serviceNoteWindowId).data("kendoWindow");
		if (win) {
			win.close();
			win.destroy();
		}
	},
	/**
	 * Handler function for the click event on the Acknowledge all menu item
	 **/
	onAcknowledgeAll: function () {
		var url = this.requestPath + 'accounts/' + this.instanceConfiguration.accountId + '/services/notifications/acknowledgeAll?includeSubaccounts=' + State.includeSubaccounts.toString();
		Utils.ajax(url, 'POST', {}, $.proxy(function (result) {
			if (result.success) {
				//this.grid.dataSource.read();
				$('.cw_grid_check_all').prop('checked', false);
				this.actionNotification.setOptions({
					message: lang.messages.ACTION_SUCCEEDED,
					status: 'success'
				}).show();
				State.mainApp && State.mainApp.getStatusesCount();
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},
	acknowledgeLogs: function (logs) {
		var url = this.requestPath + 'accounts/' + this.instanceConfiguration.accountId + '/systemErrors/acknowledge';
		Utils.ajax(url, 'POST', JSON.stringify(logs), $.proxy(function (result) {
			if (result.success) {
				this.grid.dataSource.read();
				$('.cw_grid_check_all').prop('checked', false);
				State.mainApp && State.mainApp.getStatusesCount();
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},

	onTargetNameClick: function (e) {
		EventSummaryView.prototype.onTargetNameClick.call(this, e);
	},

	onSourceNameClick: function (e) {
		EventSummaryView.prototype.onSourceNameClick.call(this, e)
	},

	getFilterableForUsers: function (element) {
		var menu = $(element).parent();
		menu.find(".k-filter-help-text").text(lang.SELECT);
		element.removeAttr("data-bind");
		var operatorEl = menu.find("[data-role=dropdownlist]");
		operatorEl.find('option[value="contains"]').remove();
		operatorEl.find('option[value="startswith"]').remove();
		operatorEl.find('option[value="eq"]').text(lang.grid.filter.ISIN);
		operatorEl.find('option[value="neq"]').text(lang.grid.filter.ISNOTIN);
		operatorEl.attr('data-index', '1');
		var multiSelect = element.kendoSortedMultiSelect({
			dataSource: this.scope.eventSummaryUsers || [],
			optionLabel: lang.grid.FILTER_SELECT_VALUE
		}).data('kendoSortedMultiSelect');
		var dataSource = this.scope.dataSource;
		menu.find('[type=submit]').on('click', {
			widget: multiSelect,
			operatorElement: operatorEl
		}, $.proxy(this.scope.filterByType, {scope: this.scope, dataSource: dataSource, field: this.field}));
		menu.find('[type=reset]').on('click', $.proxy(function (e) {
			multiSelect.value([]);
		}, this));
		Utils.setMultiSelectValueFromFilter(multiSelect, operatorEl, this.field, dataSource.filter());
	},
	filterByType: function (e) {
		var indicators = e.data.widget.value();
		var operator = e.data.operatorElement.data('kendoDropDownList').value();
		var currentFilter = this.dataSource.filter();
		var logic = (operator === 'eq') ? 'or' : 'and';
		var filter = {logic: logic, filters: []};
		for (var i = 0; i < indicators.length; i++) {
			filter.filters.push({field: this.field, operator: operator, value: indicators[i]});
		}
		if (currentFilter && currentFilter.filters) {
			for (var i = 0; i < currentFilter.filters.length; i++) {
				if (currentFilter.filters[i].filters) {
					for (var j = 0; j < currentFilter.filters[i].filters.length; j++) {
						if (currentFilter.filters[i].filters[j].field === this.field) {
							currentFilter.filters[i].filters.splice(j, 1);
							j--;
						}
					}
				}
				if (currentFilter.filters[i].field === this.field) {
					currentFilter.filters.splice(i, 1);
					i--;
				}
			}
			if (filter.filters.length) {
				currentFilter.filters.push(filter);
			}
			this.dataSource.filter(currentFilter);
		} else {

			this.dataSource.filter(filter);
		}
	},
	/**
	 * Updates the widget title
	 */
	updateTitle: function () {
		if (!this.title) {
			var titleSpan = this.isKendoWindow ? $('#' + this.id).closest('.k-window').find('.k-window-title') : $('#' + this.id).find('.cw_section_title');
			titleSpan.text(lang.EVENT_SUMMARY);
		}
	},
	/**
	 * Creates custom controls
	 */
	createCustomControls: function () {
		if (!this.customControls)
			return;
		var target = this.customControls.target;
		$(target).find('.cw_toggle_options').remove();


		if (!$(target).find('.cw_widget_settings').length) {
			$(target).append('<div class="cw_widget_settings" data-accountid="' + this.instanceConfiguration.accountId + '"></div>');
			$(target).find('.cw_section_titlebar').append('<div id="period_multi_toggle"></div>');
		}

		if ($(target).find('span.period_multi_toggle').length) {
			$(target).find('span.period_multi_toggle').detach();
			$(target).find('.cw_widget_settings').addClass('hide');
			$(target).find('.cw_section_titlebar').append('<div id="period_multi_toggle"></div>');
		}

		if (!$(target).find('.cw_multi_toggle').length) {
			this.multiToggle = new MultiToggle({
				id: 'period_multi_toggle',
				cssClass: 'cw_multi_toggle right small',
				value: this.instanceConfiguration.period || 'NONE',
				type: 'label',
				items: [{
					id: 'cw_multigraph_period_lasthour',
					title: lang.periodMultiToggle.HOUR,
					label: lang.periodMultiToggle.HOUR,
					selected: this.instanceConfiguration.period === 'LASTHOUR',
					fn: (e) => {
						this.onPeriodToggle(e);
					},
					scope: this,
					value: 'LASTHOUR'
				}, {
					id: 'cw_multigraph_period_lastday',
					title: lang.periodMultiToggle.DAY,
					label: lang.periodMultiToggle.DAY,
					selected: this.instanceConfiguration.period === 'LASTDAY',
					fn: (e) => {
						this.onPeriodToggle(e);
					},
					scope: this,
					value: 'LASTDAY'
				}, {
					id: 'cw_multigraph_period_lastweek',
					title: lang.periodMultiToggle.WEEK,
					label: lang.periodMultiToggle.WEEK,
					selected: this.instanceConfiguration.period === 'LAST7DAYS',
					fn: (e) => {
						this.onPeriodToggle(e);
					},
					scope: this,
					value: 'LAST7DAYS'
				}, {
					id: 'cw_multigraph_period_lastmonth',
					title: lang.periodMultiToggle.MONTH,
					label: lang.periodMultiToggle.MONTH,
					selected: this.instanceConfiguration.period === 'LAST30DAYS',
					fn: (e) => {
						this.onPeriodToggle(e);
					},
					scope: this,
					value: 'LAST30DAYS'
				}, {
					id: 'cw_multigraph_period_custom',
					title: lang.ALL,
					label: lang.ALL,
					selected: this.instanceConfiguration.period === 'NONE',
					fn: (e) => {
						this.onPeriodToggle(e);
					},
					scope: this,
					value: 'NONE'
				}]
			});
		}
	},
	onPeriodToggle: function (e) {
		this.instanceConfiguration.period = e;
		this.dataSource.options.transport.read.url = this.requestPath + 'accounts/' + this.instanceConfiguration.accountId + '/summary/eventSummary?includeSubaccounts=' + this.instanceConfiguration.includeSubaccounts.toString() + '&timeSelector=' + this.instanceConfiguration.period;
		this.refresh();
	},
	/**
	 * Refresh the widget
	 */
	refresh: function () {
		if (this.dataSource) {
			this.dataSource.read();
		}
	},
	/**
	 * Triggered after widget resize
	 * @param {Object} event The resize event
	 * @param {Object} ui The UI element - see http://api.jqueryui.com/resizable/
	 */
	onResize: function (event, ui) {
		this.adjustSectionHeight(this.id);
		setTimeout(() => {
			this.createCustomControls();
		});
	},
	/**
	 * Method by Andy
	 * @param {String} id The section id
	 */
	adjustSectionHeight: function (id) {
		let heightAdjust;
		if (this.isDashboardMode() && !this.isWithoutHeader()) {
			heightAdjust = 70;
		} else {
			heightAdjust = 30;
		}
		let section = $('#' + id);
		let sectionHeight = section.height();
		let contentHeight = sectionHeight - heightAdjust;
		section.find('.cw_section_content').css('height', sectionHeight);
		section.find('.k-grid').css('height', sectionHeight);
		section.find('.k-grid-content').css('height', contentHeight);

		let shownContent = section.find('.k-virtual-scrollable-wrap');
		let contentWidth = section.find('.k-grid-content').width();
		let actualContentHeight = section.find('.k-grid-content table').height();
		let shownContentHeight = shownContent.height();
		if (actualContentHeight < shownContentHeight) {
			section.find('.k-scrollbar-vertical').addClass('hide');
			shownContent.css('width', contentWidth + 18 + 'px');
		} else {
			section.find('.k-scrollbar-vertical').removeClass('hide');
		}
	},
	/**
	 * Subscribes to server events
	 */
	subscribe: function () {
		var subscriptionObj = [{
			eventType: 'EventSummary',
			accountId: this.instanceConfiguration.accountId,
			includeSubaccounts: this.instanceConfiguration.includeSubaccounts
		}];
		this.subscriberId = this.id;
		RemoteEventsManager.subscribe(this.subscriberId, subscriptionObj);
		this.resetCheckboxes();
		this.loadData();
	},
	/**
	 * Destroy
	 */
	destroy: function () {
		$(window).off('resize', $.proxy(this.onResize, this));
		this.saveUserPreferences();
		Widget.prototype.destroy.call(this);
	},
	saveUserPreferences: function () {
		let preferences = [{
			key: 'timePeriod',
			value: this.instanceConfiguration.period
		}];

		this.saveUserPrefs({
			category: 'EventSummaryWidget',
			preferences: preferences
		});
	},

	getStateForSaving(){
		return getGridStateForSaving(this.grid);
	},
	removeListeners: function () {
		$('#' + this.gridId).off();
	},

	attachListeners: function () {
		$('#' + this.gridId).on('click', '.cw_grid_check', $.proxy(this.onCheck, this));
		$('#' + this.gridId).on('click', '.cw_grid_check_all', $.proxy(this.onCheckAll, this));
	},

	onCheck: function (e) {
		var checkboxes = $('#' + this.gridId).find('.cw_grid_check');
		var serviceLogsCount = 0;
		var reasonsCount = 0;
		var selectAllIndex;
		this.selectedIds = this.selectedIds || [];
		this.selectedItems = this.selectedItems || [];
		this.filteringChanged = false;
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			var checkboxId = $(checkboxes[i]).attr('data-id');
			var index = State.markedCheckboxes.indexOf(checkboxId);
			selectAllIndex = this.selectedIds.indexOf(checkboxId);
			var uid = $(checkboxes[i]).closest('tr').attr('data-uid');
			var record = this.dataSource.getByUid(uid);
			if ($(checkboxes[i]).is(':checked')) {
				if (record.type === 'ASSET_HEALTH_REASON') {
					reasonsCount++;
				}
				if (['SERVICE_ERROR', 'SERVICE', 'SERVICE_ELEMENT', 'SERVICE_QUALIFIER'].indexOf(record.type) > -1) {
					serviceLogsCount++;
				}
				if (index === -1) {
					State.markedCheckboxes.push(checkboxId);
				}
				if (selectAllIndex === -1 && !record.historic) {
					this.selectedIds.push(checkboxId);
					this.selectedItems.push({
						id: record.id,
						sourceId: record.sourceId,
						targetId: record.targetId,
						type: record.type,
						accountId: record.accountId,
						identifierName: record.identifierName,
						identifierInstance: record.identifierInstance
					});
				}
			} else {
				if (index !== -1) {
					State.markedCheckboxes.splice(index, 1);
				}
				if (selectAllIndex !== undefined && selectAllIndex !== -1) {
					this.selectedIds.splice(selectAllIndex, 1);
					this.selectedItems.splice(selectAllIndex, 1);
				}
			}
		}
		if (this.selectedIds.length) {
			this.gridMenu.enableItem('cw_event_summary_reason_acknowledge');
			this.gridMenu.enableItem('cw_event_summary_reason_unacknowledge');
			this.gridMenu.enableItem('cw_event_summary_assign');
			this.gridMenu.enableItem('cw_event_summary_unassign');
			this.gridMenu.enableItem('cw_delete_reason');
			this.gridMenu.enableItem('cw_set_information');

			if (reasonsCount) {
				if (reasonsCount === 1) {
					this.gridMenu.enableItem('cw_event_summary_create_filter');
				} else {
					this.gridMenu.disableItem('cw_event_summary_create_filter');
				}
				this.gridMenu.enableItem('cw_event_summary_start_service');
				this.gridMenu.enableItem('cw_event_summary_stop_service');
				this.gridMenu.enableItem('cw_event_summary_incidents_create');
				this.gridMenu.enableItem('cw_event_summary_reset_hi');
			} else {
				this.gridMenu.disableItem('cw_event_summary_incidents_create');
				this.gridMenu.disableItem('cw_event_summary_reset_hi');
				this.gridMenu.disableItem('cw_event_summary_create_filter');
				this.gridMenu.disableItem('cw_event_summary_start_service');
				this.gridMenu.disableItem('cw_event_summary_stop_service');
			}
			if (serviceLogsCount) {
				//this.gridMenu.enableItem('cw_event_summary_acknowledge');
				if (serviceLogsCount === 1) {
					this.gridMenu.enableItem('cw_event_summary_add_note');
				} else {
					this.gridMenu.disableItem('cw_event_summary_add_note');
				}
			} else {
				//this.gridMenu.disableItem('cw_event_summary_acknowledge');
				this.gridMenu.disableItem('cw_event_summary_add_note');
			}
		} else {
			this.gridMenu.disableItem('cw_set_information');
			this.gridMenu.disableItem('cw_event_summary_incidents_create');
			this.gridMenu.disableItem('cw_event_summary_reset_hi');
			this.gridMenu.disableItem('cw_event_summary_create_filter');
			this.gridMenu.disableItem('cw_event_summary_start_service');
			this.gridMenu.disableItem('cw_event_summary_stop_service');
			this.gridMenu.disableItem('cw_event_summary_add_note');
			this.gridMenu.disableItem('cw_event_summary_reason_acknowledge');
			this.gridMenu.disableItem('cw_event_summary_reason_unacknowledge');
			this.gridMenu.disableItem('cw_event_summary_assign');
			this.gridMenu.disableItem('cw_event_summary_unassign');
			this.gridMenu.disableItem('cw_delete_reason');
		}
	},

	onCheckAll: function (e) {
		var checkAll = $('#' + this.gridId).find('.cw_grid_check_all').is(':checked');
		var checkboxes = $('#' + this.gridId).find('.cw_grid_check');
		var i, length = checkboxes.length;
		for (i = 0; i < length; i++) {
			$(checkboxes[i]).prop('checked', checkAll);
		}
		if (checkAll) {
			this.getAllIds();
		} else {
			this.selectedIds = [];
			this.selectedItems = [];
		}
		this.onCheck();
	},
	getAllIds: function () {
		var currentEvents;
		let url = this.requestPath + 'accounts/' + this.instanceConfiguration.accountId + '/summary/eventSummary/selectAll?includeSubaccounts=' + this.instanceConfiguration.includeSubaccounts.toString() + '&timeSelector=' + (this.instanceConfiguration.period || 'NONE');
		Utils.ajax(url, 'POST', JSON.stringify(this.gridPayload), $.proxy(function (result) {
			if (result.success) {
				this.selectedIds = this.selectedIds || [];
				this.selectedItems = this.selectedItems || [];
				for (let i = 0; i < result.data.length; i++) {
					currentEvents = result.data[i].events;
					for (let j = 0; j < currentEvents.length; j++) {
						currentEvents[j].accountId = result.data[i].accountId;
						if (!currentEvents[j].historic) {
							this.selectedIds.push(currentEvents[j].id);
							this.selectedItems.push(currentEvents[j]);
						}
					}
				}
			}
		}, this));
	},

	onCreateIncident: function (e) {
		var checkboxes = $('#' + this.gridId).find('.cw_grid_check');
		var assets = [];
		var accountList = [];
		var reasonsIds = [];
		var found

		if (this.selectedItems) {
			for (let i = 0; i < this.selectedItems.length; i++) {
				if (this.selectedItems[i].type === 'ASSET_HEALTH_REASON') {
					found = false;
					for (var j = 0; j < assets.length; j++) {
						if (assets[j].id === this.selectedItems[i].targetId) {
							found = true;
							break;
						}
					}
					if (!found) {
						assets.push({
							name: this.selectedItems[i].targetName,
							id: this.selectedItems[i].targetId
						});
					}
					found = false;
					for (var j = 0; j < accountList.length; j++) {
						if (accountList[j].id === this.selectedItems[i].accountId) {
							found = true;
							break;
						}
					}
					if (!found) {
						accountList.push({
							id: this.selectedItems[i].accountId,
							name: this.selectedItems[i].accountName
						});
					}
					if (reasonsIds.indexOf(this.selectedItems[i].id) === -1) {
						reasonsIds.push(this.selectedItems[i].id);
					}
				}
			}
		} else {
			for (var i = 0, length = checkboxes.length; i < length; i++) {
				if ($(checkboxes[i]).is(':checked')) {
					var record = this.dataSource.getByUid($(checkboxes[i]).closest('tr').attr('data-uid'));
					if (record.type === 'ASSET_HEALTH_REASON') {
						found = false;
						for (var j = 0; j < assets.length; j++) {
							if (assets[j].id === record.targetId) {
								found = true;
								break;
							}
						}
						if (!found) {
							assets.push({
								name: record.targetName,
								id: record.targetId
							});
						}
						found = false;
						for (var j = 0; j < accountList.length; j++) {
							if (accountList[j].id === record.accountId) {
								found = true;
								break;
							}
						}
						if (!found) {
							accountList.push({
								id: record.accountId,
								name: record.accountName
							});
						}
						if (reasonsIds.indexOf(record.id) === -1) {
							reasonsIds.push(record.id);
						}
					}
				}
			}
		}

		State.mainApp.navigate(IncidentsRouter.createNew('ASSET'), {
			extra: {
				assets: assets,
				accountList: accountList,
				reasonsIds: reasonsIds,
				isFromEventSummary: true
			}
		});
	},

	onAckUnack: function (e) {
		if (!this.grid) {
			return;
		}
		var checkboxes = $('.cw_grid_check'), i, length = checkboxes.length, assetIds = [], assetId, checkbox, item,
			assetName;

		if (this.selectedItems) {
			for (let i = 0; i < this.selectedItems.length; i++) {
				if (assetIds.indexOf(this.selectedItems[i].id) === -1) {
					assetIds.push(this.selectedItems[i].id);
				}
			}
		} else {
			for (i = 0; i < length; i++) {
				checkbox = $(checkboxes[i]);
				item = this.grid.dataSource.getByUid(checkbox.closest('tr').data('uid'));
				assetId = item.id;
				if (checkbox.is(':checked') && assetIds.indexOf(assetId) === -1 /*&& item.type === 'ASSET_HEALTH_REASON'*/) {
					assetIds.push(assetId);
					assetName = item.targetName;
				}
			}
		}
		var target = $(e.currentTarget);
		if (target.attr('id') === 'cw_event_summary_reason_acknowledge') {
			this.acknowledgeReasons(assetIds, assetName);
		} else if (target.attr('id') === 'cw_event_summary_reason_unacknowledge') {
			this.unacknowledgeReasons(assetIds, assetName);
		}
	},

	onResetHIButton: function () {
		var checkboxes = $('#' + this.gridId).find('.cw_grid_check');
		var data = [];

		if (this.selectedItems?.length) {
			for (let selectedItem of this.selectedItems) {
				if (selectedItem.type === 'ASSET_HEALTH_REASON' && this.grid.dataSource.get(selectedItem.id)) {
					data.push({
						monitorId: selectedItem.sourceId,
						assetId: selectedItem.targetId
					});
				}
			}
		} else {
			for (var i = 0, length = checkboxes.length; i < length; i++) {
				if ($(checkboxes[i]).is(':checked')) {
					var record = this.dataSource.getByUid($(checkboxes[i]).closest('tr').attr('data-uid'));
					if (record.type === 'ASSET_HEALTH_REASON') {
						data.push({
							monitorId: record.sourceId,
							assetId: record.targetId
						});
					}
				}
			}
		}
		Utils.resetMonitorsHI(data, $.proxy(function () {
			this.resetCheckboxes();
		}, this));
		//this.resetCheckboxes();
	},
	onUnassign: function () {
		var selectedCheckboxes = $('#' + this.gridId).find('.cw_grid_check:checked');
		var selectedEventIds = [];
		for (var i = 0; i < selectedCheckboxes.length; i++) {
			selectedEventIds.push($(selectedCheckboxes[i]).attr('data-id'));
		}
		var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/summary/eventSummary/unassign?';
		Utils.ajax(url, 'POST', JSON.stringify(this.selectedIds || selectedEventIds), $.proxy(function (result) {
			if (result.success) {
				this.actionNotification.setOptions({
					message: lang.messages.EVENT_SUCCESSFULLY_UNASSIGNED,
					status: 'success'
				}).show();
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
			this.resetCheckboxes();
		}, this));
	},
	onAssign: function (e) {
		const {top, left} = $('#'+this.menuId).offset();
		this.assignWindow = new ModalWindow({
			title: lang.ASSIGN_EVENT,
			width: 400,
			resizable: true,
			minHeight: 200,
			height: 200,
			modal: true,
			actions: [],
			position: {
				top: top + 40,
				left: left + 40
			},
			url: 'include/Summary/AssignEventForm.jsp',
			refresh: $.proxy(function () {
				this.onAssignWindowLoaded();
				$('#cw_assign_event_close').on('click', $.proxy(this.onAssignWindowClose, this));
				$('#cw_assign_event_update').on('click', $.proxy(this.onAssignWindowUpdate, this));
			}, this)
		});
		this.assignWindow.open();
	},
	onAssignWindowLoaded: function () {
		var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/recipients';
		this.assignSelector = $('#cw_assign_event_selector').kendoDropDownList({
			dataSource: new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: url,
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				schema: {
					data: function (response) {
						var result, userTeamArray = [];
						if (response.success) {
							result = response.data;
						} else if (response.length) {
							result = response;
						} else {
							result = [];
						}
						for (var i = 0, length = result.length; i < length; i++) {
							result[i] = {
								text: result[i].name,
								value: result[i].id,
								type: result[i].type
							};
							switch (result[i].type) {
								case 'TEAM':
									result[i].optionGlyphClass = 'parents';
									userTeamArray.push(result[i]);
									break;
								case 'USER':
									result[i].optionGlyphClass = 'user';
									userTeamArray.push(result[i]);
									break;
								case 'EMAIL':
									break;
							}
						}
						return userTeamArray;
					}
				},
				group: {
					field: 'type'
				},
				error: ErrorHandler.kendoServerError
			}),
			dataTextField: 'text',
			dataValueField: 'value',
			optionLabel: ' ',
			optionLabelTemplate: '<span id="cw_assign_placeholder">User/team...</span>',
			valueTemplate: '<span class="glyphicons ${optionGlyphClass}" style="padding-top: 3px"></span><span style="padding-left: 10px">${text}</span>',
			open: $.proxy(function () {
				//overwrite kendo groupable questionable functionality (show different first group; moreover, show groupable row when no data)
				var recipientsOpenedContainer = $('#cw_assign_event_selector-list');
				//hide option label from selection area
				recipientsOpenedContainer.find('.k-list-optionlabel').addClass('hide');
				var recipientsGroupHeader = recipientsOpenedContainer.find('.k-group-header');
				var recipientsNoDataContainer = recipientsOpenedContainer.find('.k-nodata');
				if (recipientsNoDataContainer.attr('style')) {//check for style display:none property put by kendo
					//case when multiselect has data; transform the grouping header row into a normal grouping element
					recipientsGroupHeader.addClass('hide');
					var firstItem = recipientsOpenedContainer.find('li').first();
					firstItem.append('<div class="k-group">' + recipientsGroupHeader.html() + '</div>');
				} else {
					//case when multiselect has no data; hide the grouping header, fix for poor kendo functionality
					recipientsGroupHeader.addClass('hide');
				}
			}, this),
			select: () => {
				$('#cw_assign_event_update').prop('disabled', false);
			},
			dataBound: function () {
				$('#cw_assign_placeholder').css('color', '#b8b8b8');
				$('#cw_assign_event_update').prop('disabled', true);
			}
		}).data('kendoDropDownList');
	},
	onAssignWindowUpdate: function (e) {
		var selectedRecipient = this.assignSelector.value();
		var recipientUrl;
		var selectedCheckboxes = $('#' + this.gridId).find('.cw_grid_check:checked');
		var selectedEventIds = [];
		for (var i = 0; i < selectedCheckboxes.length; i++) {
			selectedEventIds.push($(selectedCheckboxes[i]).attr('data-id'));
		}
		var allRecipients = this.assignSelector.dataSource.data();
		for (var j = 0; j < allRecipients.length; j++) {
			if (selectedRecipient === allRecipients[j].value) {
				if (allRecipients[j].type === 'USER') {
					recipientUrl = 'userId';
				} else if (allRecipients[j].type === 'TEAM') {
					recipientUrl = 'teamId';
				}
			}
		}
		var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/summary/eventSummary/assign?' + recipientUrl + '=' + selectedRecipient;
		Utils.ajax(url, 'POST', JSON.stringify(this.selectedIds || selectedEventIds), $.proxy(function (result) {
			if (result.success) {
				this.actionNotification.setOptions({
					message: lang.messages.EVENT_SUCCESSFULLY_ASSIGNED,
					status: 'success'
				}).show();
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
			this.assignWindow.close();
			this.resetCheckboxes();
		}, this));

	},
	onAssignWindowClose: function () {
		this.assignWindow.close();
	},
	onRemove: function () {
		this.removeSelected();
	},
	removeSelected: async function () {
		let selectedCheckboxes = $('#' + this.gridId).find('.cw_grid_check:checked');
		let uids = [];

		for (let i = 0; i < selectedCheckboxes.length; i++) {
			let checkbox = $(selectedCheckboxes[i]);
			let uid = checkbox.closest('tr').attr('data-uid');
			let record = this.dataSource.getByUid(uid);
			uids.push({
				id: record.id,
				accountId: record.accountId
			});
		}

		if (this.selectedItems) {
			var selected = [];
			for (let i = 0; i < this.selectedItems.length; i++) {
				selected.push({
					accountId: this.selectedItems[i].accountId,
					id: this.selectedItems[i].id
				})
			}
		}

		uids = selected || uids;

		if (!uids) {
			return;
		}

		const url = `${Settings.serverPath}accounts/${Cookies.CeesoftCurrentAccountId}/summary/eventSummary/delete`;
		const {result} = await Utils.ajaxPromise(url, 'POST', JSON.stringify(uids));

		if (result.success) {
			this.actionNotification.setOptions({
				message: lang.messages.REASON_SUCCESSFULLY_REMOVED,
				status: 'success'
			}).show();
			this.grid.dataSource.read();
			this.resetCheckboxes();
		} else {
			Utils.showInfo(lang.ALERT, result.message, result.details);
		}
	},
	onSetInformation(e) {
		const {top, left} = $('#'+this.menuId).offset();
		$('#'+this.id).append('<div id="set-information-window-container"></div>');
		ReactDOM.render(<SetInformationWindow
			onClose={this.onSetInformationClose}
			onUpdate={(value) => this.onSetInformationUpdate(value)}
			top={top + 40}
			left={left + 40}
		></SetInformationWindow>, $('#set-information-window-container').get(0))
	},
	onSetInformationClose() {
		ReactDOM.unmountComponentAtNode($('#set-information-window-container').get(0));
	},
	onSetInformationUpdate(informationValue) {
		let selectedCheckboxes = $('#' + this.gridId).find('.cw_grid_check:checked');
		let selectedIds = [];
		let id;

		if (this.selectedItems) {
			for (let i = 0; i < this.selectedItems.length; i++) {
				id = this.selectedItems[i].id;
				if (id) {
					selectedIds.push(id);
				}
			}
		} else {
			for (let i = 0; i < selectedCheckboxes.length; i++) {
				id = $(selectedCheckboxes[i]).attr('data-id');
				if (id) {
					selectedIds.push(id);
				}
			}
		}

		let url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/summary/eventSummary/information';
		let postObj = {
			eventIds: selectedIds,
			information: informationValue
		};
		Utils.ajax(url, 'POST', JSON.stringify(postObj), $.proxy(function (result) {
			if (result.success) {
				this.actionNotification.setOptions({
					message: lang.messages.INFO_SUCCESSFULLY_UPDATE,
					status: 'success'
				}).show();
				this.grid.dataSource.read();
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
			this.onSetInformationClose();
			this.resetCheckboxes();
		}, this));
	},
	acknowledgeReasons: function (assetIds, assetName) {
		var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/summary/eventSummary/acknowledge';
		Utils.ajax(url, 'POST', JSON.stringify(assetIds), $.proxy(function (result) {
			if (result.success) {
				this.actionNotification.setOptions({
					message: assetIds.length > 1 ? lang.messages.REASONS_ASSETS_ACKNOWLEDGED : kendo.template(lang.messages.REASONS_ACKNOWLEDGED)({'assetName': assetName}),
					status: 'success'
				}).show();
				this.grid.dataSource.read();
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
			this.resetCheckboxes();
		}, this));
	},
	unacknowledgeReasons: function (assetIds, assetName) {
		var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/summary/eventSummary/unacknowledge';
		Utils.ajax(url, 'POST', JSON.stringify(assetIds), $.proxy(function (result) {
			if (result.success) {
				this.actionNotification.setOptions({
					message: assetIds.length > 1 ? lang.messages.REASONS_ASSETS_UNACKNOWLEDGED : kendo.template(lang.messages.REASONS_UNACKNOWLEDGED)({'assetName': assetName}),
					status: 'success'
				}).show();
				this.grid.dataSource.read();
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
			this.resetCheckboxes();
		}, this));
	},
	resetCheckboxes: function() {
		$('#' + this.gridId).find('.cw_grid_check').prop('checked', false);
		$('#' + this.gridId).find('.cw_grid_check_all').prop('checked', false);
		State.markedCheckboxes = [];
		this.selectedItems = [];
		this.selectedIds = [];
	},

	onCreateFilter: function (e) {
		var checkbox = $('#' + this.gridId).find('.cw_grid_check:checked');
		var record = this.dataSource.getByUid($(checkbox).closest('tr').attr('data-uid'));
		State.mainApp.loadModule('AssetHealthFiltersConfiguration', '', {
			mode: 'create',
			populate: {
				monitorId: record.sourceId,
				monitorType: record.monitorType,
				severity: record.severity,
				reasonDescription: record.message,
				reasonSubsytem: record.subsystem,
				identifierName: record.identifierName || '',
				identifierInstance: record.identifierInstance || '',
				accountId: record.accountId,
				accountName: record.accountName
			}
		}, e);
	},

	onExportCsv: function () {
		let url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/summary/eventSummary/exportCSV';
		url += '?includeSubaccounts=' + this.instanceConfiguration.includeSubaccounts.toString() + '&timeSelector=' + (this.instanceConfiguration.period || 'NONE');
		let excludedColumns = ['action'];
        Utils.exportGridCsv(this.grid, url, excludedColumns);
	},
	sendMultipleWinServiceAction: function (command, events) {
		if (command && events) {
			var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/monitors/WINSERVICE/multipleServiceCommand/' + command;
			Utils.ajax(url, 'POST', JSON.stringify(events), $.proxy(function (result) {
				if (result.success) {
					this.actionNotification.setOptions({
						message: result.message,
						status: 'info'
					}).show();
				} else {
					this.actionNotification.setOptions({
						message: result.message,
						status: 'error'
					}).show();
				}
				this.resetCheckboxes();
			}, this));
		}
	},
	_getCheckedEvents: function () {
		let selectedCheckboxes = $('#' + this.gridId).find('.cw_grid_check:checked');
		let events = [];

		if (this.selectedItems) {
			for (let i = 0; i < this.selectedItems.length; i++) {
				if (this.allowedIdentifiers[this.selectedItems[i].identifierName]) {
					events.push({
						assetId: this.selectedItems[i].targetId,
						monitorId: this.selectedItems[i].sourceId,
						identifierName: this.selectedItems[i].identifierName,
						identifierInstance: this.selectedItems[i].identifierInstance
					});
				}
			}
		} else {
			for (let i = 0; i < selectedCheckboxes.length; i++) {
				let checkbox = $(selectedCheckboxes[i]);
				let uid = checkbox.closest('tr').attr('data-uid');
				let record = this.dataSource.getByUid(uid);
				if (this.allowedIdentifiers[record.identifierName]) {
					events.push({
						assetId: record.targetId,
						monitorId: record.sourceId,
						identifierName: record.identifierName,
						identifierInstance: record.identifierInstance
					});
				}
			}
		}
		return events;
	},
	onStartService: function () {
		this.sendMultipleWinServiceAction('START', this._getCheckedEvents());
	},
	onStopService: function () {
		this.sendMultipleWinServiceAction('STOP', this._getCheckedEvents());
	},
});
