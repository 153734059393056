import Utils from 'tools/utils'
import State from 'tools/state'


export let GridMenu = function (config) {
	Utils.apply(this, config);
	this.initComponent();
};

export default GridMenu;

GridMenu.AtLeastOneItemSelected = '1';
GridMenu.ExactlyOneItemSelected = '2';
GridMenu.AtLeastTwoItemsSelected = '3';

GridMenu.prototype = {
	/**
	 * @config {String} renderTo The selector of the tag where the menu is rendered
	 */
	/**
	 * @config {Array} items Array of objects containing items properties : id,
	 * icon, text, fn, scope, disabled, role
	 */

	initComponent: function () {
		if( typeof this.renderTo == 'string'){
			this.renderTo = '#' + this.renderTo;
			this.root = $(this.renderTo);
		}else{
			this.root = this.renderTo;
		}


		var glyphClass = 'menu-hamburger';
		var htmlCode = '<button class="k-button k-button-icon cw_actions_trigger"><span class="glyphicons ' + glyphClass + ' left"></span></button>';
		htmlCode += '<span class="cw_actions_wrapper left">';
		htmlCode += '<ul class="cw_actions_menu"></ul></span>';
		this.root.append(htmlCode);

		if (this.size === 'large') {
			this.root.find('.cw_actions_menu').css('width', '300px');
		}

		this.itemList = [];

		if( this.sortingDisabled != true) {
			this.items.sort(this.compare);
		}

		this.addItems();

		this.removeListeners();
		this.attachListeners();
		this.updateTopButton();
	},

	addItems() {
		const genericItemsText = [lang.CREATE, lang.DELETE, lang.UPDATE, lang.EDIT];
		let genericItems = [];
		let customItems = [];
		for (let i = 0, length = this.items.length; i < length; i++) {
			if (genericItemsText.indexOf(this.items[i].text) !== -1) {
				genericItems.push(this.items[i]);
			} else {
				customItems.push(this.items[i]);
			}
		}
		for (let genericItem of genericItems) {
			if (genericItem === genericItems[genericItems.length - 1] && customItems.length) {
				genericItem.dividerClass = 'double_divider';
			}
			this.addItem(genericItem);
		}
		for (let customItem of customItems) {
			this.addItem(customItem);
		}
	},

	removeListeners: function () {
		this.root.find('.cw_actions_trigger').off('click.grid_menu');
		window.removeEventListener('click', $.proxy(this.onWindowClick, this))
	},

	attachListeners: function () {
		this.root.find('.cw_actions_trigger').on('click.grid_menu', $.proxy(this.onActionButton, this));
		window.addEventListener('click', $.proxy(this.onWindowClick, this))
	},

	setGrid(grid){
		//this.removeListeners();
		this.grid = grid;
		//this.attachListeners();
		//this.onGridSelectionChanged();
	},

	compare: function (a, b) {
		if ((a.text !== lang.CREATE ) && a.text !== lang.DELETE && a.text !== lang.UPDATE && a.text !== lang.EDIT) {
			if (a.text < b.text)
				return -1;
			if (a.text > b.text)
				return 1;
			return 0;
		}
	},
	/**
	 * Handler function for the click event on the action button
	 * @param e
	 */
	onActionButton: function (e) {
		this.onGridSelectionChanged();
		if (this.onActionButtonCustom) {
			this.onActionButtonCustom(e);
		}
		e.stopPropagation();
		//temporary? close the others opened menus
		if (this.isFromDesigner) {
			$('.cw_actions_menu').removeClass('is_open');
		}
		var target = $(e.currentTarget);
		if (target.parent().find('.cw_actions_menu').length) {
			target.parent().find('.cw_actions_menu').toggleClass('is_open');
		} else {
			target.parent().toggleClass('is_open');
		}

		target.toggleClass('is_selected');
		//temporary
		if (this.isFromDesigner) {
			target.parent().find('.cw_actions_menu').css('top', '-24px').css('left', '28px');
			$('#left_panel').css('overflow', 'initial');
		}
	},

	onWindowClick: function(e){
		const menuContainer = this.root.find('.cw_actions_trigger')[0];
		const menuContent = this.root.find('.cw_actions_menu');
		if (!menuContainer?.contains(e.target) && menuContent.hasClass('is_open')){
			menuContent.removeClass('is_open');
		}
	},
	/**
	 * Disables a menu item
	 * @param {String} id The id of the item
	 */
	disableItem: function (id) {
		this.root.find('#' + id).addClass('is_disabled');
		this.updateTopButton();
	},
	/**
	 * Enables a menu item
	 * @param {String} id The id of the item
	 */
	enableItem: function (id) {
		let item = this.getItem(id);
		if( item == null )
			return;

		if (!item.role || State.mainApp.session.hasRole(item.role)) {
			this.root.find('#' + id).removeClass('is_disabled');
		} else {
			this.root.find('#' + id).addClass('is_disabled');
		}

		this.updateTopButton();
	},

	updateTopButton: function () {
		//TODO: there is an issue when user clicks on menu and all items are disabled
		//then menu becomes disabled completly and cant be closed
		return;

		var enable = false, length;
		for (var i = 0, length = this.items.length; i < length; i++) {
			if (!$('#' + this.items[i].id).hasClass('is_disabled')) {
				enable = true;
			}
		}
		this.enable(enable);
	},

	enable: function (enable) {
		if (enable) {
			this.root.find('.k-button:first').removeAttr('disabled');
		} else {
			this.root.find('.k-button:first').attr('disabled', 'disabled');
		}
	},

	enableItems: function (enable) {
		if (enable) {
			this.root.find('.cw_actions_menu').find('.cw_item').removeClass('is_disabled');
		} else {
			this.root.find('.cw_actions_menu').find('.cw_item').addClass('is_disabled');
		}
	},

	setItemText: function (id, text) {
		this.root.find('.cw_actions_menu').find('#' + id).find('.item-label').text(text);;
	},

	removeItem: function (id) {
		let listItem = this.root.find('#' + id),
			index = this.indexOfItem(id);
		if (index !== -1 && listItem.length) {
			this.itemList.splice(index, 1);
			listItem.off();
			listItem.remove();
		}
	},
	/**
	 * Adds a menu item
	 * @param {Object} item The item to be added
	 * @param {number} position The position of the item is optional (first is 1)
	 */
	addItem: function (menuItem, position) {
		if( menuItem.id == null ){
			menuItem.id = Utils.guid();
		}
		var list = this.root.find('ul'),
			leftSiblingPosition = 0,
			length = list.length,
			itemCode = '';

		itemCode += '<li id="' + menuItem.id + '" data-type="' + menuItem.dataType + '"  class="cw_item' + (menuItem.dividerClass ? ' ' + menuItem.dividerClass : '') + '">'; // adds divider line
		if (menuItem.iconType === 'material') {
			itemCode += '<i data-type="icon" data-icon-pack="material" data-id="' + menuItem.icon + '" class="uploaderGlyphIcon item material-icons">' + menuItem.icon + '</i>';
		} else {
			itemCode += '<span class="glyphicons ' + menuItem.icon + '"></span>';
		}
		itemCode += '<span class="item-label">' + menuItem.text + '</span>';

		if (menuItem.tooltip) {
			itemCode += '<span class="glyphicons question-sign grid-menu-tooltip" title="' + menuItem.tooltip + '"></span>';
		}

		itemCode += '</li>';

		// adds the menu item code to the list
		if (!position) {
			list.append(itemCode);
			this.itemList.push(menuItem);
		} else if (position === 1) {
			list.prepend(itemCode);
			this.itemList.splice(0, 0, menuItem);
		} else {
			leftSiblingPosition = position - 1;
			list.find('li:nth-child(' + leftSiblingPosition + ')').after(itemCode);
			if (this.itemList.length >= position) {
				this.itemList.splice(leftSiblingPosition, 0, menuItem);
			} else {
				this.itemList.push(menuItem);
			}
		}
		// adds the click listeners for the menu item
		if (menuItem.disabled || (menuItem.role && !State.mainApp.session.hasRole(menuItem.role))) {
			list.find('#' + menuItem.id).addClass('is_disabled');
		}
		list.find('#' + menuItem.id).off().on('click', $.proxy(function (e) {
			if (!$(e.currentTarget).hasClass('is_disabled')) {
				this.onActionButton(e);
				var item = this.getItem($(e.currentTarget).attr('id'));
				if (item.fn) {
					item.fn.call(item.scope, e);
					if (item.deleteFromGrid && !$('.cw_dialog_content').length) {
						let gridContainer = $('#' + item.deleteFromGrid).length ? $('#' + item.deleteFromGrid) : $('.' + item.deleteFromGrid);
						if (gridContainer.length) {
							let grid = gridContainer.data('kendoCustomGrid');
							kendo.ui.progress(grid.wrapper, true);
						}
					}
					$(e.currentTarget).closest('.cw_actions_menu').removeClass('is_open');
				}
			}
		}, this));
	},

	getItem: function (id) {
		return this.itemList.find(o => o.id == id );
	},

	indexOfItem: function (id) {
		return this.itemList.findIndex( o => o.id == id );
	},

	onGridSelectionChanged(){
		if(!this.grid)
			return;

		var count = $('.cw_grid_check:checked', this.grid.element).length;
		this.setRowsSelectedCount(count);
	},

	setRowsSelectedCount(count){
		//TODO: when you click on Select all then
		//then onGridSelectionChanged fires before any item actually selected
		for( let item of this.itemList ){
			switch(item.enabledIf){
				case GridMenu.AtLeastOneItemSelected:
					if( count != 0 ){
						this.enableItem(item.id);
					}else{
						this.disableItem(item.id);
					}
					break;

				case GridMenu.ExactlyOneItemSelected:
					if( count == 1 ){
						this.enableItem(item.id);
					}else{
						this.disableItem(item.id);
					}
					break;
				case GridMenu.AtLeastTwoItemsSelected:
					if( count > 1 ){
						this.enableItem(item.id);
					}else{
						this.disableItem(item.id);
					}
					break;
			}
		}
	}
}
