import './datasourceProperties.less';

import React, {useCallback, useEffect, useState, useReducer, useMemo} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import DropDownList from 'controls/react/kendoWrappers/dropDownList';
import MultiSelectV2 from 'controls/react/form/multiSelectV2';
import {Checkbox, FormEntry, IconButton, Button, ContextField, Switch} from 'controls/react/form';
import {Section} from 'controls/react/layout/section';


import SelectionLabel from './selectionLabel';
import DataSourceWindow from './datasourceWindow';
import {serviceColumns, serviceElementColumns, getServiceQualifierColumns} from './datasources/serviceColumns';
import assetColumns from './datasources/assetColumns';
import slaColumns from './datasources/slaColumns';
import monitorColumns from './datasources/monitorColumns';

import {useDynamicDataSource} from 'areas/dashboards/graph-editor-extensions/datasources/useDynamicDataSource';
import {useMonitorsDataSource} from 'areas/dashboards/graph-editor-extensions/datasources/useMonitorsDataSource';
import {useServiceElementsDataSource} from 'areas/dashboards/graph-editor-extensions/datasources/useServiceElementsDataSource';
import {useServiceQualifiersDataSource} from 'areas/dashboards/graph-editor-extensions/datasources/useServiceQualifiersDataSource';
import {getEntityDataSource} from 'areas/dashboards/graph-editor-extensions/datasources/entityDataSource';
import {ServicesApi} from 'areas/services/api';
import {AssetsApi} from 'areas/assets';
import {SlaApi} from 'areas/sla/api';
import {dialogAsync, questionDialogAsync} from 'controls/dialog';
import {AssetGroupAPI} from 'tools/apis';
import {Api, useRemoteList} from 'tools/api'

import {translator} from 'core/localization/localization';
import {i18n} from './i18n'

import AccountDropDown, {createAccountsDatasource} from 'controls/react/dropdowns/accountDropDown';
import {AccountsApi} from 'areas/administration/accounts/api';
import Cookies from 'core/cookies';
import {TextBox} from 'controls/react/form/textBox';
import {MetricWindow} from 'areas/service-boards/widgets/common/metricWindow';
import NumericTextBox from 'controls/react/form/numericTextBox';
import {Toolbar} from 'controls/react/layout';
import {indicatorToState, StateColors} from 'tools/states';
import {KpiApi} from 'areas/kpi/api';
import {AntSelect} from 'controls/react/ant/antSelect';
import {useStateLink} from 'core/react/links';
import totalMetricDataSource, {TOTAL_METRIC_TYPE} from './datasources/totalMetricDataSource';
import displayUnitDataSource, {DISPLAY_UNIT} from './datasources/displayUnitDataSource';
import displayLabelDataSource, {DISPLAY_LABEL} from './datasources/displayLabelDataSource';
import {AntCheckbox} from "controls/react/ant/antCheckbox";
import {CloudBillingMonitorsApi, useCloudTypes} from "areas/assets/monitors/cloud-billing/api";
import Settings from "settings";
import { RedirectToConfiguration } from "controls/designer/features/redirectOnClick/redirectToConfiguration";
import { filterAndUpdateArraysOnPaths, byParentIdFilterFn } from "../../../tools/utils";
import {useMetricName} from 'controls/react/metricsSelector'
import {CostDatasource} from "./costDatasource";

const i = translator({
  "This will remove the current datasource selection type": {
    "no": "Dette vil fjerne gjeldende datakilde"
  },
  "Custom link": {
    "no": "Tilpasset link"
  },
  "Custom link tooltip": {
    "en": "Setting an custom URL will override the link to the datasource. Thus the user is redirected to the URL when clicking this shape in a serviceboard.",
    "no": "Om du angir en tilpasset URL vil overstyre lenken til datakilden. Brukeren går til URLen når han klikker denne formen i en tjenestetavle."
  },
  "Show unit": {
    "no": "Vis enhet"
  },
  "Decimals": {
    "no": "Desimal"
  },
  "Open custom link in a new tab": {
    "no": "Åpne tilpasset link i ny fane"
  },
  "Metric value as severity": {
    "no": "Metrikkverdi som alvorlighet"
  },
  "Value to severity": {
    "no": "Verdi til alvorlighet"
  },
  "Select a profile...": {
    "no": "Velg en profil..."
  },
  "Select a category...": {
    "no": "Velg en kategori..."
  },
  "Select a rule type...": {
    "no": "Velg en regeltype..."
  },
  "Rule type": {
    "no": "Regeltype"
  },
  "Select a sample rate...": {
    "no": "Velg tid"
  },
  "Sample rate": {
    "no": "tid"
  },
  "Select a KPI account...": {
    "no": "Velg KPI konto"
  },
  "KPI account": {
    "no": "KPI konto"
  },
  "Total metric": {
    "no": "Total metrikk"
  },
  "User Input": {
    "no": "Bruker inndata"
  },
  "Display unit": {
    "no": "Visningsenhet"
  },
  "Display label": {
    "no": "Visningsnavn"
  },
  "none-total-metric-tooltip": {
    "en": "Metric is displayed as normal",
    "no": "Metrikker vises som normalt"
  },
  "metric-total-metric-tooltip": {
    "en": "You can select a second metric. This metric will be placed next to the metric selected above.",
    "no": "Du kan velge en ekstra metrikk. Denne Metrikken plasseres ved siden av den første metrikken."
  },
  "user_input-total-metric-tooltip": {
    "en": "You can input a string. The string will be placed next to the first metric. Eg. 100%.",
    "no": "Du kan legge inn en streng, den blir plassert ved siden av den første metrikken. F.eks. 100%."
  },
  "NONE-display-unit-tooltip": {
    "en": "We dont display unit",
    "no": "Vi viser ikke enhet"
  },
  "METRIC_UNIT-display-unit-tooltip": {
    "en": "We use the unit of the selected metric",
    "no": "Vi bruker enheten til den valgte metrikken."
  },
  "CUSTOM_UNIT-display-unit-tooltip": {
    "en": "You can input a custom unit to be displayed",
    "no": "Du kan legge inn en tilpasset enhet."
  },
  "TOTAL_UNIT-display-unit-tooltip": {
    "en": "We use the unit of the Total metric. This is only available if total metric is selected",
    "no": "Vi bruker enheten til Total metrikken. Dette er bare tilgjengelig hvis total metrikk er valgt."
  },
  "NONE-display-label-tooltip": {
    "en": "No label is set. You can still add a custom label by double clicking the shape.",
    "no": "Navn settes ikke. Du kan fortsatt legge til et navn ved å dobbeltklikke på formen."
  },
  "TOTAL_DISPLAY_LABEL-display-label-tooltip": {
    "en": "We use the name of the Total metric in the shape.",
    "no": "Vi bruker navnet på Total metrikk i formen."
  },
  "METRIC_DISPLAY_LABEL-display-label-tooltip": {
    "en": "We use the name of the metric in the shape.",
    "no": "Vi bruker navnet på metrikken i formen."
  },
  "decimal-tooltip": {
    "en": "Number of decimals we will display the metrics with. If empty we display the metrics as is.",
    "no": "Antall desimaler vi viser metrikken med. Hvis det er tomt, viser vi metrikken som den er."
  },
  "metric-value-as-severity-tooltip": {
    "en": "If selected you can use the metric value to custom set severity of the shape. The severity will match the first value that matches.",
    "no": "Hvis dette er valgt, kan du bruke metrikkverdien til å sette formens alvorlighetsgrad. Alvorlighetsgraden vil matche den første verdi som samsvarer."
  },
  "Show Health reasons": {
    "no": "Vis årsåker"
  },
  "Show summary information": {
    "no": "Vis sammendragsinformasjon"
  },
  "Open link in a new tab": {
    "no": "Åpne link i ny fane"
  },
  "To set severity for the values above set the below numbers in the inputs. \n\nCurrent configuration: \n": {
    "no": "For å angi alvorlighetsgrad for verdiene ovenfor, sett inn verdier under. \n\nGjeldende konfigurasjon: \n"
  },
  "Everything will give a CRITICAL in the shape": {
    "no": "Alt vil gi en KRITISK i denne formen"
  },
  "Everything above": {
    "no": "Alt over"
  },
  "will give a CRITICAL in the shape": {
    "no": "Vil gi en KRITISK i denne formen"
  },
  "Select metric placeholder": {
    "en": "Select a metric",
    "no": "Velg en metrikk"
  },
  "Custom unit": {
    "no": "Tilpasset enhet"
  },
  "Hide metric value": {
	  "no": "Skjul metrisk verdi"
  }
}, i18n);

export const DataSourceAttributes = Object.freeze({
	ReadOnly: 'readOnlyDataSource',
	Disabled: 'disableDataSourceSettings',
	HealthIndexMode: 'healthIndexMode',
	ApplicationMode: 'applicationMode'
})

const b = require('b_').with('data-source-properties');

async function loadMetricNames(metrics) {
	const response = await Api.fetchPost(Api.accountRoot() + 'metrics/registeredMetrics', metrics.map(x => x.id))
	return response.length ? response[0].assetName + '/' + response[0].instanceName : [];
}

export function DatasourceProperties(props) {
	if(props.ui.editor == null)
		return null;

	const cell = props.ui.editor.graph.getSelectionCell();

	const datasourceLink = useStateLink(cell.initDefaultDatasource(props.datasource));
	const redirectConfigLink = useStateLink(props.redirectConfig);
	useEffect(() => {
		props.onRedirectConfigChange(redirectConfigLink.value);
	}, [redirectConfigLink])

	const stateLink = useStateLink({
		link: datasourceLink.value.link,
		metricValueToSeverity: datasourceLink.value.metricValueToSeverity,
		totalMetricCustomValue: datasourceLink.value.totalMetricCustomValue,
		customUnit: datasourceLink.value.customUnit
	});

	const closeWindow = useCallback( () => {
		stateLink.get('windowState').update(WINDOW_STATE.HIDDEN);
	}, [stateLink.value]);

	const totalMetricName = useMetricName(datasourceLink.value.metric.totalMetricId);
	const metricName = useMetricName(datasourceLink.value.metric.id);

	const accountsFilter = useMemo(
		() => ({
			logic: 'or',
				filters: datasourceLink.value.accounts.map( x => ({field: 'accountId', operator: 'eq', value: x}))
		}),
		[datasourceLink.value.accounts.join()]);

	const accountDataSource = useMemo(() => createAccountsDatasource(), [Cookies.CeesoftCurrentAccountId]);

	const serviceDataSource = useDynamicDataSource(ServicesApi.getDynamicSearchUrl(), accountsFilter, {pageSize: 9999});
	const serviceElementDataSource = useServiceElementsDataSource(datasourceLink.value.services);

	const serviceQualifiersDataSource = useServiceQualifiersDataSource(datasourceLink.value.services, datasourceLink.value.serviceElements);

	const assetGroupsDataSource = useDynamicDataSource(AssetGroupAPI.getDynamicSearchUrl(), accountsFilter, {pageSize: 9999});

	const assetsDataSource = useDynamicDataSource(AssetsApi.getDynamicSearchUrl('true'), accountsFilter, {pageSize: 9999});
	const monitorDataSource = useMonitorsDataSource(datasourceLink.value.assets);

	const slaDataSource = useDynamicDataSource(SlaApi.getDynamicSearchUrl('true'), accountsFilter, {pageSize: 9999});

	useEffect(() => {
		props.onChange(datasourceLink.value);
	}, [datasourceLink.value]);

	const updateDatasourceAndCloseWindow = useCallback((name, value) => {
		datasourceLink.get(name).update(value);
		stateLink.get('windowState').update(WINDOW_STATE.HIDDEN);

	}, [datasourceLink.value])

	const onSelectDataSourceType = useCallback(async e => {
		const newDatasource = cell.initDefaultDatasource({
			type: e.dataItem.value
		});

		datasourceLink.update(newDatasource);
	}, []);

	const multiselectItemTemplate = useMultiselectItemtemplate();

	const updateSelectedMetrics = useCallback(ids => {
		datasourceLink.get('metric.id').update(ids.length ? ids[0] : null);
		stateLink.get('windowState').update(WINDOW_STATE.HIDDEN);
	}, []);

	const updateTotalMetric = useCallback(ids => {
		datasourceLink.get('metric.totalMetricId').update(ids.length ? ids[0] : null);
		stateLink.get('windowState').update(WINDOW_STATE.HIDDEN);
	}, [])

	const getOperator = useCallback(() => {
		return datasourceLink.value.metricValueToSeverity[0].operator == 'lte' ? ">" : "<"
	},[datasourceLink.value.metricValueToSeverity]);

	const flipOperater = useCallback(() => {
		const operators = {'lte': 'gte', 'gte': 'lte'};

		let map = JSON.parse(JSON.stringify(datasourceLink.value.metricValueToSeverity));
		map.forEach(x => {
			x.operator = (operators[x.operator] || 'lte')
		});

		datasourceLink.get('metricValueToSeverity').update(map);
	}, [datasourceLink.value.metricValueToSeverity]);

	const totalMetricDS = React.useMemo(() => totalMetricDataSource(), [datasourceLink.value.metric.totalMetricType]);

	const displayUnitDS = React.useMemo(() => displayUnitDataSource(datasourceLink.value.metric.totalMetricType),
		[datasourceLink.value.metric.totalMetricType]);

	const displayLabelDS = React.useMemo(() => displayLabelDataSource(datasourceLink.value.metric.totalMetricType),
		[datasourceLink.value.metric.totalMetricType]);

	datasourceLink.get('metric.totalMetricType')
		.changing(({rootStore, value}) => {
			const metric = rootStore.metric;
			switch (value) {
				case TOTAL_METRIC_TYPE.USER_INPUT:
					metric.totalMetric = 0;

					if (metric.displayUnitType == DISPLAY_UNIT.TOTAL_UNIT) {
						metric.displayUnitType = DISPLAY_UNIT.METRIC_UNIT;
					}
					break;

				case TOTAL_METRIC_TYPE.NONE:
					metric.totalMetric = null;

					if (metric.displayUnitType == DISPLAY_UNIT.TOTAL_UNIT) {
						metric.displayUnitType = DISPLAY_UNIT.METRIC_UNIT
					}
					break;

				case TOTAL_METRIC_TYPE.METRIC:
					metric.totalMetric = null;
					break;
			}
		});

	datasourceLink.get('accounts').changing(({rootStore, value}) => {
		rootStore.metric.id = null;
		rootStore.metric.totalMetricId = null;
	});

	datasourceLink.get('services').changing(({rootStore, value, initialValue, e}) => {
		const validService = byParentIdFilterFn(value, 'serviceId')
		filterAndUpdateArraysOnPaths(rootStore, ['serviceElements', 'serviceQualifiers'], validService)
	});

	datasourceLink.get('serviceElements').changing(({rootStore, value, initialValue, e}) => {
		const validServiceElement = byParentIdFilterFn(value, 'serviceElementId')
		filterAndUpdateArraysOnPaths(rootStore, ['serviceQualifiers'], validServiceElement)
	})

	const copyMetricStateToDatasource = useCallback(() => {
		datasourceLink.get('metricValueToSeverity').update(stateLink.value.metricValueToSeverity)
	}, [stateLink.value.metricValueToSeverity]);

	const severityTooltip = React.useMemo(() => {
		if (!datasourceLink.value.metricValueAsSeverity) {
			return '';
		}

		const severityLevels = ['OK', 'Minor', 'Major', 'Critical']

		const severityMessages = [i('To set severity for the values above set the below numbers in the inputs. \n\nCurrent configuration: \n')];

		let first = null;
		let last = null;
		let operator = null;
		let previousThreshold = null;

		stateLink.value.metricValueToSeverity.forEach((severity, index) => {
			operator = severity.operator;

			if (severity.threshold === '') {
				return;
			}

			last = severity.threshold;

			const severityName = i(severityLevels[index]);
			const operatorStr = severity.operator === 'lte' ? '>' : '<';

			if (first === null && operator === 'gte') {
				severityMessages.push(i('Everything above') + ' ' + severity.threshold + ' ' + i('will give a OK in the shape'));
				first = severity.threshold;
			} else {
				severityMessages.push(severityName + ' ' + '<' + ' ' + (operator === 'gte' ? previousThreshold: severity.threshold));
			}

			previousThreshold = severity.threshold;
		});

		if (last === null) {
			severityMessages.push(i('Everything will give a CRITICAL in the shape'))
		}
		else if (operator === 'gte') {
			severityMessages.push(i('Critical') + ' < ' + last)
		}
		else if (operator === 'lte') {
			severityMessages.push(i('Everything above') + ' ' + last + ' ' + i('will give a CRITICAL in the shape'))
		}

		return severityMessages.join('\n');
	}, [datasourceLink.value.metricValueAsSeverity, stateLink.value.metricValueToSeverity])

	if(cell.getAttribute('applicationMode')){
		return <ApplicationDatasource datasourceLink={datasourceLink}
		                              stateLink={stateLink}
		                              assetsDataSource={assetsDataSource}
		                              assetGroupsDataSource={assetGroupsDataSource}
		                              {...props}/>
	}


	return <div className={b()}>
		<FormEntry label={i('Datasource type')}>
			<DropDownList dataSource={getEntityDataSource()}
			              value={datasourceLink.value.type}
			              disabled={props.readOnly}
			              onSelect={onSelectDataSourceType} />
		</FormEntry>

		<FormEntry label={i('Account')} vertical>
			{(datasourceLink.value.type != 'metric' && datasourceLink.value.type != 'kpi') &&
				<MultiSelectV2 dataSource={accountDataSource}
				               {...datasourceLink.get('accounts').props}
				               disabled={props.readOnly}/>
			}
			{(datasourceLink.value.type == 'metric' || datasourceLink.value.type == 'kpi') &&
				<AccountDropDown value={datasourceLink.value.accounts.length > 0 ? datasourceLink.value.accounts[0] : ""}
				                 disabled={props.readOnly}
				                 onChange={v => datasourceLink.get('accounts').update([v])}/>
			}
		</FormEntry>
		{datasourceLink.value.type == 'service' &&
			<>
				<FormEntry label={i('Service')} vertical={true}>
					{!props.readOnly &&
						<IconButton iconName={"show-thumbnails"}
					            embedded={true}
					            onClick={() => stateLink.get('windowState').update(WINDOW_STATE.SERVICES)}/>
					}
					<MultiSelectV2 dataSource={serviceDataSource}
					               disabled={props.readOnly}
					               itemTemplate={multiselectItemTemplate}
					               fullItems={true}
					               {...datasourceLink.get('services').props} />
				</FormEntry>

				<FormEntry label={i('Service element')} vertical={true}>
					{!props.readOnly &&
						<IconButton iconName={"show-thumbnails"} embedded={true}
					            onClick={() => stateLink.get('windowState').update(WINDOW_STATE.ELEMENTS)}/>
					}
					<MultiSelectV2 dataSource={serviceElementDataSource}
					               disabled={props.readOnly}
					               {...datasourceLink.get('serviceElements').props}
					               fullItems={true} />
				</FormEntry>

				<FormEntry label={i('Service qualifier')} vertical={true}>
					{!props.readOnly &&
						<IconButton iconName={"show-thumbnails"} embedded={true}
					            onClick={() => stateLink.get('windowState').update(WINDOW_STATE.QUALIFIERS)}/>
					}
					<MultiSelectV2 dataSource={serviceQualifiersDataSource}
					               disabled={props.readOnly}
					               {...datasourceLink.get('serviceQualifiers').props}
					               fullItems={true} />
				</FormEntry>
			</>
		}

		{datasourceLink.value.type == 'asset' &&
			<>
				<FormEntry label={i('Asset')} vertical={true}>
					{!props.readOnly &&
						<IconButton iconName={"show-thumbnails"} embedded={true}
					            onClick={() => stateLink.get('windowState').update(WINDOW_STATE.ASSET)}/>
					}
					<MultiSelectV2 dataSource={assetsDataSource}
					               itemTemplate={multiselectItemTemplate}
					               fullItems={true}
					               {...datasourceLink.get("assets").props}/>
				</FormEntry>

				<FormEntry label={i('Monitorfilter')} vertical={true}>
					{!props.readOnly &&
						<IconButton iconName={"show-thumbnails"} embedded={true}
					            onClick={() => stateLink.get('windowState').update(WINDOW_STATE.MONITOR)}/>
					}
					<MultiSelectV2 dataSource={monitorDataSource}
					               {...datasourceLink.get('monitors').props}
					               fullItems={true} />
				</FormEntry>
			</>
		}

		{datasourceLink.value.type == 'kpi' &&
		<>
			<KpiDatasource datasource={datasourceLink}/>
		</>
		}

		{datasourceLink.value.type == 'assetGroup' &&
			<FormEntry label={i('Asset group')} vertical={true}>
				{!props.readOnly &&
					<IconButton iconName={"show-thumbnails"} embedded={true}
				            onClick={() => stateLink.get('windowState').update( WINDOW_STATE.ASSET_GROUP)}/>
				}
				<MultiSelectV2 dataSource={assetGroupsDataSource}
				               fullItems={true}
				               itemTemplate={multiselectItemTemplate}
				               {...datasourceLink.get('assetGroups').props} />
			</FormEntry>
		}

		{datasourceLink.value.type == 'sla' &&
			<FormEntry label={i('SLA')} vertical={true}>
				{!props.readOnly &&
					<IconButton iconName={"show-thumbnails"} embedded={true}
				            onClick={() => stateLink.get('windowState').update(WINDOW_STATE.SLA)}/>
				}
				<MultiSelectV2 dataSource={slaDataSource}
				               fullItems={true}
				               itemTemplate={multiselectItemTemplate}
				               {...datasourceLink.get('slas').props} />
			</FormEntry>
		}

		{datasourceLink.value.type == 'metric' && <>
			<FormEntry label={i('Metric')} vertical={true}>
				{!props.readOnly &&
					<IconButton iconName={"show-thumbnails"} embedded={true}
				                onClick={() => stateLink.get('windowState').update(WINDOW_STATE.METRICS)}/>
				}
				<TextBox value={metricName || i('Select metric placeholder')} readOnly={true}/>
			</FormEntry>

			<FormEntry label={i('Total metric')}>
				<IconButton embedded={true}
				            iconName={'question-sign'}
				            title={i(datasourceLink.value.metric.totalMetricType + '-total-metric-tooltip')}/>
				<DropDownList dataSource={totalMetricDS}
				              {...datasourceLink.get('metric.totalMetricType').props}
				              disabled={props.readOnly}/>
			</FormEntry>

			{datasourceLink.value.metric.totalMetricType === TOTAL_METRIC_TYPE.METRIC &&
				<FormEntry label={i('Total metric')} vertical={true}>
					{!props.readOnly && <IconButton iconName={'show-thumbnails'} embedded={true}
													onClick={() => stateLink.get('windowState').update(WINDOW_STATE.TOTAL_METRIC)}/>
					}
					<TextBox value={totalMetricName || i('Select metric placeholder')} readOnly={true}/>
				</FormEntry>
			}

			{datasourceLink.value.metric.totalMetricType === TOTAL_METRIC_TYPE.USER_INPUT &&
				<FormEntry label={i('User Input')}>
					<TextBox disabled={props.readOnly}
					         {...stateLink.get('totalMetricCustomValue').props}
					         onBlur={() => datasourceLink.get('metric.totalMetricCustomValue').update(stateLink.value.totalMetricCustomValue)}/>
				</FormEntry>
			}

			<FormEntry label={i('Decimals')}>
				<IconButton embedded={true}
							iconName={'question-sign'}
							title={i('decimal-tooltip')}/>
				<NumericTextBox disabled={props.readOnly}
				                spinners={true}
				                {...datasourceLink.get('decimalsNumber').props} />
			</FormEntry>

			<FormEntry label={i('Display unit')}>
				<IconButton embedded={true}
				            iconName={'question-sign'}
				            title={i(datasourceLink.value.metric.displayUnitType + '-display-unit-tooltip')}/>
				<DropDownList dataSource={displayUnitDS}
				              {...datasourceLink.get('metric.displayUnitType').props}
				              disabled={props.readOnly}/>
			</FormEntry>

			{datasourceLink.value.metric.displayUnitType === DISPLAY_UNIT.CUSTOM_UNIT &&
				<FormEntry label={i('Custom Unit')}>
					<TextBox disabled={props.readOnly}
					         {...stateLink.get('customUnit').props}
					         onBlur={() => datasourceLink.get('metric.customUnit').update(stateLink.value.customUnit)}/>
				</FormEntry>
			}

			<FormEntry label={i('Display label')}>
				<IconButton embedded={true}
							iconName={'question-sign'}
							title={i(datasourceLink.value.metric.displayLabelType + '-display-label-tooltip')}/>
				<DropDownList dataSource={displayLabelDS}
				              disabled={props.readOnly}
				              {...datasourceLink.get('metric.displayLabelType').props}/>
			</FormEntry>
		</>}

		{datasourceLink.value.type == 'cost' &&
			<>
				<CostDatasource datasource={datasourceLink}/>
			</>
		}

		{(datasourceLink.value.type == 'kpi' || datasourceLink.value.type == 'metric') &&
			<Section title={i('Metric value as severity')}
				margin={"top"}
				appearance={"frame-top-only"} childrenPadding={true}>
				<Toolbar>
					<IconButton embedded={true}
						iconName={'question-sign'}
						title={i('metric-value-as-severity-tooltip') + ' ' + severityTooltip}/>
				</Toolbar>
				<FormEntry>
					<AntCheckbox disabled={props.readOnly}
						valueLink={datasourceLink.get('metricValueAsSeverity')}>{i('Enabled')}</AntCheckbox>
					{datasourceLink.value.metricValueAsSeverity &&
						<AntCheckbox disabled={props.readOnly}
							valueLink={datasourceLink.get('hideMetricValue')}>{i('Hide metric value')}</AntCheckbox>
					}
				</FormEntry>
				{datasourceLink.value.metricValueAsSeverity && <>
					<FormEntry>
						<Button onClick={flipOperater} title={"Flip operator"}/>
					</FormEntry>
					<FormEntry containerClass={b('severity-indicators')}>
						<SeverityIndicator indicator={3} title={i('Critical')}/> {getOperator()}

						<TextBox disabled={props.readOnly}
							{...stateLink.get('metricValueToSeverity.2.threshold').props}
							onBlur={copyMetricStateToDatasource}/>

						{getOperator()} <SeverityIndicator indicator={2} title={i('Major')}/> {getOperator()}

						<TextBox disabled={props.readOnly}
							{...stateLink.get('metricValueToSeverity.1.threshold').props}
							onBlur={copyMetricStateToDatasource}/>

						{getOperator()} <SeverityIndicator indicator={1} title={i('Minor')}/> {getOperator()}

						<TextBox disabled={props.readOnly}
							{...stateLink.get('metricValueToSeverity.0.threshold').props}
							onBlur={copyMetricStateToDatasource}/>

						{getOperator()} <SeverityIndicator indicator={0} title={i('OK')}/>
					</FormEntry>
				</>}
			</Section>
		}

		{!['metric', 'cost'].includes(datasourceLink.value.type) &&
			<ShowAsLabel datasourceLink={datasourceLink}
				{...props}/>
		}

		<RedirectToConfiguration configLink={redirectConfigLink}/>

		{['asset', 'assetGroup'].includes(datasourceLink.value.type) &&
			<ShowHI datasourceLink={datasourceLink} {...props}/>}

		{['asset', 'assetGroup'].includes(datasourceLink.value.type) &&
			<AntCheckbox disabled={props.readOnly}
			             valueLink={datasourceLink.get('showReasons')}>{i('Show Health reasons')}</AntCheckbox>
		}

		{stateLink.value.windowState == WINDOW_STATE.SERVICES &&
			<DataSourceWindow onClose={closeWindow}
			                  title={i("Service")}
			                  dataSource={serviceDataSource}
			                  values={datasourceLink.value.services}
			                  onSave={value => updateDatasourceAndCloseWindow('services', value)}
			                  columns={serviceColumns} />
		}
		{stateLink.value.windowState == WINDOW_STATE.ELEMENTS &&
			<DataSourceWindow onClose={closeWindow}
			                  title={i("Service element")}
			                  dataSource={serviceElementDataSource}
			                  values={datasourceLink.value.serviceElements}
			                  onSave={value => updateDatasourceAndCloseWindow('serviceElements', value)}
			                  columns={serviceElementColumns} />
		}
		{stateLink.value.windowState == WINDOW_STATE.QUALIFIERS &&
			<DataSourceWindow onClose={closeWindow}
		                  title={i("Service qualifier")}
		                  dataSource={serviceQualifiersDataSource}
		                  values={datasourceLink.value.serviceQualifiers}
		                  onSave={value => updateDatasourceAndCloseWindow("serviceQualifiers", value)}
		                  columns={getServiceQualifierColumns()} />
		}
		{stateLink.value.windowState == WINDOW_STATE.ASSET &&
			<DataSourceWindow onClose={closeWindow}
			                  title={i("Asset")}
			                  dataSource={assetsDataSource}
			                  values={datasourceLink.value.assets}
			                  onSave={value => updateDatasourceAndCloseWindow("assets", value)}
			                  columns={assetColumns} />
		}
		{stateLink.value.windowState == WINDOW_STATE.ASSET_GROUP &&
			<DataSourceWindow onClose={closeWindow}
			                  title={i("Asset group")}
			                  dataSource={assetGroupsDataSource}
			                  values={datasourceLink.value.assetGroups}
			                  onSave={value => updateDatasourceAndCloseWindow("assetGroups", value)}
			                  columns={assetColumns} />
		}
		{stateLink.value.windowState == WINDOW_STATE.SLA &&
			<DataSourceWindow onClose={closeWindow}
			                  title={i('Sla')}
			                  dataSource={slaDataSource}
			                  values={datasourceLink.value.slas}
			                  onSave={value => updateDatasourceAndCloseWindow("slas", value)}
			                  columns={slaColumns} />
		}
		{stateLink.value.windowState == WINDOW_STATE.MONITOR &&
			<DataSourceWindow onClose={closeWindow}
			                  title={i('Monitor')}
			                  dataSource={monitorDataSource}
			                  values={datasourceLink.value.monitors}
			                  onSave={value => updateDatasourceAndCloseWindow("monitors", value)}
			                  columns={monitorColumns} />
		}
		{stateLink.value.windowState == WINDOW_STATE.METRICS &&
			<MetricWindow
				open={true}
				onClose={closeWindow}
				onAdd={updateSelectedMetrics}
				accountId={datasourceLink.value.accounts[0]}
				singleMetricSelection={true}
				defaultSelectedMetricIds={datasourceLink.value.metric.id ? [datasourceLink.value.metric.id] : []}
			/>}
		{stateLink.value.windowState === WINDOW_STATE.TOTAL_METRIC &&
		<MetricWindow
			open={true}
			onClose={closeWindow}
			onAdd={updateTotalMetric}
			accountId={datasourceLink.value.accounts[0]}
			singleMetricSelection={true}
			defaultSelectedMetricIds={datasourceLink.value.metric.totalMetricId ? [datasourceLink.value.metric.totalMetricId] : []}
		/>}

	</div>
}

const ApplicationDatasource = props => {
	const multiselectItemTemplate = useMultiselectItemtemplate();

	const {stateLink, datasourceLink} = props;

	return <div className={b()}>
		<FormEntry label={i('Account')} vertical>
			<AccountDropDown value={datasourceLink.value.accounts.length > 0 ? datasourceLink.value.accounts[0] : ""}
			                 disabled={props.readOnly}
			                 onChange={v => datasourceLink.get('accounts').update([v])}/>
		</FormEntry>

		{datasourceLink.value.type == 'asset' &&
			<FormEntry label={i('Asset')} vertical={true}>
				<DropDownList dataSource={props.assetsDataSource}
				              itemTemplate={multiselectItemTemplate}
				              dataTextField={"name"}
				              dataValueField={"id"}
				              {...updateFirstArrayEntry(datasourceLink, 'assets')}/>
			</FormEntry>
		}

		{datasourceLink.value.type == 'assetGroup' &&
			<FormEntry label={i('Asset group')} vertical={true}>
				<DropDownList dataSource={props.assetGroupsDataSource}
				              itemTemplate={multiselectItemTemplate}
				              dataTextField={"name"}
				              dataValueField={"id"}
				              {...updateFirstArrayEntry(datasourceLink, 'assetGroups')}/>
			</FormEntry>
		}

		<ShowAsLabel datasourceLink={datasourceLink} {...props}/>
		<ShowHI datasourceLink={datasourceLink} {...props}/>

		{datasourceLink.value.type == 'assetGroup' && <>
			<FormEntry><AntCheckbox valueLink={props.datasourceLink.get('expanded')}>{i('Expanded')}</AntCheckbox></FormEntry>
			<FormEntry><AntCheckbox valueLink={props.datasourceLink.get('assetGroup.showSummary')}>{i('Show summary information')}</AntCheckbox></FormEntry>
		</>}
	</div>
}

const ShowAsLabel = props => {
	return <FormEntry>
		<AntCheckbox disabled={props.readOnly}
	                    valueLink={props.datasourceLink.get('showAsLabel')}>{i('Set Datasource as label')}</AntCheckbox>
	</FormEntry>
}

const ShowHI = props => {
	return <FormEntry><AntCheckbox disabled={props.readOnly}
	                    valueLink={props.datasourceLink.get('showHI')}>{i('Show health index')}</AntCheckbox>
	</FormEntry>
}

const KpiDatasource = props => {
	const {datasource} = props;

	const profiles = useRemoteList(KpiApi.profilesUrl(datasource.value.accounts[0]), {directList: true});

	const categories = useRemoteList(KpiApi.categoriesUrl(datasource.value.kpi, datasource.value.accounts[0]), {
		directList: true,
		makeRequest: !!datasource.value.kpi.profileId
	});

	const ruleTypes = useRemoteList(KpiApi.ruleTypesUrl(datasource.value.kpi, datasource.value.accounts[0]), {
		directList: true,
		makeRequest: !!datasource.value.kpi.categoryId
	});

	const sampleRates = useRemoteList(KpiApi.sampleRatesUrl(datasource.value.kpi, datasource.value.accounts[0]), {
		directList: true,
		makeRequest: !!datasource.value.kpi.ruleTypeId
	});

	const kpiAccountsLoaded = useRemoteList(KpiApi.kpiAccountsUrl(datasource.value.kpi, datasource.value.accounts[0]), {
		directList: true,
		makeRequest: !!datasource.value.kpi.sampleRateId
	});

	const kpiAccounts = useMemo(() => {
		if (kpiAccountsLoaded.length == 0)
			return [];

		const result = [{
			value: '',
			text: i('Aggregated')
		}];

		return result.concat(kpiAccountsLoaded);

	}, [kpiAccountsLoaded])

	return (
		<>
			<FormEntry label={i('Profile')} vertical={true}>
				<AntSelect placeholder={i('Select a profile...')}
						   dataList={profiles}
				           valueLink={datasource.get('kpi.profileId')}/>
			</FormEntry>

			<FormEntry label={i('Category')} vertical={true}>
				<AntSelect placeholder={i('Select a category...')}
				           dataList={categories}
				           disabled={categories.length == 0}
				           nameField={'text'}
				           valueField={'value'}
				           valueLink={datasource.get('kpi.categoryId')}/>
			</FormEntry>

			<FormEntry label={i('Rule type')} vertical={true}>
				<AntSelect placeholder={i('Select a rule type...')}
				           dataList={ruleTypes}
				           disabled={ruleTypes.length == 0}
				           nameField={'text'}
				           valueField={'value'}
				           valueLink={datasource.get('kpi.ruleTypeId')}/>
			</FormEntry>

			<FormEntry label={i('Sample rate')} vertical={true}>
				<AntSelect placeholder={i('Select a sample rate...')}
				           dataList={sampleRates}
				           disabled={sampleRates.length == 0}
				           nameField={'text'}
				           valueField={'value'}
				           valueLink={datasource.get('kpi.sampleRateId')}/>
			</FormEntry>

			<FormEntry label={i('KPI account')} vertical={true}>
				<AntSelect placeholder={i('Select a KPI account...')}
				           dataList={kpiAccounts}
				           disabled={kpiAccounts.length == 0}
				           nameField={'text'}
				           valueField={'value'}
				           valueLink={datasource.get('kpi.accountId')}/>
			</FormEntry>
		</>
	)
}

const SeverityIndicator = props => {
	const color = StateColors[indicatorToState(props.indicator)];
	return <div style={{flex: '1 0 20px', height: '20px', backgroundColor: color}} title={props.title}></div>;
}

function useMultiselectItemtemplate(){
	return useCallback( item => item.accountName + '\\' + item.name, [1] );
}

function updateFirstArrayEntry(link, path){
	return {
		value: link.get(path).value.length > 0 ? link.get(path).value[0].id : null,
		onChange: v => link.get(path).update([{
			id: v,
			accountId: link.get('accounts').value[0]
		}])
	}
}

const WINDOW_STATE = {
	HIDDEN: Symbol('HIDDEN'),
	SERVICES: Symbol('SERVICES'),
	ELEMENTS: Symbol('ELEMENTS'),
	QUALIFIERS: Symbol('QUALIFIERS'),
	ASSET: Symbol('ASSET'),
	ASSET_GROUP: Symbol('ASSET_GROUP'),
	SLA: Symbol('SLA'),
	MONITOR: Symbol('MONITOR'),
	METRICS: Symbol('METRICS'),
	TOTAL_METRIC: Symbol('TOTAL_METRIC')
};

const defaultMetricValueToSeverity = [{
	operator: 'lte',
	threshold: ''
}, {
	operator: 'lte',
	threshold: ''
}, {
	operator: 'lte',
	threshold: ''
}];
