import {Application, Cookies, RemoteEventsManager, LocalEventsManager, ErrorHandler} from 'core';
import {State, UserPrefs, Utils} from 'tools';
import {
	CustomNotification,
	GridMenu,
	MultiToggle,
	EventsToolbar,
	PreferencesPanel,
	Dialog,
	ModalView,
	ModalWindow, Dependencies
} from 'controls';
import Settings from 'settings';
import {AssetGroupForm} from "areas/administration/assetGroupForm";
import MultiSelectGridFilter from 'controls/multiSelectGridFilter';
import {AssetGroupRouter} from "areas/assetgroups/bundleDescription";


export let AssetGroupsView = function (config) {
	Utils.apply(this, config);
	this.hasEvents = true;
	this.subscriberId = Utils.guid();
	this.initComponent();
};

jQuery.extend(AssetGroupsView.prototype, Application.prototype, {
	/**
	 * Assets view application related initializations
	 */
	initComponent: function () {
		Application.prototype.initComponent.call(this);
		this.getTags();

		this.actionNotification = new CustomNotification({
			appendToElement: '.window_area',
			status: 'success',
			style: 'top:15px; right:15px; left:15px;',
		});

		this.defaultColumns = {
			id: {
				hidden: false,
				width: 40
			},
			name: {
				hidden: false,
				width: 260
			},
			tags: {
				hidden: false,
				width: 200
			},
			description: {
				hidden: false
			}
		};
		this.loadUserPreferences();

		// update content button
		State.mainApp.contextMenu.setActionButton('GROUP');
		this.cardGridToggle = new MultiToggle({
			id: 'cw_asset_groups_view_toggle',
			cssClass: 'cw_multi_toggle right',
			value: 'false',
			items: [{
				id: 'cw_grid_view',
				title: lang.GRID_VIEW,
				selected: false,
				cssClass: 'glyphicons justify',
				fn: this.onViewToggle,
				scope: this,
				value: 'true'
			}, {
				id: 'cw_card_view',
				title: lang.CARD_VIEW,
				selected: true,
				cssClass: 'glyphicons show-big-thumbnails',
				fn: this.onViewToggle,
				scope: this,
				value: 'false'
			}]
		});
		this.gridMenu = new GridMenu({
			renderTo: 'cw_asset_groups_grid_menu',
			items: [{
				id: 'cw_create',
				icon: 'plus-sign',
				text: lang.CREATE,
				fn: State.mainApp.onNewAssetGroup,
				scope: State.mainApp,
				role: 'ASSET_CREATE'
			}, {
				id: 'cw_delete',
				icon: 'bin',
				text: lang.DELETE,
				fn: this.onAssetGroupDelete,
				scope: this,
				disabled: true,
				role: 'ASSET_DELETE'
			}]
		});

		this.removeMask();
		this.removeListeners();
		this.attachListeners();
	},
	getTags: async function () {
		let tags = await Utils.getAccountTags();
		this.filterOptions = {
			tags: tags
		};
	},
	/**
	 * Handler for the view toggle buttons click
	 * @param {String} value 'true'/'false'
	 */
	onViewToggle: function (value) {
		if (value === 'true') {
			if (this.treeList && this.gridViewLoaded) {
				this.showGridView = true;
				$('#cw_asset_groups_list').addClass('hide');
				$('#cw_asset_groups_grid').removeClass('hide');
				this.treeList.refresh();
			} else {
				this.initGridView();
				this.treeList.refresh();
			}
		} else {
			$('#cw_asset_groups_grid').addClass('hide');
			if (this.innerView) {
				this.showGridView = false;
				$('#cw_asset_groups_list').removeClass('hide');
			} else {
				this.initCardView();
			}
		}
	},
	/**
	 * Removes the listeners for the form's buttons
	 */
	removeListeners: function () {
		$('.cw_close').off();
		$('#asset_groups_tree_list').off();
		this.onAssetGroupSavedHandler = $.proxy(this.onAssetGroupSaved, this);
		LocalEventsManager.unbind('assetgroupsaved', this.onAssetGroupSavedHandler);
	},
	/**
	 * Attaches the listeners for the form's buttons
	 */
	attachListeners: function () {
		$('#asset_groups_tree_list').on('click', '.cw_grid_check', $.proxy(this.onCheck, this));
		$('#asset_groups_tree_list').on('click', '.cw_grid_check_all', $.proxy(this.onCheckAll, this));
		$('#asset_groups_tree_list').on('click', '.cw_grid_link', $.proxy(this.onAssetGroupNameClick, this));
		LocalEventsManager.bind('assetgroupsaved', this.onAssetGroupSavedHandler);
		$('.cw_close').on('click', function (e) {
			var largeCard = $('.asset_groups_settings').parent().find('.cw_card_large');
			largeCard.addClass('hide');
			e.stopPropagation();
		});
	},
	/**
	 * Init card component
	 */
	initCardView: function () {
		var deleteClass = State.mainApp.session.hasRole('ASSET_DELETE') ? '' : ' hide';
		var updateClass = State.mainApp.session.hasRole('ASSET_UPDATE') ? '' : ' hide';
		$('#asset_group_name').hide();
		var template = '<li><div class="cw_card" id="${id}">';
		template += '<div class="cw_card_avatar">';
		template += '<div class="cw_avatar cw_avatar_asset_group"></div>';
		template += '</div>';
		template += '<div class="cw_card_content">';
		template += '<h2 class="card_title card4"><strong>' + lang.NAME + ':</strong> ${name} </h2>';
		if (State.mainApp.session.hasRole("ASSET_READ")) {
			template += '<p class="card_description card_extra card4"><strong>' + lang.DESCRIPTION + ':</strong>';
			template += '#if(description){# ${description} #}#</p><p class="card_assets card_extra card4"><strong>' + lang.ASSETS + ':</strong>';
			template += '#if(memberCount==0){# ' + lang.account.messages.ASSET_GROUP_NO_ASSETS + '#}##if(memberCount==1){#' + lang.account.messages.ASSET_GROUP_ONE_ASSET + '';
			template += '#}# #if(memberCount>1){#' + lang.account.messages.ASSET_GROUP_COUNT_FIRST + ' ${memberCount} ' + lang.account.messages.ASSET_GROUP_COUNT_SECOND + '#}#</p></div>';
		}
		template += '<div class="cw_card_options">';
		template += '<span class="glyphicons bin' + deleteClass + '"></span>';
		template += '<span class="glyphicons pencil' + updateClass + '"></span>';
		template += '</div></div></li>';
		var largeCard = $('#cw_asset_groups_list').parent().parent().find('.cw_card_large');
		largeCard.off();
		largeCard.on('click', function (e) {
			$(this).toggleClass('hide');
		});
		this.showGridView = false;
		$('#cw_asset_groups_view_toggle').find('li').last().addClass('is_selected');
		var oThis = this;
		this.innerView = new ModalView({
			autoLoad: true,
			createButtonText: lang.account.ASSET_GROUP_NEW,
			//viewDataSource: this.dataSource,
			viewDataSource: this.treeList.dataSource,
			template: template,
			id: 'cw_asset_groups_list',
			cssClass: 'asset_groups_ul',
			title: lang.account.ASSET_GROUPS,
			pager: true,
			onCreateButton: $.proxy(function (e) {
				var largeCard = $('.asset_groups_settings').parent().find('.cw_card_large');
				if (State.mainApp.session.hasRole('ASSET_CREATE')) {
					var modalWindow = new ModalWindow({
						title: lang.account.ASSET_GROUP_CREATE,
						width: 400,
						url: 'include/Administration/AssetGroupForm.jsp',
						minHeight: 200,
						refresh: $.proxy(function () {
							new AssetGroupForm({
								id: 'new_asset_group',
								mode: 'new'
							});
						}, this)
					});
					modalWindow.open();
				}
				e.stopPropagation();
				largeCard.addClass('hide');
			}, this),
			onEditButton: $.proxy(function (e) {
				let largeCard = $('.asset_groups_settings').parent().find('.cw_card_large');
				if (State.mainApp.session.hasRole('ASSET_UPDATE') || State.mainApp.session.hasRole('ASSET_READ')) {
					let uid = $(e.currentTarget).closest('li').data('uid');
					let assetGroup = this.treeList.dataSource.getByUid(uid);
					assetGroup.isFromAdministration = true;
					State.mainApp.navigate(AssetGroupRouter.details(assetGroup.realId), {extra:{isFromAdministration: true}});
				}
				e.stopPropagation();
				largeCard.addClass('hide');
			}, this),
			onDeleteButton: function (e) {
				var largeCard = $('.asset_groups_settings').parent().find('.cw_card_large');
				if (State.mainApp.session.hasRole('ASSET_DELETE')) {
					var assetGroupId = e.currentTarget.parentNode.parentNode.id;
					var record = this.viewDataSource.get(assetGroupId);
					let realId = record.realId;
					var assetGroupName = record.name;
					var dependenciesUrl = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/assetGroups/' + realId + '/dependencies';
					Utils.ajax(dependenciesUrl, 'GET', {}, $.proxy(function (data) {
						if (data.length) {
							oThis.checkAssetGroupDependencies('card', assetGroupId);
						} else {
							var deleteUrl = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/assetGroups/' + realId;
							var dialog = new Dialog({
								title: lang.INFO,
								msg: lang.account.messages.ASSET_GROUP_REMOVE_CONFIRMATION,
								icon: 'ERROR',
								actionText: 'DELETE',
								fn: $.proxy(function (value, button) {
									if (button === 'ok') {
										Utils.ajax(deleteUrl, 'DELETE', {}, $.proxy(function (result) {
											if (result.success) {
												oThis.actionNotification.setOptions({
													message: lang.account.messages.ASSET_GROUP_SUCCESS_DELETED,
													status: 'success'
												}).show();
												// reload data source
												this.viewDataSource.read();
											} else {
												Utils.showInfo(lang.ALERT, result.message, result.details);
											}
										}, this));
									}
								}, this)
							});
							dialog.show();
						}

					}, this));
				}
				e.stopPropagation();
				largeCard.addClass('hide');
			},
			onCardClick: function (e) {
				this.id = e.currentTarget.id;
				this.assetGroupName = $(e.currentTarget).find('.card_title').text();
				this.assetGroupDescription = $(e.currentTarget).find('.card_description').text().replace($(e.currentTarget).find('.card_description').find('strong').text(), '');
				var oThis = this;

				url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/assetGroups/' + this.id + '/members';
				Utils.ajax(url, 'GET', {}, function (result) {
					if (result) {
						oThis.assets = result;
						var largeCard = $('#cw_asset_groups_list').parent().parent().find('.cw_card_large');
						largeCard.find('.card_title').empty();
						largeCard.find('.card_title').append(oThis.assetGroupName);
						largeCard.find('.card_description').empty();
						largeCard.find('.card_description').append('<strong>' + lang.DESCRIPTION + ':</strong>' + oThis.assetGroupDescription);
						var assetsDetails = '';
						for (var i = 0; i < result.length; i++) {
							var asset = result[i];
							assetsDetails = assetsDetails + ('<li>' + asset.name + '</li>');
						}
						largeCard.find('.cw_large_details').empty();
						largeCard.find('.cw_large_details').append(assetsDetails);

						var yOffset = 0;
						if ($('#' + oThis.id).position().top > $(window).height() * 0.6) {
							yOffset = 120;
						}
						var scrollTop = $('.list_content').scrollTop();

						largeCard.css('top', $('#' + oThis.id).position().top + 37 - yOffset - scrollTop + 'px');
						largeCard.css('left', $('#' + oThis.id).position().left + 'px');
						largeCard.css('height', '110px');
						largeCard.css('width', $('#' + oThis.id).width() + 2 + 'px');
						largeCard.removeClass('hide');
						largeCard.animate({
							height: '230px'
						}, 300, function () {
						});
					} else {
						Utils.showInfo(lang.ALERT, result.message, result.details);
					}
				});
			}
		});
		//$('#cw_filter_reset').addClass('inactive');
		//$('#cw_asset_groups_grid').addClass('hide');
		setTimeout(function () {
			$('#cw_asset_groups_list').removeClass('hide');
		}, 300);

	},
	/**
	 * Init data source
	 */
	initDataSource: function () {
		var filterMessages = lang.grid.filter;
		this.gridMessages = {
			clear: lang.CLEAR,
			info: lang.grid.filter.SHOW_ITEMS,
			filter: lang.FILTER
		};

		var scope = this;
		this.treeListDataSource = new kendo.data.TreeListDataSource({
			transport: {
				read: {
					url: Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/assetGroups/treelist?&dc=' + Utils.timestamp(),
					contentType: "application/json; charset=utf-8",
					type: "GET",
					dataType: "json",
					beforeSend: function (xhr) {
						var idParam = this.url.split('id=')[1];
						if (idParam) {
							scope.currentExpanded = idParam.split('&')[0];
						} else {
							scope.currentExpanded = null;
						}
						xhr.setRequestHeader('Auth-Token', Cookies.sessionId);
					},
					cache: false
				}
			},
			schema: {
				model: {
					id: 'treelistId',
					fields: {
						parentId: {
							field: 'parentGroupId',
							nullable: true
						},
						hasChildren: {
							field: 'hasSubgroups'
						},
						realId: {
							field: 'id'
						}
					}
				},
				parse: $.proxy(function (result) {
					for (var i = 0; i < result.length; i++) {
						if (!result[i].parentGroupId) {
							result[i].parentGroupId = null;
						}
						if (scope.currentExpanded) {
							result[i].parentGroupId = scope.currentExpanded;
						}
						if (result[i].tags) {
							result[i].tags = result[i].tags.join(', ');
						}
					}
					return result;
				}, this)
			},
			sort: this.assetGroupsSort || [{
				field: 'name',
				dir: 'asc',
				compare: null
			}],
			filter: this.assetGroupsFilter || [],
			error: ErrorHandler.kendoServerError,
			change: $.proxy(function (e) {
				if (!this.currentExpanded) {
					this.initialItems = this.treeList.dataSource.data().length;
				}
				e.initialItems = this.initialItems;
				setTimeout(function (e) {
					var checkAll = $('.cw_grid_check_all').is(':checked');
					var checkboxes = $('.cw_grid_check');
					for (var i = 0; i < checkboxes.length; i++) {
						$(checkboxes[i]).prop('checked', checkAll);
					}
				}, 0);
			}, this)
		});
	
		var assetGroupsColumns = this.assetGroupColumns || JSON.parse(UserPrefs.get('assetGroupsColumns')) || this.defaultColumns;
		assetGroupsColumns = Utils.completeColumns(assetGroupsColumns, this.defaultColumns);

		this.treeList = $('#asset_groups_tree_list').kendoCustomTreeList({
			dataSource: this.treeListDataSource,
			resizable: true,
			selectable: 'row',
			autoBind: true,
			sortable: {
				mode: 'multiple',
				allowUnsort: true
			},
			filterable: {
				messages: this.gridMessages
			},
			columns: [{
				field: 'name',
				title: lang.NAME,
				sortable: true,
				filterable: {
					messages: this.gridMessages,
					extra: false,
					operators: {
						string: {
							startswith: filterMessages.STARTS_WITH,
							neq: filterMessages.NEQ,
							eq: filterMessages.EQ
						}
					}
				},
				template: '<input type="checkbox" class="cw_grid_check" style="margin-left:10px" data-id="#= id #" /><a class="cw_grid_link ellipsis to_expand" style="display:initial; /*margin-left:10px*/" data-id="#= id #">#= name #</a>',
				//template: '<a class="cw_grid_link" style="display:initial" data-id="#= id #">#= name #</a>',
				headerTemplate: '<input type="checkbox" class="cw_grid_check_all" style="margin-left:10px" /><span style="margin-left:40px">' + lang.NAME + '</span>',
				hidden: assetGroupsColumns.name.hidden,
				width: assetGroupsColumns.name.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'tags',
				title: lang.TAGS,
				sortable: true,
				filterable: {
					ui: $.proxy(function (element) {
						let multiselect = new MultiSelectGridFilter({
							element: element,
							field: 'tags',
							grid: this.treeList,
							itemTemplate: '#=data.text#',
							tagTemplate: '#=data.text#',
							dataSource: this.filterOptions.tags,
							static: true
						});
					}, this),
					messages: this.gridMessages,
					extra: false,
					operators: {
						string: {
							eq: filterMessages.EQ,
							neq: filterMessages.NEQ
						}
					}
				},
				hidden: assetGroupsColumns.tags.hidden,
				width: assetGroupsColumns.tags.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'description',
				title: lang.DESCRIPTION,
				sortable: true,
				filterable: {
					messages: this.gridMessages,
					extra: false,
					operators: {
						string: {
							startswith: filterMessages.STARTS_WITH,
							neq: filterMessages.NEQ,
							eq: filterMessages.EQ
						}
					}
				},
				template: '#= description #',
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				},
				hidden: assetGroupsColumns.description.hidden,
				width: assetGroupsColumns.description.width
			}],
			change: $.proxy(this.onRowExpand, this),
			dataBound: $.proxy(function (e) {
				$('#asset_groups_tree_list').find('.k-grid-content').kendoTooltip({
					filter: "td.tooltip:not(:empty)",
					content: function (event) {
						return event.target.text().split("\n").join("<br />");
					}
				});
				this.switchCheckExpand();
				$('.k-i-filter').removeClass('k-i-filter').addClass('k-i-arrowhead-s');
				Utils.updateGridFilterIndicators(this.treeList);
			}, this)
		}).data('kendoCustomTreeList');

		this.treeList.thead.find("[data-field='id']>.k-header-column-menu").remove();
		var searchValue = UserPrefs.get('defaultFsView') ? '' : (UserPrefs.get('assetGroupsSearchPhrase') || '');
		this.gridFilterPanel = new PreferencesPanel({
			renderTo: 'cw_asset_groups_filters',
			grid: this.treeList,
			modulePrefName: 'AdministrationAssetGroups',
			defaultPrefViewKey: 'defaultFsView',
			prefListKey: 'assetGroupsFSViews',
			userPref: this.userPref,
			onRemove: $.proxy(this.saveUserPreferences, this),
			searchFields: ['name', 'description'],
			searchValue: searchValue,
			defaultColumns: this.defaultColumns,
			switchCheckExpand: this.switchCheckExpand
		});
	},
	/**
	 * Init grid component
	 * @param {boolean} showGrid Defaults to true
	 */
	initGridView: function (showGrid) {
		let loadGrid = true;
		if (showGrid === false) {
			loadGrid = false;
			this.gridViewLoaded = false;
		} else {
			this.gridViewLoaded = true;
		}
		if (loadGrid) {
			this.showGridView = true;
			$('#cw_asset_groups_view_toggle').find('li').first().addClass('is_selected');
			$('#cw_filter_reset').removeClass('hide');
			$('#cw_asset_groups_list').addClass('hide');
			$('#cw_asset_groups_grid').removeClass('hide');
		}

		// Add Kendo tooltip to the header of the columns
		Utils.gridColumnHeaderTooltip(this.treeList);
		this.adjustSectionHeight();	
	},
	/**
	 * Handler function for switching the checkbox with the treelist icon, for a nicer appearance
	 */
	switchCheckExpand: function () {
		$('.cw_grid_check').each(function () {
			var current = $(this);
			var firstHierarchicalIcon = current.closest('td').find('.k-icon').first();
			$(firstHierarchicalIcon).css('margin-left', '10px');
			current.insertBefore(firstHierarchicalIcon);
		});
	},
	/**
	 * Handler function for the change(select) event on the grid
	 */
	onRowExpand: function () {
		var selectedRow = this.treeList.select();
		var myRow = selectedRow[0];
		var messageEl = $(myRow).find('.to_expand');
		if ($(messageEl).hasClass('cw_message_expanded')) {
			$(messageEl).removeClass('cw_message_expanded').addClass('ellipsis');
		} else {
			$('#asset_groups_tree_list').find('.k-grid-content').find('td.cw_message_expanded').removeClass('cw_message_expanded').addClass('ellipsis');
			$(messageEl).addClass('cw_message_expanded').removeClass('ellipsis');
		}
	},
	/**
	 * Handler for the service checkbox click
	 * @param {Object} e The click event
	 */
	onCheck: function (e) {
		e.stopPropagation();
		var checkboxes = $('.cw_grid_check');
		var assetGroups = [];
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			if ($(checkboxes[i]).is(':checked')) {
				assetGroups.push($(checkboxes[i]).data('id'));
			}
		}
		if (assetGroups.length) {
			this.gridMenu.enableItem('cw_delete');
		} else {
			this.gridMenu.disableItem('cw_delete');
		}
	},
	/**
	 * Handler for the check all checkbox click
	 * @param {Object} e The click event
	 */
	onCheckAll: function (e) {
		e.preventDefault();
		e.stopPropagation();
		var target = $(e.target);
		var checkAll = $('.cw_grid_check_all').is(':checked');
		var checkboxes = $('.cw_grid_check');
		if (checkAll && checkboxes.length) {
			if (checkboxes.length === 1) {
				this.gridMenu.enableItem('cw_delete');
			} else {
				this.gridMenu.disableItem('cw_delete');
			}
		} else {
			this.gridMenu.disableItem('cw_delete');
		}
		setTimeout($.proxy(function () {
			//keep the last sort
			var currentSort = this.treeList.dataSource.sort();
			if (!currentSort.length) {
				currentSort.push({field: 'name', dir: 'desc'});
			} else {
				var nameFound = false;
				for (var j = 0; j < currentSort.length; j++) {
					if (currentSort[j].field === 'name') {
						nameFound = true;
						if (currentSort[j] && currentSort[j].dir === 'asc') {
							currentSort.splice(j, 1);
							j--;
						}
						if (currentSort[j] && currentSort[j].dir === 'desc') {
							currentSort[j].dir = 'asc';
						}
					}
				}
				if (!nameFound) {
					currentSort.push({field: 'name', dir: 'desc'});
				}
			}
			this.treeList.dataSource.sort(currentSort);
			target.prop('checked', checkAll);
		}, this), 0);
	},
	/**
	 * Handler for the delete button click
	 * @param {Object} e The click event
	 */
	onAssetGroupDelete: function (e) {
		if (!$(e.currentTarget).hasClass('disabled')) {
			var assetGroups = [];
			var checkboxes = $('#cw_asset_groups_grid').find('.cw_grid_check:checked');
			for (var i = 0, length = checkboxes.length; i < length; i++) {
				if ($(checkboxes[i]).is(':checked')) {
					assetGroups.push($(checkboxes[i]).data('id'));
				}
			}
			this.assetGroupsLength = assetGroups.length;
			this.selectedAssetGroups = assetGroups;
			if (this.assetGroupsLength) {
				this.assetGroupIndex = 0;
				this.gridMenu.disableItem('cw_update');
				this.gridMenu.disableItem('cw_delete');

				var dialog = new Dialog({
					title: lang.INFO,
					msg: assetGroups.length === 1 ? lang.account.messages.ASSET_GROUP_REMOVE_CONFIRMATION : lang.account.messages.ASSETS_GROUP_REMOVE_CONFIRMATION,
					icon: 'ERROR',
					actionText: 'DELETE',
					fn: $.proxy(function (value, button) {
						if (button === 'ok') {
							kendo.ui.progress($('.cw_section_content'), false);
							this.checkAssetGroupDependencies('grid');
						}
					}, this)
				});
				dialog.show();
			}
		}
	},
	checkAssetGroupDependencies: function (view, groupId) {
		var assetGroupId, record, assetGroupName, cb;
		if(view === 'card' && groupId) {
			assetGroupId = groupId;
			record = this.treeList.dataSource.data().find(item => item.realId == assetGroupId || item.id == assetGroupId);	
		}
		 else if (view === 'grid') {
			assetGroupId = this.selectedAssetGroups[this.assetGroupIndex++];
			record = this.treeList.dataSource.get(assetGroupId);
		}
		if (assetGroupId) {
			cb = $('.cw_health_summary_list').find('.cw_grid_check:checked');
			var realId = record.realId;
			this.assetGroupAccountId = record.accountId || Cookies.CeesoftCurrentAccountId;
			this.selectedAssetGroupId = assetGroupId;
			assetGroupName = record.name;

			var dependenciesUrl = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/assetGroups/' + realId + '/dependencies';
			Utils.ajax(dependenciesUrl, 'GET', {}, $.proxy(function (data) {
				if (data.length) {
					this.openDependenciesPopup(data, assetGroupName, realId, view);
					setTimeout(this.adjustTableHeight, 100);
				} else {
					var deleteUrl = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/assetGroups/' + realId;
					Utils.ajax(deleteUrl, 'DELETE', {}, $.proxy(function (result) {
						if (result.success) {
							this.checkAssetGroupDependencies(view);
						} else {
							this.resetCheckingAssets();
							Utils.showInfo(lang.ALERT, lang.account.messages.ASSET_DELETE_TIED_AGENT, result.details);
						}
					}, this));
				}
			}, this));
		} else {
			kendo.ui.progress($('.cw_section_content'), false);
			this.treeList.dataSource.read();
			if (this.dependenciesWindow) {
				this.dependenciesWindow.close();
			}
			this.actionNotification.setOptions({
				message: lang.account.messages.ASSET_GROUPS_SUCCESS_DELETED,
				status: 'success'
			}).show();
		}
	},
	/**
	 * Resets the checking assets
	 */
	resetCheckingAssets: function () {
		this.selectedAssetGroups = [];
		this.assetGroupIndex = 0;

		kendo.ui.progress($('.cw_section_content'), false);
		this.treeList.dataSource.read();
	},
	/**
	 * Creates and opens the agent dependencies window
	 *
	 * @param {Array} dependencies - The asset group dependencies array
	 * @param {String} assetGroupName The asset group name
	 */
	openDependenciesPopup: function (dependencies, assetGroupName, assetGroupId, view) {
		if (this.dependencyWindow) {
			this.dependenciesWindow.close();
		}
		this.dependenciesWindow = new ModalWindow({
			title: lang.INFO,
			width: 960,
			height: 640,
			minWidth: 220,
			actions: [],
			url: 'include/Dependencies.jsp',
			//resize: this.adjustTableHeight,
			onClose: $.proxy(function () {
				this.clearCheckboxes();
			}, this),
			refresh: $.proxy(function () {
				$('#cw_dependencies_deleteall').removeClass('hide');
				this.dependencyWindow = new Dependencies({
					accountId: this.assetGroupAccountId || Cookies.CeesoftCurrentAccountId,
					dependencies: dependencies,
					name: assetGroupName,
					container: '#asset_groups_tree_list',
					infoTemplate: lang.account.messages.ASSET_GROUP_DELETE_INFO,
					deleteAll: $.proxy(function (e) {
						var deleteUrl = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/assetGroups/' + assetGroupId + '/dependencies/deleteAll';
						Utils.ajax(deleteUrl, 'DELETE', {}, $.proxy(function (result) {
							if (result.success) {
								this.checkAssetGroupDependencies(view);
							} else {
								Utils.showInfo(lang.ALERT, result.message, result.details);
								this.resetCheckingAssets();
							}
						}, this));
					}, this)
				});
			}, this)
		});
		this.dependenciesWindow.open();
	},
	clearCheckboxes: function () {
		var checkAll = $('#cw_asset_groups_grid').find('.cw_grid_check_all'),
			checkboxes = $('#cw_asset_groups_grid').find('.cw_grid_check'),
			i, length = checkboxes.length;
		checkAll.prop('checked', false);
		for (i = 0; i < length; i++) {
			$(checkboxes[i]).prop('checked', false);
		}
	},
	/**
	 * Handler function for the click event on the asset update menu item
	 * @param {Object} e The click event object
	 */
	onAssetGroupNameClick: function (e) {
		//var assetGroupId = $(e.currentTarget).data('id');
		var mode = 'view';
		if (State.mainApp.session.hasRole('ASSET_UPDATE')) {
			mode = 'update';
		}
		var uid = $(e.currentTarget).closest('tr').data('uid');
		var assetGroup = this.treeList.dataSource.getByUid(uid);
		State.mainApp.navigate(AssetGroupRouter.details(assetGroup.realId), {extra:{isFromAdministration: true}});
	},
	reloadByTags: function () {
		this.treeList.dataSource.read();
	},
	/**
	 * Saves user preferences
	 */
	saveUserPreferences: function () {
		var preferences = [{
			key: 'showGridView',
			value: this.showGridView
		}, {
			key: 'eventsStatus',
			value: JSON.stringify({
				playing: this.eventsToolbar.playing,
				startFrom: this.eventsToolbar.startFrom
			})
		}];
		if (this.treeList) {
			preferences.push({
				key: 'assetGroupsSearchPhrase',
				value: this.gridFilterPanel.getSearchValue()
			});
			preferences.push({
				key: 'assetGroupsColumns',
				value: JSON.stringify(Utils.getGridColumns(this.treeList))
			});
			preferences.push({
				key: 'assetGroupsSort',
				//value: JSON.stringify(this.dataSource.sort() || []),
				value: JSON.stringify(this.treeList.dataSource.sort() || [])
			});
			preferences.push({
				key: 'assetGroupsFilter',
				//value: JSON.stringify(this.dataSource.filter() || []),
				value: JSON.stringify(this.treeList.dataSource.filter() || []),
			});
			preferences.push({
				key: 'assetGroupsFSViews',
				value: JSON.stringify(this.gridFilterPanel.fsList)
			});
			preferences.push({
				key: 'defaultFsView',
				value: this.gridFilterPanel.defaultFsViewId || ''
			});
			// add FS views
			for (var i = 0, length = this.userPref.length; i < length; i++) {
				// if is FS view
				if (Utils.isGuid(this.userPref[i].key)) {
					preferences.push(this.userPref[i]);
				}
			}
		}
		UserPrefs.save('AdministrationAssetGroups', preferences);
	},
	createDataSource: function () {
		this.dataSource = new kendo.ceeview.DataSource({
			transport: {
				read: {
					url: Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/assetGroups?&dc=' + Utils.timestamp(),
					contentType: "application/json; charset=utf-8",
					type: "GET",
					dataType: "json",
					cache: false
				}
			},
			sort: this.assetGroupsSort || [{
				field: 'name',
				dir: 'asc',
				compare: null
			}],
			filter: this.assetGroupsFilter || [],
			error: ErrorHandler.kendoServerError
		});
	},
	/**
	 * Loads user preferences
	 */
	loadUserPreferences: function () {
		var defaultFsView, showGridView, pref;
		UserPrefs.load('AdministrationAssetGroups', $.proxy(function (result) {
			if (result.success) {
				this.userPref = result.data;
				showGridView = UserPrefs.get('showGridView');
				defaultFsView = UserPrefs.get('defaultFsView');

				if (Utils.isGuid(defaultFsView)) {
					pref = JSON.parse(UserPrefs.get(defaultFsView));
					pref = pref || {
						sort: [],
						filter: []
					};
					this.assetGroupsSort = pref.sort;
					this.assetGroupsFilter = pref.filter;
					this.assetGroupsColumns = pref.columns;
				} else {
					if (UserPrefs.get('assetGroupsSort')) {
						this.assetGroupsSort = JSON.parse(UserPrefs.get('assetGroupsSort'));
					} else {
						this.assetGroupsSort = [{
							field: 'name',
							dir: 'asc',
							compare: null
						}];
					}
					if (UserPrefs.get('assetGroupsFilter')) {
						this.assetGroupsFilter = JSON.parse(UserPrefs.get('assetGroupsFilter'));
					} else {
						this.assetGroupsFilter = [];
					}
				}
				if (showGridView) {
					this.showGridView = showGridView === 'true' ? true : false;
				} else {
					this.showGridView = true;
				}
				this.cardGridToggle.select(this.showGridView ? 'cw_grid_view' : 'cw_card_view');
				var eventStatus = JSON.parse(UserPrefs.get('eventsStatus')) || {
					startFrom: 0,
					playing: true
				};
				this.eventsToolbar = new EventsToolbar({
					renderTo: $('#cw_asset_groups_events_toolbar'),
					callBackFn: $.proxy(this.onEventsTimeout, this),
					subscriberId: this.subscriberId,
					startFrom: eventStatus.startFrom,
					playing: eventStatus.playing
				});
				if (this.showGridView) {
					this.initDataSource();
					this.initGridView();
				} else {
					this.initDataSource();
					this.initCardView();
				}
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},
	/**
	 * Handler function for the asset saved event
	 */
	onAssetGroupSaved: function () {
		this.treeList.dataSource.read();
	},
	/**
	 * Method by Andy
	 */
	adjustSectionHeight: function () {
		var section = $('.cw_section_full');
		var sectionHeight = section.height();
		section.find('.cw_section_content').css('height', sectionHeight);
		section.find('.k-grid-content').first().css('height', sectionHeight - 40);
	},
	/**
	 * Subscribes to the events
	 */
	subscribe: function () {
		this.isDataSourceSubscribed = true;
		var subscriptionObj = [{
			eventType: 'Administration',
			//Entity types: ACCOUNT, AGENT, ASSET, ASSET_GROUP, AUTHENTICATION, MONITOR, NOTIFICATION_POLICY, TEAM, TEAM_CALENDAR, USER
			entityType: 'ASSET_GROUP',
			//Optional. If entityId is specified, only events for this entity are received
			//entityId:"1b8a6722-97a5-4e85-adf1-fcd2c958ebe5"
			//Optional. If accountId is specified, events for all entities of type "entityType" in this account are received
			accountId: Cookies.CeesoftCurrentAccountId
		}];
		RemoteEventsManager.subscribe(this.subscriberId, subscriptionObj);
	},
	onEventsTimeout: function (events) {
		this.treeList.dataSource.read();
	},
	/**
	 * Destroy
	 */
	destroy: function () {
		this.saveUserPreferences();
		if (this.eventsToolbar) {
			this.eventsToolbar.destroy();
		}
		if (this.tooltipOverview) {
			this.tooltipOverview.destroy();
		}
		Application.prototype.destroy.call(this);
	}
});
