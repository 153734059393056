import React from 'react';
import ReactDOM from "react-dom";
import {Utils, applyRequired} from 'tools/utils';
import Settings from 'settings';
import Tags from 'controls/tags';
import ConfigurationBase from 'areas/service-boards/configurationBase';
import FormEntry from "controls/react/form/formEntry";
import {TagsSelect} from "controls/react/tagsSelect";

export function GridWidgetConfiguration(config) {
	Utils.apply(this, config);
	this.initComponent();
};

export {GridWidgetConfiguration as default}

jQuery.extend(GridWidgetConfiguration.prototype, ConfigurationBase.prototype, {
	/**
	 * @cfg {Object} widgetObj
	 */
	/**
	 * Main init function
	 */
	initComponent: function () {
		var html;
		var widgetObj = this.widgetObj;

		html = '<div class="cw_field"><label class="cw_inline">' + lang.TITLE + ': </label><input type="text" class="cw_widget_title cw_inline" value="' + (widgetObj.title || '') + '" /></div>';
		html += '<div class="cw_field" id="cw_grid_service_account"><label class="cw_inline">' + lang.ACCOUNT + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_grid_service_accountid" type="text" value="' + (widgetObj.configuration.accountId || '') + '"/></div></div>';
		html += '<div class="cw_field">';
		html += '<input type="checkbox" checked id="cw_service_include_subaccounts_check" class="cw_show_column k-checkbox" />';
		html += '<label for="cw_service_include_subaccounts_check" class="k-checkbox-label">' + lang.INCLUDE_SUBACCOUNTS + '</label>';
		html += '</div>';

		html += '<ul class="cw_list_columns">';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_head">' + lang.widget.COLUMN_VISIBILITY + '</div>';
		html += '</li>';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_srv_state" class="cw_show_column k-checkbox"><label for="cw_srv_state" class="k-checkbox-label">' + lang.widget.STATUS + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_srv_name" class="cw_show_column k-checkbox"><label for="cw_srv_name" class="k-checkbox-label">' + lang.NAME + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_srv_account" class="cw_show_column k-checkbox" /><label for="cw_srv_account" class="k-checkbox-label">' + lang.ACCOUNT + '</label></div>';
		html += '</li>';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_srv_health" class="cw_show_column k-checkbox"><label for="cw_srv_health" class="k-checkbox-label">' + lang.summary.SERVICE_HEALTH + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_srv_sla" class="cw_show_column k-checkbox"><label for="cw_srv_sla" class="k-checkbox-label">' + lang.widget.SLA + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_srv_incidents" class="cw_show_column k-checkbox"><label for="cw_srv_incidents" class="k-checkbox-label">' + lang.INCIDENTS + '</label></div>';
		html += '</li>';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_srv_coverage" class="cw_show_column k-checkbox"><label for="cw_srv_coverage" class="k-checkbox-label">' + lang.service.COVERAGE + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_srv_operational" class="cw_show_column k-checkbox"><label for="cw_srv_operational" class="k-checkbox-label">' + lang.service.MODE + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_srv_team" class="cw_show_column k-checkbox"><label for="cw_srv_team" class="k-checkbox-label">' + lang.TEAM + '</label></div>';
		html += '</li>';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_srv_state_duration" class="cw_show_column k-checkbox"><label for="cw_srv_state_duration" class="k-checkbox-label">' + lang.service.CURRENT_STATE + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_srv_tags" class="cw_show_column k-checkbox"><label for="cw_srv_tags" class="k-checkbox-label">' + lang.TAGS + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_srv_shared" class="cw_show_column k-checkbox"><label for="cw_srv_shared" class="k-checkbox-label">' + lang.SHARED + '</label></div>';
		html += '</li>';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_srv_description" class="cw_show_column k-checkbox"><label for="cw_srv_description" class="k-checkbox-label">' + lang.DESCRIPTION + '</label></div>';
		html += '</li>';

		html += '</ul>';
		html += '<div class="cw_separator">';
		html += '<span class="cw_separator_title">' + lang.TAGS + '</span>';
		html += '<div class="w100 left">';
		html += '<div class="cw_checkoxes_with_labels"><input type="checkbox" id="cw_untagged" class="k-checkbox" /><label for="cw_untagged" class="k-checkbox-label">' + lang.SHOW_UNTAGGED + '</label></div></div>';
		html += '<div class="left w100" id="cw_tags"></div>';
		html += '</div>';

		this.widgetConfigurationFormEl.empty().append(html);

		if (widgetObj.configuration.columns) {
			//on edit reset all and set only if in configuration
			$('.cw_show_column').prop('checked', false);

			if (widgetObj.configuration.columns.srvStateIndex && !widgetObj.configuration.columns.srvStateIndex.hidden) {
				$('#cw_srv_state').prop('checked', true);
			}
			if (widgetObj.configuration.columns.name && !widgetObj.configuration.columns.name.hidden) {
				$('#cw_srv_name').prop('checked', true);
			}
			if (widgetObj.configuration.columns.accountName && !widgetObj.configuration.columns.accountName.hidden) {
				$('#cw_srv_account').prop('checked', true);
			}
			if (widgetObj.configuration.columns.srvHealthIndex && !widgetObj.configuration.columns.srvHealthIndex.hidden) {
				$('#cw_srv_health').prop('checked', true);
			}
			if (widgetObj.configuration.columns.srvSlaIndex && !widgetObj.configuration.columns.srvSlaIndex.hidden) {
				$('#cw_srv_sla').prop('checked', true);
			}
			if (widgetObj.configuration.columns.incidentCount && !widgetObj.configuration.columns.incidentCount.hidden) {
				$('#cw_srv_incidents').prop('checked', true);
			}
			if (widgetObj.configuration.columns.coverage && !widgetObj.configuration.columns.coverage.hidden) {
				$('#cw_srv_coverage').prop('checked', true);
			}
			if (widgetObj.configuration.columns.Operational && !widgetObj.configuration.columns.Operational.hidden) {
				$('#cw_srv_operational').prop('checked', true);
			}
			if (widgetObj.configuration.columns.srvLogIndex && !widgetObj.configuration.columns.srvLogIndex.hidden) {
				$('#cw_srv_log').prop('checked', true);
			}
			if (widgetObj.configuration.columns.hasDocuments && !widgetObj.configuration.columns.hasDocuments.hidden) {
				$('#cw_srv_documents').prop('checked', true);
			}
			if (widgetObj.configuration.columns.responsibleTeamName && !widgetObj.configuration.columns.responsibleTeamName.hidden) {
				$('#cw_srv_team').prop('checked', true);
			}
			if (widgetObj.configuration.columns.stateDuration && !widgetObj.configuration.columns.stateDuration.hidden) {
				$('#cw_srv_state_duration').prop('checked', true);
			}
			if (widgetObj.configuration.columns.tags && !widgetObj.configuration.columns.tags.hidden) {
				$('#cw_srv_tags').prop('checked', true);
			}
			if (widgetObj.configuration.columns.shared && !widgetObj.configuration.columns.shared.hidden) {
				$('#cw_srv_shared').prop('checked', true);
			}
			if (widgetObj.configuration.columns.description && !widgetObj.configuration.columns.description.hidden) {
				$('#cw_srv_description').prop('checked', true);
			}
		}

		if (widgetObj.configuration.includeSubaccounts === undefined || widgetObj.configuration.includeSubaccounts === true) {
			$('#cw_service_include_subaccounts_check').prop('checked', true);
		} else {
			$('#cw_service_include_subaccounts_check').prop('checked', false);
		}

		if (widgetObj.configuration.ignoreUserTags) {
			$('#cw_ignore_user_tags').prop('checked', true);
		}
		if (widgetObj.configuration.showUntagged) {
			$('#cw_untagged').prop('checked', true);
		}

		this.removeListeners();
		this.attachListeners();

		this.initKendoComponents();

		const requiredFields = ['#cw_grid_service_accountid'];
		applyRequired(requiredFields);
	},
	/**
	 * Removes listeners
	 */
	removeListeners: function () {
		$('#cw_service_include_subaccounts_check').off();
	},
	/**
	 * Attaches listeners
	 */
	attachListeners: function () {
		$('#cw_service_include_subaccounts_check').on('click', $.proxy(this.onIncludeSubaccountsToggle, this));
	},
	/**
	 * Iniitalizes kendo components
	 */
	initKendoComponents: function () {
		this.tagsList = this.widgetObj.configuration.tags;
		this.createAccountComponent({
			element: $('#cw_grid_service_accountid'),
			container: $('#cw_grid_service_account'),
			avoidDefaultAccount: true,
			events: {
				change: function () {
					this.tagsList = [];
					this.setTagsDataSource();
				},
				dataBound: function () {
					this.setTagsDataSource();
				}
			}
		});
	},
	/**
	 * Handler function for setting the data source to tags multiselect depending of what account is selected
	 * @param {Boolean} assignValue Flag which shows if only loads the data to select or also assign values to it
	 */
	setTagsDataSource: function () {
		var selectedAccount = $('#cw_grid_service_accountid').data('kendoComboBox').value();
		var includeSubaccounts = $('#cw_service_include_subaccounts_check').is(':checked');
		ReactDOM.render(<FormEntry label={lang.TAGS} vertical style={{maxWidth: '600px'}}>
			<TagsSelect
				mode={'tags'}
				accountId={selectedAccount}
				includeSubaccounts={includeSubaccounts}
				onChange={value => this.onTagsChange(value)}
				defaultValue={this.tagsList} />
		</FormEntry>, $('#cw_tags').get()[0]);
	},
	onTagsChange(value) {
		this.tagsList = value || [];
	},
	/**
	 * Handler function for toggling on/off the include subaccounts checkbox
	 */
	onIncludeSubaccountsToggle: function () {
		this.tagsList = [];
		this.setTagsDataSource();
	},
	/*
	 * Handler function for getting the widget configuration values
	 * */
	getValues: function () {
		var title = $('.cw_widget_title').val().trim();
		var srvStateCheck = $('#cw_srv_state'),
			srvNameCheck = $('#cw_srv_name'),
			srvAccountCheck = $('#cw_srv_account'),
			srvHealthCheck = $('#cw_srv_health'),
			srvSlaCheck = $('#cw_srv_sla'),
			srvIncidentCheck = $('#cw_srv_incidents'),
			srvCoverageCheck = $('#cw_srv_coverage'),
			srvOperationalCheck = $('#cw_srv_operational'),
			srvTeamCheck = $('#cw_srv_team'),
			srvStateDurationCheck = $('#cw_srv_state_duration'),
			srvTagsCheck = $('#cw_srv_tags'),
			srvSharedCheck = $('#cw_srv_shared'),
			srvDescriptionCheck = $('#cw_srv_description');
		var widgetObj = {
			id: this.mode === 'update' || this.wasUpdate ? this.widgetObj.id : Utils.guid(),
			type: 'grid_service',
			title: title ? title : lang.widget.SERVICE,
			configuration: {
				accountId: this.widgetAccountId || $('#cw_grid_service_accountid').data('kendoComboBox').value(),
				includeSubaccounts: $('#cw_service_include_subaccounts_check').is(':checked'),
				columns: {
					srvStateIndex: {
						hidden: srvStateCheck.is(':not(:checked)')
					},
					name: {
						hidden: srvNameCheck.is(':not(:checked)')
					},
					accountName: {
						hidden: srvAccountCheck.is(':not(:checked)')
					},
					srvHealthIndex: {
						hidden: srvHealthCheck.is(':not(:checked)')
					},
					srvSlaIndex: {
						hidden: srvSlaCheck.is(':not(:checked)')
					},
					incidentCount: {
						hidden: srvIncidentCheck.is(':not(:checked)')
					},
					coverage: {
						hidden: srvCoverageCheck.is(':not(:checked)')
					},
					Operational: {
						hidden: srvOperationalCheck.is(':not(:checked)')
					},
					responsibleTeamName: {
						hidden: srvTeamCheck.is(':not(:checked)')
					},
					stateDuration: {
						hidden: srvStateDurationCheck.is(':not(:checked)')
					},
					tags: {
						hidden: srvTagsCheck.is(':not(:checked)')
					},
					shared: {
						hidden: srvSharedCheck.is(':not(:checked)')
					},
					description: {
						hidden: srvDescriptionCheck.is(':not(:checked)')
					}
				},
				tags: this.tagsList,
				ignoreUserTags: $('#cw_ignore_user_tags').is(':checked'),
				showUntagged: $('#cw_untagged').is(':checked')

			}
		};

		return widgetObj;
	},
	/*
	 * Checks if form is valid
	 * */
	isValid: function () {
		var valid = true;
		var validationMessage = '';

		var account = $('#cw_grid_service_accountid').data('kendoComboBox');
		if (!Utils.isGuid(account.value())) {
			valid = false;
			validationMessage = lang.SELECT_ACCOUNT;
		}

		return {
			valid: valid,
			validationMessage: validationMessage
		};
	}
});
