import {Utils, applyRequired} from 'tools/utils';
import Settings from 'settings';
import ConfigurationBase from 'areas/service-boards/configurationBase';
import ErrorHandler from 'core/errorHandler';
import {translator} from "core/localization";

const i = translator();

export function PreviewWidgetConfiguration(config) {
	Utils.apply(this, config);
	this.initComponent();
};

export {PreviewWidgetConfiguration as default}

jQuery.extend(PreviewWidgetConfiguration.prototype, ConfigurationBase.prototype, {
	/**
	 * @cfg {Object} widgetObj
	 */
	/**
	 * Main init function
	 */
	initComponent: function () {
		var html;
		var widgetObj = this.widgetObj;
		var zoom = !(this.widgetObj.configuration.zoom === false);

		html = '<div class="cw_field">';
		html += '<label class="cw_inline">' + lang.TITLE + ': </label>';
		html += '<input type="text" class="cw_widget_title cw_inline" value="' + (widgetObj.title || '') + '" />';
		html += '</div>';
		html += '<div class="cw_field" id="cw_preview_account">';
		html += '<label class="cw_inline">' + lang.ACCOUNT + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_preview_accountid" type="text" value="' + (widgetObj.configuration.accountId || '') + '" /></div>';
		html += '</div>';
		html += '<div class="cw_field" id="cw_preview_service">';
		html += '<label class="cw_inline">' + lang.widget.SERVICE + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_smpreview_sid" type="text" value="' + (widgetObj.configuration.serviceId || '') + '" /></div>';
		html += '</div>';
		html += '<div class="cw_field">' +
		'<label class="cw_inline">' + i('Padding') + ': </label>' +
		'<input type="text" name="sp_content_padding" class="cw_inline" value="' + (widgetObj.configuration.contentPadding || 10) + '" />' +
		'</div>'+
		'<div class="cw_field">' +
		'<label class="cw_inline">' + i('Scale') + ': </label>' +
		'<span class="cw_checkbox ' + (zoom ? "cw_checked" : "") + '">' +
		'<input name="sp_zoom" type="checkbox" class="cw_checkbox_input" ' + (zoom ? "checked" : "") + ' />' +
		'<span class="cw_checkbox_check"></span>' +
		'<span class="cw_checkbox_button"></span>' +
		'</span>' +
		'</div>';

		this.widgetConfigurationFormEl.empty().append(html);

		$('[name=sp_zoom]', this.widgetConfigurationFormEl).parent()
			.off()
			.on('click', $.proxy(this.onCheckboxClicked, this));

		this.initKendoComponents();

		const requiredFields = ['#cw_preview_accountid', '#cw_smpreview_sid'];
		applyRequired(requiredFields);
	},

	onCheckboxClicked(e) {
		var input = $(':checkbox', e.currentTarget);
		input.prop('checked', !input.is(':checked'));
		$(e.currentTarget).toggleClass('cw_checked');
	},

	initKendoComponents: function () {
		this.createAccountComponent({
			element: $('#cw_preview_accountid'),
			container: $('#cw_preview_account'),
			nextElement: $('#cw_smpreview_sid'),
			events: {
				dataSourceChange: $.proxy(function (e, length) {
					this.emptyComponent({
						containers: [{
							container: $('#cw_preview_service'),
							label: lang.SERVICE
						}],
						length: length
					});
				}, this)
			}
		});
		$('#cw_smpreview_sid').kendoComboBox({
			dataSource: new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: $.proxy(function () {
							return Settings.serverPath + 'accounts/' + (this.widgetAccountId || this.widgetObj.configuration.accountId) + '/services/lite?hasModel=true';
						}, this),
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				error: ErrorHandler.kendoServerError,
				sort: {field: 'name', dir: 'asc'}
			}),
			dataTextField: 'name',
			dataValueField: 'id',
			autoBind: this.mode === 'update' || this.wasUpdate,
			placeholder: lang.SELECT
		}).data('kendoComboBox');
	},
	/*
	 * Handler function for getting the widget configuration values
	 * */
	getValues: function () {
		var serviceCombo = $('#cw_smpreview_sid').data('kendoComboBox');
		var serviceId = serviceCombo.value();
		var title = $('.cw_widget_title').val().trim();
		var modelId = serviceCombo.dataSource.get(serviceId).modelId;
		var widgetObj = {
			id: this.mode === 'update' || this.wasUpdate ? this.widgetObj.id : Utils.guid(),
			type: 'smpreview',
			title: title,
			configuration: {
				accountId: this.widgetAccountId || $('#cw_preview_accountid').data('kendoComboBox').value(),
				accountName: this.widgetAccountName,
				serviceId: serviceId,
				modelId: modelId,
				contentPadding: $(':input[name=sp_content_padding]').val() || 10,
				zoom: $(':input[name=sp_zoom]:checked').length === 1 ? true : false,
			}
		};

		return widgetObj;
	},
	/*
	 * Checks if form is valid
	 * */
	isValid: function () {
		var valid = true;
		this.validationMessage = '';

		var aCombo = $('#cw_preview_accountid').data('kendoComboBox');
		var smCombo = $('#cw_smpreview_sid').data('kendoComboBox');
		if (!Utils.isGuid(aCombo.value())) {
			valid = false;
			this.validationMessage = lang.widget.SELECT_ACCOUNT;
		}

		if (!Utils.isGuid(smCombo.value())) {
			valid = false;
			this.validationMessage = lang.widget.SELECT_SERVICE_MODEL;
		}

		return {
			valid: valid,
			validationMessage: this.validationMessage
		};
	}
});
