import React from "react";
import ReactDOM from "react-dom";

import Utils from 'tools/utils'
import State from 'tools/state'
import {saveUserPrefs} from "tools/userPrefs";
import Favorites from 'tools/favorites'
import {RemoteEventsManager} from "core/remoteEventsManager";
import SelectAccountForm from 'controls/selectAccountForm';
import {AgentsRouter} from 'areas/management/agents/bundleDescription';
import MonitorsRouter from 'areas/assets/monitors/router';
import Renderer from 'tools/renderer';
import Configuration from "../configuration";
import {AssetGroupRouter} from "areas/assetgroups/bundleDescription";

export let Application = function () {
	// set here default general properties if needed
	// this.initComponent();
	this.pagers = [];
};

export default Application;

Application.prototype = {
	/**
	 * @property
	 * @type string
	 */
	type: 'Application',
	hasEvents: false,

	execute: function (config) {
		Utils.apply(this, config);

		this.initModule();

		if (this.removeListeners != null)
			this.removeListeners();

		if (this.attachListeners != null)
			this.attachListeners();
	},

	/**
	 * Basic application related initializations
	 */
	initComponent: function () {
		this.pagers = [];

		this.addMask();

		if (this.hasEvents) {
			if (this.subscribe) {
				this.subscribe();
			}
		}

		this.checkFavorites();
		$('body').off().on('click', '.cw_favorites', $.proxy(this.onFavoritesIcon, this));
	},
	/*
	* Called to handle events when no need to define it in specific views
	* */
	handleEvents: function (events) {
		if (this.eventsToolbar) {
			this.eventsToolbar.addEvents(events);
		} else {
			if (this.onEvent) {
				this.onEvent(events);
			}
		}
	},
	/*
	 * Handler function for adding content mask
	 */
	addMask: function () {
		$('#content_area').append('<div class="cw_white_mask"></div>');
	},
	checkFavorites: function (name) {
		let currentSection;
		if (name) {
			currentSection = name;
		} else {
			if (State.mainApp && State.mainApp.breadcrumb) {
				currentSection = State.mainApp.breadcrumb.items.length ? State.mainApp.breadcrumb.items[State.mainApp.breadcrumb.items.length - 1].application : State.mainApp.breadcrumb.module.application;
				if (currentSection === 'ManagementAgents' && this.viewType === 'AgentDetails') {
					currentSection = 'ManagementServer';
				}
			}
		}
		for (var i = 0; i < Favorites.length; i++) {
			if (currentSection === Favorites[i].item.application) {
				$('.window_titlebar').find('.cw_favorites').addClass('gold');
				$('#content_area').find('.grid_favorite_item').addClass('gold');
			}
		}
	},
	/*
	 * Handler function for removing the content mask
	 */
	removeMask: function () {
		State.mainApp?.removeLoadingMask();
	},

	/**
	 * Handler for clicking on add to favorites icon
	 * @param e
	 */
	onFavoritesIcon: function (e, name) {
		var target = $(e.currentTarget);
		if (target.hasClass('gold')) {
			target.removeClass('gold');
			this.removeFromFavorites(name);
		} else {
			target.addClass('gold');
			this.addToFavorites(name);
		}
	},
	/**
	 * Add the item to favorite list
	 */
	addToFavorites: function (name) {
		let currentItem, itemId, breadcrumbConfig;
		if (!name) {
			currentItem = State.mainApp.breadcrumb.module;
			if (State.mainApp.breadcrumb.items.length) {
				currentItem = State.mainApp.breadcrumb.items[State.mainApp.breadcrumb.items.length - 1];
			}
			if (currentItem.application === 'AssetGroupSummaryView') {
				currentItem.name = lang.widget.GROUP_SUMMARY;
			}
			if (currentItem.application === 'AssetHealthSummary') {
				currentItem.name = lang.widget.ASSET_SUMMARY;
			}
			itemId = $('.aside').find('.current[data-state!="closed"]').not('.cw_favorites_menu_item').attr('id');
			breadcrumbConfig = State.mainApp.breadcrumb.module;
		} else {
			itemId = name;
			let obj = {
				application: name,
				name: name,
				text: name,
				config: {
					moduleName: name
				},
				reactModule: true
			}
			currentItem = obj;
			breadcrumbConfig = obj;
		}
		Favorites.push({
			id: itemId,
			item: currentItem,
			breadcrumbConfig: breadcrumbConfig
		});
		this.saveFavoritesPrefs();
	},
	saveFavoritesPrefs: function () {
		var preferences = [{
			key: 'favorites',
			value: JSON.stringify(Favorites)
		}];
		this.saveUserPrefs({
			category: 'FavoriteItems',
			preferences: preferences
		});
	},
	/**
	 * Handler for removing an item from favorites
	 * @param e
	 */
	removeFromFavorites: function (name) {
		let itemName = name || State.mainApp.breadcrumb.currentItem.application || State.mainApp.breadcrumb.module.application;
		if (itemName === 'ManagementAgents' && this.viewType === 'AgentDetails') {
			itemName = 'ManagementServer';
		}
		for (var i = 0; i < Favorites.length; i++) {
			if (itemName === Favorites[i].item.application) {
				Favorites.splice(i, 1);
			}
		}
		this.saveFavoritesPrefs();
		State.mainApp.breadcrumb.currentItem = {};
	},
	/**
	 * Loads favorite items
	 * @param {Object} e The object sent by event manager
	 */
	loadFavoriteItems: function (e) {
		this.favoriteItems = e.favoriteItems;
	},
	/**
	 * Handler function for the click event on an breadcrumb item
	 * @param {Object} e The click event object
	 */
	onBreadCrumbItemClick: function (e) {
		var item = $(e.currentTarget),
			module = item.attr('data-module'),
			id = item.attr('data-id') || '',
			config = item.data('ceeviewBreadcrumb');// || ceeview.breadcrumb.module.config;

		if (!item.hasClass('current')) {
			State.mainApp.loadModule(module, id, config, e);
		}
	},

	/**
	 * Called after the top toggle is pressed
	 * @param {Boolean} includeSubaccounts
	 */
	onIncludeSubaccounts: function (includeSubaccounts) {
		var control;
		if (this.grid) {
			control = this.grid;
		} else if (this.treeList) {
			control = this.treeList;
		}
		if (control) {
			var url;
			if (control.dataSource.options.transport) {
				if (typeof control.dataSource.options.transport.read.url === 'function') {
					url = control.dataSource.options.transport.read.url();
				} else {
					url = control.dataSource.options.transport.read.url;
				}
			}
			//quickfix for controls which have not the transport option
			if (!url) {
				if (this.treeList) {
					//asset group summary tree list
					url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/health/assetGroups?includeSubaccounts=' + State.includeSubaccounts;
				}
			}
			if (url.indexOf('includeSubaccounts') !== -1) {
				url = url.replace(/includeSubaccounts=(true|false)/, 'includeSubaccounts=' + includeSubaccounts);
			} else {
				url += url.indexOf('?') !== -1 ? '&' : '?';
				url += 'includeSubaccounts=' + includeSubaccounts;
			}

			if (this.showGridView) {
				if (includeSubaccounts) {
					control.showColumn('accountName');
				} else {
					control.hideColumn('accountName');
				}
			}
			if (control.dataSource.options.transport) {
				control.dataSource.options.transport.read.url = url;
				if (this.module && this.module.name === 'AssetGroupSummaryView') {
					this.treeListRefreshed = true;
				}

				//if unchecked, remove accountName from filtering and sorting
				let currentFilter = control.dataSource.filter();
				let currentSort = control.dataSource.sort();
				if (!includeSubaccounts) {
					if (currentFilter && currentFilter.filters) {
						let filters = currentFilter.filters;
						for (let i = 0; i < filters.length; i++) {
							if (filters[i].field === 'accountName') {
								currentFilter.splice(i, 1);
							} else if (filters[i].filters) {
								let subfilters = filters[i].filters;
								for (let j = 0; j < subfilters.length; j++) {
									if (subfilters[j].field === 'accountName' && !State.includeSubaccounts) {
										subfilters.splice(j, 1);
									}
								}
							}
						}
					}
					if (currentSort) {
						for (let i = 0; i < currentSort.length; i++) {
							if (currentSort[i].field === 'accountName') {
								currentSort.splice(i, 1);
							}
						}
					}
				}

				let searchValue = $('.cw_search_box').val();
				let searchFilterFound;
				if (currentFilter && searchValue && includeSubaccounts) {
					//if checked and we have a search value then accountName should be included in grid search filtering
					if (currentFilter.filters) {
						let filters = currentFilter.filters;
						for (let i = 0; i < filters.length; i++) {
							if (filters[i].filters) {
								let subfilters = filters[i].filters;
								for (let j = 0; j < subfilters.length; j++) {
									if (subfilters[j].value === searchValue) {
										searchFilterFound = true;
									}
								}
								if (searchFilterFound) {
									subfilters.push({
										field: 'accountName',
										operator: 'contains',
										value: searchValue
									})
								}
							}
						}
					}
				}

				control.dataSource.read();
			}
			if (this.subscribe) {
				this.subscribe();
			}
		}
	},
	/**
	 * Default method for switching between modules using non-stardand navingation links. Must be overwritten where necessary (mostly application views with widgets)
	 * @param {String} moduleName
	 * @param {String} id
	 * @param {Object} config
	 */
	tryExit: function (moduleName, id, config) {
		if (State.mainApp) {
			State.mainApp.loadModule(moduleName, id, config);
		}
	},
	/*
	 * Handler function for opening opening monitor details, configuration or form
	 * @param {Integer} monitorId Monitor id
	 * @param {String} monitorType Monitor type
	 * @param {Boolean} highlight Boolean for highlighting the monitor when redirecting back (Optional) (Default: true)
	 * @param {Object} event The click event
	 * @param {Boolean} isFromAllGrid Flag to know if the monitor is opened by clicking on name on all monitors grid
	 * */
	openMonitorView: function (monitorId, monitorType, highlight, event, monitorName, isFromAllGrid) {
		openMonitorView(monitorId, monitorType, highlight, event, monitorName, isFromAllGrid);
	},


	/*
	 * Handler function for setting tooltip on monitor name
	 * @param {Object} grid The kendo grid object
	 */
	setMonitorNameTooltip: function (grid) {
		var gridContent = $(grid.wrapper).find('.k-grid-content');
		gridContent.kendoTooltip({
			filter: "td.tooltip:not(:empty)",
			content: function (e) {
				var description;
				var uid = $(e.target).closest('tr').data('uid'), item = grid.dataSource.getByUid(uid);

				description = item.monitorDescription || item.description;
				return description.toString().split("\n").join("<br />");
			}
		});
	},
	/*
	 * Handler function for setting the highlight item
	 * @param {Object} e The event click object
	 * */
	setHighlightItem: function (e) {
		var target = $(e.currentTarget), itemId = target.closest('tr').find('.cw_grid_check').data('id');

		State.mainApp.context.navigation.updateCurrentItem({
			highlightItemId: itemId
		});
	},
	/*
	 * Handler function for highlighting the item
	 * @param {Object} grid The grid object
	 * */
	highlightItem: function (grid) {
		if (this.highlightItemId) {
			var wrapper = $(grid.wrapper);
			var row = wrapper.find(".cw_grid_check[data-id='" + this.highlightItemId + "']").closest('tr');
			if (row.length) {
				row.removeClass('k-alt');
				grid.select(row);
				var rowPos = row.position();
				wrapper.find('.k-grid-content').scrollTop(rowPos.top);
				//this.highlightItemId = null;
			}
		}
	},
	/*
	 * Handler function for setting highlighted monitor
	 * @param {String} monitorId
	 */
	setHighlightedMonitor: function (monitorId) {
		State.mainApp.context.navigation.updateCurrentItem({
			highlightMonitorId: monitorId
		});
	},
	/*
	 * Handler function for highlighting an monitor item in grid
	 * @param {Object} grid The grid object
	 */
	highlightMonitor: function (grid) {
		if (this.highlightMonitorId) {
			var wrapper = $(grid.wrapper);
			var row = wrapper.find(".cw_grid_link[data-id='" + this.highlightMonitorId + "']").closest('tr');
			row.removeClass('k-alt');
			grid.select(row);

			var rowPos = row.position();
			wrapper.find('.k-grid-content').scrollTop(rowPos.top);

			this.highlightMonitorId = null;
		}
	},
	/*
	 * Handler function for service qualifier agent name click
	 * @param {Object} e The click event
	 * */
	onAgentNameClick: function (e) {
		var target = $(e.currentTarget);
		var agentId = target.data('agentid');
		if (Utils.canEditAgent(agentId)) {
			State.mainApp.navigate(AgentsRouter.details(agentId));
		}
	},

	onTargetNameClick: function (e) {
		let target = $(e.currentTarget);
		let targetId = target.data('targetid');
		let targetType = target.data('targettype');
		if (targetType === 'ASSET') {
			State.mainApp.loadModule('AssetDetails', targetId, {
				id: targetId
			}, e);
		} else if (targetType === 'ASSET_GROUP') {
			State.mainApp.loadModule('AssetGroupSummaryView', '', {
				idToBeSelected: targetId
			}, e);
		}
	},
	/*
	 * Handler function for service qualifier asset name click
	 * @param {Object} e The click event
	 * */
	onSqAssetNameClick: function (e) {
		var target = $(e.currentTarget);
		var assetId = target.data('id');
		var assetName = target.data('name');
		var accountId = target.data('assetaccountid');
		var accountName = target.data('assetaccountname');
		State.mainApp.loadModule('AssetDetails', assetId, {
			id: assetId,
			name: assetName,
			accountId: accountId,
			accountName: accountName
		}, e);
	},
	/*
	 * Handler function for service qualifier asset group name click
	 * @param {Object} e The click event
	 * */
	onSqAssetGroupNameClick: function (e) {
		var target = $(e.currentTarget);
		var assetGroupId = target.data('id');
		State.mainApp.navigate(AssetGroupRouter.details(assetGroupId));
	},
	/*
	 * Handler function for service qualifier monitor name click
	 * @param {Object} e The click event
	 * */
	onSqMonitorNameClick: function (e) {
		var target = $(e.currentTarget), monitorId = target.data('id'), monitorType = target.data('type');
		this.openMonitorView(monitorId, monitorType, false, e);
	},
	/*
	* Handler function for setting the error tooltip
	* @param {Object} handler The jQuery handler where to bind the tooltip
	* */
	setErrorToolTip: function (handler) {
		if (!this.tooltipOverview) {
			this.tooltipOverview = Renderer.errorToolTip(handler);
		}
	},
	/*
	 * Save user preferences
	 * @param {Object} config The configuration object
	 * */
	saveUserPrefs: function (config) {
		saveUserPrefs(config, this.eventsToolbar, this.userPref);
	},
	/**
	 * Unsubscribe
	 */
	unsubscribe: function () {
		if (this.subscriberId) {
			RemoteEventsManager.unsubscribe(this.subscriberId);
		}
		this.isDataSourceSubscribed = false;
	},

	destroyWindows() {
		let windows = $('.k-widget.k-window').find('.k-window-content');
		$(windows).each(function() {
			$(this).data('kendoWindow').destroy();
		});
	},

	/**
	 * Abstract. To be overwritten
	 */
	destroy: function () {
		if (this.pagers) {

			while (this.pagers.length) {
				if (this.pagers[0]) {
					this.pagers[0].destroy();
				}
				this.pagers.splice(0, 1);
			}
		}

		if ($(this.indicatorSelector).length) {
			ReactDOM.unmountComponentAtNode($(this.indicatorSelector).get(0));
		}

		// $('.k-animation-container.km-popup').remove();
		if ($('body >.k-list-container') && $('body > .k-list-container').length) {
			$('body >.k-list-container').remove();
		}
		if ($('body > .k-animation-container') && $('body > .k-animation-container').length) {
			$('body > .k-animation-container').remove();
		}
		if (this.grid) {
			let tooltip = $('.k-grid-content').data('kendoTooltip');
			if (tooltip) {
				tooltip.destroy();
			}
		}
		if (this.hasEvents) {
			this.unsubscribe();
		}

		if (this.mode === 'update' || this.mode === 'edit') {
			if (this.id) {
				RemoteEventsManager.discard(this.id);
			}
		}
	}
}

export function openMonitorView(monitorId, monitorType, highlight, event, monitorName, isFromAllGrid) {
	if (highlight === undefined) {
		highlight = true;
	}
	var data = {};
	var modules = {
		SYSTEM: 'MonitorSystem',
		NIMSOFT: 'MonitorNimsoft',
		PROCESSES: 'MonitorProcess',
		WINSERVICE: 'MonitorService',
		EXTERNAL: 'MonitorExternal',
		WINEVENTS: 'MonitorEventlog',
		VMWARE: 'MonitorVMware',
		VMM: 'MonitorVmm',
		HYPERV: 'MonitorHyperv',
		NAGIOS: 'MonitorNagios',
		IBMSVC: 'MonitorIbmStorwize',
		GROOVY: 'MonitorGroovy',
		MSCLUSTER: 'MonitorMScluster',
		MAILGTW: 'MonitorMailGateway',
		PRTG: 'MonitorPRTG',
		SOLARWINDS: 'MonitorSolarWinds',
		CONNECTIVITY: 'MonitorConnectivity',
	};

	data.id = monitorId;
	data.mode = 'update';
	if (monitorName) {
		data.name = monitorName;
	}

	var moduleName = modules[monitorType];
	if (moduleName) {
		if (highlight) {
			Application.prototype.setHighlightedMonitor(monitorId);
			data.redirectBack = true;
		}
		data.isFromAllGrid = isFromAllGrid;
		State.mainApp.loadModule(moduleName, monitorId, data, event);
	} else {
		let url = MonitorsRouter.details(monitorType, monitorId);
		if(url){
			State.mainApp.navigate(url);
		}else {
			Utils.showInfo(lang.ALERT, 'No monitor found');
		}
	}
}
