import {translator} from "core/localization/localization";
import {TOTAL_METRIC_TYPE} from './totalMetricDataSource';

const i = translator({
	'Metric Unit': {
		no: 'Metrikk enhet'
	},
	'Total Unit': {
		no: 'Total metrikk'
	},
	'Custom Unit': {
		no: 'Tilpasset enhet'
	}
});

export const DISPLAY_UNIT = {
	NONE: 'NONE',
	METRIC_UNIT: 'METRIC_UNIT',
	TOTAL_UNIT: 'TOTAL_UNIT',
	CUSTOM_UNIT: 'CUSTOM_UNIT'
}

export default function displayUnitDataSource(totalMetricType) {
	const values = [
		{
			text: i('None'),
			value: DISPLAY_UNIT.NONE
		},
		{
			text: i('Metric Unit'),
			value: DISPLAY_UNIT.METRIC_UNIT
		}
	];

	totalMetricType === TOTAL_METRIC_TYPE.METRIC && values.push({
		text: i('Total Unit'),
		value: DISPLAY_UNIT.TOTAL_UNIT
	});

	values.push({
		text: i('Custom Unit'),
		value: DISPLAY_UNIT.CUSTOM_UNIT
	});

	return values;
}
