import moment from 'moment';
import Highcharts from 'highcharts';

import {Cookies, RemoteEventsManager, LocalEventsManager} from 'core';
import Widget from 'areas/service-boards/widget';
import Settings from 'settings';
import {Renderer, State, Utils} from 'tools';
import ErrorCodes from 'tools/errorCodes';
import Configuration from 'configuration';
import {MultiSelectGridFilter, CustomNotification} from 'controls';
import {ServicesRouter} from 'areas/services/bundleDescription';
import {getSeverityState} from 'controls/stateRenderer/severityState';
import ModalWindow from 'controls/modalWindow';
import ServiceLogDetailsView from 'areas/services/logDetailsView';
import {getLegendLabelFormatter, getTrendValueWidgetWrapper} from "vendor-init/hightcharts-intergation";

export function MetricsWidget(config) {
    this.onZoom = false;
	Widget.call(this, config);

	this.aggregate = false;
	this.requestPath = Settings.serverPath;
	if (this.sessionId) {
		this.requestPath = Settings.serverPath + 'sessions/' + this.sessionId + '/';
	}
	this.instanceConfiguration.timezone = this.instanceConfiguration.timezone === "<User Time>" ? Cookies.CeesoftTimezone : this.instanceConfiguration.timezone;
	this.loadErrorCodes();
};

export {MetricsWidget as default};

jQuery.extend(MetricsWidget.prototype, Widget.prototype, {
	/**
	 * Main init function
	 */
	initComponent: function () {
		var widgetContentDiv;

		this.isViewer = this.isViewer || false;
		if (this.isViewer) {
			//this.instanceConfiguration.showThreshold = true;
			this.hasChartTypeSelector = true;
		}
		//this.hasTimeSelector = true;
		this.hasToggleTimeSelector = true;
		this.hasMissingData = true;
		this.hasRegression = true;
		this.hasHideErrors = true;
		this.hasChartTypeSelector = true;
		this.hasInfoSign = true;

		if (!this.renderTo) {
			this.renderTo = $('#' + this.id).find('.cw_section_content');
			widgetContentDiv = $('#' + this.id).find('.cw_section_content');
		} else {
			widgetContentDiv = $('#' + this.renderTo);
		}

		this.widgetContentDiv = widgetContentDiv;

		var widgetDiv = $('#' + this.id);
		this.qualifierLoaded = false;

		widgetDiv.off().on('drop', $.proxy(this.onDrop, this));

		if (!this.noTitleNeeded) {
			//Provided backward compatibility
			if (this.instanceConfiguration.type !== 'MONITOR') {
				this.getData();
			} else {
				this.updateTitle();
				this.loadMonitorQualifierData();
			}
		}
		this.updatePeriodSelector();

		this.statusNotification = new CustomNotification({
			animationTime: 0,
			appendToElement: widgetContentDiv,
		});
	},
	/**
	 * Retrieves data for creating the widget and legend
	 * @param {Boolean} noSubscription
	 * @param {String} forcedTimeSelector Time selector for URL, for the case when the previous request with a low range time selector provided no data
	 */
	getData: function (noSubscription, forcedTimeSelector) {
		var widgetContentDiv = $('#' + this.id).find('.cw_section_content'), startDate, endDate, interval;
		var width = widgetContentDiv.width() - 150, target;
		if (!$('#' + this.id).length && this.customControls && this.customControls.target) {
			width = this.customControls.target.width();
		}
		// Quickfix by bogdan because in the viewer the widgetContentDiv.width() is
		// zero.
		if (width <= 0) {
			width = 300;
		}

		const periodConfig = {
			period: this.zoomPeriod || this.instanceConfiguration.period,
			startDate: this.zoomStartDate || +this.instanceConfiguration.startDate,
			endDate: this.zoomEndDate || +this.instanceConfiguration.endDate,
			width: width
		};

		if (periodConfig.period === 'CUSTOM' && !periodConfig.startDate && !periodConfig.endDate) {
			periodConfig.period = 'LASTDAY';
			this.instanceConfiguration.period = 'LASTDAY';
			periodConfig.startDate = null;
			periodConfig.endDate = null;
		}

		var periodTranslationObj = {
			'LASTHOUR': 'LASTDAY',
			'LASTDAY': 'LAST7DAYS',
			'LAST7DAYS': 'LAST30DAYS'
		};
		var period = Utils.getPeriodInterval({
			period: this.zoomPeriod || this.instanceConfiguration.period,
			startDate: this.zoomStartDate || +this.instanceConfiguration.startDate,
			endDate: this.zoomEndDate || +this.instanceConfiguration.endDate,
			width: width
		});
		this.interval = period.interval;
		this.roundRobinPoints = width;

		var urlPath, data = [], url;
		urlPath = 'serviceQualifiers';
		if (this.instanceConfiguration.serviceQualifierId) {
			data.push(this.instanceConfiguration.serviceQualifierId);
		}

		var ignoreMissingData;
		if (this.instanceConfiguration.ignoreMissingData !== undefined) {
			ignoreMissingData = this.instanceConfiguration.ignoreMissingData;
		} else {
			ignoreMissingData = this.defaultIgnoreMissingData;
		}
		if (this.zoomPeriod === 'CUSTOM' || this.instanceConfiguration.period === 'CUSTOM') {
			startDate = new Date(this.zoomStartDate || +this.instanceConfiguration.startDate);
			endDate = new Date(this.zoomEndDate || +this.instanceConfiguration.endDate);
			url = this.requestPath + 'metrics/' + urlPath + '?ignoreMissingData=' + ignoreMissingData + '&timeZone=' + this.instanceConfiguration.timezone + '&interval=' + this.interval + '&startDate=' + startDate.getTime() + '&endDate=' + endDate.getTime();
		} else {
			var timeSelector;
			if (forcedTimeSelector) {
				timeSelector = forcedTimeSelector;
			} else {
				timeSelector = this.instanceConfiguration.period;
				if (forcedTimeSelector === false) {
					this.doNotTranslatePeriod = true;
				}
			}
			url = this.requestPath + 'metrics/' + urlPath + '?ignoreMissingData=' + ignoreMissingData + '&timeZone=' + this.instanceConfiguration.timezone + '&interval=' + this.interval + '&timeSelector=' + timeSelector;
		}
		var widgetContentDiv;
		if (!this.renderTo) {
			widgetContentDiv = $('#' + this.id).find('.cw_section_content');
		} else {
			if (typeof this.renderTo === 'string') {
				widgetContentDiv = $('#' + this.renderTo);
			} else {
				widgetContentDiv = $(this.renderTo);
			}

		}

		kendo.ui.progress(widgetContentDiv, true);
		Utils.ajax(url, 'POST', JSON.stringify(data), $.proxy(function (result) {
			if (result && result.length) {
				if (result[0].found && result[0].hasMetrics) {
					if (result[0].qualifier) {
						this.serviceElementName = result[0].qualifier.elementName;
						this.serviceName = result[0].qualifier.serviceName;
						this.qualifier = result[0].qualifier;
						this.qualifierLoaded = true;
						if (this.qualifier.configuration && this.qualifier.configuration.algorithm.type === 'StaticThresholdAlgorithm') {
							this.hasThreshold = true;
						}
						if (this.instanceConfiguration.period !== 'CUSTOM') {
							this.subscribe(true);
						}
						this.data = [];
						var fullName, name;
						this.seriesItem = {};
						fullName = this.generateMultigraphLabel(result[0].qualifier);
						name = fullName.length > 60 ? fullName.substr(0, 57) + '...' : fullName;
						this.seriesItem.name = name;
						this.seriesItem.fullName = fullName;
						this.seriesItem.visible = true;
					}

					if (result[0].data && result[0].data.length) {
						this.chartData = result[0].data;
						this.newTranslatedPeriod = null;
						this.render(this.chartData);
						if (!noSubscription) {
							this.releaseEvents();
						}
					} else {
						if (!noSubscription) {
							this.releaseEvents(true);
						}
						if (this.onZoom) {
							kendo.ui.progress(widgetContentDiv, false);
							this.statusNotification.setOptions({
								message: lang.widget.messages.NO_ZOOM_AVAILABLE,
								status: 'error',
								hideOnClick: true,
								style: 'left: 70px'
							}).show();
						} else {
							if (this.isViewer && !this.doNotTranslatePeriod && this.instanceConfiguration.period !== 'LAST30DAYS' && this.newTranslatedPeriod !== 'LAST30DAYS' && this.instanceConfiguration.period !== 'CUSTOM') {
								if (this.newTranslatedPeriod) {
									this.newTranslatedPeriod = periodTranslationObj[this.newTranslatedPeriod];
								} else {
									this.newTranslatedPeriod = periodTranslationObj[this.instanceConfiguration.period];
								}
								this.instanceConfiguration.period = this.newTranslatedPeriod;
								this.getData(null, this.newTranslatedPeriod);
							} else {
								widgetContentDiv.empty();
								kendo.ui.progress(widgetContentDiv, false);
								this.statusNotification.setOptions({
									message: result.message || lang.serviceBoard.messages.NO_DATA,
									status: 'error'
								}).show();
							}
							if (this.doNotTranslatePeriod) {
								this.newTranslatedPeriod = this.instanceConfiguration.period;
							}
						}
					}
					this.updateTitle();
				} else {
					//if the result has no data we try to change the selector to a wider period
					if (this.isViewer && !this.doNotTranslatePeriod && this.instanceConfiguration.period !== 'LAST30DAYS' && this.newTranslatedPeriod !== 'LAST30DAYS' && this.instanceConfiguration.period !== 'CUSTOM') {
						if (this.newTranslatedPeriod) {
							this.newTranslatedPeriod = periodTranslationObj[this.newTranslatedPeriod];
						} else {
							this.newTranslatedPeriod = periodTranslationObj[this.instanceConfiguration.period];
						}
						this.instanceConfiguration.period = this.newTranslatedPeriod;
						this.getData(null, this.newTranslatedPeriod);
					} else {
						var isQualifierMissing = true;
						this.updateTitle(isQualifierMissing);
						if (!this.onZoom) {
							widgetContentDiv.empty();
						}
						kendo.ui.progress(widgetContentDiv, false);
						this.statusNotification.setOptions({
							message: result.message || lang.serviceBoard.messages.NO_DATA,
							status: 'error',
							hideOnClick: true,
							style: this.onZoom ? 'left: 70px' : ''
						}).show();
					}
					if (this.doNotTranslatePeriod) {
						this.newTranslatedPeriod = this.instanceConfiguration.period;
					}
				}
			} else {
				if (this.isViewer && !this.doNotTranslatePeriod && this.instanceConfiguration.period !== 'LAST30DAYS' && this.newTranslatedPeriod !== 'LAST30DAYS' && this.instanceConfiguration.period !== 'CUSTOM') {
					if (this.newTranslatedPeriod) {
						this.newTranslatedPeriod = periodTranslationObj[this.newTranslatedPeriod];
					} else {
						this.newTranslatedPeriod = periodTranslationObj[this.instanceConfiguration.period];
					}
					this.getData(null, this.newTranslatedPeriod);
				} else {
					if (!this.onZoom) {
						widgetContentDiv.empty();
					}
					kendo.ui.progress(widgetContentDiv, false);
					this.statusNotification.setOptions({
						message: result.message || lang.serviceBoard.messages.NO_DATA,
						status: 'error'
					}).show();
				}
				if (this.doNotTranslatePeriod) {
					this.newTranslatedPeriod = this.instanceConfiguration.period;
				}
			}

			if (!this.customControls) {
				this.customControls = {
					target: this.isViewer ? this.widgetContentDiv.parent() : '#' + this.id,
					toggleClick: function (value) {
						if ($('#' + this.id).find('.k-i-toggle').length) {
							$('#' + this.id).find('.k-i-toggle').trigger('click');
						} else {
							$('#' + this.id).closest('.k-window').find('.k-i-toggle').trigger('click');
						}
					}
				};
			}
			this.createCustomControls();

		}, this), null, 60000, $.proxy(function () {
			kendo.ui.progress(widgetContentDiv, false);
			this.statusNotification.setOptions({
				message: lang.messages.NO_DATA_AVAILABLE,
				status: 'error'
			}).show();
		}, this));
	},
	/**
	 /**
	 * Retrieves monitor qualifier configuration from the server
	 */
	loadMonitorQualifierData: function () {
		this.qualifier = {};
		this.qualifier.configuration = this.instanceConfiguration.qualifier;
		//compatibility fixes
		this.qualifier.configuration.assetName = this.instanceConfiguration.assetName;
		this.qualifier.configuration.algorithm = {
			type: 'Available'
		};
		this.qualifierLoaded = true;
		if (this.instanceConfiguration.period !== 'CUSTOM') {
			this.subscribe();
		} else {
			this.getData();
		}
	},
	/**
	 * Updates the widget title
	 */
	updateTitle: function (isQualifierMissing) {
		var title, strTitle;
		var titleSpan = this.isKendoWindow ? $('#' + this.id).closest('.k-window').find('.k-window-title') : $('#' + this.id).find('.cw_section_title');
		//provide backward compatibility
		if (this.instanceConfiguration.type !== 'MONITOR') {
			if (!this.title && (this.qualifierLoaded || isQualifierMissing)) {
				var serviceName = this.serviceName || '';
				var serviceElementName = this.serviceElementName || '';
				if (this.qualifier) {
					var qualifierName = this.qualifier.name || '';
				}
				if (serviceName || serviceElementName || qualifierName) {
					title = serviceName + ' \u00bb ' + serviceElementName + ' \u00bb ' + qualifierName;
					strTitle = serviceName + ' \u00bb ' + serviceElementName + ' \u00bb ' + qualifierName;
				} else {
					title = lang.SINGLEGRAPH;
					strTitle = lang.SINGLEGRAPH;
				}
			}
		} else {
			title = this.instanceConfiguration.monitorName + ' &raquo; ' + this.instanceConfiguration.instanceName + ' &raquo; ' + this.instanceConfiguration.systemQualifierName;
			strTitle = title.split('&raquo;').join('\u00bb'); //this.serviceName + ' \u00bb ' + this.serviceElementName + ' \u00bb ' + this.qualifier.name;
		}
		titleSpan.html(this.title || title);
		titleSpan.attr('title', this.title || strTitle);
	},
	/**
	 * Creates the chart based on the received dataset
	 * @param {Object} dataSet
	 * @param {String} noDataMessage (Optional) The message to display when there is no data. Default to ''.
	 */
	render: function (dataSet, noDataMessage) {
		noDataMessage = noDataMessage || '';
		var valueLabelsTemplate, valueLabelsFormat, series, tooltip, tooltipLow, tooltipHigh, set, i,
			noDataState, v, averageSet, min, max, widgetContentDiv, zoom, length;
		if (!this.renderTo) {
			widgetContentDiv = $('#' + this.id).find('.cw_section_content');
		} else {
			if (typeof this.renderTo === 'string') {
				widgetContentDiv = $('#' + this.renderTo);
			} else {
				widgetContentDiv = $(this.renderTo);
			}
		}

		widgetContentDiv.addClass('service-boards-metricsWidget').empty().append('<div class="metric_chart"></div>');
		this.dataSet = dataSet;
		this.data = [];
		this.errors = [];
		this.average = [];
		this.noData = [];
		this.aggregate = false;
		this.isBooleanMetric = this.qualifier.configuration.metricType === 'BOOLEAN';
		if (this.dataSet.length && this.dataSet[0].vH !== undefined) {
			this.aggregate = true;
		}
		if (this.isBooleanMetric) {
			min = 0;
			max = 1;
		} else {
			if (this.dataSet.length && this.dataSet[0].vL !== undefined && this.instanceConfiguration.chartType === 'range') {
				min = Utils.getMinFromObjectArray(this.dataSet, 'vL');
				max = Utils.getMaxFromObjectArray(this.dataSet, 'vH');
			} else {
				min = Utils.getMinFromObjectArray(this.dataSet, 'v');
				max = Utils.getMaxFromObjectArray(this.dataSet, 'v');
			}
		}
		if (this.instanceConfiguration.chartType === 'area') {
			min = 0;
		}
		if (this.dataSet.length) {
			noDataState = (this.dataSet[0].v === null);
		}
		for (i = 0, length = dataSet.length; i < length; i++) {
			set = [];
			if (dataSet[i].v === null) {
				this.noData.push([dataSet[i].t, min, max]);
				noDataState = true;
			} else {
				if (noDataState) {
					this.noData.push([dataSet[i].t, min, max]);
					noDataState = false;
				} else {
					if (!this.noData.length || this.noData[this.noData.length - 1][1] !== null) {
						this.noData.push([dataSet[i].t, null, null]);
					}
				}
			}
			if (this.aggregate && this.instanceConfiguration.chartType === 'range') {
				set = [dataSet[i].t, dataSet[i].vL, dataSet[i].vH];
				averageSet = [dataSet[i].t, dataSet[i].v];
				this.average.push(averageSet);
			} else {
				set = [dataSet[i].t, Utils.aggregate(this.instanceConfiguration.aggregationType, dataSet[i])];
			}

			if (!this.instanceConfiguration.hideErrors) {
				if (dataSet[i].e[0] !== 0) {
					this.errors.push({
						x: dataSet[i].t,
						y: 0,
						marker: {
							enabled: true,
							fillColor: '#FF0000',
							symbol: 'circle',
							radius: 4,
							states: {
								hover: {
									radius: 6
								}
							}
						}
					});
				} else {
					if (!this.errors.length || this.errors[this.errors.length - 1].y !== null) {
						this.errors.push({
							x: dataSet[i].t,
							y: null
						});
					}
				}
			}

			if (set.length) {
				this.data.push(set);
			}
		}

		this.count = dataSet.length || 1;
		if (this.isBooleanMetric) {
			this.configuration.chartType = 'line';
		}

		var zones = [];
		if (this.instanceConfiguration.showThreshold) {
			if (this.qualifier && this.qualifier.configuration && this.qualifier.configuration.algorithm && this.qualifier.configuration.algorithm.type !== 'DeltaAlgorithm') {
				var thresholdOperator = this.qualifier.configuration.algorithm.operator;
				var thresholdValue = this.qualifier.configuration.algorithm.threshold;
				if (thresholdOperator === 'LT' || thresholdOperator === 'LE') {
					zones = [{
						value: thresholdValue,
						color: '#7cb5ec' //blue
					}, {
						color: '#ff0000' //red
					}]
				} else if (thresholdOperator === 'GT' || thresholdOperator === 'GE') {
					zones = [{
						value: thresholdValue,
						color: '#ff0000'
					}, {
						color: '#7cb5ec'
					}]
				}
			}
		}

		var oThis = this;
		series = [{
			regression: this.instanceConfiguration.showRegression,
			regressionSettings: {
				guid: Utils.guid(),
				type: 'linear',
				color: 'rgba(255,165,0, 1)',
				name: this.seriesItem.name,
				fullName: this.seriesItem.fullName
			},
			dataGrouping: {
				approximation: this.instanceConfiguration.aggregationType || "average"
			},
			color: '#7cb5ec',
			data: this.data,
			//type: (this.aggregate && this.instanceConfiguration.chartType === 'range') ? 'arearange' : this.configuration.chartType,
			step: this.isBooleanMetric,
			zIndex: 2,
			name: this.seriesItem.name,
			fullName: this.seriesItem.fullName,
			showInLegend: (this.instanceConfiguration.legendType === 'legend' && this.seriesItem.name) ? true : false,
			zones: zones
		}, {
			type: 'arearange',
			color: '#CCCCCC',
			data: this.noData,
			name: 'NODATA_AREA',
			zIndex: 1,
			showInLegend: false
		}];

		if (!this.instanceConfiguration.hideErrors) {
			series.push({
				type: 'line',
				color: '#FF0000',
				data: this.errors,
				zIndex: 4,
				showInLegend: false
			});
		}

		if (this.aggregate && this.instanceConfiguration.chartType === 'range') {
			series.push({
				data: this.average,
				zIndex: 1,
				color: '#2f7ed8',
				lineWidth: 1,
				type: 'spline',
				showInLegend: false
			});
		}
		var plotLines = [];
		if (this.qualifier.configuration.algorithm && this.qualifier.configuration.algorithm.type === 'StaticThresholdAlgorithm' && this.instanceConfiguration.showThreshold !== false) {
			this.threshold = this.qualifier.configuration.algorithm.threshold;
			plotLines.push({
				id: 'threshold',
				color: '#FF0000',
				dashStyle: 'ShortDash',
				width: 2,
				value: this.threshold,
				zIndex: 0
			});
			series.push({
				zIndex: 0,
				name: 'Threshold',
				color: '#ffffff',
				lineWidth: 0,
				type: 'scatter',
				data: this.data.length ? [[this.data[this.data.length - 1][0], this.threshold]] : [],
				showInLegend: false
			});
		}
		if (this.qualifier.configuration.warning && this.instanceConfiguration.showThreshold !== false) {
			this.warningThreshold = this.qualifier.configuration.warning.threshold;
			plotLines.push({
				id: 'threshold',
				color: '#FFA500',
				dashStyle: 'ShortDash',
				width: 2,
				value: this.warningThreshold,
				zIndex: 0
			});
			series.push({
				zIndex: 0,
				name: 'Threshold',
				color: '#ffffff',
				lineWidth: 0,
				type: 'scatter',
				data: this.data.length ? [[this.data[this.data.length - 1][0], this.warningThreshold]] : [],
				showInLegend: false
			});
		}
		if (this.qualifier.configuration.algorithm && this.qualifier.configuration.algorithm.type === 'RangeAlgorithm') {
			this.rangeMin = this.qualifier.configuration.algorithm.min;
			this.rangeMax = this.qualifier.configuration.algorithm.max;
			plotLines.push({
				id: 'rangemin',
				color: '#FF0000',
				dashStyle: 'ShortDash',
				width: 2,
				value: this.rangeMin,
				zIndex: 0
			});
			plotLines.push({
				id: 'rangemax',
				color: '#FF0000',
				dashStyle: 'ShortDash',
				width: 2,
				value: this.rangeMax,
				zIndex: 0
			});
			series.push({
				zIndex: 0,
				name: 'Threshold',
				color: '#ffffff',
				lineWidth: 0,
				type: 'scatter',
				data: this.data.length ? [[this.data[this.data.length - 1][0], this.rangeMin], [this.data[this.data.length - 1][0], this.rangeMax]] : [],
				showInLegend: false
			});
		}

		var unit = this.qualifier.configuration.unitTypeSymbol || '';
		var exporting = jQuery.extend(true, {}, Configuration.highcharts.exporting);
		if (this.onZoom) {
			exporting.enabled = true;
			exporting.buttons.popUpBtn = {
				onclick: $.proxy(function () {
					this.zoomStartDate = null;
					this.zoomEndDate = null;
					this.zoomPeriod = null;
					this.onZoom = false;

					this.instanceConfiguration.startDate = null;
					this.instanceConfiguration.endDate = null;

					if (this.instanceConfiguration.period !== 'CUSTOM') {
						this.subscribe();
					} else {
						this.getData();
					}

					if (this.customControls && typeof this.customControls.zoom === 'function') {
						this.customControls.zoom.call(this, {
							startDate: this.instanceConfiguration.startDate,
							endDate: this.instanceConfiguration.endDate,
							period: this.instanceConfiguration.period
						});
					}
				}, this),
				align: 'left',
				width: 5,
				x: 5,
				y: 5,
				text: lang.RESET,
				_titleKey: "resetTooltip",
				theme: {
					'stroke-width': 1,
					stroke: '#aaa',
					fill: '#fff',
					r: 0,
					states: {
						hover: {
							fill: '#eee'
						},
						select: {
							fill: '#ccc'
						}
					}
				}
			};
		}
		var plotingOptionsErrorLine = {
			color: '#FF0000',
			lineWidth: 4,
			states: {
				hover: {
					lineWidth: 5
				}
			},
			marker: {
				enabled: true,
				fillColor: '#FF0000',
				symbol: 'circle',
				radius: 4,
				states: {
					hover: {
						radius: 6
					}
				}
			}
		};
		var plotingOptionsBooleanLine = {
			marker: {
				enabled: false,
				states: {
					hover: {
						fillColor: '#4572A7',
						lineWidth: 1,
						lineColor: '#4572A7',
						radius: 4,
						symbol: 'circle'
					}
				}
			}
		};

		zoom = dataSet.length > 1 ? 'x' : false;

		Highcharts.setOptions({
			global: {
				useUTC: true,
				timezoneOffset: -moment.tz(this.instanceConfiguration.timezone).utcOffset()
			}
		});
		var isLegendEnabled;
		if (this.instanceConfiguration.legendType === 'legend') {
			isLegendEnabled = true;
		} else {
			isLegendEnabled = false;
		}
		var chartType;
		if (this.instanceConfiguration.chartType === 'range' || this.instanceConfiguration.chartType === 'arearange') {
			chartType = 'spline';
		} else {
			chartType = this.instanceConfiguration.chartType;
		}
		if (!widgetContentDiv.height() && this.viewerWidgetHeight) {
			widgetContentDiv.css('height', this.viewerWidgetHeight);
			widgetContentDiv.closest('#cw_service_model_preview').css('height', this.viewerWidgetHeight);
		} else {
			widgetContentDiv.css('height', '');
			widgetContentDiv.closest('#cw_service_model_preview').css('height', '');
		}

		for (const item of series) {
			item.dataGrouping = {
				approximation: this.instanceConfiguration.aggregationType || "average"
			};
		}

		let container = this.widgetContentDiv.find('.metric_chart');
		let height;
		let dashboardWindow = container.closest('.section__content');
		if (dashboardWindow.length) {
			height = dashboardWindow.height();
		} else {
			height = container.parent().height();
		}

		let header = dashboardWindow.closest('.html-shape-container').find('.toolbar_appearance_section-header').first();
		let missingHeader = false;
		if (header.css('display') === 'none' || header.width() === -30) {
			missingHeader = true;
		}
		if (this.instanceConfiguration.timeSelectorPosition === 'BOTTOM') {
			height = height - 30;
			this.moveTimeSelectorBottom(missingHeader);
		} else if (this.instanceConfiguration.timeSelectorPosition === 'TOP' && missingHeader)  {
			height = height - 30;
			this.renderTo.css('margin-top', '35px');
		}

		this.chart = new Highcharts.Chart({
			chart: {
				renderTo: container[0],
				height: height,
				zoomType: zoom,
				marginBottom: 50,
				type: chartType,
				events: {
					load: function (e) {
						if (oThis.instanceConfiguration.legendType !== 'legend') {
							let renderTo;;
							if ($(oThis.renderTo).length) {
								renderTo = $(oThis.renderTo);
							} else {
								renderTo = $('#' + oThis.renderTo);
							}
							let widgetWidth = renderTo.width();
							this.renderer.style.fontSize = '11px';
							let mapping = {
								'LAST30DAYS': lang.MONTH,
								'LAST7DAYS': lang.WEEK,
								'LASTDAY': lang.DAY,
								'LASTHOUR': lang.HOUR,
								'CUSTOM': lang.CUSTOM
							};
							let period = mapping[oThis.instanceConfiguration.period];
							let labels, shownAsset, shownName, shownType, shownAccount, shownService, shownServiceElement, shownQualifier, shownPeriod, shownTimezone, defaultInfoTemplate, currentLabels;
							let widthLimit = 2;
							if (oThis.isViewer) {
								labels = {
									'<Asset>': 'shownAsset',
									'<Name>': 'shownName',
									'<Type>': 'shownType',
									'<Timezone>': 'shownTimezone'
								};

								shownAsset = lang.ASSET + ': ' + '<span data-id="' + oThis.qualifier.configuration.assetId + '" class="cw_link cw_asset_name">' + oThis.qualifier.configuration.assetName + '</span>';
								shownName = lang.NAME + ': ' + oThis.qualifier.configuration.categoryNode;
								shownType = lang.TYPE + ': ' + oThis.qualifier.configuration.registryType;
								shownTimezone = lang.TIMEZONE + ': ' + oThis.instanceConfiguration.timezone;
								currentLabels = ['<Asset>', '<Name>', '<Type>', '<Timezone>'];

								if (oThis.widgetContentDiv?.width() > 500) {
									widthLimit = 3;
								}
							} else {
								labels = {
									'<Asset>': 'shownAsset',
									'<Account>': 'shownAccount',
									'<Service>': 'shownService',
									'<Serviceelement>': 'shownServiceElement',
									'<Servicequalifier>': 'shownQualifier',
									'<Period>': 'shownPeriod',
									'<Timezone>': 'shownTimezone'
								};
								shownAsset = lang.ASSET + ': ' + '<span class="cw_link cw_asset_name">' + oThis.qualifier.configuration.assetName + '</span>';
								shownAccount = lang.ACCOUNT + ': ' + oThis.qualifier.accountName;
								shownService = lang.SERVICE + ': ' + oThis.qualifier.serviceName;
								shownServiceElement = lang.SERVICE_ELEMENT + ': ' + oThis.qualifier.elementName;
								shownQualifier = lang.QUALIFIER + ': ' + oThis.qualifier.name;
								shownPeriod = lang.PERIOD + ': ' + period;
								shownTimezone = lang.TIMEZONE + ': ' + oThis.instanceConfiguration.timezone;
								defaultInfoTemplate = ['<Asset>', '<Account>', '<Service>', '<Serviceelement>', '<Servicequalifier>', '<Period>', '<Timezone>'];
								currentLabels = oThis.instanceConfiguration.informationalTemplate || defaultInfoTemplate;
							}

							let firstRowTemplate = [];
							let secondRowTemplate = [];
							let firstRowLabel, secondRowLabel;
							if (currentLabels && currentLabels.length) {
								for (let i = 0; i < currentLabels.length; i++) {
									let label = labels[currentLabels[i]];
									let labelString = eval(label);
									if (labelString) {
										if (i <= widthLimit) {
											firstRowTemplate.push(labelString);
										} else {
											secondRowTemplate.push(labelString);
										}
									}
								}
								firstRowLabel = firstRowTemplate.join(' | ');
								secondRowLabel = secondRowTemplate.join(' | ');
							}

							if (firstRowLabel) {
								let firstRowLabelPosHeight = secondRowLabel ? this.chartHeight - 31 : this.chartHeight - 25;
								this.renderer.label(firstRowLabel, 5, firstRowLabelPosHeight, null, null, null, true).css({
									'color': '#999'
								}).add();
							}
							if (secondRowLabel) {
								let secondRowLabelPosHeight = this.chartHeight - 18;
								this.renderer.label(secondRowLabel, 5, secondRowLabelPosHeight, null, null, null, true).css({
									'color': '#999'
								}).add();
							}
							let shownLabels = renderTo.find('.highcharts-label').find('span:not(.cw_link)');
							//QUICKFIX to be found a better solution
							let avgCharPerPx = 5.8;
							let maxCharInLabel = widgetWidth / avgCharPerPx;
							let toolTipArray = [];
							for (let i = 0; i < shownLabels.length; i++) {
								let el = $(shownLabels[i]);
								let labelText = el.text();
								toolTipArray.push(labelText);
							}
							let toolTipText = toolTipArray.join(' - ');
							shownLabels.attr('title', toolTipText);
							if (State.currentApp?.dashboardDesigner?.props?.mode !== 'designer') {
								oThis.widgetContentDiv.find('.cw_asset_name').off().on('click', $.proxy(oThis.onAssetNameClick, oThis));
								oThis.widgetContentDiv.find('.cw_service_name').off().on('click', $.proxy(oThis.onServiceNameClick, oThis));
							}
						}
						kendo.ui.progress(widgetContentDiv, false);
						if (oThis.events && oThis.events.load) {
							oThis.events.load.call(this, e);
						}
					},
					selection: $.proxy(function (event) {
						if (event.xAxis) {
							this.onZoom = true;
							this.zoomStartDate = parseInt(event.xAxis[0].min, 10);
							this.zoomEndDate = parseInt(event.xAxis[0].max, 10);
							this.zoomPeriod = 'CUSTOM';

							if (this.customControls && typeof this.customControls.zoom === 'function') {
								this.customControls.zoom.call(this, {
									startDate: this.zoomStartDate,
									endDate: this.zoomEndDate,
									period: this.zoomPeriod
								});
							}

							this.unsubscribe();
						}
						event.preventDefault();
						this.getData(true);
					}, this)
				}
			},
			title: {
				text: ' '
			},
			legend: {
				enabled: isLegendEnabled,
				title: {
					text: '', //lang.assethealth.MONITOR_NAME,
					align: 'center'
				},
				x: 2,
				y: 20,
				floating: false,
				borderWidth: 0,
				layout: 'horizontal',
				align: 'center',
				verticalAlign: 'bottom',
				useHTML: true,
				itemDistance: 15,
				itemStyle: {
					fontSize: "10px"
				},
				style: {
					fontSize: "10px"
				},
				labelFormatter: function () {
					if (oThis.instanceConfiguration.legendType === 'legend') {
						return getLegendLabelFormatter(this);
					}
				}
			},
			credits: {
				enabled: false
			},
			exporting: exporting,
			plotOptions: {
				spline: {
					marker: {
						enabled: false,
						states: {
							hover: {
								fillColor: '#4572A7',
								lineWidth: 1,
								lineColor: '#4572A7',
								radius: 4,
								symbol: 'circle'
							}
						}
					}
				},
				scatter: {
					marker: {
						radius: 0,
						states: {
							hover: {
								enabled: false,
								lineColor: 'rgb(100,100,100)'
							}
						}
					},
					states: {
						hover: {
							marker: {
								enabled: false
							}
						}
					}
				},
				line: this.instanceConfiguration.chartType === 'line' ? plotingOptionsBooleanLine : plotingOptionsErrorLine,
				areaspline: {
					marker: {
						enabled: false,
						states: {
							hover: {
								fillColor: '#4572A7',
								lineWidth: 1,
								lineColor: '#4572A7',
								radius: 4,
								symbol: 'circle'
							}
						}
					}
				},
				series: {
					cursor: 'pointer',
					events: {
						click: $.proxy(this.onChartPointClick, this)
					},
					marker: {
						enabled: false
					}
				}
			},
			lang: {
				noData: noDataMessage,
				resetTooltip: 'Reset'
			},
			noData: {
				style: {
					fontWeight: 'bold',
					fontSize: '15px',
					color: '#303030'
				}
			},
			xAxis: {
				type: 'datetime',
				labels: {
					staggerLines: 1
				},
				dateTimeLabelFormats: {
					millisecond: Utils.widgetDateFormat(Cookies.CeesoftUserDateTimeFormat, 'metric', 'millisecond'),
					second: Utils.widgetDateFormat(Cookies.CeesoftUserDateTimeFormat, 'metric', 'second'),
					minute: Utils.widgetDateFormat(Cookies.CeesoftUserDateTimeFormat, 'metric', 'minute'),
					hour: Utils.widgetDateFormat(Cookies.CeesoftUserDateTimeFormat, 'metric', 'hour'),
					day: Utils.widgetDateFormat(Cookies.CeesoftUserDateTimeFormat, 'metric', 'day'),
					week: Utils.widgetDateFormat(Cookies.CeesoftUserDateTimeFormat, 'metric', 'week'),
					month: Utils.widgetDateFormat(Cookies.CeesoftUserDateTimeFormat, 'metric', 'month'),
					year: Utils.widgetDateFormat(Cookies.CeesoftUserDateTimeFormat, 'metric', 'year')
				},
				visible: this.instanceConfiguration.showXAxis
			},
			yAxis: {
				softMin: this.instanceConfiguration.minValue == 'dynamic' ? undefined : 0,
				minPadding: 0,
				title: {

					text: null
				},
				plotLines: plotLines,
				labels: {
					formatter: function () {
						var v = this.value;
						if (oThis.isBooleanMetric) {
							switch (this.value) {
								case 1:
									v = lang.UP;
									break;
								case 0:
									v = lang.DOWN;
									break;
								default:
									v = ' ';
									break;
							}
						} else {
							return v + ' ' + unit;
						}
						return v;
					}
				},
				visible: this.instanceConfiguration.showYAxis
			},
			tooltip: {
				crosshairs: true,
				snap: 1,
				style: {
					padding: '5px',
					width: '250px',
					height: '160px'
				},
				useHTML: true,
				// shared: this.aggregate ? false : true,
				formatter: function () {
					var v;
					var userOptions = this.series.userOptions || {};
					if (userOptions.isRegressionLine) {
						return getTrendValueWidgetWrapper(this, oThis);
					} else {
						var v = '';
						var s = Renderer.browserDateRenderer(this.x, 'datetime', '', oThis.instanceConfiguration.timezone);
						var errorObj = oThis.getErrorCode(this.x);
						var e = errorObj.errorCode;
						var t0 = errorObj.t0;
						var toTime, url;
						if (t0) {
							toTime = t0 + oThis.interval;
							url = oThis.requestPath + 'services/' + oThis.instanceConfiguration.serviceId + '/notifications/qualifiers/' + oThis.qualifier.id + '/errorDetails?fromTime=' + t0 + '&toTime=' + toTime + '&timeZone=' + oThis.instanceConfiguration.timezone;
						}
						// if error point
						if (!this.series || this.series.name !== 'NODATA') {
							if (this.series && this.series.type === 'line' && !oThis.isBooleanMetric) {
								//v += e;
								if (e.indexOf('OK') >= 0) {
									v += '<strong>' + lang.VALUE + ':</strong>' + Math.round(this.y * 100) / 100 + unit;
								}
								if (!this.point.options.errorPointData) {
									if (url && oThis.instanceConfiguration.type !== 'MONITOR') {
										return Utils.ajax(url, 'GET', {}, false, false)
											.then($.proxy(function (result) {
												if (result.data) {
													v += '<br />' + result.data.message;
													v += '<br />' + '<div class="cw_widget_tooltip">' + result.data.serviceLogMessage + '</div>';
													this.point.options.errorPointData = result.data;
													return s + '<br />' + v;
												}
												else {
													return v += errorObj.errorCode;
												}
											}, this));
									}
								} else {
									v += '<br />' + this.point.options.errorPointData.message;
									v += '<br />' + '<div class="cw_widget_tooltip">' + this.point.options.errorPointData.serviceLogMessage + '</div>';
								}
							} else {
								var v = '';
								if (!oThis.aggregate) {
									if (e.indexOf('OK') >= 0) {
										if (oThis.isBooleanMetric) {
											v += (this.y === 1 ? lang.UP : lang.DOWN);
										} else {
											v += '<strong>' + lang.VALUE + ':</strong>' + Math.round(this.y * 100) / 100 + unit;
										}
									} else {
										v += e;
										if (oThis.isBooleanMetric) {
											v += '<br />' + ((this.y === 1 ? lang.UP : lang.DOWN));
										}
										if (!this.point.options.errorPointData) {
											if (url && oThis.instanceConfiguration.type !== 'MONITOR') {
												return Utils.ajax(url, 'GET', {}, false, false)
													.then($.proxy(function (result) {
														if (result.data) {
															v += '<br />' + result.data.message;
															v += '<br />' + '<div class="cw_widget_tooltip">' + result.data.serviceLogMessage + '</div>';
															this.point.options.errorPointData = result.data;
															return s + '<br />' + v;
														} else {
															return false;
														}
													}, this));
											}
										} else {
											v += '<br />' + this.point.options.errorPointData.message;
											v += '<br />' + '<div class="cw_widget_tooltip">' + this.point.options.errorPointData.serviceLogMessage + '</div>';
										}
									}
								} else {
									if (e.indexOf('OK') >= 0) {
										if (oThis.isBooleanMetric) {
											v += (this.y === 1 ? lang.UP : lang.DOWN);
										} else {
											if (this.point.low) {
												v += '<strong>' + lang.RANGE + ': </strong>' + Math.round(this.point.low * 100) / 100 + ' - ' + Math.round(this.point.high * 100) / 100 + unit;
											} else {
												v += '<strong>' + lang.AVERAGE + ': </strong>' + Math.round(this.point.y * 100) / 100 + unit + '<br />';
												v += '<strong>' + lang.RANGE + ': </strong>' + (Math.round(oThis.getMin(this.point.x) * 100) / 100) + ' - ' + Math.round(oThis.getMax(this.point.x) * 100) / 100 + unit;
											}
										}
									} else {
										v += e;
										if (oThis.isBooleanMetric) {
											v += '<br />' + ((this.y === 1 ? lang.UP : lang.DOWN));
										}
										if (!this.point.options.errorPointData) {
											if (url && oThis.instanceConfiguration.type !== 'MONITOR') {
												return Utils.ajax(url, 'GET', {}, false, false)
													.then($.proxy(function (result) {
														if (result.data) {
															v += '<br />' + result.data.message;
															v += '<br />' + '<div class="cw_widget_tooltip">' + result.data.serviceLogMessage + '</div>';
															this.point.options.errorPointData = result.data;
															return s + v + '<br />';
														} else {
															return v;
														}
													}, this));
											}
										} else {
											v += '<br />' + this.point.options.errorPointData.message;
											v += '<br />' + '<div class="cw_widget_tooltip">' + this.point.options.errorPointData.serviceLogMessage + '</div>';
										}
									}
								}
							}
						}
						return s + '<br />' + v;
					}
				}
			},
			series: series,
			selection: function (event) {
				var isSelectionFired = true;
			}
		});

		Highcharts.setOptions({
			global: {
				useUTC: false
			}
		});
	},
	/**
	 * Handler for the click event on the asset name
	 * @param {Object} e The click event object
	 */
	onAssetNameClick: function (e) {
		if(!this.redirectConfig?.doDefaultRedirect)
			return;
		e.stopPropagation();

		LocalEventsManager.trigger('highlightItem', {
			element: e
		});

		var assetId = this.qualifier.configuration.assetId;
		var loadObj = {
			id: assetId,
			name: this.qualifier.configuration.assetName,
			accountId: this.instanceConfiguration.accountId,
			accountName: this.qualifier.accountName
		};

		State.mainApp?.loadModule('AssetDetails', assetId, loadObj, e, null, this.redirectConfig?.defaultRedirectInNewTab);
	},
	/**
	 * Handler for the click event on the service name
	 * @param {Object} e The click event object
	 */
	onServiceNameClick: function (e) {
		if(!this.redirectConfig?.doDefaultRedirect){
			return;
		}

		e.stopPropagation();

		LocalEventsManager.trigger('highlightItem', {
			element: e
		});

		var serviceId = this.instanceConfiguration.serviceId;
		var loadObj = {
			accountId: this.instanceConfiguration.accountId,
			accountName: this.qualifier.accountName
		};

		State.mainApp?.loadModule('ServiceDetails', serviceId, loadObj, e, null, this.redirectConfig?.defaultRedirectInNewTab);
	},

	/**
	 * Gets the maximum value of a given timestamp
	 * @param {Number} t The timestamp
	 * @return {Number} max The max value
	 */
	getMax: function (t) {
		var max = 0;
		for (var i = 0, length = this.dataSet.length; i < length; i++) {
			if (this.dataSet[i].t === t) {
				max = this.dataSet[i].vH;
				break;
			}
		}
		return max;
	},
	/**
	 * Gets a point from the data set at a given timestamp
	 * @param {Number} t The timestamp
	 * @return {Object} The data point
	 */
	getPoint: function (t) {
		for (var i = 0, length = this.dataSet.length; i < length; i++) {
			if (this.dataSet[i].t === t) {
				return this.dataSet[i];
			}
		}
		return {
			vH: 0,
			vL: 0,
			v: 0
		};
	},
	/**
	 * Gets the minimum value of a given timestamp
	 * @param {Number} t The timestamp
	 * @return {Number} min The min value
	 */
	getMin: function (t) {
		var min = 99999;
		for (var i = 0, length = this.dataSet.length; i < length; i++) {
			if (this.dataSet[i].t === t) {
				min = this.dataSet[i].vL;
				break;
			}
		}
		return min;
	},
	/**
	 * Search the data for a timestamp point and returns its error code
	 * @param {Number} timestamp
	 * @return {String} errorCode
	 */
	getErrorCode: function (timestamp) {
		var errorCode = '';
		var t0;

		for (var i = 0, length = this.dataSet.length; i < length; i++) {
			if (this.dataSet[i].t === timestamp) {
				for (var j = 0, length1 = this.dataSet[i].e.length; j < length1; j++) {
					if (errorCode !== '') {
						errorCode += ', ';
					}
					t0 = this.dataSet[i].t0;

					errorCode = ErrorCodes.get(this.dataSet[i].e[j]).text;
				}
				break;
			}
		}
		return {errorCode: errorCode, t0: t0};
	},
	/**
	 * Called when a metric event is received
	 * @param {Object} data The event data object
	 */
	onEvent: function (data) {
		if (this.chart) {
			//console.log('MetricsWidget/onEvent');
			//console.log(data);

			// Debug series: 0-arearange/data; 1-error; 2-NO DATA; 3- average; 4-threshold

			var doRoundRobin = true;
			if (this.dataSet && this.dataSet.length < this.roundRobinPoints) {
				doRoundRobin = false;
			}
			if (!this.aggregate || this.instanceConfiguration.chartType !== 'range') {
				this.chart.series[0].addPoint([data.metric.t, Utils.aggregate(this.instanceConfiguration.aggregationType, data.metric)], true, doRoundRobin);
			} else {
				this.chart.series[0].addPoint([data.metric.t, data.metric.vL, data.metric.vH], true, doRoundRobin);
				this.chart.series[3].addPoint([data.metric.t, Utils.aggregate(this.instanceConfiguration.aggregationType, data.metric)], true, doRoundRobin);
			}
			if (doRoundRobin) {
				this.dataSet.splice(0, 1);
			}
			this.dataSet.push(data.metric);
			if (data.metric.e[0] !== 0) {
				if (!this.instanceConfiguration.hideErrors) {
					this.chart.series[1].addPoint({
						x: data.metric.t,
						y: 0,
						marker: {
							enabled: true,
							fillColor: '#FF0000',
							symbol: 'circle',
							radius: 4,
							states: {
								hover: {
									radius: 6
								}
							}
						}
					}, true, doRoundRobin);
				}
			} else {
				this.chart.series[1].addPoint({
					x: data.metric.t,
					y: null
				}, true, doRoundRobin);
			}
			if (this.qualifier.configuration.algorithm && this.qualifier.configuration.algorithm.type === 'StaticThresholdAlgorithm') {
				for (var i = 0, length = this.chart.series.length; i < length; i++) {
					if (this.chart.series[i].name === 'Threshold') {
						this.chart.series[i].addPoint({
							x: data.metric.t,
							y: this.threshold || this.rangeMin
						}, true, doRoundRobin);
					}
				}
			}

			this.chart.redrawRegressionLine();
		} else {
			this.getData();
		}
	},
	/**
	 * Handler function for the chart point click
	 * @param {object} e The click event
	 */
	onChartPointClick: function (e) {
		var name = e.currentTarget.name;
		if (State.currentApp?.dashboardDesigner?.props?.mode !== 'designer' && (!this.redirectConfig || this.redirectConfig.doDefaultRedirect)) {
			if (name !== 'NODATA' && this.instanceConfiguration.type !== 'MONITOR') {
				if (e.point.marker && e.point.marker.fillColor === '#FF0000') {
					if (e.point.options.errorPointData) {
						let data = e.point.options.errorPointData;
						let logObj = {
							serviceId: this.instanceConfiguration.serviceId,
							serviceName: this.instanceConfiguration.serviceName,
							notificationId: data.notificationId,
							type: data.type,
							status: 'STATUS',
							message: data.serviceLogMessage
						};
						if (this.isViewer) {
							const {offsetWidth, offsetHeight} = document.getElementById('content_area');
							const logDetailsWindow = new ModalWindow({
								title: lang.service.SERVICE_LOG_DETAILS,
								width: offsetWidth * 0.8,
								height: offsetHeight * 0.8,
								minHeight: 567,
								url: 'include/Service/LogDetailsView.jsp',
								refresh: function () {
									new ServiceLogDetailsView(
										{
											...logObj,
											closeWindow : () => {
												logDetailsWindow.close();
											}
										}
									);
								}
							});
							logDetailsWindow.open();
						} else {
							State.mainApp?.loadModule('ServiceLogDetails', '', logObj, null, null, this.redirectConfig?.defaultRedirectInNewTab);
						}

					}
					// else TODO: - do ajax request
				} else if (!this.isViewer) {
					State.mainApp?.navigate(ServicesRouter.viewer(this.instanceConfiguration.serviceId, {
						serviceElementId: this.instanceConfiguration.serviceElementId,
						serviceQualifierId: this.instanceConfiguration.serviceQualifierId
					}), {newTab: this.redirectConfig?.defaultRedirectInNewTab});
				}
			}
			if (this.instanceConfiguration.qualifierType === 'datacollector.health.HealthIndexConfiguration' ||
				this.instanceConfiguration.qualifierType === 'datacollector.health.MonitorHealthIndexConfiguration') {
				this.timeStamp = e.point.options.x;
				this.openReasonsWindow();
			}
		}
	},
	openReasonsWindow: function () {
		$('#content_area').append('<div id="cw_reasons_window"><div id="cw_healh_resons_grid"></div></div>');
		if (this.reasonsWindow) {
			this.reasonsWindow.destroy();
		}
		this.viewerArea = $('#painting_area');
		this.viewerAreaOffset = this.viewerArea.offset();

		let windowConfig = {
			top: this.viewerAreaOffset.top + 2,
			left: this.viewerAreaOffset.left + 2
		}
		this.reasonsWindow = $('#cw_reasons_window').kendoWindow({
			resizable: true,
			minWidth: 200,
			minHeight: 100,
			width: 750,
			height: 250,
			position: {
				top: windowConfig.top + 400,
				left: windowConfig.left + 100
			},
			title: `${lang.viewer.REASONS_FOR} ${this.instanceConfiguration.qualifierName}`,
			actions: [
				'Close'
			],
			activate: $.proxy(function (e) {
				this.createReasonsGrid();
			}, this),
			close: $.proxy(function () {
				this.reasonsWindow.destroy();
			}, this)
		}).data('kendoWindow');
	},
	createReasonsGrid: function () {
		let filterMessages = lang.grid.filter;
		let scope = this;
		this.reasonsGrid = $('#cw_healh_resons_grid').kendoCustomGrid({
			dataSource: new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: `${Settings.serverPath}accounts/${Cookies.CeesoftCurrentAccountId}/metrics/${scope.instanceConfiguration.serviceQualifierId}/healthIndexes/${this.timeStamp}/reasons`,
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				}
			}),
			resizable: true,
			sortable: true,
			scrollable: true,
			filterable: {
				extra: false,
				operators: {
					string: {
						startswith: filterMessages.STARTS_WITH,
						neq: filterMessages.NEQ,
						eq: filterMessages.EQ,
						contains: filterMessages.CONTAINS
					},
					date: {
						gte: filterMessages.IAE,
						gt: filterMessages.IA,
						lte: filterMessages.IBE,
						lt: filterMessages.IB
					}
				}
			},
			columns: [{
				field: 'severity',
				title: lang.assethealth.SEVERITY,
				attributes: {
					'class': 'text_center'
				},
				//template: '#=Renderer.severity(severity)#',
				template: item => getSeverityState(item.severity),
				width: 50,
				sortable: {
					compare: $.proxy(function (a, b) {
						return Utils.customCompare(a, b, 'severityIndex', 6, this.dataSource.sortNow);
					}, this)
				},
				filterable: {
					operators: {
						string: {
							eq: filterMessages.ISIN,
							neq: filterMessages.ISNOTIN
						}
					},
					ui: function (element) {
						var multiselect = new MultiSelectGridFilter({
							element: element,
							field: 'severity',
							grid: $('#cw_healh_resons_grid').data('kendoCustomGrid'),
							dataSource: [{
								text: lang.CRITICAL,
								icon: '<span class="cw_status_indicator cw_es_indicator cw_status_widget_color is_critical"></span>',
								value: 'CRITICAL'
							}, {
								text: lang.MAJOR,
								icon: '<span class="cw_status_indicator cw_es_indicator cw_status_widget_color is_major"></span>',
								value: 'MAJOR'
							}, {
								text: lang.MINOR,
								color: 'is_minor',
								icon: '<span class="cw_status_indicator cw_es_indicator cw_status_widget_color is_minor"></span>',
								value: 'MINOR'
							}, {
								text: lang.OK,
								icon: '<span class="cw_status_indicator cw_es_indicator cw_status_widget_color is_ok"></span>',
								value: 'NONE'
							}, {
								text: lang.MAINTENANCE,
								icon: '<span class="cw_status_indicator cw_es_indicator cw_status_widget_color is_idle"><span class="glyphicons wrench"></span></span>',
								value: 'MAINTENANCE'
							}],
						});
					}
				},
			}, {
				field: 'timestamp',
				title: lang.TIME,
				template: '#=Renderer.browserDateRenderer(timestamp, "datetime")#',
				width: 150,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				},
				sortable: true,
				filterable: {
					extra: true,
					ui: function (el) {
						el.kendoDateTimePicker({
							format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
							timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat)
						});
					},
					messages: this.gridMessages
				},
			}, {
				field: 'text',
				title: lang.REASON_TEXT,
				width: 200,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'monitorType',
				title: lang.assethealth.MONITOR_TYPE,
				width: 150,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'monitorName',
				title: lang.assethealth.MONITOR_NAME,
				width: 150,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}],
			selectable: true,
			change: $.proxy(function() {
				var selectedRow = this.reasonsGrid.select();
				var myRow = selectedRow[0];
				var messageEl = $(myRow).find('.to_expand');
				if ($(messageEl).hasClass('cw_message_expanded')) {
					$(messageEl).removeClass('cw_message_expanded').addClass('ellipsis');
				} else {
					$('#cw_slas_list').find('.k-grid-content').find('td.cw_message_expanded').removeClass('cw_message_expanded').addClass('ellipsis');
					$(messageEl).addClass('cw_message_expanded').removeClass('ellipsis');
				}
			}, this)
		}).data('kendoCustomGrid');
	},
	/**
	 * Triggered after widget resize
	 * @param {Object} event The resize event
	 * @param {Object} ui The UI element - see http://api.jqueryui.com/resizable/
	 */
	onResize: function (event, ui) {
		kendo.ui.progress(this.widgetContentDiv, true);
		this.unsubscribe();
		this.subscribe();
		setTimeout(() => {
			this.createCustomControls();
		});
	},
	/**
	 * handler function for the drop event
	 */
	onDrop: function () {
		this.destroy();
		this.subscribe();
	},
	/**
	 * Updates multi toggle component position. When widget size is lower than 440
	 * px, period selector moves on top of the section content
	 */
	updatePeriodSelector: function () {
		var widgetDiv = $('#' + this.id);
		if (widgetDiv.width() < 440) {
			widgetDiv.find('.cw_multi_toggle').css({
				'right': '45px',
				'z-index': 100
			});
			widgetDiv.find('.cw_section_titlebar').css('overflow', 'visible');
		} else {
			widgetDiv.find('.cw_multi_toggle').css({
				'position': 'relative',
				'top': 'none',
				'right': 'none'
			});
			widgetDiv.find('.cw_section_titlebar').css('overflow', 'hidden');
		}
	},
	/**
	 * Subscribes to server metric events
	 *  * @param {Boolean} noGetData
	 */
	subscribe: function (noGetData) {
		var subscriptionObj = [{
			eventType: 'Metric',
			timeZone: this.instanceConfiguration.timezone,
			qualifierId: this.instanceConfiguration.serviceQualifierId || this.instanceConfiguration.qualifier.id || this.instanceConfiguration.systemQualifierId
		}];
		this.subscriberId = this.id;
		RemoteEventsManager.subscribe(this.subscriberId, subscriptionObj);
		if (!noGetData) {
			this.getData();
		}
	},
	/*
	 * Handler function for release events
	 * @param {Boolean} noData
	 * */
	releaseEvents: function (noData) {
		var interval = this.interval;
		if (noData || !this.aggregate) {
			interval = 0;
		}
		var intervals = {};
		var qualifierId = this.instanceConfiguration.serviceQualifierId || this.instanceConfiguration.qualifier.id || this.instanceConfiguration.systemQualifierId;
		intervals[qualifierId] = interval;

		RemoteEventsManager.releaseEvents(this.subscriberId, {
			intervals: intervals
		});
	},
	/**
	 * Destroy
	 * @@param {Boolean} dontUnsubscribe If true, it only destroys the chart without unsubscribing
	 */
	destroy: function (dontUnsubscribe) {
		if( this.reasonsWindow){
			this.reasonsWindow.destroy();
		}
		if (!dontUnsubscribe) {
			Widget.prototype.destroy.call(this);
		}
		/*if (this.chart && $(this.chart.renderTo).length) {
			this.chart.destroy();
		}*/
	}
});
