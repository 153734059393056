import './webPageWidgetConfiguration.less';

import React from 'react';
import {FormEntry, TextBox} from 'controls/react/form';
import {FormLayout} from 'controls/react/layout';

import {translator} from "core";
import {WidgetConfigurationContext} from "../../widgetConfigurationStore";
import {addLinks} from "core/react/links";

const i = translator({
  "Refresh (seconds)": {
    "no": "Oppdater (sekunder)"
  },
  "Please provide an Url": {
    "no": "Legg til URL"
  }
});

@addLinks
export default class WebPageWidgetConfiguration extends React.Component {
	static contextType = WidgetConfigurationContext;

	constructor(props, context){
		super(props, context);

		this.checkUrlValid(this.context.config.configuration.url);
	}

	render() {
		const urlLink = this.linkContext('config.configuration.url')
			.changed(() => {
				this.checkUrlValid(urlLink.value)
			});

		return (
			<FormLayout containerClass={'web-page-widget-configuration'}>
				<FormEntry label={i('Title')}>
					<TextBox {...this.linkContext('config.title').props}/>
				</FormEntry>
				<FormEntry label={i('Url')}>
					<TextBox {...urlLink.props}/>
				</FormEntry>
				<FormEntry label={i('Refresh (seconds)')}>
					<TextBox {...this.linkContext("config.configuration.refreshRate").props}/>
				</FormEntry>
			</FormLayout>
		)
	}

	checkUrlValid = value => {
		this.context.setValidation('url', value != '', {message: i('Please provide an Url')});
	}
}
