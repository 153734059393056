import Element from './element';
import Api from 'tools/api';
import {SlaApi} from 'api';
import Cookies from 'core/cookies';
import State from 'tools/state';
import Utils from 'tools/utils';
import Settings from 'settings';
import {AssetsRouter} from "areas/assets/bundleDescription";
import {compareByOperator, round} from "tools/helpers/math";
import {indicatorToState} from "tools/states";
import {TOTAL_METRIC_TYPE} from 'areas/dashboards/graph-editor-extensions/datasources/totalMetricDataSource';
import {DISPLAY_LABEL} from 'areas/dashboards/graph-editor-extensions/datasources/displayLabelDataSource';
import {DISPLAY_UNIT} from 'areas/dashboards/graph-editor-extensions/datasources/displayUnitDataSource';
import {formatNumber} from "tools/helpers/math";
import {dateToString} from "tools/dateTimeUtils";


const i = require('core/localization').translator({
  "Metric: {0}": {
    "no": "Metrikk: {0}"
  },
  "Qualifier not found": {
    "no": "Tjenestepunkt ble ikke funnet",
    "en": "Servicequalifier not found"
  },
  "No metric on qualifier": {
    "no": "Ingen metrikker for tjenestepunktet",
    "en": "No metric for this servicequalifier"
  },
  "No data": {
    "no": "Ingen data"
  },
});


function getMetricValue(metric, decimalsNumber) {
	let value = null;

	if (metric && metric.data?.length > 0 && metric.data[0] !== null && metric.data[0].e.length === 1 && metric.data[0].e[0] === 0) {
		value = parseFloat(metric.data[0].v);
	}

	return value;
}

export class Metric extends Element {
	getEntriesToLoad() {
		this.metricsToLoad = [{metricId: this.datasource.metric.id}];

		if (this.datasource.metric.totalMetricType === TOTAL_METRIC_TYPE.METRIC && this.datasource.metric.totalMetricId) {
			this.metricsToLoad.push({ metricId: this.datasource.metric.totalMetricId});
		}

        return this.metricsToLoad;
	}

	onEntriesLoaded(entries) {
		this.metrics = entries;
	}

	updateState() {
		if(!this.metrics || this.metrics.length == 0)
			return;

		let label = i('No data');

		const metric = this.getMetric();
		const totalMetric = this.getTotalMetric();

		const value = getMetricValue(metric);

		let totalMetricValue = null;
		switch (this.datasource.metric.totalMetricType){
			case  TOTAL_METRIC_TYPE.METRIC:
				totalMetricValue = getMetricValue(totalMetric);
				break;
			case TOTAL_METRIC_TYPE.USER_INPUT:
				totalMetricValue = parseFloat(this.datasource.metric.totalMetricCustomValue);
				break;
		}

		if(value !== null) {
			label = this.formatValue(value)

			if (totalMetricValue) {
				label += ' / ' + this.formatValue(totalMetricValue)
			}

			const displayUnitType = this.datasource.metric.displayUnitType;
			let unit = null

			if(displayUnitType == DISPLAY_UNIT.METRIC_UNIT) {
				unit = metric.qualifier.unitTypeSymbol;
			}
			else if (displayUnitType == DISPLAY_UNIT.TOTAL_UNIT) {
				unit = totalMetric.qualifier.unitTypeSymbol;
			}
			else if (displayUnitType == DISPLAY_UNIT.CUSTOM_UNIT) {
				unit = this.datasource.metric.customUnit
			}

			if(unit){
				label += " " + unit
			}

			if(this.datasource.metricValueAsSeverity) {
				const valueToSeverity = this.datasource.metricValueToSeverity;

				let severityIndicator = valueToSeverity.findIndex(x => compareByOperator(x.operator, value, x.threshold));

				if(severityIndicator === -1) {
					severityIndicator = 3
				}
				this.setState(indicatorToState(severityIndicator));
			}
		}

		this.removeIcon();
		if (!this.datasource.hideMetricValue) {
			this.addContentLabel(label);
		}
	}

	formatValue(value){
		var decimalsNumber = this.datasource.decimalsNumber
		decimalsNumber = parseInt(decimalsNumber)
		return formatNumber(value, decimalsNumber )
	}

	getHealthIndex(){
		return null;
	}

	getSubscriptions(subscriptions) {
		return {
			metrics: this.metricsToLoad.map(x => x.metricId)
		};
	}

	consumeEvent(event) {
		if (event.eventType != 'Metric' )
			return false;

		const metric = this.getMetric();
		if(metric == null)
			return false;

		if(this.metrics[0].metricId === event.qualifierId) {
			if(metric.data[0].v !== event.metric.v) {
				metric.data[0] = event.metric;
				return true;
			}
			else {
				return false;
			}
		}

		if(this.metrics[1]?.metricId === event.qualifierId) {
			if(this.metrics[1].metricInfo.data[0].v !== event.metric.v) {
				this.metrics[1].metricInfo.data[0] = event.metric;
				return true;
			}
			else {
				return false;
			}
		}

		return false;
	}


	getTooltipInternal(accountName) {
		const metric = this.getMetric();
		if(!metric)
			return null;

		const value = getMetricValue(metric)

		if(value == null)
			return null

		let valueLabel = this.formatValue(value)

		let result = i('Metric: {0}', metric.qualifier.assetName + '/' + metric.qualifier.instanceName)

		result += "\n" + value + " " + metric.qualifier.unitTypeSymbol

		result += "\n\n"

		let timetstamp = new Date(metric.data[0].t)

		result += dateToString(timetstamp, "datetime")

		return result
	}

	redirect() {
		const metric = this.getMetric();
		if(metric) {
			State.mainApp.navigate(AssetsRouter.details(metric.qualifier.assetId));
		}
	}

	getMetric(){
		if( this.metrics?.length > 0
			&& this.metrics[0].metricInfo.found
			&& this.metrics[0].metricInfo.hasMetrics){
			return this.metrics[0].metricInfo;
		}
	}

	getTotalMetric() {
		if (this.datasource.metric.totalMetricType === TOTAL_METRIC_TYPE.METRIC
			&& this.metrics?.length > 1 && this.metrics[1].metricInfo.found && this.metrics[1].metricInfo.hasMetrics) {
			return this.metrics[1].metricInfo;
		}

		return null;
	}

	empty(){
		return this.datasource.metric.id == null;
	}

	getLabel(){
        let metric;
		if(this.datasource.metric.displayLabelType === DISPLAY_LABEL.METRIC_DISPLAY_LABEL) {
			metric = this.getMetric();
		}
		else if(this.datasource.metric.displayLabelType === DISPLAY_LABEL.TOTAL_DISPLAY_LABEL) {
			metric = this.getTotalMetric();
		}

		if(!metric) {
			return null;
		}

		return metric.qualifier.assetName + '/' + metric.qualifier.instanceName;
	}
}
