import React from 'react';
import ReactDOM from "react-dom";
import Utils from 'tools/utils';
import State from 'tools/state';
import Cookies from 'core/cookies';
import Settings from 'settings';
import ExpandableTextarea from 'controls/expandableTextarea';
import CustomNotification from 'controls/customNotification';
import Tags from 'controls/tags';
import {CustomTheme} from "tools";
import {ServiceBoardRouter} from 'areas/service-boards/bundleDescription';
import FormEntry from "controls/react/form/formEntry";
import {TagsSelect} from "controls/react/tagsSelect";

export default function ServiceBoardForm(config) {
	Utils.apply(this, config);

	this.initComponent();
};

jQuery.extend(ServiceBoardForm.prototype, {
	/**
	 * This is main init function
	 */
	initComponent: function () {
		var actionButtonText = lang.serviceBoard.CREATE;
		this.data = {};
		this.descriptionHandler = new ExpandableTextarea({
			renderTo: $('.cw_description')
		});

		//QUICKFIX
		$('#modal').attr('style', 'overflow:hidden !important');

		this.resolution = this.resolution || {};
		if (this.mode === 'update') {
			actionButtonText = lang.serviceBoard.UPDATE;
			this.summaryPage = '';
		}
		$('#save_service_board').text(actionButtonText);

		this.visibility = $('#cw_service_board_visibility').kendoDropDownList({
			dataTextField: 'text',
			dataValueField: 'value',
			dataSource: [{
				text: lang.PUBLIC,
				value: true
			}, {
				text: lang.PRIVATE,
				value: false
			}],
			dataBound: (e) => {
				if (this.isMobileSB) {
					e.sender.wrapper.closest('div.cw_field').hide()
				}
			}
		}).data('kendoDropDownList');

		this.informationOptionDropDown = $('#cw_service_board_information_option').kendoDropDownList({
			dataTextField: 'text',
			dataValueField: 'value',
			dataSource: [{
				text: lang.serviceBoard.LOCK_TO_SCREEN,
				value: 'locked'
			}, {
				text: lang.serviceBoard.SLIDE,
				value: 'slide'
			}, {
				text: lang.serviceBoard.OFF,
				value: 'off'
			}]
		}).data('kendoDropDownList');

		this.positionDropDown = $('#cw_service_board_position').kendoDropDownList({
			dataTextField: 'text',
			dataValueField: 'value',
			dataSource: [{
				text: lang.LEFT,
				value: 'left'
			}, {
				text: lang.RIGHT,
				value: 'right'
			}, {
				text: lang.CENTER,
				value: 'center'
			}]
		}).data('kendoDropDownList');

		this.resetBackground = true;

		this.colorPicker = $("#cw_color_picker").kendoColorPicker({
			value: '#dee8ec',
			buttons: false,
			change: () => {
				this.resetBackground = false;
			}
		}).data('kendoColorPicker');

		if (this.isMobileSB) {
			this.colorPicker.wrapper.closest('div.cw_field').hide()
		}

		this.resolutionHandler = $('#cw_service_board_resolution').kendoDropDownList({
			dataTextField: 'text',
			dataValueField: 'value',
			dataSource: [{
				text: lang.serviceBoard.LOCKED_RESOLUTION,
				value: 'LOCKED'
			}, {
				text: lang.serviceBoard.FIT_SCREEN,
				value: 'RESPONSIVE'
			}, {
				text: lang.serviceBoard.MOBILE_RESOLUTION,
				value: 'MOBILE'
			}],
			dataBound: (e) => {
				if (this.isMobileSB) {
					e.sender.wrapper.closest('div.cw_field').hide()
				}
			}
		}).data('kendoDropDownList');

		this.showHeadersToggle = $('#cw_service_board_show_headers').kendoDropDownList({
			dataTextField: 'text',
			dataValueField: 'value',
			dataSource: [{
				text: lang.SHOW,
				value: true
			}, {
				text: lang.HIDE,
				value: false
			}],
			dataBound: (e) => {
				if (this.isMobileSB) {
					e.sender.wrapper.closest('div.cw_field').hide()
				}
			}
		}).data('kendoDropDownList');

		this.widgetWidthField = $('#sb_widget_width').kendoNumericTextBox({
			decimals: 0,
			min: 10,
			spinners: false,
			value: 400,
			format: 'n0'
		}).data('kendoNumericTextBox');

		if (this.isMobileSB) {
			this.widgetWidthField.wrapper.closest('div.cw_field').hide();
			this.widgetWidthField.wrapper.closest('div.cw_field').parent().append(`<span>${lang.serviceBoard.messages.DOES_NOT_APPLY_TO_MOBILE}</span>`);
		}

		this.widgetHeightField = $('#sb_widget_height').kendoNumericTextBox({
			decimals: 0,
			min: 10,
			spinners: false,
			value: 340,
			format: 'n0'
		}).data('kendoNumericTextBox');

		if (this.isMobileSB) {
			this.widgetHeightField.wrapper.closest('div.cw_field').hide();
		}

		if (this.isMobileSB) {
			$('#cw_service_board_layout').closest('div.cw_field').hide();
			$('#cw_service_board_layout').parent().parent().append(`<span>${lang.serviceBoard.messages.DOES_NOT_APPLY_TO_MOBILE}</span>`);
			$('#cw_toggle_name_public').closest('div.cw_field').hide();
			$('#cw_service_board_visibility').closest('.cw_separator').find('.cw_field').hide();
			$('#cw_service_board_visibility').closest('.cw_separator').append(`<span>${lang.serviceBoard.messages.DOES_NOT_APPLY_TO_MOBILE}</span>`);
		}

		this.removeListeners();
		this.attachListeners();

		//$('#cw_public_sb').off().on('click', $.proxy(this.onSetPublicClick, this));
		if (this.mode === 'update') {
			this.load();
		} else {
			$('.remove_sb').hide();
			var focus = function () {
				$('#sb_tag').focus();
			};
			setTimeout(focus, 2000);
		}
		this.actionNotification = new CustomNotification({
			appendToElement: '.k-grid-content',
			hideOnClick: true,
			type: 'plain'
		});

		this.modalActionNotification = new CustomNotification({
			appendToElement: '#modal',
			hideOnClick: true,
			type: 'plain'
		});
		this.renderTags();
	},
	renderTags() {
		ReactDOM.render(<FormEntry label={lang.TAGS} vertical>
			<TagsSelect
				mode={'tags'}
				disabled={this.mode === 'view'}
				accountId={Cookies.CeesoftCurrentAccountId}
				includeSubaccounts={false}
				onChange={value => this.onTagsChange(value)}
				defaultValue={this.tagsList} />
		</FormEntry>, $('#cw_tags').parent().get()[0]);
	},
	onTagsChange(value) {
		this.tagsList = value || [];
	},
	/**
	 * Called in order to remove existing listeners
	 */
	removeListeners: function () {
		$('#save_service_board').off();
		$('#cancel').off();
	},
	/**
	 * Attaches the listeners for the form's buttons
	 */
	attachListeners: function () {
		$('#cancel').on('click', $.proxy(this.onCancelButton, this));
		$('#save_service_board').on('click', $.proxy(this.onSaveButton, this));

		$('#cw_service_board_layout li').on('click', $.proxy(function (e) {
			if ($(e.currentTarget).find('span:last').html() === 'Free') {
				$('.isAvailable').prop('readonly', true);
				this.resolutionHandler.enable(false);
				this.resolutionHandler.select(0);
			} else {
				this.resolutionHandler.enable(true);
				$('.isAvailable').prop('readonly', false);
			}

			this.onToggleClick(e);

			if (this.showHeadersToggle) {
				this.showHeadersToggle.value(true);
			}
		}, this));

		$('#cw_sb_info').on('mouseover', $.proxy(this.onSbInfoOver, this));
		$('#cw_sb_info').on('mouseout', $.proxy(this.onSbInfoOut, this));
		$('.cw_reset_background').on('click', () => {
			this.colorPicker.value('#dee8ec');
			this.resetBackground = true;
		})
	},
	/**
	 *
	 */
	onSbInfoOver: function (e) {
		$('body').append('<div id="modal_info"></div>');
		let target = $(e.target);
		let targetOffset = target.offset();
		let infoLeft = targetOffset.left + 25;
		let infoTop = targetOffset.top - 70;
		this.infoWindow = $('#modal_info').kendoWindow({
			content: 'include/gsboard/ServiceBoardInfo.jsp',
			width: 600,
			height: 230,
			title: false,
			draggable: false,
			resizable: false,
			position: {
				top: infoTop,
				left: infoLeft
			},
			refresh: function (e) {
				//$('#modal_info').css('overflow', 'hidden !important');
				var hideScroll = setInterval(function () {
					$('#modal_info').css('overflow', 'hidden');
				}, 10);
				setTimeout(function () {
					clearInterval(hideScroll);
				}, 1000)
			},
		}).data("kendoWindow");
		this.infoWindow.open();
	},
	/**
	 *
	 */
	onSbInfoOut: function (e) {
		this.infoWindow.close();
	},
	/**
	 * Handler function for the click event on the visibility public/private togle
	 */
	onToggleClick: function (e) {
		var li = $(e.currentTarget);
		if (!li.find('input').is(':checked')) {
			var inputBtn = li.find('input');
			var checked = inputBtn.is(':checked');
			li.parent().find('.is_selected').removeClass('is_selected');
			if (checked === 'checked') {
				inputBtn.prop('checked', false);
				li.removeClass('is_selected');
			} else {
				inputBtn.prop('checked', true);
				li.addClass('is_selected');
			}
		}
	},
	/**
	 * Removes the invalid CSS class for all controls
	 */
	clearInvalid: function () {
		$('#sb_tag').removeClass('invalid');
	},
	/**
	 * Loads the form data from current service board and set the values into
	 * component fields
	 */
	load: function () {
		var tag = State.currentApp.tag || this.tag;
		var description = State.currentApp.description || this.description;
		$('#sb_tag').val(tag);
		this.descriptionHandler.value(description);

		this.visibility.value(this.isPublic);
		this.informationOptionDropDown.value(this.informationOption);
		this.positionDropDown.value(this.position);
		this.resolutionHandler.value(this.resolution.type);

		if (this.layout === 'free') {
			$('#cw_sb_layout_free').parent().trigger('click');
		} else {
			$('#cw_sb_layout_grid').parent().trigger('click');
		}

		if (this.mode === 'update') {
			$('#cw_service_board_layout').addClass('is_disabled');
			$('#cw_service_board_layout li').off();
		}
		this.widgetWidthField.value(this.widgetWidth || 400);
		this.widgetHeightField.value(this.widgetHeight || 340);
		this.showHeadersToggle.value(State.currentApp.headers);

		if (this.backgroundColor) {
			this.resetBackground = false;
			this.colorPicker.value(this.backgroundColor);
		} else {
			this.colorPicker.value('#dee8ec');
		}

		this.tagsList = this.tags || [];
		$('#cw_toggle_name_public').prop('checked', this.showName);
		$('#cw_toggle_update_time').prop('checked', this.showUpdateTime);
	},
	/**
	 * Callback function that handles the save response
	 * @param {Object} result
	 */
	onDataSuccessfullySaved: function (result) {

		if (result.success) {
			this.id = result.data.id;

			// close window
			$('#cancel').trigger('click');

			// show status
			var successText = (this.mode === 'create' ? lang.serviceBoard.messages.SERVICE_BOARD_CREATED : lang.serviceBoard.messages.SERVICE_BOARD_UPDATED);
			this.actionNotification.setOptions({
				message: successText,
				status: 'success'
			}).show();

			if (this.isMobileSB) {
				return;
			}

			this.resolution.type = this.resolutionHandler.value();

			if (this.mode === 'update') {
				State.serviceBoardFormMode = 'edit';
				State.mainApp.navigate(ServiceBoardRouter.details(this.id));
			} else {
				if (State.mainApp.session.hasRole('SERVICEBOARD_READ')) {
					State.serviceBoardFormMode = 'create';
					State.mainApp.navigate(ServiceBoardRouter.details(result.data.id));
				} else {
					State.mainApp.loadModule('ServiceBoard');
				}
			}
		} else {
			this.modalActionNotification.setOptions({
				message: result.message,
				status: 'error'
			}).show();
		}
	},
	/**
	 * Handler function for the click event on Save button
	 * @param {Object} e The click event object
	 */
	onSaveButton: async function (e) {
		var content = '', status, isPublic, layout;
		var layoutTemplateId = $('#sb_layout').find('.current_layout').attr('id');
		this.clearInvalid();
		if (this.isMobileSB || this.mode === 'create') {
			content = [];
			isPublic = this.visibility.value() === 'true' ? true : false;
			layout = $('#cw_service_board_layout').find('.is_selected').find('input').val() === 'true' ? 'grid' : 'free';
			this.data = {
				type: 'LEGACY',
				headers: this.showHeadersToggle.value() === 'true' ? true : false,
				tag: $('#sb_tag').val().trim(),
				id: null,
				description: this.descriptionHandler.value().trim(),
				isPublic: isPublic,
				backgroundColor: this.getBackground(),
				resolution: {
					width: this.resolution.width || null,
					height: this.resolution.height || null,
					type: this.resolutionHandler.value()
				},
				informationOption: this.informationOptionDropDown.value(),
				position: this.positionDropDown.value(),
				content: JSON.stringify(content),
				layout: layout,
				tags: this.tagsList,
				widgetWidth: this.widgetWidthField.value() || 400,
				widgetHeight: this.widgetHeightField.value() || 340,
				showName: $('#cw_toggle_name_public').is(':checked'),
				showUpdateTime: $('#cw_toggle_update_time').is(':checked')
			};

			if (this.isMobileSB) {
				this.data.id = this.id;
				const {result: {data: contentData}} = await Utils.ajaxPromise(Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/dashboards/' + this.id + '/content');
				this.data.content = contentData;
			}

			if (this.data.tag.trim() === '') {
				status = $('#modal').find('.status');
				$('#sb_tag').addClass('invalid');

				this.modalActionNotification.setOptions({
					message: lang.serviceBoard.messages.Fill_NAME,
					status: 'error'
				}).show();

				return $('#sb_tag').focus();
			}

			const url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/dashboards/';
			Utils.ajax(url, 'POST', JSON.stringify(this.data), $.proxy(this.onDataSuccessfullySaved, this));

		} else {
			// if invalid, show error message
			if ($('#sb_tag').val().trim() === '') {
				this.modalActionNotification.setOptions({
					message: lang.serviceBoard.messages.Fill_NAME,
					status: 'error'
				}).show();

				$('#sb_tag').focus();
			} else {
				this.saveServiceBoard();
				$('#cancel').trigger('click');
			}
		}
	},
	/**
	 * Handler function for the click event on Cancel button
	 * @param {Object} e The click event object
	 */
	onCancelButton: function (e) {
		var modalWindow = $('#modal').data("kendoWindow");
		modalWindow.close();
		modalWindow.destroy();
	},
	/**
	 * Saves a service board content from cards list
	 */
	saveServiceBoard: function () {
		var serviceBoard,
			tag = $('#sb_tag').val(),
			description = this.descriptionHandler.value(),
			isPublic = this.visibility.value() === 'true' ? true : false,
			informationOption = this.informationOptionDropDown.value(),
			position = this.positionDropDown.value(),
			layout = $('#cw_service_board_layout').find('.is_selected').find('input').val() === 'true' ? 'grid' : 'free';

		if (layout === 'free') {
			serviceBoard = State.currentApp.serialize();
		} else {
			serviceBoard = $('#cw_sb_layout').data('gridster').serialize();
		}

		State.currentApp.isPublic = isPublic;

		serviceBoard.sort(function compare(a, b) {
			var value = 0;
			if (a.col < b.col) {
				value = -1;
			} else if (a.col > b.col) {
				value = 1;
			}
			return value;
		});

		this.data = {
			type: 'LEGACY',
			headers: this.showHeadersToggle.value() === 'true' ? true : false,
			tag: tag,
			id: this.id,
			description: description,
			isPublic: isPublic,
			informationOption: informationOption,
			position: position,
			backgroundColor: this.getBackground(),
			resolution: {
				width: this.resolution.width || null,
				height: this.resolution.height || null,
				type: this.resolutionHandler.value()
			},
			layout: layout,
			tags: this.tagsList,
			type: this.type,
			content: JSON.stringify(serviceBoard),
			widgetWidth: this.widgetWidthField.value() || 400,
			widgetHeight: this.widgetHeightField.value() || 340,
			showName: $('#cw_toggle_name_public').is(':checked'),
			showUpdateTime: $('#cw_toggle_update_time').is(':checked')
		};

		var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/dashboards/';
		Utils.ajax(url, 'POST', JSON.stringify(this.data), $.proxy(this.onDataSuccessfullySaved, this));
	},

	getBackground() {
		const backgroundColor = this.colorPicker.value();

		this.backgroundColor = (this.resetBackground || backgroundColor === '#dee8ec') ? null: backgroundColor;
		return this.backgroundColor;
	}
});
