export default function ensureThresholdsAreConsistent(e) {
	const currentFieldName = Object.keys(e.values)[0];

	if (currentFieldName !== 'critical' && currentFieldName !== 'warning') {
		return;
	}

	let theOtherFieldName, currentShouldBeLesser;

	if (currentFieldName === 'warning') {
		theOtherFieldName = 'critical';
		currentShouldBeLesser = !e.model.inverted;
	} else {
		theOtherFieldName = 'warning';
		currentShouldBeLesser = e.model.inverted;
	}

	const currentFieldValue = parseInt(e.values[currentFieldName]);
	const theOtherFieldValue = parseInt(e.model[theOtherFieldName]);

	if (currentShouldBeLesser && currentFieldValue > theOtherFieldValue
		|| !currentShouldBeLesser && currentFieldValue < theOtherFieldValue) {
		e.model.set(theOtherFieldName, parseInt(e.values[currentFieldName]));
	}
}
