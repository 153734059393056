import Settings from 'settings';
import Cookies from 'core/cookies';

function changePassword() {

	function getUsername() {
		var params = {}, hash;
		var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
		for (var i = 0; i < hashes.length; i++) {
			hash = hashes[i].split('=');
			params[hash[0]] = hash[1];
		}
		return params.user;
	}

	var username = getUsername();

	var html = '<li id="step1"><div class="changePassword"><div class="cw_field">' + Lang.account.messages.CHANGE_DEFAULT_PASSWORD + '</div>';
	html += '<div class="cw_field"><label for="old_password">' + Lang.OLD_PASSWORD + '</label><input class="k-input" type="password" name="old_password" placeholder="Old password" id="old_password"></div>';
	html += '<div class="cw_field"><label for="password">' + Lang.PASSWORD + '</label><input class="k-input" type="password" name="password" placeholder="New password" id="password"></div>';
	html += '<div class="cw_field"><label for="password2">' + Lang.CONFIRM_PASSWORD + '</label><input class="k-input" type="password" placeholder="Re-type New password" name="password2" id="password2"></div>';
	html += '</div></li>';
	$('.steps_wrap').append(html);

	function clearValues() {
		$('#old_password').val('');
		$('#password').val('');
		$('#password2').val('');
	}

	function clearValidations() {
		$('#old_password').removeClass('invalid');
		$('#password').removeClass('invalid');
		$('#password2').removeClass('invalid');
	}

	function addInlineValidations() {
		$('#old_password, #password, #password2').blur(function () {
			if ($(this).val() === '') {
				$(this).addClass('invalid');
			} else {
				$(this).removeClass('invalid');
			}
		});
	}

	function isFormValid() {
		var valid = true;
		var status = $('.status');
		var p1 = $('#password').val();
		var p2 = $('#password2').val();
		if (p1 !== p2) {
			valid = false;
			status.find('p').text(Lang.account.messages.USER_PASSWORD_IDENTICAL);
			status.find('p').addClass('error');
			status.show();
			$('#password2').addClass('invalid');
		}
		if (p1.length < 6) {
			valid = false;
			status.find('p').text(Lang.account.messages.USER_PASSWORD_LENGTH);
			status.find('p').addClass('error');
			status.show();
			$('#password').addClass('invalid');
		}
		if (p1.indexOf(' ') !== -1) {
			valid = false;
			status.find('p').text(Lang.account.messages.USER_PASSWORD_SPACES);
			status.find('p').addClass('error');
			status.show();
			$('#password').addClass('invalid');
		}
		status.css({'margin': '20px 30px 0'});
		return valid;
	}

	clearValues()

	$('#old_password').focus();

	$('#save_password').on('click', function () {
		if (isFormValid()) {
			var p0 = $('#old_password').val();
			var p1 = $('#password').val();
			var auth = {
				username: username,
				oldPassword: p0,
				newPassword: p1
			};
			jQuery.ajax({
				url: Settings.serverPath + 'sessions/changePassword',
				data: JSON.stringify(auth),
				type: "POST",
				dataType: "json",
				contentType: "application/json; charset=utf-8",
				crossDomain: true,
				beforeSend: function (x) {
					if (x && x.overrideMimeType) {
						x.overrideMimeType("application/json;charset=UTF-8");
					}
				},
				error: function (result, message) {
					var status = $('.status');
					status.find('p').addClass('error');
					status.find('p').text(result.statusText);
					status.show();
				},
				success: function (result) {
					if (result.success) {
						var login = {
							username: username,
							password: p1
						};
						jQuery.ajax({
							url: Settings.serverPath + 'sessions/login/',
							data: JSON.stringify(login),
							type: "POST",
							dataType: "json",
							contentType: "application/json; charset=utf-8",
							crossDomain: true,
							beforeSend: function (x) {
								if (x && x.overrideMimeType) {
									x.overrideMimeType("application/json;charset=UTF-8");
								}
							},
							error: function (result, message) {
								var status = $('.status');
								status.find('p').addClass('error');
								status.find('p').text(result.statusText);
								status.show();
							},
							success: function (result) {
								if (result.success) {
									var timeout = Settings.COOKIE_TIMEOUT;
									Cookies.create("CeesoftUsername", username, timeout);
									Cookies.create("CeesoftUserId", result.data.userId, timeout);
									Cookies.create("CeesoftAccountId", result.data.accountId, timeout);
									Cookies.create("CeesoftTimezone", result.data.timeZone, timeout);
									Cookies.create("CeesoftUserLocale", result.data.locale, timeout);
									Cookies.create("CeesoftAccountName", result.data.accountName, timeout);
									Cookies.create("CeesoftCurrentAccountId", result.data.accountId, timeout);
									Cookies.create("CeesoftCurrentAccountName", result.data.accountName, timeout);
									Cookies.create("CeesoftLocale", result.data.locale, timeout);
									Cookies.create("sessionId", result.data.sessionId, timeout);
									window.location = 'index.jsp';
								} else {
									var status = $('.status');
									status.find('p').addClass('error');
									status.find('p').text(result.message);
									status.show();

								}
							}
						});
					} else {
						$('.status').find('p').text(result.message);
						$('.status').find('p').addClass('error');
						$('.status').show();
					}
				}
			});
		}
	});
}

export default changePassword;