import {Utils, applyRequired} from 'tools/utils';
import ConfigurationBase from 'areas/service-boards/configurationBase';
import ErrorHandler from 'core/errorHandler';
import Settings from 'settings';
import Cookies from 'core/cookies';

export function StateWidgetConfiguration(config) {
	Utils.apply(this, config);
	this.initComponent();
};

export {StateWidgetConfiguration as default};

jQuery.extend(StateWidgetConfiguration.prototype, ConfigurationBase.prototype, {
	/**
	 * @cfg {Object} widgetObj
	 */
	/**
	 * Main init function
	 */
	initComponent: function () {
		var html;
		var widgetObj = this.widgetObj;

		html = '<div class="cw_field"><label class="cw_inline">' + lang.TITLE + ': </label><input type="text" class="cw_widget_title cw_inline" value="' + (widgetObj.title || '') + '" /></div>';
		html += '<div class="cw_field" id="cw_sla_account"><label class="cw_inline">' + lang.ACCOUNT + ': </label><div class="cw_dropdown_container"><input id="cw_bc_service_sla" type="text" value="' + (widgetObj.configuration.accountId || '') + '" /></div></div>';
		html += '<div class="cw_field">';
		html += '<input type="checkbox" checked id="cw_barsla_include_subaccounts_check" class="cw_show_column k-checkbox" />';
		html += '<label for="cw_barsla_include_subaccounts_check" class="k-checkbox-label">' + lang.INCLUDE_SUBACCOUNTS + '</label>';
		html += '</div>';

		this.widgetConfigurationFormEl.empty().append(html);

		this.removeListeners();
		this.attachListeners();

		if (widgetObj.configuration.includeSubaccounts === undefined || widgetObj.configuration.includeSubaccounts === true) {
			$('#cw_barsla_include_subaccounts_check').prop('checked', 'checked');
		} else {
			$('#cw_barsla_include_subaccounts_check').prop('checked', '');
		}

		this.initKendoComponents();

		const requiredFields = ['#cw_bc_service_sla'];
		applyRequired(requiredFields);
	},
	/**
	 * Removes listeners
	 */
	removeListeners: function () {

	},
	/**
	 * Attaches listeners
	 */
	attachListeners: function () {

	},
	/**
	 * Iniitalizes kendo components
	 */
	initKendoComponents: function () {
		$('#cw_bc_service_sla').kendoComboBox({
			dataSource: new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: Settings.serverPath + 'accounts/' + this.serviceBoardAccountId + '/subaccounts/allLevels/lite',
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				error: ErrorHandler.kendoServerError,
				sort: {field: 'name', dir: 'asc'}
			}),
			dataTextField: 'name',
			dataValueField: 'id',
			template: '<span id="${ data.id }">${ data.name }</span>',
			autoBind: true,
			placeholder: lang.SELECT,
			select: $.proxy(this.onAccountListSelect, this),
			value: this.mode === 'create' ? Cookies.CeesoftCurrentAccountId : null
		}).data('kendoComboBox');
	},
	/*
	 * Handler function for getting the widget configuration values
	 * */
	getValues: function () {
		var accountList = $('#cw_bc_service_sla').data('kendoComboBox');
		var title = $('.cw_widget_title').val().trim();
		var widgetObj = {
			id: this.mode === 'update' || this.wasUpdate ? this.widgetObj.id : Utils.guid(),
			type: 'bar_sla',
			title: title,
			configuration: {
				accountId: this.widgetAccountId || $('#cw_bc_service_sla').data('kendoComboBox').value(),
				includeSubaccounts: $('#cw_barsla_include_subaccounts_check').is(':checked')
			}
		};

		return widgetObj;
	},
	/*
	 * Checks if form is valid
	 * */
	isValid: function () {
		var valid = true;
		var validationMessage = '';

		var account = $('#cw_bc_service_sla').data('kendoComboBox');
		if (!Utils.isGuid(account.value())) {
			valid = false;
			validationMessage = lang.SELECT_ACCOUNT;
		}

		return {
			valid: valid,
			validationMessage: validationMessage
		};
	},
	/*
	 * Handler function for selecting an account
	 * @param {Object} e The select event
	 * */
	onAccountListSelect: function (e) {
		//For comboboxes we need to check if e.item is valid
		//if we write/search invalid accounts e.item is null
		if (e.item) {
			var item = e.item.find('span'), id = item.attr('id');
			if (id) {
				this.widgetAccountId = id;
				this.widgetAccountName = item.text();
			}
		}
	}
});
