import Utils from 'tools/utils';
import Api from 'tools/api';
import Cookies from 'core/cookies';
import State from 'tools/state';
import ImageUploader from 'controls/imageUploader';
import LocalEventsManager from 'core/localEventsManager';

export function ImageWidgetConfiguration(config) {
	Utils.apply(this, config);
	this.initComponent();
	this.removeListeners();
	this.attachListeners();
};

export {ImageWidgetConfiguration as default}

ImageWidgetConfiguration.prototype = {
	initComponent: function () {
		var widget = this.widgetConfigurationFormEl;
		var widgetObj = this.widgetObj;
		let html = '<div class="cw_field"><label class="cw_inline">' + lang.TITLE + ': </label><input type="text" class="cw_widget_title full cw_inline" value="' + (widgetObj.title || '') + '" /></div>';
		html += '<div class="left w20 clear">';
		html += '<input type="checkbox" class="cw_include k-checkbox" id="cw_remove_header"><label for="cw_remove_header" class="k-checkbox-label">' + lang.widget.HIDE_HEADER + '</label>';
		html += '</div>';
		html += '<div class="left w20">';
		html += '<input type="checkbox" class="cw_include k-checkbox padding_left_5" id="cw_set_transparent"><label for="cw_set_transparent" class="k-checkbox-label">' + lang.widget.TRANSPARENT + '</label>';
		html += '</div>';
		html += '<div class="left w20">';
		html += '<input type="checkbox" class="cw_include k-checkbox padding_left_5" id="cw_stretch"><label for="cw_stretch" class="k-checkbox-label">' + lang.widget.STRETCH + '</label>';
		html += '</div>';
		html +='</div>';
		html += '<div class="cw_separator cw_image_container" style="height: 80%"><div class="cw_separator_title">' + lang.IMAGE + '</div><span title="Upload new image" class="glyphicons plus-sign pointer cw_upload_image cw_upload_image_icon"></span>';
		html += '<div class="cw_preview_text cw_upload_image pointer hide"><span>' + lang.widget.SELECT_IMAGE + '</span></div>';
		html += '<div class="cw_widget_preview_container cw_upload_image pointer"><div id="cw_widget_ci_preview" class="cw_widget_image_preview"></div></div><div id="cw_widget_custom_image" class="hide"></div>';
		html += '<div class="cw_uploader_actions hide"><span class="k-button" id="back">' + lang.BACK + '</span><span class="margin_left4 k-button k-primary" id="update">' + lang.SAVE + '</span></div>';
		html += '</div></div>';
		widget.empty().append(html);
		if (widgetObj.configuration.imageId) {
			const url = Api.images.urls.image(widgetObj.configuration.imageId, widgetObj.configuration.accountId);
			widget.find('#cw_widget_ci_preview').append('<img class="cw_widget_image" src="' + url + '">');
		}
		$('#cw_widget_custom_text').val(widgetObj.configuration.text);
		$('#cw_remove_header').prop('checked', widgetObj.configuration.removeHeader);
		$('#cw_remove_border').prop('checked', widgetObj.configuration.removeBorder);
		$('#cw_set_transparent').prop('checked', widgetObj.configuration.isTransparent);
		$('#cw_stretch').prop('checked', widgetObj.configuration.stretch);
		if (widgetObj.configuration.stretch) {
			this.stretchImage();
		}
		if ($('#cw_widget_ci_preview').is(':empty')) {
			$('.cw_preview_text').removeClass('hide');
		}
	},
	/**
	 * Removes listeners
	 */
	removeListeners: function () {
		$('.cw_upload_image').off();
		$('#back').off();
		$('#update').off();
		$('#cw_stretch').off();
		LocalEventsManager.unbind('loadRecentIcons');
	},
	/**
	 * Attaches listeners
	 */
	attachListeners: function () {
		$('.cw_upload_image').on('click', $.proxy(this.renderUploaderImagesControl, this));
		$('#back').on('click', $.proxy(this.onBackButton, this));
		$('#update').on('click', $.proxy(this.onUpdateButton, this));
		$('#cw_stretch').on('change', $.proxy(this.onStretchImage, this));
	},
	/**
	 * Iniitalizes kendo components
	 */
	renderUploaderImagesControl: function () {
		$('.cw_widget_preview_container').addClass('hide');
		$('.cw_preview_text').addClass('hide');
		$('#cw_widget_custom_image').removeClass('hide');
		$('.cw_uploader_actions').removeClass('hide');
		this.imageWidgetUploadFileField = new ImageUploader({
			id: 'cw_widget_custom_image',
			width: '100%',
			height: '90%',
			onImageUploaded: $.proxy(function (id) {
				this.uploadedImageId = id;
				var url = Api.images.urls.image(this.uploadedImageId);
				$('#cw_widget_ci_preview').empty().append('<img class="cw_widget_image" src="' + url + '">');
				this.onBackButton();
			}, this)
		});
		LocalEventsManager.bind('loadRecentIcons', $.proxy(this.loadRecentIcons, this));
		$('#cw_widget_custom_image_glyphs').on('click', '.item', $.proxy(this.onIconClick, this));
		$('#cw_widget_custom_image_user').on('click', '.item', $.proxy(this.onImageClick, this));

		console.log($('#cw_widget_custom_image').height());
		let imageContainerHeight = $('#cw_widget_custom_image').height();
		if (imageContainerHeight < 320) {
			$('#cw_widget_custom_image').css('height', 320);
		}

	},
	onBackButton: function () {
		$('.cw_uploader_actions').addClass('hide');
		$('#cw_widget_custom_image').addClass('hide').empty();
		$('.cw_widget_preview_container').removeClass('hide');
		if ($('#cw_widget_ci_preview').is(':empty')) {
			$('.cw_preview_text').removeClass('hide');
		}
	},
	onUpdateButton: function () {
		this.onBackButton();
	},
	onIconClick: function (e) {
		var icon = $(e.currentTarget).data('id');
		var iconPack = $(e.currentTarget).data('icon-pack');
		var container = $('#cw_widget_ci_preview');
		if (iconPack === 'glyph') {
			container.empty().prepend('<span class="cw_widget_image glyphicons ' + icon + '"></span>');
		}
		else {
			container.empty().prepend('<i class="material-icons ">' + icon + '</i>');
		}
	},

	onImageClick: function (e) {
		var target = $(e.currentTarget);
		var imageSrc = target.find('img').attr('src').replace('/thumbnail', '');
		this.uploadedImageId = target.attr('data-id');
		$('#cw_widget_ci_preview').empty().append('<img class="cw_widget_image" src="' + imageSrc + '">');
		if (this.widgetObj.configuration.stretch) {
			this.stretchImage();
		}
	},

	getValues: function () {
		var title = $('.cw_widget_title').val().trim() || lang.IMAGE;
		var widgetObj = {
			id:  this.widgetObj.id || Utils.guid(),
			type: 'custom_image',
			title: title,
			configuration: {
				imageId: this.uploadedImageId || this.widgetObj.configuration.imageId,
				removeHeader: $('#cw_remove_header').is(':checked'),
				removeBorder: $('#cw_remove_border').is(':checked'),
				isTransparent: $('#cw_set_transparent').is(':checked'),
				stretch: $('#cw_stretch').is(':checked')
			}
		};
		return widgetObj;
	},
	onStretchImage: function (e) {
		var target = $(e.currentTarget);
		if (target.is(':checked')) {
			this.stretchImage();
		} else {
			this.shrinkImage();
		}
	},
	stretchImage: function () {
		var image = $('#cw_widget_ci_preview').find('img');
		var width = 400;
		var height = 279;
		image.attr('width', width);
		image.attr('height', height - 15);
	},
	shrinkImage: function () {
		var image = $('#cw_widget_ci_preview').find('img');
		image.removeAttr('width');
		image.removeAttr('height');
	},
	isValid: function () {
		return {
			valid: true,
			validationMessage: ''
		};
	},
	/**
	 * Loads recent icons
	 * @param {Object} e The object sent by event manager
	 */
	loadRecentIcons: function (e) {
		this.recentlyUsedIcons = e.recentIcons;
	}
};




