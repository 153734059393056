import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import "./ceeviewNumericField.less";
import classnames from "classnames";

let b = require('b_').with('cw-numeric');

const PRECISION = 5;

export function toFixed(x) {
    x = +x;

    if (!isNaN(x)) {
        const log = -Math.floor(Math.log10(x));
        let decimals = log + PRECISION;
        decimals = decimals < 0 ? 0: decimals;
        decimals = decimals > 20 ? 20: decimals;

        let fixed = x.toFixed(decimals);

        const index = fixed.indexOf('.');

        if (index !== -1) {
            let searchIndex;

            for (searchIndex = fixed.length - 1; searchIndex >= index; searchIndex--) {
                if (!['0', '.'].includes(fixed[searchIndex])) {
                    break;
                }
            }

            fixed = fixed.substring(0, searchIndex + 1);
        }

        return fixed;
    }

    return x;
}

export function CeeviewNumericField(props) {
    const [value, setValue] = useState(toFixed(props.value));
    const {decimals, invalid} = props;

    useEffect(() => {
        setValue(props.value);
    }, [props.value])

    const onChange = React.useCallback((e) => {
        const internalValue = stripNotAllowedSymbols(e.target.value);

        setValue(internalValue);

        if (internalValue === '' && props.allowEmpty) {
        	props.onChange('')
		} else {
			props.onChange(getValidNumber(internalValue));
		}
    }, [props.allowEmpty, props.onChange]);

    function stripNotAllowedSymbols(value) {
        let stripped = value.replace(/[^0-9\.,]/ig, '');

        if (decimals === 0) {
            stripped = value.replace(/[\.,]/ig, '');
        }

        const commaIndex = stripped.lastIndexOf(".");
        const pointIndex = stripped.lastIndexOf(",");
        let finalIndex;
        let out;

        if (commaIndex >= 0 && pointIndex >= 0) {
            finalIndex = commaIndex > pointIndex ? commaIndex: pointIndex;
        }
        else if (commaIndex >= 0) {
            finalIndex = commaIndex;
        }
        else if (pointIndex >= 0) {
            finalIndex = pointIndex;
        }

        if (finalIndex) {
            const firstPart = stripped.substring(0, finalIndex).replace(/[\.,]/ig, '');
            const lastPart = stripped.substring(finalIndex);

            out = `${firstPart}${lastPart}`
        }
        else {
            out = stripped;
        }

        return out;
    }

    function getValidNumber(value) {
        return +value.replace(/[,]/ig, '.');
    }

    return <div className={classnames({[b('invalid')]: invalid}, b('container'))}>
        <input value={value} onChange={onChange} className={classnames(b('input'))} />
    </div>
}

CeeviewNumericField.propTypes = {
    decimals: PropTypes.number,
    wrapperRef: PropTypes.object,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]).isRequired,
    onChange: PropTypes.func.isRequired,
    invalid: PropTypes.bool,
    readOnly: PropTypes.bool
}

export default CeeviewNumericField;
