import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";
import {getErrorMessage} from 'controls/react/form/formBuilder/selectors/getErrorMessage';
import './errorMessage.less';

export const b = require('b_').with('formBuilder-error-message');

function ErrorMessage(props) {
	const [message, setMessage] = React.useState();

	const {errorMessage, errorTimestamp} = useSelector(getErrorMessage);

	React.useEffect(() => {
		if (errorMessage && errorTimestamp) {
			setMessage(errorMessage);
		}

		setTimeout(() => {
			setMessage(null);
		}, 20000);
	}, [errorMessage, errorTimestamp]);

	const handleClick = React.useCallback(() => {
		setMessage(null);
	}, [])

	if (!message) {
		return null;
	}

	return <div className={b()}>
		{message}
		<div className={"k-icon k-i-close " + b('close')} onClick={handleClick} />
	</div>
}

export default ErrorMessage;
