import {Localization} from "core";

export const sharedLocalization = new Localization({
  "Monitor configuration": {
    "no": "Monitorkonfigurasjon"
  },
  "Target information": {
    "no": "Målinformasjon"
  },
  "Connection information": {
    "no": "Tilkoblingsinformasjon"
  },
  "Monitor information": {
    "no": "Monitorinformasjon"
  },
  "Monitor error": {
    "no": "Monitorfeil"
  },
  "CVID": {
    "no": "CVID"
  },
  "General configuration": {
    "no": "Generelt konfigurasjon"
  },
  "Log configuration": {
    "no": "Logg konfigurasjon"
  },
  "File": {
    "no": "Fil"
  },
  "Command": {
    "no": "Kommando"
  },
  "URL": {
    "no": "URL"
  },
  "Folder": {
    "no": "Mappe"
  },
  "Source selection": {
    "no": "Kildevalg"
  },
  "File pattern": {
    "no": "Filmønster"
  },
  "Asset name": {
    "no": "Ressursnavn"
  },
  "Folder selection": {
    "no": "Mappevalg"
  },
  "Folder name": {
    "no": "Mappenavn"
  },
  "File name": {
    "no": "Filnavn"
  },
  "Please input name...": {
    "no": "Skriv inn navn..."
  }
});
