import React, {useEffect, useState} from "react";
import {translator} from "core";
import {Window} from "controls/react/kendoWrappers";
import {Api} from "tools/api";

const i = translator({
  "Test source": {
    "no": "Testkilde"
  }
});

function PageSourceWindow(props) {
	const [content, setContent] = useState('');

	useEffect(() => {
		const fetch = async () => {
			const result = await Api.fetch(props.url, {plainText: true});
			setContent(result);
		};

		fetch();
	}, []);

	return <Window title={i("Test source")} modal={true} width={"500px"} height={"500px"} onClose={props.onClose} containerClass={"cw_configuration_attachment_notification_window"} >
		<textarea className={"cw_configuration_attachment_notification_textarea"} value={content} />
	</Window>
}

export default PageSourceWindow;
