import CustomNotification from 'controls/customNotification'
import Utils from 'tools/utils';
import Settings from 'settings';
import Session from 'core/session';
import Application from 'core/application';
import State from 'tools/state';
import Cookies from 'core/cookies';
import RemoteEventsManager from 'core/remoteEventsManager';
import LocalEventsManager from 'core/localEventsManager';

import ServiceBoardView from './serviceBoardView'
import AbsoluteServiceBoardView from './absoluteServiceBoardView'
import {ApplicationState} from "framework/applicationState";


export default function StandaloneServiceBoard(config) {
	Utils.apply(this, config);
	this.module = {
		name: 'ServiceBoardView',
		initialConfig: config
	};
	this.layout = this.layout || 'grid';
	Utils.getConfig($.proxy(function () {
		this.initComponent();
	}, this));
};
//inheritance

jQuery.extend(StandaloneServiceBoard.prototype, Application.prototype, {
	/**
	 * @property
	 * @type string
	 */
	type: 'Application',
	/**
	 * Service Board aplication related initializations
	 */
	initComponent: function () {
		this.statusNotification = new CustomNotification({
			animationTime: 7500,
			appendToElement: '#cw_sb_layout',
			status: 'error',
			hideOnClick: false
		});
		this.locale = Cookies.sbLocale || Cookies.CeesoftUserLocale || 'en-US';
		this.removeListeners();
		this.attachListeners();
		this.kendoLocaleLoaded = false;
		kendo.culture(this.locale);
		this.kendoLocaleLoaded = true;
		this.localeLoaded = true;
		this.updateLangMessages();
		this.checkSession();
	},
	/**
	 * Attaches listeners
	 */
	attachListeners: function () {
		$(document).on('click', $.proxy(this.resetTimer, this));
		$(document).on('keypress', $.proxy(this.resetTimer, this));
		$(document).on('mousemove', $.proxy(this.resetTimer, this));
		$(document).on('mousedown', $.proxy(this.resetTimer, this));
		$(document).on('DOMMouseScroll', $.proxy(this.resetTimer, this));
		$(document).on('mousewheel', $.proxy(this.resetTimer, this));


		LocalEventsManager.bind('serviceboardGoToLogin', this.serviceBoardGoToLogin);

		//$(window).resize($.proxy(this.onWindowResize, this));
		$(window).keypress(function (e) {
			if (e.keyCode === 27) {
				e.preventDefault();
			}
		});
		$('#login').on('click', $.proxy(this.doLogin, this));
		$('#username').blur(function () {
			if ($(this).val().trim() === '') {
				$(this).addClass('invalid');
			} else {
				$(this).removeClass('invalid');
			}
		});
		$('#password').blur(function () {
			if ($(this).val().trim() === '') {
				$(this).addClass('invalid');
			} else {
				$(this).removeClass('invalid');
			}
		});
	},
	/**
	 * Removes listeners
	 */
	removeListeners: function () {
		LocalEventsManager.unbind('serviceboardGoToLogin', this.serviceBoardGoToLogin);

		$(window).off();
		$('#login').off();
	},

	startTimer: function () {
		this.timeoutId = window.setTimeout($.proxy(this.goInactive, this), Settings.instanceTimeout * 60000);
	},
	resetTimer: function () {
		window.clearTimeout(this.timeoutId);
		this.goActive();
	},
	goActive: function () {
		this.startTimer();
	},
	goInactive: function () {
		Cookies.erase('sbAccountId');
		Cookies.erase('sbSessionId');
		var url = 'Standalone.jsp?sbId=' + this.id;
		Utils.redirectTo(url);
	},
	updateLangMessages: function () {
		$.fn.kendoCustomGrid.widget.fn.options.noRecords = {
			template: lang.grid.NO_RECORDS
		};
		if (kendo.ui.Pager) {
			kendo.ui.Pager.fn.options.messages = lang.kendo.pager.messages;
		}
		if (kendo.ui.ColumnMenu) {
			kendo.ui.ColumnMenu.fn.options.messages = lang.kendo.columnMenu.messages;
		}
	},
	checkSession: function () {
		if (Cookies.sbSessionId) {
			var url = Settings.serverPath + 'sessions/' + Cookies.sbSessionId;
			Utils.ajax(url, 'GET', {}, $.proxy(function (result) {
				if (result.success) {
					this.sessionId = Cookies.sbSessionId;
					this.setUpSession();
				} else {
					Cookies.erase(this.id);
					Cookies.erase('sbSessionId');
					Cookies.erase('sbAccountId');
					Cookies.erase('CeesoftTimezone');
					Cookies.erase('CeesoftUserLocale');
					Cookies.erase('CeesoftUserDateTimeFormat');
					Cookies.erase('CeesoftUserDateFormat');
					Cookies.erase('CeesoftUserTimeFormat');
					this.start();
				}
			}, this));
		} else {
			//this.setUpSession();
		}
	},
	setUpSession: function () {
		this.session = new Session({
			sessionId: this.sessionId
		});

		this.session.getUserRoles(this.onRolesRetrieve, this);
	},
	onRolesRetrieve: function (roles) {
		this.start();
	},
	start: function () {
		if (this.kendoLocaleLoaded && this.localeLoaded) {
			if (Cookies[this.id]) {
				/*this.sessionId = Cookies.sbSessionId;
				 this.requestPath = Settings.serverPath + 'sessions/' + this.sessionId + '/';
				 this.accountId = Cookies.sbAccountId;
				 */
				$('._login_section').addClass('hide');
				$('body').removeClass('login');
				$('#cw_sb_layout').removeClass('hide');
				$('body').css('background-color', '#dee8ec');
				if (this.session.hasRole('SERVICEBOARD_READ')) {
					this.createSocketConnection();
				} else {
					this.statusNotification.setOptions({
						message: lang.serviceBoard.messages.NO_READ_PERMISSION,
						status: 'error'
					}).show();
				}
				//this.load(this.id);
				//setTimeout($.proxy(this.subscribe, this), 2000);
			} else {
				$('#username').val(Cookies.CeesoftUsername);
				$('#password').focus();
				$('#login_form').slideDown(400).delay(200).animate({
					top: '-15px'
				});
			}
		}
	},
	loadProperBoard: function () {
		const config = {
			id: this.id,
			sessionId: this.sessionId || Cookies.guestSessionId,
			hasEvents: true,
			accountId: this.accountId,
			loadedBy: 'standalone',
			layout: this.layout,
			headers: this.headers,
			widgetWidth: this.widgetWidth,
			widgetHeight: this.widgetHeight,
			showName: this.showName,
			showUpdateTime: this.showUpdateTime,
			informationOption: this.informationOption,
			position: this.position,
			resolution: this.resolution,
			renderName: true
		};

		let module;
		if (this.layout === 'free') {
			module = new AbsoluteServiceBoardView();
		} else {
			module = new ServiceBoardView();
		}
		State.currentApp = module;
		module.init(this.id, config);
	},

	/*
	 * Handler function for redirecting to login if error is thrown by SB
	 * @param {Object} response
	 */
	serviceBoardGoToLogin: function (response) {
		Cookies.erase(response.scope.id);
		response.scope.start();
		$('._login_section').removeClass('hide');
		var status = $('.status');
		status.find('p').text(response.message);
		status.find('p').addClass('error');
		status.show().delay(2000).slideUp();
	},
	/**
	 * Authenticates the user and obtains the sessionId
	 */
	doLogin: function (e) {
		e.preventDefault();
		var status = $('.status');
		var username = $('#username').val();
		var password = $('#password').val();
		if (username && password) {
			$('#username').removeClass('invalid');
			$('#password').removeClass('invalid');
			var auth = {
				username: username,
				password: password
			};
			$(this).hide();
			jQuery.ajax({
				url: Settings.serverPath + 'sessions/login/?detached=true',
				data: JSON.stringify(auth),
				type: "POST",
				dataType: "json",
				contentType: "application/json; charset=utf-8",
				crossDomain: true,
				beforeSend: function (x) {
					if (x && x.overrideMimeType) {
						x.overrideMimeType("application/json;charset=UTF-8");
					}
				},
				error: function (result, message) {
					$('#login').show();
					status.find('p').addClass('error');
					status.find('p').text(lang.account.messages.WARNING_USER_AUTH_CONNECTION_ISSUE);
					status.show().delay(2000).slideUp();
				},
				success: $.proxy(function (result) {
					if (result.success) {
						if (result.data.locale && this.locale !== result.data.locale) {
							Cookies.create('sbLocale', result.data.locale, 24);
							kendo.culture(result.data.locale);

						}
						this.sessionId = result.data.sessionId;
						ApplicationState.apiToken = this.sessionId;
						this.requestPath = Settings.serverPath + 'sessions/' + this.sessionId + '/';
						this.accountId = result.data.accountId;
						Cookies.create(this.id, Utils.guid(), 24);
						Cookies.create('sbSessionId', this.sessionId, 24);
						Cookies.create('sbAccountId', this.accountId, 24);
						Cookies.create('CeesoftTimezone', result.data.timeZone, 24);
						Cookies.create('CeesoftUserLocale', result.data.locale, 24);
						Cookies.create('CeesoftUserDateTimeFormat', result.data.dateTimeFormat, 24);
						Cookies.create('CeesoftUserDateFormat', result.data.dateFormat, 24);
						Cookies.create('CeesoftUserTimeFormat', result.data.timeFormat, 24);
						$('._login_section').addClass('hide');
						$('#cw_sb_layout').removeClass('hide');
						$('body').css('background-color', '#dee8ec');
						if (this.id) {
							this.setUpSession();
						}
					} else {
						$('#login').show();
						switch (result.message) {
							case 'wizard':
								window.location = 'Wizard.jsp';
								break;
							case 'umchpwd':
								window.location = 'ChangePassword.jsp?user=' + username;
								break;
							default:
								var status = $('.status');
								status.find('p').text(result.message);
								status.find('p').addClass('error');
								status.show().delay(2000).slideUp();
						}
					}
				}, this)
			});
		} else {
			if (!username) {
				$('#username').addClass('invalid');
			}
			if (!password) {
				$('#password').addClass('invalid');
			}
		}
	},
	/**
	 * Renders a serviceboard based on JSON object
	 * @param {Object} serviceBoard
	 */
	renderContent: function (serviceBoard) {
	},
	/*
	* Handler function for creating the event source
	* */
	createSocketConnection: function () {
		this.subscriberId = Utils.guid();
		this.eventSourceId = Utils.guid();

		RemoteEventsManager.connect({
			sessionId: this.sessionId || Cookies.sbSessionId,
			subscriberId: this.subscriberId,
			eventSourceId: this.eventSourceId,
			handleEvents: $.proxy(this.handleEvents, this),
			onInitialized: $.proxy(this.loadProperBoard, this),
			onSessionExpired: function () {
				Cookies.erase('sbSessionId');
				location.reload();
			}
		});
	},
	/**
	 * Called when one or multiple events are received from the server
	 * @param {Object} data The event data
	 */
	handleEvents: function (data) {
		LocalEventsManager.trigger('boardUpdate');

		if (State.currentApp && State.currentApp.handleEvents) {
			State.currentApp.handleEvents(data);
		}
	},

	/*
	* Destroy
	* */
	destroy: function () {

	}
});
