import {Utils, applyRequired} from 'tools/utils';
import Settings from 'settings'
import ConfigurationBase from 'areas/service-boards/configurationBase'
import ErrorHandler from 'core/errorHandler'

export function SummaryWidgetConfiguration(config) {
	Utils.apply(this, config);
	this.initComponent();
};

export {SummaryWidgetConfiguration as default}

jQuery.extend(SummaryWidgetConfiguration.prototype, ConfigurationBase.prototype, {
	/**
	 * @cfg {Object} widgetObj
	 */
	/**
	 * Main init function
	 */
	initComponent: function () {
		var html;
		var widgetObj = this.widgetObj;

		//backwards compatibility
		if (widgetObj.configuration.expandElements === true || widgetObj.configuration.expandElements === 'true') {
			widgetObj.configuration.expandElements = 'all';
		} else if (widgetObj.configuration.expandElements === false || widgetObj.configuration.expandElements === 'false') {
			widgetObj.configuration.expandElements = 'none';
		}
		if (!widgetObj.configuration.showQualifiers) {
			widgetObj.configuration.showQualifiers = 'all';
		}

		html = '<div class="cw_field">';
		html += '<label class="cw_inline">' + lang.TITLE + ': </label>';
		html += '<input type="text" class="cw_widget_title cw_inline" value="' + (widgetObj.title || '') + '" />';
		html += '</div>';
		html += '<div class="cw_field" id="cw_summary_account">';
		html += '<label class="cw_inline">' + lang.ACCOUNT + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_summary_accountid" type="text" value="' + (widgetObj.configuration.accountId || '') + '" /></div>';
		html += '</div>';
		html += '<div class="cw_field" id="cw_summary_service">';
		html += '<label class="cw_inline">' + lang.widget.SELECT_SERVICE + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_summary_sid" type="text" value="' + (widgetObj.configuration.serviceId || '') + '"></div>';
		html += '</div>';
		html += '<div class="cw_separator">';
		html += '<span class="cw_separator_title">' + 'Display settings' + '</span>';
		html += '<div class="cw_field">';
		html += '<label class="cw_inline">' + 'Service element' + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_elements_expand" type="text" value="' + (widgetObj.configuration.expandElements || '') + '" /></div>';
		html += '</div>';
		html += '<div class="cw_field">';
		html += '<label class="cw_inline">' + 'Servicequalifier' + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_qualifiers_expand" type="text" value="' + (widgetObj.configuration.showQualifiers || '') + '" /></div>';
		html += '</div>';
		html += '</div>';
		html += '</div>';

		this.widgetConfigurationFormEl.empty().append(html);

		this.initKendoComponents();

		const requiredFields = ['#cw_summary_accountid', '#cw_summary_sid'];
		applyRequired(requiredFields);
	},
	/**
	 * Iniitalizes kendo components
	 */
	initKendoComponents: function () {
		this.createAccountComponent({
			element: $('#cw_summary_accountid'),
			container: $('#cw_summary_account'),
			nextElement: $('#cw_summary_sid'),
			events: {
				dataSourceChange: $.proxy(function (e, length) {
					this.emptyComponent({
						containers: [{
							container: $('#cw_summary_service'),
							label: lang.widget.SERVICE
						}],
						length: length
					});
				}, this)
			}
		});
		$('#cw_summary_sid').kendoComboBox({
			dataSource: new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: $.proxy(function () {
							return Settings.serverPath + 'accounts/' + (this.widgetAccountId || this.widgetObj.configuration.accountId) + '/services/lite?hasModel=true';
						}, this),
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				error: ErrorHandler.kendoServerError,
				sort: {field: 'name', dir: 'asc'}
			}),
			dataTextField: 'name',
			dataValueField: 'id',
			autoBind: this.mode === true || this.wasUpdate,
			placeholder: lang.SELECT
		}).data('kendoComboBox');

		this.expandElementsType = $('#cw_elements_expand').kendoDropDownList({
			dataSource: [{
				text: lang.NONE,
				value: 'none',
			}, {
				text: lang.ALL,
				value: 'all',
			}, {
				text: lang.serviceBoard.ELEMENTS_DOWN_WARNING,
				value: 'downWarning',
			}],
			dataTextField: 'text',
			dataValueField: 'value',
			change: () => {
				if (this.expandElementsType.value() === 'none') {
					this.expandQualifiersType.enable(false);
				} else {
					this.expandQualifiersType.enable(true);
				}
			}
		}).data('kendoDropDownList');

		this.expandQualifiersType = $('#cw_qualifiers_expand').kendoDropDownList({
			dataSource: [{
				text: lang.ALL,
				value: 'all',
			}, {
				text: lang.serviceBoard.QUALIFIERS_BREACHED_WARNING,
				value: 'breachedWarning',
			}],
			dataTextField: 'text',
			dataValueField: 'value'
		}).data('kendoDropDownList');

		if (this.expandElementsType.value() === 'none') {
			this.expandQualifiersType.enable(false);
		}
	},
	/*
	 * Handler function for getting the widget configuration values
	 * */
	getValues: function () {
		let title = $('.cw_widget_title').val().trim();
		let serviceCombo = $('#cw_summary_sid').data('kendoComboBox');
		let serviceId = serviceCombo.value();
		let serviceName = serviceCombo.text();
		let modelId = serviceCombo.dataSource.get(serviceId).modelId;
		let widgetObj = {
			id: this.mode === 'update' || this.wasUpdate ? this.widgetObj.id : Utils.guid(),
			type: 'summary',
			title: title,
			configuration: {
				accountId: this.widgetAccountId || $('#cw_summary_accountid').data('kendoComboBox').value(),
				modelId: modelId,
				serviceId: serviceId,
				serviceName: serviceName,
				expandElements: this.expandElementsType.value(),
				showQualifiers: this.expandQualifiersType.value()
			}
		};

		return widgetObj;
	},
	/*
	 * Checks if form is valid
	 * */
	isValid: function () {
		var valid = true;

		this.validationMessage = '';

		var accountCombo = $('#cw_summary_accountid').data('kendoComboBox');
		if (!Utils.isGuid(accountCombo.value())) {
			valid = false;
			this.validationMessage = lang.widget.SELECT_ACCOUNT;
		}
		var smCombo = $('#cw_summary_sid').data('kendoComboBox');
		if (!Utils.isGuid(smCombo.value())) {
			valid = false;
			this.validationMessage = lang.widget.SELECT_SERVICE_MODEL;
		}

		return {
			valid: valid,
			validationMessage: this.validationMessage
		};
	}
});
