import Utils from 'tools/utils'
import Settings from 'settings'


export default function Session(config) {
	Utils.apply(this, config);
	this.userRoles = [];
	this.hasV2Permissions = false;
};

/**
 * Retrieves user roles from the server
 * @param {Function} callBackFn The callback function to be called after getting permissions
 * @param {Object} scope The scope of the callback function
 */
Session.prototype.getUserRoles = function (callBackFn, scope) {
	var url;
	if (this.sessionId) {
		url = Settings.serverPath + 'sessions/' + this.sessionId + '/roles/list';
	} else {
		url = Settings.serverPath + 'sessions/roles/list';
	}
	Utils.ajax(url, 'GET', {}, $.proxy(function (result) {
		this.userRoles = result;
		if (this.hasRole('PERMISSION_V2')) {
			this.hasV2Permissions = true;
		}
		if (callBackFn) {
			callBackFn.call(scope, result);
		}
	}, this));
};

/**
 * Check if the current logged user has some role
 * @param {String} role The role to be checked
 * @return {Boolean} hasRole True if the user has the role, false otherwise
 */
Session.prototype.hasRole = function (role) {
	var length = this.userRoles.length;
	var hasRole = false;
	for (var i = 0; i < length; ++i) {
		if (this.userRoles[i] === role) {
			hasRole = true;
			break;
		}
	}
	return hasRole;
};

