import ReactDOM from "react-dom";
import React from 'react';
import classnames from 'classnames';

import {Utils, toggleEllipsis, completeColumnsWidth} from 'tools/utils';
import Application from 'core/application';
import PreferencesPanel from 'controls/preferencesPanel';
import GridMenu from 'controls/gridMenu';
import CustomNotification from 'controls/customNotification';
import UserPrefs from 'tools/userPrefs';
import State from 'tools/state';
import Settings from 'settings';
import Cookies from 'core/cookies';
import Renderer from 'tools/renderer';
import RemoteEventsManager from 'core/remoteEventsManager';
import EventsToolbar from 'controls/eventsToolbar';
import ErrorHandler from 'core/errorHandler';
import ModalWindow from 'controls/modalWindow';
import MultiSelectGridFilter from 'controls/multiSelectGridFilter';
import {severityToCssClass} from 'areas/assets/indicators';
import {AgentsRouter} from 'areas/management/agents/bundleDescription';
import {SlaRouter} from 'areas/sla/bundleDescription';
import {ServicesRouter} from 'areas/services/bundleDescription';
import {IncidentsRouter} from "../incidents/bundleDescription";
import {getSeverityStateEvents} from 'controls/stateRenderer/severityState';
import defaultColumn from 'areas/views/defaultColumn';
import embeddedColumn from 'areas/views/embeddedColumn';
import {AntDateRangePicker} from 'controls/react/ant/antDateRangePicker';
import {SetInformationWindow} from 'controls/setInformationWindow';
import {translator} from "core/localization";
import {sharedLocalization} from "areas/services/localization"
import {CostsRouter} from '../cost/bundleDescription'
import {CostsApi} from "../cost/api";
import {ApplicationState} from "../../framework/applicationState";
import { TimePeriod } from "controls/react/form/timePeriodSelector";

const i = require('core/localization').translator(sharedLocalization, {
	'Assigned time' : {no : 'Tildelt tid'},
	'Assetgroup' : { no: 'Ressursgrupper'}
});

export default function EventSummaryView(config) {
	Object.assign(this, config)

	this.hasEvents = true;
	this.subscriberId = Utils.guid();
};

jQuery.extend(EventSummaryView.prototype, Application.prototype, {
	/**
	 * Main init function
	 */
	init: function(){
		if(!this.doNotLoadPrefs)
			this.doNotLoadPrefs = this.severity != null

		if(!this.accountId ){
			this.accountId = Cookies.CeesoftCurrentAccountId
		}

		this.initComponent()

		this.initialized && this.initialized({
			title: lang.EVENT_SUMMARY
		});
	},

	initComponent: function () {
		this.allowedIdentifiers = {
			'winservices.state': true,
			'winservices.status': true,
			'winservices.auto': true
		};

		if(this.includeSubaccounts == null){
			this.includeSubaccounts = State.includeSubaccounts
		}

		if(!this.embedded) {
			Application.prototype.initComponent.call(this);

			let item = $('.cw_event_summary_notifications');
			item.siblings().removeClass('is_selected');
			item.addClass('is_selected');
		}


		this.showGridView = true;
		this.loadUserPreferences();
		this.removeListeners();
		this.attachListeners();
		this.gridHeight = $('.cw_section_full').height();
		State.mainApp.contextMenu.setActionButton('ACCOUNT');

		this.showSubAccounts = false;
		this.actionNotification = new CustomNotification({
			appendToElement: '#cw_event_summary_list',
		});
		this.unAcknowledgedOnly = true;
		this.checkboxStates = [];
		State.markedCheckboxes = [];
		State.gridPosLoaded = false;
		this.showSubAccounts = true;

		//adapt header layout to custom event summary controls
		$('.window_title').css('width', '25%');
		$('.cw_window_actions').css('width', '75%');

		this.autoCloseDateRangePicker = this.autoCloseDateRangePicker.bind(this)
		ReactDOM.render(<AntDateRangePicker format='DD/MM/YYYY'
			onChange={val=>{this.onDateRangeChange(val)}}
			fromDate={moment(this.eventFromDate)}
			toDate = {moment(this.eventToDate)}/>, $('.date_range_container')[0])
	},
	/**
	 * Called in order to remove existing listeners
	 */
	removeListeners: function () {
		$('.cw_event_summary_time_range').off();
		$('#cw_event_summary_list').off();
	},
	/**
	 * Attaches the listeners
	 */
	attachListeners: function () {
		var eventSummaryHandler = $('#cw_event_summary_list');
		$('.cw_event_summary_time_range').on('click', 'li', $.proxy(this.onSetInterval, this));
		$('.cw_event_summary_event_type').on('click', 'li', $.proxy(this.onHistoricToggle, this));
		$('#cw_event_summary_date_from').on('focusout', $.proxy(this.onDateFocusout, this));
		$('#cw_event_summary_date_to').on('focusout', $.proxy(this.onDateFocusout, this));
		$('#cw_event_summary_date_from').on('focus', $.proxy(this.onDateFocus, this));
		$('#cw_event_summary_date_to').on('focus', $.proxy(this.onDateFocus, this));
		$('#cw_select_periods').on('click', $.proxy(this.onSelectPeriods, this));
		$('#cw_event_summary_list').on('click', '.cw_grid_check', $.proxy(this.onCheck, this));
		$('#cw_event_summary_list').on('click', '.cw_grid_check_all', $.proxy(this.onCheckAll, this));
		if (State.mainApp.session.hasRole('AGGR_MGMTAGENT_READ')) {
			$('#cw_event_summary_list').on('click', '.cw_target_link[data-type="AGENT_STATE"]', $.proxy(this.onTargetNameClick, this));
		}
		if (State.mainApp.session.hasRole('AGGR_SERVICE_READ')) {
			eventSummaryHandler.on('click', '.cw_source_link[data-type="SERVICE"]', $.proxy(this.onSourceNameClick, this));
			eventSummaryHandler.on('click', '.cw_target_link[data-type="SERVICE"]', $.proxy(this.onTargetNameClick, this));
			eventSummaryHandler.on('click', '.cw_source_link[data-type="SERVICE_QUALIFIER"]', $.proxy(this.onSourceNameClick, this));
			eventSummaryHandler.on('click', '.cw_target_link[data-type="SERVICE_QUALIFIER"]', $.proxy(this.onTargetNameClick, this));
			eventSummaryHandler.on('click', '.cw_source_link[data-type="SERVICE_ERROR"]', $.proxy(this.onSourceNameClick, this));
			eventSummaryHandler.on('click', '.cw_target_link[data-type="SERVICE_ERROR"]', $.proxy(this.onTargetNameClick, this));
			eventSummaryHandler.on('click', '.cw_source_link[data-type="SERVICE_ELEMENT"]', $.proxy(this.onSourceNameClick, this));
			eventSummaryHandler.on('click', '.cw_target_link[data-type="SERVICE_ELEMENT"]', $.proxy(this.onTargetNameClick, this));
			eventSummaryHandler.on('click', '.cw_source_link[data-type="SERVICE_LINK"]', $.proxy(this.onSourceNameClick, this));
			eventSummaryHandler.on('click', '.cw_target_link[data-type="SERVICE_LINK"]', $.proxy(this.onTargetNameClick, this));

			eventSummaryHandler.on('click', '.cw_source_link[data-type="SLA"]', $.proxy(this.onSourceNameClick, this));
			eventSummaryHandler.on('click', '.cw_target_link[data-type="SLA"]', $.proxy(this.onTargetNameClick, this));

			eventSummaryHandler.on('click', '.cw_source_link[data-type="SERVICE_WARNING"]', $.proxy(this.onSourceNameClick, this));
			eventSummaryHandler.on('click', '.cw_target_link[data-type="SERVICE_WARNING"]', $.proxy(this.onTargetNameClick, this));
			eventSummaryHandler.on('click', '.cw_source_link[data-type="SERVICE_MODEL"]', $.proxy(this.onSourceNameClick, this));
			eventSummaryHandler.on('click', '.cw_target_link[data-type="SERVICE_MODEL"]', $.proxy(this.onTargetNameClick, this));
			eventSummaryHandler.on('click', '.cw_source_link[data-type="SERVICE_QUALIFIER_WARNING"]', $.proxy(this.onSourceNameClick, this));
			eventSummaryHandler.on('click', '.cw_target_link[data-type="SERVICE_QUALIFIER_WARNING"]', $.proxy(this.onTargetNameClick, this));
		}
		if (State.mainApp.session.hasRole('AGGR_HEALTHASSET_READ')) {
			eventSummaryHandler.on('click', '.cw_target_link[data-type="MONITOR_ERROR"]', $.proxy(this.onTargetNameClick, this));
			eventSummaryHandler.on('click', '.cw_target_link[data-type="ASSET_HEALTH_REASON"]', $.proxy(this.onTargetNameClick, this));
			eventSummaryHandler.on('click', '.cw_source_link[data-type="AGENT_STATE"]', $.proxy(this.onSourceNameClick, this));
		}
		if (State.mainApp.session.hasRole('AGGR_HEALTHMONITOR_READ')) {
			eventSummaryHandler.on('click', '.cw_source_link[data-type="MONITOR_ERROR"]', $.proxy(this.onSourceNameClick, this));
			eventSummaryHandler.on('click', '.cw_source_link[data-type="ASSET_HEALTH_REASON"]', $.proxy(this.onSourceNameClick, this));
		}
		$(window).on('resize', $.proxy(this.setGridSize, this));
		eventSummaryHandler.on('click', '.cw_source_link[data-type="SYSTEM_ERROR"]', $.proxy(this.onSourceNameClick, this));
		eventSummaryHandler.on('click', '.cw_target_link[data-type="SYSTEM_ERROR"]', $.proxy(this.onTargetNameClick, this));
		eventSummaryHandler.on('click', '.cw_source_link[data-type="AGENT_WARNING"]', $.proxy(this.onSourceNameClick, this));
		eventSummaryHandler.on('click', '.cw_target_link[data-type="AGENT_WARNING"]', $.proxy(this.onTargetNameClick, this));
		eventSummaryHandler.on('click', '.cw_source_link[data-type="COST"]', $.proxy(this.onSourceNameClick, this));
		eventSummaryHandler.on('click', '.cw_target_link[data-type="COST"]', $.proxy(this.onTargetNameClick, this));

		if(State.mainApp.session.hasRole('AGENT_READ')) {
			eventSummaryHandler.on('click', '.cw_agent_name', $.proxy(this.onAgentNameClick, this));
		}
	},
	/**
	 * Handler for the period toggle
	 * @param {Object} e The click event object
	 */
	onSetInterval: function (e) {
		var timeSelector, fromTime, toTime;
		timeSelector = $(e.currentTarget).find('input').val();
		if (timeSelector === 'CUSTOM') {
			let periodContainer = $('.cw_event_summary_period');
			if(periodContainer.hasClass('hide'))
				periodContainer.removeClass('hide');
			else
				this.hideDateRangePicker();
			document.body.addEventListener('click', this.autoCloseDateRangePicker);
		} else if (timeSelector === 'ALL') {
			this.showHistoric = false;
			if (!this.disableEventsToolbar) {
				this.handleGridEventsToolbarState();
			}
			this.timeSelector = 'ALL';
			this.fromTime = null;
			this.toTime = null;
			this.refreshGrid(this.showHistoric, this.timeSelector, this.fromTime, this.toTime);
		} else {
			this.hideDateRangePicker();
			this.timeSelector = timeSelector;
			this.fromTime = null;
			this.toTime = null;
			this.refreshGrid(this.showHistoric, this.timeSelector, this.fromTime, this.toTime);
		}
		$('.cw_event_summary_time_range').find('li').removeClass('is_selected');
		$(e.currentTarget).addClass('is_selected');
		this.checkboxStates = Utils.getCheckboxStates(this.grid);
		e.stopPropagation();
	},

	onHistoricToggle(e) {
		let target = $(e.currentTarget);
		target.toggleClass('is_selected');
		$('input[value="ALL"]').closest('li').toggleClass('cw_disabled_option');
		this.showHistoric = this.showHistoric ? false : true;
		if (!this.disableEventsToolbar) {
			this.handleGridEventsToolbarState();
		}
		if (this.timeSelector === 'ALL') {
			this.timeSelector = TimePeriod.Last30Days;
			$('input[value="ALL"]').closest('li').removeClass('is_selected');
			$('input[value="LAST30DAYS"]').closest('li').addClass('is_selected');
		}
		this.refreshGrid(this.showHistoric, this.timeSelector, this.fromTime, this.toTime);
	},

	onDateFocus(e) {
		$('.cw_event_summary_period').removeClass('hide');
		this.dateFocus = true;
	},

	onDateFocusout(e) {
		this.dateFocus = false;
		setTimeout($.proxy(function() {
			if (!this.dateFocus) {
				$('.cw_event_summary_period').addClass('hide');
			}
		}, this), 200);
	},

	reloadByTags: function () {
		this.grid.dataSource.read();
	},

	/**
	 * Loads user preferences
	 */
	loadUserPreferences: async function () {
		this.userPref = [];

		if (this.doNotLoadPrefs != true) {
			let result = await UserPrefs.load(this.getPrefName())
			if (result.success) {
				this.userPref = result.data;

			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}

		this.initKendoComponents();
		this.removeMask();
	},
	/**
	 * Initialize kendo components
	 */
	initKendoComponents: function () {
		var eventSummarySort, eventSummaryFilter,
			defaultFsView = UserPrefs.get('defaultFsView'),
			filterMessages = lang.grid.filter,
			pref;
		var eventStatus = JSON.parse(UserPrefs.get('eventsStatus')) || {
			startFrom: 30,
			playing: !this.doNotLoadPrefs
		};

		if(this.disableEventsToolbar !== true) {
			this.eventsToolbar = new EventsToolbar({
				renderTo: $('#cw_event_summary_events_toolbar'),
				callBackFn: $.proxy(this.onEventsTimeout, this),
				subscriberId: this.subscriberId,
				startFrom: eventStatus.startFrom,
				playing: eventStatus.playing
			});
		}
		this.defaultColumns = this.embedded ? embeddedColumn(this.showSubAccounts): defaultColumn(this.showSubAccounts);

		if (Utils.isGuid(defaultFsView)) {
			pref = JSON.parse(UserPrefs.get(defaultFsView));
			pref = pref || {
				sort: [],
				filter: []
			};
			eventSummarySort = pref.sort;
			eventSummaryFilter = pref.filter;
			this.eventSummaryColumns = pref.columns;
		} else {
			if (UserPrefs.get('eventSummarySort')) {
				eventSummarySort = JSON.parse(UserPrefs.get('eventSummarySort'));
			} else {
				eventSummarySort = [{
					field: 'time',
					dir: 'desc',
					compare: null
				}];
			}
			if (UserPrefs.get('eventSummaryFilter')) {
				eventSummaryFilter = JSON.parse(UserPrefs.get('eventSummaryFilter'));
			} else {
				eventSummaryFilter = [];
			}
		}
		if (this.doNotLoadPrefs) {
			eventSummarySort = [];
			eventSummaryFilter = [];
		}

		var columnChanges = [{
			oldName: 'date',
			newName: 'time'
		}];
		var currentFilters = eventSummaryFilter?.filters;
		if (currentFilters) {
			Utils.replaceObsoleteFilters(currentFilters, columnChanges);
		}
		Utils.replaceObsoleteSort(eventSummarySort, columnChanges);

		this.gridMessages = {
			isTrue: '<span class="glyphicons ok"></span>',
			isFalse: '<span class="glyphicons remove"></span>',
			clear: lang.CLEAR,
			info: lang.grid.filter.SHOW_ITEMS,
			filter: lang.FILTER
		};

		if(this.baseUrl == null) {
			this.baseUrl = Settings.serverPath + 'accounts/' + this.accountId + '/summary/eventSummary';
		}

		this.urlParameters = '?includeSubaccounts=' + this.includeSubaccounts.toString();
		this.timeTypeUrlParameters = '';

		let timeContainer = $('.cw_event_summary_time_range');
		timeContainer.find('li').removeClass('is_selected');
		timeContainer.find('input').prop('checked', false);

		this.setTypeTimeUserPrefs();

		if (!this.disableEventsToolbar) {
			this.handleGridEventsToolbarState(true);
		}

		let url = this.baseUrl + this.urlParameters + this.timeTypeUrlParameters;

		var scope = this;
		this.dataSource = new kendo.ceeview.DataSource({
			transport: {
				read: {
					url: url,
					contentType: "application/json; charset=utf-8",
					type: 'POST',
					dataType: 'json',
					cache: false
				},
				parameterMap: (data, type) => {
					if (type === 'read' && data.filter?.filters?.length) {
						data.filter.filters = Utils.changeDateFilterToString(data.filter.filters);
					}

					if (this.severity) {
						const severityFilter = {
							logic: 'or',
							filters: this.severity.map(v => {
								return {
									field: "severity",
									operator: 'eq',
									value: v
								}
							})
						}
						if(!data.filter?.filters){
							data.filter = {
								logic: 'and',
								filters: [severityFilter]
							}
						}
						else {
							data.filter.filters.push(severityFilter)
						}

						if (this.onlyWarnings) {
							data.filter.filters.push({
								logic: 'or',
								filters: [{
									field: "severity",
									operator: 'eq',
									value: 'MAINTENANCE'
								},{
									field: "severity",
									operator: 'eq',
									value: 'WARNING'
								}]
							})
						}
					}

					if(this.showUntagged) {
						data.showUntagged = this.showUntagged
					}

					if(this.tags?.length > 0) {
						data.tags = this.tags
					}

					if(this.eventTypes?.length > 0){
						data.eventTypes = this.eventTypes
					}

					this.gridPayload = data;

					if(this.additionalPayload){
						data = {...data, ...this.additionalPayload};
					}

					if(this.additionalQuery){
						for(const [key, value] of Object.entries(this.additionalQuery)){
							data[key] = [value];
						}
					}

					if(this.additionalFilters){
						if(!data.filter?.filters){
							data.filter = {
								logic: 'and',
								filters: this.additionalFilters
							};
						}
						else {
							data.filter.filters  = data.filter.filters.concat(this.additionalFilters);
						}
					}
					return kendo.stringify(data);
				}
			},
			pageSize: 50,
			schema: {
				model: {
					fields: {
						id: {
							type: 'string',
							editable: false
						},
						sourceId: {
							type: 'string',
							editable: false
						},
						severity: {
							type: 'string',
							editable: false
						},
						severityIndex: {
							type: 'number',
							editable: false
						},
						sourceName: {
							type: 'string',
							editable: false
						},
						accountName: {
							type: 'string',
							editable: false
						},
						type: {
							type: 'string',
							editable: false
						},
						time: {
							type: 'date',
							editable: false
						},
						timeDeleted: {
							type: 'date',
							editable: false
						},
						message: {
							type: 'string',
							editable: false
						},
						targetName: {
							type: 'string',
							editable: false
						},
						agentName: {
							type: 'string',
							editable: false
						},
						action: {
							type: 'boolean',
							editable: true
						},
						actionCount: {
							type: 'number',
							editable: false
						},
						agentId: {
							type: 'string',
							editable: false
						},
						externalId: {
							type: 'string',
							editable: false
						},
						externalOwner: {
							type: 'string',
							editable: false
						},
						identifierInstance: {
							type: 'string',
							editable: false
						},
						identifierName: {
							type: 'string',
							editable: false
						},
						monitorTypeText: {
							type: 'string',
							editable: false
						},
						monitorClassText: {
							type: 'string',
							editable: false
						},
						subsystem: {
							type: 'string',
							editable: false
						},
						targetId: {
							type: 'string',
							editable: false
						},
						information: {
							type: 'string',
							editable: false
						},
						acknowledged: {
							type: 'boolean',
							editable: false
						},
						acknowledgedBy: {
							type: 'string',
							editable: false
						},
						// acknowledgedAt: {
						//     type: 'date',
						//     editable: false
						// },
						inMaintenance: {
							type: 'boolean',
							editable: false
						},
						suppressionCount: {
							type: 'number',
							editable: false
						},
						assignedTo: {
							type: 'string',
							editable: false
						},
						details: {
							type: 'string',
							editable: false
						},
						timeCreated: {
							type: 'date',
							editable: false
						},
						historic: {
							type: 'boolean'
						},
						assignedTime: {
							type: 'date',
							editable: false
						},
						groups: {
							type: 'string',
							editable: false
						}
					},
				},
				parse: $.proxy(function(result) {
					this.filterOptions = result.filterOptions;
					return result;
				}, this),
				data: $.proxy(function (data) {
					if (data.items) {
						var items = data.items;
						for (var i = 0, length = items.length; i < length; i++) {
							var value = new Date(items[i].time);
							items[i].time = value;
							if (items[i].timeDeleted) {
								items[i].timeDeleted = new Date(items[i].timeDeleted);
							}
							items[i].timeCreated = new Date(items[i].timeCreated);

							if (items[i].inMaintenance) {
								items[i].severityIndex = 7;
							}
							var targetName = items[i].targetName;
							if (targetName) {
								items[i].targetName = targetName.replace(/</g, '&lt;').replace(/>/g, '&gt;');
							}
							if (items[i].assignedTime) {
								items[i].assignedTime = new Date(items[i].assignedTime);
							}
							if (items[i].assignedToType === 'ASSIGN_TEAM') {
								items[i].assignedToGlyph = 'parents';
								if (items[i].assignedTo) {
									items[i].assignedToMouseover = lang.TEAM + ': ' + items[i].assignedTo;
								} else {
									items[i].assignedToMouseover = lang.TEAM;
								}
							} else if (items[i].assignedToType === 'ASSIGN_USER') {
								items[i].assignedToGlyph = 'user';
								if (items[i].assignedTo) {
									items[i].assignedToMouseover = lang.USER + ': ' + items[i].assignedTo;
								} else {
									items[i].assignedToMouseover = lang.USER;
								}
							} else {
								items[i].assignedToGlyph = '';
								items[i].assignedToMouseover = '';
							}
							if (items[i].acknowledgedBy) {
								items[i].acknowledgedMouseover = lang.ACKNOWLEDGED_BY + ' ' + items[i].acknowledgedBy;
							} else {
								items[i].acknowledgedMouseover = '';
							}
						}
						return items;
					} else {
						Utils.showInfo(lang.ALERT, data.message, data.details);
					}
				}, this),
				total: function (response) {
					this.visibleItems = response.visible;
					this.totalItems = response.total;

					return response.visible;
				}
			},
			change: $.proxy(function (e) {
				if (JSON.stringify(this.currentGridFilter) !== JSON.stringify(this.dataSource.filter())) {
					this.filteringChanged = true;
				} else {
					this.filteringChanged = false;
				}
				let restoreCheckboxes = true;
				if (this.filteringChanged && this.selectedIds) {
					this.selectedIds = null;
					State.markedCheckboxes = [];
					$('.cw_grid_check_all').prop('checked', false);
					$('.cw_grid_check').prop('checked', false);
					this.onCheck();
					restoreCheckboxes = false;
				}
				this.currentGridFilter = this.dataSource.filter();

				if (restoreCheckboxes) {
					var restoreCheckboxStates = function () {
						if (!this.grid) {
							return;
						}
						Utils.setCheckboxStates(this.grid, this.checkboxStates);
					};
					setTimeout($.proxy(restoreCheckboxStates, this), 200);
				}
			}, this),
			sort: eventSummarySort,
			filter: eventSummaryFilter,
			serverSorting: true,
			serverPaging: true,
			serverFiltering: true,
			error: ErrorHandler.kendoServerError
		});
		const userDefinedColumns = JSON.parse(UserPrefs.get('eventSummaryColumns'));
		var eventSummaryColumns = this.eventSummaryColumns || userDefinedColumns || this.defaultColumns;
		eventSummaryColumns = Utils.completeColumns(eventSummaryColumns, this.defaultColumns);
		Utils.replaceObsoleteColumns(eventSummaryColumns, columnChanges);

		if(this.hideIdColumn !== undefined) {
			eventSummaryColumns.id.hidden = this.hideIdColumn;
		}

		let gridContainer = $('#cw_event_summary_list');
		eventSummaryColumns = completeColumnsWidth(eventSummaryColumns, gridContainer, this.defaultColumns);

		if(this.showColumns && !userDefinedColumns){
			for (let column in eventSummaryColumns) {
				eventSummaryColumns[column].hidden = !this.showColumns.includes(column);
			}
		}

		var scope = this;
		this.grid = gridContainer.kendoCustomGrid({
			autoBind: this.disableAutoBind != true,
			dataSource: this.dataSource,
			resizable: true,
			selectable: 'row',
			editable: true,
			reorderable: true,
			cacheScrollCheckboxes: true,
			scrollable: {
				virtual: true
			},
			filterable: {
				extra: false,
				operators: {
					number: {
						eq: filterMessages.EQ,
						neq: filterMessages.NEQ
					},
					string: {
						startswith: filterMessages.STARTS_WITH,
						contains: filterMessages.CONTAINS,
						eq: filterMessages.EQ,
						neq: filterMessages.NEQ
					},
					date: {
						eq: filterMessages.EQ,
						gte: filterMessages.IAE,
						gt: filterMessages.IA,
						lte: filterMessages.IBE,
						lt: filterMessages.IB
					}
				},
				messages: this.gridMessages
			},
			sortable: {
				mode: 'multiple',
				allowUnsort: true
			},
			columns: Utils.rearrangeColumns([{
				field: 'id',
				title: lang.SELECTOR,
				menu: false,
				filterable: false,
				sortable: false,
				template: '<input type="checkbox" class="cw_grid_check" data-id="${id}" data-targetid="${targetId}" />',
				headerTemplate: '<input type="checkbox" class="cw_grid_check_all" />',
				attributes: {
					'class': 'text_center'
				},
				headerAttributes: {
					'class': 'text_center'
				},
				hidden: eventSummaryColumns.id.hidden,
				width: eventSummaryColumns.id.width
			}, {
				field: 'severity',
				title: lang.assethealth.SEVERITY,
				sortable: {
					compare: $.proxy(function (a, b) {
						return Utils.customCompare(a, b, 'severityIndex', 6, this.dataSource.sortNow);
					}, this)
				},
				filterable: {
					operators: {
						string: {
							eq: filterMessages.ISIN,
							neq: filterMessages.ISNOTIN
						}
					},
					ui: function (element) {
						var multiselect = new MultiSelectGridFilter({
							element: element,
							field: 'severity',
							grid: scope.grid,
							dataSource: new kendo.data.DataSource({ data: [{
								text: lang.CRITICAL,
								icon: '<span class="cw_status_indicator cw_es_indicator cw_status_widget_color is_critical"></span>',
								value: 'CRITICAL'
							}, {
								text: lang.MAJOR,
								icon: '<span class="cw_status_indicator cw_es_indicator cw_status_widget_color is_major"></span>',
								value: 'MAJOR'
							}, {
								text: lang.MINOR,
								color: 'is_minor',
								icon: '<span class="cw_status_indicator cw_es_indicator cw_status_widget_color is_minor"></span>',
								value: 'MINOR'
							}, {
								text: lang.OK,
								icon: '<span class="cw_status_indicator cw_es_indicator cw_status_widget_color is_ok"></span>',
								value: 'NONE'
							}, {
								text: lang.MAINTENANCE,
								icon: '<span class="cw_status_indicator cw_es_indicator cw_status_widget_color is_idle"><span class="glyphicons wrench"></span></span>',
								value: 'MAINTENANCE'
							},{
								text: lang.WARNING,
								icon: '<span class="cw_status_indicator cw_es_indicator cw_status_widget_color is_idle"><span class="glyphicons exclamation-mark"></span></span>',
								value: 'WARNING'
							}], serverSorting: true})
						});
					}
				},

				attributes: {
					'class': 'text_center'
				},
				//template: item => getEventIndicator(item),
				template: item => getSeverityStateEvents(item.severity, item.agentDown, item.inMaintenance, item.monitorError, item.historic, item.monitorMaintenance, item.maintenanceDetails),
				width: eventSummaryColumns.severity.width,
				hidden: eventSummaryColumns.severity.hidden
			}, {
				field: 'historic',
				title: lang.HISTORIC,
				template: '<span class="glyphicons #= historic ? \'ok cw_true\' : null #" title="#= historic ? lang.messages.HISTORIC_EVENT : null #"></span>',
				hidden: eventSummaryColumns.historic.hidden,
				width: eventSummaryColumns.historic.width,
				attributes: {
					'class': 'text_center'
				},
			}, {
				field: 'assignedToType',
				title: lang.ASSIGNED_TO_TYPE,
				sortable: true,
				filterable: {
					operators: {
						string: {
							eq: filterMessages.EQ,
							neq: filterMessages.NEQ
						}
					},
					ui: function (element) {
						var multiselect = new MultiSelectGridFilter({
							element: element,
							field: 'assignedToType',
							grid: scope.grid,
							dataSource: [{
								text: lang.NONE,
								icon: '<span class="glyphicons"></span>',
								value: 'ASSIGN_NONE'
							}, {
								text: lang.USER,
								icon: '<span class="glyphicons user"></span>',
								value: 'ASSIGN_USER'
							}, {
								text: lang.TEAM,
								icon: '<span class="glyphicons parents"></span>',
								value: 'ASSIGN_TEAM'
							}]
						});
					}
				},
				template: '<span class="glyphicons #=assignedToGlyph#"></span>',
				attributes: {
					'class': 'tooltip ellipsis to_expand text_center assigned_type'
				},
				hidden: eventSummaryColumns.assignedToType.hidden,
				width: eventSummaryColumns.assignedToType.width
			}, {
				field: 'acknowledged',
				title: lang.service.ACKNOWLEDGED,
				sortable: true,
				menu: false,
				filterable: true,
				template: '#if(acknowledged){#<span class="glyphicons ok"></span>#}#',
				hidden: eventSummaryColumns.acknowledged.hidden,
				width: eventSummaryColumns.acknowledged.width,
				attributes: {
					'title': '#=acknowledgedMouseover || "" #',
					'class': 'text_center'
				}
			}, {
				field: 'targetName',
				title: lang.TARGET,
				sortable: true,
				filterable: true,
				hidden: eventSummaryColumns.targetName.hidden,
				width: eventSummaryColumns.targetName.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				},
				template: '#if (targetName) {# <span class="cw_grid_link cw_target_link ellipsis to_expand" data-targetid="#= targetId #" data-type="#=type#"> #= targetName # </span> #} else {# <span class="cw_label"> #= lang.NA # </span> #}#'
			}, {
				field: 'agentName',
				title: lang.assethealth.AGENT_NAME,
				template: item => {

					const classes = classnames({
						'cw_link': State.mainApp.session.hasRole('AGENT_READ'),
						'cw_agent_name ellipsis to_expand': true
					});

					return `<span data-agentid="${item.agentId}" class="${classes}">${item.agentName ? item.agentName : ''}</span>`
				},
				sortable: true,
				filterable: {
					ui: function (element) {
						new MultiSelectGridFilter({
							element: element,
							field: 'agentName',
							grid: scope.grid,
							itemTemplate: '#=data.text#',
							tagTemplate: '#=data.text#',
							dataSource: scope.filterOptions['agentName']
						});
					},
					messages: this.gridMessages,
					extra: false,
					operators: {
						string: {
							eq: filterMessages.EQ,
							neq: filterMessages.NEQ
						}
					}
				},
				width: eventSummaryColumns.agentName.width,
				hidden: eventSummaryColumns.agentName.hidden,
				attributes: {
					'class': 'expand ellipsis'
				}
			}, {
				field: 'accountName',
				title: lang.ACCOUNT,
				sortable: true,
				template: '<span>#= accountName || "N/A" #</span>',
				filterable: {
					ui: function (element) {
						var multiselect = new MultiSelectGridFilter({
							element: element,
							field: 'accountName',
							grid: scope.grid,
							itemTemplate: '#=data.text#',
							tagTemplate: '#=data.text#',
							dataSource: scope.filterOptions['accountName']
						});
					},
					messages: this.gridMessages,
					extra: false,
					operators: {
						string: {
							eq: filterMessages.EQ,
							neq: filterMessages.NEQ
						}
					}
				},
				hidden: !this.includeSubaccounts || eventSummaryColumns.accountName.hidden,
				width: eventSummaryColumns.accountName.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'type',
				title: lang.TYPE,
				sortable: true,
				filterable: {
					operators: {
						string: {
							eq: filterMessages.EQ,
							neq: filterMessages.NEQ
						}
					},
					ui: function (element) {
						var multiselect = new MultiSelectGridFilter({
							element: element,
							field: 'type',
							grid: scope.grid,
							itemTemplate: '#=data.text#',
							tagTemplate: '#=data.text#',
							dataSource: scope.filterOptions['type']
						});
					}
				},
				template: '#= typeText#',
				hidden: eventSummaryColumns.type.hidden,
				width: eventSummaryColumns.type.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'time',
				title: lang.widget.TIME,
				template: '#= Renderer.browserDateRenderer(time, Constants.DATETIME, "")#',
				sortable: true,
				filterable: {
					ui: function (element) {
						element.kendoDateTimePicker({
							format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
							timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat)
						});
					}
				},
				hidden: eventSummaryColumns.time.hidden,
				width: eventSummaryColumns.time.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'timeDeleted',
				title: lang.TIME_DELETED,
				template: '#= Renderer.browserDateRenderer(timeDeleted, Constants.DATETIME, "")#',
				sortable: true,
				filterable: {
					ui: function (element) {
						element.kendoDateTimePicker({
							format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
							timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat)
						});
					}
				},
				hidden: eventSummaryColumns.timeDeleted.hidden,
				width: eventSummaryColumns.timeDeleted.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'message',
				title: lang.MESSAGE,
				sortable: true,
				filterable: true,
				hidden: eventSummaryColumns.message.hidden,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				},
				width: eventSummaryColumns.message.width
			}, {
				field: 'sourceName',
				title: lang.NAME,
				sortable: true,
				filterable: true,
				template: '<span class="cw_grid_link cw_source_link" data-sourceid="#= sourceId #" data-type="#=type#"> #= sourceName # </span>',
				hidden: eventSummaryColumns.sourceName.hidden,
				width: eventSummaryColumns.sourceName.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'tags',
				title: lang.TAGS,
				sortable: true,
				filterable: {
					ui: function (element) {
						var multiselect = new MultiSelectGridFilter({
							element: element,
							field: 'tags',
							grid: scope.grid,
							itemTemplate: '#=data.text#',
							tagTemplate: '#=data.text#',
							dataSource: scope.filterOptions['tags']
						});
					},
					messages: this.gridMessages,
					extra: false,
					operators: {
						string: {
							eq: filterMessages.EQ,
							neq: filterMessages.NEQ
						}
					}
				},
				template: item => item.tags.join(','),
				hidden: eventSummaryColumns.tags.hidden,
				width: eventSummaryColumns.tags.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'suppressionCount',
				title: lang.SUPPRESSION,
				attributes: {
					'class': 'text_center tooltip ellipsis to_expand'
				},
				sortable: true,
				filterable: true,
				hidden: eventSummaryColumns.suppressionCount.hidden,
				width: eventSummaryColumns.suppressionCount.width
			}, {
				field: 'actionCount',
				title: lang.ACTION_COUNT,
				sortable: true,
				menu: false,
				filterable: true,
				hidden: eventSummaryColumns.actionCount.hidden,
				width: eventSummaryColumns.actionCount.width,
				attributes: {
					'class': 'text_center action_count'
				}
			}, {
				field: 'information',
				title: lang.INFORMATION,
				sortable: true,
				menu: false,
				filterable: true,
				template: '#=information ? information : "" #',
				hidden: eventSummaryColumns.information.hidden,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				},
				width: eventSummaryColumns.information.width
			}, {
				field: 'acknowledgedBy',
				title: lang.incidents.ACKNOWLEDGED_BY,
				sortable: true,
				filterable: {
					operators: {
						string: {
							eq: filterMessages.EQ,
							neq: filterMessages.NEQ
						}
					},
					ui: function (element) {
						var multiselect = new MultiSelectGridFilter({
							element: element,
							field: 'acknowledgedBy',
							grid: scope.grid,
							itemTemplate: '#=data.text#',
							tagTemplate: '#=data.text#',
							dataSource: scope.filterOptions['acknowledgedBy']
						});
					},
					messages: this.gridMessages
				},
				template: '#=acknowledgedBy ? acknowledgedBy : "" #',
				hidden: eventSummaryColumns.acknowledgedBy.hidden,
				width: eventSummaryColumns.acknowledgedBy.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'acknowledgedAt',
				title: lang.incidents.TIME_OF_FLAG,
				sortable: true,
				menu: false,
				filterable: false,
				template: '#=Renderer.browserDateRenderer(acknowledgedAt, Constants.DATETIME)#',
				hidden: eventSummaryColumns.acknowledgedAt.hidden,
				width: eventSummaryColumns.acknowledgedAt.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'inMaintenance',
				title: lang.service.IN_MAINTENANCE,
				sortable: true,
				menu: false,
				filterable: true,
				template: '#if(inMaintenance){#<span class="glyphicons ok"></span>#}#',
				hidden: eventSummaryColumns.inMaintenance.hidden,
				width: eventSummaryColumns.inMaintenance.width
			}, {
				field: 'agentId',
				title: lang.serviceBoard.AGENT_ID,
				sortable: true,
				filterable: true,
				hidden: eventSummaryColumns.agentId.hidden,
				width: eventSummaryColumns.agentId.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'externalId',
				title: lang.account.ASSET_EXTERNAL_ID,
				sortable: true,
				filterable: true,
				hidden: eventSummaryColumns.externalId.hidden,
				width: eventSummaryColumns.externalId.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'externalOwner',
				title: lang.account.ASSET_EXTERNAL_OWNER,
				sortable: true,
				filterable: true,
				hidden: eventSummaryColumns.externalOwner.hidden,
				width: eventSummaryColumns.externalOwner.width,
				attributes: {
					'class': 'ellipsis to_expand'
				}
			}, {
				field: 'identifierInstance',
				title: lang.assethealth.IDENTIFIER_INSTANCE,
				sortable: true,
				filterable: true,
				hidden: eventSummaryColumns.identifierInstance.hidden,
				width: eventSummaryColumns.identifierInstance.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'identifierName',
				title: lang.assethealth.IDENTIFIER_NAME,
				sortable: true,
				filterable: true,
				hidden: eventSummaryColumns.identifierName.hidden,
				width: eventSummaryColumns.identifierName.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'monitorTypeText',
				title: lang.assethealth.MONITOR_TYPE,
				sortable: true,
				filterable: {
					operators: {
						string: {
							eq: filterMessages.EQ,
							neq: filterMessages.NEQ
						}
					},
					ui: function (element) {
						var multiselect = new MultiSelectGridFilter({
							element: element,
							field: 'monitorTypeText',
							grid: scope.grid,
							itemTemplate: '#=data.text#',
							tagTemplate: '#=data.text#',
							dataSource: scope.filterOptions['monitorTypeText']
						});
					},
					messages: this.gridMessages,
				},
				hidden: eventSummaryColumns.monitorTypeText.hidden,
				width: eventSummaryColumns.monitorTypeText.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'monitorClassText',
				title: lang.assethealth.MONITOR_CLASS,
				sortable: true,
				filterable: {
					operators: {
						string: {
							eq: filterMessages.EQ,
							neq: filterMessages.NEQ
						}
					},
					ui: function (element) {
						var multiselect = new MultiSelectGridFilter({
							element: element,
							field: 'monitorClassText',
							grid: scope.grid,
							itemTemplate: '#=data.text#',
							tagTemplate: '#=data.text#',
							dataSource: scope.filterOptions['monitorClassText']
						});
					},
					messages: this.gridMessages,
				},
				hidden: eventSummaryColumns.monitorClassText.hidden,
				width: eventSummaryColumns.monitorClassText.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'subsystem',
				title: lang.assethealth.SUBSYSTEM,
				sortable: true,
				filterable: {
					operators: {
						string: {
							eq: filterMessages.EQ,
							neq: filterMessages.NEQ
						}
					},
					ui: function (element) {
						var multiselect = new MultiSelectGridFilter({
							element: element,
							field: 'subsystem',
							grid: scope.grid,
							itemTemplate: '#=data.text#',
							tagTemplate: '#=data.text#',
							dataSource: scope.filterOptions['subsystem']
						});
					},
					messages: this.gridMessages,
				},
				hidden: eventSummaryColumns.subsystem.hidden,
				width: eventSummaryColumns.subsystem.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'sourceId',
				title: lang.SOURCE_ID,
				sortable: true,
				filterable: true,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				},
				hidden: eventSummaryColumns.sourceId.hidden,
				width: eventSummaryColumns.sourceId.width
			}, {
				field: 'targetId',
				title: lang.TARGET_ID,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				},
				sortable: true,
				filterable: true,
				hidden: eventSummaryColumns.targetId.hidden,
				width: eventSummaryColumns.targetId.width
			}, {
				field: 'assignedTo',
				title: lang.ASSIGNED_TO,
				sortable: true,
				filterable: true,
				hidden: eventSummaryColumns.assignedTo.hidden,
				width: eventSummaryColumns.assignedTo.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'details',
				title: lang.DETAILS,
				sortable: true,
				menu: false,
				filterable: true,
				template: '#=details ? details : "" #',
				hidden: eventSummaryColumns.details.hidden,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				},
				width: eventSummaryColumns.details.width
			}, {
				field: 'timeCreated',
				title: lang.CREATED,
				template: data => Renderer.browserDateRenderer(data.timeCreated, Constants.DATETIME, ""),
				sortable: true,
				filterable: {
					ui: function (element) {
						element.kendoDateTimePicker({
							format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
							timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat)
						});
					}
				},
				hidden: eventSummaryColumns.timeCreated.hidden,
				width: eventSummaryColumns.timeCreated.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			},
			{
				field: 'assignedTime',
				title: i('Assigned time'),
				template: '#= Renderer.browserDateRenderer(assignedTime, Constants.DATETIME, "")#',
				template: data => Renderer.browserDateRenderer(data.assignedTime, Constants.DATETIME, ""),
				sortable: true,
				filterable: {
					ui: function (element) {
						element.kendoDateTimePicker({
							format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
							timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat)
						});
					}
				},
				hidden: eventSummaryColumns.assignedTime.hidden,
				width: eventSummaryColumns.assignedTime.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			},
			{
				field: 'groups',
				title: i('Assetgroup'),
				sortable: true,
				filterable: {
					operators: {
						string: {
							eq: filterMessages.EQ,
							neq: filterMessages.NEQ
						}
					},
					ui: function (element) {
						var multiselect = new MultiSelectGridFilter({
							element: element,
							field: 'groups',
							grid: scope.grid,
							itemTemplate: '#=data.text#',
							tagTemplate: '#=data.text#',
							dataSource: scope.filterOptions['groups']
						});
					},
					messages: this.gridMessages,
				},
				hidden: eventSummaryColumns.groups.hidden,
				width: eventSummaryColumns.groups.width,
				template: '#=groups ? groups : "" #',
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}], eventSummaryColumns),
			columnMenu: true,
			change: toggleEllipsis,
			dataBound: $.proxy(this.onGridDataBound, this)
		}).data('kendoCustomGrid')

		$('.k-virtual-scrollable-wrap').scroll(function () {
			State.dynamicGridPos = $('#cw_event_summary_list').data('kendoCustomGrid').wrapper.find(".k-scrollbar").scrollTop();
		});

		// Add Kendo tooltip to the header of the columns
		Utils.gridColumnHeaderTooltip(this.grid);
		this.grid.thead.find("[data-field='id']>.k-header-column-menu").remove();

		class FakeGridMenu {
			disableItem() {}
			enableItem() {}
		};
		this.gridMenu = (this.embedded && !this.showMenu) ? new FakeGridMenu(): new GridMenu({
			renderTo: 'cw_event_summary_menu',
			items: [{
				id: 'cw_event_summary_incidents_create',
				icon: 'construction-cone',
				text: lang.incidents.CREATE_INCIDENT,
				fn: this.onCreateIncident,
				scope: this,
				role: 'INCIDENT_CREATE',
				disabled: true
			}, {
				id: 'cw_event_summary_reason_acknowledge',
				icon: 'check',
				text: lang.ACKNOWLEDGE,
				fn: this.onAckUnack,
				scope: this,
				disabled: true,
				role: 'EVENTS_UPDATE'
			}, {
				id: 'cw_event_summary_reason_unacknowledge',
				icon: 'unchecked',
				text: lang.UNACKNOWLEDGE,
				fn: this.onAckUnack,
				role: 'EVENTS_UPDATE',
				scope: this,
				disabled: true
			}, {
				id: 'cw_event_summary_reset_hi',
				icon: 'restart',
				text: lang.assethealth.RESET_HI,
				fn: this.onResetHIButton,
				scope: this,
				cssClass: '',
				disabled: true,
				role: 'MONITOR_UPDATE'
			}, {
				id: 'cw_event_summary_create_filter',
				icon: 'filter',
				text: lang.assethealth.CREATE_FILTER,
				fn: this.onCreateFilter,
				scope: this,
				cssClass: '',
				disabled: true,
				role: State.mainApp.session.hasV2Permissions ? 'HEALTHFILTER_CREATE' : undefined
			}, {
				id: 'cw_event_summary_start_service',
				icon: 'play',
				text: lang.service.START_SERVICE,
				fn: this.onStartService,
				scope: this,
				disabled: true,
				role: 'MONITOR_UPDATE'
			}, {
				id: 'cw_event_summary_stop_service',
				icon: 'stop',
				text: lang.service.STOP_SERVICE,
				fn: this.onStopService,
				scope: this,
				disabled: true,
				role: 'MONITOR_UPDATE'
			}, {
				id: 'cw_event_summary_add_note',
				icon: 'plus-sign',
				text: lang.service.ADD_NOTE,
				fn: this.onAddServiceNote,
				role: 'SERVICE_UPDATE',
				scope: this,
				disabled: true
			}, {
				id: 'cw_event_summary_unassign',
				icon: 'undo',
				text: lang.UNASSIGN,
				fn: this.onUnassign,
				role: 'EVENTS_UPDATE',
				scope: this,
				disabled: true
			}, {
				id: 'cw_event_summary_assign',
				icon: 'object-align-horizontal',
				text: lang.ASSIGN,
				fn: this.onAssign,
				role: 'EVENTS_UPDATE',
				scope: this,
				disabled: true
			}, {
				id: 'cw_event_summary_export_csv',
				icon: 'file-export',
				text: lang.EXPORT_CSV,
				fn: this.onExportCsv,
				scope: this,
				disabled: false
			}, {
				id: 'cw_delete_reason',
				icon: 'remove-circle',
				text: lang.DELETE,
				fn: () => {
					this.onRemove();
				},
				role: 'EVENTS_DELETE',
				scope: this,
				disabled: true
			}, {
				id: 'cw_set_information',
				icon: 'info-sign',
				text: lang.SET_INFORMATION,
				fn: () => {
					this.onSetInformation();
				},
				role: 'EVENTS_UPDATE',
				scope: this,
				disabled: true
			}]
		});
		var searchValue = UserPrefs.get('defaultEventSummaryFsView') ? '' : (UserPrefs.get('eventSummarySearchPhrase') || '');
		this.gridFilterPanel = new PreferencesPanel({
			renderTo: 'cw_event_summary_filters',
			grid: this.grid,
			counter: true,
			modulePrefName: this.getPrefName(),
			defaultPrefViewKey: 'defaultEventSummaryFsView',
			prefListKey: 'eventSummaryFSViews',
			userPref: this.userPref,
			onRemove: $.proxy(this.saveUserPreferences, this),
			searchFields: ['externalOwner', 'message', 'accountName', 'identifierInstance', 'identifierName', 'monitorTypeText', 'monitorClassText', 'sourceName', 'subsystem', 'type', 'targetName', 'tags', 'assignedTo', 'acknowledgedBy', 'assignedTime', 'groups'],
			searchValue: searchValue,
			hideViews: this.doNotLoadPrefs,
			defaultColumns: this.defaultColumns
		});
	},

	handleGridEventsToolbarState(skipTaskManagerChange) {
		let toolbar = $('#cw_event_summary_events_toolbar');
		if (this.showHistoric) {
			this.eventsToolbar._stopTaskManager();
			toolbar.addClass('cw_disabled_option');
			toolbar.find('.cw_events_text').addClass('cw_disabled_option');
			toolbar.find('.cw_events_play .glyphicons').addClass('cw_disabled_option');
		} else {
			if (this.eventsToolbar.playing && !skipTaskManagerChange) {
				this.eventsToolbar._startTaskManager();
			}
			toolbar.removeClass('cw_disabled_option');
			toolbar.find('.cw_events_text').removeClass('cw_disabled_option');
			toolbar.find('.cw_events_play .glyphicons').removeClass('cw_disabled_option');
		}
	},

	/**
	 * Handler function for the selection of one system errors log grid row
	 */
	onGridDataBound: function (e) {
		if (!State.mainApp.session.hasRole('AGGR_MGMTAGENT_READ')) {
			$('.cw_target_link[data-type="AGENT_STATE"]').attr('style', 'cursor: default !important').css('color', 'black');
		}
		if (!State.mainApp.session.hasRole('AGGR_SERVICE_READ')) {
			$('.cw_source_link[data-type="SERVICE"]').attr('style', 'cursor: default !important').css('color', 'black');
			$('.cw_target_link[data-type="SERVICE"]').attr('style', 'cursor: default !important').css('color', 'black');
			$('.cw_source_link[data-type="SERVICE_QUALIFIER"]').attr('style', 'cursor: default !important').css('color', 'black');
			$('.cw_target_link[data-type="SERVICE_QUALIFIER"]').attr('style', 'cursor: default !important').css('color', 'black');
			$('.cw_source_link[data-type="SERVICE_ERROR"]').attr('style', 'cursor: default !important').css('color', 'black');
			$('.cw_target_link[data-type="SERVICE_ERROR"]').attr('style', 'cursor: default !important').css('color', 'black');
			$('.cw_source_link[data-type="SERVICE_ELEMENT"]').attr('style', 'cursor: default !important').css('color', 'black');
			$('.cw_target_link[data-type="SERVICE_ELEMENT"]').attr('style', 'cursor: default !important').css('color', 'black');
		}
		if (!State.mainApp.session.hasRole('AGGR_HEALTHASSET_READ')) {
			$('.cw_target_link[data-type="MONITOR_ERROR"]').attr('style', 'cursor: default !important').css('color', 'black');
			$('.cw_target_link[data-type="ASSET_HEALTH_REASON"]').attr('style', 'cursor: default !important').css('color', 'black');
			$('.cw_source_link[data-type="AGENT_STATE"]').attr('style', 'cursor: default !important').css('color', 'black');
		}
		if (!State.mainApp.session.hasRole('AGGR_HEALTHMONITOR_READ')) {
			$('.cw_source_link[data-type="MONITOR_ERROR"]').attr('style', 'cursor: default !important').css('color', 'black');
			$('.cw_source_link[data-type="ASSET_HEALTH_REASON"]').attr('style', 'cursor: default !important').css('color', 'black');
		}
		this.adjustSectionHeight();

		let tooltip = $('#cw_event_summary_list').find('.k-grid-content').data('kendoTooltip');
		if (tooltip) {
			tooltip.destroy();
		}
		$('#cw_event_summary_list').find('.k-grid-content').kendoTooltip({
			filter: "td.tooltip:not(:empty)",
			content: (event)  => {
				let dataItem = $('#cw_event_summary_list').data("kendoCustomGrid")?.dataItem(event.target.closest('tr'));
				if (dataItem?.assignedToMouseover && event.target.hasClass('assigned_type')) {
					return dataItem.assignedToMouseover;
				} else {
					return event.target.text().split("\n").join("<br />");
				}
			}
		});

		if (this.tooltipEventSummary) {
			this.tooltipEventSummary.destroy();
		}
		this.tooltipEventSummary = $('#cw_event_summary_list').kendoTooltip({
			filter: '.cw_event_indicator .exclamation-mark',
			autoHide: true,
			position: 'bottom',
			show: function (e) {
				if (!e.sender.popup.element.textContent) {
					return;
				}
				e.sender.popup.element.addClass('warning_tooltip');
			}
		}).data("kendoTooltip");

		$('#cw_event_summary_list')
			.off('mouseover', '.cw_event_indicator .exclamation-mark')
			.on('mouseover', '.cw_event_indicator .exclamation-mark', e => {
				Utils.onMonitorErrorOver(e, {
					toolTip: this.tooltipEventSummary
				});
			});

		$('.cw_section_content').append('<div id="cw_event_summary_window_actions"></div>');

		let scope = this;
		$('.action_count').each(function() {
			let count = parseInt($(this).html());
			if (count) {
				$(this).addClass('cw_grid_link').attr('title', lang.messages.CLICK_VIEW_ACTIONS).on('click', $.proxy(scope.onActionCount, scope))
			}
		});
	},
	onActionCount(e) {
		e.preventDefault();
		e.stopPropagation();
		let windowWidth = 700;
		let windowHeight = 400;
		let target = $(e.target);
		this.actionEventId = target.closest('tr').find('.cw_grid_check').attr('data-id');
		let top = target.offset().top + 30;
		if (top + windowHeight > window.innerHeight) {
			top = target.offset().top - windowHeight - 35;
		}
		let left = target.offset().left - (windowWidth / 2) + 50;
		let navWidth = $('.aside').width();
		if (left < navWidth) {
			left = navWidth;
		} else if (left + windowWidth > window.innerWidth) {
			left = window.innerWidth - windowWidth - 50;
		}

		if (!this.actionsWindow) {
			this.actionsWindow = $('#cw_event_summary_window_actions').kendoWindow({
				width: windowWidth,
				height: windowHeight,
				position: {
					top: top,
					left: left
				},
				title: lang.ACTIONS,
				modal: false,
				resize: $.proxy(function() {
					this.resizedWindow = e.sender;
					clearTimeout(this.resizeTimeout);
					this.resizeTimeout = setTimeout($.proxy(function() {
						let currentHeight = this.actionsWindow.options.height.split('px')[0];
						this.actionsGrid.setOptions({
							height: currentHeight
						});
					}, this), 50);
				}, this)
			}).data('kendoWindow');
		} else {
			this.actionsWindow.setOptions({
				position: {
					top: top,
					left: left
				}
			});
			this.actionsWindow.open();
		}
		let html = '<div id="cw_event_summary_action_grid"></div>';
		this.actionsWindow.content(html);
		this.renderActionsGrid();
	},

	renderActionsGrid() {
		let actionsDataSource = new kendo.ceeview.DataSource({
			transport: {
				read: {
					url: Settings.serverPath + 'notifications/' + this.actionEventId + '/triggerActions',
					contentType: "application/json; charset=utf-8",
					type: "GET",
					dataType: "json",
					cache: false
				}
			},
			schema: {
				parse: function(result) {
					if (result.success) {
						return result.data;
					} else {
						Utils.showInfo(lang.ALERT, result.message, result.details);
					}
				}
			},
			error: ErrorHandler.kendoServerError
		});
		this.actionsGrid = $('#cw_event_summary_action_grid').kendoCustomGrid({
			dataSource: actionsDataSource,
			height: 400,
			resizable: true,
			sortable: true,
			noRecords: {
				template: `<div class="cw_no_records_container"><span class="cw_no_records_info">${lang.messages.CLEARED_ACTION_INFORMATION}</span></div>`
			},
			columns: [{
				field: 'time',
				title: lang.TIME,
				template: '#= Renderer.browserDateRenderer(time, "datetime") #',
				width: 120
			}, {
				field: 'actionType',
				title: lang.ACTION_TYPE,
				width: 100
			}, {
				field: 'actionData',
				title: lang.ACTION_DATA,
				width: 180,
				attributes: {
					class: 'ellipsis to_expand'
				}
			}, {
				field: 'callerId',
				title: lang.NOTIFICATION_TRIGGER
			}, {
				field: 'status',
				title: lang.STATUS,
				width: 100
			}],
			selectable: 'row',
			change: toggleEllipsis,
			dataBound() {
				if (!this.dataSource.data().length) {
					$('#cw_event_summary_action_grid').find('.k-grid-content').css('overflow', 'hidden');
				}
			}
		}).data('kendoCustomGrid');

	},

	/**
	 * Method by Andy
	 */
	adjustSectionHeight: function () {
		var section = $('.cw_section_full');
		var layoutPane = $('.layout-pane').first();
		if(layoutPane.length > 0){
			var height = layoutPane.parent().height()- layoutPane.height();
			section.find('.cw_section_content').css('height', height - 75);
			section.find('.k-grid-content').first().css('height', height - 118);
		}
		else{
			var sectionHeight = section.height();
			section.find('.cw_section_content').css('height', sectionHeight);
			section.find('.k-grid-content').first().css('height', sectionHeight - 40);
		}
	},
	setGridSize: function () {
		this.adjustSectionHeight();
		if (this.grid) {
			this.grid.refresh();
		}
	},

	setTypeTimeUserPrefs() {
		let timePeriod = UserPrefs.get('timePeriod') || (this.timePeriod ?? TimePeriod.LastDay)
		if(this.severity != null){
			timePeriod = 'ALL'
		}
		$('input[value="' + timePeriod + '"]').prop('checked', true).closest('li').addClass('is_selected');
		if (timePeriod === 'CUSTOM') {
			let fromTime = UserPrefs.get('fromTime');
			let toTime = UserPrefs.get('toTime');
			if (fromTime && toTime) {
				this.fromTime = fromTime;
				this.toTime = toTime;
				this.timeTypeUrlParameters += '&fromTime=' + fromTime;
				this.timeTypeUrlParameters += '&toTime=' + toTime;
				this.eventFromDate = new Date(parseInt(fromTime));
				this.eventToDate = new Date(parseInt(toTime));
			}
		} else if (timePeriod === 'ALL') {
			this.showHistoric = false;
			this.timeSelector = 'ALL';
			this.fromTime = null;
			this.toTime = null;
		} else {
			//24h, 7d or 30d
			this.timeSelector = timePeriod;
			this.timeTypeUrlParameters += '&timeSelector=' + timePeriod;
		}

		this.showHistoric = JSON.parse(UserPrefs.get('showHistoric')) || false;
		if (this.showHistoric) {
			this.timeTypeUrlParameters += '&type=ALL';
			$('input[value="HISTORIC"]').closest('li').addClass('is_selected');
			$('input[value="ALL"]').closest('li').addClass('cw_disabled_option');
		} else {
			this.timeTypeUrlParameters += '&type=CURRENT';
		}
	},

	/**
	 * Saves user preferences
	 */
	saveUserPreferences: function () {
		let preferences = [];
		let timePeriod = $('.cw_event_summary_time_range li.is_selected input').val();
		if (timePeriod === 'CUSTOM') {
			if (this.fromTime && this.toTime) {
				preferences.push({
					key: 'fromTime',
					value: this.fromTime
				});
				preferences.push({
					key: 'toTime',
					value: this.toTime
				});
			} else {
				timePeriod = TimePeriod.Last30Days;
			}
		}
		if (timePeriod) {
			preferences.push({
				key: 'timePeriod',
				value: timePeriod
			});
		}
		if (this.showHistoric) {
			preferences.push({
				key: 'showHistoric',
				value: this.showHistoric
			});
		}

		this.saveUserPrefs({
			category: this.getPrefName(),
			preferences: preferences,
			filterPanel: this.gridFilterPanel,
			grid: this.grid,
			eventsToolbar: this.eventsToolbar,
			keys: {
				searchPhrase: 'eventSummarySearchPhrase',
				columns: 'eventSummaryColumns',
				sort: 'eventSummarySort',
				filter: 'eventSummaryFilter',
				FsViews: 'eventSummaryFSViews',
				defaultFsView: 'defaultEventSummaryFsView'
			}
		});
	},

	/**
	 * Handler function for clicking one of the acknowledge or unacknowledge options in menu item
	 */
	onAckUnack: function (e) {
		if (!this.grid) {
			return;
		}
		var checkboxes = $('.cw_grid_check'), i, length = checkboxes.length, assetIds = [], assetId, checkbox, item,
			assetName;

		if (this.selectedItems) {
			for (let i = 0; i < this.selectedItems.length; i++) {
				if (assetIds.indexOf(this.selectedItems[i].id) === -1) {
					assetIds.push(this.selectedItems[i].id);
				}
			}
		} else {
			for (i = 0; i < length; i++) {
				checkbox = $(checkboxes[i]);
				item = this.grid.dataSource.getByUid(checkbox.closest('tr').data('uid'));
				assetId = item.id;
				if (checkbox.is(':checked') && assetIds.indexOf(assetId) === -1 /*&& item.type === 'ASSET_HEALTH_REASON'*/) {
					assetIds.push(assetId);
					assetName = item.targetName;
				}
			}
		}
		var target = $(e.currentTarget);
		if (target.attr('id') === 'cw_event_summary_reason_acknowledge') {
			this.acknowledgeReasons(assetIds, assetName);
		} else if (target.attr('id') === 'cw_event_summary_reason_unacknowledge') {
			this.unacknowledgeReasons(assetIds, assetName);
		}
	},

	onShowAllLogsToggle: function (e) {
		if (!this.grid) {
			return;
		}

		var currentUrl = this.grid.dataSource.options.transport.read.url;
		var newUrl = '';
		this.gridMenu.disableItem('cw_event_summary_acknowledge');
		this.resetCheckboxes();
		if ($(e.currentTarget).attr('id') === 'cw_event_summary_show_all') {
			this.gridMenu.disableItem('cw_event_summary_show_all');
			this.gridMenu.enableItem('cw_event_summary_show_not_acknowledged');
		} else {
			this.gridMenu.enableItem('cw_event_summary_show_all');
			this.gridMenu.disableItem('cw_event_summary_show_not_acknowledged');
		}
		if (currentUrl.indexOf('unAcknowledgedOnly=false') !== -1) {
			newUrl = currentUrl.replace('unAcknowledgedOnly=false', 'unAcknowledgedOnly=' + this.unAcknowledgedOnly);
		} else if (currentUrl.indexOf('unAcknowledgedOnly=true') !== -1) {
			newUrl = currentUrl.replace('unAcknowledgedOnly=true', 'unAcknowledgedOnly=' + this.unAcknowledgedOnly);
		} else {
			newUrl = currentUrl + '&unAcknowledgedOnly=' + this.unAcknowledgedOnly;
		}
		this.grid.dataSource.options.transport.read.url = newUrl;
		this.grid.dataSource.read();
	},

	onCheck: function (e) {
		var checkboxes = $('#cw_event_summary_list').find('.cw_grid_check');
		var serviceLogsCount = 0;
		var reasonsCount = 0;
		var selectAllIndex;
		this.selectedIds = this.selectedIds || [];
		this.selectedItems = this.selectedItems || [];
		this.filteringChanged = false;
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			var checkboxId = $(checkboxes[i]).attr('data-id');
			var index = State.markedCheckboxes.indexOf(checkboxId);
			selectAllIndex = this.selectedIds.indexOf(checkboxId);
			var uid = $(checkboxes[i]).closest('tr').attr('data-uid');
			var record = this.dataSource.getByUid(uid);
			if ($(checkboxes[i]).is(':checked')) {
				if (record.type === 'ASSET_HEALTH_REASON') {
					reasonsCount++;
				}
				if (['SERVICE_ERROR', 'SERVICE', 'SERVICE_ELEMENT', 'SERVICE_QUALIFIER'].indexOf(record.type) > -1) {
					serviceLogsCount++;
				}
				if (index === -1) {
					State.markedCheckboxes.push(checkboxId);
				}
				if (selectAllIndex === -1 && !record.historic) {
					this.selectedIds.push(checkboxId);
					this.selectedItems.push({
						id: record.id,
						sourceId: record.sourceId,
						targetId: record.targetId,
						type: record.type,
						accountId: record.accountId,
						identifierName: record.identifierName,
						identifierInstance: record.identifierInstance
					});
				}
			} else {
				if (index !== -1) {
					State.markedCheckboxes.splice(index, 1);
				}
				if (selectAllIndex !== undefined && selectAllIndex !== -1) {
					this.selectedIds.splice(selectAllIndex, 1);
					this.selectedItems.splice(selectAllIndex, 1);
				}
			}
		}
		if (this.selectedIds.length) {
			this.gridMenu.enableItem('cw_event_summary_reason_acknowledge');
			this.gridMenu.enableItem('cw_event_summary_reason_unacknowledge');
			this.gridMenu.enableItem('cw_event_summary_assign');
			this.gridMenu.enableItem('cw_event_summary_unassign');
			this.gridMenu.enableItem('cw_delete_reason');
			this.gridMenu.enableItem('cw_set_information');

			if (reasonsCount) {
				if (reasonsCount === 1) {
					this.gridMenu.enableItem('cw_event_summary_create_filter');
				} else {
					this.gridMenu.disableItem('cw_event_summary_create_filter');
				}
				this.gridMenu.enableItem('cw_event_summary_start_service');
				this.gridMenu.enableItem('cw_event_summary_stop_service');
				this.gridMenu.enableItem('cw_event_summary_incidents_create');
				this.gridMenu.enableItem('cw_event_summary_reset_hi');
			} else {
				this.gridMenu.disableItem('cw_event_summary_incidents_create');
				this.gridMenu.disableItem('cw_event_summary_reset_hi');
				this.gridMenu.disableItem('cw_event_summary_create_filter');
				this.gridMenu.disableItem('cw_event_summary_start_service');
				this.gridMenu.disableItem('cw_event_summary_stop_service');
			}
			if (serviceLogsCount) {
				//this.gridMenu.enableItem('cw_event_summary_acknowledge');
				if (serviceLogsCount === 1) {
					this.gridMenu.enableItem('cw_event_summary_add_note');
				} else {
					this.gridMenu.disableItem('cw_event_summary_add_note');
				}
			} else {
				//this.gridMenu.disableItem('cw_event_summary_acknowledge');
				this.gridMenu.disableItem('cw_event_summary_add_note');
			}
		} else {
			this.gridMenu.disableItem('cw_set_information');
			this.gridMenu.disableItem('cw_event_summary_incidents_create');
			this.gridMenu.disableItem('cw_event_summary_reset_hi');
			this.gridMenu.disableItem('cw_event_summary_create_filter');
			this.gridMenu.disableItem('cw_event_summary_start_service');
			this.gridMenu.disableItem('cw_event_summary_stop_service');
			this.gridMenu.disableItem('cw_event_summary_add_note');
			this.gridMenu.disableItem('cw_event_summary_reason_acknowledge');
			this.gridMenu.disableItem('cw_event_summary_reason_unacknowledge');
			this.gridMenu.disableItem('cw_event_summary_assign');
			this.gridMenu.disableItem('cw_event_summary_unassign');
			this.gridMenu.disableItem('cw_delete_reason');
		}
	},
	/**
	 * Handler for the check all checkbox click
	 * @param {Object} e The click event
	 */
	onCheckAll: function (e) {
		var checkAll = $('#cw_event_summary_list').find('.cw_grid_check_all').is(':checked');
		var checkboxes = $('#cw_event_summary_list').find('.cw_grid_check');
		var i, length = checkboxes.length;
		for (i = 0; i < length; i++) {
			$(checkboxes[i]).prop('checked', checkAll);
		}
		if (checkAll) {
			this.getAllIds();
		} else {
			this.selectedIds = [];
			this.selectedItems = [];
		}
		this.onCheck();
	},
	getAllIds: function () {
		var currentEvents;
		let url = this.baseUrl + '/selectAll' + this.urlParameters + this.timeTypeUrlParameters;
		Utils.ajax(url, 'POST', JSON.stringify(this.gridPayload), $.proxy(function (result) {
			if (result.success) {
				this.selectedIds = this.selectedIds || [];
				this.selectedItems = this.selectedItems || [];
				for (let i = 0; i < result.data.length; i++) {
					currentEvents = result.data[i].events;
					for (let j = 0; j < currentEvents.length; j++) {
						currentEvents[j].accountId = result.data[i].accountId;
						if (!currentEvents[j].historic) {
							this.selectedIds.push(currentEvents[j].id);
							this.selectedItems.push(currentEvents[j]);
						}
					}
				}
			}
		}, this));
	},
	onDateRangeChange(value) {
		if (value.length > 0)
			this.eventFromDate = new Date(value[0]._d.getTime());
		if (value.length > 1)
			this.eventToDate = new Date(value[1]._d.getTime());
	},

	onSelectPeriods() {
		if (this.validateDates()) {
			this.timeSelector = null;
			this.fromTime = this.eventFromDate.getTime();
			this.toTime = this.eventToDate.getTime();
			this.refreshGrid(this.showHistoric, this.timeSelector, this.fromTime, this.toTime)
			this.hideDateRangePicker();
		}
	},

	hideDateRangePicker(){
		$('.cw_event_summary_period').addClass('hide');
		document.body.removeEventListener('click', this.autoCloseDateRangePicker);
	},

	autoCloseDateRangePicker(e){
		let rangeCont=document.querySelector('.cw_event_summary_period');
		let antDropDown = document.querySelector('.ant-picker-dropdown');
		antDropDown.style.zIndex = "10500"
		if (!rangeCont?.contains(e.target) &&
			(!antDropDown || !antDropDown.contains(e.target))) {
			this.hideDateRangePicker();
		}
	},

	refreshGrid(showHistoric, timeSelector, fromTime, toTime) {
		this.timeTypeUrlParameters = '';
		let type;
		if (showHistoric) {
			type = 'ALL';
		} else {
			type = 'CURRENT';
		}
		if (type) {
			this.timeTypeUrlParameters += '&type=' + type;
		}
		if (timeSelector && timeSelector !== 'ALL') {
			this.timeTypeUrlParameters += '&timeSelector=' + timeSelector;
		}
		if (fromTime) {
			this.timeTypeUrlParameters += '&fromTime=' + fromTime;
		}
		if (toTime) {
			this.timeTypeUrlParameters += '&toTime=' + toTime;
		}
		this.dataSource.options.transport.read.url = this.baseUrl + this.urlParameters + this.timeTypeUrlParameters;
		this.dataSource.read();
	},

	/**
	 * Validates the datetime pickers
	 * @return {Boolean} isValid
	 */
	validateDates() {
		var isValid = true;
		if (!this.eventFromDate) {
			isValid = false;
		}
		if (!this.eventToDate) {
			isValid = false;
		}
		return isValid;
	},

	onEventsTimeout: function () {
		if (!this.grid) {
			return;
		}

		this.checkboxStates = Utils.getCheckboxStates(this.grid);
		this.grid.dataSource.read();
	},

	/**
	 * Handler function for sending multiple winservice commands
	 * @param {String} command The command to send, 'START' or 'STOP'
	 * @param {Array} events The list with the events to send
	 */
	sendMultipleWinServiceAction: function (command, events) {
		if (command && events) {
			var url = Settings.serverPath + 'accounts/' + this.accountId + '/monitors/WINSERVICE/multipleServiceCommand/' + command;
			Utils.ajax(url, 'POST', JSON.stringify(events), $.proxy(function (result) {
				if (result.success) {
					this.actionNotification.setOptions({
						message: result.message,
						status: 'info'
					}).show();
				} else {
					this.actionNotification.setOptions({
						message: result.message,
						status: 'error'
					}).show();
				}
				this.resetCheckboxes();
			}, this));
		}
	},
	onResetHIButton: function () {
		var checkboxes = $('#cw_event_summary_list').find('.cw_grid_check');
		var data = [];

		if (this.selectedItems?.length) {
			for (let selectedItem of this.selectedItems) {
				if (selectedItem.type === 'ASSET_HEALTH_REASON' && this.grid.dataSource.get(selectedItem.id)) {
					data.push({
						monitorId: selectedItem.sourceId,
						assetId: selectedItem.targetId
					});
				}
			}
		} else {
			for (var i = 0, length = checkboxes.length; i < length; i++) {
				if ($(checkboxes[i]).is(':checked')) {
					var record = this.dataSource.getByUid($(checkboxes[i]).closest('tr').attr('data-uid'));
					if (record.type === 'ASSET_HEALTH_REASON') {
						data.push({
							monitorId: record.sourceId,
							assetId: record.targetId
						});
					}
				}
			}
		}
		Utils.resetMonitorsHI(data, $.proxy(function () {
			this.resetCheckboxes();
		}, this));
		//this.resetCheckboxes();
	},
	onCreateFilter: function (e) {
		var checkbox = $('#cw_event_summary_list').find('.cw_grid_check:checked');
		var record = this.dataSource.getByUid($(checkbox).closest('tr').attr('data-uid'));
		State.mainApp.loadModule('AssetHealthFiltersConfiguration', '', {
			mode: 'create',
			populate: {
				monitorId: record.sourceId,
				monitorType: record.monitorType,
				severity: record.severity,
				reasonDescription: record.message,
				reasonSubsytem: record.subsystem,
				identifierName: record.identifierName || '',
				identifierInstance: record.identifierInstance || '',
				accountId: record.accountId,
				accountName: record.accountName
			}
		}, e);
	},
	/**
	 * @private
	 * Gets the winservice records
	 * @return {Array} events
	 */
	_getCheckedEvents: function () {
		let selectedCheckboxes = $('#cw_event_summary_list').find('.cw_grid_check:checked');
		let selectedIds = [];
		let events = [];

		if (this.selectedItems) {
			for (let i = 0; i < this.selectedItems.length; i++) {
				if (this.allowedIdentifiers[this.selectedItems[i].identifierName]) {
					events.push({
						assetId: this.selectedItems[i].targetId,
						monitorId: this.selectedItems[i].sourceId,
						identifierName: this.selectedItems[i].identifierName,
						identifierInstance: this.selectedItems[i].identifierInstance
					});
				}
			}
		} else {
			for (let i = 0; i < selectedCheckboxes.length; i++) {
				let checkbox = $(selectedCheckboxes[i]);
				let uid = checkbox.closest('tr').attr('data-uid');
				let record = this.dataSource.getByUid(uid);
				if (this.allowedIdentifiers[record.identifierName]) {
					events.push({
						assetId: record.targetId,
						monitorId: record.sourceId,
						identifierName: record.identifierName,
						identifierInstance: record.identifierInstance
					});
				}
			}
		}
		return events;
	},
	onStartService: function () {
		this.sendMultipleWinServiceAction('START', this._getCheckedEvents());
	},
	onStopService: function () {
		this.sendMultipleWinServiceAction('STOP', this._getCheckedEvents());
	},
	/**
	 * Handler function for the click event on Add Service Note button
	 * @param {Object} e The click event
	 */
	onAddServiceNote: function (e) {
		if (!this.selectedServiceId) {
			var checkbox = $('#cw_event_summary_list').find('.cw_grid_check:checked');
			var record = this.dataSource.getByUid($(checkbox).closest('tr').attr('data-uid'));
			this.selectedServiceId = record.targetId;
		}
		this.serviceNoteWindowId = Utils.guid();
		$('body').append('<div id="' + this.serviceNoteWindowId + '"></div>');

		var window = $('#' + this.serviceNoteWindowId).kendoWindow({
			width: '400px',
			height: '300px',
			title: i('Create log entry'),
			modal: true,
			visible: false,
			close: $.proxy(function () {
				$('#' + this.serviceNoteWindowId).data("kendoWindow").destroy();
			}, this)
		}).data('kendoWindow');
		html = '<div class="cw_service_note form">';
		html += '<div class="cw_field">';
		html += '<label class="cw_inline">' + 'Status' + '</label>';
		html += '<div class="cw_dropdown_container">';
		html += '<input id="cw_service_note_status" type="text" class="" />';
		html += '</div>';
		html += '</div>';
		html += '<div class="cw_comment_wrapper">';
		html += '<textarea id="cw_service_note_message" placeholder="' + lang.service.MESSAGE_TEXT + '" class="cw_comment_text"></textarea>';
		html += '</div>';
		html += '</div>';
		html += '<div class="status"><p></p></div>';
		html += '<div class="cw_actions absolute">';
		html += '<button class="k-button right" id="cancel_service_note">' + lang.CANCEL + '</button>';
		html += '<button class="k-button k-primary go_right" id="save_service_note">' + lang.SAVE + '</button>';
		html += '</div>';
		window.content(html);
		window.open().center();
		$('#cw_service_note_status').kendoDropDownList({
			dataSource: [{
				id: 'OK',
				text: lang.OK
			}, {
				id: 'ERROR',
				text: lang.ERROR
			}, {
				id: 'WARNING',
				text: lang.WARNING
			}, {
				id: 'FAILED',
				text: lang.FAILED
			}, {
				id: 'UNKNOWN',
				text: lang.UNKNOWN
			}],
			dataTextField: 'text',
			dataValueField: 'id'
		});
		$('#save_service_note').off();
		$('#save_service_note').on('click', $.proxy(this.onSaveServiceNote, this));
		$('#cancel_service_note').off();
		$('#cancel_service_note').on('click', $.proxy(this.onCancelServiceNote, this));
	},
	/**
	 * Handler function for clicking assign option in grid menu
	 */
	onAssign: function () {
		this.assignWindow = new ModalWindow({
			title: lang.ASSIGN_EVENT,
			width: 400,
			resizable: true,
			minHeight: 200,
			height: 200,
			modal: true,
			actions: [],
			url: 'include/Summary/AssignEventForm.jsp',
			refresh: $.proxy(function () {
				this.onAssignWindowLoaded();
				$('#cw_assign_event_close').on('click', $.proxy(this.onAssignWindowClose, this));
				$('#cw_assign_event_update').on('click', $.proxy(this.onAssignWindowUpdate, this));
			}, this)
		});
		this.assignWindow.open();
	},
	onRemove: function () {
		this.removeSelected();
	},
	removeSelected: async function () {
		let selectedCheckboxes = $('#cw_event_summary_list').find('.cw_grid_check:checked');
		let uids = [];

		for (let i = 0; i < selectedCheckboxes.length; i++) {
			let checkbox = $(selectedCheckboxes[i]);
			let uid = checkbox.closest('tr').attr('data-uid');
			let record = this.dataSource.getByUid(uid);
			uids.push({
				id: record.id,
				accountId: record.accountId
			});
		}

		if (this.selectedItems) {
			var selected = [];
			for (let i = 0; i < this.selectedItems.length; i++) {
				selected.push({
					accountId: this.selectedItems[i].accountId,
					id: this.selectedItems[i].id
				})
			}
		}

		uids = selected || uids;

		if (!uids) {
			return;
		}

		const url = `${Settings.serverPath}accounts/${this.accountId}/summary/eventSummary/delete`;
		const {result} = await Utils.ajaxPromise(url, 'POST', JSON.stringify(uids));

		if (result.success) {
			this.actionNotification.setOptions({
				message: lang.messages.REASON_SUCCESSFULLY_REMOVED,
				status: 'success'
			}).show();
			this.grid.dataSource.read();
			this.resetCheckboxes();
		} else {
			Utils.showInfo(lang.ALERT, result.message, result.details);
		}
	},
	/**
	 * Handler function for clicking unassign option in grid menu
	 */
	onUnassign: function () {
		var selectedCheckboxes = $('#cw_event_summary_list').find('.cw_grid_check:checked');
		var selectedEventIds = [];
		for (var i = 0; i < selectedCheckboxes.length; i++) {
			selectedEventIds.push($(selectedCheckboxes[i]).attr('data-id'));
		}
		var url = Settings.serverPath + 'accounts/' + this.accountId + '/summary/eventSummary/unassign?';
		Utils.ajax(url, 'POST', JSON.stringify(this.selectedIds || selectedEventIds), $.proxy(function (result) {
			if (result.success) {
				this.actionNotification.setOptions({
					message: lang.messages.EVENT_SUCCESSFULLY_UNASSIGNED,
					status: 'success'
				}).show();
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
			this.resetCheckboxes();
		}, this));
	},
	/**
	 * Handler function for clicking export to CSV option in grid menu
	 */
	onExportCsv: function () {
		let url = Settings.serverPath + 'accounts/' + this.accountId + '/summary/eventSummary/exportCSV';
		url += this.urlParameters + this.timeTypeUrlParameters;
		let excludedColumns = ['action'];
        Utils.exportGridCsv(this.grid, url, excludedColumns);
	},
	/**
	 * Handler function for actions required to be performed when the assigned window was loaded
	 */
	onAssignWindowLoaded: function () {
		var url = Settings.serverPath + 'accounts/' + this.accountId + '/recipients';
		this.assignSelector = $('#cw_assign_event_selector').kendoDropDownList({
			dataSource: new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: url,
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				schema: {
					data: function (response) {
						var result, userTeamArray = [];
						if (response.success) {
							result = response.data;
						} else if (response.length) {
							result = response;
						} else {
							result = [];
						}
						for (var i = 0, length = result.length; i < length; i++) {
							result[i] = {
								text: result[i].name,
								value: result[i].id,
								type: result[i].type
							};
							switch (result[i].type) {
								case 'TEAM':
									result[i].optionGlyphClass = 'parents';
									userTeamArray.push(result[i]);
									break;
								case 'USER':
									result[i].optionGlyphClass = 'user';
									userTeamArray.push(result[i]);
									break;
								case 'EMAIL':
									break;
							}
						}
						return userTeamArray;
					}
				},
				group: {
					field: 'type'
				},
				error: ErrorHandler.kendoServerError
			}),
			dataTextField: 'text',
			dataValueField: 'value',
			optionLabel: ' ',
			optionLabelTemplate: '<span id="cw_assign_placeholder">User/team...</span>',
			valueTemplate: '<span class="glyphicons ${optionGlyphClass}" style="padding-top: 3px"></span><span style="padding-left: 10px">${text}</span>',
			open: $.proxy(function () {
				//overwrite kendo groupable questionable functionality (show different first group; moreover, show groupable row when no data)
				var recipientsOpenedContainer = $('#cw_assign_event_selector-list');
				//hide option label from selection area
				recipientsOpenedContainer.find('.k-list-optionlabel').addClass('hide');
				var recipientsGroupHeader = recipientsOpenedContainer.find('.k-group-header');
				var recipientsNoDataContainer = recipientsOpenedContainer.find('.k-nodata');
				if (recipientsNoDataContainer.attr('style')) {//check for style display:none property put by kendo
					//case when multiselect has data; transform the grouping header row into a normal grouping element
					recipientsGroupHeader.addClass('hide');
					var firstItem = recipientsOpenedContainer.find('li').first();
					firstItem.append('<div class="k-group">' + recipientsGroupHeader.html() + '</div>');
				} else {
					//case when multiselect has no data; hide the grouping header, fix for poor kendo functionality
					recipientsGroupHeader.addClass('hide');
				}
			}, this),
			select: () => {
				$('#cw_assign_event_update').prop('disabled', false);
			},
			dataBound: function () {
				$('#cw_assign_placeholder').css('color', '#b8b8b8');
				$('#cw_assign_event_update').prop('disabled', true);
			}
		}).data('kendoDropDownList');
	},
	/**
	 * Handler function called when completing the assigned form
	 */
	onAssignWindowUpdate: function (e) {
		var selectedRecipient = this.assignSelector.value();
		var recipientUrl;
		var selectedCheckboxes = $('#cw_event_summary_list').find('.cw_grid_check:checked');
		var selectedEventIds = [];
		for (var i = 0; i < selectedCheckboxes.length; i++) {
			selectedEventIds.push($(selectedCheckboxes[i]).attr('data-id'));
		}
		var allRecipients = this.assignSelector.dataSource.data();
		for (var j = 0; j < allRecipients.length; j++) {
			if (selectedRecipient === allRecipients[j].value) {
				if (allRecipients[j].type === 'USER') {
					recipientUrl = 'userId';
				} else if (allRecipients[j].type === 'TEAM') {
					recipientUrl = 'teamId';
				}
			}
		}
		var url = Settings.serverPath + 'accounts/' + this.accountId + '/summary/eventSummary/assign?' + recipientUrl + '=' + selectedRecipient;
		Utils.ajax(url, 'POST', JSON.stringify(this.selectedIds || selectedEventIds), $.proxy(function (result) {
			if (result.success) {
				this.actionNotification.setOptions({
					message: lang.messages.EVENT_SUCCESSFULLY_ASSIGNED,
					status: 'success'
				}).show();
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
			this.assignWindow.close();
			this.resetCheckboxes();
		}, this));

	},
	/**
	 * Handler function called when closing the assigned window
	 */
	onAssignWindowClose: function () {
		this.assignWindow.close();
	},
	/**
	 * Handler function for the click event on Save Service Note button
	 * @param {Object} e The click event
	 */
	onSaveServiceNote: function (e) {
		var message = $('#cw_service_note_message').val();
		var state = $('#cw_service_note_status').data('kendoDropDownList').value();
		if (message) {
			var data = {
				message: message
			};
			var url = Settings.serverPath + 'services/' + this.selectedServiceId + '/notifications/?status=' + state;
			Utils.ajax(url, 'POST', JSON.stringify(data), $.proxy(function (result) {
				if (result.success) {
					this.onCancelServiceNote();
				} else {
					Utils.showInfo(lang.ALERT, result.message, result.details);
				}
				this.resetCheckboxes();
			}, this));
		} else {
			var status = $('.cw_service_note').find('.status');
			status.find('p').text(lang.service.messages.ENTER_MESSAGE);
			status.find('p').addClass('error');
			status.slideDown().delay(4000).slideUp();
		}
	},
	/**
	 * Handler function for the click event on Save Service Note button
	 * @param {Object} e The click event
	 */
	onCancelServiceNote: function (e) {
		this.selectedServiceId = null;
		var win = $('#' + this.serviceNoteWindowId).data("kendoWindow");
		if (win) {
			win.close();
			win.destroy();
		}
	},
	acknowledgeReasons: function (assetIds, assetName) {
		var url = Settings.serverPath + 'accounts/' + this.accountId + '/summary/eventSummary/acknowledge';
		Utils.ajax(url, 'POST', JSON.stringify(assetIds), $.proxy(function (result) {
			if (result.success) {
				this.actionNotification.setOptions({
					message: assetIds.length > 1 ? lang.messages.REASONS_ASSETS_ACKNOWLEDGED : kendo.template(lang.messages.REASONS_ACKNOWLEDGED)({'assetName': assetName}),
					status: 'success'
				}).show();
				this.grid.dataSource.read();
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
			this.resetCheckboxes();
		}, this));
	},
	unacknowledgeReasons: function (assetIds, assetName) {
		var url = Settings.serverPath + 'accounts/' + this.accountId + '/summary/eventSummary/unacknowledge';
		Utils.ajax(url, 'POST', JSON.stringify(assetIds), $.proxy(function (result) {
			if (result.success) {
				this.actionNotification.setOptions({
					message: assetIds.length > 1 ? lang.messages.REASONS_ASSETS_UNACKNOWLEDGED : kendo.template(lang.messages.REASONS_UNACKNOWLEDGED)({'assetName': assetName}),
					status: 'success'
				}).show();
				this.grid.dataSource.read();
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
			this.resetCheckboxes();
		}, this));
	},

	onCreateIncident: function (e) {
		var checkboxes = $('#cw_event_summary_list').find('.cw_grid_check');
		var assets = [];
		var accountList = [];
		var reasonsIds = [];
		var found

		if (this.selectedItems) {
			for (let i = 0; i < this.selectedItems.length; i++) {
				if (this.selectedItems[i].type === 'ASSET_HEALTH_REASON') {
					found = false;
					for (var j = 0; j < assets.length; j++) {
						if (assets[j].id === this.selectedItems[i].targetId) {
							found = true;
							break;
						}
					}
					if (!found) {
						assets.push({
							name: this.selectedItems[i].targetName,
							id: this.selectedItems[i].targetId
						});
					}
					found = false;
					for (var j = 0; j < accountList.length; j++) {
						if (accountList[j].id === this.selectedItems[i].accountId) {
							found = true;
							break;
						}
					}
					if (!found) {
						accountList.push({
							id: this.selectedItems[i].accountId,
							name: this.selectedItems[i].accountName
						});
					}
					if (reasonsIds.indexOf(this.selectedItems[i].id) === -1) {
						reasonsIds.push(this.selectedItems[i].id);
					}
				}
			}
		} else {
			for (var i = 0, length = checkboxes.length; i < length; i++) {
				if ($(checkboxes[i]).is(':checked')) {
					var record = this.dataSource.getByUid($(checkboxes[i]).closest('tr').attr('data-uid'));
					if (record.type === 'ASSET_HEALTH_REASON') {
						found = false;
						for (var j = 0; j < assets.length; j++) {
							if (assets[j].id === record.targetId) {
								found = true;
								break;
							}
						}
						if (!found) {
							assets.push({
								name: record.targetName,
								id: record.targetId
							});
						}
						found = false;
						for (var j = 0; j < accountList.length; j++) {
							if (accountList[j].id === record.accountId) {
								found = true;
								break;
							}
						}
						if (!found) {
							accountList.push({
								id: record.accountId,
								name: record.accountName
							});
						}
						if (reasonsIds.indexOf(record.id) === -1) {
							reasonsIds.push(record.id);
						}
					}
				}
			}
		}

		State.mainApp.navigate(IncidentsRouter.createNew('ASSET'), {
			extra: {
				assets: assets,
				accountList: accountList,
				reasonsIds: reasonsIds,
				isFromEventSummary: true
			}
		});
	},

	onTargetNameClick: function (e) {
		var targetEl = $(e.currentTarget);
		var type = targetEl.attr('data-type');
		var record = this.dataSource.getByUid(targetEl.closest('tr').attr('data-uid'));
		switch (type) {
			case 'MONITOR_ERROR':
			case 'ASSET_HEALTH_REASON':
				var assetId = targetEl.attr('data-targetid');
				var assetName = targetEl.text();
				State.mainApp.loadModule('AssetDetails', assetId, {name: assetName}, e);
				break;
			case 'AGENT_STATE':
				var agentId = targetEl.attr('data-targetid');
				var agentName = targetEl.text();
				if (Utils.canEditAgent(agentId)) {
					State.mainApp.navigate(AgentsRouter.details(agentId));
				}
				break;

			case 'SERVICE_WARNING':
			case 'SERVICE':
			case 'SERVICE_ERROR':
			case 'SERVICE_MODEL':
			case 'SERVICE_LINK':
			case 'SERVICE_QUALIFIER_WARNING':
			case 'SERVICE_QUALIFIER':
			case 'SERVICE_ELEMENT':
				var highlightObj;
				var serviceId = targetEl.attr('data-targetid');
				var rowUid = targetEl.closest('tr').attr('data-uid');
				var itemData = this.grid.dataSource.getByUid(rowUid);
				var accountId = itemData.accountId;
				if (type === 'SERVICE_QUALIFIER' || type === 'SERVICE_QUALIFIER_WARNING') {
					//highlight element and qualifier
					highlightObj = {
						element: itemData.sourceId
					}
				}
				if (type === 'SLA' || type === 'SERVICE_ELEMENT' || type === 'SERVICE_LINK') {
					//highlight element
					highlightObj = {
						node: itemData.sourceId
					};
				}

				State.mainApp.navigate(ServicesRouter.viewer(serviceId, {
					highlightObj: highlightObj
				}));
				break;
			case 'SLA':
				State.mainApp.navigate(SlaRouter.details(record.targetId));
				break;
			case 'SYSTEM_ERROR':
				State.mainApp.navigate(AgentsRouter.details(record.targetId));
				break;
			case 'AGENT_WARNING':
				State.mainApp.navigate(AgentsRouter.details(record.targetId));
				break;
			case 'COST':
				this.navigateToCostProfile(record.sourceId, record.targetId)
				break;
		}
	},

	onSourceNameClick: function (e) {
		var sourceEl = $(e.currentTarget);
		var type = sourceEl.attr('data-type');
		var record = this.dataSource.getByUid(sourceEl.closest('tr').attr('data-uid'));
		switch (type) {
			case 'SERVICE_ERROR':
			case 'SERVICE':
			case 'SERVICE_ELEMENT':
			case 'SERVICE_QUALIFIER':
			case 'SERVICE_LINK':
			case 'SERVICE_MODEL':
			case 'SERVICE_WARNING':
			case 'SERVICE_QUALIFIER_WARNING':
				State.mainApp.loadModule('ServiceDetails', record.targetId, {}, e);
				break;
			case 'MONITOR_ERROR':
			case 'ASSET_HEALTH_REASON':
				var monitorId = sourceEl.attr('data-sourceid');
				State.currentApp.openMonitorView(monitorId, record.monitorType, false, e);
				break;
			case 'AGENT_STATE':
				var assetId = sourceEl.attr('data-sourceid');
				State.mainApp.loadModule('AssetDetails', assetId, {}, e);
				break;
			case 'SLA':
				State.mainApp.loadModule('ServiceDetails', record.sourceId, {}, e);
				break;
			case 'SYSTEM_ERROR':
				State.mainApp.navigate(AgentsRouter.details(record.targetId));
				break;
			case 'AGENT_WARNING':
				State.mainApp.navigate(AgentsRouter.details(record.targetId));
				break;
			case 'COST':
				this.navigateToCostProfile(record.sourceId);
				break;
		}
	},

	onAgentNameClick: function (e) {
		let target = $(e.currentTarget);
		let agentId = target.data('agentid');
		State.mainApp.navigate(AgentsRouter.details(agentId));
	},

	onSetInformation() {
		$('.window_area').append('<div id="set-information-window-container"></div>');
		ReactDOM.render(<SetInformationWindow
			onClose={this.onSetInformationClose}
			onUpdate={(value) => this.onSetInformationUpdate(value)}
		></SetInformationWindow>, $('#set-information-window-container').get(0))
	},
	onSetInformationClose() {
		ReactDOM.unmountComponentAtNode($('#set-information-window-container').get(0));
	},
	onSetInformationUpdate(informationValue) {
		let selectedCheckboxes = $('#cw_event_summary_list').find('.cw_grid_check:checked');
		let selectedIds = [];
		let id;

		if (this.selectedItems) {
			for (let i = 0; i < this.selectedItems.length; i++) {
				id = this.selectedItems[i].id;
				if (id) {
					selectedIds.push(id);
				}
			}
		} else {
			for (let i = 0; i < selectedCheckboxes.length; i++) {
				id = $(selectedCheckboxes[i]).attr('data-id');
				if (id) {
					selectedIds.push(id);
				}
			}
		}

		let url = Settings.serverPath + 'accounts/' + this.accountId + '/summary/eventSummary/information';
		let postObj = {
			eventIds: selectedIds,
			information: informationValue
		};
		Utils.ajax(url, 'POST', JSON.stringify(postObj), $.proxy(function (result) {
			if (result.success) {
				this.actionNotification.setOptions({
					message: lang.messages.INFO_SUCCESSFULLY_UPDATE,
					status: 'success'
				}).show();
				this.grid.dataSource.read();
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
			this.onSetInformationClose();
			this.resetCheckboxes();
		}, this));
	},
	resetCheckboxes: function() {
		$('#cw_event_summary_list').find('.cw_grid_check').prop('checked', false);
		$('#cw_event_summary_list').find('.cw_grid_check_all').prop('checked', false);
		State.markedCheckboxes = [];
		this.selectedItems = [];
		this.selectedIds = [];
	},
	/**
	 * Subscribes to the events
	 */
	subscribe: function () {
		this.isDataSourceSubscribed = true;
		var subscriptionObj = [{
			eventType: 'EventSummary',
			accountId: this.accountId,
			includeSubaccounts: this.includeSubaccounts
		}];
		RemoteEventsManager.subscribe(this.subscriberId, subscriptionObj);
	},

	getPrefName: function () {
		return this.embedded ? 'EmbeddedEventSummary': 'EventSummary'
	},
	/**
	 * Destroy method
	 */
	destroy: function () {
		ReactDOM.unmountComponentAtNode($('.date_range_container')[0])
		this.eventsToolbar?.destroy();
		this.actionsWindow?.destroy();

		if(this.doNotLoadPrefs != true) {
			this.saveUserPreferences();
		}
	},

	navigateToCostProfile: function(modelId, expandToRecordId){
		CostsApi.costModelProfilesList(ApplicationState.accountId, modelId).then(r => {
			if(r.success && r.data?.length > 0)
				State.mainApp.navigate(CostsRouter.details(r.data[0].id, expandToRecordId));
		})
	}
});
