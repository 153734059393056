import {MultiSelect} from "controls/react/form";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import AssetGroupAPI from "tools/apis/assetGroupAPI";

AssetGroupMultiSelect.propTypes = {
	accountId: PropTypes.string.isRequired,
	value: PropTypes.array,
	onChange: PropTypes.func.isRequired,
	required: PropTypes.bool
};

const options = {
	dataTextField: "name",
	dataValueField: "id"
};

export function useAssetGroups(accountId = null) {
	const [assetGroups, setAssetGroups] = useState();

	useEffect(() => {
		const fetchAssetGroups = async () => {
			const assetGroups = await AssetGroupAPI.getAssetGroups(accountId);
			setAssetGroups(assetGroups);
		};
		fetchAssetGroups();
	}, [accountId]);

	return [assetGroups];
}

/**
 * @return {null}
 */
export function AssetGroupMultiSelect(props) {
	const [allAssetGroups] = useAssetGroups(props.accountId);

	if (!allAssetGroups) {
		return null;
	}

	return (
		<MultiSelect value={props.value}
		             data={allAssetGroups}
		             onChange={props.onChange}
		             options={{...options,...props.options}}/>
	);
}
