import React from "react";
import {translator} from "core/localization/localization";
import {ModalWindow} from "controls/modalWindow";
import IconButton from 'controls/react/form/iconButton';
import AssetForm from "areas/administration/assetForm";


const i = translator({
  "Create new asset": {
    "no": "Opprett ny ressurs"
  },
  "Create asset": {
    "no": "Lag ressurs"
  }
});

export class AddAsset extends React.PureComponent {
	render() {
		return <IconButton title={i('Create new asset')}
		                   onClick={() => this.onClick()}
		                   iconName='plus-sign'
		                   embedded
		                   disabled={this.props.disabled}
		                   containerClass={this.props.containerClass}/>;
	}

	onClick() {
		this.window = new ModalWindow({
			title: i('Create asset'),
			width: 400,
			minHeight: 567,
			height: 'auto',
			url: 'include/Administration/AssetForm.jsp',
			refresh: () => {
				new AssetForm({
					id: this.props.assetId || null,
					mode: this.props.assetId ? 'update': 'create',
					identifier: this.props.identifier
				});
			},
			onClose: () => {
				if (this.props.onClose) {
					this.props.onClose();
				}
			}
		});
		this.window.open();
	}
}

export default AddAsset;
