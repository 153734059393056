import React, { useCallback, useState}  from 'react';
import {FormEntry} from "controls/react/form/formEntry";
import {MetricWindow} from "areas/service-boards/widgets/common/metricWindow";
import {IconButton} from "controls/react/form/iconButton";
import {AntInput} from "controls/react/ant/antInput";
import {useStore} from "../genericMetricsWidgetConfiguration/useStore";
import {sharedLocalization} from "controls/designer/features/widgets/localization";
import {fromJS} from "immutable";
import {CoommonWidgetsApi} from './api';
import { CollapsibleFrame } from "controls/react/layout/collapsibleFrame";

const i = require('core/localization').translator(sharedLocalization,{
    'Metric Settings' : {
        no : 'Metrikkinnstillinger'
    },
	'Metric selector': {
		no: 'Metrikkvalg'
	}
});

export function MetricInlineSelector(props) {
	const [showMetricsWindow, setShowMetricsWindow] = React.useState(false);

	const onAdd = (items) => {
		setShowMetricsWindow(false);
		if (props.onAdd)
			props.onAdd(items);
	}

	return <CollapsibleFrame expanded={props.expanded}
	                         title={i('Metric Settings')}>
		<FormEntry label={i('Metric')}>
			<IconButton iconName={"show-thumbnails"} embedded={true}
			            onClick={() => setShowMetricsWindow(true)}
			            title={i('Metric selector')}/>
			<AntInput value={props.text} readOnly={true}/>
		</FormEntry>
		{showMetricsWindow && props.accountId != null &&
			<MetricWindow
				open={true}
				onClose={() => setShowMetricsWindow(false)}
				onAdd={onAdd}
				accountId={props.accountId}
				singleMetricSelection={props.type === 'metric-single-graph' || props.type === 'single_graph' || props.singleMetricSelection}
				defaultSelectedMetricIds={props.plainSelectedIds}
			/>
		}
	</CollapsibleFrame>;

}

function withStore(WrappedComponent) {
	function WithStoreComponent(props) {
		const [type] = useStore(["type"]);
		const [selectedMetrics, setSelectedMetrics] = useStore(["configuration", "metricsItems"]);
		const [selectedIds, setIds] = useStore(["configuration", "metrics"]);
		const [accountId] = useStore(["configuration", "accountId"]);

		const onAdd = useCallback((items) => {
			async function fetchSelectedItems() {
				const result = await CoommonWidgetsApi.fetchSelectedMetrics(accountId, items);
				setSelectedMetrics(fromJS(result.items));
				setIds(result.items.map((metric) => metric.metricId));
			};

			if (items.length || type !== 'metric-single-graph') {
				fetchSelectedItems();
			} else {
				setSelectedMetrics(fromJS([]));
			}

		});

		const metricInputText = selectedMetrics?.toJS().map(x => x.agentName).join(', ');
		const plainSelectedIds = React.useMemo(() => {
			if (selectedMetrics) {
				return selectedMetrics.toJS().map((metric) => metric.metricId)
			}
		}, [selectedMetrics]);

		return <WrappedComponent accountId={accountId} onAdd={onAdd} type={type} plainSelectedIds={plainSelectedIds}
		                         text={metricInputText}/>
	}

	return WithStoreComponent;
}
const MetricInlineSelectorStore = withStore(MetricInlineSelector)
export {MetricInlineSelectorStore}



function withState(WrappedComponent) {
	function WithStateComponent(props) {
		const [metricInputText, setMetricInputText] = useState(props.selectedMetrics?.map(x => x.agentName).join(', '));
		const onAdd = useCallback((items) => {
			async function fetchSelectedItems() {
				const result = await CoommonWidgetsApi.fetchSelectedMetrics(props.accountId, items);
				setMetricInputText(result.items.map(x => x.agentName).join(', '))
				if (props.onAdd)
					props.onAdd(result.items);
			};

			if (items.length || type !== 'metric-single-graph') {
				fetchSelectedItems();
			} else {
				setSelectedMetrics(fromJS([]));
			}
		});


		const plainSelectedIds = React.useMemo(() => {
			if (props.selectedMetrics) {
				return props.selectedMetrics.map((metric) => metric.metricId)
			}
		}, [props.selectedMetrics]);
		return <WrappedComponent accountId={props.accountId} onAdd={onAdd} type={props.type}
		                         plainSelectedIds={plainSelectedIds} text={metricInputText}
		                         singleMetricSelection={true}/>
	}

	return WithStateComponent;
}
const MetricInlineSelectorState = withState(MetricInlineSelector)
export {MetricInlineSelectorState}
