import React from 'react';
import PropTypes from 'prop-types';
import {Toolbar, ToolbarItemPosition} from "controls/react/layout/toolbar";
import {b} from "controls/react/form/formBuilder/subforms/fields/ceInterval/common";
import {Button} from "controls/react/form/button";
import Lang from "core/localization/lang";
import Window from "controls/react/kendoWrappers/window";
import Schedule from "./schedule";

function ScheduleWindow(props) {
	const [rule, setRule] = React.useState(props.rule);
	const [valid, setValid] = React.useState(false);

	const onSave = React.useCallback(() => {
		props.onSave(rule);
	}, [rule]);

	const stopPropagation = React.useCallback((e) => {
		e.stopPropagation();
	}, [])

	return <div onClick={stopPropagation}>
		<Window modal={true} onClose={props.onCancel} width={'570px'}>
			<div className={b('window-inner')}>
				<Schedule rule={props.rule} onChange={setRule} onValidate={setValid} timezone={props.timezone} />
			</div>

			<Toolbar containerClass={b('toolbar')}>
				<Button title={Lang.UPDATE}
					primary={true}
					disabled={!valid}
					onClick={onSave}
					position={ToolbarItemPosition.AT_THE_END} />
				<Button title={Lang.CANCEL}
					primary={false}
					onClick={props.onCancel}
					position={ToolbarItemPosition.AT_THE_END} />
			</Toolbar>
		</Window>
	</div>
}

ScheduleWindow.propTypes = {
	rule: PropTypes.string.isRequired,
	onSave: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	timezone: PropTypes.string.isRequired
}

export default ScheduleWindow;
