export function helperRowSelections(grid, props) {
	const wrapper = grid.wrapper;

	wrapper.on('change', (e) => {
		const $target = $(e.target);

		if ($target.hasClass('cw_grid_check')) {
			const ids = [];
			const items = [];

			Array.from(wrapper.find('.cw_grid_check:checked')).forEach((checkbox) => {
				const data = grid.dataItem($(checkbox).closest('tr'));

				ids.push(data[props.selectionField]);
				items.push(data.toJSON());
			});

			grid.trigger("custom:rowsSelected", {ids, items});
		}
	})
}
