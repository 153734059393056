import {i} from 'core/localization';



export default {
	getSelectorColumn(guid){
		return {
			field: 'id',
			title: i('Selector'),
			width: 40,
			editable: false,
			sortable: false,
			filterable: false,
			template: item => `<input type="checkbox" class="cw_grid_check" data-id="${item.id}" data-guid="${guid}" />`,
			headerTemplate: `<input type="checkbox" class="cw_grid_check_all" data-guid="${guid}"/>`,
			attributes: {
				'class': 'text_center'
			},
			headerAttributes: {
				'class': 'text_center'
			}
		};
	},

	initDropDownColumn(column, dictionary){
		if(!(dictionary instanceof Function)){
			const dictionaryResult = dictionary;
			dictionary = () => dictionaryResult;
		}

		column.template = item => {
			let value = item[column.field];
			if (value != null) {
				value = value.toUpperCase();

				let entry = dictionary(item)?.find(i => i.value.toUpperCase() == value);
				return entry && entry.text || '';
			}
			else {
				return '';
			}
		};

		column.editor = function (container, options) {
			const input = $('<input data-text-field="text" data-value-field="value"/>');
			input.appendTo(container);
			let ddl = input.kendoDropDownList({
				value: options.model[column.field],
				optionLabel: i('Select'),
				dataSource: dictionary(options.model),
				dataTextField: 'text',
				dataValueField: 'value',
				width: column.width && column.width - 10 || null,
				change: function (e) {
					const item = e.sender.dataItem();
					options.model.set(column.field, item.value);
				}
			});
		}
		return column;
	}
}
