import Utils from 'tools/utils';
import Cookies from 'core/cookies';
import Widget from 'areas/service-boards/widget';
import Settings from 'settings';
import RemoteEventsManager from 'core/remoteEventsManager';
import Renderer from 'tools/renderer';
import State from 'tools/state';
import GridSearch from 'controls/gridSearch';
import AssetHealthReasons from 'areas/assets/assetHealthReasons';
import {getSeverityState} from 'controls/stateRenderer/severityState';
import {getGridStateForSaving, updateHiddenColumns} from "controls/react/kendoWrappers/grid";
import MultiSelectGridFilter from "../../../../controls/multiSelectGridFilter";
import Lang from "core/localization/lang";

export function ReasonsWidget(config) {
	Widget.call(this, config);

	this.requestPath = Settings.serverPath;
	if (this.sessionId) {
		this.requestPath = Settings.serverPath + 'sessions/' + this.sessionId + '/';
	}
	/*
	 * @property hasConfiguration - set to true to import widget specific configuration into serviceboard
	 */
	this.hasConfiguration = true;
	this.instanceConfiguration.includeSubaccounts = this.instanceConfiguration.includeSubaccounts || false;

};

export {ReasonsWidget as default}

jQuery.extend(ReasonsWidget.prototype, Widget.prototype, {
	/**
	 * Main init function
	 */
	init: function () {
		var widgetContentDiv = $('#' + this.id).find('.cw_section_content');
		widgetContentDiv.parent().addClass('cw_section_reasonsgrid');
		this.gridId = Utils.guid();
		widgetContentDiv.empty().append('<div id="' + this.gridId + '" ></div>');
		this.gridMessages = {
			isTrue: '<span class="glyphicons service_state ok-sign"></span>',
			isFalse: '<span class="glyphicons service_state remove-sign"></span>',
			clear: lang.CLEAR,
			info: lang.grid.filter.SHOW_ITEMS,
			filter: lang.FILTER
		};
		this.currentAccountsList = [];
		this.isEventDriven = false;
		this.initKendoComponents();
		this.subscribe();
		this.updateTitle();
		$(window).off('resize', $.proxy(this.onResize, this));
		$(window).on('resize', $.proxy(this.onResize, this));

		this.attachListeners();
	},

	attachListeners: function () {
		if (State.currentApp?.dashboardDesigner?.props?.mode !== 'designer') {
			$('#' + this.gridId).on('click', '.cw_asset_link', $.proxy(this.onAssetNameClick, this));
			$('#' + this.gridId).on('click', '.cw_agent_name', $.proxy(this.onAgentNameClick, this));
		}
	},

	initKendoComponents: function () {
		var filterMessages = lang.grid.filter;
		var url = this.requestPath + 'accounts/' + this.instanceConfiguration.accountId + '/health/assetReasons?includeSubaccounts=' + this.instanceConfiguration.includeSubaccounts.toString();

		var gridSort = this.instanceConfiguration.sort || this.persistedState?.sort || [{
			field: 'timestamp',
			dir: 'desc',
			compare: null
		}];
		var gridFilter = this.instanceConfiguration.filter || this.persistedState?.filter;
		var savedColumns = updateHiddenColumns(this.persistedState?.columns, this.instanceConfiguration.columns);

		this.dataSource = new kendo.ceeview.DataSource({
			transport: {
				read: {
					url: url,
					contentType: "application/json; charset=utf-8",
					type: "POST",
					dataType: "json",
					cache: false
				},

				parameterMap: (data, type) => {
					data.showUntagged = this.configuration.showUntagged;
					data.tags = this.configuration.tags;
					if (this.configuration.assets) {
						data.assetIds = this.configuration.assets;
					}
					if (this.configuration.assetGroups) {
						data.assetGroupIds = this.configuration.assetGroups;
					}
					if (this.configuration.monitors) {
						data.monitorIds = this.configuration.monitors;
					}

					return kendo.stringify(data);
				}
			},
			schema: {
				parse: response => {
					this.filterOptions = response.filterOptions;
					return response;
				},
				data: function (response) {
					var response = response.items;
					if (response.length) {
						for (var i = 0, length = response.length; i < length; i++) {
							response[i].timestamp = new Date(response[i].timestamp);
							if (response[i].assignedToType === 'ASSIGN_TEAM') {
								response[i].assignedToGlyph = 'parents';
								if (response[i].assignedTo) {
									response[i].assignedToMouseover = lang.TEAM + ': ' + response[i].assignedTo;
								} else {
									response[i].assignedToMouseover = lang.TEAM;
								}
							} else if (response[i].assignedToType === 'ASSIGN_USER') {
								response[i].assignedToGlyph = 'user';
								if (response[i].assignedTo) {
									response[i].assignedToMouseover = lang.USER + ': ' + response[i].assignedTo;
								} else {
									response[i].assignedToMouseover = lang.USER;
								}
							} else {
								response[i].assignedToGlyph = '';
								response[i].assignedToMouseover = '';
							}
							if (response[i].acknowledgedByName) {
								response[i].acknowledgedMouseover = lang.ACKNOWLEDGED_BY + ' ' + response[i].acknowledgedByName;
							} else {
								response[i].acknowledgedMouseover = '';
							}
						}
					}
					return response;
				},
				total: function (response) {
					this.visibleItems = response.visible;
					this.totalItems = response.total;
					return response.visible;
				},
				model: {
					id: 'id',
					fields: {
						id: {
							type: 'string',
							editable: false
						},
						severity: {
							type: 'string',
							editable: false
						},
						assetName: {
							type: 'string',
							editable: false
						},
						accountName: {
							type: 'string',
							editable: false
						},
						agentName: {
							type: 'string',
							editable: false
						},
						timestamp: {
							type: 'date',
							editable: false
						},
						text: {
							type: 'string',
							editable: false
						},
						action: {
							nullable: true,
							editable: true
						},
						acknowledged: {
							type: 'boolean',
							editable: false
						},
						acknowledgedByName: {
							type: 'string',
							editable: false
						},
						/*acknowledgedAt: {
						 type: 'date',
						 editable: false
						 },*/
						assignedToName: {
							type: 'string',
							editable: false
						},
						monitorName: {
							type: 'string',
							editable: false
						},
						monitorTypeText: {
							type: 'string',
							editable: false
						},
						subsystem: {
							type: 'string',
							editable: false
						},
						identifierName: {
							type: 'string',
							editable: false
						},
						identifierInstance: {
							type: 'string',
							editable: false
						},
						information: {
							type: 'string',
							editable: false
						}
					}
				}
			},
			sort: gridSort,
			filter: gridFilter,
			change: $.proxy(function (e) {
				var sort, gridHandler = $('#' + this.gridId);
				if (e.items.length === 0) {
					if (gridHandler.find('.status').length) {
						gridHandler.find('.status').remove();
					}
				} else {
					if (gridHandler.find('.status').length) {
						gridHandler.find('.status').remove();
					}
					gridHandler.find('.k-grid-content').removeClass('hide');
				}
			}, this),
			serverSorting: true,
			serverPaging: true,
			serverFiltering: true
		});

		kendo.ui.progress($('#' + this.gridId), true);

		var ahReasonsColumns = this.instanceConfiguration.columns || {
			severityIndex: {
				hidden: false
			},
			assetName: {
				hidden: false
			},
			accountName: {
				hidden: false
			},
			timestamp: {
				hidden: false
			},
			text: {
				hidden: false
			},
			acknowledged: {
				hidden: false
			},
			monitorName: {
				hidden: false
			},
			subsystem: {
				hidden: false
			}
		};
		if (!ahReasonsColumns.severityIndex) {
			ahReasonsColumns.severityIndex = {
				hidden: false
			};
		}
		let scope = this;
		this.grid = $('#' + this.gridId).kendoCustomGrid({
			reorderable: true,
			dataSource: this.dataSource,
			resizable: true,
			scrollable: true,
			selectable: 'row',
			sortable: {
				mode: 'multiple',
				allowUnsort: true
			},
			height: this.isDashboardMode() ? '100%' : null,
			filterable: {
				extra: false,
				operators: {
					string: {
						startswith: filterMessages.STARTS_WITH,
						neq: filterMessages.NEQ,
						eq: filterMessages.EQ,
						contains: filterMessages.CONTAINS
					},
					number: {
						gte: filterMessages.GTE,
						gt: filterMessages.GT,
						lte: filterMessages.LTE,
						lt: filterMessages.LT
					},
					date: {
						gte: filterMessages.IAE,
						gt: filterMessages.IA,

						lte: filterMessages.IBE,
						lt: filterMessages.IB
					}
				}
				//,messages: this.gridMessages
			},
			columns: Utils.rearrangeColumns([{
				field: 'severityIndex',
				title: lang.STATE,
				sortable: {
					compare: $.proxy(function (a, b) {
						return Utils.customCompare(a, b, 'severityIndex', 6, this.dataSource.sortNow);
					}, this)
				},
				// filterable: {
				// 	ui: $.proxy(this.getFilterableForSeverity, {scope: this, field: 'severityIndex'}),
				// 	messages: this.gridMessages
				// },
				filterable: {
					extra: false,
					operators: {
						string: {
							eq: Lang.grid.filter.ISIN,
							neq: Lang.grid.filter.ISNOTIN
						}
					},
					ui: (element) => {
						let multiselect = new MultiSelectGridFilter({
							element: element,
							field: 'severityIndex',
							grid: this.grid,
							dataSource: new kendo.data.DataSource({data: [{
									text: lang.CRITICAL,
									icon: '<span class="cw_status_indicator cw_es_indicator cw_status_widget_color is_critical"></span>',
									value: '3'
								}, {
									text: lang.MAJOR,
									icon: '<span class="cw_status_indicator cw_es_indicator cw_status_widget_color is_major"></span>',
									value: '2'
								}, {
									text: lang.MINOR,
									color: 'is_minor',
									icon: '<span class="cw_status_indicator cw_es_indicator cw_status_widget_color is_minor"></span>',
									value: '1'
								}, {
									text: lang.OK,
									icon: '<span class="cw_status_indicator cw_es_indicator cw_status_widget_color is_ok"></span>',
									value: '0'
								}], serverSorting: true})
						});
					}
				},
				//template: '#=Renderer.severity(severity)#',
				template: item => getSeverityState(item.severity),
				hidden: ahReasonsColumns.severityIndex ? ahReasonsColumns.severityIndex.hidden : false,
				width: 120
			}, {
				field: 'assetName',
				title: 'Asset Name',
				sortable: true,
				filterable: {
					messages: this.gridMessages
				},
				attributes: {
					'class': "tooltip ellipsis to_expand cw_grid_link cw_asset_link"
				},
				hidden: ahReasonsColumns.assetName.hidden,
				width: 240
			}, {
				field: 'accountName',
				title: lang.ACCOUNT,
				sortable: true,
				filterable: {
					ui: function (element) {
						new MultiSelectGridFilter({
							element: element,
							field: 'accountName',
							grid: scope.grid,
							itemTemplate: '#=data.text#',
							tagTemplate: '#=data.text#',
							dataSource: scope.filterOptions['accountName']
						});
					},
					messages: this.gridMessages,
					extra: false,
					operators: {
						string: {
							neq: filterMessages.NEQ,
							eq: filterMessages.EQ
						}
					}
				},
				hidden: ahReasonsColumns.accountName.hidden,
				width: 150,attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'agentName',
				title: lang.AGENT,
				template: item => {
					return `<span data-agentid="${item.agentId}" class="${Utils.canEditAgent(item.agentId) ? 'cw_grid_link cw_agent_name' : ''}">${item.agentName}</span>`
				},
				sortable: true,
				filterable: {
					ui: function (element) {
						new MultiSelectGridFilter({
							element: element,
							field: 'agentName',
							grid: scope.grid,
							itemTemplate: '#=data.text#',
							tagTemplate: '#=data.text#',
							dataSource: scope.filterOptions['agentName']
						});
					},
					messages: this.gridMessages,
					extra: false,
					operators: {
						string: {
							neq: filterMessages.NEQ,
							eq: filterMessages.EQ
						}
					}
				},
				hidden: ahReasonsColumns.agentName ? ahReasonsColumns.agentName.hidden : false,
				width: 150,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'timestamp',
				title: lang.TIME,
				sortable: true,
				filterable: {
					extra: true,
					ui: function (el) {
						el.kendoDateTimePicker({
							format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
							timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat)
						});
					},
					messages: this.gridMessages
				},
				template: '#=Renderer.browserDateRenderer(timestamp, "datetime")#',
				hidden: ahReasonsColumns.timestamp.hidden,
				width: 200,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'text',
				title: lang.REASON,
				sortable: true,
				menu: false,
				filterable: true,
				attributes: {
					"class": "expand ellipsis"
				},
				headerAttributes: {
					'class': 'mw100'
				},
				hidden: ahReasonsColumns.text.hidden,
				width: 200,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'action',
				title: lang.ACTION,
				sortable: false,
				menu: false,
				filterable: false,
				template: '#= monitorType === "WINSERVICE" ? "' + lang.SELECT + '" : ""#',
				editor: $.proxy(function(container, options) {
					if (options.model.monitorType === 'WINSERVICE') {
						options.model.action = undefined;
						var input = $('<input type="text" />');
						input.attr('name', options.field);
						input.appendTo(container);
						input.kendoDropDownList({
							optionLabel: {
								text: lang.SELECT_ACTION,
								value: ''
							},
							dataSource: [{
								text: lang.service.START_SERVICE,
								value: 'start'
							}, {
								text: lang.service.STOP_SERVICE,
								value: 'stop'
							}],
							dataTextField: 'text',
							dataValueField: 'value',
							change: $.proxy(function(e) {
								this.sendServiceAction(e, container);
							}, this)
						});
						input.select(0);
					}
				}, this),
				hidden: ahReasonsColumns.action ? ahReasonsColumns.action.hidden : false,
				width: 60,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'acknowledged',
				title: lang.service.ACKNOWLEDGED,
				sortable: true,
				filterable: {
					messages: {
						isTrue: lang.YES,
						isFalse: lang.NO,
						clear: lang.CLEAR,
						info: lang.grid.filter.SHOW_ITEMS,
						filter: lang.FILTER
					}
				},
				template: '#= acknowledged ? "' + lang.YES + '" : ""#',
				hidden: ahReasonsColumns.acknowledged.hidden,
				width: 220,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'acknowledgedByName',
				title: lang.service.ACKNOWLEDGED_BY,
				sortable: true,
				menu: false,
				filterable: true,
				template: '#=acknowledgedByName ? acknowledgedByName : "" #',
				hidden: ahReasonsColumns.acknowledgedByName ? ahReasonsColumns.acknowledgedByName.hidden : false,
				width: 150,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'acknowledgedAt',
				title: lang.incidents.TIME_OF_FLAG,
				sortable: true,
				menu: false,
				filterable: false,
				template: '#=Renderer.browserDateRenderer(acknowledgedAt, Constants.DATETIME)#',
				hidden: ahReasonsColumns.acknowledgedAt ? ahReasonsColumns.acknowledgedAt.hidden : false,
				width: 150,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'assignedToName',
				title: lang.ASSIGNED_TO,
				sortable: true,
				menu: false,
				filterable: true,
				template: '#=assignedToName ? assignedToName : "" #',
				hidden: ahReasonsColumns.assignedToName ? ahReasonsColumns.assignedToName.hidden : false,
				width: 150,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'assignedToType',
				title: lang.ASSIGNED_TO_TYPE,
				sortable: true,
				filterable: {
					operators: {
						string: {
							eq: filterMessages.EQ,
							neq: filterMessages.NEQ
						}
					},
					ui: function (element) {
						var multiselect = new MultiSelectGridFilter({
							element: element,
							field: 'assignedToType',
							grid: scope.grid,
							dataSource: [{
								text: lang.NONE,
								icon: '<span class="glyphicons"></span>',
								value: 'ASSIGN_NONE'
							}, {
								text: lang.USER,
								icon: '<span class="glyphicons user"></span>',
								value: 'ASSIGN_USER'
							}, {
								text: lang.TEAM,
								icon: '<span class="glyphicons parents"></span>',
								value: 'ASSIGN_TEAM'
							}]
						});
					}
				},
				template: '<span class="glyphicons #=assignedToGlyph#" title="#=assignedToMouseover#"></span>',
				attributes: {
					'class': 'tooltip ellipsis to_expand text_center'
				},
				hidden: ahReasonsColumns.assignedToType ? ahReasonsColumns.assignedToType.hidden : false,
				width: 150
			}, {
				field: 'information',
				title: lang.INFORMATION,
				sortable: true,
				filterable: true,
				template: '#=information ? information : "" #',
				hidden: ahReasonsColumns.information ? ahReasonsColumns.information.hidden : false,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				},
				width: 200
			}, {
				field: 'monitorName',
				title: lang.MONITOR,
				sortable: true,
				menu: true,
				filterable: false,
				headerAttributes: {
					'class': 'mw100'
				},
				hidden: ahReasonsColumns.monitorName.hidden,
				width: 150,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'monitorTypeText',
				title: lang.assethealth.MONITOR_TYPE,
				sortable: true,
				filterable: {
					ui: function (element) {
						new MultiSelectGridFilter({
							element: element,
							field: 'monitorTypeText',
							grid: scope.grid,
							itemTemplate: '#=data.text#',
							tagTemplate: '#=data.text#',
							dataSource: scope.filterOptions['monitorTypeText']
						});
					},
					messages: this.gridMessages,
					extra: false,
					operators: {
						string: {
							neq: filterMessages.NEQ,
							eq: filterMessages.EQ
						}
					}
				},
				hidden: ahReasonsColumns.monitorTypeText ? ahReasonsColumns.monitorTypeText.hidden : false,
				width: 100,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'subsystem',
				title: lang.assethealth.SUBSYSTEM,
				sortable: true,
				filterable: true,
				hidden: ahReasonsColumns.subsystem.hidden,
				width: 120,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'identifierName',
				title: lang.assethealth.IDENTIFIER_NAME,
				sortable: true,
				filterable: true,
				hidden: ahReasonsColumns.identifierName ? ahReasonsColumns.identifierName.hidden : false,
				width: 150,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'identifierInstance',
				title: lang.assethealth.IDENTIFIER_INSTANCE,
				sortable: true,
				filterable: true,
				hidden: ahReasonsColumns.identifierInstance ? ahReasonsColumns.identifierInstance.hidden : false,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}], savedColumns),
			columnMenu: true,
			scrollable: {
				virtual: true
			},
			change: $.proxy(this.onRowExpand, this),
			dataBound: $.proxy(this.onReasonsDataBound, this)
		}).data('kendoCustomGrid');
		// Add Kendo tooltip to the header of the columns
		Utils.gridColumnHeaderTooltip(this.grid);
		//this.grid.thead.find("[data-field='description']>.k-header-column-menu").remove();
		var searchId = $('#' + this.id).find('.cw_search_box').attr('id');
		this.gridSearch = new GridSearch({
			id: searchId,
			dataSource: this.dataSource,
			fields: ['assetName', 'accountName', 'text', 'monitorName', 'subsystem']
		});

		if (ahReasonsColumns.accountName.hidden) {
			this.grid.hideColumn('accountName');
		}
	},
	/**
	 * Handler function for the change(select) event on the grid
	 * @param {Object} e The change event object
	 */
	onRowExpand: function (e) {
		var selectedRow = this.grid.select();
		var myRow = selectedRow[0];
		var messageEl = $(myRow).find('.to_expand');
		if ($(messageEl).hasClass('cw_message_expanded')) {
			$(messageEl).removeClass('cw_message_expanded').addClass('ellipsis');
		} else {
			$('#' + this.gridId).find('.k-grid-content').find('td.cw_message_expanded').removeClass('cw_message_expanded').addClass('ellipsis');
			$(messageEl).addClass('cw_message_expanded').removeClass('ellipsis');
		}
	},
	/**
	 * Handler function for the Reasons grid data bound event
	 */
	onReasonsDataBound: function () {
		$('#' + this.gridId).find('.k-grid-content').kendoTooltip({
			filter: "td.tooltip:not(:empty)",
			content: function (event) {
				return event.target.text().split("\n").join("<br />");
			}
		});
		setTimeout(() => this.adjustSectionHeight(this.id), 200);
		kendo.ui.progress($('#' + this.gridId), false);
	},

	onEvent: function () {
		this.isEventDriven = true;
		this.refresh();
	},
	/**
	 * Updates the widget title
	 */
	updateTitle: function () {
		if (!this.title) {
			var titleSpan = this.isKendoWindow ? $('#' + this.id).closest('.k-window').find('.k-window-title') : $('#' + this.id).find('.cw_section_title');
			titleSpan.html(lang.REASONS);
		}
	},
	/**
	 * Triggered after widget resize
	 * @param {Object} event The resize event
	 * @param {Object} ui The UI element - see http://api.jqueryui.com/resizable/
	 */
	onResize: function (event, ui) {
		// TODO the resize functionality
		this.adjustSectionHeight(this.id);
	},

	onAssetNameClick(e){
		AssetHealthReasons.prototype.onAssetNameClick.call(this, e);
	},

	onAgentNameClick(e){

	},

	refresh: function () {
		this.grid.dataSource.transport.options.read.url = this.requestPath + 'accounts/' + this.instanceConfiguration.accountId + '/health/assetReasons?includeSubaccounts=' + this.instanceConfiguration.includeSubaccounts.toString();
		this.grid.dataSource.read();
	},
	/**
	 * Method by Andy
	 */
	adjustSectionHeight: function (id) {
		let heightAdjust;
		if (this.isDashboardMode() && !this.isWithoutHeader()) {
			heightAdjust = 70;
		} else {
			heightAdjust = 30;
		}
		let section = $('#' + id);
		let sectionHeight = section.height();
		let contentHeight = sectionHeight - heightAdjust;
		section.find('.cw_section_content').css('height', sectionHeight);
		section.find('.k-grid-content').css('height', contentHeight);

		let shownContent = section.find('.k-virtual-scrollable-wrap');
		let contentWidth = section.find('.k-grid-content').width();
		let actualContentHeight = section.find('.k-grid-content table').height();
		let shownContentHeight = shownContent.height();
		if (actualContentHeight < shownContentHeight) {
			section.find('.k-scrollbar-vertical').addClass('hide');
			shownContent.css('width', contentWidth + 18 + 'px');
		} else {
			section.find('.k-scrollbar-vertical').removeClass('hide');
		}
	},
	/**
	 * Subscribes to the events
	 */
	subscribe: function () {
		this.isDataSourceSubscribed = true;
		var subscriptionObj = [{
			eventType: 'AssetHealth',
			accountId: this.instanceConfiguration.accountId,
			includeSubaccounts: this.instanceConfiguration.includeSubaccounts
		}, {
			eventType: 'Administration',
			entityType: 'ACCOUNT',
			accountId: this.instanceConfiguration.accountId
		}];
		this.subscriberId = this.id;
		RemoteEventsManager.subscribe(this.subscriberId, subscriptionObj);
	},
	/**
	 * Destroy
	 */
	destroy: function () {
		$('#' + this.gridId).off();
		$(window).off('resize', $.proxy(this.onResize, this));
		Widget.prototype.destroy.call(this);
	},

	getStateForSaving(){
		return getGridStateForSaving(this.grid);
	}
});
