export default function defaultColumn(showSubAccounts) {
	return {
		id: {
			hidden: false,
			width: 40
		},
		severity: {
			hidden: false,
			width: 60
		},
		historic: {
			hidden: false,
			width: 100
		},
		assignedToType: {
			hidden: false,
			width: 140
		},
		acknowledged: {
			hidden: false,
			width: 160
		},
		targetName: {
			hidden: false,
			width: 200
		},
		agentName: {
			hidden: false,
			width: 200
		},
		accountName: {
			hidden: !showSubAccounts,
			width: 150
		},
		type: {
			hidden: false,
			width: 140
		},
		time: {
			hidden: false,
			width: 140
		},
		timeDeleted: {
			hidden: true,
			width: 140
		},
		message: {
			hidden: false,
			width: 280
		},
		sourceName: {
			hidden: false,
			width: 200
		},
		tags: {
			hidden: false,
			width: 200
		},
		suppressionCount: {
			hidden: false,
			width: 100
		},
		actionCount: {
			hidden: false,
			width: 160
		},
		acknowledgedBy: {
			hidden: false,
			width: 160
		},
		acknowledgedAt: {
			hidden: false,
			width: 160
		},
		information: {
			hidden: false,
			width: 200
		},
		inMaintenance: {
			hidden: false,
			width: 160
		},
		agentId: {
			hidden: true,
			width: 120
		},
		externalId: {
			hidden: true,
			width: 120
		},
		externalOwner: {
			hidden: true,
			width: 120
		},
		identifierInstance: {
			hidden: true,
			width: 120
		},
		identifierName: {
			hidden: true,
			width: 120
		},
		monitorTypeText: {
			hidden: false,
			width: 120
		},
		monitorClassText: {
			hidden: true,
			width: 120
		},
		subsystem: {
			hidden: true,
			width: 120
		},
		sourceId: {
			hidden: true,
			width: 120
		},
		targetId: {
			hidden: true,
			width: 120
		},
		assignedTo: {
			hidden: false,
			width: 140
		},
		details: {
			hidden: false,
			width: 200
		},
		timeCreated: {
			hidden: false,
			width: 140
		},
		assignedTime: {
			hidden: false,
			width: 200
		},
		groups: {
			hidden: false,
			width: 140
		}
	}
}
