import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';

window.Highcharts = Highcharts;

import HighchartsMore from 'highcharts/highcharts-more';
import Bellcurve from 'highcharts/modules/histogram-bellcurve';
import Bullet from 'highcharts/modules/bullet';
import DependencyWheel from 'highcharts/modules/dependency-wheel';
import Highcharts3d from 'highcharts/highcharts-3d';
import Cylinder from 'highcharts/modules/cylinder';
import Dumbbell from 'highcharts/modules/dumbbell';
import Heatmap from 'highcharts/modules/heatmap';
import ItemSeries from 'highcharts/modules/item-series';
import Lollipop from 'highcharts/modules/lollipop';
import Funnel from 'highcharts/modules/funnel';
import Funnel3d from 'highcharts/modules/funnel3d';
import NetworkGraph from 'highcharts/modules/networkgraph';
import Organization from 'highcharts/modules/organization';
import Pyramid3d from 'highcharts/modules/pyramid3d';
import Sankey from 'highcharts/modules/sankey';
import SolidGauge from 'highcharts/modules/solid-gauge';
import StreamGraph from 'highcharts/modules/streamgraph';
import Sunburst from 'highcharts/modules/sunburst';
import Tilemap from 'highcharts/modules/tilemap';
import Timeline from 'highcharts/modules/timeline';
import TreeMap from 'highcharts/modules/treemap';
import VariablePie from 'highcharts/modules/variable-pie';
import Variwide from 'highcharts/modules/variwide';
import Vector from 'highcharts/modules/vector';
import Venn from 'highcharts/modules/venn';
import Windbarb from 'highcharts/modules/windbarb';
import Wordcloud from 'highcharts/modules/wordcloud';
import Xrange from 'highcharts/modules/xrange';

import moment from 'moment';
import getChartData from 'areas/service-boards/widgets/customMetrics/getChartData';
import PeriodMultiToggle from 'controls/react/form/periodMultiToggle';
import "./customMetrics.less";
import tooltipFormatter from './defaultTooltip';
import { addTooltipSeries, addTooltipMetric } from 'areas/service-boards/widgets/customMetrics/chartDataStore';
import { compileName } from 'areas/service-boards/widgets/stackChartWidget/helpers';
import { Utils } from 'tools';

import classnames from "classnames";

import _ from 'lodash';
import {RemoteEventsManager} from 'core';

Highcharts3d(Highcharts);
HighchartsMore(Highcharts);
Bellcurve(Highcharts);
Bullet(Highcharts);
Cylinder(Highcharts);
Sankey(Highcharts);
DependencyWheel(Highcharts);
Dumbbell(Highcharts);
Heatmap(Highcharts);
ItemSeries(Highcharts);
Lollipop(Highcharts);
Funnel(Highcharts);
Funnel3d(Highcharts);
NetworkGraph(Highcharts);
Organization(Highcharts);
Pyramid3d(Highcharts);
Sankey(Highcharts);
SolidGauge(Highcharts);
StreamGraph(Highcharts);
Sunburst(Highcharts);
Tilemap(Highcharts);
Timeline(Highcharts);
TreeMap(Highcharts);
VariablePie(Highcharts);
Variwide(Highcharts);
Vector(Highcharts);
Venn(Highcharts);
Windbarb(Highcharts);
Wordcloud(Highcharts);
Xrange(Highcharts);

function CustomMetricTitle(props) {
	return <div className={classnames("react-widget-title", "custom-metric-widget-title")}>
		{!props.skipTitle && <div className={'title-text'}>{props.title}</div>}
		{!props.hideTimeSelector && <PeriodMultiToggle
			defaultValue={props.period}
			onChange={props.onChange} />}
	</div>;
}

export default function CustomMetricWidget(props) {
	const config = React.useMemo(() => {
		return JSON.parse(JSON.stringify(props.config.configuration));
	}, [props.config.configuration]);

	const containerRef = React.useRef();
	const [period, setPeriod] = React.useState({
		period: config.period
	});

	const [chartData, setChartData] = React.useState([]);

	const onEventRef = React.useRef();
	onEventRef.current = React.useCallback((events) => {
		for (const event of events) {
			if (config.chartType === "pie") {
				const data = highchartRef.current.series[0].data;
				const index = data.findIndex((item) => item.id === event.qualifierId)

				if (index >= 0) {
					const {id, color, name} = data[index];

					data.splice(index, 1, {
						id,
						color,
						name,
						y: Utils.aggregate(config.aggregationType, event.metric)
					})
					try {
						highchartRef.current.series[0].setData([...data]);
					} catch(e) {
						console.log('error', [...data]);
					}
				}
			}
			else {
				const series = highchartRef.current.series.find((series) => series.userOptions.id === event.qualifierId);
				if (series) {
					let point = [event.metric.t, Utils.aggregate(config.aggregationType, event.metric)];
					addTooltipMetric(series.userOptions.id, point)
					series.addPoint(point, true);
				}
			}
		}
	}, [config]);

	const subscribeIdRef = React.useRef();

	const subscribeRef = React.useRef();
	subscribeRef.current = React.useCallback(() => {
		subscribeIdRef.current = props.config.id;

		RemoteEventsManager.subscribe(subscribeIdRef.current, [{
			eventType: 'MultipleMetric',
			metricIds: config.metricsItems.map( x => x.metricId)
		}]);
	}, [props.config, config]);

	const releaseEventsRef = React.useRef();
	releaseEventsRef.current = React.useCallback((chartDataArray) => {
		const recalculateIntervals = function() {
			if (!chartDataArray.length || !chartDataArray[0]?.data.length) {
				return;
			}

			return chartDataArray.reduce((acc, data) => {
				if (!data.qualifier.metricId) {
					return acc;
				}
				const { interval } = Utils.getPeriodInterval({
					period: period.period,
					startDate: period.startDate,
					endDate: period.endDate,
					width: $(containerRef.current).width()
				}, data.data[0]);
				acc[data.qualifier.metricId] = interval;
				return acc
			}, {});
		};

		if (subscribeIdRef.current) {
			let intervals = recalculateIntervals();
			if (!intervals) {
				const {interval} = Utils.getPeriodInterval({
					period: period.period,
					startDate: period.startDate,
					endDate: period.endDate,
					width: $(containerRef.current).width(),
				});

				const metricIds = config.metricsItems.map((item) => item.metricId);

				intervals = metricIds.reduce((acc, item) => {
					acc[item] = interval;
					return acc;
				}, {});
			}
			RemoteEventsManager.releaseEvents(subscribeIdRef.current, {
				intervals: intervals
			});
		}
	}, [config, period]);

	const unsubscribe = React.useCallback(() => {
		if (subscribeIdRef.current) {
			RemoteEventsManager.unsubscribe(subscribeIdRef.current);
			subscribeIdRef.current = null;
		}
	}, []);

	React.useEffect(() => {
		unsubscribe();
		subscribeRef.current();
	}, [config])

	React.useEffect(() => {
		return () => {
			unsubscribe();
		}
	}, [unsubscribe]);

	React.useEffect(() => {
		props.events.bind('events', (events) => onEventRef.current(events));
	}, [])

	const handlePeriodChange = React.useCallback((period, startDate, endDate) => {
		setPeriod({
			period,
			startDate: +startDate,
			endDate: +endDate
		});
	}, [])

	React.useEffect(() => {
		return () => {
			props.unsetTitle();
		}
	}, []);

	React.useEffect(() => {
		props.setTitle(<CustomMetricTitle title={props.config.title} onChange={handlePeriodChange} period={period} hideTimeSelector={config.hideTimeSelector} />);
	}, [props.setTitle, props.config.title, config])

	React.useEffect(() => {
		if (!config.timezone) {
			return;
		}

		Highcharts.setOptions({
			global: {
				useUTC: true,
				timezoneOffset: -moment.tz(config.timezone).utcOffset()
			}
		});
	}, [config.timezone])

	const highchartRef = React.useRef();

	const handleContainer = React.useCallback(async (node) => {
		containerRef.current = node;

		if (!node || highchartRef.current) {
			return
		}

		const yAxisUnit = config.metricsItems[0]?.unitTypeSymbol ? ` (${config.metricsItems[0]?.unitTypeSymbol})`: ''

		let chartConfig = {
			title: {
				text: undefined
			},
			exporting: {
				enabled: false
			},
			xAxis: {
				title: {
					text: 'Time'
				},
			},
			yAxis: {
				title: {
					text: 'Unit' + yAxisUnit
				},
			},
		}

		if (config.chartType !== "pie") {
			chartConfig.tooltip = {
				formatter: tooltipFormatter
			}
		}

		chartConfig = _.merge(chartConfig, JSON.parse(config.highchartConfig || "{}"));
		chartConfig = _.merge(chartConfig,{
			chart: {
				type: config.chartType,
			},
			credits: {
				enabled: false
			}
		});

		if (config.chartType === "pie") {
			// chartConfig.plotOptions = {
			// 	pie: {
			// 		dataLabels: {
			// 			enabled: false
			// 		}
			// 	}
			// };

			chartConfig.series = chartConfig.series || [];
			const pieConfig = chartConfig.series[0].data ? chartConfig.series: {
				data: []
			};

			chartConfig.series = [pieConfig];
		}
		else {
			chartConfig.series = chartConfig.series || [];

			for (const metric of config.metricsItems) {
				const found = chartConfig.series.find((series) => series.id === metric.metricId);

				if (found) {
					found.decimals = metric.decimals;
					continue;
				}

				const name = compileName(config.labelTemplate, metric);

				chartConfig.series.push({
					id: metric.metricId,
					color: metric.color,
					unitTypeSymbol: metric.unitTypeSymbol,
					customUnit: metric.customUnit,
					name,
					showInLegend: name !== ""
				})
			}
		}

		highchartRef.current = Highcharts.chart(node, chartConfig);
	}, [config]);

	React.useEffect(() => {
		const fetch = async () => {
			if (!containerRef.current) {
				return;
			}

			const data = await getChartData({
				accountId: config.accountId,
				ignoreMissingData: config.ignoreMissingData,
				timezone: config.timezone,
				period: period.period,
				startDate: period.startDate || config.startDate,
				endDate: period.endDate || config.endDate,
				metricsItems: config.metricsItems,
				width: $(containerRef.current).width(),
				useRawData: config.useRawData
			});

			if (!Array.isArray(data)) {
				return;
			}

			setChartData(data);

			if (config.chartType === "pie") {
				const seriesData = [];

				const highchartConfig = JSON.parse(config.highchartConfig || "{}");

				for (const item of data) {
					if (item.data.length === 0) {
						continue;
					}

					const found = highchartConfig.series[0].data.find((seriesData) => seriesData.id === item.qualifier.metricId);

					if (found) {
						seriesData.push({
							...found,
							y: Utils.aggregate(config.aggregationType, item.data[item.data.length - 1])
						})
					} else {
						seriesData.push({
							id: item.qualifier.metricId,
							name: compileName(config.labelTemplate, item.qualifier),
							y: Utils.aggregate(config.aggregationType, item.data[item.data.length - 1])
						})
					}
				}

				highchartRef.current.series[0].setData(seriesData);
			} else {
				for (const series of highchartRef.current.series) {
					const metricData = data.find((item) => item.qualifier.metricId === series.userOptions.id);

					if (metricData) {
						const data = metricData.data.map((item) => [item.t, Utils.aggregate(config.aggregationType, item)]);
						addTooltipSeries(series.userOptions.id, data)
						series.setData(data);
					}
				}
			}
		}

		fetch();
	}, [period]);

	React.useEffect(() => {
		releaseEventsRef.current(chartData);
	}, [chartData]);

	return <div ref={handleContainer} style={{width: '100%', height: '100%'}}>
	</div>
}

CustomMetricWidget.propTypes = {
	config: PropTypes.shape({
		configuration: PropTypes.shape({
			accountId: PropTypes.string.isRequired,
			chartType: PropTypes.string.isRequired,
			highchartConfig: PropTypes.object.isRequired,
			metricsItems: PropTypes.array.isRequired,
			ignoreMissingData: PropTypes.bool.isRequired
		}).isRequired,
		timezone: PropTypes.string.isRequired
	}).isRequired,

};
