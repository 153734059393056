import CustomNotification from 'controls/customNotification';
import GridMenu from 'controls/gridMenu';
import MultiToggle from 'controls/multiToggle';
import MaintenancePeriod from 'controls/maintenancePeriod';
import Settings from 'settings';
import PreferencesPanel from 'controls/preferencesPanel'
import ErrorHandler from 'core/errorHandler';
import LocalEventsManager from 'core/localEventsManager';
import {Cookies} from "core";
import {Renderer} from "tools";
import UserPrefs from 'tools/userPrefs';
import Utils from 'tools/utils';

/**
 * @class ceeview.management.agent.views.MaintenanceView
 * @namespace ceeview.management.agent.views
 * @constructor
 * This class is used for listing the maintenance periods for a given agent
 * @param {Object} config The config object
 */
export default function MaintenanceView (config) {
    Utils.apply(this, config);
    if ((!this.agentId && !this.assetId) || !this.accountId) {
        return;
    }
    if (this.agentId) {
        this.currentId = this.agentId;
        this.currentType = 'agent';
        this.currentUrlType = 'agents';
        this.roleType = 'AGENT';
    } else if (this.assetId) {
        this.currentId = this.assetId;
        this.currentType = 'asset';
        this.currentUrlType = 'assets';
        this.roleType = 'ASSET';
    }

    //this.currentId = this.agentId || this.assetId;
    this.initComponent();
};

MaintenanceView.prototype = {
    /**
     * @cfg {String} agentId The id of the agent
     */
    agentId: '',
    /**
     * @cfg {String} assetId The id of the asset
     */
    assetId: '',
    /**
     * @cfg {String} accountId The id of the account that contains the agent (mandatory)
     */
    accountId: '',
    /**
     * Main init method
     */
    initComponent: function () {
        this.loadUserPreferences();
        this.removeListeners();
        this.attachListeners();
        this.maintenanceNotification = new CustomNotification({
			animationTime: 3000,
            appendToElement: '#cw_maintenance_periods_placeholder',
            type: 'plain',
            style: 'top:40px; right:27px',
            hideOnClick: true
        });

        $('#cw_maintenance_grid_menu').empty();
        this.gridMenu = new GridMenu({
            renderTo: 'cw_maintenance_grid_menu',
            items: [{
                id: 'cw_maintenance_period_create',
                icon: 'plus-sign',
                text: lang.CREATE,
                role: this.roleType + '_MAINTENANCE_CREATE',
                fn: this.onPeriodCreate,
                scope: this
            }, {
                id: 'cw_maintenance_period_update',
                icon: 'pencil',
                text: lang.UPDATE,
                role: this.roleType + '_MAINTENANCE_UPDATE',
                fn: this.onPeriodUpdate,
                scope: this,
                disabled: true
            }, {
                id: 'cw_maintenance_period_delete',
                icon: 'bin',
                role: this.roleType + '_MAINTENANCE_DELETE',
                text: lang.END_MAINTENANCE,
                fn: this.onPeriodDelete,
                scope: this,
                disabled: true,
				deleteFromGrid: 'cw_maintenance_periods'
            }]
        });
    },
    /**
     * Attaches listeners
     */
    attachListeners: function () {
        LocalEventsManager.bind('maintenance_period_saved', $.proxy(this.onDataSaved, this));
        $('#cw_maintenance_periods').on('click', '.cw_grid_check', $.proxy(this.onCheck, this));
        $('#cw_maintenance_periods').on('click', '.cw_grid_check_all', $.proxy(this.onCheckAll, this));
        $('#cancel_maintenance').on('click', $.proxy(this.onMaintenanceCancel, this));
        LocalEventsManager.bind('cancelperiod', this.adjustMaintenanceGridHeight);
    },
    /**
     * Removes listeners
     */
    removeListeners: function () {
        LocalEventsManager.unbind('maintenance_period_saved');
        $('#cw_maintenance_periods').off();
    },
    /**
     * Initializes kendo components
     */
    initKendoComponents: function () {
		var defaultFsView = UserPrefs.get('defaultFsView');
        this.defaultColumns = {
            id: {
                hidden: false,
                width: 40
            },
			fromTime: {
				hidden: false,
				width: 180
			},
			toTime: {
				hidden: false,
				width: 180
			},
			createdBy: {
				hidden: false,
				width: 140
			},
			reason: {
				hidden: false,
				//width: 40
			}
        };

		let filter, sort, pref;
		if (Utils.isGuid(defaultFsView)) {
			pref = JSON.parse(UserPrefs.get(defaultFsView));
			pref = pref || {
					sort: [],
					filter: []
				};
			sort = pref.sort;
			filter = pref.filter;
			this.agentMaintenanceColumns = pref.columns;
		} else {
			if (UserPrefs.get('agentMaintenanceFilter')) {
				filter = JSON.parse(UserPrefs.get('agentMaintenanceFilter'));
			} else {
				filter = [];
			}
			if (UserPrefs.get('agentMaintenanceSort')) {
				sort = JSON.parse(UserPrefs.get('agentMaintenanceSort'));
			} else {
				sort = [{
					field: 'fromTime',
					dir: 'desc'
				}];
			}
		}

        var filterMessages = lang.grid.filter;
		var agentMaintenanceColumns = this.agentMaintenanceColumns || JSON.parse(UserPrefs.get('agentMaintenanceColumns')) || this.defaultColumns;
		agentMaintenanceColumns = Utils.completeColumns(agentMaintenanceColumns, this.defaultColumns);
        this.dataSource = new kendo.ceeview.DataSource({
            transport: {
                read: {
                    url: Settings.serverPath + 'accounts/' + this.accountId + '/' + this.currentUrlType + '/' + this.currentId + '/maintenance?showAllPeriods=true',
                    contentType: "application/json; charset=utf-8",
                    type: 'GET',
                    dataType: 'json',
                    cache: false
                }
            },
            schema: {
				model: {
					id: 'id',
					fields: {
						fromTime: {
							type: 'date'
						},
						toTime: {
							type: 'date'
						}
					}
				},
                parse: function (response) {
                    var now = Utils.timestamp();

                    for (var i = 0, length = response.length; i < length; i++) {
						response[i].fromTime = new Date(response[i].fromTime);
						response[i].toTime = new Date(response[i].toTime);
                    }
                    return response;
                }
            },
            error: ErrorHandler.kendoServerError,
            sort: sort,
            filter: filter
        });
        this.grid = $('#cw_maintenance_periods').kendoCustomGrid({
            dataSource: this.dataSource,
            resizable: true,
            selectable: 'row',
            filterable: {
                extra: false,
                operators: {
                    string: {
                        startswith: filterMessages.STARTS_WITH,
                        neq: filterMessages.NEQ,
                        eq: filterMessages.EQ
                    },
                    number: {
                        eq: filterMessages.EQ,
                        neq: filterMessages.NEQ,
                        gte: filterMessages.GTE,
                        gt: filterMessages.GT,
                        lte: filterMessages.LTE,
                        lt: filterMessages.LT
                    }
                },
                messages: this.gridMessages
            },
            sortable: {
                mode: 'multiple',
                allowUnsort: true
            },
            columnMenu: true,
            columns: [{
                field: 'id',
                title: lang.SELECTOR,
                menu: false,
                filterable: false,
                sortable: false,
                template: '<input type="checkbox" class="cw_grid_check" data-id="${id}" />',
                headerTemplate: '<input type="checkbox" class="cw_grid_check_all" />',
                attributes: {
                    'class': 'text_center'
                },
                headerAttributes: {
                    'class': 'text_center'
                },
				hidden: agentMaintenanceColumns.id.hidden,
				width: agentMaintenanceColumns.id.width
            }, {
                field: 'fromTime',
                title: lang.reports.DATE_FROM,
                sortable: true,
                filterable: true,
                template: (item) => {
                    return Renderer.browserDateRenderer(item.fromTime, "datetime", null, Cookies.CeesoftTimezone)
                },
				hidden: agentMaintenanceColumns.fromTime.hidden,
			    width: agentMaintenanceColumns.fromTime.width,
	            attributes: {
					'class': 'tooltip ellipsis to_expand edit_maintenance pointer'
	            }
            }, {
                field: 'toTime',
                title: lang.reports.DATE_TO,
                sortable: true,
                filterable: true,
                template:  (item) => {
	                return Renderer.browserDateRenderer(item.toTime, "datetime", null, Cookies.CeesoftTimezone)
                },
			    hidden: agentMaintenanceColumns.toTime.hidden,
				width: agentMaintenanceColumns.toTime.width,
	            attributes: {
					'class': 'tooltip ellipsis to_expand edit_maintenance pointer'
	            }
            }, {
                field: 'createdBy',
                title: lang.slas.CREATED_BY,
                template: '#= createdBy #',
				hidden: agentMaintenanceColumns.createdBy.hidden,
				width: agentMaintenanceColumns.createdBy.width,
	            attributes: {
					'class': 'tooltip ellipsis to_expand edit_maintenance pointer'
	            }
            }, {
                field: 'reason',
                title: lang.DESCRIPTION,
                sortable: true,
			    hidden: agentMaintenanceColumns.reason.hidden,
				width: agentMaintenanceColumns.reason.width,
	            attributes: {
					'class': 'tooltip ellipsis to_expand edit_maintenance pointer'
	            }
            }],
            change: $.proxy(this.onRowExpand, this),
            dataBound: $.proxy(function (e) {
	            $('#cw_maintenance_periods').find('.k-grid-content').kendoTooltip({
		            filter: "td.tooltip:not(:empty)",
		            content: function (event) {
			            return event.target.text().split("\n").join("<br />");
		            }
	            });

				if (State.mainApp.session.hasRole(this.roleType + '_MAINTENANCE_UPDATE')) {
					$('#cw_maintenance_periods .edit_maintenance').on('click', $.proxy(this.onPeriodUpdate, this));
				} else {
					$('#cw_maintenance_periods .edit_maintenance').removeClass('pointer')
				}
            }, this)
        }).data('kendoCustomGrid');

        // Add Kendo tooltip to the header of the columns
        Utils.gridColumnHeaderTooltip(this.grid);
        this.grid.thead.find("[data-field='id']>.k-header-column-menu").remove();
    },
    /**
     * Handler function for the change(select) event on the grid
     */
    onRowExpand: function () {
	    var selectedRow = this.grid.select();
	    var myRow = selectedRow[0];
	    var messageEl = $(myRow).find('.to_expand');
	    if ($(messageEl).hasClass('cw_message_expanded')) {
		    $(messageEl).removeClass('cw_message_expanded').addClass('ellipsis');
	    } else {
		    $('#cw_maintenance_periods').find('.k-grid-content').find('td.cw_message_expanded').removeClass('cw_message_expanded').addClass('ellipsis');
		    $(messageEl).addClass('cw_message_expanded').removeClass('ellipsis');
	    }
    },
	/**
	 * Saves user preferences
	 */
	saveUserPreferences: function () {
		this.saveUserPrefs({
			category: 'AgentMaintenance',
			filterPanel: this.gridFilterPanel,
			grid: this.grid,
			keys: {
				searchPhrase: 'agentMaintenanceSearchPhrase',
				columns: 'agentMaintenanceColumns',
				sort: 'agentMaintenanceSort',
				filter: 'agentMaintenanceFilter',
				FsViews: 'agentMaintenanceFSViews'
			}
		});
	},
	/**
	 * Loads user preferences
	 */
	loadUserPreferences: function () {
		this.userPref = [];
		UserPrefs.load('AgentMaintenance', $.proxy(function (result) {
			if (result.success) {
				this.userPref = result.data;
				this.initKendoComponents();
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},
    /**
     * Handler function for the click event on the create maintenance period menu item
     */
    onPeriodCreate: function () {
        var maintenancePeriod = new MaintenancePeriod({
            layout: 'popup',
            mode: 'create',
            type: this.currentType,
            entityId: this.currentId,
            accountId: this.accountId
        });
        this.adjustMaintenanceGridHeight('create');
    },
    /**
     * Handler function for the click event on the create maintenance period menu item
     */
	onPeriodUpdate: function (e) {
		var id;
		var target = $(e.target);
		if (target.hasClass('edit_maintenance')) {
			id = target.closest('tr').find('.cw_grid_check').attr('data-id');
		} else {
			id = $('#cw_maintenance_periods').find('.cw_grid_check:checked').attr('data-id');
		}
		var maintenancePeriod = new MaintenancePeriod({
			mode: 'update',
			id: id,
			type: this.currentType,
			entityId: this.currentId,
			accountId: this.accountId
		});
		this.adjustMaintenanceGridHeight('update');
	},
    /**
     * Handler function for the click event on the delete maintenance period menu item
     */
    onPeriodDelete: function () {
        var checkboxes = $('#cw_maintenance_periods').find('.cw_grid_check');
        var url = Settings.serverPath + 'accounts/' + this.accountId + '/' + this.currentUrlType + '/' + this.currentId + '/maintenance/delete';
        var maintenances = [];
        for (var i = 0, length = checkboxes.length; i < length; i++) {
            if ($(checkboxes[i]).is(':checked')) {
				maintenances.push($(checkboxes[i]).data('id'));
            }
        }
        if (maintenances.length) {
			let maintenancesToDelete = [];
			let historicPeriodSelected = false;
			for (let i = 0; i < maintenances.length; i++) {
				let item = this.dataSource.get(maintenances[i]);
				let toTime = item.toTime;
				if (toTime < new Date()) {
					//historic period. Do not add to delete array and show notification
					historicPeriodSelected = true;
				} else {
					maintenancesToDelete.push(maintenances[i])
				}
			}
            this.gridMenu.disableItem('cw_maintenance_period_update');
            this.gridMenu.disableItem('cw_maintenance_period_delete');
            Utils.ajax(url, 'POST', JSON.stringify(maintenancesToDelete), $.proxy(function (result) {
                if (result.success) {
                    // reload data source
                    this.dataSource.read();
                    this.maintenanceNotification.setOptions({
						message: historicPeriodSelected ? lang.service.messages.MAINTENANCES_NOT_HISTORIC_SUCCESS_ENDED : lang.service.messages.MAINTENANCES_SUCCESS_ENDED,
                        status: 'success'
                    }).show();
                } else {
                    Utils.showInfo(lang.ALERT, result.message, result.details, this.grid);
                }
            }, this));
        } else {
            this.maintenanceNotification.setOptions({
                message: lang.agents.messages.MAINTENANCES_NO_FUTURE_PERIODS,
                status: 'success'
            }).show();
        }
    },
    onMaintenanceCancel: function () {
        $('.cw_maintenance').addClass('is_hidden');
        this.adjustMaintenanceGridHeight();
    },
    /**
     * Handler function for the maintenance_saved event. Reloads the maintenance datasource
     */
    onDataSaved: function () {
        this.grid.dataSource.read();
    },
    /**
     * Handler function for the click event on maintenance row checkbox
     */
	onCheck: function () {
		var checkboxes = $('#cw_maintenance_periods').find('.cw_grid_check');
		var maintenances = [];
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			if ($(checkboxes[i]).is(':checked')) {
				maintenances.push(i);
			}
		}
		if (maintenances.length) {
			if (maintenances.length === 1) {
				this.gridMenu.enableItem('cw_maintenance_period_update');
			} else {
				this.gridMenu.disableItem('cw_maintenance_period_update');
			}
			this.gridMenu.enableItem('cw_maintenance_period_delete');
		} else {
			this.gridMenu.disableItem('cw_maintenance_period_update');
			this.gridMenu.disableItem('cw_maintenance_period_delete');
		}
	},
    /**
     * Handler function for the click event on maintenance check all checkbox
     */
    onCheckAll: function () {
        var checkAll = $('#cw_maintenance_periods').find('.cw_grid_check_all').is(':checked');
        var checkboxes = $('#cw_maintenance_periods').find('.cw_grid_check');
        var i, length = checkboxes.length;
        for (i = 0; i < length; i++) {
            $(checkboxes[i]).prop('checked', checkAll);
        }
        this.onCheck();
    },
    adjustMaintenanceGridHeight: function(mode) {
        var section = $('.cw_tabs_absolute');
        var sectionHeight = section.height(), height;
        if (mode === 'create') {
            height = sectionHeight - 42 - 180;
            section.find('#cw_maintenance_periods').css('margin-top', 180);
        } else {
            height = sectionHeight - 42;
            section.find('#cw_maintenance_periods').attr('style', '');
        }
        section.find('#cw_maintenance_periods .cw_section_content').css('height', height - 42);
        section.find('#cw_maintenance_periods .k-grid-content').css('height', height - 42 - 29);
    }
};
