import React from "react";

import DropDownList from "controls/react/kendoWrappers/dropDownList";
import {CeeViewDataSource} from "tools/ceeViewDataSource";

import {AssetGroupAPI} from "tools/apis/assetGroupAPI";

import {ErrorHandler} from "core/errorHandler";
import {translator} from "core/localization";
import withRequired from 'controls/react/dropdowns/withRequired';

const i = translator({
  "Select asset...": {
    "no": "Velg ressurs..."
  }
});

export class AssetGroupMembersDropDown extends React.PureComponent {
	constructor(props) {
		super(props);


		this.config = {
			dataSource: this.getDataSource(props.assetGroupId),
			autoBind: this.props.assetGroupId != null,
			dataTextField: 'name',
			dataValueField: 'id',
			suggest: true,
			placeholder: i('Select asset...'),
			delay: 500
		};
	}

	getDataSource(assetGroupId) {
		if (!assetGroupId) {
			return [];
		}

		return new CeeViewDataSource({
			transport: {
				read: {
					url: this.props.isAgent ? AssetGroupAPI.getAgentMembersUrl(assetGroupId) : AssetGroupAPI.getMembersUrl(assetGroupId),
					contentType: 'application/json; charset=utf-8',
					type: 'GET',
					dataType: 'json',
					cache: false
				}
			},
			error: ErrorHandler.kendoServerError
		});
	}

	componentDidUpdate(prevProps) {
		if (this.props.assetGroupId !== prevProps.assetGroupId) {
			this.kendoRef.setDataSource(this.getDataSource(this.props.assetGroupId))
		}
	}

	render() {
		return <DropDownList config={this.config}
		                     kendoRef={(ref) => this.kendoRef = ref}
		                     combo={true}
		                     invalid={this.props.invalid}
		                     {...this.props}/>
	}
}

export default withRequired(AssetGroupMembersDropDown);
