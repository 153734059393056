import Utils from 'tools/utils';
import Application from 'core/application';
import GridMenu from 'controls/gridMenu';
import State from 'tools/state';
import Settings from 'settings';
import MessageCenterForm from 'areas/views/messageCenterForm'
import ModalWindow from 'controls/modalWindow';
import Dialog from 'controls/dialog';


export default function MessageCenter(config) {
	Utils.apply(this.config);
	this.module = {
		name: 'MessageCenter',
		initialConfig: config
	};
	this.initComponent();
};

jQuery.extend(MessageCenter.prototype, Application.prototype, {
	/**
	 * Main init function
	 */
	initComponent: function () {
		Application.prototype.initComponent.call(this);
		//update view based on roles
		if (!State.mainApp.session.hasRole('NOTIFICATION_SEND')) {
			$('#cw_compose_message').remove();
		}
		this.initKendoComponents();
		this.removeListeners();
		this.attachListeners();
	},
	/**
	 * Initializes Kendo UI components
	 */
	initKendoComponents: function () {
		this.messagesDataSource = new kendo.ceeview.DataSource({
			sort: {
				field: 'time',
				dir: 'desc'
			},
			transport: {
				read: {
					url: Settings.serverPath + 'notifications/messages?unreadOnly=false',
					contentType: "application/json; charset=utf-8",
					type: "GET",
					dataType: "json",
					cache: false
				}
			},
			pageSize: 100
		});
		this.messagesGrid = $('.cw_messages_list').kendoCustomGrid({
			autoBind: true,
			dataSource: this.messagesDataSource,
			resizable: true,
			pageable: true,
			rowTemplate: '<tr #if (!read){# class="cw_msg_unread"#}# data-uid="#= notificationId #"><td><input type="checkbox" class="cw_grid_check" data-id="#= notificationId #" /></td><td class="expand_message">#= Renderer.notificationsImportant(important)#</td><td class="cw_grid_link expand_message">#= message#</td><td class="expand_message" title="#= Renderer.browserDateRenderer(time, Constants.DATETIME)#">#= Renderer.browserDateRenderer(time, Constants.DATETIME)#</td><td class="expand_message">#= description#</td></tr>',
			selectable: 'row',
			sortable: {
				mode: 'single',
				allowUnsort: true
			},
			columns: [{
				field: 'notificationId',
				title: lang.SELECTOR,
				sortable: false,
				filterable: false,
				menu: false,
				headerTemplate: '<input type="checkbox" class="cw_grid_check_all" />',
				attributes: {
					'class': 'text_center'
				},
				headerAttributes: {
					'class': 'text_center'
				},
				width: 40
			}, {
				field: 'important',
				headerTemplate: '<span class="cw_important_message glyphicons exclamation-sign"></span>',
				sortable: true,
				width: 50,
				attributes: {
					"class": "text_center"
				}
			}, {
				field: 'message',
				title: lang.service.MESSAGE,
				sortable: true,
				attributes: {
					"class": "expand ellipsis"
				}
			}, {
				field: 'time',
				title: lang.service.TIME,
				sortable: true,
				width: 180
			}, {
				field: 'description',
				title: lang.DESCRIPTION,
				sortable: true,
				attributes: {
					"class": "expand ellipsis"
				}
			}],
			dataBound: $.proxy(this.onMessagesDataBound, this),
			change: $.proxy(this.onMessageExpand, this)
		}).data('kendoCustomGrid');
		// Add Kendo tooltip to the header of the columns
		Utils.gridColumnHeaderTooltip(this.messagesGrid);

		this.gridMenu = new GridMenu({
			renderTo: 'cw_ms_center_menu',
			items: [{
				id: 'cw_compose',
				icon: 'message-plus',
				text: lang.mscenter.COMPOSE,
				fn: () => this.toggleComposeForm("create"),
				scope: this,
				role: 'NOTIFICATION_SEND',
				disabled: State.mainApp.session.hasRole('NOTIFICATION_SEND') && (State.mainApp.session.hasRole('USER_LIST') || State.mainApp.session.hasRole('TEAM_LIST')) ? false : true
			}, {
				id: 'cw_read',
				icon: 'check',
				text: lang.MARK_READ,
				fn: this.onMarkAsRead,
				scope: this,
				disabled: true,
			}, {
				id: 'cw_delete',
				icon: 'bin',
				text: lang.DELETE,
				fn: this.onMessageDelete,
				scope: this,
				disabled: true,
				deleteFromGrid: 'cw_messages_list'
			}]
		});
		this.removeMask();
	},
	/**
	 * Attaches listeners
	 */
	attachListeners: function () {
		$('.cw_messages_list').on('click', '.cw_message_mark', $.proxy(this.onMessageMark, this));
		$('.cw_messages_list').on('click', '.cw_grid_check', $.proxy(this.onMessageCheck, this));
		$('.cw_messages_list').on('click', '.cw_grid_check_all', $.proxy(this.onMessageCheckAll, this));
		$('.cw_messages_list').on('click', ".expand_message" , $.proxy(this.onMessageClick, this));
	},
	/**
	 * Removes listeners
	 */
	removeListeners: function () {
		$('#cw_compose_message').off();
		$('#cw_close_message_form').off();
		$('#close_modal').off();
		$('#cw_mark_all').off();
		$('.cw_messages_list').off();
	},
	/**
	 * Handler function for the click event on the "Mark message" toggle
	 * @param {Object} e The click event object
	 */
	onMessageMark: function (e) {
		e.stopPropagation();
		var mark = $(e.currentTarget);
		if (mark.hasClass('check')) {
			mark.removeClass('check').addClass('unchecked');
		} else {
			mark.removeClass('unchecked').addClass('check');
		}
		var notificationId = mark.attr('data-uid');
		if (mark.hasClass('check')) {
			var url = Settings.serverPath + 'notifications/messages/markRead';
			Utils.ajax(url, 'POST', JSON.stringify([notificationId]), $.proxy(function (result) {
				if (result.success) {
					mark.closest('tr').removeClass('cw_msg_unread');
					State.mainApp.getStatusesCount();
					this.messagesDataSource.read();
				}
				else {
					Utils.showInfo(lang.ALERT, result.message, result.details);
				}
			}, this));
		}
		else {
			//TODO: mark as unread on the server
			mark.closest('tr').addClass('cw_msg_unread');
		}
	},
	/**
	 * Handler function for the click event on the delete message icon
	 * @param {Object} e The click event object
	 */
	onMessageDelete: function (e) {
		var notificationId = $(e.currentTarget).closest('tr').attr('data-uid');
		var url = Settings.serverPath + 'notifications/' + notificationId + '/?notificationType=MESSAGE';
		Utils.ajax(url, 'DELETE', {}, $.proxy(function (result) {
			if (result.success) {
				this.messagesDataSource.read();
				State.mainApp.getStatusesCount();
				this.messagesDataSource.read();
			}
			else {
				Utils.showInfo(lang.ALERT, result.message, result.details, this.messagesGrid);
			}
		}, this));
	},
	/**
	 * Handler function for the click event on the message
	 */
	onMessageClick: function(e)
	{
		e.stopPropagation();
		let currentTarget = $(e.currentTarget);
		let dataItem = this.messagesGrid.dataItem(currentTarget.closest("tr"));
		this.toggleComposeForm("view", dataItem);
		
		var url = Settings.serverPath + 'notifications/messages/markRead';
		let id = [dataItem.notificationId];
		Utils.ajax(url, 'POST', JSON.stringify(id), $.proxy(function (result) {
			if (result.success) {
				State.mainApp.getStatusesCount();
				this.messagesDataSource.read();
			}
			else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},
	/**
	 * Handler function for the click event on the Compose button
	 */
	toggleComposeForm: function (mode, dataItem) {
		var modalWindow;
		modalWindow = new ModalWindow({
			title: lang.mscenter.COMPOSE,
			width: 800,
			minWidth: 580,
			height: 'auto',
			minHeight: 350,
			url: 'include/MessageCenterCreate.jsp',
			resize: function () {
				var texareaHeight = $("textarea").height();
				texareaHeight += $("#modal").height() - $("#cw_compose_form").height() - 25;
				$('textarea#cw_message_body').css('height', texareaHeight);
			},
			refresh: function () {
				new MessageCenterForm({mode: mode, dataItem: dataItem});
			}
		});
		modalWindow.open();
	},
	/**
	 * Handler function for the Messages grid data bound event
	 */
	onMessagesDataBound: function (e) {
		var grid = this.messagesGrid;
		var dataSource = this.messagesDataSource;
		var pagerElement = grid.pager.element;
		var pagerHeight = pagerElement.height();

		this.adjustSectionHeight();
		if (this.notificationId) {
			var gridElement = $('.cw_messages_list');
			var grid1 = gridElement.data('kendoCustomGrid');
			var row = gridElement.find('tr[data-uid="' + this.notificationId + '"]');
			grid1.select(row);
		}

		if (grid.dataSource.totalPages() <= 1) {
			pagerElement.children().not('span').hide();
			pagerElement.height(pagerHeight);
		}
		else {
			pagerElement.children().show();
		}
	},
	/**
	 * Handler function for the selection of one messages grid row
	 */
	onMessageExpand: function (e) {
		var selectedMessages = $('.cw_messages_list').data('kendoCustomGrid').select();
		var myMessageRow = selectedMessages[0];
		var message = $(myMessageRow).children('td:nth-child(2)');

		if ($(message).hasClass('cw_message_expanded')) {
			$(messageEl).removeClass('cw_message_expanded').addClass('ellipsis');
		}
		else {
			$('.cw_messages_list').find('.k-grid-content').find('td.cw_message_expanded').removeClass('cw_message_expanded');
			$(messageEl).addClass('cw_message_expanded').removeClass('ellipsis');
		}
	},
	/**
	 * Handler function for the click event on the Mark as read button (messages)
	 * @param {Object} e The click event object
	 */
	onMarkAsRead: function (e) {
		var dataSource = this.messagesDataSource;
		var url = Settings.serverPath + 'notifications/messages/markRead';
		var notificationsList = [];
		var checkboxes = $('.cw_grid_check');
		var i, length = checkboxes.length;
		for (i = 0; i < length; i++) {
			if ($(checkboxes[i]).is(':checked')) {
				var id = $(checkboxes[i]).attr('data-id');
				notificationsList.push(id);
			}
		}
		Utils.ajax(url, 'POST', JSON.stringify(notificationsList), $.proxy(function (result) {
			if (result.success) {
				State.mainApp.getStatusesCount();
				this.messagesDataSource.read();
				$('.cw_grid_check_all').prop('checked', false);
				this.gridMenu.disableItem('cw_delete');
				this.gridMenu.disableItem('cw_read');
			}
			else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},
	/**
	 * Handler for the service checkbox click
	 * @param {Object} e The click event
	 */
	onMessageCheck: function (e) {
		var checkboxes = $('.cw_grid_check');
		var count = 0;
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			if ($(checkboxes[i]).is(':checked')) {
				count++;
			}
		}
		if (count) {
			if(State.mainApp.session.hasRole('MESSAGE_DELETE')) {
				this.gridMenu.enableItem('cw_delete');
			}
			if(State.mainApp.session.hasRole('MESSAGE_UPDATE')) {
				this.gridMenu.enableItem('cw_read');
			}
		} else {
			this.gridMenu.disableItem('cw_delete');
			this.gridMenu.disableItem('cw_read');
		}
	},
	/**
	 * Handler for the check all checkbox click
	 * @param {Object} e The click event
	 */
	onMessageCheckAll: function (e) {
		var checkAll = $('.cw_grid_check_all').is(':checked');
		var checkboxes = $('.cw_grid_check');
		var i, length = checkboxes.length;
		for (i = 0; i < length; i++) {
			$(checkboxes[i]).prop('checked', checkAll);
		}
		if (checkAll && checkboxes.length) {
			this.gridMenu.enableItem('cw_delete');
			this.gridMenu.enableItem('cw_read');
		} else {
			this.gridMenu.disableItem('cw_delete');
			this.gridMenu.disableItem('cw_read');
		}
	},
	/**
	 * Handler for the delete button click
	 * @param {Object} e The click event
	 */
	onMessageDelete: function (e) {
		if (!$(e.currentTarget).hasClass('disabled')) {
			var url = Settings.serverPath + 'notifications/types/MESSAGE/delete';
			var checkboxes = $('.cw_grid_check');
			var i, length = checkboxes.length, haveMessages = false;
			var ids = [];
			for (i = 0; i < length; i++) {
				if ($(checkboxes[i]).is(':checked')) {
					var id = $(checkboxes[i]).attr('data-id');
					ids.push(id);
					haveMessages = true;
				}
			}
			url += '';

			if (haveMessages) {
				var dialog = new Dialog({
					title: lang.INFO,
					msg: lang.mscenter.messages.MESSAGE_REMOVE_CONFIRMATION,
					icon: 'ERROR',
					actionText: 'DELETE',
					fn: $.proxy(function (value, button) {
						if (button === 'ok') {
							this.gridMenu.disableItem('cw_delete');
							Utils.ajax(url, 'POST', JSON.stringify(ids), $.proxy(function (result) {
								if (result.success) {
									var status = $('.status');
									status.find('p').addClass('success');
									status.find('p').text(lang.mscenter.messages.MESSAGE_SUCCESS_DELETED);
									status.slideDown().delay(2000).slideUp();
									// reload data source
									this.messagesDataSource.read();
									State.mainApp.getStatusesCount();
									this.gridMenu.disableItem('cw_delete');
									this.gridMenu.disableItem('cw_read');
									$('#cw_delete').addClass('disabled');
									$('.cw_grid_check_all').prop('checked', false);
								} else {
									Utils.showInfo(lang.ALERT, result.message, result.details);
								}
							}, this));
						}
					}, this)
				});
				dialog.show();
			}
		}
	},
	/**
	 * Method by Andy
	 */
	adjustSectionHeight: function () {
		var section = $('.cw_section_full');
		var sectionHeight = section.height();
		section.find('.cw_section_content').css('height', sectionHeight);
		section.find('.k-grid-content').css('height', sectionHeight - 40 - 35);
	}
});

