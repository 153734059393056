import {translator} from '../../core';

export const costTranslates = {
	'Cost': {no: 'Kostnad'},
	'Budget': {no: 'Budsjett'},
	'Expand': {no: 'Ekspander'},
	'Collapse': {no: 'Kollaps'},
	'Cost profile': {no: 'Kostnadsprofil'},
	'all': {no: 'alle'},
	'Split': {no: 'Splitt'},
	'Total year': {no: 'Total år'},
	'Import external cost data': {no: 'Importer kostdata'},
	'No Cost Profile is created for this link. It might be from an Cost collector without a Cost Profile.': {
		no: 'Det er ingen kostprofil opprettet for denne linken. Den kan komme fra en Kost collector uten Kost profil.'
	},
	'Single delete confirmation': {
		'en': 'Are you sure you want to remove this cost?',
		'no': 'Vil du fjerne denne kostnad?'
	},
	'Multiple delete confirmation': {
		'en': 'Are you sure you want to remove these costs?',
		'no': 'Vil du fjerne disse kostnad'
	},
	'Link Cost Profile row': {no: 'Lenk kostprofilrad'},
	'Hierarchy Cost Link': {no: 'Hierarki Kost Link'},
	'Configuration': {no: 'Konfigurasjon'},
	'Show cost': {
		no: 'Vis kostnad'
	},
	'Show budget': {
		no: 'Vis budsjett'
	},
	'Creates a link to a row in another Cost Profile. Note that only the cost and name is linked. Budget is set per Cost Profile.': {
		no: 'Oppretter en link til en rad i en annen kostprofil. Merk at bare kost og navn er lenket. Budsjett styres per kostprofile.'
	},
	'The Cost Item link will include its sub tree of Cost Items if any. Note that this can only be used if created on the first level of a Cost Profile.': {
		no: 'Kost linken vil inkludere sub treet til valgt Kost Element. Dette kan kun gjøres fra nivå 1 i Kost Profilen.'
	},
	'Start date': {
		no: 'Start dato'
	},
	'Currency': {
		no: 'Valuta'
	},
	'Show estimates': {
		no: 'Vis estimater'
	},
	'Year': {
		no: 'År'
	},
	'Year estimate': {
		no: 'Årsestimat'
	},
	'Current month': {
		no: 'Nåværende måned'
	},
	'Current month estimate': {
		no: 'Estimat nåværende måned'
	},
	"Month order": {no: "Måned rekkefølge"},
	"Current month first": {no: "Nåværende måned først"},
	"Asc": {},
	"Desc": {},
	"Cost Model": {no: "Kostnad Modell"},
	"Cost Store": {no: "Kostnad Base"},
	"Cost Store/Model": {no: "Kostnad Base/Modell"},
	"Cost Sheet": {no: "Kostnad Ark"},
	"Dirty confirmation": {
		"en": "You have unsaved content in the Cost Profile. You will lose the changes if you click Close. Click Cancel and save the changes if you want to keep them.",
		"no": "Du har ulagret innhold i Kostnadsprofilen. Du vil miste endringene hvis du klikker Lukk. Klikk på Avbryt og lagre endringene hvis du vil beholde dem."
	},
	'Costprofile': {
		no: 'Kostnadsprofil'
	},
	'Display Decimals': {no: 'Vis desimaler'},
	'Display as 1000': {no: 'Vises som 1000'},
	'Costmodel settings': {},
	'Costprofile settings': {},
	"Please select": {
		"no": "Velg"
	},
	'Costmodel': {no: 'Kostmodell'},
	'Budget period': {'no': 'Budsjettperiode'},
	'Link name': {no: 'Lenkenavn'},
	'Only Costp Pofiles with same currency can be selected due to issues with currency conversion.': {
		no: 'Bare Kostprofiler med samme valuta kan velges grunnet problemer med valutakonvertering.'
	},
	'Cost report metric': {},
	'Metric registry': {},
	'The link\'s cost profile is deleted. Please remove this link.': {no: 'Lenken\'s kostprofil er slettet. Fjern denne lenken.'},
	'DELETED': {},
	'Numerics': {},
	"Display settings": {"no": "Visningsinnstillinger"},
	'Columns': {'no': 'Kolonner'},
	'Estimate': {},
	'Event summary': {'no': 'Hendelseoversikt'},
	'Metric': {no: 'Metrikk'},
	'Expression': {no: 'Expression'},
	'Link': {no: 'Link'},
	'Name exists': {
		'en': 'An identical name exist on this account, please use another name.',
		'no': 'Et identisk navn finnes på denne kontoen, bruk et annet navn.'
	},
	'Listing price': {
		no: 'Listepris'
	},
	'ListingPrice': {
		en: 'Listing price',
		no: 'Listepris'
	}
};
export const i = translator(costTranslates);
