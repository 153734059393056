import {Utils, applyRequired} from 'tools/utils';
import Settings from 'settings';

import ErrorHandler from 'core/errorHandler';
import ConfigurationBase from 'areas/service-boards/configurationBase';


export function HistoryWidgetConfiguration(config) {
	Utils.apply(this, config);
	this.initComponent();
};

export {HistoryWidgetConfiguration as default};

jQuery.extend(HistoryWidgetConfiguration.prototype, ConfigurationBase.prototype, {
	/**
	 * @cfg {Object} widgetObj
	 */
	/**
	 * Main init function
	 */
	initComponent: function () {
		var html;
		var widgetObj = this.widgetObj;

		html = '<div class="cw_field">';
		html += '<label class="cw_inline">' + lang.TITLE + ': </label>';
		html += '<input type="text" class="cw_widget_title cw_inline" value="' + (widgetObj.title || '') + '" />';
		html += '</div>';
		html += '<div class="cw_field" id="cw_sla_history_account">';
		html += '<label class="cw_inline">' + lang.ACCOUNT + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_sla_history_accountid" type="text" value="' + (widgetObj.configuration.accountId || '') + '" /></div>';
		html += '</div>';
		html += '<div class="cw_field" id="cw_sla_history_service">';
		html += '<label class="cw_inline">' + lang.widget.SERVICE + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_sla_history_serviceid" type="text" value="' + (widgetObj.configuration.serviceId || '') + '"/></div>';
		html += '</div>';
		html += '<div class="cw_field" id="cw_sla_history_sla">';
		html += '<label class="cw_inline">' + lang.widget.SLA + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_sla_history_slaid" type="text" value="' + (widgetObj.configuration.slaId || '') + '" /></div>';
		html += '</div>';
		html += '<div class="cw_field">';
		html += '<label class="cw_inline">' + lang.widget.PERIODS + ': </label>';
		html += '<input id="cw_sla_history_periods" type="text" value="' + (widgetObj.configuration.periods || '') + '"/>';
		html += '</div>';

		this.widgetConfigurationFormEl.empty().append(html);

		this.initKendoComponents();

		const requiredFields = ['#cw_sla_history_accountid', '#cw_sla_history_serviceid', '#cw_sla_history_slaid', '#cw_sla_history_periods'];
		applyRequired(requiredFields);
	},
	/**
	 * Removes listeners
	 */
	removeListeners: function () {

	},
	/**
	 * Attaches listeners
	 */
	attachListeners: function () {

	},
	/**
	 * Iniitalizes kendo components
	 */
	initKendoComponents: function () {
		var widgetObj = this.widgetObj;

		this.createAccountComponent({
			element: $('#cw_sla_history_accountid'),
			container: $('#cw_sla_history_account'),
			nextElement: $('#cw_sla_history_serviceid'),
			events: {
				dataSourceChange: $.proxy(function (e, length) {
					this.emptyComponent({
						containers: [{
							container: $('#cw_sla_history_service'),
							label: lang.widget.SERVICE
						}, {
							container: $('#cw_sla_history_sla'),
							label: lang.widget.SLA
						}],
						length: length
					});
				}, this)
			}
		});
		$('#cw_sla_history_serviceid').kendoComboBox({
			dataSource: new kendo.ceeview.DataSource({
				change: $.proxy(function (e) {
					this.emptyComponent({
						containers: [{
							container: $('#cw_sla_history_sla'),
							label: lang.widget.SLA
						}],
						length: e.items.length
					});
				}, this),
				transport: {
					read: {
						url: $.proxy(function () {
							return Settings.serverPath + 'accounts/' + (this.widgetAccountId || widgetObj.configuration.accountId) + '/services/lite?hasModel=true';
						}, this),
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				error: ErrorHandler.kendoServerError,
				sort: {field: 'name', dir: 'asc'}
			}),
			dataTextField: 'name',
			dataValueField: 'id',
			select: $.proxy(this.onSlaHistoryServiceSelect, this),
			template: '<span id="${ data.id }">${ data.name }</span>',
			autoBind: this.mode === true || this.wasUpdate,
			placeholder: lang.SELECT
		}).data('kendoComboBox');
		if (widgetObj.configuration.serviceId) {
			var slaDataSource = new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: Settings.serverPath + 'services/' + widgetObj.configuration.serviceId + '/slas/lite',
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				error: ErrorHandler.kendoServerError,
				sort: {field: 'name', dir: 'asc'}
			});
		}
		$('#cw_sla_history_slaid').kendoComboBox({
			dataSource: (this.mode === 'update' || this.wasUpdate) ? slaDataSource : [],
			dataTextField: 'name',
			dataValueField: 'id',
			enable: this.mode === 'update' || this.wasUpdate ? true : false,
			placeholder: lang.SELECT,
			autoBind: true,
			//change: $.proxy(this.onSlaHistorySlaChange, this)
		});
		$('#cw_sla_history_periods').kendoNumericTextBox({
			decimals: 0,
			format: 'n0',
			min: 1,
			//max: 12,
			spinners: false
		}).data('kendoNumericTextBox');
	},
	/*
	 * Handler function for getting the widget configuration values
	 * */
	getValues: function () {
		var widgetObj = {};

		var slaCombo = $('#cw_sla_history_slaid').data('kendoComboBox');
		var slaServiceCombo = $('#cw_sla_history_serviceid').data('kendoComboBox');
		var periodsField = $('#cw_sla_history_periods').data('kendoNumericTextBox');
		var slaName = slaCombo.text();
		var title = $('.cw_widget_title').val().trim();
		var serviceName = slaServiceCombo.text();

		widgetObj = {
			id: this.mode === 'update' || this.wasUpdate ? this.widgetObj.id : Utils.guid(),
			type: 'sla_history',
			title: title,
			configuration: {
				accountId: this.widgetAccountId || $('#cw_sla_history_accountid').data('kendoComboBox').value(),
				accountName: this.widgetAccountName,
				slaId: slaCombo.value(),
				serviceId: slaServiceCombo.value(),
				periods: periodsField.value(),
				slaName: slaName,
				serviceName: serviceName
			}
		};

		return widgetObj;
	},
	/*
	* Checks if form is valid
	* */
	isValid: function () {
		var valid = true;
		this.validationMessage = '';

		var accountCombo = $('#cw_sla_history_accountid').data('kendoComboBox');
		var slaCombo = $('#cw_sla_history_slaid').data('kendoComboBox');
		var sCombo = $('#cw_sla_history_serviceid').data('kendoComboBox');
		var periodsCombo = $('#cw_sla_history_periods').data('kendoNumericTextBox');
		var slaId = slaCombo.value();
		var slaType = slaCombo.dataSource.get(slaId).period.type;
		var periods = $('#cw_sla_history_periods').data('kendoNumericTextBox').value();
		if (!Utils.isGuid(accountCombo.value())) {
			valid = false;
			this.validationMessage = lang.widget.SELECT_ACCOUNT;
		}
		if (!Utils.isGuid(slaCombo.value())) {
			valid = false;
			this.validationMessage = lang.widget.SELECT_SLA;
		}
		if (!Utils.isGuid(sCombo.value())) {
			valid = false;
			this.validationMessage = lang.widget.SELECT_SERVICE_MODEL;
		}
		if (!periodsCombo.value()) {
			valid = false;
			this.validationMessage = lang.widget.messages.FILL_IN_NUMERIC_PERIODS;
		}
		switch (slaType) {
			case 'DAY':
				if (periods > 7) {
					valid = false;
					this.validationMessage = lang.widget.messages.MAXIMUM_PERIODS_NUMBER + 7;
				}
				break;
			case 'WEEK':
			case 'MONTH':
				if (periods > 12) {
					valid = false;
					this.validationMessage = lang.widget.messages.MAXIMUM_PERIODS_NUMBER + 12;
				}
				break;
		}

		return {
			valid: valid,
			validationMessage: this.validationMessage
		};
	},
	/**
	 * Handler function for the select event of the SLA history service dropdown list
	 * @param {Object} e The select event
	 */
	onSlaHistoryServiceSelect: function (e) {
		var serviceCombo = $("#cw_sla_history_serviceid").data('kendoComboBox');
		var slaCombo = $("#cw_sla_history_slaid").data('kendoComboBox');
		var serviceId = e.item.find('span').attr('id');
		if (serviceId) {
			slaCombo.enable(true);
			slaCombo.value('');
			var newSeDataSource = new kendo.ceeview.DataSource({
				change: $.proxy(function (e) {
					this.emptyComponent({
						containers: [{
							container: $('#cw_sla_history_sla'),
							label: lang.widget.SLA
						}],
						length: e.items.length
					});
				}, this),
				transport: {
					read: {
						url: Settings.serverPath + 'services/' + serviceId + '/slas/lite',
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				error: ErrorHandler.kendoServerError
			});
			slaCombo.setDataSource(newSeDataSource);
			slaCombo.dataSource.read();
		} else {
			slaCombo.text('');
			slaCombo.enable(false);
		}
	},
	/**
	 * Handler function for the change event of the SLA history SLA dropdown list
	 * @param {Object} e The select event
	 */
	onSlaHistorySlaChange: function (e) {
		var slaCombo = $("#cw_sla_history_slaid").data('kendoComboBox');
		var slaId = slaCombo.value();
		var slaType = slaCombo.dataSource.get(slaId).period.type;
		var periods = $('#cw_sla_history_periods').data('kendoNumericTextBox').value();
		switch (slaType) {
			case 'DAY':
				$('#cw_sla_history_periods').data('kendoNumericTextBox').max(7);
				if (periods > 7) {
					$('#cw_sla_history_periods').data('kendoNumericTextBox').value(7);
				}
				break;
			case 'WEEK':
				$('#cw_sla_history_periods').data('kendoNumericTextBox').max(12);
				break;
			case 'MONTH':
				$('#cw_sla_history_periods').data('kendoNumericTextBox').max(12);
				break;
		}
	}
});
