import produce from "immer"
import RRule from 'rrule';

export const SET_RULE = 'SET_RULE';
export const SET_FREQUENCY = 'SET_FREQUENCY';
export const SET_INTERVAL = 'SET_INTERVAL';
export const TOGGLE_DAY = 'TOGGLE_DAY';
export const SET_WEEK_DAY = 'SET_WEEK_DAY';
export const SET_START_TIME = 'SET_START_TIME';
export const SET_END_TIME = 'SET_END_TIME';
export const SET_BY_MONTH_DAY = 'SET_BY_MONTH_DAY';
export const SET_BY_POS = 'SET_BY_POS';
export const SET_BY_MONTH = 'SET_BY_MONTH';
export const RESET_MONTHLY_MODE = 'RESET_MONTHLY_MODE';
export const SET_ERROR = 'SET_ERROR';

export const MONTHLY_MODE = {
	ON_DAY: 'ON_DAY',
	ON_THE_DAY: 'ON_THE_DAY'
};

function toggleDay(draft, day) {
	draft.byweekday = draft.byweekday || [];

	const {byweekday} = draft;
	const index = byweekday.indexOf(day);

	if (index >= 0) {
		byweekday.splice(index, 1);
	}
	else {
		byweekday.push(day);
	}
}

function setEndTime(draft, value) {
	console.log('set end date', value)

	if (value === null) {
		delete draft.until;
	}
	else if (value) {
		draft.until = value;
	}
}

function changeFrequency(draft, value) {
	switch(value) {
		case RRule.SECONDLY:
			return (new RRule({ freq: RRule.SECONDLY, interval: 1, dtstart: draft.dtstart })).origOptions;
		case RRule.MINUTELY:
			return (new RRule({ freq: RRule.MINUTELY, interval: 1, dtstart: draft.dtstart })).origOptions;
		case RRule.HOURLY:
			return (new RRule({ freq: RRule.HOURLY, interval: 1, dtstart: draft.dtstart })).origOptions;
		case RRule.DAILY:
			return (new RRule({ freq: RRule.DAILY, interval: 1, dtstart: draft.dtstart })).origOptions;
		case RRule.WEEKLY:
			return (new RRule({ freq: RRule.WEEKLY, interval: 1, dtstart: draft.dtstart })).origOptions;
		case RRule.MONTHLY:
			return (new RRule({ freq: RRule.MONTHLY, interval: 1, bymonthday: 1, dtstart: draft.dtstart })).origOptions;
		case RRule.YEARLY:
			return (new RRule({ freq: RRule.YEARLY, interval: 1, bymonthday: 1, bymonth: 1, dtstart: draft.dtstart })).origOptions;
	}
}

function resetMonthlyMode(draft, mode) {
	if (draft.bysetpos && mode === MONTHLY_MODE.ON_DAY) {
		delete draft.byweekday;
		delete draft.bysetpos;
		draft.bymonthday = 1;
	}
	else if (draft.bymonthday) {
		draft.byweekday = RRule.MO;
		draft.bysetpos = 1;
		delete draft.bymonthday;
	}
}

export function setRule({value}) {
	const state = value;
	state.dtstart = state.dtstart || new Date();

	return state;
}

export const reducer = produce((draft, action) => {
	switch(action.type) {
		case SET_RULE:
			return setRule(action);
		case SET_FREQUENCY:
			return changeFrequency(draft, action.value);
		case SET_INTERVAL:
			draft.interval = action.value;
			break;
		case TOGGLE_DAY:
			toggleDay(draft, action.value);
			break;
		case SET_WEEK_DAY:
			draft.byweekday = action.value;
			break;
		case SET_START_TIME:
			draft.dtstart = action.value;
			break;
		case SET_END_TIME:
			setEndTime(draft, action.value);
			break;
		case SET_BY_MONTH_DAY:
			draft.bymonthday = action.value;
			break;
		case SET_BY_POS:
			draft.bysetpos = action.value;
			break;
		case SET_BY_MONTH:
			draft.bymonth = action.value;
			break;
		case RESET_MONTHLY_MODE:
			resetMonthlyMode(draft, action.mode);
			break;
		case SET_ERROR:
			draft.error = action.value;
			break;
	}
});

export function validate(state) {
	if (!state.interval) {
		return false;
	}

	return true;
}

export default reducer;
