import {VALID_DEPENDENCY_TYPES} from '../../constants';

export function prepareFormMaps(groups, groupToForm, allFields) {
    const fieldToForm = {};
    const formToFields = [];
    const formToDependencyFields = [];
    const accumulatedFields = [];

    for (const [groupId, fields] of Object.entries(groups)) {
        const form = groupToForm[groupId];

        for (const field of fields) {
            fieldToForm[field] = form;
            if (!formToFields[form]) {
                formToFields[form] = [];
                formToDependencyFields[form] = [...accumulatedFields];
            }

            formToFields[form].push(field);

            if (allFields[field].required && VALID_DEPENDENCY_TYPES.includes(allFields[field].type)) {
                accumulatedFields.push(field);
            }
        }
    }

    return {fieldToForm, formToFields, formToDependencyFields};
};
