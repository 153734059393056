import {translator} from "core/localization/localization";
import {TOTAL_METRIC_TYPE} from './totalMetricDataSource';

const i = translator({
	'Metric label': {
		no: 'Metrikk navn'
	},
	'Total label': {
		no: 'Total metrikk'
	},
});

export const DISPLAY_LABEL = {
	NONE: 'NONE',
	METRIC_DISPLAY_LABEL: 'METRIC_DISPLAY_LABEL',
	TOTAL_DISPLAY_LABEL: 'TOTAL_DISPLAY_LABEL',
}

export default function displayLabelDataSource(totalMetricType) {
	const values = [
		{
			text: i('None'),
			value: DISPLAY_LABEL.NONE
		},
		{
			text: i('Metric label'),
			value: DISPLAY_LABEL.METRIC_DISPLAY_LABEL
		}
	];

	totalMetricType === TOTAL_METRIC_TYPE.METRIC && values.push({
		text: i('Total label'),
		value: DISPLAY_LABEL.TOTAL_DISPLAY_LABEL
	});

	return values;
}
