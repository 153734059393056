import 'controls/react/ant/antSelect.less'
import React, {FunctionComponent, useState, useCallback, useEffect} from 'react';
import {Select, SelectProps} from 'antd';
import Api from 'tools/api';
import Settings from "settings";
import {newGuid} from 'tools/guid';
import {Cookies} from 'core/cookies';

const {Option} = Select;

const i = require('core/localization').translator({
	'There are no tags...': {
		no: 'Velg etikett...'
	},
	'Choose a tag...': {
		no: 'Etikett er satt...'
	}
})

interface TagsSelectProps extends SelectProps{
	accountId?: string,
	includeSubaccounts?: boolean,
	value?: any,
	onChange?: any
}

interface TagsSelectState {
	existingTags: Array<any>;
	selectedValue: Array<any>
}


export class TagsSelect extends React.Component<TagsSelectProps, TagsSelectState>{
	public static defaultProps = {
		accountId: Cookies.CeesoftCurrentAccountId,
		includeSubaccounts: false
	}

	constructor(props: TagsSelectProps) {
		super(props);

		this.state = {
			existingTags: [],
			selectedValue: props.defaultValue
		}
	}

	render(){
		const {onChange, accountId, includeSubaccounts, invalid, ...others} = this.props;

		return (
			<Select value={this.props.value || this.state.selectedValue}
			        mode={'tags'}
					onChange={this.onChange}
			        placeholder={this.state.existingTags.length ? i('Choose a tag...') : i('There are no tags...')}
			        {...others}>
				{this.state.existingTags.map( tag =>
					<Option key={tag.id} title={tag.name}>
						{tag.name}
					</Option>
				)}
			</Select>
		);
	}

	onChange = newValue => {
		const cleanedNewValue = newValue.filter(x => x.trim());

		this.setState({
			selectedValue: cleanedNewValue
		});
		this.props.onChange(cleanedNewValue);
	}

	componentDidUpdate(prevProps) {
		if(!prevProps.defaultValue && this.props.defaultValue) {
			this.setState({selectedValue: this.props.defaultValue});
		}
	}

	async componentDidMount(){
		const url = `${Settings.serverPath}accounts/${this.props.accountId}/tags?includeSubaccounts=${this.props.includeSubaccounts}`;

		let result = await Api.fetch(url);
		if(!result.success)
			return;

		let tagsList = [];
		for (let tag of result.data) {
			tagsList.push({
				id: tag,
				name: tag
			});
		}
		this.setState({existingTags: tagsList});
	}
}
