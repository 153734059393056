import Utils from 'tools/utils'
import State from 'tools/state'
import {ApplicationsRouter} from "areas/application/bundleDescription";

export default function ModuleStatusIndicator(config) {
	Utils.apply(this, config);
	this._initComponent();
};

ModuleStatusIndicator.prototype = {
	/**
	 * @cfg {jQuery element} renderTo Mandatory. The jQuery element where the control is rendered
	 */
	renderTo: null,
	/**
	 * @cfg {String} type Mandatory. The type of the module
	 */
	type: null,
	/**
	 * Main init function
	 */
	_initComponent: function () {
		this.config = {};
		this.id = Utils.guid();

		this._setConfig();
		this._render();
	},
	/**
	 * Attaches listeners
	 */
	_attachListeners: function () {
		this.moduleHandler.off();
		this.moduleHandler.on('click', '.cw_value', $.proxy(this._onIndicatorClick, this));
		this.moduleHandler.on('click', '.cw_status_module', $.proxy(this._onModuleClick, this));
	},
	/*
	* Handler function for setting the configuration
	* */
	_setConfig: function () {
		switch (this.type) {
			case 'service':
				this.config = {
					title: lang.SERVICE,
					icon: 'git-merge',
					module: 'Service',
					items: {
						down: {
							value: 0,
							'class': 'is_critical',
							title: lang.DOWN,
							config: {
								isView: true,
								serviceState: 'DOWN'
							}
						},
						error: {
							value: 0,
							'class': 'is_major',
							title: lang.WARNING,
							config: {
								isView: true,
								serviceState: 'WARNING'
							}
						},
						inactive: {
							value: 0,
							'class': 'is_idle',
							title: lang.INACTIVE,
							config: {
								isView: true,
								serviceState: 'INACTIVE'
							}
						}
					}
				};
				break;
			case 'health':
				this.config = {
					title: lang.ASSET_HEALTH,
					icon: 'heartbeat',
					module: 'AssetHealthSummary',
					items: {
						critical: {
							value: 0,
							'class': 'is_critical',
							title: lang.CRITICAL,
							config: {
								healthIndexRangeType: ['CRITICAL'],
								includeSubaccounts: false,
								isView: true,
								source: "buckets"
							}
						},
						major: {
							value: 0,
							'class': 'is_major',
							title: lang.MAJOR,
							config: {
								healthIndexRangeType: ['MAJOR'],
								includeSubaccounts: false,
								isView: true,
								source: "buckets"
							}
						},
						minor: {
							value: 0,
							'class': 'is_minor',
							title: lang.MINOR,
							config: {
								healthIndexRangeType: ['MINOR'],
								includeSubaccounts: false,
								isView: true,
								source: "buckets"
							}
						},
						maintenance: {
							value: 0,
							'class': 'is_idle',
							title: lang.MAINTENANCE,
							config: {
								healthIndexRangeType: ['MAINTENANCE'],
								includeSubaccounts: false,
								isView: true,
								source: "buckets"
							}
						}
					}
				};
				break;
			case 'application':
				this.config = {
					title: lang.APPLICATION,
					icon: 'artificial-intelligence',
					module: 'Application',
					items: {
						critical: {
							value: 0,
							'class': 'is_critical',
							title: lang.CRITICAL,
							config: {
								stateFilter: 'CRITICAL'
							}
						},
						major: {
							value: 0,
							'class': 'is_major',
							title: lang.MAJOR,
							config: {
								stateFilter: 'MAJOR'
							}
						},
						minor: {
							value: 0,
							'class': 'is_minor',
							title: lang.MINOR,
							config: {
								stateFilter: 'MINOR'
							}
						},
						maintenance: {
							value: 0,
							'class': 'is_idle',
							title: lang.INACTIVE,
							config: {
								stateFilter: 'IDLE'
							}
						}
					}
				};
				break;
			case 'sla':
				this.config = {
					title: lang.widget.SLA,
					icon: 'record',
					module: 'SLAs',
					items: {
						breached: {
							value: 0,
							'class': 'is_critical',
							title: lang.BREACHED,
							config: {
								indicator: 'FAILED',
								isView: true
							}
						},
						warning: {
							value: 0,
							'class': 'is_major',
							title: lang.WARNING,
							config: {
								indicator: 'WARNING',
								isView: true
							}
						}
					}
				};
				break;
			case 'agent':
				this.config = {
					title: lang.AGENT,
					icon: 'macbook',
					module: 'ManagementAgents',
					items: {
						down: {
							value: 0,
							'class': 'is_critical',
							title: lang.DOWN,
							config: {
								customFilter: {
									field: 'agentState',
									operator: 'eq',
									value: 'error'
								}
							}
						},
						warning: {
							value: 0,
							'class': 'is_major',
							title: lang.WARNING,
							config: {
								customFilter: {
									field: 'agentState',
									operator: 'eq',
									value: 'warning'
								}
							}
						},
						maintenance: {
							value: 0,
							'class': 'is_idle',
							title: lang.MAINTENANCE,
							config: {
								customFilter: {
									field: 'agentStateSort',
									operator: 'eq',
									value: 'maintenance'
								}
							}
						}
					}
				};
				break;
			case 'server':
				this.config = {
					title: lang.SERVER_AGENT,
					icon: 'server',
					module: 'ManagementServer',
					items: {
						down: {
							value: 0,
							'class': 'is_critical',
							title: lang.DOWN,
							config: {}
						},
						warning: {
							value: 0,
							'class': 'is_major',
							title: lang.WARNING,
							config: {}
						},
						maintenance: {
							value: 0,
							'class': 'is_idle',
							title: lang.MAINTENANCE,
							config: {}
						}
					}
				};
				break;
			default:
				break;
		}
	},
	/*
	* Handler function for rendering the content
	* */
	_render: function () {
		var html = '', item, key, isEmpty = true;
		html += '<div class="cw_status_mini" id="' + this.id + '" data-module="' + this.config.module + '">';
		html += '<div class="cw_icon cw_status_module pointer" title="' + this.config.title + '"><span class="glyphicons ' + this.config.icon + '"></span></div>';
		for (key in this.config.items) {
			item = this.config.items[key];
			if (this.items[key]) {
				isEmpty = false;
				html += '<div data-indicator="' + key + '" class="cw_value ' + item['class'] + '" title="' + item.title + '">' + this.items[key] + '</div>';
			}
		}
		html += '</div>';

		if (!isEmpty) {
			this.renderTo.append(html);
			this.moduleHandler = $('#' + this.id);

			this._attachListeners();
		}
	},
	/*
	* Handler function for clicking indicator status
	* @param {Object} e The click event object
	* */
	_onIndicatorClick: function (e) {
		let target = $(e.currentTarget);
		let indicator = target.data('indicator');
		let currentValue = this.config.items[indicator];
		let module = target.closest('.cw_status_mini').data('module');
		if (module === 'Application') {
			let filterObj = {
				extra: {
					stateFilter: currentValue.config.stateFilter,
					isView: true
				}
			};
			State.mainApp.navigate(ApplicationsRouter.list(), filterObj);
		} else {
			State.mainApp.loadModule(this.config.module, '', currentValue.config);
		}
	},
	/*
	* Handler function for clicking the module icon
	* @param {Object} e The click event object
	* */
	_onModuleClick: function (e) {
		let target = $(e.target);
		let module = target.closest('.cw_status_mini').data('module');
		if (module === 'Application') {
			State.mainApp.navigate(ApplicationsRouter.list());
		} else {
			State.mainApp.loadModule(this.config.module);
		}
	}
}
