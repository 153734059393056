import React from 'react';
import ReactDOM from "react-dom";
import {Utils, applyRequired} from 'tools/utils';
import ConfigurationBase from 'areas/service-boards/configurationBase';
import Settings from 'settings';
import FormEntry from "controls/react/form/formEntry";
import {TagsSelect} from "controls/react/tagsSelect";
import lang from "../../../../core/localization/lang-en";

const i = require('core/localization').translator({
	'Show menu': {no: 'Vis meny'},
	'Display settings': {no: 'Skjerminnstillinger'},
});

export function EventsSummaryWidgetConfiguration(config) {
	Utils.apply(this, config);
	this.initComponent();
};

export {EventsSummaryWidgetConfiguration as default}

jQuery.extend(EventsSummaryWidgetConfiguration.prototype, ConfigurationBase.prototype, {
	/**
	 * @cfg {Object} widgetObj
	 */
	/**
	 * Main init function
	 */
	initComponent: function () {
		let widget = this.widgetConfigurationFormEl;
		var widgetObj = this.widgetObj;
		var html = '';
		html = '<div class="cw_field"><label class="cw_inline">' + lang.TITLE + ': </label><input type="text" class="cw_widget_title cw_inline" value="' + (widgetObj.title || '') + '" /></div>';
		html += '<div class="cw_field" id="cw_grid_events_account"><label class="cw_inline">' + lang.ACCOUNT + ': </label><div class="cw_dropdown_container"><input id="cw_grid_events_accountid" type="text" value="' + (widgetObj.configuration.accountId || '') + '" /></div></div>';
		html += '<div class="cw_field">';
		html += '<input type="checkbox" checked id="cw_events_include_subaccounts_check" class="cw_show_column k-checkbox"/>';
		html += '<label for="cw_events_include_subaccounts_check" class="k-checkbox-label">' + lang.INCLUDE_SUBACCOUNTS + '</label>';
		html += '</div>';
		html += '<ul class="cw_list_columns">';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_head">' + lang.widget.COLUMN_VISIBILITY + '</div>';
		html += '</li>';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_events_account_name" class="cw_show_column k-checkbox"><label for="cw_events_account_name" class="k-checkbox-label">' + lang.ACCOUNT + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" id="cw_events_external_owner" class="cw_show_column k-checkbox"><label for="cw_events_external_owner" class="k-checkbox-label">' + lang.account.ASSET_EXTERNAL_OWNER + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_events_severity_index" class="cw_show_column k-checkbox"><label for="cw_events_severity_index" class="k-checkbox-label">' + lang.assethealth.SEVERITY + '</label></div>';
		html += '</li>';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_events_acknowledged" class="cw_show_column k-checkbox"><label for="cw_events_acknowledged" class="k-checkbox-label">' + lang.service.ACKNOWLEDGED + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" id="cw_events_external_id" class="cw_show_column k-checkbox"><label for="cw_events_external_id" class="k-checkbox-label">' + lang.account.ASSET_EXTERNAL_ID + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" id="cw_events_source_id" class="cw_show_column k-checkbox"><label for="cw_events_source_id" class="k-checkbox-label">' + lang.SOURCE_ID + '</label></div>';
		html += '</li>';
		html += '</li>';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_events_acknowledged_at" class="cw_show_column k-checkbox"><label for="cw_events_acknowledged_at" class="k-checkbox-label">' + lang.incidents.TIME_OF_FLAG + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_events_historic" class="cw_show_column k-checkbox"><label for="cw_events_historic" class="k-checkbox-label">' + lang.HISTORIC + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" id="cw_events_subsystem" class="cw_show_column k-checkbox"><label for="cw_events_subsystem" class="k-checkbox-label">' + lang.assethealth.SUBSYSTEM + '</label></div>';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_events_acknowledged_by" class="cw_show_column k-checkbox"><label for="cw_events_acknowledged_by" class="k-checkbox-label">' + lang.incidents.ACKNOWLEDGED_BY + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" id="cw_events_identifier_instance" class="cw_show_column k-checkbox"><label for="cw_events_identifier_instance" class="k-checkbox-label">' + lang.assethealth.IDENTIFIER_INSTANCE + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_events_suppression" class="cw_show_column k-checkbox"><label for="cw_events_suppression" class="k-checkbox-label">' + lang.SUPPRESSION + '</label></div>';
		html += '</li>';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_events_action_count" class="cw_show_column k-checkbox"><label for="cw_events_action_count" class="k-checkbox-label">' + lang.ACTION_COUNT + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" id="cw_events_identifier_name" class="cw_show_column k-checkbox"><label for="cw_events_identifier_name" class="k-checkbox-label">' + lang.assethealth.IDENTIFIER_NAME + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" id="cw_events_tags" class="cw_show_column k-checkbox"><label for="cw_events_tags" class="k-checkbox-label">' + lang.TAGS + '</label></div>';
		html += '</li>';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_events_agent_id" class="cw_show_column k-checkbox"><label for="cw_events_agent_id" class="k-checkbox-label">' + lang.serviceBoard.AGENT_ID + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_events_information" class="cw_show_column k-checkbox"><label for="cw_events_information" class="k-checkbox-label">' + lang.INFORMATION + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_events_target_name" class="cw_show_column k-checkbox"><label for="cw_events_target_name" class="k-checkbox-label">' + lang.TARGET + '</label></div>';
		html += '</li>';
		html += '</li>';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_events_agent_name" class="cw_show_column k-checkbox"><label for="cw_events_agent_name" class="k-checkbox-label">' + lang.assethealth.AGENT_NAME + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_events_maintenance" class="cw_show_column k-checkbox"><label for="cw_events_maintenance" class="k-checkbox-label">' + lang.service.IN_MAINTENANCE + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" id="cw_events_target_id" class="cw_show_column k-checkbox"><label for="cw_events_target_id" class="k-checkbox-label">' + lang.TARGET_ID + '</label></div>';
		html += '</li>';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_events_assigned_type" class="cw_show_column k-checkbox"><label for="cw_events_assigned_type" class="k-checkbox-label">' + lang.ASSIGNED_TO_TYPE + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_events_message" class="cw_show_column k-checkbox"><label for="cw_events_message" class="k-checkbox-label">' + lang.MESSAGE + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_events_date" class="cw_show_column k-checkbox"><label for="cw_events_date" class="k-checkbox-label">' + lang.widget.TIME + '</label></div>';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_events_assigned_to" class="cw_show_column k-checkbox"><label for="cw_events_assigned_to" class="k-checkbox-label">' + lang.ASSIGNED_TO + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" id="cw_events_monitor_type" class="cw_show_column k-checkbox"><label for="cw_events_monitor_type" class="k-checkbox-label">' + lang.assethealth.MONITOR_TYPE + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" id="cw_events_monitor_class" class="cw_show_column k-checkbox"><label for="cw_events_monitor_class" class="k-checkbox-label">' + lang.assethealth.MONITOR_CLASS + '</label></div>';
		html += '</li>';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_events_time_deleted" class="cw_show_column k-checkbox"><label for="cw_events_time_deleted" class="k-checkbox-label">' + lang.TIME_DELETED + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" id="cw_events_created" class="cw_show_column k-checkbox"><label for="cw_events_created" class="k-checkbox-label">' + lang.CREATED + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_events_source_name" class="cw_show_column k-checkbox"><label for="cw_events_source_name" class="k-checkbox-label">' + lang.NAME + '</label></div>';
		html += '</li>';
		html += '<li class="cw_list_row">';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_events_type_text" class="cw_show_column k-checkbox"><label for="cw_events_type_text" class="k-checkbox-label">' + lang.TYPE + '</label></div>';
		html += '<div class="cw_list_item dashboard-form-list-item"><input type="checkbox" checked id="cw_events_details" class="cw_show_column k-checkbox"><label for="cw_events_details" class="k-checkbox-label">' + lang.DETAILS + '</label></div>';
		html += '</li>';
		html += '</li>';
		html += '</ul>';
		html +='<div class="cw_separator">';
		html += '<span class="cw_separator_title">' + lang.TAGS + '</span>';
		html += '<div class="w100 left">';
		html += '<div class="cw_checkoxes_with_labels"><input type="checkbox" id="cw_untagged" class="k-checkbox" /><label for="cw_untagged" class="k-checkbox-label">' + lang.SHOW_UNTAGGED + '</label></div></div>';
		html += '<div class="left w100" id="cw_tags"></div>';
		html += '</div>';
		html +='<div class="cw_separator">';
		html += '<span class="cw_separator_title">' + i('Display settings') + '</span>';
		html += '<div class="w100 left">';
		html += '<div class="cw_checkoxes_with_labels"><input type="checkbox" id="cw_showMenu" class="k-checkbox" /><label for="cw_showMenu" class="k-checkbox-label">' + i('Show menu') + '</label></div></div>';		
		html += '</div>';
		widget.empty().append(html);

		if (widgetObj.configuration.columns) {
			if (!jQuery.isEmptyObject(widgetObj.configuration.columns)) {
				//on edit reset all and set only if in configuration
				$('.cw_show_column').prop('checked', false);

				if (widgetObj.configuration.columns.severity && !widgetObj.configuration.columns.severity.hidden) {
					$('#cw_events_severity_index').prop('checked', true);
				}
				if (widgetObj.configuration.columns.targetName && !widgetObj.configuration.columns.targetName.hidden) {
					$('#cw_events_target_name').prop('checked', true);
				}
				if (widgetObj.configuration.columns.agentName && !widgetObj.configuration.columns.agentName.hidden) {
					$('#cw_events_agent_name').prop('checked', true);
				}
				if (widgetObj.configuration.columns.accountName && !widgetObj.configuration.columns.accountName.hidden) {
					$('#cw_events_account_name').prop('checked', true);
				}
				if (widgetObj.configuration.columns.type && !widgetObj.configuration.columns.type.hidden) {
					$('#cw_events_type_text').prop('checked', true);
				}
				if (widgetObj.configuration.columns.time && !widgetObj.configuration.columns.time.hidden) {
					$('#cw_events_date').prop('checked', true);
				}
				if (widgetObj.configuration.columns.message && !widgetObj.configuration.columns.message.hidden) {
					$('#cw_events_message').prop('checked', true);
				}
				if (widgetObj.configuration.columns.sourceName && !widgetObj.configuration.columns.sourceName.hidden) {
					$('#cw_events_source_name').prop('checked', true);
				}
				if (widgetObj.configuration.columns.acknowledged && !widgetObj.configuration.columns.acknowledged.hidden) {
					$('#cw_events_acknowledged').prop('checked', true);
				}
				if (widgetObj.configuration.columns.acknowledgedBy && !widgetObj.configuration.columns.acknowledgedBy.hidden) {
					$('#cw_events_acknowledged_by').prop('checked', true);
				}
				if (widgetObj.configuration.columns.acknowledgedAt && !widgetObj.configuration.columns.acknowledgedAt.hidden) {
					$('#cw_events_acknowledged_at').prop('checked', true);
				}
				if (widgetObj.configuration.columns.agentId && !widgetObj.configuration.columns.agentId.hidden) {
					$('#cw_events_agent_id').prop('checked', true);
				}
				if (widgetObj.configuration.columns.externalId && !widgetObj.configuration.columns.externalId.hidden) {
					$('#cw_events_external_id').prop('checked', true);
				}
				if (widgetObj.configuration.columns.externalOwner && !widgetObj.configuration.columns.externalOwner.hidden) {
					$('#cw_events_external_owner').prop('checked', true);
				}
				if (widgetObj.configuration.columns.identifierInstance && !widgetObj.configuration.columns.identifierInstance.hidden) {
					$('#cw_events_identifier_instance').prop('checked', true);
				}
				if (widgetObj.configuration.columns.identifierName && !widgetObj.configuration.columns.identifierName.hidden) {
					$('#cw_events_identifier_name').prop('checked', true);
				}
				if (widgetObj.configuration.columns.monitorType && !widgetObj.configuration.columns.monitorType.hidden) {
					$('#cw_events_monitor_type').prop('checked', true);
				}
				if (widgetObj.configuration.columns.monitorTypeText && !widgetObj.configuration.columns.monitorTypeText.hidden) {
					$('#cw_events_monitor_type').prop('checked', true);
				}
				if (widgetObj.configuration.columns.monitorClass && !widgetObj.configuration.columns.monitorClass.hidden) {
					$('#cw_events_monitor_class').prop('checked', true);
				}
				if (widgetObj.configuration.columns.subsystem && !widgetObj.configuration.columns.subsystem.hidden) {
					$('#cw_events_subsystem').prop('checked', true);
				}
				if (widgetObj.configuration.columns.sourceId && !widgetObj.configuration.columns.sourceId.hidden) {
					$('#cw_events_source_id').prop('checked', true);
				}
				if (widgetObj.configuration.columns.targetId && !widgetObj.configuration.columns.targetId.hidden) {
					$('#cw_events_target_id').prop('checked', true);
				}
				if (widgetObj.configuration.columns.suppressionCount && !widgetObj.configuration.columns.suppressionCount.hidden) {
					$('#cw_events_suppression').prop('checked', true);
				}
				if (widgetObj.configuration.columns.assignedTo && !widgetObj.configuration.columns.assignedTo.hidden) {
					$('#cw_events_assigned_to').prop('checked', true);
				}
				if (widgetObj.configuration.columns.tags && !widgetObj.configuration.columns.tags.hidden) {
					$('#cw_events_tags').prop('checked', true);
				}
				if (widgetObj.configuration.columns.historic && !widgetObj.configuration.columns.historic.hidden) {
					$('#cw_events_historic').prop('checked', true);
				}
				if (widgetObj.configuration.columns.assignedToType && !widgetObj.configuration.columns.assignedToType.hidden) {
					$('#cw_events_assigned_type').prop('checked', true);
				}
				if (widgetObj.configuration.columns.timeDeleted && !widgetObj.configuration.columns.timeDeleted.hidden) {
					$('#cw_events_time_deleted').prop('checked', true);
				}
				if (widgetObj.configuration.columns.actionCount && !widgetObj.configuration.columns.actionCount.hidden) {
					$('#cw_events_action_count').prop('checked', true);
				}
				if (widgetObj.configuration.columns.information && !widgetObj.configuration.columns.information.hidden) {
					$('#cw_events_information').prop('checked', true);
				}
				if (widgetObj.configuration.columns.inMaintenance && !widgetObj.configuration.columns.inMaintenance.hidden) {
					$('#cw_events_maintenance').prop('checked', true);
				}
				if (widgetObj.configuration.columns.details && !widgetObj.configuration.columns.details.hidden) {
					$('#cw_events_details').prop('checked', true);
				}
				if (widgetObj.configuration.columns.timeCreated && !widgetObj.configuration.columns.timeCreated.hidden) {
					$('#cw_events_created').prop('checked', true);
				}
			} else {
				$('.cw_show_column').prop('checked', true);
			}
		}
		if (widgetObj.configuration.includeSubaccounts === undefined || widgetObj.configuration.includeSubaccounts === true) {
			$('#cw_events_include_subaccounts_check').prop('checked', true);
		} else {
			$('#cw_events_include_subaccounts_check').prop('checked', false);
		}

		if (widgetObj.configuration.showMenu === true) {
			$('#cw_showMenu').prop('checked', true);
		} else {
			$('#cw_showMenu').prop('checked', false);
		}

		this.initKendoComponents();

		const requiredFields = ['#cw_grid_events_accountid'];
		applyRequired(requiredFields);
	},
	/**
	 * Iniitalizes kendo components
	 */
	initKendoComponents: function () {
		this.tagsList = this.widgetObj.configuration.tags;
		this.createAccountComponent({
			element: $('#cw_grid_events_accountid'),
			container: $('#cw_grid_events_account'),
			events: {
				change: function () {
					this.tagsList = [];
					this.setTagsDataSource();
				},
				dataBound: function () {
					this.setTagsDataSource();
				}
			}
		});
	},

	setTagsDataSource: function () {
		let accountId = $('#cw_grid_events_accountid').data('kendoComboBox').value();
		let includeSubaccounts = $('#cw_events_include_subaccounts_check').is(':checked');

		ReactDOM.render(<FormEntry label={lang.TAGS} vertical style={{maxWidth: '600px'}}>
			<TagsSelect
				mode={'tags'}
				accountId={accountId}
				includeSubaccounts={includeSubaccounts}
				onChange={value => this.onTagsChange(value)}
				defaultValue={this.tagsList} />
		</FormEntry>, $('#cw_tags').get()[0]);
	},
	onTagsChange(value) {
		this.tagsList = value || [];
	},

	getValues: function () {
		var title = $('.cw_widget_title').val().trim();
		var widgetObj = {
			id: this.mode === 'update' || this.wasUpdate ? this.widgetObj.id : Utils.guid(),
			type: 'grid_event_summary',
			title: title ? title : lang.EVENT_SUMMARY,
			configuration: {
				accountId: $('#cw_grid_events_accountid').data('kendoComboBox').value(),
				includeSubaccounts: $('#cw_events_include_subaccounts_check').is(':checked'),
				columns: {
					severity: {
						hidden: $('#cw_events_severity_index').is(':not(:checked)')
					},
					targetName: {
						hidden: $('#cw_events_target_name').is(':not(:checked)')
					},
					agentName: {
						hidden: $('#cw_events_agent_name').is(':not(:checked)')
					},
					accountName: {
						hidden: $('#cw_events_account_name').is(':not(:checked)')
					},
					type: {
						hidden: $('#cw_events_type_text').is(':not(:checked)')
					},
					time: {
						hidden: $('#cw_events_date').is(':not(:checked)')
					},
					message: {
						hidden: $('#cw_events_message').is(':not(:checked)')
					},
					sourceName: {
						hidden: $('#cw_events_source_name').is(':not(:checked)')
					},
					acknowledged: {
						hidden: $('#cw_events_acknowledged').is(':not(:checked)')
					},
					acknowledgedBy: {
						hidden: $('#cw_events_acknowledged_by').is(':not(:checked)')
					},
					acknowledgedAt: {
						hidden: $('#cw_events_acknowledged_at').is(':not(:checked)')
					},
					agentId: {
						hidden: $('#cw_events_agent_id').is(':not(:checked)')
					},
					externalId: {
						hidden: $('#cw_events_external_id').is(':not(:checked)')
					},
					externalOwner: {
						hidden: $('#cw_events_external_owner').is(':not(:checked)')
					},
					identifierInstance: {
						hidden: $('#cw_events_identifier_instance').is(':not(:checked)')
					},
					identifierName: {
						hidden: $('#cw_events_identifier_name').is(':not(:checked)')
					},
					monitorTypeText: {
						hidden: $('#cw_events_monitor_type').is(':not(:checked)')
					},
					monitorClass: {
						hidden: $('#cw_events_monitor_class').is(':not(:checked)')
					},
					subsystem: {
						hidden: $('#cw_events_subsystem').is(':not(:checked)')
					},
					sourceId: {
						hidden: $('#cw_events_source_id').is(':not(:checked)')
					},
					targetId: {
						hidden: $('#cw_events_target_id').is(':not(:checked)')
					},
					suppressionCount: {
						hidden: $('#cw_events_suppression').is(':not(:checked)')
					},
					assignedTo: {
						hidden: $('#cw_events_assigned_to').is(':not(:checked)')
					},
					tags: {
						hidden: $('#cw_events_tags').is(':not(:checked)')
					},
					historic: {
						hidden: $('#cw_events_historic').is(':not(:checked)')
					},
					assignedToType: {
						hidden: $('#cw_events_assigned_type').is(':not(:checked)')
					},
					timeDeleted: {
						hidden: $('#cw_events_time_deleted').is(':not(:checked)')
					},
					actionCount: {
						hidden: $('#cw_events_action_count').is(':not(:checked)')
					},
					information: {
						hidden: $('#cw_events_information').is(':not(:checked)')
					},
					inMaintenance: {
						hidden: $('#cw_events_maintenance').is(':not(:checked)')
					},
					details: {
						hidden: $('#cw_events_details').is(':not(:checked)')
					},
					timeCreated: {
						hidden: $('#cw_events_created').is(':not(:checked)')
					},
				},
				tags: this.tagsList,
				showUntagged: $('#cw_untagged').is(':checked'),
				showMenu: $('#cw_showMenu').is(':checked')
			}
		};
		return widgetObj;
	},
	isValid: function () {
		var valid = true;
		var validationMessage = '';

		var accountCombo = $('#cw_grid_events_accountid').data('kendoComboBox');

		if (!Utils.isGuid(accountCombo.value())) {
			valid = false;
			validationMessage = lang.widget.SELECT_ACCOUNT;
		}
		return {
			valid: valid,
			validationMessage: validationMessage
		};
	}
});
