import React from "react";
import {translator} from "core";
import Cookies from 'core/cookies';
import State from 'tools/state';

import './metricLegendContainer.less'

let i = translator({

});

export class MetricLegendContainer extends React.PureComponent {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className='cw_raw_data_info'>
				{this.props.data?.assetName && <span>{i('Asset')}: <span className="cw_link" onClick={this.onAssetName}>{this.props.data.assetName}</span> | </span>}
				{this.props.data?.categoryNode && <span>{i('Name')}: {this.props.data.categoryNode} | </span>}
				{this.props.data?.registryType && <span>{i('Type')}: {this.props.data.registryType} | </span>}
				<span>{i('Timezone')}: {Cookies.CeesoftTimezone}</span>
			</div>
		)
	}

	onAssetName = (e) => {
		State.mainApp.loadModule('AssetDetails', this.props.data.assetId, {}, e);
	}
}

