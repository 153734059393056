import React from "react"
import PropTypes from "prop-types";
import {Lang} from 'core'
import injectSheet from "react-jss";
import { MultiToggle } from "controls/react";
import { DateTimePicker } from "controls/react/kendoWrappers";
import Portal from 'controls/react/portal';

const ITEMS = {
	LASTHOUR: Lang.periodMultiToggle.HOUR,
	LASTDAY: Lang.periodMultiToggle.DAY,
	LAST7DAYS: Lang.periodMultiToggle.WEEK,
	LAST30DAYS: Lang.periodMultiToggle.MONTH,
	CUSTOM: Lang.CUSTOM
};

const styles = {
	container: {
		position: 'relative',
	},
	datePicker: {
		boxShadow: '1px 1px 2px 2px #aaa',
		zIndex: 1001,
		position: 'absolute',
		padding: 5,
		border: '1px solid #eee',
		backgroundColor: 'white',
		width: 400
	}
};

function PeriodMultiToggle(props) {
	const {classes} = props;

	const [showDatePicker, setShowDatePicker] = React.useState(false);
	const [startDate, setStartDate] = React.useState(props.startDate || new Date(Date.now() - 1000 * 60 * 60 * 24));
	const [endDate, setEndDate] = React.useState(props.endDate || new Date());

	const onPeriodChange = React.useCallback((value) => {
		if (value === "CUSTOM") {
			setShowDatePicker(true);
			return;
		}

		setShowDatePicker(false);
		props.onChange(value);
	}, [props.onChange]);

	const onCustomRange = React.useCallback(() => {
		setShowDatePicker(false);
		props.onChange("CUSTOM", startDate, endDate);
	}, [startDate, endDate, props.onChange]);

	const containerRef = React.useRef();

	const datePickerRefCb = React.useCallback((node) => {
		if (!node) {
			return;
		}

		const nodeRect = node.getBoundingClientRect();
		const containerRect = containerRef.current.getBoundingClientRect();

		const top = containerRect.bottom;
		const left = containerRect.left - (nodeRect.width - containerRect.width) / 2;

		node.style.top = `${top}px`;
		node.style.left = `${left + 60}px`;
	});

	const onStartDate = React.useCallback((timestamp) => {
		setStartDate(new Date(timestamp));
	}, []);

	const onEndDate = React.useCallback((timestamp) => {
		setEndDate(new Date(timestamp))
	}, []);

	return <div className={classes.container} ref={containerRef}>
		<MultiToggle
			value={props.defaultValue}
			onChange={onPeriodChange}
			items={ITEMS} />
		{showDatePicker && <Portal><div className={classes.datePicker} ref={datePickerRefCb}>
			<div className="cw_time_input_container">
				<div>{Lang.FROM}</div>
				<DateTimePicker value={startDate} onChange={onStartDate} />
			</div>
			<div className="cw_time_input_container">
				<div>{Lang.TO}</div>
				<DateTimePicker value={endDate} onChange={onEndDate} />
			</div>
			<button disabled={!startDate || !endDate} className="k-button k-primary right" onClick={onCustomRange}>{Lang.APPLY}</button>
		</div>
		</Portal>}
	</div>
}

PeriodMultiToggle.propTypes = {
	defaultValue: PropTypes.string.isRequired,
	startDate: PropTypes.instanceOf(Date),
	endDate: PropTypes.instanceOf(Date),
	onChange: PropTypes.func.isRequired
}

export default injectSheet(styles)(PeriodMultiToggle)
