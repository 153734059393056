import {LocalEventsManager} from "core";
import {authenticationSavedAction} from "../actions/authenticationSavedAction";
import {assetSavedAction} from "../actions/assetSavedAction";

const EVENTS = {
    "authenticationsaved": authenticationSavedAction,
    "assetsaved": assetSavedAction
};

export function localToStore(store) {
    for (const [event, action] of Object.entries(EVENTS)) {
        LocalEventsManager.bind(event, (...params) => store.dispatch(action(params)));
    }
}
