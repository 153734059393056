import {root as monitorsRoot} from '../router';

const root = monitorsRoot + '/log';

export function getDescriptions(){
	return [
		{
			url: root + '/:id',
			getModuleImport: () => import('./details')
		}
	];
}

export class LogRouter {
	static create() {
		return `${root}/new`;
	}
	static details(monitorId) {
		return root + '/' + monitorId
	}
}
