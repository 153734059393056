import {RemoteEventsManager} from "core/remoteEventsManager";
import {State} from "tools/state";
import Settings from "settings";
import {DashboardsRouter} from "areas/dashboards/bundleDescription";

export async function redirectTo(url, preserveHash = false, newTab = false) {
	RemoteEventsManager.closeConnection();
	if (State.currentApp) {
		State.isUnloading = true;

		State.mainApp.unloadModule && await State.mainApp.unloadModule();
	}

	if(!newTab && url[0] == '#'){
		State.mainApp.navigate(url);
	}else{
		if (preserveHash) {
			url += window.location.hash;
		}
		if (newTab) {
			window.open(url, '_blank');
		} else {
			var redirect = function () {
				window.location = url;
			};
			// wait for events to unsubscribe and user preferences to be saved
			setTimeout(redirect, 1000);
		}
	}
}

export function openInNewWindow(url){
	window.open(Settings.httpRoot + "#" + url, "_blank");
}
