import Element from './element';
import Api from 'tools/api';
import {SlaApi} from 'api';
import Cookies from 'core/cookies';
import State from 'tools/state';
import Utils from 'tools/utils';
import Settings from 'settings';
import {SlaRouter} from 'areas/sla/bundleDescription';
import {translator} from "../../core/localization/localization";
import {compareByOperator} from "tools/helpers/math";
import {indicatorToState} from "tools/states";
import {KpiRouter} from "areas/kpi/bundleDescription";
const i = translator({
	"KPI: {0}": {
		"no": "KPI: {0}"
	},
});


export class Kpi extends Element {
	kpi = null;
	kpiConfig = null;

	constructor(designer, cell) {
		super(designer, cell);

		this.kpiConfig = this.datasource.kpi;
	}

	getEntriesToLoad() {
		return [{
			profileId: this.kpiConfig.profileId,
			configurationType: this.kpiConfig.ruleTypeId,
			sampleRate: this.kpiConfig.sampleRateId,
			kpiAccountId: this.kpiConfig.accountId
		}];
	}

	onEntriesLoaded(entries) {
		this.kpi = entries.length ? entries[0] : null;
	}

	updateState() {
		if(this.kpi == null)
			return;

		this.addContentLabel(this.kpi.value);

		if(this.datasource?.metricValueAsSeverity) {
			const valueToSeverity = this.datasource.metricValueToSeverity;

			let severityIndicator = valueToSeverity.findIndex(x => compareByOperator(x.operator, this.kpi.value || 0, x.threshold));
			if(severityIndicator == -1) {
				severityIndicator = 3
			}
			this.setState(indicatorToState(severityIndicator));
		}
	}

	getSubscriptions(subscriptions) {
		return {
			kpis: [{
				profileId: this.kpiConfig.profileId,
				accountId: this.kpiConfig.accountId,
				configurationId: this.kpiConfig.configurationTypeId
			}]
		};
	}

	consumeEvent(event) {
		if (event.eventType != 'Kpi')
		 	return false;
		//
		// const sla = this.slas.find( x=> x.slaId == event.calculation.slaId);
		// if(sla && sla.indicator != event.calculation.indicator){
		// 	sla.indicator = event.calculation.indicator;
		// 	return true;
		// }

		return false;
	}

	getTooltipInternal(accountName) {
		if (this.kpi == null)
			return null;

		return this.kpi.profileText + '/' + this.kpi.configurationTypeText;
	}

	redirect() {
		if(!this.kpi)
			return;

		State.mainApp.navigate(KpiRouter.details(this.kpiConfig.profileId));
	}

	empty(){
		return this.kpiConfig?.sampleRateId == null;
	}

	getLabel(){
		let userData = this.editorUi.getCellValueAsXml(this.cell);

		if(this.datasource.showAsLabel && this.kpi){
			return this.kpi.profileText + '/' + this.kpi.configurationTypeText;
		}

		return null;
	}
}


const INDICATOR_TO_PRIORITY = {
	'OK': 3,
	'WARNING': 2,
	'FAILED': 1
};

const PRIORITY_TO_STATE = [
	'INVALID',
	'ERROR',
	'WARNING',
	'ACTIVE'
];

function indicatorToPriority(indicator) {
	return INDICATOR_TO_PRIORITY[indicator] || 0;
}
