import {delay, put, select} from 'redux-saga/effects';
import {getItemValue} from '../selectors/getItemValue';
import {getReduxFormValue} from '../selectors/getReduxFormValue';

import {LOADABLE_TYPES} from '../constants'
import {setFieldEnabledAction} from '../actions/setFieldEnabledAction';
import {setDataCallParams} from '../actions/setDataCallParams';
import {loadDataAction} from '../actions/loadDataAction';

export function* checkFieldState(action) {
	yield delay(200);

	let itemDependencies = yield select(getItemValue, 'dependencies');

	if (!itemDependencies) {
		return;
	}

	for (let [field, dependencies] of itemDependencies.entries()) {
		const fieldConfig = yield select(getItemValue, ['fields', field]);

		if (!fieldConfig) {
			continue;
		}

		const {shouldBeEnabled, shouldLoadData, newValues} = yield checkShouldBeEnabled(field, dependencies);

		const isLoadable = LOADABLE_TYPES.has(fieldConfig.get('type'));

		const status = yield select(getItemValue, ['enabledFields', field]);

		if (status !== shouldBeEnabled) {
			yield put(setFieldEnabledAction(field, shouldBeEnabled));
		}

		if (isLoadable && shouldBeEnabled && shouldLoadData) {
			yield put(setDataCallParams(field, newValues));
			yield put(loadDataAction(field));
		}
	}
}

export function* checkShouldBeEnabled(field, dependencies) {
	// Fields with no dependencies should be loaded just once
	// so if we perform the data call we shouldn't repeat it again
	if (dependencies.size === 0) {
		const data = yield select(getItemValue, ['fieldData', field])

		return {
			shouldBeEnabled: true,
			shouldLoadData: !data,
			newValues: {}
		};
	}

	let shouldBeEnabled = true;
	let shouldLoadData = false;

	const oldValues = (yield select(getItemValue, ['dataCallParams', field])) || {};

	const newValues = {};

	for (let dependency of dependencies) {
		const oldValue = oldValues[dependency];
		const currentValue = yield select(getReduxFormValue, dependency);

		shouldLoadData = shouldLoadData || (oldValue !== currentValue);

		const fieldConfig = yield select(getItemValue, ['fields', dependency]);

		if (fieldConfig) {
			if (fieldConfig.get('type') === 'CECheckBox') {
				if (!currentValue) {
					console.log(field, '// should be disabled because of not checked', dependency, currentValue);
					// shouldBeEnabled = false;
					// break;
				}
			}

			if (shouldBeEnabled && currentValue === undefined || currentValue === null || currentValue === '') {
				console.log(field, 'should be disabled because of empty', dependency, currentValue);
				shouldBeEnabled = false;
				break;
			}
		}

		newValues[dependency] = currentValue;
	}

	return {shouldBeEnabled, shouldLoadData, newValues};
}
