import {connect} from "react-redux";
import {formValueSelector, change} from "redux-form";
import {List} from "immutable";
import {getItemValue} from "../../selectors/getItemValue";
import {loadDataAction} from "../../actions/loadDataAction";
import {infoChangedAction} from "../../actions/infoChangedAction";
import {isFieldInError} from "../../selectors/isFieldInError";
import {showValidation} from "../../selectors/showValidation";
import {getCallback} from "../../selectors/getCallback";
import {formBuilderForm} from "controls/react/form/formBuilder/constants";
import {getTimezone} from '../../selectors/getTimezone';
import {select} from 'redux-saga/effects';
import {getReduxFormValues} from 'controls/react/form/formBuilder/selectors/getReduxFormValues';

const mapStateToProps = (state, ownProps) => {
    const selector = formValueSelector(ownProps.meta.form);
    const field = ownProps.targetValue;

    const props = {
        data: getItemValue(state, ['fieldData', field]),
        showFieldValidation: showValidation(state),
        fieldInError: isFieldInError(state, field),
        workInProgress: false, //getItemValue(state, ['workInProgress', field]),
		systemType: getItemValue(state, ['original', 'systemType']),
		onData: getCallback(state, 'onData'),
		timezone: getTimezone(state),
		values: getReduxFormValues(state)
	};

    const fieldDependencies = getItemValue(state, ['dependencies', field]) || List();

    props.dependencies = fieldDependencies;

    for(let dep of fieldDependencies) {
        props[dep] = selector(state, `properties.${dep}`);
    }

	if (ownProps.filenameField) {
		props[ownProps.filenameField] = selector(state, `properties.${ownProps.filenameField}`);
	}

	return props;
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    reloadData: (options) => dispatch(loadDataAction(ownProps.targetValue, options)),
    onInfoChanged: () => dispatch(infoChangedAction(ownProps.targetValue)),
	changeField: (field, value) => dispatch(change(formBuilderForm, `properties.${field}`, value))
});

export default connect(mapStateToProps, mapDispatchToProps);
