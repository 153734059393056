export function reformatFormsAndGroups(originalForms, fields) {
    const out = [];
    const groupToForm = {};

    for (const [index, form] of originalForms.entries()) {
        const newForm = new Set();

        for (const field of form.components) {
            if (fields[field].type === 'CEGroup') {
                newForm.add(field);
                groupToForm[field] = index;
            } else if (fields[field].type === 'CESeparator') {
                // skip field
            } else {
                newForm.add(`default_${index}`);
                groupToForm[`default_${index}`] = index;
            }
        }

        out.push(Array.from(newForm))
    }

    return {forms: out, groupToForm};
};
