import {newGuid} from 'tools/guid'
import Lang from 'core/localization/lang'

export function dialogAsync(config){
	return new Promise( resolve => {
		var dialog = new Dialog({
			...config,
			...{
				fn: (value, button) => {
					resolve(button);
				}
			}
		});
		dialog.show();
	});
}

export async function questionDialogAsync(question) {
	return 'ok' == await dialogAsync({
		title: Lang.INFO,
		msg: question,
		icon: 'INFO',
		buttons:
		{
			ok: true,
			cancel: true
		},
	});
}

export async function infoDialogAsync(title, msg, details, grid = null, windowClose){
	if (grid) {
		kendo.ui.progress(grid.wrapper, false);
	}

	return await dialogAsync({
		title: title,
		msg: msg,
		details: details,
		icon: 'WARNING',
		windowClose: windowClose
	});
}

export let Dialog = function (config) {
	Object.assign(this, config);
	if (this.closable === undefined) {
		this.closable = true;
	}
	if (!this.buttons) {
		this.buttons = {
			ok: true,
			//cancel: true
		};
	}
	if (!this.icon) {
		this.icon = '';
	}
	this.initComponent();
};

export default Dialog;

jQuery.extend(Dialog.prototype, {
	initComponent: function () {
		this.minWidth = 200;
		this.id = newGuid();

		if( this.showOnCreate ){
			this.show();
		}
	},
	/**
	 * Displayes the dialog window */
	show: function () {
		var content = this.buildContent(),
			modalTag = document.createElement('div'),
			value, windowClose = this.windowClose;
		modalTag.setAttribute('id', this.id);
		$('body').append(modalTag);
		var modalSelector = '#' + this.id;

		this.dialogWidth = this.dialogWidth || 300;
		this.dialogHeight = this.dialogHeight || 150;
		this.dlgWindow = $(modalSelector).kendoWindow({
			// minWidth: options.minWidth,
			modal: true,
			actions: this.actions || (this.closable ? ['Close'] : ['Maximize']),
			resizable: this.resizable || false,
			draggable: this.draggable ?? false,
			title: this.title,
			visible: false,
			width: this.dialogWidth,
			height: this.dialogHeight,
			close: $.proxy(function () {
				$(modalSelector).off();
				$('.dialog_input').off();
				$(modalSelector).data('kendoWindow').destroy();
				if (typeof windowClose === 'function') {
					windowClose.call(this);
				}
				if (this.advancedInfoWindow) {
					this.advancedInfoWindow.destroy();
				}
			}, this),
			open: $.proxy(this.onWindowOpen, this),
			activate: $.proxy(this.onWindowActivate, this)
		}).data("kendoWindow");
		$(modalSelector).parent().addClass('dialog');

		//close window on ESC key
		if (this.closable) {
			$(modalSelector).on('keypress', $.proxy(this.onKeyPress, this));
		}
		//set the content
		this.dlgWindow.content(content);

		//open the dialog window
		this.dlgWindow.center();
		this.dlgWindow.open();

		this.styleMessageContainer();

		$('#cw_show_advanced_info').on('click', $.proxy(this.onShowAdvancedInfo, this));
	},
	styleMessageContainer() {
		let windowHeight = this.dlgWindow.options.height;
		this.dlgWindow.wrapper.find('.cw_dialog_message').css('height', windowHeight - 70);
	},
	onWindowOpen: function () {
		var modalSelector = $('#' + this.id), value;
		if (this.prompt) {
			$('.dialog_input').on('keyup', $.proxy(this.onInputKeyUp, this));
			value = $('.dialog_input').val();
			if (value) {
				modalSelector.find('.dialog_ok').removeAttr('disabled');
			} else {
				modalSelector.find('.dialog_ok').attr('disabled', 'disabled');
			}
		}
		//attach handler for ENTER key
		if (!this.multiline) {
			$('.dialog_input').on('keypress', $.proxy(this.onInputKeyPress, this));
		}

		//attach handlers to buttons
		modalSelector.on('click', 'button', $.proxy(this.onButtonClick, this));
	},
	onWindowActivate: function () {
		var modalSelector = $('#' + this.id), value;
		if (this.prompt) {
			$('.dialog_input').focus();
			value = $('.dialog_input').val();
			if (value) {
				$('.dialog_input').select();
			}
		} else {
			modalSelector.find('.dialog_ok').focus();
		}
		if (this.headerIcon) {
			const iconContainer = `<span class="glyphicons ${this.headerIcon}" title="${this.headerMouseover || ''}"></span>`
			modalSelector.parent().find('.k-window-actions').append(iconContainer);
		}
	},
	/**
	 * Handler for the onKeyPress event
	 */
	onInputKeyPress: function (e) {
		var value = $('.dialog_input').val();
		if (e.keyCode === 13 && value) {
			$('#' + this.id).find('.dialog_ok').trigger('click');
		}
	},
	/**
	 * Handler for the onKeyPress event
	 */
	onKeyPress: function (e) {
		if (e.keyCode === 27) {
			e.preventDefault();
			this.dlgWindow.close();
		}
	},
	/*
	 * Handler for the onKeyUp event
	 */
	onInputKeyUp: function (e) {
		var value = $('.dialog_input').val();
		if (value) {
			$('#' + this.id).find('.dialog_ok').removeAttr('disabled');
		} else {
			$('#' + this.id).find('.dialog_ok').attr('disabled', 'disabled');
		}
	},
	/*
	 * Handler for the onClick event
	 */
	onButtonClick: function (e) {
		var value = $('.dialog_input').val();
		this.dlgWindow.close();
		if (this.advancedInfoWindow) {
			this.advancedInfoWindow.close();
		}
		$(e.currentTarget).unbind(e);
		if (this.fn) {
			if (e.target.name === 'ok' && this.deleteFromGrid) {
				kendo.ui.progress(this.deleteFromGrid.wrapper, true);
			}
			this.fn.call(this.scope, value, e.target.name);

		}
	},
	/**
	 * Creates dialog HTML content based on configuration options;
	 */
	buildContent: function () {
		var icon,
			content = '<div class="cw_dialog_content clearfix">';
		if (!this.icon) {
			icon = 'no_icon';
		} else {
			icon = this.getIconClass(this.icon);
		}
		content += '<span class="glyphicons ' + icon + '"></span>';
		if (this.msg) {
			content += '<span class="cw_dialog_message">' + this.msg + '</span>';
		}
		this.placeholder = this.placeholder || '';
		this.defaultText = this.defaultText || '';
		if (this.prompt) {
			if (this.multiline) {
				content += '<div class="cw_dialog_text"><textarea rows="3" cols="27" class="dialog_input" name="user_response" placeholder="' + this.placeholder + '">' + this.defaultText + '</textarea></div>';
			} else {
				content += '<input type="text" class="dialog_input" name="user_response" maxlength="1024"  value="' + this.defaultText + '" placeholder="' + this.placeholder + '" />';
			}
		}
		//add buttons
		content += '</div><div class="actions clearfix">';
		if (this.buttons.cancel) {
			content += '<button class="k-button right dialog_cancel" type="button"  name="cancel">' + Lang.CANCEL + '</button>';
		}
		if (this.buttons.ok) {
			var actionText = this.actionText || 'OK';
			content += '<button class="k-button k-primary right dialog_ok" type="button" name="ok">' + Lang[actionText] + '</button>';
		}
		if (this.details && this.details.length) {
			content += '<div id="cw_show_advanced_info" class="cw_show_advanced cw_advanced_toggle pointer">' + Lang.SHOW_ADVANCED_INFO + '</div>';
		}
		content += '</div>';
		return content;
	},
	/**
	 * Gets the icon CSS class based on icon name
	 * @param {String} iconName
	 * @return {String} iconClass The CSS class
	 */
	getIconClass: function (iconName) {
		var iconClass = '';
		switch (iconName) {
			case 'INFO':
				iconClass = 'info-sign';
				break;
			case 'WARNING':
				iconClass = 'exclamation-sign';
				break;
			case 'SUCCESS':
				iconClass = 'ok-sign';
				break;
			case 'ERROR':
				iconClass = 'remove-sign';
				break;
		}
		return iconClass;
	},
	onShowAdvancedInfo: function (e) {
		var target = $(e.target);
		if (target.hasClass('cw_show_advanced')) {
			var advancedInfoContainer = document.createElement('div');
			advancedInfoContainer.setAttribute('id', newGuid());
			$('body').append(advancedInfoContainer);
			var windowTop = this.dlgWindow.options.position.top + this.dlgWindow.options.height + 30;
			var windowLeft = this.dlgWindow.options.position.left;
			var infoWindowWidth = $('#' + this.id).width();
			this.advancedInfoWindow = $(advancedInfoContainer).kendoWindow({
				width: infoWindowWidth,
				title: Lang.ADVANCED_INFO_TITLE,
				height: 300,
				position: {
					top: windowTop,
					left: windowLeft
				},
				actions: ['Close'],
				resizable: true,
				draggable: true,
				animation: false,
				close: function() {
					target.addClass('cw_show_advanced').text(Lang.SHOW_ADVANCED_INFO);
				}
			}).data("kendoWindow");
			target.removeClass('cw_show_advanced');
			target.text(Lang.HIDE_ADVANCED_INFO);
			var content = '<div style="padding: 10px;">';
			for (var i = 0; i < this.details.length; i++) {
				content += '<div style="margin-bottom: -10px;">' + this.details[i] + '</div><br>';
			}
			content += '</div>';
			this.advancedInfoWindow.content(content);
		} else {
			target.addClass('cw_show_advanced');
			this.advancedInfoWindow.close();
			target.text(Lang.SHOW_ADVANCED_INFO);
		}
	}
});
