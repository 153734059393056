import "./redirectToConfiguration.less";

import React, { useEffect, useState } from "react";
import { FormEntry } from "controls/react/form";
import { AntInput } from "controls/react/ant/antInput";
import { AntCheckbox } from "controls/react/ant/antCheckbox";
import { CollapsibleFrame } from "controls/react/layout/collapsibleFrame";
import { translator } from "core";
import { PresentationApi } from 'areas/dashboards/api'
import {AntSelect, textValueFields} from 'controls/react/ant/antSelect';
import { AntAccountDropDown } from 'controls/react/ant/specific/antAccountDropDown';
import { Cookies } from "core";
import {RedirectType} from "controls/designer/features/redirectOnClick/redirectType";
import {useStateLink} from "core/react/links";

const i = translator({
    "Redirect": {
        "no": "Videresend"
    },
    "Custom Link": {
        "no": "Tilpasset lenke"
    },
    "Open in new tab": {
        "no": "Åpne i ny tab"
    },
    "Dashboard": {
        "no": "Dashboard"
    }
});

const b = require('b_').with('redirect-to-config-section');

export const RedirectToConfiguration = props => {
	const {configLink} = props;

	const typeLink = configLink.get('type');
	const accountLink = configLink.get('accountId');
	const linkLink = configLink.get('link');
	const dashboardLink = configLink.get('dashboardId');

	const labelLink = configLink.get('label');
	const [dashboardsList, setDashboardsList] = useState([]);
	const [customLinkText, setCustomLinkText] = React.useState(linkLink.value)

	useEffect(() => {
		loadDashboardList();
		dashboardLink.update(null);
	}, [accountLink])

	useEffect(() => {
		updateInfoLabel();
	}, [linkLink])

	async function loadDashboardList() {
		let dashboards = [];

		if (typeLink.value == RedirectType.Dashboard && accountLink.value) {
			const response = await PresentationApi.list(accountLink.value)
			if(response.success !== false) {
				dashboards = response
			}
		}

		setDashboardsList(dashboards)
	}

	function updateInfoLabel() {
		let label = ''
		if (typeLink.value == RedirectType.Dashboard && dashboardsList.length > 0)
			label = dashboardsList.find(x => x.value == linkLink.value)?.text;

		if (typeLink.value == RedirectType.Custom)
			label = linkLink.value ?? ''

		labelLink.update(`${label + ' '}(${typeLink.value ?? 'Default'})`)
	}

	return (
		<div className={b()}>
			<CollapsibleFrame contentPadding={false} childr enPadding={true} title={"Redirect"}>
				<FormEntry label={i('Redirect')}>
					<AntSelect dataList={getRedirectTypeDatasource()}
					           {...textValueFields}
					           valueLink={typeLink}/>
				</FormEntry>
				{typeLink.value == RedirectType.Dashboard &&
				<>
					<FormEntry label={i('Account')} width={"fit"}>
						<AntAccountDropDown valueLink={accountLink}/>
					</FormEntry>
					<FormEntry label={i('Dashboard')}>
						<AntSelect dataList={dashboardsList}
						           nameField={"tag"}
						           valueLink={dashboardLink}/>
					</FormEntry>
				</>
				}
				{typeLink.value == RedirectType.Custom &&
					<FormEntry label={i('Custom Link')}>
						<AntInput value={customLinkText}
						          onChange={v => setCustomLinkText(v)}
						          onBlur={() => linkLink.onChange(customLinkText)}/>
					</FormEntry>
				}
				<FormEntry width={"fit"}>
					<AntCheckbox {...configLink.get('linkInNewTab').props}>{i('Open in new tab')}</AntCheckbox>
				</FormEntry>
			</CollapsibleFrame>
		</div>
	)
}

let redirectTypeDatasource;

function getRedirectTypeDatasource() {
	if (!redirectTypeDatasource) {
		redirectTypeDatasource = [
			{
				text: i('Default'),
				value: RedirectType.Default,
			}, {
				text: i('Dashboard'),
				value: RedirectType.Dashboard,
			}, {
				text: i('Custom'),
				value: RedirectType.Custom
			}, {
				text: i('None'),
				value: RedirectType.None
			}]
	}
	return redirectTypeDatasource;
}
