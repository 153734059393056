import classnames from "classnames";
import PropTypes from "prop-types";
import React, {useState, useCallback} from "react";

import {Toolbar} from "controls/react/layout/toolbar";
import {Section} from "controls/react/layout/section";
import {IconButton} from "../form/iconButton";

import './collapsibleFrame.less';

export function CollapsibleFrame(props) {
	const [state, setState] = useState(props.expanded);

	const onToggle = useCallback(() => {
		setState(!state);
	}, [state]);

	return <Section appearance={"frame"}>
		<Toolbar title={props.title} >
			<IconButton iconName={state ? 'minus' : 'unchecked'}
			            onClick={onToggle}
			            embedded={true}/>
		</Toolbar>
		{state && props.children}
		{!state && <span onClick={onToggle} className={"collapsible-frame-maximize"}>{lang.widget.messages.MAXIMIZE_SETTINGS}</span>}
	</Section>
}

CollapsibleFrame.propTypes = {
	children: PropTypes.node.isRequired,
	title: PropTypes.string,
	expanded: PropTypes.bool
};

CollapsibleFrame.defaultProps = {
	expanded: true
}

export default CollapsibleFrame;
