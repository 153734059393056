import { _getCurrentItemId } from "../selectors/getCurrentItemId";

export function _setItemValue(state, path, value) {
    const itemId = _getCurrentItemId(state);

    if (!Array.isArray(path)) {
        path = [path];
    }

    return state.setIn(['items', itemId, ...path], value);
}