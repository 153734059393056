import {cancel, delay, fork, put, select} from 'redux-saga/effects';
import {getItemValue} from '../selectors/getItemValue';
import {setWorkInProgressAction} from '../actions/setWorkInProgressAction';
import {CHECK_DIRTY_TYPES, formBuilderForm, RESETABLE_TYPES} from '../constants';
import {getReduxFormValue} from '../selectors/getReduxFormValue';
import {change, isDirty} from 'redux-form';
import {setFieldDataAction} from '../actions/setFieldDataAction';
import {getCallback} from '../selectors/getCallback';
import {checkFormEnabledAction} from '../actions/checkFormStateAction';
import {newGuid} from 'tools/guid';
import {getReduxFormValues} from '../selectors/getReduxFormValues';
import {setErrorMessageAction} from 'controls/react/form/formBuilder/actions/setErrorMessageAction';

const EXCEPTIONS = ['datacollector.ws.configuration.rest.RESTGetRequest'];

export function* loadData({field, options}) {
	const spinnerTask = yield fork(function* () {
		yield delay(200);
		yield put(setWorkInProgressAction(field, true));
	});

	try {
		const properties = yield select(getReduxFormValues);

		const onData = yield select(getCallback, 'onData');

		if (!onData) {
			return;
		}

		const data = yield onData(field, {...properties, ...options});

		yield cancel(spinnerTask);

		if (data.data === null) {
			return;
		}

		yield put(setWorkInProgressAction(field, false));

		yield resetField(field, data);

		if (data.data) {
			data.version = newGuid();
		}

		yield put(setFieldDataAction(field, data));
		yield put(checkFormEnabledAction());
	} catch (e) {
		console.warn(`Data for field ${field} can't be loaded due to error: ${e.message}`);
		yield put(change(formBuilderForm, `properties.${field}`, '', false));
		yield put(setErrorMessageAction(e.message));
		yield put(setWorkInProgressAction(field, false));
		yield cancel(spinnerTask);
	}
}

function* resetField(field, data) {
	const fieldConfig = yield select(getItemValue, ['fields', field]);

	if (!RESETABLE_TYPES.has(fieldConfig.get('type'))) {
		return;
	}

	let shouldBeReset = true;

	const currentValue = yield select(getReduxFormValue, field);

	if (Array.isArray(data)) {
		for (let {value} of data) {
			if (value == currentValue) {
				shouldBeReset = false;
				break;
			}
		}
	} else if (data.data) {
		if (currentValue == data.data) {
			shouldBeReset = false;
		}
	}

	const className = yield select(getItemValue, ['original', 'className']);

	if (shouldBeReset && !EXCEPTIONS.includes(className)) {
		const itemDependencies = yield select(getItemValue, ['dependencies', field]);
		shouldBeReset = yield select(isDirty(formBuilderForm), ...Array.from(itemDependencies).map(f => `properties.${f}`))
	}

	let isFieldDirty;

	if (EXCEPTIONS.includes(className)) {
		isFieldDirty = false;
	}
	else {
		isFieldDirty = yield select(isDirty(formBuilderForm), `properties.${field}`);
	}

	const canBeReset = !(CHECK_DIRTY_TYPES.has(fieldConfig.get('type')) && isFieldDirty);
	const needsToBeUpdated = currentValue !== undefined && data.data !== undefined && currentValue != data.data;

	if (canBeReset && shouldBeReset && needsToBeUpdated) {
		console.warn(`Current, data: "${currentValue}", "${data.data}"`)
		console.warn('LoadData::Reset (overwrite) field', field);

		if (data.data) {
			yield put(change(formBuilderForm, `properties.${field}`, data.data, false));
		} else if (data.data !== undefined) {
			yield put(change(formBuilderForm, `properties.${field}`, '', false));
		}

	}
}
