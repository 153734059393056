import React from "react"
import {translator} from "core";
import {Button, TextBox, TextArea, Grid, FormEntry, ActionButtons, NumericTextBox} from "controls/react/form";
import {Window, Section, Toolbar, ToolbarItemPosition, FormLayout} from 'controls/react/layout';
import Settings from 'settings';
import Api from 'tools/api';
import CustomNotification from 'controls/customNotification';
import Utils from 'tools/utils';
import {ManagementApi} from "areas/management/api";

import './conversionWindow.less';

let i = translator({
  "Formula": {
    "en": "Formula",
    "no": "Formel"
  },
  "Decimals": {
    "en": "Decimals",
    "no": "Desimal"
  },
  "Valid formula": {
    "en": "The formula is valid",
    "no": "Formelen er gyldig"
  },
  "Valid formula output": {
    "en": "The formula is valid. Output is: ",
    "no": "Formelen er gyldig. resultatet er : "
  },
  "Not valid formula": {
    "en": "The formula is not valid",
    "no": "Formelen er ikke gyldig"
  },
  "Formula placeholder": {
    "en": "Expressions must use 'metric' to represent the metric value. Examples: metric*100",
    "no": "Uttrykket må bruke 'metric' for å representere metrikkverdien. Eksempel: metric*100"
  },
  "Conversion formula": {
    "en": "Conversion formula",
    "no": "Konverteringsformel"
  },
  "Formula tooltip": {
    "en": "This formula editor support normal operators (+-/*%^) and functions (cos, sin, log). Look at  https://www.objecthunter.net/exp4j/#Built-in_functions for more examples",
    "no": "Denne formeleditoren støtter normale operatorer (+ - / *% ^) og funksjoner (cos, sin, log). Se på https://www.objecthunter.net/exp4j/#Built-in_functions for flere eksempler"
  }
});

export class ConversionWindow extends React.PureComponent {
	constructor(props) {
		super(props);
		let formula;
		if (props.formula) {
			formula = props.formula.trim();
		} else {
			formula = '';
		}
		this.state = {
			formula: props.formula ? props.formula.trim() : '',
			decimals: parseInt(props.decimals) || 2
		};
	}

	render() {
		return (
			<div>
				<Window height={220}
						width={400}
						modal
						top={this.props.offset.top + 30}
						left={this.props.offset.left + 5}
						title={i('Conversion formula')}
						ref={window => this.window = window}
						onResize={this.onResize}
						onClose={this.props.onClose}>
					<div className="notification_container" current-conversion-id={this.props.metricId}></div>
					<Section>
						<FormLayout>
							<FormEntry vertical label={i('Formula')}>
								<span className="glyphicons question-sign conversion_formula_tooltip" title={i("Formula tooltip")}></span>
								<TextArea value={this.state.formula}
										  placeholder={i('Formula placeholder')}
										  onChange={(value) => this.onFormulaChange(value)}
								/>
							</FormEntry>
							<FormEntry label={i('Decimals')}>
								<NumericTextBox value={this.state.decimals}
												spinners={true}
												onChange={(value) => this.onDecimalsChange(value)}
												onSpin={(value) => this.onDecimalsChange(value)}
								/>
							</FormEntry>
						</FormLayout>
					</Section>
					<Toolbar containerClass="conversion_actions">
						<Button title={'Validate'}
								primary={true}
								disabled={this.state.formula === ''}
								onClick={() => this.onValidate()}
								position={ToolbarItemPosition.BEFORE_TITLE} />
						<Button title={'Update'}
								primary={true}
								onClick={() => this.onUpdate()}
								position={ToolbarItemPosition.AT_THE_END} />
						<Button title={i('Cancel')}
								primary={false}
								onClick={() => this.props.onClose()}
								position={ToolbarItemPosition.AT_THE_END} />
					</Toolbar>
				</Window>
			</div>
		)
	}

	async componentDidMount() {
		setTimeout(() => {
			$('.c-textarea').focus();
		}, 50);
		this.fetchLastValue();
		this.actionNotification = new CustomNotification({
			appendToElement: '.notification_container',
		});
	}

	async fetchLastValue() {
		let lastInfoResult = await ManagementApi.getQualifiersLastValue([{metricId: this.props.metricId}]);
		let lastValueData = lastInfoResult[0];
		if (lastValueData && lastValueData.found && lastValueData.hasMetrics) {
			this.lastValue = lastValueData.data[0].v;
		}
	}

	onFormulaChange = (value) => {
		this.setState({
			formula: value
		});
	}

	onDecimalsChange = (value) => {
		this.setState({
			decimals: value
		});
	}

	onValidate = async () => {
		let formula = this.state.formula;
		let url = `${Api.serverRoot()}metrics/validateConversion?expression=${formula}`;
		if (this.lastValue) {
			url += '&value=' + this.lastValue;
		}
		let result = await Api.fetch(url);
		let message, status;
		if (result.success) {
			status = 'success';
			if (result.data) {
				let value = result.data;
				let decimals = this.state.decimals;
				value = Utils.truncateDecimals(value, decimals);
				message = i('Valid formula output') + value;
			} else {
				message = i('Valid formula');
			}
		} else {
			message = i('Not valid formula');
			status = 'error';
		}
		this.actionNotification.setOptions({
			message: message,
			status: status
		}).show();
	}

	onUpdate = () => {
		let formula = this.state.formula.trim();
		let decimals = this.state.decimals;
		this.props.onUpdate(formula, decimals);
	}

	onResize() {
		let newHeight = this.wrapper.height() - 50;
		let content = $('.notification_container').next('.section');
		content.css('height', newHeight);
		let textAreaRows = parseInt((newHeight - 100) / 20);
		content.find('textarea').attr('rows', textAreaRows);
	}
}

