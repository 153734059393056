import Application from 'core/application';
import CustomNotification from 'controls/customNotification';
import EventsToolbar from 'controls/eventsToolbar';
import GridMenu from 'controls/gridMenu';
import PreferencesPanel from 'controls/preferencesPanel';
import Dialog from 'controls/dialog';
import Settings from 'settings';
import Cookies from 'core/cookies';
import ErrorHandler from 'core/errorHandler';
import RemoteEventsManager from 'core/remoteEventsManager';
import UserPrefs from 'tools/userPrefs';
import State from 'tools/state';
import Utils from 'tools/utils';

/**
 * @class ceeview.management.SystemErrors This class handles the System Errors view
 * @namespace ceeview.management
 * @extends Application
 * @constructor
 * @param {Object} config The config object
 */
export default function SystemErrors (config) {
    Utils.apply(this, config);
    this.module = {
        name: 'ManagementSystemErrors',
        initialConfig: config
    };
    this.hasEvents = true;
    this.subscriberId = Utils.guid();
    this.initComponent();
};

jQuery.extend(SystemErrors.prototype, Application.prototype, {
    /**
     * Main init function
     */
    initComponent: function () {
		Application.prototype.initComponent.call(this);


		let item = $('.cw_systemerror_notifications');
		item.siblings().removeClass('is_selected');
       	item.addClass('is_selected');
        this.showGridView = true;
        this.loadUserPreferences();
        this.removeListeners();
        this.attachListeners();
        this.fromTime = 0;
        this.toTime = new Date().getTime();
        this.showSubAccounts = false;

        this.actionNotification = new CustomNotification({
            appendToElement: '.cw_section_content',
        });
        this.unAcknowledgedOnly = true;
        if (State.mainApp.session.hasRole('SYSTEM_ERROR_LIST')) {
            this.showSubAccounts = true;
        }
    },
    /**
     * Called in order to remove existing listeners
     */
    removeListeners: function () {
        $('.cw_system_errors_timerange').off();
        $('#cw_system_errors_logs').off();
    },
    /**
     * Attaches the listeners
     */
    attachListeners: function () {
        $('.cw_system_errors_timerange').on('click', 'li', $.proxy(this.onSetInterval, this));
        $('#cw_system_errors_logs').on('click', '.cw_grid_check', $.proxy(this.onSystemErrorCheck, this));
        $('#cw_system_errors_logs').on('click', '.cw_grid_check_all', $.proxy(this.onSystemErrorCheckAll, this));
        $('#cw_system_errors_logs').on('click', '.cw_agent_link', $.proxy(this.onAgentNameClick, this));
        $(window).off().on('resize', $.proxy(this.adjustSectionHeight, this));
    },
    /**timke
     * Handler for the period toggle
     * @param {Object} e The click event object
     */
    onSetInterval: function (e) {
        var newUrl, url;
        this.timeSelector = $(e.currentTarget).find('input').val();
        $('.cw_system_errors_timerange').find('li').removeClass('is_selected');
        $(e.currentTarget).addClass('is_selected');
        url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/systemErrors?includeSubaccounts=' + State.includeSubaccounts.toString() + '&unAcknowledgedOnly=' + this.unAcknowledgedOnly + '&fromTime=' + this.fromTime + '&toTime=' + this.toTime + '&timeSelector=' + this.timeSelector;
        if (url.indexOf('timeSelector=') !== -1) {
            newUrl = Utils.replaceUrlParam(url, 'timeSelector', this.timeSelector);
            this.urlParameters = Utils.replaceUrlParam(this.urlParameters, 'timeSelector', this.timeSelector);
        } else {
            newUrl = url + '&timeSelector=' + this.timeSelector;
            this.urlParameters = this.urlParameters + '&timeSelector=' + this.timeSelector;
        }
        this.dataSource.options.transport.read.url = newUrl;
        this.dataSource.page(1);
    },
    /**
     * Loads user preferences
     */
    loadUserPreferences: function () {
        this.userPref = [];
        UserPrefs.load('ManagementSystemErrors', $.proxy(function (result) {
            if (result.success) {
                this.userPref = result.data;
                this.initKendoComponents();
                this.removeMask();
            } else {
                Utils.showInfo(lang.ALERT, result.message, result.details);
            }
        }, this));
    },
    /**
     * Initialize kendo components
     */
    initKendoComponents: function () {
        var systemErrorsSort, systemErrorsFilter,
            defaultFsView = UserPrefs.get('defaultFsView'),
            filterMessages = lang.grid.filter,
            pref;
        this.timePeriod = UserPrefs.get('timePeriod') || 'LASTDAY';
        if (this.timePeriod) {
            $('.cw_system_errors_timerange').find('li').removeClass('is_selected');
            $('.cw_system_errors_timerange').find('input').prop('checked', false);
            $('input[value="' + this.timePeriod + '"]').prop('checked', true).closest('li').addClass('is_selected');
        }
        var eventStatus = JSON.parse(UserPrefs.get('eventsStatus')) || {
                startFrom: 30,
                playing: true
            };
        this.eventsToolbar = new EventsToolbar({
            renderTo: $('#cw_system_messages_events_toolbar'),
            callBackFn: $.proxy(this.onEventsTimeout, this),
	        subscriberId: this.subscriberId,
            startFrom: eventStatus.startFrom,
            playing: eventStatus.playing
        });
        this.defaultColumns = {
            notificationId: {
                hidden: false,
                width: 40
            },
            code: {
                hidden: false,
                width: 120
            },
            message: {
                hidden: false,
                width: 280
            },
            accountName: {
                hidden: !this.showSubAccounts,
                width: 150
            },
            suppressedAtDate: {
                hidden: false,
                width: 150
            },
            stackTrace: {
                hidden: false,
                width: 240
            },
            agentName: {
                hidden: false,
                width: 120
            },
            acknowledgedBy: {
                hidden: false,
                width: 170
            },
            acknowledgedAtDate: {
                hidden: false,
                width: 220
            },
            suppressionCounter: {
                hidden: false,
                width: 150
            },
            date: {
                hidden: false,
                width: 160
            },
            description: {
                hidden: false,
                width: 140
            }
        };

        if (Utils.isGuid(defaultFsView)) {
            pref = JSON.parse(UserPrefs.get(defaultFsView));
            pref = pref || {
                    sort: [],
                    filter: []
                };
            systemErrorsSort = pref.sort;
            systemErrorsFilter = pref.filter;
            this.systemErrorsColumns = pref.columns;
        } else {
            if (UserPrefs.get('systemErrorsSort')) {
                systemErrorsSort = JSON.parse(UserPrefs.get('systemErrorsSort'));
            } else {
                systemErrorsSort = [{
                    field: 'date',
                    dir: 'desc',
                    compare: null
                }];
            }
            if (UserPrefs.get('systemErrorsFilter')) {
                systemErrorsFilter = JSON.parse(UserPrefs.get('systemErrorsFilter'));
            } else {
                systemErrorsFilter = [];
            }
        }
        this.gridMessages = {
            isTrue: '<span class="glyphicons service_state ok-sign"></span>',
            isFalse: '<span class="glyphicons service_state remove-sign"></span>',
            clear: lang.CLEAR,
            info: lang.grid.filter.SHOW_ITEMS,
            filter: lang.FILTER
        };

		this.baseUrl = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/systemErrors';
		this.urlParameters = '?includeSubaccounts=' + State.includeSubaccounts.toString() + '&timeSelector=' + this.timePeriod + '&unAcknowledgedOnly=' + this.unAcknowledgedOnly;
		var url = this.baseUrl + this.urlParameters;

        this.dataSource = new kendo.ceeview.DataSource({
            transport: {
                read: {
                    url: url,
                    contentType: "application/json; charset=utf-8",
                    type: 'POST',
                    dataType: 'json',
                    cache: false
                },
                parameterMap: $.proxy(function (data, type) {
                    var i, length, value;
                    if (type === 'read') {
                        if (data.filter && data.filter.filters.length) {
							data.filter.filters = Utils.changeDateFilterToString(data.filter.filters);
                            length = data.filter.filters.length;
                            for (i = 0; i < length; i++) {
                                if (data.filter.filters[i].field === 'date' || data.filter.filters[i].field === 'acknowledgedAtDate' || data.filter.filters[i].field === 'suppressedAtDate') {
                                    value = Utils.customDateToString(data.filter.filters[i].value, 'YYYY-MM-DD HH:mm:ss');
                                    data.filter.filters[i].value = value;
                                }
                            }
                        }
                        if (data.pageSize < 0) {
                            //console.log('Page size: ' + data.pageSize);
                        }
                        if (data.take < 0) {
                            //console.log('Page size: ' + data.take);
                        }
                        this.gridPayload = data;
                        return kendo.stringify(data);
                    }
                }, this)
            },
            schema: {
                data: function (response) {
                    return response.items;
                },
                total: function (response) {
                    this.visibleItems = response.visible;
                    this.totalItems = response.total;

                    return response.visible;
                },
                model: {
                    id: 'notificationId',
                    fields: {
                        date: {
                            type: 'date'
                        },
                        acknowledgedAtDate: {
                            type: 'date'
                        },
                        suppressedAtDate: {
                            type: 'date'
                        },
                        suppressionCounter: {
                            type: 'number'
                        }
                    }
                },
                parse: function (data) {
                    for (var i = 0, length = data.items.length; i < length; i++) {
                        var value = new Date(data.items[i].time);
                        data.items[i].date = value;
                        data.items[i].acknowledgedAtDate = new Date(data.items[i].acknowledgedAt);
                        data.items[i].suppressedAtDate = new Date(data.items[i].suppressedAt);
                    }
                    return data;
                }
            },
            serverSorting: true,
            serverPaging: true,
            serverFiltering: true,
            sort: systemErrorsSort,
            filter: systemErrorsFilter,
			change: $.proxy(function() {
				if (JSON.stringify(this.currentGridFilter) !== JSON.stringify(this.dataSource.filter())) {
					this.filteringChanged = true;
				} else {
					this.filteringChanged = false;
				}
				if (this.filteringChanged && this.selectedIds) {
					this.selectedIds = null;
					$('.cw_grid_check_all').prop('checked', false);
					$('.cw_grid_check').prop('checked', false);
					this.onSystemErrorCheck();
				}
				this.currentGridFilter = this.dataSource.filter();
			}, this),
            error: ErrorHandler.kendoServerError
        });

        var systemErrorsColumns = this.systemErrorsColumns || JSON.parse(UserPrefs.get('systemErrorsColumns')) || this.defaultColumns;
        systemErrorsColumns = Utils.completeColumns(systemErrorsColumns, this.defaultColumns);
        var sectionHeight = $('.cw_section_full').height();
        var gridContent = sectionHeight - 60;
        this.dataSource.pageSize(Math.floor(gridContent / 22));
        this.grid = $('#cw_system_errors_logs').kendoCustomGrid({
            dataSource: this.dataSource,
			cacheScrollCheckboxes: true,
            resizable: true,
			reorderable: true,
            autoBind: false,
            selectable: 'row',
            scrollable: {
                virtual: true
            },
            filterable: {
                extra: false,
                operators: {
                    string: {
                        contains: filterMessages.CONTAINS,
                        eq: filterMessages.EQ
                    },
                    date: {
                        eq: filterMessages.EQ,
                        gte: filterMessages.IAE,
                        gt: filterMessages.IA,
                        lte: filterMessages.IBE,
                        lt: filterMessages.IB
                    }
                },
                messages: this.gridMessages
            },
            sortable: {
                mode: 'multiple',
                allowUnsort: true
            },
            columns: Utils.rearrangeColumns([{
                field: 'notificationId',
                title: lang.SELECTOR,
                menu: false,
                filterable: false,
                sortable: false,
                template: '<input type="checkbox" class="cw_grid_check" data-id="${notificationId}" />',
                headerTemplate: '<input type="checkbox" class="cw_grid_check_all" />',
                attributes: {
                    'class': 'text_center'
                },
                headerAttributes: {
                    'class': 'text_center'
                },
                hidden: systemErrorsColumns.notificationId.hidden,
                width: systemErrorsColumns.notificationId.width
            }, {
                field: 'code',
                title: lang.management.ERROR_CODE,
                sortable: true,
                filterable: true,
                hidden: systemErrorsColumns.code.hidden,
                width: systemErrorsColumns.code.width,
	            attributes: {
		            'class': 'tooltip ellipsis to_expand'
	            }
            }, {
                field: 'message',
                title: lang.MESSAGE,
                sortable: true,
                filterable: true,
                hidden: systemErrorsColumns.message.hidden,
	            attributes: {
		            'class': 'tooltip ellipsis to_expand'
	            },
                width: systemErrorsColumns.message.width
            }, {
                field: 'accountName',
                title: lang.ACCOUNT,
                sortable: true,
                template: '<span>#= accountName || "N/A" #</span>',
                filterable: {
                    ui: $.proxy(function (element) {
                        return Renderer.filterGridByOwnElementDS.call(this, element, 'accountName');
                    }, this),
                    messages: this.gridMessages,
                    extra: false,
                    operators: {
                        string: {
                            neq: filterMessages.NEQ,
                            eq: filterMessages.EQ
                        }
                    }
                },
                hidden: !State.includeSubaccounts || systemErrorsColumns.accountName.hidden,
                width: systemErrorsColumns.accountName.width,
	            attributes: {
		            'class': 'tooltip ellipsis to_expand'
	            }
            }, {
                field: 'suppressedAtDate',
                title: lang.TIME,
                template: '#= Renderer.browserDateRenderer(suppressedAt, Constants.DATETIME)#',
                filterable: {
                    ui: function (element) {
                        element.kendoDateTimePicker({
                            format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
                            timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat)
                        });
                    }
                },
                sortable: true,
                hidden: systemErrorsColumns.suppressedAtDate.hidden,
                width: systemErrorsColumns.suppressedAtDate.width,
	            attributes: {
		            'class': 'tooltip ellipsis to_expand'
	            }
            }, {
                field: 'stackTrace',
                title: lang.service.STACK_TRACE,
                sortable: true,
                filterable: true,
	            attributes: {
		            'class': 'tooltip ellipsis to_expand'
	            },
                hidden: systemErrorsColumns.stackTrace.hidden,
                width: systemErrorsColumns.stackTrace.width
            }, {
                field: 'agentName',
                title: lang.AGENT,
                sortable: true,
                filterable: true,
                hidden: systemErrorsColumns.agentName.hidden,
                width: systemErrorsColumns.agentName.width,
                template: function (data) {
                    var template = '';
                    if (data.agentName) {
                        if (data.agentName.indexOf('cw_label')>-1){
                            template += data.agentName;
                        } else{
                            template += '<span class="cw_grid_link cw_agent_link ellipsis to_expand" data-agentid="' + data.agentId + '"> ' + data.agentName + ' </span>';
                        }
                    } else {
                        template += '<span class="cw_label"> #= lang.NA # </span>';
                    }
                    return template;
                },
	            attributes: {
		            'class': 'tooltip ellipsis to_expand'
	            }
            }, {
                field: 'acknowledgedAtDate',
                title: lang.service.ACKNOWLEDGED_DATE,
                template: '<span class="cw_action_log_details pointer"> #= Renderer.browserDateRenderer(acknowledgedAt, Constants.DATETIME)#</span>',
                filterable: {
                    ui: function (element) {
                        element.kendoDateTimePicker({
                            format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
                            timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat)
                        });
                    }
                },
                sortable: true,
                hidden: systemErrorsColumns.acknowledgedAtDate.hidden,
                width: systemErrorsColumns.acknowledgedAtDate.width,
	            attributes: {
		            'class': 'tooltip ellipsis to_expand'
	            }
            }, {
                field: 'acknowledgedBy',
                title: lang.service.ACKNOWLEDGED_BY,
                sortable: true,
                hidden: systemErrorsColumns.acknowledgedBy.hidden,
                width: systemErrorsColumns.acknowledgedBy.width,
	            attributes: {
		            'class': 'tooltip ellipsis to_expand'
	            }
            }, {
                field: 'suppressionCounter',
                title: lang.management.SUPPRESSION_COUNT,
                sortable: true,
                hidden: systemErrorsColumns.suppressionCounter.hidden,
                width: systemErrorsColumns.suppressionCounter.width,
	            attributes: {
		            'class': 'tooltip ellipsis to_expand'
	            }
            }, {
                field: 'date',
                title: lang.incidents.CREATED,
                template: '#= Renderer.browserDateRenderer(time, Constants.DATETIME)#',
                sortable: true,
                filterable: {
                    ui: function (element) {
                        element.kendoDateTimePicker({
                            format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
                            timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat)
                        });
                    }
                },
                hidden: systemErrorsColumns.date.hidden,
                width: systemErrorsColumns.date.width,
	            attributes: {
		            'class': 'tooltip ellipsis to_expand'
	            }
            }, {
                field: 'description',
                title: lang.DESCRIPTION,
                template: '#= description #',
	            attributes: {
		            'class': 'tooltip ellipsis to_expand'
	            },
                sortable: true,
                filterable: true,
                hidden: systemErrorsColumns.description.hidden,
                width: systemErrorsColumns.description.width
            }], systemErrorsColumns),
            columnMenu: true,
            change: $.proxy(this.onRowExpand, this),
            dataBound: $.proxy(this.onGridDataBound, this)
        }).data('kendoCustomGrid')
        // Add Kendo tooltip to the header of the columns
        Utils.gridColumnHeaderTooltip(this.grid);
        this.adjustSectionHeight();
        this.grid.thead.find("[data-field='notificationId']>.k-header-column-menu").remove();
        this.gridMenu = new GridMenu({
            renderTo: 'cw_system_errors_menu',
            items: [{
                id: 'cw_syserror_delete',
                icon: 'bin',
                text: lang.DELETE,
                fn: this.onDelete,
                role: State.mainApp.session.hasV2Permissions ? 'MESSAGE_DELETE' : undefined,
                scope: this,
                disabled: true
            }, {
                id: 'cw_syserror_acknowledge',
                icon: 'ok',
                text: lang.management.ACKNOWLEDGE,
                fn: this.onAcknowledge,
                role: State.mainApp.session.hasV2Permissions ? 'MESSAGE_UPDATE' : undefined,
                scope: this,
                disabled: true
            }, {
                id: 'cw_syserror_acknowledge_all',
                icon: 'ok',
                text: lang.management.ACKNOWLEDGE_ALL,
                role: State.mainApp.session.hasV2Permissions ? 'MESSAGE_UPDATE' : undefined,
                fn: this.onAcknowledgeAll,
                scope: this
            }, {
                id: 'cw_syserror_show_all',
                icon: 'list',
                text: lang.management.SHOW_ALL,
                fn: this.onShowAllLogsToggle,
                scope: this
            }, {
                id: 'cw_syserror_show_not_acknowledged',
                icon: 'show-thumbnails-with-lines',
                text: lang.management.SHOW_NOT_ACKNOWLEDGED,
                fn: this.onShowAllLogsToggle,
                disabled: true,
                scope: this
            }]
        });
        var searchValue = UserPrefs.get('defaultSystemErrorsFsView') ? '' : (UserPrefs.get('systemErrorsSearchPhrase') || '');
        this.gridFilterPanel = new PreferencesPanel({
            renderTo: 'cw_system_errors_filters',
            grid: this.grid,
            counter: true,
            modulePrefName: 'ManagementSystemErrors',
            defaultPrefViewKey: 'defaultSystemErrorsFsView',
            prefListKey: 'systemErrorsFSViews',
            userPref: this.userPref,
            onRemove: $.proxy(this.saveUserPreferences, this),
            searchFields: ['stackTrace', 'message', 'description', 'accountName'],
            searchValue: searchValue,
            defaultColumns: this.defaultColumns
		});
    },
    /**
     * Handler function for the selection of one service log grid row
     * @param {Object} e The expand event object
     */
    onRowExpand: function (e) {
        var selectedRow = this.grid.select();
        var myRow = selectedRow[0];
        var stackTraceEl = $(myRow).children('td:nth-child(6)');
        var messageEl = $(myRow).children('td:nth-child(3)');

        if ($(stackTraceEl).hasClass('cw_message_expanded')) {
            $(stackTraceEl).removeClass('cw_message_expanded').addClass('ellipsis');
            $(messageEl).removeClass('cw_message_expanded').addClass('ellipsis');
        } else {
            $('#cw_system_errors_logs').find('.k-grid-content').find('td.cw_message_expanded').removeClass('cw_message_expanded').addClass('ellipsis');
            $(stackTraceEl).addClass('cw_message_expanded').removeClass('ellipsis');
            $(messageEl).addClass('cw_message_expanded').removeClass('ellipsis');
        }
    },
    /**
     * Handler function for the selection of one system errors log grid row
     * @param {Object} e The databound event object
     */
    onGridDataBound: function (e) {
        var dataSource = this.grid.dataSource;
        if (dataSource.filter()) {
            $('.cw_window_actions').find('.restart').removeClass('inactive');
        }
        var scrollBack = function () {
            if (this.reloadLogsPage) {
                this.grid.dataSource.page(this.currentLogsPage);
                //$('#cw_system_errors_logs').find('.k-scrollbar-vertical').scrollTop(this.scrollTop);
                this.reloadLogsPage = false;
            }
        };
        setTimeout($.proxy(scrollBack, this), 200);
        $('#cw_system_errors_logs').find('.k-grid-content').kendoTooltip({
            filter: "td.tooltip:not(:empty)",
            content: function (event) {
                return event.target.text().split("\n").join("<br />");
            }
        });
    },
    /**
     * Method by Andy
     */
    adjustSectionHeight: function () {
        var section = $('.cw_section_full');
        var sectionHeight = section.height();
        section.find('.cw_section_content').css('height', sectionHeight);
        section.find('.k-grid-content').first().css('height', sectionHeight - 40);
    },
    /**
     * Saves user preferences
     */
    saveUserPreferences: function () {
        var value = $('.cw_system_errors_timerange li.is_selected input').val();
        if (value === 'CUSTOM' && !this.hasCustomPeriod) {
            value = this.userPref[7].value;
        }
        var preferences = [{
            key: 'timePeriod',
            value: value
        }];
        this.saveUserPrefs({
            category: 'ManagementSystemErrors',
            preferences: preferences,
            filterPanel: this.gridFilterPanel,
            grid: this.grid,
            eventsToolbar: this.eventsToolbar,
            keys: {
                searchPhrase: 'systemErrorsSearchPhrase',
                columns: 'systemErrorsColumns',
                sort: 'systemErrorsSort',
                filter: 'systemErrorsFilter',
                FsViews: 'systemErrorsFSViews',
                defaultFsView: 'defaultSystemErrorsFsView'
            }
        });
    },
    /**
     * Handler function for the click event on the Acknowledge all menu item
     **/
    onAcknowledgeAll: function () {
        var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/systemErrors/acknowledgeAll?fromTime=' + this.fromTime + '&toTime=' + this.toTime + '&includeSubaccounts=' + State.includeSubaccounts.toString();
        if (this.timeSelector) {
            url += '&timeSelector=' + this.timeSelector;
        }
        this.currentLogsPage = this.grid.dataSource.page();
        this.gridMenu.disableItem('cw_syserror_acknowledge');
        this.gridMenu.disableItem('cw_syserror_delete');
        Utils.ajax(url, 'POST', {}, $.proxy(function (result) {
            if (result.success) {
                this.reloadLogsPage = true;
                this.grid.dataSource.read();
                $('#cw_system_errors_logs').find('.cw_grid_check_all').prop('checked', false);
                State.mainApp.getStatusesCount();
            } else {
                Utils.showInfo(lang.ALERT, result.message, result.details);
            }
        }, this));
    },
    /**
     * Handler function for the click event on the Acknowledge menu item
     **/
    onAcknowledge: function () {
        var checkboxes = $('#cw_system_errors_logs').find('.cw_grid_check');
        var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/systemErrors/acknowledge';
        var logs = [];
        this.currentLogsPage = this.grid.dataSource.page();
        for (var i = 0, length = checkboxes.length; i < length; i++) {
            if ($(checkboxes[i]).is(':checked')) {
                logs.push($(checkboxes[i]).data('id'));
            }
        }
        logs = this.selectedIds || logs;
        if (logs.length) {
            this.gridMenu.disableItem('cw_syserror_acknowledge');
            this.gridMenu.disableItem('cw_syserror_delete');
            Utils.ajax(url, 'POST', JSON.stringify(logs), $.proxy(function (result) {
                if (result.success) {
                    this.reloadLogsPage = true;
                    this.grid.dataSource.read();
                    $('#cw_service_logs').find('.cw_grid_check_all').prop('checked', false);
                    State.mainApp.getStatusesCount();
                } else {
                    Utils.showInfo(lang.ALERT, result.message, result.details);
                }
            }, this));
        }
    },
    /**
     * Handler function for the Show not acknowledge menu item
     * @param {Object} e The click event object
     */
    onShowAllLogsToggle: function (e) {
        var currentUrl = this.grid.dataSource.options.transport.read.url;
        var newUrl = '';
        this.gridMenu.disableItem('cw_syserror_acknowledge');
        this.gridMenu.disableItem('cw_syserror_delete');
        $('#cw_system_errors_logs').find('.cw_grid_check_all').prop('checked', false);
        $('#cw_system_errors_logs').find('.cw_grid_check').prop('checked', false);
        if ($(e.currentTarget).attr('id') === 'cw_syserror_show_all') {
            this.unAcknowledgedOnly = false;
            this.gridMenu.disableItem('cw_syserror_show_all');
            this.gridMenu.enableItem('cw_syserror_show_not_acknowledged');
        } else {
            this.unAcknowledgedOnly = true;
            this.gridMenu.enableItem('cw_syserror_show_all');
            this.gridMenu.disableItem('cw_syserror_show_not_acknowledged');
        }

        if (currentUrl.indexOf('unAcknowledgedOnly') !== -1) {
        	newUrl = Utils.replaceUrlParam(currentUrl, 'unAcknowledgedOnly', this.unAcknowledgedOnly);
        	this.urlParameters = Utils.replaceUrlParam(this.urlParameters, 'unAcknowledgedOnly', this.unAcknowledgedOnly);
		} else {
			newUrl = currentUrl + '&unAcknowledgedOnly=' + this.unAcknowledgedOnly;
			this.urlParameters = this.urlParameters + '&unAcknowledgedOnly=' + this.unAcknowledgedOnly;
		}
		
        this.grid.dataSource.options.transport.read.url = newUrl;
        this.grid.dataSource.read();
    },
    /**
     * Handler function for the Show not acknowledge menu item
     */
    onDelete: function () {
        var url = Settings.serverPath + 'notifications/types/SYSTEM_ERROR/delete';
        var checkboxes = $('#cw_system_errors_logs').find('.cw_grid_check');
        var notifications = [];
        for (var i = 0, length = checkboxes.length; i < length; i++) {
            if ($(checkboxes[i]).is(':checked')) {
                notifications.push($(checkboxes[i]).data('id'));
            }
        }
        notifications = this.selectedIds || notifications;
        if (notifications.length) {
            var dialog = new Dialog({
                title: lang.INFO,
                msg: notifications.length > 1 ? lang.management.messages.LOGS_REMOVE_CONFIRMATION : lang.management.messages.LOG_REMOVE_CONFIRMATION,
                icon: 'ERROR',
                actionText: 'DELETE',
                fn: $.proxy(function (value, button) {
                    if (button === 'ok') {
                        this.gridMenu.disableItem('cw_syserror_delete');
                        this.gridMenu.disableItem('cw_syserror_acknowledge');
                        Utils.ajax(url, 'POST', JSON.stringify(this.selectedIds || notifications), $.proxy(function (result) {
                            if (result.success) {
                                this.actionNotification.setOptions({
                                    message: lang.management.messages.LOGS_DELETED,
                                    status: 'success'
                                }).show();
                                // reload data source
                                this.grid.dataSource.read();
                                State.mainApp.getStatusesCount();
                            } else {
                                Utils.showInfo(lang.ALERT, result.message, result.details, this.grid);
                            }
                        }, this));
                    }
                }, this),
				deleteFromGrid: this.grid
            });
            dialog.show();
        }
    },
    /**
     * Handler for the system error checkbox click
     * @param {Object} e The click event
     */
    onSystemErrorCheck: function (e) {
        var checkboxes = $('#cw_system_errors_logs').find('.cw_grid_check');
        var count = 0;
		var selectAllIndex;
		this.filteringChanged = false;
        for (var i = 0, length = checkboxes.length; i < length; i++) {
			if (this.selectedIds) {
				selectAllIndex = this.selectedIds.indexOf(checkboxId);
			}
            if ($(checkboxes[i]).is(':checked')) {
                count++;
				if (selectAllIndex === -1) {
					this.selectedIds.push(checkboxId);
				}
            } else {
				if (selectAllIndex !== undefined && selectAllIndex !== -1) {
					this.selectedIds.splice(selectAllIndex, 1);
				}
            }
        }
        if (count) {
            this.gridMenu.enableItem('cw_syserror_delete');
            this.gridMenu.enableItem('cw_syserror_acknowledge');
        } else {
            this.gridMenu.disableItem('cw_syserror_delete');
            this.gridMenu.disableItem('cw_syserror_acknowledge');
        }
    },
    /**
     * Handler for the check all checkbox click
     * @param {Object} e The click event
     */
    onSystemErrorCheckAll: function (e) {
        var checkAll = $('#cw_system_errors_logs').find('.cw_grid_check_all').is(':checked');
        var checkboxes = $('#cw_system_errors_logs').find('.cw_grid_check');
        var i, length = checkboxes.length;
        for (i = 0; i < length; i++) {
            $(checkboxes[i]).prop('checked', checkAll);
        }
        if (checkAll && checkboxes.length) {
            this.gridMenu.enableItem('cw_syserror_delete');
            this.gridMenu.enableItem('cw_syserror_acknowledge');
			this.getAllIds();
        } else {
            this.gridMenu.disableItem('cw_syserror_delete');
            this.gridMenu.disableItem('cw_syserror_acknowledge');
			this.selectedIds = null;
        }
    },
	getAllIds: function () {
		let url = this.baseUrl + '/selectAll' + this.urlParameters;
		Utils.ajax(url, 'POST', JSON.stringify(this.gridPayload), $.proxy(function (result) {
			if (result.success) {
				this.selectedIds = result.data;
			}
		}, this));
	},
    onEventsTimeout: function (events) {
        this.grid.dataSource.read();
    },
    /**
     * Subscribes to the events
     */
    subscribe: function () {
        this.isDataSourceSubscribed = true;
        var subscriptionObj = [{
            eventType: 'Notification',
            notificationType: 'SYSTEM_ERROR',
            includeSubaccounts: State.includeSubaccounts.toString()
        }];
        RemoteEventsManager.subscribe(this.subscriberId, subscriptionObj);
    },
    /**
     * Destroy method
     */
    destroy: function () {
        if (this.eventsToolbar) {
            this.eventsToolbar.destroy();
        }
        this.saveUserPreferences();
        Application.prototype.destroy.call(this);
    }
});
