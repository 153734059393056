import {InputNumber, InputNumberProps} from "antd";
import { valueType } from "antd/lib/statistic/utils";
import { observer } from "mobx-react";
import React, {ChangeEventHandler, KeyboardEventHandler} from "react";

import {addEnterEscHandlers, captureValueLink} from "controls/react/ant/utils";


export enum NumberKeysPattern {
	PositiveInteger = '^[0-9]+$',
	Integer = '^(\-)?[0-9]+$',
	PositiveDecimal = '^([\.0-9]+)(\.)?([0-9]*)$',
	Decimal = '^(\-)?([0-9]+)(\.)?([0-9]*)$',
	DecimalOrEmpty = '^((\-)?([0-9]+)(\.)?([0-9]*))$|^$',
	PositiveDecimalOrEmpty = '^(([0-9]+)(\.[0-9]+)?)$|^$'
}

export interface AntInputNumberProps extends Omit<InputNumberProps, 'onChange'> {
	valueLink?: any,
	invalid?: boolean,
	errors?: Array<any>,
	onChange?: (newValue: string | number, e: ChangeEventHandler<string | number>) => void,
	ref?: any,
	pattern?: string,
	onKeyUp?: KeyboardEventHandler<HTMLInputElement>,
	onPressEnter?: () => void,
	onPressEsc?: () => void,
	onInvalidInput?: () => void
}

export const AntInputNumber:React.FunctionComponent<AntInputNumberProps> = observer(React.forwardRef((props, ref) => {
	let {errors, invalid, pattern, onChange, onKeyDown, onInvalidInput, ...antNativeProps} = captureValueLink(props);

	const validateKeyNumber = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if(!e.ctrlKey && e.key.length == 1 && !e.key.match(/^[\-\.0-9]$/)) {
			e.preventDefault();
			return;
		}
		if (onKeyDown) {
			onKeyDown(e);
		}
	}

	const validateNumber = (e: valueType) => {
		if (pattern === undefined) {
			pattern = NumberKeysPattern.DecimalOrEmpty;
		}

		if(e && !e.toString().match(pattern)) {
			onInvalidInput?.();
			return;
		}
		if (onChange) {
			onChange(e, null);
		}
	}

	let wrappedProps = addEnterEscHandlers(antNativeProps);

	return <InputNumber ref={ref} onKeyDown={validateKeyNumber} onChange={validateNumber} {...wrappedProps}/>
}));
