import CustomNotification from 'controls/customNotification';
import RemoteEventsManager from 'core/remoteEventsManager';
import ErrorCodes from 'tools/errorCodes';
import Utils from "tools/utils";
import MultiToggle from 'controls/multiToggle';
import Cookies from 'core/cookies';
import Settings from "settings";
import React from "react";
import ReactDOM from "react-dom";
import WidgetInfoContainer from "../../controls/react/widgetInfoContainer";
import State from 'tools/state';
import {loadQualifierDetails } from "../services/designer/qualifierWizard/wizardSave";
import { aggregationTypeDataSource } from './widgets/common/dataSources/aggregationTypeDataSource';
import LocalEventsManager from 'core/localEventsManager';

import "./widget.less";
import Lang from "core/localization/lang";

const i = require('core/localization').translator({
	'Y-axis': {
		no:'Y-akse'
	},
	'Dynamic': {
		no: 'Dynamikk'
	},
	"Zero": {
		no: 'Null'
	}
});

export let Widget = function (config) {
	Utils.apply(this, config);

	this.isDashboard = this.configuration.isDashboard || $('.html-shape-container').length > 0;
	if (State.currentApp?.dashboardDesigner?.props?.mode === 'designer') {
		this.isDashboardDesigner = true;
	}

	//new widgets does not use configuration property and store everything directly on config
	this.instanceConfiguration = JSON.parse(JSON.stringify(config.configuration || config));
};
export default Widget;

Widget.prototype = {
	getRequestPath() {
		if (this.sessionId) {
			return Settings.serverPath + 'sessions/' + this.sessionId + '/';
		}
		else {
			return Settings.serverPath;
		}
	},

	/*
	* Handler function for creating the widget controls
	* */
	createCustomControls: function (hideToggleTimeSelector, isSlaServiceHistory) {
		this.isSlaServiceHistory = isSlaServiceHistory;
		this.target = $(this.customControls.target);
		if (this.target.closest('.cw_freeform_widget').length) {
			this.target = this.target.closest('.k-window');
			this.isFreeServiceBoard = true;
		}
		if (this.target.hasClass('cw_widget')) {
			this.isServiceBoard = true;
		}

		let widgetSettings = this.target.find('.cw_widget_settings');
		if (this.removeContainer) {
			widgetSettings.remove();
		}
		if (widgetSettings.length) {
			widgetSettings.remove();
		}
		let id = this.instanceConfiguration.serviceQualifierId || this.instanceConfiguration.monitorId;
		this.target.append('<div id="' + id + '" class="cw_widget_settings cw_settings_overlay" data-monitorid="' + this.instanceConfiguration.monitorId + '" data-servicequalifierid="' + this.instanceConfiguration.serviceQualifierId + '"></div>');
		this.target.find('.cw_widget_settings').css('height', this.target.height());
		if (this.isFreeServiceBoard) {
			this.target.find('.cw_widget_settings').css({
				'height': this.target.height() - 31,
				'top': '31px'
			})
		}
		if (this.isServiceBoard || this.isDashboard) {
			this.target.find('.cw_widget_settings').css({
				'top': '40px'
			})
		}

		if (this.target.find('.period_multi_toggle').length) {
			this.target.find('div.period_multi_toggle').remove();
		}

		var currentSelector;
		if (this.target.find('.period_multi_toggle').length) {
			currentSelector = this.target.find('.period_multi_toggle');
		} else if (this.target.find('#period_multi_toggle').length) {
			currentSelector = this.target.find('#period_multi_toggle');
		}

		if (this.hasTimeSelector || this.hasToggleTimeSelector) {
			if (currentSelector) {
				currentSelector.remove();
			}
			this.target.append('<div class="period_multi_toggle"></div>');
			let toggleContainer = this.target.find('.period_multi_toggle');
			let optionsContainer = this.target.find('.cw_toggle_options');
			if (this.target.width() < 398) {
				toggleContainer.addClass('cw_dropdown_time_selector');
				if (this.isServiceBoard || this.isDashboard) {
					toggleContainer.css({
						'top': '4px',
						'right': '55px'
					});
				}
				if (this.isAssetDetails) {
					toggleContainer.css({
						'right': '55px'
					});
				}
				if (this.isDashboard) {
					optionsContainer.css({
						'position': 'absolute',
						'top': '9px',
						'right': '138px'
					});
				}
				this.hasTimeSelector = true;
				this.hasToggleTimeSelector = false;
			} else {
				toggleContainer.addClass('cw_multitoggle_time_selector');
				if (this.isViewer) {
					if (this.type === 'history' || this.type === 'multigraph') {
						toggleContainer.attr('data-elementid', this.instanceConfiguration?.serviceElementId);
					} else if (this.type === 'metrics') {
						toggleContainer.attr('data-qualifierid', this.qualifier?.id);
					}
				}
				if (this.isServiceBoard) {
					toggleContainer.css({
						'top': '8px',
						'right': '55px'
					});
				}
				if (this.isAssetDetails) {
					toggleContainer.css({
						'right': '55px'
					});
				}
				if (this.isDashboard) {
					if (this.isDashboardDesigner) {
						toggleContainer.css({
							'top': '8px',
							'right': '55px'
						});
						optionsContainer.css({
							'position': 'absolute',
							'top': '9px',
							'right': '255px'
						});
					} else {
						toggleContainer.css({
							'top': '8px',
							'right': '5px'
						});
						optionsContainer.css({
							'position': 'absolute',
							'top': '9px',
							'right': '205px'
						});
					}

				}
				this.hasTimeSelector = false;
				this.hasToggleTimeSelector = true;
			}
			if (this.isFreeServiceBoard) {
				if (this.target && this.target.hasClass('no_header')) {
					toggleContainer.css({
						'right': '75px'
					})
				} else {
					toggleContainer.css({
						'right': '55px'
					})
				}
			}

			if (isSlaServiceHistory) {
				toggleContainer.css({
					'top': 'auto',
					'margin-top': '7px',
					'right': '15px'
				});
			}
		}

		let settingsHtml = '';
		settingsHtml += '<div class="section section_content-padding section_appearance_frame">' +

			'<div class="toolbar toolbar_appearance_section-header toolbar_margin-none section__header">' +
				'<div class="toolbar__entry toolbar__entry_title">' + lang.serviceBoard.CHART_SETTINGS + '</div>' +
			'</div>' +

			'<div class="section__content section__content_direction_row section__content_layout_equal">' +
				'<div id="cw_settings_left_column">';

			if (this.hasMissingData) {
				settingsHtml += '<div class="cw_field">';
				let widgetCheckboxesCount = $('.missing_data' + id).length;
				settingsHtml += '<input type="checkbox" class="k-checkbox missing_data' + id + '" id="cw_ignore_missing_data' + id + widgetCheckboxesCount + '"/>';
				settingsHtml += '<label for="cw_ignore_missing_data' + id + widgetCheckboxesCount + '" class="k-checkbox-label">' + lang.widget.messages.MISSING_DATA + '</label>';
				settingsHtml += '</div>';
			}

			if (this.hasHideErrors) {
				settingsHtml += '<div class="cw_field">';
				let widgetCheckboxesCount = $('.hide_error' + id).length;
				settingsHtml += '<input type="checkbox" class="k-checkbox hide_error' + id + '" id="cw_hide_errors' + id + widgetCheckboxesCount + '"/>';
				settingsHtml += '<label for="cw_hide_errors' + id + widgetCheckboxesCount + '" class="k-checkbox-label">' + lang.widget.messages.HIDE_ERRORS + '</label>';
				settingsHtml += '</div>';
			}

			if (this.hasRegression) {
				settingsHtml += '<div class="cw_field">';
				let widgetCheckboxesCount = $('.slope' + id).length;
				settingsHtml += '<input type="checkbox" class="k-checkbox slope' + id + '" id="cw_show_slope' + id + widgetCheckboxesCount + '"/>';
				settingsHtml += '<label for="cw_show_slope' + id + widgetCheckboxesCount + '" class="k-checkbox-label">' + lang.SHOW_REGRESSION + '</label>';
				settingsHtml += '</div>';
			}

			if (this.hasThreshold) {
				settingsHtml += '<div class="cw_field">';
				let widgetCheckboxesCount = $('.threshold' + id).length;
				settingsHtml += '<input type="checkbox" class="k-checkbox threshold' + id + '" id="cw_show_threshold' + id + widgetCheckboxesCount + '"/>';
				settingsHtml += '<label for="cw_show_threshold' + id + widgetCheckboxesCount + '" class="k-checkbox-label">' + lang.SHOW_THRESHOLD + '</label>';
				settingsHtml += '</div>';
			}

		settingsHtml += '</div>'; //left column


		settingsHtml += '<div id="cw_settings_right_column">';
		settingsHtml += '</div>';

		settingsHtml += '</div>'; //section content

		settingsHtml += '<div class="section section__footer">' +
							'<div class="toolbar toolbar_appearance_section-header toolbar_margin-none profile-entry-editor__toolbar">' +
								'<div class="toolbar__entry toolbar__entry_spacer"></div>' +
								'<div class="toolbar__entry">' +
									'<div class="form-action-buttons">' +
										'<button id="cw_settings_update" class="form-action-buttons__button k-button k-primary">' + lang.UPDATE + '</button>' +
										'<button id="cw_settings_cancel" class="form-action-buttons__button k-button">' + lang.CANCEL + '</button>' +
									'</div>' +
								'</div>' +
							'</div>'
							// '<div >' +
							// 	'<button class="k-button right" id="cw_settings_cancel">' + lang.CANCEL + '</button>' +
							// 	'<button class="k-button k-primary go_right" id="cw_settings_update">' + lang.UPDATE + '</button>' +
							// '</div>' +
						'</div>' +
		'</div>'; //section

		this.target.find('.cw_widget_settings').append(settingsHtml);

		if (this.hasMissingData && (this.instanceConfiguration.ignoreMissingData || (this.instanceConfiguration.ignoreMissingData === undefined && this.defaultIgnoreMissingData))) {
			this.target.find('.missing_data' + id).prop('checked', true);
		}
		if (this.hasRegression && this.instanceConfiguration.showRegression) {
			this.target.find('.slope' + id).prop('checked', true);
		}
		if (this.hasThreshold && this.instanceConfiguration.showThreshold !== false) {
			this.target.find('.threshold' + id).prop('checked', true);
		}
		if (this.hasHideErrors && this.instanceConfiguration.hasOwnProperty('hideErrors') && this.instanceConfiguration.hideErrors !== false) {
			this.target.find('.hide_error' + id).prop('checked', true);
		}

		this.target.find('#cw_settings_cancel').on('click', $.proxy(this.onSettingsCancel, this));
		this.target.find('#cw_settings_update').on('click', $.proxy(this.onSettingsUpdate, this));

		if (['single_graph', 'singlegraph', 'multi_graph_asset', 'multi_graph_assetgroup', 'graph_metric', 'metrics', "asset_metrics",
			"kpi_metrics", 'multigraph', 'asset_multigraph', 'kpi_multigraph',
			'metric-multi-graph-asset', 'metric-multi-graph-asset-group', 'metric-single-graph', 'service-single-graph',
			'service-history', 'health-single-graph', 'health-multi-graph', 'kpi-single-graph', 'kpi-multi-graph', 'kpi-history',
			'kpi-gauge', 'service-gauge', 'metric-gauge', 'health-gauge', ''].includes(this.type)) {

			let aggregationHtml = '';
			aggregationHtml += '<div class="cw_field">';
			aggregationHtml += '<label class="cw_inline" style="background-color: initial; border: initial; width: 90px;">' + lang.widget.AGGREGATION;
			aggregationHtml += '<span class="glyphicons question-sign label-info-title" title="' + lang.widget.messages.AGGREGATION_MOUSEOVER + '"></span>';
			aggregationHtml += '</label>';
			aggregationHtml += '<div class="cw_dropdown_container" style="padding-left: 90px"><input type="text" style="width: 100px" id="cw_settings_aggregation_type"/></div>';
			aggregationHtml += '</div>';
			this.target.find('#cw_settings_right_column').append(aggregationHtml);

			this.hasAggregateSelector = true;
			this.aggregateMultiToggle = this.target.find('#cw_settings_aggregation_type').kendoDropDownList({
				dataSource: aggregationTypeDataSource,
				value: this.instanceConfiguration.aggregationType || "average",
				dataTextField: 'text',
				dataValueField: 'value'
			}).data('kendoDropDownList');

			let minValueHtml = '';
			minValueHtml += '<div class="cw_field">';
			minValueHtml += '<label class="cw_inline" style="background-color: initial; border: initial; width: 90px;">' + i('Y-axis');
			minValueHtml += '</label>';
			minValueHtml += '<div class="cw_dropdown_container" style="padding-left: 90px"><input type="text" style="width: 100px" id="cw_settings_min_value_type"/></div>';
			minValueHtml += '</div>';
			this.target.find('#cw_settings_right_column').append(minValueHtml);

			this.minValueDropDown = this.target.find('#cw_settings_min_value_type').kendoDropDownList({
				dataSource: [{
					text: i('Zero'),
					value: 'zero'
				}, {
					text: i('Dynamic'),
					value: 'dynamic'
				}],
				value: this.instanceConfiguration.minValue || "zero",
				dataTextField: 'text',
				dataValueField: 'value'
			}).data('kendoDropDownList');
		}

		let showTimeSelector = true;
		if (this.instanceConfiguration.timeSelectorPosition) {
			if (this.instanceConfiguration.timeSelectorPosition === 'NONE') {
				showTimeSelector = false;
				let toggleOptions = this.target.find('.cw_toggle_options');
				if (toggleOptions.length) {
					let toggleOptionsRight = parseInt(toggleOptions.css('right').split('px')[0]);
					let toggleOptionsDif = toggleOptionsRight - 195;
					let toggleOptionsMinRight;
					if (this.isDashboardDesigner) {
						toggleOptionsMinRight = 60;
					} else if (this.isDashboard) {
						toggleOptionsMinRight = 10;
					}
					if (toggleOptionsDif < toggleOptionsMinRight) {
						toggleOptionsDif = toggleOptionsMinRight;
					}
					toggleOptions.css('right', toggleOptionsDif);
				}
			}
		} else if (this.instanceConfiguration.hideTimeSelector) {
			showTimeSelector = false;
		}

		if (showTimeSelector) {
			if (this.hasTimeSelector) {
				let periods =  [{
					field: lang.periodMultiToggle.HOUR,
					val: 'LASTHOUR'
				}, {
					field: lang.periodMultiToggle.DAY,
					val: 'LASTDAY'
				}, {
					field: lang.periodMultiToggle.WEEK,
					val: 'LAST7DAYS'
				}, {
					field: lang.periodMultiToggle.MONTH,
					val: 'LAST30DAYS'
				},{
					field: lang.CUSTOM,
					val: 'CUSTOM'
				}];
				if (this.instanceConfiguration.excludedPeriods) {
					periods = periods.filter(x => !this.instanceConfiguration.excludedPeriods.includes(x.val));
				}
				this.timeMultiToggle = this.target.find('.period_multi_toggle').kendoDropDownList({
					dataSource: periods,
					value: this.instanceConfiguration.period || 'LASTDAY',
					dataTextField: 'field',
					dataValueField: 'val',
					change: $.proxy(function (e) {
						this.instanceConfiguration.period = e.sender.value();
						if (typeof this.customControls.change === 'function') {
							this.customControls.change.call(this, e);
						}
						if (typeof this.getData === 'function') {
							var forceTimeSelector = false;
							this.getData(true, forceTimeSelector);
						}
						this.zoomStartDate = null;
						this.zoomEndDate = null;
						this.zoomPeriod = null;
						this.onZoom = false;
						this.unsubscribe();
						this.subscribe(true);
					}, this)
				}).data('kendoDropDownList');

				this.target.find('.period_multi_toggle').siblings('.k-dropdown-wrap').css({
					'height': '25px',
					'margin-top': '2px'
				});

				if (this.newTranslatedPeriod) {
					this.timeMultiToggle.value(this.newTranslatedPeriod);
				}
			}
			if (this.hasToggleTimeSelector && !hideToggleTimeSelector) {
				let cssClass = isSlaServiceHistory ? 'cw_multi_toggle right' : 'cw_multi_toggle small right';
				let periods =  [{
					id: 'cw_hour',
					title: lang.periodMultiToggle.HOUR,
					//selected: false,
					label: lang.periodMultiToggle.HOUR,
					fn: (value, target) => this.onPeriodMultiToggleClick(value, target),
					scope: this,
					value: 'LASTHOUR'
				}, {
					id: 'cw_day',
					title: lang.periodMultiToggle.DAY,
					//selected: true,
					label: lang.periodMultiToggle.DAY,
					fn: (value, target) => this.onPeriodMultiToggleClick(value, target),
					scope: this,
					value: 'LASTDAY'
				}, {
					id: 'cw_week',
					title: lang.periodMultiToggle.WEEK,
					//selected: false,
					label: lang.periodMultiToggle.WEEK,
					fn: (value, target) => this.onPeriodMultiToggleClick(value, target),
					scope: this,
					value: 'LAST7DAYS'
				}, {
					id: 'cw_month',
					title: lang.periodMultiToggle.MONTH,
					//selected: false,
					label: lang.periodMultiToggle.MONTH,
					fn: (value, target) => this.onPeriodMultiToggleClick(value, target),
					scope: this,
					value: 'LAST30DAYS'
				}, {
					id: 'cw_custom',
					title: lang.CUSTOM,
					//selected: false,
					label: lang.CUSTOM,
					fn: (value, target) => this.onPeriodMultiToggleClick(value, target),
					scope: this,
					value: 'CUSTOM'
				}];
				if (this.instanceConfiguration.excludedPeriods) {
					periods = periods.filter(x => !this.instanceConfiguration.excludedPeriods.includes(x.value));
				}
				this.periodMultiToggle = new MultiToggle({
					selector: this.target.find('.period_multi_toggle'),
					type: 'label',
					cssClass: cssClass,
					value: this.instanceConfiguration.period || 'LASTDAY',
					items: periods
				});
				let toggleContainer = this.target.find('.period_multi_toggle');
				toggleContainer.find('li').css({
					'height': '22px',
					'padding-top': '4px'
				});
				if (isSlaServiceHistory) {
					toggleContainer.find('li').css({
						'height': '25px',
						'padding-top': '6px'
					});
				}
				this.periodMultiToggle.setSelectedItem(this.instanceConfiguration.period || 'LASTDAY');
			}
		}

		if (this.hasChartTypeSelector) {
			let chartTypeHtml = '';
			chartTypeHtml += '<div class="cw_field">';
			chartTypeHtml += '<label class="cw_inline" style="background-color: initial; border: initial; width: 90px;">' + lang.widget.CHART_TYPE + '</label>';
			chartTypeHtml += '<div class="cw_dropdown_container" style="padding-left: 90px"><input type="text" style="width: 100px" id="cw_settings_chart_type"/></div>';
			chartTypeHtml += '</div>';

			this.target.find('#cw_settings_right_column').append(chartTypeHtml);
			this.chartTypeMultiToggle = this.target.find('#cw_settings_chart_type').kendoDropDownList({
				dataSource: [{
					field: lang.widget.LINE,
					val: 'line'
				}, {
					field: lang.widget.AREA,
					val: 'area'
				}, {
					field: lang.widget.RANGE,
					val: 'range'
				}],
				value: this.instanceConfiguration.chartType || 'LINE',
				dataTextField: 'field',
				dataValueField: 'val'
			}).data('kendoDropDownList');
		}

		if (this.hasInfoSign) {
			if (this.isViewer) {
				if (this.isAssetDetails) {
					this.infoSignWidgetId = $(this.renderTo).attr('id');
				} else {
					if (this.renderTo === 'cw_preview_widget') {
						//default opened widget
						this.infoSignWidgetId = this.instanceConfiguration.serviceId;
					} else {
						//other spawned widgets
						this.infoSignWidgetId = this.renderTo;
					}
				}
			} else {
				this.infoSignWidgetId = this.id;
			}
			if (!this.target.find('.cw_widget_info_sign').length) {
				this.target.append('<span widget-id="' + this.infoSignWidgetId + '" class="cw_widget_info_sign glyphicons info-sign"></span>');
			}
			let infoSignContainer = this.target.find('.cw_widget_info_sign');
			let toggleOptionsSign = this.target.find('.cw_toggle_options');
			let leftDiff, rightPos;
			if (toggleOptionsSign.length) {
				if (this.isDashboard) {
					rightPos = '90px';
					leftDiff = 48;
				} else {
					rightPos = '75px';
					leftDiff = 35;
				}
			} else {
				leftDiff = 15;
				if (this.isDashboard) {
					rightPos = '70px';
				} else {
					rightPos = '55px';
				}
			}

			if (this.hasTimeSelector || this.hasToggleTimeSelector) {
				let toggleContainer = this.target.find('.period_multi_toggle');
				if (toggleContainer.length) {
					let toggleContainerPosition = toggleContainer.position();
					let toggleContainerLeft = toggleContainerPosition.left;
					if (toggleContainerLeft <= 0) {
						toggleContainerLeft = 210;
					}
					infoSignContainer.css('left', toggleContainerLeft - leftDiff + 'px');
				} else {
					infoSignContainer.css({
						'right': rightPos,
						'top': '6px'
					});
				}
			} else {
				infoSignContainer.css({
					'right': rightPos,
					'top': '6px'
				});
			}
			if (this.isDashboard) {
				infoSignContainer.css('top', '11px');
			}

			infoSignContainer.off();
			infoSignContainer.on('mouseover', $.proxy(this.onInfoSignHover, this));
			infoSignContainer.on('mouseout', $.proxy(this.onInfoSignHoverOut, this));
		} else {
			this.target.find('.cw_widget_info_sign').remove();
		}

		let actions;
		if (this.target.find('.k-window-actions').length) {
			actions = this.target.find('.k-window-actions');
		} else if (this.target.closest('.k-window').find('.k-window-actions').length) {
			actions = this.target.closest('.k-window').find('.k-window-actions');
		}
		if (actions) {
			var ariaToggle;
			if (actions.find('[aria-label="toggle"]').length) {
				ariaToggle = actions.find('[aria-label="toggle"]')
			} else if (actions.find('[aria-label="Toggle"]').length) {
				ariaToggle = actions.find('[aria-label="Toggle"]')
			}
		}
		if (ariaToggle) {
			if (!this.hasThreshold && !this.hasRegression && !this.hasMissingData && !this.hasChartTypeSelector) {
				ariaToggle.addClass('hide');
			} else {
				ariaToggle.removeClass('hide');
			}
		}

		if (this.type !== 'history' && !this.isFromServiceDetails) {
			this.truncateTitle();
		}
	},

	truncateTitle() {
		let leftMostPosition, titleContainer, widgetContainer, cssLeft;

		if (this.isDashboard) {
			widgetContainer = this.target;
		} else if (this.widgetContentDiv) {
			//kendo window
			widgetContainer = this.widgetContentDiv.closest('.k-widget');
			if (!widgetContainer.length) {
				//gridster
				widgetContainer = this.widgetContentDiv.closest('.cw_widget');
			}
		} else if (this.target) {
			//gridster when no widgetContentDiv
			widgetContainer = this.target;
		}

		let periodToggle = widgetContainer.find('.period_multi_toggle');
		if (periodToggle.length) {
			leftMostPosition = periodToggle.position().left;
		}
		let toggleOptions = widgetContainer.find('.cw_toggle_options');
		if (toggleOptions.length && !this.isDashboard) {
			leftMostPosition -= 25;
			toggleOptions.css('margin-left', '30px');
		}
		if (this.isDashboard) {
			if (this.isDashboardDesigner) {
				leftMostPosition -= 40;

			} else {
				leftMostPosition -= 45;
			}
		}

		if (leftMostPosition) {
			if (this.isDashboard) {
				titleContainer = $(widgetContainer.find('.toolbar__entry_title').get(0));
				titleContainer.addClass('ellipsis');
			} else {
				titleContainer = widgetContainer.find('.k-window-title');
				if (!titleContainer.length) {
					titleContainer = widgetContainer.find('.cw_section_title');
				}
			}
			titleContainer.css('width', leftMostPosition - 20 + 'px');
			let titleText = titleContainer.text();
			titleContainer.attr('title', titleText);
		}
	},

	getMetricInfoUrl(widgetConfig) {
		const baseUrl = Settings.serverPath + 'accounts/' + (widgetConfig.accountId || Cookies.CeesoftCurrentAccountId) + '/services/' + widgetConfig.serviceId + '/';
		const url = baseUrl + 'elements/' + widgetConfig.serviceElementId + '/qualifiers/' + widgetConfig.serviceQualifierId + '/?update=false';
		return url;
	},

	async onInfoSignHover(e) {
		let data, infoObj, config, type, title;
		let widgetId = $(e.target).attr('widget-id');
		let widgets = State.currentApp.widgets;
		let currentWidget, infoUrl;
		if (widgets) {
			if (widgets.length) {
				for (let i = 0; i < widgets.length; i++) {
					if (widgets[i].id === widgetId) {
						currentWidget = widgets[i];
					}
				}
			} else {
				currentWidget = widgets[widgetId];
			}
		} else {
			currentWidget = this;
		}
		if (['service-gauge', 'metrics', 'gauge', 'service-single-graph'].indexOf(currentWidget.type) !== -1) {
			config = currentWidget.instanceConfiguration;
			infoObj = {
				accountId: config.accountId,
				serviceId: config.serviceId,
				serviceElementId: config.serviceElementId,
				serviceQualifierId: config.serviceQualifierId
			};
		} else {
			if (currentWidget.dataItem && currentWidget.dataItem.type === 'COLLECTOR') {
				//asset details collector
				config = currentWidget.dataItem;
				infoObj = {
					accountId: config.accountId,
					serviceId: config.parentId,
					serviceElementId: config.elementId,
					serviceQualifierId: config.qualifierId
				};
			} else if (currentWidget.type === 'graph_gauge' || currentWidget.type === 'metric-gauge') {
				//metric gauge
				data = currentWidget.instanceConfiguration.metricsItem;
			} else if (['asset_gauge', 'kpi_gauge', 'health-gauge', 'kpi-gauge'].indexOf(currentWidget.type) !== -1) {
				//health/kpi gauge
				data = currentWidget.instanceConfiguration;
			} else {
				data = currentWidget.hoverInfoQualifier;
			}
		}
		if (infoObj) {
			type = 'request';
			infoUrl = this.getMetricInfoUrl(infoObj);
			data = await loadQualifierDetails(infoUrl);
		} else {
			data = this.deleteNotShowInfo(data);
			type = 'preloaded';
		}
		let coordinates = e.target.getBoundingClientRect();
		let flags = {
			hasAggregation: currentWidget.hasAggregateSelector,
			hasChartType: currentWidget.hasChartTypeSelector,
			hasMissingData: currentWidget.hasMissingData,
			hasRegression: currentWidget.hasRegression,
			hasThreshold: currentWidget.hasThreshold
		};
		let isAnyFlag = false;
		for (let key in flags) {
			if (flags[key]) {
				isAnyFlag = true;
			}
		}
		ReactDOM.render(<WidgetInfoContainer
			data={data}
			type={type}
			chartSettings={currentWidget.instanceConfiguration}
			flags={flags}
			coordinates={coordinates}
			isAnyFlag={isAnyFlag}
		/>, e.target);
	},

	onInfoSignHoverOut(e) {
		this.hoverContainerTarget = e.target;
		//to make sure the container is destroyed; timing logic necessary for very fast hovering in and out
		let destroyContainer = setInterval(() => ReactDOM.unmountComponentAtNode(e.target), 200);
		setTimeout(() => clearInterval(destroyContainer), 400);
	},

	deleteNotShowInfo(data) {
		if (data) {
			delete data.accountId;
			delete data.agentId;
			delete data.assetId;
			delete data.conversion;
			delete data.configuration;
			delete data.customUnit;
			delete data.gaugeType;
			delete data.id;
			delete data.kpiAccountId;
			delete data.labelTemplate;
			delete data.metrics;
			delete data.monitorId;
			delete data.monitorType;
			delete data.profileId;
			delete data.ruleTypeId;
			delete data.sampleRateId;
			delete data.selected;
			delete data.qualifierDescription;
		}

		return data;
	},

	onPeriodMultiToggleClick: function (value, target) {
		if (value === 'CUSTOM') {
			if (this.target.find('.cw_custom_time_selector').length) {
				this.target.find('.cw_custom_time_selector').removeClass('hide');
				this.endTimePicker?.value(new Date());
			} else {
				var html = '';
				html += '<div class="cw_custom_time_selector" style="top: 30px;">';
				html += '<div class="cw_time_input_container">';
				html += '<div>' + lang.FROM + '</div>';
				html += '<input class="cw_start_time cw_time_input"/>';
				html += '</div>';
				html += '<div class="cw_time_input_container">';
				html += '<div>' + lang.TO + '</div>';
				html += '<input class="cw_end_time cw_time_input"/>';
				html += '</div>';
				html += '<button disabled class="k-button k-primary right" id="cw_apply_custom_time">' + lang.APPLY + '</button>';
				html += '</div>';
				this.target.append(html);

				if (this.isServiceBoard || this.isDashboard) {
					this.target.find('.cw_custom_time_selector').css('top', '');
				}
				let viewerWindow = $('#cw_service_model_preview');
				if (viewerWindow.length) {
					this.target.find('.cw_custom_time_selector').css('right', '10px');
				}
				if (this.isSlaServiceHistory) {
					this.target.find('.cw_custom_time_selector').css({
						'position': 'absolute',
						'top': 'auto',
						'margin-top': '40px',
						'right': '20px'
					});
				}

				if (this.instanceConfiguration.hideHours) {
					this.startTimePicker = this.target.find(".cw_start_time").kendoDatePicker({
						format: Utils.datePatternConverter(Cookies.CeesoftUserDateFormat),
						value: this.instanceConfiguration?.startDate ? new Date(this.instanceConfiguration.startDate) : '',
						change: $.proxy(function() {
							let startValue = this.startTimePicker.value();
							let endValue = this.endTimePicker.value();
							if (endValue) {
								this.target.find('#cw_apply_custom_time').attr('disabled', false);
								if (endValue < startValue) {
									this.endTimePicker.value(startValue);
								}
							}
							this.endTimePicker.min(startValue);
						}, this)
					}).data('kendoDatePicker');

					this.endTimePicker = this.target.find('.cw_end_time').kendoDatePicker({
						format: Utils.datePatternConverter(Cookies.CeesoftUserDateFormat),
						value: this.instanceConfiguration?.endDate ? new Date(this.instanceConfiguration.endDate) : new Date(),
						change: $.proxy(function() {
							if (this.startTimePicker.value()) {
								this.target.find('#cw_apply_custom_time').attr('disabled', false);
							}
						}, this)
					}).data('kendoDatePicker');
				} else {
					this.startTimePicker = this.target.find(".cw_start_time").kendoDateTimePicker({
						format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
						timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat),
						value: this.instanceConfiguration?.startDate ? new Date(this.instanceConfiguration.startDate) : '',
						change: $.proxy(function() {
							let startValue = this.startTimePicker.value();
							let endValue = this.endTimePicker.value();
							if (endValue) {
								this.target.find('#cw_apply_custom_time').attr('disabled', false);
								if (endValue < startValue) {
									this.endTimePicker.value(startValue);
								}
							}
							this.endTimePicker.min(startValue);
						}, this)
					}).data('kendoDateTimePicker');

					this.endTimePicker = this.target.find('.cw_end_time').kendoDateTimePicker({
						format: Utils.datePatternConverter(Cookies.CeesoftUserDateTimeFormat),
						timeFormat: Utils.getTimeFormat(Cookies.CeesoftUserTimeFormat),
						value: this.instanceConfiguration?.endDate ? new Date(this.instanceConfiguration.endDate) : new Date(),
						change: $.proxy(function() {
							if (this.startTimePicker.value()) {
								this.target.find('#cw_apply_custom_time').attr('disabled', false);
							}
						}, this)
					}).data('kendoDateTimePicker');
				}

				this.target.find('#cw_apply_custom_time').on('click', $.proxy(this.onApplyCustomTime, this));
			}
		} else {
			if (this.startTimePicker && this.endTimePicker) {
				this.resetCustomTimePickers();
			}
			this.instanceConfiguration.period = value;
			var event = {
				sender: {
					wrapper: this.target,
					value: value
				}
			};
			if (typeof this.customControls.change === 'function') {
				this.customControls.change.call(this, event);
			}
			if (typeof this.getData === 'function') {
				var forceTimeSelector = false;
				this.getData(true, forceTimeSelector);
			}
			this.zoomStartDate = null;
			this.zoomEndDate = null;
			this.zoomPeriod = null;
			this.onZoom = false;
			this.unsubscribe();
			this.subscribe(true);
			this.fireConfigurationChanged();
			this.target.find('.cw_custom_time_selector').addClass('hide');

			if (this.isSlaServiceHistory) {
				let configObj = {
					period: value
				}
				LocalEventsManager.trigger('getActiveWidgetSettings', configObj);
			}

			if (this.onWidgetPeriodChanged) {
				this.onWidgetPeriodChanged(this.viewerContext, null, target);
			}
		}

		if (this.isViewer) {
			let currentHighlightWindow = $(target.closest('.k-window'));
			currentHighlightWindow.addClass('k-state-focused');
			$('body').on('click', (e) => {
				if ($(e.target).closest('.k-window')[0] !== currentHighlightWindow[0]) {
					currentHighlightWindow.removeClass('k-state-focused');
				}
			})
		}
	},
	onSettingsCancel() {
		this.target.find('.cw_widget_settings').css('display', 'none');
	},
	onSettingsUpdate() {
		let id = this.instanceConfiguration.serviceQualifierId || this.instanceConfiguration.monitorId;
		this.target.find('.cw_widget_settings').css('display', 'none');

		this.instanceConfiguration.ignoreMissingData = this.target.find('.missing_data' + id).is(':checked');
		this.instanceConfiguration.showRegression = this.target.find('.slope' + id).is(':checked');
		this.instanceConfiguration.showThreshold = this.target.find('.threshold' + id).is(':checked');
		this.instanceConfiguration.hideErrors = this.target.find('.hide_error' + id).is(':checked');

		if (this.chartTypeMultiToggle) {
			this.instanceConfiguration.chartType = this.chartTypeMultiToggle.value();
		}
		if (this.aggregateMultiToggle) {
			this.instanceConfiguration.aggregationType = this.aggregateMultiToggle.value();
		}

		if(this.minValueDropDown){
			this.instanceConfiguration.minValue = this.minValueDropDown.value()
		}

		if (this.hasChartTypeSelector || this.hasAggregateSelector) {
			this.unsubscribe();
			this.subscribe(true);
		}

		this.getData(true);
		this.fireConfigurationChanged();
	},
	resetCustomTimePickers() {
		this.startTimePicker.value('');
		this.startTimePicker.min(new Date(1900, 0, 1));
		this.startTimePicker.max(new Date(2100, 0, 1));
		this.endTimePicker.value('');
		this.endTimePicker.min(new Date(1900, 0, 1));
		this.endTimePicker.max(new Date(2100, 0, 1));
	},
	onApplyCustomTime: function (e) {
		this.instanceConfiguration.period = 'CUSTOM';
		this.instanceConfiguration.startDate = this.startTimePicker.value().getTime();
		if (this.instanceConfiguration.hideHours) {
			let endDate = this.endTimePicker.value();
			endDate.setDate(endDate.getDate() + 1);
			this.instanceConfiguration.endDate = endDate.getTime();
		} else {
			this.instanceConfiguration.endDate = this.endTimePicker.value().getTime();
		}
		var event = {
			sender: {
				wrapper: this.target,
				value: 'CUSTOM'
			},
			startDate: this.instanceConfiguration.startDate,
			endDate: this.instanceConfiguration.endDate
		};
		if (typeof this.customControls.change === 'function') {
			this.customControls.change.call(this, event);
		}
		if (typeof this.getData === 'function') {
			var forceTimeSelector = false;
			this.getData(true, forceTimeSelector);
		}
		this.zoomStartDate = null;
		this.zoomEndDate = null;
		this.zoomPeriod = null;
		this.onZoom = false;
		this.unsubscribe();
		this.subscribe(true);
		this.target.find('.cw_custom_time_selector').addClass('hide');
		this.fireConfigurationChanged();

		if (this.isSlaServiceHistory) {
			let configObj = {
				period: 'CUSTOM',
				startDate: this.instanceConfiguration.startDate,
				endDate: this.instanceConfiguration.endDate
			}
			LocalEventsManager.trigger('getActiveWidgetSettings', configObj);
		}
	},
	/*
	 * Handler function for getting the grid configuration to save along with the widget configration
	 */
	getConfiguration: function () {
		var config = {};
		if (this.hasConfiguration) {
			config = {
				sort: this.grid ? this.grid.dataSource.sort() || [] : [],
				filter: this.grid ? this.grid.dataSource.filter() || [] : [],
				columns: this.grid ? Utils.getGridColumns(this.grid) || [] : []
			};
			if (this.gridSearch) {
				let searchValue = this.gridSearch.getValue();
				config.searchValue = searchValue;
			}
		}

		return config;
	},

	saveDisabledSeries: function () {
		if (this.chart) {
			this.disabledSeries = [];
			for (var i = 0; i < this.chart.series.length; i++) {
				if (!this.chart.series[i].visible) {
					this.disabledSeries.push(i);
				}
			}
		}
	},

	restoreDisabledSeries: function () {
		if (this.disabledSeries && this.disabledSeries.length) {
			for (var i = 0; i < this.disabledSeries.length; i++) {
				this.chart.series[this.disabledSeries[i]].hide();
			}
		}
	},

	fireConfigurationChanged(){
		this.onConfigurationChanged && this.onConfigurationChanged(this.instanceConfiguration);
	},

	generateMultigraphLabel: function (qualifierConfig) {
		var labelTemplateArray = this.instanceConfiguration.labelTemplate || [], template = [], label, labelString;

		if (qualifierConfig.configuration && qualifierConfig.configuration.description) {
			qualifierConfig.qualifierDescription = qualifierConfig.configuration.description;
		}

		var labels = {
			'<KPI account>': 'kpiAccountName',
			'<Account>': 'accountName',
			'<Service>': 'serviceName',
			'<Serviceelement>': 'elementName',
			'<Servicequalifier>': 'name',

			'<Asset>': 'assetName',
			'<MonitorType>': 'monitorTypeText',
			'<Monitorname>': 'monitorName',
			'<Subsystem>': 'groupName',
			'<Instance>': 'instanceName',
			'<Qualifier>': 'qualifierDescription',

			'<Profile>': 'profileText',
			'<RuleType>': 'typeText',
			'<SampleRate>': 'sampleRateText',

			//instance
			'<Name>': 'categoryNode',
			'<Category>': 'identifier',

			'<Type>': 'registryType'
		};

		if (labelTemplateArray.length) {
			for (var i = 0, length = labelTemplateArray.length; i < length; i++) {
				label = labels[labelTemplateArray[i]];
				labelString = qualifierConfig[label];
				if (labelString) {
					template.push(labelString);
				}
			}

			return template.join('/');
		}

		return '';
	},
	/**
	 * Triggered after widget resize
	 * @param {Object} event The resize event
	 * @param {Object} ui The UI element - see http://api.jqueryui.com/resizable/
	 */
	onResize: function (event, ui) {
		//State.currentApp.saveServiceBoard();
	},
	/**
	 * Gets widget height as string
	 * @return {String} Widget height (i.e '32px')
	 */
	getHeight: function () {
		return $('#' + this.id).height() + 'px';
	},
	/**
	 * Sets the widget height
	 * @param {String} height The height in px : '32px'
	 * @deprecated
	 */
	setHeight: function (height) {
		// TODO remove all the calls to this method
	},
	/**
	 * Displays an error message inside a widget
	 * @param {Object} message
	 */
	showErrorMessage: function (message, status , animationTime) {
		var widgetContentDiv;
		if (!this.renderTo) {
			widgetContentDiv = $('#' + this.id).find('.cw_section_content');
		} else {
			widgetContentDiv = $('#' + this.renderTo);
		}
		widgetContentDiv.empty().append('<div class="cw_section_placeholder"></div>');
		this.statusNotification = new CustomNotification({
			appendToElement: widgetContentDiv,
			message: message,
			status: status || 'error',
			animationTime: animationTime
		}).show();
	},

	hideErrorMessage: function () {
		if (this.statusNotification) {
			this.statusNotification.hide();
		}
	},

	moveTimeSelectorBottom: function (missingHeader) {
		let chartHeight = this.renderTo.height();
		let periodMultiToggle = this.target.find('.period_multi_toggle');
		let periodMultiToggleTop = parseInt(periodMultiToggle.css('top').split('px')[0]);
		let newPeriodMultiToggleTop = periodMultiToggleTop + chartHeight;
		if (missingHeader) {
			newPeriodMultiToggleTop = newPeriodMultiToggleTop - 35;
		}
		periodMultiToggle.css('top', newPeriodMultiToggleTop);
		let toggleOptions = this.target.find('.cw_toggle_options');
		if (toggleOptions.length) {
			let toggleOptionsRight = parseInt(toggleOptions.css('right').split('px')[0]);
			let toggleOptionsDif = toggleOptionsRight - 195;
			let toggleOptionsMinRight;
			if (this.isDashboardDesigner) {
				toggleOptionsMinRight = 60;
			} else if (this.isDashboard) {
				toggleOptionsMinRight = 10;
			}
			if (toggleOptionsDif < toggleOptionsMinRight) {
				toggleOptionsDif = toggleOptionsMinRight;
			}
			toggleOptions.css('right', toggleOptionsDif);
		}
		let infoSign = this.target.find('.cw_widget_info_sign');
		let infoSignRight = 0;
		if(infoSign.css('left'))
			infoSignRight = parseInt(infoSign.css('left').split('px')[0]);
		infoSign.css('left', infoSignRight + 195);
	},

	getTitleNode: function () {
		let node = $('.cw_section_title', '#' + this.id).get(0);

		if (!node) {
			const root = document.getElementById(this.id);
			if(!root)
				return null;

			const kWindow = $(root.closest('.k-window'));

			if (kWindow.find('.free_title_container').length) {
				node = kWindow.find('.free_title_container').get(0);
			}
			else {
				const $div = $('<div />').addClass('free_title_container');

				kWindow.append($div);

				node = $div.get(0);
			}
		}

		return node;
	},

	setTitle: function(el) {
		this.reactTitle = true;

		const node = this.getTitleNode();

		if (node) {
			const freeLayout = $(node).addClass('').hasClass('free_title_container')

			ReactDOM.render(React.cloneElement(el, {
				skipTitle: freeLayout
			}), node);
		}
	},

	unsetTitle: function () {
		const node = this.getTitleNode();

		if (node) {
			ReactDOM.unmountComponentAtNode(node);
		}
	},

	setTitleIfEmpty(newTitle) {
		if (this.title) {
			return;
		}

		let titleTag = document.getElementById(this.id)
			.querySelector('.cw_section_title, .oe');

		if (titleTag != null) {
			titleTag.innerText = newTitle;
		}
	},

	/*
	 * Handler function for setting the error tooltip
	 * @param {Object} handler The jQuery handler where to bind the tooltip
	 * */
	setErrorToolTip: function (handler) {
		if (!this.tooltipOverview) {
			this.tooltipOverview = Renderer.errorToolTip(handler);
		}
	},
	/*
	 * Handler function for loading the error codes
	 * */
	loadErrorCodes: function () {
		var errorCodes = ErrorCodes;
		errorCodes.setOptions({
			callback: this.initComponent(),
			sessionId: this.sessionId
		}).load();
	},
	/**
	 * Unsubscribe widget
	 */
	unsubscribe: function () {
		if (this.subscriberId) {
			RemoteEventsManager.unsubscribe(this.subscriberId);
		} else {
			//console.log('no subscriber found for unsbuscriber');
		}
		this.isDataSourceSubscribed = false;
	},
	/**
	 * Called when one or multiple events are received from the server
	 * @param {Array} events The events
	 */
	handleEvents: function (events) {
		//if onEvent exists: call onEvent as we are just refreshing
		if (this.onEvents) {
			this.onEvents(events);
		} else if (this.onEvent) {
			this.onEvent(events);
		}
	},

	isDashboardMode: function(){
		return $('#' + this.id).hasClass('html-shape-container');
	},

	isDashboardViewer: function(){
		return !this.isDashboardDesigner;
	},

	isWithoutHeader() {
		const toolbar = $('.html-shape-container').find('.toolbar');
		return !toolbar.length
			|| toolbar.css('display') === 'none';
	},

	destroy: function () {
		if (this.hoverContainerTarget) {
			ReactDOM.unmountComponentAtNode(this.hoverContainerTarget);
		}

		this.unsubscribe();
	}
}
