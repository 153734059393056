import Cookies from 'core/cookies'
import Settings from 'settings'
import Chosen from 'controls/chosen'
import ErrorHandler from 'core/errorHandler';
import ModalWindow from "./modalWindow";
import {Api} from 'tools/api'
import {infoDialogAsync} from 'controls/dialog';

/**
 * @class ceeview.controls.TagsForm
 * @namespace ceeview.controls
 * @constructor
 * This class is used for setting tags
 * @param {Object} config The config object
 */
export default function TagsForm(config) {
	Object.assign(this, config);
	this.accountId = this.accountId || Cookies.CeesoftCurrentAccountId;
	this.initComponent();
};


TagsForm.prototype = {
	/*
	 * @cfg {Object} window (Mandatory). Kendo window object
	 */
	window: null,
	/*
	 * @cfg {String} type (Mandatory). View type where tags will be used
	 */
	type: '',
	/*
	 * @cfg {Object} viewScope (Mandatory). The view scope
	 */
	viewScope: null,
	/**
	 * Main init function
	 */
	initComponent: function () {
		this.removeListeners();
		this.attachListeners();
		this.initKendoComponents();
	},
	/**
	 * Removes listeners
	 */
	removeListeners: function () {
		this.window.kendoWindow.wrapper.find('#cw_close').off();
		this.window.kendoWindow.wrapper.find('#cw_update').off();
	},
	/**
	 * Attaches listeners
	 */
	attachListeners: function () {
		this.window.kendoWindow.wrapper.find('#cw_close').on('click', $.proxy(this.onClose, this));
		this.window.kendoWindow.wrapper.find('#cw_update').on('click', $.proxy(this.onUpdate, this));
	},
	initKendoComponents: function () {
		this.tagsControl = new Chosen({
			dataSource: new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: Settings.serverPath + 'accounts/' + this.accountId + '/tags?_dc=',
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				schema: {
					parse: function (response) {
						var result;
						if (response.success) {
							result = response.data;
						} else if (response.length) {
							result = response;
						} else {
							result = [];
						}
						for (var i = 0, length = result.length; i < length; i++) {
							result[i] = {
								text: result[i],
								value: result[i],
								editable: false,
								removable: false,
								destroyable: false
							};
						}
						return result;
					}
				},
				error: ErrorHandler.kendoServerError
			}),
			renderEl: $('.cw_tags'),
			placeholder: lang.messages.TAG_NEW_EXISTING,
			dataTextField: 'name',
			dataValueField: 'id',
			enabled: true,
			//editableItems: true,
			change: $.proxy(function (event) {
				var disable;
				if (event.controlHandler) {
					if (event.controlHandler.dataSource.data().length) {
						disable = false;
					} else {
						disable = true;
					}
				}
				this.window.kendoWindow.wrapper.find('#cw_update').prop('disabled', disable);
			}, this),
		});
	},
	/**
	 * Handler function for the click event on the Update button
	 * @param {Object} e The click event object
	 */
	onUpdate: function () {
		var ids = [], tags = [], url, control, checkboxes;
		if( this.viewScope.grid?.kendoGrid){
			//a case for react pages. But the whole implementation still sucks a lot, requires reworks
			// no paramenters should being looked this way
			control = this.viewScope.grid.kendoGrid
		}else if (this.viewScope.grid) {
			control = this.viewScope.grid;
		} else if (this.viewScope.treeList) {
			control = this.viewScope.treeList;
		}
		var checkboxes;
		if (control.wrapper) {
			checkboxes = control.wrapper.find('.cw_grid_check:checked');
		} else {
			checkboxes = $('#content_area').find('.cw_grid_check:checked');
		}
		var length = checkboxes.length;
		var tagValues = this.tagsControl.getValues();

		for (var i = 0; i < length; i++) {
			var id = $(checkboxes[i]).data('id');
			if (this.type === 'assetgroup') {
				var record = this.viewScope.treeList.dataSource.get(id);
				var realId = record.realId;
				ids.push(realId);
			} else {
				ids.push(id);
			}
		}
		for (var i = 0, length = tagValues.length; i < length; i++) {
			tags.push(tagValues[i].name.trim());
		}
		var data = {
			ids: this.viewScope.selectedIds || ids,
			tags: tags
		};
		var url = this.type === 'application' ? Settings.serverPath : Settings.serverPath + 'accounts/' + this.accountId + '/';
		switch (this.type) {
			case 'service':
				url += 'services';
				break;
			case 'dashboards':
				url += 'dashboards';
				break;
			case 'asset':
				url += 'assets';
				break;
			case 'account':
				url = Settings.serverPath + 'accounts';
				break;
			case 'kpi':
				url += 'kpi';
				break;
			case 'sla':
				url += 'slas';
				break;
			case 'incident':
				url += 'incidents';
				break;
			case 'report':
				url += 'reports';
				break;
			case 'monitor':
				url += 'monitors';
				break;
			case 'assetgroup':
				url += 'assetGroups';
				break;
			case 'application':
				url += 'applications';
				break;
			default:
				break;
		}
		url += '/tags';

		Api.fetchPost(url, data)
			.then(result => {
				if (result.success) {
					control.dataSource.read();
					control.refresh();
					this.onClose();
					this.viewScope.gridMenu?.disableItem('cw_set_tags');
					this.viewScope.actionNotification?.setOptions({
						message: lang.messages.TAGS_SUCCESS_MESSAGE,
						status: 'success'
					}).show();
				} else {
					infoDialogAsync(lang.ALERT, result.message, result.details);
				}
			});
	},
	/**
	 * Handler function for the click event on the Cancel/close button
	 * @param {Object} e The click event object
	 */
	onClose: function (e) {
		this.window.close();
	}
};

export function openTagsFormWindow (type, viewScope) {
	const window = new ModalWindow({
		title: lang.SET_TAGS,
		width: 400,
		resizable: true,
		minHeight: 320,
		height: 320,
		modal: true,
		actions: [],
		url: 'include/TagsForm.jsp',
		refresh: $.proxy(function () {
			var tagsFormView = new TagsForm({
				window: window,
				type: type,
				viewScope: viewScope
			});
		}, this)
	});
	window.open();
}
