import {translator} from "core";

export const i18n = {
	"Datasource type": {
		"no": "Datakilde type"
	},
	"Set Datasource as label": {
		"no": "Sett datakilde som navn"
	},
	"Show health index": {
		"no": "Vis helseindeks"
	},
	"Expanded": {
		"no": "Ekspandert"
	},
	"Selection": {
		"no": "Seleksjon"
	},
	"Monitorfilter": {
		"no": "Monitorfilter"
	},
};
