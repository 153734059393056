import React from 'react';
import ReactDOM from "react-dom";
import {Application, Cookies, RemoteEventsManager} from "core";
import {State, UserPrefs, Utils} from "tools";
import {Settings} from "settings";
import {Dependencies, Dialog, ModalWindow, PreferencesPanel} from "controls";
import MonitorsApi from "areas/assets/monitors/api";
import Api from 'tools/api';
import {NavigationAction} from 'appStore';
import EventsToolbar from 'controls/eventsToolbar';
import {DocumentationButton} from "controls/react/form/documentationButton";
import {EventSummaryRouter} from "areas/views/bundleDescription"

export function BaseMonitorView (config) {
	Utils.apply(this, config);
};

export {BaseMonitorView as default}

jQuery.extend(BaseMonitorView.prototype, Application.prototype, {
	/*
	 * @cfg {String} preferencesCategory The preferences category for saving the user preferences
	 */
	/**
	 * @property
	 * @type string
	 */
	type: 'Application',
	/**
	 * Module related initializations can be done here
	 */
	initComponent: function () {
		Application.prototype.initComponent.call(this);

		this.subscribe();

		$('.cw_section_content').on('click', '.cw_agent_name', $.proxy(this.onAgentNameClick, this));
		$('.cw_section_content').on('click', '.cw_target_name', $.proxy(this.onTargetNameClick, this));
		$('.cw_section_content').on('click', '.cw_pillbox', $.proxy(this.onMonitorStateClick, this));

		this.defaultColumns = {
			id: {
				width: 40,
				hidden: false
			},
			assetIndicator: {
				width: 100,
				hidden: false
			},
			name: {
				width: 240,
				hidden: false
			},
			accountName: {
				width: 200,
				hidden: false
			},
			agentName: {
				width: 240,
				hidden: false
			},
			typeText: {
				width: 140,
				hidden: false
			},
			classText: {
				width: 140,
				hidden: true
			},
			targetType: {
				width: 150,
				hidden: false
			},
			targetName: {
				width: 200,
				hidden: false
			},
			tags: {
				hidden: false,
				width: 150
			},
			description: {
				hidden: false
			}
		};

		this.defaultSearchFields = ['name', 'description', 'targetName', 'typeText', 'classText'];
		this.filterMessages = lang.grid.filter;
		this.gridMessages = {
			isTrue: '<span class="glyphicons status_icon circle-arrow-top"></span>',
			isFalse: '<span class="glyphicons status_icon circle-arrow-down"></span>',
			clear: lang.CLEAR,
			info: lang.grid.filter.SHOW_ITEMS,
			filter: lang.FILTER
		};

		this.defaultGridSort = {
			field: 'targetName',
			dir: 'asc',
			compare: null
		};

		this.prefsConfigKeys = {
			searchPhrase: this.preferencesCategory + 'SearchPhrase',
			sort: this.preferencesCategory + 'Sort',
			filter: this.preferencesCategory + 'Filter',
			columns: this.preferencesCategory + 'Columns',
			fsViews: this.preferencesCategory + 'FSViews'
		};

		if (this.monitorAPIName) {
			let actionButtonObj = {
				isMonitor: true,
				monitorType: this.monitorAPIName,
				addAction: this.onMonitorAdd || this.onCreate
			};
			State.mainApp.contextMenu.setActionButton(actionButtonObj);
		}
		var eventStatus = JSON.parse(UserPrefs.get('eventsStatus')) || {
			startFrom: 0,
			playing: true
		};
		this.eventsToolbar = new EventsToolbar({
			renderTo: $('#cw_events_toolbar'),
			callBackFn: $.proxy(this.onEventsTimeout, this),
			subscriberId: this.subscriberId,
			startFrom: eventStatus.startFrom,
			playing: eventStatus.playing
		});

		this.renderDocumentationButton();
	},

	onEventsTimeout: function () {
		this.checkboxStates = Utils.getCheckboxStates(this.grid);
		this.grid.dataSource.read();
	},

	subscribe() {
		this.subscriberId = Utils.guid();
		let subscriptionObj = [{
			eventType: 'MonitorHealth',
			accountId: Cookies.CeesoftCurrentAccountId,
			includeSubaccounts: State.includeSubaccounts,
			monitorType: this.monitorAPIName || null
		}, {
			eventType: 'Administration',
			entityType: 'MONITOR',
			accountId: Cookies.CeesoftCurrentAccountId
		}, {
			eventType: 'AssetHealth',
			accountId: Cookies.CeesoftCurrentAccountId,
			includeSubaccounts: State.includeSubaccounts
		}];
		RemoteEventsManager.subscribe(this.subscriberId, subscriptionObj);
	},

	handleEvents(events) {
		if (this.eventsToolbar.playing) {
			let reloadGrid = false;
			for (let i = 0; i < events.length; i++) {
				if (events[i].eventType === 'MonitorHealth' || events[i].eventType === 'Administration' || events[i].eventType === 'AssetHealth') {
					reloadGrid = true;
					break;
				}
			}
			if (reloadGrid) {
				let grid = this.grid || $('.k-grid').data('kendoCustomGrid');
				if (grid) {
					grid.dataSource.read();
				}
			}
		}
	},
	onMonitorStateClick: function (e) {
		const target = $(e.currentTarget);
		const uid = target.closest('tr').data('uid');
		const record = this.grid.dataSource.getByUid(uid);
		State.mainApp.navigate(EventSummaryRouter.list({additionalQuery: {monitorIds: record.id}}))
	},
	/*
	 * Handler function for rendering user preferences
	 */
	renderUserPreferences: function () {
		var searchFields = $.merge(this.preferencesSearchFields || [], this.defaultSearchFields);
		var searchValue = UserPrefs.get('defaultMonitorsFsView') ? '' : (UserPrefs.get(this.prefsConfigKeys.searchPhrase) || '');
		this.gridFilterPanel = new PreferencesPanel({
			renderTo: 'cw_monitor_filters',
			grid: this.grid,
			modulePrefName: this.preferencesCategory,
			defaultPrefViewKey: 'defaultMonitorsFsView',
			prefListKey: this.prefsConfigKeys.fsViews,
			userPref: State.currentApp.userPref,
			onRemove: $.proxy(State.currentApp.saveUserPreferences, State.currentApp),
			searchFields: searchFields,
			searchValue: searchValue,
			defaultColumns: this.defaultColumns
		});
	},
	renderDocumentationButton: function () {
		if(!this.monitorAPIName) {
			this.monitorAPIName = "all";
		}

		ReactDOM.render(<DocumentationButton monitorType={this.monitorAPIName}>
		</DocumentationButton>,  $('.cw_documentation').get()[0]);
	},

	getTags: async function () {
		let tags = await Utils.getAccountTags();
		this.filterOptions = {
			tags: tags
		};
	},
	/**
	 * Loads user preferences
	 */
	loadUserPreferences: function () {
		this.userPref = [];
		UserPrefs.load(this.preferencesCategory, $.proxy(function (result) {
			if (result.success) {
				this.userPref = result.data;
				this.initComponent();
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},
	/**
	 * Saves user preferences
	 */
	saveUserPreferences: function () {
		var keys = {
            defaultFsView: 'defaultMonitorsFsView'
		};

		if (this.prefsConfigKeys) {
            keys.searchPhrase = this.prefsConfigKeys.searchPhrase;
			keys.columns = this.prefsConfigKeys.columns;
			keys.sort = this.prefsConfigKeys.sort;
			keys.filter = this.prefsConfigKeys.filter;
			keys.FsViews = this.prefsConfigKeys.fsViews;
		}

		this.saveUserPrefs({
			category: this.preferencesCategory,
			filterPanel: this.gridFilterPanel,
			grid: this.grid,
			eventsToolbar: this.eventsToolbar,
			keys: keys
		});
	},
	getGridConfig: function () {
		var sort, filter, columns;

		var defaultFsView = UserPrefs.get('defaultFsView');
		if (Utils.isGuid(defaultFsView)) {
			var pref = JSON.parse(UserPrefs.get(defaultFsView));
			pref = pref || {
				sort: [],
				filter: []
			};
			sort = pref.sort;
			filter = pref.filter;
			columns = pref.columns;
		} else {
			sort = JSON.parse(UserPrefs.get(this.prefsConfigKeys.sort));
			filter = JSON.parse(UserPrefs.get(this.prefsConfigKeys.filter));
			columns = JSON.parse(UserPrefs.get(this.prefsConfigKeys.columns));
		}

		columns = columns || this.defaultColumns;
		columns = Utils.completeColumns(columns, this.defaultColumns);

		return {
			sort: sort || this.defaultGridSort,
			filter: filter,
			columns: columns || this.defaultColumns || []
		};
	},
	/*
	 * Handler function for showing monitor grid notifications
	 * */
	showNotifications: function() {
		if (this.savedMonitorNotification) {
			this.actionNotification.setOptions({
				message: this.savedMonitorNotification,
				status: 'success'
			}).show();
			this.savedMonitorNotification = null;
		}
	},
	onRemove: function (e) {
		this.selectedMonitors = [];
		if (!$(e.currentTarget).hasClass('disabled')) {
			var checkboxes = this.grid.element.find('.cw_grid_check:checked');
			for (var i = 0, length = checkboxes.length; i < length; i++) {
				if ($(checkboxes[i]).is(':checked')) {
					this.selectedMonitors.push($(checkboxes[i]).data('id'));
				}
			}
			this.selectedMonitorsLength = this.selectedMonitors.length;
			if (this.selectedMonitorsLength) {
				this.monitorIndex = 0;
				this.gridMenu.disableItem('cw_update');
				this.gridMenu.disableItem('cw_delete');

				var dialog = new Dialog({
					title: lang.INFO,
					msg: this.selectedMonitors.length > 1 ? lang.assethealth.messages.MONITORS_REMOVE_CONFIRMATION : lang.assethealth.messages.MONITOR_REMOVE_CONFIRMATION,
					icon: 'ERROR',
					actionText: 'DELETE',
					fn: $.proxy(function (value, button) {
						if (button === 'ok') {
							kendo.ui.progress($('#cw_agents_list'), true);
							this.checkDependencies();
						}
					}, this),
					deleteFromGrid: this.grid
				});
				dialog.show();
			}
		}
	},
	/*
	* Handler function for checking dependencies
	* */
	checkDependencies: function () {
		var monitorId = this.selectedMonitors[this.monitorIndex++], record, monitorName, cb;
		if (monitorId) {
			cb = this.grid.element.find('.cw_grid_check:checked');
			record = this.grid.dataSource.get(monitorId);

			this.monitorAccountName = record.accountName;
			this.monitorAccountId = record.accountId;
			this.selectedMonitorId = monitorId;
			monitorName = record.name;

			var dependenciesUrl = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/monitors/' + this.selectedMonitorId + '/dependencies';
			Utils.ajax(dependenciesUrl, 'GET', {}, $.proxy(function (data) {
				if (data.length) {
					this.openDependenciesPopup(data, monitorName, monitorId);
					//setTimeout(this.adjustTableHeight, 100);
				} else {
					var deleteUrl = Settings.serverPath + 'accounts/' + this.monitorAccountId + '/monitors/' + monitorId;
					Utils.ajax(deleteUrl, 'DELETE', {}, $.proxy(function (result) {
						if (result.success) {
							this.checkDependencies();
						} else {
							this.resetChecking();
							Utils.showInfo(lang.ALERT, result.message, result.details);
						}
					}, this));
				}
			}, this));
		} else {
			kendo.ui.progress($(this.grid.element), false);
			this.grid.dataSource.read();
			if (this.dependenciesWindow) {
				this.dependenciesWindow.close();
			}
			this.actionNotification.setOptions({
				message: lang.assethealth.messages.MONITOR_SUCCESS_DELETED,
				status: 'success'
			}).show();
		}
	},
	resetChecking: function () {
		this.selectedMonitors = [];
		this.monitorIndex = 0;

		kendo.ui.progress($(this.grid.element), false);
		this.grid.dataSource.read();
	},
	openDependenciesPopup: function (dependencies, monitorName, monitorId) {
		if (this.dependencyWindow) {
			this.dependenciesWindow.close();
		}
		this.dependenciesWindow = new ModalWindow({
			title: lang.INFO,
			width: 960,
			height: 640,
			minWidth: 220,
			actions: [],
			url: 'include/Dependencies.jsp',
			onClose: $.proxy(function () {
				this.clearCheckboxes();
			}, this),
			refresh: $.proxy(function () {
				$('#cw_dependencies_deleteall').removeClass('hide');
				this.dependencyWindow = new Dependencies({
					accountId: this.monitorAccountId,
					dependencies: dependencies,
					name: monitorName,
					container: '#cw_agents_list',
					infoTemplate: lang.assethealth.messages.MONITOR_DELETE_INFO,
					deleteAll: $.proxy(function (e) {
						var deleteUrl = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/monitors/' + monitorId + '/dependencies/deleteAll';
						Utils.ajax(deleteUrl, 'DELETE', {}, $.proxy(function (result) {
							if (result.success) {
								this.checkDependencies();
							} else {
								Utils.showInfo(lang.ALERT, result.message, result.details);
								this.resetChecking();
							}
						}, this));
					}, this)
				});
			}, this)
		});
		this.dependenciesWindow.open();
	},

	async setMaintenance(isMaintenance) {
		let selectedIds = this.getSelectedIds();
		let url = MonitorsApi.getSetMaintenanceUrl(Cookies.CeesoftCurrentAccountId, isMaintenance);
		let result = await Api.fetchPost(url, selectedIds);
		if (result.success) {
			this.grid.dataSource.read();
		}
	},

	getSelectedIds() {
		let checkboxes = this.grid.element.find('.cw_grid_check:checked');
		let selectedIds = [];
		for (var i = 0, length = checkboxes.length; i < length; i++) {
			if ($(checkboxes[i]).is(':checked')) {
				selectedIds.push($(checkboxes[i]).data('id'));
			}
		}
		return selectedIds;
	},

	clearCheckboxes: function () {
		var checkAll = this.grid.element.find('.cw_grid_check_all'),
				checkboxes = this.grid.element.find('.cw_grid_check'),
				i, length = checkboxes.length;
		checkAll.prop('checked', false);
		for (i = 0; i < length; i++) {
			$(checkboxes[i]).prop('checked', false);
		}
		kendo.ui.progress($(this.grid.element), false);
	},

	adjustSectionHeight: function () {
		let section = $('.cw_section_full');
		if (section.length) {
			let sectionHeight = section.height();
			section.find('.cw_section_content').css('height', sectionHeight);
			section.find('.k-grid-content').css('height', sectionHeight - 40);
		}
	},

	/**
	 * Called when application is unloaded/destroyed
	 */
	destroy: function() {
		if (this.eventsToolbar) {
			this.eventsToolbar.destroy();
		}
		this.saveUserPreferences();
	}
});
