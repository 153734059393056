import Utils from 'tools/utils';

import ErrorHandler from 'core/errorHandler';
import ConfigurationBase from 'areas/service-boards/configurationBase';
import {ApplicationsApi} from '../api';

import {translator} from "core";

const i = translator({
	'Select an application': {
		no: 'Velg en applikasjon'
	},
	'Application': {
		no: 'Applikasjon'
	}
});

export class ApplicationWidgetConfiguration extends ConfigurationBase{
	constructor(config) {
		super(config);
	}

	initComponent() {
		var zoom = !(this.widgetObj.configuration.zoom === false);

		var html;
		html = '<div class="cw_field">' +
			'<label class="cw_inline">' + lang.TITLE + ': </label>' +
			'<input type="text" class="cw_widget_title cw_inline" value="' + (this.widgetObj.title || '') + '" />' +
			'</div>' +
			'<div class="cw_field sp_account_container">' +
			'<label class="cw_inline">' + lang.ACCOUNT + ': </label>' +
			'<div class="cw_dropdown_container">' +
			'<input name=sp_account type="text" value="' + (this.widgetObj.configuration.accountId || '') + '" />' +
			'</div>' +
			'</div>' +
			'<div class="cw_field sp_sp_container">' +
			'<label class="cw_inline">' + i('Application') + ': </label>' +
			'<div class="cw_dropdown_container">' +
			'<input name=sp_sp type="text" value="' + (this.widgetObj.configuration.servicePresentationId || '') + '" />' +
			'</div>' +
			'</div>' +
			'<div class="cw_field sp_sp_container">' +
			'<label class="cw_inline">' + i('Padding') + ': </label>' +
			'<input type="text" name="sp_content_padding" class="cw_inline" value="' + (this.widgetObj.configuration.contentPadding || 10) + '" />' +
			'</div>' +
			'<div class="cw_field">' +
			'<label class="cw_inline">' + i('Scale') + ': </label>' +
			'<span class="cw_checkbox ' + (zoom ? "cw_checked" : "") + '">' +
			'<input name="ap_zoom" type="checkbox" class="cw_checkbox_input" ' + (zoom ? "checked" : "") + ' />' +
				'<span class="cw_checkbox_check"></span>' +
				'<span class="cw_checkbox_button"></span>' +
			'</span>' +
			'</div>';

		this.widgetConfigurationFormEl.empty().append(html);

		this.initKendoComponents();

		$('[name=ap_zoom]', this.widgetConfigurationFormEl).parent()
			.off()
			.on('click', $.proxy(this.onCheckboxClicked, this));
	}

	initKendoComponents() {
		var widgetObj = this.widgetObj;

		this.createAccountComponent({
			element: $(':input[name=sp_account]'),
			container: $('.sp_account'),
			nextElement: $(':input[name=sp_sp]'),
			events: {
				dataSourceChange: $.proxy(function (e, length) {
					this.emptyComponent({
						containers: [{
							container: $('.sp_sp_container'),
							label: lang.widget.SERVICE
						}],
						length: length
					});
				}, this)
			}
		});

		$(':input[name=sp_sp]').kendoComboBox({
			dataSource: new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: () => ApplicationsApi.listUrl(this.widgetAccountId || widgetObj.configuration.accountId),
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json"
					}
				},
				schema: {
					parse: result => {
						return result.success && result.data;
					}
				},
				error: ErrorHandler.kendoServerError
			}),
			change: e => {
				e.sender.wrapper.removeClass('invalid');
			},
			dataTextField: "name",
			dataValueField: "id",
			autoBind: true,
			placeholder: lang.SELECT
		}).data('kendoComboBox');
	}

	onCheckboxClicked(e) {
		var input = $(':checkbox', e.currentTarget);
		input.prop('checked', !input.is(':checked'));
		$(e.currentTarget).toggleClass('cw_checked');
	}

	getValues() {
		var title = $('.cw_widget_title').val().trim();
		var widgetObj = {
			id: this.mode === 'update' || this.wasUpdate ? this.widgetObj.id : Utils.guid(),
			type: 'application_preview',
			title: title,
			configuration: {
				accountId: $(":input[name=sp_account]").data('kendoComboBox').value(),
				servicePresentationId: $(':input[name=sp_sp]').data('kendoComboBox').value(),
				contentPadding: $(':input[name=sp_content_padding]').val() || 10,
				zoom: $(':input[name=ap_zoom]:checked').length === 1 ? true : false,
			}
		};

		return widgetObj;
	}

	onCheckboxClicked(e) {
		var input = $(':checkbox', e.currentTarget);
		input.prop('checked', !input.is(':checked'));
		$(e.currentTarget).toggleClass('cw_checked');
	}

	isValid() {
		var valid = true;

		this.validationMessage = '';

		var presentation = $(':input[name=sp_sp]').data('kendoComboBox');
		if (!Utils.isGuid(presentation.value())) {
			valid = false;
			this.validationMessage = i('Select an application');
			presentation.wrapper.addClass('invalid');
		}

		var account = $(':input[name=sp_account]').data('kendoComboBox');
		if (!Utils.isGuid(account.value())) {
			valid = false;
			this.validationMessage = lang.widget.SELECT_ACCOUNT;
		}

		return {
			valid: valid,
			validationMessage: this.validationMessage
		};
	}
}
