import {change} from "redux-form";
import {getCurrentItemId} from "../selectors/getCurrentItemId";
import {formBuilderForm} from "../constants";
import {select, put} from "redux-saga/effects";
import {getStandalone} from 'controls/react/form/formBuilder/selectors/getStandalone';

export function* authentificationSaved({params}) {
	const standalone = yield select(getStandalone);
	const itemId = yield select(getCurrentItemId);

    if (!standalone && !itemId) {
        return;
    }

    if (params && params[0] && params[0].data && params[0].data.id) {
        yield put(change(formBuilderForm, 'properties.authId', params[0].data.id));
    }
}
