import React from "react";
import {translator} from "core";
import {Button, TextBox, Grid, FormEntry, ActionButtons, MultiSelect} from "controls/react/form";
import {Window, Section, Toolbar, ToolbarItemPosition} from 'controls/react/layout';
import Chosen from 'controls/chosen';
import AttributesApi from './attributesApi'
import {Utils} from "tools";
import Dialog from 'controls/dialog';
import Lang from 'core/localization/lang'
import {AntSelect} from "controls/react/ant/antSelect";
import withRequired from 'controls/react/dropdowns/withRequired';

let i = translator({
	"Attribute": {no: "Attributer"},
	"Duplicate attribute message": {
		"en": "Key and group combination already exist, creating this attribute will overwrite existing attribute. Click update to continue or cancel to cancel operation.",
		"no": "N\u00f8kkel og gruppe finnes, opprette denne attributen vil overskrive attributen som finnes. Klikk oppdater for \u00E5 fortsette eller avbryt for g\u00E5 tilbake"
	}
});

const TextBoxWithRequired = (props) => withRequired(TextBox)(props);

export class AttributeWindow extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			attributeData: this.props.attributeData || {
				group: '',
				key: '',
				value: ''
			},
			parsedData: [],
			groupValue: []
		};
	}

	render() {
		let mode = this.state.groupValue.length >= 1 ? 'multiple' : 'tags';
		return (
			<Window width={400}
					modal
			        height={200}
					title={i('Attribute')}
					ref={window => this.window = window}
					onClose={this.props.onCancel}>
				<Section childrenPadding={true}>
					<FormEntry label={i('Key')} valueLin={this.state.attributeData.key}>
						<TextBoxWithRequired onChange={(value) => this.onAttributeFieldChange(value, 'key')}
								 value={this.state.attributeData.key}
					             required={true}
						/>
					</FormEntry>
					<FormEntry label={i('Value')}>
						<TextBoxWithRequired onChange={(value) => this.onAttributeFieldChange(value, 'value')}
								 value={this.state.attributeData.value}
					             required={true}
						/>
					</FormEntry>
					<FormEntry label={i('Group')} required={!this.state.groupValue.length}>
						<AntSelect value={this.state.groupValue}
						           mode={mode}
						           sortValues={false}
						           isAttributesView={true}
						           onChange={(value, newValues) => this.onGroupsChange(value, newValues)}
						           dataList={this.state.parsedData}
						/>
					</FormEntry>
				</Section>
				<Toolbar>
					<ActionButtons onSave={() => this.saveAttribute()}
								   onCancel={this.props.onCancel}
								   saveDisabled={!this.checkIfMandatoryFieldsCompleted()}
								   mode={this.props.mode}
					/>
				</Toolbar>
			</Window>
		)
	}

	async componentDidMount() {
		this.initialData = {};
		for (let key in this.props.attributeData) {
			this.initialData[key] = this.props.attributeData[key];
		}

		let result = await AttributesApi.getGroups(this.props.assetId);
		if (result.success) {
			let data = result.data;
			let parsedData = [];
			for (let i = 0; i < data.length; i++) {
				parsedData.push({
					id: Utils.guid(),
					name: data[i]
				});
			}
			this.setState({parsedData: parsedData});
		}
		if (this.props.mode === 'update') {
			let groupValue = this.props.attributeData['group'], groupItem = [];
			groupItem.push(groupValue);
			this.setState({
				groupValue: groupItem
			});
		}
	}

	onGroupsChange = (value, dataList) => {
		let valueText = '';
		for (let item of this.state.parsedData) {
			if (item.id === value[0]) {
				valueText = item.name;
			}
		}
		let parsedData = [...this.state.parsedData];
		//fix for receiving dataList empty after removing the first group
		if (!dataList.length) {
			dataList.push({
				id: Utils.guid(),
				name: this.initialData.group
			});
		}
		this.setState({
			parsedData: dataList ? dataList : parsedData
		});
		this.onAttributeFieldChange(valueText, 'group')
	}

	checkIfMandatoryFieldsCompleted() {
		let mandatoryCompleted = true;
		for (let key in this.state.attributeData) {
			if (!this.state.attributeData[key]) {
				mandatoryCompleted = false;
			}
		}
		return mandatoryCompleted;
	}

	onAttributeFieldChange = (value, field) => {
		let groupValue = [];
		let attributeData = {...this.state.attributeData};

		attributeData[field] = value;
		if (field === 'group') {
			if (value.length) {
				groupValue.push(value);
			}
		} else {
			groupValue = [...this.state.groupValue]
		}
		this.setState({
			attributeData: attributeData,
			[field]: value,
			groupValue: groupValue
		});
	}

	saveAttribute = () => {
		let existingAttributes = this.props.existingAttributes;
		let attribute = this.state.attributeData;
		let duplicateAttribute;
		for (let i = 0; i < existingAttributes.length; i++) {
			if (existingAttributes[i].key === attribute.key && existingAttributes[i].group === attribute.group) {
				if (this.initialData.key !== attribute.key && this.initialData.group !== attribute.group) {
					//excluding self if edit mode
					duplicateAttribute = existingAttributes[i];
				}
			}
		}
		if (duplicateAttribute) {
			let dialog = new Dialog({
				title: lang.INFO,
				msg: i('Duplicate attribute message'),
				icon: 'WARNING',
				actionText: 'UPDATE',
				buttons: {
					ok: true,
					cancel: true
				},
				fn: $.proxy(function (value, button) {
					if (button === 'ok') {
						this.props.onSave(attribute, this.initialData);
					}
				}, this)
			});
			dialog.show();
		} else {
			this.props.onSave(attribute, this.initialData);
		}
	}
}

