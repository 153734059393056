
import Utils from 'tools/utils';
import classnames from 'classnames';

export function getServiceState(serviceState, text, operatingState, agentStatus, qualifierError, assetMaintenance) {
	let tooltip = '';
	let exclamation, statusClass;
	if (typeof serviceState === 'string') {
		statusClass = Utils.getStateClass(serviceState);
	} else {
		statusClass = Utils.getStateClassFromIndex(serviceState);
	}
	let classArray = ['cw_service_indicator', 'js_service_state', 'cw_status', 'pointer'];
	if (operatingState === 'IN_MAINTENANCE') {
		classArray.push('is_idle', 'cw_indicator', 'glyphicons', 'wrench');
		tooltip = lang.SERVICE_MAINTENANCE;
	} else if (operatingState === 'NON_OPERATIONAL') {
		classArray.push('is_idle', 'cw_indicator', 'glyphicons', 'clock');
		tooltip = lang.service.OUTSIDE_OPERATION_PERIOD;
	} else {
		classArray.push(statusClass);
		if (qualifierError || ['AGENT_DOWN', 'AGENT_MAINTENANCE'].indexOf(agentStatus) !== -1 || assetMaintenance) {
			tooltip = [];
			let exclamationClass;
			if (qualifierError) {
				exclamationClass = 'exclamation-mark';
				tooltip.push(lang.account.messages.QUALIFIER_ERROR);
			}
			if (agentStatus === 'AGENT_DOWN') {
				exclamationClass = 'exclamation-mark';
				tooltip.push(lang.account.messages.AGENT_WARNING);
			} else if (agentStatus === 'AGENT_MAINTENANCE') {
				exclamationClass = 'wrench';
				tooltip.push(lang.account.messages.AGENT_MAINTENANCE)
			}
			if (assetMaintenance) {
				if (!qualifierError) {
					exclamationClass = 'wrench';
				}
				tooltip.push(lang.account.messages.ASSET_MAINTENANCE)
			}
			tooltip = tooltip.join(' / ');
			let exclamationClasses = classnames(
				'glyphicons',
				exclamationClass
			);
			exclamation = `<span class="${exclamationClasses}">`;
		}
	}

	let classes = classnames(classArray);
	let html = `<span class="${classes}" title="${tooltip}">`;
	if (exclamation) {
		html += exclamation;
	}
	return $(html).prop('outerHTML') + (text || '');
}
