import React from 'react';
import { Section } from 'controls/react/layout/section';
import { Window } from 'controls/react/kendoWrappers/window';
import { Toolbar } from 'controls/react/layout/toolbar';
import { AntInput } from "controls/react/ant/antInput";
import { FormEntry } from "controls/react/form";
import { translator } from "core";
import { useStateLink } from 'core/react/links';
import { Cookies } from "core/cookies";
import { State } from 'tools';
import { ReportsRouter } from './bundleDescription';
import { AntSelect } from "controls/react/ant/antSelect";
import { useAccountsLite } from 'api'
import { ActionButtons } from "controls/react/form/actionButtons";
import ReportApi from './api';

const b = require('b_').with('custom-metric-widget-configuration');

const i = translator({
	"Title is required": {
		"no": "Tittel kreves"
	},
});

function ReportDuplicateWindow(props) {
	const stateLink = useStateLink({
		name: '',
		accountIds: [Cookies.CeesoftCurrentAccountId]
	});
	const accountIdsLink = stateLink.get('accountIds');
	const accountsList = useAccountsLite(Cookies.CeesoftCurrentAccountId);

	const onSave = async () => {
		if (stateLink.get('name').props.invalid || accountIdsLink.props.invalid)
			return;
		if (accountIdsLink.value.length === 1) {
				State.mainApp.navigate(ReportsRouter.details(props.reportId, 'copy', {
					newAccountId: accountIdsLink.value[0],
					newAccountName: (accountsList.find(x => x.id == accountIdsLink.props.value[0])).name,
					newReportName: stateLink.get('name').props.value,
				}));
				props.onClose();
		}
		else if (accountIdsLink.value.length > 1) {
			let payload = {
				reportId: props.reportId,
				accountIds: accountIdsLink.value,
				reportName: stateLink.get('name').props.value
			};
			let response = await ReportApi.copyReports(payload);
			if (response.success == true) {
				props.onReportsDuplicate();
				props.onClose();
			}
		}
	}

	return <Window
		title={props.title}
		containerClass={'data-source-window'}
		modal={true}
		width={400}
		height={'auto'}
		onClose={props.onClose}
		resizable={false} >
		<div className={b()}>
			<Section>
				<FormEntry label={i('Name')} width={"fit"} valueLink={stateLink.get('name').required()}>
					<AntInput valueLink={stateLink.get('name')} />
				</FormEntry>
				<FormEntry label={i('Account')} width={"fit"} valueLink={accountIdsLink.required()}>
					<AntSelect dataList={accountsList}
						mode = {'multiple'}
						value={accountIdsLink.value}
						valueLink={accountIdsLink} />
				</FormEntry>
			</Section>

			<Toolbar>
				<ActionButtons
					onSave={onSave}
					onCancel={props.onClose} />
			</Toolbar>
		</div>
	</Window>
}

export default ReportDuplicateWindow;
