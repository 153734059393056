import './designer.less'

import React from "react";

import {Page} from "controls/react/layout/page";
import {Toolbar, ToolbarItemPosition} from "controls/react/layout/toolbar";
import {ActionButtons} from "controls/react/form/actionButtons";
import {Button} from "antd";

import {DashboardDesigner} from "areas/dashboards/dashboard-designer";
import {PresentationApi} from "areas/dashboards/api";
import {DashboardsRouter} from "areas/dashboards/bundleDescription";
import {Api} from "tools/api";
import {Renderer} from "tools/renderer";
import {Utils} from "tools/utils";
import {BoxView} from "controls/react/layout/boxView";
import {PropertiesForm} from "areas/dashboards/propertiesForm";
import {Section} from "controls/react/layout/section";
import {MenuButton} from "controls/react/menuButton";
import {State} from "tools";
import {RemoteEventsManager} from "core/remoteEventsManager";
import {WidgetsWizard} from "controls/designer/features/widgets/widgetForm";
import {WidgetsActions} from "controls/designer/features/widgets/widgetsActions";
import {newGuid} from "tools/guid";
import {getWidgetDescription} from "controls/designer/features/widgets/allWidgets";
import {convertServiceBoardToDashboard} from "areas/dashboards/serviceBoardConverter";
import Cookies from 'core/cookies'

const b = require('b_').with('dashboards-designer')
const i = require('core/localization').translator({
  "A new dashboard": {
    "no": "Et nytt dashboard"
  },
  "View mode: Click edit to modify dashboard": {
    "no": "Visning: Klikk rediger for å endre dashboardet"
  },
  "Click here if you want to return to original home page": {
    "no": "Klikk her om du vil gå til orginal hjemside"
  },
  "Last event: {0}": {
    "no": "Sist hendelse: {0}"
  },
  "Resolution: {0}": {
    "no": "Oppløsning: {0}"
  },
  "Properties": {
    "no": "Egenskaper"
  },
  "Add widget": {
    "no": "Opprett widget"
  },
  "Convert": {
    "no": "Konverter"
  }
});

export class Designer extends React.Component{
	resolutionRef = React.createRef();

	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			lastUpdate: Renderer.dateRenderer(new Date().getTime(), 'datetime'),
			title: this.getTitle()
		}

		this.menuItems = this.getMenuItems();

		if (this.props.isHomePageDashboard) {
			this.isHomePageDashboard = true;
		} else {
			this.isHomePageDashboard = JSON.parse(sessionStorage.getItem('isCurrentHomeDashboard'));
		}
	}

	render() {
		const hasPermission = State.mainApp.session.hasRole('SERVICEBOARD_UPDATE');
		return <Page noContentPaddings={true}>
			{!this.props.standalone &&
				<Toolbar title={this.state.title}>
					{this.props.mode == 'designer' && <MenuButton items={this.menuItems}/>}
					{this.props.mode == 'viewer' &&
						<span className={b('last-update')} position={ToolbarItemPosition.AFTER_TITLE}>{i('Last event: {0}', this.state.lastUpdate)}</span>
					}

					{this.props.mode == 'designer' &&
						<BoxView type={"info"}
						         contentRef={this.resolutionRef}
						         rounded={true}
						         border={true}position={ToolbarItemPosition.AFTER_TITLE}/>
					}

					{this.props.mode == 'viewer' && hasPermission && !this.props.isHomePageDashboard && !this.isHomePageDashboard &&
						<BoxView type={"info"}
						         rounded={true}
						         border={true}>{i('View mode: Click edit to modify dashboard')}</BoxView>
					}

					{(this.props.isHomePageDashboard || this.isHomePageDashboard) &&
						<BoxView type={"info"}
						         rounded={true}
						         border={true}
								 containerClass='pointer'
								 onClick={this.onOriginalHomePage}
						>{i('Click here if you want to return to original home page')}</BoxView>
					}

					{this.props.mode == 'viewer' && !this.props.isHomePageDashboard && !this.isHomePageDashboard &&
						<ActionButtons onSave={DashboardsRouter.designer(this.props.id)}
					               onCancel={DashboardsRouter.list()}
					               showSave={hasPermission}
					               mode={'edit'}/>
					}

					{this.props.mode == 'designer' &&
						<ActionButtons onSave={this.saveDashboard}
						               onCancel={this.cancelDashboard}
						               actionButtonLabel={this.dashboard?.type == 'LEGACY' ? i('Convert') : null}
						               update={this.props.id != null}/>
					}
				</Toolbar>
			}

			<DashboardDesigner
				id={this.props.id}
				mode={this.props.mode}
				onLoaded={this.onDesignerLoaded}
				dashboardLoaded={this.onDashboardLoaded}
			/>

			{!this.state.loading && this.props.standalone && (this.dashboard.showName || this.dashboard.showUpdateTime) &&
				<div className={b('status-bar',
					{mode: this.dashboard.informationOption, position: this.dashboard.position})}>
					<Toolbar appearance={"transparent"}>
						{this.dashboard.showName && <div position={ToolbarItemPosition.BEFORE_TITLE}>{this.dashboard.tag}</div>}
						{this.dashboard.showUpdateTime && <div position={ToolbarItemPosition.AT_THE_END}>{i('Last event: {0}', this.state.lastUpdate)}</div>}
					</Toolbar>
				</div>
			}
		</Page>;
	}

	componentDidMount() {
		State.loadedDashboards = {[this.props.id]: true};
		this.onEventUnsubscriber = RemoteEventsManager.onEvent(() => {
			this.setState({lastUpdate: Renderer.dateRenderer(new Date().getTime(), 'datetime')})
		});

	}

	componentWillUnmount() {
		this.onEventUnsubscriber();

		setTimeout(() => {
			if (this.props.isHomePageDashboard) {
				sessionStorage.setItem('isCurrentHomeDashboard', true);
			} else {
				sessionStorage.setItem('isCurrentHomeDashboard', false);
			}
		}, 1000);

		if(this.props.mode == 'designer' && this.dashboard.id){
			Utils.returnToPrevAccount();
		}
	}

	getTitle(){
		return this.dashboard?.tag || i('A new dashboard');
	}

	onDashboardLoaded = (dashboard) => {
		this.dashboard = dashboard;

		if(this.props.mode == 'designer' && this.dashboard.id) {
			Utils.changeAccount(dashboard.accountId, dashboard.accountName || 'No account name');
		}
	}

	onDesignerLoaded = async (dashboardDesigner) => {
		this.dashboardDesigner = dashboardDesigner;

		if(this.dashboard.type == 'LEGACY'){
			await convertServiceBoardToDashboard(this.dashboard, dashboardDesigner);
		}

		this.props.initialized({
			title: this.getTitle()
		});

		if(this.resolutionRef.current){
			dashboardDesigner.designer.graph.getModel().addListener(mxEvent.CHANGE, this.updateResolutionLabel);
			this.updateResolutionLabel();
		}

		this.setState({
			loading: false,
			title: this.getTitle()
		});

		if(this.props.id == null){
			const saved = await this.dashboardDesigner.editProperties();
			if(!saved){
				this.props.app.navigation.pop();
			}
			else{
				this.setState({title: dashboardDesigner.dashboard.tag})
			}
		}

		if(this.props.standalone && !this.dashboardDesigner.designer.dashboardSettings.allowRedirects){
			State.mainApp._navigationEnabled = false;
		}

		if(this.props.mode != 'designer'){
			onAdminEvent(this.dashboardDesigner, this.props.id, e => {
				if(this.selfChange)
					return;

				State.mainApp.reloadCurrentModule();
			});
		}
	}

	updateResolutionLabel = () => {
		const {graph} = this.dashboardDesigner.designer;
		const {width, height} = graph.view.getGraphBounds();
		if(this.resolutionRef.current) {
			this.resolutionRef.current.innerText = i('Resolution: {0}', width + 'x' + height);
		}
	}

	saveDashboard = async () => {
		this.bypassDirtyCheck = true;
		const isStillPresentation = this.dashboard.type == 'PRESENTATION';

		const dashboard = this.dashboardDesigner.getDashboardForSaving();
		if(dashboard == null)
			return;

		this.selfChange = true;
		const result = await PresentationApi.save(dashboard);

		//if it is still a presentation then a conversion happens during a saving.
		//it takes time but we already redirecting user to a vewier again and it fails.
		// So we redirect user to a grid instead
		if(isStillPresentation) {
			State.mainApp.navigate(DashboardsRouter.list())
		}else {
			this.props.app.navigation.pop();
		}
	}

	cancelDashboard = () => {
		this.bypassDirtyCheck = true;
		State.mainApp.context.navigation.pop();
	}

	dirtyCheck = () => {
		return {
			isDirty: !this.bypassDirtyCheck && this.dashboardDesigner?.isDashboardDirty(),
			message: lang.dashboard.EXIT_DIRTY_CONFIRMATION
		}
	}

	getMenuItems() {
		return [{
			icon: 'display',
			text: i('Properties'),
			fn: () => this.dashboardDesigner.editProperties()
		}, {
			icon: 'plus',
			text: i('Add widget'),
			fn: () => {
				this.dashboardDesigner.designer.ui.actions.get(WidgetsActions.ShowEditWidgetFormDefault).funct();
			}
		}];
	}

	tryExit (moduleName, id, config, role, loadInNewTab) {
		if (role && !State.mainApp.session.hasRole(role)) {
			//Todo: show privileges warning
		} else {
			State.mainApp.loadModule(moduleName, id, config, undefined, undefined, loadInNewTab);
		}
	}

	onOriginalHomePage() {
		State.mainApp.initHomePage();
	}
}

export default Designer;

export function onAdminEvent(dashboardDesigner, dashboardId, callback){
	const originalDestroy = dashboardDesigner?.designer?.destroy;
	if(originalDestroy == null)
		return;

	const unsubscriber = RemoteEventsManager.subscribeCallback([{
		eventType: 'ServiceBoard',
		actionTypes: ['UPDATE', 'DELETE'],
		serviceBoardId: dashboardId
	}], e => {
		callback(e);
	});

	dashboardDesigner.designer.destroy = function() {
		unsubscriber.unsubscribe();
		originalDestroy.apply(this, arguments)
	}
}
