import Utils from 'tools/utils';
import Cookies from 'core/cookies';
import Settings from 'settings';
import Renderer from 'tools/renderer';
import ErrorHandler from 'core/errorHandler';
import State from 'tools/state';
import BaseFormatPanel from 'controls/designer/graph-editor/js/baseFormatPanel';
import Api from 'tools/api';
import ReactDOM from 'react-dom';
import React from 'react';
import RuleTextbox from "areas/services/designer/graph-editor-extensions/ruleTextbox";
import WeightProperties from "areas/services/designer/graph-editor-extensions/weightProperties";
import {translator} from 'core';
import {StateColors} from 'tools/states';
import {getPresentationModeDataSource} from "areas/services/designer/graph-editor-extensions/presentationMode";
import {iterateAllCells} from "controls/designer/utils";

const i = translator({
  "State color": {
    "no": "Tilstandsfarge"
  },
  "Element up": {
    "no": "Element opp"
  },
  "Element down": {
    "no": "Element ned"
  },
  "Custom state color only applies to the servicemodel preview": {
    "no": "Tilpasset tilstandsfarge gjelder kun for tjenestemodellvisning"
  },
  "Reset to default": {
    "no": "Tilbakestill til standard"
  },
  "Service element presentation": {
    "no": "Tjeneste element presentasjon"
  },
  "Presentation mode tooltip": {
    "en": "Maximized: Children will always be shown. This is the default setting for a service element\nMaximize all elements if down: All child elements will be expanded if parent element is down \nMaximize down elements: Child elements in down state will be expand if parent is down\nMinimized: Elements will never be expand regardless of state",
    "no": "Maksimert: sub elementer vil alltid vises. Dette er standardinnstillingen for et tjenesteelement\nMaksimer alle elementer hvis nede: Alle sub elementer vil bli utvidet hvis overordnede element er nede \nMaksimer nedelementer: Sub elementer i nede tilstand vil utvides hvis overordnede er nede\nMinimert: Elementer vil aldri ekspanderes uavhengig av tilstand"
  }
});

export default function ElementPropertiesPanel(format, editorUi, container) {
	BaseFormatPanel.call(this, format, editorUi, container);
	this.init();
}

mxUtils.extend(ElementPropertiesPanel, BaseFormatPanel);

ElementPropertiesPanel.prototype.init = function () {
	this.graph = this.editorUi.editor.graph;
	this.container.style.borderBottom = 'none';

	this.root = $('<div class=panel></div>');

	var cell = this.graph.getSelectionCell();

	this.initName();
	this.initDescription();

	if (cell.isServiceLink()) {
		this.initService();
	} else {
		this.initAggregated();
		this.initPresentationMode();
		this.initStateExpressionRule();
	}

	this.initStateColors();

	this.initTeam();
	this.initCVID();

	this.container.appendChild(this.root[0]);

	var listener = $.proxy(this.onModelChanged, this);
	this.graph.getModel().addListener(mxEvent.CHANGE, listener);
	this.listeners.push({
		destroy: $.proxy(function () {
			this.graph.getModel().removeListener(listener);
		}, this)
	});
	listener();
};

ElementPropertiesPanel.prototype.initName = function () {
	var row = this.createRow(lang.NAME, '<input type="text" name="elementName"/>');

	this.name = $(':input', row);
	this.root.append(row);

	this.name.change($.proxy(function () {
		this.graph.getSelectionCell().customData.name = this.name.val();
		var object = this.graph.getSelectionCell().getValue();
		object.setAttribute("label", this.name.val());
		this.graph.getModel().setValue(this.graph.getSelectionCell(), object);
	}, this));
}

ElementPropertiesPanel.prototype.initDescription = function () {
	var row = this.createRow(lang.DESCRIPTION,
		'<textarea placeholder="' + lang.presentation.designer.DESCRIPTION_WATERMARK + '"></textarea>'
	);

	this.description = $(':input', row);
	this.description.val(this.graph.getSelectionCell().customData.description);
	this.root.append(row);

	this.description.change($.proxy(function () {
		this.graph.getSelectionCell().customData.description = this.description.val();
	}, this));
}

ElementPropertiesPanel.prototype.initAggregated = function () {
	let isAggregated = this.graph.getSelectionCell().customData.isAggregated;

	var row = $('' +
		'<div class="panel-row">' +
		'<div class="label"><label>' + lang.designer.AGGREGATE_FROM + '</label></div>' +
			`<div style="display: flex;"><div><input type="radio" name="prop_aggregate" value="elements" ${isAggregated ? 'checked' : ''}> ` + lang.designer.SERVICE_ELEMENTS + '</div>' +
			`<div style="margin-left: 10px"><input type="radio" name="prop_aggregate" value="qualifiers" ${!isAggregated ? 'checked' : ''}> ` + lang.designer.SERVICE_QUALIFIERS + '</div></div>' +
		'</div>' +
		'</div>');

	//this.aggregated = $(':input', row);
	this.root.append(row);

	$(':input[value=elements]', row).click(() => {
		this.editorUi.actions.get("sd-aggregated-elements").funct();
	});
	$(':input[value=qualifiers]', row).click(() => {
		this.editorUi.actions.get("sd-aggregated-qualifiers").funct();
	});
}

ElementPropertiesPanel.prototype.initStateExpressionRule = function () {
	this.ser = this.createDropdownRow(lang.viewer.STATE_EXPRESSION_RULE, {
		dataSource: {
			data: [
				{text: lang.designer.DEFAULT, value: "sd-rules-default"},
				{text: lang.designer.RULESET, value: "sd-ser-ruleset"},
				{text: lang.designer.WEIGHTED, value: "sd-ser-weighted"}
			]
		},
		dataTextField: "text",
		dataValueField: "value",
		value: lang.DEFAULT,
		select: (e) => {
			this.setRulesetOption(e.dataItem.value);

			if (e.dataItem.value === 'sd-ser-ruleset') {
				this.editorUi.actions.get('sd-ser-ruleset').funct()
			}
		}
	});
	var cell = this.graph.getSelectionCell();
	var isChecked = cell.customData.coverageWarning ? 'checked' : '';
	var row = $('' +
		'<div id="cw_coverage_warning_container" title="' + lang.designer.COVERAGE_WARNING_MOUSEOVER + '">' +
		'<input type="checkbox" id="cw_coverage_warning" ' + isChecked + ' style="margin-top: 10px" />' +
		'<label for="cw_coverage_warning" class="right" style="position: absolute; margin-top: 8px; padding-left: 8px">' + lang.designer.COVERAGE_WARNING + '</label>' +
		'</div>');

	this.coverageWarning = $(':input', row);
	this.root.append(row);

	if (cell.customData.rule.type === 'Default') {
		this.ser.select(0)
	} else if (cell.customData.rule.type === 'Ruleset') {
		this.ser.select(1);
	} else {
		this.ser.select(2);
	}

	this.setRulesetOption(cell.customData.rule.type);

	this.coverageWarning.change($.proxy(function () {
		cell.customData.coverageWarning = $('#cw_coverage_warning').is(':checked');
	}, this));
}

ElementPropertiesPanel.prototype.setRulesetOption = function (value) {
	switch (value) {
		case 'sd-ser-ruleset':
		case 'Ruleset':
			this.initRuleTextbox();
			$(document).on('ruleset-changed.sd', () => this.initRuleTextbox());
			return;
		case 'sd-ser-weighted':
		case 'Weighted':
			this.initSelectWeights();
			return;
		default: {
			const cell = this.graph.getSelectionCell();
			cell.customData.rule = {type: 'Default'};
			this.clearOptions();
			return;
		}
	}
}

ElementPropertiesPanel.prototype.clearOptions = function () {
	let $div = $('.ruleset-options', this.root);

	if ($div.length !== 0) {
		ReactDOM.unmountComponentAtNode($div[0]);
	}

	return $div[0];
};

ElementPropertiesPanel.prototype.getRulesetOptionNode = function () {
	let node = this.clearOptions();

	if (!node) {
		const $div = $('<div/>').addClass('ruleset-options');
		$('#cw_coverage_warning_container', this.root).before($div);
		node = $div[0];
	}

	return node;
};

ElementPropertiesPanel.prototype.initRuleTextbox = function () {
	const cell = this.graph.getSelectionCell();

	const node = this.getRulesetOptionNode();

	let elements;

	if (cell.customData && cell.customData.getFirstLevelChildrenNodes) {
		elements = cell.customData.getFirstLevelChildrenNodes();
	}

	ReactDOM.render(<RuleTextbox
		value={cell.customData.rule.ruleset}
		qualifiers={cell.customData.qualifiers}
		elements={elements}
		onEditClick={() => this.editorUi.actions.get('sd-ser-ruleset').funct() }
	/>, node);
};

ElementPropertiesPanel.prototype.initSelectWeights = function () {
	var cell = this.graph.getSelectionCell();

	$('#cw_coverage_warning').prop('checked', !!cell.customData.coverageWarning);

	const node = this.getRulesetOptionNode();

	ReactDOM.render(<WeightProperties
		rule={cell.customData.rule}
		isAggregated={cell.customData.isAggregated}
		onUpdate={(value) => { cell.customData.rule = value; } }
	/>, node);
},


ElementPropertiesPanel.prototype.initService = function () {
	var cell = this.graph.getSelectionCell();

	if(cell.customData.state == 'INVALID' && cell.customData.linkAccountId == null){
		this.createRow(null, lang.viewer.messages.SERVICE_DELETED, 'title');
	}

	this.account = this.createDropdownRow(lang.ACCOUNT, {
		dataSource: new kendo.ceeview.DataSource({
			transport: {
				read: {
					url: Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/subaccounts/allLevels/lite',
					contentType: "application/json; charset=utf-8",
					type: "GET",
					dataType: "json",
					cache: false
				}
			},
			schema: {
				parse: function (response) {
					response.unshift({
						id: "00000000-0000-0000-0000-00000000000f",
						name: lang.SHARED
					});

					return response;
				},
			},
			error: ErrorHandler.kendoServerError
		}),
		dataTextField: 'name',
		dataValueField: 'id',
		change: $.proxy(function (e) {
			var accountId = e.sender.value();
			if (accountId === "00000000-0000-0000-0000-00000000000f" || Utils.isGuid(accountId)) {
				this.service.enable(true);
				this.service.dataSource.read();
			} else {
				this.service.enable(false);
			}

		}, this),
		optionLabel: lang.SELECT_ACCOUNT,
		value: cell.customData.linkAccountId
	});

	this.service = this.createDropdownRow(lang.SERVICE, {
		dataSource: new kendo.ceeview.DataSource({
			transport: {
				read: {
					url: $.proxy(function () {
						var shared = false;
						var accountId = this.account.value();
						if (!accountId || accountId == "00000000-0000-0000-0000-00000000000f") {
							shared = true;
							accountId = Cookies.CeesoftCurrentAccountId;
						}

						if (cell.customData.state == 'INVALID' && cell.customData.linkAccountId == null) {
							accountId = Cookies.CeesoftCurrentAccountId;
						}

						return Settings.serverPath + 'accounts/' + accountId + '/services/lite?hasModel=true&shared=' + shared
					}, this),
					contentType: "application/json; charset=utf-8",
					type: "GET",
					dataType: "json",
					cache: false
				}
			},
			// filter the dropdown to exclude the current service model
			filter: {
				field: 'id',
				operator: 'neq',
				value: cell.getDatasource().services[0].id
			},
			error: ErrorHandler.kendoServerError
		}),
		dataTextField: 'name',
		dataValueField: 'id',
		autoBind: true,
		enabled: cell.customData.linkServiceId !== null ? true : false,
		select: $.proxy(this.onServiceSelect, this),
		change: $.proxy(function (e) {
			if (e.sender.value() == '')
				return;

			var cell = this.graph.getSelectionCell();

			cell.customData.linkServiceId = e.sender.value();
			cell.customData.linkAccountId = this.account.value();
		}, this),
		optionLabel: lang.SELECT_SERVICE,
		value: cell.customData.linkServiceId
	});
	if (cell.customData.linkServiceId) {
		this.service.dataSource.read();
		this.service.enable(true);
	}
},

ElementPropertiesPanel.prototype.initTeam = function () {
	var cell = this.graph.getSelectionCell();
	var hasTeamRole = State.mainApp.session.hasRole('TEAM_READ') || State.mainApp.session.hasRole('TEAM_LIST');
	if (!hasTeamRole)
		return;

	var row = this.createRow(lang.TEAM, '<input name="team"/>');

	row.find(':input').kendoSortedMultiSelect({
		dataSource: new kendo.ceeview.DataSource({
			transport: {
				read: {
					url: Api.team.urls.list(),
					contentType: "application/json; charset=utf-8",
					type: "GET",
					dataType: "json",
					cache: false
				}
			},
			error: ErrorHandler.kendoServerError
		}),
		dataTextField: 'name',
		dataValueField: 'id',
		autoBind: hasTeamRole,
		index: 0,
		change: $.proxy(function (e) {
			this.graph.getSelectionCell().customData.responsibleTeamIds = e.sender.value();
		}, this),
		template: Renderer.templates.responsibleTeam,
		valueTemplate: Renderer.templates.responsibleTeam,
		optionLabel: {
			name: lang.service.SLA_NO_TEAM,
			id: null,
			accountName: ''
		},
		value: cell.customData.responsibleTeamIds
	}).data('kenoMultiSelect');
}

ElementPropertiesPanel.prototype.initCVID = function () {
	var cell = this.graph.getSelectionCell();
	let row = this.createRow('CVID', '<input type="text" name="cvid" readonly/>');
	row.find(':input').val(cell.customData.id);
}

ElementPropertiesPanel.prototype.onModelChanged = function () {
	var cell = this.graph.getSelectionCell();
	var label =  this.graph.convertValueToString(cell);

	this.name.val(label);
	cell.customData.name = label;
}

ElementPropertiesPanel.prototype.initStateColors = function () {
	let stateUp = this.createColorOption(i('Element up'), () => {
			let cell = this.graph.getSelectionCell();
			return cell.getAttribute('state-color-active');
		}, color => {
			this.graph.getModel().beginUpdate();
			try {
				let cell = this.graph.getSelectionCell();
				cell.setAttribute('state-color-active', color);
				this.graph.fireEvent(new mxEventObject('cell-state-color-changed', 'cell', cell));
			}
			finally {
				this.graph.getModel().endUpdate();
			}
		},
		StateColors.ACTIVE,
		null,
		null,
		true,
		i('Custom state color only applies to the servicemodel preview')
	);

	let stateDown = this.createColorOption(i('Element down'),  () => {
			let cell = this.graph.getSelectionCell();
			return cell.getAttribute('state-color-inactive');
		}, color => {
			this.graph.getModel().beginUpdate();
			try {
				let cell = this.graph.getSelectionCell();
				cell.setAttribute('state-color-inactive', color);
				this.graph.fireEvent(new mxEventObject('cell-state-color-changed', 'cell', cell));
			}
			finally {
				this.graph.getModel().endUpdate();
			}
		},
		StateColors.ERROR,
		null,
		null,
		true,
		i('Custom state color only applies to the servicemodel preview')
	);

	var row = this.createRow(i('State color'), [stateUp, stateDown], i('Custom state color only applies to the servicemodel preview'));


	let button = $(`<input type="button" value="${i('Reset to default')}" class="k-button k-primary" />`)
	this.createRow(null, button);
	button.click(() => {
		this.graph.getModel().beginUpdate();
		try {
			let cell = this.graph.getSelectionCell();
			cell.removeAttribute('state-color-inactive');
			cell.removeAttribute('state-color-active');
			this.graph.fireEvent(new mxEventObject('cell-state-color-changed', 'cell', cell));
		}
		finally {
			this.graph.getModel().endUpdate();
		}
	});
}

ElementPropertiesPanel.prototype.initPresentationMode = function () {
	let cell = this.graph.getSelectionCell();
	this.createDropdownRow(i('Service element presentation'), {
		dataSource: {
			data: getPresentationModeDataSource()
		},
		dataTextField: "text",
		dataValueField: "value",
		value: cell.getAttribute('sd-visualisation') || 'maximized',
		select: (e) => {
			this.graph.getModel().beginUpdate();
			try {
				cell.setAttribute('sd-visualisation', e.dataItem.value);

				//we set Show maximize/Minimiz icons to true if all had been set to maximized before current change
				if(e.dataItem.value != 'maxmized') {
					let anyNotMaximizedCell = false;
					iterateAllCells(this.graph, currentCell => {
						if(currentCell == cell)
							return;

						const value = currentCell.getAttribute('sd-visualisation')
						if (value != null && value != 'maximized') {
							anyNotMaximizedCell = true;
						}
					});

					if (!anyNotMaximizedCell) {
						this.graph.presentationModeSettings.showIcons = true;
					}
				}
			}
			finally {
				this.graph.getModel().endUpdate();
			}
		}
	}, i('Presentation mode tooltip'));
}
