import Utils from 'tools/utils'
import Cookies from 'core/cookies'
import Widget from 'areas/service-boards/widget'
import Settings from 'settings'
import CustomNotification from 'controls/customNotification'
import Highcharts from 'highcharts'
import Configuration from 'configuration';


export function HistoryWidget(config) {
	Widget.call(this, config);

	this.requestPath = Settings.serverPath;
	if (this.sessionId) {
		this.requestPath = Settings.serverPath + 'sessions/' + this.sessionId + '/';
	}
	this.configuration.timezone = this.configuration.timezone === "<User Time>" ? Cookies.CeesoftTimezone : this.configuration.timezone;
	this.initComponent();
};

export {HistoryWidget as default}

jQuery.extend(HistoryWidget.prototype, Widget.prototype, {
	initComponent: function () {
		var widgetContentDiv;

		this.customControls = {
			target: '#' + this.id,
			toggleClick: function (value) {
				if ($('#' + this.id).find('.k-i-toggle').length) {
					$('#' + this.id).find('.k-i-toggle').trigger('click');
				} else {
					$('#' + this.id).closest('.k-window').find('.k-i-toggle').trigger('click');
				}
			}
		};
		this.customControls.change = $.proxy(this.onCustomControlsChange, this);
		this.createCustomControls();

		if (!this.renderTo) {
			widgetContentDiv = $('#' + this.id).find('.cw_section_content');
			$('#' + this.id).find('.cw_widget_settings').find('.cw_missing_data').detach();
		} else {
			widgetContentDiv = $('#' + this.renderTo);
			widgetContentDiv.closest('.k-window').find('.cw_widget_settings').find('.cw_missing_data').detach();
		}

		this.widgetContentDiv = widgetContentDiv;
		kendo.ui.progress(widgetContentDiv, true);

		this.statusNotification = new CustomNotification({
			animationTime: 0,
			appendToElement: widgetContentDiv,
		});

		var widgetDiv = $('#' + this.id);
		widgetDiv.off();
		widgetDiv.on('drop', $.proxy(this.onDrop, this));

		this.load();
	},
	/**
	 * Updates the widget title
	 */
	updateTitle: function () {
		var title, strTitle;
		var titleSpan = this.isKendoWindow ? $('#' + this.id).closest('.k-window').find('.k-window-title') : $('#' + this.id).find('.cw_section_title');
		title = [this.configuration.profileText, this.configuration.categoryText, this.configuration.ruleTypeText, this.configuration.sampleRateText].join(' &raquo; ');
		strTitle = title.split('&raquo;').join('\u00bb');
		title = this.title || title;
		strTitle = this.title || strTitle;
		titleSpan.html(title);
		titleSpan.attr('title', strTitle);
	},
	/*
	* Handler function for loading the widget details
	* */
	load: function () {
		var startDate, endDate;
		var url = this.requestPath + 'kpi/' + this.configuration.profileId + '/types/' + this.configuration.ruleTypeId + '/sampleRates/' + this.configuration.sampleRateId;
		if (this.configuration.kpiAccountId === 'aggregated') {
			url += '/data';
		} else {
			url += '/accounts/' + this.configuration.kpiAccountId + '/accountData';
		}
		url += '/?timeZone=' + this.configuration.timezone;
		if (this.configuration.period === 'CUSTOM') {
			startDate = new Date(this.configuration.startDate);
			endDate = new Date(this.configuration.endDate);
			url += '&startDate=' + Utils.customDateToString(startDate.getTime(), 'YYYYMMDDHHmmss') + '&endDate=' + Utils.customDateToString(endDate.getTime(), 'YYYYMMDDHHmmss');
		} else {
			url += '&timeSelector=' + this.configuration.period;
		}
		url += '&resample=' + this.configuration.rate;
		url += '';

		Utils.ajax(url, 'GET', {}, $.proxy(function (result) {
			if (result.length) {
				this.data = result;
				this.render();
			} else {
				kendo.ui.progress(this.widgetContentDiv, false);
				this.statusNotification.setOptions({
					message: lang.serviceBoard.messages.NO_DATA,
					status: 'error'
				}).show();
			}
		}, this), $.proxy(function (result) {
			kendo.ui.progress(this.widgetContentDiv, false);
			this.statusNotification.setOptions({
				message: lang.serviceBoard.messages.NO_DATA,
				status: 'error'
			}).show();
		}, this), null, 60000, $.proxy(function () {
			kendo.ui.progress(this.widgetContentDiv, false);
			this.statusNotification.setOptions({
				message: lang.messages.NO_DATA_AVAILABLE,
				status: 'error'
			}).show();
		}, this));
	},
	/*
	* Handler function for rendering the widget
	* */
	render: function () {
		this.updateTitle();

		var complementaryPercentageSerie = [], kpiSeries = [], categories = [], time;
		this.widgetContentDiv.empty().append('<div class="cw_kpi_history"></div>');

		for (var i = 0, length = this.data.length; i < length; i++) {
			time = this.data[i].time;

			if (this.configuration.rate === 'ONE_DAY') {
				categories.push(Renderer.browserDateRenderer(time, '', 'dd MMM'));
			} else if (this.configuration.rate === 'ONE_WEEK') {
				categories.push(lang.WEEK[0] + Utils.getWeekOfMonth(time));
			} else if (this.configuration.rate === 'ONE_MONTH') {
				categories.push(Renderer.browserDateRenderer(time, '', 'MMM'));
			}

			kpiSeries.push({
				name: Renderer.browserDateRenderer(time, '', Utils.widgetDateFormat(Cookies.CeesoftUserDateFormat, 'kpiHistory')),
				color: '#7cc623',
				y: this.data[i].value,
				time: time
			});
		}

		var self = this;
		let container = this.widgetContentDiv.find('.cw_kpi_history');
		let height;
		let dashboardWindow = container.closest('.section__content');
		if (dashboardWindow.length) {
			height = dashboardWindow.height();
			container.css('height', height);
		} else {
			height = this.widgetContentDiv.height();
		}
		this.chart = new Highcharts.Chart({
			chart: {
				type: 'column',
				renderTo: this.widgetContentDiv.find('.cw_kpi_history')[0],
				height: height,
				marginTop: 30,
				marginBottom: 70,
				marginRight: 20,
				events: {
					load: function () {
						var ren = this.renderer;
						var label = lang.TIMEZONE + ': ' + self.configuration.timezone;
						ren.label(label, 20, this.chartHeight - 18).css({
							'color': '#999'
						}).add();
						// Draw the asset and period label
						var periodValue = self.configuration.period;
						var mapping = {
							'LAST30DAYS': lang.MONTH,
							'LAST7DAYS': lang.WEEK,
							'LASTDAY': lang.DAY,
							'LASTHOUR': lang.HOUR,
							'CUSTOM': lang.CUSTOM
						};
						var period = mapping[periodValue];
						var rightLabel = lang.PROFILE + ': ' + self.configuration.profileText + ' - ' + lang.widget.PERIOD + ': ' + period;
						ren.label(rightLabel, 20, this.chartHeight - 33).css({
							'color': '#999'
						}).add();
						kendo.ui.progress(self.widgetContentDiv, false);
					}
				}
			},
			title: {
				text: null
			},
			legend: {
				enabled: false
			},
			credits: {
				enabled: false
			},
			exporting: Configuration.highcharts.exporting,
			xAxis: {
				categories: categories,
				labels: {
					staggerLines: 2
				}
			},
			yAxis: {
				title: {
					text: lang.KPI,
					margin: 0
				},
				min: 0,
				max: 100,
				stackLabels: {
					enabled: true,
					style: {
						color: '#000000',
						visibility: 'visible',
						fontWeight: 'normal'
					},
					formatter: function () {
						return Math.floor(this.points['1,' + this.x + ',0'][1]);
					}
				}
			},
			tooltip: {
				crosshairs: false,
				formatter: function () {
					var label = lang.KPI + ': ' + (Math.round(this.y * 1000) / 1000) + '<br/>';
					label += lang.PERIOD + ': ' + Renderer.browserDateRenderer(this.point.options.time, "date");

					return label;
				}
			},
			plotOptions: {
				column: {
					stacking: 'normal',
					dataLabels: {
						enabled: false
					}

				},
				series: {
					cursor: 'pointer',
					minPointLength: 2
				}
			},
			series: [{
				name: ' ',
				data: []
			}, {
				name: lang.PERCENTAGE,
				data: kpiSeries
			}]
		});
		kendo.ui.progress(this.widgetContentDiv, false);
	},
	/*
	* Handler function for custom controls change
	* @param {Object} e The change event
	* */
	onCustomControlsChange: function (e) {
		var target = $(e.currentTarget);
		var value = e.sender.value();

		this.configuration.period = value;
		this.load();
	},
	/**
	 * Triggered after widget resize
	 * @param {Object} event The resize event
	 * @param {Object} ui The UI element - see http://api.jqueryui.com/resizable/
	 */
	onResize: function (event, ui) {
		setTimeout(() => {
			this.createCustomControls();
		});
	},
	/**
	 * Handler function for the drop event
	 */
	onDrop: function () {
		this.destroy();

		//reload
	},
	/**
	 * Destroy
	 */
	destroy: function (dontUnsubscribe) {

	}
});
