import React, {ChangeEventHandler, KeyboardEventHandler, useCallback} from "react";
import {Select} from 'antd';
import { Link } from "core/react/links";


export function renderSelectOptions(list: Array<any>, {nameField = 'name', valueField = 'id'}) {
	const {Option} = Select;
	return list.map(x => <Option key={x[valueField]} value={x[valueField]}>{x[nameField]}</Option>);
}

type ValueLinkHolder<T> = {
	valueLink?: Link<T>
}

export function captureValueLink<VT, T extends ValueLinkHolder<VT>>(props: T): Omit<T, "valueLink"> & {onChange: ChangeEventHandlerWrapped<any>} {
	let {valueLink, ...others} = props;

	if (valueLink) {
		Object.assign(others, valueLink.props);
	}

	return others;
}


export type ChangeEventHandlerWrapped<E extends HTMLInputElement|HTMLTextAreaElement> = (value: string, e: React.ChangeEvent<E>) => void;

type OnChangeHolder<E extends HTMLInputElement|HTMLTextAreaElement> = {
	onChange: ChangeEventHandlerWrapped<E>
}


export function wrapOnChange<E extends HTMLInputElement|HTMLTextAreaElement, T extends OnChangeHolder<E>>(props: T){
	return {
		...props,
		onChange: (e: React.ChangeEvent<E>) => {
			props.onChange(e.target.value, e);
		}
	}
}

type OnKeyUpHolder<E extends Element> = {
	onKeyUp?: KeyboardEventHandler<E>,
	onPressEnter?: () => void,
	onPressEsc?: () => void,
}

export function addEnterEscHandlers<E extends Element, P extends OnKeyUpHolder<E>>(props: P){
	let {onPressEnter, onPressEsc, ...restProps} = props

	if(!onPressEnter && !onPressEsc)
		return restProps

	const initialOnKeyUp = props.onKeyUp

	const onKeyUpWrapper = (e: React.KeyboardEvent<E>) => {
		if(onPressEnter && e.key == 'Enter'){
			onPressEnter()
		}

		if(onPressEsc && e.key == 'Escape'){
			onPressEsc()
		}

		initialOnKeyUp && initialOnKeyUp(e)
	};

	restProps.onKeyUp = onKeyUpWrapper;
	return restProps
}
