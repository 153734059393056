import React from "react";

import {DropDownList} from "controls/react/kendoWrappers/dropDownList";
import {CeeViewDataSource} from "tools/ceeViewDataSource";
import {AssetGroupAPI} from "tools/apis/assetGroupAPI";
import {AgentAPI} from "tools/apis/agentAPI";
import {ErrorHandler} from "core/errorHandler";
import {translator} from "core/localization";
import withRequired from 'controls/react/dropdowns/withRequired';

const i = translator({
  "Select asset group...": {
    "no": "Velg ressursgruppe..."
  }
});

export class AssetGroupDropDown extends React.PureComponent {
	constructor(props) {
		super(props);

		this.dataSource = new CeeViewDataSource({
			transport: {
				read: {
					url: AssetGroupAPI.getListUrl(props.accountId),
					contentType: 'application/json; charset=utf-8',
					type: 'GET',
					dataType: 'json',
					cache: false
				}
			},
			error: ErrorHandler.kendoServerError
		});

		this.config = {
			dataSource: this.dataSource,
			dataTextField: 'name',
			dataValueField: 'id',
			suggest: true,
			placeholder: i('Select asset group...'),
			autoBind: true
		};
	}

	render() {
		return <DropDownList config={this.config}
		                     combo={true}
		                     type='assetGroup'
		                     {...this.props}/>
	}
}

export default withRequired(AssetGroupDropDown);
