import {root as monitorsRoot} from '../router';

const root = monitorsRoot + '/eatonups';

export function getDescriptions(){
	return [
		{
			url: root + '/:id',
			getModuleImport: () => import('./details')
		}
	];
}

export class EatonUpsRouter {
	static create() {
		return `${root}/new`;
	}
}
