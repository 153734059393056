import Utils from 'tools/utils';
import Cookies from 'core/cookies';
import Settings from 'settings';
import CustomNotification from 'controls/customNotification';

import GridMenu from 'controls/gridMenu';
import PreferencesPanel from 'controls/preferencesPanel';
import State from 'tools/state';
import UserPrefs from 'tools/userPrefs';
import Application from 'core/application';
import RemoteEventsManager from 'core/remoteEventsManager';
import {UserRouter} from "../administration/users/bundleDescription";


export default function ManagementUsersView(config) {
	Utils.apply(this, config);
	this.module = {
		name: 'ManagementUsers',
		initialConfig: config
	};
	this.hasEvents = false;
	this.subscriberId = Utils.guid();
	this.initComponent();
};

jQuery.extend(ManagementUsersView.prototype, Application.prototype, {
	initComponent: function () {
		Application.prototype.initComponent.call(this);


		this.actionNotification = new CustomNotification({
			appendToElement: '#cw_users_list',
		});

		this.removeListeners();
		this.attachListeners();
		this.loadUserPreferences();
		this.removeMask();
	},
	initKendoComponents: function () {
		var usersFilter, usersSort, filterMessages = lang.grid.filter, url;
		var defaultFsView = UserPrefs.get('defaultFsView');

		this.defaultColumns = {
			id: {
				hidden: false,
				width: 40
			},
			username: {
				hidden: false,
				width: 300
			},
			accountName: {
				hidden: false,
				width: 300
			},
			fullName: {
				hidden: false,
				width: 300
			},
			sessionId: {
				hidden: false,
				width: 300
			},
			lastActionTime: {
				hidden: false,
				width: 220
			},
			loginTime: {
				hidden: false,
				width: 220
			},
			authType: {
				hidden: false,
				width: 220
			}
		};
		this.gridMessages = {
			isTrue: '<span class="glyphicons ok cw_true"></span>' + lang.YES,
			isFalse: '<span class="glyphicons remove cw_false"></span>' + lang.NO,
			clear: lang.CLEAR,
			info: lang.grid.filter.SHOW_ITEMS,
			filter: lang.FILTER
		};

		if (Utils.isGuid(defaultFsView)) {
			var pref = JSON.parse(UserPrefs.get(defaultFsView));
			if (pref) {
				usersSort = pref.sort;
				usersFilter = pref.filter;
				this.usersColumns = pref.columns;
			} else {
				usersSort = [];
				usersFilter = [];
			}

		} else {
			if (UserPrefs.get('usersSort')) {
				usersSort = JSON.parse(UserPrefs.get('usersSort'));
			} else {
				usersSort = [{
					field: 'alive',
					dir: 'desc',
					compare: null
				}];
			}
			if (UserPrefs.get('usersFilter')) {
				usersFilter = JSON.parse(UserPrefs.get('usersFilter'));
			} else {
				usersFilter = [];
			}
		}

		var usersColumns = this.usersColumns || JSON.parse(UserPrefs.get('usersColumns')) || this.defaultColumns;
		usersColumns = Utils.completeColumns(usersColumns, this.defaultColumns);
		url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/userSessions' + '?includeSubaccounts=' + State.includeSubaccounts.toString();

		this.dataSource = new kendo.ceeview.DataSource({
			transport: {
				read: {
					url: url,
					dataType: 'json',
					contentType: 'application/json; charset=utf-8',
					type: 'GET',
					cache: false
				}
			},
			sort: usersSort,
			filter: usersFilter,
			schema: {
				model: {
					id: 'id',
					fields: {
						username: {
							type: 'string'
						},
						fullName: {
							type: 'string'
						},
						lastActionTime: {
							type: 'date'
						},
						loginTime: {
							type: 'date'
						}
					}
				},
				parse: function (data) {
					for (var i = 0, length = data.length; i < length; i++) {
						data[i].lastActionTime = new Date(data[i].lastActionTime);
						data[i].loginTime = new Date(data[i].loginTime);
						data[i].sessionId = data[i].id;
					}

					return data;
				}
			}
		});

		if (!usersColumns.sessionId) {
			usersColumns.sessionId = this.defaultColumns.sessionId;
		}

		if (!usersColumns.id) {
			usersColumns.id = this.defaultColumns.id;
		}
		var userCellTemplate = State.mainApp.session.hasRole('USER_UPDATE') ? '<a class="cw_grid_link" data-userid="#= userId #">#=username #</a>' : '#=username#';
		this.grid = $('#cw_users_list').kendoCustomGrid({
			dataSource: this.dataSource,
			resizable: true,
			reorderable: true,
			selectable: 'row',
			sortable: {
				mode: 'multiple',
				allowUnsort: true
			},
			filterable: {
				extra: false,
				operators: {
					string: {
						startswith: filterMessages.STARTS_WITH,
						neq: filterMessages.NEQ,
						eq: filterMessages.EQ,
						contains: filterMessages.CONTAINS
					},
					number: {
						eq: filterMessages.EQ,
						neq: filterMessages.NEQ
					}
				},
				messages: this.gridMessages
			},
			columns: Utils.rearrangeColumns([{
				field: 'id',
				title: lang.SELECTOR,
				sortable: false,
				filterable: false,
				template: '<input #= id === "' +Cookies.sessionId +'"  ? "disabled" : "" # type="checkbox" class="#= id !== "' +Cookies.sessionId +'"  ? "cw_grid_check" : ""#" data-id="#= id #" />',
				headerTemplate: '<input type="checkbox" class="cw_grid_check_all" />',
				attributes: {
					'class': 'text_center'
				},
				headerAttributes: {
					'class': 'text_center'
				},
				hidden: usersColumns.id.hidden,
				width: usersColumns.id.width
			}, {
				field: 'username',
				title: lang.USERNAME,
				template: userCellTemplate,
				hidden: usersColumns.username.hidden,
				width: usersColumns.username.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'accountName',
				title: lang.ACCOUNT,
				hidden: !State.includeSubaccounts || usersColumns.accountName.hidden,
				width: usersColumns.accountName.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'fullName',
				title: lang.FULLNAME,
				sortable: true,
				filterable: true,
				hidden: usersColumns.fullName.hidden,
				width: usersColumns.fullName.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'sessionId',
				title: lang.SESSION_ID,
				sortable: true,
				filterable: true,
				hidden: usersColumns.sessionId.hidden,
				width: usersColumns.sessionId.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'lastActionTime',
				title: lang.management.LAST_COMMAND,
				sortable: true,
				filterable: true,
				template: '<span>#= Renderer.browserDateRenderer(lastActionTime, "datetime") #</span>',
				hidden: usersColumns.lastActionTime.hidden,
				width: usersColumns.lastActionTime.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'loginTime',
				title: lang.management.LAST_LOGIN,
				sortable: true,
				filterable: true,
				template: '<span>#= Renderer.browserDateRenderer(loginTime, "datetime") #</span>',
				hidden: usersColumns.loginTime.hidden,
				width: usersColumns.loginTime.width,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}, {
				field: 'authType',
				title: lang.TYPE,
				sortable: true,
				filterable: true,
				hidden: usersColumns.authType ? usersColumns.authType.hidden : false,
				width: usersColumns.authType ? usersColumns.authType.width : 220,
				attributes: {
					'class': 'tooltip ellipsis to_expand'
				}
			}], usersColumns),
			columnMenu: true,
			change: $.proxy(this.onRowExpand, this),
			dataBound: function () {
				$('#cw_users_list').find('.k-grid-content').kendoTooltip({
					filter: "td.tooltip:not(:empty)",
					content: function (event) {
						return event.target.text().split("\n").join("<br />");
					}
				});
				$('.cw_grid_link').each(function () {
					if ($(this).text() === 'public') {
						$(this).click(false);
						$(this).attr('style', 'cursor: default !important; color: #999');
						$(this).closest('tr').css('color', '#999').attr('title', lang.management.messages.GENERIC_PUBLIC_USERS);
					}
				});
			}
		}).data('kendoCustomGrid')
		// Add Kendo tooltip to the header of the columns
		Utils.gridColumnHeaderTooltip(this.grid);
		this.adjustSectionHeight();
		this.grid.thead.find("[data-field='id']>.k-header-column-menu").remove();
		this.gridMenu = new GridMenu({
			renderTo: 'cw_users_grid_menu',
			items: [{
				id: 'cw_grid_reload',
				icon: 'refresh',
				text: lang.RELOAD,
				fn: this.onReload,
				scope: this,
				disabled: false
			}, {
				id: 'cw_user_logout',
				icon: 'power',
				text: lang.LOGOUT,
				fn: this.onUserLogout,
				role: State.mainApp.session.hasV2Permissions ? 'ACTIVEUSER_DELETE' : undefined,
				scope: this,
				disabled: true
			}]
		});
		var searchValue = UserPrefs.get('defaultFsView') ? '' : (UserPrefs.get('usersSearchPhrase') || '');
		this.gridFilterPanel = new PreferencesPanel({
			renderTo: 'cw_users_filters',
			grid: this.grid,
			modulePrefName: 'ManagementUsers',
			defaultPrefViewKey: 'defaultFsView',
			prefListKey: 'usersFSViews',
			userPref: this.userPref,
			onRemove: $.proxy(this.saveUserPreferences, this),
			searchFields: ['username', 'fullName'],
			searchValue: searchValue,
			defaultColumns: this.defaultColumns
		});

		this.removeMask();
	},
	/**
	 * Removes listeners
	 */
	removeListeners: function () {
		$('#cw_users_list').off();
	},
	/**
	 * Attaches listeners
	 */
	attachListeners: function () {
		var usersHandler = $('#cw_users_list');
		usersHandler.on('click', '.cw_grid_check', $.proxy(this.onCheck, this));
		usersHandler.on('click', '.cw_grid_check_all', $.proxy(this.onCheckAll, this));
		usersHandler.on('click', '.cw_grid_link', $.proxy(this.onUserNameClick, this));
	},
	/**
	 * Handler for the user checkbox click
	 * @param {Object} e The click event
	 */
	onCheck: function (e) {
		if ($('.cw_grid_check:checked').length) {
			this.gridMenu.enableItem('cw_user_logout');
		} else {
			this.gridMenu.disableItem('cw_user_logout');
		}
	},
	/**
	 * Handler for the check all checkbox click
	 * @param {Object} e The click event
	 */
	onCheckAll: function (e) {
		var checkAll = $('.cw_grid_check_all').is(':checked');
		var checkboxes = $('.cw_grid_check');
		var i, length = checkboxes.length;
		for (i = 0; i < length; i++) {
			$(checkboxes[i]).prop('checked', checkAll);
		}

		if (checkAll && checkboxes.length) {
			this.gridMenu.enableItem('cw_user_logout');
		} else {
			this.gridMenu.disableItem('cw_user_logout');
		}
	},
	/*
	 * Handler function to reloading the grid
	 * */
	onReload: function () {
		this.grid.dataSource.read();
		this.actionNotification.setOptions({
			message: lang.messages.GRID_RELOADED,
			status: 'success'
		}).show();
	},
	/*
	 * Handler function to logout user
	 * */
	onUserLogout: function () {
		var checkboxes = $('.cw_grid_check'), checkbox, sessions = [];

		for (var i = 0, length = checkboxes.length; i < length; i++) {
			checkbox = $(checkboxes[i]);
			if (checkbox.is(':checked')) {
				sessions.push(checkbox.data('id'));
			}
		}

		var url = Settings.serverPath + 'sessions/logout';
		Utils.ajax(url, 'POST', JSON.stringify(sessions), $.proxy(function (result) {
			if (result.success) {
				this.grid.dataSource.read();
				this.actionNotification.setOptions({
					message: result.message,
					status: 'success'
				}).show();
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},
	/**
	 * Handle for the click event on the user name
	 * @param {Object} e The click event object
	 */
	onUserNameClick: function (e) {
		var target = $(e.currentTarget), userId = target.data('userid');
		if (State.mainApp.session.hasRole('USER_UPDATE')) {
			State.mainApp.navigate(UserRouter.profile(userId));
		}
		e.stopPropagation();
	},

	/**
	 * Handler function for the change(select) event on the grid
	 * @param {Object} e The change event object
	 */
	onRowExpand: function (e) {
		var selectedRow = this.grid.select();
		var myRow = selectedRow[0];
		var messageEl = $(myRow).find('.to_expand');
		if ($(messageEl).hasClass('cw_message_expanded')) {
			$(messageEl).removeClass('cw_message_expanded').addClass('ellipsis');
		} else {
			$('#cw_users_list').find('.k-grid-content').find('td.cw_message_expanded').removeClass('cw_message_expanded').addClass('ellipsis');
			$(messageEl).addClass('cw_message_expanded').removeClass('ellipsis');
		}
	},
	/**
	 * Called when one or multiple events are received from the server
	 * @param {Object} data The event data
	 */
	onEvent: function (data) {
		this.dataSource.read();
	},
	/**
	 * Saves user preferences
	 */
	saveUserPreferences: function () {
		this.saveUserPrefs({
			category: 'ManagementUsers',
			filterPanel: this.gridFilterPanel,
			grid: this.grid,
			keys: {
				searchPhrase: 'usersSearchPhrase',
				columns: 'usersColumns',
				sort: 'usersSort',
				filter: 'usersFilter',
				FsViews: 'usersFSViews'
			}
		});
	},
	/**
	 * Loads user preferences
	 */
	loadUserPreferences: function () {
		this.userPref = [];
		UserPrefs.load('ManagementUsers', $.proxy(function (result) {
			if (result.success) {
				this.userPref = result.data;
				this.initKendoComponents();
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	},
	/**
	 * Method by Andy
	 */
	adjustSectionHeight: function () {
		var section = $('.cw_section_full:visible'),
			sectionHeight = section.height();
		section.find('.cw_section_content').css('height', sectionHeight);
		section.find('.k-grid-content').css('height', sectionHeight - 40);
	},
	/**
	 * Subscribes to the events
	 */
	subscribe: function () {
		this.isDataSourceSubscribed = true;
		var subscriptionObj = [{
			eventType: ''
		}];
		RemoteEventsManager.subscribe(this.subscriberId, subscriptionObj);
	},
	/**
	 * Called when application is unloaded/destroyed
	 */
	destroy: function () {
		this.saveUserPreferences();
		Application.prototype.destroy.call(this);
	}
});
