import React, {useEffect, useState} from "react";
import {FormEntry} from "../../../controls/react/form/formEntry";
import {AntSelect} from "../../../controls/react/ant/antSelect";
import {AntDatePicker} from "../../../controls/react/ant/antDatePicker";
import {Section} from "../../../controls/react/layout";
import {AntInput, AntTextArea} from "../../../controls/react/ant/antInput";
import {translator} from "../../../core";
import {observer} from "mobx-react";
import {NewBudgetModel} from "./newBudgetModel";
import {AccountsApi} from "api";
import {ApplicationState} from "framework/applicationState";
import {Api} from "tools";
import {CostBudget, CostBudgetData} from "../models/costBudget";

interface BudgetConfigurationFormProps {
	budget: NewBudgetModel,
	nameRef?: React.RefObject<typeof AntInput>
	showDisplayNumberSettings: boolean,
	importSettings?: any
}

const i = translator({
	'Name': { no: 'Navn' },
	'Description': {},
	'Currency': {
		no: 'Valuta'
	},
	'Cost Model': {no: 'Kostnad Modell'},
	'Start month': {
		no: 'Start måned'
	},
	'Duplicate budget values': {no: 'Duplikat budsjett verdier'},
	'Duplicate cost values': {no: 'Duplikat kost verdier'},
	'Display settings': {no: 'Skjerminnstillinger'},
	'Display decimals': {no: 'Vis desimaler'},
	'Display as 1000': {no: 'Vises som 1000'}
})

export const AccountSelect = observer((props: {target: NewBudgetModel, modelField: keyof NewBudgetModel}) => {
	const [accounts, setAccounts] = useState([]);
	useEffect(() => {
		(async () => {
			const url = AccountsApi.getLiteUrl(ApplicationState.accountId);
			const response = await Api.fetch(url);
			setAccounts(response);
		})()
	}, [])

	return <FormEntry label={i('Account')} model={props.target} modelField={props.modelField}>
		<AntSelect dataList={accounts} />
	</FormEntry>
});

export const BudgetConfigurationForm = observer(
class Inner extends React.Component<BudgetConfigurationFormProps> {
	static defaultProps = {
		hideAdvanced: false,
		showDisplayNumberSettings: false
	}

	maxDecimalDigits: number = 20;

	constructor(props: BudgetConfigurationFormProps) {
		super(props);
	}

	render() {
		const { budget, importSettings } = this.props;
		const currencies = ['EUR', 'USD', 'NOK'].reduce((out, name) => {
			out.push({name: name, id: name});
			return out;
		}, []);
		const nameProps: {ref?: any} = {};
		if (this.props.nameRef) {
			nameProps['ref'] = this.props.nameRef;
		}
		return (<>
				<Section appearance={'frame'} title={i('Name')} childrenPadding={true}>
					<FormEntry label={i('Name')} model={budget} modelField={"name"}>
						<AntInput {...nameProps}/>
					</FormEntry>
					{budget?.creationType !== 'COST_COLLECTOR' && <AccountSelect target={budget} modelField={'accountId'}/>}
					<FormEntry label={i('Description')} vertical={true} model={budget} modelField={"description"}>
						<AntTextArea/>
					</FormEntry>
				</Section>
			{importSettings}
			<Section appearance={'frame'} title={i('Cost Model')} childrenPadding={true}>
				<FormEntry label={i('Start month')} model={budget} modelField={"startDate"}>
					<AntDatePicker
						picker={'month'}
						format={'MMM YYYY'}
						allowClear={false}
					/>
				</FormEntry>
				<FormEntry label={i('Currency')} model={budget} modelField={"currency"}>
					<AntSelect dataList={currencies}/>
				</FormEntry>
			</Section>
		</>);
	}
})
