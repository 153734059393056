export let State = {
	mainApp: null,

	get currentApp(){
		return this._currentApp
	},

	set currentApp(value){
		this._currentApp = value;

		this.mainApp?.updateLoadedModulesIformationForLegacyModules(value);
	},

	firstTimeInSummary: true,
	includeSubaccounts: false,
	lastChangesTimestamp: null,
	isUnloading: false,
	widgetForm: null,
	dynamicGridPos: null,
	gridPosLoaded: false,
	markedCheckboxes: [],
	agentStatusTypes: [],
	runScriptCode: false,
	isAccountChanged: false,
	editedSharedQualifiers: [],
	startedIntervals: [],
	metricMonitorProfileSort: [],
    metricColors: ['#0000FF', '#00FF00', '#00FFFF', '#FF00FF', '#FFFF00', '#0000AA', '#00FFAA', '#00AA00', '#00AAFF', '#00AAAA',
        '#FF00AA', '#FFAA00', '#FFAAFF', '#FFAAAA', '#AA0000', '#AA00FF', '#AAFF00', '#AAFF00', '#AAFFFF', '#AAFFAA',
        '#AAAA00', '#33FFFF', '#FF3399', '#33FF99', '#FFCCFF'],
	loadedDashboards: {}
};

export default State;
