import {Api} from "tools/api";
import {DataListTextValue} from "framework/entities/dataList";
import { ApiRequest } from "framework/api";

type GetEventsStateParameters = {
	tags?: string[],
	eventTypes?: string[],
	includeSubaccounts?: boolean,
	accountId?: string
	showUntagged?: boolean
	ignoreUserTags?: boolean
}

export function getEventsState(p: GetEventsStateParameters) {
	return new ApiRequest({
		url: 'summary/eventAggregate',
		accountBased: true,
		accountId: p.accountId,
		includeSubaccounts: p.includeSubaccounts,
		method: 'POST',
		payload: {
			tags: p.tags,
			eventTypes: p.eventTypes,
			showUntagged: p.showUntagged,
			ignoreUserTags: p.ignoreUserTags === undefined ? true : p.ignoreUserTags
		}
	})
}

export function getEventTypes(accountId?: string) {
	return new ApiRequest<DataListTextValue<string>>({
		url: 'summary/eventSummary/types',
		accountId: accountId,
		accountBased: true
	})
}
