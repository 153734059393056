import Cookies from 'core/cookies';
import State from 'tools/state';
import {newGuid} from "tools/guid";

export let Chosen = function (config) {
	Object.assign(this, config);
	this.initComponent();
};

export default Chosen;

Chosen.prototype = {
	/**
	 * @config {jQuery} renderEl The DOM element where the control will be rendered. Mandatory.
	 */

	/**
	 * @config {Array} value Array of objects containing text, value and removable properties
	 **/
	value: [],
	/**
	 * @config {Boolean} enable Allows user input. Default to true
	 **/
	enabled: true,
	/**
	 * @config {String} placeholder Text to be displayed as placeholder. Default to ''
	 **/
	placeholder: '',
	/**
	 * @config {String} dataTextField The textfield name. Default to 'text'
	 **/
	dataTextField: 'text',
	/**
	 * @config {String} dataValueField The textfield name. Default to 'value'
	 **/
	dataValueField: 'value',
	/**
	 * @config {String} nonRemovableMessage The message to display for non-removable targets, default to ''.
	 */
	nonRemovableMessage: '',
	/**
	 * @config {Boolean} editableItems True, if the items can have editable text. Default to false.
	 */
	editableItems: false,

	onChange: function(value){},
	/**
	 * Main init method
	 */
	initComponent: function () {
		var onChange;

		if (this.change) {
			onChange = this.change;
		}

		var tagTemplate;
		this._isDirty = false;
		if (this.editableItems) {
			if (this.nonRemovableMessage || this.nonRemovableItems) {
				//boolean present only in Report Schedule recipients. To be refined
				if (this.optionSeparation) {
					tagTemplate = (data) => {
						if (data.destroyable) {
							return `<span class="cw_item_label" data-value="${data.value}" data-editable="${data.editable}" data-destroyable="${data.destroyable}" title="${data.text}">
									${data.text}
									<span class="glyphicons cw_target_icon" />
									<span style="position:absolute; right: 0; top: 2px; " class="glyphicons ${data.optionGlyphClass}" />
								</span>`;
						} else {
							let title = data.text + ' - ' + this.nonRemovableMessage;
							return `<span class="cw_item_label non_removable_tag" data-value="${data.value}" data-editable="${data.editable}" data-destroyable="${data.destroyable}" title="${title}">
									${data.text}
									<span style="position:absolute; right: 0; top: 2px; " class="glyphicons ${data.optionGlyphClass}" />
								</span>`;
						}
					}
				} else {
					tagTemplate = (data) => {
						if (data.destroyable) {
							return `<span class="cw_item_label" data-value="${data.value}" data-editable="${data.editable}" data-destroyable="${data.destroyable}" title="${data.text}">
									${data.text}
									<span class="glyphicons pencil cw_target_icon" />
								</span>`;
						} else {
							let title = data.text + ' - ' + this.nonRemovableMessage;
							return `<span class="cw_item_label" data-value="${data.value}" data-editable="${data.editable}" data-destroyable="${data.destroyable}" title="${title}">
									${data.text}
								</span>`;
						}
					}
				}
			} else {
				tagTemplate = (data) => {
					if (data.destroyable) {
						return `<span class="cw_item_label" data-value="${data.value}" data-editable="${data.editable}" data-destroyable="${data.destroyable}" title="${data.text}">
								${data.text}
								<span class="glyphicons pencil cw_target_icon" />
							</span>`
					} else {
						return `<span class="cw_item_label" data-value="${data.value}" data-editable="${data.editable}" data-destroyable="${data.destroyable}" title="${data.text}">
								${data.text}
							</span>`
					}
				}
			}
		} else {
			if (this.nonRemovableMessage) {
				tagTemplate = (data) => {
					let title = data.text + ' - ' + this.nonRemovableMessage;
					return `<span class="cw_item_label" data-value="${data.value}" data-editable="${data.editable}" data-destroyable="${data.destroyable}" title="${title}">${data.text}</span>`;
				}
			} else {
				tagTemplate = (data) => {
					return `<span class="cw_item_label" data-value="${data.value}" data-editable="${data.editable}" data-destroyable="${data.destroyable}" title="${data.text}">${data.text}</span>`;
				}
			}
		}


		this._control = this.renderEl.kendoSortedMultiSelect({
			init: (instance) => {
				const originalTagTemplate = instance.tagTemplate;

				instance.tagTemplate = (data) => {
					const html = originalTagTemplate(data);
					if (this.assetTarget && data.destroyable === false) {
						const stripped = $(html);
						stripped.find('span.k-i-close').remove();
						return stripped;
					}
					return html;
				};
			},
			editableMultiselect: true,
			maxSelectedItems: this.maxSelectedItems || null,
			dataSource: this.dataSource || this.value,
			clearButton: !this.assetTarget,
			dataTextField: 'text',
			dataValueField: 'value',
			autoBind: true,
			enable: this.enabled,
			placeholder: this.placeholder,
			tagTemplate: tagTemplate,
			deselect: (e) => {
				if (this.assetTarget && !e.dataItem.destroyable) {
					e.preventDefault();
				}
			},
			dataBound: $.proxy(function() {
				if (this.value && this.updateItemsOnDataBound && !this.valuesAlreadyUpdated) {
					this.valuesAlreadyUpdated = true;
					this.setValues(this.value);
				}
			}, this),
			change: $.proxy(function (e) {
				var currentValues = [];
				let items = e.sender.wrapper.find('.cw_item_label');
				$(items).each(function() {
					currentValues.push($(this).attr('data-value'));
				});

				var i = 0, total = this._control.dataSource.total();
				var newData = [], removedItem;

				while (i < total) {
					var dataItem = this._control.dataSource.at(i);
					if (dataItem.editable) {
						if ($.inArray(dataItem.value, currentValues) !== -1) {
							newData.push({
								text: dataItem.text,
								value: dataItem.value,
								editable: dataItem.editable,
								removable: true,
								destroyable: true
							});
						} else {
							removedItem = dataItem;
						}
					}
					i++;
				}

				//this._control.element.parent().find('ul').empty();
				// this._control.setDataSource(new kendo.ceeview.DataSource({
				// 	data: newData
				// }));

				if (removedItem) {
					/*
					 * notify about item that has been removed
					 * fire change event
					 * */
					if (removedItem.removable) {
						e.sender.dataSource.remove(removedItem);
					}
					this._change('remove', e, removedItem);
				}
				this._control.listView._filtered = false;
				this._control.value(currentValues);
				this._control.listView._filtered = true;
				this._control.listView.refresh();
				if (onChange) {
					onChange(currentValues);
				}
				this.fireOnChange();

				let maxItems = this._control.options.maxSelectedItems;
				if (maxItems <= currentValues.length) {
					this.blurDueToMaxItemsReached = true;
					this._control.input.blur();
				}
			}, this),
			open: $.proxy(function () {
				if (this.open && typeof(this.open) === 'function') {
					this.open();
				}
			}, this)
		}).data('kendoSortedMultiSelect');


		this._control.input.on("focusout", $.proxy(function(e) {
			if (this.newItem && this.newItem !== this.placeholder) {
				let currentValues = this._control.value();
				let currentDatasource = this._control.dataSource.data().toJSON();
				let newItemId = newGuid();
				currentDatasource.push({
					value: newItemId,
					text: this.newItem,
					editable: true,
					removable: true,
					destroyable: true
					//accountName: Cookies.CeesoftCurrentAccountName
				});
				currentValues.push(newItemId);
				this._control.setDataSource(currentDatasource);
				this._control.value(currentValues);
			}
		}, this));

		var targetNode = $(this.renderEl);
		var span = targetNode.next('span');
		var scope = this;
		$(span).on('DOMSubtreeModified', function() {
			scope.newItem = $(this).text();
		});

		var length = this.value.length;
		if (length) {
			var values = [];
			for (var i = 0; i < length; i++) {
				values.push(this.value[i].value);
			}
			if (values.length) {
				this._control.value(values);
			}
		}
		this.renderEl.prev().find('input').on('keyup', $.proxy(this._onKeyUp, this));
		this.renderEl.prev().find('input').off('blur');
		this.renderEl.prev().find('input').on('blur', $.proxy(this._onBlur, this));
		this.renderEl.prev().find('input').on('keydown', $.proxy(this._onKeyDown, this));
		//this.renderEl.prev().find('input').bindFirst('keypress', $.proxy(this._onKeyDown, this));
		this.renderEl.parent().on('click', '.cw_target_icon', $.proxy(this._onTargetNameEdit, this));
		this._updateNonEditableTags();
		if (!this.enabled) {
			this.renderEl.parent().find('.cw_target_icon').addClass('hide');
		}
	},

	fireOnChange(){
		this.onChange(this.getValues());
	},
	/**
	 * Handler function for chosen change event
	 * @private
	 * @param {String} type The event type that has happened
	 * @param {Object} e The event where the change has happened
	 * @param {Object} element The element that has been modified, added or removed
	 * @param {Boolean} isNewItemAdded Flag to know if the change method is triggered from a new added element case; useful to remove a potential invalid new entry
	 */
	_change: function (type, e, element, isNewItemAdded) {
		this._isDirty = true;
		if (this.change) {
			this.change.call(this, {
				type: type,
				actionEvent: e,
				element: element,
				controlHandler: {
					dataSource: this._control.dataSource
				},
				isNewItemAdded: isNewItemAdded
			});
		}
	},
	/*
	* Handler function for checking if component has changes
	* */
	hasChanges: function () {
		return this._isDirty;
	},

	setDataSource: function(dataSource){
		this._control.setDataSource(dataSource);
	},
	/**
	 * Handler function for the keyup event on input
	 * @private
	 * @param {Event} e The keyup event object
	 */
	_onKeyUp: function (e) {
		let text = $(e.currentTarget).val();
		let isNewItemAdded = true;
		if (e.keyCode === 13) {
			this._dirtyText = '';
		} else {
			this._dirtyText = text;
		}
		if ((e.keyCode === 13) && text) {
			let record = {
				text: text,
				value: newGuid(),
				editable: true,
				removable: true,
				destroyable: true
			};
			if (this.optionSeparation) {
				record.type = 'EMAIL';
				record.optionGlyphClass = 'envelope';
			}
			let control = $(this.renderEl).data('kendoSortedMultiSelect');
			//control.dataSource.add(record);

			this._change('add', e, record, isNewItemAdded);
			control.refresh();
			this.renderEl.prev().find('input').trigger('blur');
			let focus = $.proxy(function () {
				this.renderEl.prev().find('input').trigger('focus');
			}, this);
			setTimeout(focus, 100);
			this.fireOnChange();
		}
		if (e.keyCode === 8 || e.keyCode === 13) {
			this._updateNonEditableTags();
		}
	},

	_onTargetNameEdit: function (e) {
		State.isItemEdited = true;
		var span = $(e.currentTarget);
		var wrapper = span.parent().parent();
		span.addClass('hide');
		var tagWidth = span.parent().width() + 20;
		var tagValue = span.parent().data('value');
		var data = this._control.dataSource.data();
		var tagText = data.find(x => x.value === tagValue).text;
		this._orginalTagText = tagText;
		span.parent().addClass('hide');
		wrapper.append('<input class="cw_target_input" type="text" style="width:' + tagWidth + 'px;"  value="' + tagText + '" data-id="' + tagValue + '"/>');
		this.renderEl.parent().find('.cw_target_input').off().on('keyup', $.proxy(this._onTargetEditKeyUp, this));
		this.renderEl.parent().find('.cw_target_input').on('blur', $.proxy(this._onTargetEditBlur, this));
		this.renderEl.parent().find('.cw_target_input').focus();
	},
	_onTargetEditKeyUp: function (e) {
		//enter
		var input = $(e.currentTarget);
		var text = input.val();
		if (e.keyCode === 13 && text) {
			var value = input.attr('data-id');
			var data = this._control.dataSource.data();
			var item = data.find(x => x.value == value);
			var oldText = item.text;
			item.text = text;
			item.oldText = oldText;
			this._change('update', e, item);
			input.parent().find('.cw_target_icon').removeClass('hide');
			input.parent().find('.cw_target_icon').parent().removeClass('hide');
			input.parent().find('.cw_target_icon').parent().get(0).firstChild.nodeValue = text + ' ';
			input.off().remove();

			this.fireOnChange();
		}
		if (e.keyCode === 27) {
			input.parent().find('.cw_target_icon').removeClass('hide');
			input.parent().find('.cw_target_icon').parent().removeClass('hide');
			input.off().remove();
		}
		if (!text) {
			input.addClass('invalid');
		} else {
			input.removeClass('invalid');
		}
	},
	_onTargetEditBlur: function (e) {
		var input = $(e.currentTarget);
		var text = input.val();
		if (text) {

			var value = input.attr('data-id');
			var data = this._control.dataSource.data();
			data.find( x => x.value == value).text = text;
			input.parent().find('.cw_target_icon').removeClass('hide');
			input.parent().find('.cw_target_icon').parent().removeClass('hide');
			input.parent().find('.cw_target_icon').parent().get(0).firstChild.nodeValue = text + ' ';
			input.off().remove();
		} else {
			input.focus();
			input.addClass('invalid');
		}
	},
	_onKeyDown: function (e) {
		if (e.keyCode === 8) {
			var lastTagElement = this._control.wrapper.find('ul').find('li:last').find('span:first').find('span:first');
			if (lastTagElement.attr('data-destroyable') === 'false') {
				e.stopImmediatePropagation();
			}
		}
	},
	_onBlur: function (e) {
		var text = this._dirtyText;
		if (text) {
			var record = {
				text: text,
				value: newGuid(),
				editable: true,
				removable: true,
				destroyable: true
			};
			if (this.optionSeparation) {
				record.type = 'EMAIL';
				record.optionGlyphClass = 'envelope';
			}
			var data = this._control.value();
			var values = [];
			for (var i = 0, length = data.length; i < length; i++) {
				values.push(data[i]);
			}
			//this._control.dataSource.add(record);
			this._control.dataSource.filter([]);
			values.push(record.value);
			this._control.refresh();
			/*
			 * notify about item that has been removed
			 * fire change event
			 * */
			if (!this.blurDueToMaxItemsReached) {
				this._change('add', e, record);
			}
			this._control.value([]);
			this._control.listView._filtered = false;
			this._control.value(values);
			this._control.listView._filtered = true;
			this._control.listView.refresh();
			this._dirtyText = '';
			//this.renderEl.prev().find('input').trigger('blur');
			var focus = $.proxy(function () {
				if (!this.blurDueToMaxItemsReached) {
					this.renderEl.prev().find('input').trigger('focus');
				}
				this.blurDueToMaxItemsReached = false;
			}, this);
			setTimeout(focus, 100);
			this.fireOnChange();
		}
		this._updateNonEditableTags();
	},
	_updateNonEditableTags: function () {
		this.renderEl.parent().find('span[data-destroyable="false"]').each(function (index, element) {
			var el = $(element);
			if (!el.data('destroyable')) {
				el.parent().next().find('.k-i-close').remove();
			}
		});
		this.enable(this.enabled);
	},
	/**
	 * Gets the selected values
	 * @returns {Array} values Array with the selected values (ids)
	 */
	_getValues: function () {
		return this._control.dataItems();
	},
	/**
	 * Sets values for the control
	 * @param {Array} values Array of objects (text, value, editable)
	 */
	_setValues: function (values) {
		// this._control.setDataSource(new kendo.ceeview.DataSource({
		// 	data: values
		// }));
		var ids = [];
		var length = values.length;
		if (length) {
			var data = this._control.dataSource.data();
			for (var i = 0; i < length; i++) {
				//second condition quickfix for 2.2 to not duplicate items; to be refined and check if any case in this is wanted (now only when optionSeparation boolean)
				if (!data.find(x => x.value == values[i].value)) {
					if (this.optionSeparation) {
						var currentItems = this._control.dataSource.data();
						for (var j = 0; j < currentItems.length; j++) {
							if (currentItems[j].text === values[i].text) {
								var itemToBeRemoved = this._control.dataSource.at(j);
								this._control.dataSource.remove(itemToBeRemoved);
							}
						}
					}
					this._control.dataSource.add(values[i]);
				}
				ids.push(values[i].value);
			}
			this._control.value(ids);
		} else {
			this._control.value([]);
		}
	},
	/**
	 * Sets values for the control
	 * @param {Array} values Array of objects (textfield, valuefield, editable)
	 */
	setValues: function (values) {
		values = values || [];

		var _values = [];
		for (var i = 0, length = values.length; i < length; i++) {
			_values.push({
				text: values[i][this.dataTextField],
				value: values[i][this.dataValueField] || newGuid(),
				editable: values[i].editable || false,
				removable: values[i].removable || false,
				destroyable: values[i].destroyable || false
			});
			if (this.optionSeparation) {
				_values[i].type = values[i].type;
				_values[i].optionGlyphClass = values[i].optionGlyphClass;
			}
		}
		this._setValues(_values);
	},
	/**
	 * Gets array of objects (texfield, valuefield)
	 * @return {Array}
	 */
	getValues: function () {
		let data = this._control.dataItems();
		for (let item of data) {
			item[this.dataTextField] = item.text.trim();
			item[this.dataValueField] = item.value;
		}
		return data;
	},
	/**
	 * Enables/disables the control
	 * @param {Boolean} active
	 */
	enable: function (active) {
		this._control.enable(active);
		this.enabled = active;
		if (active) {
			this.renderEl.parent().find('.k-delete, .cw_target_icon').each(function (index, element) {
				$(element).removeClass('hide');
			});
		} else {
			this.renderEl.parent().find('.k-delete, .cw_target_icon').each(function (index, element) {
				$(element).addClass('hide');
			});
		}
	},
	readonly: function (value) {
		this._control.readonly(value);
		if (value) {
			this._control.wrapper.addClass('no_edit');
		} else {
			this._control.wrapper.removeClass('no_edit');
		}
	},
	destroy: function() {
		this._control.destroy();
	}
};
