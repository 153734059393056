import Api from 'tools/api';
import Settings from "settings";

class SlaApi {
	static getSlaUrl(accountId, id, update) {
		return Api.accountRoot(accountId) + `slas/${id}/?update=${update}`;
	}

	static getSlaLiteUrl(accountId) {
		return Api.accountRoot(accountId) + `slas/lite?includeSubaccounts=true`;
	}

	static getDynamicSearchUrl(includeSubaccounts, accountId) {
		return Api.accountRoot(accountId) + `slas/search/lite?includeSubaccounts=` + includeSubaccounts;
	}

	static list(accountId, cache) {
		return Api.fetch(
			SlaApi.getSlaLiteUrl(accountId),
			null,
			cache,
			'sla' + accountId
		);
	}

	static calculation(slaId, accountId, sessionId) {
		return Api.fetch(
			Api.sessionAndAccountRoot(accountId, sessionId) + 'slas/' + slaId + '/calculation'
		);
	}

	static getName(slaId, accountId, sessionId) {
		return Api.fetch(Api.sessionAndAccountRoot(accountId, sessionId) + 'slas/' + slaId + '/name');
	}
}

export {SlaApi};
