
export const root = '/assets/monitors';

export default class Router {
	static details(type, id) {
		if (type !== 'FILESEARCH' && type != 'SNMP' && type != 'SNMPTRAP' && type != 'XEN_SERVER' &&
			type != 'REMOTESYSTEM' && type != 'REMOTEISERIES' && type != 'METRICMONITOR' &&
			type != 'LOGMONITOR' && type != 'DATABASE' && type !== 'EATONUPS'
			&& type !== 'MODBUS' && type !== 'URL'
			&& type !== 'DBWATCH' && type !== 'SCRIPT'
			&& type !== 'CONTAINER' && type != 'INTERFACETRAFFIC')
			return '';

		let monitorRoot = type.toLowerCase();

		if (type == 'SNMPTRAP') {
			monitorRoot = 'snmp-trap';
		}

		if (type == 'XEN_SERVER') {
			monitorRoot = 'xenserver';
		}

		if (type == 'METRICMONITOR') {
			monitorRoot = 'metric';
		}

		if (type == 'LOGMONITOR') {
			monitorRoot = 'log';
		}

		if(type == 'INTERFACETRAFFIC'){
			monitorRoot = 'interface-traffic';
		}

		return `${root}/${monitorRoot}/${id}`;
	}
}
