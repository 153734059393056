export function mapFieldsToForm(forms) {
    const out = {};

    for (const [index, form] of forms.entries()) {
        for (const field of form.components) {
            out[field] = index;
        }
    }

    return out;
};