import Utils from 'tools/utils';
import Widget from 'areas/service-boards/widget';
import Settings from 'settings';
import CustomNotification from 'controls/customNotification';
import RemoteEventsManager from 'core/remoteEventsManager';
import State from 'tools/state';
import ChunkProgressBar from 'controls/chunkProgressBar';
import Renderer from 'tools/renderer';
import {debounce} from "lodash";

export function StateWidget(config) {
	Widget.call(this, config);

	this.subscriptionId = null;
	this.requestPath = Settings.serverPath;
	if (this.sessionId) {
		this.requestPath = Settings.serverPath + 'sessions/' + this.sessionId + '/';
	}
	this.configuration.includeSubaccounts = this.configuration.includeSubaccounts || false;
	this.initComponent();
	this.onEvent = debounce(this.onEvent, 5000);
};

export {StateWidget as default};

jQuery.extend(StateWidget.prototype, Widget.prototype, {
	/**
	 * Main init function
	 */
	initComponent: function () {
		this.widgetHandler = $('#' + this.id);

		this.renderContent();
		this.removeListeners();
		this.attachListeners();

		this.statusNotification = new CustomNotification({
			animationTime: 0,
			appendToElement: this.widgetHandler,
		});
	},
	/**
	 * Attaches listeners
	 */
	attachListeners: function () {
		this.widgetHandler.on('drop', $.proxy(this.onDrop, this));
		if (!this.sessionId && State.currentApp?.dashboardDesigner?.props?.mode !== 'designer') {
			this.widgetHandler.on('click', '.cw_bar, .cw_progress_bar', $.proxy(this.onAssetHealthClick, this));
		}
		$(window).on('resize', $.proxy(this.updateBarWidgetLayout, this));

	},
	/**
	 * Removes listeners
	 */
	removeListeners: function () {
		this.widgetHandler.off();

	},
	/**
	 * Renders widget content
	 */
	renderContent: function () {
		var html = '';
		html += '<div class="cw_account_health_chart cw_bars_chart">';
		html += '<div class="cw_bars cw_account_health_bars">';

		this.severities = this.configuration.severities || ['CRITICAL', 'MAJOR', 'MINOR', 'OK', 'INACTIVE'];
		if (this.severities.indexOf('CRITICAL') !== -1) {
			html += '<div class="cw_bar is_critical no_bg"><span class="value">0</span><span class="legend">' + lang.CRITICAL + '</span></div>';
		}
		if (this.severities.indexOf('MAJOR') !== -1) {
			html += '<div class="cw_bar is_major no_bg"><span class="value">0</span><span class="legend">' + lang.MAJOR + '</span></div>';
		}
		if (this.severities.indexOf('MINOR') !== -1) {
			html += '<div class="cw_bar is_minor no_bg"><span class="value">0</span><span class="legend">' + lang.MINOR + '</span></div>';
		}
		if (this.severities.indexOf('OK') !== -1) {
			html += '<div class="cw_bar is_ok no_bg"><span class="value">0</span><span class="legend">' + lang.OK + '</span></div>';
		}
		if (this.severities.indexOf('INACTIVE') !== -1) {
			html += '<div class="cw_bar is_idle no_bg"><span class="value">0</span><span class="legend">' + lang.INACTIVE + '</span></div>';
		}

		html += '</div>';
		html += '<div class="cw_progress"></div>';
		html += '</div>';
		this.widgetHandler.find('.cw_section_content').empty().append(html);
		if (this.isDashboard) {
			this.widgetHandler.find('.cw_progress').addClass('cw_dashboard_progress');
		}

		this.subscribe();
		this.renderHealthChart();
	},
	/**
	 * Populates the Service Health section
	 */
	renderHealthChart: function () {
		var url = this.requestPath + 'accounts/' + this.configuration.accountId + '/summary/healthAggregate?includeSubaccounts=' + this.configuration.includeSubaccounts.toString();
		var health;

		let tags = this.configuration.tags;
		let tagsArray = [];
		if (tags) {
			for (let i = 0; i < tags.length; i++) {
				if (tags[i].name) {
					tagsArray.push(tags[i].name);
				} else if (typeof tags[i] === 'string') {
					tagsArray.push(tags[i]);
				}
			}
		}
		this.configuration.tags = tagsArray;

		var data = {
			tags: this.configuration.tags,
			showUntagged: this.configuration.showUntagged,
			assetGroupIds: this.configuration.assetGroupIds,
			ignoreUserTags: true
		};
		Utils.ajax(url, 'POST', JSON.stringify(data), $.proxy(function (result) {
			if (result.success) {
				this.data = result.data;
				health = result.data;
				var items = [{percentageWidth: 100, cssClass: 'is_idle'}];
				if (this.widgetHandler.find('.cw_account_health_chart').length) {
					var healthBars = this.widgetHandler.find('.cw_bars');
					healthBars.find('.is_critical').find('.value').text(health.critical);
					healthBars.find('.is_critical').removeClass('no_bg').addClass(health.critical ? '' : 'no_bg');
					healthBars.find('.is_major').find('.value').text(health.major);
					healthBars.find('.is_major').removeClass('no_bg').addClass(health.major ? '' : 'no_bg');
					healthBars.find('.is_minor').find('.value').text(health.minor);
					healthBars.find('.is_minor').removeClass('no_bg').addClass(health.minor ? '' : 'no_bg');
					healthBars.find('.is_ok').find('.value').text(health.ok);
					healthBars.find('.is_ok').removeClass('no_bg').addClass(health.ok ? '' : 'no_bg');
					healthBars.find('.is_idle').find('.value').text(health.maintenance);
					healthBars.find('.is_idle').removeClass('no_bg').addClass(health.maintenance ? '' : 'no_bg');
					var percentage0 = 0,
						percentage20 = 0,
						percentage60 = 0,
						percentage40 = 0,
						percentage100 = 0;

					if (health) {
						if (health.total !== 0) {
							if (this.severities.indexOf('CRITICAL') === -1) {
								health.total -= health.critical;
							}
							if (this.severities.indexOf('MAJOR') === -1) {
								health.total -=  health.major;
							}
							if (this.severities.indexOf('MINOR') === -1) {
								health.total -= health.minor;
							}
							if (this.severities.indexOf('OK') === -1) {
								health.total -= health.ok;
							}
							if (this.severities.indexOf('IDLE') === -1) {
								health.total -= health.maintenance;
							}

							percentage0 = health.maintenance * 100 / health.total;
							percentage60 = health.minor * 100 / health.total;
							percentage40 = health.major * 100 / health.total;
							percentage20 = health.critical * 100 / health.total;
							percentage100 = health.ok * 100 / health.total;

							items = [];
							if (this.severities.indexOf('CRITICAL') !== -1) {
								items.push({
									percentageWidth: percentage20,
									cssClass: 'is_critical',
									title: lang.widget.messages.TOTAL_CRITICAL_ASSETS + ' ' + health.critical
								});
							}
							if (this.severities.indexOf('MAJOR') !== -1) {
								items.push({
									percentageWidth: percentage40,
									cssClass: 'is_major',
									title: lang.widget.messages.TOTAL_MAJOR_ASSETS + ' ' + health.major
								});
							}
							if (this.severities.indexOf('MINOR') !== -1) {
								items.push({
									percentageWidth: percentage60,
									cssClass: 'is_minor',
									title: lang.widget.messages.TOTAL_MINOR_ASSETS + ' ' + health.minor
								});
							}
							if (this.severities.indexOf('OK') !== -1) {
								items.push({
									percentageWidth: percentage100,
									cssClass: 'is_ok',
									title: lang.widget.messages.TOTAL_OK_ASSETS + ' ' + health.ok
								});
							}
							if (this.severities.indexOf('IDLE') !== -1) {
								items.push({
									percentageWidth: percentage0,
									cssClass: 'is_idle',
									title: lang.widget.messages.TOTAL_MAINTENANCE_ASSETS + ' ' + health.maintenance
								});
							}
						}
					}
					var healthChunkProgresBar = new ChunkProgressBar({
						items: items,
						renderTo: this.widgetHandler.find('.cw_progress')
					});

					items.forEach(x => {
						healthBars.find('.' + x.cssClass).attr('title', x.title);
					});

					Renderer.assetBarsIcons(health, this.widgetHandler.find('.cw_account_health_chart'));
				}
			} else {
				this.showErrorMessage(result.message);
			}
		}, this), null, 60000, $.proxy(function () {
			this.statusNotification.setOptions({
				message: lang.messages.NO_DATA_AVAILABLE,
				status: 'error'
			}).show();
		}, this));
	},
	/**
	 * Handler function for the click event on the asset health from buckets/grid
	 * @param {Object} e The click event object
	 */
	onAssetHealthClick: function (e) {
		if(!this.redirectConfig?.doDefaultRedirect)
			return;
		e.stopPropagation();
		var target = $(e.currentTarget);
		var healthIndexRangeType = null;
		var serviceId = null;
		var openAssetTab = true;
		var source = '';
		if (target.hasClass('cw_bar') || target.hasClass('cw_progress_bar')) {
			if (target.find('.value').text() === '0') {
				openAssetTab = false;
			} else {
				if (target.hasClass('is_critical')) {
					healthIndexRangeType = 'CRITICAL';
				} else if (target.hasClass('is_major')) {
					healthIndexRangeType = 'MAJOR';
				} else if (target.hasClass('is_minor')) {
					healthIndexRangeType = 'MINOR';
				} else if (target.hasClass('is_ok')) {
					healthIndexRangeType = 'OK';
				} else if (target.hasClass('is_idle')) {
					healthIndexRangeType = 'MAINTENANCE';
				}
				source = 'buckets';
			}
		}
		if (openAssetTab) {
			var assetConfig = {
				source: source,
				healthIndexRangeType: healthIndexRangeType,
				accountList: [this.configuration.accountId],
				serviceId: serviceId,
				isView: true,
				showUntagged: this.configuration.showUntagged,
				assetGroupIds: this.configuration.assetGroupIds
			};
			if (this.configuration.tags?.length) {
				assetConfig.filteringTags = this.configuration.tags;
				assetConfig.ignoreUserTags = true;
			}
			State.currentApp.tryExit('AssetHealthSummary', '', assetConfig, undefined, this.redirectConfig?.defaultRedirectInNewTab);
		}
	},
	/**
	 * Called when multiple events are received from the server
	 */
	onEvent: function () {
		this.renderHealthChart();
	},
	/**
	 * Triggered after widget resize
	 */
	onResize: function () {
	},
	/**
	 * handler function for the drop event
	 * @deprecated
	 */
	onDrop: function () {
		this.destroy();
		this.subscribe();
	},
	/**
	 * Refresh the widget
	 */
	refresh: function () {
		this.renderHealthChart();
	},
	/**
	 * Subscribes to server health events
	 */
	subscribe: function () {
		var subscriptionObj = [{
			eventType: 'AssetHealth',
			accountId: this.configuration.accountId,
			includeSubaccounts: State.includeSubaccounts
		}];
		this.subscriberId = this.id;
		RemoteEventsManager.subscribe(this.subscriberId, subscriptionObj);
	},
	/**
	 * Destroy
	 */
	destroy: function () {
		Widget.prototype.destroy.call(this);
	}
});
